import * as React from 'react';
import CardEnumOptions from '../Shared/CardEnumOptions';
import CardEnumWysiwygOptions from '../Shared/CardEnumWysiwygOptions';
import CheckboxValidation from '../Shared/CheckboxValidation';

export function ResponsiveScript({ parameters, onChange }) {
  const enumArray = Array.isArray(parameters.items.enum)
    ? parameters.items.enum
    : [];
  const scriptsArray = Array.isArray(parameters.value) ? parameters.value : [];

  return (
    <div className="responsive-script-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        className="focusable-wsywig-form-input"
      >
        <label>Responsive Script</label>
        <div className="card-enum">
          <label>Scripts</label>
          <div className="responsive-item-check">
            <CardEnumWysiwygOptions
              initialValues={scriptsArray}
              names={
                Array.isArray(parameters.value)
                  ? parameters.value.map(val => `${val}`)
                  : undefined
              }
              onChange={newEnum => {
                newEnum = newEnum || 'option 1';
                newEnum = newEnum && newEnum.length > 0 ? newEnum : [''];
                return onChange({
                  ...parameters,
                  value: newEnum,
                });
              }}
              type="string"
            />
          </div>
        </div>
      </div>
      <br />
      <label>Responsive Options</label>
      <div className="card-enum">
        <div className="responsive-item-check">
          <CheckboxValidation
            onChangeValue={() => {
              if (parameters.hasOutcome !== 1) {
                onChange({
                  ...parameters,
                  hasOutcome: 1,
                  items: {
                    type: 'string',
                    enum: parameters.items.enumNames,
                    enumNames: parameters.items.enumNames,
                  },
                });
              } else {
                onChange({
                  ...parameters,
                  hasOutcome: 0,
                  items: {
                    type: 'string',
                    enum: parameters.items.enumNames,
                    enumNames: parameters.items.enumNames,
                  },
                });
              }
            }}
            isChecked={parameters.hasOutcome === 1 ? true : false}
            label="Has an Outcome?"
            id={`outcome`}
          />
        </div>
        <CardEnumOptions
          initialValues={enumArray}
          names={
            Array.isArray(parameters.items.enumNames)
              ? parameters.items.enumNames.map(val => `${val}`)
              : undefined
          }
          showNames={Array.isArray(parameters.items.enumNames)}
          hasOutcome={parameters.hasOutcome}
          onChange={(newEnum, enumNames) => {
            newEnum = newEnum || 'option 1';
            newEnum = newEnum && newEnum.length > 0 ? newEnum : [''];
            enumNames = enumNames && enumNames.length > 0 ? enumNames : [''];
            return onChange({
              ...parameters,
              items: {
                enum: newEnum,
                enumNames,
                type: 'string',
              },
            });
          }}
          type="string"
        />
      </div>
    </div>
  );
}
