import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Header, Button, Message, List, Icon } from 'semantic-ui-react';
import _ from 'lodash';

//Import Components
import GranteeUnavailabilityAccordion from './GranteeUnavailabilityAccordion';

class GranteeCalendarSubmitModal extends Component {
  state = {
    showSubmitModal: false,
    showValidationModal: false,
    reviewMessage: false,
    showHSRequired: false,
    showEHSRequired: false,
  };

  handleShowModal = () => {
    let result = true;
    const {
      monthsSelected,
      isHSRequired,
      isEHSRequired,
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
    } = this.props;

    this.resetDefaults();

    Object.keys(monthsSelected).forEach(function(key) {
      if (!monthsSelected[key]) result = false;
    });

    if (!result) this.setState({ reviewMessage: true });

    if (isHSRequired && (_.isEmpty(hsStartDate) || _.isEmpty(hsEndDate))) {
      this.setState({ showHSRequired: true });
      result = false;
    }

    if (isEHSRequired && (_.isEmpty(ehsStartDate) || _.isEmpty(ehsEndDate))) {
      this.setState({ showEHSRequired: true });
      result = false;
    }

    if (result) {
      this.setState({ showSubmitModal: true });
    } else {
      this.setState({ showValidationModal: true });
    }
  };

  resetDefaults = () => {
    this.setState({
      showValidationModal: false,
      showSubmitModal: false,
      reviewMessage: false,
      showHSRequired: false,
      showEHSRequired: false,
    });
  };

  handleCancelCloseAction = () => {
    this.setState({ showSubmitModal: false });
  };

  handleContinueCloseAction = () => {
    this.setState({ showSubmitModal: false });
    this.props.closeAction();
  };

  handleHideModal = () => {
    this.setState({ showValidationModal: false });
  };

  render() {
    let {
      showSubmitModal,
      showValidationModal,
      reviewMessage,
      showHSRequired,
      showEHSRequired,
    } = this.state;
    let {
      finalUpdate,
      unavailableDates,
      sessionDates,
      breakDates,
    } = this.props;

    const reviewAllMessage =
      'You have not completed reviewing all the months for the fiscal year, ' +
      'please complete reviewing them before submitting the Unavailability Calendar. ' +
      'The reviewed months will have checkmarks next to them.';

    const hsRequiredMessage =
      'The HS:School Year Start Date and HS:School Year End Date are required fields.';

    const ehsRequiredMessage =
      'The EHS:School Year Start Date and EHS:School Year End Date are required fields.';

    return (
      <div>
        <Button
          disabled={finalUpdate}
          color="green"
          onClick={this.handleShowModal}
        >
          Submit Unavailability
        </Button>
        <Modal show={showSubmitModal} onHide={this.handleShowCancelAction}>
          <Modal.Header closeButton>
            <Modal.Title>Submit Unavailability</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Header as="h4">
              Are you sure you want to submit your unavailability? You will not
              have access to edit your unavailability once submitted.
            </Header>
            <Message content="Please make sure you have set your unavailability correctly before clicking Continue." />
            <GranteeUnavailabilityAccordion
              unavailableDates={unavailableDates}
              sessionDates={sessionDates}
              breakDates={breakDates}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button color="green" onClick={this.handleContinueCloseAction}>
              Continue
            </Button>
            <Button color="red" onClick={this.handleCancelCloseAction}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showValidationModal} onHide={this.handleHideModal}>
          <Modal.Header closeButton>
            <Modal.Title>There are error(s) on the page:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Message negative>
              <Message.Header>
                The following error(s) need to be fixed before you can submit
                the unavailability.
              </Message.Header>
              <List>
                {reviewMessage ? (
                  <List.Item className="red">
                    {' '}
                    <Icon name="help" />
                    {reviewAllMessage}
                  </List.Item>
                ) : (
                  ''
                )}
                {showHSRequired ? (
                  <List.Item className="red">
                    {' '}
                    <Icon name="help" />
                    {hsRequiredMessage}
                  </List.Item>
                ) : (
                  ''
                )}
                {showEHSRequired ? (
                  <List.Item className="red">
                    {' '}
                    <Icon name="help" />
                    {ehsRequiredMessage}
                  </List.Item>
                ) : (
                  ''
                )}
              </List>
            </Message>
          </Modal.Body>
          <Modal.Footer>
            <Button color="red" onClick={this.handleHideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(GranteeCalendarSubmitModal);
