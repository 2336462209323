import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Message, Segment } from 'semantic-ui-react';

// Import actions.
import {
  addSubmission,
  fetchFollowUpTaskDetail,
  fetchSubmission,
  saveDraft,
} from '../../../actions/followUpActions';
import AmsAlert from '../../../utils/AmsAlert';
import FollowUpAdditionalCitationsForm from './FollowUpAdditionalCitationsForm';
import FollowUpCitationsForm from './FollowUpCitationsForm';

// Import helper function.
import enforceRole from '../../../utils/EnforceRole';

// Import acl.
import { acl } from '../../../config';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';

class FollowUpFormContainer extends Component {
  state = {
    errors: [],
    surveyData: {},
    followUpTaskDetail: {},
    formData: {},
    showAlert: false,
    formProcessing: false,
    alertMessage: '',
    alertType: '',
    amsSubmissionId: '',
    editMode: false,
    formDisabled: false,
    formDisableClassName: '',
    errorType: '',
  };

  componentWillReceiveProps(nextProps) {
    const {
      followUpTaskDetail,
      selectedSubmission,
      selectedSubmission: { data },
      formProcessing,
    } = nextProps;
    if (!selectedSubmission || !data || !data.surveyData || !data.surveyData) {
      let cleanRecommendations =
        followUpTaskDetail.FollowInfo &&
        followUpTaskDetail.FollowInfo.furRecommendations &&
        followUpTaskDetail.FollowInfo.furRecommendations.map(recommendation => {
          if (recommendation) {
            recommendation.evidences &&
              // eslint-disable-next-line array-callback-return
              recommendation.evidences.filter(evidence => {
                if (evidence) {
                  if (evidence.includes('data:')) return evidence;
                }
              });
          }
          return recommendation;
        });
      this.setState(
        {
          surveyData: {
            additionalCitations:
              followUpTaskDetail.FollowInfo &&
              followUpTaskDetail.FollowInfo.additionalCitations &&
              followUpTaskDetail.FollowInfo.additionalCitations
                ? followUpTaskDetail.FollowInfo.additionalCitations
                : [],
            furRecommendations:
              followUpTaskDetail.FollowInfo &&
              followUpTaskDetail.FollowInfo.furRecommendations &&
              followUpTaskDetail.FollowInfo.furRecommendations
                ? cleanRecommendations
                : [],
          },
          formProcessing,
        },
        () => this.disableForm()
      );
    } else if (selectedSubmission && data && data.surveyData) {
      this.setState(
        {
          surveyData: data.surveyData,
          formProcessing,
        },
        () => this.disableForm()
      );
    }
  }

  validate = surveyData => {
    let errors = [];
    let citations = [];
    const duplicateCitationsCheck = [];
    Object.keys(surveyData).forEach(formKey => {
      // eslint-disable-next-line array-callback-return
      surveyData[formKey].map(recommendation => {
        let missingFields = [];
        if (formKey === 'furRecommendations') {
          citations.push(recommendation.associatedCitations);
          if (
            (recommendation.granteeCorrectedCitationResponse !== undefined &&
              (recommendation.granteeCorrectedCitationResponse === '' ||
                recommendation.granteeCorrectedCitationResponse === null)) ||
            recommendation.granteeCorrectedCitationResponse === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.associatedCitations,
              requiredField: 'granteeCorrectedCitationResponse',
              message: 'Grantee corrected citation option is a required field',
            };
          } else if (
            (recommendation.correctedCitationReason !== undefined &&
              (recommendation.correctedCitationReason === '' ||
                recommendation.correctedCitationReason === null)) ||
            recommendation.correctedCitationReason === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.associatedCitations,
              requiredField: 'correctedCitationReason',
              message: 'Citation explanation is a required field',
            };
          } else if (
            (recommendation.evidences !== undefined &&
              (_.isEmpty(recommendation.evidences) ||
                recommendation.evidences === null ||
                (recommendation.evidences &&
                  recommendation.evidences.map(evidence => {
                    if (evidence === undefined) return true;
                    // eslint-disable-next-line array-callback-return
                    else return;
                  })[0]))) ||
            recommendation.evidences === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.associatedCitations,
              requiredField: 'evidences',
              message: 'Attachment is a required field',
            };
          } else {
            // eslint-disable-next-line array-callback-return
            return;
          }
          errors.push(missingFields);
          this.setState({
            errorType: 'Missing required fields',
          });
        } else if (formKey === 'additionalCitations') {
          citations.push(recommendation.citation);
          if (
            (recommendation.guide !== undefined &&
              (recommendation.guide === '' || recommendation.guide === null)) ||
            recommendation.guide === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.citation
                ? recommendation.citation
                : 'Additional Citations',
              requiredField: 'guide',
              message: 'Guide is a required field',
            };
          } else if (
            (recommendation.performanceMeasure !== undefined &&
              (recommendation.performanceMeasure === '' ||
                recommendation.performanceMeasure === null)) ||
            recommendation.performanceMeasure === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.citation
                ? recommendation.citation
                : 'Additional Citations',
              requiredField: 'performanceMeasure',
              message: 'Performance measure is a required field',
            };
          } else if (
            (recommendation.citation !== undefined &&
              (recommendation.citation === '' ||
                recommendation.citation === null)) ||
            recommendation.citation === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.citation
                ? recommendation.citation
                : 'Additional Citations',
              requiredField: 'citation',
              message: 'Citation is a required field',
            };
          } else if (
            (recommendation.citationExplanation !== undefined &&
              (recommendation.citationExplanation === '' ||
                recommendation.citationExplanation === null)) ||
            recommendation.citationExplanation === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.citation
                ? recommendation.citation
                : 'Additional Citations',
              requiredField: 'citationExplanation',
              message: 'Citation explanation is a required field',
            };
          } else if (
            (recommendation.evidenceFiles !== undefined &&
              (_.isEmpty(recommendation.evidenceFiles) ||
                recommendation.evidenceFiles === null ||
                (recommendation.evidenceFiles &&
                  recommendation.evidenceFiles.map(evidence => {
                    if (evidence === undefined) return true;
                    // eslint-disable-next-line array-callback-return
                    else return;
                  })[0]))) ||
            recommendation.evidenceFiles === undefined
          ) {
            missingFields = {
              citationNumber: recommendation.citation
                ? recommendation.citation
                : 'Additional Citations',
              requiredField: 'evidences',
              message: 'Attachment is a required field',
            };
          } else {
            // eslint-disable-next-line array-callback-return
            return;
          }
          errors.push(missingFields);
          this.setState({
            errorType: 'Missing required fields',
          });
        }
      });
    });
    citations &&
      // eslint-disable-next-line array-callback-return
      citations.map(citation => {
        if (
          duplicateCitationsCheck.length > 0 &&
          duplicateCitationsCheck.includes(citation)
        ) {
          if ('RAN' === this.props.followUpTaskDetail.parentReviewtype) {
            if ('1302.102(d)(1)(ii)' !== citation) {
              errors.push({
                citationNumber: citation,
                message: 'Duplicate citation is not allowed.',
              });
            }
          } else {
            errors.push({
              citationNumber: citation,
              message: 'Duplicate citation is not allowed.',
            });
          }
        }

        if ('RAN' === this.props.followUpTaskDetail.parentReviewtype) {
          if ('1302.102(d)(1)(ii)' !== citation) {
            duplicateCitationsCheck.push(citation);
          }
        } else {
          duplicateCitationsCheck.push(citation);
        }
      });
    return errors;
  };

  buildFormData = (data, index, citationFormName) => {
    const { surveyData } = this.state;
    let formData = surveyData[citationFormName];
    if (data.name === 'guide') {
      formData[index] = {
        ...formData[index],
        [data.name]: data.value,
        performanceMeasure: '',
        citation: '',
      };
    } else if (data.name === 'performanceMeasure') {
      formData[index] = {
        ...formData[index],
        [data.name]: data.value,
        citation: '',
      };
    } else {
      formData[index] = {
        ...formData[index],
        [data.name]: data.value,
      };
    }
    this.setState({
      surveyData: {
        ...surveyData,
        [citationFormName]: formData,
      },
    });
  };

  addAdditionalCitation = () => {
    const { surveyData } = this.state;
    let newAdditionalCitations = _.cloneDeep(surveyData.additionalCitations);
    newAdditionalCitations.push({
      guide: null,
      performanceMeasure: null,
      citation: null,
    });
    this.setState({
      surveyData: {
        ...surveyData,
        additionalCitations: newAdditionalCitations,
      },
    });
  };

  removeAdditionalCitation = index => {
    const { surveyData } = this.state;
    let newAdditionalCitations = _.cloneDeep(surveyData.additionalCitations);
    _.remove(newAdditionalCitations, (citation, cIndex) => {
      return index === cIndex;
    });
    this.setState({
      surveyData: {
        ...surveyData,
        additionalCitations: newAdditionalCitations,
      },
    });
  };

  disableForm = () => {
    const { selectedSubmission, followUpTaskDetail } = this.props;
    const { amsSubmissionId } = followUpTaskDetail;

    if (
      followUpTaskDetail &&
      (!followUpTaskDetail.isReviewAccessible || followUpTaskDetail.readOnly)
    ) {
      this.setState({
        formDisableClassName: 'formDisabled',
        formDisabled: true,
        editMode: false,
      });
      return;
    }

    if (amsSubmissionId) {
      if (
        selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'draft'
      ) {
        this.setState({
          formDisableClassName: '',
          formDisabled: false,
        });
        return;
      } else {
        this.setState({
          formDisableClassName: 'formDisabled',
          formDisabled: true,
          editMode: false,
        });
        return;
      }
    }

    this.setState({
      formDisableClassName: '',
      formDisabled: false,
      editMode: false,
    });
  };

  showSaveDraftButton = () => {
    const { selectedSubmission, followUpTaskDetail } = this.props;
    const { submissionId } = this.state;
    const { amsSubmissionId } = followUpTaskDetail;

    if (followUpTaskDetail && followUpTaskDetail.isReportFinalized) return;

    if (followUpTaskDetail && !followUpTaskDetail.isReviewAccessible) return;

    if (followUpTaskDetail && followUpTaskDetail.readOnly) return;

    if (amsSubmissionId || submissionId) {
      if (
        selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'draft'
      )
        return (
          <Button
            size="large"
            content={'Save as Draft'}
            onClick={e => {
              e.preventDefault();
              this.handleSaveDraft();
            }}
          />
        );
      else return;
    } else
      return (
        <Button
          size="large"
          content={'Save as Draft'}
          onClick={e => {
            e.preventDefault();
            this.handleSaveDraft();
          }}
        />
      );
  };

  showSubmitButton = () => {
    const { selectedSubmission, followUpTaskDetail } = this.props;
    const { editMode } = this.state;
    const { amsSubmissionId } = followUpTaskDetail;

    if (followUpTaskDetail && followUpTaskDetail.isReportFinalized) return;

    if (followUpTaskDetail && !followUpTaskDetail.isReviewAccessible) return;

    if (followUpTaskDetail && followUpTaskDetail.readOnly) return;

    if (
      (!amsSubmissionId &&
        selectedSubmission &&
        !selectedSubmission.amsSubmissionId) ||
      editMode ||
      (selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'draft')
    )
      return (
        <Button
          primary
          type="submit"
          size="large"
          content={'Submit'}
          onClick={e => {
            e.preventDefault();
            this.handleSubmit();
          }}
        />
      );
  };

  showEditButton = () => {
    const { selectedSubmission, currentUser, followUpTaskDetail } = this.props;
    const { submissionId, editMode } = this.state;
    const { amsSubmissionId } = followUpTaskDetail;

    if (followUpTaskDetail && followUpTaskDetail.isReportFinalized) return;

    if (followUpTaskDetail && followUpTaskDetail.readOnly) return;

    if (followUpTaskDetail && !followUpTaskDetail.isReviewAccessible) return;

    if (editMode || !selectedSubmission.editable) return;

    if (amsSubmissionId || submissionId) {
      if (
        selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'submitted'
      )
        return enforceRole(
          <Button
            size="large"
            content={'Edit'}
            color="green"
            icon="edit"
            onClick={e => {
              this.setState({ formDisabled: false, editMode: true });
            }}
          />,
          acl.actions.followUp.editSubmissions,
          currentUser.roles
        );
      else return;
    } else return;
  };

  showCancelButton = () => {
    const { currentUser } = this.props;
    const { editMode } = this.state;

    if (!editMode) return;
    else
      return enforceRole(
        <Button
          negative
          size="large"
          content={'Cancel'}
          onClick={() => {
            this.setState({
              formDisabled: true,
              editMode: false,
            });
          }}
        />,
        acl.actions.followUp.editSubmissions,
        currentUser.roles
      );
  };

  structureFileData = tempData => {
    tempData &&
      // eslint-disable-next-line array-callback-return
      Object.keys(tempData).map(key => {
        if (key.toLowerCase().includes('evidence')) {
          const all = [];
          if (Array.isArray(tempData[key])) {
            if (!_.isEmpty(tempData[key])) {
              tempData[key].forEach(files => {
                if (
                  typeof files !== 'undefined' &&
                  files !== null &&
                  Array.isArray(files)
                )
                  return files.forEach(file => all.push(file));
                else if (typeof files !== 'undefined' && files !== null)
                  all.push(files);
              });
              tempData[key] = all;
            }
            return tempData;
          }
        } else {
          if (
            typeof tempData[key] === 'object' &&
            !Array.isArray(tempData[key])
          ) {
            return this.structureFileData(tempData[key]);
          } else if (Array.isArray(tempData[key])) {
            tempData[key].forEach(file => {
              return this.structureFileData(file);
            });
          }
        }
      });
    return tempData;
  };

  handleSaveDraft = () => {
    const { surveyData } = this.state;
    const { followUpTaskDetail, selectedSubmission } = this.props;
    const { amsSubmissionId } = followUpTaskDetail;

    let structuredData = {};

    structuredData = this.structureFileData(surveyData);

    this.setState({
      formProcessing: true,
    });

    let submission = {};

    !amsSubmissionId && !selectedSubmission.amsSubmissionId
      ? (submission = {
          data: {
            reviewId: this.props.followUpTaskDetail.reviewId,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: structuredData,
          },
        })
      : (submission = {
          data: {
            reviewId: this.props.followUpTaskDetail.reviewId,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsSubmissionId:
              amsSubmissionId || selectedSubmission.amsSubmissionId,
            amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: structuredData,
          },
        });
    this.props.saveDraft(submission).then(
      data => {
        if (data.amsSubmissionId) {
          this.setState(
            {
              submissionId: data.amsSubmissionId,
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Draft saved successfully',
              formProcessing: false,
              newRecommendationsFiles: [],
              editMode: false,
            },
            () => {
              this.props.fetchData(data.amsSubmissionId);
            }
          );
        }
      },
      err => {
        this.setState({
          showAlert: true,
          alertType: 'error',
          alertMessage: 'Draft save was unsuccessful',
          formProcessing: false,
        });
      }
    );
  };

  handleSubmit = () => {
    const { surveyData } = this.state;
    let errors = this.validate(surveyData);
    this.setState({
      errors,
    });
    if (!_.isEmpty(errors)) {
      window.scroll({
        top: 100,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      this.submitData();
    }
  };

  submitData = () => {
    const { surveyData } = this.state;
    const { followUpTaskDetail, selectedSubmission } = this.props;
    const { amsSubmissionId } = followUpTaskDetail;
    this.setState({
      formProcessing: true,
    });

    let structuredData = {};

    structuredData = this.structureFileData(surveyData);

    let submission = {};

    !amsSubmissionId && !selectedSubmission.amsSubmissionId
      ? (submission = {
          data: {
            reviewId: this.props.followUpTaskDetail.reviewId,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: structuredData,
          },
        })
      : (submission = {
          data: {
            reviewId: this.props.followUpTaskDetail.reviewId,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsSubmissionId:
              amsSubmissionId || selectedSubmission.amsSubmissionId,
            amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: structuredData,
          },
        });

    this.props.addSubmission(submission).then(
      data => {
        if (data.amsSubmissionId) {
          this.setState(
            {
              submissionId: data.amsSubmissionId,
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Data saved to Report',
              formProcessing: false,
              newRecommendationsFiles: [],
              editMode: false,
            },
            () => {
              this.props.fetchData(data.amsSubmissionId);
            }
          );
        }
      },
      err => {
        this.setState({
          showAlert: true,
          alertType: 'error',
          alertMessage: 'Data submission was unsuccessful',
          formProcessing: false,
        });
      }
    );
  };

  renderVersionTable = () => {
    const { selectedSubmission, followUpTaskDetail } = this.props;
    if (_.isEmpty(selectedSubmission)) return null;

    const { versionHistory } = selectedSubmission;

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const version = cell;

            // Fetch specific version.
            this.setState({ formProcessing: true });
            this.props.fetchData(followUpTaskDetail.amsSubmissionId, version);
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      <AmsTable
        remote={false}
        basic
        total={versionHistory && versionHistory.length}
        columns={columns}
        keyField="version"
        ref="table"
        data={versionHistory}
      />
    );
  };

  showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = this.state;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          this.setState({
            showAlert: false,
            alertMessage: '',
            alertType: '',
          });
        }}
      />
    );
  };

  render() {
    const {
      surveyData,
      formDisableClassName,
      formDisabled,
      errors,
      errorType,
    } = this.state;
    return (
      <div className={formDisabled ? formDisableClassName : ''}>
        {this.showSuccessConfirmation()}
        <Segment basic loading={this.state.formProcessing}>
          {!_.isEmpty(errors) && (
            <Message
              negative
              icon={'cancel'}
              header={errorType ? errorType : 'Something went wrong!'}
              list={errors.map((error, index) => (
                <li key={index}>
                  <b>{error.citationNumber}: </b>
                  {error.message}
                </li>
              ))}
            />
          )}
          <FollowUpCitationsForm
            buildFormData={this.buildFormData}
            surveyData={surveyData}
            formDisableClassName={formDisableClassName}
            formDisabled={formDisabled}
          />

          <FollowUpAdditionalCitationsForm
            buildFormData={this.buildFormData}
            surveyData={surveyData}
            addAdditionalCitation={this.addAdditionalCitation}
            removeAdditionalCitation={this.removeAdditionalCitation}
            formDisableClassName={formDisableClassName}
            formDisabled={formDisabled}
          />
          {this.showSubmitButton()}
          {this.showSaveDraftButton()}
          {this.showEditButton()}
          {this.showCancelButton()}
        </Segment>
        <div>
          <Grid>
            <Grid.Column floated="right" width={7}>
              {this.renderVersionTable()}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

FollowUpFormContainer.propTypes = {
  followUpTaskDetail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  followUpTaskDetail: state.submissions.followUpTaskDetail,
  amsForm: state.submissions.AMSForm,
  selectedSubmission: state.submissions.selectedSubmission,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {
  addSubmission,
  fetchFollowUpTaskDetail,
  fetchSubmission,
  saveDraft,
})(FollowUpFormContainer);
