import React from 'react';
import { Button } from 'semantic-ui-react';

// Import components.
import FinalizeReportButton from './FinalizeReportButton';
import GenerateReportButton from './GenerateReportButton';
import Sign from './Sign';

const ReportActivityButtons = props => {
  return (
    <div className="dropdown">
      <Button
        disabled={props.disabled}
        data-toggle="dropdown"
        icon
        aria-label="More activities"
      >
        <span className="caret"></span>
      </Button>
      <div className="dropdown-menu">
        <Button.Group vertical>
          <GenerateReportButton showAlertToggle={props.showAlertToggle} />
          <FinalizeReportButton showAlertToggle={props.showAlertToggle} />
          <Sign showAlertToggle={props.showAlertToggle} />
        </Button.Group>
      </div>
    </div>
  );
};

ReportActivityButtons.propTypes = {};

export default ReportActivityButtons;
