import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

class GranteeCalendarAlreadySubmittedMessage extends Component {
  renderAlreadySubmittedDetails() {
    let { showAlreadySubmittedMessage, grantees } = this.props;
    return showAlreadySubmittedMessage ? (
      <div>
        <Message
          header="Grantee Unavailability already Submitted."
          content={
            grantees.length > 1
              ? 'The Grantee unavailibility has already been submitted for this agency.  You may view the Grantee details by selecting the grantee from the drop down below.'
              : 'The Grantee unavailibility has already been submitted for this agency.  You may view the Grantee details below.'
          }
        />
      </div>
    ) : (
      ''
    );
  }

  render() {
    return <div> {this.renderAlreadySubmittedDetails()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(GranteeCalendarAlreadySubmittedMessage);
