import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import { Button, Modal, Segment, Form, Input } from 'semantic-ui-react';

//Import actions
import { selectTab, fetchLogs } from '../../actions/adminActions';

// Import utils.
import AmsModal from '../../utils/AmsModal';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsTable from '../../utils/AmsTable';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

const LogsPage = props => {
  const dispatch = useDispatch();
  const { data, total } = useSelector(state => state.admin.logs);
  const [requestInput, setRequestInput] = useState({
    limit: 25,
    page: 1,
    sortName: 'eventTime',
    sortOrder: 'desc',
    filters: {},
  });
  const [rowDetails, setRowDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const amsTableRef = useRef();

  useEffect(() => {
    const getEventLogs = () => {
      dispatch(
        selectTab({
          key: 7,
          pageTitle: 'Logs',
        })
      );
      dispatch(fetchLogs(requestInput));
    };
    getEventLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getLogs = () => {
      dispatch(fetchLogs(requestInput));
    };
    getLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestInput.limit,
    requestInput.page,
    requestInput.sortOrder,
    requestInput.sortName,
  ]);

  const getLogs = () => {
    dispatch(fetchLogs(requestInput));
  };

  const resetFilters = () => {
    let input = {
      limit: 25,
      page: 1,
      sortName: 'eventTime',
      sortOrder: 'desc',
      filters: {},
    };
    setRequestInput(input);
    dispatch(fetchLogs(input));
  };

  const renderFilters = () => {
    return (
      <Segment>
        <AmsSkipLinks
          links={[{ title: 'Skip filters and go to results', to: 'results' }]}
        />

        <Form className="filter-form">
          <Form.Group>
            <Form.Field
              width="5"
              label={{ children: 'Category Type', htmlFor: 'categoryType' }}
              placeholder="Select Category Type"
              control={AmsLookupDropdown}
              value={
                requestInput && requestInput.filters.category
                  ? requestInput.filters.category
                  : ''
              }
              selection
              category={'auditLogCategories'}
              onChange={(e, { value }) =>
                setRequestInput({
                  ...requestInput,
                  page: 1,
                  filters: { ...requestInput.filters, category: value },
                })
              }
              id="categoryType"
              aria-labelledby="categoryType"
            />

            <Form.Field
              width={3}
              label={{
                children: 'Review ID',
                htmlFor: 'filterReviewID',
              }}
              control={Input}
              id="filterReviewID"
              placeholder="Review ID"
              value={
                requestInput && requestInput.filters['tags.reviewId']
                  ? requestInput.filters['tags.reviewId']
                  : ''
              }
              maxLength="10"
              onChange={(e, { value }) =>
                setRequestInput({
                  ...requestInput,
                  page: 1,
                  filters: {
                    ...requestInput.filters,
                    'tags.reviewId': value && value.trim(),
                  },
                })
              }
              fluid
            />

            <Form.Group>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  primary
                  onClick={getLogs}
                >
                  Filter
                </Form.Button>
              </Form.Field>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  basic
                  onClick={resetFilters}
                >
                  Reset
                </Form.Button>
              </Form.Field>
            </Form.Group>
          </Form.Group>
        </Form>
      </Segment>
    );
  };

  const renderLogs = () => {
    const dateFormatter = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const scrollToRef = () => {
      window.scrollTo(0, amsTableRef.current.offsetTop);
      amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      setRequestInput({ ...requestInput, limit: sizePerPage, page });
      scrollToRef();
    };

    const onPageChange = (page, sizePerPage) => {
      setRequestInput({ ...requestInput, sizePerPage, page });
      scrollToRef();
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === requestInput.sortName &&
        sortOrder === requestInput.sortOrder
      )
        return null;
      setRequestInput({
        ...requestInput,
        sortOrder,
        page: 1,
        sortName: sortField,
      });
      scrollToRef();
    };

    const addedBy = (cell, row) => {
      return cell.fullName;
    };

    const deleteFormatter = (cell, row) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          setShowModal(true);
          setRowDetails(row);
        }}
      >
        Detail
      </a>
    );

    const reviewIdFormatter = (cell, row) => {
      if (cell) {
        return <Link to={`review/${cell}`}>{cell}</Link>;
      } else return 'N/A';
    };

    const columns = [
      {
        dataField: 'categoryDesc',
        text: 'Category',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Description',
        sort: true,
      },
      {
        dataField: 'tags.reviewId',
        text: 'Review ID',
        sort: true,
        formatter: reviewIdFormatter,
      },
      {
        dataField: 'result',
        text: 'Result',
        sort: true,
      },
      {
        dataField: 'addedBy',
        text: 'Source',
        sort: true,
        formatter: addedBy,
      },
      {
        dataField: 'eventTime',
        text: 'Timestamp',
        sort: true,
        formatter: dateFormatter,
      },
      {
        isDummyField: true,
        dataField: 'dummy',
        formatter: deleteFormatter,
        text: 'Action',
      },
    ];

    const defaultSorted = [
      {
        dataField: requestInput.sortName,
        order: requestInput.sortOrder,
      },
    ];

    return (
      <div ref={amsTableRef} tabIndex="-1">
        <AmsTable
          defaultSorted={defaultSorted}
          remote
          data={data}
          page={requestInput.page}
          total={total}
          // total={logs && logs.length}
          limit={requestInput.limit}
          // loading={loading}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  const rowDetailsModal = () => (
    <AmsModal
      open={showModal}
      size="small"
      closeOnDimmerClick={false}
      onClose={() => {
        setShowModal(false);
        setRowDetails({});
      }}
      className="ams-semantic-modal-fix"
      // closeIcon
    >
      <Modal.Header>Details</Modal.Header>
      <Modal.Content>
        <>
          <div>
            <pre>
              <code className="json">
                {JSON.stringify(rowDetails, null, ' ')}
              </code>
            </pre>
          </div>
        </>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content="Close"
          onClick={() => {
            setRowDetails({});
            setShowModal(false);
          }}
        />
      </Modal.Actions>
    </AmsModal>
  );

  return (
    <>
      {renderFilters()}
      {renderLogs()}
      {rowDetailsModal()}
    </>
  );
};

export default LogsPage;
