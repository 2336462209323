import findIndex from 'lodash/findIndex';

import {
  RESOURCE_ADDED,
  RESOURCE_FETCHED,
  RESOURCE_UPDATED,
  RESOURCE_DELETED,
} from '../redux/types';

export default function reviews(state = [], action) {
  switch (action.type) {
    case RESOURCE_FETCHED: {
      return action.resources;
    }

    case RESOURCE_ADDED: {
      const newList = [...state, action.resource];
      return newList;
    }

    case RESOURCE_UPDATED: {
      let modified = state.map((item, index) => {
        if (item._id !== action.resource._id) {
          return item;
        }
        return {
          ...item,
          ...action.resource,
        };
      });
      return modified;
    }

    case RESOURCE_DELETED: {
      console.log(action.id);
      const index = findIndex(state, { _id: action.id });

      if (index >= 0) {
        const modified = [...state.slice(0, index), ...state.slice(index + 1)];
        return modified;
      }

      return state;
    }

    default:
      return state;
  }
}
