import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Responsive, Visibility } from 'semantic-ui-react';

// Components
import { fixedMenuStyle, MemoMenuNavBar, menuStyle } from '../../../utils/menu';
import Footer from '../../Shared/Footer/Footer';
import GlobalAlert from '../../Shared/GlobalAlert';
import DesktopNavBar from './DesktopNavBar';

// Import util.
import AmsSkipLinks from '../../../utils/AmsSkipLinks';
import IdleTimer from '../../../utils/IdleTimer';

// Import config.

const getWidth = () => {
  const isSSR = typeof window === 'undefined';

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

class DesktopContainer extends Component {
  state = {
    menuFixed: false,
    overlayFixed: false,
  };

  stickTopMenu = () => this.setState({ menuFixed: true });

  unStickTopMenu = () => this.setState({ menuFixed: false });

  generateMainNav = () => {
    const { roles } = this.props.currentUser;
    return <MemoMenuNavBar location={this.props.location} roles={roles} />;
  };

  render() {
    const { menuFixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <AmsSkipLinks links={[{ title: 'Skip to main content', to: 'main' }]} />

        <div style={{ paddingBottom: '150px' }}>
          <DesktopNavBar />

          <Visibility
            // onBottomPassed={this.stickTopMenu}
            // onBottomVisible={this.unStickTopMenu}
            once={false}
          >
            <nav role="navigation" aria-label="Main menu">
              <Menu
                fluid
                pointing
                secondary
                size="large"
                fixed={menuFixed ? 'top' : undefined}
                style={menuFixed ? fixedMenuStyle : menuStyle}
              >
                {this.generateMainNav()}
              </Menu>
            </nav>
          </Visibility>

          <GlobalAlert />

          <IdleTimer />

          <main
            role="main"
            id="main"
            style={{ marginBottom: '2rem' }}
            tabIndex="-1"
          >
            {this.props.children}
          </main>
        </div>

        <Footer />
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {})(DesktopContainer);
