import _, { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Well } from 'react-bootstrap';
import Form from '@rjsf/core';
import { connect } from 'react-redux';
import { Form as SemanticForm, Segment } from 'semantic-ui-react';
import AmsAlert from '../../../utils/AmsAlert';

// Import actions.
import {
  fetchPresiteFormDetail,
  fetchPresiteFormSchema,
  fetchPresiteSubmission,
  performanceMeasureSelected,
  presetSaveDraft,
  presetSaveSubmission,
  presetUpdateSubmission,
  presiteUpdateGuide,
} from '../../../actions/presiteActions';

import { postFormSubmission } from '../../../actions/surveyActions';

// Import components.
import ShowErrors from '../../../utils/ShowErrors';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';
import AttachmentField from '../../Shared/FormHelpers/AttachmentField';
import DefinitionsListField from '../../Shared/FormHelpers/DefinitionsListField';

// Import config.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';

// Import utils
import { flattenObj } from '../../Shared/FormHelpers/helpers/utils';

class PresiteForm extends Component {
  state = {
    formProcessing: false,
    formDisabled: true,
    editButtonClicked: false,
    reviewId: '',
    amsFormId: '',
    amsSubmissionId: '',
    submittedBy: '',
    submissionStatus: '',
    submissionTime: '',
    formSchema: {},
    uiSchema: {},
    formData: {},
    submission: {},
    errors: {},
    dataHasChanged: false,
    cancelMovingAway: false,
    showAlert: false,
  };

  componentWillReceiveProps(nextProps) {
    const {
      presite: { reviewId, selectedPerformanceMeasure, submission },
      formProcessing,
      errors,
      selectedName,
    } = nextProps;
    if (this.state.cancelMovingAway) {
      this.setState({
        cancelMovingAway: false,
      });
      return;
    }
    this.setState({
      formProcessing,
      errors,
      submission,
      formDisabled: true,
      editButtonClicked: false,
      selectedName: selectedName,
    });

    if (nextProps.formChangeRequested && this.state.dataHasChanged) {
      this.setState(
        {
          showChangeConfirmationAlert: true,
        },
        () => {
          return;
        }
      );
    }

    if (
      selectedPerformanceMeasure &&
      selectedPerformanceMeasure.formSchema &&
      selectedPerformanceMeasure.uiSchema
    ) {
      const { formSchema, uiSchema } = selectedPerformanceMeasure;

      this.setState({
        formSchema,
        uiSchema,
        reviewId,
        amsFormId: selectedPerformanceMeasure.forms[0].amsFormId,
        amsSubmissionId: submission
          ? submission.amsSubmissionId
          : selectedPerformanceMeasure.forms[0].amsSubmissionId,
        submittedBy: selectedPerformanceMeasure.forms[0].submittedBy,
        submissionStatus: submission
          ? submission.surveyStatus
          : selectedPerformanceMeasure.forms[0].submissionStatus,
        submissionTime: selectedPerformanceMeasure.forms[0].submissionTime,
      });

      if (submission && submission.data && submission.data.surveyData) {
        const { surveyData } = submission.data;
        this.setState({
          formDisabled: !_.isEmpty(surveyData),
          formData: surveyData,
          amsSubmissionId: submission.amsSubmissionId,
          submissionStatus: submission.surveyStatus,
        });
      } else {
        this.setState({ formData: {}, formDisabled: false, submission: {} });
      }
    }
  }

  resetState = () => {
    this.setState({
      formProcessing: false,
      formDisabled: true,
      editButtonClicked: false,
      reviewId: '',
      amsFormId: '',
      amsSubmissionId: '',
      submittedBy: '',
      submissionStatus: '',
      submissionTime: '',
      formSchema: {},
      uiSchema: {},
      formData: {},
      submission: {},
      errors: {},
      dataHasChanged: false,
    });
  };

  handleChange = ({ formData }) => {
    // If Every value of the form field is undefined, set formData to empty so that save buttons are disabled.
    if (Object.values(formData).every(v => v === undefined)) {
      this.setState({ formData: {} });
      return;
    }

    if (this.checkDifference(formData)) {
      this.setState(
        { formData: formData, dataHasChanged: true },
        this.props.dataHasChangedSwitch(true)
      );
    }
  };

  validate = (data, errors) => {
    if (this.state.draftButtonClicked) {
      return {};
    }

    return errors;
  };

  handleError = e => {};

  transformErrors = errors => {
    if (this.state.draftButtonClicked) {
      return [];
    }
    return _.filter(errors, error => {
      if (error.name === 'const') {
        error.message = 'This is a required field.';
      }
      if (error.message === 'should be string')
        error.message = 'Please add at least one file.';

      if (error.name === 'required')
        error.message = 'This is a required field.';
      if (error.params.type === 'integer')
        error.message =
          'This field only accepts whole numbers. Please enter a whole number.';
      if (error.name === 'minimum')
        error.message =
          'This field only accept values greater than 0. Please enter a number greater than 0.';
      if (error.name === 'minItems')
        error.message = 'This is a required field.';
      return (
        error.name === 'required' ||
        error.name === 'const' ||
        error.name === 'minimum' ||
        error.name === 'minItems' ||
        error.message === 'Please add at least one file.' ||
        error.params.type === 'integer'
      );
    });
  };

  onSubmit = () => {
    const {
      draftButtonClicked,
      reviewId,
      amsFormId,
      amsSubmissionId,
      formData,
      submissionStatus,
    } = this.state;

    const { selectedPerformanceMeasure } = this.props.presite;

    if (!amsFormId || !reviewId) return;

    let requestObject = { reviewId, amsFormId, surveyData: formData };

    if (amsSubmissionId) requestObject.amsSubmissionId = amsSubmissionId;

    requestObject.submissionStatus = draftButtonClicked ? 'Draft' : 'Submitted';

    this.setState({ formProcessing: true });

    // draft
    if (draftButtonClicked) {
      this.props
        .postFormSubmission({ data: { ...requestObject } })
        .then(response => {
          this.setState({
            draftButtonClicked: false,
            errors: {},
            showAlert: true,
            alertType: 'success',
            alertMessage: 'Draft saved successfully',
          });
          const { amsSubmissionId, performanceMeasure } = response;
          if (amsSubmissionId) {
            this.props.presiteUpdateGuide(performanceMeasure, amsSubmissionId);
            this.resetState();
            if (
              selectedPerformanceMeasure &&
              _.isEmpty(selectedPerformanceMeasure.formSchema)
            ) {
              this.props.fetchPresiteFormSchema({
                filters: {
                  amsFormId,
                },
              });
            }
            this.props
              .fetchPresiteSubmission({
                filters: { amsSubmissionId },
              })
              .then(() => {
                this.setState(
                  { formProcessing: false, errors: {} },
                  this.props.dataHasChangedSwitch(false)
                );
              })
              .catch(error =>
                this.setState({
                  formProcessing: false,
                  errors: {
                    ...this.state.errors,
                    fetchPresiteSubmission: error.message,
                  },
                })
              );
          }
        })
        .catch(error =>
          this.setState({
            formProcessing: false,
            draftButtonClicked: false,
            errors: {
              ...this.state.errors,
              presetSaveDraft: error.message,
            },
          })
        );
    } else {
      // update
      if (
        !draftButtonClicked &&
        submissionStatus &&
        submissionStatus.toLowerCase() === 'submitted'
      ) {
        this.props
          .postFormSubmission({ data: { ...requestObject } })
          .then(response => {
            this.setState({
              errors: {},
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Updated successfully',
            });
            const { amsSubmissionId, performanceMeasure } = response;
            if (amsSubmissionId) {
              this.props.presiteUpdateGuide(
                performanceMeasure,
                amsSubmissionId
              );
              this.resetState();
              if (
                selectedPerformanceMeasure &&
                _.isEmpty(selectedPerformanceMeasure.formSchema)
              ) {
                this.props.fetchPresiteFormSchema({
                  filters: {
                    amsFormId,
                  },
                });
              }
              this.props
                .fetchPresiteSubmission({
                  filters: { amsSubmissionId },
                })
                .then(() =>
                  this.setState(
                    { formProcessing: false, errors: {} },
                    this.props.dataHasChangedSwitch(false)
                  )
                )
                .catch(error => {
                  this.setState({
                    formProcessing: false,
                    errors: {
                      ...this.state.errors,
                      fetchPresiteSubmission: error.message,
                    },
                  });
                });
            }
          })
          .catch(error => {
            this.setState({
              formProcessing: false,
              errors: {
                ...this.state.errors,
                presetSaveSubmission: error.message,
              },
            });
          });
        return; // End of update exit.
      }

      // new
      if (!draftButtonClicked) {
        this.props
          .postFormSubmission({ data: { ...requestObject } })
          .then(response => {
            this.setState({
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Data saved successfully',
            });
            const { amsSubmissionId, performanceMeasure } = response;
            if (amsSubmissionId) {
              this.props.presiteUpdateGuide(
                performanceMeasure,
                amsSubmissionId
              );
              this.resetState();
              if (
                selectedPerformanceMeasure &&
                _.isEmpty(selectedPerformanceMeasure.formSchema)
              ) {
                this.props.fetchPresiteFormSchema({
                  filters: {
                    amsFormId,
                  },
                });
              }
              this.props
                .fetchPresiteSubmission({
                  filters: { amsSubmissionId },
                })
                .then(() => {
                  this.setState(
                    { formProcessing: false, errors: {} },
                    this.props.dataHasChangedSwitch(false)
                  );
                })
                .catch(error => {
                  this.setState({
                    formProcessing: false,
                    errors: {
                      ...this.state.errors,
                      fetchPresiteSubmission: error.message,
                    },
                  });
                });
            }
          })
          .catch(error => {
            this.setState({
              formProcessing: false,
              errors: {
                ...this.state.errors,
                presetSaveSubmission: error.message,
              },
            });
          });
      }
    }
  };

  showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = this.state;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          this.setState({
            showAlert: false,
            alertMessage: '',
            alertType: '',
          });
        }}
      />
    );
  };

  showDraftButton = () => {
    const { presite } = this.props;

    const {
      formData,
      amsSubmissionId,
      submissionStatus,
      editButtonClicked,
    } = this.state;

    if (presite && presite.readOnly) return;

    if (!this.props.presite.allowed) return;

    if (presite && !presite.isReviewAccessible) return;

    if (presite && presite.reviewStatus && presite.reviewStatus === 'Cancelled')
      return null;

    if (
      editButtonClicked &&
      submissionStatus &&
      submissionStatus.toLocaleLowerCase() === 'draft'
    ) {
      return (
        <SemanticForm.Button
          type="submit"
          content="Save as Draft"
          disabled={_.isEmpty(formData)}
          onClick={() => this.setState({ draftButtonClicked: true })}
        />
      );
    }
    if (
      !editButtonClicked &&
      (_.isEmpty(submissionStatus) || _.isEmpty(amsSubmissionId))
    ) {
      return (
        <SemanticForm.Button
          type="submit"
          content="Save as Draft"
          disabled={_.isEmpty(formData)}
          onClick={() => this.setState({ draftButtonClicked: true })}
        />
      );
    }

    return null;
  };

  showEditButton = () => {
    const { presite } = this.props;

    if (presite && presite.readOnly) return;

    if (!this.props.presite.allowed) return;

    if (presite && !presite.isReviewAccessible) return;

    if (presite && presite.isReportFinalized) return;
    const { submission } = this.props.presite;

    if (submission && !submission.editable) return;

    if (this.state.editButtonClicked) return;

    if (presite && presite.reviewStatus && presite.reviewStatus === 'Cancelled')
      return null;

    if (this.state.formDisabled && !_.isEmpty(this.state.formData)) {
      return (
        <SemanticForm.Button
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            this.setState({ formDisabled: false, editButtonClicked: true });
          }}
        />
      );
    }
  };

  showSubmitButton = () => {
    const { presite } = this.props;

    const {
      formData,
      amsSubmissionId,
      submissionStatus,
      editButtonClicked,
    } = this.state;

    if (presite && presite.readOnly) return;

    if (!this.props.presite.allowed) return;

    if (presite && !presite.isReviewAccessible) return;

    if (presite && presite.reviewStatus && presite.reviewStatus === 'Cancelled')
      return null;

    if (
      editButtonClicked &&
      submissionStatus &&
      (submissionStatus.toLocaleLowerCase() === 'submitted' ||
        submissionStatus.toLocaleLowerCase() === 'draft')
    ) {
      return (
        <SemanticForm.Button
          type="submit"
          primary
          disabled={_.isEmpty(formData)}
          content={'Submit'}
        />
      );
    }

    if (
      !editButtonClicked &&
      (_.isEmpty(submissionStatus) || _.isEmpty(amsSubmissionId))
    ) {
      return (
        <SemanticForm.Button
          type="submit"
          primary
          disabled={_.isEmpty(formData)}
          content={'Submit'}
        />
      );
    }
  };

  //used for key/value pair modification of survey data object, for omitBy lodash function
  //that results in  deleting key/value pairs where the value is not a real user inputted answer
  trimObj = e => {
    return (
      (_.isEmpty(e) ||
        e.length === 0 ||
        (Array.isArray(e) && e[0] === undefined)) &&
      !(e === true || e === false || typeof e === 'number')
    );
  };

  checkDifference = newData => {
    let modified = flattenObj(newData);

    modified = _.omitBy(modified, this.trimObj);

    //checks if flattened surveyData is empty to an empty object, or if its equal to the original survey data submission
    //uses flatten and trim to ensure that the data object does not equate to different because of citation data

    if (
      _.isEqual({}, modified) ||
      _.isEqual(
        modified,
        this.state.submission &&
          this.state.submission.data &&
          _.omitBy(
            flattenObj(this.state.submission.data.surveyData),
            this.trimObj
          )
      )
    ) {
      return false;
    }
    return true;
  };

  showCancelButton = () => {
    const { presite } = this.props;

    const { formData, submissionStatus, editButtonClicked } = this.state;

    const { submission } = this.props.presite;

    if (presite && presite.readOnly) return null;

    if (presite && presite.reviewStatus && presite.reviewStatus === 'Cancelled')
      return null;

    if (
      !editButtonClicked ||
      (formData &&
        submissionStatus &&
        submissionStatus.toLowerCase() !== 'submitted')
    )
      return null;
    else
      return (
        <SemanticForm.Button
          negative
          // size="large"
          content={'Cancel'}
          onClick={() => {
            this.setState(
              {
                editButtonClicked: false,
                formDisabled: true,
                dataHasChanged: false,
                formData:
                  submission &&
                  submission.data &&
                  submission.data.surveyData &&
                  submission.data.surveyData,
              },
              () => {
                this.props.dataHasChangedSwitch(false);
              }
            );
          }}
        />
      );
  };

  renderConfirmationMessage = () => {
    const { showChangeConfirmationAlert, submissionStatus } = this.state;
    return (
      <AmsAlert
        show={showChangeConfirmationAlert}
        title="Are you sure you want to move away from this form"
        text={`You selected another page, are you sure you want to leave the current page?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={
          submissionStatus &&
          submissionStatus.toLocaleLowerCase() === 'submitted'
            ? 'Submit and continue'
            : 'Save draft and continue'
        }
        cancelButtonText={'Continue without saving'}
        showConfirm
        showCancelButton
        showThirdButton
        thirdButtonText="Cancel"
        onThirdButtonAction={() => {
          this.setState(
            {
              formDisabled: false,
              showChangeConfirmationAlert: false,
              cancelMovingAway: true,
              editButtonClicked: _.isEmpty(this.state.submission)
                ? false
                : true,
            },
            () => {
              this.props.performanceMeasureSelected(this.props.selectedName);
            }
          );
        }}
        onCancel={() => {
          this.setState(
            {
              showChangeConfirmationAlert: false,
              dataHasChanged: false,
            },
            () => this.props.dataHasChangedSwitch(false)
          );
        }}
        onConfirm={() => {
          this.setState(
            {
              showChangeConfirmationAlert: false,
              dataHasChanged: false,
              draftButtonClicked:
                (submissionStatus &&
                  submissionStatus.toLocaleLowerCase() !== 'submitted') ||
                _.isEmpty(submissionStatus)
                  ? true
                  : false,
            },
            () => {
              this.onSubmit();
            }
          );
        }}
      />
    );
  };

  renderVersionTable = () => {
    if (_.isEmpty(this.state.submission)) return null;

    const {
      submission: { versionHistory },
      amsSubmissionId,
    } = this.state;

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const version = cell;

            // Fetch specific version.
            this.setState({ formProcessing: true });
            this.props
              .fetchPresiteSubmission({
                filters: { amsSubmissionId, version },
              })
              .then(() => this.setState({ formProcessing: false }))
              .catch(error => {
                this.setState({
                  formProcessing: false,
                  errors: {
                    ...this.state.errors,
                    fetchPresiteSubmission: error.message,
                  },
                });
              });
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      <AmsTable
        remote={false}
        basic
        total={versionHistory.length}
        columns={columns}
        keyField="version"
        ref="table"
        data={versionHistory}
      />
    );
  };

  renderSurveyStatus = () => {
    if (_.isEmpty(this.state.submission)) return null;

    const {
      submission: {
        amsSubmissionId,
        surveyStatus,
        data: { editedBy, editTime },
      },
    } = this.state;

    if (!amsSubmissionId) return null;

    return (
      <Well>
        {editedBy && (
          <div>
            <strong>Submitted By: </strong>
            {editedBy}
          </div>
        )}
        {surveyStatus && (
          <div>
            <strong>Submission Status: </strong> {surveyStatus}
          </div>
        )}
        {editTime && (
          <div>
            <strong>Submission Time: </strong>
            {AmsDateFormatters.formatDateTime(editTime)}
          </div>
        )}
      </Well>
    );
  };

  fields = () => {
    return {
      attachmentField: AttachmentField,
      definitionsListField: DefinitionsListField,
    };
  };

  render() {
    const { formSchema, uiSchema, formData } = this.state;
    const { presite } = this.props;

    if (!formSchema && !uiSchema) return null;

    return (
      <div className="form-container">
        {this.renderSurveyStatus()}
        <ShowErrors errors={this.state.errors} />
        {this.renderConfirmationMessage()}
        {this.showSuccessConfirmation()}
        <Segment basic loading={this.state.formProcessing}>
          <div className="text-right">{this.showEditButton()}</div>
          <fieldset
            disabled={
              (presite && presite.isReportFinalized) ||
              (presite &&
                presite.reviewStatus &&
                presite.reviewStatus.toLowerCase() === 'cancelled') ||
              (presite && presite.readOnly) ||
              (presite && !presite.isReviewAccessible)
                ? true
                : this.state.formDisabled
            }
          >
            <Form
              ArrayFieldTemplate={ArrayFieldTemplate}
              key={this.state.amsFormId}
              schema={formSchema}
              uiSchema={uiSchema}
              formData={formData}
              onSubmit={this.onSubmit}
              onChange={this.handleChange}
              onError={this.handleError}
              validate={this.validate}
              liveValidate={false}
              transformErrors={this.transformErrors}
              showErrorList={false}
              noHtml5Validate
              fields={this.fields()}
            >
              <div className="ui form">
                {presite && !presite.isReportFinalized ? (
                  <SemanticForm.Group>
                    {this.showDraftButton()}
                    {this.showSubmitButton()}
                    {this.showCancelButton()}
                  </SemanticForm.Group>
                ) : (
                  <SemanticForm.Group>
                    {this.showSubmitButton()}
                  </SemanticForm.Group>
                )}
              </div>
            </Form>
          </fieldset>
          {this.showEditButton()}
          <div className="row">
            <br />
            <br />
            <div className="col-xs-8">{this.renderVersionTable()}</div>
          </div>
        </Segment>
      </div>
    );
  }
}

PresiteForm.propTypes = {
  presite: PropTypes.object,
  presetSaveDraft: PropTypes.func.isRequired,
  presetSaveSubmission: PropTypes.func.isRequired,
  presetUpdateSubmission: PropTypes.func.isRequired,
  fetchPresiteSubmission: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  presite: state.forms.presite,
});

export default connect(mapStateToProps, {
  presetSaveDraft,
  presetSaveSubmission,
  fetchPresiteSubmission,
  presetUpdateSubmission,
  fetchPresiteFormDetail,
  fetchPresiteFormSchema,
  performanceMeasureSelected,
  presiteUpdateGuide,
  postFormSubmission,
})(PresiteForm);
