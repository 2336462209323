import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import style.
import '../assets/style.css';

// Import tabs.
import CalendarWidget from '../../Calendar/CalendarWidget';

class UserCalendar extends Component {
  render() {
    let userFetched =
      this.props.profiles.firstName + ' ' + this.props.profiles.lastName + "'";
    return (
      <div>
        <CalendarWidget
          userAdministration={
            this.props.profiles.firstName ? userFetched : null
          }
          oid={this.props.oid || ''}
        />
      </div>
    );
  }
}

UserCalendar.propTypes = {};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  profiles: state.profile,
});

export default connect(
  mapStateToProps,
  {}
)(UserCalendar);
