import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  Header,
  Grid,
  Pagination,
  Message,
  List,
  Segment,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

// Import actions.
import { fetchSearchReports } from '../../actions/reportActions';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import { shortenText } from '../../utils/TextFormatters';

const style = {
  resultRow: {
    lineHeight: '1.57',
    wordWrap: 'break-word',
    color: '#545454',
    marginTop: '0.5rem',
  },
};

const Search = props => {
  const { resultsCount, reportsList } = useSelector(
    store => store.report.fetchedSearchReports
  );
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const { searchQuery, searchType, pageNumber } = props.params;

  useEffect(() => {
    setLoading(true);
    setErrors(null);
    dispatch(
      fetchSearchReports({
        pageNum: Number(pageNumber) || 1,
        searchCriteria: searchType,
        searchStrings: searchQuery && searchQuery.split(/\s+/).filter(v => v),
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(errors => {
        setLoading(false);
        setErrors(errors.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, searchType, searchQuery]);

  /*useEffect(() => {
    if(!Array.isArray(reportsList) || reportsList.length > 0){
      setLoading(false)
    }
  }, [reportsList]);*/

  const pageChange = (e, data) => {
    props.router.push(
      `/search/${searchType}/${searchQuery}/${data.activePage}`
    );
  };

  const showErrors = () => {
    if (isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        content={errors}
      />
    );
  };

  const resultCount = () => {
    const counterStart = (pageNumber - 1) * 20 + 1;
    const counterEnd =
      20 * pageNumber <= resultsCount ? 20 * pageNumber : resultsCount;

    const results =
      resultsCount > 0
        ? `${counterStart}-${counterEnd} of ${resultsCount} results for`
        : 'No results found for';
    return <Header as="h2">{`${results} "${searchQuery}"`}</Header>;
  };

  const results =
    reportsList &&
    reportsList.map(result => {
      const description =
        (result.metadata && result.metadata.content) || 'No description';
      return (
        <List.Item key={result._id}>
          <List.Content>
            <h3>
              <List.Header
                as="a"
                href={
                  '/search/downloadreport?key=' + encodeURIComponent(result._id)
                }
                download={result.filename}
              >
                {result.fileDisplayName || 'No filename'}
              </List.Header>
            </h3>
            <List.Description>
              Shipped date: {result.uploadDate}{' '}
            </List.Description>
            <List.Description style={style.resultRow}>
              {shortenText(description, 150)}
            </List.Description>
          </List.Content>
        </List.Item>
      );
    });

  const renderSearchResults = () => {
    if (loading) {
      return (
        <Dimmer active inverted>
          <Loader inverted>Loading results...</Loader>
        </Dimmer>
      );
    }
    if (errors) {
      return showErrors();
    }
    return (
      <>
        {results && results.length > 0 && !loading && resultCount()}

        <Segment basic>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                {(results && <List relaxed="very">{results}</List>) ||
                  'No results found'}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Pagination
          boundaryRange={0}
          onPageChange={pageChange}
          activePage={pageNumber}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={Math.ceil(resultsCount / 20) || 1}
          size="small"
        />
      </>
    );
  };

  return (
    <SlidingContainer title={'Final Report Search'}>
      {renderSearchResults()}
    </SlidingContainer>
  );
};

export default Search;
