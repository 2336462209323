import _, { isEmpty, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import DatePicker from 'react-datepicker';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';

//Import Components
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsModal from '../../../utils/AmsModal';

// Import actions.
import { addNote } from '../../../actions/noteActions';
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

class UserSettingsForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    errors: {},
    roles: [], // User's roles.
    rolesList: [], // All AMS roles.
    showReasonModal: false,
    showAlertModal: false,
    data: {
      oid: '',
      userType: '',
      regionId: '',
      contractExpiration: '',
      vendorId: '',
      isHSESFederal: false,
      active: false,
      onHold: false,
      onHoldReason: null,
      regionalRegions: [],
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (props.profile.oid !== state.data.oid) {
      const { profileTab, rolesList, notes } = props;
      const {
        userType,
        active,
        regionId,
        contractExpiration,
        vendorId,
        onHold,
        isHSESFederal,
        oid,
        roles,
        granteeId,
        regionalRegions,
      } = props.profile;
      if (profileTab !== 'userSettings') {
        this.cancelEdit();
      }

      let onHoldReason = null;
      if (!isEmpty(notes) && onHold) {
        const reasonNotes = notes.filter(e => {
          return e.tags.field === 'On Hold Reason';
        });
        const note = reasonNotes.reduce((acc, val) => {
          if (acc === null) return val;
          return AmsDateFormatters.getMoment(val.updateDate).isAfter(
            acc.updateDate
          )
            ? val
            : acc;
        }, null);
        onHoldReason = note && note.note;
      }

      return {
        roles,
        rolesList,
        data: {
          userType,
          active,
          regionId,
          contractExpiration,
          vendorId,
          onHold,
          onHoldReason,
          isHSESFederal,
          granteeId,
          oid,
          regionalRegions,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    const {
      userType,
      active,
      regionId,
      contractExpiration,
      vendorId,
      onHold,
      isHSESFederal,
      oid,
      roles,
      granteeId,
      regionalRegions,
    } = this.props.profile;

    const { notes } = this.props;

    let onHoldReason = null;
    if (!isEmpty(notes) && onHold) {
      const reasonNotes = notes.filter(e => {
        return e.tags.field === 'On Hold Reason';
      });
      const note = reasonNotes.reduce((acc, val) => {
        if (acc === null) return val;
        return AmsDateFormatters.getMoment(val.updateDate).isAfter(
          acc.updateDate
        )
          ? val
          : acc;
      }, null);
      onHoldReason = note && note.note;
    }

    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
      roles,
      data: {
        userType,
        active,
        regionId,
        contractExpiration,
        vendorId,
        onHold,
        onHoldReason,
        isHSESFederal,
        granteeId,
        oid,
        regionalRegions,
      },
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data, this.state.roles);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      const { data, roles } = this.state;

      // Only update if there's a valid userId.

      if (data.onHold && data.onHoldReason) {
        const reasonNote = {
          note: data.onHoldReason,
          createdAt: AmsDateFormatters.getMoment().utc(),
          updateAt: AmsDateFormatters.getMoment().utc(),
          submittedBy: pick(this.props.currentUser, [
            'oid',
            'userName',
            'fullName',
          ]),
        };

        reasonNote.tags = {
          type: 'admin_user_settings',
          userId: data.oid,
          field: 'On Hold Reason',
        };
        this.props.addNote(reasonNote);
      }

      if (data.oid) {
        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });

        this.props
          .updateUserRoles(data.oid, { roles })
          .then(() => this.setState({ showSuccess: true, loading: false }))
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                roles: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = (data, roles) => {
    const errors = {};
    // if (!data.userType) errors.userType = "User Type is a required fields";

    // Vendor ID is only mandatory for consultants
    if (data.userType === 'Consultant' || data.userType === 'consultant')
      if (!data.vendorId) errors.vendorId = 'Please Enter Vendor ID/Grantee ID';

    if (
      data.userType === 'Peer Reviewer' ||
      data.userType === 'peer reviewer'
    ) {
      if (!data.vendorId) errors.vendorId = 'Please Enter Vendor ID';
      if (!data.granteeId) errors.granteeId = 'Please Enter Grantee ID';
    }

    if (!roles || !roles.length)
      errors.roles = 'User Roles is a required fields';
    return errors;
  };

  handleChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  handleToggle = (e, { name, checked }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: checked },
      errors: _.omit(this.state.errors, name),
    });

  handleRoleChange = (e, { value }) =>
    this.setState({ roles: value, errors: _.omit(this.state.errors, 'roles') });

  handleOnHoldReasonChange = (e, data) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, pendingOnHoldReason: data.value },
      errors: _.omit(this.state.errors, 'onHold'),
    });
    return;
  };

  handleContractExpirationChange = e => {
    const date = e.endOf('day').format();
    new Date(date) < new Date()
      ? this.setState({
          ...this.state,
          errors: {
            ...this.state.errors,
            contractExpiration: 'Contract expiration should be in the future',
          },
        })
      : this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            contractExpiration: date,
          },
          errors: _.omit(this.state.errors, 'contractExpiration'),
        });
  };

  handleReasonSubmit = (e, data) => {
    const reason =
      this.state.data &&
      this.state.data.pendingOnHoldReason &&
      Array.isArray(this.state.data.pendingOnHoldReason) &&
      this.state.data.pendingOnHoldReason.length > 0
        ? this.state.data.pendingOnHoldReason.join(', ')
        : '';

    this.setState({
      ...this.state,
      showReasonModal: false,
      data: {
        ...this.state.data,
        onHold: true,
        onHoldReason: reason,
        pendingOnHoldReason: null,
      },
      errors: _.omit(this.state.errors, 'onHold'),
    });
  };

  handleOnHoldChange = (e, { name, checked }) => {
    if (this.state.data.onHold) {
      this.setState({
        ...this.state,
        showAlertModal: true,
      });
      return;
    }

    this.setState({
      ...this.state,
      showReasonModal: true,
    });
  };

  renderAlertModal = () => {
    return (
      <AmsAlert
        show={this.state.showAlertModal}
        title="Are you sure?"
        text={`You are about to remove the on hold on this user. Are you sure you want continue?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, remove on hold'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ ...this.state, showAlertModal: false });
        }}
        onConfirm={() => {
          this.setState({
            ...this.state,
            showAlertModal: false,
            data: { ...this.state.data, onHold: false },
            errors: _.omit(this.state.errors, 'onHold'),
          });
        }}
      />
    );
  };

  renderReasonModal = () => {
    const { showReasonModal } = this.state;
    return (
      <AmsModal
        size="small"
        open={showReasonModal}
        centered={false}
        className="ams-semantic-modal-fix"
      >
        <Modal.Header content="Select reason for putting user on hold" />
        <Modal.Content>
          <Form.Field required>
            <AmsFormLabel fieldLabel="onHoldReason" name="Reason">
              <AmsLookupDropdown
                width={7}
                name="onHoldReason"
                value={this.state.data.pendingOnHoldReason || []}
                multiple
                placeholder="Select Reason"
                onChange={(e, data) => this.handleOnHoldReasonChange(e, data)}
                category={'userOnHoldReasons'}
                control={AmsLookupDropdown}
                fluid
                search
                selection
                id="onHoldReason"
                aria-labelledby="onHoldReason"
                searchInput={{
                  id: 'onHoldReasonSearch',
                  title: 'User on hold search',
                  'aria-labelledby': 'onHoldReason',
                }}
              />
            </AmsFormLabel>
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() =>
              this.setState({
                ...this.state,
                showReasonModal: false,
                data: {
                  ...this.state.data,
                  pendingOnHoldReason: null,
                },
                errors: _.omit(this.state.errors, 'onHold'),
              })
            }
            content="Cancel"
          />
          <Button
            primary
            disabled={
              !this.state.data.pendingOnHoldReason ||
              this.state.data.pendingOnHoldReason.length === 0
            }
            onClick={this.handleReasonSubmit}
            content="Save Note"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  render() {
    const {
      editMode,
      loading,
      errors,
      data,
      emptyValue,
      showSuccess,
      roles,
      rolesList,
    } = this.state;

    const reasonText = this.state.data.onHoldReason
      ? '(' + this.state.data.onHoldReason + ')'
      : '';

    return (
      <Form onSubmit={this.onSubmit} loading={loading}>
        <div className="profile-form-title-wrapper">
          <h2>User Settings</h2>
        </div>

        <div className="profile-form-fields-wrapper">
          {showSuccess && (
            <Message
              positive
              icon="check"
              header="Success!"
              content="Data saved successfully."
            />
          )}

          {!_.isEmpty(errors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={Object.keys(errors).map(errorKey => (
                <li key={errorKey}>{errors[errorKey]}</li>
              ))}
            />
          )}

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSettingsUserType"
                      label="User Type"
                    />
                  ),
                  htmlFor: 'userSettingsUserType',
                }}
                error={!!errors.userType}
                name="userType"
                selection
                onChange={this.handleChange}
                value={data.userType}
                category={'userTypes'}
                placeholder="Select user type"
                id="userSettingsUserType"
                aria-labelledby="userSettingsUserType"
                control={AmsLookupDropdown}
              />
            ) : (
              <Form.Field>
                <label>
                  User Type
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsUserType"
                  />
                </label>
                <div className="default-value">
                  {data.userType || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSettingsRegionId"
                      label="Region"
                    />
                  ),
                  htmlFor: 'userSettingsRegionId',
                }}
                selection
                name="regionalRegions"
                multiple
                onChange={this.handleChange}
                value={data.regionalRegions}
                category={'regions'}
                placeholder="Select Regions"
                id="userSettingsRegionId"
                aria-labelledby="userSettingsRegionId"
                control={AmsLookupDropdown}
              />
            ) : (
              <Form.Field>
                <label>
                  Region{' '}
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsRegionId"
                  />
                </label>
                <div className="default-value">
                  {data.regionalRegions
                    ? data.regionalRegions.join(', ')
                    : emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSettingsContractExpiration"
                      label="Contract Expiration"
                    />
                  ),
                  htmlFor: 'userSettingsContractExpiration',
                }}
                name="contractExpiration"
                onChangeRaw={e => e.preventDefault()}
                onChange={this.handleContractExpirationChange}
                value={data.contractExpiration}
                placeholder="Contract Expiration"
                id="userSettingsContractExpiration"
                aria-labelledby="userSettingsContractExpiration"
                error={
                  this.state.error?.contractExpiration &&
                  'Contract expiration must be in the future'
                }
                control={DatePicker}
              />
            ) : (
              <Form.Field>
                <label>
                  Contract Expiration{' '}
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsContractExpiration"
                  />
                </label>
                <div className="default-value">
                  {data.contractExpiration
                    ? AmsDateFormatters.formatDate(data.contractExpiration)
                    : emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                required={
                  data.userType === 'Consultant' ||
                  data.userType === 'consultant' ||
                  data.userType === 'Peer Reviewer' ||
                  data.userType === 'peer reviewer'
                }
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSettingsVendorID"
                      label="Vendor ID"
                    />
                  ),
                  htmlFor: 'userSettingsVendorID',
                }}
                error={!!errors.vendorId}
                name="vendorId"
                onChange={this.handleChange}
                value={data.vendorId}
                placeholder="Vendor ID"
                id="userSettingsVendorID"
                aria-labelledby="userSettingsVendorID"
                control={Input}
              />
            ) : (
              <Form.Field
                required={
                  data.userType === 'Consultant' ||
                  data.userType === 'consultant' ||
                  data.userType === 'Peer Reviewer' ||
                  data.userType === 'peer reviewer'
                }
              >
                <label>
                  Vendor ID
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsVendorID"
                  />
                </label>
                <div className="default-value">
                  {data.vendorId || emptyValue}
                </div>
              </Form.Field>
            )}

            {editMode &&
            (data.userType === 'Peer Reviewer' ||
              data.userType === 'peer reviewer') ? (
              <Form.Field
                required={
                  data.userType === 'Peer Reviewer' ||
                  data.userType === 'peer reviewer'
                }
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSettingsGranteeID"
                      label="Grantee ID"
                    />
                  ),
                  htmlFor: 'userSettingsGranteeID',
                }}
                error={!!errors.vendorId}
                name="granteeId"
                onChange={this.handleChange}
                value={data.granteeId}
                placeholder="Grantee ID"
                id="userSettingsGranteeID"
                aria-labelledby="userSettingsGranteeID"
                control={Input}
              />
            ) : (
              <Form.Field
                required={
                  data.userType === 'Peer Reviewer' ||
                  data.userType === 'peer reviewer'
                }
              >
                <label>
                  Grantee ID
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsGranteeID"
                  />
                </label>
                <div className="default-value">
                  {data.granteeId || emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSettingsHSESFederal"
                      label="HSES Federal"
                    />
                  ),
                  htmlFor: 'userSettingsHSESFederal',
                }}
                name="isHSESFederal"
                onChange={this.handleToggle}
                checked={data.isHSESFederal}
                // role="group"
                toggle
                id="userSettingsHSESFederal"
                aria-labelledby="userSettingsHSESFederal"
                control={Checkbox}
              />
            ) : (
              <Form.Field>
                <label>
                  HSES Federal
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsHSESFederal"
                  />
                </label>
                <div className="default-value">
                  {data.isHSESFederal ? 'Yes' : 'No'}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="userSettingsActive"
                      label="Active"
                    />
                  ),
                  htmlFor: 'userSettingsActive',
                }}
                name="active"
                onChange={this.handleToggle}
                checked={data.active}
                toggle
                id="userSettingsActive"
                aria-labelledby="userSettingsActive"
                control={Checkbox}
              />
            ) : (
              <Form.Field>
                <label>
                  Active
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsActive"
                  />
                </label>
                <div className="default-value">
                  {data.active ? 'Yes' : 'No'}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <>
                {this.renderReasonModal()}
                {this.renderAlertModal()}
                <Form.Field>
                  <Checkbox
                    label={{
                      children: (
                        <AmsHelpText
                          category="helpText"
                          fieldLabel="userSettingsOnHold"
                          label={
                            <span
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              On Hold{' '}
                            </span>
                          }
                        />
                      ),
                      htmlFor: 'userSettingsOnHold',
                    }}
                    name="onHold"
                    onChange={(e, data) => this.handleOnHoldChange(e, data)}
                    checked={data.onHold}
                    toggle
                    id="userSettingsOnHold"
                    aria-labelledby="userSettingsOnHold"
                  />
                  {data.onHold ? (
                    <>
                      {reasonText}{' '}
                      <>
                        (
                        <Link
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                              ...this.state,
                              showReasonModal: true,
                              data: {
                                ...this.state.data,
                                pendingOnHoldReason: this.state.data
                                  .onHoldReason
                                  ? this.state.data.onHoldReason.split(', ')
                                  : [],
                              },
                            });
                          }}
                        >
                          Edit
                        </Link>
                        )
                      </>
                    </>
                  ) : (
                    ''
                  )}
                </Form.Field>
              </>
            ) : (
              <Form.Field>
                <label>
                  On Hold
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsOnHold"
                  />
                </label>
                <div className="default-value">
                  {data.onHold ? `Yes ${reasonText}` : 'No'}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field required>
                <AmsFormLabel
                  helpText={true}
                  fieldLabel="userSettingsRoles"
                  name="User Roles"
                >
                  <Dropdown
                    error={!!errors.roles}
                    name="roles"
                    multiple
                    search
                    selection
                    onChange={this.handleRoleChange}
                    value={roles}
                    options={rolesList.map(roleType => ({
                      key: roleType.role,
                      text: roleType.displayName,
                      value: roleType.role,
                    }))}
                    placeholder="Select user role"
                    id="userSettingsRoles"
                    aria-labelledby="userSettingsRoles"
                    searchInput={{
                      id: 'userSettingsRolesSearch',
                      title: 'User settings roles search',
                      'aria-labelledby': 'userSettingsRoles',
                    }}
                  />
                </AmsFormLabel>
              </Form.Field>
            ) : (
              <Form.Field required>
                <label>
                  User Roles
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="userSettingsRoles"
                  />
                </label>
                <div className="default-value">
                  {(roles &&
                    roles
                      .map(roleKey => {
                        const foundRole = _.find(
                          rolesList,
                          roleType => roleType.role === roleKey
                        );
                        return foundRole && foundRole.displayName;
                      })
                      .join(', ')) ||
                    emptyValue}
                </div>
              </Form.Field>
            )}
          </Form.Group>
        </div>

        <Segment basic floated="right">
          {editMode ? (
            <div>
              <Button size="large" primary>
                Save
              </Button>
              <Button
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.cancelEdit();
                }}
                basic
              >
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              size="large"
              floated="right"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  error: {},
                  showSuccess: false,
                  editMode: true,
                });
              }}
            >
              Edit
            </Button>
          )}
        </Segment>
      </Form>
    );
  }
}

UserSettingsForm.propTypes = {
  profile: PropTypes.object,
  rolesList: PropTypes.array,
  lookups: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notes: state.note.notes,
  lookups: state.lookups,
  profile: state.profile,
  profileTab: state.profileTab,
  rolesList: state.admin.userRoles,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {
  updateUserProfile,
  addNote,
  updateUserRoles,
})(UserSettingsForm);
