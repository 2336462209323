import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchUsers, fetchInternalUsers } from '../../actions/userActions';

import { FieldGroupSelect } from '../../utils/FieldGroup';

import { fetchLookups } from '../../actions/lookupActions';

import {
  fetchReportGranteeContact,
  fetchExternalUsers,
} from '../../actions/reportActions';

import DatePicker from 'react-datepicker';

// Be sure to include styles at some point, probably during your bootstrapping
import 'react-select/dist/react-select.css';

import './assets/report-share.css';

class ReportShare extends Component {
  constructor() {
    super();

    this.state = {
      emailTo: [],
      visible: false,
      explain: '',
      reportFile: false,
      reportLink: '',
      message: '',
    };

    this.reset = this.reset.bind(this);

    this.emailToChange = this.emailToChange.bind(this);
    this.usersToChange = this.usersToChange.bind(this);
    this.messageChange = this.messageChange.bind(this);
    this.granteeEmailsChange = this.granteeEmailsChange.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.getEmailToOptions = this.getEmailToOptions.bind(this);
    this.getUsersToOptions = this.getUsersToOptions.bind(this);

    this.submit = this.submit.bind(this);
    this.actionName = this.actionName.bind(this);
  }

  reset(state, cb) {
    if (!cb) cb = () => {};
    this.setState(
      {
        actionName: '',
        emailTo: [],
        usersTo: [],
        visible: false,
        explain: '',
        reportFile: false,
        reportLink: '',
        includeComments: true,
        message: '',
      },
      () => {
        if (state && typeof state === 'object') {
          this.setState(state, cb);
        } else {
          cb();
        }
      }
    );
  }

  componentDidMount() {
    if (typeof this.props.onInit === 'function') {
      this.props.onInit(this);
    }

    this.props.fetchUsers();
    this.props.fetchLookups();
    this.props.fetchReportGranteeContact(this.props.reviewId);
    this.expirationDate = new Date();
    this.reset();
  }

  componentWillReceiveProps(nextProps) {
    // TODO
  }

  getEmailToOptions(input, callback) {
    return this.props.externalUsers
      .filter(user => {
        return (
          this.state.emailTo.filter(
            u => u.value.email === user.email && u.value.group === user.group
          ).length === 0
        );
      })
      .map(item => {
        return {
          label:
            item.firstName +
            ' ' +
            item.lastName +
            ', ' +
            item.group +
            ', ' +
            item.region,
          value: { email: item.email, group: item.group },
        };
      });
  }

  getUsersToOptions(input, callback) {
    return this.props.internalUsers
      .filter(user => {
        return (
          this.state.usersTo.filter(
            u => u.value.email === user.email && u.value.group === user.group
          ).length === 0
        );
      })
      .map(user => {
        let name = [
          user.title,
          user.firstName,
          user.middleName,
          user.lastName,
        ].join(' ');
        return {
          label: name,
          value: { email: user.email, group: user.group },
        };
      });
  }

  emailToChange(value) {
    this.setState({
      emailTo: value.filter((item, index, arr) => {
        let firstIndex;
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].value.email === item.value.email &&
            arr[i].value.group === item.value.group
          ) {
            firstIndex = i;
          }
        }
        return index === firstIndex;
      }),
    });
  }

  usersToChange(value) {
    this.setState({
      usersTo: value.filter((item, index, arr) => {
        let firstIndex;
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].value.email === item.value.email &&
            arr[i].value.group === item.value.group
          ) {
            firstIndex = i;
          }
        }
        return index === firstIndex;
      }),
    });
  }

  messageChange(ev) {
    this.setState({
      message: ev.target.value,
    });
  }

  granteeEmailsOptions() {
    return (this.state.granteeEmails || []).map(item => {
      return {
        label: item,
        value: { email: item },
      };
    });
  }

  granteeEmailsChange(ev) {
    this.setState({
      granteeEmails: ev.target.value,
    });
  }

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
      message: '',
      emailTo: [],
      usersTo: [],
      expirationDateCtrlError: '',
      expirationDateLabelError: '',
    });
  }

  actionName(action) {
    if (typeof action === 'string') {
      let message = '';
      let emailTo = [];
      let granteeEmails = [];

      if (action === 'Grantee') {
        granteeEmails = [
          {
            label: 'Anand',
            value: {
              email: 'nkanand@qssinc.com',
              group: 'Grantee',
            },
          },
          {
            label: 'Mohan',
            value: {
              email: 'jcamilineni_con@qssinc.com',
              group: 'Grantee',
            },
          },
        ];
      }

      if (action === 'OHS') {
        message = `Hello:\n\
        The ${
          (((this.props.review || {}).selectedReview || {}).grantees[0] || {})
            .granteeName
        }/${
          (((this.props.review || {}).selectedReview || {}).grantees[0] || {})
            .granteeId
        } (${
          ((this.props.review || {}).selectedReview || {}).numberOfGrants
        }) ${((this.props.review || {}).selectedReview || {}).reviewType}${
          ((this.props.review || {}).selectedReview || {}).reviewType ===
          'CLASS'
            ? '&reg;'
            : ''
        }/${
          ((this.props.review || {}).selectedReview || {}).reviewId
        } monitoring report has been sent to you for review and for any comments or feedback you choose to provide.
\n\
Please provide your comments within 5 business days. At that time, the report will move forward automatically.\n\
`;
      }

      if (action === 'OGC') {
        message = `Hello:\n\
        The ${
          (((this.props.review || {}).selectedReview || {}).grantees[0] || {})
            .granteeName
        }/${
          (((this.props.review || {}).selectedReview || {}).grantees[0] || {})
            .granteeId
        } (${
          ((this.props.review || {}).selectedReview || {}).numberOfGrants
        }) ${((this.props.review || {}).selectedReview || {}).reviewType}/${
          ((this.props.review || {}).selectedReview || {}).reviewId
        } monitoring report has been sent to you for review and for any comments or feedback you choose to provide.
\n\
Please provide your comments within 10 business days. At that time, the report will move forward automatically.\n\
`;
      }
      if (action === 'RO') {
        message = `Hello,\n\
\n\
The ${
          (((this.props.review || {}).selectedReview || {}).grantees[0] || {})
            .granteeName
        }/${
          (((this.props.review || {}).selectedReview || {}).grantees[0] || {})
            .granteeId
        } (${
          ((this.props.review || {}).selectedReview || {}).numberOfGrants
        }) ${((this.props.review || {}).selectedReview || {}).reviewType}/${
          ((this.props.review || {}).selectedReview || {}).reviewId
        } monitoring report has been sent to you for review and for any comments or feedback you choose to provide.
\n\
Please provide your comments within 5 business days. At that time, the report will move forward automatically.\n\
`;
      }
      (
        document.querySelector('.share-report--message.message') || {}
      ).value = message;
      this.setState({
        message: message,
        actionName: action,
        emailTo: emailTo,
        expirationDate: null,
        granteeEmails: granteeEmails,
      });
    }

    if (['RO', 'OHS', 'OGC'].indexOf(action) !== -1) {
      this.props.fetchExternalUsers({
        group: action,
        region: (
          ((this.props.review || {}).selectedReview || {}).grantees[0] || {}
        ).region,
        reviewType: ((this.props.review || {}).selectedReview || {}).reviewType,
      });
      this.props.fetchInternalUsers({
        reviewType: ((this.props.review || {}).selectedReview || {}).reviewType,
      });
    }

    return this.state.actionName;
  }

  handleValidation() {
    let formIsValid = true;
    if (!this.state.expirationDate && this.state.actionName !== 'Grantee') {
      formIsValid = false;
      this.setState({
        expirationDateCtrlError: 'errCtrl',
        expirationDateLabelError: 'errLbl',
      });
    }
    return formIsValid;
  }

  submit(previewOnly) {
    if (typeof this.props.onSubmit === 'function') {
      if (this.handleValidation()) {
        this.setState({
          expirationDateCtrlError: '',
          expirationDateLabelError: '',
        });
        let emails = [];
        this.state.emailTo
          .map(v => {
            return v.value;
          })
          .forEach(email => {
            if (Array.isArray(email)) {
              email.forEach(v => {
                emails.push(v);
              });
            } else {
              emails.push(email);
            }
          });
        let adjustedMessage = this.state.message.replace(
          new RegExp(`\n`, `g`),
          `<br/>`
        );
        this.props.onSubmit(
          {
            actionName: this.state.actionName,
            emailTo: emails,
            usersTo: this.state.usersTo.map(v => {
              return v.value;
            }),
            message: adjustedMessage,
            granteeEmails: this.state.granteeEmails.map(
              item => item.value.email
            ),
            reportLink: this.state.reportLink,
            sendComments: true,
            reportFile: this.state.reportFile,
          },
          this,
          previewOnly
        );
      }
    }
  }

  renderEmailToValue(option) {
    return (
      <div className="share-report--email-to--user">
        <div
          className="share-report--email-to--user-icon"
          style={{
            backgroundColor: option.color || '#d0d0d0',
            backgroundImage: option.icon ? 'url(' + option.icon + ')' : 'none',
          }}
        />
        {option.name}
      </div>
    );
  }

  entityToChar = (str, elm) => {
    const el = document.createElement(elm);
    el.innerHTML = str;
    return el.value;
  };

  render() {
    let userToOptions = this.getUsersToOptions();
    return (
      <div
        className="share-report"
        style={{ display: this.state.visible ? '' : 'none' }}
      >
        <div className="share-report--content">
          <div
            className="row share-report--explain"
            style={{ display: this.state.explain ? '' : 'none' }}
          >
            <div className="col-sm-12">
              <p>
                <i>{this.state.explain}</i>
              </p>
            </div>
          </div>
          <div
            className="row share-report--share-width"
            style={{
              display: this.state.actionName === 'Grantee' ? 'none' : '',
            }}
          >
            <div className="col-sm-3">
              <h3>Share with DLH</h3>
            </div>
            <div className="col-sm-9">
              <FieldGroupSelect
                closeOnSelect={false}
                id="internalUsers"
                value={this.state.usersTo}
                multi={true}
                matchProp={'label'}
                options={userToOptions}
                onChange={this.usersToChange}
                label="DLH Users"
                placeholder="Select Users"
                searchable={false}
                clearable
              />
            </div>
          </div>
          <div
            className="row share-report--share-width"
            style={{
              display: this.state.actionName === 'Grantee' ? 'none' : '',
            }}
          >
            <div className="col-sm-3">
              <h3>Share with {this.state.actionName}</h3>
            </div>
            <div className="col-sm-9">
              <FieldGroupSelect
                closeOnSelect={false}
                id="externalUsers"
                value={this.state.emailTo}
                multi={true}
                matchProp={'label'}
                options={this.getEmailToOptions()}
                onChange={this.emailToChange}
                label={this.state.actionName + ' Users'}
                placeholder="Select Users"
                searchable={false}
                clearable
              />
            </div>
          </div>
          <div
            className={
              'row share-report--share-width' +
              (this.state.actionName === 'Grantee' ? ' inactive' : '')
            }
            style={{
              display: this.state.actionName === 'Grantee' ? '' : 'none',
            }}
          >
            <div className="col-sm-3">
              <h3>Grantee Contacts</h3>
            </div>
            <div className="col-sm-9">
              <FieldGroupSelect
                closeOnSelect={false}
                id="granteeEmails"
                value={this.state.granteeEmails}
                multi={true}
                matchProp={'label'}
                options={this.granteeEmailsOptions()}
                onChange={this.granteeEmailsChange}
                label="Grantee Emails"
                placeholder="Grantee Emails"
                searchable={false}
                clearable
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              display:
                ['OHS'].indexOf(this.state.actionName) !== -1 ? '' : 'none',
            }}
          />
          <div
            className="row"
            style={{
              display: this.state.actionName === 'Grantee' ? 'none' : '',
            }}
          >
            <div className="col-sm-3">
              <h3 className={this.state.expirationDateLabelError}>
                Expiration Date <sup style={{ color: 'red' }}>*</sup>
              </h3>
            </div>
            <div className="col-sm-9">
              <DatePicker
                className={this.state.expirationDateCtrlError}
                selected={this.state.expirationDate}
                dateFormat="MM/DD/YYYY"
                minDate={new Date()}
                onChange={(date => {
                  this.expirationDate = date.toDate();
                  this.setState({ expirationDate: date });
                  // eslint-disable-next-line no-extra-bind
                }).bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <textarea
                className="share-report--message message"
                autoComplete="Off"
                placeholder="your message ..."
                onBlur={this.messageChange}
                onChange={this.messageChange}
                value={this.entityToChar(this.state.message, 'textarea')}
              />
            </div>
          </div>
          <div
            className="row share-report--info"
            style={{ display: this.state.reportFile ? 'block' : 'none' }}
          >
            <div className="col-sm-12">
              <h3>Report File</h3>
              <p>
                HTML version of this report and its respective comment box will
                be attached and sent up on submission of this form.
              </p>
            </div>
          </div>
          <div
            className="row share-report--info"
            style={{ display: this.state.reportLink ? 'block' : 'none' }}
          />

          <div className="row">
            <div className="col-sm-12">
              <button
                className="btn btn-primary active"
                onClick={() => {
                  this.submit(false);
                }}
              >
                Send
              </button>
              <button className="btn btn-primary" onClick={this.hide}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReportShare.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchLookups: PropTypes.func.isRequired,
  fetchExternalUsers: PropTypes.func.isRequired,
  fetchInternalUsers: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    users: state.users,
    externalUsers: (state.report || {}).externalUsers,
    internalUsers: (state.users || {}).internalUsers,
    reportGranteeContact: (state.report || {}).reportGranteeContact,
    review: state.review,
  };
};

export default connect(mapStateToProps, {
  fetchUsers,
  fetchExternalUsers,
  fetchInternalUsers,
  fetchLookups,
  fetchReportGranteeContact,
})(ReportShare);
