import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Icon,
  Divider,
  Form,
  TextArea,
  Comment,
} from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router';
import { orderBy } from 'lodash';

//import utils
import {
  prepData,
  humanizeFileSize,
} from '../Shared/FormHelpers/helpers/utils';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import MimeIcon from '../Shared/FormHelpers/helpers/MimeIcon';
import AmsAlert from '../../utils/AmsAlert';

//import actions
import { downloadFeedbackFile } from '../../actions/surveyActions';
import { reportExternalCommentReplyAction } from '../../actions/reportActions';

export default function ReportExternalComments(props) {
  const dispatch = useDispatch();
  const { newReport } = useSelector(state => state.report);

  const [replyMessage, setReplyMessage] = useState('');
  const [comments, setComments] = useState([]);
  const [modalReplyIndex, setModalReplyIndex] = useState(-1);
  const [showAlert, setShowAlert] = useState(false);
  const [activityInfo, setActivityInfo] = useState({
    type: '',
    message: '',
  });

  useEffect(() => {
    if (newReport && newReport.comments && newReport.comments.length > 0)
      setComments(newReport.comments);
  }, [newReport]);

  const [activeIndex, setActiveIndex] = useState(-1);
  const subDividerStyle = { marginLeft: '-15px', marginRight: '-15px' };
  let length = comments.length;

  const entityToChar = (str, elm) => {
    const el = document.createElement(elm);
    el.innerHTML = str;
    return el.value;
  };

  const approvedBox = (
    <div
      style={{
        float: 'right',
        width: '90px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px #007a26',
        backgroundColor: '#f5fff8',
        color: '#007a26',
      }}
    >
      <Icon name="check circle" color="green"></Icon>
      APPROVED
    </div>
  );

  const needClarificationBox = (
    <div
      style={{
        float: 'right',
        width: '125px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px rgb(189,152,77)',
        backgroundColor: 'rgb(254,248,222)',
        color: 'rgb(189,152,77)',
      }}
    >
      NEED CLARIFICATION
    </div>
  );

  const inProgressBox = (
    <div
      style={{
        float: 'right',
        width: '90px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px rgb(69,138,204)',
        backgroundColor: 'rgb(225,239,253)',
        color: 'rgb(69,138,204)',
      }}
    >
      IN PROGRESS
    </div>
  );

  const rejectedBox = (
    <div
      style={{
        float: 'right',
        width: '90px',
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        height: '21px',
        padding: '4px 6px 5px',
        borderRadius: '3px',
        border: 'solid 1px #d91919',
        backgroundColor: '#fff5f5',
        color: '#d91919',
      }}
    >
      <Icon
        style={{ float: 'left', lineHeight: 'inherit' }}
        name="close circle"
        color="red"
      ></Icon>
      REJECTED
    </div>
  );

  const handleClick = (idx, e) => {
    const newIndex = activeIndex === idx ? -1 : idx;
    setActiveIndex(newIndex);
  };

  const triggerDownload = (id, name) => {
    if (id && name) {
      //this.setState({ downloading: true });

      dispatch(downloadFeedbackFile(id, name));
    }
  };

  const renderActivityInfoAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type={activityInfo.type}
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={activityInfo.message}
        onConfirm={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const renderFeedbackFiles = evidences => {
    if (evidences.length === 0) {
      return '';
    }

    let files = evidences.map(e => {
      return prepData(e);
    });

    return (
      <div style={{ marginTop: '10px' }}>
        {files.map((e, idx) => {
          let fileName = e.name;
          if (e.size) fileName = `${fileName} - (${humanizeFileSize(e.size)})`;

          return (
            <div key={idx}>
              <a
                href=""
                download={e.name}
                onClick={event => {
                  event.preventDefault();
                  triggerDownload(e.id, e.name);
                }}
              >
                <MimeIcon mime={e.type} />
                {fileName}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  const onSubmitReply = data => {
    let input = {
      findingId: data.findingId[0],
      conversationId: data.conversationId,
      reviewId: newReport.reviewID,
      response: replyMessage,
    };

    dispatch(
      reportExternalCommentReplyAction(input, result => {
        setReplyMessage('');
        setModalReplyIndex(-1);

        if (result instanceof Error) {
          setShowAlert(true);
          setActivityInfo({
            type: 'error',
            message: `'Error: ${result.response.data.message ||
              'unknown error'}`,
          });
        } else {
          setShowAlert(true);
          setActivityInfo({
            type: 'success',
            message: 'You have replied to the external comment.',
          });
        }
      })
    );
  };

  const renderFindings = () => {
    const current = comments[activeIndex];

    const subHeaderStyle = {
      fontSize: '14px',
      fontFamily: 'PublicSans-Medium',
      height: '14px',
      margin: '5px 0px 14px 0px',
    };

    const commentStyle = {
      margin: '4px 17px 9px 0px',
      fontSize: '15px',
      textAlign: 'justify',
    };

    if (!current) {
      return;
    }

    let findings;
    if (current.findings && current.findings.length > 0) {
      findings = current.findings.map((e, index) => {
        let narrativeComment;
        let findingComment;
        let findingTypeComment;

        if (e && e.comments) {
          e.comments.forEach(comment => {
            if (comment.type === 'narrative') {
              narrativeComment = comment;
            } else if (comment.type === 'findingType') {
              findingTypeComment = comment;
            } else if (comment.type === 'finding') {
              findingComment = comment;
            }
          });
        }

        if (e.type === 'all') {
          return (
            <>
              <div style={{ fontSize: '14px' }}>
                <span
                  style={{ color: '#00449f', fontFamily: 'PublicSans-Bold' }}
                >
                  ALL FINDINGS
                </span>{' '}
                <i>({e.citationNumbers.join(', ')})</i>
                {e.status === 'Approved' && approvedBox}
                {e.status === 'Rejected' && rejectedBox}
                {e.status === 'Need Clarification' && needClarificationBox}
                {e.status === 'In Progress' && inProgressBox}
              </div>
              <Divider style={subDividerStyle} />
              <>
                <div style={subHeaderStyle}>
                  Comments for All Findings
                  <span
                    style={{
                      display: 'inline-block',
                      marginLeft: ' 0.5em',
                      color: 'rgba(0,0,0,.4)',
                      fontSize: '.875em',
                    }}
                    class="subheader-metadata"
                  >
                    {' '}
                    {AmsDateFormatters.formatDateTime(
                      e.comments[0].updatedTime
                    )}
                  </span>
                </div>
                <span style={commentStyle}>{e.comments[0].comment}</span>
                {renderFeedbackFiles(e.comments[0].evidences)}
              </>
            </>
          );
        }

        return (
          <>
            <div style={{ fontSize: '14px' }}>
              <span style={{ color: '#00449f', fontFamily: 'PublicSans-Bold' }}>
                FINDING {index + 1}
              </span>{' '}
              <i>({e.citationNumbers})</i>
              {e.status === 'Approved' && approvedBox}
              {e.status === 'Rejected' && rejectedBox}
              {e.status === 'Need Clarification' && needClarificationBox}
              {e.status === 'In Progress' && inProgressBox}
            </div>
            <Divider style={subDividerStyle} />
            {findingComment && findingComment.comment && (
              <>
                <div style={subHeaderStyle}>
                  Comments for Finding {index + 1}
                  {findingComment.status === 'Approved' && approvedBox}
                  {findingComment.status === 'Rejected' && rejectedBox}
                  {findingComment.status === 'Need Clarification' &&
                    needClarificationBox}
                  {findingComment.status === 'In Progress' && inProgressBox}
                  <span
                    style={{
                      display: 'inline-block',
                      marginLeft: ' 0.5em',
                      color: 'rgba(0,0,0,.4)',
                      fontSize: '.875em',
                    }}
                    class="subheader-metadata"
                  >
                    {' '}
                    {AmsDateFormatters.formatDateTime(
                      findingComment.updatedTime
                    )}
                  </span>
                </div>
                <span style={commentStyle}>{findingComment.comment}</span>
                <div
                  style={{ fontSize: '12px', marginTop: '10px' }}
                  class="actions"
                >
                  {(findingComment.responses === null ||
                    findingComment.responses.length === 0) && (
                    <Link
                      to="#"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        setModalReplyIndex(index);
                      }}
                      class=""
                    >
                      Reply
                    </Link>
                  )}
                  {findingComment.responses &&
                    findingComment.responses.length > 0 && (
                      <Comment.Group>
                        {orderBy(
                          findingComment.responses,
                          ['updatedTime'],
                          ['desc']
                        ).map(reply => {
                          return (
                            <Comment>
                              <Comment.Content>
                                <Comment.Author as="span">
                                  {reply.responseBy}
                                </Comment.Author>
                                <Comment.Metadata>
                                  <div>
                                    {' '}
                                    {AmsDateFormatters.formatDateTime(
                                      reply.updatedTime
                                    )}
                                  </div>
                                </Comment.Metadata>
                                <Comment.Text>{reply.response}</Comment.Text>
                              </Comment.Content>
                            </Comment>
                          );
                        })}
                      </Comment.Group>
                    )}
                  {index === modalReplyIndex && (
                    <Form
                      reply
                      onSubmit={() => {
                        onSubmitReply({
                          conversationId: current.conversationId,
                          findingId: e.findingIds,
                        });
                      }}
                    >
                      <TextArea
                        value={entityToChar(replyMessage, 'textarea')}
                        onChange={e => {
                          setReplyMessage(e.target.value);
                        }}
                        rows={3}
                        style={{
                          height: '100px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      />
                      <Form.Group style={{ marginTop: '5px' }}>
                        <Form.Button
                          type="submit"
                          primary
                          content={'Add Reply'}
                        />
                        <Form.Button
                          onClick={() => {
                            setModalReplyIndex(-1);
                          }}
                          type="cancel"
                          content={'Cancel'}
                        />
                      </Form.Group>
                    </Form>
                  )}
                </div>
                {renderFeedbackFiles(findingComment.evidences)}
                {((narrativeComment && narrativeComment.comment) ||
                  (findingTypeComment && findingTypeComment.comment)) && (
                  <Divider style={subDividerStyle} />
                )}
              </>
            )}
            {findingTypeComment && findingTypeComment.comment && (
              <>
                <div style={subHeaderStyle}>
                  Comments for Finding Type ({e.findingType})
                  {findingTypeComment.status === 'Approved' && approvedBox}
                  {findingTypeComment.status === 'Rejected' && rejectedBox}
                  {findingTypeComment.status === 'Need Clarification' &&
                    needClarificationBox}
                  {findingTypeComment.status === 'In Progress' && inProgressBox}
                  <span
                    style={{
                      display: 'inline-block',
                      marginLeft: ' 0.5em',
                      color: 'rgba(0,0,0,.4)',
                      fontSize: '.875em',
                    }}
                    class="subheader-metadata"
                  >
                    {' '}
                    {AmsDateFormatters.formatDateTime(
                      findingTypeComment.updatedTime
                    )}
                  </span>
                </div>
                <span style={commentStyle}>{findingTypeComment.comment}</span>
                {renderFeedbackFiles(findingTypeComment.evidences)}
                {narrativeComment && narrativeComment.comment && (
                  <Divider style={subDividerStyle} />
                )}
              </>
            )}
            {narrativeComment && narrativeComment.comment && (
              <>
                <div style={subHeaderStyle}>
                  Comments for Narrative
                  {narrativeComment.status === 'Approved' && approvedBox}
                  {narrativeComment.status === 'Rejected' && rejectedBox}
                  {narrativeComment.status === 'Need Clarification' &&
                    needClarificationBox}
                  {narrativeComment.status === 'In Progress' && inProgressBox}
                  <span
                    style={{
                      display: 'inline-block',
                      marginLeft: ' 0.5em',
                      color: 'rgba(0,0,0,.4)',
                      fontSize: '.875em',
                    }}
                    class="subheader-metadata"
                  >
                    {' '}
                    {AmsDateFormatters.formatDateTime(
                      narrativeComment.updatedTime
                    )}
                  </span>
                </div>

                <span style={commentStyle}>{narrativeComment.comment}</span>
                {renderFeedbackFiles(narrativeComment.evidences)}
              </>
            )}
            {index !== current.findings.length - 1 && (
              <Divider style={subDividerStyle} />
            )}
          </>
        );
      });
    } else {
      return <span>N/A</span>;
    }

    return (
      <>
        <>{findings}</>
      </>
    );
  };

  const renderComments = () => {
    const current = comments[activeIndex];

    const toRender = comments.map((e, idx) => {
      let legacy = e.findings === null && e.note !== null;

      return (
        <React.Fragment key={idx}>
          <Accordion.Title
            active={activeIndex === idx}
            index={idx}
            onClick={e => handleClick(idx, e)}
          >
            <Link
              to="#"
              style={{ textDecoration: 'none', color: 'inherit' }}
              onClick={e => {
                e.preventDefault();
                handleClick(idx, e);
              }}
            >
              {e.submittedBy.fullName} |{' '}
              {legacy
                ? AmsDateFormatters.getMoment(e.timestamp).format('L')
                : e.timestamp || 'null'}
              <Icon style={{ float: 'right' }} name="dropdown" />
            </Link>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === idx}>
            <Divider
              style={{
                marginLeft: '-15px',
                marginRight: '-15px',
                marginTop: '-10px',
              }}
            />
            {legacy ? <span>{e.note}</span> : renderFindings()}
          </Accordion.Content>
        </React.Fragment>
      );
    });

    return toRender;
  };

  return (
    <>
      <h2>External Comments ({length})</h2>
      <Accordion
        style={{
          boxShadow:
            '0 16px 28px -1px rgba(14, 31, 53, 0.1), 0 8px 16px 0 rgba(14, 31, 53, 0.16), 0 4px 8px 0 rgba(14, 31, 53, 0.1)',
          width: '100%',
        }}
        styled
      >
        {renderComments()}
        {renderActivityInfoAlert()}
      </Accordion>
    </>
  );
}
