/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Import utils.
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';

// Import Icon
import pdfIcon from '../../../../assets/images/pdf-icon.png';

// Import Actions
import { downloadGranteeReviewReport } from '../../../../actions/granteeActions';

const ReviewShippedInfo = ({ selectedReview, downloadGranteeReviewReport }) => {
  const generatePDFLink = () => (
    <a
      href=""
      onClick={e => {
        e.preventDefault();
        downloadGranteeReviewReport(selectedReview.reviewId, {
          fileName: `${selectedReview.reportAttachmentObjectId}`,
        });
      }}
    >
      <span>
        {selectedReview.reShipped ? 'Reshipped on' : 'Shipped on'}{' '}
        {AmsDateFormatters.formatDate(selectedReview.reportShippedDate)}{' '}
      </span>
      <img
        alt="PDF icon"
        className="manifest-pdf-icon"
        src={pdfIcon}
        width="16"
      />
    </a>
  );

  return selectedReview && selectedReview.reportShippedDate ? (
    <div>
      {selectedReview.reportAttachmentObjectId && (
        <div>
          <span
            className="field-title"
            role="heading"
            aria-level="2"
            aria-label="Review Status"
          >
            Final Report:{' '}
          </span>{' '}
          {generatePDFLink()}
        </div>
      )}
    </div>
  ) : null;
};

ReviewShippedInfo.propTypes = {
  selectedReview: PropTypes.object.isRequired,
  downloadGranteeReviewReport: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps, { downloadGranteeReviewReport })(
  ReviewShippedInfo
);
