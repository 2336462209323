import React, { Component } from 'react';
import SlidingContainer from '../../utils/layout/SlidingContainer';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { FieldGroupSelect } from '../../utils/FieldGroup';
import { fetchUsers } from '../../actions/userActions';
import {
  fetchReportVersionData,
  fetchReportStatusData,
} from '../../actions/reportActions';
import {
  fetchFollowupRecommendations,
  saveReviewRpmFeedback,
  reviewRpmFeedbackProgramSpecialist,
  reviewRpmFeedbackFiscalSpecialist,
} from '../../actions/reviewActions';

import {
  fetchReviewDetail,
  fetchFindingDetails,
  fetchEmailNotifyMpsRequest,
} from '../../actions/reviewActions';
import { fetchRegionalUsers } from '../../actions/userActions';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import style
import './assets/style.css';

class ReviewFindingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regUsersRequested: false,
      followUpReviewer: null,
      rpmSaveToggle: false,
      rpmCommentStatus: 'rpmNotified',
      formData: {
        ncCount: 0,
        defCount: 0,
        furRecommendations: [],
        furRecommendationIndex: 0,
      },
      rpmFormRows: [
        {
          NumberOfANCs: 0,
          CorrectiveActionPeriod: 0,
        },
        {
          NumberOfANCs: 0,
          CorrectiveActionPeriod: 0,
        },
      ],
      ro: {
        ProgramSpecialist: null,
        pocProgramSpecialist: null,
        grantSpecialist: null,
      },
      dlh: {
        ProgramSpecialist: null,
      },
    };

    this.getRecommendations = this.getRecommendations.bind(this);
    this.currentCitation = this.currentCitation.bind(this);
    this.getData = this.getData.bind(this);
    this.radioSelectionHandler = this.radioSelectionHandler.bind(this);
  }

  adjustFindingDetails(details, reviewDetails) {
    if (!details) return details;
    let newDetails = [];
    details.forEach(finding => {
      if ('associatedCitations' in finding || 'nonAssocCitations' in finding) {
        (finding.associatedCitations || []).forEach(item => {
          newDetails.push({
            citation: item.associatedCitation || item.nonAssocCitations || null,
            granteeName:
              ((reviewDetails.grantees || [])[0] || {}).granteeName || null,
            guideName: null,
            performanceMeasure: null,
            findingStatus: (() => {
              let type = finding.type + '';
              if (type.match(/Deficiency/)) return 'DEF';
              if (type.match(/Area\s+of\s+Noncompliance/)) return 'ANC';
              if (type.match(/Area\s+of\s+Concern/)) return 'AOC';

              return type;
            })(finding.type),
            narrative: item.narrative,
            reportDeliveryDate: finding.reportDeliveryDate,
            correctiveActionTimeFrame:
              item.correctiveActionPeriod || finding.correctiveActionPeriod,
            correctiveActionDueDate:
              item.correctionDueDate || finding.correctionDueDate,
            followUpDetermination: null,
          });
        });
      } else {
        newDetails.push(finding);
      }
    });
    return newDetails;
  }

  getRecommendations() {
    let recommendations = [];

    this.state.formData.furRecommendations.forEach(recommendation => {
      (Array.isArray(recommendation.associatedCitations)
        ? recommendation.associatedCitations
        : [recommendation.associatedCitations]
      ).forEach(citation => {
        let i,
          item = {};

        for (i in recommendation) {
          if (i === 'associatedCitations') {
            item[i] =
              typeof citation === 'string'
                ? citation
                : (citation || {}).associatedCitation;
          } else {
            item[i] = recommendation[i];
          }
        }

        recommendations.push(item);
      });
    });

    return recommendations;
  }

  getData() {
    return {
      reviewId: this.props.params.id, // Review ID
      ncCount: this.state.formData.ncCount, // Non compliant findings count
      defCount: this.state.formData.defCount, // Deficiency Count
      furRecommendations: this.getRecommendations(), // Array of citations
    };
  }

  componentWillReceiveProps() {
    if (
      ((this.props.review || {}).selectedReview || {}).reviewId &&
      !this.props.regionalUsers &&
      !this.state.regUsersRequested
    ) {
      this.props.fetchRegionalUsers({
        reviewId: ((this.props.review || {}).selectedReview || {}).reviewId,
        roleName: 'GrantSpecialist',
      });
      this.setState({ regUsersRequested: true });
    }
  }

  componentDidMount() {
    this.props.fetchFollowupRecommendations(this.props.params.id);
    this.props.fetchUsers();
    this.findingDetailsRequested = null;
    this.props.fetchReviewDetail({
      reviewId: this.props.params.id,
    });
    this.props.reviewRpmFeedbackProgramSpecialist();
  }

  associatedCitationId(data, compareId) {
    if (data === undefined) {
      throw Error('Undefined associatedCitationId');
    }

    if (typeof data === 'string') {
      data = data.split('§').sort();
    } else if (Array.isArray(data)) {
      data = data.map(item => item.associatedCitation).sort();
    } else if ('associatedCitation' in data) {
      data = data.associatedCitation.split('§');
    }

    if (typeof compareId === 'string') {
      compareId = compareId.split('§').sort();
    } else if (Array.isArray(compareId)) {
      compareId = compareId.map(item => item.associatedCitation).sort();
    } else {
      return data.join('§');
    }

    // find intersection
    let intersection = false;

    []
      .concat(data, compareId)
      .filter(item => !!item)
      .forEach((item, index, arr) => {
        if (index !== arr.indexOf(item)) {
          intersection = true;
        }
      });

    return intersection;
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      !Object.keys(this.props.reportStatusData || {}).length &&
      Object.keys(nextProps.reportStatusData || {}).length
    ) {
      // eslint-disable-next-line no-unused-vars
      let signedReport = nextProps.reportStatusData.reportVersions
        .filter(report => report.versionStatus === 'signed')
        .pop();
    }

    if (
      !Object.keys(this.props.reportVersionData || {}).length &&
      Object.keys(nextProps.reportVersionData || {}).length
    ) {
    }

    let details =
      ((nextProps || {}).review || {}).fetchedFollowupRecommendations || null;
    let allDetails =
      ((nextProps || {}).review || {}).fetchedFindingDetails || null;
    details = (details || {}).FollowInfo || {};
    if (!this.detailsLoaded /* && details */ && allDetails) {
      allDetails.findingsList = this.adjustFindingDetails(
        allDetails.findingsList || allDetails.findings,
        (this.props.review || {}).selectedReview || {}
      );
      this.detailsLoaded = true;

      let formData = this.state.formData;
      formData.ncCount =
        ['string', 'number'].indexOf(typeof details.ncCount) !== -1
          ? details.ncCount
          : formData.ncCount;
      formData.defCount =
        ['string', 'number'].indexOf(typeof details.defCount) !== -1
          ? details.defCount
          : formData.defCount;
      formData.furRecommendations = details.furRecommendations || [];

      formData.ncCount = allDetails.findingsList.filter(citation =>
        (citation.findingStatus + '').match(/^ANC$/i)
      ).length;
      formData.defCount = allDetails.findingsList.filter(citation =>
        (citation.findingStatus + '').match(/^DEF$/i)
      ).length;

      let citationId = this.props.params.citationId;

      let allDetailsMapped = (allDetails.findingsList || [])
        // .filter(citation => !(citation.type + '').match(/area\s+of\s+concern/i))
        .map(citation => {
          return {
            associatedCitations: citation.citation,
            citationDueDate:
              citation.correctiveActionDueDate || new Date().toISOString(),
            citationLanguage: '',
            citationNarrative: citation.narrative || '',
            supportCorrectionText: '',
            verifyCorrectionText: '',
            capDays: citation.correctiveActionTimeFrame || 0,
            citationStartDate: new Date().toISOString(),
          };
        });

      if (citationId) {
        let finding = allDetails.findingsList.filter(item =>
          this.associatedCitationId(item.citation, citationId)
        )[0];
        formData.defCapDays =
          parseInt(
            ((finding.correctiveActionTimeFrame || '') + '').replace(
              /^\s+/,
              ''
            ) + '',
            10
          ) || 0;

        if (
          formData.furRecommendations.filter(citation =>
            this.associatedCitationId(citation.associatedCitations, citationId)
          ).length === 0
        ) {
          formData.furRecommendations.push({
            associatedCitations: citationId,
            citationDueDate: new Date(
              (
                allDetailsMapped.filter(citation =>
                  this.associatedCitationId(
                    citation.associatedCitations,
                    citationId
                  )
                )[0] || {}
              ).citationDueDate || new Date().valueOf()
            ).toISOString(),
            citationLanguage: '',
            citationNarrative:
              (
                allDetailsMapped.filter(citation =>
                  this.associatedCitationId(
                    citation.associatedCitations,
                    citationId
                  )
                )[0] || {}
              ).citationNarrative || '',
            supportCorrectionText: '',
            verifyCorrectionText: '',
            capDays:
              (
                allDetailsMapped.filter(citation =>
                  this.associatedCitationId(
                    citation.associatedCitations,
                    citationId
                  )
                )[0] || {}
              ).capDays || 0,
            citationStartDate: new Date().toISOString(),
          });
        }
      }

      allDetailsMapped.forEach(citation => {
        if (
          formData.furRecommendations.filter(item =>
            this.associatedCitationId(
              item.associatedCitations,
              this.associatedCitationId(citation.associatedCitations)
            )
          ).length === 0
        ) {
          formData.furRecommendations.push({
            associatedCitations: citation.associatedCitations,
            citationDueDate:
              citation.citationDueDate || new Date().toISOString(),
            citationLanguage: '',
            citationNarrative: citation.citationNarrative || '',
            supportCorrectionText: '',
            verifyCorrectionText: '',
            capDays: citation.capDays || 0,
            citationStartDate: new Date().toISOString(),
          });
        }
      });

      let citationOrder = allDetailsMapped.map(
        citation => citation.associatedCitations
      );

      formData.furRecommendations = formData.furRecommendations.sort((a, b) => {
        let va = citationOrder.indexOf(
          this.associatedCitationId(a.associatedCitations, true)
        );
        let vb = citationOrder.indexOf(
          this.associatedCitationId(b.associatedCitations, true)
        );

        return va - vb;
      });

      if (citationId) {
        formData.furRecommendations.forEach((citation, index) => {
          if (
            this.associatedCitationId(citation.associatedCitations, citationId)
          ) {
            formData.furRecommendationIndex = index;
          }
        });
      }

      this.setState({ formData: formData });
    }
  }

  currentCitation(prop) {
    let formData = this.state.formData;
    let citation = null;
    let citationId = this.associatedCitationId(
      (formData.furRecommendations[formData.furRecommendationIndex] || {})
        .associatedCitations || ''
    );

    if (citationId !== undefined) {
      let allDetails =
        ((this.props || {}).review || {}).fetchedFindingDetails || null;
      if (allDetails) {
        allDetails.findingsList = this.adjustFindingDetails(
          allDetails.findingsList || allDetails.findings,
          (this.props.review || {}).selectedReview || {}
        );

        citation =
          allDetails.findingsList
            // .filter(citation => !(citation.type + '').match(/area\s+of\s+concern/i))
            .filter(citation =>
              this.associatedCitationId(citation.citation, citationId)
            )[0] || null;
      }
    }

    if (prop) {
      return (citation || {})[prop];
    }

    return citation;
  }

  radioSelectionHandler(selection) {
    this.setState({
      followUpReviewer: selection,
    });
  }

  specialistLookup = param => {
    let specialist = null;
    let specialistIndex = this.props.users.findIndex(function(user) {
      return user.oid === param;
    });
    if (specialistIndex === -1) {
      specialist = param;
    } else {
      specialist = this.props.users[specialistIndex].name;
    }
    return specialist;
  };

  render() {
    let details =
      (this.props.review || {}).fetchedFollowupRecommendations || null;
    let selectedReview = (this.props.review || {}).selectedReview || {};
    let reviewType =
      ((this.props.review || {}).selectedReview || {}).reviewType || '';

    let currentUser = this.props.currentUser;

    if (!currentUser) {
      return <div className="well">Loading user info</div>;
    } else {
      let roles = currentUser.roles || [];
      if (
        roles.indexOf('Admin') === -1 &&
        roles.indexOf('AmsAdmi') === -1 &&
        roles.indexOf('FiscalSpecialist') === -1 &&
        roles.indexOf('RegionalProgramManager') === -1
      ) {
        return (
          <div className="well">
            <div className="alert alert-danger">
              Sorry, You are not authorized to view this page
            </div>
          </div>
        );
      }
    }

    if (
      reviewType &&
      !this.findingDetailsRequested &&
      !isEmpty(this.props.params.id)
    ) {
      this.findingDetailsRequested = true;
      this.props.fetchFindingDetails(
        {
          reviewId: this.props.params.id,
        },
        !this.props.review.selectedReview.newFAFlowFlag,
        { reviewType: reviewType }
      );
    }

    let daysLeft = Math.max(
      0,
      ((this.props.review || {}).fetchedFindingDetails || {}).daysToComplete ||
        0
    );
    if (daysLeft === 0) {
      daysLeft = 'past due';
    }

    if (!details) return <div className="well">Loading data...</div>;

    if (!this.state.formData.furRecommendations.length)
      return <div className="well">Loading Recommendations...</div>;

    let dlhFields = (
      <span>
        <span>
          2. Who is the point of contact from this region assigned to the follow
          up review
        </span>
        <br />
        <div className="row row-with-padding">
          <div className="col-md-2 ps-col">
            <span className="ps">
              Program specialist&nbsp;<span className="ast">*</span>
            </span>
          </div>
          <div className="col-md-3">
            <FieldGroupSelect
              closeOnSelect={true}
              value={this.state.dlh.ProgramSpecialist || null}
              multi={false}
              matchProp={'label'}
              options={[
                {
                  label: 'Other',
                  value: null,
                },
              ].concat(
                (
                  this.props.review
                    .reviewRpmFeedbackProgramSpecialistFetchedList || []
                ).map(item => {
                  return {
                    label: item.name,
                    value: item.oid,
                  };
                })
              )}
              label=""
              onChange={ev => {
                let dlh = { ...this.state.dlh };
                dlh.ProgramSpecialist = ev.value;
                dlh.ProgramSpecialistOther = dlh.ProgramSpecialist
                  ? ''
                  : dlh.ProgramSpecialist;
                this.setState({
                  dlh: dlh,
                });
              }}
              placeholder="Select"
              searchable={false}
              clearable={false}
            />
          </div>
          <div className="col-md-2 pad-top-8">Other then please specify</div>
          <div className="col-md-3">
            <input
              type="text"
              disabled={!!this.state.dlh.ProgramSpecialist}
              className="col-sm-12 form-control"
              value={this.state.dlh.ProgramSpecialistOther || ''}
              onChange={ev => {
                let dlh = { ...this.state.dlh };
                dlh.ProgramSpecialistOther = ev.target.value;
                this.setState({
                  dlh: dlh,
                });
              }}
            />
          </div>
        </div>
        {selectedReview.reviewStatus === 'Follow Up Recs Complete' ? (
          ''
        ) : (
          <button
            className={['btn', 'btn-primary', 'btn-sm', 'rpm-save'].join(' ')}
            onClick={() => {
              let errorMessages = [];
              if (!errorMessages.length) {
                const dlh = {
                  reviewId: ((this.props.review || {}).selectedReview || {})
                    .reviewId,
                  RPMFeedback: {
                    followUpReviewer: this.state.followUpReviewer,
                    pocProgramSpecialist:
                      this.state.dlh.ProgramSpecialist ||
                      this.state.dlh.ProgramSpecialistOther,
                    submittedBy: this.props.currentUser.oid,
                  },
                };

                this.props.saveReviewRpmFeedback(dlh, response => {
                  if (response && response instanceof Error) {
                    alert('Error: ' + response.message);
                  } else if (response && response.data) {
                    if (response.data.outcome === 'Success') {
                      this.setState({
                        ...this.state,
                        rpmSaveToggle: true,
                      });
                      alert('RPM Feedback Saved');
                    } else {
                      alert('Error: unknown error');
                    }
                  } else {
                    alert('Warning: unknown save status');
                  }
                });
              }
              this.setState({
                errorMessages: errorMessages.filter(
                  (message, index, messages) =>
                    messages.indexOf(message) === index
                ),
              });
            }}
            disabled={this.state.rpmSaveToggle}
            style={
              (
                ((this.props.review || {}).fetchedFollowupRecommendations || {})
                  .FollowInfo || {}
              ).status === 'rpmCommented' ||
              (
                ((this.props.review || {}).fetchedFollowupRecommendations || {})
                  .FollowInfo || {}
              ).status === 'Finalized'
                ? { display: 'none' }
                : { display: 'block', margin: 'auto' }
            }
          >
            Save
          </button>
        )}
      </span>
    );

    let roFields = (
      <span>
        <div className="row row-with-padding">
          <div className="col-md-2">
            <span className="ps">
              Program specialist<span className="ast">*</span>
            </span>
          </div>
          <div className="col-md-3">
            {this.props.review.fetchedFollowupRecommendations.FollowInfo
              .status === 'rpmCommented' ||
            this.props.fetchedFollowupRecommendations.FollowInfo.status ===
              'rpmCommented' ? (
              <div className="col-sm-6">
                <i>MAP in saved feedback</i>
              </div>
            ) : (
              <FieldGroupSelect
                closeOnSelect={true}
                value={this.state.ro.ProgramSpecialist || null}
                multi={false}
                matchProp={'label'}
                options={[
                  {
                    label: 'Other',
                    value: null,
                  },
                ].concat(
                  (
                    this.props.review
                      .reviewRpmFeedbackProgramSpecialistFetchedList || []
                  ).map(item => {
                    return {
                      label: item.name,
                      value: item.oid,
                    };
                  })
                )}
                label=""
                onChange={ev => {
                  let ro = { ...this.state.ro };
                  ro.ProgramSpecialist = ev.value;
                  this.setState({
                    ro: ro,
                  });
                }}
                placeholder="Select"
                searchable={false}
                clearable={false}
              />
            )}
          </div>
          <div className="col-md-2">If Other then please specify</div>
          <div className="col-md-3">
            {this.props.review.fetchedFollowupRecommendations.FollowInfo
              .status === 'rpmCommented' ||
            this.props.fetchedFollowupRecommendations.FollowInfo.status ===
              'rpmCommented' ? (
              ''
            ) : (
              <input
                type="text"
                disabled={!!this.state.ro.ProgramSpecialist}
                className="col-sm-12 form-control"
                value={this.state.ro.ProgramSpecialistOther || ''}
                onChange={ev => {
                  let ro = { ...this.state.ro };
                  ro.ProgramSpecialistOther = ev.target.value;
                  this.setState({
                    ro: ro,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="row row-with-padding no-top-padding">
          <div className="col-md-2">
            <span className="ps">Grantee specialist: (If Applicable)</span>
          </div>
          <div className="col-md-3">
            {this.props.review.fetchedFollowupRecommendations.FollowInfo
              .status === 'rpmCommented' ||
            this.props.fetchedFollowupRecommendations.FollowInfo.status ===
              'rpmCommented' ? (
              <div className="col-sm-6">
                <i />
              </div>
            ) : (
              <span>
                {this.state.regUsersRequested &&
                this.props.regionalUsers &&
                this.props.regionalUsers.length === 0 ? (
                  'Not applicable'
                ) : (
                  <FieldGroupSelect
                    closeOnSelect={true}
                    value={this.state.ro.grantSpecialist || null}
                    multi={false}
                    matchProp={'label'}
                    options={(this.props.regionalUsers || []).map(item => {
                      return {
                        label: item.name,
                        value: item.oid,
                      };
                    })}
                    label=""
                    onChange={ev => {
                      console.log(ev.value);
                      let ro = { ...this.state.ro };
                      ro.grantSpecialist = ev.value;
                      this.setState({
                        ro: ro,
                      });
                    }}
                    placeholder="Select"
                    searchable={false}
                    clearable={false}
                  />
                )}
              </span>
            )}
          </div>
        </div>

        <span>
          2. Who is the point of contact from this region assigned to the follow
          up review
        </span>
        <br />
        <div className="row row-with-padding">
          <div className="col-md-2">
            <span className="ps">
              Program specialist<span className="ast">*</span>
            </span>
          </div>
          <div className="col-md-3">
            {this.props.review.fetchedFollowupRecommendations.FollowInfo
              .status === 'rpmCommented' ||
            this.props.fetchedFollowupRecommendations.FollowInfo.status ===
              'rpmCommented' ? (
              <div className="col-sm-6">
                <i />
              </div>
            ) : (
              <FieldGroupSelect
                closeOnSelect={true}
                value={this.state.ro.pocProgramSpecialist || null}
                multi={false}
                matchProp={'label'}
                options={[
                  {
                    label: 'Other',
                    value: null,
                  },
                ].concat(
                  (
                    this.props.review
                      .reviewRpmFeedbackProgramSpecialistFetchedList || []
                  ).map(item => {
                    return {
                      label: item.name,
                      value: item.oid,
                    };
                  })
                )}
                label=""
                onChange={ev => {
                  let ro = { ...this.state.ro };
                  ro.pocProgramSpecialist = ev.value;
                  ro.pocProgramSpecialistOther = ro.ProgramSpecialist
                    ? ''
                    : ro.ProgramSpecialist;
                  this.setState({
                    ro: ro,
                  });
                }}
                placeholder="Select"
                searchable={false}
                clearable={false}
              />
            )}
          </div>
          <div className="col-md-2">If Other then please specify</div>
          <div className="col-md-3">
            {this.props.review.fetchedFollowupRecommendations.FollowInfo
              .status === 'rpmCommented' ||
            this.props.fetchedFollowupRecommendations.FollowInfo.status ===
              'rpmCommented' ? (
              ''
            ) : (
              <input
                type="text"
                disabled={!!this.state.ro.pocProgramSpecialist}
                className="col-sm-12 form-control"
                value={this.state.ro.pocProgramSpecialistOther || ''}
                onChange={ev => {
                  let ro = { ...this.state.ro };
                  ro.pocProgramSpecialistOther = ev.target.value;
                  this.setState({
                    ro: ro,
                  });
                }}
              />
            )}
          </div>
        </div>
        {selectedReview.reviewStatus === 'Follow Up Recs Complete' ? (
          ''
        ) : (
          <button
            className={['btn', 'btn-primary', 'btn-sm', 'rpm-save'].join(' ')}
            onClick={() => {
              let errorMessages = [];
              let rpmFeedback;
              if (!errorMessages.length) {
                rpmFeedback = {
                  reviewId: ((this.props.review || {}).selectedReview || {})
                    .reviewId,
                  RPMFeedback: {
                    followUpReviewer: this.state.followUpReviewer,
                    programSpecialist:
                      this.state.ro.ProgramSpecialist ||
                      this.state.ro.ProgramSpecialistOther,
                    pocProgramSpecialist:
                      this.state.ro.pocProgramSpecialist ||
                      this.state.ro.pocProgramSpecialistOther,
                    grantSpecialist: this.state.ro.grantSpecialist || null,
                    submittedBy: this.props.currentUser.oid,
                  },
                };
              }

              this.props.saveReviewRpmFeedback(rpmFeedback, response => {
                if (response && response instanceof Error) {
                  alert('Error: ' + response.message);
                } else if (response && response.data) {
                  if (response.data.outcome === 'Success') {
                    this.setState({
                      ...this.state,
                      rpmSaveToggle: true,
                    });
                    alert('RPM Feedback Saved');
                  } else {
                    alert('Error: unknown error');
                  }
                } else {
                  alert('Warning: unknown save status');
                }
              });

              this.setState({
                errorMessages: errorMessages.filter(
                  (message, index, messages) =>
                    messages.indexOf(message) === index
                ),
              });
            }}
            disabled={this.state.rpmSaveToggle}
            style={
              (
                ((this.props.review || {}).fetchedFollowupRecommendations || {})
                  .FollowInfo || {}
              ).status === 'rpmCommented' ||
              (
                ((this.props.review || {}).fetchedFollowupRecommendations || {})
                  .FollowInfo || {}
              ).status === 'Finalized'
                ? { display: 'none' }
                : { display: 'block', margin: 'auto' }
            }
          >
            Save
          </button>
        )}
      </span>
    );

    let roData = null;

    if (
      (
        (
          (this.props.review || {}).fetchedFollowupRecommendations.FollowInfo ||
          {}
        ).RPMFeedbackInfo || {}
      ).followUpReviewer === 'RO'
    ) {
      roData = (
        <span>
          <p>
            Program Specialist is{' '}
            {this.specialistLookup(
              (
                (
                  (this.props.review || {}).fetchedFollowupRecommendations
                    .FollowInfo || {}
                ).RPMFeedbackInfo || {}
              ).programSpecialist
            )}
          </p>
          <p style={{ marginBottom: '1em' }}>
            Grantee Specialist is{' '}
            {this.specialistLookup(
              (
                (
                  (this.props.review || {}).fetchedFollowupRecommendations
                    .FollowInfo || {}
                ).RPMFeedbackInfo || {}
              ).grantSpecialist || 'not applicable'
            )}
          </p>
        </span>
      );
    }

    let grantees = (
      ((this.props.review || {}).selectedReview || {}).grantees || []
    ).map(grantee => {
      return ` ${grantee.granteeName} (${grantee.granteeId})`;
    });

    return (
      <SlidingContainer
        calendar
        title={
          grantees + '| ' + reviewType + ' ( ' + this.props.params.id + ' ) '
        }
      >
        <div className="well">
          <div className="row">
            <div className="col-sm-12">
              <p>
                <b>
                  Review status:{' '}
                  {
                    ((this.props.review || {}).selectedReview || {})
                      .reviewStatus
                  }
                </b>
              </p>
              <p>
                <b>
                  Date report received by Grantee:{' '}
                  {((this.props.review || {}).selectedReview || {})
                    .reportReceivedDate ? (
                    AmsDateFormatters.formatDate(
                      ((this.props.review || {}).selectedReview || {})
                        .reportReceivedDate
                    )
                  ) : (
                    <i>no date recieved from backend</i>
                  )}
                </b>
              </p>
              <p>
                <b>Days till due date: {daysLeft}</b>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">&nbsp;</div>
          </div>
          <div className={'row readonly-citattion-form'}>
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">Non-Compliance</label>

                <div className="row">
                  <div className="col-sm-6">
                    <p>Number of Non-Compliance(S):</p>
                  </div>
                  <div className="col-sm-6">
                    <p>{this.state.formData.ncCount}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <p>Corrective Action Period Days:</p>
                  </div>
                  <div className="col-sm-6">
                    {(
                      [...Array(367).keys()]
                        .map(v => {
                          return {
                            value: v,
                            label: v || '-',
                          };
                        })
                        .filter(item => {
                          let v = (
                            this.currentCitation('findingStatus') + ''
                          ).match(/^DEF$/i)
                            ? 0
                            : (
                                this.state.formData.furRecommendations[
                                  this.state.formData.furRecommendationIndex
                                ] || {}
                              ).correctiveActionTimeFrame ||
                              parseInt(
                                this.currentCitation(
                                  'correctiveActionTimeFrame'
                                ),
                                10
                              ) ||
                              0;
                          return item.value === v;
                        })[0] || {}
                    ).label || ''}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">Deficiency</label>

                <div className="row">
                  <div className="col-sm-6">
                    <p>Number of Deficiency(s):</p>
                  </div>
                  <div className="col-sm-6">
                    <p>{this.state.formData.defCount}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <p>Corrective Action Period Days:</p>
                  </div>
                  <div className="col-sm-6">
                    {(
                      [...Array(367).keys()]
                        .map(v => {
                          return {
                            value: v,
                            label: v || '-',
                          };
                        })
                        .filter(item => {
                          let v = (
                            this.currentCitation('findingStatus') + ''
                          ).match(/^ANC$/i)
                            ? 0
                            : (
                                this.state.formData.furRecommendations[
                                  this.state.formData.furRecommendationIndex
                                ] || {}
                              ).correctiveActionTimeFrame ||
                              parseInt(
                                this.currentCitation(
                                  'correctiveActionTimeFrame'
                                ),
                                10
                              ) ||
                              0;
                          return item.value === v;
                        })[0] || {}
                    ).label || ''}

                    {(() => {
                      if (
                        !(
                          this.currentCitation('correctiveActionTimeFrame') + ''
                        ).match(/\d+/i)
                      ) {
                        return (
                          <p>
                            <i>
                              {this.currentCitation(
                                'correctiveActionTimeFrame'
                              ) || ''}
                            </i>
                          </p>
                        );
                      }
                      return '';
                    }).call(this)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'row readonly-citattion-form'}>
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">
                  Follow-up Review data
                </label>

                <div className="row">
                  <div className="col-sm-6">
                    <p>Recommended Review Type:</p>
                  </div>
                  <div className="col-sm-6">
                    {(
                      [
                        'Desk/FTL Solo',
                        'Desk Review',
                        'FTL Solo',
                        'OnSite Follow-up',
                      ]
                        .map(v => {
                          return {
                            value: v,
                            label: v,
                          };
                        })
                        .filter(item => {
                          let v = (
                            this.state.formData.furRecommendations[
                              this.state.formData.furRecommendationIndex
                            ] || {}
                          ).furType;
                          return item.value === v;
                        })[0] || {}
                    ).label || ''}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <p>Follow-up Lead:</p>
                  </div>
                  <div className="col-sm-6">
                    {(
                      (() => {
                        let users = this.props.users;
                        if (Array.isArray(users) && users.length) {
                          return users
                            .filter(
                              user =>
                                (user.roles || []).indexOf('FollowupLead') !==
                                -1
                            )
                            .map(user => {
                              return {
                                value: user.oid,
                                label:
                                  user.name ||
                                  [user.firstName, user.lastName]
                                    .filter(v => !!v)
                                    .join(' ') ||
                                  user.oid,
                              };
                            });
                        }
                        return [];
                      })().filter(item => {
                        let v = (
                          this.state.formData.furRecommendations[
                            this.state.formData.furRecommendationIndex
                          ] || {}
                        ).furLead;
                        return item.value === v;
                      })[0] || {}
                    ).label || ''}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <p>Proposed Start Date of Review:</p>
                  </div>
                  <div className="col-sm-6">
                    {AmsDateFormatters.formatDate(
                      (
                        this.state.formData.furRecommendations[
                          this.state.formData.furRecommendationIndex
                        ] || {}
                      ).furStartDate
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">
                  Overall Follow-up Recommendation
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    {(
                      (
                        (this.props.review || {})
                          .fetchedFollowupRecommendations || {}
                      ).FollowInfo || {}
                    ).overallRecommendations || ''}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 label-block">
              <div>
                <label className="label label-default">Citations</label>
              </div>
            </div>
          </div>

          <div className="row progress-bar-blocks">
            {(stepsNum => {
              let steps = [];
              let showAddButton = 0;
              let width =
                Math.floor(100 / (stepsNum + showAddButton)).toFixed(1) + '%';
              if (showAddButton)
                steps.push(
                  <div
                    onClick={ev => {
                      let formData = this.state.formData;
                      formData.furRecommendations.push({
                        associatedCitations: new Date().valueOf().toString(36), // Associated Citation
                        citationDueDate: new Date().toISOString(), // due date
                        citationLanguage: '', // citation language
                        citationNarrative: '', // citation narrative
                        supportCorrectionText: '', // supported correction text
                        verifyCorrectionText: '', // verify correction text
                      });
                      formData.furRecommendationIndex =
                        formData.furRecommendations.length - 1;
                      this.setState({
                        formData: formData,
                      });
                    }}
                    key={0}
                    style={{ width: width }}
                  >
                    <u>Add Recommendation</u>
                  </div>
                );
              for (let i = 0; i < stepsNum; i++) {
                steps.push(
                  <div
                    style={{ width: width }}
                    className={
                      this.state.formData.furRecommendationIndex === i
                        ? 'selected'
                        : ''
                    }
                    key={i + 1}
                    onClick={(index => {
                      return () => {
                        let formData = this.state.formData;
                        let citation = formData.furRecommendations[index];
                        let allDetails =
                          (this.props.review || {}).fetchedFindingDetails ||
                          null;
                        let finding = allDetails.findingsList
                          // .filter(citation => !(citation.findingStatus + '').match(/area\s+of\s+concern/i))
                          .filter(item =>
                            this.associatedCitationId(
                              item.citation,
                              this.associatedCitationId(
                                citation.associatedCitations
                              )
                            )
                          )[0];
                        if (!finding) {
                          alert(
                            'Info » WILL BE REMOVED. incorrectly created item from previous logic'
                          );
                          formData.furRecommendations.splice(index, 1);
                          formData.furRecommendationIndex = Math.max(
                            0,
                            Math.min(
                              formData.furRecommendationIndex,
                              formData.furRecommendations.length - 1
                            )
                          );
                          this.setState({
                            formData: formData,
                          });
                          return;
                        }
                        formData.defCapDays =
                          parseInt(
                            (
                              (finding.correctiveActionTimeFrame || '0') + ''
                            ).replace(/^\s+/, '') + '',
                            10
                          ) || 0;
                        formData.furRecommendationIndex = index;
                        this.setState({
                          formData: formData,
                          furRecommendationIndex: index,
                        });
                      };
                    })(i + 0)}
                  >
                    {(Array.isArray(
                      this.state.formData.furRecommendations[i]
                        .associatedCitations
                    )
                      ? this.state.formData.furRecommendations[i]
                          .associatedCitations
                      : [
                          this.state.formData.furRecommendations[i]
                            .associatedCitations,
                        ]
                    ).map((item, index) => {
                      return (
                        <b key={index}>
                          {' '}
                          {typeof item === 'string'
                            ? item
                            : item.associatedCitation}{' '}
                        </b>
                      );
                    })}{' '}
                    ({i + 1} of {stepsNum})
                  </div>
                );
              }
              return steps;
            })(this.state.formData.furRecommendations.length)}
          </div>

          {this.state.formData.furRecommendations[
            this.state.formData.furRecommendationIndex
          ] ? (
            <div className={'row readonly-citattion-form'}>
              <div className="col-sm-12 label-block">
                <div>
                  <p>
                    <b>
                      Citation due date:{' '}
                      {AmsDateFormatters.formatDate(
                        this.state.formData.furRecommendations[
                          this.state.formData.furRecommendationIndex
                        ].citationDueDate
                      )}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.formData.furRecommendations[
            this.state.formData.furRecommendationIndex
          ] ? (
            <div className={'row readonly-citattion-form'}>
              <div className="col-sm-6 label-block">
                <div>
                  <label className="label label-default">
                    Citation Language
                  </label>
                  <p>
                    {
                      this.state.formData.furRecommendations[
                        this.state.formData.furRecommendationIndex
                      ].citationLanguage
                    }
                  </p>
                </div>
              </div>
              <div className="col-sm-6 label-block">
                <div>
                  <label className="label label-default">
                    Citation Narrative
                  </label>
                  <p>
                    {
                      this.state.formData.furRecommendations[
                        this.state.formData.furRecommendationIndex
                      ].citationNarrative
                    }
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.formData.furRecommendations[
            this.state.formData.furRecommendationIndex
          ] ? (
            <div className={'row readonly-citattion-form'}>
              <div className="col-sm-6 label-block">
                <div>
                  <label className="label label-default">
                    Activities to support Correction
                  </label>
                  <p>
                    {
                      this.state.formData.furRecommendations[
                        this.state.formData.furRecommendationIndex
                      ].supportCorrectionText
                    }
                  </p>
                </div>
              </div>
              <div className="col-sm-6 label-block">
                <div>
                  <label className="label label-default">
                    Activities to verify Correction
                  </label>
                  <p>
                    {
                      this.state.formData.furRecommendations[
                        this.state.formData.furRecommendationIndex
                      ].verifyCorrectionText
                    }
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h2 className="rpm-input">RPM INPUT</h2>
            <hr className="rpm-hr" />
          </div>
        </div>
        <span className="rpm-review-info">
          <p>
            Review ID:{' '}
            {((this.props.review || {}).selectedReview || {}).reviewId}
          </p>
          <p>Total number of ANC Findings: {this.state.formData.ncCount}</p>
          <p>Total number of DEF Findings: {this.state.formData.defCount}</p>
        </span>
        <br />
        {this.props.review.fetchedFollowupRecommendations.FollowInfo.status ===
          'rpmCommented' ||
        this.props.review.fetchedFollowupRecommendations.FollowInfo.status ===
          'Finalized' ||
        this.props.fetchedFollowupRecommendations.FollowInfo.status ===
          'rpmCommented' ||
        this.props.fetchedFollowupRecommendations.FollowInfo.status ===
          'Finalized' ? (
          <span className="rpm-review-info">
            <p>
              Follow-up review for these findings is conducted by{' '}
              {
                (
                  (
                    (this.props.review || {}).fetchedFollowupRecommendations
                      .FollowInfo || {}
                  ).RPMFeedbackInfo || {}
                ).followUpReviewer
              }
            </p>
            {roData}
            <p>
              Point of contact Program Specialist is{' '}
              {this.specialistLookup(
                (
                  (
                    (this.props.review || {}).fetchedFollowupRecommendations
                      .FollowInfo || {}
                  ).RPMFeedbackInfo || {}
                ).pocProgramSpecialist
              )}
            </p>
          </span>
        ) : (
          <span>
            <span>
              1. Who is going to conduct Follow-up review for these findings?
            </span>
            <br />
            <div className="rpm-fur">
              <input
                type="radio"
                value="DLH"
                name="FUR"
                onChange={() => {
                  this.radioSelectionHandler('DLH');
                }}
              />
              &nbsp;<label htmlFor="DLH">DLH Follow-up Lead</label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                value="RO"
                name="FUR"
                onChange={() => {
                  this.radioSelectionHandler('RO');
                }}
              />
              &nbsp;<label htmlFor="RO">Regional Office</label>
              <br />
            </div>
            {this.state.followUpReviewer === 'DLH' ? dlhFields : null}
            {this.state.followUpReviewer === 'RO' ? roFields : null}
          </span>
        )}
      </SlidingContainer>
    );
  }
}

const mapStateToProps = state => ({
  review: state.review,
  users: state.users.users,
  regionalUsers: state.users.regionalUsers,
  savedFollowupRecommendations: state.report.savedFollowupRecommendations,
  reportVersionData: state.report.reportVersionData,
  reportStatusData: state.report.reportStatusData,
  fetchedFollowupRecommendations: state.review.fetchedFollowupRecommendations,
});

export default connect(mapStateToProps, {
  fetchUsers,
  fetchReviewDetail,
  fetchFollowupRecommendations,
  fetchFindingDetails,
  fetchReportVersionData,
  fetchReportStatusData,
  fetchEmailNotifyMpsRequest,
  saveReviewRpmFeedback,
  reviewRpmFeedbackProgramSpecialist,
  reviewRpmFeedbackFiscalSpecialist,
  fetchRegionalUsers,
})(ReviewFindingsPage);
