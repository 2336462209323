import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';

// Import actions.
import {
  fetchPresiteFormDetail,
  performanceMeasureSelected,
} from '../../../actions/presiteActions';

import { fetchReviewDetail } from '../../../actions/reviewActions';

// Import components
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import PresiteFormContainer from './PresiteFormContainer';
import PresitePerformanceMeasures from './PresitePerformanceMeasures';

class PresitePage extends Component {
  componentDidMount() {
    const reviewId = this.props.router.params.reviewId;
    if (!reviewId) return;
    this.props.fetchReviewDetail({ reviewId: reviewId });
    this.props.fetchPresiteFormDetail({ reviewId }).then(() => {
      // Select the first performance measure at first load.
      this.props.performanceMeasureSelected();
    });
  }

  render = () => {
    return (
      <SlidingContainer
        granteeStatusBanner={true}
        granteeStatusData={{
          id: this.props.router.params.reviewId,
          type: 'review',
        }}
        calendar={false}
        title={
          'Pre-Review Survey for Review ID ' + this.props.router.params.reviewId
        }
      >
        <Grid stackable>
          {/* <Grid.Row>
            <Grid.Column width={16}>
              <PresiteGrantInfo />
            </Grid.Column>
          </Grid.Row> */}

          <Grid.Row>
            <Grid.Column width={3}>
              <PresitePerformanceMeasures />
            </Grid.Column>
            <Grid.Column stretched width={13}>
              <PresiteFormContainer />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SlidingContainer>
    );
  };
}

PresitePage.contextTypes = {
  router: PropTypes.object.isRequired,
};

PresitePage.propTypes = {
  fetchPresiteFormDetail: PropTypes.func.isRequired,
};

export default connect(null, {
  fetchPresiteFormDetail,
  performanceMeasureSelected,
  fetchReviewDetail,
})(PresitePage);
