import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Header, Icon, Segment } from 'semantic-ui-react';

//Import Components
import ScheduleViewGranteeDatesTable from './ScheduleViewGranteeDatesTable';

class ScheduleViewGranteeAccordion extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === titleProps.index ? -1 : titleProps.index;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { unavailableDates, sessionDates, breakDates } = this.props;
    const { activeIndex } = this.state;
    return (
      <Segment>
        <Header as="h5">Below is the list of currently selected dates.</Header>
        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 0}
            className="accordion-title-override"
            onClick={this.handleClick}
            index={0}
          >
            <Icon name="dropdown" />
            Unavailable dates selected
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <ScheduleViewGranteeDatesTable dates={unavailableDates} />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 1}
            className="accordion-title-override"
            onClick={this.handleClick}
            index={1}
          >
            <Icon name="dropdown" />
            Not in session dates selected
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <ScheduleViewGranteeDatesTable dates={sessionDates} />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 2}
            className="accordion-title-override"
            onClick={this.handleClick}
            index={2}
          >
            <Icon name="dropdown" />
            Spring Break dates selected
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <ScheduleViewGranteeDatesTable dates={breakDates} />
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ScheduleViewGranteeAccordion);
