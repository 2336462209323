import axios from 'axios';

import { FETCH_ME } from '../redux/types';

export const meFetched = () => ({
  type: FETCH_ME,
});

export const me = () => async dispatch =>
  await axios.get(`/`).then(() => dispatch(meFetched));
