import axios from 'axios';

import {
  LOOKUPS_FETCHED,
  CATEGORY_LOOKUPS_FETCHED,
  LOOKUP_ADDED,
  LOOKUP_UPADATED,
  AMS_LOOKUP_FETCHED,
} from '../redux/types';

export const lookupsFetched = lookups => ({
  type: LOOKUPS_FETCHED,
  lookups,
});

export const categoryLookupsFetched = lookups => ({
  type: CATEGORY_LOOKUPS_FETCHED,
  lookups,
});

export const amsLookupFetched = (category, lookups) => ({
  type: AMS_LOOKUP_FETCHED,
  lookups,
  category,
});

export const lookupAdded = lookup => ({
  type: LOOKUP_ADDED,
  lookup,
});

export const lookupUpdated = lookup => ({
  type: LOOKUP_UPADATED,
  lookup,
});

export function getLookups(lookups) {
  return {
    type: LOOKUPS_FETCHED,
    lookups,
  };
}

export function fetchLookups() {
  return dispatch => {
    axios.post(`/lookups`).then(response => {
      // dispatch(getLookups(response.data));
    });
  };
}

export const fetchAllLookups = () => dispatch =>
  axios
    .post(`/lookups/all`)
    .then(response => dispatch(lookupsFetched(response.data)));

export const fetchLookup = category => dispatch =>
  axios
    .post(`/lookups/get/${category}`)
    .then(response => dispatch(categoryLookupsFetched(response.data)));

export const addLookup = data => dispatch =>
  axios
    .post(`/lookups/add`, data)
    .then(response => dispatch(lookupAdded(response.data)));

export const updateLookup = (id, data) => dispatch =>
  axios
    .patch(`/lookups/update/${id}`, data)
    .then(response => dispatch(lookupUpdated(response.data)));

export const fetchAmsLookup = category => dispatch =>
  axios
    .post(`/lookups/get/${category}`)
    .then(response => dispatch(amsLookupFetched(category, response.data)));
