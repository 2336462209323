import FocusTrap from 'focus-trap-react/dist/focus-trap-react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Header,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
} from 'semantic-ui-react';

//Import components
import NoteForm from './NoteForm';
import NotesList from './NotesList';

//import actions
import { addNote } from '../../actions/noteActions';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

import './assets/style.css';

const NotesContainer = ({
  tags,
  defaultFilter,
  filterOptions,
  list,
  enabled,
  type,
  children,
  title,
  currentUser,
  reloadData,
  addNote,
  reloadDataOnClick,
  isPrimary,
  defaultOpen = false,
}) => {
  const initialVisibility = defaultOpen;

  const [visible, setVisibility] = useState(defaultOpen);
  const [ref, setRef] = useState(null);

  const toggleVisibility = e => {
    e.preventDefault();
    setVisibility(!visible);
    reloadDataOnClick && reloadData();
  };

  const handleContextRef = contextRef => {
    setRef(contextRef);
  };

  const submitNote = content => {
    const data = {
      note: content,
      createdAt: AmsDateFormatters.getMoment().utc(),
      updateAt: AmsDateFormatters.getMoment().utc(),
      submittedBy: pick(currentUser, ['oid', 'userName', 'fullName']),
    };

    data.tags = tags;
    addNote(data).then(() => {
      reloadData();
    });
  };

  const unmountTrap = () => {
    setVisibility(false);
  };

  const renderNotes = () => {
    const toRender = visible ? (
      <FocusTrap
        focusTrapOptions={{
          onDeactivate: unmountTrap,
          escapeDeactivates: false,
        }}
      >
        <Sidebar
          className={'sidebar-helper'}
          as={Menu}
          width="wide"
          animation="overlay"
          direction="right"
          vertical
          visible={visible}
        >
          {' '}
          <Header as="h2" attached="top">
            {type}
            <Button
              // color="black"
              style={{ float: 'right', background: 'none' }}
              icon
              size="tiny"
              onClick={toggleVisibility}
              className="notes-close-icon"
              aria-label="close"
            >
              <Icon name="close"></Icon>
            </Button>
          </Header>
          <Segment attached>
            <NoteForm submitNote={submitNote} />
            <NotesList
              tags={tags}
              filterOptions={filterOptions}
              defaultFilter={defaultFilter}
              list={list}
            />
          </Segment>
        </Sidebar>
      </FocusTrap>
    ) : (
      <Sidebar
        className={'sidebar-helper'}
        as={Menu}
        width="wide"
        animation="overlay"
        direction="right"
        vertical
        visible={visible}
      >
        {' '}
        <Header as="h2" attached="top">
          {type}
          <Button
            // color="black"
            style={{ float: 'right', background: 'none' }}
            icon
            size="tiny"
            onClick={toggleVisibility}
            className="notes-close-icon"
            aria-label="close"
          >
            <Icon name="close"></Icon>
          </Button>
        </Header>
        <Segment attached>
          <NoteForm submitNote={submitNote} />
          <NotesList
            tags={tags}
            filterOptions={filterOptions}
            defaultFilter={defaultFilter}
            list={list}
          />
        </Segment>
      </Sidebar>
    );
    return toRender;
  };

  return (
    <div className="page" ref={handleContextRef}>
      {/* <Sticky context={ref}>
        <Segment basic>
          {enabled && (
            <div style={{ float: 'right' }}>
              <Button
                basic={!visible}
                color="blue"
                icon="comment"
                content={type}
                onClick={toggleVisibility}
              />
            </div>
          )}
          <div float="left">
            <Header as="h1">{title || ''}</Header>
          </div>
        </Segment>
      </Sticky> */}

      <Button
        basic={isPrimary ? visible : !visible}
        color="blue"
        icon="comment"
        content={type}
        onClick={toggleVisibility}
        style={{ width: '100%' }}
      />

      {renderNotes()}

      <Sidebar.Pusher>
        <Responsive clearing basic as={Segment}>
          {children}
        </Responsive>
      </Sidebar.Pusher>
    </div>
  );
};

NotesContainer.propTypes = {
  title: PropTypes.string,
};

const mapStateToProps = state => ({
  comments: state.survey.comments,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {
  addNote,
})(NotesContainer);
