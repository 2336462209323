import React from 'react';
import { Link } from 'react-router';

export const urlFormatter = (cell, row, pathName) => (
  <Link
    to={{
      pathname: pathName,
    }}
  >
    {cell}
  </Link>
);
