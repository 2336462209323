import _ from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import {
  Form,
  Segment,
  Icon,
  Loader,
  Dimmer,
  Accordion,
} from 'semantic-ui-react';

import EASForm from '../../Shared/SurveyForms/EASForm';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsSkipLinks from '../../../utils/AmsSkipLinks';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import { customWidgets } from '../../Shared/SurveyForms/customWidgets';
import { customFields } from '../../Shared/SurveyForms/customFields';
import { transformErrors } from '../../Shared/FormHelpers/helpers/utils';
import { fetchConsolidatedQuestions } from '../../../actions/surveyActions';
import { selectTab } from '../../../actions/adminActions';
import EASArrayFieldTemplate from '../../Shared/SurveyForms/EASArrayFieldTemplate';

function SurveyQuestionsAdminPage(props) {
  const dispatch = useDispatch();
  const [responseErrors, setResponseErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({ reviewType: null });
  const [attributes, setAttributes] = useState({});
  const viewForm = useRef(null);
  const [activeIndexes, setActiveIndexes] = useState([]);

  //grab fileTypes on mount
  useEffect(() => {
    dispatch(selectTab({ key: 8, pageTitle: 'Survey Questions' }));
  }, []);

  // Data
  const getQuestions = () => {
    setLoading(true);

    let requestInput = {
      filters: {
        reviewType: filters.reviewType,
        attributes: {
          contentArea: attributes.contentArea || null,
          evidenceSource: attributes.evidenceSource || null,
          performanceArea: attributes.performanceArea || null,
          performanceMeasure: attributes.performanceMeasure || null,
        },
      },
    };

    if (_.isEmpty(requestInput.filters.attributes.contentArea)) {
      delete requestInput.filters.attributes.contentArea;
    }
    if (_.isEmpty(requestInput.filters.attributes.evidenceSource)) {
      delete requestInput.filters.attributes.evidenceSource;
    }
    if (_.isEmpty(requestInput.filters.attributes.performanceArea)) {
      delete requestInput.filters.attributes.performanceArea;
    }
    if (_.isEmpty(requestInput.filters.attributes.performanceMeasure)) {
      delete requestInput.filters.attributes.performanceMeasure;
    }

    dispatch(fetchConsolidatedQuestions(requestInput))
      .then(data => {
        setActiveIndexes([0]);
        setLoading(false);
        setData(data?.consolidatedQuestions);
      })
      .catch(error => {
        setResponseErrors([...responseErrors, error.response]);
      });
  };

  // Accordion
  const handleAccordionClick = index => {
    setActiveIndexes(prevIndexes => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(item => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  // Filters
  const filtersOnSubmit = e => {
    e.preventDefault();
    getQuestions();
  };

  const handleFiltesrValueChange = (e, { name, value }) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleAttributesValueChange = (e, { name, value }) => {
    setAttributes({ ...attributes, [name]: value });
  };

  const handleFilterReset = () => {
    setFilters({ reviewType: null }, () => {});
    setAttributes(
      {
        contentArea: null,
        performanceArea: null,
        evidenceSource: null,
        performanceMeasure: null,
      },
      () => {
        getQuestions();
      }
    );
  };

  // Renders
  const showResponseErrors = () => {
    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map(
              (errorObject, index) =>
                errorObject && (
                  <li key={index}>
                    {errorObject.message} code:(
                    {errorObject.code})
                  </li>
                )
            )}
          </ul>
        </Alert>
      );
  };

  const renderSurveyQuestionsFilters = () => {
    const { reviewType } = filters;
    const {
      contentArea,
      performanceArea,
      evidenceSource,
      performanceMeasure,
    } = attributes;

    return (
      <Segment>
        <Form onSubmit={filtersOnSubmit} noValidate className="filter-form">
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />

          <Form.Group>
            <Form.Field width={3}>
              <AmsFormLabel name="Review Type" fieldLabel="filterReviewType">
                <AmsLookupDropdown
                  placeholder="Select Review Type"
                  value={reviewType}
                  fluid
                  search
                  selection
                  name="reviewType"
                  control={AmsLookupDropdown}
                  category={'easReviewTypes'}
                  onChange={handleFiltesrValueChange}
                  aria-labelledby="filterReviewType"
                  searchInput={{
                    id: 'filterReviewType',
                    title: 'Filter by Review Type',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel name="Content Area" fieldLabel="filterContentArea">
                <AmsLookupDropdown
                  placeholder="Select Content Area"
                  value={contentArea}
                  fluid
                  search
                  selection
                  name="contentArea"
                  control={AmsLookupDropdown}
                  category={'easContentArea'}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterContentArea"
                  searchInput={{
                    id: 'filterContentArea',
                    title: 'Filter by Content Area',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel
                name="Performance Area"
                fieldLabel="filterPerformanceArea"
              >
                <AmsLookupDropdown
                  placeholder="Select Performance Area"
                  value={performanceArea}
                  fluid
                  search
                  selection
                  name="performanceArea"
                  control={AmsLookupDropdown}
                  category={'easPerformanceArea'}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterPerformanceArea"
                  searchInput={{
                    id: 'filterPerformanceArea',
                    title: 'Filter by Performance Area',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel
                name="Performance Measure"
                fieldLabel="filterPerformanceMeasure"
              >
                <AmsLookupDropdown
                  placeholder="Select Performance Measure"
                  value={performanceMeasure}
                  fluid
                  search
                  selection
                  name="performanceMeasure"
                  control={AmsLookupDropdown}
                  category={'easPerformanceMeasure'}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterPerformanceMeasure"
                  searchInput={{
                    id: 'filterPerformanceMeasure',
                    title: 'Filter by Performance Measure',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel name="Guide" fieldLabel="filterEvidenceSource">
                <AmsLookupDropdown
                  placeholder="Select Guide"
                  value={evidenceSource}
                  fluid
                  search
                  selection
                  name="evidenceSource"
                  control={AmsLookupDropdown}
                  category={'easEvidenceSource'}
                  onChange={handleAttributesValueChange}
                  aria-labelledby="filterEvidenceSource"
                  searchInput={{
                    id: 'filterEvidenceSource',
                    title: 'Filter by Guide',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            &nbsp;
            <Form.Button
              width={1}
              className="filter-button"
              primary
              content={'Filter'}
              fluid
            />
            <Form.Button
              width={1}
              className="filter-button"
              content="Reset"
              fluid
              onClick={handleFilterReset}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  };
  const renderForm = () => {
    if (data && !_.isEmpty(data)) {
      const performanceAreas = data && data.performanceAreas;
      const accordionForm =
        performanceAreas &&
        performanceAreas.map((e, idx) => {
          return (
            <div
              style={{
                backgroundColor: '#DCDCDC',
                marginTop: 20,
              }}
            >
              <React.Fragment key={idx}>
                <Accordion fluid styled>
                  <Accordion.Title
                    active={activeIndexes.includes(idx)}
                    index={idx}
                    style={{
                      backgroundColor: '#DCDCDC',
                      marginTop: 20,
                      color: 'rgba(0, 0, 0, 0.95)',
                    }}
                    onClick={() => handleAccordionClick(idx)}
                  >
                    <h3>
                      {e.performanceArea}
                      <Icon
                        style={{ float: 'right' }}
                        name={activeIndexes.includes(idx) ? 'minus' : 'plus'}
                      />
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndexes.includes(idx)}>
                    {renderSurvey(e)}
                  </Accordion.Content>
                </Accordion>
              </React.Fragment>
            </div>
          );
        });
      return accordionForm;
    }
  };

  const renderSurvey = performanceArea => {
    const schema =
      performanceArea &&
      performanceArea.formSchema &&
      performanceArea.formSchema.properties;

    const tempschema = {
      dependencies: {},
      properties: {},
      required: [],
      title: '',
      type: 'object',
    };

    let keys = Object.keys(schema);
    keys.forEach(e => {
      tempschema.properties[e] = schema[e];
    });

    delete performanceArea.uiSchema['ui:order'];

    if (!_.isEmpty(schema)) {
      return (
        <div>
          {loading && (
            <Dimmer active inverted>
              <Loader content="Loading..." />
            </Dimmer>
          )}
          <div id="eas-form">
            <EASForm
              schema={tempschema}
              uiSchema={performanceArea.uiSchema}
              ArrayFieldTemplate={EASArrayFieldTemplate}
              fields={customFields}
              widgets={customWidgets}
              children={true}
              ref={viewForm}
              showErrorList={false}
              transformErrors={transformErrors}
              onAutoSave={(autosaveData, validated) => {
                console.log('Data to save:', autosaveData);
                console.log('Validated:', validated);
              }}
            ></EASForm>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="survey-questions">
      {showResponseErrors()}
      {renderSurveyQuestionsFilters()}
      {renderForm()}
    </div>
  );
}

export default SurveyQuestionsAdminPage;
