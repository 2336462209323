import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Form from '@rjsf/core';
import { connect } from 'react-redux';
import { Button, Form as SemanticForm, Segment } from 'semantic-ui-react';
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import { fields, formSchema, uiSchema } from './FollowupSurveySchema';

// Import helper function.
import enforceRole from '../../utils/EnforceRole';

// Import acl.
import { acl } from '../../config';

// Import Actions
import {
  addSubmission,
  fetchAMSForm,
  fetchFollowUpTaskDetail,
  fetchSubmission,
  saveDraft,
  uploadDocuments,
} from '../../actions/submissions';
import AmsTable from '../../utils/AmsTable';

class FollowupSurveyPage extends Component {
  state = {
    files: [],
    uploadedFiles: [],
    surveyData: {},
    filesError: '',
    submissionId: '',
    followUpTaskDetail: {},
    submission: {},
    formData: {},
    showAlert: false,
    formProcessing: false,
    alertMessage: '',
    alertType: '',
    amsSubmissionId: '',
    editMode: false,
  };

  validate = (surveyData, errors) => {
    // const { narratives } = surveyData
    // if (_.isEmpty(narratives)) {
    //   errors.narratives.addError('Please add a narrative')
    // }
    return errors;
  };

  transformErrors = errors => {
    console.log(errors);
    return errors.map(error => {
      if (
        error.name === 'required' &&
        error.params.missingProperty === 'supportingDocuments'
      ) {
        error.message =
          'Please attach supporting Documents. This is a required Field.';
      }
      if (
        error.name === 'required' &&
        error.params.missingProperty === 'narratives'
      ) {
        error.message = 'Please add a narrative. This is a required Field.';
      }
      return error;
    });
  };

  componentDidMount() {
    this.fetchData(this.context.router.params.reviewId);
  }

  fetchData = (reviewId, version) => {
    const input = {
      reviewId: reviewId,
    };
    this.props.fetchFollowUpTaskDetail(input).then(data => {
      this.setState(
        {
          submissionId: data.followUpTaskDetail.amsSubmissionId,
          followUpTaskDetail: data.followUpTaskDetail,
        },
        () => {
          if (data.followUpTaskDetail.amsSubmissionId) {
            let input = {
              filters: {
                amsSubmissionId: data.followUpTaskDetail.amsSubmissionId,
                version: _.isNumber(version) ? version : null,
              },
            };
            this.props.fetchSubmission(input).then(
              response => {
                const { surveyData } = response.submission.data;
                let newRecs =
                  surveyData && surveyData.furRecommendations
                    ? _.cloneDeep(surveyData.furRecommendations)
                    : data.followUpTaskDetail &&
                      data.followUpTaskDetail.FollowInfo &&
                      data.followUpTaskDetail.FollowInfo.furRecommendations
                    ? _.cloneDeep(
                        data.followUpTaskDetail.FollowInfo.furRecommendations
                      )
                    : [];
                newRecs.map(recs => {
                  recs.evidences = [];
                  return recs;
                });
                this.setState(
                  {
                    surveyData: surveyData,
                    formData: {
                      additionalCitations:
                        surveyData &&
                        surveyData.additionalCitations &&
                        surveyData.additionalCitations,
                    },
                    files: [],
                    formProcessing: false,
                    amsSubmissionId: data.followUpTaskDetail.amsSubmissionId,
                    newRecommendationsFiles: newRecs,
                    editMode: false,
                  },
                  () => {
                    this.disableForm();
                  }
                );
              },
              err => {
                this.setState(
                  {
                    formProcessing: false,
                  },
                  () => console.log(err)
                );
              }
            );
          } else {
            let newRecs =
              data.followUpTaskDetail &&
              data.followUpTaskDetail.FollowInfo &&
              data.followUpTaskDetail.FollowInfo.furRecommendations
                ? _.cloneDeep(
                    data.followUpTaskDetail.FollowInfo.furRecommendations
                  )
                : [];
            newRecs.map(recs => {
              recs.evidences = [];
              return recs;
            });
            this.setState(
              {
                newRecommendationsFiles: newRecs,
              },
              () => {
                this.disableForm();
              }
            );
          }
        }
      );
    });
  };

  handleChange = ({ formData }) => {
    const { additionalCitations } = formData;
    this.setState({
      formData: formData,
      surveyData: {
        ...this.state.surveyData,
        additionalCitations: additionalCitations,
      },
    });
  };

  handleTextChange = (e, data, index) => {
    const { followUpTaskDetail, submissionId, surveyData } = this.state;
    const { furRecommendations } = followUpTaskDetail.FollowInfo;
    if (submissionId) {
      let newRecommendations = _.cloneDeep(surveyData.furRecommendations);
      newRecommendations[index] = {
        ...newRecommendations[index],
        [data.name]: data.value,
      };
      this.setState({
        surveyData: {
          ...this.state.surveyData,
          furRecommendations: newRecommendations,
        },
      });
    } else {
      let newRecommendations = _.cloneDeep(furRecommendations);
      newRecommendations[index] = {
        ...newRecommendations[index],
        [data.name]: data.value,
      };
      this.setState({
        ...this.state,
        followUpTaskDetail: {
          ...this.state.followUpTaskDetail,
          FollowInfo: {
            ...this.state.followUpTaskDetail.FollowInfo,
            furRecommendations: newRecommendations,
          },
        },
        surveyData: {
          ...this.state.surveyData,
          furRecommendations: newRecommendations,
        },
      });
    }
  };

  handleRadioButtonChange = (e, data, index) => {
    const { followUpTaskDetail, submissionId, surveyData } = this.state;
    const { furRecommendations } = followUpTaskDetail.FollowInfo;
    if (submissionId) {
      let newRecommendations = _.cloneDeep(surveyData.furRecommendations);
      newRecommendations[index] = {
        ...newRecommendations[index],
        [data.name]: data.value,
      };
      this.setState({
        surveyData: {
          ...this.state.surveyData,
          furRecommendations: newRecommendations,
        },
      });
    } else {
      let newRecommendations = _.cloneDeep(furRecommendations);
      newRecommendations[index] = {
        ...newRecommendations[index],
        [data.name]: data.value,
      };
      this.setState({
        ...this.state,
        followUpTaskDetail: {
          ...this.state.followUpTaskDetail,
          FollowInfo: {
            ...this.state.followUpTaskDetail.FollowInfo,
            furRecommendations: newRecommendations,
          },
        },
        surveyData: {
          ...this.state.surveyData,
          furRecommendations: newRecommendations,
        },
      });
    }
  };

  handleSubmit = async () => {
    const { submissionId, formData, surveyData, amsSubmissionId } = this.state;
    const { additionalCitations } = formData;
    const errors = this.validate(surveyData);
    this.setState({
      surveyData: {
        ...surveyData,
        additionalCitations: additionalCitations,
      },
      formProcessing: true,
    });

    // New Submission
    if (_.isEmpty(errors) && !amsSubmissionId) {
      this.handleFileSubmit().then(files => {
        // eslint-disable-next-line array-callback-return
        files.map(file => {
          const f = file.uploadedFiles.map(uploadedFile => {
            return uploadedFile.fileMongoId;
          });
          if (
            !_.isEmpty(
              surveyData.furRecommendations[file.questionIndex].evidences
            )
          )
            surveyData.furRecommendations[
              file.questionIndex
            ].evidences = _.concat(
              surveyData.furRecommendations[file.questionIndex].evidences,
              f
            );
          else
            surveyData.furRecommendations[file.questionIndex] = {
              ...surveyData.furRecommendations[file.questionIndex],
              evidences: f,
            };
        });
        const submission = {
          data: {
            reviewId: `${this.context.router.params.reviewId}`,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: surveyData,
          },
        };
        this.props.addSubmission(submission).then(
          data => {
            if (data.amsSubmissionId) {
              this.setState(
                {
                  submissionId: data.amsSubmissionId,
                  showAlert: true,
                  alertType: 'success',
                  alertMessage: 'Data saved to Report',
                  formProcessing: false,
                  newRecommendationsFiles: [],
                  editMode: false,
                },
                () => {
                  this.fetchData(this.context.router.params.reviewId);
                }
              );
            }
          },
          err => {
            this.setState(
              {
                filesError: [err.message || 'Data submission was unsuccessful'],
                showAlert: true,
                alertType: 'error',
                alertMessage: 'Data submission was unsuccessful',
                formProcessing: false,
              },
              () => console.log(this.state.filesError)
            );
          }
        );
      });
    } else if (_.isEmpty(errors) && amsSubmissionId) {
      this.handleFileSubmit().then(files => {
        // eslint-disable-next-line array-callback-return
        files.map(file => {
          const f = file.uploadedFiles.map(uploadedFile => {
            return uploadedFile.fileMongoId;
          });
          if (
            !_.isEmpty(
              surveyData.furRecommendations[file.questionIndex].evidences
            )
          )
            surveyData.furRecommendations[
              file.questionIndex
            ].evidences = _.concat(
              surveyData.furRecommendations[file.questionIndex].evidences,
              f
            );
          else
            surveyData.furRecommendations[file.questionIndex] = {
              ...surveyData.furRecommendations[file.questionIndex],
              evidences: f,
            };
        });
        // return;
        const submission = {
          data: {
            reviewId: `${this.context.router.params.reviewId}`,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsSubmissionId: submissionId,
            amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: surveyData,
          },
        };
        this.props.addSubmission(submission).then(
          data => {
            if (data.amsSubmissionId) {
              this.setState(
                {
                  submissionId: data.amsSubmissionId,
                  showAlert: true,
                  alertType: 'success',
                  alertMessage: 'Data saved to Report',
                  formProcessing: false,
                  newRecommendationsFiles: [],
                  editMode: false,
                },
                () => {
                  this.fetchData(this.context.router.params.reviewId);
                }
              );
            }
          },
          err => {
            this.setState(
              {
                filesError: [err.message || 'Data submission was unsuccessful'],
                showAlert: true,
                alertType: 'error',
                alertMessage: 'Data submission was unsuccessful',
                formProcessing: false,
              },
              () => console.log(this.state.filesError)
            );
          }
        );
      });
    }
  };

  handleSaveDraft = async () => {
    const { submissionId, formData, surveyData, amsSubmissionId } = this.state;
    const { additionalCitations } = formData;
    const errors = this.validate(surveyData);
    this.setState({
      surveyData: {
        ...surveyData,
        additionalCitations: additionalCitations,
      },
      formProcessing: true,
    });
    if (_.isEmpty(errors) && !amsSubmissionId) {
      this.handleFileSubmit().then(files => {
        // eslint-disable-next-line array-callback-return
        files.map(file => {
          const f = file.uploadedFiles.map(uploadedFile => {
            return uploadedFile.fileMongoId;
          });
          if (
            !_.isEmpty(
              surveyData.furRecommendations[file.questionIndex].evidences
            )
          )
            surveyData.furRecommendations[
              file.questionIndex
            ].evidences = _.concat(
              surveyData.furRecommendations[file.questionIndex].evidences,
              f
            );
          else
            surveyData.furRecommendations[file.questionIndex] = {
              ...surveyData.furRecommendations[file.questionIndex],
              evidences: f,
            };
        });

        const submission = {
          data: {
            reviewId: `${this.context.router.params.reviewId}`,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: surveyData,
          },
        };
        this.props.saveDraft(submission).then(
          data => {
            if (data.amsSubmissionId) {
              this.setState(
                {
                  submissionId: data.amsSubmissionId,
                  showAlert: true,
                  alertType: 'success',
                  alertMessage: 'Draft saved successfully',
                  newRecommendationsFiles: [],
                  formProcessing: false,
                },
                () => {
                  this.fetchData(this.context.router.params.reviewId);
                }
              );
            }
          },
          err => {
            this.setState(
              {
                filesError: [err.message || 'Data save was unsuccessful'],
                showAlert: true,
                alertType: 'error',
                alertMessage: 'Draft save was unsuccessful',
                formProcessing: false,
              },
              () => console.log(this.state.filesError)
            );
          }
        );
      });
    } else if (_.isEmpty(errors) && amsSubmissionId) {
      this.handleFileSubmit().then(files => {
        // eslint-disable-next-line array-callback-return
        files.map(file => {
          const f = file.uploadedFiles.map(uploadedFile => {
            return uploadedFile.fileMongoId;
          });
          if (
            !_.isEmpty(
              surveyData.furRecommendations[file.questionIndex].evidences
            )
          )
            surveyData.furRecommendations[
              file.questionIndex
            ].evidences = _.concat(
              surveyData.furRecommendations[file.questionIndex].evidences,
              f
            );
          else
            surveyData.furRecommendations[file.questionIndex] = {
              ...surveyData.furRecommendations[file.questionIndex],
              evidences: f,
            };
        });

        const submission = {
          data: {
            reviewId: `${this.context.router.params.reviewId}`,
            surveyName: 'Follow-up Review',
            surveyType: 'Follow-up',
            amsSubmissionId: submissionId,
            // amsFormId: this.props.followUpTaskDetail.amsFormId,
            surveyData: surveyData,
          },
        };
        this.props.saveDraft(submission).then(
          data => {
            if (data.amsSubmissionId) {
              this.setState(
                {
                  submissionId: data.amsSubmissionId,
                  showAlert: true,
                  alertType: 'success',
                  alertMessage: 'Draft saved successfully',
                  formProcessing: false,
                  newRecommendationsFiles: [],
                },
                () => {
                  this.fetchData(this.context.router.params.reviewId);
                }
              );
            }
          },
          err => {
            this.setState(
              {
                filesError: [err.message || 'Data Save was unsuccessful'],
                showAlert: true,
                alertType: 'error',
                alertMessage: 'Data Save was unsuccessful',
                formProcessing: false,
              },
              () => console.log(this.state.filesError)
            );
          }
        );
      });
    }
  };

  handleFileSubmit = () => {
    const { newRecommendationsFiles } = this.state;

    const obj = {
      reviewId: `${this.context.router.params.reviewId}`,
      reviewType: 'FUR',
    };

    const promises = [];

    newRecommendationsFiles.forEach((furRecommendations, questionIndex) => {
      if (!_.isEmpty(furRecommendations.evidences)) {
        const promise = this.props.uploadDocuments(
          furRecommendations.evidences,
          obj,
          questionIndex
        );
        promises.push(promise);
      }
    });

    return Promise.all(promises);
  };

  disableForm = () => {
    const { submissionId, followUpTaskDetail } = this.state;
    const { selectedSubmission } = this.props;
    const { amsSubmissionId } = followUpTaskDetail;
    if (amsSubmissionId || submissionId) {
      if (
        selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'draft'
      ) {
        this.setState({
          formDisableClassName: '',
          formDisabled: false,
        });
        return;
      } else {
        this.setState({
          formDisableClassName: 'formDisabled',
          formDisabled: true,
          editMode: false,
        });
        return;
      }
    }

    this.setState({
      formDisableClassName: '',
      formDisabled: false,
      editMode: false,
    });
  };

  showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = this.state;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          this.setState({
            showAlert: false,
            alertMessage: '',
            alertType: '',
          });
        }}
      />
    );
  };

  log = type => console.log.bind(console, type);

  renderForm = () => {
    // const { followUpTaskDetail } = this.props
    const {
      surveyData,
      followUpTaskDetail,
      formDisableClassName,
      formDisabled,
    } = this.state;
    return (
      <fieldset
        disabled={
          followUpTaskDetail && followUpTaskDetail.isReportFinalized
            ? true
            : formDisabled
        }
      >
        <SemanticForm className={formDisableClassName}>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6">
                  <h4>
                    Corrective Action Period End Date :{' '}
                    {(followUpTaskDetail &&
                      followUpTaskDetail.FollowInfo &&
                      AmsDateFormatters.formatDate(
                        followUpTaskDetail.FollowInfo
                          .correctiveActionPeriodEndDate
                      )) ||
                      ''}
                  </h4>
                </div>
                <div className="col-sm-6">
                  <h4>
                    Review Start and End Date :{' '}
                    {`${AmsDateFormatters.formatDate(
                      followUpTaskDetail.reviewStartDate
                    )} - ${AmsDateFormatters.formatDate(
                      followUpTaskDetail.reviewEndDate
                    )}` || ''}
                  </h4>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-6">
                  <h4>
                    Corrective Action Period Days :{' '}
                    {(followUpTaskDetail &&
                      followUpTaskDetail.FollowInfo &&
                      followUpTaskDetail.FollowInfo.capDays) ||
                      ''}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <br />
          {followUpTaskDetail &&
            followUpTaskDetail.FollowInfo &&
            followUpTaskDetail.FollowInfo.furRecommendations.map(
              (furRecommendation, index) => {
                return (
                  <div key={index}>
                    <Segment>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-6">
                              <h5>
                                Citation Number :{' '}
                                {furRecommendation.associatedCitations || ''}
                              </h5>
                            </div>
                            <div className="col-sm-6">
                              <h5>
                                Finding Type :{' '}
                                {furRecommendation.findingsType || ''}
                              </h5>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-sm-12">
                              <h5>Citation Narrative :</h5>
                              <div>
                                {furRecommendation.citationNarrative || ''}
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <SemanticForm.Field
                        label={'Did the Grantee correct the citation?'}
                      />
                      <SemanticForm.Group>
                        <SemanticForm.Checkbox
                          disabled={
                            followUpTaskDetail &&
                            followUpTaskDetail.isReportFinalized
                              ? true
                              : formDisabled
                          }
                          radio
                          name={'granteeCorrectedCitation'}
                          label="Yes"
                          value={true}
                          checked={
                            surveyData &&
                            surveyData.furRecommendations &&
                            surveyData.furRecommendations[index]
                              .granteeCorrectedCitation === true
                          }
                          onChange={(e, data) =>
                            this.handleRadioButtonChange(e, data, index)
                          }
                        />
                        <SemanticForm.Checkbox
                          disabled={
                            followUpTaskDetail &&
                            followUpTaskDetail.isReportFinalized
                              ? true
                              : formDisabled
                          }
                          radio
                          name={'granteeCorrectedCitation'}
                          label="No"
                          value={false}
                          checked={
                            surveyData &&
                            surveyData.furRecommendations &&
                            surveyData.furRecommendations[index]
                              .granteeCorrectedCitation === false
                          }
                          onChange={(e, data) =>
                            this.handleRadioButtonChange(e, data, index)
                          }
                        />
                      </SemanticForm.Group>
                      <SemanticForm.TextArea
                        name="correctedCitationReason"
                        label={`Please explain your response ${furRecommendation.associatedCitations}`}
                        value={
                          (surveyData &&
                            surveyData.furRecommendations &&
                            surveyData.furRecommendations[index]
                              .correctedCitationReason) ||
                          ''
                        }
                        onChange={(e, data) =>
                          this.handleTextChange(e, data, index)
                        }
                      />
                      <div>{this.renderAttachDocuments(index)}</div>
                      {/* <hr /> */}
                    </Segment>
                    <br />
                  </div>
                );
              }
            )}
          <SemanticForm.Group widths="equal" />
        </SemanticForm>
      </fieldset>
    );
  };

  renderAttachDocuments = furIndex => {
    const {
      formDisabled,
      surveyData,
      followUpTaskDetail,
      newRecommendationsFiles,
    } = this.state;
    return (
      <div>
        <div>
          <label>Attach Supporting Documents *</label>
          <br />
          <Button
            disabled={
              (surveyData &&
                surveyData.furRecommendation &&
                surveyData.furRecommendation[furIndex] &&
                (surveyData.furRecommendation[furIndex]
                  .granteeCorrectedCitation ||
                  surveyData.furRecommendation[furIndex]
                    .correctedCitationReason)) ||
              formDisabled
                ? true
                : false
            }
            primary
            className="btn btn-primary"
            content="Select Files"
            onClick={() => this.addFiles(furIndex)}
          />
          <Button
            disabled={formDisabled ? true : false}
            className="btn btn-primary"
            content="Clear Selected Files"
            onClick={() => {
              this.setState({
                newRecommendationsFiles: newRecommendationsFiles.map(
                  (recommendation, index) => {
                    if (furIndex === index) recommendation.evidences = [];
                    return recommendation;
                  }
                ),
              });
            }}
          />
          <br />
        </div>
        <br />
        {!_.isEmpty(newRecommendationsFiles) &&
        !_.isEmpty(newRecommendationsFiles[furIndex].evidences) &&
        !formDisabled ? (
          <div>
            <h5>Selected files</h5>
            {newRecommendationsFiles[furIndex].evidences.map((file, index) => {
              return (
                <li key={index}>
                  <label>{file.name}</label>
                </li>
              );
            })}
          </div>
        ) : (
          <div />
        )}
        {followUpTaskDetail.FollowInfo.furRecommendations &&
        !_.isEmpty(
          followUpTaskDetail.FollowInfo.furRecommendations[furIndex].evidences
        ) ? (
          <div>
            <h5>Uploaded files</h5>
            {followUpTaskDetail.FollowInfo.furRecommendations[
              furIndex
            ].evidences.map((file, index) => {
              return (
                <li key={index}>
                  <label>{_.split(file, '|')[2]}</label>
                </li>
              );
            })}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

  showDraftButton = () => {
    const { selectedSubmission } = this.props;
    const { submissionId, followUpTaskDetail } = this.state;
    const { amsSubmissionId } = followUpTaskDetail;

    if (followUpTaskDetail && followUpTaskDetail.isReportFinalized)
      return false;

    if (amsSubmissionId || submissionId) {
      if (
        selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'draft'
      )
        return true;
      else return false;
    } else return true;
  };

  showEditButton = () => {
    const { selectedSubmission, currentUser } = this.props;
    const { submissionId, followUpTaskDetail, editMode } = this.state;
    const { amsSubmissionId } = followUpTaskDetail;

    if (followUpTaskDetail && followUpTaskDetail.isReportFinalized) return;

    if (editMode || !selectedSubmission.editable) return;

    if (amsSubmissionId || submissionId) {
      if (
        selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'submitted'
      )
        return enforceRole(
          <Button
            size="large"
            content={'Edit'}
            color="green"
            icon="edit"
            onClick={e => {
              this.setState({ formDisabled: false, editMode: true });
            }}
          />,
          acl.actions.followUp.editSubmissions,
          currentUser.roles
        );
      else return;
    } else return;
  };

  renderVersionTable = () => {
    const { selectedSubmission } = this.props;
    if (_.isEmpty(selectedSubmission)) return null;

    const {
      selectedSubmission: { versionHistory },
    } = this.props;

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const version = cell;

            // Fetch specific version.
            this.setState({ formProcessing: true });
            this.fetchData(this.context.router.params.reviewId, version);
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      <AmsTable
        remote={false}
        basic
        total={versionHistory.length}
        columns={columns}
        keyField="version"
        ref="table"
        data={versionHistory}
      />
    );
  };

  render() {
    const { currentUser } = this.props;
    const {
      followUpTaskDetail,
      formData,
      formDisabled,
      formProcessing,
      editMode,
    } = this.state;
    const { reviewId, grantInfo } = followUpTaskDetail;
    const grantee = grantInfo ? grantInfo[0] : {};
    return (
      <div>
        <SlidingContainer title={'Follow-up Review Survey Detail page'}>
          <div className="well survey-header-info">
            {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
            {'Follow-up'} ( {reviewId} )
          </div>
          {this.showSuccessConfirmation()}
          <Segment basic loading={formProcessing}>
            {this.renderForm()}
            <br />
            <fieldset
              disabled={
                followUpTaskDetail && followUpTaskDetail.isReportFinalized
                  ? true
                  : formDisabled
              }
            >
              <Form
                schema={formSchema || {}}
                uiSchema={uiSchema || {}}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                onError={this.log('errors')}
                validate={this.validate}
                // transformErrors={this.transformErrors}
                liveValidate={false}
                showErrorList={false}
                formData={formData}
                noHtml5Validate={true}
                fields={fields}
                // widgets={widgets}
              >
                <div>
                  {this.showDraftButton() && (
                    <Button
                      size="large"
                      content={'Save as Draft'}
                      onClick={e => {
                        e.preventDefault();
                        this.handleSaveDraft();
                      }}
                    />
                  )}

                  {(!formDisabled || editMode) &&
                    followUpTaskDetail &&
                    !followUpTaskDetail.isReportFinalized && (
                      <Button
                        primary
                        type="submit"
                        size="large"
                        content={'Submit'}
                        // onClick={this.handleSubmit}
                      />
                    )}
                  {editMode &&
                    enforceRole(
                      <Button
                        negative
                        size="large"
                        content={'Cancel'}
                        onClick={() => {
                          this.setState({
                            formDisabled: true,
                            editMode: false,
                          });
                        }}
                      />,
                      acl.actions.followUp.editSubmissions,
                      currentUser.roles
                    )}
                </div>
              </Form>
            </fieldset>
            {this.showEditButton()}
            <div className="row">
              <div className="col-md-6 col-md-offset-6">
                {this.renderVersionTable()}
              </div>
            </div>
          </Segment>
        </SlidingContainer>
      </div>
    );
  }

  addFiles = index => {
    const { surveyData, newRecommendationsFiles, amsSubmissionId } = this.state;
    //const { furRecommendations } = followUpTaskDetail.FollowInfo;
    // const _self = this
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.setAttribute('type', 'file');
    // fileInput.setAttribute("accept", ".doc,.docx,.xls,.xlsx,.pdf");

    fileInput.addEventListener('change', ev => {
      let files = [];
      Array.prototype.slice
        .call(fileInput.files)
        .filter(file => {
          return true;
        })
        .forEach(file => {
          files.push(file);
        });

      if (amsSubmissionId && surveyData.furRecommendations) {
        newRecommendationsFiles[index] = {
          ...newRecommendationsFiles[index],
          evidences: newRecommendationsFiles[index].evidences.concat(
            _.filter(files, file => {
              return !_.isString(file);
            })
          ),
        };
        this.setState({
          ...this.state,
          newRecommendationsFiles: newRecommendationsFiles,
        });
      } else {
        // let newRecommendationsFiles = _.cloneDeep(furRecommendations);
        newRecommendationsFiles[index] = {
          ...newRecommendationsFiles[index],
          evidences: newRecommendationsFiles[index].evidences.concat(files),
        };
        this.setState({
          ...this.state,
          newRecommendationsFiles: newRecommendationsFiles,
        });
      }
    });
    fileInput.addEventListener('click', ev => {
      setTimeout(ev => {
        fileInput.parentNode.removeChild(fileInput);
      }, 1000);
    });
    document.body.appendChild(fileInput);
    fileInput.click(() => {});
  };
}

FollowupSurveyPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    followUpTaskDetail: state.submissions.followUpTaskDetail,
    amsForm: state.submissions.AMSForm,
    selectedSubmission: state.submissions.selectedSubmission,
    currentUser: state.auth.user,
  };
}

export default connect(mapStateToProps, {
  addSubmission,
  uploadDocuments,
  fetchAMSForm,
  fetchFollowUpTaskDetail,
  fetchSubmission,
  saveDraft,
})(FollowupSurveyPage);
