import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import { Modal, Button, Icon, Input } from 'semantic-ui-react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { fetchCitations } from '../../../../actions/reviewActions';
import AmsModal from '../../../../utils/AmsModal';
import AmsTable from '../../../../utils/AmsTable';

function CitationPickerWidget(props) {
  const dispatch = useDispatch();
  const [showCitationModal, setShowCitationModal] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedCitation, setSelectedCitation] = useState([props.value]);
  const [citations, setCitaitons] = useState([]);
  const [fetchedCitations, setFetchedCitations] = useState([]);
  const [disableEdit, setDisableEdit] = useState(false);

  const { followUpCitations } = useSelector(
    state => state.review.followUpSurveyDetails
  );

  const { survey } = useSelector(state => state);

  const answerData =
    survey && survey.consolidatedAnswers ? survey.consolidatedAnswers : null;

  /// TODO Pass Citations from EAS Form
  useEffect(() => {
    const fetchCitationsDetails = async () => {
      try {
        const data = await dispatch(fetchCitations({}));
        var citationsToRemove =
          props?.formContext?.surveyCitations?.map(x => x.key) || [];
        setFetchedCitations(data.citations.citations);
        var allCitations =
          data?.citations?.citations?.filter(
            x => !citationsToRemove.includes(x.standardNumber)
          ) || [];
        setCitaitons(allCitations);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCitationsDetails(props.formContext?.reviewId);
  }, []);

  useEffect(() => {
    if (citations.length !== 0) {
      let citationsToRemove = followUpCitations.map(x => x.name) || [];
      var allCitations =
        fetchedCitations.filter(
          x => !citationsToRemove.includes(x.standardNumber)
        ) || [];
      setCitaitons(allCitations);
    }
  }, [followUpCitations]);

  useEffect(() => {
    const id = parseQuestionId(props ? props.id : null);

    if (answerData && answerData.surveyData && answerData.surveyData[id]) {
      let citation =
        answerData.surveyData[id] &&
        answerData.surveyData[id] &&
        answerData.surveyData[id].CitationPicker;

      if (citation) {
        let citationToCheck = followUpCitations.find(e => e.name === citation);

        if (
          citationToCheck &&
          citationToCheck.citationEditable &&
          citationToCheck.citationEditable === true
        ) {
          setDisableEdit(false);
        } else {
          setDisableEdit(true);
        }
      }
    }
  }, [answerData, followUpCitations]);

  useEffect(() => {
    if (props.value === undefined) {
      setDisableEdit(false);
      setSelectedCitation(undefined);
    }
  }, [props.value]);

  const citation = props.value;

  const parseQuestionId = questionId => {
    let match =
      questionId.match(/^root_([a-fA-F0-9-]+_\d+)/) === null
        ? questionId.match(/^root_([0-9a-fA-F-]+)_/)
        : questionId.match(/^root_([a-fA-F0-9-]+_\d+)/);

    return match ? match[1] : null;
  };

  const style = {
    description: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '60px',
    },
  };

  const formatDescription = (cell, row) => {
    return <div style={style.description}>{cell}</div>;
  };
  const columns = [
    {
      dataField: 'standardNumber',
      text: 'Citation Number',
      formatter: (cell, row) => {
        return (
          <Link onClick={e => e.preventDefault()} to="#">
            {cell}
          </Link>
        );
      },
      sort: true,
      headerStyle: {
        width: '30%',
      },
    },
    {
      dataField: 'standardText',
      formatter: formatDescription,
      text: 'Citation Description',

      headerStyle: {
        width: '70%',
      },
    },
  ];
  const total = citations ? citations.length : 0;
  const selectRow = {
    mode: 'radio',
    style: { background: '#c8e6c9' },
    clickToSelect: true,
    selected: selectedCitation,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedCitation([row.standardNumber]);
      } else {
        setSelectedCitation([]);
      }
    },
    selectionRenderer: ({ mode, ...rest }) => {
      return rest.checked ? (
        <Icon color="green" name="check circle"></Icon>
      ) : null;
    },
  };
  const CitationSearch = props => {
    const handleClick = () => {
      props.onSearch(query);
    };
    return (
      <>
        <Input
          style={{ width: '92%', marginRight: '5px' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault();
              handleClick();
            }
          }}
        >
          <input
            placeholder="Search..."
            value={query}
            autoFocus
            onChange={e => {
              setQuery(e.target.value);
            }}
            type="text"
          />
        </Input>
        <Button onClick={handleClick}>Filter</Button>
      </>
    );
  };

  return (
    <>
      {citation ? (
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          <input type="hidden" id={props.id} value={citation} />
          {citation}
        </div>
      ) : (
        <></>
      )}
      {!disableEdit && (
        <div>
          <Link
            to={'#'}
            className="citation-picker-edit-link"
            onClick={e => {
              e.preventDefault();
              setShowCitationModal(true);
            }}
          >
            {citation ? 'Edit Citation' : 'Select a new citation'}
          </Link>
        </div>
      )}
      <AmsModal
        size={'fullscreen'}
        className="ams-semantic-modal-fix"
        open={showCitationModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowCitationModal(false);
        }}
      >
        <Modal.Header>Select a Citation</Modal.Header>
        <ToolkitProvider
          keyField="standardNumber"
          data={(citations && citations) || []}
          columns={columns}
          search
        >
          {props => (
            <>
              <Modal.Content style={{ paddingBottom: '5px' }}>
                <CitationSearch {...props.searchProps} />
              </Modal.Content>
              <Modal.Content style={{ paddingTop: '0px' }} scrolling>
                <AmsTable
                  selectRow={selectRow}
                  remote={false}
                  basic
                  total={total}
                  columns={columns}
                  loading={false}
                  {...props.baseProps}
                />
              </Modal.Content>
            </>
          )}
        </ToolkitProvider>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowCitationModal(false);
              setQuery('');
            }}
            basic
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedCitation || selectedCitation.length === 0}
            onClick={() => {
              setShowCitationModal(false);
              setQuery('');
              props.onChange(
                selectedCitation && selectedCitation.length > 0
                  ? selectedCitation[0]
                  : null
              );
            }}
            primary
            content="Select"
          />
        </Modal.Actions>
      </AmsModal>
    </>
  );
}

export default CitationPickerWidget;
