import axios from 'axios';
import { isEmpty } from 'lodash';

import {
  CATEGORY_ADDED,
  CATEGORY_FETCHED,
  CATEGORIES_FETCHED,
  CATEGORY_UPDATED,
  CATEGORY_DELETED,
  ADD_ERROR,
  REMOVE_ERROR,
} from '../../redux/types';

export const categoriesFetched = categories => ({
  type: CATEGORIES_FETCHED,
  categories,
});

export const categoryDetailsFetched = category => ({
  type: CATEGORY_FETCHED,
  category,
});

export function updatedCategory(category) {
  return {
    type: CATEGORY_UPDATED,
    category,
  };
}

export function addedCategory(category) {
  return {
    type: CATEGORY_ADDED,
    category,
  };
}

export function removedCategory(id, category) {
  return {
    type: CATEGORY_DELETED,
    id,
    category,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export const fetchCategories = () => dispatch =>
  axios.get(`/help/categories`).then(response => {
    return dispatch(categoriesFetched(response.data));
  });

export const fetchCategoryDetailsBySlug = slug => dispatch =>
  axios.get(`/help/categories?slug=${slug}`).then(response => {
    if (!isEmpty(response.data)) {
      dispatch(categoryDetailsFetched(response.data));
    }
    return response.data;
  });

export const fetchCategoryDetailsById = id => dispatch =>
  axios
    .get(`/help/categories?id=${id}`)
    .then(response => dispatch(categoryDetailsFetched(response.data)));

export function addCategory(categoryObject) {
  return dispatch => {
    return axios.post(`/help/categories`, categoryObject).then(response => {
      return dispatch(addedCategory(response.data));
    });
  };
}

export function updateCategory(id, categoryObject) {
  return dispatch => {
    return axios
      .post(`/help/categories/${id}`, categoryObject)
      .then(response => {
        return dispatch(addedCategory(response.data));
      });
  };
}

export function deleteCategory(id) {
  return dispatch => {
    axios.delete(`/help/categories/${id}`).then(function(response) {
      return dispatch(removedCategory(id, response.data));
    });
  };
}
