import React, { useRef, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import {
  Form,
  Modal,
  Button,
  Item,
  List,
  Header,
  Message,
  Dimmer,
  Input,
  Loader,
  Segment,
  Dropdown,
} from 'semantic-ui-react';
import { Link } from 'react-router';
import { Well } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

// Import UI config
import { fileSizeFormat } from '../../components/Shared/FormHelpers/helpers/utils';

// Import utils.
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsHelpText from '../../utils/AmsHelpText';
import AmsTable from '../../utils/AmsTable';
import AmsAlert from '../../utils/AmsAlert';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsModal from '../../utils/AmsModal';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import actions
import { fetchAmsLookup } from '../../actions/lookupActions';
import { downloadGranteeReviewReport } from '../../actions/granteeActions';
import { downloadReviewEvidence } from '../../actions/reviewActions';
import {
  selectTab,
  fetchFilesList,
  updateFile,
} from '../../actions/adminActions';
import { userProfileFetched } from '../../actions/profileActions';

import './assets/style.css';

function FilesAdminPage(props) {
  const amsTableRef = useRef(null);

  const scrollToRef = ref => {
    console.log('fired');
    window.scrollTo(0, ref.current.offsetTop);
    ref.current.focus();
  };

  const scrollToResults = () => scrollToRef(amsTableRef);

  const formatDate = cell => AmsDateFormatters.formatDate(cell);

  const style = {
    positionHeader: {
      background: '#f3f4f5',
    },
    skillHeader: {
      fontSize: '.8125rem',
      fontWeight: '400',
      textTransform: 'upperCase',
      color: 'rgba(0,0,0,.6)',
      letterSpacing: '.05em',
      marginBottom: '.5rem',
      marginTop: 0,
    },
  };

  const { fileTypes } = useSelector(store => store.lookups.amsLookups);
  const [responseErrors, setResponseErrors] = useState([]);
  const [allowedFileTypes, setAllowedFileTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  //modal data
  const [uploadedFile, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [modalError, setModalError] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [reason, setReason] = useState('');

  const [filters, setFilters] = useState({
    reviewId: '',
    reviewType: '',
    fileCategory: '',
    fileType: [],
    fiscalYear: 2020,
  });

  const [paginationOptions, setPaginationOptions] = useState({
    page: 1,
    limit: 25,
    sortName: 'uploadDate',
    sortOrder: 'desc',
  });

  const dispatch = useDispatch();

  //grab fileTypes on mount
  useEffect(() => {
    dispatch(fetchAmsLookup('fileTypes'));
    props.selectTab({
      key: 6,
      pageTitle: 'Files',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //find activeFileTypes once fileTypes props are recieved
  useEffect(() => {
    if (fileTypes && fileTypes.length) {
      const parent = _.find(fileTypes, { parent: true }); // Find lookup category parent.
      const activeFileTypes = _.filter(fileTypes, {
        parent: false,
        active: true,
      }); // Find active lookup items.
      if (parent && parent.active) {
        if (activeFileTypes.length) {
          let allowedFileTypes = activeFileTypes.map(fileType => {
            return `${fileType.value.toLowerCase()}`;
          });
          setAllowedFileTypes(allowedFileTypes);
        }
      }
    }
  }, [fileTypes]);

  const getData = () => {
    const { page, limit, sortName, sortOrder } = paginationOptions;
    setLoading(true);
    const selectedFilters = _.cloneDeep(filters);
    Object.keys(selectedFilters).forEach(e => {
      const filter = selectedFilters[e];
      if (
        (!!filter === false && !_.isArray(filter)) ||
        (_.isArray(filter) && filter.length === 0)
      )
        delete selectedFilters[e];
    });
    let requestInput = {
      filters: selectedFilters,
      page,
      limit,
      sortName,
      sortOrder,
    };

    props
      .fetchFilesList(requestInput)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setResponseErrors([...responseErrors, error.response.data]);
      });
  };

  //retrieve data whenever paginationOptions are changed
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOptions]);

  const filtersOnSubmit = e => {
    e.preventDefault();
    getData();
  };

  const handleValueChange = (e, { name, value }) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleFilterReset = () => {
    setFilters(
      {
        reviewId: '',
        reviewType: '',
        fileCategory: '',
        fiscalYear: 2020,
      },
      () => {
        getData();
      }
    );
  };

  const renderFileFilters = () => {
    const { reviewType, reviewId, fileCategory, fiscalYear } = filters;

    const fileCategoryOptions = [
      { key: 'evidence', value: 'evidence', text: 'Evidence' },
      { key: 'report', value: 'report', text: 'Report' },
    ];

    return (
      // <div className="page-filters">
      <Segment>
        <Form onSubmit={filtersOnSubmit} noValidate className="filter-form">
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />

          <Form.Group>
            <Form.Field width={3}>
              <AmsFormLabel
                name="Review Type"
                helpText={true}
                fieldLabel="filterReviewType"
              >
                <AmsLookupDropdown
                  placeholder="Select Review Type"
                  value={reviewType}
                  name="reviewType"
                  onChange={handleValueChange}
                  control={AmsLookupDropdown}
                  fluid
                  search
                  selection
                  // clearable
                  category={'reviewTypes'}
                  aria-labelledby="filterReviewType"
                  searchInput={{
                    id: 'filterReviewType',
                    title: 'Select a Review Type',
                    'aria-labelledby': 'filterReviewType',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={3}>
              <AmsFormLabel
                name="Fiscal Year"
                helpText={true}
                fieldLabel="filterFiscalYear"
              >
                <AmsLookupDropdown
                  placeholder="Select Fiscal Year"
                  value={fiscalYear}
                  fluid
                  search
                  selection
                  name="fiscalYear"
                  control={AmsLookupDropdown}
                  category={'fiscalYear'}
                  onChange={handleValueChange}
                  aria-labelledby="filterFiscalYear"
                  searchInput={{
                    id: 'filterFiscalYear',
                    title: 'Filter by Fiscal Year',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field
              width={3}
              label={{
                children: (
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="filterReviewID"
                    label="Review ID"
                  />
                ),
                htmlFor: 'filterReviewID',
              }}
              control={Input}
              id="filterReviewID"
              name="reviewId"
              placeholder="Review ID"
              value={reviewId}
              maxLength="10"
              onChange={handleValueChange}
              fluid
            />
            <Form.Field
              label={{ children: 'File Category', htmlFor: 'fileCategory' }}
              width={5}
              placeholder="Select File Category"
              value={fileCategory}
              name="fileCategory"
              onChange={handleValueChange}
              fluid
              options={fileCategoryOptions}
              selection
              // clearable
              control={Dropdown}
              id="fileCategory"
              aria-labelledby="fileCategory"
              selectOnBlur={false}
              openOnFocus={false}
            />
            &nbsp;
            <Form.Button
              width={2}
              className="filter-button"
              primary
              content={'Filter'}
              fluid
            />
            <Form.Button
              width={1}
              className="filter-button"
              content="Reset"
              fluid
              onClick={handleFilterReset}
            />
          </Form.Group>
        </Form>
      </Segment>
      // </div>
    );
  };

  const generateFileModalAlert = () => {
    if (!_.isEmpty(modalError)) {
      return (
        <Message
          negative
          icon={'cancel'}
          header={'Something went wrong!'}
          content={`${modalError}: Ensure you are uploading the correct file type`}
        ></Message>
      );
    }
  };

  const generateFileVersionList = () => {
    if (!modalData) return null;

    const { fileVersions } = modalData;

    if (!fileVersions || fileVersions.length === 0) {
      return null;
    }

    return (
      <Well style={{ marginTop: '10px' }}>
        <Header as="h6" icon="history" content="File History" />
        <Item.Group divided relaxed>
          {fileVersions.map(e => {
            const { uploadedBy, uploadDate, reason } = e.fileUserInfo;
            return (
              <Item>
                <Item.Content>
                  <Item.Meta>
                    "{e.fileName}" - {formatDate(uploadDate)}
                  </Item.Meta>
                  <Item.Description>
                    {' '}
                    <div>{uploadedBy && `Submitted by: ${uploadedBy}`}</div>
                    <div>{reason && `Reason: ${reason}`}</div>
                  </Item.Description>
                </Item.Content>
              </Item>
            );
          })}
        </Item.Group>
      </Well>
    );
  };

  const getBase64 = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      setFile(reader.result);
    };
    reader.onerror = function() {
      //console.log('Error: ', error);
      //setError
    };
  };

  const fileOnDrop = files => {
    setModalError({});
    setFileName(files[0].name);
    getBase64(files[0]);
  };

  const generateModalHeaderDetails = () => {
    if (!modalData || !modalData.metadata) {
      return null;
    }
    const { reviewId, reviewType, citation } = modalData.metadata;

    return (
      <List horizontal relaxed="very">
        <List.Item key={'id'}>
          <List.Content>
            <List.Header style={style.skillHeader}>Review ID</List.Header>
            <Header as="h3">{reviewId}</Header>
          </List.Content>
        </List.Item>
        <List.Item key={'type'}>
          <List.Content>
            <List.Header style={style.skillHeader}>Review Type</List.Header>
            <Header as="h3">{reviewType}</Header>
          </List.Content>
        </List.Item>
        {citation && citation.length > 0 ? (
          <List.Item key={'citation'}>
            <List.Content>
              <List.Header style={style.skillHeader}>Citation</List.Header>
              <Header as="h3">{citation}</Header>
            </List.Content>
          </List.Item>
        ) : null}
      </List>
    );
  };

  const renderFileVersionHistoryModal = () => {
    return (
      <AmsModal
        open={showFileModal}
        className="ams-semantic-modal-fix"
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowFileModal(false);
          setModalError({});
          setFile(null);
          setReason('');
          setFileName('');
          setModalData(null);
        }}
      >
        <Modal.Header tabIndex="-1">File Detail</Modal.Header>
        {generateFileModalAlert()}
        <Modal.Content>
          {generateModalHeaderDetails()}
          {!_.isEmpty(modalData) && generateFileVersionList()}

          {modalLoading === false ? (
            <Dropzone
              id="attachmentDropzone"
              disablePreview={false}
              onDrop={fileOnDrop}
              //multiple
              accept={`${allowedFileTypes &&
                allowedFileTypes.length &&
                allowedFileTypes.join(',')}`}
              className="upload-area-attachment"
              activeClassName="dragged"
            >
              {({ getRootProps, getInputProps, acceptedFiles }) => {
                return (
                  <div
                    style={{ marginTop: '10px' }}
                    className="upload-area-attachment"
                    {...getRootProps()}
                  >
                    {acceptedFiles.length > 0 ? (
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '25px',
                          lineHeight: '100px',
                        }}
                      >
                        {uploadedFile && (
                          <div
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '100%',
                            }}
                          >
                            {acceptedFiles[0].name}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="upload-area-text">
                        <input {...getInputProps()} />
                        <br />
                        <Button>Replace File</Button>
                      </div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          ) : (
            <Dimmer active inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
          )}
          {uploadedFile && modalLoading === false ? (
            <Form style={{ marginTop: '25px' }}>
              <Form.Group widths="equal">
                <Form.Field
                  required
                  value={reason}
                  control={Input}
                  onChange={(e, { value }) => setReason(value)}
                  label="Reason"
                />
              </Form.Group>
            </Form>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          {uploadedFile !== null && reason !== '' && (
            <Button onClick={() => setShowAlert(true)} content="Save" />
          )}
          <Button
            onClick={() => {
              setShowFileModal(false);
              setModalData();
              setModalError({});
              setFile(null);
              setReason('');
              setFileName('');
            }}
            content="Cancel"
          />

          <Button
            download={modalData && modalData.fileName}
            onClick={() => {
              triggerDownload();
            }}
            primary
            content="Download"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const triggerDownload = () => {
    if (modalData) {
      if (modalData.fileCategory === 'report') {
        dispatch(
          downloadGranteeReviewReport(modalData.metadata.reviewId, {
            fileName: modalData._id,
          })
        );
      } else {
        let extension = modalData.fileName.split('|');
        extension.pop();
        extension = extension.join('-');

        dispatch(
          downloadReviewEvidence(
            modalData.metadata.reviewId,
            modalData.fileName,
            extension
          )
        );
      }
    }
  };

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmCancelText="No"
        confirmButtonText="Yes"
        confirmButtonColor={'#DD6B55'}
        text={`Are you sure you want to replace this file?`}
        onConfirm={() => {
          handleSubmit();
          setShowAlert(false);
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const handleSubmit = () => {
    const data = {
      file: uploadedFile,
      fileName: fileName,
      id: modalData._id,
      fileCategory: modalData.fileCategory,
      amsSubmissionId: modalData.amsSubmissionId,
      questionKey: modalData.questionKey,
      reviewId: modalData.metadata.reviewId,
      reason: reason,
    };

    setModalLoading(true);

    dispatch(updateFile(data))
      .then(() => {
        getData();
        setShowFileModal(false);
        setFile(null);
        setReason('');
        setModalError({});
        setFileName('');
        setModalData(null);
        setModalLoading(false);
      })
      .catch(error => {
        setModalLoading(false);
        setModalError(error.message);
      });
  };

  const renderFilesTable = () => {
    const { files } = props;
    const { data, limit, total } = files;

    const linkId = (cell, row) => {
      return (
        <Link to={`/review/${row.metadata.reviewId}`}>
          {row.metadata.reviewId}
        </Link>
      );
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      setPaginationOptions({
        ...paginationOptions,
        page,
        limit: sizePerPage,
      });

      scrollToResults();
    };

    const onPageChange = (page, sizePerPage) => {
      setPaginationOptions({
        ...paginationOptions,
        page: page,
        limit: sizePerPage,
      });

      scrollToResults();
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === paginationOptions.sortName &&
        sortOrder === paginationOptions.sortOrder
      )
        return null;

      if (type === 'sort') {
        setPaginationOptions({
          ...paginationOptions,
          sortName: sortField || paginationOptions.sortName,
          sortOrder: sortOrder || paginationOptions.sortOrder,
        });
      }
    };

    const formatFileType = cell => {
      return <span>{cell}</span>;
    };

    const columns = [
      {
        dataField: 'fileName',
        text: 'File Name',
        sort: true,
        // formatter: formatFileName,
      },
      {
        dataField: 'fileType',
        text: 'File Type',
        formatter: formatFileType,
      },
      {
        dataField: 'fileCategory',
        sort: true,
        text: 'File Category',
        formatter: formatFileType,
      },
      {
        dataField: 'reviewId',
        formatter: linkId,
        sort: true,
        text: 'Review ID',
      },
      {
        dataField: 'uploadDate',
        sort: true,
        formatter: formatDate,
        text: 'Relevance',
      },
      {
        dataField: 'fileSize',
        text: 'Size',
        formatter: cell => fileSizeFormat(cell),
        sort: true,
      },
    ];

    const rowClasses = () => {
      return 'file-table-row';
    };

    const rowEvents = {
      onClick: (e, row) => {
        setModalData(row);
        setShowFileModal(true);
        setReason('');
        setFile(null);
        setModalError({});
        setFileName('');
      },
    };

    const defaultSorted = [
      {
        dataField: paginationOptions.sortName,
        order: paginationOptions.sortOrder,
      },
    ];

    return (
      <div ref={amsTableRef} tabIndex="-1">
        <AmsTable
          data={data}
          defaultSorted={defaultSorted}
          rowClasses={rowClasses}
          rowEvents={rowEvents}
          page={paginationOptions.page}
          total={total}
          limit={limit}
          loading={loading}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  const showResponseErrors = () => {
    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map(
              (errorObject, index) =>
                errorObject && (
                  <li key={index}>
                    {errorObject.message} code:(
                    {errorObject.code})
                  </li>
                )
            )}
          </ul>
        </Alert>
      );
  };

  return (
    <div className="admin-users">
      {showResponseErrors()}
      {showConfirmationAlert()}
      {renderFileVersionHistoryModal()}
      {renderFileFilters()}
      {renderFilesTable()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    files: state.admin.files,
    currentUser: state.auth.user,
  };
}

export default connect(mapStateToProps, {
  selectTab,
  fetchFilesList,
  userProfileFetched,
})(FilesAdminPage);
