import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import AmsTable from '../../../../utils/AmsTable';

// Import actions
import {
  fetchReviewSubmissions,
  fetchFUReviewSubmissions,
  clearReviewSubmissions,
  fetchFUSurveyDetails,
  fetchFollowupSurveyCitations,
} from '../../../../actions/fy19/reviewActions';
import { fa2GuideSelected } from '../../../../actions/surveyActions';

// Import configs.
import ReviewSubmissionsFilters from './ReviewSubmissionsFilters';

// Import utils
import { reviewTypeFormat } from '../../../../utils/TextFormatters';

class ReviewSubmissionsList extends Component {
  state = {
    loading: false,
    selectedReview: {},
    reviewSubmissions: [],
    filters: { guideName: '', performanceMeasure: '', status: '' },
    loadedSubmissionData: false,
    firstCitation: null,
  };

  constructor(props) {
    super(props);
    this.amsTableRef = React.createRef();
  }

  componentDidMount = () => {
    this.loadData();
  };

  componentWillUnmount = () => {
    this.props.clearReviewSubmissions();
  };

  componentWillReceiveProps = nextProps => {
    const reviewId = this.context.router.params.reviewId;
    const { reviewSubmissions } = nextProps;

    if (Array.isArray(reviewSubmissions)) {
      this.setState({
        reviewSubmissions: reviewSubmissions.map((item, index) => ({
          index,
          ...item,
        })),
      });
    }

    if (
      isEmpty(this.props.reviewFUSubmissions) &&
      this.props.selectedReview &&
      this.props.selectedReview.reviewType === 'Follow-up' &&
      !this.state.loadedSubmissionData
    ) {
      this.props
        .fetchFUReviewSubmissions({
          filters: { reviewId: reviewId },
        })
        .then(() =>
          this.setState({ loading: false, loadedSubmissionData: true })
        )
        .catch(error => {
          this.setState({
            loading: false,
            loadedSubmissionData: true,
            errors: {
              ...this.state.errors,
              fetchReviewSubmissions:
                (error.response && error.response.data.message) || '',
            },
          });
        });
    }
  };

  loadData = () => {
    const reviewId = this.context.router.params.reviewId;

    if (isEmpty(reviewId)) return null;

    this.setState({ loading: true });

    if (
      this.props.selectedReview &&
      this.props.selectedReview.reviewType === 'Follow-up'
    ) {
      if (
        this.props.selectedReview.fiscalYear === 2024 &&
        this.props.selectedReview.isNewForms
      ) {
        this.props
          .fetchFUSurveyDetails({
            reviewId: reviewId,
          })
          .then(() => this.setState({ loading: false }))
          .catch(error => {
            this.setState({
              loading: false,
              errors: {
                ...this.state.errors,
                fetchReviewSubmissions:
                  (error.response && error.response.data.message) || '',
              },
            });
          });
        this.props.fetchFollowupSurveyCitations({ reviewId }).then(data => {
          this.setState({ firstCitation: data.followupCitations[0] });
        });
      } else {
        this.props
          .fetchFUReviewSubmissions({
            filters: { reviewId: reviewId },
          })
          .then(() => this.setState({ loading: false }))
          .catch(error => {
            this.setState({
              loading: false,
              errors: {
                ...this.state.errors,
                fetchReviewSubmissions:
                  (error.response && error.response.data.message) || '',
              },
            });
          });
      }
    } else {
      this.props
        .fetchReviewSubmissions({
          reviewId,
          filters: this.state.filters,
        })
        .then(() => this.setState({ loading: false }))
        .catch(error => {
          this.setState({
            loading: false,
            errors: {
              ...this.state.errors,
              fetchReviewSubmissions:
                (error.response && error.response.data.message) || '',
            },
          });
        });
    }
  };

  handleFilterClicked = ({ guideName, performanceMeasure, status }) => {
    this.setState(
      {
        filters: { guideCode: guideName, formCode: performanceMeasure, status },
      },
      () => this.loadData()
    );
  };

  renderTable = () => {
    const { selectedReview } = this.props;

    if (this.context.router.params.reviewId !== selectedReview.reviewId) {
      return null;
    }
    let reviewType = selectedReview
      ? selectedReview.reviewType
        ? selectedReview.reviewType
        : ''
      : '';

    reviewType = reviewTypeFormat(reviewType);

    const formatLink = (cell, row) => {
      const reviewId = this.context.router.params.reviewId;
      return row.performanceMeasure ===
        'Document Supervision Incident(s) Details Guide' ||
        row.performanceMeasure ===
          'Document Discipline Incident(s) Details Guide' ||
        row.performanceMeasure ===
          'Document Inappropriate Release Incident(s) Details Guide' ? (
        <Link
          aria-label={row.performanceMeasure}
          to={{
            pathname: `/task/${reviewId}/survey/${reviewType}`,
            query: {
              g: encodeURIComponent(row.guideName),
              pm: encodeURIComponent(row.performanceMeasure),
              t: encodeURIComponent(row.multiSubmissionType),
            },
          }}
          // target="_blank"
        >
          {cell}
        </Link>
      ) : (
        <Link
          aria-label={row.performanceMeasure}
          to={{
            pathname: `/task/${reviewId}/survey/${reviewType}`,
            query: {
              g: encodeURIComponent(row.guideName),
              pm: encodeURIComponent(row.performanceMeasure),
            },
          }}
        >
          {cell}
        </Link>
      );
    };

    const formatStatus = (cell, row) => {
      if (cell === 'Completed') return <Icon name="check" />;
      return cell;
    };

    const columns = [
      {
        formatter: formatLink,
        dataField: 'guideName',
        text: 'Guide Name',
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      {
        dataField: 'performanceMeasure',
        formatter: formatLink,
        text: 'Performance Measure',
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      { dataField: 'target', text: 'Target', sort: true },
      { dataField: 'actual', text: 'Actual', sort: true },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: formatStatus,
      },
    ];

    return (
      <div tabIndex="-1">
        <AmsTable
          data={
            selectedReview && selectedReview.reviewType
              ? this.state.reviewSubmissions
              : null
          }
          remote={false}
          total={this.state.reviewSubmissions.length}
          keyField="amsFormId"
          columns={columns}
          loading={this.state.loading}
          basic={false}
          sizePerPageOption={true}
          paginationTotalHeaderSize="h3"
        />
      </div>
    );
  };

  render() {
    if (this.props.selectedReview.reviewType === 'Follow-up') {
      return null;
    }
    return (
      <>
        <ReviewSubmissionsFilters
          isRANIncident={
            this.props.selectedReview && this.props.selectedReview.isRANIncident
              ? this.props.selectedReview.isRANIncident
              : false
          }
          onFilterClicked={this.handleFilterClicked}
        />
        {this.renderTable()}
      </>
    );
  }
}

ReviewSubmissionsList.contextTypes = {
  router: PropTypes.object.isRequired,
};

ReviewSubmissionsList.propTypes = {
  selectedReview: PropTypes.object.isRequired,
  reviewSubmissions: PropTypes.array.isRequired,
  fetchReviewSubmissions: PropTypes.func.isRequired,
  fa2GuideSelected: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  reviewSubmissions: state.reviewSubmissions.list,
  reviewFUSubmissions: state.reviewSubmissions.list,
});

export default connect(mapStateToProps, {
  fetchReviewSubmissions,
  fa2GuideSelected,
  clearReviewSubmissions,
  fetchFUReviewSubmissions,
  fetchFUSurveyDetails,
  fetchFollowupSurveyCitations,
})(ReviewSubmissionsList);
