import React from 'react';
import { Header, Grid, Divider } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

// Import utils.
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';

const LABELS = {
  reviewId: 'Review ID',
  reviewType: 'Review Type',
  reviewSubType: 'Review Sub Type',
  reviewStartDate: 'Review Start Date',
  reviewEndDate: 'Review End Date',
  dataCollection: 'Data Collection',
  numberOfGrants: 'Number of Grants',
  granteeName: 'Grantee Name',
  granteeNumber: 'Grantee Number',
  programType: 'Program Type',
  hsDirector: 'Head Start Director',
  ehsDirector: 'Early Head Start Director',
  hsDirectorPhoneNumber: 'Head Start Director Phone Number',
  ehsDirectorPhoneNumber: 'Early Head Start Director Phone Number',
  hsDirectorEmail: 'Head Start Director Email',
  ehsDirectorEmail: 'Early Head Start Director Email',
  centerName: 'Center Name',
  address: 'Address',
  contactNumber: 'Contact Number',
  numberOfClassRooms: 'Number of Classrooms',
  name: 'Name',
  email: 'Email',
  emailid: 'Email',
  role: 'Role',
  roleName: 'Role',
  languageSkills: 'Language Skills',
  contentAreaSkills: 'Content Area Skills',
  otherSkills: 'Other Skills',
  other: 'Other Skills',
  assignment: 'Assigned as',
  inactivationDate: 'Inactivation Date',
  inactivationReason: 'Inactivation Reason',
  classrooms: 'Classroom Names',
  reviewersAssigned: 'Number of Reviewers Assigned',
  reviewersRequired: 'Number of Reviewers Required',
  shadowReviewersAssigned: 'Number of Shadow Reviewers Assigned',
  shadowReviewersRequired: 'Number of Shadow Reviewers Required',
};

const ManifestRowItem = ({ field = '', row = '', formatter = '' }) => {
  const label = LABELS[field];
  const item = row[field];

  if (!item) return null;

  let formattedRow = '';

  switch (formatter) {
    case 'date':
      formattedRow = AmsDateFormatters.formatDate(item);
      break;

    default:
      formattedRow = item;
      break;
  }

  return (
    <Grid.Row columns={2} style={style.resetBottomPadding}>
      <Grid.Column width={4}>{label}</Grid.Column>
      {field !== 'classrooms' ? (
        <Grid.Column width={12}>{formattedRow}</Grid.Column>
      ) : (
        <Grid.Column width={12}>
          {row.classrooms &&
            `${row.classrooms.map(classroom => classroom.name).join(', ')}`}
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

const ManifestRow = ({ row = [] }) => {
  if (!row) return null;

  return Object.keys(row).map(key => {
    return <ManifestRowItem key={key} field={key} row={row} />;
  });
};

const ManifestDetail = ({ title = '', data = [] }) => {
  if (!data || !data.length) return null;

  if (title === 'Review Team Information') {
    data = data.map(e => {
      if (e.isActive === false) {
        return {
          ...e,
          name: `${e.name} (Inactive)`,
        };
      } else {
        return {
          ...e,
        };
      }
    });
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as={'h3'} color="grey">
            {title}
          </Header>
        </Grid.Column>
      </Grid.Row>

      {data.map((val, key) => (
        <React.Fragment key={key}>
          <ManifestRow row={val} />

          <Grid.Row style={style.resetBottomPadding}>
            <Grid.Column>&nbsp;</Grid.Column>
          </Grid.Row>
        </React.Fragment>
      ))}

      <Grid.Row>
        <Grid.Column>
          <Divider />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const ReviewManifestDetails = props => {
  const { manifest } = useSelector(store => store.review);

  const renderReviewInformation = () => {
    if (!manifest) return null;

    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column>
            <Header as={'h3'} color="grey">
              Review Information
            </Header>
          </Grid.Column>
        </Grid.Row>

        <ManifestRowItem field="reviewId" row={manifest} />

        <ManifestRowItem field="reviewType" row={manifest} />

        <ManifestRowItem field="reviewSubType" row={manifest} />

        <ManifestRowItem
          field="reviewStartDate"
          row={manifest}
          formatter="date"
        />

        <ManifestRowItem
          field="reviewEndDate"
          row={manifest}
          formatter="date"
        />

        <ManifestRowItem field="dataCollection" row={manifest} />

        <ManifestRowItem field="numberOfGrants" row={manifest} />

        <ManifestRowItem field="reviewersRequired" row={manifest} />

        <ManifestRowItem field="reviewersAssigned" row={manifest} />

        <ManifestRowItem field="shadowReviewersRequired" row={manifest} />

        <ManifestRowItem field="shadowReviewersAssigned" row={manifest} />

        <Grid.Row>
          <Grid.Column>
            <Divider />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  };

  return (
    <>
      <Grid>{renderReviewInformation()}</Grid>

      <ManifestDetail
        title="Grantee Information"
        data={manifest.granteeInfo || []}
      />

      <ManifestDetail
        title="Target Center Details"
        data={manifest.targetCenterDetails || []}
      />

      <ManifestDetail
        title="Review Team Information"
        data={manifest.reviewTeamInfo || []}
      />

      <ManifestDetail
        title="Skill Information"
        data={manifest.reviewerSkilsInfo || []}
      />

      <ManifestDetail
        title="Residual Center Details (Eligible minus Target)"
        data={manifest.residualCenterDetails || []}
      />

      <ManifestDetail
        title="Other Information"
        data={manifest.otherInfo || []}
      />
    </>
  );
};

const style = {
  resetBottomPadding: {
    paddingBottom: '0px',
  },
};

export default ReviewManifestDetails;
