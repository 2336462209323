import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-dom';
import { Tab, Responsive, Menu } from 'semantic-ui-react';

// Import actions.
import { userProfileTabSelected } from '../../../actions/profileActions';

// Helper function
import enforceRole from '../../../utils/EnforceRole';

const getWidth = () => {
  const isSSR = typeof window === 'undefined';

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const Tabs = ({ ...props }) => {
  const { settings, type } = props;

  const width = getWidth();
  const mWidth = Responsive.onlyMobile.maxWidth;

  const gridSettings =
    width <= mWidth
      ? { paneWidth: 16, tabWidth: 16 }
      : { paneWidth: 12, tabWidth: 4 };

  const handleChange = (e, data) => {
    const key = data && data.panes[data.activeIndex].key;
    props.userProfileTabSelected(key);
  };

  if (type === 'profile') {
    const { roles } = props.currentUser;
    const { oid, tabs, getForm } = settings;

    const panes = tabs.map((tab, idx) => {
      const menuItem = tab.name;

      const tabContent = enforceRole(
        <Tab.Pane key={tab.key}>
          {tab.key === 'calendar' ? getForm(tab.key, oid) : getForm(tab.key)}
        </Tab.Pane>,
        tab.allowed,
        roles
      );

      return {
        menuItem: (
          <Menu.Item
            link
            key={idx}
            as={Link}
            href="#"
            onClick={e => {
              e.preventDefault();
            }}
          >
            {menuItem}
          </Menu.Item>
        ),
        key: tab.key,

        render: () => tabContent,
      };
    });

    return (
      <Tab
        grid={gridSettings}
        menu={{ fluid: true, vertical: true }}
        menuPosition="left"
        panes={panes}
        onTabChange={handleChange}
      />
    );
  }
};

Tabs.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { userProfileTabSelected })(Tabs);
