/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import {
  Button,
  Card,
  Divider,
  Segment,
  Accordion,
  Icon,
} from 'semantic-ui-react';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

const SurveyNotesHistory = props => {
  const { tags, filterOptions, defaultFilter } = props;
  //defaultFilter is a key in tags ex: 'performanceMeasure'
  //filterOptions will be all the avaialble values for the defaultFilter key in tags ex: [Program Management, Program Performance]

  let { list } = props;

  //filter is a value in tags, ex: 'Program Management'
  const [filter, setFilter] = useState(null);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    if (filter === null || filter === undefined) {
      setFilter(tags[defaultFilter]);
    } else {
      const found = filterOptions.find(e => {
        return e.value === filter;
      });
      if (!found) {
        setFilter(tags[defaultFilter]);
      }
    }
  }, [filterOptions]);

  const generateFilterDropdown = () => {
    return (
      filterOptions &&
      filterOptions.map((e, index) => {
        if (e.value === 'divider' && e.text === '') {
          return <Divider key={index}></Divider>;
        }

        return (
          <Button
            style={{
              background: 'none',
              borderStyle: 'none',
              textAlign: 'left',
            }}
            onClick={() => {
              setFilter(e.value);
            }}
            className="dropdown-item"
            active={filter === e.value}
            key={e.key}
            value={e.value}
          >
            {e.text}
          </Button>
        );
      })
    );
  };

  const renderHistory = () => {
    return (
      <ul className="eas-ul">
        {list.map((e, index) => {
          return (
            <li key={index} className="history-item eas-li">
              <span class="line"></span>
              <span class="number"></span>
              <div class="blob">
                Program Overview set to{' '}
                <span className="bold">{e.content}</span> by{' '}
                <span className="bold">{e.header}</span> on{' '}
                <span className="bold">
                  {AmsDateFormatters.getMoment(e.updatedAt).format(
                    'M/D/YYYY, h:mm A'
                  )}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const generateNotes = () => {
    list = list.filter(e => {
      return (
        isEmpty(filter) ||
        e.filter[defaultFilter] === filter ||
        (e.filter[filter] !== undefined && e.filter[filter] === tags[filter])
      );
    });

    return (
      !isEmpty(list) && (
        <Card className="findings-card">
          {list && list.length > 0 && (
            <div style={{ margin: '10px', textAlign: 'center' }}>
              <Accordion>
                <Accordion.Title
                  active={showHistory}
                  index={0}
                  onClick={() => {
                    console.log(showHistory + 'before');
                    setShowHistory(!showHistory);
                    console.log(showHistory + 'fater');
                  }}
                >
                  {!showHistory ? 'See History' : 'Hide History'}
                  <Icon name="dropdown" />
                </Accordion.Title>
                <Accordion.Content active={showHistory}>
                  <div style={{ textAlign: 'left' }}>{renderHistory()}</div>
                </Accordion.Content>
              </Accordion>
            </div>
          )}
        </Card>
      )
    );
  };

  const showFilterDropdown = () => {
    return (
      !isEmpty(filterOptions) && (
        <Segment basic clearing style={{ paddingLeft: '0px' }}>
          <div className="dropdown">
            <Button
              className="filter-notes-dropdown-button"
              id="dropdownMenu1"
              data-toggle="dropdown"
              style={{
                background: 'none',
                borderStyle: 'none',
              }}
              size="medium"
              as={Link}
              to={`#`}
            >
              Filter Notes
              <span className="caret"></span>
            </Button>
            <div className="dropdown-menu">
              <Button.Group vertical>{generateFilterDropdown()}</Button.Group>
            </div>
          </div>
        </Segment>
      )
    );
  };

  return (
    <>
      {showFilterDropdown()}
      {generateNotes()}
    </>
  );
};

export default SurveyNotesHistory;
