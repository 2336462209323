import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import { fetchFUTaskDetail } from '../../../../actions/submissions';
import AmsFormLabel from '../../../../utils/AmsFormLabel';

function CAPAWidget(props) {
  const dispatch = useDispatch();
  const [contentAreas, setContentAres] = useState(null);
  const [disable, setDisabled] = useState(false);

  const { followUpCitations } = useSelector(
    state => state.review.followUpSurveyDetails
  );

  const answersData = useSelector(store => store.survey.consolidatedAnswers);

  const parseQuestionId = questionId => {
    let match;
    if (
      props.formContext &&
      props.formContext.attributes &&
      props.formContext.attributes.hasOwnProperty('citation')
    ) {
      match =
        questionId.match(/^root_([a-fA-F0-9-]+_\d+)/) === null
          ? questionId.match(/^root_([0-9a-fA-F-]+)_/)
          : questionId.match(/^root_([a-fA-F0-9-]+_\d+)/);
    } else {
      match = questionId.match(/^root_([a-fA-F0-9-]+_\d+)/)
        ? questionId.match(/^root_([a-fA-F0-9-]+_\d+)/)
        : questionId.match(/^root_([0-9a-fA-F-]+)_/);
    }

    return match ? match[1] : null;
  };

  useEffect(() => {
    if (
      props.formContext &&
      props.formContext.attributes &&
      props.formContext.attributes.hasOwnProperty('citation') &&
      props.formContext.attributes.citation &&
      followUpCitations &&
      followUpCitations.length > 0
    ) {
      let questionCode = parseQuestionId(props.id);
      let citation =
        answersData &&
        answersData.surveyData &&
        answersData.surveyData[questionCode] &&
        answersData.surveyData[questionCode].CitationPicker;

      if (citation) {
        let citationToCheck = followUpCitations.find(e => e.name === citation);

        if (citationToCheck)
          setDisabled(
            citationToCheck && citationToCheck.isFileAttached ? true : false
          );
      }
    }
  }, [followUpCitations, answersData]);

  /// TODO Pass Guides from EAS Form
  useEffect(() => {
    const fetchFollowUpTaskDetails = async reviewId => {
      if (!reviewId) return;
      let input = { reviewId };
      try {
        const data = await dispatch(fetchFUTaskDetail(input));
        var cas = data.followUpTaskDetail?.guides;
        setContentAres(cas);
      } catch (err) {
        console.log(err);
      }
    };

    fetchFollowUpTaskDetails(props.formContext?.reviewId);
  }, []);

  let caName = 'Content Area';
  let paName = 'Performance Area';
  if (props.required) {
    caName += '*';
    paName += '*';
  }

  return (
    <>
      <Form.Field style={{ marginBottom: '15px' }}>
        <AmsFormLabel name={caName} fieldLabel="filterContentArea">
          <Dropdown
            placeholder="Select Content Area"
            value={props.value?.content_area}
            name="contentArea"
            id={props.id + '_content_area'}
            options={buildContentArea(contentAreas)}
            onChange={(e, data) => {
              var cas = contentAreas.filter(x => x.name == data.value);
              if (cas && cas.length > 0) {
                var valToSave = {
                  content_area: data.value,
                  performance_area: null,
                };
                if (props.onChange) {
                  props.onChange(valToSave);
                }
              }
            }}
            fluid
            search
            selection
            required={props.required}
            disabled={props.readonly || props.disabled || disable}
            aria-labelledby="filterContentArea"
            searchInput={{
              id: 'filterContentArea',
              title: 'Select a Content Area',
              'aria-labelledby': 'filterContentArea',
            }}
          />
        </AmsFormLabel>
      </Form.Field>
      <Form.Field>
        <AmsFormLabel
          name={paName}
          helpText={true}
          fieldLabel="filterPerformanceArea"
        >
          <Dropdown
            placeholder="Select Performance Area"
            name="performanceArea"
            value={props.value?.performance_area}
            id={props.id + '_performance_area'}
            onChange={(e, data) => {
              var valToSave = { ...props.value };
              valToSave.performance_area = data.value;
              if (props.onChange) {
                props.onChange(valToSave);
              }
            }}
            options={buildPerformanceAreas(contentAreas, props.value)}
            fluid
            search
            required={props.required}
            disabled={props.readonly || props.disabled || disable}
            selection
            aria-labelledby="filterPerformanceArea"
            searchInput={{
              id: 'filterPerformanceArea',
              title: 'Filter by Performance Area',
            }}
          />
        </AmsFormLabel>
      </Form.Field>
    </>
  );
}

const buildPerformanceAreas = (contentAreas, value) => {
  var performanceAreas = null;
  if (contentAreas) {
    var selectedCA = contentAreas?.filter(c => c.name == value?.content_area);
    if (selectedCA && selectedCA[0]) {
      performanceAreas = selectedCA[0].performanceAreas;
    }
  }
  let performanceAreasOptions =
    performanceAreas &&
    performanceAreas.map(performanceArea => {
      return {
        key: performanceArea.name,
        value: performanceArea.name,
        text: performanceArea.name,
      };
    });
  return performanceAreasOptions ?? [];
};

const buildContentArea = contentAreas => {
  let contentAreaOptions =
    contentAreas &&
    contentAreas.map(contentArea => {
      return {
        key: contentArea.guideCode,
        value: contentArea.name,
        text: contentArea.name,
      };
    });
  return contentAreaOptions ?? [];
};

CAPAWidget.defaultProps = {
  autofocus: false,
};

export default CAPAWidget;
