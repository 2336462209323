import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Panel, PanelGroup,  } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Menu,
  Modal,
  Segment,
  Step,
  Tab,
} from 'semantic-ui-react';
import AmsAlert from '../../utils/AmsAlert';
import AmsFormLabel from '../../utils/AmsFormLabel';

import _ from 'lodash';
import classLogo from './assets/ClassLogo.png';

// Import Actions
import { fetchNotes } from '../../actions/noteActions';
import { fetchUserProfile } from '../../actions/profileActions';
import {
  fetchClassTaskDetail,
  fetchVersionHistory,
  requestReplaceClass,
  requestSkipClass,
  requestSkipSecondObservation,
} from '../../actions/submissions';

// Import components.
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import SlidingContainer from '../../utils/layout/SlidingContainer';
import NotesContainer from '../Notes/index';

// Import acl.
import { acl } from '../../config';

// Import helper function.
import enforceRole from '../../utils/EnforceRole';
import { formatUserNotes } from '../Notes/util.js';

// Import Form
import CLASSObservationCycle from './CLASSObservationCycle';

import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsModal from '../../utils/AmsModal';
import AmsTable from '../../utils/AmsTable';

const skipReasonOptions = [
  {
    key: '1',
    value:
      'The reviewer is not fluent in the predominant language of the class',
    text: 'The reviewer is not fluent in the predominant language of the class',
  },
  {
    key: '2',
    value: 'Less than 50% of children were present in the class',
    text: 'Less than 50% of children were present in the class',
  },
  {
    key: '3',
    value:
      'The class had a short-term substitute teacher who had been in the room less than 10 days',
    text:
      'The class had a short-term substitute teacher who had been in the room less than 10 days',
  },
  {
    key: '4',
    value:
      'The class has a new teacher who has been in the class less than 10 days',
    text:
      'The class has a new teacher who has been in the class less than 10 days',
  },
  {
    key: '5',
    value: 'The class is engaging in a daily activity that cannot be observed',
    text: 'The class is engaging in a daily activity that cannot be observed',
  },
  {
    key: '6',
    value: 'An unexpected event occured and the class was not observable',
    text: 'An unexpected event occured and the class was not observable',
  },
  {
    key: '7',
    value: 'A planned event was taking place and the class was not observable',
    text: 'A planned event was taking place and the class was not observable',
  },
  {
    key: '8',
    value:
      'The class was already selected as a replacement by another reviewer',
    text: 'The class was already selected as a replacement by another reviewer',
  },
  {
    key: '9',
    value:
      'Time constraint or logistical challenges prevented the observation of this class',
    text:
      'Time constraint or logistical challenges prevented the observation of this class',
  },
  {
    key: '10',
    value: 'The HSES center data was inaccurate',
    text: 'The HSES center data was inaccurate',
  },
  {
    key: '11',
    value: 'Cannot observer due to weather',
    text: 'Cannot observer due to weather',
  },
  { key: '12', value: 'Previously observed', text: 'Previously observed' },
  { key: '13', value: 'Other', text: 'Other' },
];
const secondSkipReasonOptions = [
  {
    key: '1',
    value:
      'The reviewer is not fluent in the predominant language of the class',
    text: 'The reviewer is not fluent in the predominant language of the class',
  },
  {
    key: '2',
    value: 'Less than 50% of children were present in the class',
    text: 'Less than 50% of children were present in the class',
  },
  {
    key: '3',
    value: 'The class has a short-term substitute teacher',
    text: 'The class has a short-term substitute teacher',
  },
  {
    key: '4',
    value: 'The class is engaging in a daily activity that cannot be observed',
    text: 'The class is engaging in a daily activity that cannot be observed',
  },
  {
    key: '5',
    value: 'An unexpected event occured and the class was not observable',
    text: 'An unexpected event occured and the class was not observable',
  },
  {
    key: '6',
    value: 'A planned event was taking place and the class was not observable',
    text: 'A planned event was taking place and the class was not observable',
  },
  {
    key: '7',
    value:
      'The class was already selected as a replacement by another reviewer',
    text: 'The class was already selected as a replacement by another reviewer',
  },
  {
    key: '8',
    value:
      'Time constraint or logistical challenges prevented the observation of this class',
    text:
      'Time constraint or logistical challenges prevented the observation of this class',
  },
  {
    key: '9',
    value: 'The HSES center data was inaccurate',
    text: 'The HSES center data was inaccurate',
  },
  { key: '10', value: 'Other', text: 'Other' },
];
const HSESInaccuracyOptions = [
  {
    key: '1',
    value: 'The class did not have HEAD start children enrolled',
    text: 'The class did not have HEAD start children enrolled',
  },
  {
    key: '2',
    value: 'The class was EHS instead of HS',
    text: 'The class was EHS instead of HS',
  },
  {
    key: '3',
    value: 'The class/center has been permanently closed',
    text: 'The class/center has been permanently closed',
  },
  {
    key: '4',
    value: 'The class/center was temporarly closed',
    text: 'The class/center was temporarly closed',
  },
  {
    key: '5',
    value: 'The class does not exist anymore',
    text: 'The class does not exist anymore',
  },
];

class CLASSSurveyPage extends Component {
  state = {
    centerName: '',
    sampleName: '',
    observationCycle: {},
    active: 'Observation Cycle One',
    centerOptions: [],
    classrooms: [],
    selectedClassroom: {},
    selectedClassroomReviewer: {},
    selectedCenter: {},
    selectedFormId: '',
    selectedSubmissionId: '',
    observationCycleOneSubmissionId: '',
    observationCycleTwoSubmissionId: '',
    showModal: false,
    reasonSelected: '',
    showSkipConfirm: false,
    givenReason: '',
    centerReplacement: false,
    versionHistory: [],
    version: '',
    reviewerId: '',
    maxVersion: '',
    skipErrors: [],
    showMovingAlert: false,
    reviewId: '',
  };

  componentDidMount() {
    this.fetchClassTaskDetail();
    this.fetchSurveyNotes();
    this.setState({
      reviewerId: !_.isEmpty(this.props.location.query.submitterOid)
        ? this.props.location.query.submitterOid
        : this.props.currentUser.oid,
      reviewType: this.props.location.query
        ? this.props.location.query.reviewType
        : 'CLASS',
    });
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.selectedSubmission !== this.props.selectedSubmission) {
      const versions = nextProps.selectedSubmission.versionHistory.map(
        version => {
          return version.version;
        }
      );
      this.setState({
        versionHistory: nextProps.selectedSubmission.versionHistory,
        maxVersion: _.max(versions),
      });
    }
  };

  fetchSurveyNotes = () => {
    const body = {
      filters: {
        tags: {
          reviewId: this.context.router.params.reviewId,
          type: 'survey_collection',
        },
      },
    };

    this.props.fetchNotes(body);
  };

  fetchClassTaskDetail = () => {
    let request = {
      filters: {
        reviewId: `${this.context.router.params.reviewId}`, //'1805555C'
      },
    };
    const centerNameText = center => {
      if (center.allClassesSkipped) return `(S) ${center.centerName}`;
      else if (center.fromResidualClassListFlag)
        return `(R) ${center.centerName}`;
      else return `${center.centerName}`;
    };
    if (this.props.location.query) {
      if (this.props.location.query.roleFlag === 'true') {
        request = {
          oid: this.props.location.query.submitterOid,
          filters: {
            reviewId: `${this.context.router.params.reviewId}`, //'1805555C'
          },
        };
      } else {
        request = {
          filters: {
            reviewId: `${this.context.router.params.reviewId}`, //'1805555C'
          },
        };
      }
      this.setState({
        granteeName: this.props.location.query.granteeName,
        granteeId: this.props.location.query.granteeId,
      });
    }
    this.props.fetchClassTaskDetail(request).then(data => {
      if (data.classTaskDetail) {
        const centerOptions = data.classTaskDetail.map(center => {
          if (center.allClassesSubmitted)
            return {
              key: center.centerName,
              value: center.centerName,
              text: centerNameText(center),
              icon: {
                name: 'check circle',
                color: 'green',
                size: 'large',
              },
            };
          else
            return {
              key: center.centerName,
              value: center.centerName,
              text: centerNameText(center),
            };
        });
        this.setState(
          {
            centerOptions: centerOptions,
          },
          () => {
            if (
              !_.isEmpty(this.state.selectedClassroom) &&
              !_.isEmpty(this.state.selectedCenter)
            ) {
              const { selectedClassroom, centerName } = this.state;
              const updatedCenter = _.find(data.classTaskDetail, center => {
                return center.centerName === centerName;
              });
              const updatedClassrooms = updatedCenter.classrooms;
              const updatedClassroom = _.find(updatedClassrooms, classroom => {
                return (
                  classroom.classRoomName === selectedClassroom.classRoomName
                );
              });
              this.setState({
                selectedClassroom: updatedClassroom,
                selectedCenter: updatedCenter,
              });
            }
          }
        );
      }
    });
  };

  handleClick = (e, data) => {
    e.preventDefault();
    const { selectedClassroom, surveyEdited } = this.state;

    if (surveyEdited) {
      this.setState({
        showMovingAlert: true,
        requestedFrom: {
          e: e,
          data: data,
          name: 'observationCycle',
        },
      });
      return;
    }

    const input = {
      reviewId: this.context.router.params.reviewId,
      classSampleId: selectedClassroom.classSampleId,
      oid: this.props.location.query.submitterOid,
    };
    this.props.fetchVersionHistory(input);

    this.setState({ active: data.title, observationCycle: {} }, () => {
      const observationCycle = this.handleObservationCycle(selectedClassroom);
      this.setState({
        ...this.state,
        selectedClassroom: selectedClassroom,
        observationCycle: observationCycle,
        version: null,
      });
    });
  };

  handleChange = (e, { name, value }) => {
    const { surveyEdited } = this.state;
    if (surveyEdited) {
      this.setState({
        showMovingAlert: true,
        requestedFrom: {
          e: e,
          data: {
            name: name,
            value: value,
          },
          name: 'centerName',
        },
      });
      return;
    }
    if (name === 'centerName') {
      const selectedCenter = _.find(this.props.classTaskDetail, center => {
        return center.centerName === value;
      });
      const classrooms = selectedCenter.classrooms;

      this.props.fetchUserProfile(this.state.reviewerId).then(data => {
        this.setState({
          selectedClassroomReviewer: data.profile,
          observationCycleOneSubmissionId: '',
          observationCycleTwoSubmissionId: '',
          showModal: false,
          reasonSelected: '',
          givenReason: '',
          skipClassroom: false,
          centerReplacement: false,
          predominantLanguage: '',
          HSESInaccuracy: '',
          version: '',
          versionHistory: {},
        });
      });
      if (classrooms[0].observationCycleOneSubmissionId) {
        this.setState(
          {
            active: 'Observation Cycle Two',
            [name]: value,
            classrooms: classrooms,
            selectedCenter: selectedCenter,
            observationCycleOneSubmissionId: '',
            observationCycleTwoSubmissionId: '',
            paneActiveIndex: 0,
          },
          () => {
            const observationCycle = this.handleObservationCycle(classrooms[0]);
            this.setState({
              ...this.state,
              selectedClassroom: classrooms[0],
              observationCycle: observationCycle,
              observationCycleOneSubmissionId:
                classrooms[0].observationCycleOneSubmissionId,
            });
          }
        );
      } else if (_.isEmpty(classrooms[0].observationCycleOneSubmissionId)) {
        this.setState(
          {
            active: 'Observation Cycle One',
            [name]: value,
            classrooms: classrooms,
            selectedCenter: selectedCenter,
            observationCycleOneSubmissionId: '',
            observationCycleTwoSubmissionId: '',
            paneActiveIndex: 0,
          },
          () => {
            const observationCycle = this.handleObservationCycle(classrooms[0]);
            this.setState({
              ...this.state,
              selectedClassroom: classrooms[0],
              observationCycle: observationCycle,
            });
          }
        );
      }
      return;
    }
    this.setState({
      [name]: value,
    });
  };

  handleTabChange = (e, data) => {
    const { selectedCenter, reviewerId, surveyEdited } = this.state;

    if (surveyEdited) {
      this.setState({
        showMovingAlert: true,
        requestedFrom: {
          e: e,
          data: data,
          name: 'classroomName',
        },
      });
      return;
    }

    this.props.fetchUserProfile(reviewerId).then(data => {
      this.setState({
        selectedClassroomReviewer: data.profile,
        observationCycleOneSubmissionId: '',
        observationCycleTwoSubmissionId: '',
        showModal: false,
        reasonSelected: '',
        givenReason: '',
        skipClassroom: false,
        centerReplacement: false,
        predominantLanguage: '',
        HSESInaccuracy: '',
        version: '',
        versionHistory: {},
      });
    });

    if (
      selectedCenter.classrooms[data.activeIndex]
        .observationCycleOneSubmissionId
    ) {
      this.setState(
        {
          active: 'Observation Cycle Two',
          observationCycle: {},
          observationCycleOneSubmissionId: '',
          observationCycleTwoSubmissionId: '',
          paneActiveIndex: data.activeIndex,
        },
        () => {
          const observationCycle = this.handleObservationCycle(
            selectedCenter.classrooms[data.activeIndex]
          );
          this.setState({
            ...this.state,
            selectedClassroom: selectedCenter.classrooms[data.activeIndex],
            observationCycle: observationCycle,
            observationCycleOneSubmissionId:
              selectedCenter.classrooms[data.activeIndex]
                .observationCycleOneSubmissionId,
          });
        }
      );
    } else if (
      _.isEmpty(
        selectedCenter.classrooms[data.activeIndex]
          .observationCycleOneSubmissionId
      )
    ) {
      this.setState(
        {
          active: 'Observation Cycle One',
          observationCycle: {},
          observationCycleOneSubmissionId: '',
          observationCycleTwoSubmissionId: '',
          paneActiveIndex: data.activeIndex,
        },
        () => {
          const observationCycle = this.handleObservationCycle(
            selectedCenter.classrooms[data.activeIndex]
          );
          this.setState({
            ...this.state,
            selectedClassroom: selectedCenter.classrooms[data.activeIndex],
            observationCycle: observationCycle,
          });
        }
      );
    }
  };

  handleObservationCycle = classroom => {
    const { active } = this.state;
    if (active === 'Observation Cycle One') {
      return {
        selectedFormId: classroom.observationCycleOneFormId,
        selectedSubmissionId: classroom.observationCycleOneSubmissionId,
        observationCycle: 1,
      };
    } else
      return {
        selectedFormId: classroom.observationCycleTwoFormId,
        selectedSubmissionId: classroom.observationCycleTwoSubmissionId,
        observationCycle: 2,
      };
  };

  updateObservationCycle = observationCycle => {
    this.setState(
      {
        observationCycle: observationCycle,
      },
      () => {
        if (observationCycle.observationCycle === 1) {
          this.setState({
            observationCycleOneSubmissionId:
              observationCycle.selectedSubmissionId,
            version: null,
          });
        } else if (observationCycle.observationCycle === 2) {
          this.setState({
            observationCycleTwoSubmissionId:
              observationCycle.selectedSubmissionId,
            version: null,
          });
        }
        this.fetchClassTaskDetail();
      }
    );
  };

  closeEditModal = () => {
    this.setState({
      showModal: false,
      reasonSelected: '',
      givenReason: '',
      skipClassroom: false,
      centerReplacement: false,
      predominantLanguage: '',
      HSESInaccuracy: '',
      skipErrors: [],
    });
  };

  handleSkipDialogChanges = (__e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  handleSkipDialogToggle = (_e, { name, checked }) => {
    if (name === 'skipClassroom' && checked) {
      this.setState({
        showSkipConfirm: true,
      });
    }
    this.setState({
      [name]: checked,
    });
  };

  showSkipErrors() {
    const { skipErrors } = this.state;

    if (skipErrors && skipErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {skipErrors.map((errorObject, index) => (
              <li key={index}>{errorObject.message}</li>
            ))}
          </ul>
        </Alert>
      );
  }

  showSkipModal = () => {
    const {
      showModal,
      loading,
      reasonSelected,
      skipClassroom,
      givenReason,
      centerReplacement,
      predominantLanguage,
      HSESInaccuracy,
    } = this.state;
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        size="large"
        // onHide={this.closeEditModal}
      >
        {' '}
        <Modal.Header>Replacement/Skip Selection</Modal.Header>
        <Modal.Content>
          <Form id="replacementModal" loading={loading}>
            {this.showSkipErrors()}

            {/* <Form.Group> */}
            <Form.Field>
              <label htmlFor="reasonSelected">
                Indicate the reason you are unable to observe this class
              </label>
              <Dropdown
                id="reasonSelected"
                name="reasonSelected"
                selection
                options={skipReasonOptions}
                onChange={this.handleSkipDialogChanges}
                placeholder={'Select Reason'}
                value={reasonSelected}
                openOnFocus={false}
              />
            </Form.Field>
            {reasonSelected ===
              'The reviewer is not fluent in the predominant language of the class' && (
              <Form.Field>
                <label htmlFor="predominantLanguage">
                  Please select the predominant language of the class
                </label>
                <AmsLookupDropdown
                  id="predominantLanguage"
                  name="predominantLanguage"
                  selection
                  fluid
                  onChange={this.handleSkipDialogChanges}
                  value={predominantLanguage}
                  category={'languages'}
                />
              </Form.Field>
            )}
            {reasonSelected === 'The HSES center data was inaccurate' && (
              <Form.Field>
                <label htmlFor="HSESInaccuracyReason">
                  How was the HSES center data inaccurate?
                </label>
                <Dropdown
                  id="HSESInaccuracyReason"
                  name="HSESInaccuracy"
                  selection
                  options={HSESInaccuracyOptions}
                  onChange={this.handleSkipDialogChanges}
                  placeholder={'Select Reason'}
                  value={HSESInaccuracy}
                />
              </Form.Field>
            )}
            <Form.Field>
              <label htmlFor="givenReasonTextArea">
                Describe why this class cannot be observed at this time and why
                the observation could not be rescheduled for another day
              </label>
              <Form.TextArea
                id="givenReasonTextArea"
                autoHeight
                name="givenReason"
                title="Explain Reason"
                onChange={this.handleSkipDialogChanges}
                placeholder="Please explain here"
                value={givenReason}
              />
            </Form.Field>
            <label className="skip-info-label-color">
              Only select this option if no other class/group is available in
              this center.
            </label>

            <Form.Field>
              <Form.Checkbox
                label={{
                  htmlFor: 'skipClassRoomCheckbox',
                  children: 'Do not select a replacement class/group',
                }}
                id="skipClassRoomCheckbox"
                name="skipClassroom"
                checked={skipClassroom}
                disabled={centerReplacement ? true : false}
                onChange={this.handleSkipDialogToggle}
              />
            </Form.Field>
            <Form.Field>
              <Form.Checkbox
                label={{
                  children:
                    'Do not select a replacement class/group from the same center',
                  htmlFor: 'centerReplacementCheckbox',
                }}
                id="centerReplacementCheckbox"
                name="centerReplacement"
                disabled={skipClassroom ? true : false}
                checked={centerReplacement}
                onChange={this.handleSkipDialogToggle}
              />
            </Form.Field>
            <label className="skip-info-label-color">
              Please provide a detailed justification for why you could not
              observe this class/group.
            </label>
            {/* </Form.Group> */}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="replacementModal"
            onClick={skipClassroom ? this.skipClass : this.replaceClass}
            disabled={this.isButtonDisabled()}
          >
            {skipClassroom ? `Skip` : `Replace`}
          </Button>
          <Button onClick={this.closeEditModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  showSecondSkipModal = () => {
    const { showModal, loading, reasonSelected, givenReason } = this.state;
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        size="large"
        // onHide={this.closeEditModal}
      >
        <Modal.Header>Replacement/Skip Selection</Modal.Header>
        <Modal.Content>
          <Form loading={loading}>
            {this.showSkipErrors()}

            {/* <Form.Group> */}
            <Form.Field>
              <label htmlFor="secondSkipReason">
                Indicate the reason you are unable to complete a second
                observation cycle for this class
              </label>
              <Dropdown
                id="secondSkipReason"
                name="reasonSelected"
                selection
                options={secondSkipReasonOptions}
                onChange={this.handleSkipDialogChanges}
                placeholder={'Select Reason'}
                value={reasonSelected}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="givenReasonSecondTextArea">
                Describe in detail why you were unable to observe a second
                observation cycle in this class
              </label>
              <Form.TextArea
                autoHeight
                id="givenReasonSecondTextArea"
                name="givenReason"
                onChange={this.handleSkipDialogChanges}
                placeholder="Please explain here"
                value={givenReason}
              />
            </Form.Field>
            <label className="skip-info-label-color">
              Please provide a detailed justification for why you could not
              observe this class/group.
            </label>
            {/* </Form.Group> */}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={this.skipSecondObservation}
            disabled={!reasonSelected || !givenReason ? true : false}
          >
            {'Skip'}
          </Button>
          <Button onClick={this.closeEditModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  isButtonDisabled = () => {
    const {
      reasonSelected,
      givenReason,
      HSESInaccuracy,
      predominantLanguage,
    } = this.state;
    if (
      reasonSelected ===
      'The reviewer is not fluent in the predominant language of the class'
    ) {
      if (reasonSelected && givenReason && predominantLanguage) {
        return false;
      } else return true;
    } else if (reasonSelected === 'The HSES center data was inaccurate') {
      if (reasonSelected && givenReason && HSESInaccuracy) {
        return false;
      } else return true;
    } else if (reasonSelected && givenReason) {
      return false;
    } else return true;
  };

  skipClass = () => {
    const {
      reasonSelected,
      givenReason,
      predominantLanguage,
      HSESInaccuracy,
      selectedClassroom,
    } = this.state;
    const request = {
      reviewId: this.context.router.params.reviewId,
      classSampleId: selectedClassroom.classSampleId,
      skipReason: reasonSelected,
      skipReasonGiven: givenReason,
      predominantLanguage: predominantLanguage,
      HSESInaccuracy: HSESInaccuracy,
    };
    this.props
      .requestSkipClass(request)
      .then(data => {
        if (data.result === 'Success') {
          this.setState(
            {
              showModal: false,
              selectedClassroom: {
                ...selectedClassroom,
                classroomSkipRequested: true,
              },
              skipErrors: [],
            },
            () => {
              this.fetchClassTaskDetail();
            }
          );
        }
      })
      .catch(error => {
        this.setState({
          skipErrors: [
            ...this.state.skipErrors,
            (error.response || { data: Error('unknown error') }).data,
          ],
        });
      });
  };

  skipSecondObservation = () => {
    const { reasonSelected, givenReason, selectedClassroom } = this.state;
    const request = {
      reviewId: this.context.router.params.reviewId,
      classSampleId: selectedClassroom.classSampleId,
      obCycleSkipJustification: reasonSelected,
      obCycleSkipJustificationReason: givenReason,
    };
    this.props
      .requestSkipSecondObservation(request)
      .then(data => {
        if (data.result === 'Success') {
          this.setState(
            {
              showModal: false,
              selectedClassroom: {
                ...selectedClassroom,
                obCycleSkipRequested: true,
              },
              skipErrors: [],
            },
            () => {
              this.fetchClassTaskDetail();
            }
          );
        }
      })
      .catch(error => {
        this.setState({
          skipErrors: [
            ...this.state.skipErrors,
            (error.response || { data: Error('unknown error') }).data,
          ],
        });
      });
  };

  replaceClass = () => {
    const {
      reasonSelected,
      givenReason,
      centerReplacement,
      predominantLanguage,
      HSESInaccuracy,
      selectedClassroom,
    } = this.state;
    const request = {
      reviewId: this.context.router.params.reviewId,
      classSampleId: selectedClassroom.classSampleId,
      replacementReason: reasonSelected,
      replacementReasonGiven: givenReason,
      predominantLanguage: predominantLanguage,
      HSESInaccuracy: HSESInaccuracy,
      centerReplacement: centerReplacement,
    };
    this.props
      .requestReplaceClass(request)
      .then(data => {
        if (data.result === 'Success') {
          this.setState(
            {
              showModal: false,
              selectedClassroom: {
                ...selectedClassroom,
                replacementRequested: true,
              },
              skipErrors: [],
            },
            () => {
              this.fetchClassTaskDetail();
            }
          );
        }
      })
      .catch(error => {
        this.setState({
          skipErrors: [
            ...this.state.skipErrors,
            (error.response || { data: Error('unknown error') }).data,
          ],
        });
      });
  };

  showSkipConfirmationAlert() {
    // Check if Required fileds are added

    return (
      <AmsAlert
        show={this.state.showSkipConfirm}
        title="Skip Observation"
        text={`Note: This button should only be selected when you are closing out the review.
      Are you sure you want to skip this class/group without selecting a replacement? You should only do it if you have approval from the Review Team Leader. The Review Team Leader will be notified that replacement class/group was not selected.`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({
            showSkipConfirm: false,
            skipClassroom: false,
          });
        }}
        onConfirm={() => {
          this.setState({ showSkipConfirm: false });
          // this.saveData();
        }}
      />
    );
  }

  steps = classroom => {
    const {
      selectedClassroomReviewer,
      active,
      observationCycleOneSubmissionId,
      observationCycleTwoSubmissionId,
      selectedClassroom,
    } = this.state;

    const skipText = () => {
      if (
        !_.isEmpty(this.props.location.query.reviewStatus) &&
        this.props.location.query.reviewStatus.toLowerCase() === 'cancelled'
      )
        return '';
      if (
        (selectedClassroom.classroomSkipRequested &&
          selectedClassroom.classroomSkipApproved !== false) ||
        selectedClassroom.replacementRequested ||
        (selectedClassroom.observationCycleOneSubmissionId &&
          selectedClassroom.observationCycleTwoSubmissionId)
      )
        return '';
      else if (!selectedClassroom.observationCycleOneSubmissionId)
        return 'I cannot observe this class/group';
      else if (
        selectedClassroom.observationCycleOneSubmissionId &&
        (!selectedClassroom.obCycleSkipRequested ||
          selectedClassroom.obCycleSkipApproved === false) &&
        !selectedClassroom.observationCycleTwoSubmissionId
      )
        return 'I am not able to complete a second observation cycle in this class';
      else if (
        selectedClassroom.observationCycleOneSubmissionId &&
        selectedClassroom.obCycleSkipRequested &&
        selectedClassroom.obCycleSkipApproved !== false &&
        !selectedClassroom.obCycleSkipApproved
      )
        return '';
      else if (
        selectedClassroom.observationCycleOneSubmissionId &&
        selectedClassroom.obCycleSkipRequested &&
        selectedClassroom.obCycleSkipApproved
      )
        return 'Classroom Observation Cycle 2 skip request has been approved';
    };

    const skipTextHeader = skipText();

    return (
      <div>
        {skipTextHeader && skipTextHeader.length > 0 && (
          <h3 style={{ display: 'flex', justifyContent: 'center' }}>
            <Link
              onClick={e => {
                e.preventDefault();
                this.setState({ showModal: true });
              }}
              href="#"
            >
              {skipTextHeader}
            </Link>
          </h3>
        )}
        <Segment>
          <div>
            <span
              role="heading"
              aria-level="3"
              className="field-title"
              aria-label="Reviewer"
            >
              Reviewer:{' '}
            </span>
            {!_.isEmpty(this.props.location.query.submitterName)
              ? ` ${this.props.location.query.submitterName}`
              : ` ${selectedClassroomReviewer.firstName} ${selectedClassroomReviewer.lastName}`}
          </div>
          <div>
            <span
              role="heading"
              aria-level="3"
              className="field-title"
              aria-label="Program Type"
            >
              Program Type:{' '}
            </span>
            {selectedClassroom.programType}
          </div>
          <div>
            <span
              role="heading"
              aria-level="3"
              className="field-title"
              aria-label="Option Variation"
            >
              Option Variation:{' '}
            </span>
            {selectedClassroom.optionVariation}
          </div>
          <div>
            <span
              role="heading"
              aria-level="3"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:{' '}
            </span>
            {selectedClassroom.submissionStatus}
          </div>
          <div>
            <span
              role="heading"
              aria-level="3"
              className="field-title"
              aria-label="Program Option"
            >
              Program Option:{' '}
            </span>
            {selectedClassroom.programOption}
          </div>
          <div>
            <span
              role="heading"
              aria-level="3"
              className="field-title"
              aria-label="Days of Operation"
            >
              Days of Operation:{' '}
            </span>
            {selectedClassroom.schedule}
          </div>
        </Segment>
        <Step.Group fluid>
          <Step
            href="#"
            as={Link}
            active={active === 'Observation Cycle One' ? true : false}
            link
            // completed={
            //   classroom.observationCycleOneSubmissionId ||
            //   observationCycleOneSubmissionId ||
            //   selectedClassroom.observationCycleOneSubmissionId
            //     ? true
            //     : false
            // }
            onClick={this.handleClick}
            title="Observation Cycle One"
            description={
              classroom.observationCycleOneSubmissionId ||
              observationCycleOneSubmissionId ||
              selectedClassroom.observationCycleOneSubmissionId
                ? 'Completed'
                : 'Complete observation cycle one'
            }
          />
          <Step
            href="#"
            as={Link}
            disabled={
              classroom.observationCycleOneSubmissionId ||
              observationCycleOneSubmissionId ||
              selectedClassroom.observationCycleOneSubmissionId
                ? false
                : true
            }
            active={active === 'Observation Cycle Two' ? true : false}
            link
            // completed={
            //   classroom.observationCycleTwoSubmissionId ||
            //   observationCycleTwoSubmissionId ||
            //   selectedClassroom.observationCycleTwoSubmissionId
            //     ? true
            //     : false
            // }
            onClick={this.handleClick}
            title="Observation Cycle Two"
            description={
              classroom.observationCycleTwoSubmissionId ||
              observationCycleTwoSubmissionId ||
              selectedClassroom.observationCycleTwoSubmissionId
                ? 'Completed'
                : 'Complete observation cycle two'
            }
          />
        </Step.Group>
        <br />
      </div>
    );
  };

  responsiveDropdownPanes = () => {
    const { centerName, classroomName, active } = this.state;
    return (
      <Tab.Pane>
        {this.steps()}
        <div>
          <CLASSObservationCycle
            centerName={centerName}
            classroomName={classroomName}
            observationCycle={active}
          />
        </div>
      </Tab.Pane>
    );
  };

  registerSurveyChanges = surveyEdited => {
    this.setState({ surveyEdited });
  };

  renderMovingMessage = () => {
    const { showMovingAlert, requestedFrom } = this.state;
    return (
      <AmsAlert
        show={showMovingAlert}
        title="Are you sure you want to move away from this form?"
        text={
          'You selected another page, are you sure you want to leave the current page?'
        }
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Continue without saving'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({
            showMovingAlert: false,
          });
        }}
        onConfirm={() => {
          if (
            requestedFrom &&
            requestedFrom.name &&
            requestedFrom.name === 'centerName'
          ) {
            this.setState(
              {
                surveyEdited: false,
                showMovingAlert: false,
              },
              () => this.handleChange(requestedFrom.e, requestedFrom.data)
            );
          } else if (
            requestedFrom &&
            requestedFrom.name &&
            requestedFrom.name === 'classroomName'
          ) {
            this.setState(
              {
                surveyEdited: false,
                showMovingAlert: false,
              },
              () => this.handleTabChange(requestedFrom.e, requestedFrom.data)
            );
          } else if (
            requestedFrom &&
            requestedFrom.name &&
            requestedFrom.name === 'observationCycle'
          ) {
            this.setState(
              {
                surveyEdited: false,
                showMovingAlert: false,
              },
              () => this.handleClick(requestedFrom.e, requestedFrom.data)
            );
          }
        }}
      />
    );
  };

  panes = () => {
    const {
      selectedCenter,
      selectedClassroom,
      observationCycle,
      selectedFormId,
      selectedSubmissionId,
      version,
      maxVersion,
    } = this.state;
    const classroomText = classroom => {
      if (
        classroom.classroomSkipRequested &&
        classroom.classroomSkipApproved !== false
      )
        return `${classroom.classRoomName} (S)`;
      else if (classroom.fromResidualClassListFlag)
        return `${classroom.classRoomName} (R)`;
      else return `${classroom.classRoomName}`;
    };

    const classroomPanes = selectedCenter.classrooms.map((classroom, index) => {
      const icon =
        classroom.submissionStatus.toLowerCase() === 'submitted'
          ? 'check circle'
          : '';

      return {
        menuItem: (
          <Menu.Item
            as={Link}
            key={index}
            to="#"
            onClick={e => e.preventDefault()}
          >
            {classroomText(classroom)}
            <Icon name={icon} color="green" size="large"></Icon>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            {this.steps(classroom)}
            <div>
              <CLASSObservationCycle
                selectedCenter={selectedCenter}
                selectedClassroom={selectedClassroom}
                observationCycle={observationCycle}
                selectedFormId={selectedFormId}
                selectedSubmissionId={selectedSubmissionId}
                updateObservationCycle={this.updateObservationCycle}
                version={version}
                maxVersion={maxVersion}
                reset={{
                  runOnce: false,
                  editMode: false,
                  formErrors: {},
                  surveyData: {},
                  rawData: {},
                  key: AmsDateFormatters.getMoment(),
                }}
                isShowES={this.context.router.params.fiscalYear != 2022}
                reviewType={this.state.reviewType}
                reviewStatus={
                  !_.isEmpty(this.props.location.query.reviewStatus) &&
                  this.props.location.query.reviewStatus
                }
                registerSurveyChanges={this.registerSurveyChanges}
              />
            </div>
          </Tab.Pane>
        ),
      };
    });
    return classroomPanes;
  };

  renderContent = () => {
    const { centerName, centerOptions, paneActiveIndex } = this.state;
    return (
      <div>
        <Form>
          <Form.Field>
            <AmsFormLabel name="Select Center" fieldLabel="selectCenterName">
              <Dropdown
                aria-labelledby="selectCenterName"
                searchInput={{ id: 'selectCenterName' }}
                name="centerName"
                onChange={this.handleChange}
                options={centerOptions}
                placeholder="Choose Center"
                selection
                search
                upward
                value={centerName}
                openOnFocus={false}
              />
            </AmsFormLabel>
          </Form.Field>
          <input type="submit" value="submit" hidden />
        </Form>
        <br />
        <br />
        {centerName && (
          <Form.Group>
            <Tab
              menu={{
                fluid: true,
                vertical: true,
                tabular: true,
                stackable: true,
              }}
              activeIndex={paneActiveIndex ? paneActiveIndex : 0}
              panes={this.panes()}
              onTabChange={this.handleTabChange}
            />
            {/* <Responsive as={Tab} minWidth={768} menu={{ fluid: true, vertical: true, tabular: true, stackable: true }} panes={this.panes()} />
            <Responsive {...Responsive.onlyMobile}>
              <label>Select Class</label>
              <Responsive
                {...Responsive.onlyMobile}
                as={Form.Dropdown}
                name='classroomName'
                onChange={this.handleChange}
                options={[
                  { key: 1, text: 'Classroom 1', value: 'classroom1' },
                  { key: 2, text: 'Classroom 2', value: 'classroom2' },
                  { key: 3, text: 'Classroom 3', value: 'classroom3' },
                ]}
                placeholder='Choose classroom'
                selection
                search
                value={classroomName}
              />
              <Responsive {...Responsive.onlyMobile}>
                <br />
                {this.responsiveDropdownPanes()}
              </Responsive>
            </Responsive> */}
          </Form.Group>
        )}
      </div>
    );
  };

  renderVersionTable = () => {
    const { versionHistory } = this.state;

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            this.setState({
              version: row.version,
            });
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, _row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    if (!_.isEmpty(this.state.versionHistory))
      return (
        <AmsTable
          remote={false}
          basic
          total={versionHistory.length}
          columns={columns}
          keyField="version"
          ref="table"
          data={versionHistory ? versionHistory : []}
        />
      );
  };

  updateVersion = (selectedSubmissionId, version) => {
    let observationCycle = this.state.observationCycle;
    observationCycle.selectedSubmissionId = selectedSubmissionId;

    this.setState({
      version,
      observationCycle: observationCycle,
    });
  };

  render() {
    const { currentUser, notes } = this.props;
    const { granteeName, granteeId, selectedClassroom } = this.state;

    //notes logic
    const tags = {
      type: 'survey_collection',
      reviewId: this.context.router.params.reviewId,
    };

    const surveyNotes = notes && formatUserNotes(notes);

    // const title = () => {
    //   return (
    //     <div
    //       className="row"
    //       style={{ display: 'flex', 'align-items': 'baseline' }}
    //     >
    //       <div className="col-sm-9">
    //         <h3>
    //           FY 2019 CLASS
    //           <sup>®</sup>
    //         </h3>
    //       </div>
    //       <div className="col-xs-offset-3">
    //         <img
    //           src={classLogo}
    //           className="img-responsive"
    //           style={{ width: 100 }}
    //           alt="CLASS logo"
    //         />
    //       </div>
    //     </div>
    //   );
    // };

    return (
      <SlidingContainer
        calendar
        title={`CLASS® Survey for Review ID ${this.context.router.params.reviewId}`}
        granteeStatusBanner={true}
        granteeStatusData={{
          id: this.props.router.params.reviewId,
          type: 'review',
        }}
        actionButtons={
          <NotesContainer
            tags={tags}
            list={surveyNotes && surveyNotes}
            enabled={surveyNotes}
            type={'Notes'}
            reloadData={() => {
              this.fetchSurveyNotes();
            }}
          />
        }
      >
        <div className="well survey-header-info" style={{ overflow: 'auto' }}>
          <div style={{ width: '70%', float: 'left', marginTop: '-10px' }}>
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Review ID"
              >
                Review ID:{' '}
              </span>{' '}
              {`${this.context.router.params.reviewId}`}
            </div>
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Grantee Name"
              >
                Grantee Name:{' '}
              </span>{' '}
              {`${granteeName} (${granteeId})`}
            </div>
          </div>
          <div
            style={{
              width: '110px',
              float: 'right',
              marginTop: '-10px',
              textAlign: 'right',
            }}
          >
            <img
              src={classLogo}
              className="img-responsive"
              alt="CLASS logo"
              width="100"
            />
          </div>
          <div className={{ clear: 'both' }} />
        </div>

        <Segment className="well outcomes-info" color="blue">
          <Header as="h2">Instructions</Header>
          <Header as="h3" color="blue">
            Overview
          </Header>

          <p>
            The Pre-K Classroom Assessment Scoring System
            <sup>®</sup> (CLASS
            <sup>®</sup>) is an observation tool used to analyze and assess the
            effectiveness of interactions between children and teachers in
            preschool classes. The Office of Head Start (OHS) uses the CLASS
            <sup>®</sup> to measure grantee performance and identify whether
            children served by Head Start programs receive high-quality
            services.
          </p>
          <Header as="h3" color="blue">
            CLASS
            <sup>®</sup> Sample and Scheduling
          </Header>

          <p>
            The list of classes in the sample (the Sampling Report) will
            identify the exact classes that must be observed, background
            information about the classes (e.g., schedule, type of program), and
            the center in which each classroom is located.
          </p>
          <Header as="h3" color="blue">
            Reviewers must observe all classes on the Sampling Report.
          </Header>
          <p>
            The CLASS Lead (CL) will provide the schedule for the CLASS
            <sup>®</sup> observations. If any of the selected classes are not
            available for the scheduled observation, the CLASS
            <sup>®</sup> Reviewer should contact the CL to adjust the schedule
            in order to observe those classrooms. The CLASS
            <sup>®</sup> Reviewer should notify the CL of any changes to the
            schedule or if any classroom on the Sampling Report cannot be
            observed during the onsite visit. The CLASS
            <sup>®</sup> Reviewer should contact the CL with any question
            regarding the Sampling Report.
          </p>
          <Header as="h3" color="blue">
            CLASS
            <sup>®</sup> Observation Cycles
          </Header>

          <p>
            In each class selected for observation, CLASS
            <sup>®</sup> Reviewers conduct two 35-minute observation cycles.
          </p>
          <p>
            Each observation cycle includes a 20 minute observation followed
            immediately by ten minutes of scoring and 5 minutes of completing
            the background questions. If an observation cycle is cut short but
            lasts 10 minutes or more, CLASS
            <sup>®</sup> Reviewers should score the observation. If the
            observation lasts less than 10 minutes, it should not be scored and
            does not count.
          </p>
          <Header as="h3" color="blue">
            CLASS
            <sup>®</sup> Background Questions
          </Header>

          <p>
            In each class selected for observation, CLASS
            <sup>®</sup> Reviewers must complete all CLASS
            <sup>®</sup> observation cycle background questions.
          </p>
          <p>
            For each observation cycle, reviewers must answer a series of
            background questions. When completing the background questions,
            reflect upon what you observed during your observation.
          </p>
        </Segment>
        {selectedClassroom && selectedClassroom.observationCycleOneSubmissionId
          ? this.showSecondSkipModal()
          : this.showSkipModal()}
        {this.renderContent()}
        {this.showSkipConfirmationAlert()}
        {this.renderMovingMessage()}
        <br />
        {enforceRole(
          <div className="col-sm-5 col-xs-offset-7">
            {this.renderVersionTable()}
          </div>,
          acl.actions.classDetailButtons.viewVersionHistory,
          currentUser.roles
        )}
      </SlidingContainer>
    );
  }
}

CLASSSurveyPage.propTypes = {
  fetchClassTaskDetail: PropTypes.func.isRequired,
};

CLASSSurveyPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    classTaskDetail: state.submissions.classTaskDetail,
    profile: state.profile.profile,
    selectedSubmission: state.submissions.selectedSubmission,
    resetVersionHistory: state.submissions.resetVersionHistory,
    notes: state.note.notes,
  };
}

export default connect(mapStateToProps, {
  fetchVersionHistory,
  fetchClassTaskDetail,
  fetchUserProfile,
  requestReplaceClass,
  requestSkipClass,
  fetchNotes,
  requestSkipSecondObservation,
})(CLASSSurveyPage);
