import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import GoogleMap from 'google-map-react';
import { ButtonGroup, Button } from 'react-bootstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Label,
  LabelList,
} from 'recharts';

// Import components.
import ReviewsList from '../Reviews/ReviewsList';
import ReviewCreateForm from '../Review/ReviewCreateForm';

// import ReviewsInProgressMap from '../Maps/ReviewsInProgressMap';

import Task from '../Tasks/AddTask';

import AnalystSampleReport1 from '../OldReports/SSRS/AnalystSampleReport1';
import AnalystSampleReport2 from '../OldReports/SSRS/AnalystSampleReport2';
import BrowseGranteeData from '../OldReports/SSRS/BrowseGranteeData';
import RtlSurveyList from '../Survey/RtlSurveyList';
import Weather from '../Weather/Weather';
import CalendarWidget from '../Calendar/CalendarWidget';
import ReviewStatusItems from '../Review/ReviewStatusItems';

// Import actions.
import { fetchReviews, reviewsFetched } from '../../actions/reviewActions';

import enforceRole from '../../utils/EnforceRole';

// Imort UI config
import { acl } from '../../config';

// Import style.
import './asset/style.css';

// Sample data
const reviewsByRegionData = [
  { region: 1, count: 22 },
  { region: 2, count: 8 },
  { region: 3, count: 45 },
  { region: 4, count: 13 },
  { region: 5, count: 14 },
  { region: 6, count: 138 },
  { region: 7, count: 25 },
  { region: 8, count: 12 },
  { region: 9, count: 30 },
  { region: 10, count: 36 },
  { region: 11, count: 89 },
  { region: 12, count: 1 },
];

const reviewsByStatusSummarizedData = [
  { name: 'Total Count', value: 412.0 },
  { name: 'Inprogress Count', value: 344.0 },
  { name: 'Complete Count', value: 61.0 },
  { name: 'Cancelled Count', value: 6.0 },
];

class Workbench extends Component {
  constructor() {
    super();

    this.state = {
      mapActive: true,
      listActive: false,
    };
    this.toggleMapList = this.toggleMapList.bind(this);
  }

  componentDidMount() {
    // this.getReviewStatus();
  }

  componentWillUnmount() {
    this.props.reviewsFetched([]);
  }

  getReviewStatus() {
    const { currentUser } = this.props;

    const requestInput = {
      userId: currentUser.oid,
      rowStart: 0,
      rowEnd: 99,
      orderBy: 'dueDate',
      filters: {
        selectedReviewStatus: ['In Progress'],
      },
    };

    this.props.fetchReviews(requestInput);
  }

  showError() {
    if (this.props.error) {
      return (
        <div className="alert alert-danger">
          <strong>Danger!</strong> {this.props.error}
        </div>
      );
    }
  }

  showReviews() {
    return (
      <div className="panel panel-primary">
        <div className="panel-heading">
          <h3 className="panel-title">Reviews</h3>
        </div>
        <ul className="list-group">
          <li className="list-group-item">
            <strong>{this.props.reviews.name}</strong>
          </li>
          <li className="list-group-item">
            {this.props.reviews.review ? (
              <ReviewsList reviews={this.props.reviews.review} />
            ) : (
              ''
            )}
          </li>
        </ul>
      </div>
    );
  }

  toggleMapList(event) {
    switch (event.target.id) {
      case 'map':
        this.setState({ mapActive: true, listActive: false });
        break;
      case 'list':
        this.setState({ mapActive: false, listActive: true });
        break;
      default:
        this.setState({ mapActive: true, listActive: false });
        break;
    }
  }

  showMap() {
    const { reviews } = this.props;

    const { listActive, mapActive } = this.state;
    const { item } = this.props.weather;

    const reviewsInProgress = _.filter(reviews, {
      workFlowStatus: 'In Progress',
    });

    return (
      <div className="col-sm-12">
        <div className="panel panel-white">
          <div className="panel-heading">
            <h4 className="panel-title">Reviews In Progress</h4>
          </div>
          <div className="panel-body--old">
            <div className="col-sm-12 map-list-toggle">
              <ButtonGroup>
                <Button
                  onClick={this.toggleMapList}
                  id="map"
                  active={mapActive}
                >
                  Map
                </Button>
                <Button
                  onClick={this.toggleMapList}
                  id="list"
                  active={listActive}
                >
                  List
                </Button>
              </ButtonGroup>
            </div>

            <div className="map-area" hidden={listActive}>
              <GoogleMap
                apiKey={'AIzaSyDc_TlgKAkfo6J7p40ZH1cOrtQmGuOEr7I'}
                center={
                  item
                    ? [parseFloat(item.lat), parseFloat(item.long)]
                    : [38.907192, -77.036871]
                }
                zoom={12}
              />
            </div>

            <div className="map-area-review-status" hidden={mapActive}>
              <ReviewStatusItems reviews={reviewsInProgress} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  showReviewCreateForm() {
    const { currentUser } = this.props;

    return enforceRole(
      <div className="col-lg-4 col-md-5">
        <div className="panel panel-white">
          <div className="panel-heading">
            <h2 className="panel-title">Review </h2>
            <div className="panel-control" />
          </div>
          <div className="panel-body--old">
            <div style={{ height: '400px' }}>
              <ReviewCreateForm />
            </div>
          </div>
        </div>
      </div>,
      acl.actions.review.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  }

  showTaskBlock() {
    return (
      <div className="col-lg-4 col-md-5">
        <div className="panel panel-white">
          <div className="panel-heading">
            <h2 className="panel-title">Tasks </h2>
            <div className="panel-control" />
          </div>
          <div className="panel-body--old">
            <div style={{ height: '400px' }}>{/* <TaskList /> */}</div>
          </div>
        </div>
      </div>
    );
  }

  analystReport1() {
    const { roles } = this.props.currentUser;

    if (
      _.includes(roles, 'Analyst') ||
      _.includes(roles, 'TechnicalWriter') ||
      _.includes(roles, 'Editor')
    ) {
      return (
        <div className="col-sm-8">
          <div className="panel panel-white">
            <div className="panel-heading">
              <h2 className="panel-title">Next 45 Days</h2>
              <div className="panel-control" />
            </div>
            <div className="panel-body--old">
              <div style={{ height: '400px' }}>
                <AnalystSampleReport1 />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  analystReport2() {
    const { roles } = this.props.currentUser;

    if (
      _.includes(roles, 'Analyst') ||
      _.includes(roles, 'TechnicalWriter') ||
      _.includes(roles, 'Editor')
    ) {
      return (
        <div className="col-sm-8">
          <div className="panel panel-white">
            <div className="panel-heading">
              <h2 className="panel-title">Analyst Timeline</h2>
              <div className="panel-control" />
            </div>
            <div className="panel-body--old">
              <div style={{ height: '400px' }}>
                <AnalystSampleReport2 />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  browseGranteeDataReport() {
    return (
      <div className="col-sm-12">
        <div className="panel panel-white">
          <div className="panel-heading">
            <h2 className="panel-title">National Grantee List</h2>
            <div className="panel-control" />
          </div>
          <div className="panel-body--old">
            <div style={{ height: '400px' }}>
              <BrowseGranteeData />
            </div>
          </div>
        </div>
      </div>
    );
  }

  analystAddNewTask() {
    const { roles } = this.props.currentUser;

    if (
      _.includes(roles, 'Analyst') ||
      _.includes(roles, 'TechnicalWriter') ||
      _.includes(roles, 'Editor')
    ) {
      return (
        <div className="col-sm-4">
          <div className="panel panel-white">
            <div className="panel-heading">
              <h2 className="panel-title">Add New Task </h2>
              <div className="panel-control" />
            </div>
            <div className="panel-body--old">
              <div style={{ height: '400px' }}>
                <Task allowAdd />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  granteeMyReviews() {
    return (
      <div className="col-sm-8">
        <div className="panel panel-white">
          <div className="panel-heading">
            <h2 className="panel-title">My Reviews </h2>
            <div className="panel-control" />
          </div>
          <div className="panel-body--old">
            <div style={{ height: '400px' }}>{/*<MyReviews />*/}</div>
          </div>
        </div>
      </div>
    );
  }

  rtlSurveyList() {
    const { roles } = this.props.currentUser;

    if (_.includes(roles, 'Review Team Lead')) {
      return (
        <div className="col-sm-8">
          <div className="panel panel-white">
            <div className="panel-heading">
              <h2 className="panel-title"> My Surveys </h2>
              <div className="panel-control" />
            </div>
            <div className="panel-body--old">
              <div style={{ height: '400px' }}>
                <RtlSurveyList />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  blankBlock() {
    return (
      <div className="col-sm-4">
        <div className="panel panel-white">
          <div className="panel-heading">
            <h2 className="panel-title">Configurable Block </h2>
            <div className="panel-control" />
          </div>
          <div className="panel-body--old">
            <div style={{ height: '400px' }} />
          </div>
        </div>
      </div>
    );
  }

  showWeather() {
    return (
      <div className="col-sm-8">
        <div className="panel panel-white">
          <div className="panel-body" style={{ minHeight: '487px' }}>
            <Weather />
          </div>
        </div>
      </div>
    );
  }

  showCalendar() {
    return (
      // <div className="col-sm-4">
      <div className="panel panel-white" style={{ minHeight: '487px' }}>
        <div className="panel-body--old">
          <CalendarWidget />
        </div>
      </div>
      // </div>
    );
  }

  renderChart = () => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
      name,
      value,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 1.25;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'}>
          {name}
        </text>
      );
    };

    return (
      // <div className="col-sm-12">
      <div className="panel panel-white">
        <div className="panel-heading">
          <h4 className="panel-title">Reviews In Progress</h4>
        </div>

        <div className="panel-body--old">
          <div className="row">
            <div className="col-sm-6">
              <h4>Number of Reviews by Region and State</h4>
              <BarChart
                width={600}
                height={400}
                data={reviewsByRegionData}
                isAnimationActive={false}
                margin={{ top: 15, right: 30, left: 20, bottom: 15 }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="region">
                  <Label value="Regions" offset={-10} position="insideBottom" />
                </XAxis>
                <YAxis
                  label={{
                    value: 'Reviews',
                    angle: -90,
                    position: 'insideLeft',
                  }}
                />
                <Tooltip />
                <Bar dataKey="count" fill="#ff695e">
                  <LabelList dataKey="count" position="top" />
                </Bar>
              </BarChart>
            </div>
            <div className="col-sm-6">
              <h4>Distributions of Reviews by Review Status</h4>
              <PieChart width={800} height={400}>
                <Pie
                  cx={300}
                  cy={200}
                  data={reviewsByStatusSummarizedData}
                  dataKey="value"
                  labelLine={true}
                  outerRadius={100}
                  label={renderCustomizedLabel}
                  isAnimationActive={false}
                >
                  {reviewsByStatusSummarizedData.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  };

  showReviewStatus() {
    const { roles } = this.props.currentUser;

    let columns = 'col-xs-12';

    // Fill 12 grids if roles for create riview are not included in the current user's roles.
    if (_.intersection(acl.actions.review.create, roles).length) {
      columns = 'col-xs-8';
    }

    return (
      <div className={columns}>
        <div className="panel panel-white">
          <div className="panel-heading">
            <h4 className="panel-title">Review Status</h4>
          </div>
          <div className="panel-body--old">
            <div style={{ minHeight: '400px' }}>
              <ReviewStatusItems reviews={this.props.reviews} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row workbench">
        <div className="col-sm-12">
          {/* <div className="row">
            <div className="col-sm-12">{this.showMap()}</div>
          </div> */}

          <div className="row">
            <div className="col-sm-12">{this.showCalendar()}</div>
          </div>
        </div>

        {/* {this.showMap()} */}

        {/* {this.showWeather()} */}

        {/* {this.showCalendar()} */}

        {/* {this.showReviewStatus()}

        {this.showReviewCreateForm()} */}

        {/*
        {this.browseGranteeDataReport()}

        {this.showChart1()}

        {this.showTaskBlock()}

        {this.showReviewerBlock()} */}

        {/* {this.analystAddNewTask()} */}

        {/* {this.analystReport1()}

        {this.rtlSurveyList()}

        {this.granteeMyReviews()}

        {this.blankBlock()}

        {this.analystReport2()}

        {this.blankBlock()} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    value: state.review.reviewList,
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    weather: state.weather,
  };
}

Workbench.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  fetchReviews: PropTypes.func.isRequired,
  reviewsFetched: PropTypes.func.isRequired,
  weather: PropTypes.object,
};

export default connect(mapStateToProps, { fetchReviews, reviewsFetched })(
  Workbench
);
