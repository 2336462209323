import findIndex from 'lodash/findIndex';
import {
  CATEGORIES_FETCHED,
  CATEGORY_DELETED,
  CATEGORY_FETCHED,
  POST_FETCHED,
  TAGS_FETCHED,
  TAG_DELETED,
} from '../redux/types';

const initialState = {
  help: {
    categories: [],
  },
};

export default function help(state = initialState, action) {
  switch (action.type) {
    case CATEGORIES_FETCHED: {
      return {
        ...state,
        categories: action.categories,
      };
    }

    case CATEGORY_FETCHED: {
      return {
        ...state,
        categoryDetails: action.category,
      };
    }

    case CATEGORY_DELETED: {
      const index = findIndex(state.categories, { _id: action.id });

      if (index >= 0) {
        const modified = [
          ...state.categories.slice(0, index),
          ...state.categories.slice(index + 1),
        ];

        return { ...state, categories: modified };
      }

      return state;
    }
    case TAGS_FETCHED: {
      return {
        ...state,
        tags: action.tags,
      };
    }
    case TAG_DELETED: {
      const index = findIndex(state.tags, { _id: action.id });

      if (index >= 0) {
        const modified = [
          ...state.tags.slice(0, index),
          ...state.tags.slice(index + 1),
        ];

        return { ...state, tags: modified };
      }

      return state;
    }

    case POST_FETCHED: {
      return {
        ...state,
        post: action.post,
      };
    }
    default:
      return state;
  }
}
