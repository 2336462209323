import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadEvidenceFileAttachment } from '../../../actions/reviewActions';
import MimeIcon from '../../Shared/FormHelpers/helpers/MimeIcon';
import {
  attachmentFileData,
  humanizeFileSize,
} from '../../Shared/FormHelpers/helpers/utils';
import { downloadSurveyAttachment } from '../../../actions/surveyActions';

const FindingsDownloadAttachement = ({ evidenceFiles, isLegacy }) => {
  const [downloadingFileId, setDownloadingFileId] = useState('');
  const dispatch = useDispatch();

  if (!evidenceFiles || evidenceFiles.length === 0) {
    return null;
  }

  let files = evidenceFiles.map(e => {
    return attachmentFileData(e);
  });

  const triggerDownload = (id, name) => {
    if (id && name) {
      if (isLegacy) {
        dispatch(downloadSurveyAttachment(id, name))
          .then(() => setDownloadingFileId(''))
          .catch(() => setDownloadingFileId(''));
      } else {
        setDownloadingFileId(id);
        dispatch(downloadEvidenceFileAttachment(id, name))
          .then(() => setDownloadingFileId(''))
          .catch(() => setDownloadingFileId(''));
      }
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      {files.map((e, idx) => {
        if (downloadingFileId === e.id) {
          return <div key={idx}>Downloading...</div>;
        }

        let fileName = e.name;
        if (e.size) fileName = `${fileName} - (${humanizeFileSize(e.size)})`;
        return (
          <div key={idx}>
            <a
              href={0}
              download={e.name}
              onClick={event => {
                event.preventDefault();
                setDownloadingFileId(e.id);
                triggerDownload(e.id, e.name);
              }}
            >
              <MimeIcon mime={e.type} />
              {fileName}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default FindingsDownloadAttachement;
