import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Loader, Dimmer, Header } from 'semantic-ui-react';
import AmsAlert from '../../utils/AmsAlert';

import { Panel, Alert, Button, PanelGroup } from 'react-bootstrap';
import { fetchReviewDetail } from '../../actions/reviewActions';

import {
  fetchSurveyOutcomes,
  fetchDataCollectionOutcomes,
  fetchClassroomOutcomes,
  fetchCenterOutcomes,
  fetchChildFilesSummary,
} from '../../actions/surveyActions';

import enforceRole from '../../utils/EnforceRole';

// Imort UI config
import { acl } from '../../config';

const style = {
  tableHeader: {
    background: 'rgb(243, 244, 245)',
  },
};

class SurveyOutcome extends Component {
  state = {
    showErrorAlert: false,
    errorMessage: '',
    loading: true,
    responseErrors: [],
  };

  componentDidMount() {
    const { id, koboFormId, category } = this.props.params;

    const requestInput = {
      reviewId: id,
      koboFormId: koboFormId,
    };

    if (id) this.props.fetchReviewDetail({ reviewId: id });

    if (category === 'data-collection') {
      this.props
        .fetchDataCollectionOutcomes(requestInput)
        .then(() => this.setState({ loading: false }))
        .catch(error => {
          this.setState({
            loading: false,
            responseErrors: [...this.state.responseErrors, error.response.data],
          });
        });
    } else if (category === 'classroom-exploration-outcomes') {
      this.props
        .fetchClassroomOutcomes(requestInput)
        .then(() => this.setState({ loading: false }))
        .catch(error => {
          this.setState({
            loading: false,
            responseErrors: [...this.state.responseErrors, error.response.data],
          });
        });
    } else if (category === 'center-and-facilities-exploration-outcomes') {
      this.props
        .fetchCenterOutcomes(requestInput)
        .then(() => this.setState({ loading: false }))
        .catch(error => {
          this.setState({
            loading: false,
            responseErrors: [...this.state.responseErrors, error.response.data],
          });
        });
    } else if (category === 'child-files') {
      this.props
        .fetchChildFilesSummary(requestInput)
        .then(() => this.setState({ loading: false }))
        .catch(error => {
          this.setState({
            loading: false,
            responseErrors: [...this.state.responseErrors, error.response.data],
          });
        });
    } else {
      this.props
        .fetchSurveyOutcomes(requestInput)
        .then(() => this.setState({ loading: false }))
        .catch(error => {
          const { message } = error.response.data;
          if (message)
            this.setState({
              loading: false,
              showErrorAlert: true,
              errorMessage: message,
            });
        });
    }
  }

  showErrorAlert() {
    const { showErrorAlert, errorMessage } = this.state;

    return (
      <AmsAlert
        show={showErrorAlert}
        title="Oops!"
        text={errorMessage}
        type={'error'}
        showConfirm
        onCancel={() => {
          this.setState({ showErrorAlert: false, errorMessage: '' });
        }}
        onConfirm={() => {
          this.setState({ showErrorAlert: false, errorMessage: '' });
        }}
      />
    );
  }

  renderSurveyQuestions() {
    const { outComes } = this.props;

    return (
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={10}>Question</Table.HeaderCell>
            <Table.HeaderCell>Yes</Table.HeaderCell>
            <Table.HeaderCell>No</Table.HeaderCell>
            <Table.HeaderCell>N/A</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {outComes.map((item, index) => {
            return (
              <Table.Row key={`datarow${index}`}>
                <Table.Cell>{item.qLabel}</Table.Cell>
                <Table.Cell>{item.Yes}</Table.Cell>
                <Table.Cell>{item.No}</Table.Cell>
                <Table.Cell>{item.NA}</Table.Cell>
                <Table.Cell>{item.Total}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  renderClassroomAccordion() {
    const { classroomOutcomes } = this.props;
    const categoryHeaders = {
      effectiveLessonPlanning: [
        'Center Name',
        'Classroom Name',
        'Effective Lesson Planning',
        'Outcome',
        'Explanation',
      ],
      effectiveTeachingPractises: [
        'Center Name',
        'Classroom Name',
        'Effective Teaching Practices',
        'Outcome',
        'Explanation',
      ],
      childrenClassroomProgress: [
        'Center Name',
        'Classroom Name',
        'Children Class Room Progress',
        'Outcome',
        'Explanation',
      ],
      childrenOpportunities: [
        'Center Name',
        'Classroom Name',
        'Child Opportunities',
        'Outcome',
        'Explanation',
      ],
      learningAllActivities: [
        'Center Name',
        'Classroom Name',
        'Learning All Activities',
        'Outcome',
        'Explanation',
      ],
      experienceResponsiveness: [
        'Center Name',
        'Classroom Name',
        'Experience Responsiveness',
        'Outcome',
        'Explanation',
      ],
      classroomManagement: [
        'Center Name',
        'Classroom Name',
        'Class Room Management',
        'Outcome',
        'Explanation',
      ],
      receivingSupport: [
        'Center Name',
        'Classroom Name',
        'Receiving Support',
        'Outcome',
        'Explanation',
      ],
    };

    return (
      <PanelGroup accordion id="classroom-accordion">
        {classroomOutcomes.map((classOutcome, index) => (
          <Panel eventKey={index}>
            <Panel.Heading>
              <Panel.Title toggle>{classOutcome.description}...</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              <PanelGroup accordion id="classroom-accordion-child">
                {classOutcome.categories.map((category, index) => (
                  <Panel eventKey={index}>
                    <Panel.Heading>
                      <Panel.Title toggle>{category.description}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                      <Table striped>
                        <Table.Header>
                          <Table.Row>
                            {categoryHeaders[category.type].map(
                              (header, index) => {
                                return (
                                  <Table.HeaderCell width={10} key={index}>
                                    {header}{' '}
                                  </Table.HeaderCell>
                                );
                              }
                            )}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {category.data.map((item, index) => {
                            return (
                              <Table.Row key={index}>
                                <Table.Cell>{item.centerName}</Table.Cell>
                                <Table.Cell>{item.classroomName}</Table.Cell>
                                <Table.Cell>{item.question}</Table.Cell>
                                <Table.Cell>{item.outcome}</Table.Cell>
                                <Table.Cell>
                                  {item.explanations &&
                                  item.explanations.length ? (
                                    item.explanations.length > 1 ? (
                                      <div className="row">
                                        {' '}
                                        <ul>
                                          {item.explanations.map(
                                            (item, index) => (
                                              <li key={index}>{item}</li>
                                            )
                                          )}{' '}
                                        </ul>
                                      </div>
                                    ) : (
                                      item.explanations
                                    )
                                  ) : null}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    </Panel.Body>
                  </Panel>
                ))}
              </PanelGroup>
            </Panel.Body>
          </Panel>
        ))}
      </PanelGroup>
    );
  }

  renderCenterAccordion() {
    const { centerOutcomes } = this.props;
    return (
      <PanelGroup accordion id="center-accordion">
        {centerOutcomes.map((centerOutcome, index) => {
          return (
            <Panel key={centerOutcome.type} eventKey={centerOutcome.type}>
              <Panel.Heading>
                <Panel.Title toggle>{centerOutcome.description}</Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <PanelGroup accordion id="center-accordion-child">
                  {centerOutcome.categories.map((category, ind) => {
                    if (!category.hasOwnProperty('type')) return null;
                    return (
                      <Panel
                        key={category.type + ind}
                        header={category.description}
                        eventKey={category.type + ind}
                      >
                        <Table striped>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell
                                width={10}
                                style={style.tableHeader}
                              >
                                Question
                              </Table.HeaderCell>
                              <Table.HeaderCell style={style.tableHeader}>
                                Outcome
                              </Table.HeaderCell>
                              <Table.HeaderCell style={style.tableHeader}>
                                Explanation
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {category.data.map((item, index) => {
                              return (
                                <Table.Row key={index}>
                                  <Table.Cell>{item.question}</Table.Cell>
                                  <Table.Cell>
                                    {item.outcome && item.outcome.length ? (
                                      item.outcome.length > 1 ? (
                                        <div className="row">
                                          {' '}
                                          <ul>
                                            {item.outcome.map((item, index) => (
                                              <li key={index}>{item}</li>
                                            ))}{' '}
                                          </ul>
                                        </div>
                                      ) : (
                                        item.outcome
                                      )
                                    ) : null}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {item.explanations &&
                                    item.explanations.length ? (
                                      item.explanations.length > 1 ? (
                                        <div className="row">
                                          {' '}
                                          <ul>
                                            {item.explanations.map(
                                              (item, index) => (
                                                <li key={index}>{item}</li>
                                              )
                                            )}{' '}
                                          </ul>
                                        </div>
                                      ) : (
                                        item.explanations
                                      )
                                    ) : null}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      </Panel>
                    );
                  })}
                </PanelGroup>
              </Panel.Body>
            </Panel>
          );
        })}
      </PanelGroup>
    );
  }

  renderChildFiles() {
    const { childFilesSummary } = this.props;

    return (
      <div>
        <Header as="h3">OVERALL ELIGIBILITY FILE SUBMISSION RESULTS</Header>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={10} style={style.tableHeader}>
                Criteria
              </Table.HeaderCell>
              <Table.HeaderCell style={style.tableHeader}>
                Outcome ( Yes / No )
              </Table.HeaderCell>
              <Table.HeaderCell style={style.tableHeader}>
                Outcome Details (Child file id's)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Are there any duplicated child files?</Table.Cell>
              <Table.Cell>
                {(childFilesSummary[2] || {}).isThereAnyDuplicateChildIds ||
                false
                  ? 'Yes'
                  : 'No'}
              </Table.Cell>
              <Table.Cell>
                {(
                  (childFilesSummary[2] || {}).duplicateChildIdsList || []
                ).join(', ')}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Are there any child files missing?</Table.Cell>
              <Table.Cell>
                {(childFilesSummary[2] || {}).isThereAnyMissingChildIds || false
                  ? 'Yes'
                  : 'No'}
              </Table.Cell>
              <Table.Cell>
                {((childFilesSummary[2] || {}).missingChidIdsList || []).join(
                  ', '
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {childFilesSummary
          .filter((data, index) => index < 2)
          .map((data, index) => {
            let title =
              'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: DOCUMENTATION - ' +
              data.type;
            let short_description = (
              <Table.Cell>Total files reviewed</Table.Cell>
            );
            let description = (
              <Table.Cell>
                <p>
                  Total # and % of files with any one of the four documentation
                  errors
                </p>
                <ul>
                  <li>No eligibility determination record (EDR) for child</li>
                  <li>Eligibility category not indicated in EDR</li>
                  <li>
                    No documentation that an in-person or phone interview took
                    place
                  </li>
                  <li>Eligibility documentation not retained</li>
                </ul>
              </Table.Cell>
            );
            if (data.type === '1302_12_k') {
              title =
                'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: DOCUMENTATION - 1302.12(k)';
            } else if (data.type === '1302_12_c') {
              title =
                'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: ELIGIBILITY - 1302.12(c) / 1302.12(e)';
              short_description = (
                <Table.Cell>Total # of files reviewed</Table.Cell>
              );
              description = (
                <Table.Cell>
                  <p>
                    Total # of files in error (over allowance) for any of the
                    following income categories
                  </p>
                  <ul>
                    <li>
                      Family income is between 100-130% of poverty guidelines
                    </li>
                    <li>
                      Family income is greater than 130% of poverty guidelines
                    </li>
                    <li>No eligible category determination made by program</li>
                  </ul>
                </Table.Cell>
              );
            }
            return (
              <>
                <Header as="h3">{title}</Header>
                <Table celled unstackable key={index}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={10} style={style.tableHeader}>
                        Criteria
                      </Table.HeaderCell>
                      <Table.HeaderCell style={style.tableHeader}>
                        Count
                      </Table.HeaderCell>
                      <Table.HeaderCell style={style.tableHeader}>
                        Count
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      {short_description}
                      <Table.Cell>
                        {(data.totalFilesReviewed || {}).count || 0}
                      </Table.Cell>
                      <Table.Cell>
                        {(data.totalFilesReviewed || {}).percent || 0}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      {description}
                      <Table.Cell>
                        {(data.totalFileErrors || {}).count || 0}
                      </Table.Cell>
                      <Table.Cell>
                        {(data.totalFileErrors || {}).percent || 0}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </>
            );
          })}
      </div>
    );
  }

  renderAccordion() {
    const { dataCollectionOutcomes, currentUser } = this.props;

    return enforceRole(
      <PanelGroup accordion id="data-collection">
        {dataCollectionOutcomes.map((dataCollectionOutcome, index) => {
          return (
            <Panel eventKey={index}>
              <Panel.Heading>
                <Panel.Title toggle>
                  {dataCollectionOutcome.description}
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <PanelGroup accordion id="data-collection-child">
                  {dataCollectionOutcome.categories.map((category, index) => (
                    <Panel eventKey={index}>
                      <Panel.Heading>
                        <Panel.Title toggle>
                          {dataCollectionOutcome.description}
                        </Panel.Title>
                      </Panel.Heading>

                      <Panel.Body collapsible>
                        <Table striped>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>
                                Name of Reviewer
                              </Table.HeaderCell>
                              <Table.HeaderCell>Date</Table.HeaderCell>
                              <Table.HeaderCell>
                                Who did you talk with?
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                Summary of Information Learned
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                Potential Concerns
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {category.data.map(item => {
                              return (
                                <Table.Row>
                                  <Table.Cell>{item.amsEmailId}</Table.Cell>
                                  <Table.Cell collapsing>
                                    {item.date}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {item.whoDidYouSpeak}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {item.summaryOfInformationLearned}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {item.potentialConcerns}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      </Panel.Body>
                    </Panel>
                  ))}
                </PanelGroup>
              </Panel.Body>
            </Panel>
          );
        })}
      </PanelGroup>,
      acl.actions.survey.dataCollectionOutcomes,
      currentUser.roles
    );
  }

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:({errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  renderSurveyOutcome() {
    const { category } = this.props.params;

    switch (category) {
      case 'data-collection':
        return this.renderAccordion();
      case 'classroom-exploration-outcomes':
        return this.renderClassroomAccordion();
      case 'center-and-facilities-exploration-outcomes':
        return this.renderCenterAccordion();
      case 'child-files':
        return this.renderChildFiles();
      default:
        return this.renderSurveyQuestions();
    }
  }

  renderButtons() {
    const { category } = this.props.params;
    if (category === 'classroom-exploration') {
      return (
        <Button
          className="btn btn-primary"
          bsSize="large"
          target="_blank"
          href="classroom-exploration-outcomes"
        >
          Classroom Qualitative Outcomes
        </Button>
      );
    } else if (category === 'center-and-facilities-exploration') {
      return (
        <Button
          className="btn btn-primary"
          bsSize="large"
          target="_blank"
          href="center-and-facilities-exploration-outcomes"
        >
          {' '}
          Center and Facilities Qualitative Outcomes
        </Button>
      );
    }
  }

  renderHeaderInfo() {
    const { category, id } = this.props.params;

    let review = this.props.selectedReview || null;
    let reviewType = (review || {}).reviewType;
    let grantee = ((review || {}).grantees || [])[0] || null;
    return (
      <div className="panel-body--old">
        <div className="row">
          <div className="col-sm-12">
            <div className="well survey-header-info">
              {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
              {reviewType} ( {id} )
            </div>
          </div>
          <div className="col-sm-12">
            {(() => {
              switch (category) {
                case 'data-collection':
                  return null;
                case 'classroom-exploration':
                  return null;
                case 'classroom-exploration-outcomes':
                  return (
                    <div className="well outcomes-info">
                      <p>
                        <label className="label label-info">?</label>
                      </p>
                      <li>
                        For detailed qualitative summary by Centers and
                        Classrooms, select each section by clicking on the main
                        category name at a time and there by selecting sub
                        category name within each category.
                      </li>
                      <li>
                        To collapse the detailed qualitative summary by Centers,
                        simply click the main category name
                      </li>
                      <li>
                        To exit out of the page close the tab by using cross
                        mark on the qualitative summary tab
                      </li>
                    </div>
                  );
                case 'center-and-facilities-exploration':
                  return null;
                case 'center-and-facilities-exploration-outcomes':
                  return (
                    <div className="well outcomes-info">
                      <p>
                        <label className="label label-info">?</label>
                      </p>
                      <li>
                        For detailed qualitative summary by Centers, select each
                        section by clicking on the category name at a time and
                        there by selecting each center name within each
                        category.
                      </li>
                      <li>
                        To collapse the detailed qualitative summary by Centers,
                        simply click the category name
                      </li>
                      <li>
                        To exit out of the page close the tab by using cross
                        mark on the qualitative summary tab
                      </li>
                    </div>
                  );
                case 'child-files':
                  return null;
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { category } = this.props.params;
    const { loading } = this.state;

    return (
      <div className="row page task-page">
        {this.showResponseErrors()}
        {this.showErrorAlert()}
        <div className="col-sm-12">
          <div className="panel panel-white">
            <div className="panel-heading">
              <h2 className="panel-title">
                {category
                  .split('-')
                  .join(' ')
                  .toLocaleUpperCase()}
              </h2>
            </div>

            {this.renderHeaderInfo()}
            <div style={{ marginRight: '70px' }} className="buttons text-right">
              {this.renderButtons()}
            </div>

            <div className="panel-body--old">
              {loading ? (
                <Dimmer active inverted>
                  <Loader inverted>Loading...</Loader>
                </Dimmer>
              ) : (
                this.renderSurveyOutcome()
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SurveyOutcome.propTypes = {};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    outComes: state.survey.outComes,
    childFilesSummary: state.survey.childFilesSummary,
    observations: state.survey.observations,
    requestOutcomes: state.survey.requestOutcomes,
    dataCollectionOutcomes: state.survey.dataCollectionOutcomes,
    classroomOutcomes: state.survey.classroomOutcomes,
    centerOutcomes: state.survey.centerOutcomes,
    selectedReview: state.review.selectedReview,
  };
}

export default connect(mapStateToProps, {
  fetchSurveyOutcomes,
  fetchDataCollectionOutcomes,
  fetchClassroomOutcomes,
  fetchCenterOutcomes,
  fetchChildFilesSummary,
  fetchReviewDetail,
})(SurveyOutcome);
