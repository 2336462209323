import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

// Import config
import { acl } from '../../config';

// Import helpers.
import AmsTable from '../../utils/AmsTable';
import enforceRole from '../../utils/EnforceRole';

// Import actions.
import { downloadGranteeReviewReport } from '../../actions/granteeActions';

const GranteeReviewItems = ({
  currentUser,
  reviews,
  downloadGranteeReviewReport,
}) => {
  const linkId = (cell, row) => {
    return <Link to={`/review/${cell}`}>{cell}</Link>;
  };

  const formatDownloadLink = (cell, row) => {
    const allwedRoles = acl.actions.report.download;
    const currentUserRoles = acl.actions.report.download;

    // Build download link.
    if (
      !_.isEmpty(cell) &&
      !_.isEmpty(allwedRoles) &&
      !_.isEmpty(currentUserRoles)
    ) {
      return enforceRole(
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            downloadGranteeReviewReport(row.reviewId, { fileName: `${cell}` });
          }}
        >
          Download
        </a>,
        allwedRoles, // Allowed roles
        currentUserRoles // Current user roles
      );
    }

    return null;
  };

  const columns = [
    {
      dataField: 'reviewId',
      formatter: linkId,
      headerStyle: { width: '17%' },
      text: 'Review ID',
      sort: true,
    },
    {
      headerStyle: { width: '20%' },
      dataField: 'reviewType',
      sort: true,
      text: 'Review Type',
    },
    { headerStyle: { width: '13%' }, dataField: 'fy', sort: true, text: 'FY' },
    {
      headerStyle: { width: '25%' },
      dataField: 'outCome',
      sort: true,
      text: 'Outcome',
    },
    {
      headerStyle: { width: '25%' },
      dataField: 'finalReport',
      formatter: formatDownloadLink,
      sort: true,
      text: 'Final Report',
    },
  ];

  return (
    <AmsTable
      data={reviews}
      columns={columns}
      basic
      remote={false}
      keyField="reviewId"
      total={reviews ? reviews.length : 0}
      pagination={reviews.length > 10}
    ></AmsTable>
  );
};

GranteeReviewItems.propTypes = {
  currentUser: PropTypes.object.isRequired,
  downloadGranteeReviewReport: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { downloadGranteeReviewReport })(
  GranteeReviewItems
);
