import { INVITATION_LOADED } from '../redux/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVITATION_LOADED:
      return { ...action.invitation };

    default:
      return state;
  }
};
