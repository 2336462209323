import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchWeather } from '../../actions/weatherActions';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import style.
import './assets/style.css';

// Import icon mapping.
import { setWeatherIcon } from './assets/weather-icon-mapping';

class Weather extends Component {
  componentDidMount() {
    this.props.fetchWeather();
  }

  weatherTop() {
    const { lastBuildDate, location, wind, item } = this.props.weather;

    return (
      <div className="col-md-12">
        <div className="weather-current">
          <div className="col-sm-9">
            <div className="weather-location">
              {' '}
              {location && `${location.city}, ${location.region}`}{' '}
            </div>
            <div className="weather-date">
              {lastBuildDate && AmsDateFormatters.formatDateTime(lastBuildDate)}
            </div>
            <div className="weather-wind-speed">
              {wind && `Wind: ${wind.speed} MPH`}
            </div>
          </div>

          <div className="col-sm-3">
            <div className="col-sm-12">
              <i
                className={
                  item && `${setWeatherIcon(item.condition.code)} weather-icon`
                }
              />
            </div>
            <div className="col-sm-12 weather-summary">
              {item && item.condition.text}
            </div>
          </div>
        </div>
      </div>
    );
  }

  weatherBottom() {
    const { item } = this.props.weather;

    let todayForcast = null;

    return (
      <div className="col-md-12">
        <div className="weather-bottom">
          <div className="col-md-8">
            <ul className="list-unstyled weather-days">
              {item &&
                // eslint-disable-next-line array-callback-return
                item.forecast.map((forcastItem, index) => {
                  if (index === 0) todayForcast = forcastItem;
                  // Skip today's date and only show next 6 days.
                  if (
                    AmsDateFormatters.getMoment(forcastItem.date).format(
                      'MM-DD-YYYY'
                    ) !== AmsDateFormatters.getMoment().format('MM-DD-YYYY')
                  )
                    if (index < 7)
                      return (
                        <li key={index} className="col-xs-4 col-sm-2">
                          <span className="forcast-day">{forcastItem.day}</span>
                          {
                            <i
                              className={`${setWeatherIcon(
                                forcastItem.code
                              )} forcast-icon`}
                            />
                          }
                          <span className="forcast-temprature">
                            {forcastItem.high}
                            <sup>°F</sup>
                          </span>
                        </li>
                      );
                })}
            </ul>
          </div>

          <div className="col-md-4">
            <div className="forcast-today">
              {todayForcast && (
                <div className="col-md-6 high-low">
                  {todayForcast.low}
                  <sup>°</sup>&darr; {todayForcast.high}
                  <sup>°</sup>&uarr;{' '}
                </div>
              )}
              {item && (
                <div className="col-md-6 temprature">
                  <span>
                    {item.condition.temp}
                    <sup>°F</sup>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="weather-widget">
        <div className="row">
          {this.weatherTop()}
          {this.weatherBottom()}
          {/* <div className="pull-right yahoo-logo"><a href="https://www.yahoo.com/?ilc=401" target="_blank"> <img src="https://poweredby.yahoo.com/purple_retina.png" width="134" height="29" /></a></div> */}
        </div>
      </div>
    );
  }
}

Weather.propTypes = {
  weather: PropTypes.object.isRequired,
  fetchWeather: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    weather: state.weather,
  };
}

export default connect(mapStateToProps, { fetchWeather })(Weather);
