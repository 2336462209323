import axios from 'axios';

import { POST_FETCHED, POST_ADDED } from '../../redux/types';

export function postFetched(post) {
  return {
    type: POST_FETCHED,
    post,
  };
}

export function addedPost(post) {
  return {
    type: POST_ADDED,
    post,
  };
}
export const queryHelpData = query => dispatch =>
  axios.post(`/help/search`, query).then(response => {
    return response.data;
  });

export const fetchPost = id => dispatch =>
  axios.get(`/help/posts/${id}`).then(response => {
    dispatch(postFetched(response.data));
    return response.data;
  });

export function addPost(postObject) {
  return dispatch => {
    return axios.post(`/help/posts`, postObject).then(response => {
      return response.data;
    });
  };
}

export function createFileModel(fileObj) {
  return dispatch => {
    return axios.post(`/help/fileModels`, fileObj).then(response => {
      return response.data;
    });
  };
}

export function processFiles(filesArr, id) {
  return dispatch => {
    return axios.post(`/help/posts/${id}/files`, filesArr).then(response => {
      return response.data;
    });
  };
}

export function updatePost(postObject, id) {
  return dispatch => {
    return axios.post(`/help/posts/${id}`, postObject).then(response => {
      return response.data;
    });
  };
}

export const uploadAttachment = attachment => dispatch => {
  if (attachment.file) {
    const { name, type, size, lastModified } = attachment.file;
    const payload = new FormData();
    payload.append('file', attachment.file, name);
    payload.append('contentType', type);
    payload.append('filename', name);
    payload.append('filesize', size);
    payload.append('lastModified', lastModified);

    return axios.post(`/help/files`, payload).then(response => response.data);
  }
};

/*export function deletePost(id) {
  return dispatch => {
    axios.delete(`/help/posts/${id}`).then(function(response) {
      return dispatch(removedPost(id, response.data));
    });
  };
}*/
