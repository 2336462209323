/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { Button, Form, Icon, Menu, Message } from 'semantic-ui-react';

// Import components
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsTable from '../../utils/AmsTable';

// Import actions.
import {
  addReviewEvidence,
  downloadReviewDocument,
  fetchReviewEvidence,
} from '../../actions/reviewActions';

class AttachEvidence extends Component {
  state = {
    openModal: false,
    saving: false,
    fileDownloading: {},
    total: 0,
    page: 1,
    limit: 10,
    sortName: 'uploadedDate',
    sortOrder: 'desc',
    reviewId: '',
    reviewType: '',
    data: {
      title: '',
      description: '',
      file: null,
    },
    documentsList: [],
    errors: {},
    message: '',
  };

  componentDidMount = () => {
    let id =
      this.context.router.params.id || this.context.router.params.reviewId;
    if (id)
      this.props.fetchReviewEvidence({
        filters: { reviewId: id },
      });
  };

  static getDerivedStateFromProps(nextProps) {
    const { selectedReview } = nextProps;
    if (
      selectedReview &&
      selectedReview.reviewDocuments &&
      selectedReview.reviewDocuments.data &&
      selectedReview.reviewDocuments.data.length
    ) {
      const {
        reviewDocuments: { data, total },
        reviewId,
        reviewType,
        readOnly,
      } = selectedReview;
      return {
        documentsList: data,
        total,
        readOnly,
        reviewId,
        reviewType,
      };
    } else {
      const { reviewId, reviewType, readOnly } = selectedReview;
      return { reviewId, reviewType, readOnly };
    }
  }

  getData = () => {
    const { page, limit, sortName, sortOrder } = this.state;
    const {
      selectedReview: { reviewId },
    } = this.props;

    if (reviewId) {
      this.props.fetchReviewEvidence({
        page,
        limit,
        sortName,
        sortOrder,
        reviewId,
        filters: { reviewId },
      });
    }
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleChange = (e, { name, value }) =>
    this.setState({
      data: { ...this.state.data, [name]: value },
    });

  showError = () => {
    const { errors } = this.state;

    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(errorKey => (
          <li key={errorKey}>{errors[errorKey]}</li>
        ))}
      />
    );
  };

  showConfirmation = () => {
    const { message } = this.state;

    if (_.isEmpty(message)) return;

    return (
      <Message positive icon="check" header="Success!" content={message} />
    );
  };

  clearForm = e => {
    if (e) e.preventDefault();
    this.setState({
      data: {
        title: '',
        description: '',
        file: null,
      },
    });
  };

  removeFile = e => {
    e.preventDefault();
    this.setState({ data: { ...this.state.data, file: null } });
  };

  fileOnDrop = files => {
    this.setState({ data: { ...this.state.data, file: files[0] } });
  };

  /*renderTable = () => {
    const {
      total,
      limit,
      page,
      sortName,
      sortOrder,
      documentsList,
    } = this.state;

    const onPageChange = (page, sizePerPage) => {
      this.setState({ page, limit: sizePerPage }, () => this.getData());
    };

    const onSortChange = (sortName, sortOrder) => {
      this.setState({ sortName, sortOrder }, () => this.getData());
    };

    const renderPaginationShowsTotal = (start, to, total) => (
      <div className="pagination-total">
        Showing rows {start} to {to} of {total}
      </div>
    );

    const formatTitle = (cell, row) => {
      return (
        <div>
          <strong>{cell}</strong>
          {row.description && (
            <div>{_.truncate(row.description, { length: 200 })}</div>
          )}
        </div>
      );
    };

    const formatDate = (cell, row) => {
      return AmsDateFormatters.formatDateTime(cell);
    };

    const formatDownloadLink = (cell, row) => {
      const { reviewId, documentType, filename } = row;

      return this.state.fileDownloading[row._id] ? (
        <div>downloading...</div>
      ) : (
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            if (reviewId && documentType && filename) {
              this.setState({
                fileDownloading: {
                  ...this.state.fileDownloading,
                  [row._id]: true,
                },
              });
              this.props
                .downloadReviewDocument(reviewId, filename, documentType)
                .then(() =>
                  this.setState({
                    fileDownloading: {
                      ...this.state.fileDownloading,
                      [row._id]: false,
                    },
                  })
                )
                .catch(error =>
                  this.setState({
                    errors: { downloadError: error.message },
                    fileDownloading: {
                      ...this.state.fileDownloading,
                      [row._id]: false,
                    },
                  })
                );
            }
          }}
        >
          Download Attachment
        </a>
      );
    };

    const options = {
      sizePerPage: limit,
      onPageChange: onPageChange,
      onSortChange: onSortChange,
      sortName: sortName,
      sortOrder: sortOrder,
      page: page,
      paginationShowsTotal: renderPaginationShowsTotal,
      hidePageListOnlyOnePage: true,
    };

    return (
      <BootstrapTable
        data={documentsList}
        pagination
        remote
        options={options}
        fetchInfo={{ dataTotalSize: total }}
        tableContainerClass="table-container"
        headerContainerClass="table-header"
        tds
        bordered={false}
        hover
        condensed
      >
        <TableHeaderColumn isKey dataField="_id" hidden>
          id
        </TableHeaderColumn>
        <TableHeaderColumn
          width={'30%'}
          dataField="title"
          dataSort={true}
          dataFormat={formatTitle}
          tdStyle={{ whiteSpace: 'normal' }}
        >
          Title
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="uploadedDate"
          dataFormat={formatDate}
          dataSort={true}
        >
          Uploaded Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="uploadedBy"
          dataSort={true}
          tdStyle={{ whiteSpace: 'normal' }}
        >
          Uploaded By
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="fileName"
          dataFormat={formatDownloadLink}
          tdStyle={{ whiteSpace: 'normal' }}
        >
          Download
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };*/

  renderTable = () => {
    const { total, page, documentsList } = this.state;

    const onSizePerPageChange = (sizePerPage, page) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
        }
      );
    };

    const onPageChange = (page, sizePerPage) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
        }
      );
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === this.state.sortName &&
        sortOrder === this.state.sortOrder
      )
        return null;
      this.setState(
        {
          sortName: sortField || this.state.sortName,
          sortOrder: sortOrder || this.state.sortOrder,
        },
        () => this.getData()
      );
    };

    const formatTitle = (cell, row) => {
      return (
        <div>
          <strong>{cell}</strong>
          {row.description && (
            <div>{_.truncate(row.description, { length: 200 })}</div>
          )}
        </div>
      );
    };

    const formatDate = cell => {
      return AmsDateFormatters.formatDateTime(cell);
    };

    const formatDownloadLink = (cell, row) => {
      const { reviewId, documentType, filename } = row;

      return this.state.fileDownloading[row._id] ? (
        <div>downloading...</div>
      ) : (
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            if (reviewId && documentType && filename) {
              this.setState({
                fileDownloading: {
                  ...this.state.fileDownloading,
                  [row._id]: true,
                },
              });
              this.props
                .downloadReviewDocument(reviewId, filename, documentType)
                .then(() =>
                  this.setState({
                    fileDownloading: {
                      ...this.state.fileDownloading,
                      [row._id]: false,
                    },
                  })
                )
                .catch(error =>
                  this.setState({
                    errors: { downloadError: error.message },
                    fileDownloading: {
                      ...this.state.fileDownloading,
                      [row._id]: false,
                    },
                  })
                );
            }
          }}
        >
          Download Attachment
        </a>
      );
    };

    const columns = [
      { dataField: '_id', hidden: true, text: 'id' },
      {
        headerStyle: { width: '30%' },
        dataField: 'title',
        sort: true,
        formatter: formatTitle,
        text: 'Title',
      },
      {
        dataField: 'uploadedDate',
        formatter: formatDate,
        sort: true,
        text: 'Uploaded Date',
      },
      {
        dataField: 'uploadedBy',
        sort: true,
        text: 'Uploaded By',
      },
      {
        dataField: 'fileName',
        formatter: formatDownloadLink,
        text: 'Download',
      },
    ];

    const defaultSorted = [
      {
        dataField: this.state.sortName,
        order: this.state.sortOrder,
      },
    ];

    return (
      <AmsTable
        data={documentsList}
        pagination
        remote
        defaultSorted={defaultSorted}
        page={page}
        total={total}
        limit={this.state.limit}
        loading={this.state.loading}
        columns={columns}
        keyField="_id"
        onTableChange={onTableChange}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
      />
    );
  };

  validate = () => {
    const errors = {};
    if (!this.state.data.title) errors.title = 'Title is required';
    if (!this.state.data.description)
      errors.description = 'Description is required';
    if (!this.state.data.file) errors.fileDrop = 'File attachment is required';
    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const {
        data: { title, description, file },
        reviewId,
        reviewType,
      } = this.state;

      if (!reviewId && !reviewType) return;

      // Assemble form data.
      const formData = new FormData();
      formData.set('reviewId', reviewId);
      formData.set('reviewType', reviewType);
      formData.set('title', title);
      formData.set('description', description);

      if (file) formData.set('file', file);

      this.setState({ saving: true });

      this.props
        .addReviewEvidence(formData)
        .then(() => {
          this.setState({
            saving: false,
            message: 'Evidence Attached successfully.',
          });
          this.clearForm();
          this.getData();
          setTimeout(() => {
            this.setState({ message: '' }); //Clear message after a few seconds
          }, 3000);
        })
        .catch(error =>
          this.setState({
            saving: false,
            errors: { saveError: error.response.data.message },
          })
        );
    }
  };

  renderModal = () => {
    let readOnly = this.state.readOnly || false;

    if (
      ((this.props.selectedReview || {}).reviewAccessLevel &&
        (this.props.selectedReview || {}).reviewAccessLevel
          .isReviewAccessible === false) ||
      (this.props.selectedReview || {}).reviewStatus === 'Report Signed' ||
      (this.props.selectedReview || {}).reviewStatus === 'Shipped'
    ) {
      readOnly = true;
    }

    return (
      <Modal
        className="modal-container"
        overlayClassName="modal-overlay-container"
        isOpen={this.state.openModal}
        onRequestClose={() => this.toggleModal()}
        contentLabel="Attach Evidence"
      >
        <div className="row" style={{ overflow: 'visible' }}>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-11">
                <h2 className="no-top-margin">Attach Documents</h2>
              </div>
              <div className="col-sm-1">
                <Button
                  // color="black"
                  style={{ float: 'right', background: 'none' }}
                  icon
                  size="large"
                  onClick={this.toggleModal}
                  aria-label="close"
                >
                  <Icon name="close"></Icon>
                </Button>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <hr />
          </div>

          <div className="col-sm-12 error-block">
            {this.showConfirmation()}
            {this.showError()}
          </div>
          <div className="col-xs-12">
            <Form
              onSubmit={this.handleSubmit}
              loading={this.state.saving}
              noValidate
            >
              <Form.Input
                error={!!this.state.errors.title}
                label="Title"
                aria-label="Title"
                disabled={readOnly}
                name="title"
                placeholder="Title"
                value={this.state.data.title}
                onChange={this.handleChange}
              />
              <Form.TextArea
                error={!!this.state.errors.description}
                label="Description"
                aria-label="Add Description"
                disabled={readOnly}
                name="description"
                placeholder="Add description"
                rows={5}
                value={this.state.data.description}
                onChange={this.handleChange}
              />
              <Form.Field disabled={readOnly}>
                <label htmlFor="attachmentDropzone">File</label>

                <Dropzone
                  error={!!this.state.errors.fileDrop}
                  id="attachmentDropzone"
                  disablePreview={false}
                  onDrop={this.fileOnDrop}
                  multiple
                  name="fileDrop"
                  accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf"
                  className="upload-area-attachment"
                  activeClassName="dragged"
                >
                  {({ getRootProps, getInputProps, acceptedFiles }) => {
                    return (
                      <div
                        className="upload-area-attachment"
                        {...getRootProps()}
                      >
                        {acceptedFiles.length > 0 ? (
                          <div className="upload-area-text">
                            <input
                              id="attachmentDropzone"
                              {...getInputProps()}
                            />
                            {this.state.data.file && (
                              <div>{acceptedFiles[0].name}</div>
                            )}
                            <br />
                            <BootstrapButton bsStyle="primary">
                              Choose file to upload
                            </BootstrapButton>
                          </div>
                        ) : (
                          <div className="upload-area-text">
                            <input
                              id="attachmentDropzone"
                              {...getInputProps()}
                            />
                            <div>Drop attachment files here or ...</div>
                            <br />
                            <BootstrapButton bsStyle="primary">
                              Choose file to upload
                            </BootstrapButton>
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Dropzone>
              </Form.Field>

              {this.state.data.file && (
                <div className="text-right">
                  <a href="" onClick={this.removeFile}>
                    Remove attached file
                  </a>
                </div>
              )}

              <Form.Group>
                <Form.Button
                  disabled={readOnly}
                  primary
                  content="Save Attachment"
                  size="small"
                />
                <Form.Button
                  content="Clear Form"
                  disabled={readOnly}
                  onClick={this.clearForm}
                  size="small"
                />
              </Form.Group>
            </Form>

            <h4>Documents</h4>
            {this.renderTable()}
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { router } = this.props;

    if (router.routes.length >= 2 && router.routes[1].name === 'Reports') {
      return null;
    }

    if (
      (this.props.selectedReview.reviewType === 'CLASS' ||
        this.props.selectedReview.reviewType === 'AIAN-CLASS' ||
        this.props.selectedReview.reviewType === 'PR-CLASS' ||
        this.props.selectedReview.reviewType === 'VP-CLASS' ||
        this.props.selectedReview.reviewType === 'CLASS-Video' ||
        this.props.selectedReview.reviewType ===
          'AIAN CLASS Self-Observations') &&
      this.props.source === 'cards'
    ) {
      return null;
    }

    return this.props.selectedReview.reviewType === 'CLASS' ||
      this.props.selectedReview.reviewType === 'AIAN-CLASS' ||
      this.props.selectedReview.reviewType === 'FA1-R' ||
      this.props.selectedReview.reviewType === 'FA-1' ||
      this.props.selectedReview.reviewType === 'FA1-FR' ||
      this.props.selectedReview.reviewType === 'FA-2' ||
      this.props.selectedReview.reviewType === 'FA2-CR' ||
      this.props.selectedReview.reviewType === 'Special' ||
      this.props.selectedReview.reviewType === 'AIAN-DEF' ||
      this.props.selectedReview.reviewType === 'ERSEA' ||
      this.props.selectedReview.reviewType === 'PR-CLASS' ||
      this.props.selectedReview.reviewType === 'VP-CLASS' ||
      this.props.selectedReview.reviewType === 'CLASS-Video' ||
      this.props.selectedReview.reviewType ===
        'AIAN CLASS Self-Observations' ? (
      <>
        <Menu.Item
          key={'AttachEvidence'}
          as={Link}
          link
          to="#"
          onClick={e => {
            e.preventDefault();
            this.toggleModal();
          }}
        >
          Attach Documents
        </Menu.Item>
        {this.renderModal()}
      </>
    ) : null;
  }
}

AttachEvidence.contextTypes = {
  router: PropTypes.object.isRequired,
};

AttachEvidence.propTypes = {
  selectedReview: PropTypes.object,
  addReviewEvidence: PropTypes.func.isRequired,
  downloadReviewDocument: PropTypes.func.isRequired,
  fetchReviewEvidence: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps, {
  fetchReviewEvidence,
  addReviewEvidence,
  downloadReviewDocument,
})(withRouter(AttachEvidence));
