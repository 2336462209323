import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Label } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { acl } from '../../../../config';
import enforceRole from '../../../../utils/EnforceRole';

// Import actions.
import { fetchFindingDetails } from '../../../../actions/reviewActions';
import { Link } from 'react-router';
import { fetchUsers } from '../../../../actions/userActions';

class ReviewFindings extends Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.review.selectedReview.reviewType) {
      const reviewId =
        this.context.router.params.reviewId || this.context.router.params.id;
      const reviewType = nextProps.review.selectedReview.reviewType;
      const fiscalYear = nextProps.review.selectedReview.fiscalYear;
      const isNewForms = nextProps.review.selectedReview.isNewForms;
      if (
        !isEmpty(reviewId) &&
        reviewType &&
        !this.findingsRequested &&
        fiscalYear !== 2018
      ) {
        this.props.fetchFindingDetails(
          { reviewId },
          false,
          { reviewType },
          { isNewForms }
        );
        this.findingsRequested = true;
      }

      if (!isEmpty(reviewId) && reviewType && fiscalYear === 2018) {
        this.props.fetchFindingDetails({ reviewId }, true, { reviewType });
      }
    }
    return true;
  }

  render = () => {
    const { findings, selectedReview } = this.props;
    const fiscalYear =
      selectedReview.fiscalYear &&
      selectedReview.fiscalYear.toString().slice(-2);
    const validReviewtypes = ['FA2-CR', 'FA1-FR'];
    const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];

    if (!findings) return null;

    const count1 =
      'Follow-up' === selectedReview.reviewType
        ? findings.correctedFindingsCount
        : findings.findingCount;
    const count2 =
      'Follow-up' === selectedReview.reviewType
        ? findings.unCorrectedFindingsCount
        : findings.strongPracticeCount;

    if (
      validReviewtypes.includes(selectedReview.reviewType) ||
      (newFormReviewTypes.includes(selectedReview?.reviewType) &&
        selectedReview?.isNewForms)
    ) {
      if (findings?.count == 0) return null;
      return enforceRole(
        <Menu.Item
          link
          key="ReviewFindings"
          as={Link}
          to={`/review/fy${fiscalYear}/${this.props.selectedReview.reviewId}/findings-analysis`}
        >
          <Label>{`${count1}/${count2}`}</Label>
          {'Findings Analysis'}
        </Menu.Item>,
        acl.actions.review['surveyFindings'],
        this.props.currentUser.roles
      );
    }

    return enforceRole(
      <Menu.Item
        link
        key="ReviewFindings"
        as={Link}
        to={`/review/fy${fiscalYear}/${this.props.selectedReview.reviewId}/findings`}
      >
        <Label>{findings.count}</Label>
        {'Findings Analysis'}
      </Menu.Item>,
      acl.actions.review['findings'],
      this.props.currentUser.roles
    );
  };
}

ReviewFindings.contextTypes = {
  router: PropTypes.object.isRequired,
};

ReviewFindings.propTypes = {
  fetchFindingDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  findings: state.review.fetchedFindingDetails,
  selectedReview: state.review.selectedReview,
  currentUser: state.auth.user,
  review: state.review,
});

export default connect(mapStateToProps, { fetchFindingDetails, fetchUsers })(
  ReviewFindings
);
