import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Form as SemanticForm,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Segment,
} from 'semantic-ui-react';
// Import components.
import AmsModal from '../../../utils/AmsModal';
import AmsTable from '../../../utils/AmsTable';
import FollowUpAttachmentField from './FollowUpAttachmentField';

class FollowUpAdditionalCitationsForm extends Component {
  state = {
    formData: {},
    furRecommendations: [],
    additionalCitations: [
      { guide: null, performanceMeasure: null, citation: null },
    ],
    showCitationModal: false,
    modalCitationIndex: null,
    modalCitationValue: null,
  };

  componentWillReceiveProps(nextProps) {
    const { surveyData, formDisableClassName, formDisabled } = nextProps;
    this.setState({
      surveyData,
      formDisableClassName,
      formDisabled,
    });
  }

  handleAttachmentChange = (_additionalCitation, index, { formData }) => {
    let formattedData = {
      name: 'evidenceFiles',
      value: formData.evidenceFiles,
    };
    this.props.buildFormData(formattedData, index, 'additionalCitations');
  };

  handleTextChange = (_e, data, index) => {
    this.props.buildFormData(data, index, 'additionalCitations');
  };

  handleSelection = (_e, data, index) => {
    this.props.buildFormData(data, index, 'additionalCitations');
  };

  buildGuides = guides => {
    let guideOptions = guides.map(guide => {
      return {
        key: guide.guideCode,
        value: guide.name,
        text: guide.name,
      };
    });
    return guideOptions;
  };

  buildPerformanceMeasures = guideName => {
    const {
      followUpTaskDetail: { guides },
    } = this.props;
    if (!guideName)
      return [
        {
          key: '',
          value: '',
          text: '',
        },
      ];
    else {
      let selectedGuide =
        guides &&
        _.filter(guides, guide => {
          if (guide.name === guideName) {
            return guide;
          }
        });
      let performanceMeasureOptions =
        selectedGuide &&
        selectedGuide.length > 0 &&
        selectedGuide[0].performanceMeasures &&
        selectedGuide[0].performanceMeasures.map(
          (performanceMeasure, index) => {
            return {
              key: index,
              value: performanceMeasure.name,
              text: performanceMeasure.name,
            };
          }
        );
      return performanceMeasureOptions;
    }
  };

  buildCitations = (guideName, performanceMeasureName) => {
    const {
      followUpTaskDetail: { guides },
    } = this.props;
    if (!guideName || !performanceMeasureName)
      return [
        {
          key: '',
          value: '',
          text: '',
        },
      ];
    else {
      let selectedGuide = _.filter(guides, guide => {
        if (guide.name === guideName) {
          return guide;
        }
      });
      let selectedPerformanceMeasure = _.filter(
        selectedGuide[0].performanceMeasures,
        performanceMeasure => {
          if (performanceMeasure.name === performanceMeasureName) {
            return performanceMeasure;
          }
        }
      );
      let citationOptions =
        selectedPerformanceMeasure[0].citations &&
        selectedPerformanceMeasure[0].citations.map((citation, _index) => {
          return {
            key: citation,
            value: citation,
            text: citation,
          };
        });
      return citationOptions;
    }
  };

  generateCitationLabels = additionalCitation => {
    if (
      additionalCitation &&
      additionalCitation.citation &&
      additionalCitation.citation.length > 0
    ) {
      return (
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          {additionalCitation.citation}
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          No citations found.
        </div>
      );
    }
  };

  renderForm = () => {
    const { formDisabled } = this.state;
    const { followUpTaskDetail, surveyData } = this.props;

    return (
      <div>
        <Grid>
          <Grid.Column floated="left" width={5}>
            <Header as="h2">Additional Citations</Header>
          </Grid.Column>
          <Grid.Column floated="right" width={4}>
            <Button
              icon
              primary
              onClick={e => {
                e.preventDefault();
                this.props.addAdditionalCitation();
              }}
              disabled={formDisabled}
              labelPosition="left"
            >
              Add Additional Citation
              <Icon name="add" />
            </Button>
          </Grid.Column>
        </Grid>
        <br />
        <div className={`ui form`}>
          {followUpTaskDetail &&
            followUpTaskDetail.guides &&
            surveyData &&
            surveyData.additionalCitations &&
            surveyData.additionalCitations.map((additionalCitation, index) => {
              return (
                <div key={index}>
                  <Segment>
                    <Grid>
                      <Grid.Column floated="right" width={3}>
                        <Button
                          icon
                          onClick={e => {
                            e.preventDefault();
                            this.props.removeAdditionalCitation(index);
                          }}
                          disabled={formDisabled}
                          color="red"
                          labelPosition="left"
                        >
                          Remove Citation
                          <Icon name="remove" />
                        </Button>
                      </Grid.Column>
                    </Grid>
                    <br />
                    <SemanticForm.Group
                      widths={'equal'}
                      className={'citations-guides'}
                    >
                      <SemanticForm.Dropdown
                        required
                        label={{
                          children: 'Guide',
                          htmlFor: `guideSelect${index}`,
                        }}
                        id={`guideSelect${index}`}
                        name="guide"
                        placeholder="Select Guide"
                        selection
                        options={this.buildGuides(followUpTaskDetail.guides)}
                        onChange={(e, data) =>
                          this.handleSelection(e, data, index)
                        }
                        value={additionalCitation && additionalCitation.guide}
                        openOnFocus={false}
                      />
                      <SemanticForm.Dropdown
                        required
                        label={{
                          children: 'Performance Measure',
                          htmlFor: `performanceSelect${index}`,
                        }}
                        id={`performanceSelect${index}`}
                        name="performanceMeasure"
                        placeholder="Select Performance Measure"
                        selection
                        options={
                          additionalCitation.guide
                            ? this.buildPerformanceMeasures(
                                additionalCitation.guide
                              )
                            : [
                                {
                                  key: '',
                                  value: '',
                                  text: '',
                                },
                              ]
                        }
                        onChange={(e, data) =>
                          this.handleSelection(e, data, index)
                        }
                        value={
                          additionalCitation &&
                          additionalCitation.performanceMeasure
                        }
                        openOnFocus={false}
                      />
                      <SemanticForm.Field required>
                        <label>Citations</label>
                        {this.generateCitationLabels(additionalCitation)}
                        <Link
                          to={'#'}
                          onClick={e => {
                            e.preventDefault();
                            const newArr = [];
                            if (
                              additionalCitation.citation &&
                              additionalCitation.citation.length > 0
                            ) {
                              newArr[0] = additionalCitation.citation;
                            }
                            this.setState({
                              ...this.state,
                              showCitationModal: true,
                              modalCitationIndex: index,
                              modalCitationValue: newArr,
                            });
                          }}
                        >
                          {additionalCitation &&
                          additionalCitation.citation &&
                          additionalCitation.citation.length > 0
                            ? 'Edit Citation'
                            : 'Add a Citation'}
                        </Link>
                      </SemanticForm.Field>
                      {/* <SemanticForm.Dropdown
                        required
                        label="Citations"
                        name="citation"
                        placeholder="Select Citations"
                        selection
                        options={
                          additionalCitation.guide &&
                          additionalCitation.performanceMeasure
                            ? this.buildCitations(
                                additionalCitation.guide,
                                additionalCitation.performanceMeasure
                              )
                            : [
                                {
                                  key: '',
                                  value: '',
                                  text: '',
                                },
                              ]
                        }
                        onChange={(e, data) =>
                          this.handleSelection(e, data, index)
                        }
                        value={
                          additionalCitation && additionalCitation.citation
                        }
                      />*/}
                    </SemanticForm.Group>
                    <SemanticForm.TextArea
                      required
                      className={'corrected-citation-reason'}
                      name="citationExplanation"
                      title="Please explain your response"
                      label="Please explain your response"
                      value={
                        (additionalCitation &&
                          additionalCitation.citationExplanation) ||
                        ''
                      }
                      onChange={(e, data) =>
                        this.handleTextChange(e, data, index)
                      }
                    />
                    <FollowUpAttachmentField
                      additionalCitation={additionalCitation}
                      index={index}
                      onChange={this.handleAttachmentChange}
                      evidences={
                        additionalCitation && additionalCitation.evidenceFiles
                      }
                      formName={'additionalCitations'}
                      formDisabled={formDisabled}
                    />
                  </Segment>
                  <br />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  renderCitationModal = () => {
    return (
      <AmsModal
        size={'fullscreen'}
        className="ams-semantic-modal-fix"
        open={this.state.showCitationModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          this.setState({ ...this.state, showCitationModal: false });
        }}
      >
        <Modal.Header>Citations</Modal.Header>
        {this.generateCitationTable()}
        <Modal.Actions>
          <Button
            onClick={() =>
              this.setState({
                ...this.state,
                showCitationModal: false,
                modalCitationIndex: null,
                modalCitationValue: null,
              })
            }
            negative
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.saveModalSelection()}
            positive
            labelPosition="right"
            icon="checkmark"
            content="Done"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  saveModalSelection = () => {
    const data = {
      name: 'citation',
      value: this.state.modalCitationValue
        ? this.state.modalCitationValue[0]
        : '',
    };
    this.props.buildFormData(
      data,
      this.state.modalCitationIndex,
      'additionalCitations'
    );
    this.setState({
      ...this.state,
      modalCitationIndex: null,
      modalCitationValue: null,
      showCitationModal: false,
    });
  };

  generateCitationTable = () => {
    const style = {
      description: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '60px',
      },
    };
    const formatDescription = (cell, _row) => {
      return <div style={style.description}>{cell}</div>;
    };
    const columns = [
      {
        dataField: 'standardNumber',
        text: 'Citation Number',
        formatter: (cell, _row) => {
          return (
            <Link onClick={e => e.preventDefault()} to="#">
              {cell}
            </Link>
          );
        },
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      {
        dataField: 'standardText',
        formatter: formatDescription,
        text: 'Citation Description',

        headerStyle: {
          width: '70%',
        },
      },
    ];

    const total =
      this.props.citations && this.props.citations.citations
        ? this.props.citations.citations.length
        : 0;

    const selectRow = {
      mode: 'radio',
      style: { background: '#c8e6c9' },
      clickToSelect: true,
      selected: this.state.modalCitationValue,
      onSelect: (row, isSelect, _rowIndex, _e) => {
        if (isSelect) {
          this.setState(() => ({
            ...this.state,
            modalCitationValue: [row.standardNumber],
          }));
        } else {
          this.setState(() => ({
            ...this.state,
            modalCitationValue: [],
          }));
        }
      },
      selectionRenderer: ({ mode, ...rest }) => {
        return rest.checked ? (
          <Icon color="green" name="check circle"></Icon>
        ) : null;
      },
    };

    const CitationSearch = props => {
      let input;
      const handleClick = () => {
        props.onSearch(input.value);
      };
      return (
        <>
          <Input style={{ width: '92%', marginRight: '5px' }}>
            <input placeholder="Search..." ref={n => (input = n)} type="text" />
          </Input>
          <Button onClick={handleClick}>Filter</Button>
        </>
      );
    };

    return (
      <ToolkitProvider
        keyField="standardNumber"
        data={(this.props.citations && this.props.citations.citations) || []}
        columns={columns}
        search
      >
        {props => (
          <>
            <Modal.Content style={{ paddingBottom: '5px' }}>
              <CitationSearch {...props.searchProps} />
            </Modal.Content>
            <Modal.Content style={{ paddingTop: '0px' }} scrolling>
              <AmsTable
                selectRow={selectRow}
                remote={false}
                basic
                total={total}
                columns={columns}
                loading={false}
                {...props.baseProps}
              />
            </Modal.Content>
          </>
        )}
      </ToolkitProvider>
    );
  };

  render() {
    const { followUpTaskDetail } = this.props;

    if (!followUpTaskDetail) return null;

    return (
      <div className="citation-form-container">
        {this.renderForm()}
        {this.renderCitationModal()}
      </div>
    );
  }
}

FollowUpAdditionalCitationsForm.propTypes = {
  followUpTaskDetail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  citations: state.review.citations,
  followUpTaskDetail: state.submissions.followUpTaskDetail,
  amsForm: state.submissions.AMSForm,
  selectedSubmission: state.submissions.selectedSubmission,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {})(FollowUpAdditionalCitationsForm);
