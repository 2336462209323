import { WEATHER_FETCHED } from '../redux/types';

export default function task(state = {}, action) {
  switch (action.type) {
    case WEATHER_FETCHED:
      return action.weather;

    default:
      return state;
  }
}
