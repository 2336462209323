import React from 'react';
import { Header } from 'semantic-ui-react';

import Calendar from './Calendar';

import './assets/style.css';

const CalendarWidget = props => {
  return (
    <div className="calendar-widget">
      <div className="calendar-widget-content-wrapper">
        <Header
          as="h1"
          content={
            props.userAdministration
              ? props.userAdministration + 's Calendar'
              : 'My Calendar'
          }
        />
        {/* <div className="calendar-widget-content-title">
          <h2
            className={
              props.userAdministration
                ? 'panel-title enlarged-h2'
                : 'panel-title'
            }
          >
            {props.userAdministration
              ? props.userAdministration + ' Calendar'
              : 'My Calendar'}
          </h2>
        </div> */}

        <div className="calendar-widget-content">
          <Calendar oid={props.oid || ''} userAdministration={true} />
        </div>
      </div>
    </div>
  );
};

CalendarWidget.propTypes = {};

export default CalendarWidget;
