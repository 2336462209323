import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Form, Segment } from 'semantic-ui-react';

// Import component
import TaskCreateForm from '../Tasks/TaskCreateForm';
import TaskDetailModal from '../Tasks/TaskDetailModal';
import TaskItems from '../Tasks/TaskItems';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import GranteeListDropdown from '../../utils/GranteeListDropdown';

//Import actions
import { selectTab } from '../../actions/adminActions';

import './assets/style.css';

class TaskAdminPage extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      modalIsOpen: false,
      filters: {
        fromDate: null,
        toDate: null,
        reviewId: '',
        taskDefKey: [],
        granteeList: '',
      },
      selectedTask: {},
      selectedFilters: {},
      errors: {
        fromDateError: '',
        toDateError: '',
      },
      responseErrors: [],
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handelTaskItemClick = this.handelTaskItemClick.bind(this);
  }

  componentWillMount() {
    this.props.selectTab({
      key: 0,
      pageTitle: 'Tasks',
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillUpdate(nextProps, nextState) {
    $('table thead tr th').attr('tabindex', '0');
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  // Validate date range filter.
  validateDateRangeFilter() {
    const { fromDate, toDate } = this.state.filters;

    let errors = {};

    // Either from or to are available.
    if (!_.isEmpty(fromDate) || !_.isEmpty(toDate)) {
      if (_.isEmpty(fromDate)) {
        errors.fromDateError = 'Both From and To dates are required';
        this.setState({ errors });
      }

      if (_.isEmpty(toDate)) {
        errors.toDateError = 'Both From and To dates are required';
        this.setState({ errors });
      }
    }

    // Both from and to dates are set.
    if (!_.isEmpty(fromDate) && !_.isEmpty(toDate)) {
      // Check if both dates are valid.
      if (
        !AmsDateFormatters.getMoment(fromDate).isValid() &&
        !AmsDateFormatters.getMoment(toDate).isValid()
      ) {
        errors.fromDateError = 'Invalid date added';
        errors.toDateError = 'Invalid date added';
        this.setState({ errors });
      }

      // Check if to date is after from date.
      if (
        AmsDateFormatters.getMoment(toDate).isBefore(
          AmsDateFormatters.getMoment(fromDate)
        )
      ) {
        errors.toDateError = 'To date must be after From date.';
        this.setState({ errors });
      }
    }

    return errors;
  }

  getData() {
    const {
      fromDate,
      toDate,
      reviewId,
      taskDefKey,
      granteeList,
    } = this.state.filters;

    let filters = {};

    if (Object.keys(this.validateDateRangeFilter()).length === 0) {
      if (!_.isEmpty(fromDate) && !_.isEmpty(toDate))
        // Only add date filters if both from and to are provided.

        filters = {
          ...filters,
          fromDate: AmsDateFormatters.formatDate(fromDate),
          toDate: AmsDateFormatters.formatDate(toDate),
        };
    } else {
      return; // There are date filter errors, abort request.
    }

    if (!_.isEmpty(reviewId))
      filters = { ...filters, reviewId: reviewId.trim() }; // Add filter if available

    if (!_.isEmpty(taskDefKey)) filters = { ...filters, taskDefKey }; // Add filter if available

    if (!_.isEmpty(granteeList)) {
      filters = { ...filters, granteeList: [granteeList] }; // Add selected grantees.
    }

    this.setState({ selectedFilters: filters }, () => this.taskItems.getData());
  }

  handleFilterSubmit() {
    this.setState({ responseErrors: [] });
    this.getData();
  }

  handleFilterReset() {
    this.setState(
      {
        modalIsOpen: false,
        filters: {
          fromDate: null,
          toDate: null,
          reviewId: '',
          taskDefKey: [],
          granteeList: '',
        },
        selectedTask: {},
        selectedFilters: {},
        errors: {
          fromDateError: '',
          toDateError: '',
        },
        responseErrors: [],
      },
      () => this.taskItems.getData()
    );
  }

  handleFromDateChange = value => {
    if (!value) {
      this.setState({
        ...this.state,
        filters: { ...this.state.filters, fromDate: null },
      });
      return;
    }
    let errors = Object.assign({}, this.state.errors, { fromDateError: '' });
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        fromDate: AmsDateFormatters.getMoment(value).startOf('day'),
      },
      errors: errors,
    });
  };

  handleToDateChange = value => {
    if (!value) {
      this.setState({
        ...this.state,
        filters: { ...this.state.filters, toDate: null },
      });
      return;
    }
    let errors = Object.assign({}, this.state.errors, { toDateError: '' });
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        toDate: AmsDateFormatters.getMoment(value).startOf('day'),
      },
      errors: errors,
    });
  };

  handleSelectedGranteesChange = value =>
    this.setState({
      ...this.state,
      filters: { ...this.state.filters, granteeList: value },
    });

  handleReviewIdInputhChange = (e, { value }) =>
    this.setState({
      ...this.state,
      filters: { ...this.state.filters, reviewId: value },
    });

  renderTaskFilters() {
    const {
      fromDate,
      toDate,
      reviewId,
      taskDefKey,
      granteeList,
    } = this.state.filters;

    const { fromDateError, toDateError } = this.state.errors;

    return (
      <Segment>
        <AmsSkipLinks
          links={[{ title: 'Skip filters and go to results', to: 'add-task' }]}
        />

        <Form className="filter-form">
          <Form.Group widths="equal">
            <Form.Field
              // width={2}
              error={
                !!fromDateError && {
                  content: fromDateError,
                }
              }
              name="fromDate"
              control={DatePicker}
              label={{
                children: 'From Date',
                htmlFor: 'fromDate',
              }}
              selectsStart
              placeholderText="From Date"
              selected={fromDate}
              fromDate={fromDate}
              onChange={this.handleFromDateChange}
              id="fromDate"
              aria-labelledby="fromDate"
            />

            <Form.Field
              // width={2}
              error={
                !!toDateError && {
                  content: toDateError,
                }
              }
              name="toDate"
              control={DatePicker}
              label={{
                children: 'To Date',
                htmlFor: 'toDate',
              }}
              disabled={!fromDate}
              selectsStart
              placeholderText="To Date"
              selected={toDate}
              fromDate={fromDate}
              minDate={fromDate}
              onChange={this.handleToDateChange}
              id="toDate"
              aria-labelledby="toDate"
            />

            <Form.Field>
              <Form.Input
                label={{ children: 'Review ID', htmlFor: 'reviewId' }}
                value={reviewId}
                maxLength="10"
                onChange={this.handleReviewIdInputhChange.bind(this)}
                id="reviewId"
                placeholder="Enter Review ID"
                aria-labelledby="reviewId"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              label={{ children: 'Task Type', htmlFor: 'taskTypes' }}
              placeholder="Select Task Type"
              control={AmsLookupDropdown}
              value={taskDefKey}
              multiple
              selection
              category={'taskTypes'}
              onChange={(e, { value }) =>
                this.setState({
                  ...this.state,
                  filters: { ...this.state.filters, taskDefKey: value },
                })
              }
              id="taskTypes"
              aria-labelledby="taskTypes"
            />
            <Form.Field>
              <GranteeListDropdown
                dropdownLabel="Grantees"
                placeholder="Type Grantee Name or ID to filter"
                value={granteeList}
                selection
                search
                onValueSelected={this.handleSelectedGranteesChange.bind(this)}
                onError={error => console.log(error)}
              />
            </Form.Field>

            <Form.Group>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  primary
                  onClick={this.handleFilterSubmit.bind(this)}
                >
                  Filter
                </Form.Button>
              </Form.Field>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  basic
                  onClick={this.handleFilterReset.bind(this)}
                >
                  Reset
                </Form.Button>
              </Form.Field>
            </Form.Group>
          </Form.Group>
        </Form>
      </Segment>
    );
  }

  showReviewError(error) {
    this.setState({
      responseErrors: [...this.state.responseErrors, error.response.data],
    });
  }

  renderTaskList() {
    const { selectedFilters } = this.state;

    return (
      <TaskItems
        showDelete
        showAssignee
        filters={selectedFilters}
        onFilter={ref => (this.taskItems = ref)}
        taskItemClick={this.handelTaskItemClick}
        onError={this.showReviewError.bind(this)}
      />
    );
  }

  handelTaskItemClick(selectedTask) {
    const { reviewId, taskDefKey } = selectedTask;

    if (!reviewId && !taskDefKey) return;

    switch (taskDefKey) {
      case 'performCenterClassroomEligibilityFilesDataCollection': {
        switch (selectedTask.reviewType) {
          case 'FA1-FR':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/fa1fr`
            );
            break;
          case 'FA2-CR':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/fa2cr`
            );
            break;
          case 'Special':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/special`
            );
            break;
          default:
            break;
        }
        break;
      }
      case 'performEvidenceSourceGuideDataCollection': {
        switch (selectedTask.reviewType) {
          case 'FA1-FR':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/fa1fr`
            );
            break;
          case 'FA2-CR':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/fa2cr`
            );
            break;
          case 'RAN':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/ran`
            );
            break;
          case 'Special':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/special`
            );
            break;
          default:
            break;
        }
        break;
      }
      case 'performDataCollection': {
        switch (selectedTask.reviewType) {
          case 'CLASS':
          case 'AIAN-CLASS':
          case 'PR-CLASS':
          case 'VP-CLASS':
          case 'CLASS-Video':
          case 'AIAN CLASS Self-Observations':
            // window.open(`/task/${selectedTask.reviewId}/survey/class`);
            this.props.router.push(
              `/task/${selectedTask.fiscalYear}/${selectedTask.reviewId}/survey/class`
            );
            break;
          case 'FA-1': {
            if (selectedTask.newFAFlowFlag) {
              // window.open(`/task/${selectedTask.reviewId}/survey/fa1`);
              this.props.router.push(
                `/task/${selectedTask.reviewId}/survey/fa1`
              );
            } else {
              this.setState({
                modalIsOpen: true,
                selectedTask,
              });
            }
            break;
          }
          case 'FA1-FR': {
            if (selectedTask.newFAFlowFlag) {
              this.props.router.push(
                `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                  selectedTask.reviewId
                }/survey/fa1fr`
              );
            } else {
              this.setState({
                modalIsOpen: true,
                selectedTask,
              });
            }
            break;
          }
          case 'FA-2': {
            if (selectedTask.newFAFlowFlag) {
              // window.open(`/task/${selectedTask.reviewId}/survey/fa2`);
              this.props.router.push(
                `/task/${selectedTask.reviewId}/survey/fa2`
              );
            } else {
              this.setState({
                modalIsOpen: true,
                selectedTask,
              });
            }
            break;
          }
          case 'FA2-CR': {
            if (selectedTask.newFAFlowFlag) {
              this.props.router.push(
                `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                  selectedTask.reviewId
                }/survey/fa2cr`
              );
            } else {
              this.setState({
                modalIsOpen: true,
                selectedTask,
              });
            }
            break;
          }
          case 'Follow-up':
            // window.open(`/task/${selectedTask.reviewId}/survey/follow-up`);
            this.props.router.push(
              `/task/${selectedTask.reviewId}/survey/follow-up`
            );
            break;
          case 'RAN':
            // window.open(`/task/${selectedTask.reviewId}/survey/ran`);
            this.props.router.push(`/task/${selectedTask.reviewId}/survey/ran`);
            break;
          case 'Special':
            // window.open(`/task/${selectedTask.reviewId}/survey/special`);
            this.props.router.push(
              `/task/${selectedTask.reviewId}/survey/special`
            );
            break;
          case 'AIAN-DEF':
            // window.open(`/task/${selectedTask.reviewId}/survey/aian-def`);
            this.props.router.push(
              `/task/${selectedTask.reviewId}/survey/aian-def`
            );
            break;
          case 'FA2-CR':
            this.props.router.push(
              `/task/${selectedTask.reviewId}/survey/fa2cr`
            );
            break;
          case 'FA1-FR':
            this.props.router.push(
              `/task/${selectedTask.reviewId}/survey/fa1fr`
            );
            break;
          case 'ERSEA':
            // window.open(`/task/${selectedTask.reviewId}/survey/ersea`);
            this.props.router.push(
              `/task/${selectedTask.reviewId}/survey/ersea`
            );
            break;

          default:
            break;
        }
        break;
      }
      case 'approveDataCollection': {
        this.context.router.push(`/review/${selectedTask.reviewId}`);
        break;
      }
      case 'performReportCreation': {
        this.context.router.push(`/reviews/${selectedTask.reviewId}`);
        break;
      }
      case 'reviewReportCollection': {
        if (selectedTask.newFAFlowFlag) {
          let fiscalYear = selectedTask.fiscalYear
            ? `fy` + selectedTask.fiscalYear.toString().slice(-2)
            : 'fy19';
          this.context.router.push(
            `/review/${fiscalYear}/${selectedTask.reviewId}`
          );
        } else {
          this.context.router.push(`/review/${selectedTask.reviewId}`);
        }
        break;
      }
      default:
        break;
    }
  }

  showDetailModal() {
    const { selectedTask, modalIsOpen } = this.state;

    if (selectedTask && selectedTask._id)
      return (
        <TaskDetailModal
          task={selectedTask}
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
        />
      );
  }

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:(
                {errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  showCreateTaskModal() {
    const { action } = this.props.params;

    if (action === 'create') return <TaskCreateForm />;
  }

  handleAddNewTask() {
    this.context.router.push(`/admin/tasks/create`);
  }

  showCreateTaskButton() {
    return (
      <div className="create-task-form-wrapper">
        <Button
          onClick={this.handleAddNewTask.bind(this)}
          className="btn btn-primary btn-addon m-b-sm"
          id="add-task"
        >
          <i className="fa fa-plus" /> Add New Task
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className="task-page">
        {this.showResponseErrors()}
        {this.showDetailModal()}
        {this.renderTaskFilters()}
        {this.showCreateTaskButton()}
        {this.showCreateTaskModal()}
        {this.renderTaskList()}
      </div>
    );
  }
}

TaskAdminPage.propTypes = {
  tasks: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
};

TaskAdminPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    tasks: state.tasks.taskList,
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps, { selectTab })(TaskAdminPage);
