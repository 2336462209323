import moment from 'moment-timezone';

// Import configs
import { settings } from '../config';

import {
  CALENDAR_EVENTS_FETCHED,
  CALENDAR_HOLIDAYS_FETCHED,
} from '../redux/types';

moment.tz.setDefault(settings.defaultTimeZone);

const initialState = {
  exclude: [],
  events: [],
  holidays: [],
  lastUpdatedDate: '',
};

export default function calendar(state = initialState, action) {
  switch (action.type) {
    case CALENDAR_HOLIDAYS_FETCHED: {
      let exclude = [];
      let holidays = action.holidays;
      return { ...state, holidays, exclude: [...state.exclude, ...exclude] };
    }

    case CALENDAR_EVENTS_FETCHED: {
      let exclude = [];
      let lastUpdatedDate = action.lastUpdatedDate;
      const events = action.events.map(event => {
        if (event.type === 'review') {
          event.color = settings.calendar.events.colors.review;
        }
        if (event.type === 'invited') {
          event.color = settings.calendar.events.colors.invited;
        }
        if (event.type === 'unavailable') {
          event.color = settings.calendar.events.colors.unavailable;
        }
        if (event.type === 'withdrawn') {
          event.color = settings.calendar.events.colors.withdrawn;
        }
        if (event.type === 'declined') {
          event.color = settings.calendar.events.colors.declined;
        }
        if (event.type === 'Federal') {
          event.color = settings.calendar.events.colors.federalHolidays;
        }
        if (event.type !== 'unavailable')
          exclude = [
            ...exclude,
            ...getRangeOfDates(moment(event.start), moment(event.end), 'day'),
          ];
        return event;
      });

      return {
        ...state,
        events,
        exclude: [...exclude],
        lastUpdatedDate,
      };
    }
    default:
      return state;
  }
}

const getRangeOfDates = (start, end, key, arr = [start.startOf(key)]) => {
  if (start.isAfter(end)) throw new Error('start must precede end');
  const next = moment(start)
    .add(1, key)
    .startOf(key);
  if (next.isAfter(end, key)) return arr;
  return getRangeOfDates(next, end, key, arr.concat(next));
};
