import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import { Grid, Header, Label, List, Loader, Segment } from 'semantic-ui-react';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import enforceRole from '../../utils/EnforceRole';
import { shortenText } from '../../utils/TextFormatters';
import { generateActivePostsObject } from './utils';

// Import config.
import { acl } from '../../config';

// Style
import './assets/style.css';

// Actions.
import { fetchTags } from '../../actions/help/tagActions';

const HelpSections = props => {
  const currentUser = useSelector(state => state.auth.user);
  const { categoryDetails, tags } = useSelector(state => state.help);
  const [loading, setLoading] = useState(false);
  const [activePosts, setActivePosts] = useState({});

  const isAdmin = enforceRole(
    true,
    acl.actions.help.create, // Allowed roles
    currentUser.roles // Current user roles
  );

  const dispatch = useDispatch();

  const activeTags =
    tags &&
    tags.filter(e => {
      return e.active === true;
    });

  useEffect(() => {
    setLoading(true);
    dispatch(fetchTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categoryDetails) {
      setLoading(false);
    }
    if (categoryDetails && activeTags && activeTags.length > 0) {
      const activePostsObj = generateActivePostsObject(
        categoryDetails,
        isAdmin ? tags : activeTags,
        isAdmin
      );

      setActivePosts(activePostsObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetails]);

  const renderActivePosts = () => {
    const activePostTags = Object.keys(activePosts);
    if (activePostTags.length > 0) {
      return activePostTags.map(e => {
        const tagObj = tags.find(tag => tag._id === e);
        const isActive = tagObj.active;
        return (
          <React.Fragment key={e}>
            <Header as="h3">
              Tag: {tagObj.title}
              {isActive ? '' : '(inactive)'}
            </Header>
            <List relaxed="very">
              {activePosts[e].map(post => {
                return (
                  <List.Item key={post._id}>
                    <List.Content>
                      <List.Header>
                        <Link
                          to={`/help/${categoryDetails.category.slug}/${post._id}`}
                        >
                          {post.title}
                        </Link>
                        &nbsp;
                        {post.published ? '' : <Label content="Unpublished" />}
                      </List.Header>
                      <List.Description>{`Last Updated: ${AmsDateFormatters.formatDate(
                        post.updatedDate
                      )}`}</List.Description>
                    </List.Content>
                    <List.Content style={{ marginTop: '0.5em' }}>
                      <List.Description>
                        {shortenText(post.content, 150)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </React.Fragment>
        );
      });
    }
    return (
      <p
        style={{
          textAlign: 'center',
          marginTop: '40px',
          color: 'rgb(133,137,145)',
        }}
      >
        No posts found
      </p>
    );
  };

  return (
    <Grid stackable>
      <Grid.Row stretched>
        <Grid.Column>
          <Segment basic>
            {loading ? (
              <Loader
                active={loading}
                inline="centered"
                content="Loading posts..."
              />
            ) : (
              renderActivePosts()
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default HelpSections;
