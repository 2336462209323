import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { getReportSavedResult, fetchReportStatusData } from './reportActions';
import { downloadBlob } from '../components/Shared/FormHelpers/helpers/utils';

import {
  REVIEWS_FETCHED,
  SURVEY_DETAILS_FETCHED,
  REVIEW_ADDED,
  REVIEW_SELECTED,
  REVIEW_FINALIZED,
  MANIFEST_FETCHED,
  MANIFEST_FILE_RECREATED,
  ADD_ERROR,
  REMOVE_ERROR,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  REVIEW_SURVEY_SUBMISSIONS_FETCHED,
  REVIEW_SURVEY_SUBMITTERS_FETCHED,
  REVIEW_FIDINGS_DETAILS,
  REVIEW_FOLLOWUP_RECOMMENDATIONS,
  REVIEW_EMAIL_NOTIFY_MPS,
  REVIEW_SET_RESPONSE_OBJ,
  REVIEW_EMAIL_NOTIFY_RL,
  REVIEW_EMAIL_NOTIFY_QC,
  REVIEW_EMAIL_NOTIFY_FOM,
  REVIEW_RPM_FEEDBACK_PROGRAM_SPECIALIST,
  REVIEW_RPM_FEEDBACK_FISCAL_SPECIALIST,
  REVIEW_RPM_FEEDBACK,
  UNSCHEDULED_REVIEWERS_FETCHED,
  REVIEWER_POSITION_CLEARED,
  REVIEW_INFORMATION_FETCHED,
  REVIEW_DOCUMENTS_FETCHED,
  REVIEW_EVIDENCE_ADDED,
  REVIEW_EVIDENCE_FETCHED,
  REVIEW_EVIDENCE_DOWNLOADED,
  REVIEW_FINALIZE_FUR,
  UPDATE_REVIEW_STATUS,
  REVIEW_CREATE_FOLLOWUP_REVIEW,
  CITATIONS_FETCHED,
  CITATIONS_FILTERED,
  CITATIONS_FILTER_CLEARED,
  UNSCHEDULED_REVIEWERS_CLEARED,
  REVIEWER_POSITION_ADD,
  REVIEWER_POSITION_REVIEWER_ADD,
  REVIEWER_POSITION_SELECTED,
  REVIEWER_POSITION_FETCHED,
  REVIEWER_POSITION_REVIEWER_UPDATE,
  REVIEW_FOLLOWUPS_FETCHED,
  EVIDENCE_BINDER_FETCHED,
  REVIEWER_TOTAL_INCREMENT,
  CREATE_REVIEW_ADD_PRIMARY_USER,
  CREATE_REVIEW_UPDATE_TEAM,
  CREATE_REVIEW_UPDATE_SHADOWREVIEWER_LOCATION,
  CREATE_REVIEW_REMOVE_PRIMARY_USER,
  UPDATE_TEAM_POSITIONS,
  JOURNAL_LOGS_FETCHED,
  REVIEW_FOLLOWUP_FINDING_DETAILS_FETCHED,
  REVIEW_FOLLOWUP_FUR_TEAM_FETCHED,
  REVIEW_FOLLOWUP_FUR_TEAM_UPDATED,
  EXPLORATION_SUMMARY_FETCHED,
  MANAGEMENT_SYSTEM_ANALYSIS_REPORT_FETCHED,
  PRE_REVIEW_SUMMARY_PAGE_FETCHED,
} from '../redux/types';

export function manifestFetched(manifest) {
  return {
    type: MANIFEST_FETCHED,
    manifest,
  };
}

export const manifestFileRecreated = data => ({
  type: MANIFEST_FILE_RECREATED,
  link: data.link,
  message: data.message,
});

export function reviewsFetched(reviews) {
  return {
    type: REVIEWS_FETCHED,
    reviewsList: reviews,
    reviews: reviews.data,
  };
}

export function getSurveyDetailList(surveyDetails) {
  return {
    type: SURVEY_DETAILS_FETCHED,
    surveyDetails,
  };
}

export function setReview(message) {
  return {
    type: REVIEW_ADDED,
    message,
  };
}

export function getSelectedReview(review) {
  return {
    type: REVIEW_SELECTED,
    review,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function removeErrorByAction(action) {
  return {
    type: REMOVE_ERROR,
    action,
  };
}

export function setMessage(message) {
  return {
    type: ADD_MESSAGE,
    message,
  };
}

export function setReviewFinal(message) {
  return {
    type: REVIEW_FINALIZED,
    message,
  };
}

export function removeMessage() {
  return {
    type: REMOVE_MESSAGE,
  };
}

export function createFollowupReviewFetched() {
  return {
    type: REVIEW_CREATE_FOLLOWUP_REVIEW,
  };
}

export const reviewSurveySubmissionsFetched = submissions => ({
  type: REVIEW_SURVEY_SUBMISSIONS_FETCHED,
  submissions,
});

export const reviewSurveySubmittersFetched = submitters => ({
  type: REVIEW_SURVEY_SUBMITTERS_FETCHED,
  submitters,
});

export const reviewDocumentsFetched = reviewDocumentsFetchedList => ({
  type: REVIEW_DOCUMENTS_FETCHED,
  reviewDocumentsFetchedList,
});

export function getFindingDetailsResponse(fetchedFindingDetails) {
  return {
    type: REVIEW_FIDINGS_DETAILS,
    fetchedFindingDetails,
  };
}

export function getFollowupRecommendationsResponse(
  fetchedFollowupRecommendations
) {
  return {
    type: REVIEW_FOLLOWUP_RECOMMENDATIONS,
    fetchedFollowupRecommendations,
  };
}

export function fetchedEmailNotifyMpsResponse(fetchedEmailNotifyMps) {
  return {
    type: REVIEW_EMAIL_NOTIFY_MPS,
    fetchedEmailNotifyMps,
  };
}

export function returnedResStatus(returnedStatus) {
  return {
    type: REVIEW_SET_RESPONSE_OBJ,
    returnedStatus,
  };
}

export function fetchedEmailNotifyQCResponse(fetchedEmailNotifyQC) {
  return {
    type: REVIEW_EMAIL_NOTIFY_QC,
    fetchedEmailNotifyQC,
  };
}

export function fetchedEmailNotifyRLResponse(fetchedEmailNotifyRL) {
  return {
    type: REVIEW_EMAIL_NOTIFY_RL,
    fetchedEmailNotifyRL,
  };
}

export function fetchedEmailNotifyFOMResponse(fetchedEmailNotifyFOM) {
  return {
    type: REVIEW_EMAIL_NOTIFY_FOM,
    fetchedEmailNotifyFOM,
  };
}

export function saveReviewRpmFeedbackFetched(saveReviewRpmFeedbackFetchedData) {
  return {
    type: REVIEW_RPM_FEEDBACK,
    saveReviewRpmFeedbackFetchedData,
  };
}

export function reviewRpmFeedbackFiscalSpecialistFetched(
  reviewRpmFeedbackFiscalSpecialistFetchedList
) {
  return {
    type: REVIEW_RPM_FEEDBACK_FISCAL_SPECIALIST,
    reviewRpmFeedbackFiscalSpecialistFetchedList,
  };
}

export function reviewRpmFeedbackProgramSpecialistFetched(
  reviewRpmFeedbackProgramSpecialistFetchedList
) {
  return {
    type: REVIEW_RPM_FEEDBACK_PROGRAM_SPECIALIST,
    reviewRpmFeedbackProgramSpecialistFetchedList,
  };
}

export const unscheduledReviewersFetched = unscheduledReviewers => ({
  type: UNSCHEDULED_REVIEWERS_FETCHED,
  unscheduledReviewers,
});

export const unscheduledReviewersCleared = unscheduledReviewers => ({
  type: UNSCHEDULED_REVIEWERS_CLEARED,
  unscheduledReviewers,
});

export const reviewInfoFetched = reviewInfo => ({
  type: REVIEW_INFORMATION_FETCHED,
  reviewInfo,
});
export const reviewEvidenceAdded = document => ({
  type: REVIEW_EVIDENCE_ADDED,
  document,
});

export const reviewEvidenceFetched = documents => ({
  type: REVIEW_EVIDENCE_FETCHED,
  documents,
});

export const reviewEvidenceDownloaded = () => ({
  type: REVIEW_EVIDENCE_DOWNLOADED,
});

export function finalizeFURRes(finalizeFURRes) {
  return {
    type: REVIEW_FINALIZE_FUR,
    finalizeFUR: true,
  };
}

export function updateReviewStatus(status) {
  return {
    type: UPDATE_REVIEW_STATUS,
    status,
  };
}

export const saveCitations = citations => {
  return {
    type: CITATIONS_FETCHED,
    citations,
  };
};

export const citationsFiltered = citations => ({
  type: CITATIONS_FILTERED,
  citations,
});

export const citationsFilterCleared = citations => {
  return {
    type: CITATIONS_FILTER_CLEARED,
  };
};

export const clearReviewPositions = () => {
  return {
    type: REVIEWER_POSITION_CLEARED,
  };
};

export const addPosition = positions => {
  return {
    type: REVIEWER_POSITION_ADD,
    positions,
  };
};

export const addPositionReviewer = reviewers => {
  return {
    type: REVIEWER_POSITION_REVIEWER_ADD,
    reviewers,
  };
};

export const updatePositionReviewer = (reviewer, data) => {
  return {
    type: REVIEWER_POSITION_REVIEWER_UPDATE,
    reviewer,
    data,
  };
};

export const selectedPosition = (selectedPosition, runPresetSearch) => {
  return {
    type: REVIEWER_POSITION_SELECTED,
    selectedPosition,
    runPresetSearch,
  };
};

export const reviewerPositionFetched = () => {
  return {
    type: REVIEWER_POSITION_FETCHED,
  };
};

export function reviewFollowUpsFetched(followUps) {
  return {
    type: REVIEW_FOLLOWUPS_FETCHED,
    reviewFollowUpsList: followUps,
  };
}

export const managementSystemAnalysisReportFetched = managementSystemAnalysisSummary => ({
  type: MANAGEMENT_SYSTEM_ANALYSIS_REPORT_FETCHED,
  managementSystemAnalysisSummary,
});

export const preReviewSummaryFetched = preReviewSummaryPage => ({
  type: PRE_REVIEW_SUMMARY_PAGE_FETCHED,
  preReviewSummaryPage,
});

export const incrementNumberOfReviewers = () => {
  return {
    type: REVIEWER_TOTAL_INCREMENT,
  };
};

export const addPrimaryUser = primaryUser => {
  return {
    type: CREATE_REVIEW_ADD_PRIMARY_USER,
    primaryUser,
  };
};

export const updateTeam = (name, team) => {
  return {
    type: CREATE_REVIEW_UPDATE_TEAM,
    team,
    name,
  };
};

export const updateShadowReviewerLocation = shadowReviewer => {
  return {
    type: CREATE_REVIEW_UPDATE_SHADOWREVIEWER_LOCATION,
    shadowReviewer,
  };
};

export const removePrimaryUser = primaryUser => {
  return {
    type: CREATE_REVIEW_REMOVE_PRIMARY_USER,
    primaryUser,
  };
};

export const updateTeamPositions = updatedTeamPositions => {
  return {
    type: UPDATE_TEAM_POSITIONS,
    updatedTeamPositions,
  };
};

export const journalLogsFetched = journalLogs => {
  return {
    type: JOURNAL_LOGS_FETCHED,
    journalLogs,
  };
};

export const followUpFindingDetailsFetched = followupFindingDetails => {
  return {
    type: REVIEW_FOLLOWUP_FINDING_DETAILS_FETCHED,
    followupFindingDetails,
  };
};

export const followUpFURTeamInfoFetched = furTeamInfo => {
  return {
    type: REVIEW_FOLLOWUP_FUR_TEAM_FETCHED,
    furTeamInfo,
  };
};

export const updateFURs = (followUpReviews, index) => {
  return {
    type: REVIEW_FOLLOWUP_FUR_TEAM_UPDATED,
    review: followUpReviews[index],
    index: index,
  };
};

export const explorationSummaryFetched = explorationSummary => {
  return {
    type: EXPLORATION_SUMMARY_FETCHED,
    explorationSummary,
  };
};

// export const fetchReviews = requestInput => dispatch =>
//   axios.post(`/review/list`, requestInput)
//     .then(response => dispatch(reviewsFetched(response.data)));

export const fetchReviews = requestInput => dispatch =>
  axios
    .post(`/review/list`, requestInput)
    .then(response => dispatch(reviewsFetched(response.data)));

export const fetchReviewDetail = requestInput => dispatch =>
  axios
    .post(`/review/detail`, requestInput)
    .then(response => dispatch(getSelectedReview(response.data)));

export const fetchSurveyDetailList = requestInput => dispatch =>
  axios
    .post(`/review/surveyDetailList`, requestInput)
    .then(response => dispatch(getSurveyDetailList(response.data)));

export const addReview = reviewObject => dispatch =>
  axios.post(`/review/add`, reviewObject).then(response => {
    dispatch(setMessage(response.data));
    return response.data;
  });

export const reviewInfoFetch = reviewId => dispatch =>
  axios
    .post(`/review/reviewInfoFetch/${reviewId}`)
    .then(response => dispatch(reviewInfoFetched(response.data)));

export const updateReview = reviewObject => dispatch =>
  axios.post(`/review/update`, reviewObject).then(response => {
    dispatch(setMessage(response.data));
    return response.data;
  });

export const finalizeReview = reviewObject => dispatch =>
  axios
    .post(`/review/finalize`, reviewObject)
    .then(response => {
      dispatch(setMessage(response.data));
    })
    .catch(error => {
      console.error(error);
      // dispatch(setError(error))
    });

export function clearMessage() {
  return dispatch => {
    dispatch(removeMessage());
  };
}

export function clearReviewDetail() {
  return dispatch => {
    dispatch(getSelectedReview({}));
  };
}

export const evidenceBinderFetched = files => ({
  type: EVIDENCE_BINDER_FETCHED,
  files,
});

export const fetchFindingDetails = (
  requestInput,
  isOldAPI,
  reviewType,
  isNewForms
) => dispatch => {
  if ((reviewType || {}).reviewType === 'FA2-CR') {
    axios
      .post(`/review/fa2cr-findingDetails`, requestInput)
      .then(response => dispatch(getFindingDetailsResponse(response.data)));
  } else if ((reviewType || {}).reviewType === 'Follow-up') {
    if (!(isNewForms || {}).isNewForms) {
      axios
        .post(`/review/fuFindingDetails`, requestInput)
        .then(response => dispatch(getFindingDetailsResponse(response.data)));
    } else {
      axios
        .post(`/review/followup-findingDetails`, requestInput)
        .then(response => dispatch(getFindingDetailsResponse(response.data)));
    }
  } else if ((reviewType || {}).reviewType === 'RAN') {
    if (!(isNewForms || {}).isNewForms) {
      axios
        .post(`/review/ranFindingDetails`, requestInput)
        .then(response => dispatch(getFindingDetailsResponse(response.data)));
    } else {
      axios
        .post(`/review/ran-findingDetails`, requestInput)
        .then(response => dispatch(getFindingDetailsResponse(response.data)));
    }
  } else if (!isOldAPI) {
    if (
      (reviewType || {}).reviewType === 'FA-2' ||
      (reviewType || {}).reviewType === 'Special' ||
      (reviewType || {}).reviewType === 'AIAN-DEF' ||
      (reviewType || {}).reviewType === 'ERSEA' ||
      (reviewType || {}).reviewType === 'FA2-CR'
    ) {
      if (
        (reviewType || {}).reviewType === 'Special' &&
        (isNewForms || {}).isNewForms
      ) {
        axios
          .post(`/review/special-findingDetails`, requestInput)
          .then(response => dispatch(getFindingDetailsResponse(response.data)));
      } else {
        axios
          .post(`/review/fa2-successorFindingDetails`, requestInput)
          .then(response => dispatch(getFindingDetailsResponse(response.data)));
      }
    } else if ((reviewType || {}).reviewType === 'FA-1') {
      axios
        .post(`/review/fa1-successorFindingDetails`, requestInput)
        .then(response => dispatch(getFindingDetailsResponse(response.data)));
    } else if ((reviewType || {}).reviewType === 'FA1-FR') {
      axios
        .post(`/review/fa1fr-findingDetails`, requestInput)
        .then(response => dispatch(getFindingDetailsResponse(response.data)));
    }
  } else if (isOldAPI) {
    axios
      .post(`/review/ancestorFindingDetails`, requestInput)
      .then(response => dispatch(getFindingDetailsResponse(response.data)));
  }
};

export const fetchFollowupRecommendations = reviewId => dispatch =>
  axios
    .post(`/review/fetchFurRecs/${reviewId}`)
    .then(response =>
      dispatch(getFollowupRecommendationsResponse(response.data))
    );

export const fetchEmailNotifyMpsRequest = (requestInput, cb) => dispatch =>
  axios
    .post(`/review/email/notifyMps`, requestInput)
    .then(
      response =>
        cb(response) ||
        (dispatch(fetchedEmailNotifyMpsResponse(response.data)) &&
          dispatch(returnedResStatus(response))),
      cb || console.error
    );

export const fetchEmailNotifyQAARequest = (requestInput, cb) => dispatch =>
  axios
    .post(`/review/email/notifyQAA`, requestInput)
    .then(
      response =>
        cb(response) || dispatch(fetchedEmailNotifyQCResponse(response.data)),
      cb || console.error
    );

export const fetchEmailNotifyRLRequest = (requestInput, cb) => dispatch =>
  axios
    .post(`/review/email/notifyRL`, requestInput)
    .then(
      response =>
        cb(response) || dispatch(fetchedEmailNotifyRLResponse(response.data)),
      cb || console.error
    );

export const fetchEmailNotifyFOMRequest = (requestInput, cb) => dispatch =>
  axios
    .post(`/review/email/notifyFOM`, requestInput)
    .then(
      response =>
        cb(response) || dispatch(fetchedEmailNotifyFOMResponse(response.data)),
      cb || console.error
    );

export const fetchManifest = reviewId => dispatch =>
  axios
    .post(`/review/fetchManifest`, { reviewId })
    .then(response => dispatch(manifestFetched(response.data)));

export const recreateManifestFile = reviewId => dispatch =>
  axios.post(`/review/manifest/recreate`, { reviewId }).then(response => {
    if (response.data && response.data.message) {
      dispatch(manifestFileRecreated(response.data));
      return response.data.message;
    }
  });

export const fetchReviewSurveySubmissions = data => dispatch =>
  axios
    .post(`/survey/submissions`, data)
    .then(response => dispatch(reviewSurveySubmissionsFetched(response.data)));

export const fetchReviewSurveySubmitters = reviewId => dispatch =>
  axios
    .post(`/survey/submitters/${reviewId}`)
    .then(response => dispatch(reviewSurveySubmittersFetched(response.data)));

export const saveReviewRpmFeedback = (requestInput, cb) => dispatch =>
  axios
    .post(`/review/saveRpmFeedback`, requestInput)
    .then(
      response =>
        cb(response) || dispatch(saveReviewRpmFeedbackFetched(response.data)),
      cb || console.error
    );

export const reviewRpmFeedbackProgramSpecialist = () => dispatch =>
  axios
    .post(`/review/profileUsersProgramSpecialist`, {})
    .then(response =>
      dispatch(reviewRpmFeedbackProgramSpecialistFetched(response.data))
    );

export const reviewRpmFeedbackFiscalSpecialist = () => dispatch =>
  axios
    .post(`/review/profileUsersFiscalSpecialist`, {})
    .then(response =>
      dispatch(reviewRpmFeedbackFiscalSpecialistFetched(response.data))
    );
export const fetchUnscheduledReviewers = filter => dispatch =>
  axios
    .post(`/review/fetch-unscheduled`, filter)
    .then(response => dispatch(unscheduledReviewersFetched(response.data)));

export const addReviewEvidence = formData => dispatch =>
  axios({
    method: 'post',
    url: '/review/evidence/add',
    data: formData,
  })
    .then(response => {
      dispatch(reviewEvidenceAdded(response.data));
    })
    .catch(err => console.log(err));

export const fetchReviewEvidence = reviewId => dispatch =>
  axios
    .post(`/review/evidence/fetch`, reviewId)
    .then(response => {
      dispatch(reviewEvidenceFetched(response.data));
    })
    .catch(err => dispatch(setError(err)));

// FIXME this endpoint action + endpoint are soppo
export const downloadReviewEvidence = (
  reviewId,
  filename,
  file
) => dispatch => {
  return (
    axios
      .post(`/review/downloadEvidence`, { filename })
      // { responseType: 'arraybuffer' }) // This is required for file download to work
      .then(response => {
        const { data } = response;

        if (!data) return;

        const bData = data.split(';base64,').pop();
        const buf = Buffer.from(bData, 'base64');

        const blob = new Blob([buf], {
          type: 'application/octet-stream',
        });

        downloadBlob(blob, file);
        dispatch(reviewEvidenceDownloaded());
        return response;
      })
  );
};

// export const downloadReviewEvidence = filename => dispatch =>
//   axios
//     .post(
//       `/review/downloadEvidence`,
//       { filename },
//       { responseType: 'arraybuffer' }
//     )
//     .then(response => {
//       const { data } = response;

//       if (!data)
//         return;

//       const contentType = response.headers['content-type'];

//       fileDownload(response.data, `evidence_${reviewId}_evidence_${moment().format('x')}.${fileExtension}`, contentType);
//       dispatch(reviewEvidenceDownloaded())
//       return response;
//     });

// {
//
//   const { data } = response;
//
//   if (!data)
//     return;
//   const contentType = response.headers['content-type'];
//   fileDownload(response, `smth.docx`, contentType);
//   dispatch(reviewEvidenceDownloaded());
//   return response;
//
// })

export const downloadReviewDocument = (
  reviewId,
  filename,
  fileExtension
) => dispatch =>
  axios
    .post(
      `/review/downloadEvidence`,
      { filename },
      { responseType: 'arraybuffer' }
    ) // This is required for file download to work
    .then(response => {
      const { data } = response;

      if (!data) return;

      const contentType = response.headers['content-type'];

      fileDownload(
        response.data,
        `evidence_${reviewId}_evidence_${moment().format(
          'x'
        )}.${fileExtension}`,
        contentType
      );
      dispatch(reviewEvidenceDownloaded());
      return response;
    });

export const finalizeFUR = (requestInput, cb) => dispatch =>
  axios
    .post(`/review/email/finalizeFUR`, requestInput)
    .then(
      response => cb(response) || dispatch(finalizeFURRes(response.data)),
      cb || console.error
    );

export const fetchReviewDocuments = requestInput => dispatch =>
  axios
    .post(`/review/fetchReviewDocuments`, requestInput)
    .then(response => dispatch(reviewDocumentsFetched(response.data)));

export const fetchCreateFollowupReview = requestInput => dispatch =>
  axios
    .post(`/review/createFollowupReview`, requestInput)
    .then(response => dispatch(createFollowupReviewFetched(response.data)));

export const updateFindingStatus = (
  requestInput,
  reviewType,
  reviewId,
  flow
) => dispatch => {
  dispatch(removeErrorByAction('Finding Update'));
  if (
    reviewType.reviewType === 'FA-2' ||
    (reviewType || {}).reviewType === 'Special' ||
    (reviewType || {}).reviewType === 'AIAN-DEF' ||
    (reviewType || {}).reviewType === 'ERSEA' ||
    (reviewType || {}).reviewType === 'FA2-CR'
  ) {
    axios
      .post(`/review/fa-two-findingStatusUpdate`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)))
      .catch(err => {
        err.response.action = 'Finding Update';
        dispatch(setError(err));
      });
  }
  if (reviewType.reviewType === 'FA-1') {
    axios
      .post(`/review/fa-one-findingStatusUpdate`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)))
      .catch(err => {
        err.response.action = 'Finding Update';
        dispatch(setError(err));
      });
  }
  if (reviewType.reviewType === 'RAN') {
    axios
      .post(`/review/ran-findingStatusUpdate`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)))
      .catch(err => {
        err.response.action = 'Finding Update';
        dispatch(setError(err));
      });
  }
  if (reviewType.reviewType === 'Follow-up') {
    axios
      .post(`/review/fu-findingStatusUpdate`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)))
      .catch(err => {
        err.response.action = 'Finding Update';
        dispatch(setError(err));
      });
  }
};

export const updateFUD = (
  requestInput,
  reviewId,
  flow,
  reviewType
) => dispatch => {
  dispatch(removeErrorByAction('Finding Update'));
  if (
    reviewType.reviewType === 'FA-2' ||
    (reviewType || {}).reviewType === 'Special' ||
    (reviewType || {}).reviewType === 'AIAN-DEF' ||
    (reviewType || {}).reviewType === 'ERSEA' ||
    (reviewType || {}).reviewType === 'FA2-CR'
  ) {
    axios
      .post(`/review/fa-two-updateFUD`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)));
  }
  if (reviewType.reviewType === 'FA-1') {
    axios
      .post(`/review/fa-one-updateFUD`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)));
  }
  if (reviewType.reviewType === 'RAN') {
    axios
      .post(`/review/ran-updateFUD`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)));
  }
  if (reviewType.reviewType === 'Follow-up') {
    axios
      .post(`/review/fu-updateFUD`, requestInput)
      .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)));
  }
};

export const fetchFindingsDetail = reviewId => dispatch => {
  return axios
    .post(`/review/fuFindingDetails/${reviewId}`)
    .then(response => dispatch(getFindingDetailsResponse(response.data)))
    .then(() => {
      return {};
    });
};

export const updateCitationTFCDate = body => dispatch =>
  axios
    .post(`/review/updateCitationExtendByValue`, body)
    .then(response => response);

export const updatePlanFollowUpReviewTeam = body => dispatch =>
  axios
    .post(`/review/updatePlanFollowUpReviewTeam`, body)
    .then(response => response);

export const updateCAPDays = (
  requestInput,
  reviewId,
  flow,
  reviewType
) => dispatch => {
  dispatch(removeErrorByAction('Finding Update'));
  return axios
    .post(`/review/updateCAPDays`, requestInput)
    .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)));
};

export const updateDisallowance = (
  requestInput,
  reviewId,
  flow,
  reviewType
) => dispatch => {
  return axios
    .post(`/review/updateDisallowance`, requestInput)
    .then(() => dispatch(fetchFindingDetails(reviewId, flow, reviewType)));
};

export const finalizeFollowupRecommendations = requestInput => dispatch =>
  axios
    .post(`/review/finalizeFollowupRecommendations`, requestInput)
    .then(result => dispatch(returnedResStatus(result)));

export const fetchEmailReturnToFOM = (requestInput, cb) => dispatch =>
  axios
    .post(`/review/email/returnToFOM`, requestInput)
    .then(
      response =>
        cb(response) || dispatch(fetchedEmailNotifyFOMResponse(response.data)),
      cb || console.error
    );

export const submitToFOM = (report, reviewId, cb) => dispatch =>
  axios
    .post(`/report/save`, report)
    .then(response => {
      return (
        cb(response) ||
        (dispatch(getReportSavedResult(response.data)) &&
          dispatch(
            axios
              .post(`/review/email/notifyFOM`, reviewId)
              .then(
                dispatch(fetchReviewDetail(reviewId)),
                dispatch(fetchReportStatusData(reviewId.reviewId)),
                response =>
                  dispatch(fetchedEmailNotifyFOMResponse(response.data))
              )
          ))
      );
    })
    .catch(function(error) {
      console.log(error);
    });

export const confirmGrantee = (payload, reviewId, reviewType) => dispatch => {
  if (
    reviewType.reviewType === 'FA-2' ||
    reviewType.reviewType === 'FA2-CR' ||
    reviewType.reviewType === 'Special' ||
    (reviewType || {}).reviewType === 'ERSEA' ||
    reviewType.reviewType === 'RAN' ||
    reviewType.reviewType === 'Follow-up' ||
    reviewType.reviewType === 'AIAN-DEF' ||
    reviewType.reviewType === 'FA2-CR'
  ) {
    axios.post('/review/fa-two-confirmGrantee', payload).then(() => {
      return dispatch(fetchFindingDetails(reviewId, false, reviewType));
    });
  }
  if (reviewType.reviewType === 'FA-1') {
    axios.post('/review/fa-one-confirmGrantee', payload).then(() => {
      return dispatch(fetchFindingDetails(reviewId, false, reviewType));
    });
  }
};

export const fetchCitations = requestInput => dispatch =>
  axios
    .post(`/review/fetchCitations`, requestInput)
    .then(response => dispatch(saveCitations(response.data)));

export const filterCitations = query => dispatch =>
  axios
    .get(`/review/citations/filter/${query}`)
    .then(response => dispatch(citationsFiltered(response.data)));

export const fetchReviewFollowUps = requestInput => dispatch =>
  axios
    .post(`/review/listFollowUp`, requestInput)
    .then(response => dispatch(reviewFollowUpsFetched(response.data)));

export const fetchEvidenceBinder = filterInput => dispatch =>
  axios
    .post(`/review/fetchEvidenceBinder`, filterInput)
    .then(response => dispatch(evidenceBinderFetched(response.data)));

export const fetchJournalLogs = input => dispatch => {
  axios
    .post(`/review/fetchJournalDetails`, input)
    .then(response => dispatch(journalLogsFetched(response.data)));
};

export const fetchFollowUpFindingDetails = input => dispatch => {
  return axios
    .post(`/review/fetchFollowUpFindingDetails/${input}`)
    .then(response => dispatch(followUpFindingDetailsFetched(response.data)));
};

export const fetchFURTeamInfo = input => dispatch => {
  return axios
    .post(`/review/fetchFURTeamInfo/${input}`)
    .then(response => dispatch(followUpFURTeamInfoFetched(response.data)));
};

export const updateCitationExtendCapDays = body => dispatch =>
  axios
    .post(`/review/updateCitationExtendCapDays`, body)
    .then(response => response);

export const savePlanFollowUpTeamUpdates = body => dispatch =>
  axios
    .post(`/review/savePlanFollowUpTeamUpdates`, body)
    .then(response => response);

export const fetchExplorationSummary = input => dispatch => {
  axios
    .post(`/review/fetchExplorationSummary`, input)
    .then(response => dispatch(explorationSummaryFetched(response.data)));
};

export const updateCitationFindingStatus = (
  requestInput,
  reviewId,
  reviewType
) => dispatch =>
  axios
    .post(`/review/fa-two-findingStatusUpdate`, requestInput)
    .then(response => {
      dispatch(fetchFindingDetails(reviewId, false, reviewType));
      return true;
    })
    .catch(err => {
      err.response.action = 'Finding Update';
      dispatch(setError(err));
    });

export const confirmGrantees = (
  payload,
  reviewId,
  reviewType,
  isNewForms
) => dispatch =>
  axios.post('/review/fa-two-confirmGrantee', payload).then(() => {
    return dispatch(
      fetchFindingDetails(reviewId, false, reviewType, isNewForms)
    );
  });

export const updateCitationDisallowance = (
  requestInput,
  reviewId,
  reviewType
) => dispatch =>
  axios
    .post(`/review/update-disallowance-details`, requestInput)
    .then(response => {
      dispatch(fetchFindingDetails(reviewId, false, reviewType));
      return true;
    })
    .catch(err => {
      err.response.action = 'Finding Update';
      dispatch(setError(err));
    });

export const updateCollectedData = (
  requestInput,
  reviewId,
  reviewType,
  isNewForms
) => dispatch =>
  axios
    .post(`/review/update-collected-data`, requestInput)
    .then(response => {
      dispatch(fetchFindingDetails(reviewId, false, reviewType, isNewForms));
      return true;
    })
    .catch(err => {
      err.response.action = 'Finding Update';
      dispatch(setError(err));
    });

export const updateAnalysisNarrative = (
  requestInput,
  reviewId,
  reviewType,
  isNewForms
) => dispatch =>
  axios
    .post(`/review/update-Analysis-Narrative`, requestInput)
    .then(response => {
      dispatch(fetchFindingDetails(reviewId, false, reviewType, isNewForms));
      return true;
    })
    .catch(err => {
      err.response.action = 'Analysis Narrative update';
      dispatch(setError(err));
    });

export const fetchCapByFindingStatus = requestInput => dispatch =>
  axios
    .post(`/review/fetch-Cap-By-FindingStatus`, requestInput)
    .then(response => response);

export const updateDisallowanceMonitized = (
  requestInput,
  reviewId,
  reviewType
) => dispatch =>
  axios
    .post(`/review/update-Disallowance-Monitized`, requestInput)
    .then(response => response);

export const findingStatusUpdate = (
  requestInput,
  reviewId,
  reviewType,
  isNewForms
) => dispatch =>
  axios
    .post(`/review/status-Update`, requestInput)
    .then(response => {
      dispatch(fetchFindingDetails(reviewId, false, reviewType, isNewForms));
      return true;
    })
    .catch(err => {
      err.response.action = 'Finding Status Update';
      dispatch(setError(err));
    });

export const submitCitationDisallowance = (
  requestInput,
  reviewId,
  reviewType,
  isNewForms
) => dispatch =>
  axios
    .post(`/review/submit-disallowance-details`, requestInput)
    .then(response => {
      dispatch(fetchFindingDetails(reviewId, false, reviewType, isNewForms));
      return true;
    })
    .catch(err => {
      err.response.action = 'Submit Disallowance Details';
      dispatch(setError(err));
    });

export const evidenceFileUpload = (formData, onUploadProgress) => dispatch =>
  axios({
    method: 'post',
    url: '/review/evidenceFileUpload',
    data: formData,
    onUploadProgress,
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(
          new Error(`Received status code ${response.status}`)
        );
      }
      return response.data;
    })
    .catch(err => {
      // You can either log the error here or return a rejected Promise
      // so that it can be caught in subsequent `.catch()` blocks
      console.log(err);
      return Promise.reject(err);
    });

export const downloadEvidenceFileAttachment = (id, name) => dispatch => {
  return axios
    .post(
      `/review/evidenceFileDownload/${id}`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;
      if (!data) return;
      const contentType = response.headers['content-type'];
      fileDownload(response.data, name, contentType);

      return response;
    });
};

export const deleteEvidenceFileAttachment = input => dispatch =>
  axios
    .post(`/review/evidenceFileDelete`, input)
    .then(response => {
      return response.data;
    })
    .catch(err => console.log(err));

export const surveyEvidenceBinder = filterInput => dispatch =>
  axios
    .post(`/review/surveyEvidenceBinder`, filterInput)
    .then(response => dispatch(evidenceBinderFetched(response.data)));

export const managementSystemAnalysisReport = filterInput => dispatch =>
  axios
    .post(`/review/management-system-analysis-report`, filterInput)
    .then(response =>
      dispatch(managementSystemAnalysisReportFetched(response.data))
    );

export const preReviewSummary = filterInput => dispatch =>
  axios
    .post(`/review/pre-review-summary`, filterInput)
    .then(response => dispatch(preReviewSummaryFetched(response.data)));

export const surveySubmitToFOM = (report, reviewId, cb) => dispatch =>
  axios
    .post(`/report/saveReport`, report)
    .then(response => {
      return (
        cb(response) ||
        (dispatch(getReportSavedResult(response.data)) &&
          dispatch(
            axios
              .post(`/review/email/notifyFOM`, reviewId)
              .then(
                dispatch(fetchReviewDetail(reviewId)),
                dispatch(fetchReportStatusData(reviewId.reviewId)),
                response =>
                  dispatch(fetchedEmailNotifyFOMResponse(response.data))
              )
          ))
      );
    })
    .catch(function(error) {
      console.log(error);
    });
