import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Checkbox,
  Divider,
  Form,
  Input,
  Radio,
  Segment,
} from 'semantic-ui-react';

// Import components
import AllUserListPreloadDropdown from '../../../utils/AllUserListPreloadDropdown';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import GranteeListDropdown from '../../../utils/GranteeListDropdown';
import UserListPreloadDropdown from '../../../utils/UserListPreloadDropdown';
import GuidesPerformanceMeasure from './GuidesPerformanceMeasure';
import ReviewersPosition from './ReviewersPosition';

// Import utils
import enforceRole from '../../../utils/EnforceRole';
import { differenceInObjects } from '../../Shared/FormHelpers/helpers/utils.js';

// Import configs
import { acl } from '../../../config';

// Import actions.
import { granteesFiltered } from '../../../actions/granteeActions';
import {
  addPosition,
  addPositionReviewer,
  addReview,
  updateReview,
} from '../../../actions/reviewActions';
import { updateSpecialAssignments } from '../../../actions/specialActions';
import { usersFiltered } from '../../../actions/userActions';

// Options
const reviewStatusOptions = [
  {
    key: 'Team Assigned',
    value: 'Team Assigned',
    text: 'Team Assigned',
  },
  { key: 'In Progress', value: 'In Progress', text: 'In Progress' },
  { key: 'Review On Hold', value: 'Review On Hold', text: 'Review On Hold' },
  { key: 'Report On Hold', value: 'Report On Hold', text: 'Report On Hold' },
  { key: 'Cancelled', value: 'Cancelled', text: 'Cancelled' },
  {
    key: 'Report Not Issued',
    value: 'Report Not Issued',
    text: 'Report Not Issued',
  },
];

const specialReviewLeadOptions = [
  {
    key: 'dlh',
    value: 'dlh',
    text: 'DLH Review Lead',
  },
  {
    key: 'regional',
    value: 'regional',
    text: 'Regional Office Lead',
  },
];

class ReviewsCreateForm extends Component {
  state = {
    alertDialog: {
      show: false,
      type: 'success',
      message: '',
    },
    showUpdateConfirmationDialog: false,
    showWithdrawConfirmationAlert: false,
    showInActiveStatusWarning: false,
    showInvitationFlow: true,
    saving: false,
    errors: {},
    teamPlanningComplete: false,
    loadingEditData: false,
    dateSelected: false,
    granteeRegions: [],
    pendingDate: {},
    data: {
      reviewId: '',
      grantee: [],
      startDate: null,
      endDate: null,
      reviewLeads: [],
      regionalProgramManagers: [],
      logisticsAssociates: [],
      reviewers: [],
      positions: [],
      programSpecialists: [],
      followupLeads: [],
      fieldOperationsManagers: [],
      fiscalSpecialists: [],
      grantSpecialists: [],
      analysts: [],
      dualCoders: [],
      pendingReviewers: [],
      reviewersList: [], // edit only -- from team.
      reviewerOptions: [], // edit only -- reviewer dropdown list.
      reviewStatus: 'Team Assigned',
      specialReviewLead: '',
      invitationReviewers: [], // edit only -- for invitation reviewers
      shadowReviewers: [],
      pendingShadowReviewers: [],
      shadowReviewerOptions: [],
      unannounced: false,
      previousStatus: '',
    },
    pms: {},
    modalOpen: false,
    reviewerPositionSkills: {
      languageSkills: [],
      otherSkills: [],
      contentAreaSkills: [],
    },
    positions: [],
  };

  componentWillReceiveProps = nextProps => {
    const {
      reviewInfo: {
        reviewId,
        startDate,
        endDate,
        grantInfo,
        pendingReviewers,
        logisticsAssociates,
        reviewLeads,
        reviewers,
        positions,
        dualCoders,
        followupLeads,
        fieldOperationsManagers,
        fiscalSpecialists,
        grantSpecialists,
        analysts,
        programSpecialists,
        regionalProgramManagers,
        shadowReviewers,
        pendingShadowReviewers,
        teamPlanningComplete,
        reviewStatus,
        specialReviewLead,
        unannounced,
        previousStatus,
        grantRegions,
      },
    } = nextProps;

    const diff = Object.keys(differenceInObjects(this.props, nextProps));
    if (
      diff &&
      (diff.length === 0 || (diff.length === 1 && diff[0] === 'usersFilter'))
    ) {
      return;
    }

    // Edit mode populate.
    if (this.props.edit && !this.state.loadingEditData) {
      // loadingEditData is added to prevent infinite loop.
      this.setState({ loadingEditData: true }, () => {
        let reviewersList = [];

        // Populate grantee data for edit.
        if (grantInfo && grantInfo.length)
          this.props.granteesFiltered(grantInfo);

        const pendingReviewersList = _.filter(pendingReviewers, {
          invitationStatus: 'Pending',
        });

        reviewersList = [...reviewersList, ...reviewers];

        const shadowReviewerOptions = [
          ...shadowReviewers,
          ...pendingShadowReviewers,
        ];

        // Populate form data.
        this.setState({
          loadingEditData: false,
          teamPlanningComplete,
          granteeRegions: grantRegions,
          data: {
            ...this.state.data,
            reviewId,
            startDate:
              this.state.data.startDate ||
              AmsDateFormatters.getMoment(startDate),
            endDate:
              this.state.data.startDate || this.state.data.endDate
                ? this.state.data.endDate
                : AmsDateFormatters.getMoment(endDate),
            grantee: grantInfo.map(grantee => grantee.granteeId),

            reviewersList,
            // reviewerOptions,
            reviewStatus,
            specialReviewLead,

            logisticsAssociates:
              logisticsAssociates && logisticsAssociates.map(u => u.oid),
            reviewLeads: reviewLeads && reviewLeads.map(u => u.oid),
            reviewers,
            positions,

            dualCoders: dualCoders && dualCoders.map(u => u.oid),
            followupLeads: followupLeads && followupLeads.map(u => u.oid),
            fieldOperationsManagers:
              fieldOperationsManagers &&
              fieldOperationsManagers.map(u => u.oid),
            fiscalSpecialists:
              fiscalSpecialists && fiscalSpecialists.map(u => u.oid),
            grantSpecialists:
              grantSpecialists && grantSpecialists.map(u => u.oid),
            analysts: analysts && analysts.map(u => u.oid),
            programSpecialists:
              programSpecialists && programSpecialists.map(u => u.oid),
            regionalProgramManagers:
              regionalProgramManagers &&
              regionalProgramManagers.map(u => u.oid),

            pendingReviewers: pendingReviewersList.map(
              reviewers => reviewers.oid
            ),

            pendingReviewerOptions: pendingReviewersList.map(reviewers => ({
              key: reviewers.oid,
              text: reviewers.fullName,
              value: reviewers.oid,
            })),

            reviewerOptions: this.invitationReviewerStructure(
              pendingReviewers,
              reviewers
            ),

            shadowReviewerOptions: shadowReviewerOptions,

            shadowReviewers: shadowReviewers && shadowReviewers.map(u => u.oid),

            pendingShadowReviewers: pendingShadowReviewers,

            pendingShadowReviewersList:
              pendingShadowReviewers && pendingShadowReviewers.map(u => u.oid),
            unannounced,
            previousStatus,
          },
        });
      });
    }
  };

  handleUnannouncedToggle = (e, { checked }) => {
    this.setState({ data: { ...this.state.data, unannounced: checked } });
  };

  handleValueChange = (e, { name, value }) => {
    let { errors } = this.state;

    // Clear validation errors if there are any.
    if (value) {
      errors = _.omit(errors, [name]);
      this.props.setError(errors, name);
    }

    if (name === 'reviewers') {
      this.setState({
        data: {
          ...this.state.data,
          reviewersList: this.userStructure(value, 'reviewer'),
        },
        errors,
      });
      return;
    }
    this.setState({ data: { ...this.state.data, [name]: value }, errors });
  };

  handleStartDateChange = date => {
    if (_.isEmpty(this.state.pendingDate) && this.checkReviewers()) {
      this.setState({
        showWithdrawConfirmationAlert: true,
        pendingDate: {
          type: 'startDate',
          date: date,
        },
      });

      return;
    }

    let { errors } = this.state;

    if (!date) {
      this.setState({
        data: {
          ...this.state.data,
          startDate: null,
          endDate: null,
          pendingDate: {},
        },
      });
      return;
    }

    // If the start date is greater than end date, reset the end date to null.
    if (AmsDateFormatters.getMoment(date).isAfter(this.state.data.endDate)) {
      this.setState({
        data: {
          ...this.state.data,
          startDate: date,
          endDate: null,
          pendingDate: {},
        },
      });

      return;
    }

    errors = _.omit(errors, ['startDate']);
    this.props.setError(errors, 'startDate');
    this.setState({
      data: { ...this.state.data, startDate: date },
      errors,
      pendingDate: {},
    });
  };

  handleEndDateChange = date => {
    let { errors } = this.state;

    if (this.checkReviewers()) {
      this.setState({
        showWithdrawConfirmationAlert: true,
        pendingDate: {
          type: 'endDate',
          date: date,
        },
      });

      return;
    }

    if (date) {
      errors = _.omit(errors, ['endDate']);
      this.props.setError(errors, 'endDate');
    }

    this.setState({
      data: { ...this.state.data, endDate: date },
      pendingDate: {},
      errors,
    });
  };

  showWithdrawConfirmationAlert = () => {
    const { showWithdrawConfirmationAlert, pendingDate } = this.state;

    return (
      <AmsAlert
        show={showWithdrawConfirmationAlert}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        title={'Are you sure?'}
        text={
          'Changing this review dates will withdraw reviewers in either assigned, invited or accepted status on this review. Do you want to proceed?'
        }
        onConfirm={() => {
          this.withdrawReviewers();
          if (pendingDate.type === 'startDate') {
            this.handleStartDateChange(pendingDate.date);
          } else {
            this.handleEndDateChange(pendingDate.date);
          }
          this.setState({ showWithdrawConfirmationAlert: false });
        }}
        onCancel={() => {
          this.setState({
            pendingDate: {},
            showWithdrawConfirmationAlert: false,
          });
        }}
      />
    );
  };

  showInActiveStatusWarning = () => {
    const { showInActiveStatusWarning } = this.state;

    return (
      <AmsAlert
        show={showInActiveStatusWarning}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        title={'Are you sure?'}
        text={'Inactive Grantee has been selected. Do you want to proceed?'}
        onConfirm={() => {
          // eslint-disable-next-line array-callback-return
          const grantRegions = this.state.templSelectedData.map(e => {
            const grant =
              this.props.granteeFilteredData &&
              this.props.granteeFilteredData.length &&
              this.props.granteeFilteredData.find(g => g.granteeId === e);
            if (grant) {
              return grant.region;
            }
          });

          this.setState({
            data: {
              ...this.state.data,
              grantee: this.state.templSelectedData,
            },
            granteeRegions: grantRegions,
          });
          this.setState({
            showInActiveStatusWarning: false,
            templSelectedData: '',
          });
        }}
        onCancel={() => {
          this.setState({
            showInActiveStatusWarning: false,
            templSelectedData: '',
          });
        }}
      />
    );
  };

  checkReviewers = () => {
    //temporary disabling this function as per business request. Do not want to withdraw reviewers on date change yet.
    return false;

    /*const { reviewers } = this.props.reviewInfo;
    return !!reviewers.find(
      e => e.status !== 'invitationRescinded' && e.status !== 'withdrawn'
    );*/
  };

  withdrawReviewers = () => {
    let { reviewers } = this.props.reviewInfo;

    const withdrawnReviewers = reviewers.map(reviewer => {
      reviewer.status = 'invitationRescinded';
      return reviewer;
    });

    this.props.addPositionReviewer(withdrawnReviewers);
  };

  toggleConfirmation = () => {
    this.setState({ teamPlanningComplete: !this.state.teamPlanningComplete });
  };

  validate = () => {
    const { data } = this.state;
    const errors = {};

    if (!this.props.reviewType)
      errors.reviewType = 'Review Type must be selected';
    if (!_.isEmpty(data.reviewId) && isNaN(data.reviewId.substring(0, 2)))
      errors.reviewId =
        'The first two characters of the review ID should be numeric';
    if (!data.grantee || !data.grantee.length)
      errors.grantee = 'Grantee is required';
    if (!data.startDate) errors.startDate = 'Start date is required';
    if (!data.endDate) errors.endDate = 'End date is required';

    let team = _.union(
      data.fieldOperationsManagers,
      data.analysts,
      data.reviewLeads,
      data.logisticsAssociates,
      data.reviewers,
      data.dualCoders,
      data.followupLeads,
      data.fieldOperationsManagers,
      data.fiscalSpecialists,
      data.grantSpecialists,
      data.analysts,
      data.programSpecialists,
      data.regionalProgramManagers,
      data.pendingReviewers,
      data.specialReviewLead
    );

    let duplicate = _.intersection(team, data.pendingShadowReviewersList);
    if (duplicate && duplicate.length > 0) {
      errors.pendingShadowReviewers =
        'Shadow reviewers cannot be included in other roles';
    }
    duplicate = _.intersection(team, data.shadowReviewers);
    if (duplicate && duplicate.length > 0) {
      errors.shadowReviewers =
        'Shadow reviewers cannot be included in other roles';
    }

    return errors;
  };

  resetDialogMessage = () => {
    this.setState({ alertDialog: { show: false, message: '', type: '' } });
  };

  showMessageDialog = () => {
    const { show, type, message } = this.state.alertDialog;

    return (
      <AmsAlert
        show={show}
        type={type || 'success'}
        showConfirm
        title={type === 'success' ? 'Review created' : 'Oops...'}
        text={message}
        onConfirm={() => {
          if (type === 'success') {
            this.setState({
              alertDialog: { show: false, message: '', type: '' },
            });
            this.context.router.push(`/review`);
            return;
          }
          this.setState({
            alertDialog: { show: false, message: '', type: '' },
          });
        }}
      />
    );
  };

  showUpdateConfirmationDialog = () => {
    const { showUpdateConfirmationDialog } = this.state;
    return (
      <AmsAlert
        show={showUpdateConfirmationDialog}
        title="Update Review"
        text="You are about to update this review. Are you sure?"
        type="info"
        showCancelButton
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, update it!'}
        cancelButtonText={'No, cancel'}
        onConfirm={() => {
          this.setState({ showUpdateConfirmationDialog: false });
          // Execute update process.
          this.saveChanges();
        }}
        onCancel={() => {
          this.setState({ showUpdateConfirmationDialog: false });
        }}
        onEscapeKey={() =>
          this.setState({ showUpdateConfirmationDialog: false })
        }
        onOutsideClick={() =>
          this.setState({ showUpdateConfirmationDialog: false })
        }
      />
    );
  };

  showResendConfirmationDialog = () => {
    const { showResendConfirmation } = this.state;

    return (
      <AmsAlert
        show={showResendConfirmation}
        type={'success'}
        showConfirm
        title={'Invitation Resent'}
        text={'Invitation has been resent to the reviewer'}
        onConfirm={() => {
          this.setState({
            showResendConfirmation: false,
          });
        }}
      />
    );
  };

  saveChanges = () => {
    const {
      pms,
      data: {
        reviewId,
        grantee,
        startDate,
        endDate,

        logisticsAssociates,
        reviewLeads,
        dualCoders,
        followupLeads,
        fieldOperationsManagers,
        fiscalSpecialists,
        grantSpecialists,
        analysts,
        programSpecialists,
        regionalProgramManagers,
        shadowReviewers,
        pendingShadowReviewersList,
        reviewStatus,
        specialReviewLead,
        unannounced,
        technicalWriters,
        editors,
      },
      teamPlanningComplete,
    } = this.state;

    // Assemble data to send to API.
    const data = {
      reviewId,
      granteeIds: grantee,
      startDate: startDate
        ? AmsDateFormatters.getMoment(startDate)
            .startOf('day')
            .format()
        : null,
      endDate: endDate
        ? AmsDateFormatters.getMoment(endDate)
            .endOf('day')
            .format()
        : null,
      reviewType: this.props.reviewType,
      fiscalYear: this.props.fiscalYear,

      logisticsAssociates: this.userStructure(
        logisticsAssociates,
        'logisticsAssociate'
      ),
      reviewLeads: this.userStructure(reviewLeads, 'reviewLead'),
      dualCoders: this.userStructure(dualCoders, 'dualCoder'),
      followupLeads: this.userStructure(followupLeads, 'followupLead'),
      fieldOperationsManagers: this.userStructure(
        fieldOperationsManagers,
        'fieldOperationsManager'
      ),
      fiscalSpecialists: this.userStructure(
        fiscalSpecialists,
        'fiscalSpecialist'
      ),
      grantSpecialists: this.userStructure(grantSpecialists, 'grantSpecialist'),
      analysts: this.userStructure(analysts, 'analyst'),
      technicalWriters: this.userStructure(technicalWriters, 'technicalWriter'),
      editors: this.userStructure(editors, 'editor'),
      programSpecialists: this.userStructure(
        programSpecialists,
        'programSpecialist'
      ),
      regionalProgramManagers: this.userStructure(
        regionalProgramManagers,
        'regionalProgramManager'
      ),
      shadowReviewers: this.shadowReviewerStructure(shadowReviewers),
      pendingShadowReviewers: this.userStructure(
        pendingShadowReviewersList,
        'shadowReviewer'
      ),

      // reviewers: reviewersList,
      reviewers: this.props.reviewInfo.reviewers,
      positions: this.props.reviewInfo.positions,

      // pendingReviewers,
      teamPlanningComplete,
      reviewStatus,
      specialReviewLead,
      unannounced,
      datesChanged: false,
    };

    this.setState({ saving: true });

    // Edit Mode.
    if (this.props.edit) {
      //check if dates have changed
      if (
        AmsDateFormatters.getMoment(startDate)
          .startOf('day')
          .format() !==
          AmsDateFormatters.getMoment(this.props.reviewInfo.startDate)
            .startOf('day')
            .format() ||
        AmsDateFormatters.getMoment(endDate)
          .startOf('day')
          .format() !==
          AmsDateFormatters.getMoment(this.props.reviewInfo.endDate)
            .startOf('day')
            .format()
      ) {
        data.datesChanged = true;
      }
      this.props
        .updateSpecialAssignments(pms)
        .then(() => {
          const canUpdateReview = _.intersection(
            acl.actions.review.update,
            this.props.currentUser.roles
          );

          if (!_.isEmpty(canUpdateReview)) {
            this.props
              .updateReview(data)
              .then(response => {
                const { reviewId } = response;
                this.setState({
                  saving: false,
                  showResendConfirmation:
                    this.state.action === 'resendInvite' ? true : false,
                  action: '',
                });
                if (reviewId) {
                  this.props.setMessage(
                    `Review with ID: ${reviewId} updated successfully.`
                  );
                }
              })
              .catch(error => {
                this.setState({ saving: false });
                this.props.setError({
                  responseError: error.response.data.message,
                });
              });
          } else {
            this.setState({ saving: false });
            if (reviewId) {
              this.props.setMessage(
                `Review with ID: ${reviewId} updated successfully.`
              );
            }
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.setError({ responseError: error.response.data.message });
        });
    } else {
      this.props
        .addReview(data)
        .then(response => {
          const { reviewId } = response;
          this.setState({ saving: false });
          if (reviewId) {
            this.setState({
              alertDialog: {
                show: true,
                message: `Review with ID: ${reviewId} created successfully`,
                type: 'success',
              },
            });
          }
        })
        .catch(error => {
          this.setState({
            saving: false,
            alertDialog: {
              show: true,
              message: error.response.data.message,
              type: 'error',
            },
          });
        });
    }
  };

  showFutureDatesWarning = () => {
    const { showFutureDatesWarning } = this.state;
    return (
      <AmsAlert
        show={showFutureDatesWarning}
        title="Are you sure?"
        text=" The review status for the selected start and end review dates do not align. Are you sure you want to continue with this submission?"
        type="warning"
        showCancelButton
        showConfirm
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, proceed.'}
        cancelButtonText={'No, cancel'}
        onConfirm={() => {
          if (this.props.edit) {
            this.setState({
              showFutureDatesWarning: false,
              showUpdateConfirmationDialog: true,
            });
          } else {
            this.setState({ showFutureDatesWarning: false });
            this.saveChanges();
          }
        }}
        onCancel={() => {
          this.setState({ showFutureDatesWarning: false });
        }}
      />
    );
  };

  checkDates = () => {
    const { data } = this.state;
    if (
      AmsDateFormatters.getMoment(data.startDate).isAfter(
        AmsDateFormatters.getMoment(),
        'day'
      ) &&
      (data.reviewStatus === 'In Progress' ||
        data.reviewStatus === 'In Analysis')
    )
      return false;
    return true;
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    this.props.setError(errors);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      // Show Confirmation Dialog during edit.

      if (!this.checkDates()) {
        this.setState({ showFutureDatesWarning: true });
      } else if (this.props.edit) {
        this.setState({ showUpdateConfirmationDialog: true });
      } else {
        this.saveChanges();
      }
    }
  };

  toggleInvitationFlow = () =>
    this.setState({
      showInvitationFlow: !this.state.showInvitationFlow,
    });

  shadowReviewerStructure = oids => {
    const { usersFilter } = this.props;
    const { shadowReviewerOptions } = this.state.data;

    const team =
      oids &&
      oids.map(oid => {
        let t = {};

        if (shadowReviewerOptions) t = _.find(shadowReviewerOptions, { oid });

        if (!t) t = _.find(usersFilter['shadowReviewer'], { oid });

        let name = '';
        if (t.name) name = t.name;
        if (t.fullName) name = t.fullName;

        return {
          oid: t.oid,
          firstName: t.firstName,
          lastName: t.lastName,
          name,
        };
      });

    return team;
  };

  userStructure = (oids, userType) => {
    const { usersFilter, reviewInfo } = this.props;
    const {
      data: { reviewersList, pendingShadowReviewers },
    } = this.state;
    const team =
      oids &&
      oids.map(oid => {
        let t = {};
        if (userType === 'reviewer') {
          t = _.find(reviewersList, { oid });
          if (t) return t;
          else t = _.find(usersFilter[userType], { oid });
        } else if (usersFilter && usersFilter[userType])
          t = _.find(usersFilter[userType], { oid });

        if (_.isEmpty(t) && this.props.edit)
          t = _.find(reviewInfo[`${userType}s`], { oid });

        if (_.isEmpty(t)) t = _.find(pendingShadowReviewers, { oid });

        if (_.isEmpty(t) && userType === 'fieldOperationsManager') {
          t = _.find(usersFilter['regionalProgramManager'], { oid });
        }

        // TODO: Fix this issue of fullName and name difference in the payload

        let name = '';
        if (t.name) name = t.name;
        if (t.fullName) name = t.fullName;

        return {
          oid: t.oid,
          firstName: t.firstName,
          lastName: t.lastName,
          name,
        };
      });

    return team;
  };

  invitationReviewerStructure = (pendingReviewers, reviewers) => {
    const acceptedReviewersList = _.filter(pendingReviewers, {
      invitationStatus: 'Accepted',
    }).map(acceptedReviewer => ({
      oid: acceptedReviewer.oid,
      firstName: acceptedReviewer.firstName,
      lastName: acceptedReviewer.lastName,
      name: acceptedReviewer.fullName,
    }));

    return [...reviewers, ...acceptedReviewersList].map(reviewer => ({
      key: reviewer.oid,
      text: reviewer.name,
      value: reviewer.oid,
    }));
  };

  handlePMCheckboxValueChange = pms => {
    this.setState({ pms });
  };

  handleGranteeChange = value => {
    const { granteeFilteredData } = this.props;
    if (value && value.length > 0) {
      const recentGranteee = _.last(value);
      const existingFilterData = this.state.data.grantee.filter(
        // eslint-disable-next-line eqeqeq
        existingGrantee => existingGrantee == recentGranteee
      );
      if (existingFilterData && existingFilterData.length > 0) {
        this.setState({
          data: { ...this.state.data, grantee: value },
        });
      } else {
        let { granteeFilteredData } = this.props;
        let selectedGrantee = granteeFilteredData.filter(
          // eslint-disable-next-line eqeqeq
          newGrantee => newGrantee.granteeId == recentGranteee
        );
        if (
          selectedGrantee &&
          selectedGrantee.length > 0 &&
          selectedGrantee[0].active
        ) {
          this.setState({
            data: { ...this.state.data, grantee: value },
          });
        } else {
          this.setState({
            showInActiveStatusWarning: true,
            templSelectedData: value,
          });
          return;
        }
      }
    } else {
      this.setState({
        data: { ...this.state.data, grantee: value },
      });
    }
    // eslint-disable-next-line array-callback-return
    const grantRegions = value.map(e => {
      const grant =
        granteeFilteredData &&
        granteeFilteredData.length &&
        granteeFilteredData.find(g => g.granteeId === e);
      if (grant) {
        return grant.region;
      }
    });
    this.setState({
      granteeRegions: grantRegions,
    });
  };

  renderForm = () => {
    const { currentUser, edit, reviewType } = this.props;
    const {
      data: { specialReviewLead },
      showInvitationFlow,
      errors,
    } = this.state;

    const showBlock =
      reviewType === 'Special' ||
      reviewType === 'AIAN-DEF' ||
      reviewType === 'ERSEA'
        ? specialReviewLead === ''
          ? false
          : true
        : true;

    const hasMultiple =
      reviewType === 'Special' ||
      reviewType === 'AIAN-DEF' ||
      reviewType === 'RAN' ||
      reviewType === 'Follow-up' ||
      reviewType === 'ERSEA';

    return (
      <Form loading={this.state.saving} noValidate>
        <Form.Group>
          {(reviewType === 'Special' ||
            reviewType === 'AIAN-DEF' ||
            reviewType === 'ERSEA') && (
            <Form.Dropdown
              label="Who would lead the review?"
              name="specialReviewLead"
              title="Select Special Lead"
              error={!!errors.specialReviewLead}
              value={this.state.data.specialReviewLead}
              options={specialReviewLeadOptions}
              onChange={this.handleValueChange}
              disabled={edit}
              selection
            />
          )}
        </Form.Group>

        {showBlock && (
          <div>
            {enforceRole(
              <div>
                <Form.Group
                  widths={
                    reviewType === 'Other' || reviewType === 'RAN' ? 2 : 'equal'
                  }
                >
                  {reviewType !== 'Other' && reviewType !== 'RAN' && (
                    <Form.Field
                      control={Input}
                      label={{
                        children:
                          reviewType === 'Follow-up'
                            ? 'Preliminary Review ID'
                            : reviewType === 'AIAN-CLASS'
                            ? 'Primary Review ID'
                            : 'Review ID',
                        htmlFor: 'reviewIdInput',
                      }}
                      error={!!errors.reviewId}
                      disabled={edit}
                      id="reviewIdInput"
                      name="reviewId"
                      onChange={this.handleValueChange}
                      value={this.state.data.reviewId}
                      placeholder={
                        reviewType === 'Follow-up'
                          ? 'Type the Preliminary Review ID'
                          : reviewType === 'AIAN-CLASS'
                          ? 'Type the Primary Review ID'
                          : 'Type the Review ID'
                      }
                    />
                  )}

                  <Form.Field
                    required
                    error={!!errors.grantee}
                    disabled={edit}
                    control={GranteeListDropdown}
                    dropdownLabel="Grantee"
                    placeholder="Type Grantee Name or ID to filter"
                    value={this.state.data.grantee}
                    selection
                    multiple
                    search
                    onValueSelected={this.handleGranteeChange}
                    calledFrom={'createReview'}
                    onError={this.props.setError}
                    clearable
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  {reviewType === 'RAN' && (
                    <Form.Field error={!!errors.fiscalSpecialists}>
                      <AmsFormLabel
                        name="Fiscal Specialist"
                        fieldLabel="selectFiscalSpecialist"
                      >
                        <UserListPreloadDropdown
                          name="fiscalSpecialists"
                          userType="fiscalSpecialist"
                          value={this.state.data.fiscalSpecialists}
                          onChange={this.handleValueChange}
                          aria-labelledby="selectFiscalSpecialist"
                          searchInput={{
                            id: 'selectFiscalSpecialist',
                          }}
                          placeholder="Select Fiscal Specialist"
                          multiple
                          selection
                          search
                        />
                      </AmsFormLabel>
                    </Form.Field>
                  )}
                </Form.Group>

                <Form.Group widths="equal">
                  {(reviewType === 'RAN' ||
                    reviewType === 'CLASS' ||
                    reviewType === 'FA-1' ||
                    reviewType === 'FA1-FR' ||
                    reviewType === 'FA-2' ||
                    reviewType === 'FA2-CR' ||
                    reviewType === 'Follow-up' ||
                    reviewType === 'AIAN-CLASS' ||
                    reviewType === 'Special' ||
                    reviewType === 'AIAN-DEF' ||
                    reviewType === 'ERSEA' ||
                    reviewType === 'PR-CLASS' ||
                    reviewType === 'VP-CLASS' ||
                    reviewType === 'CLASS-Video' ||
                    reviewType === 'AIAN CLASS Self-Observations') && (
                    <Form.Field error={!!errors.fieldOperationsManagers}>
                      <AmsFormLabel name="Manager" fieldLabel="selectManager">
                        <UserListPreloadDropdown
                          name="fieldOperationsManagers"
                          multipleUserTypes={
                            hasMultiple
                              ? [
                                  'fieldOperationsManager',
                                  'regionalProgramManager',
                                ]
                              : false
                          }
                          aria-labelledby="selectManager"
                          searchInput={{
                            id: 'selectManager',
                          }}
                          userType="fieldOperationsManager"
                          value={this.state.data.fieldOperationsManagers}
                          onChange={this.handleValueChange}
                          placeholder="Select Manager"
                          multiple
                          selection
                          search
                        />
                      </AmsFormLabel>
                    </Form.Field>
                  )}

                  {reviewType !== 'Other' && (
                    <Form.Field error={!!errors.analysts}>
                      <AmsFormLabel name="Analyst" fieldLabel="selectAnalyst">
                        <UserListPreloadDropdown
                          searchInput={{ id: 'selectAnalyst' }}
                          aria-labelledby="selectAnalyst"
                          name="analysts"
                          userType="analyst"
                          value={this.state.data.analysts}
                          onChange={this.handleValueChange}
                          placeholder="Select Analyst"
                          multiple
                          selection
                          search
                        />
                      </AmsFormLabel>
                    </Form.Field>
                  )}
                </Form.Group>

                <Form.Group
                  widths={
                    reviewType === 'Follow-up' || reviewType === 'RAN'
                      ? 2
                      : 'equal'
                  }
                >
                  {reviewType !== 'Follow-up' &&
                    reviewType !== 'RAN' &&
                    reviewType !== 'Special' &&
                    reviewType !== 'AIAN-DEF' &&
                    reviewType !== 'ERSEA' && (
                      <Form.Field error={!!errors.reviewLeads}>
                        <AmsFormLabel
                          name="Review Team Lead"
                          fieldLabel="selectReviewTeamLead"
                        >
                          <UserListPreloadDropdown
                            name="reviewLeads"
                            userType="reviewLead"
                            searchInput={{ id: 'selectReviewTeamLead' }}
                            aria-labelledby="selectReviewTeamLead"
                            value={this.state.data.reviewLeads}
                            onChange={this.handleValueChange}
                            label="Review Team Lead"
                            placeholder="Select Review Team
                        Leads"
                            multiple
                            selection
                            search
                          />
                        </AmsFormLabel>
                      </Form.Field>
                    )}

                  {reviewType !== 'RAN' && (
                    <Form.Field error={!!errors.logisticsAssociates}>
                      <AmsFormLabel
                        name="Logistics Associate"
                        fieldLabel="selectLogisticsAssociate"
                      >
                        <UserListPreloadDropdown
                          searchInput={{
                            id: 'selectLogisticsAssociate',
                          }}
                          aria-labelledby="selectLogisticsAssociate"
                          name="logisticsAssociates"
                          userType="logisticsAssociate"
                          value={this.state.data.logisticsAssociates}
                          onChange={this.handleValueChange}
                          placeholder="Select Logistics Associate"
                          multiple
                          selection
                          search
                        />
                      </AmsFormLabel>
                    </Form.Field>
                  )}
                </Form.Group>

                {(reviewType === 'CLASS' ||
                  reviewType === 'AIAN-CLASS' ||
                  reviewType === 'PR-CLASS' ||
                  reviewType === 'VP-CLASS' ||
                  reviewType === 'CLASS-Video' ||
                  reviewType === 'AIAN CLASS Self-Observations') && (
                  <Form.Group widths={2}>
                    <Form.Field error={!!errors.dualCoders}>
                      <AmsFormLabel
                        name="Dual Coder"
                        fieldLabel="selectDualCoder"
                      >
                        <UserListPreloadDropdown
                          searchInput={{ id: 'selectDualCoder' }}
                          aria-labelledby="selectDualCoder"
                          name="dualCoders"
                          userType="dualCoder"
                          value={this.state.data.dualCoders}
                          onChange={this.handleValueChange}
                          placeholder="Select Dual Coder"
                          multiple
                          selection
                          search
                        />
                      </AmsFormLabel>
                    </Form.Field>
                  </Form.Group>
                )}

                {(reviewType === 'Follow-up' ||
                  reviewType === 'RAN' ||
                  reviewType === 'Special' ||
                  reviewType === 'AIAN-DEF' ||
                  reviewType === 'ERSEA') && (
                  <Form.Group
                    widths={
                      reviewType === 'Follow-up' ||
                      reviewType === 'RAN' ||
                      reviewType === 'Special' ||
                      reviewType === 'AIAN-DEF' ||
                      reviewType === 'ERSEA'
                        ? 2
                        : 'equal'
                    }
                  >
                    {(reviewType === 'RAN' ||
                      reviewType === 'Follow-up' ||
                      ((reviewType === 'Special' ||
                        reviewType === 'AIAN-DEF' ||
                        reviewType === 'ERSEA') &&
                        specialReviewLead === 'regional')) && (
                      <Form.Field error={!!errors.programSpecialists}>
                        <AmsFormLabel
                          name="Program Specialist"
                          fieldLabel="selectProgramSpecialist"
                        >
                          <UserListPreloadDropdown
                            aria-labelledby="selectProgramSpecialist"
                            searchInput={{ id: 'selectProgramSpecialist' }}
                            name="programSpecialists"
                            userType="programSpecialist"
                            value={this.state.data.programSpecialists}
                            onChange={this.handleValueChange}
                            placeholder="Select Program Specialist"
                            multiple
                            selection
                            search
                          />
                        </AmsFormLabel>
                      </Form.Field>
                    )}

                    {(reviewType === 'Special' ||
                      reviewType === 'AIAN-DEF' ||
                      reviewType === 'ERSEA') &&
                      specialReviewLead === 'regional' && (
                        <Form.Field error={!!errors.grantSpecialists}>
                          <AmsFormLabel
                            name="Grant Specialist"
                            fieldLabel="selectGrantSpecialist"
                          >
                            <UserListPreloadDropdown
                              aria-labelledby="selectGrantSpecialist"
                              searchInput={{ id: 'selectGrantSpecialist' }}
                              name="grantSpecialists"
                              userType="grantSpecialist"
                              value={this.state.data.grantSpecialists}
                              onChange={this.handleValueChange}
                              placeholder="Select Grant Specialist"
                              multiple
                              selection
                              search
                            />
                          </AmsFormLabel>
                        </Form.Field>
                      )}

                    {(reviewType === 'RAN' || reviewType === 'Follow-up') && (
                      <Form.Field error={!!errors.followupLeads}>
                        <AmsFormLabel
                          name="Follow-up Lead"
                          fieldLabel="selectFollowupLead"
                        >
                          <UserListPreloadDropdown
                            aria-labelledby="selectFollowupLead"
                            searchInput={{ id: 'selectFollowupLead' }}
                            name="followupLeads"
                            userType="followupLead"
                            value={this.state.data.followupLeads}
                            onChange={this.handleValueChange}
                            placeholder="Select Follow-up Lead"
                            multiple
                            selection
                            search
                          />
                        </AmsFormLabel>
                      </Form.Field>
                    )}

                    {(reviewType === 'Special' ||
                      reviewType === 'AIAN-DEF' ||
                      reviewType === 'ERSEA') &&
                      specialReviewLead === 'dlh' && (
                        <Form.Field error={!!errors.reviewLeads}>
                          <AmsFormLabel
                            name="Review Lead"
                            fieldLabel="selectReviewLead"
                          >
                            <UserListPreloadDropdown
                              name="reviewLeads"
                              aria-labelledby="selectReviewLead"
                              searchInput={{ id: 'selectReviewLead' }}
                              userType="reviewLead"
                              value={this.state.data.reviewLeads}
                              onChange={this.handleValueChange}
                              placeholder="Select Review Lead"
                              multiple
                              selection
                              search
                            />
                          </AmsFormLabel>
                        </Form.Field>
                      )}
                  </Form.Group>
                )}

                <Form.Group widths="equal">
                  <Form.Field
                    id="selectStartDate"
                    required
                    width={2}
                    error={!!errors.startDate}
                    name="startDate"
                    label={{
                      children: 'Start Date',
                      htmlFor: 'selectStartDate',
                    }}
                    control={DatePicker}
                    placeholder="Please select start Date"
                    // isClearable
                    selectsStart
                    minDate={AmsDateFormatters.getMoment()}
                    selected={this.state.data.startDate}
                    startDate={this.state.data.startDate}
                    onChange={this.handleStartDateChange}
                  />

                  <Form.Field
                    required
                    width={2}
                    error={!!errors.endDate}
                    name="endDate"
                    label={{ children: 'End Date', htmlFor: 'selectEndDate' }}
                    id="selectEndDate"
                    control={DatePicker}
                    placeholder="Please select end date"
                    // isClearable={true}
                    selected={this.state.data.endDate}
                    selectsEnd
                    startDate={this.state.data.startDate}
                    minDate={this.state.data.startDate}
                    endDate={this.state.data.endDate}
                    onChange={this.handleEndDateChange}
                  />
                </Form.Group>

                <Form.Group widths={2}>
                  <Form.Field error={!!errors.pendingShadowReviewers}>
                    <AmsFormLabel
                      name="Invite Shadow Reviewers"
                      fieldLabel="inviteShadowReviewers"
                    >
                      <AllUserListPreloadDropdown
                        name="pendingShadowReviewersList"
                        userType="shadowReviewer"
                        startDate={this.state.data.startDate || {}}
                        endDate={this.state.data.endDate || {}}
                        onChange={this.handleValueChange}
                        searchInput={{ id: 'inviteShadowReviewers' }}
                        aria-labelledby="inviteShadowReviewers"
                        value={this.state.data.pendingShadowReviewersList || []}
                        additionalOptions={
                          this.state.data.pendingShadowReviewers
                        }
                        placeholder="Select Shadow Reviewer"
                        disabled={
                          !(
                            this.state.data.startDate && this.state.data.endDate
                          )
                        }
                        multiple
                        selection
                        search
                      />
                    </AmsFormLabel>
                  </Form.Field>

                  <Form.Field error={!!errors.shadowReviewers}>
                    <AmsFormLabel
                      name="Shadow Reviewers"
                      fieldLabel="assignShadowReviewers"
                    >
                      <AllUserListPreloadDropdown
                        label="Shadow Reviewers"
                        hasSource
                        userType="shadowReviewer"
                        name="shadowReviewers"
                        aria-labelledby="assignShadowReviewers"
                        searchInput={{ id: 'assignShadowReviewers' }}
                        disabled={
                          !(
                            this.state.data.startDate && this.state.data.endDate
                          )
                        }
                        value={this.state.data.shadowReviewers}
                        additionalOptions={
                          this.state.data.shadowReviewerOptions
                        }
                        onChange={this.handleValueChange}
                        multiple
                        selection
                        search
                      />
                    </AmsFormLabel>
                  </Form.Field>
                </Form.Group>

                {reviewType !== 'Other' && reviewType !== 'RAN' && (
                  <div>
                    {showInvitationFlow ? (
                      <div>
                        <ReviewersPosition
                          startDate={this.state.data.startDate}
                          endDate={this.state.data.endDate}
                          reviewType={reviewType}
                          grantee={this.state.data.grantee}
                          granteeRegions={_.uniq(
                            this.state.granteeRegions
                          ).map(e => String(e))}
                          edit={edit}
                          history={
                            this.props.reviewInfo
                              ? this.props.reviewInfo.reviewersHistory
                              : []
                          }
                          //TODO: get selected Reviewers and send them upstream to update or create
                        />

                        <br />
                        <Form.Group
                          widths={
                            this.props.edit
                              ? 2
                              : reviewType !== 'FA-1' ||
                                reviewType !== 'Other' ||
                                reviewType !== 'RAN'
                              ? 2
                              : 'equal'
                          }
                        >
                          {reviewType !== 'Other' && reviewType !== 'RAN' && (
                            <></>
                            // <ReviewersPosition
                            //   startDate={this.state.data.startDate}
                            //   endDate={this.state.data.endDate}
                            //   reviewType={reviewType}
                            //   grantee={this.state.data.grantee}
                            //   edit={edit}
                            //   //TODO: get selected Reviewers and send them upstream to update or create
                            // />

                            // <ReviewerSearch
                            //   error={!!errors.pendingReviewers}
                            //   control={ReviewerFilter}

                            //   startDate={this.state.data.startDate}
                            //   endDate={this.state.data.endDate}

                            //   disabled={
                            //     !(
                            //       this.state.data.startDate &&
                            //       this.state.data.endDate
                            //     )
                            //   }
                            //   reviewersList={this.state.data.reviewersList}
                            //   selectedReviewers={(
                            //     selectedReviewers,
                            //     action
                            //   ) => {
                            //     this.setState({
                            //       data: {
                            //         ...this.state.data,
                            //         reviewersList: selectedReviewers,
                            //       },
                            //       action: action ? action : '',
                            //     });
                            //     if (action === 'resendInvite') {
                            //       this.saveChanges();
                            //     }
                            //   }}
                            // />
                          )}
                        </Form.Group>
                        {/* <div>
                          <span
                            onClick={this.toggleInvitationFlow}
                            style={{ cursor: 'pointer' }}
                          >
                            Skip invitation. <a>Add reviewers manually.</a>
                          </span>
                        </div> */}
                      </div>
                    ) : (
                      <Segment inverted color="blue">
                        <Form.Group
                          widths={
                            this.props.edit
                              ? 2
                              : reviewType !== 'Other' || reviewType !== 'RAN'
                              ? 2
                              : 'equal'
                          }
                        >
                          <Form.Field
                            error={!!errors.reviewers}
                            name="reviewers"
                            control={UserListPreloadDropdown}
                            userType="reviewer"
                            value={
                              this.state.data.reviewersList &&
                              this.state.data.reviewersList.map(e => e.oid)
                            }
                            onChange={this.handleValueChange}
                            label="Reviewer"
                            placeholder="Type reviewers name to filter"
                            multiple
                            selection
                            search
                          />
                        </Form.Group>
                        <div>
                          <span
                            onClick={this.toggleInvitationFlow}
                            style={{ cursor: 'pointer' }}
                          >
                            Cancel adding manually. Invite reviewers.
                          </span>
                        </div>
                      </Segment>
                    )}
                  </div>
                )}

                <br />
                <Form.Field
                  label={{
                    children: 'Team planning is complete',
                    htmlFor: 'teamPlanning',
                  }}
                  role="group"
                  id={'teamPlanning'}
                  aria-labelledby={'teamPlanning'}
                  checked={this.state.teamPlanningComplete}
                  onChange={this.toggleConfirmation}
                  control={Checkbox}
                ></Form.Field>

                {this.state.data.reviewStatus &&
                this.state.data.reviewStatus !== 'Review On Hold' ? (
                  <Form.Group>
                    <Form.Dropdown
                      label="Review Status"
                      title="Select Review Status"
                      name="reviewStatus"
                      error={!!errors.reviewStatus}
                      value={this.state.data.reviewStatus}
                      options={reviewStatusOptions}
                      onChange={this.handleStatusChange}
                      selection
                      upward
                    />
                  </Form.Group>
                ) : (
                  this.renderOnHoldStatus()
                )}

                <Form.Group>
                  <Form.Field
                    label={{
                      children: 'Unannounced',
                      htmlFor: 'unannounced',
                    }}
                    role="group"
                    toggle
                    control={Radio}
                    id={'unannounced'}
                    aria-labelledby={'unannounced'}
                    error={!!errors.unannounced}
                    checked={this.state.data.unannounced}
                    onClick={(e, data) => {
                      this.handleUnannouncedToggle(e, data);
                    }}
                  ></Form.Field>
                </Form.Group>

                <br />
              </div>,
              acl.actions.review.update, // Allowed roles
              currentUser.roles // Current user roles
            )}

            {(reviewType === 'Special' ||
              reviewType === 'AIAN-DEF' ||
              reviewType === 'ERSEA') &&
              edit && (
                <div>
                  <Divider />

                  <GuidesPerformanceMeasure
                    reviewId={this.state.data.reviewId}
                    handlePMCheckboxValueChange={
                      this.handlePMCheckboxValueChange
                    }
                  />

                  <br />
                </div>
              )}

            <br />

            <Form.Group>
              <Form.Button
                form="createReviewForm"
                primary
                content={edit ? 'Update Review' : 'Create Review'}
                onClick={this.onSubmit}
              />
              <Form.Button
                form="createReviewForm"
                content="Cancel"
                onClick={e => {
                  e.preventDefault();
                  this.context.router.goBack();
                }}
              />
            </Form.Group>
          </div>
        )}
      </Form>
    );
  };

  handleStatusChange = (e, data) => {
    const { value } = data;
    if (value === 'Review On Hold') {
      this.setState({ showOnHoldWarning: true, onHold: true });
    } else {
      this.handleValueChange(e, data);
    }
  };

  renderOnHoldWarning = () => {
    const status = this.state.data.reviewStatus;
    const { onHold } = this.state;
    let text;
    if (onHold === true) {
      text =
        'You are about to set this review on hold. Would you like to proceed?';
    } else {
      text =
        'You are about to remove the hold on this review. Would you like to proceed?';
    }
    return (
      <AmsAlert
        show={this.state.showOnHoldWarning}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        title={'Are you sure?'}
        text={text}
        onConfirm={() => {
          if (onHold === true) {
            this.setState({
              data: {
                ...this.state.data,
                reviewStatus: 'Review On Hold',
                previousStatus: status,
              },
              onHold: null,
              showOnHoldWarning: false,
            });
          } else {
            this.setState({
              data: {
                ...this.state.data,
                reviewStatus: this.state.data.previousStatus,
              },
              onHold: null,
              showOnHoldWarning: false,
            });
          }
        }}
        onCancel={() => {
          this.setState({ showOnHoldWarning: false, onHold: null });
        }}
      />
    );
  };

  renderOnHoldStatus = () => {
    return (
      <>
        <Form.Group>
          <label>Review Status</label>
        </Form.Group>
        <Form.Group>
          <label style={{ marginRight: '20px' }}>On Hold </label>
          <Link
            to="#"
            onClick={e => {
              e.preventDefault();
              this.setState({ showOnHoldWarning: true, onHold: false });
            }}
          >
            (Remove the hold)
          </Link>
        </Form.Group>
      </>
    );
  };

  render() {
    return (
      <div>
        {this.showMessageDialog()}
        {this.showUpdateConfirmationDialog()}
        {this.showResendConfirmationDialog()}
        {this.showWithdrawConfirmationAlert()}
        {this.showInActiveStatusWarning()}
        {this.renderOnHoldWarning()}
        {this.showFutureDatesWarning()}
        {this.renderForm()}
      </div>
    );
  }
}

ReviewsCreateForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

ReviewsCreateForm.propTypes = {
  usersFilter: PropTypes.object.isRequired,
  addReview: PropTypes.func.isRequired,
  updateReview: PropTypes.func.isRequired,
  updateSpecialAssignments: PropTypes.func.isRequired,
  granteesFiltered: PropTypes.func.isRequired,
  usersFiltered: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  usersFilter: state.usersFilter,
  reviewInfo: state.review.reviewInfo,
  filteredData: state.granteeFilter,
  granteeFilteredData: state.granteeFilter,
});

export default connect(mapStateToProps, {
  addReview,
  updateReview,
  updateSpecialAssignments,
  granteesFiltered,
  usersFiltered,
  addPosition,
  addPositionReviewer,
})(ReviewsCreateForm);
