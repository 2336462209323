import React from 'react';
import { Grid, Header, Image, Icon, List, Segment } from 'semantic-ui-react';
import { detect } from 'detect-browser';

// Assets
import './assets/style.css';
import logo from '../assets/images/ams-logo.png';

const browser = detect();

const isOutdatedBrowser =
  browser && browser.name === 'ie' && browser.version <= 10 ? true : false;

const renderLogo = () => (
  <Image centered src={logo} alt="Aligned Monitoring System 2.0 Home" />
);

const renderDescription = () => (
  <>
    <Header as="h2">Please upgrade your browser to use IT-AMS</Header>

    <p>
      We built IT-AMS using the latest techniques and technologies. This makes
      IT-AMS faster and easier to use. Unfortunately, your browser doesn't
      support those technologies.
    </p>
    <p>Download one of these great browsers and you'll be on your way:</p>
  </>
);

const renderBrowserList = () => (
  <List horizontal relaxed>
    <List.Item>
      <List.Content>
        <Icon
          circular
          inverted
          size="big"
          name="chrome"
          className="chrome-flat-icon"
        />
        <List.Header as="h4">Google Chrome</List.Header>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <Icon
          circular
          inverted
          size="big"
          name="safari"
          className="safari-flat-icon"
        />
        <List.Header as="h4">Apple Safari</List.Header>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <Icon
          circular
          inverted
          size="big"
          name="firefox"
          className="firefox-flat-icon"
        />
        <List.Header as="h4">Mozilla Firefox</List.Header>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <Icon
          circular
          inverted
          size="big"
          name="microsoft edge"
          className="edge-flat-icon"
        />
        <List.Header as="h4">Microsoft Edge</List.Header>
      </List.Content>
    </List.Item>
  </List>
);

const renderMessage = () => {
  return (
    <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: '50%' }} textAlign="center">
        <Segment>
          <Segment basic>{renderLogo()}</Segment>

          <Segment basic>{renderDescription()}</Segment>

          <Segment basic>{renderBrowserList()}</Segment>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

const OutdatedBrowser = () => (isOutdatedBrowser ? renderMessage() : null);

export default OutdatedBrowser;
