import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Divider,
  Form,
  List,
  Message,
  Popup,
  TextArea,
} from 'semantic-ui-react';

//Import Components
import GranteeCurrentlySelectedDatesModal from './GranteeCurrentlySelectedDatesModal';
import SchedulerViewGranteeAvailabityRadioButton from './SchedulerViewGranteeAvailabityRadioButton';
import SchedulerViewGranteeCalendarModal from './SchedulerViewGranteeCalendarModal';
import ScheduleViewGranteeSchoolDates from './ScheduleViewGranteeSchoolDates';

import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

// Import action
import { fetchHolidays } from '../../../actions/calendarAction';
import { schedulerUpdateGranteeSchedule } from '../../../actions/granteeActions';
import SlidingContainer from '../../../utils/layout/SlidingContainer';

import { granteeName } from '../../../utils/TextFormatters';

class SchedulingGranteeViewUnavailability extends Component {
  state = {
    unavailableDates: [],
    sessionDates: [],
    breakDates: [],
    holidays: [],
    hsStartDate: null,
    hsEndDate: null,
    ehsStartDate: null,
    ehsEndDate: null,
    comments: '',
    schedulerComments: '',
    isHSRequired: false,
    isEHSRequired: false,
    changesMade: false,
    editMode: false,
    showCancelWarning: false,
    showSaveMessage: false,
    showErrorMessage: false,
    showConfirmSubmitMessage: false,
    showCalendarModal: false,
    currentlySelectedMonthYear: AmsDateFormatters.getMoment(),
    currentlySelectedMonth: '',
    editedMonths: {
      October: false,
      November: false,
      December: false,
      January: false,
      February: false,
      March: false,
      April: false,
      May: false,
      June: false,
      July: false,
      August: false,
      September: false,
    },
    errors: [],
  };

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      holidays: _.uniqBy(
        [...this.state.holidays, ...nextProps.holidays],
        'date'
      ),
    });
  }

  loadData() {
    const { grantee } = this.props;
    if (!_.isEmpty(grantee)) {
      this.setState({
        comments: grantee.comments,
        schedulerComments: grantee.schedulerComments,
      });
      this.getDatesFromGranteeDetails(grantee);
      this.getHolidays();
      this.getSchoolDatesFromGranteeDetails(grantee);
      this.checkLoadValidations(grantee);
    }
  }

  getDatesFromGranteeDetails(grantee) {
    this.setState({
      unavailableDates: this.convertDatesToDisplayFormat(
        grantee.unavailabilityDates
      ),
      sessionDates: this.convertDatesToDisplayFormat(grantee.notInSessionDates),
      breakDates: this.convertDatesToDisplayFormat(grantee.springBreakDates),
    });
  }

  getHolidays() {
    const { fetchHolidays } = this.props;
    fetchHolidays(AmsDateFormatters.getMoment().year());
    fetchHolidays(AmsDateFormatters.getMoment().year() + 1);
  }

  convertDatesToDisplayFormat(dates) {
    let tempDates = [];
    Array.from(dates).forEach(function(day) {
      tempDates.push(AmsDateFormatters.getMoment(day));
    });
    return tempDates;
  }

  getSchoolDatesFromGranteeDetails(grantee) {
    if (!_.isEmpty(grantee.hsStartDate))
      this.setState({
        hsStartDate: AmsDateFormatters.getMoment(grantee.hsStartDate),
      });
    if (!_.isEmpty(grantee.hsEndDate))
      this.setState({
        hsEndDate: AmsDateFormatters.getMoment(grantee.hsEndDate),
      });
    if (!_.isEmpty(grantee.ehsStartDate))
      this.setState({
        ehsStartDate: AmsDateFormatters.getMoment(grantee.ehsStartDate),
      });
    if (!_.isEmpty(grantee.ehsEndDate))
      this.setState({
        ehsEndDate: AmsDateFormatters.getMoment(grantee.ehsEndDate),
      });
  }

  checkLoadValidations(grantee) {
    const { granteeType } = grantee;
    let type = '';
    if (granteeType !== undefined) type = granteeType;

    if (type.toLowerCase().indexOf('and') >= 0) {
      this.setState({ isHSRequired: true, isEHSRequired: true });
    } else if (type.toLowerCase().startsWith('early')) {
      this.setState({ isEHSRequired: true });
    } else if (type.toLowerCase().startsWith('head')) {
      this.setState({ isHSRequired: true });
    } else {
      this.setState({ isHSRequired: false, isEHSRequired: false });
    }
  }

  handleEditClick = () => {
    this.setState({ editMode: true, currentlySelectedMonth: '' });
  };

  handleCloseClick = () => {
    const { hideCalendar } = this.props;
    hideCalendar();
  };

  handleSaveClick = () => {
    if (this.validData()) {
      this.setState({ showSaveMessage: true, showErrorMessage: false });
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  validData = () => {
    if (this.invalidSchoolDates()) return false;
    return true;
  };

  invalidSchoolDates = () => {
    const {
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      isHSRequired,
      isEHSRequired,
    } = this.state;
    let errors = [];
    let result = false;

    if (isHSRequired) {
      if (_.isEmpty(hsStartDate) || _.isEmpty(hsEndDate)) {
        errors.push(
          'The HS:School Year Start Date and HS:School Year End Date are required fields.'
        );
        result = true;
      } else if (AmsDateFormatters.getMoment(hsStartDate).isAfter(hsEndDate)) {
        errors.push(
          'The HS:School Year Start Date cannot be after the HS:School Year End Date.'
        );
        result = true;
      }
    }
    if (isEHSRequired) {
      if (_.isEmpty(ehsStartDate) || _.isEmpty(ehsEndDate)) {
        errors.push(
          'The EHS:School Year Start Date and EHS:School Year End Date are required fields.'
        );
        result = true;
      } else if (
        AmsDateFormatters.getMoment(ehsStartDate).isAfter(ehsEndDate)
      ) {
        errors.push(
          'The EHS:School Year Start Date cannot be after the EHS:School Year End Date.'
        );
        result = true;
      }
    }

    this.setState({ errors: errors });
    return result;
  };

  handleConfirmSubmitClick = () => {
    const { schedulerUpdateGranteeSchedule } = this.props;
    schedulerUpdateGranteeSchedule(this.generateGranteeDetails());
    this.setState({
      showConfirmSubmitMessage: true,
      showSaveMessage: false,
      editMode: false,
    });
  };

  generateGranteeDetails = () => {
    const {
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      comments,
      schedulerComments,
      unavailableDates,
      sessionDates,
      breakDates,
    } = this.state;
    const { granteeId, agencyId, finalUpdate } = this.props.grantee;
    const granteeDetails = {
      agencyId: agencyId,
      granteeId: granteeId,
      fiscalUear: AmsDateFormatters.getMoment().year(),
      finalUpdate: finalUpdate || false,
      comments: comments,
      schedulerComments: schedulerComments,
      unavailableDates: this.convertDatesToAPIFormat(unavailableDates),
      notInSessionDates: this.convertDatesToAPIFormat(sessionDates),
      springBreakDates: this.convertDatesToAPIFormat(breakDates),
      hsStartDate:
        hsStartDate != null
          ? AmsDateFormatters.getMoment(hsStartDate).utc()
          : '',
      hsEndDate:
        hsEndDate != null ? AmsDateFormatters.getMoment(hsEndDate).utc() : '',
      ehsStartDate:
        ehsStartDate != null
          ? AmsDateFormatters.getMoment(ehsStartDate).utc()
          : '',
      ehsEndDate:
        ehsEndDate != null ? AmsDateFormatters.getMoment(ehsEndDate).utc() : '',
      selectedMonths: null,
      sendEmail: true,
    };

    //Added because backend wants an array of data returned instead now.
    let returnData = [];
    returnData.push(granteeDetails);
    return { grantees: returnData };
  };

  convertDatesToAPIFormat(dates) {
    let tempDates = [];
    Array.from(dates).forEach(function(day) {
      tempDates.push(AmsDateFormatters.getMoment(day).utc());
    });
    return tempDates;
  }

  handleCancelClick = () => {
    const { changesMade } = this.state;
    if (changesMade) this.setState({ showCancelWarning: true });
    else this.setState({ editMode: false });
  };

  handleConfirmCancelClick = () => {
    this.setState({
      editMode: false,
      showCancelWarning: false,
      changesMade: false,
      schedulerComments: '',
      currentlySelectedMonth: '',
      editedMonths: {
        October: false,
        November: false,
        December: false,
        January: false,
        February: false,
        March: false,
        April: false,
        May: false,
        June: false,
        July: false,
        August: false,
        September: false,
      },
    });
    this.loadData();
  };

  handleMonthClick = month => {
    let selectedMonthYear = AmsDateFormatters.getMoment();
    if (month === 'October' || month === 'November' || month === 'December') {
      selectedMonthYear = AmsDateFormatters.getMoment(
        month + AmsDateFormatters.getMoment().year()
      );
    } else {
      selectedMonthYear = AmsDateFormatters.getMoment(
        month +
          AmsDateFormatters.getMoment()
            .add(1, 'y')
            .year()
      );
    }
    this.setState({
      currentlySelectedMonth: month,
      showCalendarModal: true,
      currentlySelectedMonthYear: selectedMonthYear,
    });
  };

  handleHideCalendarModal = () => {
    this.setState({
      showCalendarModal: false,
      currentlySelectedMonth: '',
      currentlySelectedMonthYear: AmsDateFormatters.getMoment(),
    });
  };

  handleUpdateHsStartDate = date => {
    // If the start date is greater than end date, reset the end date to null.
    if (AmsDateFormatters.getMoment(date).isAfter(this.state.hsEndDate)) {
      this.setState({
        hsStartDate: date,
        hsEndDate: null,
      });
      return;
    }

    this.setState({ hsStartDate: date, changesMade: true });
  };

  handleUpdateHsEndDate = date => {
    this.setState({ hsEndDate: date, changesMade: true });
  };

  handleUpdateEhsStartDate = date => {
    if (AmsDateFormatters.getMoment(date).isAfter(this.state.ehsEndDate)) {
      this.setState({
        ehsStartDate: date,
        ehsEndDate: null,
      });
      return;
    }

    this.setState({ ehsStartDate: date, changesMade: true });
  };

  handleUpdateEHsEndDate = date => {
    this.setState({ ehsEndDate: date, changesMade: true });
  };

  handleUpdateComments = event => {
    this.setState({ schedulerComments: event.target.value, changesMade: true });
  };

  handleUpdateMonth = (unavailableDates, sessionDates, breakDates) => {
    let { editedMonths, currentlySelectedMonth } = this.state;
    editedMonths[currentlySelectedMonth] = true;
    this.setState({
      unavailableDates: unavailableDates,
      sessionDates: sessionDates,
      breakDates: breakDates,
      editedMonths: editedMonths,
      changesMade: true,
      showCalendarModal: false,
      currentlySelectedMonth: '',
      currentlySelectedMonthYear: AmsDateFormatters.getMoment(),
    });
  };

  renderSaveConfirmation = () => {
    const { showSaveMessage } = this.state;
    return (
      <AmsAlert
        show={showSaveMessage}
        title="You are about to save changes to the Grantee's Unavailability"
        text={`Are you sure you want to continue?  Click Confirm Submit if you want to update the Grantee's Unavailability.  Click Cancel if you want to continue working on changes.`}
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Confirm Submit'}
        cancelButtonText={'Cancel'}
        showCancelButton
        showConfirm
        onCancel={() => {
          this.setState({ showSaveMessage: false });
        }}
        onConfirm={() => {
          this.handleConfirmSubmitClick();
        }}
      />
    );
  };

  renderSumbitConfirmMessage = () => {
    return (
      <Message positive onDismiss={this.handleDismissConfirmsMessage}>
        <Message.Header>Success!</Message.Header>
        <p>Your changes have been submitted successfully.</p>
      </Message>
    );
  };

  handleDismissConfirmsMessage = () => {
    this.setState({ showConfirmSubmitMessage: false });
  };

  renderErrorMessage = () => {
    const { errors } = this.state;
    const listItems = errors.map(d => <List.Item key={d}>{d}</List.Item>);
    return (
      <Message negative onDismiss={this.handleDismissErrorMessages}>
        <Message.Header>Errors!</Message.Header>
        <p>
          The following issues need to be fixed before you can submit changes.
        </p>
        <List>{listItems}</List>
      </Message>
    );
  };

  handleDismissErrorMessages = () => {
    this.setState({ showErrorMessage: false });
  };

  renderCancelConfirmation = () => {
    const { showCancelWarning } = this.state;
    return (
      <AmsAlert
        show={showCancelWarning}
        title="Cancel Changes will cancel all of the changes you have made"
        text={`You will lose any changes you have made.  Click Confirm Cancel if you want to cancel your changes.  Click Cancel if you want to continue working on changes.`}
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Confirm Cancel'}
        cancelButtonText={'Cancel'}
        showCancelButton
        showConfirm
        onCancel={() => {
          this.setState({ showCancelWarning: false });
        }}
        onConfirm={() => {
          this.handleConfirmCancelClick();
        }}
      />
    );
  };

  renderScheduleViewButton = () => {
    const {
      grantee,
      grantee: { notInSessionDates, springBreakDates, unavailabilityDates },
    } = this.props;

    if (!grantee) return;

    if (
      _.isEmpty(notInSessionDates) &&
      _.isEmpty(springBreakDates) &&
      _.isEmpty(unavailabilityDates)
    ) {
      return (
        <Popup
          trigger={
            <Button basic size="large" color="blue" content="Schedule View" />
          }
          content="There are no unavailability dates entered."
          size="mini"
          inverted
        />
      );
    }

    return (
      <Button
        basic
        color="blue"
        size="large"
        as={Link}
        to={`/grantee/${grantee && grantee.granteeId}/availability/schedule`}
        content="Schedule View"
      />
    );
  };

  render() {
    const {
      comments,
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      isHSRequired,
      isEHSRequired,
      schedulerComments,
      editMode,
      unavailableDates,
      sessionDates,
      breakDates,
      currentlySelectedMonth,
      editedMonths,
      currentlySelectedMonthYear,
      showCalendarModal,
      holidays,
      showConfirmSubmitMessage,
      showErrorMessage,
    } = this.state;
    const { grantee } = this.props;

    const actionButtons = (
      <>
        <List.Item>
          {/* <List.Content>
            <Button
              basic
              color="blue"
              size="large"
              as={Link}
              to={`/grantee/${grantee &&
                grantee.granteeId}/availability/extension-request`}
              content="Extension Request"
            />
          </List.Content> */}
        </List.Item>
        <List.Item>
          <List.Content>{this.renderScheduleViewButton()}</List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            {!editMode && grantee.hsesStatus === 'Active' && (
              <Button color="green" size="large" onClick={this.handleEditClick}>
                Edit Grantee Unavailability
              </Button>
            )}
          </List.Content>
        </List.Item>
      </>
    );

    return (
      <SlidingContainer
        title={granteeName(grantee)}
        actionButtons={actionButtons}
      >
        <Form id="grantee-unavailability-form">
          {showConfirmSubmitMessage ? this.renderSumbitConfirmMessage() : ''}
          {showErrorMessage ? this.renderErrorMessage() : ''}
          {/* <Divider /> */}
          <ScheduleViewGranteeSchoolDates
            hsStartDate={hsStartDate}
            hsEndDate={hsEndDate}
            ehsStartDate={ehsStartDate}
            ehsEndDate={ehsEndDate}
            isHSRequired={isHSRequired}
            isEHSRequired={isEHSRequired}
            editMode={editMode}
            handleHsStartDate={this.handleUpdateHsStartDate}
            handleHsEndDate={this.handleUpdateHsEndDate}
            handleEhsStartDate={this.handleUpdateEhsStartDate}
            handleEhsEndDate={this.handleUpdateEHsEndDate}
          />
          <SchedulerViewGranteeCalendarModal
            unavailableDates={unavailableDates}
            sessionDates={sessionDates}
            breakDates={breakDates}
            currentlySelectedMonthYear={currentlySelectedMonthYear}
            editMode={editMode}
            showCalendarModal={showCalendarModal}
            handleHideCalendarModal={this.handleHideCalendarModal}
            holidays={holidays}
            handleUpdateMonth={this.handleUpdateMonth}
          />
          <Form.Group>
            <SchedulerViewGranteeAvailabityRadioButton
              month={'October'}
              monthWasEdited={editedMonths['October']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'November'}
              monthWasEdited={editedMonths['November']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'December'}
              monthWasEdited={editedMonths['December']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'January'}
              monthWasEdited={editedMonths['January']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
          </Form.Group>
          <Form.Group>
            <SchedulerViewGranteeAvailabityRadioButton
              month={'February'}
              monthWasEdited={editedMonths['February']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'March'}
              monthWasEdited={editedMonths['March']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'April'}
              monthWasEdited={editedMonths['April']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'May'}
              monthWasEdited={editedMonths['May']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
          </Form.Group>
          <Form.Group>
            <SchedulerViewGranteeAvailabityRadioButton
              month={'June'}
              monthWasEdited={editedMonths['June']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'July'}
              monthWasEdited={editedMonths['July']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'August'}
              monthWasEdited={editedMonths['August']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
            <SchedulerViewGranteeAvailabityRadioButton
              month={'September'}
              monthWasEdited={editedMonths['September']}
              currentlySelectedMonth={currentlySelectedMonth}
              handleMonthClick={this.handleMonthClick}
            />
          </Form.Group>
          <GranteeCurrentlySelectedDatesModal
            unavailableDates={unavailableDates}
            sessionDates={sessionDates}
            breakDates={breakDates}
          />
          <Divider />
          <Form.Group>
            <Form.Field width={16}>
              {!editMode ? (
                <Form.Field>
                  <strong>Grantee Comments: </strong>
                  {comments}
                </Form.Field>
              ) : (
                ''
              )}
              <br />
              {editMode ? (
                <Form.Field
                  disabled={!editMode}
                  control={TextArea}
                  label={{
                    children: 'Scheduler Comments:',
                    htmlFor: 'schedulerComments',
                  }}
                  placeholder=""
                  onChange={this.handleUpdateComments}
                  value={schedulerComments}
                  id="schedulerComments"
                  aria-labelledby="schedulerComments"
                />
              ) : (
                <Form.Field>
                  <strong>Scheduler Comments: </strong>
                  {schedulerComments}
                </Form.Field>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group>
            {editMode ? (
              <div>
                <Button color="green" onClick={this.handleSaveClick}>
                  Save Changes
                </Button>
                <Button color="red" onClick={this.handleCancelClick}>
                  Cancel Changes
                </Button>
              </div>
            ) : (
              ''
            )}
          </Form.Group>
          {this.renderSaveConfirmation()}
          {this.renderCancelConfirmation()}
          <input type="submit" value="submit" hidden />
        </Form>
      </SlidingContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    grantee: state.grantee,
    holidays: state.calendar.holidays,
  };
};

export default connect(mapStateToProps, {
  schedulerUpdateGranteeSchedule,
  fetchHolidays,
})(SchedulingGranteeViewUnavailability);
