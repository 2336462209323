import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

class GranteeCalendarExpiredMessage extends Component {
  renderExpiredDetails() {
    let { showExpiredMessage, grantees } = this.props;
    return showExpiredMessage ? (
      <div>
        <Message
          header="Grantee Unavailability Expired."
          content={
            grantees.length > 1
              ? 'The time to submit grantee unavailability has expired and you cannot make any changes.  You may view the Grantee details by selecting the grantee from the drop down below.'
              : 'The time to submit grantee unavailability has expired and you cannot make any changes.  You may view the Grantee details below.'
          }
        />
      </div>
    ) : (
      ''
    );
  }

  render() {
    return <div> {this.renderExpiredDetails()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarExpiredMessage);
