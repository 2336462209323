import React from 'react';
import { connect } from 'react-redux';

// Import components
import LookupParentForm from './LookupParentForm';
import LookupStringForm from './LookupStringForm';
import LookupNumberForm from './LookupNumberForm';
import LookupBooleanForm from './LookupBooleanForm';
import LookupObjectForm from './LookupObjectForm';
import LookupArrayForm from './LookupArrayForm';

const LookupDetails = ({ selectedLookup }) => {
  const loadTypeForm = () => {
    return (
      <div>
        <LookupStringForm />
        <LookupNumberForm />
        <LookupBooleanForm />
        <LookupObjectForm />
        <LookupArrayForm />
      </div>
    );
  };

  return (
    <div className="lookup-detail-wrapper">
      <h3>Lookup</h3>
      <div>
        <LookupParentForm />
        <hr />
      </div>
      <div>
        {/* Load child forms */}
        {loadTypeForm()}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedLookup: state.lookups.selectedLookup,
});

export default connect(
  mapStateToProps,
  {}
)(LookupDetails);
