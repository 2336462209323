import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { Link } from 'react-router';

import AmsTable from '../../../../utils/AmsTable';

export default function FollowUpSurveyDetails(props) {
  const [submission, setSubmission] = useState(null);
  const [firstCitation, setFirstCitation] = useState('');
  const { review } = useSelector(state => state);

  const { newFollowUpDetails } = useSelector(state => state.reviewSubmissions);

  useEffect(() => {
    if (
      newFollowUpDetails &&
      newFollowUpDetails.name &&
      newFollowUpDetails.name.length > 0
    )
      setSubmission([newFollowUpDetails]);
  }, [JSON.stringify(newFollowUpDetails)]);

  useEffect(() => {
    const first =
      review.followUpSurveyDetails &&
      review.followUpSurveyDetails.followUpCitations &&
      review.followUpSurveyDetails.followUpCitations[0]
        ? review.followUpSurveyDetails.followUpCitations[0].name
        : '';
    setFirstCitation(first);
  }, [review.followUpSurveyDetails]);

  const reviewId = review.selectedReview.reviewId;

  //<Link to={`/review/${cell}`}>{cell}</Link>;
  const guideNameFormatter = (cell, row) => {
    return firstCitation && firstCitation.length > 0 ? (
      <Link
        to={`/review/fy${review.selectedReview.fiscalYear
          .toString()
          .slice(-2)}/${
          review.selectedReview.reviewId
        }/survey/followup?citation=${firstCitation}`}
      >
        {cell}
      </Link>
    ) : (
      cell
    );
  };

  const columns = [
    {
      dataField: 'name',
      formatter: guideNameFormatter,
      headerStyle: { width: '40%' },
      text: 'Guide Name',
    },
    {
      dataField: 'totalForms',
      headerStyle: { width: '20%' },
      text: 'Total Forms (Citations)',
    },
    {
      dataField: 'formsCompleted',
      headerStyle: { width: '20%' },
      text: 'Completed Forms (Citations)',
    },
    {
      dataField: 'status',
      headerStyle: { width: '20%' },
      text: 'Status',
    },
  ];

  const renderSubmission = () => {
    let loading =
      !submission || submission.length === 0 || firstCitation === '';

    return (
      <>
        <Dimmer active={loading} inverted>
          <Loader active={loading} inverted />
        </Dimmer>
        <Segment basic>
          {firstCitation &&
          submission &&
          submission[0] &&
          submission[0].name &&
          submission[0].name.length > 0 &&
          submission.length > 0 &&
          firstCitation.length > 0 ? (
            <AmsTable
              keyField="name"
              data={submission}
              columns={columns}
              basic
              sizePerPageOption={false}
            />
          ) : null}
        </Segment>
      </>
    );
  };

  return <div>{renderSubmission()}</div>;
}
