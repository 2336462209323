import React from 'react';

//Import components
import AmsHelpText from '../../../utils/AmsHelpText';

const ChangePassword = () => (
  <div>
    <div className="profile-form-title-wrapper">
      <h2>
        Change Password
        <AmsHelpText category="helpText" fieldLabel="changePassword" />
      </h2>
    </div>
    <a
      target="_blank"
      className="change-password-button"
      href="https://account.activedirectory.windowsazure.com/ChangePassword.aspx"
      rel="noopener noreferrer"
    >
      Change Password
    </a>
  </div>
);

export default ChangePassword;
