import { GRANTEE_OPTIONS_FETCHED } from '../redux/types';

export default function granteeOptions(state = [], action) {
  switch (action.type) {
    case GRANTEE_OPTIONS_FETCHED: {
      return action.granteeOptions;
    }

    default:
      return state;
  }
}
