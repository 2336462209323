import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, intersection } from 'lodash';
import {
  Dimmer,
  Loader,
  Input,
  Checkbox,
  Header,
  Form,
  Divider,
  Dropdown,
  Modal,
  Button,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router';

import GranteeListDropdown from '../../../utils/GranteeListDropdown';
import { addReview, updateReview } from '../../../actions/reviewActions';
import AmsAlert from '../../../utils/AmsAlert';
import AmsButtonSelectorList from '../../../utils/AmsButtonSelectorList';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { acl } from '../../../config';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsModal from '../../../utils/AmsModal';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import ReviewInfoChangeHistory from './ReviewInfoChangeHistory';

const specialReviewLeadOptions = [
  {
    key: 'dlh',
    value: 'dlh',
    text: 'DLH Review Lead',
  },
  {
    key: 'regional',
    value: 'regional',
    text: 'Regional Office Lead',
  },
];

const ReviewInfoForm = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedReviewType, setSelectedReviewType] = useState('');
  const [selectedFiscalYear, setSelectedFiscalYear] = useState('');
  const [selectedReviewId, setSelectedReviewId] = useState('');
  const [selectedGranteeIds, setSelectedGranteeIds] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedSpecialReviewLead, setSelectedSpecialReviewLead] = useState(
    ''
  );
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showExitAndSave, setShowExitAndSave] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [createdReviewId, setCreatedReviewId] = useState('');
  const { reviewInfo } = useSelector(state => state.review);
  const {
    reviewType,
    fiscalYear,
    specialReviewLead,
    reviewId,
    grantInfo,
    startDate,
    endDate,
    resendEmail,
    sendEmail,
    unannounced,
    reviewStatus,
    reviewChangeHistory,
  } = useSelector(state => state.review.reviewInfo);
  const { user } = useSelector(state => state.auth);
  const [currentReviewStatus, setReviewStatus] = useState('');
  const [showOnHoldWarning, setShowOnHoldWarning] = useState(false);
  const [onHold, setOnHold] = useState(null);
  const [currentUnannounced, setCurrentUnannounced] = useState(false);
  const [showInActiveStatusWarning, setShowInActiveStatusWarning] = useState(
    false
  );
  const { granteeFilter } = useSelector(state => state);
  const [tempGranteeIds, setTempGranteeIds] = useState([]);
  const [tempReviewStatus, setTempReviewStatus] = useState('');
  const [currentFieldData, setCurrentFieldData] = useState({});
  const [currentReviewChangeHistory, setCurrentReviewChangeHistory] = useState(
    {}
  );
  const [openChangeHistory, setOpenChangeHistory] = useState(false);
  const [currentReviewChanges, setCurrentReviewChanges] = useState(false);

  useEffect(() => {
    const setupReviewInfo = () => {
      if (props.editMode && reviewInfo) {
        setSelectedEndDate(AmsDateFormatters.getMoment(endDate));
        setSelectedFiscalYear(fiscalYear);
        setSelectedReviewId(reviewId);
        setSelectedReviewType(reviewType);
        setSelectedStartDate(AmsDateFormatters.getMoment(startDate));
        setSelectedSpecialReviewLead(specialReviewLead);
        setSelectedGranteeIds(grantInfo.map(grantee => grantee.granteeId));
      }
    };
    setupReviewInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMode, reviewInfo]);

  useEffect(() => {
    if (props.saveRequest === true) {
      saveReview('request');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saveRequest]);

  useEffect(() => {
    const updateReviewStatus = () => {
      if (reviewStatus) {
        setReviewStatus(reviewStatus);
      }
      setCurrentUnannounced(unannounced);
    };
    updateReviewStatus();
  }, [reviewStatus, unannounced]);

  useEffect(() => {
    const updateCurrentChangeHistory = () => {
      if (currentFieldData) {
        let history =
          currentReviewChanges &&
          currentReviewChanges.filter(
            data => data.type === currentFieldData.name
          ).length > 0
            ? currentReviewChanges &&
              currentReviewChanges
                .filter(data => data.type === currentFieldData.name)
                .sort((a, b) =>
                  AmsDateFormatters.getMoment(b.modifiedDate).diff(
                    AmsDateFormatters.getMoment(a.modifiedDate)
                  )
                )
            : reviewChangeHistory &&
              reviewChangeHistory
                .filter(data => data.type === currentFieldData.name)
                .sort((a, b) =>
                  AmsDateFormatters.getMoment(b.modifiedDate).diff(
                    AmsDateFormatters.getMoment(a.modifiedDate)
                  )
                );
        history && history[0]
          ? setCurrentReviewChangeHistory(history[0])
          : setCurrentReviewChangeHistory({});
      }
      if (!props.editMode) handleFieldChanges();
    };

    updateCurrentChangeHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldData]);

  const reviewStatusOptions = [
    { key: 'Review On Hold', value: 'Review On Hold', text: 'Review On Hold' },
    { key: 'Report On Hold', value: 'Report On Hold', text: 'Report On Hold' },
    { key: 'Cancelled', value: 'Cancelled', text: 'Cancelled' },
    {
      key: 'Report Not Issued',
      value: 'Report Not Issued',
      text: 'Report Not Issued',
    },
  ];

  const handleReviewTypeSelection = (e, reviewType) => {
    setSelectedReviewType(reviewType.value);
    if (
      reviewType.value !== 'Special' &&
      reviewType.value !== 'AIAN-DEF' &&
      reviewType.value !== 'ERSEA'
    )
      setSelectedSpecialReviewLead('');
    props.selectReviewType(reviewType.value);
    setReviewStatus('');
  };

  const handleFiscalYearSelection = (e, fiscalYear) => {
    setSelectedFiscalYear(fiscalYear.value);
  };

  const handleSpecialReviewLeadSelection = (e, specialReviewLead) => {
    setSelectedSpecialReviewLead(specialReviewLead.value);
  };

  const validate = () => {
    const errors = {};

    if (!isEmpty(selectedReviewId) && isNaN(selectedReviewId.substring(0, 2)))
      errors.reviewId =
        'The first two characters of the review ID should be numeric';
    if (!isEmpty(selectedReviewId) && selectedReviewId.match(/[^\w]|_/g))
      errors.reviewId = 'Only alphanumeric values are allowed for review ID';
    if (!selectedGranteeIds || !selectedGranteeIds.length)
      errors.grantee = 'Grantee is required';
    if (!selectedStartDate) errors.startDate = 'Start date is required';
    if (!selectedEndDate) errors.endDate = 'End date is required';
    if (selectedEndDate.isBefore(selectedStartDate))
      errors.endDate = 'End date can not be before start date';
    return errors;
  };

  const saveReview = type => {
    const errors = validate();
    let datesChanged = false;
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (
        AmsDateFormatters.getMoment(startDate)
          .startOf('day')
          .format() !==
          AmsDateFormatters.getMoment(selectedStartDate)
            .startOf('day')
            .format() ||
        AmsDateFormatters.getMoment(endDate)
          .startOf('day')
          .format() !==
          AmsDateFormatters.getMoment(selectedEndDate)
            .startOf('day')
            .format()
      ) {
        datesChanged = true;
      }

      let input = {
        reviewId: selectedReviewId,
        granteeIds: selectedGranteeIds,
        startDate: selectedStartDate
          ? AmsDateFormatters.getMoment(selectedStartDate)
              .startOf('day')
              .format()
          : null,
        endDate: selectedEndDate
          ? AmsDateFormatters.getMoment(selectedEndDate)
              .endOf('day')
              .format()
          : null,
        reviewType: selectedReviewType,
        fiscalYear: selectedFiscalYear,
        unannounced: currentUnannounced,
      };
      if (selectedSpecialReviewLead)
        input['specialReviewLead'] = selectedSpecialReviewLead;
      if (
        selectedReviewType === 'Special' ||
        selectedReviewType === 'AIAN-DEF' ||
        selectedReviewType === 'ERSEA'
      )
        input['sendEmail'] = type === 'exit' ? true : false;
      setLoading(true);
      if (!props.editMode)
        dispatch(addReview(input))
          .then(response => {
            const { reviewId } = response;
            props.storeCreatedReviewId(reviewId);
            if (reviewId && type === 'exit') {
              setShowAlert(true);
              setAlertMessage(
                `Review with ID: ${reviewId} created successfully`
              );
              setAlertType('success');
              setCreatedReviewId(reviewId);
              setLoading(false);
            } else if (reviewId && type === 'continue') {
              if (
                reviewType !== 'Special' &&
                reviewType !== 'ERSEA' &&
                reviewType !== 'AIAN-DEF'
              )
                props.goToStep('team', reviewId, true);
              else props.goToStep('pms', reviewId, true);
            }
          })
          .catch(error => {
            setShowAlert(true);
            setAlertMessage(error.response.data.message);
            setAlertType('error');
            setLoading(false);
          });
      else {
        const canUpdateReview = intersection(
          acl.actions.review.update,
          user.roles
        );
        let data = {
          ...reviewInfo,
          startDate: selectedStartDate
            ? AmsDateFormatters.getMoment(selectedStartDate)
                .startOf('day')
                .format()
            : startDate,
          endDate: selectedEndDate
            ? AmsDateFormatters.getMoment(selectedEndDate)
                .endOf('day')
                .format()
            : endDate,
          unannounced: currentUnannounced,
          reviewChanges: currentReviewChanges ? currentReviewChanges : [],
          datesChanged,
        };

        if (currentReviewStatus)
          data = { ...data, reviewStatus: currentReviewStatus };

        if (!isEmpty(canUpdateReview)) {
          dispatch(updateReview(data))
            .then(response => {
              if (type === 'request') {
                props.dataHasChangedSwitch('successSave');
                return;
              }
              if (
                reviewType !== 'Special' &&
                reviewType !== 'ERSEA' &&
                reviewType !== 'AIAN-DEF'
              )
                props.goToStep('team', reviewId, true);
              else props.goToStep('pms', reviewId, true);
            })
            .catch(error => {
              console.log(error);
              setAlertMessage(error.response);
              setShowAlert(true);
              setAlertType('error');
            });
        }
      }
    }
  };

  const showMessageDialog = () => {
    return (
      <AmsAlert
        show={showAlert}
        type={alertType || 'success'}
        showConfirm
        title={alertType === 'success' ? 'Review created' : 'Oops...'}
        text={alertMessage}
        onConfirm={() => {
          setAlertMessage('');
          setShowAlert(false);
          setAlertType('');
          if (alertType === 'success')
            props.router.push(`/review/${createdReviewId}`);
        }}
      />
    );
  };

  const showExitAndSaveDialog = () => {
    return (
      <AmsAlert
        show={showExitAndSave}
        type={alertType || 'success'}
        showConfirm
        showCancelButton
        title={'Do you want to continue?'}
        text={alertMessage}
        onConfirm={() => {
          setAlertMessage('');
          setShowExitAndSave(false);
          setAlertType('');
          saveReview('exit');
        }}
        onCancel={() => {
          setShowExitAndSave(false);
          setAlertMessage('');
          setAlertType('');
        }}
      />
    );
  };

  const renderOnHoldStatus = () => {
    return (
      <>
        <Form.Group>
          <label>Review Status</label>
        </Form.Group>
        <Form.Group>
          <label style={{ marginRight: '20px' }}>
            {currentReviewStatus === 'Review On Hold'
              ? 'Review On Hold'
              : 'Report On Hold'}{' '}
          </label>
          <Link
            to="#"
            onClick={e => {
              e.preventDefault();
              setCurrentFieldData({
                name: 'reviewStatus',
                data: false,
                changedTo: 'Removed the hold',
                changedFrom: reviewStatus,
              });
              setOpenChangeHistory(true);
              setOnHold(false);
              setAlertMessage(
                'You are about to remove the hold on this review. Would you like to proceed?'
              );
            }}
          >
            (Remove the hold)
          </Link>
        </Form.Group>
      </>
    );
  };

  const renderChangeHistoryForm = () => {
    return (
      <AmsModal
        size="large"
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setOpenChangeHistory(false);
        }}
        open={openChangeHistory}
        className="ams-semantic-modal-fix"
      >
        <Header content="Add/Update reasons for changing field" />

        <Modal.Content>
          <Modal.Description>
            <Form>
              <>
                Select the reason(s) for changing or updating the field you have
                selected
              </>
              <Form.Field>
                <AmsFormLabel
                  name="Add/Update Reason"
                  fieldLabel="reviewChangeReasons"
                >
                  <AmsLookupDropdown
                    placeholder="Add/Update reason for changing field"
                    value={
                      currentReviewChangeHistory &&
                      currentReviewChangeHistory.reason
                    }
                    name="reviewChangeReasons"
                    onChange={(e, { name, value }) => {
                      setCurrentReviewChangeHistory({
                        type: currentFieldData.name,
                        reason: value,
                      });
                    }}
                    control={AmsLookupDropdown}
                    fluid
                    search
                    selection
                    // clearable
                    category={'reviewChangeReasons'}
                    aria-labelledby="reviewChangeReasons"
                    searchInput={{
                      id: 'reviewChangeReasons',
                      title: 'Select a Review Type',
                      'aria-labelledby': 'reviewChangeReasons',
                    }}
                    multiple
                  />
                </AmsFormLabel>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setCurrentReviewChangeHistory({});
              setOpenChangeHistory(false);
            }}
            content="No, Cancel"
          />
          <Button
            onClick={() => {
              props.dataHasChangedSwitch(true);
              let reviewChanges = currentReviewChanges
                ? [...currentReviewChanges]
                : [];
              if (
                reviewChanges.filter(
                  data => data.type === currentFieldData.name
                ).length > 0
              ) {
                reviewChanges = reviewChanges.map(data => {
                  let changes = data;
                  if (changes.type === currentFieldData.name) {
                    changes = {
                      type: changes.type,
                      reason: currentReviewChangeHistory.reason,
                      changedFrom: currentFieldData.changedFrom,
                      changedTo: currentFieldData.changedTo,
                    };
                  }
                  return changes;
                });
              } else {
                reviewChanges.push({
                  type: currentFieldData.name,
                  reason: currentReviewChangeHistory.reason,
                  changedFrom: currentFieldData.changedFrom,
                  changedTo: currentFieldData.changedTo,
                });
              }
              setCurrentReviewChanges(reviewChanges);
              handleFieldChanges();
            }}
            primary
            content="Yes, update it"
            disabled={
              currentReviewChangeHistory &&
              currentReviewChangeHistory.reason &&
              currentReviewChangeHistory.reason.length > 0
                ? false
                : true
            }
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const handleFieldChanges = () => {
    switch (currentFieldData.name) {
      case 'reviewStatus':
        if (onHold) {
          setShowOnHoldWarning(true);
          setOnHold(false);
        } else if (currentFieldData.data.value === 'Review On Hold') {
          setAlertMessage(
            'You are about to set this review on hold. Would you like to proceed?'
          );
          setShowOnHoldWarning(true);
          setOnHold(true);
          setTempReviewStatus(currentFieldData.data.value);
        } else if (currentFieldData.data.value === 'Report On Hold') {
          setAlertMessage(
            'You are about to set this review as report on hold. Would you like to proceed?'
          );
          setShowOnHoldWarning(true);
          setOnHold(true);
          setTempReviewStatus(currentFieldData.data.value);
        } else {
          if (currentFieldData.data.value === 'Report Not Issued')
            setAlertMessage(
              'You are about to set this review to report not issued. Would you like to proceed?'
            );
          if (currentFieldData.data.value === 'Cancelled')
            setAlertMessage(
              'You are about to set this review as cancelled. Would you like to proceed?'
            );
          setShowOnHoldWarning(true);
          setTempReviewStatus(currentFieldData.data.value);
        }
        break;
      case 'unannounced':
        setCurrentUnannounced(currentFieldData.data.checked);
        break;
      case 'reviewStartDate':
        setSelectedStartDate(currentFieldData.date);
        break;
      case 'reviewEndDate':
        setSelectedEndDate(currentFieldData.date);
        break;
      default:
        break;
    }
    setOpenChangeHistory(false);
  };

  const renderOnHoldWarning = () => {
    // const status = currentReviewStatus;
    return (
      <AmsAlert
        show={showOnHoldWarning}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        title={'Are you sure?'}
        text={alertMessage}
        onConfirm={() => {
          if (onHold === true) {
            setOnHold(null);
            setShowOnHoldWarning(false);
            setReviewStatus(tempReviewStatus);
            setAlertMessage('');
            setTempReviewStatus('');
          } else if (
            tempReviewStatus === 'Report Not Issued' ||
            tempReviewStatus === 'Cancelled'
          ) {
            setReviewStatus(tempReviewStatus);
            setShowOnHoldWarning(false);
            setAlertMessage('');
            setTempReviewStatus('');
          } else {
            setOnHold(null);
            setShowOnHoldWarning(false);
            if (
              AmsDateFormatters.getMoment(startDate) >
              AmsDateFormatters.getMoment()
            )
              setReviewStatus('Created');
            else setReviewStatus('In Progress');
            setAlertMessage('');
          }
        }}
        onCancel={() => {
          setOnHold(null);
          setShowOnHoldWarning(false);
          setAlertMessage('');
        }}
      />
    );
  };

  const renderInActiveStatusWarning = () => {
    return (
      <AmsAlert
        show={showInActiveStatusWarning}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        title={'Are you sure?'}
        text={'Inactive Grantee has been selected. Do you want to proceed?'}
        onConfirm={() => {
          setSelectedGranteeIds(tempGranteeIds);
          setTempGranteeIds([]);
          setShowInActiveStatusWarning(false);
        }}
        onCancel={() => {
          setTempGranteeIds([]);
          setShowInActiveStatusWarning(false);
        }}
      />
    );
  };

  const reviewInfoForm = () => {
    return (
      <Form error noValidate>
        {!props.editMode ? (
          <>
            <Form.Field
              required
              content="Select a review type (Required)"
              id="reviewType"
              itemsPerRow={5}
              value={selectedReviewType}
              category="reviewCreateReviewTypes"
              handleSelection={(e, item) => handleReviewTypeSelection(e, item)}
              chunked
              control={AmsButtonSelectorList}
              disabled={props.editMode}
              tabIndex={props.editMode ? '-1' : '0'}
            />
            {selectedReviewType && (
              <Form.Field
                required
                content="Select a fiscal year (Required)"
                id="fiscalYear"
                value={selectedFiscalYear}
                category="reviewFiscalYear"
                handleSelection={(e, item) =>
                  handleFiscalYearSelection(e, item)
                }
                control={AmsButtonSelectorList}
                disabled={props.editMode}
                tabIndex={props.editMode ? '-1' : '0'}
              />
            )}
          </>
        ) : (
          <>
            <Form.Field required>
              <span>Review Type:</span>
              <div className="default-value">
                {selectedReviewType ? selectedReviewType : reviewType}
              </div>
            </Form.Field>
            <Form.Field required>
              <span>Fiscal Year:</span>
              <div className="default-value">
                {selectedFiscalYear ? selectedFiscalYear : fiscalYear}
              </div>
            </Form.Field>
          </>
        )}
        {selectedFiscalYear && (
          <>
            {!props.editMode ? (
              <Form.Group>
                {(selectedReviewType === 'Special' ||
                  selectedReviewType === 'AIAN-DEF' ||
                  selectedReviewType === 'ERSEA') && (
                  <Form.Field
                    required
                    content="Who would lead the review? (Required)"
                    id="specialReviewLead"
                    value={selectedSpecialReviewLead}
                    items={specialReviewLeadOptions}
                    handleSelection={(e, item) =>
                      handleSpecialReviewLeadSelection(e, item)
                    }
                    control={AmsButtonSelectorList}
                  />
                )}
              </Form.Group>
            ) : (
              <>
                {(selectedReviewType === 'Special' ||
                  selectedReviewType === 'AIAN-DEF' ||
                  selectedReviewType === 'ERSEA') && (
                  <Form.Field required>
                    <span>Who would lead the review:</span>
                    <div className="default-value">
                      {specialReviewLead === 'dlh'
                        ? 'DLH Review Lead'
                        : specialReviewLead === 'regional'
                        ? 'Regional Office Lead'
                        : specialReviewLead}
                    </div>
                  </Form.Field>
                )}
              </>
            )}
          </>
        )}

        {((selectedSpecialReviewLead &&
          (selectedReviewType === 'Special' ||
            selectedReviewType === 'AIAN-DEF' ||
            selectedReviewType === 'ERSEA')) ||
          (selectedFiscalYear &&
            selectedReviewType !== 'Special' &&
            selectedReviewType !== 'AIAN-DEF' &&
            selectedReviewType !== 'ERSEA')) && (
          <>
            {!props.editMode ? (
              <Form.Group
                widths={
                  selectedReviewType === 'Other' || selectedReviewType === 'RAN'
                    ? 2
                    : 'equal'
                }
              >
                {selectedReviewType !== 'Other' &&
                  selectedReviewType !== 'RAN' && (
                    <Form.Field
                      control={Input}
                      label={{
                        children:
                          selectedReviewType === 'Follow-up'
                            ? 'Preliminary Review ID'
                            : selectedReviewType === 'AIAN-CLASS'
                            ? 'Primary Review ID'
                            : 'Review ID',
                        htmlFor: 'reviewIdInput',
                      }}
                      error={
                        !!errors.reviewId && {
                          content: errors.reviewId,
                          pointing: 'above',
                        }
                      }
                      // error={!!errors.reviewId}
                      id="reviewIdInput"
                      name="reviewId"
                      onChange={(e, { name, value }) => {
                        setSelectedReviewId(value);
                      }}
                      value={selectedReviewId}
                      placeholder={
                        selectedReviewType === 'Follow-up'
                          ? 'Type the Preliminary Review ID'
                          : selectedReviewType === 'AIAN-CLASS'
                          ? 'Type the Primary Review ID'
                          : 'Type the Review ID'
                      }
                      disabled={props.editMode}
                    />
                  )}
                <Form.Field
                  required
                  error={!!errors.grantee}
                  control={GranteeListDropdown}
                  dropdownLabel="Grantee (Required)"
                  placeholder="Type Grantee Name or ID to filter"
                  value={selectedGranteeIds}
                  selection
                  multiple
                  search
                  onValueSelected={value => {
                    if (!selectedGranteeIds.includes(value[value.length - 1])) {
                      let lastGrantee = granteeFilter.filter(
                        grantee => grantee.granteeId === value[value.length - 1]
                      );
                      if (
                        lastGrantee &&
                        lastGrantee.length === 1 &&
                        !lastGrantee[0].active
                      ) {
                        setTempGranteeIds(value);
                        setShowInActiveStatusWarning(true);
                      } else setSelectedGranteeIds(value);
                    } else setSelectedGranteeIds(value);
                  }}
                  calledFrom={'createReview'}
                  onError={error => {
                    let tempErrors = errors;
                    tempErrors.grantee = error;
                    setErrors(tempErrors);
                  }}
                  clearable
                  disabled={props.editMode}
                  tabIndex={props.editMode ? '-1' : '0'}
                />
              </Form.Group>
            ) : (
              <>
                <Form.Field required>
                  <span>
                    {selectedReviewType === 'Follow-up'
                      ? 'Preliminary Review ID'
                      : selectedReviewType === 'AIAN-CLASS'
                      ? 'Primary Review ID'
                      : 'Review ID'}
                    :
                  </span>
                  <div className="default-value">
                    {selectedReviewId ? selectedReviewId : reviewId}
                  </div>
                </Form.Field>
                <Form.Field required>
                  <span>Grantee:</span>
                  <div className="default-value">
                    {grantInfo &&
                      grantInfo
                        .map(
                          grantee =>
                            `${grantee.granteeName} (${grantee.granteeId})`
                        )
                        .join(', ')}
                  </div>
                </Form.Field>
              </>
            )}
            <Form.Group widths="equal">
              <Form.Field
                id="selectStartDate"
                required
                width={2}
                error={
                  !!errors.startDate && {
                    content: errors.startDate,
                    pointing: 'above',
                  }
                }
                name="startDate"
                label={{
                  children: 'Start Date (Required)',
                  htmlFor: 'selectStartDate',
                }}
                control={DatePicker}
                placeholder="Please select start Date"
                selectsStart
                minDate={AmsDateFormatters.getMoment()}
                selected={selectedStartDate}
                startDate={selectedStartDate}
                onChange={date => {
                  setCurrentFieldData({
                    name: 'reviewStartDate',
                    date,
                    changedTo: AmsDateFormatters.formatCalendarEventDate(
                      date,
                      'MM/DD/YYYY'
                    ),
                    changedFrom: startDate,
                  });
                  if (props.editMode) setOpenChangeHistory(true);
                  // else handleFieldChanges();

                  // setSelectedStartDate(date);
                }}
                popperPlacement="top"
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
                onChangeRaw={e => e.preventDefault()}
                placeholderText={'MM/DD/YYYY'}
              />

              <Form.Field
                required
                width={2}
                error={
                  !!errors.endDate && {
                    content: errors.endDate,
                    pointing: 'above',
                  }
                }
                name="endDate"
                label={{
                  children: 'End Date (Required)',
                  htmlFor: 'selectEndDate',
                }}
                id="selectEndDate"
                control={DatePicker}
                placeholder="Please select end date"
                selected={selectedEndDate}
                selectsEnd
                startDate={selectedStartDate}
                minDate={selectedStartDate}
                endDate={selectedEndDate}
                onChange={date => {
                  setCurrentFieldData({
                    name: 'reviewEndDate',
                    date,
                    changedTo: AmsDateFormatters.formatCalendarEventDate(
                      date,
                      'MM/DD/YYYY'
                    ),
                    changedFrom: endDate,
                  });
                  if (props.editMode) setOpenChangeHistory(true);
                  // else handleFieldChanges();
                  // setSelectedEndDate(date);
                }}
                popperPlacement="top-start"
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
                onChangeRaw={e => e.preventDefault()}
                placeholderText={'MM/DD/YYYY'}
              />
            </Form.Group>
            {isEmpty(currentReviewStatus) ||
            (currentReviewStatus &&
              currentReviewStatus !== 'Review On Hold' &&
              currentReviewStatus !== 'Report On Hold') ? (
              <div style={{ paddingBottom: '10px' }}>
                <span style={{ opacity: !props.editMode ? 0.45 : 1.0 }}>
                  Is this review On Hold, Cancelled or Report Not Issued (If Yes
                  select the appropriate value from the drop-down, if No, no
                  action is required)
                </span>
                <Form.Group>
                  <Form.Field
                    label={{
                      children: 'Review Status',
                      htmlFor: 'reviewStatus',
                      'aria-labelledby': 'reviewStatus',
                    }}
                    name="reviewStatus"
                    // // error={!!errors.reviewStatus}
                    value={
                      currentReviewStatus === 'Review On Hold' ||
                      currentReviewStatus === 'Report Not Issued' ||
                      currentReviewStatus === 'Cancelled' ||
                      currentReviewStatus === 'Report On Hold'
                        ? currentReviewStatus
                        : ''
                    }
                    options={reviewStatusOptions}
                    onChange={(e, data) => {
                      setCurrentFieldData({
                        name: 'reviewStatus',
                        data,
                        changedTo: data.value,
                        changedFrom: reviewStatus,
                      });
                      if (props.editMode) setOpenChangeHistory(true);
                    }}
                    search
                    searchInput={{
                      id: `reviewStatus`,
                      title: 'Select Review Status',
                    }}
                    control={Dropdown}
                    selection
                    upward
                    openOnFocus={false}
                    disabled={!props.editMode}
                    defaultOpen={false}
                    selectOnBlur={false}
                    placeholder="Select Review Status"
                  />
                </Form.Group>
                {!props.editMode && (
                  <>
                    Note: Review status option is only available after review is
                    created
                  </>
                )}
              </div>
            ) : (
              renderOnHoldStatus()
            )}
            <>
              <>
                Is this Unannounced Review (If Yes select the toggle button, if
                No, no action is required)
              </>
              <Form.Group>
                <Form.Field
                  label={{
                    children: 'Unannounced',
                    htmlFor: 'unannounced',
                  }}
                  role="group"
                  toggle
                  control={Checkbox}
                  id={'unannounced'}
                  aria-labelledby={'unannounced'}
                  // error={!!errors.unannounced}
                  checked={currentUnannounced}
                  onClick={(e, data) => {
                    setCurrentFieldData({
                      name: 'unannounced',
                      data,
                      changedTo: data.checked ? 'Unannounced' : 'Announced',
                      changedFrom: unannounced ? 'Unannounced' : 'Announced',
                    });
                    if (props.editMode) setOpenChangeHistory(true);
                  }}
                ></Form.Field>
              </Form.Group>
            </>
          </>
        )}
        {props.editMode &&
          reviewChangeHistory &&
          reviewChangeHistory.length > 0 && <ReviewInfoChangeHistory />}
        <Divider />
        <Form.Group>
          {selectedEndDate &&
            selectedFiscalYear &&
            selectedGranteeIds.length > 0 &&
            selectedReviewType &&
            selectedStartDate && (
              <>
                <Form.Button
                  form="createReviewForm"
                  primary
                  content={'Save and Continue'}
                  onClick={() => {
                    saveReview('continue');
                  }}
                  size="huge"
                />
                {!props.editMode && (
                  <Form.Button
                    form="createReviewForm"
                    primary
                    content={'Save and Exit'}
                    onClick={() => {
                      if (
                        selectedReviewType === 'Special' ||
                        selectedReviewType === 'AIAN-DEF' ||
                        selectedReviewType === 'ERSEA'
                      ) {
                        saveReview('exit');
                      } else {
                        setAlertMessage(
                          'You are about to save and exit without building a team. An email will not be sent to team leads'
                        );
                        setAlertType('warning');
                        setShowExitAndSave(true);
                      }
                    }}
                    size="huge"
                  />
                )}
              </>
            )}
          <Form.Button
            form="createReviewForm"
            content="Exit without saving"
            onClick={e => {
              e.preventDefault();
              if (
                reviewType === 'Special' ||
                reviewType === 'AIAN-DEF' ||
                reviewType === 'ERSEA'
              ) {
                let data = { ...props.previousReviewInfo };
                data['sendEmail'] = resendEmail === true ? true : false;
                if (resendEmail && !sendEmail && reviewId && props.editMode)
                  dispatch(updateReview(data));
              }
              reviewId
                ? props.router.push(`/review/${reviewId}`)
                : props.router.push(`/review`);
            }}
            size="huge"
          />
        </Form.Group>
      </Form>
    );
  };

  return (
    <>
      {showMessageDialog()}
      {showExitAndSaveDialog()}
      {renderOnHoldWarning()}
      {renderInActiveStatusWarning()}
      {renderChangeHistoryForm()}
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted />
      </Dimmer>
      <Header as="h2">Review Information</Header>
      <Divider />
      {reviewInfoForm()}
    </>
  );
};

export default ReviewInfoForm;
