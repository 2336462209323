import React from 'react';
import {
  Header,
  Segment,
  Grid,
  Responsive,
  List,
  Card,
  Checkbox,
  Button,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import FindingsGranteeCard from './FindingsGranteeCard';
import FindingsStatusCard from './FindingsStatusCard';
import FindingsCATCard from './FindingsCATCard';
import FindingsStandardCard from './FindingsStandardCard';
import FindingsDisallowanceCard from './FindingsDisallowanceCard';
import FindingsNarrativeCard from './FindingsNarrativeCard';
import FindingsDataCollectionCard from './FindingsDataCollectionCard';
import FindingsCenterExploration from './FindingsCenterExploration';
import FindingsClassRoomExploration from './FindingsClassRoomExploration';
import FindingsStrongpracticesCard from './FindingsStrongpracticesCard';
import { findingCardClasses, determineFindingStatus } from './FindingsUtils';

const FindingsCitationDetails = props => {
  const { selectedReview } = useSelector(state => state.review);

  const gridContainerStyle = {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginTop: '30px',
  };

  const rowStyle = {
    marginLeft: '-3px',
    marginRight: '-3px',
  };

  const columnStyle = {
    paddingLeft: '15px',
    paddingRight: '15px',
  };

  const finding =
    props?.citation?.collectDataType !== 'strongPractice'
      ? props?.citation?.citation
      : props?.citation?.collectedData[0]?.questionCode;

  const dataCollectType = [
    'ersea',
    'finding',
    'fotp',
    'correctedFindings',
    'unCorrectedFindings',
  ];
  const renderDataCollectionCard = () => {
    if (dataCollectType.includes(props?.citation?.collectDataType)) {
      return (
        <Card
          className={findingCardClasses(
            'findings-card',
            determineFindingStatus(selectedReview, props.citation)
          )}
        >
          <Card.Content
            style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
            header="Collected Data"
          />
          <FindingsCenterExploration
            questionData={props.citation.centerExploration}
            findingId={props.citation.findingId}
            citation={props.citation}
            showGuideName={true}
          />
          <FindingsClassRoomExploration
            questionData={props.citation.classroomExploration}
            findingId={props.citation.findingId}
            citation={props.citation}
            showGuideName={true}
          />
          <FindingsDataCollectionCard
            questionData={props.citation.collectedData}
            findingId={props?.citation?.findingId}
            collectDataType={props?.citation?.collectDataType}
            history={props?.citation?.collectedDataHistory}
            handleSaveButtonAction={props.handleSaveButtonAction}
            citation={props.citation}
          />
        </Card>
      );
    } else if (props?.citation?.collectDataType === 'center') {
      return (
        <Card
          className={findingCardClasses(
            'findings-card-centerExploration',
            props.citation?.findingStatus
          )}
        >
          <Card.Content
            style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
            header="Center Exploration Summary"
          />
          <FindingsCenterExploration
            questionData={props.citation.centerExploration}
            findingId={props.citation.findingId}
            citation={props.citation}
            showGuideName={false}
          />
        </Card>
      );
    } else if (props?.citation?.collectDataType === 'classRoom') {
      return (
        <Card
          className={findingCardClasses(
            'findings-card-centerExploration',
            props.citation?.findingStatus
          )}
        >
          <Card.Content
            style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
            header="Classroom Exploration Summary"
          />
          <FindingsClassRoomExploration
            questionData={props.citation.classroomExploration}
            findingId={props.citation.findingId}
            citation={props.citation}
            showGuideName={false}
          />
        </Card>
      );
    } else if (props?.citation?.collectDataType === 'strongPractice') {
      return (
        <FindingsStrongpracticesCard
          questionData={props.citation.collectedData}
          findingId={props.citation.findingId}
          collectDataType={props?.citation?.collectDataType}
          handleSaveButtonAction={props.handleSaveButtonAction}
          citation={props.citation}
        />
      );
    }
  };

  return (
    <Responsive>
      <Header as="h3">{finding}</Header>
      {/* <Header as="h4">Guide: {guideName}</Header> */}
      <Grid style={gridContainerStyle} columns={2}>
        <Grid.Column width={6}>
          <FindingsGranteeCard
            confirmedGrantees={
              props?.citation?.confirmedGrantees
                ? props.citation.confirmedGrantees
                : []
            }
            findingId={props?.citation?.findingId}
            citation={props.citation}
            handleSaveButtonAction={props.handleSaveButtonAction}
          />
          {props?.citation?.collectDataType !== 'strongPractice' && (
            <FindingsStatusCard
              citation={props.citation}
              handleSaveButtonAction={props.handleSaveButtonAction}
            />
          )}
          {props?.citation?.collectDataType !== 'strongPractice' && (
            <FindingsCATCard
              citation={props.citation}
              handleSaveButtonAction={props.handleSaveButtonAction}
            />
          )}
          {props?.citation?.collectDataType !== 'strongPractice' && (
            <FindingsDisallowanceCard
              citation={props.citation}
              handleSaveButtonAction={props.handleSaveButtonAction}
            />
          )}
        </Grid.Column>
        <Grid.Column width={10}>
          {props?.citation?.collectDataType !== 'strongPractice' && (
            <FindingsStandardCard
              citation={props.citation}
              handleSaveButtonAction={props.handleSaveButtonAction}
            />
          )}
          {renderDataCollectionCard()}
          {props?.citation?.collectDataType !== 'strongPractice' && (
            <FindingsNarrativeCard
              citation={props.citation}
              handleSaveButtonAction={props.handleSaveButtonAction}
            />
          )}
        </Grid.Column>
      </Grid>
    </Responsive>
  );
};

export default FindingsCitationDetails;
