import React from 'react';
import LayoutField from 'react-jsonschema-form-layout-2';

export const formSchema = {
  type: 'object',
  properties: {
    observationTime: {
      title: 'Observation Time',
      type: 'object',
      required: ['observationStartTime', 'observationStopTime'],
      properties: {
        observationStartTime: {
          type: 'object',
          title: 'Start Time',
          properties: {
            starthour: {
              type: 'string',
              title: 'HH',
              enum: [
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
              ],
            },
            startminute: {
              type: 'string',
              title: 'MM',
              enum: [
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30',
                '31',
                '32',
                '33',
                '34',
                '35',
                '36',
                '37',
                '38',
                '39',
                '40',
                '41',
                '42',
                '43',
                '44',
                '45',
                '46',
                '47',
                '48',
                '49',
                '50',
                '51',
                '52',
                '53',
                '54',
                '55',
                '56',
                '57',
                '58',
                '59',
              ],
            },
            startampm: {
              type: 'string',
              title: 'AM/PM',
              enum: ['AM', 'PM'],
            },
          },
        },
        observationStopTime: {
          title: 'End Time',
          type: 'object',
          properties: {
            stophour: {
              type: 'string',
              title: 'HH',
              enum: [
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
              ],
            },
            stopminute: {
              type: 'string',
              title: 'MM',
              enum: [
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30',
                '31',
                '32',
                '33',
                '34',
                '35',
                '36',
                '37',
                '38',
                '39',
                '40',
                '41',
                '42',
                '43',
                '44',
                '45',
                '46',
                '47',
                '48',
                '49',
                '50',
                '51',
                '52',
                '53',
                '54',
                '55',
                '56',
                '57',
                '58',
                '59',
              ],
            },
            stopampm: {
              type: 'string',
              title: 'AM/PM',
              enum: ['AM', 'PM'],
            },
          },
        },
        observationDurationCheck: {
          type: 'boolean',
        },
        observationNotes: {},
      },
      dependencies: {
        observationDurationCheck: {
          oneOf: [
            {
              required: ['observationNotes'],
              properties: {
                observationDurationCheck: {
                  enum: [true],
                },
                observationNotes: {
                  type: 'string',
                  title:
                    'Please explain why you observed for less than 20 minutes and detail exactly how long you observed',
                },
              },
            },
            {
              properties: {
                observationDurationCheck: {
                  enum: [false],
                },
              },
            },
          ],
        },
        observationNotes: ['observationStopTime', 'observationStartTime'],
      },
    },
    sampling: {
      title: 'Sampling',
      type: 'object',
      required: ['kidsEnrolled', 'visitDate'],
      properties: {
        visitDate: {
          type: 'string',
          format: 'date',
          title: 'Visit Date',
        },
        kidsEnrolled: {
          type: 'number',
          title: 'Number of Children Enrolled',
          enum: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
          ],
        },
      },
    },
    backgroundQuestions: {
      title: 'Background Questions',
      type: 'object',
      required: [
        'minimumKidsPresent',
        'predominantLanguage',
        'otherLanguageSpoken',
        'leadTeacherPresent',
        'leadTeacherNew',
      ],
      properties: {
        minimumKidsPresent: {
          type: 'number',
          title: 'Minimum number of children present during observation',
          enum: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
          ],
        },
        predominantLanguage: {
          type: 'string',
          title:
            'What was the predominant language spoken between teachers and students during this observation cycle?',
          enum: ['English', 'Spanish', 'Other'],
        },
        otherPredominantLanguage: {},
        otherLanguageSpoken: {
          type: 'boolean',
          enumNames: ['Yes', 'No'],
          title:
            'Did you hear any languages other than the predominant language being spoken in the classroom?',
        },
        otherLanguage: {},
        childrenSpeakingOtherLanguages: {},
        staffSpeakingOtherLanguages: {},
        leadTeacherPresent: {
          type: 'boolean',
          enumNames: ['Yes', 'No'],
          title: 'Was the Lead Teacher present for this complete observation?',
        },
        leadTeacherNew: {
          type: 'boolean',
          enumNames: ['Yes', 'No'],
          title:
            'Has the Lead Teacher been in the position and completed ten or more consecutive school days?',
        },
      },
      dependencies: {
        predominantLanguage: {
          oneOf: [
            {
              properties: {
                predominantLanguage: { enum: ['English', 'Spanish'] },
              },
            },
            {
              required: ['otherPredominantLanguage'],
              properties: {
                predominantLanguage: { enum: ['Other'] },
                otherPredominantLanguage: {
                  type: 'string',
                  title: 'Please Specify:',
                },
              },
            },
          ],
        },
        otherLanguageSpoken: {
          oneOf: [
            {
              properties: {
                otherLanguageSpoken: { enum: [true] },
                otherLanguage: {
                  type: 'string',
                  title: 'Which languages?',
                },
                childrenSpeakingOtherLanguages: {
                  type: 'number',
                  title:
                    'Approximately how many children spoke the non-predominant languages?',
                  enum: [
                    1,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                    21,
                    22,
                    23,
                    24,
                    25,
                    26,
                    27,
                    28,
                    29,
                    30,
                  ],
                },
                staffSpeakingOtherLanguages: {
                  type: 'number',
                  title:
                    'Approximately how many staff spoke the non-predominant languages?',
                  enum: [
                    0,
                    1,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                  ],
                },
              },
            },
            { properties: { otherLanguageSpoken: { enum: [false] } } },
          ],
        },
      },
    },
    formatActivity: {
      title: 'Format / Activity (Check all that apply)',
      type: 'object',
      properties: {
        activityRoutine: {
          title: 'Routine (e.g., toothbrushing, handwashing, etc.)',
          type: 'boolean',
        },
        activityTransitions: {
          title: 'Transitions (e.g., waking up from nap, lining up, etc.)',
          type: 'boolean',
        },
        activityWholeGroup: { title: 'Whole group', type: 'boolean' },
        activityIndividualTime: {
          title: 'Individual time',
          type: 'boolean',
        },
        activityMealsSnacks: { title: 'Meals/snacks', type: 'boolean' },
        activitySmallGroup: { title: 'Small Group', type: 'boolean' },
        activityFreeChoiceCenters: {
          title: 'Free Choice/Centers',
          type: 'boolean',
        },
      },
    },
    scores: {
      title: 'Scores',
      type: 'object',
      properties: {
        positiveClimate: {
          title: '1. Positive Climate (PC)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        negativeClimate: {
          title: '2. Negative Climate (NC)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        negativeClimateReason: {},
        teacherSensitivity: {
          title: '3. Teacher Sensitivity (TS)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        studentPerspectiveRegard: {
          title: '4. Regard for Student Perspectives (RSP)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        behaviorManagement: {
          title: '5. Behavior Management (BM)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        productivity: {
          title: '6. Productivity (PD)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        instructionalLearningFormats: {
          title: '7. Instructional Learning Formats (ILF)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        conceptDevelopment: {
          title: '8. Concept Development (CD)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        feedbackQuality: {
          title: '9. Quality of Feedback (QF)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
        languageModeling: {
          title: '10. Language Modeling (LM)',
          type: 'number',
          enum: [1, 2, 3, 4, 5, 6, 7],
        },
      },
      dependencies: {
        negativeClimate: {
          oneOf: [
            {
              properties: {
                negativeClimate: { enum: [5, 6, 7] },
                negativeClimateReason: {
                  type: 'string',
                  title:
                    'You have selected a negative climate score of a 5, 6, or 7 for this cycle, indicating an extremely high level of negativity in this class. If this is correct, please notify the CL, and select a reason from the dropdown for your selection.',
                  enum: [
                    'Negative affect (irritability, anger, harsh voice)',
                    'Peer Aggression',
                    'Punitive control (yelling, threats, physical control, harsh punishment)',
                    'Sarcasm/disrespect (sarcastic voice, teasing, humiliation)',
                    'Sever negativity (victimization, bullying, physical punishment)',
                  ],
                },
              },
            },
            { properties: { negativeClimate: { enum: [1, 2, 3, 4] } } },
          ],
        },
      },
    },
  },
};

const CustomDescriptionField = ({ id, description }) => {
  if (Array.isArray(description)) {
    return (
      <div id={id}>
        <ul className="array-descripton-list">
          {description.map((item, key) => (
            <li key={key}>{item}</li>
          ))}
        </ul>
      </div>
    );
  }
  return <div id={id}>{description}</div>;
};

export const fields = {
  layout: LayoutField,
  DescriptionField: CustomDescriptionField,
};

export const uiSchema = {
  observationTime: {
    'ui:field': 'layout',
    'ui:layout': [
      {
        observationStartTime: {
          md: 6,
        },
        observationStopTime: {
          md: 6,
        },
      },
      {
        observationNotes: { md: 12 },
      },
    ],
    observationNotes: {
      'ui:widget': 'textarea',
    },
    observationDurationCheck: { 'ui:widget': 'hidden' },
    observationStartTime: {
      starthour: { classNames: 'col-md-2 observation-time' },
      startminute: { classNames: 'col-md-2 observation-time' },
      startampm: { classNames: 'col-md-3 observation-time' },
    },
    observationStopTime: {
      stophour: { classNames: 'col-md-2 observation-time' },
      stopminute: { classNames: 'col-md-2 observation-time' },
      stopampm: { classNames: 'col-md-3 observation-time' },
    },
  },
  sampling: {
    'ui:field': 'layout',
    'ui:layout': [{ visitDate: { md: 6 }, kidsEnrolled: { md: 6 } }],
    visitDate: { 'ui:widget': 'date' },
    kidsEnrolled: { 'ui:widget': 'select' },
  },
  backgroundQuestions: {
    classNames: 'background-questions',
    'ui:field': 'layout',
    'ui:layout': [
      { minimumKidsPresent: { md: 6 } },
      {
        predominantLanguage: { md: 6 },
        otherPredominantLanguage: { md: 6 },
      },
      { otherLanguageSpoken: { md: 6 } },
      {
        otherLanguage: { md: 4 },
        childrenSpeakingOtherLanguages: { md: 4 },
        staffSpeakingOtherLanguages: { md: 4 },
      },
      { leadTeacherPresent: { md: 6 }, leadTeacherNew: { md: 6 } },
    ],
    minimumKidsPresent: { 'ui:widget': 'select' },
    predominantLanguage: { 'ui:widget': 'select' },
    otherLanguageSpoken: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
    },
    childrenSpeakingOtherLanguages: { 'ui:widget': 'select' },
    staffSpeakingOtherLanguages: { 'ui:widget': 'select' },
    leadTeacherPresent: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
    },
    leadTeacherNew: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:help':
        'Note: The Lead Teacher may be a substitute Lead Teacher or a permanent Lead Teacher.',
    },
  },
  formatActivity: {
    'ui:field': 'layout',
    'ui:layout': [
      {
        activityRoutine: { md: 4 },
        activityTransitions: { md: 4 },
        activityWholeGroup: { md: 4 },
      },
      {
        activityIndividualTime: { md: 4 },
        activityMealsSnacks: { md: 4 },
        activitySmallGroup: { md: 4 },
      },
      { activityFreeChoiceCenters: { md: 4 } },
    ],
  },
  scores: {
    classNames: 'scores-questions',
    'ui:field': 'layout',
    'ui:layout': [
      { positiveClimate: { md: 6 }, negativeClimate: { md: 6 } },
      { negativeClimateReason: { md: 8 } },
      {
        teacherSensitivity: { md: 6 },
        studentPerspectiveRegard: { md: 6 },
      },
      { behaviorManagement: { md: 6 }, productivity: { md: 6 } },
      {
        instructionalLearningFormats: { md: 6 },
        conceptDevelopment: { md: 6 },
      },
      { feedbackQuality: { md: 6 }, languageModeling: { md: 6 } },
    ],
    positiveClimate: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Relationships',
        'Positive Affect',
        'Positive Communications',
        'Respect',
      ],
    },
    negativeClimate: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Negative Affect',
        'Punitive control',
        'Sarcasm/Disrespect',
        'Severe Negativity',
      ],
    },
    negativeClimateReason: { 'ui:widget': 'select' },
    teacherSensitivity: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Awareness',
        'Responsiveness',
        'Addresses Problems',
        'Student Comfort',
      ],
    },
    studentPerspectiveRegard: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Flexibility and Student Focus',
        'Support for Autonomy and Leadership',
        'Student Expression',
        'Restriction of Movement',
      ],
    },
    behaviorManagement: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Clear Behavior Expectations',
        'Proactive',
        'Redirection of Misbehavior',
        'Student Behavior',
      ],
    },
    productivity: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Maximizing Learning Time',
        'Routines',
        'Transitions',
        'Preparations',
      ],
    },
    instructionalLearningFormats: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Effective Facilitation',
        'Variety of Modalities and Materials',
        'Student Interest',
        'Clarity of Learning Objectives',
      ],
    },
    conceptDevelopment: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Analysis and Reasoning',
        'Creating',
        'Integration',
        'Connections to the Real World',
      ],
    },
    languageModeling: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Frequent Conversation',
        'Open-Ended Question',
        'Repetition and Extension',
        'Self and Parallel Talk',
        'Advance Language',
      ],
    },
    feedbackQuality: {
      'ui:widget': 'radio',
      'ui:options': { inline: true },
      'ui:description': [
        'Scaffolding',
        'Feedback Loops',
        'Prompting Thought Processes',
        'Providing Information',
        'Encouragement and Affirmation',
      ],
    },
  },
};
