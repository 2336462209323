import _ from 'lodash';
import React, { Component } from 'react';
import { Well } from 'react-bootstrap';
import {
  BootstrapTable as BootstrapTableOld,
  TableHeaderColumn,
} from 'react-bootstrap-table';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import {
  Button,
  Comment,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Form as SemanticForm,
  Header,
  Icon,
  List,
  Loader,
  Message,
  Modal,
  Segment,
  TextArea,
} from 'semantic-ui-react';

// Import utils.
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsModal from '../../utils/AmsModal';
import RegionalUsersPreloadDropdown from '../../utils/RegionalUsersPreloadDropdown';

//util
import { differenceInObjects } from '../Shared/FormHelpers/helpers/utils.js';

// Import actions.
import {
  fetchFindingsDetail,
  updatePlanFollowUpReviewTeam,
} from '../../actions/reviewActions';

class FollowUpFormContainer extends Component {
  state = {
    errors: {},
    data: {},
    dataHasChanged: false,
    showAlert: false,
    expanding: [],
    showReasonModal: false,
    showCitationModal: false,
    reasonForRequest: [],
    reasonForRequestOther: '',
    loading: false,
  };

  componentWillReceiveProps(nextProps) {
    const { findings } = nextProps;
    if (findings && findings.legacy) {
      this.props.router.push(
        `/review/${findings.reviewId}/follow-up-recommendations-legacy`
      );
      return;
    }
    const diff = differenceInObjects(this.props, nextProps);

    if (_.isEmpty(diff)) {
      return;
    }
    this.setState({
      data: _.cloneDeep(findings),
      dataHasChanged: false,
    });
  }

  showAlert = (showAlert, data) => {
    this.setState({
      data: data,
      showAlert,
    });
  };

  userStructure = (oids, userType, row) => {
    const { regionalUsers } = this.props;
    const team =
      oids &&
      oids.map(oid => {
        let t = {};

        if (regionalUsers) t = _.find(regionalUsers, { oid });

        if (_.isEmpty(t)) {
          t = _.find(row.programSpecialist, { oid });
        }

        return t;
      });

    return team;
  };

  handleProgramSpecialistSelect = (ev, data) => {
    const { index } = data;

    const errors = { ...this.state.errors };

    if (index === -1) return;

    if (!_.isEmpty(errors[index])) {
      errors[index].programSpecialist = '';
      this.setState({ errors: errors });
    }

    const { followUpReviews } = this.state.data;
    const row = followUpReviews[data.index];

    if (row && row.hasOwnProperty(data.type)) {
      row[data.type] = this.userStructure(data.value, 'programSpecialist', row);
    }

    this.setState({
      dataHasChanged: true,
      data: { ...this.state.data, followUpReviews },
    });
  };

  handleReviewTypeSelect = (ev, data) => {
    const { index } = data;

    const errors = { ...this.state.errors };

    if (index === -1) return;

    if (!_.isEmpty(errors[index])) {
      errors[index].reviewType = '';
      this.setState({ errors: errors });
    }

    this.handleChange(ev, data);
  };

  handleChange = (ev, data) => {
    const { index } = data;

    if (index === -1) return;

    const { followUpReviews } = this.state.data;
    const row = followUpReviews[data.index];

    if (row && row.hasOwnProperty(data.type) && row[data.type] !== data.value) {
      if (data.value && data.type === 'reviewerRequired') {
        if (data.value)
          this.setState({ showReasonModal: true, selectedRowData: data });
        else {
          this.setState({ showReasonModal: false, selectedRowData: data });
          row['reviewerRequestReasons'] = '';
          row['otherReason'] = '';
        }
      } else if (!data.value && data.type === 'reviewerRequired') {
        this.setState({ showReasonModal: false, selectedRowData: data });
        row['reviewerRequestReasons'] = '';
        row['otherReason'] = '';
        row['reviewerRequestAccepted'] = null;
      }
      row[data.type] = data.value;
    }

    this.setState({
      dataHasChanged: true,
      data: { ...this.state.data, followUpReviews },
    });
  };

  formatProgramSpecialistDropdown = (cell, row) => {
    const { followUpReviews } = this.state.data;
    const index = followUpReviews.indexOf(row);
    const hasError =
      this.state.errors[index] && !!this.state.errors[index].programSpecialist;
    const { currentUser, findings } = this.props;

    return currentUser.roles.includes('OHSStaff') &&
      currentUser.roles.length === 1 ? (
      <>
        {followUpReviews[index].programSpecialist
          .map(user => user.fullName)
          .join(',')}
      </>
    ) : (
      <AmsFormLabel
        table
        hidden
        name={`Select Program Specialist for ${row.reviewId}`}
        fieldLabel={`programSpecialistDropdown${index}`}
      >
        <RegionalUsersPreloadDropdown
          className="table-extend-dropdown"
          error={hasError}
          aria-labelledby={`programSpecialistDropdown${index}`}
          searchInput={{
            id: `programSpecialistDropdown${index}`,
          }}
          name="programSpecialists"
          roleName="programSpecialist"
          type="programSpecialist"
          index={index}
          hasSource={index !== 0}
          value={followUpReviews[index].programSpecialist.map(e => e.oid)}
          //value={}
          onChange={this.handleProgramSpecialistSelect}
          placeholder="Select Program Specialist"
          multiple
          selection
          search
          reviewId={findings && findings.reviewId}
        />
      </AmsFormLabel>
    );
  };

  formatReviewName = (cell, row) => {
    return (
      <>
        <span>
          {cell}
          {row.reviewId ? (
            <>
              {' '}
              - <Link to={`/review/${row.reviewId}`}>{row.reviewId}</Link>
            </>
          ) : null}
        </span>
        <br></br>
        <span disp>
          {row.citationList && row.citationList.length > 0 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  showCitationModal: true,
                  citationModalData: row,
                });
              }}
            >
              (View Citations)
            </a>
          )}
        </span>
      </>
    );
  };

  formatDate = (cell, row) => {
    return !_.isEmpty(cell) ? AmsDateFormatters.formatDate(cell) : '';
  };

  formatRequiredDropdown = (cell, row) => {
    const index = this.state.data.followUpReviews.indexOf(row);
    if (row.reviewerRequestAccepted || row.reviewerRequestAccepted === false)
      return <>{row.reviewerRequired ? 'Yes' : 'No'}</>;
    else
      return (
        <Dropdown
          title="Reviewer Required"
          value={cell}
          fluid
          selection
          index={index}
          type="reviewerRequired"
          onChange={(ev, data) => this.handleChange(ev, data)}
          options={[
            { value: true, key: true, text: 'Yes' },
            { value: false, key: false, text: 'No' },
          ]}
        />
      );
  };

  formatReviewTypeDropdown = (cell, row) => {
    const index = this.state.data.followUpReviews.indexOf(row);
    const hasError =
      this.state.errors[index] && !!this.state.errors[index].reviewType;
    const { currentUser } = this.props;

    return currentUser.roles.includes('OHSStaff') &&
      currentUser.roles.length === 1 ? (
      <>{cell}</>
    ) : (
      <AmsFormLabel
        table
        hidden
        name={`Select recommended review type for ${row.reviewId}`}
        fieldLabel={`recommendedReviewTypeDropdown${index}`}
      >
        <Dropdown
          value={cell}
          fluid
          aria-labelledby={`recommendedReviewTypeDropdown${index}`}
          upward
          error={hasError}
          selection
          index={index}
          type="reviewType"
          onChange={this.handleReviewTypeSelect}
          options={[
            { value: 'Onsite', key: 'Onsite', text: 'Onsite' },
            { value: 'Desk', key: 'Desk', text: 'Desk' },
          ]}
          openOnFocus={false}
          defaultOpen={false}
          selectOnBlur={false}
          search
          searchInput={{
            id: `recommendedReviewTypeDropdown${index}`,
          }}
          placeholder={'Select Review Type'}
        />
      </AmsFormLabel>
    );
  };

  reviewerRequiredReasonModal = () => {
    const { selectedRowData, data, acceptDeclineFlag } = this.state;
    const { followUpReviews } = data;
    return (
      <AmsModal
        size="small"
        centered={false}
        closeOnDimmerClick={false}
        onClose={this.closeModal}
        open={this.state.showReasonModal}
        className="ams-semantic-modal-fix"
      >
        <Header
          content={!acceptDeclineFlag ? 'Select Reasons' : 'Selected Reasons'}
        />
        <Modal.Content>
          <Modal.Description>
            {this.reviewerRequiredReasonModalForm()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {!acceptDeclineFlag && (
            <Button
              onClick={() => {
                followUpReviews[selectedRowData.index] = {
                  ...followUpReviews[selectedRowData.index],
                  reviewerRequired: true,
                };

                this.setState({
                  data: { ...this.state.data, followUpReviews },
                  showReasonModal: false,
                });
              }}
              content="Add Reason(s)"
              primary
            />
          )}
          {!acceptDeclineFlag && (
            <Button onClick={this.closeModal} content="Cancel" />
          )}
          {acceptDeclineFlag && (
            <Button
              disabled={
                selectedRowData.reviewerRequestAccepted === true ? true : false
              }
              onClick={() => {
                followUpReviews[selectedRowData.index] = {
                  ...followUpReviews[selectedRowData.index],
                  reviewerRequestAccepted: true,
                };

                this.setState({
                  data: { ...this.state.data, followUpReviews },
                  showReasonModal: false,
                  acceptDeclineFlag: false,
                  dataHasChanged: true,
                });
              }}
              content={
                selectedRowData.reviewerRequestAccepted === true
                  ? 'Accepted'
                  : 'Accept'
              }
              primary
            />
          )}
          {acceptDeclineFlag && (
            <Button
              disabled={
                selectedRowData.reviewerRequestAccepted === true
                  ? false
                  : selectedRowData.reviewerRequestAccepted === false
                  ? true
                  : false
              }
              onClick={() => {
                followUpReviews[selectedRowData.index] = {
                  ...followUpReviews[selectedRowData.index],
                  reviewerRequestAccepted: false,
                };

                this.setState({
                  data: { ...this.state.data, followUpReviews },
                  showReasonModal: false,
                  acceptDeclineFlag: false,
                  dataHasChanged: true,
                });
              }}
              content={
                selectedRowData.reviewerRequestAccepted === false
                  ? 'Declined'
                  : 'Decline'
              }
              negative
            />
          )}
          {acceptDeclineFlag && (
            <Button onClick={this.closeModal} content="Cancel" />
          )}
        </Modal.Actions>
      </AmsModal>
    );
  };

  citationInfoModal = () => {
    const citations =
      this.state.citationModalData && this.state.citationModalData.citationList;

    const info =
      citations &&
      // eslint-disable-next-line array-callback-return
      citations.map(e => {
        for (let i = 0; i < this.state.data.findings.length; i++) {
          let finding = this.state.data.findings[i];
          if (finding.citationNumbers.includes(e)) {
            return {
              name: e,
              findingType: finding.findingType,
              startDate: this.formatDate(finding.tfcStartDate),
              endDate: this.formatDate(finding.tfcEndDate),
            };
          }
        }
      });

    return (
      <AmsModal
        size="small"
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          this.setState({ showCitationModal: false, citationModalData: null });
        }}
        open={this.state.showCitationModal}
        className="ams-semantic-modal-fix"
      >
        <Header content={'Citation Info'} />
        <Modal.Content>
          <Modal.Description>
            <List divided relaxed>
              {info &&
                info.map(e => {
                  if (!e) {
                    return null;
                  }
                  return (
                    <List.Item>
                      <List.Content>
                        <List.Header>{e.name}</List.Header>
                        <strong> Finding Type:</strong> {e.findingType}
                        <br></br>
                        <strong>Start Date:</strong> {e.startDate}
                        <br></br>
                        <strong>End Date:</strong> {e.endDate}
                      </List.Content>
                    </List.Item>
                  );
                })}
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({
                showCitationModal: false,
                citationModalData: null,
              });
            }}
            content="Cancel"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  closeModal = () => {
    const { selectedRowData } = this.state;
    const { index } = selectedRowData;

    if (index === -1) return;

    const { followUpReviews } = this.state.data;
    const row = followUpReviews[selectedRowData.index];
    if (row && row.hasOwnProperty(selectedRowData.type)) {
      if (
        selectedRowData.value &&
        selectedRowData.type === 'reviewerRequired'
      ) {
        this.setState({ selectedRowData: {} });
      }
      row[selectedRowData.type] = false;
      row['reviewerRequestReasons'] = [];
      row['otherReason'] = '';
    }

    this.setState({
      dataHasChanged: true,
      data: { ...this.state.data, followUpReviews },
      showReasonModal: false,
      reasonForRequest: [],
      reasonForRequestOther: '',
      acceptDeclineFlag: false,
    });
  };

  reviewerRequiredReasonModalForm = () => {
    const { selectedRowData, data, acceptDeclineFlag } = this.state;
    const { followUpReviews } = data;
    return (
      <>
        <Form
          noValidate
          onSubmit={e => {
            console.log('submit');
          }}
        >
          <Form.Field
            label="Select one or many reasons the Regional Office is requesting Additional onsite support"
            placeholder="Select one or many reasons the Regional Office is requesting Additional onsite support"
            style={{
              pointerEvents: acceptDeclineFlag ? 'none' : 'all',
            }}
            value={
              selectedRowData &&
              (selectedRowData.index || selectedRowData.index === 0) &&
              followUpReviews[selectedRowData.index] &&
              followUpReviews[selectedRowData.index].reviewerRequestReasons &&
              followUpReviews[selectedRowData.index].reviewerRequestReasons
            }
            search
            selection
            multiple
            name={'reviewerRequestReasons'}
            category={'reviewerRequestReasons'}
            control={AmsLookupDropdown}
            onChange={(e, { value, name }) => {
              followUpReviews[selectedRowData.index] = {
                ...followUpReviews[selectedRowData.index],
                [name]: value,
              };

              this.setState({
                data: { ...this.state.data, followUpReviews },
              });
            }}
          />
          {selectedRowData &&
            (selectedRowData.index || selectedRowData.index === 0) &&
            followUpReviews[selectedRowData.index] &&
            !_.isEmpty(
              followUpReviews[selectedRowData.index].reviewerRequestReasons
            ) &&
            followUpReviews[
              selectedRowData.index
            ].reviewerRequestReasons.includes('Other') && (
              <Form.Field
                label="Other, please explain"
                placeholder="please explain the reason(s)"
                style={{
                  pointerEvents: acceptDeclineFlag ? 'none' : 'all',
                }}
                value={
                  selectedRowData &&
                  (selectedRowData.index || selectedRowData.index === 0) &&
                  followUpReviews[selectedRowData.index] &&
                  followUpReviews[selectedRowData.index].otherReason &&
                  followUpReviews[selectedRowData.index].otherReason
                }
                control={TextArea}
                name="otherReason"
                rows={5}
                onChange={(e, { value, name }) => {
                  followUpReviews[selectedRowData.index] = {
                    ...followUpReviews[selectedRowData.index],
                    [name]: value,
                  };

                  this.setState({
                    data: { ...this.state.data, followUpReviews },
                  });
                }}
              />
            )}
        </Form>
      </>
    );
  };

  generateFollowUpReviewsTable = () => {
    const { data } = this.state;

    return data ? (
      <BootstrapTableOld
        trClassName="clickable"
        remote
        bodyContainerClass="no-table-overflow"
        data={data.followUpReviews}
        tableContainerClass="table-container"
        headerContainerClass="table-header"
        hover
        condensed
        bordered={false}
      >
        <TableHeaderColumn
          hidden={true}
          isKey={true}
          dataField="reviewId"
          dataSort={true}
        >
          {' '}
          Review Id
        </TableHeaderColumn>
        <TableHeaderColumn
          width="15%"
          dataField="reviewName"
          dataFormat={this.formatReviewName}
          dataSort={true}
        >
          {' '}
          Follow-up Review
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="startDate"
          dataFormat={this.formatDate}
          dataSort={true}
        >
          Start Date
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="endDate"
          dataFormat={this.formatDate}
          dataSort={true}
        >
          End Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="reviewType"
          dataSort={true}
          columnClassName="always-visible"
          dataFormat={this.formatReviewTypeDropdown}
        >
          Recommended Review Type
        </TableHeaderColumn>
        <TableHeaderColumn
          width="20%"
          dataField="programSpecialist"
          columnClassName="always-visible"
          dataFormat={this.formatProgramSpecialistDropdown}
          dataSort={true}
        >
          Program Specialist
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="followUpLead"
          dataFormat={(cell, row) => cell.map(e => e.fullName).join(', ')}
          dataSort={true}
        >
          Assigned FUL
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="reviewerRequired"
          columnClassName="always-visible"
          dataFormat={this.formatRequiredDropdown}
          dataSort={true}
        >
          Reviewer Required
        </TableHeaderColumn>
      </BootstrapTableOld>
    ) : null;
  };

  formatRequestAcceptDecline = (cell, row) => {
    const { currentUser } = this.props;
    const index = this.state.data.followUpReviews.indexOf(row);
    return row &&
      row.reviewerRequired &&
      row.reviewerRequestReasons &&
      row.reviewerRequestReasons.length > 0 &&
      !_.has(row, 'reviewerRequestAccepted') &&
      currentUser &&
      (currentUser.roles.includes('AmsAdmi') ||
        currentUser.roles.includes('OHSStaff') ||
        currentUser.roles.includes('Analyst') ||
        currentUser.roles.includes('TechnicalWriter') ||
        currentUser.roles.includes('Editor') ||
        currentUser.roles.includes('FieldOperationsManager')) ? (
      <Link
        onClick={e =>
          this.setState({
            showReasonModal: true,
            selectedRowData: { ...row, index },
            acceptDeclineFlag: true,
          })
        }
      >
        {row.reviewerRequestAccepted
          ? 'Accepted'
          : row.reviewerRequestAccepted === false
          ? 'Declined'
          : 'Accept/Decline'}
      </Link>
    ) : (
      <>
        {row && row.reviewerRequestAccepted
          ? 'Accepted'
          : row && row.reviewerRequestAccepted === false
          ? 'Declined'
          : 'N/A'}
      </>
    );
  };

  generateFollowUpReviewsTableNext = () => {
    const { data } = this.state;

    const columns = [
      {
        dataField: 'reviewName',
        text: 'Follow-up Review',
        formatter: this.formatReviewName,
        width: '30%',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'startDate',
        text: 'Start Date',
        formatter: this.formatDate,
        width: '8%',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'endDate',
        text: 'End Date',
        formatter: this.formatDate,
        width: '8%',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'reviewType',
        text: 'Recommended Review Type',
        formatter: this.formatReviewTypeDropdown,
        classes: 'always-visible',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'programSpecialist',
        text: 'Program Specialist',
        formatter: this.formatProgramSpecialistDropdown,
        classes: 'always-visible',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'followUpLead',
        text: 'Assigned FUL',
        width: '15%',
        formatter: (cell, row) => cell.map(e => e.fullName).join(', '),
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'reviewerRequired',
        text: 'Reviewer Required',
        width: '15%',
        formatter: this.formatRequiredDropdown,
        classes: 'always-visible',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'acceptDecline',
        isDummyField: true,
        text: 'Accept/Decline',
        formatter: this.formatRequestAcceptDecline,
        headerAttrs: { scope: 'col', tabIndex: -1 },
        // align: 'right',
      },
    ];

    return (
      data &&
      data.followUpReviews && (
        <BootstrapTable
          keyField="reviewId"
          // rowClasses={rowClasses}
          data={data.followUpReviews}
          columns={columns}
          bordered={false}
        />
      )
    );
  };

  validate = data => {
    const errors = {};

    data.forEach((e, index) => {
      if (_.isEmpty(e.reviewType)) {
        errors[index] = {};
        errors[index].reviewType = 'Please select a Review Type';
      }
      if (_.isEmpty(e.programSpecialist)) {
        errors[index] = errors[index] || {};
        errors[index].programSpecialist = 'Please select a Program Specialist';
      }
    });

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data.followUpReviews);
    this.setState({ errors: errors });
    if (_.isEmpty(errors)) {
      this.setState({ showAlert: true });
    } else {
      this.props.setErrors(
        'Please select Program Specialist and Review Type for all Followup Reviews'
      );
    }
  };

  resetState = () => {
    this.props.clearErrors();
    this.rpmComment.ref.current.value = '';

    this.setState({
      data: _.cloneDeep(this.props.findings),
      dataHasChanged: false,
      showAlert: false,
    });
  };

  showConfirmationAlert = () => {
    const { currentUser } = this.props;
    return (
      <AmsAlert
        show={this.state.showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmCancelText="No"
        confirmButtonText="Yes"
        confirmButtonColor={'#DD6B55'}
        text={
          currentUser.roles.includes('OHSStaff') &&
          currentUser.roles.length === 1
            ? `Are you sure you are done with the reviewer requests? You are about to save changes you have made to the reviewer request for the Follow-up reviews`
            : `Are you sure you are done planning your Follow-up Review? This will result in the creation of review(s) based on your recommendations and assign selected team members.`
        }
        onConfirm={() => {
          this.updateFollowUpReviewsData();
          this.setState({ showAlert: false });
        }}
        onCancel={() => {
          this.props.clearErrors();
          this.setState({ showAlert: false });
        }}
      />
    );
  };

  showUpdateAlert = () => {
    const { currentUser } = this.props;
    return (
      <AmsAlert
        show={this.state.showUpdateAlert}
        title=""
        type="success"
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={
          currentUser.roles.includes('OHSStaff') &&
          currentUser.roles.length === 1
            ? `Reviewer request feedbacks have been sucessfully submitted`
            : `The Follow-up review has been successfully updated`
        }
        onConfirm={() => {
          this.setState({ showUpdateAlert: false });
        }}
      />
    );
  };

  updateFollowUpReviewsData = () => {
    this.props.clearErrors();

    let { updatePlanFollowUpReviewTeam, reloadData, setErrors } = this.props;
    let { data } = this.state;
    let { value } = this.rpmComment.ref.current;
    this.setState({ loading: true });

    if (value !== '') {
      const commentObject =
        value === ''
          ? {}
          : {
              note: value,
              submittedBy: _.pick(this.props.currentUser, [
                'oid',
                'userName',
                'fullName',
              ]),
              timestamp: AmsDateFormatters.getMoment().utc(),
            };
      data.rpmNotes.push(commentObject);
      this.rpmComment.ref.current.value = '';
    }

    updatePlanFollowUpReviewTeam(data)
      .then(result => {
        if (result.status === 200) {
          this.setState({ loading: false, showUpdateAlert: true });
          reloadData();
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        const { message } = error.response.data;
        setErrors(message || 'This request could not be processed.');
      });
  };

  generateRPMNotes = () => {
    const { data } = this.state;

    return !_.isEmpty(data) && data.rpmNotes && !_.isEmpty(data.rpmNotes) ? (
      <Comment.Group>
        {data.rpmNotes.map((e, index) => (
          <Comment key={index}>
            <Comment.Content>
              <Comment.Author>
                {e.submittedBy && e.submittedBy.fullName}
              </Comment.Author>
              <Comment.Metadata>
                <div style={{ color: '#777666' }}>
                  {' '}
                  {e.timestamp
                    ? AmsDateFormatters.formatDateTime(e.timestamp)
                    : ''}
                </div>
              </Comment.Metadata>
              <Comment.Text>{e.note}</Comment.Text>
            </Comment.Content>
          </Comment>
        ))}
      </Comment.Group>
    ) : null;
  };

  render() {
    return (
      <Segment basic>
        <Dimmer active={this.state.loading} inverted>
          <Loader active={this.state.loading} inverted>
            Loading
          </Loader>
        </Dimmer>
        {this.showConfirmationAlert()}
        {this.reviewerRequiredReasonModal()}
        {this.citationInfoModal()}
        {this.showUpdateAlert()}
        <Form onSubmit={e => this.onSubmit(e)}>
          <Header as="h2">Plan Follow-up Review Team</Header>
          <Message info>
            <Icon name="info" />
            You have to submit in order for the changes you make to be saved.
            You can find the submit button at the bottom of the page.
          </Message>
          {this.generateFollowUpReviewsTableNext()}
          <Well style={{ marginTop: '10px' }}>
            <p style={{ textAlign: 'center' }}>
              Please note that all fields are required
            </p>
          </Well>
          <Header as="h2">RPM Notes</Header>

          {this.generateRPMNotes()}
          <TextArea
            title="Add a note"
            ref={node => (this.rpmComment = node)}
            placeholder=""
            onChange={() => {
              if (!this.state.dataHasChanged)
                this.setState({ dataHasChanged: true });
            }}
          />
          <Divider></Divider>
          <SemanticForm.Group>
            <SemanticForm.Button
              type="submit"
              disabled={!this.state.dataHasChanged}
              primary
              content={'Submit'}
            />
            <SemanticForm.Button
              onClick={e => this.resetState()}
              disabled={!this.state.dataHasChanged}
              type="cancel"
              content={'Cancel'}
            />
          </SemanticForm.Group>
        </Form>
      </Segment>
    );
  }
}

FollowUpFormContainer.propTypes = {};

const mapStateToProps = state => ({
  findings: state.review.fetchedFindingDetails,
  currentUser: state.auth.user,
  usersFilter: state.usersFilter,
  regionalUsers: state.users.regionalUsers,
});

export default connect(mapStateToProps, {
  fetchFindingsDetail,
  updatePlanFollowUpReviewTeam,
})(withRouter(FollowUpFormContainer));
