import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';

// Import components.
import ReviewGranteeInfo from './ReviewGranteeInfo';
import ReviewLegacyInfo from './ReviewLegacyInfo';
// import ReviewManifestFile from './ReviewManifestFile';
import ReviewDates from './ReviewDates';
import ReviewStatus from './ReviewStatus';
import ReviewReportTemplate from './ReviewReportTemplate';
import ReviewSubmitToFOMButton from './ReviewSubmitToFOMButton';
import ReviewShippedDetails from './ReviewShippedDetails';

const ReviewInfo = props => {
  switch (props.type) {
    case 'legacy':
      return (
        <Grid stackable>
          <Grid.Row stretched>
            <Grid.Column>
              <Segment basic>
                <ReviewGranteeInfo />
                <ReviewDates />
                <ReviewLegacyInfo />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    case 'fy19':
      return (
        <Grid columns={2} stackable>
          <Grid.Row stretched>
            <Grid.Column>
              <Segment basic>
                <ReviewGranteeInfo />
                <ReviewDates />
                {/* <ReviewManifestFile /> */}
                <ReviewReportTemplate />
                <ReviewShippedDetails />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment basic>
                <ReviewStatus />
              </Segment>
              <Segment basic>
                <ReviewSubmitToFOMButton />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    default:
      return null;
  }
};

export default ReviewInfo;
