import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, List, Icon } from 'semantic-ui-react';
import { fetchAmsLookup } from '../actions/lookupActions';
import AmsFormLabel from './AmsFormLabel';
import { chunk, filter } from 'lodash';

const AmsButtonSelectorList = ({
  category,
  content,
  id,
  itemsPerRow,
  value,
  items,
  handleSelection,
  chunked = false,
  tabIndex,
}) => {
  const dispatch = useDispatch();
  const { amsLookups } = useSelector(state => state.lookups);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const fetchLookup = () => {
      dispatch(fetchAmsLookup(category));
    };
    if (category) fetchLookup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    const setItemOptions = () => {
      const activeItems = filter(amsLookups[category], {
        parent: false,
        active: true,
      });
      const types = activeItems.map(t => ({
        key: t._id,
        text: t.value.label,
        value: t.value.value,
      }));
      setCurrentItems(types);
    };
    if (category) setItemOptions();
    else setCurrentItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amsLookups[category]]);

  const checkMarkButton = item => (
    <Form.Button
      color={value === item.value ? 'blue' : null}
      basic
      onClick={e => handleSelection(e, item)}
      size="medium"
      type="button"
      icon
      labelPosition="left"
      tabIndex={tabIndex}
    >
      {item.text}

      <Icon
        name={value === item.value ? 'check circle' : 'circle outline'}
        size="large"
        color={value === item.value ? 'green' : null}
      ></Icon>
    </Form.Button>
  );

  return (
    <>
      <AmsFormLabel name={content} fieldLabel={id}>
        <div role="group" id={id} aria-labelledby={id}>
          {chunked ? (
            <List>
              {chunk(currentItems, itemsPerRow).map((chunkedItems, rindex) => {
                return (
                  <List.Item key={rindex}>
                    <List horizontal size={'medium'}>
                      <>
                        {chunkedItems.map((item, index) => {
                          return (
                            <List.Item key={index}>
                              {checkMarkButton(item)}
                            </List.Item>
                          );
                        })}
                      </>
                    </List>
                  </List.Item>
                );
              })}
            </List>
          ) : (
            <List horizontal>
              {currentItems.map((item, index) => {
                return (
                  <List.Item key={index}>{checkMarkButton(item)}</List.Item>
                );
              })}
            </List>
          )}
        </div>
      </AmsFormLabel>
    </>
  );
};

export default AmsButtonSelectorList;
