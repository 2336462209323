import React from 'react';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ReviewStatus = ({ selectedReview, surveySubmissions }) => (
  <>
    <div style={{}} className="text-right">
      {selectedReview.unannounced && (
        <Label size="large" circular color="orange">
          Unannounced
        </Label>
      )}
    </div>
    <div className="surveys-status text-right">
      {selectedReview.finalized && (
        <div className="surveys-status">
          <div className="survey-status-final-message">
            This review is ready for report production
          </div>
        </div>
      )}
      {(selectedReview &&
        ['FA2-CR', 'FA1-FR'].includes(selectedReview?.reviewType)) ||
      (['RAN', 'Special'].includes(selectedReview?.reviewType) &&
        selectedReview?.isNewForms) ? (
        <div className="survey-status-count">
          {`${surveySubmissions.totalGuideSubmittedCount ||
            0} out of ${surveySubmissions.totalGuideCount || 0}`}
        </div>
      ) : (
        <div className="survey-status-count">
          {`${selectedReview.totalGuideSubmittedCount ||
            0} out of ${selectedReview.totalGuideCount || 0}`}
        </div>
      )}
      {selectedReview && (
        <div className="survey-status-message">Guides have been completed</div>
      )}
    </div>
  </>
);

ReviewStatus.propTypes = {
  selectedReview: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  surveySubmissions: state.reviewSubmissions.list,
});

export default connect(mapStateToProps)(ReviewStatus);
