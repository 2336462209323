import React, { useEffect, useState } from 'react';
import { Segment, Tab, Grid } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Message, Icon } from 'semantic-ui-react';

// Import availability views
import GranteeDetailCalendarForm from './GranteeDetailCalendarForm';

// Import actions
import {
  updateGranteeSchedule,
  fetchGranteeAvailability,
} from '../../../actions/granteeActions';

// Import utilities
import AmsAlert from '../../../utils/AmsAlert';

const GranteeDetailAvailabilityPage = ({
  selectedGrantee,
  fiscalYear,
  nextFiscalYear,
  editMode,
  lastEditedGrantee,
  savePreviouseGrantee,
  resetEditMode,
  changeSelectedFiscalYear,
}) => {
  const { granteeId, results } = useSelector(state => state.granteeDetails);
  const { granteeDetails } = useSelector(state => state);
  const [programYearPanes, setProgramYearPanes] = useState([]);
  const [tabEdited, setTabEdited] = useState(false);
  const [programYearIndex, setProgramYearIndex] = useState(0);
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const dispatch = useDispatch();

  const style = {
    boldInfo: {
      fontFamily: 'PublicSans-Bold',
    },
  };

  useEffect(() => {
    const buildProgramPanes = () => {
      let panes = [
        {
          menuItem: 'Current Program Year',
          render: () => (
            <Tab.Pane>
              {(!granteeDetails[fiscalYear].granteeInfo.invited ||
                granteeDetails[fiscalYear].granteeInfo.isManualInvite) && (
                <Message style={style.boldInfo} info>
                  <Icon name="info" />
                  <span>
                    There were no invitations sent to the grantee for the
                    current program year.{' '}
                    {granteeDetails.hsesStatus === 'Active' &&
                      (!isEmpty(
                        granteeDetails[fiscalYear].granteeInfo
                          .availabilitySpecifiedDate
                      ) ? (
                        <>
                          Grantee Availability was submitted manually as of{' '}
                          {
                            granteeDetails[fiscalYear].granteeInfo
                              .availabilitySpecifiedDate
                          }
                          .
                        </>
                      ) : (
                        <>Please submit the Grantee's availability Manually.</>
                      ))}
                  </span>
                </Message>
              )}
              {(granteeDetails.hsesStatus === 'Active' ||
                granteeDetails[fiscalYear].granteeInfo.invited) && (
                <GranteeDetailCalendarForm
                  fiscalYear={fiscalYear}
                  savePreviouseGrantee={data => savePreviouseGrantee(data)}
                  editMode={editMode}
                  resetEditMode={resetEditMode}
                  changeTabEdited={changeTabEdited}
                  paneTitle={'Current Program Year'}
                />
              )}
            </Tab.Pane>
          ),
        },
        {
          menuItem: 'Next Program Year',
          render: () => (
            <Tab.Pane>
              {(!granteeDetails[nextFiscalYear].granteeInfo.invited ||
                granteeDetails[nextFiscalYear].granteeInfo.isManualInvite) && (
                <Message style={style.boldInfo} info>
                  <Icon name="info" />
                  <span>
                    There were no invitations sent to the grantee for the next
                    program year.{' '}
                    {granteeDetails.hsesStatus === 'Active' &&
                      (!isEmpty(
                        granteeDetails[nextFiscalYear].granteeInfo
                          .availabilitySpecifiedDate
                      ) ? (
                        <>
                          Grantee Availability was submitted manually as of{' '}
                          {
                            granteeDetails[nextFiscalYear].granteeInfo
                              .availabilitySpecifiedDate
                          }
                          .
                        </>
                      ) : (
                        <>Please submit the Grantee's availability Manually.</>
                      ))}
                  </span>
                </Message>
              )}
              {(granteeDetails.hsesStatus === 'Active' ||
                granteeDetails[nextFiscalYear].granteeInfo.invited) && (
                <GranteeDetailCalendarForm
                  fiscalYear={nextFiscalYear}
                  savePreviouseGrantee={data => savePreviouseGrantee(data)}
                  editMode={editMode}
                  resetEditMode={resetEditMode}
                  changeTabEdited={changeTabEdited}
                  paneTitle={'Next Program Year'}
                />
              )}
            </Tab.Pane>
          ),
        },
      ];

      setProgramYearPanes(panes);
    };
    buildProgramPanes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fiscalYear,
    granteeDetails,
    nextFiscalYear,
    lastEditedGrantee,
    selectedGrantee,
    editMode,
  ]);

  const changeTabEdited = value => {
    setTabEdited(value);
  };

  const saveWhenMoving = payload => {
    let data = [];
    data.push({ ...payload, sendEmail: true });
    if (!isEmpty(payload))
      dispatch(
        updateGranteeSchedule({
          grantees: data,
          invitationId: payload.invitationId || '',
          generateInvitationId: granteeDetails[fiscalYear].granteeInfo.invited
            ? false
            : true,
        })
      )
        .then(() => {
          setDialogContent({
            type: 'success',
            showCancelButton: false,
            showConfirm: true,
            confirmButtonText: 'Ok',
            title: 'Progress Saved',
            text: results
              ? results.success
              : 'Grantee calendar saved Successfully.',
            onConfirm: () => {
              setShowDialogModal(false);
              setDialogContent({});
            },
          });
          setShowDialogModal(true);
          dispatch(
            fetchGranteeAvailability({
              granteeId,
            })
          );
        })
        .catch(error => {
          setDialogContent({
            type: 'error',
            showCancelButton: false,
            showConfirm: true,
            confirmButtonText: 'Ok',
            title: 'Something went wrong',
            text: error.response.data.message || 'Submission was unsuccessful',
            onConfirm: () => {
              setShowDialogModal(false);
              setDialogContent({});
            },
          });
          setShowDialogModal(true);
        });
  };

  const showTabChangeModal = () => {
    return (
      <AmsAlert
        show={showDialogModal}
        {...dialogContent}
        confirmButtonColor={'#DD6B55'}
      />
    );
  };

  const onTabChange = (e, { activeIndex }) => {
    changeSelectedFiscalYear(activeIndex === 0 ? fiscalYear : nextFiscalYear);
    if (activeIndex !== programYearIndex) {
      if (tabEdited) {
        setDialogContent({
          type: 'warning',
          showCancelButton: true,
          showConfirm: true,
          confirmButtonText: 'Save and continue',
          cancelButtonText: 'Continue without saving',
          title: 'Continue to selected program year',
          text:
            'You have selected another program year. The current changes you have made have not been saved. Do you want to continue to your selected program year?',
          onConfirm: () => {
            let data = lastEditedGrantee;
            saveWhenMoving(data);
            setTabEdited(false);
            setDialogContent({});
            setShowDialogModal(false);
            resetEditMode(false);
          },
          onCancel: () => {
            setTabEdited(false);
            setDialogContent({});
            setShowDialogModal(false);
            savePreviouseGrantee({});
            resetEditMode(false);
          },
        });
        setShowDialogModal(true);
      } else {
        resetEditMode(false);
      }
      setProgramYearIndex(activeIndex);
    }
  };

  return (
    <div>
      {showTabChangeModal()}
      <Grid>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment basic>
              <Tab
                activeIndex={programYearIndex}
                panes={programYearPanes}
                onTabChange={onTabChange}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default GranteeDetailAvailabilityPage;
