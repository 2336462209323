import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import _ from 'lodash';

// Import actions.
import {
  fetchRANFormDetail,
  ranGuideSelected,
  ranPerformanceMeasureSelected,
} from '../../../actions/ranActions';
import { fetchNotes } from '../../../actions/noteActions';

// Utils
import { formatUserNotes, generateTagFilter } from '../../Notes/util.js';

// Import components
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import NotesContainer from '../../Notes/index';
import RANPerformanceMeasures from './RANPerformanceMeasures';
import RANFormContainer from './RANFormContainer';
import RANGranteeInfo from './RANGranteeInfo';

class RANPage extends Component {
  componentDidMount() {
    const reviewId = this.props.router.params.reviewId;
    if (!reviewId) return;
    this.props.fetchRANFormDetail({ reviewId }).then(() => {
      this.preselect();
      this.fetchSurveyNotes();
    });
  }

  fetchSurveyNotes = () => {
    const body = {
      filters: {
        tags: {
          reviewId: this.props.router.params.reviewId,
        },
      },
    };

    this.props.fetchNotes(body);
  };

  preselect = () => {
    // Extract query strings if passed and use them to preselect Guide and PM.
    const guideName = !_.isEmpty(this.props.location.query.g)
      ? decodeURIComponent(this.props.location.query.g)
      : '';
    const performanceMeasure = !_.isEmpty(this.props.location.query.pm)
      ? decodeURIComponent(this.props.location.query.pm)
      : '';
    this.props.ranGuideSelected(guideName);
    this.props.ranPerformanceMeasureSelected(performanceMeasure);
  };

  render = () => {
    const {
      selectedGuide,
      selectedPerformanceMeasure,
      reviewId,
      isReportFinalized,
    } = this.props.ran;

    const { notes } = this.props;

    const tags = {
      type: 'survey_collection',
      guide: selectedGuide && selectedGuide.name,
      reviewId,
      performanceMeasure:
        selectedPerformanceMeasure && selectedPerformanceMeasure.name,
    };

    const surveyNotes = notes && formatUserNotes(notes);
    const generalOptions = [
      { key: 'review', value: '', text: 'All Notes for this Review' },
      { key: 'divider', value: 'divider', text: '' },
    ];
    const pmOptions =
      (selectedGuide &&
        selectedGuide.performanceMeasures &&
        selectedGuide.performanceMeasures.map(pm => {
          return {
            key: pm.name,
            text: pm.name,
            value: pm.name,
          };
        })) ||
      [];

    const options = generateTagFilter([...generalOptions, ...pmOptions]);
    return (
      <SlidingContainer
        calendar
        title={`RAN Survey for Review ID ${reviewId}`}
        granteeStatusBanner={true}
        granteeStatusData={{
          id: this.props.router.params.reviewId,
          type: 'review',
        }}
        actionButtons={
          <NotesContainer
            tags={tags}
            defaultFilter={'performanceMeasure'}
            filterOptions={options}
            list={surveyNotes && surveyNotes}
            enabled={!isReportFinalized && surveyNotes}
            type={'Notes'}
            reloadData={() => {
              this.fetchSurveyNotes();
            }}
          />
        }
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <RANGranteeInfo />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <RANPerformanceMeasures />
            </Grid.Column>

            <Grid.Column stretched width={10}>
              <Segment basic>
                <RANFormContainer />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SlidingContainer>
    );
  };
}

RANPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

RANPage.propTypes = {
  fetchRANFormDetail: PropTypes.func.isRequired,
  ranGuideSelected: PropTypes.func.isRequired,
  ranPerformanceMeasureSelected: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  notes: state.note.notes,
  ran: state.forms.ran,
});

export default connect(mapStateToProps, {
  fetchRANFormDetail,
  fetchNotes,
  ranGuideSelected,
  ranPerformanceMeasureSelected,
})(RANPage);
