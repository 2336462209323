import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import {
  REVIEWS_FETCHED,
  ADD_ERROR,
  REMOVE_ERROR,
  FETCH_REGIONAL_REVIEWS,
} from '../redux/types';

export function getReviews(reviews) {
  return {
    type: REVIEWS_FETCHED,
    reviews,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function fetchReviews(userId) {
  return dispatch => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/amsweb/review/myReviews/${userId}`)
      .then(function(response) {
        dispatch(getReviews(response.data));
      })
      .catch(function(error) {
        dispatch(setError(error));
      });
  };
}

export function regionalReviewsFetched(regionalReviews) {
  return {
    type: FETCH_REGIONAL_REVIEWS,
    regionalReviews,
  };
}
export const fetchRegionalReviews = () => dispatch =>
  axios.get('/review/myRegionalReviews').then(function(response) {
    dispatch(regionalReviewsFetched(response.data));
  });

export const exportMyRegionalReviewsTable = () => dispatch =>
  axios
    .post(
      `/review/exportMyRegionalReviews`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;
      const contentType = response.headers['content-type'];
      if (!response) return;

      fileDownload(
        data,
        `My-Regional-Reviews-Table_${moment().format('x')}.xlsx`,
        contentType
      );
      return response;
    });
// import config from '../config'

// export const SET_REVIEWS = 'SET_REVIEWS';
// export const SET_REVIEW_DETAIL = 'SET_REVIEW_DETAIL';
// export const SET_REVIEW_ERROR = 'SET_REVIEW_ERROR';

// export function setReviews(reviews) {
//   return {
//     type: SET_REVIEWS,
//     reviews
//   }
// }

// export function setReviewError(error) {
//   return {
//     type: SET_REVIEW_ERROR,
//     error
//   }
// }

// export function setReviewDetail(detail) {
//   return {
//     type: SET_REVIEW_DETAIL,
//     detail
//   }
// }

// export function fetchReviews() {
//   return dispatch => {
//     axios.get('/reviews')
//       .then(function (response) {
//         dispatch(setReviews(response.data));
//       })
//       .catch(function (error) {
//         console.log(error)
//         // dispatch(setNotificationsError(error));
//       });
//   }
// }

// export function fetchReviewDetail(reviewId) {

//   return dispatch => {
//     axios.get('/reviews/details')
//       .then(function (response) {
//         dispatch(setReviewDetail(response.data[0].review.Survey[0]));
//       })
//       .catch(function (error) {
//         console.log(error)
//         // dispatch(setNotificationsError(error));
//       });
//   }
// }

// function handleResponse(response) {
//   if (response.ok) {
//     return response.json();
//   } else {
//     let error = new Error(response.statuText);
//     throw error;
//   }
// }
