import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Radio,
  Icon,
  Step,
  Grid,
  Container,
  Divider,
  Message,
  Dropdown,
} from 'semantic-ui-react';
import { isEmpty, filter, uniqBy, map } from 'lodash';
import AmsAlert from '../../utils/AmsAlert';

// Import helpers.
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsFormLabel from '../../utils/AmsFormLabel';

const AmsSubmitReloadReportWizardPage = props => {
  const [step, setStep] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [result, setResult] = useState('');
  const [selectedAssigneeOid, setSelectedAssigneeOid] = useState('');
  const [assigneeSelectionCompleted, setAssigneeSelectionCompleted] = useState(
    false
  );
  const [primaryManager, setPrimaryManager] = useState({});
  const [selectedMemberOidList, setSelectedMemberOidList] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const [selectedEmailRecipientOid, setSelectedEmailRecipientOid] = useState(
    ''
  );

  const [teamMembersOptions, setTeamMembersOptions] = useState([]);

  const [memberDetailsList, setMemberDetailsList] = useState([]);

  useEffect(() => {
    const {
      selectedReview: {
        managerList,
        technicalWriterList,
        editorList,
        analystList,
        followupLeadList,
      },
    } = props;
    let primaryManager = {};
    primaryManager =
      props.selectedReview &&
      !isEmpty(props.selectedReview.Team) &&
      // eslint-disable-next-line array-callback-return
      props.selectedReview.Team.find(manager => {
        if (manager.roleName === 'FieldOperationsManager' && manager.isPrimary)
          return manager;
      });

    if (
      primaryManager &&
      !isEmpty(primaryManager) &&
      primaryManager.isActive === true
    ) {
      setSelectedAssigneeOid(primaryManager.oid);
      setPrimaryManager(primaryManager);
    }
    setStep('assignee');

    setMemberDetailsList([
      ...managerList,
      ...technicalWriterList,
      ...editorList,
      ...analystList,
      ...followupLeadList,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { selectedReview } = props;

    const assignee =
      selectedReview &&
      !isEmpty(selectedReview.Team) &&
      // eslint-disable-next-line array-callback-return
      selectedReview.Team.find(member => {
        if (member.oid === selectedAssigneeOid) return member;
      });

    setSelectedAssignee(assignee);
    setAssigneeSelectionCompleted(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssigneeOid]);

  const teamMemberList = (memberList, userType) => {
    const filteredList = filter(memberList, function(e) {
      return e.isActive === true;
    });
    return (
      filteredList &&
      filteredList.map(memberDetails => {
        return {
          text: displayText(memberDetails, userType),
          value: memberDetails.oid,
          key: memberDetails.id,
          name: memberDetails.name,
          email: memberDetails.email,
        };
      })
    );
  };

  const displayText = (userDetails, userType) => {
    return `${userDetails.name} ${
      primaryManager &&
      !isEmpty(primaryManager.oid) &&
      userDetails.oid === primaryManager.oid
        ? '(Primary Manager)'
        : `(${userType})`
    }${
      selectedAssigneeOid &&
      !isEmpty(selectedAssigneeOid) &&
      userDetails.oid === selectedAssigneeOid
        ? '(Assignee*)'
        : ''
    }`;
  };

  const handleClick = (e, { value }) => {
    e.preventDefault();
    setStep(value);
    setAssigneeSelectionCompleted(false);
    selectedMemberOidList.splice(0, selectedMemberOidList.length);
    setSelectedAssigneeOid(primaryManager.oid);
  };

  const goToStep = step => {
    setStep(step);
  };

  const getUserType = roleName => {
    switch (roleName) {
      case 'FieldOperationsManager':
        return 'Manager';
      case 'Editor':
        return 'Editor';
      case 'TechnicalWriter':
        return 'Technical Writer';
      case 'Analyst':
        return 'Analyst';
      case 'FollowupLead':
        return 'Follow-up Lead';
      default:
        return '';
    }
  };

  const showConfirmationAlert = () => {
    const selectedMemberDetailsList = filter(
      teamMembersOptions,

      x => selectedMemberOidList.findIndex(y => y === x.value) > -1
    );

    //list of emails for cc list
    const selectedMemberEmailList = selectedMemberDetailsList.map(x => x.email);
    //list of oids for cc list
    const selectedMemberCCList = selectedMemberDetailsList.map(x => x.value);

    const selectedEmailRecipientDetails =
      !isEmpty(teamMembersOptions) &&
      // eslint-disable-next-line array-callback-return
      teamMembersOptions.find(member => {
        if (member.value === selectedEmailRecipientOid) return member;
      });

    const selectedEmailRecipientName =
      selectedEmailRecipientDetails && selectedEmailRecipientDetails.name;

    const selectedMemberNames = selectedMemberDetailsList.map(x => x.name);

    const formattedSelectedNames = formatSelectedNames([
      ...selectedMemberNames,
      selectedEmailRecipientName,
    ]);

    return (
      <AmsAlert
        show={showAlert}
        title="Please confirm updates"
        type="warning"
        showConfirm
        showCancelButton
        confirmCancelText="Cancel"
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={(() => {
          if (((props || {}).report.reportStatusData || {})._id) {
            if (
              isEmpty(selectedMemberDetailsList) &&
              isEmpty(selectedEmailRecipientOid)
            ) {
              return (
                <span>
                  You are about to reload the Report without notifying any of
                  the team members, Are you sure you want to continue?
                </span>
              );
            } else {
              if (
                selectedAssignee &&
                !selectedMemberDetailsList.some(
                  member => member.value === selectedAssignee.oid
                ) &&
                selectedAssignee.oid !== selectedEmailRecipientOid
              ) {
                return (
                  <span>
                    You are about to reload the Report without notifying the
                    assigned{' '}
                    {primaryManager &&
                    !isEmpty(primaryManager.oid) &&
                    selectedAssignee.oid === primaryManager.oid
                      ? 'Primary Manager'
                      : `${getUserType(selectedAssignee.roleName)}`}{' '}
                    -{selectedAssignee.name}, Are you sure you want to continue?
                  </span>
                );
              } else {
                return formattedSelectedNames &&
                  !isEmpty(formattedSelectedNames) ? (
                  <span>
                    You are about to reload the Report and selected team
                    member(s) -{formattedSelectedNames}, Are you sure you want
                    to continue?
                  </span>
                ) : (
                  <span>
                    You are about to reload the Report without notifying any
                    team member, Are you sure you want to continue?
                  </span>
                );
              }
            }
          } else {
            if (
              isEmpty(selectedMemberDetailsList) &&
              isEmpty(selectedEmailRecipientOid)
            ) {
              return (
                <span>
                  You are about to move the review to In Analysis Status and
                  generate the Report without notifying any of the team members,
                  Are you sure you want to continue?
                </span>
              );
            } else {
              if (
                selectedAssignee &&
                !selectedMemberDetailsList.some(
                  member => member.value === selectedAssignee.oid
                ) &&
                selectedAssignee.oid !== selectedEmailRecipientOid
              ) {
                return (
                  <span>
                    You are about to move the review to In Analysis Status and
                    generate the Report without notifying the assigned{' '}
                    {primaryManager &&
                    !isEmpty(primaryManager.oid) &&
                    selectedAssignee.oid === primaryManager.oid
                      ? 'Primary Manager'
                      : `${getUserType(selectedAssignee.roleName)}`}{' '}
                    -{selectedAssignee.name}, Are you sure you want to continue?
                  </span>
                );
              } else {
                return formattedSelectedNames &&
                  !isEmpty(formattedSelectedNames) ? (
                  <span>
                    You are about to move the review to In Analysis Status,
                    generate the Report and selected team member(s) -
                    {formattedSelectedNames}, Are you sure you want to continue?
                  </span>
                ) : (
                  <span>
                    You are about to move the review to In Analysis Status,
                    generate the Report without notifying any team member, Are
                    you sure you want to continue?
                  </span>
                );
              }
            }
          }
        })()}
        onConfirm={() => {
          const {
            selectedReview: { reviewId, reviewType, isNewForms },
            content,
            currentUser: { oid },
            selectedReview,
          } = props;
          const report = {
            reviewID: reviewId,
            reportContent: content,
            reportStatus: '',
            versionStatus: 'draft',
            who: oid,
            versionNumber: '0.01',
            reportRegenerated: false,
            when: AmsDateFormatters.getMoment().format(),
            recreateReport: true,
            saveType: ((props || {}).report.reportStatusData || {})._id
              ? 'reloadReport'
              : 'submitToManger',
          };
          const validReviewTypes = ['FA2-CR', 'FA1-FR'];
          const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];
          if (
            validReviewTypes.includes(reviewType) ||
            (newFormReviewTypes.includes(reviewType) && isNewForms)
          ) {
            props.surveySubmitToFOM(
              report,
              {
                reviewId: selectedReview.reviewId,
                emailList: selectedMemberEmailList,
                ccList: selectedMemberCCList,
                assigneeOid: selectedAssigneeOid,
                emailRecipientOid: selectedEmailRecipientOid,
              },
              result => {
                setShowEmailAlert(true);
                setResult(result);
              }
            );
          } else {
            props.submitToFOM(
              report,
              {
                reviewId: selectedReview.reviewId,
                emailList: selectedMemberEmailList,
                ccList: selectedMemberCCList,
                assigneeOid: selectedAssigneeOid,
                emailRecipientOid: selectedEmailRecipientOid,
              },
              result => {
                setShowEmailAlert(true);
                setResult(result);
              }
            );
          }
          setShowAlert(false);
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const showEmailListAlert = () => {
    const selectedMemberDetailsList = filter(
      teamMembersOptions,
      x => selectedMemberOidList.findIndex(y => y === x.value) > -1
    );

    const selectedMemberEmailList = selectedMemberDetailsList.map(x => x.email);

    const selectedEmailRecipientDetails =
      !isEmpty(teamMembersOptions) &&
      // eslint-disable-next-line array-callback-return
      teamMembersOptions.find(member => {
        if (member.value === selectedEmailRecipientOid) {
          return member;
        }
      });

    const selectedEmailRecipientName =
      selectedEmailRecipientDetails && selectedEmailRecipientDetails.name;

    const selectedMemberNames = selectedMemberDetailsList.map(x => x.name);

    const formattedSelectedNames = formatSelectedNames([
      ...selectedMemberNames,
      selectedEmailRecipientName,
    ]);

    return (
      <AmsAlert
        show={showEmailAlert}
        title=""
        type={result instanceof Error ? 'error' : 'success'}
        showConfirm
        confirmButtonText="OK"
        confirmButtonColor={'#DD6B55'}
        text={
          result instanceof Error
            ? +'Error: ' + (result.message || 'unknown error')
            : !isEmpty(selectedMemberEmailList)
            ? formattedSelectedNames
            : 'Successful'
        }
        onConfirm={
          result instanceof Error
            ? () => {
                setShowAlert(true);
                setShowEmailAlert(false);
              }
            : () => {
                setShowAlert(false);
                setShowEmailAlert(false);
                setSelectedAssigneeOid('');
                props.data.handleModalClosure(false);
                setSelectedMemberOidList([]);
              }
        }
      />
    );
  };

  const formatSelectedNames = selectedNames => {
    if (!isEmpty(selectedNames)) {
      if (selectedNames.length === 1) {
        return selectedNames.join(' ').concat(' is notified');
      }
      if (selectedNames.length === 2) {
        return selectedNames.join(' and ').concat(' is notified');
      } else if (selectedNames.length > 2) {
        let subString = '';
        for (let i = 0; i < selectedNames.length - 2; i++) {
          subString = subString.concat(selectedNames[i]).concat(', ');
        }
        return subString
          .concat(selectedNames[selectedNames.length - 2])
          .concat(' and ')
          .concat(selectedNames[selectedNames.length - 1])
          .concat(' is notified');
      }
    }
  };

  const handleFinalizeClick = () => {
    setShowAlert(true);
  };

  const handleSelectedStateChange = (e, { value }) => {
    setSelectedAssigneeOid(value);
  };

  const assigneeForm = () => {
    const { selectedReview } = props;
    let userList = [];
    if (!isEmpty(selectedReview)) {
      userList = uniqBy(
        [
          ...map(selectedReview.managerList, manager => {
            return { ...manager, userType: 'Manager' };
          }),
          ...map(selectedReview.editorList, editor => {
            return { ...editor, userType: 'Editor' };
          }),
          ...map(selectedReview.technicalWriterList, technicalWriter => {
            return { ...technicalWriter, userType: 'Technical Writer' };
          }),
          ...map(selectedReview.analystList, analyst => {
            return { ...analyst, userType: 'Analyst' };
          }),
          ...map(selectedReview.followupLeadList, followupLead => {
            return { ...followupLead, userType: 'Follow-up Lead' };
          }),
        ],
        'oid'
      );
    }
    if (!isEmpty(selectedReview)) {
      return (
        <>
          {assigneeSelectionAlert()}
          {(() => {
            if (((props || {}).report.reportStatusData || {})._id) {
              if (isEmpty(memberDetailsList)) {
                return (
                  <span>
                    The Report will be reloaded. Are you sure you want to
                    continue?
                  </span>
                );
              } else if (primaryManager && !isEmpty(primaryManager.oid)) {
                if (
                  isEmpty(selectedReview.editorList) &&
                  isEmpty(selectedReview.technicalWriterList) &&
                  isEmpty(selectedReview.analystList) &&
                  isEmpty(selectedReview.followupLeadList)
                ) {
                  return (
                    <span>
                      The Report will be reloaded and assigned to the Primary
                      Manager - {primaryManager.name}. Are you sure you want to
                      continue?
                    </span>
                  );
                } else {
                  return (
                    <span>
                      The Report will be reloaded and assigned to the Primary
                      Manager - {primaryManager.name}. If you want to change the
                      assignment, select any of the following members.
                    </span>
                  );
                }
              } else if (primaryManager && isEmpty(primaryManager.oid)) {
                return (
                  <span>
                    There is no Primary Manager on this review. Select any of
                    the following Assignee and click continue to reload the
                    report.
                  </span>
                );
              }
            } else {
              if (isEmpty(memberDetailsList)) {
                return (
                  <span>
                    The Review will be moved to In Analysis Status and Report
                    will be generated. Are you sure you want to continue?
                  </span>
                );
              } else if (primaryManager && !isEmpty(primaryManager.oid)) {
                if (
                  isEmpty(selectedReview.editorList) &&
                  isEmpty(selectedReview.technicalWriterList) &&
                  isEmpty(selectedReview.analystList) &&
                  isEmpty(selectedReview.followupLeadList)
                ) {
                  return (
                    <span>
                      The Review will be moved to In Analysis Status and Report
                      will be generated and assigned to the Primary Manager -{' '}
                      {primaryManager.name}. Are you sure you want to continue?
                    </span>
                  );
                } else {
                  return (
                    <span>
                      The Review will be moved to In Analysis Status and Report
                      will be generated and assigned to the Primary Manager -{' '}
                      {primaryManager.name}. If you want to change the
                      assignment, select any of the following member.
                    </span>
                  );
                }
              } else if (primaryManager && isEmpty(primaryManager.oid)) {
                return (
                  <span>
                    There is no Primary Manager on this review. Select any of
                    the following Assignee and click continue to move the Review
                    to In Analysis Status and generate the report.
                  </span>
                );
              }
            }
          })()}
          {userList.map(user => {
            return assigneeRadioButtonField(user, user.userType);
          })}
          <Divider />
          <Button
            floated="right"
            width={15}
            primary
            content="Continue"
            onClick={e => {
              e.preventDefault();
              setAssigneeSelectionCompleted(true);

              if (!isEmpty(selectedAssigneeOid)) {
                goToStep('notification');
                setTeamMembersOptions(
                  uniqBy(
                    [
                      ...teamMemberList(
                        props.selectedReview.managerList,
                        'Manager'
                      ),
                      ...teamMemberList(
                        props.selectedReview.technicalWriterList,
                        'Technical Writer'
                      ),
                      ...teamMemberList(
                        props.selectedReview.editorList,
                        'Editor'
                      ),
                      ...teamMemberList(
                        props.selectedReview.analystList,
                        'Analyst'
                      ),
                      ...teamMemberList(
                        props.selectedReview.followupLeadList,
                        'Follow-up Lead'
                      ),
                    ],
                    'value'
                  )
                );

                setSelectedAssigneeOid(selectedAssigneeOid);
                setSelectedEmailRecipientOid(selectedAssigneeOid);
              } else if (isEmpty(memberDetailsList)) {
                goToStep('notification');
              }
            }}
          />
          <Button
            floated="right"
            width={15}
            content={'Cancel'}
            onClick={() => {
              props.data.handleModalClosure(false);
            }}
          />
        </>
      );
    }
  };

  const assigneeRadioButtonField = (userDetails, userType) => {
    return (
      <Form.Field>
        <Radio
          disabled={userDetails.isActive === false}
          label={
            primaryManager.oid === userDetails.oid
              ? `${primaryManager.name}${' '}(Primary Manager)${
                  userDetails.isActive === false ? ' (Inactive)' : ''
                }`
              : `${userDetails.name}${' '}(${userType})${
                  userDetails.isActive === false ? ' (Inactive)' : ''
                }`
          }
          checked={
            selectedAssigneeOid === userDetails.oid &&
            userDetails.isActive === true
          }
          onChange={handleSelectedStateChange}
          value={userDetails.oid}
        />
      </Form.Field>
    );
  };

  const notificationForm = () => {
    const { selectedReview } = props;
    if (!isEmpty(selectedReview)) {
      let filteredTeamMembersOptions = [];
      if (selectedEmailRecipientOid) {
        filteredTeamMembersOptions = teamMembersOptions.filter(e => {
          return e.value !== selectedEmailRecipientOid;
        });
      }
      return (
        <>
          {(() => {
            if (isEmpty(memberDetailsList)) {
              return (
                <span>
                  Currently, there is no Primary Manager assigned on this review
                  hence notification will not be sent
                </span>
              );
            } else if (primaryManager && !isEmpty(primaryManager.oid)) {
              if (
                isEmpty(selectedReview.editorList) &&
                isEmpty(selectedReview.technicalWriterList) &&
                isEmpty(selectedReview.analystList) &&
                isEmpty(selectedReview.followupLeadList)
              ) {
                return (
                  <span>
                    Notification will be sent to the selected Assignee{' '}
                    {selectedAssignee.displayName}
                  </span>
                );
              } else {
                return (
                  <span>
                    Do you want the notification to be sent only to the assignee
                    you selected or select any one member in 'To' dropdown to
                    notify about this change and copy members from the 'CC'
                    dropdown below.
                  </span>
                );
              }
            } else if (primaryManager && isEmpty(primaryManager.oid)) {
              if (memberDetailsList.length > 1) {
                return (
                  <span>
                    Currently, there is no Primary Manager assigned on this
                    review hence notification will be sent to the selected
                    Assignee {selectedAssignee.name} or select any one member in
                    'To' dropdown to notify about this change and copy members
                    from the 'CC' dropdown below.
                  </span>
                );
              } else {
                return (
                  <span>
                    Currently, there is no Primary Manager assigned on this
                    review hence notification will be sent to the selected
                    Assignee {selectedAssignee.name}.
                  </span>
                );
              }
            }
          })()}
          <Form.Group widths="equal">
            {teamMembersOptions && teamMembersOptions.length > 0 && (
              <div>
                <br />
                <Form.Field>
                  <AmsFormLabel
                    required
                    name={`To`}
                    fieldLabel={`memberToNotify`}
                  >
                    <Dropdown
                      required
                      placeholder={`Select Member to Notify about the Change`}
                      value={selectedEmailRecipientOid}
                      onChange={(e, { value }) => {
                        setSelectedEmailRecipientOid(value);

                        let copyCCList = [...selectedMemberOidList];
                        copyCCList = copyCCList.filter(e => {
                          return e !== value;
                        });
                        setSelectedMemberOidList(copyCCList);
                      }}
                      options={teamMembersOptions}
                      searchInput={{
                        id: 'memberToNotify',
                        title: 'Select Member to Notify about the Change',
                      }}
                      aria-labelledby="memberToNotify"
                      fluid
                      search
                      selection
                      clearable
                      openOnFocus={false}
                      popperPlacement="top-start"
                    />
                  </AmsFormLabel>
                </Form.Field>
              </div>
            )}

            {teamMembersOptions && teamMembersOptions.length > 1 && (
              <div>
                <br />
                <Form.Field>
                  <AmsFormLabel
                    name={`CC`}
                    fieldLabel={`membersToCopy`}
                  ></AmsFormLabel>
                  <Dropdown
                    placeholder={`Select Members to Copy in the Notification`}
                    popperPlacement="top-start"
                    value={selectedMemberOidList}
                    onChange={(e, { value }) => {
                      setSelectedMemberOidList(value);
                    }}
                    options={filteredTeamMembersOptions || teamMembersOptions}
                    searchInput={{
                      id: 'membersToCopy',
                      title: 'Select Members to Copy in the Notification',
                    }}
                    aria-labelledby="membersToCopy"
                    fluid
                    multiple
                    search
                    selection
                    clearable
                    openOnFocus={false}
                  />
                </Form.Field>
              </div>
            )}
          </Form.Group>

          <Divider />
          <Button
            floated="right"
            primary
            content="Finalize"
            onClick={handleFinalizeClick}
          />
          <Button
            floated="right"
            content="Cancel"
            onClick={e => {
              e.preventDefault();
              props.data.handleModalClosure(false);
            }}
          />
        </>
      );
    }
  };

  const assigneeSelectionAlert = () => {
    if (assigneeSelectionCompleted && isEmpty(selectedAssigneeOid)) {
      return <Message negative>Please select an Assignee.</Message>;
    }
  };

  return (
    <>
      {showConfirmationAlert()}
      {showEmailListAlert()}
      <Container className="left-align">
        <Grid.Column>
          <Grid.Row>
            <Step.Group widths={3}>
              <Step
                onClick={handleClick}
                active={step === 'assignee'}
                value="assignee"
                style={{ textDecoration: 'none' }}
                href="#"
                tabIndex={step === 'assignee' ? '-1' : '0'}
                completed={
                  (assigneeSelectionCompleted &&
                    !isEmpty(selectedAssigneeOid)) ||
                  (assigneeSelectionCompleted && isEmpty(memberDetailsList))
                }
              >
                <Icon name="user" />
                <Step.Content>
                  <Step.Title
                    style={{
                      color: step === 'assignee' && '#245e9d !important',
                    }}
                  >
                    Assignee
                  </Step.Title>
                  {assigneeSelectionCompleted ? (
                    selectedAssignee && !isEmpty(selectedAssignee.oid) ? (
                      <Step.Description>
                        {' '}
                        {selectedAssignee.name}{' '}
                        {primaryManager &&
                        !isEmpty(primaryManager.oid) &&
                        selectedAssignee.oid === primaryManager.oid
                          ? '(Primary Manager)'
                          : `(${getUserType(selectedAssignee.roleName)})`}{' '}
                        is selected
                      </Step.Description>
                    ) : (
                      <Step.Description>
                        No assignee is selected, as there is no Primary Manager
                        in the Team.
                      </Step.Description>
                    )
                  ) : (
                    <Step.Description>
                      Select a member to assign the report
                    </Step.Description>
                  )}
                </Step.Content>
              </Step>
              <Step
                active={step === 'notification'}
                value="notification"
                style={{ textDecoration: 'none' }}
                href="#"
                tabIndex={step === 'notification' ? '-1' : '0'}
                disabled={!assigneeSelectionCompleted}
              >
                <Icon name="mail" />
                <Step.Content>
                  <Step.Title
                    style={{
                      color: step === 'notification' && '#245e9d !important',
                    }}
                  >
                    Notification
                  </Step.Title>
                  <Step.Description>
                    Selected members in the dropdown will be notified about this
                    change
                  </Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>
          </Grid.Row>
          <Grid.Row>
            <Divider />
            {step === 'assignee' ? assigneeForm() : notificationForm()}
          </Grid.Row>
        </Grid.Column>
      </Container>
    </>
  );
};

export default AmsSubmitReloadReportWizardPage;
