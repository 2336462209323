import React from 'react';
import ChildFileOutcomeNew from './ChildFileOutcomeNew';
import ChildFileOutcome from '../SurveyOutcomes/ChildFileOutcome';

const ChildFileOutcomePaths = props => {
  const { params } = props.router;
  const validYears = ['fy23', 'fy24'];
  return (
    <>
      {params && validYears.includes(params.fiscalYear) ? (
        <ChildFileOutcomeNew props={props} />
      ) : (
        <ChildFileOutcome props={props} />
      )}
    </>
  );
};
export default ChildFileOutcomePaths;
