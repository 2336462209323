import React from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

const GranteeAvailabiltiyInstructionTab = props => {
  const { instructionContent } = useSelector(state => state.granteeDetails);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(instructionContent),
        }}
      />
    </>
  );
};

export default GranteeAvailabiltiyInstructionTab;
