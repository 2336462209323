import React, { Component } from 'react';
import XLSX from 'xlsx';
import fileDownload from 'js-file-download';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

class XlsExport extends Component {
  render() {
    const renderFile = () => {
      let grantees = this.props.grantees;
      let grantee = grantees[0];
      let details = this.props.details;
      if (!details) {
        return null;
      }

      details.findingsList = this.props.adjustFindingDetailsAction(
        details.findingsList || details.findings,
        (this.props.review || {}).selectedReview || {}
      );

      /* convert from array of arrays to workbook */
      let data;

      if (this.props.reviewType !== 'RAN') {
        data = {
          cols: [
            { key: 'A', index: 0 },
            { key: 'B', index: 1 },
            { key: 'C', index: 2 },
            { key: 'D', index: 3 },
            { key: 'E', index: 4 },
            { key: 'F', index: 5 },
            { key: 'G', index: 6 },
            { key: 'H', index: 7 },
            { key: 'I', index: 8 },
            { key: 'J', index: 9 },
            { key: 'K', index: 10 },
          ],
          data: [
            [
              'Citations',
              'Grantee Id',
              'Guide Name',
              'Performance Measure',
              'Findings Status',
              'Reason',
              'Narrative',
              'Report delivery Date',
              'Corrective Action Time frame',
              'Corrective Action Due Date',
              'Follow-up Determination',
            ],
          ],
        };

        (details.findingsList || []).forEach((row, index) => {
          let granteeIdFormatter = ids => {
            let idsArray = ids.map(each => each.granteeId);
            let joined = idsArray.join();
            let exportIds = joined.replace(',', ', ');
            return exportIds;
          };
          data.data.push([
            row.citation,

            grantees.length === 1
              ? grantee.granteeId
              : row.confirmedGrantees.length > 0
              ? granteeIdFormatter(row.confirmedGrantees)
              : 'No grantee assigned',
            row.guideName || '',
            row.performanceMeasure || '',
            this.props.statusFormatterAction(row, index, true),
            this.props.excelReasonFormatterAction(row),

            row.narrative || '',
            row.reportDeliveryDate
              ? AmsDateFormatters.formatDate(row.reportDeliveryDate)
              : '',
            row.correctiveActionTimeFrame ? row.correctiveActionTimeFrame : '',
            row.correctiveActionDueDate
              ? AmsDateFormatters.formatDate(row.correctiveActionDueDate)
              : '',
            row.followUpDetermination ? row.followUpDetermination : '',
          ]);
          data.data.push([
            [
              (row.associatedCitations || [])
                .map(item => {
                  return (
                    item.associatedCitation +
                    (item.associatedCitationDefinition
                      ? ' - ' + item.associatedCitationDefinition + ' '
                      : '')
                  );
                })
                .join('\n'),
              data.narrative,
            ]
              .filter(v => !!v)
              .join('\n\n'),
          ]);
        });
      } else {
        data = {
          cols: [
            { key: 'A', index: 0 },
            { key: 'B', index: 1 },
            { key: 'C', index: 2 },
            { key: 'D', index: 3 },
            { key: 'E', index: 4 },
            { key: 'F', index: 5 },
            { key: 'G', index: 6 },
            { key: 'H', index: 7 },
            { key: 'I', index: 8 },
            { key: 'J', index: 9 },
            { key: 'K', index: 10 },
          ],
          data: [
            [
              'Citations',
              'Grantee Id',
              'Specification Type',
              'Findings Status',
              'Reason',
              'Narrative',
              'Report delivery Date',
              'Corrective Action Time frame',
              'Corrective Action Due Date',
              'Follow-up Determination',
            ],
          ],
        };

        (details.findingsList || []).forEach((row, index) => {
          let granteeIdFormatter = ids => {
            let idsArray = ids.map(each => each.granteeId);
            let joined = idsArray.join();
            let exportIds = joined.replace(',', ', ');
            return exportIds;
          };
          data.data.push([
            row.citation,

            grantees.length === 1
              ? grantee.granteeId
              : row.confirmedGrantees.length > 0
              ? granteeIdFormatter(row.confirmedGrantees)
              : 'No grantee assigned',
            row.performanceMeasure || '',
            this.props.statusFormatterAction(row, index, true),
            this.props.excelReasonFormatterAction(row),
            row.narrative || '',
            row.reportDeliveryDate
              ? AmsDateFormatters.formatDate(row.reportDeliveryDate)
              : '',
            row.correctiveActionTimeFrame ? row.correctiveActionTimeFrame : '',
            row.correctiveActionDueDate
              ? AmsDateFormatters.formatDate(row.correctiveActionDueDate)
              : '',
            row.followUpDetermination ? row.followUpDetermination : '',
          ]);
          data.data.push([
            [
              (row.associatedCitations || [])
                .map(item => {
                  return (
                    item.associatedCitation +
                    (item.associatedCitationDefinition
                      ? ' - ' + item.associatedCitationDefinition + ' '
                      : '')
                  );
                })
                .join('\n'),
              data.narrative,
            ]
              .filter(v => !!v)
              .join('\n\n'),
          ]);
        });
      }
      let worksheet = XLSX.utils.aoa_to_sheet(data.data);
      let new_workbook = XLSX.utils.book_new();
      let title = ('Grantee: ' + ((grantee || {}).granteeId || '')).substring(
        0,
        27
      );

      let filename = this.props.reviewId + '.csv';

      XLSX.utils.book_append_sheet(new_workbook, worksheet, title);

      /* bookType can be any supported output type */
      var wopts = { bookType: 'csv', bookSST: false, type: 'array' };

      var wbout = XLSX.write(new_workbook, wopts);

      /* the saveAs call downloads a file on the local machine */
      let file = new Blob([wbout], { type: 'application/octet-stream' });

      fileDownload(file, `${filename}`, 'application/octet-stream');
    };

    return (
      <button onClick={renderFile} className="btn btn-sm btn-default">
        Export Table
      </button>
    );
  }
}

export default XlsExport;
