/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';

//Import Components
import ScheduleViewGranteeAccordion from './ScheduleViewGranteeAccordion';

class GranteeCurrentlySelectedDatesModal extends Component {
  state = {
    showSelectedModal: false,
  };

  handleShowModal = e => {
    e.preventDefault();
    this.setState({ showSelectedModal: true });
  };

  handleHideModal = () => {
    this.setState({ showSelectedModal: false });
  };

  render() {
    const { unavailableDates, sessionDates, breakDates } = this.props;
    return (
      <div>
        <a href="" onClick={this.handleShowModal}>
          Grantee's Unavailability List View
        </a>
        <Modal
          show={this.state.showSelectedModal}
          onHide={this.handleHideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Grantee's Selected Unavailability Dates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScheduleViewGranteeAccordion
              unavailableDates={unavailableDates}
              sessionDates={sessionDates}
              breakDates={breakDates}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button color="blue" onClick={this.handleHideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(GranteeCurrentlySelectedDatesModal);
