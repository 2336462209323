import React, { useEffect, useState } from 'react';
import { Message, Menu, List, Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router';

// Import util
import enforceRole from '../../utils/EnforceRole';

// Import config.
import { acl } from '../../config';

import HelpSections from './HelpSections';

import SlidingContainer from '../../utils/layout/SlidingContainer';

// Actions.
import {
  fetchCategoryDetailsBySlug,
  fetchCategories,
} from '../../actions/help/categoryActions';

// Style
import './assets/style.css';

const HelpCategoryPage = props => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(null);
  const { categoryDetails, categories } = useSelector(state => state.help);
  const currentUser = useSelector(state => state.auth.user);

  const category = categoryDetails && categoryDetails.category;

  const activeCategories =
    categories &&
    categories.filter(e => {
      return e.active === true;
    });
  useEffect(() => {
    const { router } = props;

    dispatch(fetchCategoryDetailsBySlug(router.params.slug)).then(data => {
      if (isEmpty(data)) setErrorMsg('Error: Category page not found');
    });
    dispatch(fetchCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebar = (
    <Menu vertical fluid>
      {activeCategories &&
        categoryDetails &&
        activeCategories.map(e => {
          return (
            <Menu.Item
              key={e.slug}
              active={category && category.title === e.title}
              href={`/help/${e.slug}`}
              as={Link}
            >
              {e.title}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const createButton = () => {
    return enforceRole(
      <Button color="green" size="large" as={Link} href="/help/create">
        Add Post
      </Button>,
      acl.actions.help.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  };

  const actionButtons = (
    <>
      <List.Item>
        <List.Content>{createButton()}</List.Content>
      </List.Item>
    </>
  );

  const renderErrorMsg = () => {
    if (errorMsg) {
      return (
        <Message
          negative
          icon={'cancel'}
          header={'Something went wrong!'}
          content={errorMsg}
        />
      );
    }
  };

  return (
    <>
      <SlidingContainer
        sidebarComponent={sidebar}
        actionButtons={actionButtons}
        calendar
        title={
          categoryDetails && !isEmpty(categoryDetails)
            ? `${categoryDetails.category.title}`
            : 'Loading...'
        }
      >
        {renderErrorMsg()}
        <HelpSections />
      </SlidingContainer>
    </>
  );
};

export default HelpCategoryPage;
