import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Button, List, Modal } from 'semantic-ui-react';
import { forEach } from 'lodash';

// actions
import { fetchGranteeStatus } from '../actions/granteeActions';

// utils
import { granteeName } from '../utils/TextFormatters';
import AmsModal from '../utils/AmsModal';
import AmsDateFormatters from '../utils/AmsDateFormatters';

const GranteeStatusBanner = props => {
  const dispatch = useDispatch();
  const { id, type, view } = props;
  const { granteeDetails } = useSelector(state => state);
  const { review } = useSelector(state => state);
  const [statusText, setStatusText] = useState();
  const [showModal, setShowModal] = useState(false);
  const [granteeArray, setGranteeArray] = useState([]);
  const [multiple, setMultiple] = useState(false);
  const [inactive, setInactive] = useState([]);

  const formatDate = input => {
    return AmsDateFormatters.formatUtcDate(input);
  };

  useEffect(() => {
    if (id && type) dispatch(fetchGranteeStatus(id, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const { status } = granteeDetails;
    if (status && Array.isArray(status) && status.length > 0) {
      let updated = '';
      let type = '';
      let counter = [];
      const inactiveArr = [];
      forEach(status, (curr, index) => {
        if (curr.type === 'Terminated' || curr.type === 'Relinquished') {
          counter.push(index);
          if (updated === '') {
            updated = curr.updatedAt;
            type = curr.type;
          } else if (
            AmsDateFormatters.getMoment(curr.updatedAt).isAfter(updated)
          ) {
            updated = curr.updatedAt;
            type = curr.type;
          }
        } else if (curr.type === 'Inactive') {
          inactiveArr.push(curr.granteeId);
        }
      });

      if (type !== '') {
        if (counter.length > 1) {
          const text = `The grantees for this review have been terminated or relinquished. `;
          setStatusText(text);
          setMultiple(true);
          setGranteeArray(counter);
        } else {
          if (
            review &&
            review.selectedReview &&
            review.selectedReview.numberOfGrants === 'Multiple Grant'
          ) {
            setStatusText(
              `The grantee associated with this review has been ${type.toLowerCase()} as of ${formatDate(
                updated
              )}. `
            );
            setMultiple(true);
            setGranteeArray(counter);
          } else {
            setStatusText(
              `This grantee has been ${type.toLowerCase()} as of ${formatDate(
                updated
              )}.`
            );
            setMultiple(false);
            setGranteeArray([]);
          }
        }
      }
      if (inactiveArr && inactiveArr.length > 0) {
        setInactive(inactiveArr);
      }
    }
  }, [granteeDetails, review]);

  let visible = statusText ? 'inherit' : 'none';
  return (
    <>
      <div
        style={{
          background: '#ffbe2e',
          textAlign: 'left',
          color: '#000',
          paddingLeft: '0px',
          paddingRight: '0px',
          borderRadius: '5px',
          marginBottom: '1rem',
          display: visible,
        }}
      >
        <Segment basic>
          <strong style={{ fontWeight: '700' }}>
            {statusText}
            {multiple && (
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  setShowModal(true);
                }}
              >
                Click for more information.
              </Link>
            )}
          </strong>{' '}
        </Segment>
        <AmsModal
          className="ams-semantic-modal-fix"
          open={showModal}
          closeIcon
          centered={false}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <Modal.Header>Grantee Details</Modal.Header>
          <Modal.Content>
            <List divided relaxed>
              {granteeArray.map(e => {
                const grantee = granteeDetails.status[e];
                if (!grantee) {
                  return null;
                }
                return (
                  <List.Item>
                    <List.Content>
                      <List.Header>
                        {granteeName({
                          granteeId: grantee.granteeId,
                          granteeName: grantee.granteeName,
                        })}
                      </List.Header>
                      {grantee.type} on {formatDate(grantee.updatedAt)}
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
              content="Close"
            />
          </Modal.Actions>
        </AmsModal>
      </div>
      {view === 'reviewDetail' && inactive && inactive.length > 0 ? (
        <div
          style={{
            background: '#ffbe2e',
            textAlign: 'left',
            color: '#000',
            paddingLeft: '0px',
            paddingRight: '0px',
            borderRadius: '5px',
            marginBottom: '1rem',
          }}
        >
          <Segment basic>
            <strong style={{ fontWeight: '700' }}>
              {inactive.join(', ')} is Inactive
            </strong>
          </Segment>
        </div>
      ) : null}
    </>
  );
};

export default GranteeStatusBanner;
