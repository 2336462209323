import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

//import components
import CardEnumOptions from '../Shared/CardEnumOptions';
import Wysiwyg from '../../../Shared/Wysiwyg';

//import actions
import { formBuilderFocusedComponent } from '../../../../actions/surveyActions';

export function TQAnswer({ parameters, onChange }) {
  const enumArray = Array.isArray(parameters.enum) ? parameters.enum : [];

  const dispatch = useDispatch();
  const focusedComponent = useSelector(state => state.survey.formBuilderFocus);

  const isFocused = focusedComponent === 'TQDescription';

  const handleFocus = () => {
    const elementsToRemove = document.querySelectorAll('trix-toolbar');

    // Iterate over the NodeList and remove each element
    elementsToRemove.forEach(element => {
      element.remove();
    });
    setTimeout(() => {
      dispatch(formBuilderFocusedComponent('TQDescription'));
    }, 250);
  };

  return (
    <>
      <h5>Description</h5>
      {isFocused === false && (
        <div
          className="form-control description-input wsywig-input-unfocused"
          name={'description'}
          value={parameters.description}
          onFocus={handleFocus}
          contentEditable={true}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(parameters.description),
          }}
        />
      )}
      {isFocused === true && (
        <Wysiwyg
          id={'description-trix-editor'}
          focusOnInit={true}
          value={parameters.description}
          onChange={content => {
            onChange({ ...parameters, description: content });
          }}
        />
      )}
      <div className="card-enum">
        <h3>TQ Answer List</h3>

        <CardEnumOptions
          initialValues={enumArray}
          names={
            Array.isArray(parameters.enumNames)
              ? parameters.enumNames.map(val => `${val}`)
              : undefined
          }
          showNames={false}
          onChange={(newEnum, enumNames) => {
            newEnum = newEnum || 'option 1';
            return onChange({
              ...parameters,
              enum: newEnum,
              enumNames,
            });
          }}
        />
      </div>
    </>
  );
}

export function TQLabel({ parameters, onChange }) {
  const enumArray = Array.isArray(parameters.enum) ? parameters.enum : [];

  const dispatch = useDispatch();
  const focusedComponent = useSelector(state => state.survey.formBuilderFocus);

  const isFocused = focusedComponent === 'TQDescription';

  const handleFocus = () => {
    const elementsToRemove = document.querySelectorAll('trix-toolbar');

    // Iterate over the NodeList and remove each element
    elementsToRemove.forEach(element => {
      element.remove();
    });
    setTimeout(() => {
      dispatch(formBuilderFocusedComponent('TQDescription'));
    }, 250);
  };
  
  if (parameters.hide) {
    return (<></>);
  }
  
  return (
    <>
      <h5>Description</h5>
      {isFocused === false && (
        <div
          className="form-control description-input wsywig-input-unfocused"
          name={'description'}
          value={parameters.description}
          onFocus={handleFocus}
          contentEditable={true}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(parameters.description),
          }}
        />
      )}
      {isFocused === true && (
        <Wysiwyg
          id={'description-trix-editor'}
          focusOnInit={true}
          value={parameters.description}
          onChange={content => {
            onChange({ ...parameters, description: content });
          }}
        />
      )}
    </>
  );
}
