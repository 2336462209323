import { filter, groupBy, isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import { Card, Icon, Message } from 'semantic-ui-react';

// Import Components
import SlidingContainer from '../../../utils/layout/SlidingContainer';

// Import Actions
import {
  fetchGrantee,
  fetchGranteeExtensionHistory,
} from '../../../actions/granteeActions';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { granteeName } from '../../../utils/TextFormatters';
import GranteeSidebar from '../GranteeSidebar';

export default function GranteeExtensionHistory(props) {
  const { extensionRequestHistory } = useSelector(state => state.grantee);
  const { grantee } = useSelector(state => state);
  const [loading, setLoading] = useState(false);

  const [previousRequests, setPreviousRequests] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const buildRequestCards = () => {
      if (extensionRequestHistory) {
        setPreviousRequests(
          groupBy(
            orderBy(
              filter(extensionRequestHistory, o => o.extensionRequest),
              ['submittedAt'],
              ['desc']
            ),
            'fiscalYear'
          )
        );
      }
      setLoading(false);
    };
    buildRequestCards();
  }, [extensionRequestHistory]);

  useEffect(() => {
    const granteeAvailability = () => {
      const granteeId = props.params.id;
      dispatch(fetchGrantee(granteeId));
      dispatch(fetchGranteeExtensionHistory(granteeId));
      setLoading(true);
    };
    granteeAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SlidingContainer
        loading={{ loader: loading, loadingMessage: 'Loading Grantee...' }}
        title={granteeName(grantee)}
        sidebarComponent={<GranteeSidebar />}
      >
        {!isEmpty(previousRequests) ? (
          Object.keys(previousRequests)
            .sort()
            .reverse()
            .map(key => {
              return (
                <>
                  <h1>{`Extension Requests for Fiscal Year: ${key}`}</h1>
                  <Card.Group itemsPerRow={2}>
                    {previousRequests[key] &&
                      previousRequests[key].map((request, index) => {
                        return (
                          <Card fluid>
                            <Card.Content>
                              <Card.Header
                                content={`${index + 1}. Extension Request  ${
                                  request.hasOwnProperty('approved') &&
                                  request.approved
                                    ? ' - Approved'
                                    : request.approved === false
                                    ? ' - Declined'
                                    : ''
                                }`}
                              />
                              <Card.Description>
                                <b>Submitted by:</b> {request.submittedBy}
                                <br />
                                <b>Submitted at:</b>{' '}
                                {AmsDateFormatters.formatDateTime(
                                  request.submittedAt
                                )}
                                <br />
                                <b>Reason for Extension:</b>{' '}
                                {request.extensionReason}
                                <br />
                                {request.multiGrant && (
                                  <>
                                    <Message info>
                                      The following extension request is an
                                      extension for the following grantees.
                                      <Message.List>
                                        {request.grantees.map(grantee => (
                                          <Message.Item>
                                            <Link
                                              to={`/grantee/${grantee.granteeId}/availability/schedule-extensions`}
                                            >
                                              {grantee.granteeName} (
                                              {grantee.granteeId})
                                            </Link>
                                          </Message.Item>
                                        ))}
                                      </Message.List>
                                    </Message>
                                  </>
                                )}
                                <br />
                                {request.hasOwnProperty('approved') &&
                                  request.responseBy && (
                                    <>
                                      <h3>Response</h3>
                                      <br />
                                      <b>Response Comment:</b>{' '}
                                      {request.responseComment}
                                      <br />
                                      <b>Response Date:</b>{' '}
                                      {AmsDateFormatters.formatDateTime(
                                        request.responseAt
                                      )}
                                      <br />
                                      <b>Response By:</b>{' '}
                                      {request.responseBy.fullName}
                                    </>
                                  )}
                              </Card.Description>
                            </Card.Content>
                          </Card>
                        );
                      })}
                  </Card.Group>
                </>
              );
            })
        ) : (
          <>
            <Message
              style={{
                boldInfo: {
                  fontFamily: 'PublicSans-Bold',
                },
              }}
              info
            >
              <Icon name="info" />
              <span>
                There are no schedule extensions requested for this grantee
              </span>
            </Message>
          </>
        )}
      </SlidingContainer>
    </>
  );
}
