import React from 'react';

import { acl } from '../../config';

// Import forms.
import UserSettingsForm from './Forms/UserSettingsForm';
import VendorInformationForm from './Forms/VendorInformationForm';
import ContactInformationForm from './Forms/ContactInformationForm';
import DemographicForm from './Forms/DemographicForm';
import EducationForm from './Forms/EducationForm';
import ExperienceForm from './Forms/ExperienceForm';
import MiscellaneousForm from './Forms/MiscellaneousForm';
import TravelPreferenceForm from './Forms/TravelPreferenceForm';
import ChangePasswordForm from './Forms/ChangePasswordForm';
import TrainingForm from './Forms/TrainingForm';
import AdditionalCredentialsForm from './Forms/AdditionalCredentialsForm';
import Skills from './Forms/Skills';
import UserCalendar from './Forms/UserCalendar';
import UserReviewHistory from './Forms/UserReviewHistory';

export const profileTabs = [
  { key: 'calendar', name: 'Calendar', allowed: ['All'] },
  {
    key: 'userSettings',
    name: 'User Settings',
    allowed: acl.actions.profile.userSettings,
  },
  {
    key: 'vendorInformation',
    name: 'Vendor Information and Agreements',
    allowed: ['All'],
  },
  { key: 'contactInformation', name: 'Contact Information', allowed: ['All'] },
  { key: 'demographic', name: 'Demographic', allowed: ['All'] },
  { key: 'education', name: 'Education', allowed: ['All'] },
  { key: 'experience', name: 'Experience', allowed: ['All'] },
  { key: 'miscellaneous', name: 'Miscellaneous', allowed: ['All'] },
  { key: 'travelPreference', name: 'Travel Preference', allowed: ['All'] },
  { key: 'training', name: 'Training', allowed: ['All'] },
  {
    key: 'additionalCredentials',
    name: 'Additional Credentials',
    allowed: ['All'],
  },
  { key: 'changePassword', name: 'Change Password', allowed: ['All'] },
  { key: 'skills', name: 'Skills', allowed: ['All'] },
  {
    key: 'reviewHistory',
    name: 'Review History',
    allowed: ['All'],
  },
];

export const getForm = (key, oid) => {
  switch (key) {
    case 'userSettings':
      return <UserSettingsForm />;
    case 'vendorInformation':
      return <VendorInformationForm />;
    case 'contactInformation':
      return <ContactInformationForm />;
    case 'demographic':
      return <DemographicForm />;
    case 'education':
      return <EducationForm />;
    case 'experience':
      return <ExperienceForm />;
    case 'miscellaneous':
      return <MiscellaneousForm />;
    case 'travelPreference':
      return <TravelPreferenceForm />;
    case 'changePassword':
      return <ChangePasswordForm />;
    case 'training':
      return <TrainingForm />;
    case 'additionalCredentials':
      return <AdditionalCredentialsForm />;
    case 'skills':
      return <Skills />;
    case 'calendar':
      return <UserCalendar oid={oid} />;
    case 'reviewHistory':
      return <UserReviewHistory />;

    default:
      return null;
  }
};
