import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';

//import Components
import ReviewLayout from '../../../../utils/layout/ReviewLayout';
import ReviewOCISummaryLegend from './ReivewOCISummaryLegend';
import ReviewOCISummaryDetails from './ReviewOCISummaryDetails';

//import Actions
import { fetchReviewDetail } from '../../../../actions/reviewActions';
const ReviewOCISummaryPage = props => {
  useEffect(() => {
    const { router } = props;
    if (
      !props.selectedReview.reviewId ||
      props.selectedReview.reviewId !== router.params.id
    ) {
      props.fetchReviewDetail({ reviewId: router.params.id });
    }
  }, [props]);

  return (
    <ReviewLayout type={'Performance Rating Summary'}>
      <Header as="h2" content="Performance Rating Summary" />
      {/* <ReviewOCISummaryHeader /> */}
      <ReviewOCISummaryDetails />
      <ReviewOCISummaryLegend />
    </ReviewLayout>
  );
};

const mapStateToProps = state => {
  return {
    selectedReview: state.review.selectedReview,
  };
};

export default connect(mapStateToProps, { fetchReviewDetail })(
  ReviewOCISummaryPage
);
