import { combineReducers } from 'redux';

import reviews from './reviews';
import notifications from './notifications';
import help from './help';
import auth from './auth';
import report from './report';
import todos from './todos';
import task from './task';
import review from './review';
import resource from './resource';
import carousel from './carousel';
import survey from './survey';
import grantees from './grantees';
import error from './error';
import profile from './profile';
import weather from './weather';
import geocode from './geocode';
import users from './users';
import lookups from './lookups';
import message from './message';
import admin from './admin';
import calendar from './calendar';
import workflow from './workflow';
import grantee from './grantee';
import granteeOptions from './granteeOptions';
import profileTab from './profileTab';
import granteeFilter from './granteeFilter';
import taskDetail from './taskDetail';
import usersFilter from './usersFilter';
import tasks from './tasks';
import submissions from './submissions';
import reviewers from './reviewers';
import invitation from './invitation';
import reviewSubmissions from './reviewSubmissions';
import forms from './forms';
import note from './note';
import granteeDetails from './granteeDetails';
import agreement from './agreement';

export default combineReducers({
  error,
  reviews,
  notifications,
  help,
  auth,
  report,
  todos,
  task,
  review,
  resource,
  carousel,
  survey,
  note,
  grantees,
  grantee,
  profile,
  weather,
  geocode,
  users,
  lookups,
  message,
  admin,
  calendar,
  workflow,
  granteeOptions,
  profileTab,
  granteeFilter,
  taskDetail,
  usersFilter,
  tasks,
  submissions,
  reviewers,
  invitation,
  reviewSubmissions,
  forms,
  granteeDetails,
  agreement,
});
