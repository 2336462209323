import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router';
import _ from 'lodash';

const ReviewPreSite = ({ selectedReview, survey }) => {
  if (
    selectedReview.reviewType === 'Follow-up' ||
    selectedReview.reviewType === 'RAN'
  )
    return null;

  let showSpecialPresiteCard = false;

  // Find if the PreSite checkboxes were selected on the special review.
  if (survey && survey.guides) {
    const guides = _.find(survey.guides, { guideCode: 'PRE' });
    const presitePMS =
      guides && guides.performanceMeasures
        ? _.find(guides.performanceMeasures, { isActive: true })
        : null;

    if (presitePMS) {
      showSpecialPresiteCard = true;
    }
  }

  return selectedReview &&
    selectedReview.reviewId &&
    (selectedReview.reviewType === 'FA-1' ||
      selectedReview.reviewType === 'FA-2' ||
      (selectedReview.reviewType === 'Special' && showSpecialPresiteCard) ||
      (selectedReview.reviewType === 'AIAN-DEF' && showSpecialPresiteCard) ||
      (selectedReview.reviewType === 'ERSEA' && showSpecialPresiteCard)) ? (
    <Menu.Item
      key="ReviewPreSite"
      to={`/task/${selectedReview.reviewId}/survey/presite`}
      as={Link}
    >
      Pre-Review
    </Menu.Item>
  ) : null;
};

ReviewPreSite.propTypes = {
  selectedReview: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  survey: state.survey,
});

export default connect(mapStateToProps)(ReviewPreSite);
