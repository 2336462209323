import { filter, find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Label,
  Loader,
  Menu,
  Tab,
} from 'semantic-ui-react';

import { updateReview } from '../../../actions/reviewActions';
import {
  fetchSpecialAssignments,
  updateSpecialAssignments,
} from '../../../actions/specialActions';

const ReviewGuidesPerformanceMeasuresForm = props => {
  const { sendEmail, resendEmail, reviewId, reviewType } = useSelector(
    state => state.review.reviewInfo
  );
  const [pms, setPms] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.reviewId) {
      setLoading(true);
      dispatch(fetchSpecialAssignments({ reviewId: props.reviewId }))
        .then(pms => {
          setPms(pms.assignments);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reviewId]);

  useEffect(() => {}, [pms]);

  const renderPerformanceMeasures = (guideCode = 'PMQ') => {
    const { guides } = pms;

    const guide = guides && find(guides, { guideCode });

    return (
      guide &&
      guide.performanceMeasures.map((p, i) => {
        const key = `${guideCode}-${i}`;

        return (
          <Form.Field
            key={key}
            label={{
              children: p.perfName,
              htmlFor: p.perfName,
            }}
            id={p.perfName}
            name={key}
            value={p.perfName}
            checked={p.isActive}
            onChange={(e, { name }) => {
              props.dataHasChangedSwitch(true);
              // Toggle the performance measures.
              const updatedPMs = [];

              guides &&
                guides.forEach(guide => {
                  const updatedPerformanceMeasures = [];

                  guide.performanceMeasures.forEach(g => {
                    if (g.key === name) {
                      updatedPerformanceMeasures.push({
                        ...g,
                        isActive: !g.isActive,
                      });
                    } else {
                      updatedPerformanceMeasures.push({ ...g });
                    }
                  });

                  updatedPMs.push({
                    ...guide,
                    performanceMeasures: updatedPerformanceMeasures,
                  });
                });

              const pms = {
                reviewId: props.reviewId,
                guides: updatedPMs,
              };

              setPms(pms);
            }}
            control={Checkbox}
          />
        );
      })
    );
  };

  const renderGuides = () => {
    const { guides } = pms;

    const panes = [];

    guides &&
      guides.map(d => {
        const count = filter(d.performanceMeasures, o => {
          if (o.isActive === true) return o;
        }).length;

        const pane = {
          menuItem: (
            <Menu.Item key={d.guideCode}>
              {d.guideName} <Label color="blue">{count}</Label>
            </Menu.Item>
          ),
          render: () => renderPerformanceMeasures(d.guideCode),
        };

        return panes.push(pane);
      });

    return panes;
  };

  useEffect(() => {
    if (props.saveRequest === true) {
      savePMS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saveRequest]);

  const savePMS = () => {
    dispatch(updateSpecialAssignments(pms))
      .then(response => {
        if (props.saveRequest === true) {
          props.dataHasChangedSwitch('successSave');
          return;
        }
        if (
          response &&
          response.assignments &&
          response.assignments.result.toLowerCase() === 'success'
        )
          props.goToStep('team', props.reviewId, true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted />
      </Dimmer>
      <Header>Select Guides and Performance Measure</Header>
      <Divider />
      <>
        <Tab
          menu={{
            fluid: true,
            vertical: true,
            secondary: true,
            size: 'large',
          }}
          grid={{ paneWidth: 6, tabWidth: 10 }}
          panes={renderGuides()}
        />
      </>
      <Divider />
      {
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <Button
                form="createReviewForm"
                primary
                content={'Save and Continue'}
                onClick={() => {
                  // saveReview('continue');
                  savePMS();
                }}
                size="huge"
              />
            </Grid.Column>
            {/* <Grid.Column>
              <Button
                form="createReviewForm"
                primary
                content={'Save and Exit'}
                onClick={() => {
                  // saveReview('exit');
                }}
                size="huge"
              />
            </Grid.Column> */}
            <Grid.Column width={4}>
              <Button
                form="createReviewForm"
                content="Exit without saving"
                onClick={e => {
                  e.preventDefault();
                  if (
                    reviewType === 'Special' ||
                    reviewType === 'AIAN-DEF' ||
                    reviewType === 'ERSEA' ||
                    reviewType === 'RAN'
                  ) {
                    let data = { ...props.previousReviewInfo };
                    data['sendEmail'] = resendEmail === true ? true : false;
                    if (resendEmail && !sendEmail && reviewId && props.editMode)
                      dispatch(updateReview(data));
                  }
                  props.router.push(`/review/${props.reviewId}`);
                }}
                size="huge"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    </>
  );
};

export default ReviewGuidesPerformanceMeasuresForm;
