import React, { Link } from 'react';
import { Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Form from '@rjsf/core';

import AttachmentField from '../../Shared/FormHelpers/AttachmentField';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';
import {
  fileUploadFormSchema,
  fileUploadUISchema,
} from './FollowUpSurveyFileSchema';

const FollowUpAttachmentField = props => {
  const handleFurRecommendationChange = (
    furRecommendation,
    index,
    { formData }
  ) => {
    props.onChange(furRecommendation, index, { formData });
  };

  const handleAdditionalCitationChange = (
    additionalCitation,
    index,
    { formData }
  ) => {
    props.onChange(additionalCitation, index, { formData });
  };

  const validate = (data, errors) => {
    return errors;
  };

  const customDescriptionField = ({ id, description }) => {
    // IF there is no description, hide the link
    if (isEmpty(description) || (description && isEmpty(description[0].text)))
      return null;

    let citation = description ? description[0].citationNumber : '';
    let params = {
      pathname: `/review/citations/definition`,
      query: { citation },
    };
    return (
      <span>
        <Link to={params} target="_blank">
          {description ? description[0].text : ''}
        </Link>
      </span>
    );
  };

  const fields = () => {
    return {
      DescriptionField: customDescriptionField,
      attachmentField: AttachmentField,
    };
  };

  const log = type => console.log.bind(console, type);

  const renderFURRecommendationForm = () => {
    const { furRecommendation, index, formName } = props;

    return (
      <Form
        id={`${formName}-${index}`}
        schema={fileUploadFormSchema}
        ArrayFieldTemplate={ArrayFieldTemplate}
        uiSchema={fileUploadUISchema}
        onChange={(e, formData) =>
          handleFurRecommendationChange(furRecommendation, index, e)
        }
        onError={log('errors')}
        validate={validate}
        liveValidate={false}
        showErrorList={false}
        formData={{
          evidences: furRecommendation && furRecommendation.evidences,
        }}
        noHtml5Validate={true}
        fields={fields()}
      >
        <div hidden>
          <Button
            size="large"
            type="submit"
            content={'Submit'}
            hidden
            disabled
            onClick={e => {
              e.preventDefault();
            }}
          />
        </div>
      </Form>
    );
  };

  const renderAdditionalCitationForm = () => {
    const { index, additionalCitation, formName } = props;
    return (
      <Form
        id={`${formName}-${index}`}
        ArrayFieldTemplate={ArrayFieldTemplate}
        schema={fileUploadFormSchema}
        uiSchema={fileUploadUISchema}
        onChange={(e, formData) => {
          handleAdditionalCitationChange(additionalCitation, index, e);
        }}
        onError={log('errors')}
        validate={validate}
        liveValidate={false}
        showErrorList={false}
        formData={{
          evidences: additionalCitation && additionalCitation.evidences,
        }}
        noHtml5Validate={true}
        fields={fields()}
      >
        <div hidden>
          <Button
            size="large"
            type="submit"
            content={'Submit'}
            hidden
            disabled
            onClick={e => {
              e.preventDefault();
            }}
          />
        </div>
      </Form>
    );
  };

  const renderField = () => {
    const { furRecommendation, additionalCitation } = props;

    if (!isEmpty(furRecommendation)) return renderFURRecommendationForm();
    if (!isEmpty(additionalCitation)) return renderAdditionalCitationForm();

    return null;
  };

  return <>{renderField()}</>;
};
export default FollowUpAttachmentField;
