import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import AmsPopup from '../../../utils/AmsPopup';

function LockDataCollection({ handleSurveyComplete, disableForm }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Popup
        trigger={
          <Button
            content="Lock Data Collection"
            onClick={handleSurveyComplete}
            disabled={disableForm}
          />
        }
        //
        content="Button function info displayed on click. The button will only display if all questions are complete."
        size="small"
      />

      <AmsPopup
        content="This allows you to lock the data collection for all guides in this review when questions are completed to prevent 
      any further changes."
        label="What is this?"
        type="Button"
        style={{
          background: 'none',
          border: 'none',
          textDecoration: 'underline',
          cursor: 'pointer',
          textAlign: 'left',
        }}
      />
    </div>
  );
}

export default LockDataCollection;
