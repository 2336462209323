import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import _, { isEmpty } from 'lodash';

import { fetchAmsLookup } from '../actions/lookupActions';

class SurveyLookupDropdown extends React.Component {
  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    const { category, fetchAmsLookup, amsLookups } = this.props;
    if (category) {
      if (_.isEmpty(amsLookups[category]))
        fetchAmsLookup(category)
          .then()
          .catch(error => {
            if (this.props.setError)
              this.props.setError({
                responseError: error.response.data.message,
              });
          });
    }
  };

  prepareLookupItems = (lookupItems, type) => {
    switch (type.toLowerCase()) {
      case 'string':
        return lookupItems.map(lookup => ({
          key: lookup._id,
          text: lookup.value,
          value: lookup.value,
        }));
      case 'number':
        return lookupItems.map(lookup => ({
          key: lookup._id,
          text: lookup.value,
          value: lookup.value,
        }));
      case 'object':
        this.sortObjects(lookupItems);
        return lookupItems.map(lookup => ({
          key: lookup._id,
          text: lookup.value.label,
          value: lookup.value.value,
        }));
      default:
        break;
    }
  };

  renderDropdown = () => {
    const { amsLookups, category, target } = this.props;

    let filteredLookupItems = [];

    const lookups = amsLookups[category];

    const parent = _.find(lookups, { parent: true }); // Find lookup category parent.
    filteredLookupItems = _.filter(lookups, { parent: false, active: true }); // Find active lookup items.
    if (target && !isEmpty(target)) {
      filteredLookupItems = _.filter(filteredLookupItems, lookup => {
        if (lookup.value.target && lookup.value.target.includes(target))
          return lookup;
      });
    } // filter lookup items based on target.

    // Only render lookup dropdown if parent is active.
    if (parent && parent.active) {
      // Check if lookup list is not empty.
      if (filteredLookupItems.length) {
        const options = this.prepareLookupItems(
          filteredLookupItems,
          parent.valueType
        ); // Prepare lookups for Semantic UI dropdown list.
        const dropDownProps = _.omit(this.props, [
          'category',
          'fetchAmsLookup',
          'amsLookups',
          'setError',
        ]); // exclude props not necessary for Dropdown.
        return (
          <Dropdown
            selectOnBlur={false}
            openOnFocus={false}
            {...dropDownProps}
            options={options}
          /> // Only show dropdown if lookup items are available
        );
      }
    }
    return null;
  };

  sortObjects(lookupItems) {
    lookupItems.sort((a, b) => {
      if (a.value.value.toLowerCase() < b.value.value.toLowerCase()) {
        return -1;
      }
      if (a.value.value.toLowerCase() > b.value.value.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  render() {
    return this.renderDropdown();
  }
}

SurveyLookupDropdown.propTypes = {
  fetchAmsLookup: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  amsLookups: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  amsLookups: state.lookups.amsLookups,
});

export default connect(mapStateToProps, { fetchAmsLookup })(
  SurveyLookupDropdown
);
