import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Constants from './../Shared/SurveyFormConstants';
import Wysiwyg from '../../../Shared/Wysiwyg';
import { isEmpty } from 'lodash';

class CheckboxListWidget extends Component {
  constructor(props) {
    super(props);

    const { value } = props;
    this.state = {
      selectedValues: value || [],
      otherReasonValue: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selectedValues: this.props.value || [] });
    }
  }

  selectValue(value, selected, all) {
    const at = all.indexOf(value);
    const updated = selected.slice(0, at).concat(value, selected.slice(at));

    return updated.sort((a, b) => {
      // Make sure opt-other and opt-other-reason are always at the last
      if (a.startsWith('opt-other-reason')) return 1; // always last
      if (b.startsWith('opt-other-reason')) return -1; // b is last so a comes before
      if (a === 'opt-other') return 1; // a is second last if b is not opt-other-reason
      if (b === 'opt-other') return -1; // b is second last if a is not opt-other-reason

      // For all other values, sort based on their index in 'all'
      return all.indexOf(a) - all.indexOf(b);
    });
  }

  deselectValue(value, selected) {
    return selected.filter(v => v !== value);
  }

  deselectValues(values, selected) {
    return selected.filter(v => values.indexOf(v) === -1);
  }

  handleOptionChange = (event, option) => {
    const { selectedValues } = this.state;
    const { enumOptions } = this.props.options;

    const allOptions = enumOptions.map(({ value }) => value);

    if (event.target.checked) {
      const updatedValues = this.selectValue(
        option.value,
        selectedValues,
        allOptions
      );
      this.setState({ selectedValues: updatedValues }, () => {
        this.triggerOnChange(updatedValues);
      });
    } else {
      const checkItems = [option.value, ...this.getContingentItems(option)];
      const updatedValues = this.deselectValues(checkItems, selectedValues);
      this.setState({ selectedValues: updatedValues }, () => {
        this.triggerOnChange(updatedValues);
      });
    }
  };

  handleOtherChange = event => {
    const { selectedValues, otherReasonValue } = this.state;
    const { enumOptions } = this.props.options;
    const allOptions = enumOptions.map(({ value }) => value);

    if (event.target.checked) {
      const updatedValues = this.selectValue(
        Constants.OTHER_KEY,
        selectedValues,
        allOptions
      );
      this.setState({ selectedValues: updatedValues }, () => {
        this.triggerOnChange(updatedValues);
      });
    } else {
      const oldValue = selectedValues.find(el =>
        el?.toString().startsWith(Constants.OTHER_REASON_KEY)
      );
      const newCheckListValues = this.deselectValue(oldValue, selectedValues);
      const updatedValues = this.deselectValue(
        Constants.OTHER_KEY,
        newCheckListValues
      );
      this.setState({ selectedValues: updatedValues }, () => {
        this.triggerOnChange(updatedValues);
      });
    }
  };

  handleOtherReasonChange = content => {
    const { selectedValues } = this.state;
    const { enumOptions } = this.props.options;
    const allOptions = enumOptions.map(({ value }) => value);
    const oldValue = selectedValues.find(el =>
      el?.toString().startsWith(Constants.OTHER_REASON_KEY)
    );
    const newCheckListValues = this.deselectValue(oldValue, selectedValues);
    let updatedValues = newCheckListValues;

    if (!isEmpty(content)) {
      const newValue = Constants.OTHER_REASON_KEY + content;
      updatedValues = this.selectValue(
        newValue,
        newCheckListValues,
        allOptions
      );
    }

    this.setState({ selectedValues: updatedValues }, () => {
      this.triggerOnChange(updatedValues);
    });
  };

  getContingentItems(option) {
    const { schema } = this.props;
    const { contingentEnum } = schema.items;
    const index = option.index;
    return contingentEnum?.map(v => `${index}_${v}`) || [];
  }

  triggerOnChange(value) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const { id, disabled, options, autofocus, readonly, schema } = this.props;
    let { enumOptions, enumDisabled, inline } = options;
    // Filter out OTHER Key from the list to display
    enumOptions = enumOptions?.filter(o => o.value != Constants.OTHER_KEY);

    const { selectedValues } = this.state;
    const otherIndex = enumOptions.length;
    const otherChecked = selectedValues.indexOf(Constants.OTHER_KEY) !== -1;
    const otherReasonValue = otherChecked
      ? selectedValues.find(el =>
          el?.toString().startsWith(Constants.OTHER_REASON_KEY)
        )
      : null;
    const otherReasonValueClean = isEmpty(otherReasonValue)
      ? ''
      : otherReasonValue.replace(Constants.OTHER_REASON_KEY, '');
    const otherDisabled =
      enumDisabled && enumDisabled.indexOf(Constants.OTHER_KEY) !== -1;
    const otherDisabledCls =
      disabled || otherDisabled || readonly ? 'disabled' : '';
    const otherCheckbox = (
      <span>
        <input
          type="checkbox"
          id={`${id}_${otherIndex}`}
          checked={otherChecked}
          disabled={disabled || otherDisabled || readonly}
          autoFocus={autofocus && otherIndex === 0}
          onChange={this.handleOtherChange}
        />
        <span>Other</span>
      </span>
    );
    let allOptions = [];
    enumOptions.forEach((option, index) => {
      allOptions.push(option.value);
      if (schema.items.contingentEnum) {
        schema.items.contingentEnum.forEach(contingentItem => {
          allOptions.push(`${index}_${contingentItem}`);
        });
      }
    });

    return (
      <div className="checkboxes" id={id}>
        {enumOptions.map((option, index) => {
          const checked = selectedValues.indexOf(option.value) !== -1;
          const itemDisabled =
            enumDisabled && enumDisabled.indexOf(option.value) !== -1;
          const disabledCls =
            disabled || itemDisabled || readonly ? 'disabled' : '';
          const checkId = `${id}_${index}`;
          const checkItems = [option.value, ...this.getContingentItems(option)];

          const checkbox = (
            <span>
              <input
                type="checkbox"
                id={checkId}
                checked={checked}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && index === 0}
                onChange={event => this.handleOptionChange(event, option)}
              />
              <span>{option.label}</span>
            </span>
          );

          const cProp = 'c' + index;
          const contingentList =
            checked &&
            schema.hasContingentCheckboxes &&
            schema.contingentItems?.hasOwnProperty(cProp) ? (
              <div className="contingent-checkboxes">
                {schema.contingentItems[cProp]?.map(
                  (contingentItem, innerIndex) => {
                    const option = `${index}_${contingentItem}`;
                    const contingentChecked =
                      selectedValues.indexOf(option) !== -1;

                    return (
                      <div
                        key={innerIndex}
                        className={`checkbox ${disabledCls}`}
                      >
                        <span>
                          <input
                            type="checkbox"
                            id={`${checkId}_${innerIndex}`}
                            checked={contingentChecked}
                            disabled={disabled || itemDisabled || readonly}
                            autoFocus={autofocus && innerIndex === 0}
                            onChange={event => {
                              if (event.target.checked) {
                                const updatedValues = this.selectValue(
                                  option,
                                  selectedValues,
                                  allOptions
                                );
                                this.setState(
                                  { selectedValues: updatedValues },
                                  () => {
                                    this.triggerOnChange(updatedValues);
                                  }
                                );
                              } else {
                                const updatedValues = this.deselectValue(
                                  option,
                                  selectedValues
                                );
                                this.setState(
                                  { selectedValues: updatedValues },
                                  () => {
                                    this.triggerOnChange(updatedValues);
                                  }
                                );
                              }
                            }}
                          />
                          <span>{contingentItem}</span>
                        </span>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              <></>
            );
          return inline ? (
            <label key={index} className={`checkbox-inline ${disabledCls}`}>
              {checkbox}
              {contingentList}
            </label>
          ) : (
            <div key={index} className={`checkbox ${disabledCls}`}>
              <label>{checkbox}</label>
              {contingentList}
            </div>
          );
        })}
        {!schema.showOther ? null : (
          <>
            <div key={otherIndex} className={`checkbox ${otherDisabledCls}`}>
              <label style={{ width: '100%' }}>{otherCheckbox}</label>
            </div>
            {!otherChecked ? null : (
              <div id={`${id}_${otherIndex}_other`}>
                <span style={{ fontWeight: 'bold' }}>Please specify*</span>
                <Wysiwyg
                  id={`${id}_${otherIndex}_other_text`}
                  style={{ width: '100%' }}
                  required
                  disabled={disabled || otherDisabled || readonly}
                  value={otherReasonValueClean}
                  focusOnInit={false}
                  onChange={this.handleOtherReasonChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

CheckboxListWidget.defaultProps = {
  autofocus: false,
  options: {
    inline: false,
  },
};

CheckboxListWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool,
  }).isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxListWidget;
