import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchFollowupSubmission } from '../../../../actions/fy19/reviewActions';
import { Link, withRouter } from 'react-router';

import AmsTable from '../../../../utils/AmsTable';

class FollowupSubmissions extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      !this.props.review.selectedReview.reviewId &&
      nextProps.review.selectedReview.reviewId
    ) {
      this.props.fetchFollowupSubmission({
        reviewId: nextProps.review.selectedReview.reviewId,
      });
    }
    return true;
  }

  componentDidMount = () => {
    if (this.props.params.reviewId) {
      this.props.fetchFollowupSubmission({
        reviewId: this.props.params.reviewId,
      });
    }
  };

  typeFormat = (cell, row) => {
    const {
      selectedReview: { reviewId },
    } = this.props.review;

    return (
      <Link
        to={{
          pathname: `/task/${reviewId}/survey/follow-up`,
          query: {},
        }}
      >
        {cell}
      </Link>
    );
  };

  statusFormat = (cell, row) => {
    return row.surveyStatus;
  };

  render() {
    let submission = [this.props.review.followUpSubmission || {}];
    submission[0].type = 'Follow-up data collection form';
    if (this.props.review.selectedReview.reviewType !== 'Follow-up') {
      return null;
    }

    const columns = [
      { hidden: true, dataField: 'index', text: 'Index' },
      {
        dataField: 'type',
        formatter: this.typeFormat,
        headerStyle: { width: '20%' },
        text: 'Type',
      },
      {
        dataField: 'status',
        formatter: this.statusFormat,
        headerStyle: { width: '80%' },
        text: 'Status',
      },
    ];
    return (
      <Segment
        basic
        loading={!(this.props.review.followUpSubmission || {}).surveyStatus}
      >
        <AmsTable
          keyField="index"
          data={submission}
          columns={columns}
          total={submission && submission.length}
          basic
          sizePerPageOption={false}
        />
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    review: state.review,
  };
};

export default connect(mapStateToProps, { fetchFollowupSubmission })(
  withRouter(FollowupSubmissions)
);
