import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Header, Modal } from 'semantic-ui-react';

import { Link } from 'react-router';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsModal from '../../../utils/AmsModal';
import AmsTable from '../../../utils/AmsTable';

const ReviewInfoChangeHistory = props => {
  const { reviewChangeHistory } = useSelector(state => state.review.reviewInfo);
  const [showModal, setShowModal] = useState(false);

  const renderChangeHistoryTable = () => {
    const type = (cell, row) => {
      if (row) {
        switch (row.type) {
          case 'unannounced':
            return <>Unannounced</>;
          case 'reviewStatus':
            return <>Review Status</>;
          case 'reviewStartDate':
            return <>Review Start Date</>;
          case 'reviewEndDate':
            return <>Review End Date</>;
          default:
            break;
        }
      }
    };

    const modifiedBy = (cell, row) => {
      if (row && !isEmpty(row.UpdatedBy))
        return <>{row.UpdatedBy.fullName && row.UpdatedBy.fullName}</>;
    };

    const reasons = (cell, row) => {
      if (row && row.reason && row.reason.length > 0) {
        return <>{row.reason.join(', ')}</>;
      }
    };

    // const sharedDateFormatter = (cell, row) => {
    //   if (row && row.expirationDate) {
    //     const date = new Date(row.expirationDate);
    //     return <>{AmsDateFormatters.getMoment(date).format('L')}</>;
    //   }
    // };

    const data =
      reviewChangeHistory &&
      reviewChangeHistory.length > 0 &&
      reviewChangeHistory.sort((a, b) =>
        AmsDateFormatters.getMoment(b.modifiedDate).diff(
          AmsDateFormatters.getMoment(a.modifiedDate)
        )
      );

    const columns = [
      {
        formatter: type,
        dataField: 'type',
        text: 'Form Field',
        sort: true,
        // headerStyle: {
        //   width: '30%',
        // },
      },
      {
        dataField: 'changedTo',
        text: 'Changed To',
        sort: true,
        // headerStyle: {
        //   width: '30%',
        // },
      },
      {
        dataField: 'reason',
        text: 'Reasons',
        sort: true,
        formatter: reasons,
      },
      {
        formatter: modifiedBy,
        dataField: 'UpdatedBy.fullName',
        text: 'Updated By',
        sort: true,
        // headerStyle: {
        //   width: '30%',
        // },
      },
      // {
      //   dataField: 'sharedDate',
      //   text: 'Shared Date',
      //   sort: true,
      //   formatter: sharedDateFormatter,
      // },
    ];

    return (
      <div>
        <AmsTable
          data={data || []}
          remote={false}
          total={data && data.length}
          keyField="_id"
          columns={columns}
          pagination={false}
          options={{
            hideSizePerPage: true,
            showTotal: false,
          }}
          showTotal={false}
          paginationTotalHeaderSize="h3"
          basic={data && data.length ? false : true}
          sizePerPageOption={data && data.length ? true : false}
        />
      </div>
    );
  };

  const renderChangeHistoryModal = () => {
    return (
      <AmsModal
        size="large"
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowModal(false);
        }}
        open={showModal}
        className="ams-semantic-modal-fix"
      >
        <Header content="Review Info Change Histroy" />

        <Modal.Content>
          <Modal.Description>{renderChangeHistoryTable()}</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            content="Close"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  return (
    <>
      <Link
        to="#"
        onClick={e => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
        View Change History
      </Link>
      {renderChangeHistoryModal()}
    </>
  );
};

export default ReviewInfoChangeHistory;
