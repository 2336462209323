import axios from 'axios';

import {
  SPECIAL_ASSIGNMENTS_FETCHED,
  SPECIAL_ASSIGNMENTS_UPDATED,
  SPECIAL_SURVEY_ASSIGNMENTS_FETCHED,
  SPECIAL_SURVEY_ASSIGNMENTS_UPDATED,
} from '../redux/types';

export const specialAssignmentsFetched = assignments => ({
  type: SPECIAL_ASSIGNMENTS_FETCHED,
  assignments,
});

export const specialAssignmentsUpdated = assignments => ({
  type: SPECIAL_ASSIGNMENTS_UPDATED,
  assignments,
});

export const specialSurveyAssignmentsFetched = assignments => ({
  type: SPECIAL_SURVEY_ASSIGNMENTS_FETCHED,
  assignments,
});

export const specialSurveyAssignmentsUpdated = assignments => ({
  type: SPECIAL_SURVEY_ASSIGNMENTS_UPDATED,
  assignments,
});

export const fetchSpecialAssignments = obj => dispatch =>
  axios
    .post(`/survey/special/assignments/fetch`, obj)
    .then(response => dispatch(specialAssignmentsFetched(response.data)));

export const updateSpecialAssignments = obj => dispatch =>
  axios
    .post(`/survey/special/assignments/add`, obj)
    .then(response => dispatch(specialAssignmentsUpdated(response.data)));

export const fetchSpecialSurveyAssignments = obj => dispatch =>
  axios
    .post(`/survey/special/assignments/fetchGuides`, obj)
    .then(response => dispatch(specialAssignmentsFetched(response.data)));

export const updateSpecialSurveyAssignments = obj => dispatch =>
  axios
    .post(`/survey/special/assignments/update`, obj)
    .then(response => dispatch(specialSurveyAssignmentsUpdated(response.data)));
