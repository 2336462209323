import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router';

import {
  Grid,
  Comment,
  Message,
  Header,
  Segment,
  Popup,
  Label,
  Icon,
  Accordion,
  Button,
} from 'semantic-ui-react';

//Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import ReportTrackerFindingsModal from './ReportTrackerFindingsModal';

import {
  fetchReportFindingsDetail,
  exportDelegatedTasks,
} from '../../actions/taskActions';

import './assets/style.css';
import _ from 'lodash';
import DOMPurify from 'dompurify';

const ReportTrackerFindingsPage = props => {
  const dispatch = useDispatch();

  //fetched data for task details, current user, and external user email list
  const taskDetail = useSelector(state => state.taskDetail);
  //task data
  const [reviewFindings, setReviewFindings] = useState([]);
  const [activeComments, setActiveComments] = useState({});
  const [findingsCount, setFindingsCount] = useState(0);
  const [activePanelsObj, setActivePanelsObj] = useState({});
  const [delegatedTasks, setDelegatedTasks] = useState(false);
  const newReviewTypes = ['FA1-FR', 'FA2-CR'];
  const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];

  //html style / components
  const approvedBox = (
    <div
      style={{
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        float: 'right',
        borderRadius: '3px',
        border: 'solid 1px #007a26',
        backgroundColor: '#f5fff8',
        color: '#007a26',
        textAlign: 'center',
        display: 'inline-block',
        height: '28px',
        width: '90px',
        marginRight: '10px',
      }}
    >
      <Icon name="check circle" color="green"></Icon>
      APPROVED
    </div>
  );

  const needClarificationBox = (
    <div
      style={{
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        float: 'right',
        borderRadius: '3px',
        border: 'solid 1px rgb(189,152,77)',
        backgroundColor: 'rgb(254,248,222)',
        color: 'rgb(189,152,77)',
        textAlign: 'center',
        display: 'inline-block',
        height: '28px',
        width: '120px',
        marginRight: '10px',
      }}
    >
      NEED CLARIFICATION
    </div>
  );

  const inProgressBox = (
    <div
      style={{
        fontSize: '10px',
        fontFamily: 'PublicSans-Bold',
        float: 'right',
        borderRadius: '3px',
        border: 'solid 1px rgb(69,138,204)',
        backgroundColor: 'rgb(225,239,253)',
        color: 'rgb(69,138,204)',
        textAlign: 'center',
        display: 'inline-block',
        height: '28px',
        width: '90px',
        marginRight: '10px',
      }}
    >
      IN PROGRESS
    </div>
  );

  const rejectedBox = (
    <div
      style={{
        fontSize: '10px',
        float: 'right',
        fontFamily: 'PublicSans-Bold',
        borderRadius: '3px',
        border: 'solid 1px #d91919',
        backgroundColor: '#fff5f5',
        color: '#d91919',
        textAlign: 'center',
        display: 'inline-block',
        height: '28px',
        width: '90px',
        marginRight: '10px',
      }}
    >
      <Icon
        style={{
          float: 'left',
          lineHeight: 'inherit',
          padding: '1px 0px 0px 10px',
        }}
        name="close circle"
        color="red"
      ></Icon>
      REJECTED
    </div>
  );

  //set data upon fetch
  useEffect(() => {
    if (taskDetail && taskDetail.reportFindings) {
      const payload = taskDetail.reportFindings;

      if (payload.delegated === true) {
        setDelegatedTasks(true);
      }

      if (payload.length > 0) {
        setReviewFindings(payload);
        if (payload.length > 1) {
          let count = 0;
          payload.forEach(e => {
            count += e.findingsCount;
          });
          setFindingsCount(count);
        }
      } else {
        redirectToTasksSummary();
      }
    } else {
      if (
        props &&
        props.params &&
        props.params.reviewId &&
        props.params.conversationId
      ) {
        dispatch(
          fetchReportFindingsDetail({
            reviewId: props.params.reviewId,
            conversationId: props.params.conversationId,
          })
        );
      } else {
        redirectToTasksSummary();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetail]);

  const redirectToTasksSummary = () => {
    props.router.push('/task');
  };

  const generateReviewDetails = data => {
    const divStyle = { marginRight: '3em' };
    const boldStyle = { fontFamily: 'PublicSans-Bold' };
    if (!data || !data.reviewType) {
      return null;
    }

    const addComment = `/add-comment?key=${data.hashContent}`;
    const reportLink = (
      <a
        href={addComment}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Report Link for ${data.reviewId}`}
      >
        Report Link
      </a>
    );

    return (
      <>
        <span style={divStyle}>
          <span style={boldStyle}>Review Type: </span>
          {data.reviewType}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Review Status: </span>
          {data.reviewStatus}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Review Outcome: </span>
          {data.reviewOutcome}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>State: </span>
          {data.state}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Agency ID: </span>
          {data.agencyId}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}>Start Date: </span>
          {AmsDateFormatters.formatDate(data.reviewStartDate)}
        </span>

        <span style={divStyle}>
          <span style={boldStyle}>Due Date: </span>
          {AmsDateFormatters.formatDate(data.reviewEndDate)}
        </span>
        <span style={divStyle}>
          <span style={boldStyle}> {reportLink} </span>
        </span>
      </>
    );
  };

  const generateDelegatedTaskDetails = data => {
    const newView =
      newReviewTypes.includes(data.reviewType) ||
      (data.isNewForms === true &&
        newFormReviewTypes.includes(data.reviewType));

    return data.findings.map((e, index) => {
      // const headerStyle = { marginBottom: '10px', fontSize: '12px' };
      return (
        <Segment.Group key={index}>
          <Segment>
            <Header as="h4" color="blue">
              Finding {index + 1}
              <span
                style={{ color: 'black', float: 'right', marginRight: '-10px' }}
              >
                {e.feedbackStatus !== null
                  ? e.feedbackStatus === 'Approved'
                    ? approvedBox
                    : e.feedbackStatus === 'Rejected'
                    ? rejectedBox
                    : e.feedbackStatus === 'Need Clarification'
                    ? needClarificationBox
                    : e.feedbackStatus === 'In Progress'
                    ? inProgressBox
                    : null
                  : null}
              </span>
            </Header>
          </Segment>

          <Grid as={Segment} style={{ margin: '5px' }}>
            <Grid.Row
              columns="4"
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <Grid.Column>
                <span style={{ marginRight: '10px' }}>{e.findingType}</span>{' '}
                <Link
                  to={{
                    pathname: `/review/citations/definition`,
                    query: { citation: e.citation },
                  }}
                  target="_blank"
                >
                  <Popup
                    trigger={
                      <Label
                        style={{
                          color: '#245e9d',
                          background: 'white',
                          marginLeft: '-10px',
                          fontSize: '14px',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {' '}
                        {e ? `${e.citation}` : ''}
                      </Label>
                    }
                    content="Select to see Citation Definition"
                  />
                </Link>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginLeft: '5px', marginRight: '5px' }}>
              <Grid.Column>
                <Header as="h5" style={{ fontSize: '12px' }}>
                  {newView ? 'Collected Data and Narrative' : 'Narrative'}
                  {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                  <span
                    style={{
                      color: 'black',
                      float: 'right',
                      marginRight: '-15px',
                    }}
                  ></span>
                </Header>
                <Message>
                  {!newView ? (
                    <></>
                  ) : (
                    <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                      {e.collectedData?.map(x => (
                        <li>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(x),
                            }}
                          ></span>
                        </li>
                      ))}
                    </ul>
                  )}
                  <br />
                  <span
                    style={{ whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(e.narrative),
                    }}
                  ></span>
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment.Group>
      );
    });
  };

  const generateFindingsDetails = (data, idx) => {
    if (!data || !data.reviewType) {
      return null;
    }

    const newView =
      newReviewTypes.includes(data.reviewType) ||
      (data.isNewForms === true &&
        newFormReviewTypes.includes(data.reviewType));

    return data.findings.map((e, index) => {
      const headerStyle = { marginBottom: '10px', fontSize: '12px' };
      return (
        <Segment.Group key={index}>
          <Segment>
            <Header as="h4" color="blue">
              Finding {index + 1}
              <span
                style={{ color: 'black', float: 'right', marginRight: '-10px' }}
              >
                {e.feedbackStatus !== null
                  ? e.feedbackStatus === 'Approved'
                    ? approvedBox
                    : e.feedbackStatus === 'Rejected'
                    ? rejectedBox
                    : e.feedbackStatus === 'Need Clarification'
                    ? needClarificationBox
                    : e.feedbackStatus === 'In Progress'
                    ? inProgressBox
                    : null
                  : null}
                {e.provideFeedback && (
                  <ReportTrackerFindingsModal
                    type="feedback"
                    level="finding"
                    data={data}
                    index={index}
                    allFindings={
                      (reviewFindings && reviewFindings.length > 1) ||
                      (props.params.reviewId === undefined ? true : false)
                    }
                  />
                )}
              </span>
            </Header>
          </Segment>

          <Grid as={Segment} style={{ margin: '5px' }}>
            <Grid.Row
              columns="4"
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <Grid.Column>
                <Header
                  style={{ marginBottom: '7px', fontSize: '12px' }}
                  as="h5"
                >
                  Citations
                </Header>
                <Link
                  to={{
                    pathname: `/review/citations/definition`,
                    query: { citation: e.citation },
                  }}
                  target="_blank"
                >
                  <Popup
                    trigger={
                      <Label
                        style={{
                          color: 'blue',
                          background: 'white',
                          marginLeft: '-10px',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {' '}
                        {e ? e.citation : ''}
                      </Label>
                    }
                    content="Select to see Citation Definition"
                  />
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Header style={headerStyle} as="h5">
                  {newView ? 'Content Area' : 'Guide Name'}
                </Header>
                {newView ? e.contentAreas?.join(', ') : e.guideName}
              </Grid.Column>
              <Grid.Column>
                <Header style={headerStyle} as="h5">
                  {newView ? 'Performance Area' : 'Performance Measure'}
                </Header>
                {newView
                  ? e.performanceAreas?.join(', ')
                  : e.performanceMeasure}
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Header
                  style={{
                    marginBottom: '10px',
                    display: 'inline-block',
                    marginRight: '5px',
                    fontSize: '12px',
                  }}
                  as="h5"
                >
                  Finding Type
                  {e.findingTypeStatus !== null && ` (${e.findingTypeStatus})`}
                </Header>
                <span style={{ marginRight: '-9px' }}>
                  {/*e.provideFeedbackForFindingType && (
                    <ReportTrackerFindingsModal
                      type="feedback"
                      level="Finding Type"
                      data={data}
                      index={index}
                      allFindings={reviewFindings && reviewFindings.length > 1}
                    />
                  )*/}
                </span>
                <div style={{ marginRight: '16px' }}>{e.findingType}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginLeft: '5px', marginRight: '5px' }}>
              <Grid.Column>
                <Header as="h5" style={{ fontSize: '12px' }}>
                  {newView ? 'Collected Data and Narrative' : 'Narrative'}
                  {e.narrativeStatus !== null && ` (${e.narrativeStatus})`}
                  <span
                    style={{
                      color: 'black',
                      float: 'right',
                      marginRight: '-15px',
                    }}
                  ></span>
                </Header>
                <Message>
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {!newView ? (
                      <></>
                    ) : (
                      <ul style={{ margin: '0px', paddingLeft: '10px' }}>
                        {e.collectedData?.map(x => (
                          <li>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(x),
                              }}
                            ></span>
                          </li>
                        ))}
                      </ul>
                    )}
                    <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(e.narrative),
                      }}
                    ></div>
                  </div>
                </Message>
              </Grid.Column>
            </Grid.Row>
            {e.comments && e.comments.length > 0 && (
              <Grid.Row>
                <Grid.Column>
                  {generateCommentsView(index, idx, data)}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment.Group>
      );
    });
  };

  const generateDelegatedFindingsView = (data, index) => {
    const title = `${data.reviewType} Review ID - ${data.reviewId} | Findings (${data.findingsCount}) | ${data.grantType}: `;
    const granteeLink = `${data.granteeName} (${data.granteeId})`;
    const isActive =
      activePanelsObj.hasOwnProperty(data.conversationId) &&
      activePanelsObj[data.conversationId] === true;

    return (
      <React.Fragment key={index}>
        <Accordion.Title
          index={index}
          active={isActive}
          style={{ backgroundColor: '#f9fcff' }}
          //onClick={e => handleClick(idx, e)}
        >
          <Header as="h3">
            {title}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/grantee/${data ? data.granteeId : ''}`}
            >
              {granteeLink}
            </a>
            <div style={{ float: 'right' }} className="actionButtons">
              {data && data.provideFeedback && (
                <ReportTrackerFindingsModal
                  type="feedback"
                  context="taskSummaryPage"
                  level="review"
                  index={data}
                  allFindings={true}
                  delegated={true}
                />
              )}

              <Link
                to="#"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  float: 'right',
                }}
                onClick={e => {
                  e.preventDefault();

                  const found = activePanelsObj.hasOwnProperty(
                    data.conversationId
                  );

                  const newActiveObj = { ...activePanelsObj };

                  if (found) {
                    newActiveObj[data.conversationId] = !newActiveObj[
                      data.conversationId
                    ];
                  } else {
                    newActiveObj[data.conversationId] = true;
                  }

                  setActivePanelsObj(newActiveObj);
                }}
              >
                <Icon style={{ float: 'right' }} name="dropdown" />
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Select to expand or collapse review
                </span>
              </Link>
            </div>
            {data && data.findingsFeedbackStatus !== null
              ? data.findingsFeedbackStatus === 'Approved'
                ? approvedBox
                : data.findingsFeedbackStatus === 'Rejected'
                ? rejectedBox
                : data.findingsFeedbackStatus === 'Need Clarification'
                ? needClarificationBox
                : data.findingsFeedbackStatus === 'In Progress'
                ? inProgressBox
                : null
              : null}
          </Header>
        </Accordion.Title>
        <Accordion.Content active={isActive}>
          <div
            style={{
              backgroundColor: '#f9fcff',
              marginTop: '-10px',
              marginLeft: '-14px',
              paddingLeft: '14px',
              marginRight: '-14px',
              paddingRight: '14px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            {generateReviewDetails(data)}
          </div>
          {generateDelegatedTaskDetails(data)}
        </Accordion.Content>
      </React.Fragment>
    );
  };

  const generateSingleDelegatedReviewView = data => {
    const title = `${data.reviewType} Review ID - ${data.reviewId} | Findings (${data.findingsCount}) | ${data.grantType}: `;
    const granteeLink = `${data.granteeName} (${data.granteeId})`;
    return (
      <>
        <Header as="h3">
          {title}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/grantee/${data ? data.granteeId : ''}`}
          >
            {granteeLink}
          </a>
          <div style={{ float: 'right' }} className="actionButtons">
            {data && data.provideFeedback && (
              <ReportTrackerFindingsModal
                type="feedback"
                context="taskSummaryPage"
                level="review"
                allFindings={props.params.reviewId === undefined ? true : false}
                index={data}
                delegated={true}
              />
            )}
          </div>
          {data && data.findingsFeedbackStatus !== null
            ? data.findingsFeedbackStatus === 'Approved'
              ? approvedBox
              : data.findingsFeedbackStatus === 'Rejected'
              ? rejectedBox
              : data.findingsFeedbackStatus === 'Need Clarification'
              ? needClarificationBox
              : data.findingsFeedbackStatus === 'In Progress'
              ? inProgressBox
              : null
            : null}
        </Header>
        <Grid stackable style={{ width: '100' }}>
          <Grid.Row>
            <Grid.Column>
              <Segment>{generateReviewDetails(data)}</Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column> {generateDelegatedTaskDetails(data)}</Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  };

  const generateCommentsView = (type, index, data) => {
    const active = activeComments[index];
    const expanded =
      active && active[type] !== undefined && active[type] !== false;

    const comments =
      type === 'all' ? data.comments : data.findings[type].comments;

    const handleExpandComments = () => {
      const copyComments = { ...activeComments };

      if (copyComments[index] && copyComments[index][type] === true) {
        copyComments[index][type] = false;
      } else {
        if (copyComments[index] === undefined) copyComments[index] = {};
        copyComments[index][type] = true;
      }

      setActiveComments(copyComments);
    };

    return (
      <Accordion
        className={type === 'all' ? '' : 'finding-level-comments'}
        style={{ boxShadow: 'none' }}
      >
        <Accordion.Title
          active={expanded === true}
          index={0}
          //onClick={handleExpandComments}
        >
          <Link
            to="#"
            style={{ textDecoration: 'none' }}
            onClick={e => {
              e.preventDefault();
              handleExpandComments(e);
            }}
          >
            <Icon name="dropdown" />
            View All Comments
          </Link>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          <Comment.Group style={{ marginLeft: '15px' }}>
            {comments.map(e => {
              return (
                <Comment>
                  <Comment.Content>
                    <Comment.Author as="span">{e.commentBy}</Comment.Author>
                    <Comment.Metadata>
                      <div>
                        {AmsDateFormatters.formatDateTime(e.updatedTime)}
                      </div>
                    </Comment.Metadata>
                    <Comment.Text>{e.comment}</Comment.Text>
                  </Comment.Content>
                  {e.responses && e.responses.length > 0 && (
                    <Comment.Group>
                      {_.orderBy(e.responses, ['updatedTime'], ['desc']).map(
                        reply => {
                          return (
                            <Comment>
                              <Comment.Content>
                                <Comment.Author as="span">
                                  {reply.responseBy}
                                </Comment.Author>
                                <Comment.Metadata>
                                  <div>
                                    {' '}
                                    {AmsDateFormatters.formatDateTime(
                                      reply.updatedTime
                                    )}
                                  </div>
                                </Comment.Metadata>
                                <Comment.Text>{reply.response}</Comment.Text>
                              </Comment.Content>
                            </Comment>
                          );
                        }
                      )}
                    </Comment.Group>
                  )}
                </Comment>
              );
            })}
          </Comment.Group>
        </Accordion.Content>
      </Accordion>
    );
  };

  const generateSingleReviewFindingsView = data => {
    if (delegatedTasks) {
      return generateSingleDelegatedReviewView(data);
    }
    const title = `${data.reviewType} Review ID - ${data.reviewId} | Findings (${data.findingsCount}) | ${data.grantType}: `;
    const granteeLink = `${data.granteeName} (${data.granteeId})`;

    const hasCommentAll = data.comments && data.comments.length > 0;
    return (
      <>
        <Header as="h3">
          {title}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/grantee/${data ? data.granteeId : ''}`}
          >
            {granteeLink}
          </a>
          <div style={{ float: 'right' }} className="actionButtons">
            {data && data.forwardFindings && (
              <ReportTrackerFindingsModal
                type="forward"
                data={data}
                allFindings={reviewFindings && reviewFindings.length > 1}
              />
            )}
          </div>
          {data && data.findingsFeedbackStatus !== null
            ? data.findingsFeedbackStatus === 'Approved'
              ? approvedBox
              : data.findingsFeedbackStatus === 'Rejected'
              ? rejectedBox
              : data.findingsFeedbackStatus === 'Need Clarification'
              ? needClarificationBox
              : data.findingsFeedbackStatus === 'In Progress'
              ? inProgressBox
              : null
            : null}
        </Header>
        <Grid stackable style={{ width: '100' }}>
          <Grid.Row>
            <Grid.Column>
              <Segment>{generateReviewDetails(data)}</Segment>
            </Grid.Column>
          </Grid.Row>
          {hasCommentAll && (
            <Grid.Row>
              <Grid.Column>
                <Segment>{generateCommentsView('all', 0, data)}</Segment>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>{generateFindingsDetails(data)}</Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  };

  const generateReviewFindingsView = (data, index) => {
    const title = `${data.reviewType} Review ID - ${data.reviewId} | Findings (${data.findingsCount}) | ${data.grantType}: `;
    const granteeLink = `${data.granteeName} (${data.granteeId})`;

    const isActive =
      activePanelsObj.hasOwnProperty(data.conversationId) &&
      activePanelsObj[data.conversationId] === true;

    const hasCommentAll = data.comments && data.comments.length > 0;

    return (
      <React.Fragment key={index}>
        <Accordion.Title
          index={index}
          active={isActive}
          style={{ backgroundColor: '#f9fcff' }}
          //onClick={e => handleClick(idx, e)}
        >
          <Header as="h3">
            {title}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/grantee/${data ? data.granteeId : ''}`}
            >
              {granteeLink}
            </a>
            <div style={{ float: 'right' }} className="actionButtons">
              {data && data.forwardFindings && (
                <ReportTrackerFindingsModal
                  type="forward"
                  data={data}
                  allFindings={reviewFindings && reviewFindings.length > 1}
                />
              )}
              <Link
                to="#"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  float: 'right',
                }}
                onClick={e => {
                  e.preventDefault();

                  const found = activePanelsObj.hasOwnProperty(
                    data.conversationId
                  );

                  const newActiveObj = { ...activePanelsObj };

                  if (found) {
                    newActiveObj[data.conversationId] = !newActiveObj[
                      data.conversationId
                    ];
                  } else {
                    newActiveObj[data.conversationId] = true;
                  }

                  setActivePanelsObj(newActiveObj);
                }}
              >
                <Icon style={{ float: 'right' }} name="dropdown" />
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Select to expand or collapse review
                </span>
              </Link>
            </div>
            {data && data.findingsFeedbackStatus !== null
              ? data.findingsFeedbackStatus === 'Approved'
                ? approvedBox
                : data.findingsFeedbackStatus === 'Rejected'
                ? rejectedBox
                : data.findingsFeedbackStatus === 'Need Clarification'
                ? needClarificationBox
                : data.findingsFeedbackStatus === 'In Progress'
                ? inProgressBox
                : null
              : null}
          </Header>
        </Accordion.Title>
        <Accordion.Content
          active={isActive}
          className="view-all-findings-accordion"
        >
          <div
            style={{
              backgroundColor: '#f9fcff',
              marginTop: '-10px',
              marginLeft: '-14px',
              paddingLeft: '14px',
              marginRight: '-14px',
              paddingRight: '14px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            {generateReviewDetails(data)}
          </div>
          {hasCommentAll && (
            <Segment
              className="all-findings-comment-view"
              style={{ padding: '10px', paddingLeft: '0px' }}
            >
              {generateCommentsView('all', 0, data)}
            </Segment>
          )}
          {generateFindingsDetails(data, index)}
        </Accordion.Content>
      </React.Fragment>
    );
  };

  const generateReviewList = () => {
    return (
      <Accordion
        exclusive={false}
        style={{
          boxShadow:
            '0 16px 28px -1px rgba(14, 31, 53, 0.1), 0 8px 16px 0 rgba(14, 31, 53, 0.16), 0 4px 8px 0 rgba(14, 31, 53, 0.1)',
          width: '100%',
        }}
        styled
      >
        {reviewFindings.map((e, index) => {
          if (delegatedTasks === true) {
            return generateDelegatedFindingsView(e, index);
          }
          return generateReviewFindingsView(e, index);
        })}
      </Accordion>
    );
  };

  const expandAllReviews = () => {
    const activeObj = {};
    for (let i = 0; i < reviewFindings.length; i++) {
      activeObj[reviewFindings[i].conversationId] = true;
    }

    setActivePanelsObj(activeObj);
  };

  const collapseAllReviews = () => {
    setActivePanelsObj({});
  };

  const renderCollapseExpandActions = () => {
    let collapseAll;
    let expandAll;

    const activeKeys = Object.keys(activePanelsObj);

    if (activeKeys.length === 0) {
      collapseAll = true;
    } else if (activeKeys.length === reviewFindings.length) {
      expandAll = true;
    }
    const expandAllComponent =
      expandAll === true ? (
        <span>Expand All</span>
      ) : (
        <Link
          to="#"
          onClick={e => {
            e.preventDefault();
            expandAllReviews();
          }}
        >
          Expand All
        </Link>
      );

    const collapseAllComponent =
      collapseAll === true ? (
        <span>Collapse All</span>
      ) : (
        <Link
          to="#"
          onClick={e => {
            e.preventDefault();
            collapseAllReviews();
          }}
        >
          Collapse All
        </Link>
      );

    return (
      <>
        {expandAllComponent}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        {collapseAllComponent}
      </>
    );
  };

  const renderDelegatedActionButtons = () => {
    return (
      <>
        <ReportTrackerFindingsModal
          type="feedback"
          context="taskSummaryPage"
          level="review"
          allDelegated={true}
          delegatedFindingsView={true}
          index={
            (reviewFindings &&
              reviewFindings.filter(e => {
                return e.provideFeedback !== false;
              })) ||
            []
          }
          allFindings={false}
          delegated={true}
        />
        <Button
          style={{ backgroundColor: 'rgb(18,64,141)', color: 'white' }}
          onClick={() => {
            dispatch(exportDelegatedTasks());
          }}
        >
          Export
        </Button>
      </>
    );
  };

  const headerMargin = delegatedTasks === true ? '60px' : '20px';

  const delegatedStyleCollapse = {
    fontSize: '12px',
    display: 'block',
    position: 'absolute',

    right: '25px',
    marginTop: '10px',
  };

  return (
    <SlidingContainer title={'Tasks'}>
      {reviewFindings.length > 1 ? (
        <>
          <Header style={{ paddingBottom: headerMargin }} as="h2">
            All Findings ({findingsCount})
            {delegatedTasks === true && (
              <div
                style={{
                  float: 'right',
                  fontSize: '12px',
                }}
                className="delegatedActionButtons"
              >
                {renderDelegatedActionButtons()}
              </div>
            )}
            <div
              style={
                delegatedTasks === true
                  ? delegatedStyleCollapse
                  : { float: 'right', fontSize: '12px' }
              }
              className="actionButtons"
            >
              {renderCollapseExpandActions()}
            </div>
          </Header>
          {generateReviewList()}
        </>
      ) : (
        generateSingleReviewFindingsView(
          reviewFindings.length > 0 && reviewFindings[0]
        )
      )}
    </SlidingContainer>
  );
};
export default ReportTrackerFindingsPage;
