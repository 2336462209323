import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Header, Form, Button } from 'semantic-ui-react';

import { addPosition } from '../../../actions/reviewActions';
import AmsModal from '../../../utils/AmsModal';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import { includes, filter } from 'lodash';
import AmsAlert from '../../../utils/AmsAlert';

const ReviewerPositionForm = props => {
  const { positions, reviewers } = useSelector(
    state => state.review.reviewInfo
  );
  const [reviewerPositionSkills, setReviewerPosition] = useState({
    languageSkills: [],
    contentAreaSkills: [],
    otherSkills: [],
    reviewersRequired: null,
  });
  const [showReviewersCountAlert, setShowReviewersCountAlert] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.reviewerPositionSkills)
      setReviewerPosition(props.reviewerPositionSkills);
  }, [props.reviewerPositionSkills]);

  const showReviewersCountModal = () => {
    return (
      <AmsAlert
        show={showReviewersCountAlert}
        type={'error'}
        showConfirm
        title={'Oops...'}
        text={
          'The number of reviewers already invited/assigned to this review is greater than the selected number of reviewers needed. Please withdraw assigned reviewers to match the number of reviewers you need.'
        }
        onConfirm={() => {
          setShowReviewersCountAlert(false);
        }}
      />
    );
  };

  const editReviewerPositions = () => {
    let positionsList = [...positions];
    let count = filter(reviewers, reviewer => {
      return (
        reviewer.position === reviewerPositionSkills._id &&
        reviewer.status !== 'withdrawn' &&
        reviewer.status !== 'declined' &&
        reviewer.status !== 'invitationRescinded'
      );
    }).length;
    if (reviewerPositionSkills.reviewersRequired < count) {
      setShowReviewersCountAlert(true);
      return;
    }
    if (props.editPosition) {
      positionsList[reviewerPositionSkills._id] = {
        ...reviewerPositionSkills,
      };
    } else
      positionsList.push({
        ...reviewerPositionSkills,
        active: true,
      });

    const updatedPositions = positionsList.map((position, index) => {
      position = {
        ...position,
        _id: position.index ? position.index.toString() : index.toString(),
        name: position.name ? position.name : `Position ${index + 1}`,
      };

      return position;
    });

    if (props.editPosition) dispatch(addPosition(updatedPositions));
    else props.setUpdatedPositions(updatedPositions);
    setReviewerPosition({});
    if (props.setEditPosition) props.setEditPosition(false);
    props.handleModalClose();
  };

  const onChangeAddSkills = (e, { name, value }) => {
    if (name === 'reviewersRequired') {
      value = Number(value);
    }
    let reviewerPosition = reviewerPositionSkills;
    reviewerPosition = {
      ...reviewerPosition,
      [name]: value,
    };

    setReviewerPosition(reviewerPosition);
  };

  const addPositionModal = () => {
    const checkReviewerStatus = reviewers.map(reviewer => {
      if (
        reviewer.position === reviewerPositionSkills._id &&
        reviewer.status !== 'withdrawn'
      )
        return true;
      else return false;
    });
    const enableEdit = includes(checkReviewerStatus, true) ? false : true;
    return (
      <AmsModal
        size="small"
        centered={false}
        closeOnDimmerClick={false}
        onClose={props.handleModalClose}
        open={props.showReviewerPositionAddModal}
        className="ams-semantic-modal-fix"
        closeOnEscape={false}
        // style={{ overflowY: 'hidden' }}
      >
        <Header
          content={
            enableEdit
              ? 'Select skills for position'
              : 'Edit the number of reviewers'
          }
        />
        <Modal.Content>
          <Modal.Description>
            <Form.Field required>
              <AmsFormLabel
                name="How many reviewers do you need for this position?"
                fieldLabel="reviewersRequired"
              >
                <Form.Input
                  width={7}
                  required
                  name="reviewersRequired"
                  placeholder="Enter the number of reviewers needed"
                  value={
                    reviewerPositionSkills &&
                    reviewerPositionSkills.reviewersRequired
                      ? reviewerPositionSkills.reviewersRequired
                      : null
                  }
                  onChange={(e, data) => onChangeAddSkills(e, data)}
                  // control={Input}
                  style={{ width: '100%' }}
                  type="number"
                  id="reviewersRequired"
                  fluid
                  search
                  selection
                />
              </AmsFormLabel>
            </Form.Field>
            {enableEdit && (
              <>
                <Form.Field required>
                  <AmsFormLabel
                    name="Content Area Skills"
                    fieldLabel="contentAreaSkills"
                  >
                    <AmsLookupDropdown
                      width={7}
                      name="contentAreaSkills"
                      placeholder="Select Content Area Skills"
                      value={
                        reviewerPositionSkills &&
                        reviewerPositionSkills.contentAreaSkills &&
                        reviewerPositionSkills.contentAreaSkills.length > 0
                          ? reviewerPositionSkills.contentAreaSkills
                          : []
                      }
                      onChange={(e, data) => onChangeAddSkills(e, data)}
                      category={'contentAreaSkills'}
                      fluid
                      multiple
                      selection
                      id="contentAreaSkills"
                    />
                  </AmsFormLabel>
                </Form.Field>
                <Form.Field>
                  <AmsFormLabel
                    name="Language Skills"
                    fieldLabel="languageSkills"
                  >
                    <AmsLookupDropdown
                      name="languageSkills"
                      placeholder="Select Language Skills"
                      value={
                        reviewerPositionSkills &&
                        reviewerPositionSkills.languageSkills &&
                        reviewerPositionSkills.languageSkills.length > 0
                          ? reviewerPositionSkills.languageSkills
                          : []
                      }
                      onChange={(e, data) => onChangeAddSkills(e, data)}
                      category={'languages'}
                      fluid
                      multiple
                      selection
                      id="languageSkills"
                    />
                  </AmsFormLabel>
                </Form.Field>
                <Form.Field>
                  <AmsFormLabel name="Other Skills" fieldLabel="otherSkills">
                    <AmsLookupDropdown
                      width={7}
                      name="otherSkills"
                      placeholder="Select Other Skills"
                      value={
                        reviewerPositionSkills &&
                        reviewerPositionSkills.otherSkills &&
                        reviewerPositionSkills.otherSkills.length > 0
                          ? reviewerPositionSkills.otherSkills
                          : []
                      }
                      onChange={(e, data) => onChangeAddSkills(e, data)}
                      category={'otherSkills'}
                      fluid
                      multiple
                      selection
                      id="otherSkills"
                    />
                  </AmsFormLabel>
                </Form.Field>
              </>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={props.handleModalClose} content="Close" />
          <Button
            disabled={
              ((reviewerPositionSkills.contentAreaSkills &&
                reviewerPositionSkills.contentAreaSkills.length > 0) ||
                (reviewerPositionSkills.languageSkills &&
                  reviewerPositionSkills.languageSkills.length > 0) ||
                (reviewerPositionSkills.otherSkills &&
                  reviewerPositionSkills.otherSkills.length > 0)) &&
              reviewerPositionSkills.reviewersRequired &&
              reviewerPositionSkills.reviewersRequired > 0
                ? false
                : true
            }
            onClick={() => {
              editReviewerPositions();
              props.dataHasChangedSwitch(true);
            }}
            primary
            content="Save Reviewer position"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  return (
    <>
      {showReviewersCountModal()}
      {addPositionModal()}
    </>
  );
};

export default ReviewerPositionForm;
