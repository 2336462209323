import React from 'react';
import { Button } from 'semantic-ui-react';

export default function GenerateReportButton(props) {
  return (
    <Button
      className="dropdown-item"
      style={{
        background: 'none',
        borderStyle: 'none',
        textAlign: 'left',
        color: 'black',
      }}
      onClick={
        () => {
          let message =
            'You are about to generate the Report. Are you sure you want to continue?';
          props.showAlertToggle(true, message, 'generateReport');
          console.log('generate report');
        }
        // this.generateReport(this.props.reportVersionData.versionStatus)
      }
      content="Generate Report"
    />
  );
}
