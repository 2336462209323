/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  dataURLtoBlob,
  humanizeFileSize,
  addNameToDataURL,
  removeNameFromDataURL,
} from './helpers/utils';
import MimeIcon from './helpers/MimeIcon';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';

import { prepData } from './helpers/utils';
import { differenceInObjects } from '../../Shared/FormHelpers/helpers/utils.js';

// Import actions
import { downloadSurveyAttachment } from '../../../actions/surveyActions';
import { fetchAmsLookup } from '../../../actions/lookupActions';

class AttachmentField extends Component {
  state = {
    downloading: false,
    id: '',
    name: '',
    file: this.props.value,
    showError: false,
  };

  componentDidMount = () => {
    this.props.fetchAmsLookup('fileTypes');
    if (this.props.formData) {
      this.setState({ ...prepData(this.props.formData) });
    }

    this.reader = new FileReader();
    this.reader.addEventListener('load', this.handleFileLoad, true);
  };

  componentWillUnmount() {
    this.reader.removeEventListener('load', this.handleFileLoad, true);
  }

  componentWillReceiveProps(nextProps) {
    const parent = _.find(nextProps.fileTypes, { parent: true }); // Find lookup category parent.
    const activeFileTypes = _.filter(nextProps.fileTypes, {
      parent: false,
      active: true,
    }); // Find active lookup items.
    if (parent && parent.active) {
      if (activeFileTypes.length) {
        let allowedFileTypes = activeFileTypes.map(fileType => {
          return `${fileType.value.toLowerCase()}`;
        });
        this.setState({ ...this.state, allowedFileTypes });
      }
    }

    const diff = differenceInObjects(this.props, nextProps);

    if (
      _.isEmpty(diff) &&
      !(this.state.file === undefined && this.props.formData && this.state.name)
    ) {
      return;
    }

    if (!_.isEqual(nextProps.formData, this.props.formData)) {
      if (!nextProps.formData && !this.props.formData) {
        this.setState({
          downloading: false,
          id: '',
          name: '',
          file: this.props.value,
        });
      } else {
        this.setState({ ...prepData(nextProps.formData) });
      }
    } else {
      if (this.state.name && !this.state.id && !this.state.file) {
        this.setState({
          ...this.state,
          file: removeNameFromDataURL(nextProps.formData),
        });
      }
    }
  }

  handleFileLoad = () => {
    if (!this.state.showError) {
      this.setState({ file: this.reader.result }, () => {
        if (this.props.onChange && this.state.file && this.state.name) {
          const dataUrlWithName = addNameToDataURL(
            this.state.file,
            this.state.name
          );
          if (dataUrlWithName) this.props.onChange(dataUrlWithName);
        }
      });
    }
  };

  handleOnChange = e => {
    if (!_.includes(this.state.allowedFileTypes, e.target.files[0].type)) {
      this.setState({ showError: true });
      return;
    } else if (e.target.files && e.target.files.length) {
      this.setState({
        name: e.target.files[0].name,
        type: e.target.files[0].type,
        size: e.target.files[0].size,
        lastModified: e.target.files[0].lastModified,
        showError: false,
      });
      this.reader.readAsDataURL(e.target.files[0]);
    }
  };

  showError = () => {
    return (
      <Message negative>
        <Message.Header>Unsupported file type</Message.Header>
        <p>The file you selected is not supported</p>
      </Message>
    );
  };

  triggerDownload = e => {
    e.preventDefault();
    if (this.state.id && this.state.name) {
      this.setState({ downloading: true });

      this.props
        .downloadSurveyAttachment(this.state.id, this.state.name)
        .then(() => this.setState({ downloading: false }))
        .catch(() => this.setState({ downloading: false }));
    }
  };

  createFileDownloadLink = () => {
    if (this.state.downloading) return <div>Downloading...</div>;

    // During upload.
    if (this.state.file) {
      const blob = dataURLtoBlob(this.state.file);
      const url = URL.createObjectURL(blob);

      return (
        <a href={url} download={this.state.name}>
          <MimeIcon mime={blob.type} />
          {`${this.state.name}  - (${humanizeFileSize(blob.size)})`}
        </a>
      );
    }

    // During rendering attached file.
    if (this.state.id && this.state.name) {
      let fileName = this.state.name;

      if (this.state.size)
        fileName = `${fileName} - (${humanizeFileSize(this.state.size)})`;

      return (
        <a href="" download={this.state.name} onClick={this.triggerDownload}>
          <MimeIcon mime={this.state.type} />
          {fileName}
        </a>
      );
    }

    return null;
  };

  render() {
    return (
      this.createFileDownloadLink() || (
        <>
          {this.state.showError && this.showError()}
          {this.props.fileTypes && (
            <input
              type="file"
              title="Add supporting documents"
              className="custom"
              value={this.props.value}
              required={this.props.required}
              onChange={this.handleOnChange}
              accept={`${this.state.allowedFileTypes &&
                this.state.allowedFileTypes.length &&
                this.state.allowedFileTypes.join(',')}`}
            />
          )}
        </>
      )
    );
  }
}

const mapStateToProps = state => ({
  fileTypes: state.lookups.amsLookups.fileTypes,
});

export default connect(mapStateToProps, {
  downloadSurveyAttachment,
  fetchAmsLookup,
})(AttachmentField);
