import React from 'react';
import { Dimmer, Grid, Header, List, Loader, Segment } from 'semantic-ui-react';

// React Bootstrap Table 2
import BootstrapTableNext from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';

// Import Utils
import { formatNumber } from '../utils/TextFormatters';

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => {
  let counter = 0;

  const pageOptions = options.map(option => {
    const isSelect = currSizePerPage === `${option.page}`;
    const separator = counter < options.length - 1 ? ', ' : '';
    counter++;

    return (
      <List.Item
        as="a"
        href="#"
        key={option.text}
        className={`${isSelect ? 'active' : ''}`}
        onClick={e => {
          e.preventDefault();
          onSizePerPageChange(option.page);
        }}
      >
        {option.text}
        {separator}
      </List.Item>
    );
  });

  return (
    <>
      <List horizontal className="size-per-page" size="large">
        <List.Item>Show</List.Item>
        {pageOptions}
        <List.Item>results per page</List.Item>
      </List>
    </>
  );
};

const customTotal = (from, to, size, headerSize) => {
  return (
    <Header as={headerSize}>
      Showing {from} to {to} of {formatNumber(size)} Results
    </Header>
  );
};

const options = {
  custom: true,
  showTotal: true,
  paginationSize: 4,
  pageStartIndex: 1,
  withFirstAndLast: true,

  firstPageText: 'First',
  prePageText: 'Previous',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',

  sizePerPageList: [
    {
      text: '10',
      value: 10,
    },
    {
      text: '25',
      value: 25,
    },
    {
      text: '30',
      value: 30,
    },
    {
      text: '50',
      value: 50,
    },
    {
      text: '100',
      value: 100,
    },
    {
      text: '250',
      value: 250,
    },
  ],
};

const AmsTable = ({
  data,
  page,
  total,
  limit,
  loading,
  columns,
  keyField,
  onTableChange,
  onSizePerPageChange,
  onPageChange,
  rowClasses,
  rowEvents,
  rowStyle,
  defaultSorted,
  remote = true,
  basic = false,
  selectRow = false,
  search,
  sizePerPageOption = true,
  expandRow = {},
  noDataIndication = false,
  paginationTotalHeaderSize = 'h2',
  customSize = null,
}) => {
  if (!selectRow) {
    selectRow = {
      mode: 'radio',
      hideSelectColumn: true,
      clickToSelect: false,
    };
  }

  if (!expandRow) {
    expandRow = {};
  }

  columns.forEach(e => {
    e.headerAttrs = {
      scope: 'col',
      tabIndex: e.sort === true ? 0 : -1,
    };
  });

  if (remote === false && customSize) {
    options.sizePerPage = customSize;
  }

  return (
    <PaginationProvider
      pagination={
        remote
          ? paginationFactory({
              ...options,
              page: page || 1,
              sizePerPage: limit || 10,
              totalSize: total || 0,
              onPageChange: onPageChange,
              onSizePerPageChange: onSizePerPageChange,
              sizePerPageRenderer: sizePerPageRenderer,
              paginationTotalRenderer: (from, to, size) =>
                customTotal(from, to, size, paginationTotalHeaderSize),
            })
          : paginationFactory({
              ...options,
              totalSize: total || 0,
              sizePerPageRenderer: sizePerPageRenderer,
              paginationTotalRenderer: (from, to, size) =>
                customTotal(from, to, size, paginationTotalHeaderSize),
            })
      }
    >
      {({ paginationProps, paginationTableProps }) => (
        <div id="results" className="ams-table" role="search">
          {loading && (
            <Dimmer active inverted>
              {/* <Loader active inline="centered" content="Loading results..." /> */}
              <Loader content="Loading results..." />
            </Dimmer>
          )}
          {!basic && <PaginationTotalStandalone {...paginationProps} />}
          <BootstrapTableNext
            noDataIndication={noDataIndication}
            expandRow={expandRow}
            rowClasses={rowClasses}
            rowEvents={rowEvents}
            defaultSorted={defaultSorted}
            remote={remote === true}
            hover
            selectRow={selectRow}
            striped
            bordered={false}
            keyField={keyField || '_id'}
            data={data || []}
            columns={columns || []}
            onTableChange={onTableChange || null}
            search={search || null}
            {...paginationTableProps}
            rowStyle={rowStyle}
          />
          <Segment basic>
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  {sizePerPageOption && (
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  )}
                </Grid.Column>
                <Grid.Column>
                  <PaginationListStandalone {...paginationProps} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
      )}
    </PaginationProvider>
  );
};

export default AmsTable;
