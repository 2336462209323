import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

class GranteeCalendarResetMultiMessage extends Component {
  handleDismiss = () => {
    const { closeResetMultiMessage } = this.props;
    closeResetMultiMessage();
  };

  renderResetMultiMessage() {
    let { showResetdMultiMessage } = this.props;
    return showResetdMultiMessage ? (
      <div>
        <Message
          onDismiss={this.handleDismiss}
          header="All changes cancelled!"
          content="All of your changes have been reset. Please select another Grantee to update from the drop down above."
        />
      </div>
    ) : (
      ''
    );
  }

  render() {
    return <div> {this.renderResetMultiMessage()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarResetMultiMessage);
