/* eslint-disable */

import React, { Component } from 'react';

import 'ContentTools';

/*import '../../../assets/content-tools/content-tools';
import '../../../assets/content-tools/content-tools.min.css';*/

class ContentTools extends Component {
  constructor() {
    super();

    this.container = null;
    this.instance = null;

    this.componentDidMount = this.componentDidMount.bind(this);
    this.setContent = this.setContent.bind(this);
    this.getContent = this.getContent.bind(this);
    this.containerNode = this.containerNode.bind(this);

    this.replaceInstance = this.replaceInstance.bind(this);

    this.dataSets = this.dataSets.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.onInit === 'function') {
      this.props.onInit(this);
    }

    window.ContentTools.StylePalette.add([
      new window.ContentTools.Style('By-line', 'article__by-line', ['p']),
      new window.ContentTools.Style('Caption', 'article__caption', ['p']),
      new window.ContentTools.Style('Example', 'example', ['pre']),
      new window.ContentTools.Style('Example + Good', 'example--good', ['pre']),
      new window.ContentTools.Style('Example + Bad', 'example--bad', ['pre']),
    ]);

    // editor.addEventListener('saved', function(ev) {
    //   console.info("ContentTools saved", ev);
    // });
  }

  /**
   * Remove helping editor classes and attributes
   */
  clearEditorContent(content) {
    let node = window.document.createElement('div');

    node.innerHTML = content;

    // Remove attr [contenteditable]
    let n;
    while ((n = node.querySelector('[contenteditable]'))) {
      n.removeAttribute('contenteditable');
    }

    // Remove classes: ce-element ce-element--type-text ce-element--focused
    while (
      (n = node.querySelector(
        '.ce-element, .ce-element--type-text, .ce-element--focused'
      ))
    ) {
      n.className = n.className.replace(
        /\s*(ce\-element|ce\-element\-\-type\-text|ce\-element\-\-focused)\s*/,
        ' '
      );
    }

    return node.innerHTML;
  }

  containerNode() {
    return this.container._owner.child.stateNode;
  }

  setContent(content) {
    if (content.indexOf('data-name=') === -1) {
      content =
        '<div data-editable data-name="content"><p>' + content + '</p></div>';
    }
    this.containerNode().innerHTML = content;

    this.replaceInstance();
  }

  dataSets() {
    let _self = this;
    return Array.prototype.slice
      .call(_self.containerNode().querySelectorAll('[data-name]'))
      .map(function(node) {
        return {
          node: function() {
            return node;
          },
          name: function() {
            return node.getAttribute('data-name');
          },
          readonly: function(status) {
            if (typeof status === 'boolean') {
              if (status) {
                if (node.hasAttribute('data-editable')) {
                  node.removeAttribute('data-editable');
                }
              } else {
                node.setAttribute('data-editable', '');
              }
              _self.replaceInstance();
            } else {
              return !node.hasAttribute('data-editable');
            }
          },
          html: function() {
            return node.innerHTML;
          },
          text: function() {
            return (
              node.textContent ||
              node.innerText ||
              node.innerHTML.replace(/\<.*?\>/g, '')
            );
          },
        };
      });
  }

  getContent() {
    return this.clearEditorContent(this.containerNode().innerHTML);
  }

  replaceInstance() {
    // remove previous editor node
    let editorNode = window.document.body.querySelector('.ct-app');
    if (editorNode) {
      editorNode.parentElement.removeChild(editorNode);
    }

    // create new editor node
    this.instance = window.ContentTools.EditorApp.get();
    this.instance.init('[data-editable]', 'data-name');
  }

  render() {
    this.container = (
      <div className={this.props.className || ''}>{this.props.children}</div>
    );

    return this.container;
  }
}

export default ContentTools;
