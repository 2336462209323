import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Segment,
  Form,
  Menu,
  Grid,
  Button,
  Icon,
  Header,
  Message,
  TextArea,
  Divider,
  Popup,
  Label,
} from 'semantic-ui-react';
import { Link } from 'react-router';
import { orderBy, isEmpty, isNil } from 'lodash';

// Import helper function.
import enforceRole from '../../../utils/EnforceRole';

// Import components.
import FollowUpAttachmentField from './FollowUpAttachmentField';
import FollowUpAdditionalCitationsForm from './FollowUpAdditionalCitationsForm';

import FollowUpCorrectiveStatusInfo from './FollowUpCorrectiveStatusInfo';
// Import utils.
import AmsTable from '../../../utils/AmsTable';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { acl } from '../../../config';

const FollowUpCitationsForm = props => {
  const { user: currentUser } = useSelector(state => state.auth);
  const { followUpTaskDetail, selectedSubmission } = useSelector(
    state => state.submissions
  );
  const [disablebuttons, setDisablebuttons] = useState(false);
  const [citationsList, setCitationsList] = useState([]);
  const [correctedCitationReason, setCorrectedCitationReason] = useState('');
  const correctedCitationReasonProps =
    props.selectedCitationDetails &&
    props.selectedCitationDetails.correctedCitationReason;

  useEffect(() => {
    let citationsList = [];
    let selectedFormDetails = {};

    setCitationsList(createCitationList(citationsList));

    if (props.dataHasChanged) {
      selectedFormDetails = getLatestSelectedFormDetails(
        selectedFormDetails,
        orderBy(citationsList, ['key'], ['desc'])
      );
      // props.fetchData(selectedFormDetails.amsSubmissionId);
    } else if (!isEmpty(props.selectedCitationDetails)) {
      selectedFormDetails = getSelectedFormDetailsByKey(
        citationsList,
        props.selectedCitationDetails.key
      );
    } else {
      selectedFormDetails = getLatestSelectedFormDetails(
        selectedFormDetails,
        citationsList
      );
      props.fetchData(selectedFormDetails.amsSubmissionId);
      props.data.handleDataChanged(props.dataHasChanged);
    }

    props.data.handleSeletedCitationDetails(selectedFormDetails);

    setDisablebuttons(isEmptyObj(selectedFormDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(props.citationsData),
  ]);

  useEffect(() => {
    correctedCitationReason !== correctedCitationReasonProps &&
      setCorrectedCitationReason(correctedCitationReasonProps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctedCitationReasonProps]);

  const getSelectedFormDetailsByKey = (list, key) => {
    return (
      list &&
      !isEmpty(list) &&
      // eslint-disable-next-line array-callback-return
      list.find(listDetails => {
        if (listDetails.key === key) {
          return listDetails;
        }
      })
    );
  };

  const getLatestSelectedFormDetails = (selectedFormDetails, listOne) => {
    selectedFormDetails =
      listOne &&
      !isEmpty(listOne) &&
      listOne.find(listOneDetails => {
        return listOneDetails;
      });

    return selectedFormDetails;
  };

  const createCitationList = citationsList => {
    props.citationsData &&
      // eslint-disable-next-line array-callback-return
      props.citationsData.map((citations, index) => {
        citationsList.push({
          ...citations,
          key: index,
        });
      });

    return citationsList;
  };

  const citationMenuItemTabByType = (citationsDetails, index) => {
    return (
      <Menu.Item
        role="menuitem"
        as={Link}
        aria-labelledby="selectCitations"
        to="#"
        link
        key={index}
        active={
          props.selectedCitationDetails &&
          props.selectedCitationDetails.key === citationsDetails.key
        }
        onClick={e => {
          e.preventDefault();
          handleMenuSelect(e, citationsDetails, index);
        }}
      >
        {citationsDetails && citationsDetails.citation !== null
          ? !citationsDetails.isPreliminaryFinding
            ? !citationsDetails.isActive
              ? `${citationsDetails.citation} (New-Dropped)`
              : citationsDetails.submissionStatus &&
                'submitted' === citationsDetails.submissionStatus.toLowerCase()
              ? `${citationsDetails.citation} (New-Submitted)`
              : citationsDetails.submissionStatus &&
                'draft' === citationsDetails.submissionStatus.toLowerCase()
              ? `${citationsDetails.citation} (New-Draft)`
              : `${citationsDetails.citation} (New-Pending)`
            : citationsDetails.submissionStatus &&
              'submitted' === citationsDetails.submissionStatus.toLowerCase()
            ? `${citationsDetails.citation} (Submitted)`
            : citationsDetails.submissionStatus &&
              'draft' === citationsDetails.submissionStatus.toLowerCase()
            ? `${citationsDetails.citation} (Draft)`
            : `${citationsDetails.citation} (Pending)`
          : citationsDetails.submissionStatus &&
            'draft' === citationsDetails.submissionStatus.toLowerCase()
          ? ` (New-Draft)`
          : `(New-Pending)`}
      </Menu.Item>
    );
  };

  const handleAttachmentChange = (furRecommendation, index, { formData }) => {
    let formattedData = {
      name: 'evidences',
      value: formData.evidences,
    };

    props.data.handleDataChanged(false);
    props.buildFormData(formattedData, index, 'furRecommendations');
  };

  const handleTextChange = (e, data, index) => {
    props.data.handleDataChanged(false);
    props.buildFormData(data, index, 'furRecommendations');
    setCorrectedCitationReason(data.value);
  };

  const handleRadioButtonChange = (e, data, index) => {
    let formattedData = {
      name: data.name,
      value: data.value,
    };

    props.data.handleDataChanged(false);

    props.buildFormData(formattedData, index, 'furRecommendations');
  };

  const handleSelection = (e, data, index) => {
    props.data.handleDataChanged(false);
    props.buildFormData(data, index, 'additionalCitations');
  };

  const handleMenuSelect = (e, furRecommendation, index) => {
    if (
      props.selectedCitationDetails &&
      index !== props.selectedCitationDetails.key
    ) {
      if (props.dataModified) {
        props.data.handleShowMovingAlert();
        props.data.handlePreviousSeletedCitationDetails(
          props.selectedCitationDetails
        );
      } else {
        props.fetchData(furRecommendation.amsSubmissionId);
      }

      if (furRecommendation && isEmpty(furRecommendation.amsSubmissionId)) {
        props.buildFormData(furRecommendation, index, 'CLEARCITATIONDATA');
      }

      props.data.handleSeletedCitationDetails({
        ...furRecommendation,
        index: furRecommendation.key,
      });
      props.data.handleDataChanged(false);
      setDisablebuttons(isEmptyObj(furRecommendation));
    }
  };

  const renderSaveDraftButton = () => {
    if (
      (props.selectedCitationDetails &&
        isEmpty(props.selectedCitationDetails.amsSubmissionId)) ||
      selectedSubmission.amsSubmissionId
    ) {
      if (
        (props.selectedCitationDetails &&
          isEmpty(props.selectedCitationDetails.amsSubmissionId)) ||
        (selectedSubmission &&
          selectedSubmission.surveyStatus &&
          selectedSubmission.surveyStatus.toLowerCase() === 'draft')
      )
        return (
          <Button
            size="large"
            content={'Save as Draft'}
            onClick={e => {
              e.preventDefault();
              props.data.handleSaveDraft();
            }}
            disabled={disablebuttons}
          />
        );
      else return;
    } else
      return (
        <Button
          size="large"
          content={'Save as Draft'}
          onClick={e => {
            e.preventDefault();
            props.data.handleSaveDraft();
          }}
          disabled={disablebuttons}
        />
      );
  };

  const renderSubmitButton = () => {
    const { editMode } = props;
    const { amsSubmissionId } = followUpTaskDetail;

    if (
      props.selectedCitationDetails &&
      !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
      followUpTaskDetail &&
      followUpTaskDetail.isReportFinalized
    )
      return;

    if (
      props.selectedCitationDetails &&
      !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
      followUpTaskDetail &&
      !followUpTaskDetail.isReviewAccessible
    )
      return;

    if (
      props.selectedCitationDetails &&
      !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
      followUpTaskDetail &&
      followUpTaskDetail.readOnly
    )
      return;

    if (
      (!amsSubmissionId &&
        selectedSubmission &&
        !selectedSubmission.amsSubmissionId) ||
      editMode ||
      (selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'draft') ||
      (props.selectedCitationDetails &&
        isEmpty(props.selectedCitationDetails.amsSubmissionId))
    )
      return (
        <Button
          primary
          floated="right"
          type="submit"
          size="large"
          content={'Submit'}
          onClick={e => {
            e.preventDefault();
            props.data.handleSubmit();
          }}
          disabled={disablebuttons}
        />
      );
  };

  const renderCancelButton = () => {
    const { editMode } = props;

    if (
      (props.selectedCitationDetails &&
        isEmpty(props.selectedCitationDetails.amsSubmissionId)) ||
      !editMode
    )
      return;
    else
      return enforceRole(
        <Button
          negative
          floated="right"
          size="large"
          content={'Cancel'}
          onClick={() => {
            props.data.handleCancel();
          }}
          disabled={disablebuttons}
        />,
        acl.actions.followUp.editSubmissions,
        currentUser.roles
      );
  };

  const isEmptyObj = object => {
    if (object && object.isPreliminaryFinding) {
      if (
        isEmpty(object.correctedCitationReason) &&
        isEmpty(object.granteeCorrectedCitationResponse) &&
        (isEmpty(object.evidences) || 'undefined' === object.evidences)
      ) {
        return true;
      }
    } else {
      if (
        object &&
        isEmpty(object.guideName) &&
        isEmpty(object.performanceMeasure) &&
        isEmpty(object.citation) &&
        (isEmpty(object.correctedCitationReason) ||
          'undefined' === object.correctedCitationReason) &&
        (isEmpty(object.evidences) || 'undefined' === object.evidences)
      ) {
        return true;
      }
    }

    return false;
  };

  const renderRemoveAdditionalCitation = () => {
    return (
      <Button
        icon
        floated="right"
        size="large"
        onClick={e => {
          e.preventDefault();
          props.removeAdditionalCitation(
            props.selectedCitationDetails && props.selectedCitationDetails.key
          );
        }}
        disabled={disablebuttons}
        color="red"
        labelPosition="left"
      >
        Remove Citation
        <Icon name="remove" />
      </Button>
    );
  };

  const renderEditButton = () => {
    if (followUpTaskDetail && followUpTaskDetail.isReportFinalized) return;

    if (followUpTaskDetail && followUpTaskDetail.readOnly) return;

    if (followUpTaskDetail && !followUpTaskDetail.isReviewAccessible) return;

    //ensure the current submission isnt an old version
    const { versionHistoryList } = selectedSubmission;
    let oldVersion = false;
    if (
      versionHistoryList &&
      selectedSubmission &&
      versionHistoryList[0].version !== selectedSubmission.data.version
    ) {
      oldVersion = true;
    }

    if (selectedSubmission.amsSubmissionId && oldVersion !== true) {
      return enforceRole(
        <Button
          floated="left"
          size="large"
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            props.data.handleEdit();
          }}
        />,
        acl.actions.followUp.editSubmissions,
        currentUser.roles
      );
    } else return;
  };

  const renderValidationAlertMessage = () => {
    if (
      props.responseErrors &&
      props.responseErrors.additionalCitationError &&
      !isEmpty(props.responseErrors.additionalCitationError)
    ) {
      return (
        <Message
          negative
          icon={'cancel'}
          header={props.errorType ? props.errorType : 'Something went wrong!'}
          list={Object.keys(props.responseErrors.additionalCitationError).map(
            key => (
              <li key={key}>
                {props.responseErrors.additionalCitationError[key]}
              </li>
            )
          )}
        />
      );
    }
  };

  const renderVersionTable = () => {
    if (isEmpty(selectedSubmission)) return null;

    const { versionHistoryList } = selectedSubmission;

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const version = cell;

            props.fetchData(
              props.selectedCitationDetails &&
                props.selectedCitationDetails.amsSubmissionId,
              version
            );
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      !isEmpty(selectedSubmission.amsSubmissionId) && (
        <AmsTable
          remote={false}
          basic
          total={versionHistoryList && versionHistoryList.length}
          columns={columns}
          keyField="version"
          data={versionHistoryList}
        />
      )
    );
  };
  const renderAdditionalCitationsButton = () => {
    return enforceRole(
      <Button
        fluid
        icon
        primary
        onClick={e => {
          e.preventDefault();
          props.data.addAdditionalCitation();
        }}
        labelPosition="left"
        disabled={
          (followUpTaskDetail && followUpTaskDetail.isReportFinalized) ||
          (followUpTaskDetail && followUpTaskDetail.readOnly) ||
          (followUpTaskDetail && !followUpTaskDetail.isReviewAccessible) ||
          (followUpTaskDetail &&
            followUpTaskDetail.reviewStatus === 'Cancelled')
        }
      >
        Add Additional Citation
        <Icon name="add" />
      </Button>,
      acl.actions.followUp.editSubmissions,
      currentUser.roles
    );
  };

  const renderForm = () => {
    const { formDisabled } = props;

    let citation = props.selectedCitationDetails
      ? props.selectedCitationDetails.citation
      : '';

    let params = {
      pathname: `/review/citations/definition`,
      query: { citation },
    };

    return (
      <Form>
        <label htmlFor="selectCitations">Select Citation </label>
        <Grid stackable>
          <Grid.Column width={3}>
            <Menu
              role="menubar"
              id="selectCitations"
              fluid
              vertical
              defaultActiveIndex={0}
            >
              {citationsList &&
                !isEmpty(citationsList) &&
                citationsList.map((citationDetails, index) =>
                  citationMenuItemTabByType(citationDetails, index)
                )}
            </Menu>
            {renderAdditionalCitationsButton()}
          </Grid.Column>
          <Grid.Column stretched width={13}>
            <div>
              <fieldset
                disabled={
                  props.selectedCitationDetails &&
                  !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
                  formDisabled
                }
              >
                {renderValidationAlertMessage()}
                <FollowUpCorrectiveStatusInfo
                  selectedCitationDetails={props.selectedCitationDetails}
                />
                {props.selectedCitationDetails &&
                props.selectedCitationDetails.isPreliminaryFinding ? (
                  <Header as="h3">Finding</Header>
                ) : props.selectedCitationDetails &&
                  !props.selectedCitationDetails.isPreliminaryFinding ? (
                  <Header as="h3">
                    Additional Citation{' '}
                    {props.selectedCitationDetails &&
                      !props.selectedCitationDetails.isActive &&
                      '(Dropped)'}
                  </Header>
                ) : null}
                <Segment>
                  {props.selectedCitationDetails &&
                  props.selectedCitationDetails.isPreliminaryFinding ? (
                    <div>
                      <Grid columns={2}>
                        <Grid.Column width={6}>
                          <span
                            role="heading"
                            aria-level="3"
                            className="field-title"
                            aria-label="Citation Number"
                          >
                            Citation Number :{' '}
                          </span>
                          {(props.selectedCitationDetails &&
                            props.selectedCitationDetails.citation) ||
                            ''}
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <span
                            role="heading"
                            aria-level="4"
                            className="field-title"
                            aria-label="Finding Type"
                          >
                            Finding Type :{' '}
                          </span>
                          {(props.selectedCitationDetails &&
                          !isEmpty(
                            props.selectedCitationDetails.amsSubmissionId
                          )
                            ? props.selectedCitationDetails.prevFindingsType
                            : props.selectedCitationDetails.findingType) || ''}
                        </Grid.Column>
                      </Grid>
                      <Grid.Row
                        style={{ whiteSpace: 'pre-wrap', marginLeft: '0px' }}
                      >
                        <Grid>
                          <Grid.Column width={12}>
                            <span
                              role="heading"
                              aria-level="4"
                              className="field-title"
                              aria-label="Citation Narrative"
                            >
                              Citation Narrative :{' '}
                            </span>
                            {props.selectedCitationDetails &&
                            !isEmpty(
                              props.selectedCitationDetails.citationNarrative
                            )
                              ? props.selectedCitationDetails.citationNarrative
                              : ''}
                          </Grid.Column>
                        </Grid>
                      </Grid.Row>
                      <br />
                      <Form.Field
                        htmlFor={`corrected-radio-${props.selectedCitationDetails &&
                          props.selectedCitationDetails.key}`}
                        className={'field required'}
                        label={'Did the Grantee correct the citation?'}
                        error={
                          props.responseErrors &&
                          props.responseErrors.additionalCitationError &&
                          !isNil(
                            props.responseErrors.additionalCitationError
                              .granteeCorrectedCitationResponse
                          ) &&
                          !!props.responseErrors.additionalCitationError
                            .granteeCorrectedCitationResponse
                        }
                      />
                      <Form.Group>
                        <Form.Checkbox
                          radio
                          aria-labelledby={`corrected-radio-${props.selectedCitationDetails &&
                            props.selectedCitationDetails.key}`}
                          id={`corrected-radio-${props.selectedCitationDetails &&
                            props.selectedCitationDetails.key}`}
                          name={'granteeCorrectedCitationResponse'}
                          label="Yes"
                          value="true"
                          checked={
                            props.selectedCitationDetails &&
                            props.selectedCitationDetails
                              .granteeCorrectedCitationResponse === 'true'
                          }
                          onChange={(e, data) =>
                            handleRadioButtonChange(
                              e,
                              data,
                              `${props.selectedCitationDetails.key}`
                            )
                          }
                          error={
                            props.responseErrors &&
                            props.responseErrors.additionalCitationError &&
                            !isNil(
                              props.responseErrors.additionalCitationError
                                .granteeCorrectedCitationResponse
                            ) &&
                            !!props.responseErrors.additionalCitationError
                              .granteeCorrectedCitationResponse
                          }
                          disabled={
                            props.selectedCitationDetails &&
                            !isEmpty(
                              props.selectedCitationDetails.amsSubmissionId
                            ) &&
                            formDisabled
                          }
                        />
                        <Form.Checkbox
                          radio
                          aria-labelledby={`corrected-radio-${props.selectedCitationDetails &&
                            props.selectedCitationDetails.key}`}
                          name={'granteeCorrectedCitationResponse'}
                          label="No"
                          value="false"
                          checked={
                            props.selectedCitationDetails &&
                            props.selectedCitationDetails
                              .granteeCorrectedCitationResponse === 'false'
                          }
                          onChange={(e, data) =>
                            handleRadioButtonChange(
                              e,
                              data,
                              `${props.selectedCitationDetails.key}`
                            )
                          }
                          error={
                            props.responseErrors &&
                            props.responseErrors.additionalCitationError &&
                            !isNil(
                              props.responseErrors.additionalCitationError
                                .granteeCorrectedCitationResponse
                            ) &&
                            !!props.responseErrors.additionalCitationError
                              .granteeCorrectedCitationResponse
                          }
                          disabled={
                            props.selectedCitationDetails &&
                            !isEmpty(
                              props.selectedCitationDetails.amsSubmissionId
                            ) &&
                            formDisabled
                          }
                        />

                        <Form.Checkbox
                          radio
                          aria-labelledby={`corrected-radio-${props.selectedCitationDetails &&
                            props.selectedCitationDetails.key}`}
                          name={'granteeCorrectedCitationResponse'}
                          label="Withdraw"
                          value="withdraw"
                          checked={
                            props.selectedCitationDetails &&
                            props.selectedCitationDetails
                              .granteeCorrectedCitationResponse === 'withdraw'
                          }
                          onChange={(e, data) =>
                            handleRadioButtonChange(
                              e,
                              data,
                              `${props.selectedCitationDetails.key}`
                            )
                          }
                          error={
                            props.responseErrors &&
                            props.responseErrors.additionalCitationError &&
                            !isNil(
                              props.responseErrors.additionalCitationError
                                .granteeCorrectedCitationResponse
                            ) &&
                            !!props.responseErrors.additionalCitationError
                              .granteeCorrectedCitationResponse
                          }
                          disabled={
                            props.selectedCitationDetails &&
                            !isEmpty(
                              props.selectedCitationDetails.amsSubmissionId
                            ) &&
                            formDisabled
                          }
                        />
                      </Form.Group>
                      <AmsFormLabel
                        name={`Please explain your response${
                          props.selectedCitationDetails.citation ? ` for ` : '*'
                        }`}
                        fieldLabel="correctedCitationReason"
                        disabled={
                          props.selectedCitationDetails &&
                          !isEmpty(
                            props.selectedCitationDetails.amsSubmissionId
                          ) &&
                          formDisabled
                        }
                      >
                        {props.selectedCitationDetails &&
                          props.selectedCitationDetails.citation &&
                          !isEmpty(props.selectedCitationDetails.citation) && (
                            <Link to={params} target="_blank">
                              <Popup
                                trigger={
                                  <Label
                                    style={{
                                      color: 'blue',
                                      background: 'white',
                                      textDecorationLine: 'underline',
                                    }}
                                  >
                                    {props.selectedCitationDetails.citation
                                      ? `${props.selectedCitationDetails.citation}` +
                                        '*'
                                      : ''}
                                  </Label>
                                }
                                content="Select to see Citation Definition"
                              />
                            </Link>
                          )}
                        <Form.Field
                          control={TextArea}
                          name="correctedCitationReason"
                          searchInput={{ id: 'correctedCitationReason' }}
                          required
                          value={correctedCitationReason || ''}
                          onChange={(e, data) =>
                            handleTextChange(
                              e,
                              data,
                              props.selectedCitationDetails &&
                                props.selectedCitationDetails.key
                            )
                          }
                          aria-labelledby="correctedCitationReason"
                          error={
                            props.responseErrors &&
                            props.responseErrors.additionalCitationError &&
                            !isNil(
                              props.responseErrors.additionalCitationError
                                .correctedCitationReason
                            ) &&
                            !!props.responseErrors.additionalCitationError
                              .correctedCitationReason
                          }
                          disabled={
                            props.selectedCitationDetails &&
                            !isEmpty(
                              props.selectedCitationDetails.amsSubmissionId
                            ) &&
                            formDisabled
                          }
                        />
                      </AmsFormLabel>
                      <FollowUpAttachmentField
                        furRecommendation={props.selectedCitationDetails}
                        index={
                          props.selectedCitationDetails &&
                          props.selectedCitationDetails.key
                        }
                        onChange={handleAttachmentChange}
                        evidences={
                          props.selectedCitationDetails &&
                          props.selectedCitationDetails.evidences
                        }
                        formName={'furRecommendations'}
                        formDisabled={formDisabled}
                      />
                      <br />
                    </div>
                  ) : props.selectedCitationDetails &&
                    !props.selectedCitationDetails.isPreliminaryFinding ? (
                    <FollowUpAdditionalCitationsForm
                      buildFormData={props.buildFormData}
                      surveyData={props.surveyData}
                      formDisabled={props.formDisabled}
                      selectedCitationDetails={props.selectedCitationDetails}
                      handleSelection={handleSelection}
                      responseErrors={props.responseErrors}
                      citationsData={props.citationsData}
                    />
                  ) : null}
                </Segment>
              </fieldset>
              {
                <>
                  <Divider />

                  {(!formDisabled ||
                    (props.selectedCitationDetails &&
                      isEmpty(
                        props.selectedCitationDetails.amsSubmissionId
                      ))) && (
                    <Form.Group inline>
                      {renderSaveDraftButton()}
                      {renderSubmitButton()}
                      {renderCancelButton()}
                      {props.selectedCitationDetails &&
                        !props.selectedCitationDetails.isPreliminaryFinding &&
                        props.selectedCitationDetails.submissionStatus &&
                        'submitted' ===
                          props.selectedCitationDetails.submissionStatus.toLowerCase() &&
                        renderRemoveAdditionalCitation()}
                    </Form.Group>
                  )}
                </>
              }

              {props.selectedCitationDetails &&
                !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
                formDisabled &&
                props.selectedCitationDetails.isActive &&
                renderEditButton()}
              {props.selectedCitationDetails &&
                !isEmpty(props.selectedCitationDetails.amsSubmissionId) && (
                  <div>
                    <Grid>
                      <Grid.Column width={7} floated="right">
                        {renderVersionTable()}
                      </Grid.Column>
                    </Grid>
                  </div>
                )}
            </div>
          </Grid.Column>
        </Grid>
      </Form>
    );
  };

  return <>{<div>{renderForm()}</div>}</>;
};

export default FollowUpCitationsForm;
