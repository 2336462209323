import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Nav, NavItem } from 'react-bootstrap';
import _ from 'lodash';

// Import style.
import './assets/style.css';

// Import layout
import SlidingContainer from '../../utils/layout/SlidingContainer';

// Import Admin Tabs.
import { tabs } from '../../config';

class AdminPage extends Component {
  handleTabSelect(activeTab, event) {
    this.context.router.push(`/admin/${event.target.id}`);
  }

  buildAdminTables() {
    const { active, currentUser } = this.props;
    const { adminTabs } = tabs;

    if (!_.includes(currentUser.roles, 'LogisticsAssociate'))
      return (
        <Nav
          bsStyle="pills"
          activeKey={active.key}
          onSelect={this.handleTabSelect.bind(this)}
        >
          {adminTabs.map((adminTab, index) => {
            return (
              <NavItem
                key={index}
                eventKey={index}
                id={adminTab.toLowerCase()}
                aria-label={`admin ${adminTab}`}
              >
                {adminTab}
              </NavItem>
            );
          })}
        </Nav>
      );
    else if (_.includes(currentUser.roles, 'LogisticsAssociate'))
      return (
        <Nav
          bsStyle="pills"
          activeKey={0}
          onSelect={this.handleTabSelect.bind(this)}
        >
          <NavItem key={0} eventKey={0} id={'users'} active>
            {'Users'}
          </NavItem>
        </Nav>
      );
  }

  render() {
    const { active } = this.props;

    return (
      <SlidingContainer title={`${active.pageTitle} - Administration`}>
        {this.buildAdminTables()}
        {/* Render child routes */}

        <div style={{ marginTop: '2em' }}>{this.props.children}</div>
      </SlidingContainer>
    );
  }
}

AdminPage.propTypes = {
  active: PropTypes.object.isRequired,
};

AdminPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    active: state.admin.active,
    currentUser: state.auth.user,
  };
}

export default connect(mapStateToProps, {})(AdminPage);
