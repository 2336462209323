import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FollowUpTabsPage from './index';
import FollowUpPage from './../FollowUpSurvey/index';
import { fetchFUReviewSubmissions } from '../../../actions/fy19/reviewActions';

const FollowUpSurveyPaths = props => {
  const dispatch = useDispatch();
  const { list } = useSelector(state => state.reviewSubmissions);
  const { params } = props.router;

  useEffect(() => {
    const reviewId = params.reviewId;
    dispatch(
      fetchFUReviewSubmissions({
        filters: { reviewId: reviewId },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.reviewId]);

  return (
    <>
      {list && list.length > 0 ? (
        <FollowUpTabsPage props={props} />
      ) : (
        <FollowUpPage />
      )}
    </>
  );
};

export default FollowUpSurveyPaths;
