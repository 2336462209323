import {
  UNSCHEDULED_REVIEWERS_FETCHED,
  UNSCHEDULED_REVIEWERS_CLEARED,
} from '../redux/types';

const initialState = {
  unscheduled: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UNSCHEDULED_REVIEWERS_FETCHED:
      return {
        ...state,
        unscheduled: action.unscheduledReviewers,
      };

    case UNSCHEDULED_REVIEWERS_CLEARED:
      return { ...state, unscheduled: action.unscheduledReviewers };

    default:
      return state;
  }
};
