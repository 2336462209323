import { TASK_ADDED, TASK_FETCHED, TASK_DELETED } from '../redux/types';
import _ from 'lodash';

export default function task(state = [], action) {
  switch (action.type) {
    case TASK_FETCHED:
      return action.tasks;

    case TASK_ADDED: {
      const newList = [...state, action.task];
      return newList;
    }
    case TASK_DELETED: {
      const index = _.findIndex(state, { taskId: action.taskId });

      if (index >= 0) {
        const modified = [...state.slice(0, index), ...state.slice(index + 1)];
        return modified;
      }

      return state;
    }

    default:
      return state;
  }
}
