import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import AmsModal from '../../../../utils/AmsModal';
import { isEmpty } from 'lodash';
import AmsAlert from '../../../../utils/AmsAlert';

// Import helpers.
import enforceRole from '../../../../utils/EnforceRole';
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';
import AmsSubmitReloadReportWizardPage from '../../AmsSubmitReloadReportWizardPage';

// Import configs.
import { acl } from '../../../../config';

// Import actions.
import {
  reportSaveAction,
  reportTemplateAction,
  fetchReportDraftTemplate,
  reportNewFaTwoAction,
  fetchReportStatusData,
  surveyReportSaveAction,
} from '../../../../actions/reportActions';
import {
  fetchEmailNotifyFOMRequest,
  submitToFOM,
  surveySubmitToFOM,
} from '../../../../actions/reviewActions';
import { fetchFUReviewSubmissions } from '../../../../actions/fy19/reviewActions';

class ReviewSubmitToFOMButton extends Component {
  state = {
    showAlert: false,
    showButtonModal: false,
    result: '',
  };

  componentDidMount() {
    const {
      selectedReview: { reviewId, reviewType },
    } = this.props;

    if (reviewId && reviewType)
      this.props.fetchReportDraftTemplate({ reviewId }, reviewType);
  }

  componentWillReceiveProps() {
    if ((this.props.selectedReview || {}).reviewId && !this.reportInfoReq) {
      this.reportInfoReq = true;
      this.props.fetchReportStatusData(
        (this.props.selectedReview || {}).reviewId
      );
    }
    const { selectedReview } = this.props;
    if (
      !this.reportDraftTemplateRequested &&
      selectedReview.reviewType === 'FA-2' &&
      selectedReview.reviewId
    ) {
      this.reportDraftTemplateRequested = true;
      const { reviewId } = this.props.selectedReview;
      this.props.reportNewFaTwoAction({ reviewId });
    }
  }

  errorMessage = () => {
    return (
      <>
        <p>
          In order to proceed, you must complete the missing incident form(s) or
          finding guide(s):
        </p>
        <ul style={{ textAlign: 'left' }}>
          {this.props.selectedReview.draftRANForms &&
            this.props.selectedReview.draftRANForms.map(item => (
              <li key={item}>{item}</li>
            ))}
        </ul>
      </>
    );
  };

  handleModalClick = () => {
    if (
      Array.isArray(this.props.selectedReview.draftRANForms) &&
      isEmpty(this.props.selectedReview.draftRANForms)
    ) {
      this.setState({
        showButtonModal: true,
      });
    } else {
      this.setState({
        showAlert: true,
      });
    }
  };

  showErrorAlert() {
    return (
      <AmsAlert
        show={this.state.showAlert}
        title="Performance Measure Not Complete"
        text={this.errorMessage()}
        type={'error'}
        showConfirm
        onConfirm={() => {
          this.setState({ showAlert: false });
        }}
      />
    );
  }

  handleModalClosure = value => {
    this.setState({
      showButtonModal: value,
    });
  };

  renderSubmitToFOMButton = () => {
    const { selectedReview } = this.props;
    if (
      selectedReview &&
      selectedReview.reviewStatus &&
      selectedReview.reviewStatus === 'Cancelled'
    )
      return null;

    let content = 'Submit';
    if (((this.props || {}).report.reportStatusData || {})._id) {
      content = 'Reload Report';
    } else {
      content = 'Submit to Manager';
    }

    const invalidDate =
      !AmsDateFormatters.getMoment(selectedReview.startDate)
        .startOf('day')
        .isSameOrBefore(AmsDateFormatters.getMoment().startOf('day')) &&
      content === 'Submit to Manager';

    const isSubmitToManagerDisabledInd = this.isSubmitToManagerDisabled(
      invalidDate
    );
    const surveyReviewTypes = ['FA2-CR', 'FA1-FR'];
    const newFormReviewTypes = ['Special'];
    const { currentUser } = this.props;
    const { showButtonModal } = this.state;

    let validRoles = currentUser.roles;

    if (!selectedReview || !selectedReview.Team) {
      return null;
    }

    if (currentUser.roles.includes('ReviewLead')) {
      const hasRoleAndOidInReview = selectedReview.Team.some(
        obj => obj.roleName === 'ReviewLead' && obj.oid === currentUser.oid
      );
      if (!hasRoleAndOidInReview) {
        validRoles = validRoles.filter(e => {
          return e !== 'ReviewLead';
        });
      }
    }

    if (currentUser.roles.includes('RegionalProgramManager')) {
      const validReviewManageRoles = [
        'RegionalProgramManager',
        'FieldOperationsManager',
      ];
      const hasRoleAndOidInReview = selectedReview.Team.some(
        obj =>
          validReviewManageRoles.includes(obj.roleName) &&
          obj.oid === currentUser.oid
      );
      if (!hasRoleAndOidInReview) {
        validRoles = validRoles.filter(e => {
          return e !== 'RegionalProgramManager';
        });
      }
    }

    if (currentUser.roles.includes('ProgramSpecialist')) {
      const hasRoleAndOidInReview = selectedReview.Team.some(
        obj =>
          obj.roleName === 'ProgramSpecialist' && obj.oid === currentUser.oid
      );
      if (!hasRoleAndOidInReview) {
        validRoles = validRoles.filter(e => {
          return e !== 'ProgramSpecialist';
        });
      }
    }

    return (this.props.selectedReview || {}).reportStage === 'signed' ||
      ((this.props.selectedReview || {}).reviewAccessLevel &&
        (this.props.selectedReview || {}).reviewAccessLevel
          .isReviewAccessible === false &&
        (this.props.selectedReview || {}).reviewStatus !== 'Report Signed' &&
        (this.props.selectedReview || {}).reviewStatus !== 'Shipped')
      ? null
      : enforceRole(
          <AmsModal
            className="ams-semantic-modal-fix"
            size="small"
            open={showButtonModal}
            trigger={
              <Button
                disabled={isSubmitToManagerDisabledInd}
                primary={
                  surveyReviewTypes.includes(selectedReview.reviewType) ||
                  (newFormReviewTypes.includes(selectedReview.reviewType) &&
                    selectedReview.isNewForms)
                    ? !isSubmitToManagerDisabledInd
                    : true
                }
                onClick={this.handleModalClick}
                content={content}
                floated="right"
              />
            }
          >
            <Modal.Content>
              {
                <AmsSubmitReloadReportWizardPage
                  selectedReview={selectedReview}
                  content={(this.props.reportTemplate || {}).content}
                  {...this.props}
                  data={{
                    handleModalClosure: this.handleModalClosure,
                  }}
                />
              }
            </Modal.Content>
          </AmsModal>,
          acl.actions.classDetailButtons.submitToFOM,
          validRoles
        );
  };

  isSubmitToManagerDisabled = invalidDate => {
    const { selectedReview } = this.props;

    return (
      !this.props.reportTemplate ||
      this.props.reportTemplate === 'Error' ||
      this.props.selectedReview.reportStage === 'finalized' ||
      invalidDate ||
      isEmpty([
        ...(selectedReview.managerList || []),
        ...(selectedReview.editorList || []),
        ...(selectedReview.technicalWriterList || []),
        ...(selectedReview.analystList || []),
        ...(selectedReview.followupLeadList || []),
      ]) ||
      (selectedReview.reviewType === 'Follow-up' &&
        selectedReview.isNewForms &&
        this.props.reviewFUSurveyDetails &&
        this.props.reviewFUSurveyDetails.status.toLowerCase() !==
          'completed') ||
      (selectedReview.reviewType === 'Follow-up' &&
        !selectedReview.isNewForms &&
        this.props.reviewFUSubmissions &&
        this.props.reviewFUSubmissions.length > 0 &&
        this.props.reviewFUSubmissions.some(
          submission => submission.status.toLowerCase() !== 'completed'
        ))
    );
  };

  render = () => {
    return (
      <div>
        {this.renderSubmitToFOMButton()}
        {this.showErrorAlert()}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  selectedReview: state.review.selectedReview,
  reportDraftTemplate: state.report.reportDraftTemplate,
  reportTemplate: state.report.reportTemplate,
  report: state.report,
  reviewFUSubmissions: state.reviewSubmissions.list,
  reviewFUSurveyDetails: state.reviewSubmissions.newFollowUpDetails,
});

export default connect(mapStateToProps, {
  reportSaveAction,
  fetchReportDraftTemplate,
  fetchEmailNotifyFOMRequest,
  reportTemplateAction,
  submitToFOM,
  reportNewFaTwoAction,
  fetchReportStatusData,
  fetchFUReviewSubmissions,
  surveyReportSaveAction,
  surveySubmitToFOM,
})(ReviewSubmitToFOMButton);
