import axios from 'axios';

import { WEATHER_FETCHED, ADD_ERROR, REMOVE_ERROR } from '../redux/types';

export function setWeather(weather) {
  return {
    type: WEATHER_FETCHED,
    weather,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function fetchWeather() {
  return dispatch => {
    // Ask user to gain access to current location.
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;

        axios
          .get(`/weather/${latitude}/${longitude}`)
          .then(response => {
            dispatch(setWeather(response.data));
          })
          .catch(error => {
            dispatch(setError(error));
          });
      },
      error => {
        // let url = 'https://freegeoip.net/json/';
        let url = 'https://ipinfo.io/json';

        // TODO: Replace with axios.Using fetch due to CORS issue with freegeoip.net
        fetch(url)
          .then(resp => resp.json()) // Transform the data into json
          .then(function(data) {
            // const { zip_code, latitude, longitude } = data;

            const { loc } = data;
            const location = loc.split(',');
            let latitude = location[0];
            let longitude = location[1];

            axios
              .get(`/weather/${latitude}/${longitude}`)
              .then(response => {
                dispatch(setWeather(response.data));
              })
              .catch(error => {
                dispatch(setError(error));
              });
          })
          .catch(err => {
            dispatch(setError(err));
          });
      },
      { maximumAge: Infinity, timeout: 5000 }
    );
  };
}
