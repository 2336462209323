import axios from 'axios';

import {
  GEOCODE_FETCHED,
  GEOCODE_CLEARED,
  ADD_ERROR,
  REMOVE_ERROR,
} from '../redux/types';

export function getGeocode(geocode) {
  return {
    type: GEOCODE_FETCHED,
    geocode,
  };
}

export function resetGeocode(geocode) {
  return {
    type: GEOCODE_CLEARED,
    geocode,
  };
}

export function getGeocodeList(geocode) {
  return {
    type: GEOCODE_FETCHED,
    geocode,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function clearGeocode() {
  return dispatch => {
    dispatch(resetGeocode([]));
  };
}

export function fetchGeocode(address) {
  return dispatch => {
    axios
      .post(`/geocode`, { address })
      .then(response => {
        dispatch(getGeocode(response.data));
      })
      .catch(error => {
        dispatch(setError(error));
      });
  };
}

export function fetchGeocodeList(addressList) {
  return dispatch => {
    axios
      .post(`/geocode/list`, addressList)
      .then(response => {
        dispatch(getGeocode(response.data));
      })
      .catch(error => {
        dispatch(setError(error));
      });
  };
}
