import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Loader, Dimmer } from 'semantic-ui-react';

import enforceRole from '../../utils/EnforceRole';

// Imort UI config
import { acl } from '../../config';

// Import actions.
import { fetchTaskDetail } from '../../actions/taskActions';

import './assets/style.css';

const linkSurveyName = (cell, row) => {
  return (
    <div>
      <a target="_blank" href={row.link} rel="noopener noreferrer">
        {cell}
      </a>{' '}
      {row.isOptional ? '(Optional)' : ''}
    </div>
  );
};

class TaskTabs extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { reviewId } = this.props;

    if (reviewId) this.getData(reviewId);
  }

  getData(reviewId) {
    this.setState({ loading: true });
    this.props
      .fetchTaskDetail(reviewId)
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({ loading: false });
        console.error(error);
      });
  }

  render() {
    const { loading } = this.state;
    const { taskDetail, currentUser } = this.props;

    if (_.isEmpty(taskDetail)) return null;

    const { presiteSurvey, onsiteSurvey, easSurvey } = taskDetail;

    return loading ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <Tabs defaultActiveKey={'onsite'} id="uncontrolled-tab-example">
        {onsiteSurvey && onsiteSurvey.length && (
          <Tab eventKey={'onsite'} title="Onsite">
            <BootstrapTable
              tableContainerClass="table-container"
              headerContainerClass="table-header"
              tableBodyClass="table-body"
              data={onsiteSurvey}
              bordered={false}
              pagination={onsiteSurvey && onsiteSurvey.length > 10}
            >
              <TableHeaderColumn isKey={true} dataField="link" hidden>
                Link
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={linkSurveyName}
                dataField="surveyName"
              >
                Survey Name
              </TableHeaderColumn>
              <TableHeaderColumn
                width="40%"
                dataAlign="center"
                dataField="submissionCount"
              >
                Survey Submission Count
              </TableHeaderColumn>
            </BootstrapTable>
          </Tab>
        )}

        {presiteSurvey &&
          presiteSurvey.length &&
          enforceRole(
            <Tab eventKey={'presite'} title="Pre-Review">
              <BootstrapTable
                tableContainerClass="table-container"
                headerContainerClass="table-header"
                tableBodyClass="table-body"
                data={presiteSurvey}
                bordered={false}
                pagination={presiteSurvey && presiteSurvey.length > 10}
              >
                <TableHeaderColumn isKey={true} dataField="link" hidden>
                  Link
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={linkSurveyName}
                  dataField="surveyName"
                >
                  Survey Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="40%"
                  dataAlign="center"
                  dataField="submissionCount"
                >
                  Survey Submission Count
                </TableHeaderColumn>
              </BootstrapTable>
            </Tab>,
            acl.actions.task.presiteTab, // Allowed roles
            currentUser.roles // Current user roles
          )}

        {easSurvey && easSurvey.length && (
          <Tab eventKey={'informal'} title="EAS Assessments">
            <BootstrapTable
              data={easSurvey}
              tableContainerClass="table-container"
              headerContainerClass="table-header"
              tableBodyClass="table-body"
              bordered={false}
              pagination={easSurvey && easSurvey.length > 10}
            >
              <TableHeaderColumn isKey={true} dataField="link" hidden>
                Survey Name
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={linkSurveyName}
                dataField="surveyName"
              >
                Survey Name
              </TableHeaderColumn>
              <TableHeaderColumn
                width="40%"
                dataAlign="center"
                dataField="submissionCount"
              >
                Survey Submission Count
              </TableHeaderColumn>
            </BootstrapTable>
          </Tab>
        )}
      </Tabs>
    );
  }
}
TaskTabs.propTypes = {
  currentUser: PropTypes.object.isRequired,
  reviewId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    taskDetail: state.taskDetail,
  };
}

export default connect(mapStateToProps, { fetchTaskDetail })(TaskTabs);
