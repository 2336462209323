import { GEOCODE_FETCHED, GEOCODE_CLEARED } from '../redux/types';

export default function geocode(state = [], action) {
  switch (action.type) {
    case GEOCODE_FETCHED:
      return action.geocode;

    case GEOCODE_CLEARED:
      return action.geocode;

    default:
      return state;
  }
}
