import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';

// Import components.
import LookupDetails from './LookupDetails';
import LookupTypeAddModal from './LookupTypeAddModal';

// Import actions.
import { fetchAllLookups, fetchLookup } from '../../../actions/lookupActions';

class LookupList extends Component {
  state = {
    active: '',
    selectedLookup: {},
    selectedCategoryLookups: [],
  };

  componentDidMount = () => {
    this.getData();
  };

  componentWillReceiveProps(nextProps) {
    const { selectedLookup, selectedCategoryLookups } = nextProps;

    this.setState({
      active: selectedLookup ? selectedLookup._id : '',
      selectedLookup,
      selectedCategoryLookups: _.filter(selectedCategoryLookups),
    });
  }

  getData = () =>
    this.props
      .fetchAllLookups()
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        this.setState({
          loading: false,
          errors: { ...this.state.errors, profile: err.response.data.message },
        });
      });

  getCategoryList = category => this.props.fetchLookup(category);

  renderList = () => (
    <List selection relaxed verticalAlign="middle">
      {this.props.categories.map(item => (
        <List.Item
          key={item._id}
          active={this.state.active === item._id}
          onClick={() => {
            this.setState({ active: item._id });
            this.getCategoryList(item.category);
          }}
        >
          <List.Content>
            <List.Header as="a" style={{ color: '#3169A0 !important' }}>
              {item.label || item.value || item.category}
            </List.Header>
            {item.valueType}
            {!item.active && (
              <span>
                {' '}
                - <i>(Disabled)</i>
              </span>
            )}
          </List.Content>
        </List.Item>
      ))}
    </List>
  );

  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="col-xs-3">
            <div className="row">
              <div className="col-xs-12 lookup-add-button-wrapper">
                <LookupTypeAddModal refreshList={this.getData} />
              </div>
            </div>
            <div className="col-xs-12 lookup-category-list-wrapper scrolling">
              {this.renderList()}
            </div>
          </div>

          <div className="col-xs-9">
            <LookupDetails />
          </div>
        </div>
      </div>
    );
  }
}

LookupList.propTypes = {
  fetchLookup: PropTypes.func.isRequired,
  fetchAllLookups: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  selectedCategoryLookups: PropTypes.array.isRequired,
  selectedLookup: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  categories: state.lookups.categories,
  selectedLookup: state.lookups.selectedLookup,
  selectedCategoryLookups: state.lookups.selectedCategoryLookups,
});

export default connect(mapStateToProps, { fetchAllLookups, fetchLookup })(
  LookupList
);
