import axios from 'axios';

import { TAGS_FETCHED, TAG_DELETED } from '../../redux/types';

export const tagsFetched = tags => ({
  type: TAGS_FETCHED,
  tags,
});

export function removedTag(id, tag) {
  return {
    type: TAG_DELETED,
    id,
    tag,
  };
}

export const fetchTags = () => dispatch =>
  axios.get(`/help/tags`).then(response => {
    dispatch(tagsFetched(response.data));
  });

export const fetchTagById = id => dispatch =>
  axios.get(`/help/tags/${id}`).then(response => {
    dispatch(tagsFetched(response.data));
  });

export function addTag(tagObject) {
  return dispatch => {
    return axios.post(`/help/tags`, tagObject).then(response => response);
  };
}

export function updateTag(id, tagObject) {
  return dispatch => {
    return axios.post(`/help/tags/${id}`, tagObject).then(response => {
      return response;
    });
  };
}

export function deleteTag(id) {
  return dispatch => {
    axios.delete(`/help/tags/${id}`).then(function(response) {
      return dispatch(removedTag(id, response.data));
    });
  };
}
