import { ADD_ERROR, REMOVE_ERROR } from '../redux/types';
import { config } from '../config';

export default function error(state = [], action) {
  switch (action.type) {
    case ADD_ERROR: {
      // Login session timeout
      if (action.error.response && action.error.response.status === 440) {
        localStorage.removeItem('token');

        if (typeof window !== 'undefined') {
          if (process.env.NODE_ENV === 'development') {
            window.location.href = `http://localhost:${config.API_PORT}/auth/logout`;
          } else {
            window.location.href = '/auth/logout';
          }
        }
      }

      return [...state, action.error.response];
    }

    case REMOVE_ERROR: {
      if (action.action) {
        return state.filter(error => {
          return !(error.action && error.action === action.action);
        });
      } else {
        return state.filter((error, i) => i !== action.index);
      }
    }

    default:
      return state;
  }
}
