import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

// Import actions
import { fetchRegionalUsers } from '../actions/userActions';

class RegionalUsersPreloadDropdown extends Component {
  componentDidMount = () => {
    const { roleName, reviewId, fetchRegionalUsers, hasSource } = this.props;

    if (hasSource) {
      return;
    }

    if (fetchRegionalUsers) fetchRegionalUsers({ reviewId, roleName });
  };

  render() {
    const { regionalUsers } = this.props;

    const dropDownProps = _.omit(this.props, [
      'userType',
      'regionalUsers',
      'filterUsers',
      'multipleUserTypes',
      'hasSource',
      'reviewId',
      'roleName',
    ]); // exclude props not necessary for Dropdown.

    return (
      <Dropdown
        {...dropDownProps}
        options={
          regionalUsers && regionalUsers.length
            ? regionalUsers.map(user => ({
                key: user.oid,
                text: `${user.firstName} ${user.lastName}`,
                value: user.oid,
              }))
            : []
        }
      />
    );
  }
}

RegionalUsersPreloadDropdown.propTypes = {
  regionalUsers: PropTypes.object,
  fetchRegionalUsers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  regionalUsers: state.users.regionalUsers,
});

export default connect(mapStateToProps, { fetchRegionalUsers })(
  RegionalUsersPreloadDropdown
);
