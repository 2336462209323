import axios from 'axios';

import {
  CAROUSEL_ADDED,
  CAROUSEL_FETCHED,
  CAROUSEL_UPDATED,
  CAROUSEL_DELETED,
  ADD_ERROR,
  REMOVE_ERROR,
} from '../redux/types';

export const carouselFetched = carousels => ({
  type: CAROUSEL_FETCHED,
  carousels,
});

export function modifyCarousel(carousel) {
  return {
    type: CAROUSEL_UPDATED,
    carousel,
  };
}

export function setCarousel(carousel) {
  return {
    type: CAROUSEL_ADDED,
    carousel,
  };
}

export function removeCarousel(id, carousel) {
  return {
    type: CAROUSEL_DELETED,
    id,
    carousel,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export const fetchCarousels = () => dispatch =>
  axios.post(`/carousel/list`).then(response => {
    dispatch(carouselFetched(response.data));
    return;
  });

export function addCarousel(carouselObject) {
  return dispatch => {
    if (carouselObject.file) {
      let imageObject = new FormData();
      imageObject.append('file', carouselObject.file);

      return axios
        .post(`/upload/carousel`, imageObject)
        .then(response => {
          if (response.status === 200) {
            // Add filename of newly added file.
            carouselObject.image = response.data.filename;

            return axios
              .post(`/carousel`, carouselObject)
              .then(response => {
                dispatch(setCarousel(response.data));
              })
              .catch(error => {
                return dispatch(setError(error));
              });
          } else {
            return setError('Unknown Error');
          }
        })
        .catch(error => {
          return dispatch(setError(error));
        });
    } else {
      // File not attached.
      return axios
        .post(`/carousel`, carouselObject)
        .then(response => {
          return dispatch(setCarousel(response.data));
        })
        .catch(error => {
          return dispatch(setError(error));
        });
    }
  };
}

export function updateCarousel(id, carouselObject) {
  return dispatch => {
    if (carouselObject.file) {
      let imageObject = new FormData();
      imageObject.append('file', carouselObject.file);

      return axios
        .post(`/upload/carousel`, imageObject)
        .then(response => {
          if (response.status === 200) {
            // Add filename of newly added file.
            carouselObject.image = response.data.filename;

            axios
              .put(`/carousel/${id}`, carouselObject)
              .then(function(response) {
                return dispatch(modifyCarousel(response.data));
              })
              .catch(function(error) {
                return dispatch(setError(error));
              });
          }
        })
        .catch(error => {
          return dispatch(setError(error));
        });
    } else {
      // No file
      return axios
        .put(`/carousel/${id}`, carouselObject)
        .then(function(response) {
          return dispatch(modifyCarousel(response.data));
        })
        .catch(function(error) {
          return dispatch(setError(error));
        });
    }
  };
}

export function deleteCarousel(id) {
  return dispatch => {
    axios
      .delete(`/carousel/${id}`)
      .then(function(response) {
        return dispatch(removeCarousel(id, response.data));
      })
      .catch(function(error) {
        return dispatch(setError(error));
      });
  };
}

export const upateAllCarousels = carouselList => dispatch =>
  axios
    .put(`/carousel`, carouselList)
    .then(response => dispatch(carouselFetched(response.data)));
