import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

class GranteeCalendarSubmitMultiMessage extends Component {
  handleDismiss = () => {
    const { closeSubmitMultiMessage } = this.props;
    closeSubmitMultiMessage();
  };

  renderGranteeCalendarSubmitMultiMessage() {
    let { showSubmittedMultiMessage } = this.props;
    return showSubmittedMultiMessage ? (
      <div>
        <Message
          onDismiss={this.handleDismiss}
          header="The changes you have made have been submitted."
          content="This was a final Submit and you will be unable to make any more changes going forward.  Please select a Grantee to view from the drop down above."
        />
      </div>
    ) : (
      ''
    );
  }

  render() {
    return <div> {this.renderGranteeCalendarSubmitMultiMessage()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarSubmitMultiMessage);
