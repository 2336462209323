import {
  GRANTEE_FETCHED,
  AGENCY_LOOKUP,
  GRANTEE_SCHEDULE_UPDATED,
  GRANTEE_EXTENSION_FETCHED,
  GRANTEE_EXTENSION_HISTORY_FETCHED,
} from '../redux/types';

export default function grantee(state = {}, action) {
  switch (action.type) {
    case GRANTEE_FETCHED: {
      return { ...state, ...action.grantee };
    }

    case AGENCY_LOOKUP: {
      return { ...state, agencies: action.agencies };
    }

    case GRANTEE_SCHEDULE_UPDATED: {
      return {
        ...state,
        granteeScheduleUpdated: action.granteeScheduleUpdated,
      };
    }

    case GRANTEE_EXTENSION_FETCHED: {
      return { ...state, extensionRequests: action.extensionRequests };
    }

    case GRANTEE_EXTENSION_HISTORY_FETCHED: {
      return {
        ...state,
        extensionRequestHistory: action.extensionRequestHistory,
      };
    }

    default:
      return state;
  }
}
