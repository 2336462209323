import React, { Component } from 'react';

import Wysiwyg from './index';

class WysiwygField extends Component {
  constructor(props) {
    super(props);
    this.state = { content: props.formData };
  }

  handleOnChange = content => {
    if (content !== this.props.formData) {
      this.setState({ content }, () => {
        this.props.onChange(content);
      });
    }
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.formData !== prevProps.formData) {
      this.setState({ content: this.props.formData });
    }
  }

  render() {
    const { content } = this.state;

    return (
      <>
        <label className="control-label" htmlFor={this.props.idSchema.$id}>
          {this.props.schema.title}
        </label>
        <Wysiwyg
          id={this.props.idSchema.$id}
          value={content}
          onChange={this.handleOnChange}
        />
      </>
    );
  }
}

export default WysiwygField;
