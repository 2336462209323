import { TASKS_FETCHED, DELEGATED_TASKS_FETCHED } from '../redux/types';

const initialState = {
  total: 0,
  taskList: [],
  tasksList: {
    total: -1,
    page: 0,
    data: [],
  },
  delegatedTasksList: {
    total: -1,
    page: 0,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TASKS_FETCHED:
      return {
        ...state,
        taskList: action.tasks.data,
        tasksList: action.tasks,
        total: action.tasks.total,
      };

    case DELEGATED_TASKS_FETCHED:
      return {
        ...state,
        delegatedTasksList: action.tasks,
      };

    default:
      return state;
  }
};
