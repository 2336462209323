import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

// Import actions
import { filterUsers } from '../actions/userActions';

class UserListPreloadDropdown extends Component {
  componentWillReceiveProps = nextProps => {
    const { userType, multipleUserTypes, filterUsers, hasSource } = nextProps;
    if (userType !== this.props.userType) {
      if (hasSource) {
        return;
      }

      if (multipleUserTypes && filterUsers) {
        multipleUserTypes.forEach(e => {
          filterUsers(null, e);
        });
        return;
      }

      if ((userType || userType === '') && filterUsers)
        filterUsers(null, userType);
    }
  };

  componentDidMount = () => {
    const { userType, multipleUserTypes, filterUsers, hasSource } = this.props;

    if (hasSource) {
      return;
    }

    if (multipleUserTypes && filterUsers) {
      multipleUserTypes.forEach(e => {
        filterUsers(null, e);
      });
      return;
    }

    if ((userType || userType === '') && filterUsers)
      filterUsers(null, userType);
  };

  render() {
    const { userType, usersFilter, multipleUserTypes } = this.props;

    const dropDownProps = _.omit(this.props, [
      'userType',
      'usersFilter',
      'filterUsers',
      'multipleUserTypes',
      'hasSource',
    ]); // exclude props not necessary for Dropdown.

    // TODO: Refactor shadow reviewer type reducer and action

    //if multipleUserTypes
    if (multipleUserTypes) {
      let result = _.uniqBy(
        multipleUserTypes.reduce((accumulator, userType) => {
          const toAdd = usersFilter[userType] || [];
          return [...accumulator, ...toAdd];
        }, []),
        'oid'
      );

      return (
        <Dropdown
          {...dropDownProps}
          options={
            result && result.length
              ? result.map(user => ({
                  key: user.oid,
                  text: `${user.firstName} ${user.lastName}`,
                  value: user.oid,
                }))
              : []
          }
        />
      );
    }

    if (userType === '')
      return (
        <Dropdown
          {...dropDownProps}
          options={
            usersFilter &&
            usersFilter['shadowReviewer'] &&
            usersFilter['shadowReviewer'].length
              ? usersFilter['shadowReviewer'].map(user => ({
                  key: user.oid,
                  text: `${user.firstName} ${user.lastName}`,
                  value: user.oid,
                }))
              : []
          }
        />
      );

    if (!(usersFilter && usersFilter[userType] && usersFilter[userType].length))
      return <Dropdown {...dropDownProps} options={[]} />; // Show empty dropdown if no data.

    return (
      <Dropdown
        {...dropDownProps}
        options={
          usersFilter && usersFilter[userType] && usersFilter[userType].length
            ? usersFilter[userType].map(user => ({
                key: user.oid,
                text: `${user.firstName} ${user.lastName}`,
                value: user.oid,
              }))
            : usersFilter &&
              userType === '' &&
              usersFilter['shadowReviewer'] &&
              usersFilter['shadowReviewer'].length
            ? usersFilter['shadowReviewer'].map(user => ({
                key: user.oid,
                text: `${user.firstName} ${user.lastName}`,
                value: user.oid,
              }))
            : []
        }
      />
    );
  }
}

UserListPreloadDropdown.propTypes = {
  userType: PropTypes.string.isRequired,
  usersFilter: PropTypes.object,
  filterUsers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  usersFilter: state.usersFilter,
});

export default connect(mapStateToProps, { filterUsers })(
  UserListPreloadDropdown
);
