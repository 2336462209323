import React from 'react';
import { Header, Grid, Divider } from 'semantic-ui-react';

// Import utils.
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';

const LABELS = {
  reviewId: 'Review ID',
  reviewStatus: 'Review Status',
  reviewType: 'Review Type',
  fiscalYear: 'Fiscal Year',
  type: 'Type',
  reason: 'Reason',
  changedFrom: 'Changed From',
  changedTo: 'Changed To',
  changes: 'Changes',
  fullName: 'Full Name',
  comment: 'Reviewer Comment',
  dateChangeReason: 'Reason',
};

const JournalRowItem = ({ field = '', row = '', formatter = '' }) => {
  const label = LABELS[field];
  const item = row[field];

  if (!item) return null;

  let formattedRow = '';

  switch (formatter) {
    case 'reason':
      if (item && item.length > 0) {
        formattedRow = item.join(', ');
      } else {
        formattedRow = item;
      }
      break;
    case 'changedFrom':
      if (
        'reviewStartDate' === row['type'] ||
        'reviewEndDate' === row['type']
      ) {
        formattedRow = AmsDateFormatters.formatDate(item);
      } else {
        formattedRow = item;
      }
      break;
    default:
      formattedRow = item;
      break;
  }
  return (
    <Grid.Row columns={2} style={style.resetBottomPadding}>
      <Grid.Column width={8}>{label}</Grid.Column>
      <Grid.Column width={8}>{formattedRow}</Grid.Column>
    </Grid.Row>
  );
};

const JournalRow = ({ row = [] }) => {
  if (!row) return null;
  const allowedKeys = Object.keys(LABELS);
  return Object.keys(row)
    .filter(key => allowedKeys.includes(key))
    .map(key => {
      return <JournalRowItem field={key} row={row} formatter={key} />;
    });
};

const JournalDetail = ({ title = '', data = [] }) => {
  if (!data || !data.length) return null;

  return (
    <Grid style={{ marginLeft: '10px' }}>
      <Grid.Row>
        <Grid.Column>
          <Header style={{ 'font-size': '1.3em' }} color="grey">
            {title}
          </Header>
        </Grid.Column>
      </Grid.Row>

      {data.map((val, key) => (
        <React.Fragment key={key}>
          <JournalRow row={val} />
          <Grid.Row>
            <Grid.Column>
              <Divider />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      ))}
    </Grid>
  );
};

const ReviewJournalDetails = props => {
  const { journalData } = props;

  const renderReviewInformation = () => {
    if (!journalData) return null;

    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column>
            <Header style={{ 'font-size': '1.3em' }} as="h2" color="grey">
              Review Information
            </Header>
          </Grid.Column>
        </Grid.Row>

        <JournalRowItem field="reviewId" row={journalData['details']} />

        <JournalRowItem field="reviewStatus" row={journalData['details']} />

        <JournalRowItem field="reviewType" row={journalData['details']} />

        <JournalRowItem field="fiscalYear" row={journalData['details']} />

        <JournalRowItem field="fullName" row={journalData['details']} />

        <JournalRowItem field="comment" row={journalData['details']} />

        <JournalRowItem field="dateChangeReason" row={journalData['details']} />

        <Grid.Row>
          <Grid.Column>
            <Divider />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  };

  return (
    <>
      <Grid style={{ marginLeft: '10px' }}>{renderReviewInformation()}</Grid>

      <JournalDetail
        title="Review Info Updates"
        data={
          (journalData &&
            journalData.details &&
            journalData.details.reviewChanges) ||
          []
        }
      />

      <JournalDetail
        title="Review Team Updates"
        data={
          (journalData &&
            journalData.details &&
            journalData.details.teamChanges) ||
          []
        }
      />
    </>
  );
};

const style = {
  resetBottomPadding: {
    paddingBottom: '0px',
  },
};

export default ReviewJournalDetails;
