import React from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { Link } from 'react-router';

import AmsTable from '../../../../utils/AmsTable';

export default function FollowupSubmissionsList(props) {
  const { review } = useSelector(state => state);

  const { list } = useSelector(state => state.reviewSubmissions);

  const reviewId = review.selectedReview.reviewId;

  const getSubmissionStatus = () => {
    if (!Array.isArray(list)) return null;

    if (list.some(submission => submission.status !== 'Completed')) {
      return 'Pending';
    } else {
      return 'Submitted';
    }
  };

  const typeFormat = (cell, row) => {
    return (
      <Link
        to={{
          pathname: `/task/${reviewId}/survey/follow-up`,
          query: {},
        }}
      >
        {cell}
      </Link>
    );
  };

  const statusFormat = (cell, row) => {
    return getSubmissionStatus();
  };

  const columns = [
    { hidden: true, dataField: 'index', text: 'Index' },
    {
      dataField: 'type',
      formatter: typeFormat,
      headerStyle: { width: '20%' },
      text: 'Type',
    },
    {
      dataField: 'status',
      formatter: statusFormat,
      headerStyle: { width: '80%' },
      text: 'Status',
    },
  ];

  const renderSubmission = () => {
    let submission = [{ type: '', status: '' }];
    submission[0].type = 'Follow up data collection form';
    if (review.selectedReview.reviewType !== 'Follow-up') {
      return null;
    }

    return (
      <Segment basic loading={!list}>
        <AmsTable
          keyField="index"
          data={submission}
          columns={columns}
          basic
          sizePerPageOption={false}
        />
      </Segment>
    );
  };

  return <div>{renderSubmission()}</div>;
}
