import React, { useState } from 'react';
import { Grid, Divider, Button } from 'semantic-ui-react';

import { isObject } from 'lodash';

import AmsAlert from '../../../utils/AmsAlert';

const ArrayFieldTemplate = props => {
  const [state, setState] = useState({});
  const renderDeleteConfirmation = () => {
    const {
      showDeleteConfirmationAlert,
      deleteEvent,
      deleteElement,
      deleteFileName,
    } = state;
    return (
      <AmsAlert
        show={showDeleteConfirmationAlert}
        title="Are you sure you want to delete this file"
        text={`You are about to delete this file. Are you sure you want to delete ${deleteFileName}?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Delete'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          setState({
            ...state,
            showDeleteConfirmationAlert: false,
          });
        }}
        onConfirm={() => {
          deleteElement.onDropIndexClick(deleteElement.index)(deleteEvent);
          setTimeout(
            () => setState({ ...state, showDeleteConfirmationAlert: false }),
            1
          );
        }}
      />
    );
  };

  const getFileName = file => {
    if (isObject(file)) {
      return null;
    }
    const name = file.indexOf('name=') + 5;
    return file.substring(name, file.indexOf(';', name));
  };

  return (
    <div className={props.className}>
      {props.TitleField(props)}
      {renderDeleteConfirmation()}
      {props.items &&
        props.items.map(element => (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column width={14}>
                  {<div style={{ marginTop: '10px' }}>{element.children}</div>}
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button
                    negative
                    onClick={event => {
                      event.preventDefault();

                      if (
                        element.children.props.formData === undefined ||
                        isObject(element.children.props.formData)
                      ) {
                        element.onDropIndexClick(element.index)(event);
                      } else {
                        setState({
                          deleteEvent: event,
                          deleteElement: element,
                          showDeleteConfirmationAlert: true,
                          deleteFileName: getFileName(
                            element.children.props.formData
                          ),
                        });
                      }
                    }}
                    floated="right"
                  >
                    Delete
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Divider></Divider>
            </Grid>
          </>
        ))}

      {props.canAdd && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}></Grid.Column>
            <Grid.Column width={2}>
              <Button primary onClick={props.onAddClick} floated="right">
                Add Document
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};
export default ArrayFieldTemplate;
