import axios from 'axios';

import {
  RESOURCE_ADDED,
  RESOURCE_FETCHED,
  RESOURCE_UPDATED,
  RESOURCE_DELETED,
  ADD_ERROR,
  REMOVE_ERROR,
} from '../redux/types';

export function getResources(resources) {
  return {
    type: RESOURCE_FETCHED,
    resources,
  };
}

export function setResource(resource) {
  return {
    type: RESOURCE_ADDED,
    resource,
  };
}

export function modifyResource(resource) {
  return {
    type: RESOURCE_UPDATED,
    resource,
  };
}

export function removeResource(resource, id) {
  console.log(id);
  return {
    type: RESOURCE_DELETED,
    id: resource === true ? id : 0,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function fetchResources() {
  return dispatch => {
    axios
      .post(`/resource/list`)
      .then(function(response) {
        dispatch(getResources(response.data));
      })
      .catch(function(error) {
        dispatch(setError(error));
      });
  };
}

export function addResource(resourceObject) {
  return dispatch => {
    axios
      .post(`/resource`, resourceObject)
      .then(function(response) {
        dispatch(setResource(response.data));
      })
      .catch(function(error) {
        dispatch(setError(error));
      });
  };
}

export function updateResource(id, resourceObject) {
  return dispatch => {
    axios
      .put(`/resource/${id}`, resourceObject)
      .then(function(response) {
        dispatch(modifyResource(response.data));
      })
      .catch(function(error) {
        dispatch(setError(error));
      });
  };
}

export function deleteResource(id) {
  return dispatch => {
    axios
      .delete(`/resource/${id}`)
      .then(function(response) {
        dispatch(removeResource(response.data, id));
      })
      .catch(function(error) {
        dispatch(setError(error));
      });
  };
}
