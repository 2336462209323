import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';

// Import actions
import { fetchGrantees } from '../../actions/granteeActions';

class GranteeList extends Component {
  constructor() {
    super();
    this.handleRowClick = this.handleRowClick.bind(this);
  }
  componentWillMount() {
    this.props.fetchGrantees();
  }

  handleRowClick(row) {
    if (row.granteeId)
      this.context.router.push(`/workbench/grantee/detail/${row.granteeId}`);
  }

  prepareGranteeList() {
    const { grantees } = this.props;

    const options = {
      onRowClick: this.handleRowClick,
    };

    return (
      <BootstrapTable
        trClassName="clickable"
        data={grantees}
        tableContainerClass="table-container"
        headerContainerClass="table-header"
        hover
        condensed
        bordered={false}
        options={options}
        pagination
      >
        <TableHeaderColumn dataField="granteeId" isKey={true} dataSort={true}>
          Grantee ID
        </TableHeaderColumn>
        <TableHeaderColumn dataField="name" dataSort={true}>
          Grantee Name
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  render() {
    return (
      <div className="panel panel-white">
        <div className="panel-body--old">{this.prepareGranteeList()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    grantees: state.grantee,
  };
}

export default connect(mapStateToProps, { fetchGrantees })(GranteeList);
