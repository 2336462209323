import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Well } from 'react-bootstrap';
import { find, isEqual, isEmpty, sortBy } from 'lodash';
import DatePicker from 'react-datepicker';
import { Form, Message, Dropdown, Button, Modal } from 'semantic-ui-react';

// Import actions.
import {
  addUserEvents,
  updateUserEvent,
  deleteUserEvent,
} from '../../actions/calendarAction';

// Import utils.
import AmsModal from '../../utils/AmsModal';
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

import { settings } from '../../config';
const UNAVAILABLE_COLOR = settings.calendar.events.colors.unavailable;

class CalendarUnavailableModal extends Component {
  state = {
    id: '',
    saving: false,
    deleting: false,
    title: 'Unavailable',
    type: 'unavailable',
    allDay: true,
    color: UNAVAILABLE_COLOR,
    start: null,
    end: null,
    excludedDates: [],
    selectedEvent: {},
    errors: {},
    showAlert: false,
    showSaveAlert: false,
  };

  componentWillReceiveProps(nextProps) {
    const { _id } = nextProps.selectedEvent;
    if (_id) {
      const { events } = this.props;
      const selectedEvent = find(events, ['_id', _id]);
      if (selectedEvent)
        this.setState({
          id: selectedEvent._id,
          start: AmsDateFormatters.getMoment(selectedEvent.start),
          end: AmsDateFormatters.getMoment(selectedEvent.end),
        });
    }
  }

  closeModal = () => {
    this.setState(
      {
        id: '',
        saving: false,
        deleting: false,
        start: null,
        end: null,
        excludedDates: [],
        selectedEvent: {},
        errors: {},
        showAlert: false,
      },
      () => {
        // Refresh Calendar.
        this.props.refreshCalendar();
        // Hide Modal.
        this.props.closeEditModal();
      }
    );
  };

  handleStartChange = start => {
    if (!start) {
      this.setState({ start: null, end: null });
      return;
    }
    this.setState({ start: AmsDateFormatters.getMoment(start).startOf('day') });
  };

  handleEndChange = end => {
    if (!end) {
      this.setState({ end: null });
      return;
    }
    this.setState({ end: AmsDateFormatters.getMoment(end).endOf('day') });
  };

  handleExcludeDates = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  buildExcludeDates = () => {
    const { start, end } = this.state;
    const startDate = AmsDateFormatters.getMoment(start)
      .add(1, 'days')
      .startOf('day');
    const dateRange = [];

    if (start && end) {
      while (startDate.isBefore(AmsDateFormatters.getMoment(end), 'day')) {
        dateRange.push(AmsDateFormatters.getMoment(startDate).format());
        startDate.add(1, 'days');
      }
    }

    return dateRange.map(date => {
      return {
        key: date,
        value: date,
        text: AmsDateFormatters.formatDate(date),
      };
    });
  };

  submitDateRange = () => {
    const { id, start, end, excludedDates } = this.state;

    const getDates = (startDate, stopDate) => {
      var dateArray = [];
      var currentDate = AmsDateFormatters.getMoment(startDate);
      var endDate = AmsDateFormatters.getMoment(stopDate);
      while (currentDate <= endDate) {
        dateArray.push(AmsDateFormatters.formatDate(currentDate));
        currentDate = AmsDateFormatters.getMoment(currentDate).add(1, 'days');
      }
      return dateArray;
    };

    let unavailabilityExists = find(this.props.events, event => {
      let eventRangeList = getDates(event.start, event.end);
      if (event._id === id) {
        return;
      }
      if (isEqual(sortBy(eventRangeList), sortBy(excludedDates))) {
        return;
      }
      return (
        (AmsDateFormatters.getMoment(event.start) >
          AmsDateFormatters.getMoment(start) &&
          AmsDateFormatters.getMoment(event.end) <
            AmsDateFormatters.getMoment(end)) ||
        (AmsDateFormatters.getMoment(start) <
          AmsDateFormatters.getMoment(event.start) &&
          AmsDateFormatters.getMoment(event.start) <
            AmsDateFormatters.getMoment(end)) ||
        (AmsDateFormatters.getMoment(start) <
          AmsDateFormatters.getMoment(event.end) &&
          AmsDateFormatters.getMoment(event.end) <
            AmsDateFormatters.getMoment(end))
      );
    });

    if (!isEmpty(unavailabilityExists)) {
      this.setState({
        errors:
          'One or more of the dates you selected is already marked as unavailable',
      });
      return;
    }

    if (id) {
      this.updateEvent();
      return;
    }

    this.addEvent();
  };

  addEvent = () => {
    const {
      start,
      end,
      title,
      color,
      id,
      type,
      allDay,
      excludedDates,
    } = this.state;

    if (id) return;

    this.setState({ saving: true });
    const data = {
      start: AmsDateFormatters.getMoment(start).format(),
      end: AmsDateFormatters.getMoment(end).format(),
      type,
      title,
      color,
      allDay,
    };

    this.props
      .addUserEvents(data, this.props.oid, excludedDates)
      .then(response => {
        this.setState({ saving: false });
        this.closeModal();
      })
      .catch(error => {
        this.setState({
          saving: false,
          errors: error.response.data.message || 'Unknown error occured',
        });
      });
  };

  updateEvent = () => {
    const { start, end, id, excludedDates } = this.state;

    if (!id) return;

    this.setState({ saving: true });

    this.props
      .updateUserEvent(id, {
        start: AmsDateFormatters.getMoment(start).format(),
        end: AmsDateFormatters.getMoment(end).format(),
        excludedDates,
      })
      .then(response => {
        this.setState({ saving: false });
        this.closeModal();
      })
      .catch(error => {
        this.setState({
          saving: false,
          errors: error.response.data.message || 'Unknown error occured',
        });
      });
  };

  deleteEvent = () => {
    const { id } = this.state;
    if (id) {
      this.setState({ deleting: true });
      this.props
        .deleteUserEvent(id)
        .then(() => {
          this.setState({ deleting: false });
          this.closeModal();
        })
        .catch(error => {
          this.setState({
            deleting: false,
            errors: error.response.data.message || 'Unknown error occured',
            showAlert: false,
          });
        });
    }
  };

  showConfirmationAlert() {
    return (
      <AmsAlert
        show={this.state.showAlert}
        title="Remove Unavailability"
        text={`Are you sure you want to remove unavailability?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, Remove it!'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showAlert: false });
        }}
        onConfirm={() => {
          this.deleteEvent();
        }}
      />
    );
  }

  showSaveConfirmationAlert() {
    return (
      <AmsAlert
        show={this.state.showSaveAlert}
        title={this.state.id ? 'Edit Unavailability' : 'Add Unavailability'}
        text={
          this.state.id
            ? `Are you sure you want to edit unavailability?`
            : `Are you sure you want to add unavailability?`
        }
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showSaveAlert: false });
        }}
        onConfirm={() => {
          this.setState({ showSaveAlert: false });
          this.submitDateRange();
        }}
      />
    );
  }

  render() {
    const { showModal } = this.props;
    const {
      start,
      end,

      errors,
      saving,
      deleting,
      id,
      excludedDates,
    } = this.state;
    return (
      <AmsModal
        className="ams-semantic-modal-fix calendar-unavailability-modal"
        open={showModal}
        onClose={this.closeModal}
        size="small"
        closeOnEscape={false}
      >
        <Modal.Header>
          Select date range to provide your unavailability
        </Modal.Header>
        <Modal.Content>
          {this.showConfirmationAlert()}
          {this.showSaveConfirmationAlert()}

          {id && (
            <Well>
              <h3>Editing Selected Unavailability</h3>
            </Well>
          )}

          {!isEmpty(errors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              content={errors}
            />
          )}

          <Form>
            <Form.Group widths="equal">
              <Form.Field
                required
                fluid
                autoFocus={!id} // Don't expand calendar during edit.
                control={DatePicker}
                placeholderText="Select From Date"
                // isClearable
                selectsStart
                excludeDates={this.props.exclude}
                selected={start}
                minDate={AmsDateFormatters.getMoment()}
                startDate={start}
                onChange={this.handleStartChange}
                id="fromDate"
                aria-labelledby="fromDate"
                label={{
                  children: 'From',
                  htmlFor: 'fromDate',
                }}
              ></Form.Field>

              <Form.Field
                required
                fluid
                control={DatePicker}
                placeholderText="Select To Date"
                disabled={!start}
                excludeDates={this.props.exclude}
                selected={end}
                selectsEnd
                // isClearable
                startDate={start}
                minDate={start}
                endDate={end}
                onChange={this.handleEndChange}
                id="toDate"
                aria-labelledby="toDate"
                label={{
                  children: 'To',
                  htmlFor: 'toDate',
                }}
              >
                {/* <label>To</label>
                <Form.Input
                  fluid
                  as={DatePicker}
                  placeholderText="Select To Date"
                  disabled={!start}
                  excludeDates={this.props.exclude}
                  selected={end}
                  selectsEnd
                  isClearable={true}
                  startDate={start}
                  minDate={start}
                  endDate={end}
                  onChange={this.handleEndChange}
                /> */}
              </Form.Field>
            </Form.Group>
            {/* <Form.Group widths="equal"> */}
            <Form.Field
              disabled={!start && !end}
              control={Dropdown}
              fluid
              name="excludedDates"
              onChange={this.handleExcludeDates}
              options={this.buildExcludeDates()}
              value={excludedDates}
              placeholder="Excluded dates"
              selection
              multiple
              id="excludeDates"
              aria-labelledby="excludeDates"
              label={{
                children: 'Exclude dates (optional)',
                htmlFor: 'excludeDates',
                'aria-labelledby': 'excludeDates',
              }}
              selectOnBlur={false}
              openOnFocus={false}
            ></Form.Field>
            <input type="submit" hidden value="submit" />
            {/* </Form.Group> */}
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={() => this.closeModal()}>
            Close
          </Button>
          <Button
            disabled={!start || !end || deleting}
            onClick={() => this.setState({ showSaveAlert: true })}
            primary
          >
            {saving ? 'Saving...' : 'Save dates'}
          </Button>
          {!isEmpty(id) && (
            <Button onClick={() => this.setState({ showAlert: true })} negative>
              {deleting ? 'Deleting...' : 'Delete'}
            </Button>
          )}
        </Modal.Actions>
      </AmsModal>
    );
  }
}

CalendarUnavailableModal.propTypes = {
  exclude: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  showModal: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired,
  refreshCalendar: PropTypes.func.isRequired,
  addUserEvents: PropTypes.func.isRequired,
  updateUserEvent: PropTypes.func.isRequired,
  deleteUserEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  exclude: state.calendar.exclude,
  events: state.calendar.events,
});

export default connect(mapStateToProps, {
  addUserEvents,
  updateUserEvent,
  deleteUserEvent,
})(CalendarUnavailableModal);
