import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Segment,
  Form,
  Button,
  Message,
  TextArea,
  Input,
  Dropdown,
} from 'semantic-ui-react';

//Import components
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

import _ from 'lodash';

// Import actions.
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';
import {
  addNote,
  fetchProfileUpdatedDateNotes,
} from '../../../actions/noteActions';

// Import settings.
import { acl } from '../../../config';

class TravelPreferenceForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    errors: {},
    data: {
      oid: '',
      availableToWorkInRegion: [],
      preferredAirportName: '',
      preferredAirportApproxParkingCost: '',
      preferredAirportRoundTripMileage: '',
      alternateAirportName: '',
      alternateAirportApproxParkingCost: '',
      alternateAirportRoundTripMileage: '',
      trainStation: '',
      additionalInfo: '',
      willingToTravelByAir: false,
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (state.data.oid !== props.profile.oid) {
      const { profileTab } = props;

      const {
        oid,
        availableToWorkInRegion,
        preferredAirportName,
        preferredAirportApproxParkingCost,
        preferredAirportRoundTripMileage,
        alternateAirportName,
        alternateAirportApproxParkingCost,
        alternateAirportRoundTripMileage,
        trainStation,
        additionalInfo,
        willingToTravelByAir,
      } = props.profile;

      if (profileTab !== 'travelPreference') {
        this.cancelEdit();
      }

      return {
        data: {
          oid,
          availableToWorkInRegion: availableToWorkInRegion || [],
          preferredAirportName,
          preferredAirportApproxParkingCost,
          preferredAirportRoundTripMileage,
          alternateAirportName,
          alternateAirportApproxParkingCost,
          alternateAirportRoundTripMileage,
          trainStation,
          additionalInfo,
          willingToTravelByAir,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    const {
      oid,
      availableToWorkInRegion,
      preferredAirportName,
      preferredAirportApproxParkingCost,
      preferredAirportRoundTripMileage,
      alternateAirportName,
      alternateAirportApproxParkingCost,
      alternateAirportRoundTripMileage,
      trainStation,
      additionalInfo,
      willingToTravelByAir,
    } = this.props.profile;

    const oldData = {
      oid,
      availableToWorkInRegion: availableToWorkInRegion || [],
      preferredAirportName,
      preferredAirportApproxParkingCost,
      preferredAirportRoundTripMileage,
      alternateAirportName,
      alternateAirportApproxParkingCost,
      alternateAirportRoundTripMileage,
      trainStation,
      additionalInfo,
      willingToTravelByAir,
    };

    this.setState({
      data: oldData,
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      let { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        const updateTimeNote = {
          note: AmsDateFormatters.getMoment().utc(),
          createdAt: AmsDateFormatters.getMoment().utc(),
          updateAt: AmsDateFormatters.getMoment().utc(),
          submittedBy: _.pick(this.props.currentUser, [
            'oid',
            'userName',
            'fullName',
          ]),
        };
        updateTimeNote.tags = {
          type: 'profile_update_date',
          userId: data.oid,
          field: 'Travel Preference Update Date',
        };
        this.props.addNote(updateTimeNote).then(e => {
          const body = {
            filters: {
              tags: {
                userId: data.oid,
                type: 'profile_update_date',
              },
            },
          };

          this.props.fetchProfileUpdatedDateNotes(body);
        });

        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};
    if (!data.availableToWorkInRegion || !data.availableToWorkInRegion.length)
      errors.availableToWorkInRegion = 'Region is required';
    if (!data.preferredAirportName)
      errors.preferredAirportName = 'Prefered departure airport is required';
    if (!data.preferredAirportRoundTripMileage)
      errors.preferredAirportRoundTripMileage =
        'Round trip milage to airport is required';
    if (
      data.willingToTravelByAir === '' ||
      data.willingToTravelByAir === undefined ||
      data.willingToTravelByAir === null
    )
      errors.willingToTravelByAir = 'Specify willing to travel by air';
    return errors;
  };

  handleChange = (e, { name, value }) => {
    if (value === 'Yes') value = true;
    else if (value === 'No') value = false;

    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.userSettings.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  render() {
    const {
      editMode,
      loading,
      errors,
      data,
      emptyValue,
      showSuccess,
    } = this.state;

    const { notes } = this.props;
    let date = null;
    if (!_.isEmpty(notes)) {
      const updatedTimeNotes = notes.filter(e => {
        return e.tags.field === 'Travel Preference Update Date';
      });
      const note = updatedTimeNotes.reduce((acc, val) => {
        if (acc === null) return val;
        return AmsDateFormatters.getMoment(val.updateDate).isAfter(
          acc.updateDate
        )
          ? val
          : acc;
      }, null);
      date = note && note.note;
    }

    return (
      <>
        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <div className="profile-form-title-wrapper">
            <h2>Travel Preference</h2>
          </div>

          <div className="profile-form-fields-wrapper">
            {showSuccess && (
              <Message
                positive
                icon="check"
                header="Success!"
                content="Data saved successfully."
              />
            )}

            {!_.isEmpty(errors) && (
              <Message
                negative
                icon="cancel"
                header="Something went wrong!"
                list={Object.keys(errors).map(errorKey => (
                  <li key={errorKey}>{errors[errorKey]}</li>
                ))}
              />
            )}

            <Form.Group widths="equal">
              {editMode ? (
                <Form.Field
                  required
                  error={!!errors.willingToTravelByAir}
                  style={{ width: '25px' }}
                  name="willingToTravelByAir"
                  onChange={this.handleChange}
                  value={data.willingToTravelByAir === true ? 'Yes' : 'No'}
                  options={[
                    { key: 'Yes', text: 'Yes', value: 'Yes' },
                    { key: 'No', text: 'No', value: 'No' },
                  ]}
                  placeholder="Select"
                  selection
                  control={Dropdown}
                  id="miscellaneousAirQuestion"
                  aria-labelledby="miscellaneousAirQuestion"
                  label={{
                    children: (
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="miscellaneousAirQuestion"
                        label="Are you willing to travel?"
                      />
                    ),
                    htmlFor: 'miscellaneousAirQuestion',
                  }}
                />
              ) : (
                <Form.Field required>
                  <label>
                    Are you willing to travel?
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="miscellaneousAirQuestion"
                    />
                  </label>
                  <div className="default-value">
                    {data.willingToTravelByAir === true ? 'Yes' : 'No'}
                  </div>
                </Form.Field>
              )}

              {editMode ? (
                <Form.Field required>
                  <AmsFormLabel
                    helpText={true}
                    fieldLabel="preferenceTravelRegion"
                    name="What Regions are you available to work in"
                  >
                    <AmsLookupDropdown
                      error={!!errors.availableToWorkInRegion}
                      name="availableToWorkInRegion"
                      multiple
                      // search
                      selection
                      onChange={this.handleChange}
                      value={data.availableToWorkInRegion}
                      category={'regions'}
                      placeholder="Select regions"
                      id="preferenceTravelRegion"
                      aria-labelledby="preferenceTravelRegion"
                      // searchInput={{
                      //   id: 'preferenceTravelRegionSearch',
                      //   title: 'User settings roles search',
                      //   'aria-labelledby': 'preferenceTravelRegion',
                      // }}
                    />
                  </AmsFormLabel>
                </Form.Field>
              ) : (
                <Form.Field required>
                  <label>
                    What Regions are you available to work in?
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelRegion"
                    />
                  </label>
                  <div className="default-value">
                    {data.availableToWorkInRegion
                      ? data.availableToWorkInRegion.join(', ')
                      : emptyValue}{' '}
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            <h3>Preferred Departure Airport</h3>
            <Form.Group widths="equal">
              {editMode ? (
                <Form.Field
                  required
                  error={!!errors.preferredAirportName}
                  name="preferredAirportName"
                  onChange={this.handleChange}
                  value={data.preferredAirportName || ''}
                  placeholder="Airport"
                  control={Input}
                  id="preferenceTravelAirport"
                  aria-labelledby="preferenceTravelAirport"
                  label={{
                    children: (
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="preferenceTravelAirport"
                        label="Airport"
                      />
                    ),
                    htmlFor: 'preferenceTravelAirport',
                  }}
                />
              ) : (
                <Form.Field required>
                  <label>
                    Airport
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelAirport"
                    />
                  </label>
                  <div className="default-value">
                    {data.preferredAirportName || emptyValue}
                  </div>
                </Form.Field>
              )}

              {editMode ? (
                <Form.Field
                  required
                  error={!!errors.preferredAirportRoundTripMileage}
                  name="preferredAirportRoundTripMileage"
                  onChange={this.handleChange}
                  value={data.preferredAirportRoundTripMileage || ''}
                  placeholder="Round trip mileage to airport"
                  control={Input}
                  id="preferenceTravelMileage"
                  aria-labelledby="preferenceTravelMileage"
                  label={{
                    children: (
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="preferenceTravelMileage"
                        label="Round trip mileage to airport"
                      />
                    ),
                    htmlFor: 'preferenceTravelMileage',
                  }}
                />
              ) : (
                <Form.Field required>
                  <label>
                    Round trip mileage to airport
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelMileage"
                    />
                  </label>
                  <div className="default-value">
                    {data.preferredAirportRoundTripMileage || emptyValue}
                  </div>
                </Form.Field>
              )}

              {editMode ? (
                <Form.Field
                  name="preferredAirportApproxParkingCost"
                  onChange={this.handleChange}
                  value={data.preferredAirportApproxParkingCost || ''}
                  placeholder="Approx. cost of airport parking or shuttle"
                  control={Input}
                  id="preferenceTravelParking"
                  aria-labelledby="preferenceTravelParking"
                  label={{
                    children: (
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="preferenceTravelParking"
                        label="Approx. cost of airport parking or shuttle"
                      />
                    ),
                    htmlFor: 'preferenceTravelParking',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Approx. cost of airport parking or shuttle
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelParking"
                    />
                  </label>
                  <div className="default-value">
                    {data.preferredAirportApproxParkingCost || emptyValue}
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            <h3>2nd Choice Departure airport</h3>
            <Form.Group widths="3">
              {editMode ? (
                <Form.Field
                  name="alternateAirportName"
                  onChange={this.handleChange}
                  value={data.alternateAirportName || ''}
                  placeholder="Airport"
                  control={Input}
                  id="alternateAirportName"
                  aria-labelledby="alternateAirportName"
                  label={{
                    children: 'Airport',
                    htmlFor: 'alternateAirportName',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Airport
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelAirport"
                    />
                  </label>
                  <div className="default-value">
                    {data.alternateAirportName || emptyValue}
                  </div>
                </Form.Field>
              )}

              {editMode ? (
                <Form.Field
                  name="alternateAirportRoundTripMileage"
                  onChange={this.handleChange}
                  value={data.alternateAirportRoundTripMileage || ''}
                  placeholder="Round trip mileage to airport"
                  control={Input}
                  id="alternateAirportRoundTripMileage"
                  aria-labelledby="alternateAirportRoundTripMileage"
                  label={{
                    children: 'Round trip mileage to airport',
                    htmlFor: 'alternateAirportRoundTripMileage',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Round trip mileage to airport
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelMileage"
                    />
                  </label>
                  <div className="default-value">
                    {data.alternateAirportRoundTripMileage || emptyValue}
                  </div>
                </Form.Field>
              )}

              {editMode ? (
                <Form.Field
                  name="alternateAirportApproxParkingCost"
                  onChange={this.handleChange}
                  value={data.alternateAirportApproxParkingCost || ''}
                  placeholder="Approx. cost of airport parking or shuttle"
                  control={Input}
                  id="alternateAirportApproxParkingCost"
                  aria-labelledby="alternateAirportApproxParkingCost"
                  label={{
                    children: 'Approx. cost of airport parking or shuttle',
                    htmlFor: 'alternateAirportApproxParkingCost',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Approx. cost of airport parking or shuttle
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelParking"
                    />
                  </label>
                  <div className="default-value">
                    {data.alternateAirportApproxParkingCost || emptyValue}
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            <h3>Departure Train Station</h3>
            <Form.Group widths="3">
              {editMode ? (
                <Form.Input
                  error={!!errors.trainStation}
                  name="trainStation"
                  onChange={this.handleChange}
                  value={data.trainStation || ''}
                  placeholder="Train Station (City and State of Departure)"
                  control={Input}
                  id="preferenceTravelTrain"
                  aria-labelledby="preferenceTravelTrain"
                  label={{
                    children: 'Train Station (City and State of Departure)',
                    htmlFor: 'preferenceTravelTrain',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Train Station (City and State of Departure)
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelTrain"
                    />
                  </label>
                  <div className="default-value">
                    {data.trainStation || emptyValue}
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group widths="2">
              {editMode ? (
                <Form.Field
                  name="additionalInfo"
                  onChange={this.handleChange}
                  value={data.additionalInfo}
                  placeholder="Additional information"
                  control={TextArea}
                  id="preferenceTravelAdditional"
                  aria-labelledby="preferenceTravelAdditional"
                  label={{
                    children: (
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="preferenceTravelAdditional"
                        label="Please share any additional information that will help Danya
                      with scheduling your reviews, for example, travel limitations."
                      />
                    ),
                    htmlFor: 'preferenceTravelAdditional',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Please share any additional information that will help DLH
                    with scheduling your reviews, for example, travel
                    limitations.
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="preferenceTravelAdditional"
                    />
                  </label>
                  <div className="default-value">
                    {data.additionalInfo || emptyValue}
                  </div>
                </Form.Field>
              )}
            </Form.Group>
          </div>

          <Segment basic floated="right">
            {editMode ? (
              <div>
                <Button size="large" primary>
                  Save
                </Button>
                <Button
                  size="large"
                  onClick={e => {
                    e.preventDefault();
                    this.cancelEdit();
                  }}
                  basic
                >
                  Cancel
                </Button>
              </div>
            ) : (
              this.showEditButton()
            )}
          </Segment>
        </Form>
        {date ? (
          <span
            style={{ display: 'block', textAlign: 'center', marginTop: '40px' }}
          >
            Last updated on: {AmsDateFormatters.formatDate(date)}
          </span>
        ) : null}
      </>
    );
  }
}

TravelPreferenceForm.propTypes = {
  profile: PropTypes.object,
  rolesList: PropTypes.array,
  lookups: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  notes: state.note.userProfileUpdatedNotes,
  profileTab: state.profileTab,
  rolesList: state.admin.userRoles,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {
  updateUserProfile,
  updateUserRoles,
  addNote,
  fetchProfileUpdatedDateNotes,
})(TravelPreferenceForm);
