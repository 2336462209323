import {
  has,
  isEmpty,
  filter,
  map,
  uniqBy,
  find,
  remove,
  orderBy,
  isEqual,
} from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRANForms,
  fetchRANFormDetail,
  ranGuideSelected,
  ranPerformanceMeasureSelected,
  fetchRANSubmission,
  fetchRANFormSchema,
} from '../../../actions/ranActions';
import { fetchReviewDetail } from '../../../actions/reviewActions';
import GranteeInfo from './RANGranteeInfo';
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import NotesContainer from '../../Notes';
import { fetchNotes } from '../../../actions/noteActions';
import { postFormSubmission } from '../../../actions/surveyActions';
import AmsAlert from '../../../utils/AmsAlert';
import RANGuides from './RANGuides';
import RANPerformanceMeasures from './RANPerformanceMeasures';
import RAN24PerformanceMeasures from './RANFY24Fotp/RAN24PerformanceMeasures.js';
import RANIncidents from './RANIncidents';
import { formatUserNotes, generateTagFilter } from '../../Notes/util.js';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';
import RANForm from './RANForm';
import FindingsOutsideProtocol from './FindingsOutsideProtocol/index.js';
import RAN24FindingsOutsideProtocol from './RANFY24Fotp/FindingOutsideProtocol/index.js';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import RANIncidentMulti from './RANIncidentMulti';

export default function RANIncidentPage(props) {
  const dispatch = useDispatch();
  const { selectedReview } = useSelector(state => state.review);
  const {
    selectedGuide,
    selectedPerformanceMeasure,
    isReportFinalized,
    reviewId,
    selectedCenter,
    selectedClassroom,
    submission,
  } = useSelector(state => state.forms.ran);
  const { notes } = useSelector(state => state.note);
  const [surveyNotes, setSurveyNotes] = useState([]);
  const [options, setOptions] = useState([]);
  const [tags, setTags] = useState({});
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [showMovingAlert, setShowMovingAlert] = useState(false);
  const [movingTo, setMovingTo] = useState({ pm: '', guide: '' });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [incidentFileSubmission, setIncidentFileSubmission] = useState({});
  const [incidentFileErrors, setIncidentFileErrors] = useState({});
  const [selectedPM, setSelectedPM] = useState({});
  const amsTableRef = useRef(null);
  const [amsFormId, setAmsFormId] = useState('');
  const [selectedIncidentFile, setSelectedIncidentFile] = useState({});
  const [originalData, setOriginalData] = useState({});

  useEffect(() => {
    let id = props.params && props.params.reviewId && props.params.reviewId;

    if (id) {
      if (!has(selectedReview, 'id') || selectedReview.id === '') {
        dispatch(fetchReviewDetail({ reviewId: id }))
          .then(() => {
            if (
              isEmpty(selectedGuide) &&
              isEmpty(selectedPerformanceMeasure) &&
              !props.reviewDetailsFetched
            ) {
              dispatch(fetchRANFormDetail({ reviewId: id }));
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        dispatch(fetchRANFormDetail({ reviewId: id }));
      }
    }
    return () => {
      dispatch(clearRANForms());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch form schema
   */
  const fetchFormSchema = () => {
    const { forms } = selectedPM;
    let amsFormId = '';
    let amsSubmissionId = '';
    setLoading(true);
    amsFormId = forms && forms[0] && forms[0].amsFormId;
    amsSubmissionId = forms && forms[0] && forms[0].amsSubmissionId;
    if (amsFormId) {
      setAmsFormId(amsFormId);
      dispatch(fetchRANFormSchema({ filters: { amsFormId } })).then(
        response => {
          if (response) {
            fetchSurveyData(amsSubmissionId);
          }
        }
      );
    } else {
      // TO DO: Display something when form id is not present
      setLoading(false);
    }
  };

  // useEffect to fetch notes when the selected guide or pm changes
  useEffect(() => {
    if (selectedGuide && selectedPerformanceMeasure) {
      let id = props.params && props.params.reviewId && props.params.reviewId;
      const body = {
        filters: {
          tags: {
            reviewId: id,
          },
        },
      };
      dispatch(fetchNotes(body));
      setSelectedPM(selectedPerformanceMeasure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuide, selectedPerformanceMeasure]);

  // useEffect to fetch form schema
  useEffect(() => {
    if (
      !isEmpty(props.location.query.t) &&
      'MULTI' === props.location.query.t
    ) {
      fetchFormSchemaForMulti();
    } else {
      fetchFormSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedPM.name)]);

  // useEffect to fetch form schema when selected pm changes
  useEffect(() => {
    if (selectedPM && !selectedPM.formSchema) {
      if (
        !isEmpty(props.location.query.t) &&
        'MULTI' === props.location.query.t
      ) {
        fetchFormSchemaForMulti();
      } else {
        fetchFormSchema();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedPM.formSchema)]);

  /**
   * Fetch form schema
   */
  const fetchFormSchemaForMulti = () => {
    const { forms } = selectedPM;
    let amsFormId = '';
    let amsSubmissionId = '';

    amsFormId = selectedPM && selectedPM.amsFormId;
    const data = orderBy(forms, ['incidentId'], ['desc']);
    amsSubmissionId = data[0] && data[0].amsSubmissionId;
    if (amsFormId) {
      dispatch(fetchRANFormSchema({ filters: { amsFormId } })).then(
        response => {
          if (response) {
            fetchSurveyData(amsSubmissionId);
          }
        }
      );
    }
  };

  const handleSelectedIncidents = selectedData => {
    setSelectedIncidentFile(selectedData);
  };

  // useEffect to construct tag options for notes
  useEffect(() => {
    if (
      notes &&
      !isEmpty(selectedGuide) &&
      !isEmpty(selectedPerformanceMeasure)
    ) {
      const pmOptions =
        selectedGuide.performanceMeasures.map(pm => {
          return {
            key: pm.name,
            text: pm.name,
            value: pm.name,
          };
        }) || [];
      setSurveyNotes(formatUserNotes(notes));
      setTags({
        type: 'survey_collection',
        reviewId,
        guide: selectedGuide.name,
        performanceMeasure: selectedPerformanceMeasure.name,
      });
      setOptions(
        generateTagFilter([
          ...[
            { key: 'review', value: '', text: 'All Notes for this Review' },
            { key: 'guide', value: 'guide', text: 'All Notes for this Guide' },
            { key: 'divider', value: 'divider', text: '' },
          ],
          ...pmOptions,
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  // useEffect to preselect the guide and performance measure from url params
  useEffect(() => {
    if (reviewId) preselect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId, JSON.stringify(props.location.query || '')]);

  /**
   * Preselect the guide and pm
   */
  const preselect = () => {
    // Extract query strings if passed and use them to preselect Guide and PM.
    const guideName = !isEmpty(props.location.query.g)
      ? decodeURIComponent(props.location.query.g)
      : '';
    const performanceMeasure = !isEmpty(props.location.query.pm)
      ? decodeURIComponent(props.location.query.pm)
      : '';
    const multiSubmissionType = !isEmpty(props.location.query.t)
      ? decodeURIComponent(props.location.query.t)
      : '';
    dispatch(ranGuideSelected(guideName));
    dispatch(ranPerformanceMeasureSelected(performanceMeasure));
  };

  /**
   * Checks difference between form data and the submission data to ensure there is a difference
   * @returns {boolean}
   */
  const checkDifference = () => {
    if (isEqual({}, formData) || isEqual(formData, originalData)) {
      return false;
    }
    return true;
  };

  /**
   * Set the guide and pm on redux store
   * @param {Object} guide
   */
  const changeGuide = guide => {
    setLoading(true);
    if (dataHasChanged && !showMovingAlert && checkDifference()) {
      setMovingTo({ guide: guide });
      setShowMovingAlert(true);
      return;
    }
    setFormData({});
    dispatch(fetchRANFormDetail({ reviewId })).then(() => {
      dispatch(ranGuideSelected(guide));
      dispatch(ranPerformanceMeasureSelected(guide.performanceMeasures[0]));
      setLoading(false);
    });

    setSelectedIncidentFile({});
    setRouterRedirects(guide, guide.name, guide.performanceMeasures[0].name);
  };

  /**
   * Set the pm and guide on redux store
   * @param {Object} performanceMeasure
   * @param {Object} selectedGuide
   * @returns {void}
   */
  const changePerformanceMeasure = (performanceMeasure, selectedGuide) => {
    setLoading(true);
    if (dataHasChanged && !showMovingAlert && checkDifference()) {
      setMovingTo({
        pm: performanceMeasure,
        guide: selectedGuide,
      });
      setShowMovingAlert(true);
      return;
    }
    setFormData({});
    dispatch(
      fetchRANFormDetail({
        reviewId,
      })
    ).then(() => {
      dispatch(ranGuideSelected(selectedGuide));
      dispatch(ranPerformanceMeasureSelected(performanceMeasure));
      setLoading(false);
    });

    setRouterRedirects(
      selectedGuide,
      selectedGuide.name,
      performanceMeasure.name
    );
    setSelectedPM(performanceMeasure);
  };

  const setRouterRedirects = (
    selectedGuide,
    selectedGuideName,
    selectedPerformanceMeasureName
  ) => {
    let multiSubType = '';
    let selectedPMForm = '';
    selectedPMForm = selectedGuide && selectedGuide.performanceMeasures[0];

    if (Array.isArray(selectedPMForm.forms) && !isEmpty(selectedPMForm.forms)) {
      multiSubType =
        selectedPMForm &&
        selectedPMForm.forms[0] &&
        selectedPMForm.forms[0].multiSubmissionType;
    } else {
      multiSubType = 'MULTI';
    }

    if (
      multiSubType &&
      !isEmpty(multiSubType) &&
      (selectedPerformanceMeasureName ===
        'Document Supervision Incident(s) Details Guide' ||
        selectedPerformanceMeasureName ===
          'Document Discipline Incident(s) Details Guide' ||
        selectedPerformanceMeasureName ===
          'Document Inappropriate Release Incident(s) Details Guide')
    ) {
      props.router.push(
        `${props.location.pathname}?g=${encodeURIComponent(
          selectedGuideName
        )}&pm=${encodeURIComponent(
          selectedPerformanceMeasureName
        )}&t=${encodeURIComponent(multiSubType)}`
      );
    } else {
      props.router.push(
        `${props.location.pathname}?g=${encodeURIComponent(
          selectedGuideName
        )}&pm=${encodeURIComponent(selectedPerformanceMeasureName)}`
      );
    }
  };

  // Switch to control dataHasChanged state
  const dataHasChangedSwitch = value => {
    setDataHasChanged(value);
  };

  /**
   * Handle incident file submission for the form data
   * @returns {void}
   */
  const handleIncidentFileSubmit = () => {
    let data = {
      create: incidentFileSubmission.create || [],
      update: incidentFileSubmission.update || [],
      delete:
        filter(
          incidentFileSubmission.delete,
          incidentFile => incidentFile.mongoId
        ) || [],
      topQuestion: formData.topQuestion,
      surveyData: {},
      incidentType: 'RAN-INCIDENTS',
      submissionStatus:
        !isEmpty(submission.surveyStatus) &&
        submission.surveyStatus.toLowerCase() === 'submitted'
          ? 'submitted'
          : 'draft',
    };
    if (submission && submission.amsSubmissionId)
      data.amsSubmissionId = submission.amsSubmissionId;
    if (selectedPM.forms && selectedPM.forms[0].amsFormId)
      data.amsFormId = selectedPM.forms[0].amsFormId;
    if (reviewId) data.reviewId = reviewId;
    if (selectedPM.formCode) data.formCode = selectedPM.formCode;
    if (selectedGuide.guideCode) data.guideCode = selectedGuide.guideCode;
    dispatch(postFormSubmission({ data }))
      .then(response => {
        if (response) {
          console.log('success');
        }
        dataHasChangedSwitch(false);
        dispatch(fetchRANFormDetail({ reviewId })).then(() => {
          if (movingTo.pm)
            changePerformanceMeasure(movingTo.pm, movingTo.guide);
          else changeGuide(movingTo.guide);
          setLoading(false);
          setIncidentFileErrors({});
        });
      })
      .catch(error => {
        let errorData =
          !isEmpty(error.response.data.message) &&
          JSON.parse(error.response.data.message) &&
          !isEmpty(JSON.parse(error.response.data.message).text) &&
          JSON.parse(error.response.data.message);
        setIncidentFileErrors(errorData);
        setLoading(false);
      });
  };

  /**
   * Submit the form data
   * @returns {void}
   */
  const handleSubmit = () => {
    const { forms } = selectedPerformanceMeasure;
    let amsFormId = '';
    amsFormId = forms && forms[0] && forms[0].amsFormId;

    if (!amsFormId || !reviewId) return;

    let requestObject = { reviewId, amsFormId, surveyData: formData };

    if (formData.topQuestion === false) {
      requestObject = {
        reviewId,
        amsFormId,
        surveyData: { topQuestion: false },
      };
    }

    if (submission.amsSubmissionId)
      requestObject.amsSubmissionId = submission.amsSubmissionId;

    if (selectedCenter) requestObject.centerName = selectedCenter;

    if (selectedClassroom) requestObject.classSampleId = selectedClassroom;

    // draft
    if (
      isEmpty(submission) ||
      (submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft')
    ) {
      dispatch(
        postFormSubmission({
          data: { ...requestObject, submissionStatus: 'Draft' },
        })
      )
        .then(response => {
          dataHasChangedSwitch(false);
          dispatch(fetchRANFormDetail({ reviewId })).then(() => {
            if (movingTo.pm)
              changePerformanceMeasure(movingTo.pm, movingTo.guide);
            else changeGuide(movingTo.guide);
            setLoading(false);
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      // update
      if (
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'submitted'
      ) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(response => {
            dataHasChangedSwitch(false);
            dispatch(fetchRANFormDetail({ reviewId })).then(() => {
              if (movingTo.pm)
                changePerformanceMeasure(movingTo.pm, movingTo.guide);
              else changeGuide(movingTo.guide);
              setLoading(false);
            });
          })
          .catch(error => {
            console.log(error);
          });
        return; // End of update exit.
      }
    }
  };

  /**
   * Display the moving alert modal when the data has changed
   * @returns {void}
   */
  const renderMovingAlert = () => {
    const { surveyStatus } = submission;
    return (
      <AmsAlert
        show={showMovingAlert}
        title="Are you sure you want to move away from this form"
        text={`You selected another page, are you sure you want to leave the current page?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={
          surveyStatus && surveyStatus.toLowerCase() === 'submitted'
            ? 'Submit and continue'
            : 'Save draft and continue'
        }
        cancelButtonText={'Continue without saving'}
        showConfirm
        showCancelButton
        showThirdButton
        thirdButtonText="Cancel"
        onThirdButtonAction={() => {
          setShowMovingAlert(false);
          setLoading(false);
        }}
        onCancel={() => {
          if (movingTo.pm)
            changePerformanceMeasure(movingTo.pm, movingTo.guide);
          else changeGuide(movingTo.guide);
          setShowMovingAlert(false);
          setDataHasChanged(false);
        }}
        onConfirm={() => {
          setShowMovingAlert(false);
          if (
            selectedPerformanceMeasure.name ===
              'Document Supervision Incident(s)' ||
            selectedPerformanceMeasure.name ===
              'Document Inappropriate Release Incident(s)' ||
            selectedPerformanceMeasure.name ===
              'Document Discipline Incident(s)' ||
            selectedPerformanceMeasure.name ===
              'Document Supervision Incident(s) Details Guide' ||
            selectedPerformanceMeasure.name ===
              'Document Discipline Incident(s) Details Guide' ||
            selectedPerformanceMeasure.name ===
              'Document Inappropriate Release Incident(s) Details Guide'
          ) {
            if (checkIfRANMultiNotNewForm()) {
              handleIncidentFileSubmit();
            } else {
              if (movingTo.pm)
                changePerformanceMeasure(movingTo.pm, movingTo.guide);
              else changeGuide(movingTo.guide);
              setLoading(false);
              setIncidentFileErrors({});
              setDataHasChanged(false);
            }
          } else handleSubmit();
        }}
      />
    );
  };

  const checkIfRANMultiNotNewForm = () => {
    return (
      (!isEmpty(props.location.query.t) &&
        'MULTI' !== props.location.query.t) ||
      ('SINGLE' === props.location.query.t &&
        selectedReview &&
        !selectedReview.isRANMulti) ||
      (selectedReview && !selectedReview.isRANMulti)
    );
  };

  /**
   * Render the version table for the submissions
   * @returns {void}
   */
  const renderVersionHistoryTable = () => {
    const data = submission.versionHistory.sort((a, b) =>
      AmsDateFormatters.getMoment(b.modifiedDate).diff(
        AmsDateFormatters.getMoment(a.modifiedDate)
      )
    );

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const amsSubmissionId = submission.amsSubmissionId;
            const version = cell;

            // Fetch specific version.
            dispatch(
              fetchRANSubmission({
                filters: { amsSubmissionId, version },
              })
            ).then(response => {
              if (response.submission) {
                setOriginalData(response.submission.data.surveyData);
                setFormData(response.submission.data.surveyData);
              }
            });
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      <AmsTable
        remote={false}
        basic
        total={data.length}
        columns={columns}
        keyField="version"
        ref={amsTableRef}
        data={data}
      />
    );
  };

  /**
   * Handle incident file data changes for form submission
   * @param {Object} data
   * @param {string} type - top question or incident file
   * @returns {void}
   */
  const handleIncidentFileDataChange = (data, type = '') => {
    if (type === 'topQuestion') {
      setFormData({
        ...formData,
        topQuestion: data.topQuestion,
      });
      setDataHasChanged(true);
      return;
    }

    if (!data.rowClicked && !data.fileEditButtonClicked) {
      let created = [...(incidentFileSubmission.create || [])];

      let newList = uniqBy(
        [...(created || []), ...(formData.incidentFiles || [])],
        'incidentId'
      );

      let maxValue = 0;
      if (!isEmpty(newList)) {
        maxValue = Math.max.apply(
          Math,
          newList.map(o => {
            return o.incidentId;
          })
        );
      }

      created.push({ ...data.formData, incidentId: ++maxValue });

      setIncidentFileSubmission({
        ...incidentFileSubmission,
        create: [...created],
      });
      let formObj = {
        ...formData,
        topQuestion: formData.topQuestion,
        incidentFiles: uniqBy(
          [...(created || []), ...(formData.incidentFiles || [])],
          'incidentId'
        ),
      };
      setFormData(formObj);
    } else {
      if (
        find(
          incidentFileSubmission.create,
          file => file.incidentId === data.previousIncidentId
        )
      ) {
        let created = map(incidentFileSubmission.create, file => {
          if (file.incidentId === data.previousIncidentId)
            file = { ...data.formData };
          return file;
        });
        setIncidentFileSubmission({
          ...incidentFileSubmission,
          create: [...created],
        });
        setFormData({
          ...formData,
          topQuestion: formData.topQuestion,
          incidentFiles: map(formData.incidentFiles, file => {
            if (file.incidentId === data.previousIncidentId)
              file = {
                ...data.formData,
                mongoId: data.selectedIncidentFile.mongoId,
              };
            return file;
          }),
        });
      } else if (
        find(
          incidentFileSubmission.update,
          file => file.incidentId === data.previousIncidentId
        )
      ) {
        let updated = map(incidentFileSubmission.update, file => {
          if (file.incidentId === data.previousIncidentId)
            file = {
              ...data.formData,
              mongoId: data.selectedIncidentFile.mongoId,
            };
          return file;
        });
        setIncidentFileSubmission({
          ...incidentFileSubmission,
          update: [...updated],
        });
        setFormData({
          ...formData,
          topQuestion: formData.topQuestion,
          incidentFiles: map(formData.incidentFiles, file => {
            if (file.incidentId === data.previousIncidentId)
              file = {
                ...data.formData,
                mongoId: data.selectedIncidentFile.mongoId,
              };
            return file;
          }),
        });
      } else {
        let updated = incidentFileSubmission.update
          ? [...incidentFileSubmission.update]
          : [];
        updated.push({
          ...data.formData,
          mongoId: data.selectedIncidentFile.mongoId,
        });
        setIncidentFileSubmission({
          ...incidentFileSubmission,
          update: [...updated],
        });
        setFormData({
          ...formData,
          topQuestion: formData.topQuestion,
          incidentFiles: map(formData.incidentFiles, file => {
            if (file.incidentId === data.previousIncidentId)
              file = {
                ...data.formData,
                mongoId: data.selectedIncidentFile.mongoId,
              };
            return file;
          }),
        });
      }
    }

    setDataHasChanged(true);
  };

  /**
   * Fetch form survey submission
   * @param {string} amsSubmissionId
   */
  const fetchSurveyData = amsSubmissionId => {
    setLoading(true);
    if (amsSubmissionId)
      dispatch(
        fetchRANSubmission({
          filters: {
            amsSubmissionId: amsSubmissionId,
          },
        })
      ).then(response => {
        if (response.submission) {
          setFormData(response.submission.data.surveyData);
          setOriginalData(response.submission.data.surveyData);
          setIncidentFileSubmission({});
          setSelectedIncidentData(response);
        }
        setLoading(false);
      });
    else {
      setLoading(false);
      setFormData({});
      setSelectedIncidentFile({});
    }
  };

  const setSelectedIncidentData = response => {
    if (
      response.submission.data?.surveyData?.incidentFiles &&
      ((!isEmpty(props.location.query.t) &&
        'MULTI' === props.location.query.t) ||
        (selectedReview && selectedReview.isRANMulti))
    ) {
      const { isActive, incidentId } =
        response.submission.data &&
        response.submission.data.surveyData &&
        response.submission.data.surveyData.incidentFiles &&
        response.submission.data.surveyData.incidentFiles[0].incidentData;
      const { amsFormId, mongoId, incidentData } =
        response.submission.data &&
        response.submission.data.surveyData &&
        response.submission.data.surveyData.incidentFiles &&
        response.submission.data.surveyData.incidentFiles[0];
      const { surveyStatus, amsSubmissionId } = response.submission;

      const { editedBy, editTime } = response.submission.data;
      setSelectedIncidentFile({
        amsFormId: amsFormId,
        mongoId: mongoId,
        amsSubmissionId: amsSubmissionId,
        submissionStatus: surveyStatus,
        submissionTime: editTime,
        submittedBy: editedBy,
        isActive: isActive,
        incidentId: incidentId,
        formData: incidentData,
      });
    }
  };

  /**
   * Handle incident file deletion for form submission
   * @param {Object} data
   * @returns {void}
   */
  const handleIncidentFileDelete = data => {
    setIncidentFileSubmission({
      delete: [...data.delete],
      update: [...data.update],
      create: [...data.create],
    });
    setFormData({
      ...formData,
      topQuestion: formData.topQuestion,
      incidentFiles: remove(
        formData.incidentFiles,
        file => file.incidentId !== data.selectedIncidentFile.incidentId
      ),
    });
    setDataHasChanged(true);
  };

  /**
   * Keep track of the form data changes and cancel moving to another page
   * @param {Object} formData
   */
  const handleCancelChange = formData => {
    setFormData(formData);
    setDataHasChanged(false);
  };

  /**
   * Set the form data changes for submission
   * @param {Object} formData
   * @returns {void}
   */
  const handleDataChange = ({ formData }) => {
    if (Object.values(formData).every(v => v === undefined)) {
      setFormData({});
      return;
    }
    setFormData(formData);
    setDataHasChanged(true);
  };

  const checkForRANMultiAndRoute = () => {
    return (!isEmpty(props.location.query.t) &&
      'MULTI' === props.location.query.t) ||
      (selectedReview && selectedReview.isRANMulti) ? (
      <RANIncidentMulti
        amsFormId={amsFormId}
        formData={formData}
        fetchSurveyData={id => fetchSurveyData(id)}
        handleSelectedIncidents={handleSelectedIncidents}
        selectedIncidentFile={selectedIncidentFile}
        fetchSurveyDataByVersion={fetchSurveyDataByVersion}
        loading={loading}
      />
    ) : (
      <RANIncidents
        handleDataChange={handleIncidentFileDataChange}
        formData={formData}
        incidentFileSubmission={incidentFileSubmission}
        fetchSurveyData={id => fetchSurveyData(id)}
        dataHasChangedSwitch={dataHasChangedSwitch}
        amsFormId={amsFormId}
        handleIncidentFileDelete={handleIncidentFileDelete}
        incidentFileSaveErrors={incidentFileErrors}
        handleCancelChange={handleCancelChange}
      />
    );
  };

  const fetchSurveyDataByVersion = input => {
    const { filters } = input;
    setLoading(true);
    if (filters && filters.amsSubmissionId)
      dispatch(
        fetchRANSubmission({
          filters: filters,
        })
      ).then(response => {
        setLoading(false);
        if (response.submission) {
          setOriginalData(response.submission.data.surveyData);
          setFormData(response.submission.data.surveyData);
          setIncidentFileSubmission({});
          setSelectedIncidentData(response);
        }
      });
    else {
      setLoading(false);
      setFormData({});
    }
  };
  return (
    <SlidingContainer
      calendar
      granteeStatusBanner={true}
      granteeStatusData={{
        type: 'review',
      }}
      title={`RAN Survey for Review ID ${reviewId}`}
      actionButtons={
        <NotesContainer
          tags={tags && tags}
          defaultFilter={'performanceMeasure'}
          filterOptions={options && options}
          list={surveyNotes && surveyNotes}
          enabled={!isReportFinalized && surveyNotes}
          type={'Notes'}
          reloadData={() => {
            const body = {
              filters: {
                tags: {
                  reviewId,
                },
              },
            };
            dispatch(fetchNotes(body));
          }}
        />
      }
    >
      {dataHasChanged && renderMovingAlert()}
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>{<GranteeInfo />}</Grid.Column>
        </Grid.Row>
        <Grid.Column width={4}>
          <RANGuides {...props} changeGuide={changeGuide} />
          {selectedReview.fiscalYear == 2024 ? (
            <RAN24PerformanceMeasures
              changePerformanceMeasure={changePerformanceMeasure}
            />
          ) : (
            <RANPerformanceMeasures
              changePerformanceMeasure={changePerformanceMeasure}
            />
          )}
        </Grid.Column>
        <Grid.Column stretched width={12}>
          {!isEmpty(selectedPM) &&
          selectedPM.name === 'Findings Outside the Protocol' ? (
            selectedReview.fiscalYear == 2024 ? (
              <RAN24FindingsOutsideProtocol
                formData={formData}
                handleDataChange={handleDataChange}
                dataHasChangedSwitch={dataHasChangedSwitch}
                handleCancelChange={handleCancelChange}
              />
            ) : (
              <FindingsOutsideProtocol
                formData={formData}
                handleDataChange={handleDataChange}
                dataHasChangedSwitch={dataHasChangedSwitch}
                handleCancelChange={handleCancelChange}
              />
            )
          ) : selectedPM.name === 'Document Supervision Incident(s)' ||
            selectedPM.name === 'Document Inappropriate Release Incident(s)' ||
            selectedPM.name === 'Document Discipline Incident(s)' ||
            selectedPM.name ===
              'Document Supervision Incident(s) Details Guide' ||
            selectedPM.name ===
              'Document Discipline Incident(s) Details Guide' ||
            selectedPM.name ===
              'Document Inappropriate Release Incident(s) Details Guide' ? (
            checkForRANMultiAndRoute()
          ) : (
            <Segment basic>
              <RANForm
                handleDataChange={handleDataChange}
                formData={formData}
                dataHasChangedSwitch={dataHasChangedSwitch}
                handleCancelChange={handleCancelChange}
                amsFormId={amsFormId}
              />
            </Segment>
          )}
          {'MULTI' !== props.location.query.t &&
            submission &&
            submission.versionHistory &&
            renderVersionHistoryTable()}
        </Grid.Column>
      </Grid>
    </SlidingContainer>
  );
}
