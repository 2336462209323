import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Message, Checkbox, Input } from 'semantic-ui-react';

// Import actions.
import { updateLookup, addLookup } from '../../../actions/lookupActions';

class LookupNumberForm extends Component {
  state = {
    updating: false,
    adding: false,
    errors: {},
    data: {},
    newData: {
      value: '',
      valueType: 'Number',
      active: true,
      parent: false,
    },
  };

  componentWillReceiveProps = nextProps => {
    const { selectedCategoryLookups } = nextProps;

    const data = {};
    selectedCategoryLookups.forEach(lookup => {
      if (!lookup.parent) data[lookup._id] = lookup;
    });

    this.setState({ data });
  };

  resetNewForm = () =>
    this.setState({
      adding: false,
      errors: {},
      newData: {
        value: '',
        valueType: 'Number',
        active: true,
        parent: false,
      },
    });

  validate = data => {
    const errors = {};
    // Validate update forms.
    if (data._id) {
      if (!_.isNumber(data.value))
        errors[data._id] = {
          ...errors[data._id],
          value: 'Lookup value is required.',
        };
    } else {
      // Validating new data form.
      if (_.isEmpty(data.value))
        errors.newData = {
          ...errors.newData,
          value: 'Lookup value is required.',
        };
    }
    return errors;
  };

  onUpdateSubmit = (id, e) => {
    e.preventDefault();

    const errors = this.validate(this.state.data[id]);
    this.setState({ errors });

    // No more errors submit data.
    if (!errors[id] || Object.keys(errors[id]).length === 0) {
      const { _id, value, active } = this.state.data[id];
      const data = { value: Number(value), active };
      if (!_id) return;
      this.setState({ updating: { [_id]: true } });
      this.props
        .updateLookup(_id, data)
        .then(() => this.setState({ updating: { [_id]: false }, errors: {} }))
        .catch(error => {
          this.setState({
            updating: { [_id]: false },
            errors: { ...this.state.errors, parentError: error.response },
          });
        });
    }
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.newData);

    this.setState({ errors });

    // No more errors submit data.
    if (!errors.newData || Object.keys(errors.newData).length === 0) {
      const { value, valueType, active, parent } = this.state.newData;
      const data = {
        value: Number(value),
        valueType,
        active,
        parent,
        category: this.props.selectedLookup.category,
      };

      this.setState({ adding: true });

      this.props
        .addLookup(data)
        .then(() => this.resetNewForm())
        .catch(error => {
          this.setState({
            adding: false,
            errors: { ...this.state.errors, parentError: error.response },
          });
        });
    }
  };

  handleChange = (id, e, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [id]: { ...this.state.data[id], [name]: Number(value) },
      },
    });
  };

  handleToggle = (id, e) => {
    this.setState({
      data: {
        ...this.state.data,
        [id]: { ...this.state.data[id], active: !this.state.data[id].active },
      },
    });
  };

  handleNewChange = (e, { name, value }) => {
    this.setState({
      newData: { ...this.state.newData, [name]: value },
    });
  };

  handleNewToggle = () => {
    this.setState({
      newData: { ...this.state.newData, active: !this.state.newData.active },
    });
  };

  renderUpdateForm = () => {
    const { data, updating, errors } = this.state;

    return (
      !_.isEmpty(data) && (
        <div>
          <h4>Values</h4>

          {Object.keys(data).map(key => {
            const child = data[key];

            if (!child._id) return null;

            return (
              <Form
                onSubmit={this.onUpdateSubmit.bind(this, child._id)}
                key={child._id}
              >
                <Form.Group widths="equal">
                  <Form.Field
                    name="value"
                    type="number"
                    control={Input}
                    value={child.value}
                    error={errors[child._id] ? !!errors[child._id].value : null}
                    onChange={this.handleChange.bind(this, child._id)}
                    id={child._id}
                    aria-labelledby={child._id}
                    title="number value"
                  />

                  <Form.Field style={{ textAlign: 'right' }}>
                    <Form.Field
                      name="active"
                      toggle
                      role="group"
                      control={Checkbox}
                      label={{ children: 'Enabled', htmlFor: child._id }}
                      checked={child.active}
                      onChange={this.handleToggle.bind(this, child._id)}
                      id={child._id}
                      aria-labelledby={child._id}
                    />
                  </Form.Field>

                  <Form.Field style={{ textAlign: 'right' }}>
                    <Form.Button
                      color="green"
                      size="tiny"
                      disabled={updating[child._id]}
                      content={updating[child._id] ? 'Updating...' : 'Update'}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            );
          })}
          <div>
            <hr />
          </div>
        </div>
      )
    );
  };

  renderAddNewForm = () => {
    const { newData, errors, adding } = this.state;

    return (
      <div>
        <h4>Add new value</h4>
        <Form onSubmit={this.onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              name="value"
              type="number"
              control={Input}
              value={newData.value}
              error={errors.newData ? !!errors.newData.value : null}
              onChange={this.handleNewChange}
              id="newChild"
              aria-labelledby="newChild"
              title="new value"
            />

            <Form.Field style={{ textAlign: 'right' }}>
              <Form.Field
                name="active"
                toggle
                role="group"
                control={Checkbox}
                label={{ children: 'Enabled', htmlFor: 'enabledValue' }}
                checked={newData.active}
                onChange={this.handleNewToggle}
                id="enabledValue"
                aria-labelledby="enabledValue"
              />
            </Form.Field>

            <Form.Field style={{ textAlign: 'right' }}>
              <Form.Button
                color="green"
                size="tiny"
                disabled={adding}
                content={adding ? 'Saving...' : 'Add Value'}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  };

  showError = () => {
    const { errors } = this.state;
    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(key => (
          <li key={key}>
            {Object.keys(errors[key]).map(errorKey => errors[key][errorKey])}
          </li>
        ))}
      />
    );
  };

  render() {
    const { selectedLookup } = this.props;

    return (
      selectedLookup.valueType === 'Number' && (
        <div>
          {this.showError()}

          {this.renderUpdateForm()}

          {this.renderAddNewForm()}
        </div>
      )
    );
  }
}

LookupNumberForm.propTypes = {
  updateLookup: PropTypes.func.isRequired,
  addLookup: PropTypes.func.isRequired,
  selectedLookup: PropTypes.object.isRequired,
  selectedCategoryLookups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  selectedLookup: state.lookups.selectedLookup,
  selectedCategoryLookups: state.lookups.selectedCategoryLookups,
});

export default connect(mapStateToProps, { updateLookup, addLookup })(
  LookupNumberForm
);
