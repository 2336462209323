import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import actions.
import { selectTab } from '../../actions/adminActions';

class TemplateAdminPage extends Component {
  componentWillMount() {
    this.props.selectTab({
      key: 4,
      pageTitle: 'Templates',
    });
  }

  render() {
    return <div>Templates Admin Page</div>;
  }
}

TemplateAdminPage.propTypes = {};

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  { selectTab }
)(TemplateAdminPage);
