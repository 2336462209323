import React, { Component } from 'react';
import { connect } from 'react-redux';

class BooleanTrueOnlyField extends Component {
  render() {
    return (
      <div class="checkbox ">
        <label>
          <input
            checked={this.props.formData}
            onChange={(data, ev) => {
              let val = data.target.checked ? true : undefined;
              this.props.onChange(val);
            }}
            type="checkbox"
            disabled={this.props.disabled}
            id={'root_' + this.props.name}
            required={this.props.required}
          />
          <span>{this.props.schema.title}</span>
        </label>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(BooleanTrueOnlyField);
