import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Icon,
  Input,
  List,
  Message,
  Modal,
  Radio,
  Segment,
} from 'semantic-ui-react';

// Import util
import AmsModal from '../../utils/AmsModal';
import enforceRole from '../../utils/EnforceRole';

// Import config.
import { acl } from '../../config';

// Style
import './assets/style.css';

// Actions.
import {
  addCategory,
  deleteCategory,
  fetchCategories,
  updateCategory,
} from '../../actions/help/categoryActions';

const HelpCategoryModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoryId, setCategoryId] = useState(null);
  const [data, setData] = useState({
    title: '',
    description: '',
    active: false,
  });
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.help);
  const currentUser = useSelector(state => state.auth.user);

  const onSubmit = e => {
    setErrors({});
    e.preventDefault();
    /*const errors = ''//validateInput(data);

    if (errors) return;*/

    // No errors save data.
    saveCategory();
  };

  const saveCategory = () => {
    if (categoryId) {
      dispatch(updateCategory(categoryId, data))
        .then(() => {
          clearForm();
          setShowModal(false);
          dispatch(fetchCategories());
        })
        .catch(error => {
          setErrors(error.message);
        });
    } else {
      dispatch(addCategory(data))
        .then(() => {
          clearForm();
          setShowModal(false);
          dispatch(fetchCategories());
        })
        .catch(error => {
          setErrors(error.message);
        });
    }
  };

  const renderCategoryList = () => {
    const editCategoryClicked = id => {
      setCategoryId(id);
      const categoryData = categories.find(e => {
        return e._id === id;
      });

      setData(_.pick(categoryData, ['title', 'description', 'active']));
    };

    const deleteCategoryClicked = id => {
      dispatch(deleteCategory(id));
    };

    if (!categories || categories.length === 0) {
      return <span>No Categories found.</span>;
    }
    return (
      <List divided relaxed>
        {categories.map(e => (
          <List.Item key={e._id}>
            <List.Content floated="right">
              <Button.Group icon>
                <Button
                  onClick={() => {
                    editCategoryClicked(e._id);
                  }}
                  basic
                  color="green"
                  aria-label="Edit Row"
                >
                  <Icon name="pencil" />
                </Button>
                <Button
                  basic
                  aria-label="Delete Row"
                  onClick={() => {
                    deleteCategoryClicked(e._id);
                  }}
                  color="red"
                >
                  <Icon name="trash" />
                </Button>
              </Button.Group>
            </List.Content>
            <List.Content>{e.title}</List.Content>
          </List.Item>
        ))}
      </List>
    );
  };

  const clearForm = () => {
    setErrors({});
    setData({
      title: '',
      description: '',
      active: false,
    });
    setCategoryId(null);
  };

  const handleValueChange = (e, { name, value, checked }) => {
    setErrors({});
    if (name === 'active') {
      value = checked;
    }
    setData({ ...data, [name]: value });
  };

  const generateModalAlert = () => {
    if (!_.isEmpty(errors)) {
      return (
        <Message
          style={{ width: '95%', margin: '0 auto' }}
          negative
          header={'Something went wrong!'}
        >
          <Icon name="cancel" />
          {errors.message}
        </Message>
      );
    }
  };

  const renderModal = () => {
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {' '}
        <Modal.Header>Add or Modify Help Categories</Modal.Header>
        <Modal.Content scrolling>
          <div>{renderCategoryList()}</div>
          <Segment clearing>
            {generateModalAlert()}
            <Form onSubmit={onSubmit}>
              <Form.Field
                required
                value={data.title}
                aria-label="Title of Category"
                onChange={handleValueChange}
                name="title"
                control={Input}
                label="Title"
                placeholder="Title"
              />
              <Form.Field
                title="Description of Category"
                value={data.description}
                onChange={handleValueChange}
                name="description"
                control={Input}
                label="Description"
                placeholder="Description"
              />
              <Form.Field
                checked={data.active}
                name="active"
                onChange={handleValueChange}
                toggle
                id="activeToggle"
                label={{
                  children: 'Active',
                  htmlFor: 'activeToggle',
                }}
                control={Radio}
              />

              <Segment basic floated="left">
                <Button
                  type="submit"
                  onClick={e => {
                    onSubmit(e);
                  }}
                  disabled={!data.title}
                  primary
                >
                  {!categoryId ? 'Create' : 'Save'}
                </Button>
                <Button
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Segment>
              <Segment basic floated="right">
                <Button
                  onClick={() => {
                    clearForm();
                  }}
                >
                  Clear Form
                </Button>
              </Segment>
            </Form>
          </Segment>
        </Modal.Content>
      </AmsModal>
    );
  };

  const renderButton = () => {
    return enforceRole(
      <Button
        className="help-edit-modal-button"
        onClick={e => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
        Modify Categories
      </Button>,
      acl.actions.help.create, // Allowed roles
      currentUser.roles // Current user roles);
    );
  };

  return (
    <>
      {renderModal()}
      {renderButton()}
    </>
  );
};

export default HelpCategoryModal;
