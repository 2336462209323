import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Message } from 'semantic-ui-react';

class GranteeCalendarClosed extends Component {
  showTabsAgain = () => {
    this.props.showTabsAgain();
  };

  renderClosedDetails() {
    let { showCloseMessage } = this.props;
    return showCloseMessage ? (
      <div>
        <Message
          header="The changes you have made have been cancelled."
          content="You can still make changes to your unavailability if needed. If you would like to view and edit your unavailability now please click the View Unavailability button below."
        />
        <Button color="blue" onClick={this.showTabsAgain}>
          View Unavailability
        </Button>
      </div>
    ) : (
      <div />
    );
  }

  render() {
    return <div> {this.renderClosedDetails()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarClosed);
