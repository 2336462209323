import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

export default function Sign(props) {
  const { user } = useSelector(state => state.auth);
  const { newReport } = useSelector(state => state.report);
  const { selectedReview } = useSelector(state => state.review);
  return (
    (newReport || {}).reportStage === 'finalized' &&
    selectedReview &&
    selectedReview.reviewStatus !== 'Report On Hold' &&
    (((user || {}).roles || []).indexOf('Analyst') !== -1 ||
      ((user || {}).roles || []).indexOf('TechnicalWriter') !== -1 ||
      ((user || {}).roles || []).indexOf('Editor') !== -1 ||
      ((user || {}).roles || []).indexOf('AmsAdmi') !== -1) && (
      <Button
        className="dropdown-item"
        style={{
          background: 'none',
          borderStyle: 'none',
          textAlign: 'left',
          color: 'black',
        }}
        onClick={
          () => {
            let message =
              'You are about to sign the Report. Are you sure you want to continue?';
            props.showAlertToggle(true, message, 'sign');
            console.log('sign');
          }
          // this.generateReport(this.props.reportVersionData.versionStatus)
        }
        content="Sign"
      />
    )
  );
}
