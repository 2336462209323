import React from 'react';
import { isEmpty } from 'lodash';
import DOMPurify from 'dompurify';

function ResponsiveScriptWidget(props) {
  let scripts = [];
  if (
    Array.isArray(props.schema.selectedValue) &&
    Array.isArray(props.schema.value)
  ) {
    props.schema.selectedValue.forEach(index => {
      if (props.schema.value.length > index) {
        scripts.push(props.schema.value[index]);
      }
    });
  } else if (!Array.isArray(props.schema.value)) {
    scripts.push(props.schema.value);
  }

  scripts = scripts.filter(x => !isEmpty(x));
  var scriptsView = scripts.map(x => (
    <li key={x}>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x) }} />
    </li>
  ));

  var labelClass = 'responsive-script ' + props.schema.className;
  return scripts.length > 0 ? (
    <div className={labelClass}>
      <span style={{ textAlign: 'left' }}>
        <b>{'Responsive Script: '}</b>{' '}
        <ul style={{ marginBottom: '0px' }}>{scriptsView}</ul>
      </span>
    </div>
  ) : (
    <></>
  );
}

export default ResponsiveScriptWidget;
