import React from 'react';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Set to moment timezone.
const formatDate = date => AmsDateFormatters.getMoment(date).fromNow();

export const formatUserNotes = list => {
  return list.map((e, index) => {
    return {
      header: e.submittedBy.fullName,
      content: e.note,
      footer: e.updateAt,
      filter: e.tags,
    };
  });
};

export const generateTagFilter = options => {
  const list = options.map(item => ({
    key: item.key,
    text: item.text,
    value: item.value,
  }));
  return list;
};
