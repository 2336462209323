import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

// Import Actions
import { fetchDualComparisonReport } from '../../actions/submissions';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsTable from '../../utils/AmsTable';

class CLASSDualCoderComparison extends Component {
  state = {
    dualComparisonReport: {},
  };

  componentDidMount() {
    let input = {
      reviewId: this.context.router.params.id, //"1805555C"
    };
    this.props.fetchDualComparisonReport(input).then(data => {
      this.setState({
        dualComparisonReport: {
          ...data.dualComparisonReport,
        },
      });
    });
  }

  isExpandableRow = row => {
    return true;
  };

  expandComponent = row => {
    const columnClassNameFormatOC1 = (fieldValue, row, rowIdx, colIdx) => {
      return row.OC1difference > 1 ? 'td-column-function-red' : '';
    };

    const columnClassNameFormatOC2 = (fieldValue, row, rowIdx, colIdx) => {
      return row.OC2difference > 1 ? 'td-column-function-red' : '';
    };

    return (
      <BootstrapTable
        data={row.data}
        // tableContainerClass='react-bs-table react-bs-container-body'
        // headerContainerClass='table-header'
      >
        <TableHeaderColumn
          row="0"
          className="td-header-dual-comparison"
          columnClassName="td-column-dual-comparison-area"
          dataField="area"
          rowSpan="2"
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
          isKey={true}
        >
          Area
        </TableHeaderColumn>
        <TableHeaderColumn
          row="0"
          className="td-header-dual-comparison"
          colSpan="3"
        >
          Observation Cycle 1
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          className="td-header-dual-comparison"
          dataField="OC1reviewer"
        >
          Reviewer
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          className="td-header-dual-comparison"
          dataField="OC1dualCoder"
        >
          Dual Coder
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          className="td-header-dual-comparison"
          dataField="OC1difference"
          columnClassName={columnClassNameFormatOC1}
        >
          Difference
        </TableHeaderColumn>
        <TableHeaderColumn
          row="0"
          className="td-header-dual-comparison"
          colSpan="3"
        >
          Observation Cycle 2
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          className="td-header-dual-comparison"
          dataField="OC2reviewer"
        >
          Reviewer
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          className="td-header-dual-comparison"
          dataField="OC2dualCoder"
        >
          Dual Coder
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          className="td-header-dual-comparison"
          dataField="OC2difference"
          columnClassName={columnClassNameFormatOC2}
        >
          Difference
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  prepareDualComparisonReport = () => {
    const { dualComparisonReport } = this.props;

    const data = dualComparisonReport.reportData
      ? dualComparisonReport.reportData.map((d, index) => {
          return {
            ...d,
            id: index,
          };
        })
      : [];

    const columns = [
      { dataField: 'id', hidden: true, text: 'id' },
      {
        headerClasses: 'td-header-dual-comparison',
        text: 'Center Name',
        dataField: 'centerName',
      },
      {
        dataField: 'classroomName',
        text: 'Classroom Name',
        headerClasses: 'td-header-dual-comparison',
      },
      {
        dataField: 'reviewer',
        text: 'Reviewer',
        headerClasses: 'td-header-dual-comparison td-dropdown',
      },
      {
        dataField: 'dualCoder',
        text: 'Dual Coder',
        headerClasses: 'td-header-dual-comparison td-dropdown',
      },
      {
        dataField: 'visitDate',
        text: 'Visit Date',
        headerClasses: 'td-header-dual-comparison',
      },
      {
        text: '# Children Enrolled',
        dataField: 'childrenEnrolled',
        headerClasses: 'td-header-dual-comparison',
      },
    ];

    const columRenderer = ({ expanded, rowKey, expandable }) => {
      let content = '';

      if (expandable) {
        content = expanded ? '-' : '+';
      } else {
        content = ' ';
      }
      return <div> {content} </div>;
    };
    const expandRow = {
      renderer: this.expandComponent,
      showExpandColumn: true,
      expandColumnRenderer: columRenderer,
    };

    return (
      <AmsTable
        remote={false}
        total={data.length}
        columns={columns}
        keyField="id"
        loading={this.state.loading}
        data={data || []}
        expandRow={expandRow}
      ></AmsTable>
    );
  };

  render() {
    const { reviewId, reviewType, GrantInfo } = this.state.dualComparisonReport;
    const grantee = GrantInfo ? GrantInfo[0] : {};
    return (
      <div>
        <SlidingContainer
          // calendar
          title={
            'Dual Coder Comparison Report for Review ID ' +
            this.context.router.params.id
          }
        >
          {/* {assignments.assignmentChangeFlag &&
            <Message
              negative
              icon='warning'
              header='Notice'
              content={`The New ${assignments.sampleLabel} Class Sampling report has been generated on ${moment(assignments.sampleGenrationDate).format("MMM Do YYYY")}. All the earlier assignments made if any based on older version of samples will be replaced.`}
            />
          } */}
          <div className="well survey-header-info">
            {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
            {reviewType} ( {reviewId} )
          </div>
          {this.prepareDualComparisonReport()}
        </SlidingContainer>
      </div>
    );
  }
}

CLASSDualCoderComparison.propTypes = {};

CLASSDualCoderComparison.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    selectedReview: state.review.selectedReview,
    dualComparisonReport: state.submissions.dualComparisonReport,
  };
}

export default connect(mapStateToProps, { fetchDualComparisonReport })(
  CLASSDualCoderComparison
);
