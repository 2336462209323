import axios from 'axios';

import {
  FA1_PERFORMANCE_MEASURE_SELECTED,
  FA1_FORM_DETAIL_FETCHED,
  FA1_DRAFT_SAVED,
  FA1_SUBMISSION_SAVED,
  FA1_SUBMISSION_UPDATED,
  FA1_SUBMISSION_FETCHED,
  FA1_FORM_SCHEMA_FETCHED,
  FA1_GUIDE_SELECTED,
  FA1_CENTER_SELECTED,
  FA1_CLASSROOM_SELECTED,
  FA1_UPDATE_GUIDE,
  FA1_CLEAR_FORMS,
} from '../redux/types';

export const fa1PerformanceMeasureSelected = selected => ({
  type: FA1_PERFORMANCE_MEASURE_SELECTED,
  selected,
});

export const fa1CenterSelected = center => ({
  type: FA1_CENTER_SELECTED,
  center,
});

export const fa1ClassroomSelected = classroom => ({
  type: FA1_CLASSROOM_SELECTED,
  classroom,
});

export const fa1FormDetailFetched = fa1 => ({
  type: FA1_FORM_DETAIL_FETCHED,
  fa1,
});

export const fa1FormSchemaFetched = schema => ({
  type: FA1_FORM_SCHEMA_FETCHED,
  schema,
});

export const fa1SubmissionFetched = submission => ({
  type: FA1_SUBMISSION_FETCHED,
  submission,
});

export const fa1DraftSaved = () => ({
  type: FA1_DRAFT_SAVED,
});

export const fa1SubmissionSaved = () => ({
  type: FA1_SUBMISSION_SAVED,
});

export const fa1SubmissionUpdated = () => ({
  type: FA1_SUBMISSION_UPDATED,
});

export const fa1GuideSelected = (guide, performanceMeasure) => ({
  type: FA1_GUIDE_SELECTED,
  guide,
  performanceMeasure,
});

export const fa1UpdateGuide = (guide, performanceMeasure, submissionId) => ({
  type: FA1_UPDATE_GUIDE,
  guide,
  performanceMeasure,
  submissionId,
});

export const clearFA1Forms = () => ({
  type: FA1_CLEAR_FORMS,
});

export const fetchFA1FormDetail = request => dispatch =>
  axios
    .post(`/survey/fa1/fetch-form-detail`, request)
    .then(response => dispatch(fa1FormDetailFetched(response.data)));

export const fetchFA1FormSchema = formId => dispatch =>
  axios
    .post(`/survey/fa1/fetch-form-schema`, formId)
    .then(response => dispatch(fa1FormSchemaFetched(response.data)));

export const fetchFA1Submission = request => dispatch =>
  axios
    .post(`/survey/fa1/fetch-submission`, request)
    .then(response => dispatch(fa1SubmissionFetched(response.data)));

export const fa1SaveDraft = request => dispatch =>
  axios.post(`/survey/fa1/save-draft`, request).then(response => {
    dispatch(fa1DraftSaved());
    return response.data;
  });

export const fa1SaveSubmission = request => dispatch =>
  axios.post(`/survey/fa1/save-submission`, request).then(response => {
    dispatch(fa1SubmissionSaved());
    return response.data;
  });

export const fa1UpdateSubmission = request => dispatch =>
  axios.post(`/survey/fa1/update-submission`, request).then(response => {
    dispatch(fa1SubmissionSaved());
    return response.data;
  });
