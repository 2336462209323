/* eslint-disable react/prop-types,react/destructuring-assignment */
import React from 'react';
import { Button, Grid, Segment, Icon } from 'semantic-ui-react';
import { utils } from '@rjsf/core';
import { cleanClassNames, getSemanticProps, MaybeWrap } from './utils';

const { isFixedItems } = utils;

const ArrayFieldTitle = ({ TitleField, idSchema, uiSchema, title }) => {
  if (!title) {
    return null;
  }

  const id = `${idSchema.$id}__title`;
  return (
    <TitleField
      id={id}
      className="array-title"
      title={title}
      options={uiSchema['ui:options']}
    />
  );
};

function ArrayFieldDescription({ DescriptionField, idSchema, description }) {
  if (!description) {
    // See #312: Ensure compatibility with old versions of React.
    return null;
  }
  const id = `${idSchema.$id}__description`;
  return (
    <DescriptionField
      id={id}
      className="array-description"
      description={description}
    />
  );
}

const gridStyle = vertical => ({
  display: 'grid',
  gridTemplateColumns: `1fr ${vertical ? 65 : 110}px`,
});

// checks if its the first array item
function isInitialArrayItem(props) {
  // no underscore because im not sure if we want to import a library here
  const { idSchema } = props.children.props;
  return idSchema.target && idSchema.conditions;
}

// Used in the two templates
function DefaultArrayItem(props) {
  return (
    <div className="array-item" key={props.key}>
      <div className="row">
        <div className="col-md-9">
          <h5 className="array-item-label">
            {props.itemLabel + ' ' + (props.index + 1)}
          </h5>
        </div>
        <div className="col-md-3">
          <div style={{ textAlign: 'right' }}>
            {props.hasToolbar && props.hasRemove && (
              <Button.Group size="mini" vertical={false}>
                {props.hasRemove && (
                  <Button
                    className="array-item-remove"
                    tabIndex="-1"
                    icon
                    size="tiny"
                    labelPosition="left"
                    disabled={props.disabled || props.readOnly}
                    onClick={props.onDropIndexClick(props.index)}
                    style={{
                      background: '#db2828',
                      color: '#fff',
                    }}
                  >
                    <Icon name="trash" />
                    Delete {props.itemLabel + ' ' + (props.index + 1)}
                  </Button>
                )}
              </Button.Group>
            )}
          </div>
        </div>
      </div>
      <MaybeWrap wrap={props.wrapItem} component={Segment}>
        <Grid
          style={
            !isInitialArrayItem(props)
              ? { ...gridStyle(!props.horizontalButtons), alignItems: 'center' }
              : gridStyle(!props.horizontalButtons)
          }
        >
          <Grid.Column width={16} verticalAlign="middle">
            {props.children}
          </Grid.Column>
        </Grid>
      </MaybeWrap>
    </div>
  );
}

// Used for arrays that are represented as multiple selection fields
// (displayed as a multi select or checkboxes)
function DefaultFixedArrayFieldTemplate({
  uiSchema,
  idSchema,
  canAdd,
  className,
  classNames,
  disabled,
  items,
  onAddClick,
  readOnly,
  required,
  schema,
  title,
  TitleField,
  itemProps,
}) {
  const fieldTitle = uiSchema['ui:title'] || title;
  const fieldDescription = uiSchema['ui:description'] || schema.description;
  const itemLabel = schema.category || 'Item';
  return (
    <div className={cleanClassNames([className, classNames])}>
      <ArrayFieldTitle
        key={`array-field-title-${idSchema.$id}`}
        TitleField={TitleField}
        idSchema={idSchema}
        uiSchema={uiSchema}
        title={fieldTitle}
        required={required}
      />

      {fieldDescription && (
        <div
          className="field-description"
          key={`field-description-${idSchema.$id}`}
        >
          {fieldDescription}
        </div>
      )}

      <div key={`array-item-list-${idSchema.$id}`}>
        <div className="row array-item-list">
          {items &&
            items.map(p => DefaultArrayItem({ ...p, ...itemProps, itemLabel }))}
        </div>

        {canAdd && (
          <div
            style={{
              position: 'relative',
              textAlign: 'left',
            }}
          >
            <Button
              onClick={onAddClick}
              disabled={disabled || readOnly}
              icon
              size="tiny"
              labelPosition="left"
              style={{
                background: '#337ab7',
                color: '#fff',
              }}
            >
              <Icon name="plus" />
              Add {itemLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function DefaultNormalArrayFieldTemplate({
  uiSchema,
  idSchema,
  canAdd,
  className,
  classNames,
  disabled,
  DescriptionField,
  items,
  onAddClick,
  readOnly,
  required,
  schema,
  title,
  TitleField,
  itemProps,
}) {
  const fieldTitle = uiSchema['ui:title'] || title;
  const fieldDescription = uiSchema['ui:description'] || schema.description;
  const itemLabel = schema.category || 'Item';
  return (
    <div
      className={cleanClassNames([
        className,
        classNames,
        'sortable-form-fields',
      ])}
    >
      <ArrayFieldTitle
        key={`array-field-title-${idSchema.$id}`}
        TitleField={TitleField}
        idSchema={idSchema}
        uiSchema={uiSchema}
        title={fieldTitle}
        required={required}
      />

      {fieldDescription && (
        <ArrayFieldDescription
          key={`array-field-description-${idSchema.$id}`}
          DescriptionField={DescriptionField}
          idSchema={idSchema}
          description={fieldDescription}
        />
      )}

      <div key={`array-item-list-${idSchema.$id}`}>
        <div className="row array-item-list">
          {items &&
            items.map(p => DefaultArrayItem({ ...p, ...itemProps, itemLabel }))}
        </div>

        {canAdd && (
          <div
            style={{
              position: 'relative',
              textAlign: 'left',
            }}
          >
            <Button
              onClick={onAddClick}
              disabled={disabled || readOnly}
              icon
              size="tiny"
              labelPosition="left"
              style={{
                background: '#337ab7',
                color: '#fff',
              }}
            >
              <Icon name="plus" />
              Add {itemLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function ArrayFieldTemplate(props) {
  const { options, schema, uiSchema, formContext } = props;
  const semanticProps = getSemanticProps({
    options,
    uiSchema,
    formContext,
    defaultSchemaProps: { horizontalButtons: false, wrapItem: false },
  });
  const { horizontalButtons, wrapItem } = semanticProps;
  const itemProps = { horizontalButtons, wrapItem };

  if (isFixedItems(schema)) {
    return <DefaultFixedArrayFieldTemplate {...props} itemProps={itemProps} />;
  }
  return <DefaultNormalArrayFieldTemplate {...props} itemProps={itemProps} />;
}

export default ArrayFieldTemplate;
