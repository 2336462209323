import React from 'react';

/**
 * Combine multiple strings containing class names into a single string,
 * removing duplicates. E.g.
 * cleanClassNames('bar', 'baz bar', 'x y ', undefined)
 * // 'bar baz x y'
 */
export function cleanClassNames(classNameArr, omit = []) {
  // Split each arg on whitespace, and add it to an array. Skip false-y args
  // like "" and undefined.
  const classList = classNameArr
    .filter(Boolean)
    .reduce(
      (previous, current) => previous.concat(current.trim().split(/\s+/)),
      []
    );

  // Remove any class names from omit, and make the rest unique before
  // returning them as a string
  return [...new Set(classList.filter(cn => !omit.includes(cn)))].join(' ');
}

/**
 * Extract props meant for semantic UI components from props that are
 * passed to Widgets, Templates and Fields.
 */
export function getSemanticProps({
  formContext = {},
  uiSchema = {},
  options = {},
  defaultSchemaProps = { fluid: true, inverted: false },
  defaultContextProps = {},
}) {
  const formContextProps = formContext.semantic;
  let schemaProps = uiSchema['ui:options'] && uiSchema['ui:options'].semantic;
  let optionProps = options.semantic;
  // formContext props should overide other props
  return Object.assign(
    {},
    { ...(defaultSchemaProps && defaultSchemaProps) },
    { ...(defaultContextProps && defaultContextProps) },
    schemaProps,
    optionProps,
    formContextProps
  );
}

export function MaybeWrap({ wrap, component: Component = 'div', ...props }) {
  return wrap ? <Component {...props} /> : props.children;
}
