import axios from 'axios';

import {
  NOTES_FETCHED,
  USER_PROFILE_UPDATED_NOTES_FETCHED,
} from '../redux/types';

export const notesFetched = notes => ({
  type: NOTES_FETCHED,
  notes,
});

export const userProfileUpdatedNotesFetched = notes => ({
  type: USER_PROFILE_UPDATED_NOTES_FETCHED,
  notes,
});

export const fetchNotes = request => dispatch =>
  axios
    .post(`/notes`, request)
    .then(response => dispatch(notesFetched(response.data)));

export const fetchProfileUpdatedDateNotes = request => dispatch =>
  axios
    .post(`/notes`, request)
    .then(response => dispatch(userProfileUpdatedNotesFetched(response.data)));

export const addNote = request => dispatch =>
  axios.post(`/notes/create`, request).then(response => response);
