import {
  TASK_DETAIL_FETCHED,
  TASK_REPORT_FINDINGS_DETAIL_FETCHED,
} from '../redux/types';

export default function taskDetail(state = {}, action) {
  switch (action.type) {
    case TASK_DETAIL_FETCHED:
      return action.taskDetail;

    case TASK_REPORT_FINDINGS_DETAIL_FETCHED:
      return { ...state, reportFindings: action.taskDetail };

    default:
      return state;
  }
}
