import { useSelector } from 'react-redux';

export default function HelpBreadcrumb(routes, params) {
  const { categories, post } = useSelector(state => state.help);

  let breadcrumb = [
    { key: 'home', content: 'Home', href: '/' },
    { key: 'help', content: 'Help', href: '/help' },
  ];
  const categoryTitle =
    categories && categories.find(e => e.slug === params.slug)
      ? categories.find(e => e.slug === params.slug).title
      : params.slug;
  const postTitle = post && post._id === params.id ? post.title : params.id;

  if (params.id && routes[routes.length - 1].getDisplayName) {
    breadcrumb.push({
      key: params.slug,
      content: categoryTitle,
      href: `/help/${params.slug}`,
    });
    breadcrumb.push({
      key: params.id,
      content: postTitle,
      href: `/help/${params.slug}/${params.id}`,
    });
    breadcrumb.push({
      key: routes[routes.length - 1].getDisplayName(),
      content: routes[routes.length - 1].getDisplayName(),
      active: true,
    });
  } else if (params.id) {
    breadcrumb.push({
      key: params.slug,
      content: categoryTitle,
      href: `/help/${params.slug}`,
    });
    breadcrumb.push({
      key: params.id,
      content: postTitle,
      active: true,
    });
  } else {
    if (params.slug) {
      breadcrumb.push({
        key: params.slug,
        content: categoryTitle,
        active: true,
      });
    } else {
      breadcrumb.push({ key: 'create', content: 'Create', active: true });
    }
  }
  return breadcrumb;
}
