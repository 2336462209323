import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

// Import Actions
import { fetchPassFailReport } from '../../actions/submissions';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsTable from '../../utils/AmsTable';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

class CLASSPassFailReport extends Component {
  state = {
    passFailReport: {},
  };

  componentDidMount() {
    let input = {
      reviewId: this.context.router.params.reviewId, //"1805555C"
    };
    this.props.fetchPassFailReport(input).then(data => {
      this.setState({
        passFailReport: {
          ...data.passFailReport,
        },
      });
    });
  }

  isExpandableRow = row => {
    return true;
  };

  expandComponent = row => {
    const parentData = row;

    const observationCycle = (cell, row) => {
      if (cell === 1) return 'One';
      else return 'Two';
    };

    const userType = (cell, row) => {
      if (cell === 'R') return `${parentData.reviewer} (${cell})`;
      else if (cell === 'DC') return `${parentData.dualCoder} (${cell})`;
    };

    const submissionTime = (cell, row) => {
      return AmsDateFormatters.formatDateTime(cell);
    };

    const data = row.data.map((d, index) => {
      return {
        ...d,
        id: index,
      };
    });

    const centerClassName = (cell, row) => {
      return `${row.className} - ${row.classroomName}`;
    };

    return (
      <BootstrapTable
        data={data}
        // tableContainerClass='react-bs-table react-bs-container-body'
        // headerContainerClass='table-header'
      >
        <TableHeaderColumn
          row="0"
          dataField="id"
          isKey={true}
          autoValue={true}
          hidden={true}
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          row="0"
          width="10%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="className"
          rowSpan="3"
          dataFormat={centerClassName}
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
        >
          Center Name-Classroom Name
        </TableHeaderColumn>
        <TableHeaderColumn
          row="0"
          width="10%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="observationCycle"
          dataFormat={observationCycle}
          rowSpan="3"
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
        >
          Observation Cycle
        </TableHeaderColumn>
        <TableHeaderColumn
          row="0"
          width="10%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="role"
          dataFormat={userType}
          rowSpan="3"
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
        >
          User (Type)
        </TableHeaderColumn>
        <TableHeaderColumn
          row="0"
          width="10%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="cycleDate"
          dataFormat={submissionTime}
          rowSpan="3"
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
        >
          Submission Time
        </TableHeaderColumn>
        <TableHeaderColumn
          row="0"
          width="10%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          colSpan="10"
          headerAlign="center"
        >
          Scores and Percentage
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          PC
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          NC
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          {this.state.passFailReport.reviewType == 'PR-CLASS' &&
          this.context.router.params.fiscalYear != 2022
            ? 'ES'
            : 'TS'}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          RSP
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          BM
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          PD
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          ILF
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          CD
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          QF
        </TableHeaderColumn>
        <TableHeaderColumn
          row="1"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
        >
          LM
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="positiveClimate"
        >
          {parentData.PC}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="negativeClimate"
        >
          {parentData.NC}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="teacherSensitivity"
        >
          {parentData.TS}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="studentPerspectiveRegard"
        >
          {parentData.RSP}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="behaviorManagement"
        >
          {parentData.BM}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="productivity"
        >
          {parentData.PD}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="instructionalLearningFormats"
        >
          {parentData.ILF}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="conceptDevelopment"
        >
          {parentData.CD}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="feedbackQuality"
        >
          {parentData.QF}
        </TableHeaderColumn>
        <TableHeaderColumn
          row="2"
          width="5%"
          columnClassName="td-column-pass-fail-column"
          className="td-column-pass-fail-header"
          dataField="languageModeling"
        >
          {parentData.LM}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  preparePassFailReport = () => {
    const { passFailReport } = this.props;

    const data = passFailReport.reportData
      ? passFailReport.reportData.map((d, index) => {
          return {
            ...d,
            id: index,
          };
        })
      : [];

    const columRenderer = ({ expanded, rowKey, expandable }) => {
      let content = '';

      content = expanded ? '-' : '+';

      return <div> {content} </div>;
    };

    const expandRow = {
      renderer: this.expandComponent.bind(this),
      showExpandColumn: true,
      expandColumnRenderer: columRenderer,
    };

    const columns = [
      {
        dataField: 'id',
        hidden: true,
        text: 'id',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'reviewer',
        text: 'Reviewer',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'dualCoder',
        text: 'Dual Coder',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'results',
        text: 'Result',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'match',
        text: 'Match',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'Cnt',
        text: 'Total Observation Cycles',
        headerStyle: {
          width: '20%',
        },
      },
    ];

    return (
      <AmsTable
        data={data || []}
        expandRow={expandRow}
        remote={false}
        total={data ? data.length : 0}
        columns={columns}
        keyField="id"
        pagination={data ? data.length > 10 : false}
      ></AmsTable>
    );
  };

  render() {
    const { reviewId, reviewType, GrantInfo } = this.state.passFailReport;
    const grantee = GrantInfo ? GrantInfo[0] : {};
    return (
      <div>
        <SlidingContainer
          // calendar
          title={
            'Dual Coding Pass Fail Summary Report for Review ID ' +
            this.context.router.params.reviewId
          }
        >
          {/* {assignments.assignmentChangeFlag &&
            <Message
              negative
              icon='warning'
              header='Notice'
              content={`The New ${assignments.sampleLabel} Class Sampling report has been generated on ${moment(assignments.sampleGenrationDate).format("MMM Do YYYY")}. All the earlier assignments made if any based on older version of samples will be replaced.`}
            />
          } */}
          <div className="well survey-header-info">
            {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
            {reviewType} ( {reviewId} )
          </div>
          {this.preparePassFailReport()}
        </SlidingContainer>
      </div>
    );
  }
}

CLASSPassFailReport.propTypes = {};

CLASSPassFailReport.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    selectedReview: state.review.selectedReview,
    passFailReport: state.submissions.passFailReport,
  };
}

export default connect(mapStateToProps, { fetchPassFailReport })(
  CLASSPassFailReport
);
