import React from 'react';
import { Button } from 'semantic-ui-react';
import Form from 'react-jsonschema-form';

import AttachmentField from '../../../Shared/FormHelpers/AttachmentField';
import ArrayFieldTemplate from '../../../Shared/FormHelpers/ArrayFieldTemplate';

const fileUploadFormSchema = {
  type: 'object',
  required: ['evidenceFiles'],
  properties: {
    evidenceFiles: {
      type: 'array',
      title: 'Attach Supporting Documents',
      items: {
        type: 'string',
      },
    },
  },
};

const fileUploadUISchema = {
  evidenceFiles: {
    'ui:options': {
      orderable: false,
      removable: true,
    },
    items: {
      'ui:field': 'attachmentField',
    },
  },
};

const FA1FRFindingsOutsideProtocolAttachmentField = props => {
  // Pass attachment change to form schema implemenation to track changes
  const handleAdditionalCitationChange = (
    additionalCitation,
    index,
    { formData }
  ) => {
    props.onChange(additionalCitation, index, { formData });
  };

  const validate = (data, errors) => {
    return errors;
  };

  const fields = () => {
    return {
      attachmentField: AttachmentField,
    };
  };

  const log = type => console.log.bind(console, type);

  // Render the custom built form for adding additional citations
  const renderAdditionalCitationForm = () => {
    const { index, additionalCitation, formName } = props;

    return (
      <Form
        id={`${formName}-${index}`}
        ArrayFieldTemplate={ArrayFieldTemplate}
        schema={fileUploadFormSchema}
        uiSchema={fileUploadUISchema}
        onChange={e =>
          handleAdditionalCitationChange(additionalCitation, index, e)
        }
        onError={log('errors')}
        validate={validate}
        liveValidate={false}
        showErrorList={false}
        formData={{
          evidenceFiles: additionalCitation && additionalCitation.evidenceFiles,
        }}
        noHtml5Validate={true}
        fields={fields()}
      >
        <div hidden>
          <Button
            size="large"
            type="submit"
            content={'Submit'}
            hidden
            disabled
            onClick={e => {
              e.preventDefault();
            }}
          />
        </div>
      </Form>
    );
  };

  const renderField = () => {
    return renderAdditionalCitationForm();
  };

  return <>{renderField()}</>;
};

export default FA1FRFindingsOutsideProtocolAttachmentField;
