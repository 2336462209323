import { find, isEmpty, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import {
  citationsFilterCleared,
  filterCitations,
} from '../actions/reviewActions';
import AmsFormLabel from '../utils/AmsFormLabel';
const AmsCitationDropdown = ({
  value,
  required,
  multiple,
  selection,
  search,
  onValueSelected,
  onError,
}) => {
  const [citationList, setCitationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { citations } = useSelector(state => state.review.citations);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(citationsFilterCleared());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!citations && isEmpty(citations)) {
      return;
    }
    const newList = citations.map((citation, index) => ({
      key: index,
      value: citation.standardNumber || '',
      text: citation.standardNumber || 'N/A',
    }));

    let oldCitations = citationList;
    oldCitations = uniqBy([...(oldCitations || []), ...newList], 'value');

    setCitationList(oldCitations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citations]);

  useEffect(() => {
    if (value && value.length > 0)
      value.forEach(citation => {
        if (!find(citationList, option => option.value === citation))
          filterList(citation);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = (e, { value }) => {
    onValueSelected(value);
  };

  const onSearchChange = (e, data) => {
    if (!data.searchQuery || data.searchQuery.length < 3) {
      return;
    }
    filterList(data.searchQuery);
  };

  const filterList = async filterQuery => {
    try {
      setLoading(true);
      await dispatch(filterCitations(filterQuery));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError(error);
    }
  };

  return (
    <>
      <AmsFormLabel
        name="Citations"
        helpText={true}
        fieldLabel="filterCitations"
      >
        <Dropdown
          required={!!required}
          fluid
          multiple={multiple}
          search={search}
          selection={selection}
          placeholder={'Select Citation ID'}
          value={!value ? (multiple ? [] : '') : value}
          onSearchChange={onSearchChange}
          options={citationList}
          loading={loading}
          onChange={onChange}
          searchInput={{
            id: 'filterCitations',
            title: 'Select Citation ID',
          }}
          aria-labelledby="filterCitations"
          selectOnBlur={false}
          openOnFocus={false}
        />
      </AmsFormLabel>
    </>
  );
};

export default AmsCitationDropdown;
