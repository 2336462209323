import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';

import {
  Container,
  Grid,
  Header,
  Icon,
  List,
  Segment,
} from 'semantic-ui-react';

// Style
import './assets/style.css';

// Image

class Footer extends Component {
  addContact() {
    return (
      <div className="col-sm-6">
        <h2>
          Aligned Monitoring System <sup>2.0</sup>
        </h2>
        <span>Support hours of operations </span> <br />
        <span>
          Monday - Thursday, 9:00am - 8:00pm ET | Friday, 9:00am - 5:00pm ET
        </span>
        <br />
        <span>
          <a href="mailto:support@ams20.com">
            support@ams20.com
          </a>{' '}
          | <a href="tel:800-518-1932">800-518-1932 (option 4)</a>
        </span>
        <br />
      </div>
    );
  }

  addHelpDesk() {
    return (
      <div className="col-sm-6">
        <h2>Help Desk</h2>
        <a
          alt="Launch Remedy"
          href="/external-link/?url=https://login.salesforce.com/?ec=302&startURL=%2Fhome%2Fhome.jsp"
          className="btn btn-default btn-helpDesk"
          rel="noopener noreferrer"
        >
          Launch Remedy
        </a>
      </div>
    );
  }

  render() {
    return (
      <Segment
        id="footer"
        role="contentinfo"
        inverted
        vertical
        style={{
          padding: '3em 0em 1.5em',
          marginTop: '5em',
          // position: 'absolute',
          // left: '0',
          // bottom: '0',
          // height: '100px',
          // width: '100%',
          // overflow: 'hidden',

          // left: '0',
          position: 'absolute',
          bottom: '0',
          width: '100%',
          height: '150px',
        }}
      >
        <Container>
          <Grid inverted stackable columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Header inverted as="h2" content="Help Desk" />
                <Link
                  href="/external-link/?url=https://login.salesforce.com/?ec=302&startURL=%2Fhome%2Fhome.jsp"
                  rel="noopener noreferrer"
                  aria-label={`Launch remedy external link`}
                  style={{ color: 'white' }}
                >
                  Launch Remedy <span className="sr-only">external icon</span>
                  <Icon name="external" />
                </Link>
              </Grid.Column>

              <Grid.Column textAlign="center">
                <Header inverted as="h2" content="Support Hours" />
                <List inverted>
                  <List.Item>Monday - Thursday, 9:00am - 8:00pm ET</List.Item>
                  <List.Item>Friday, 9:00am - 5:00pm ET</List.Item>
                </List>
              </Grid.Column>

              <Grid.Column textAlign="right">
                <Header inverted as="h2" content="Contact Information" />
                <List inverted>
                  <List.Item>
                    <Link href="mailto:support@ams20.com">
                      support@ams20.com
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link href="tel:800-518-1932">
                      1-800-518-1932 (option 4)
                    </Link>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {/* <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Divider inverted section />
                <Image centered src={AMSLogoSquare} width={32} alt="Aligned Monitoring System 2.0 Home" />
                <List horizontal inverted bulleted size="small">
                  <List.Item>
                    Aligned Monitoring System <sup>2.0</sup>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid> */}
        </Container>
      </Segment>
    );
  }
}

Footer.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default Footer;
