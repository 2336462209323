import React from 'react';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Set to moment timezone.
const formatDate = date => AmsDateFormatters.getMoment(date).fromNow();

export const formatUserNotes = list => {
  const renderNote = note =>
    note &&
    note.split('\n').map((item, i) => {
      return <p key={i}>{item}</p>;
    });

  return list.map((e, index) => {
    return {
      header: e.submittedBy.fullName,
      content: renderNote(e.note),
      footer: formatDate(e.updateAt),
      filter: e.tags,
    };
  });
};

export const generateTagFilter = options => {
  const list = options.map(item => ({
    key: item.key,
    text: item.text,
    value: item.value,
  }));
  return list;
};
