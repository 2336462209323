/* eslint-disable no-useless-escape */
import React, { Component } from 'react';

import { connect } from 'react-redux';

import ContentTools from './../Shared/ContentTools/ContentTools';

import { applyTemplateFindings } from './ReportPreview.jsx';

class ReportItem extends Component {
  constructor() {
    super();
    this.editor = null;
    this.currentData = null;

    this.onEditorInit = this.onEditorInit.bind(this);
    this.getEditor = this.getEditor.bind(this);
    this.getCurrentData = this.getCurrentData.bind(this);
    this.getStyledContent = this.getStyledContent.bind(this);
    // this.componentLoadTemplate = this.componentLoadTemplate.bind(this);
  }

  getEditor() {
    return this.editor || null;
  }

  getStyledContent() {
    let _self = this;
    return fetch('/report-preview/report-template.css')
      .then(response => response.text())
      .then(styleContent => {
        _self.cacheResolvedUrl = _self.cacheResolvedUrl || {};
        let params = {
          styleContent: styleContent,
          content: _self.getEditor().getContent(),
        };
        let resolveUrl = url => {
          if (!(url in _self.cacheResolvedUrl)) {
            _self.cacheResolvedUrl[url] = fetch(url).then(response =>
              response.arrayBuffer()
            );
          }
          return _self.cacheResolvedUrl[url];
        };

        return Promise.all(
          (
            params.styleContent.match(
              /url\((\"|)\/report\-preview[^\"\']+\.(png|jpg|jpeg|gif)\1\)/gi
            ) || []
          )
            .map(str => {
              return {
                str: str,
                paramName: 'styleContent',
                // eslint-disable-next-line no-template-curly-in-string
                str2: 'url("data:image/png;base64,${data}")',
                url: str.replace(
                  /url\((\"|)(\/report\-preview[^\"\']+\.)(png|jpg|jpeg|gif)\1\)/i,
                  '$2$3'
                ),
              };
            })
            .concat(
              // encode images to base64 in CSS Style
              // encode images to base64 in HTML Image Elements
              (
                params.content.match(
                  /(\<img[^\>]*[\s\n])(src\s*=\s*[\"\'])(\/report\-preview[^\"\']+)([\"\'][^\>]*\>)/g
                ) || []
              ).map(str => {
                return {
                  str: str,
                  paramName: 'content',
                  str2: str.replace(
                    /(\<img[^\>]*[\s\n])(src\s*=\s*[\"\'])(\/report\-preview[^\"\']+)([\"\'][^\>]*\>)/,
                    // eslint-disable-next-line no-template-curly-in-string
                    '$1$2data:image/png;base64,${data}$4'
                  ),
                  url: str.replace(
                    /(\<img[^\>]*[\s\n])(src\s*=\s*[\"\'])(\/report\-preview[^\"\']+)([\"\'][^\>]*\>)/,
                    '$3'
                  ),
                };
              }),
              // encode images to base64 in HTML Image Elements
              (
                params.content.match(
                  /(\<img[^\>]*[\s\n])(src\s*=\s*[\"\'])(alert\.png|checked\.png|deny\.png)([\"\'][^\>]*\>)/g
                ) || []
              ).map(str => {
                return {
                  str: str,
                  paramName: 'content',
                  str2: str.replace(
                    /(\<img[^\>]*[\s\n])(src\s*=\s*[\"\'])(alert\.png|checked\.png|deny\.png)([\"\'][^\>]*\>)/,
                    // eslint-disable-next-line no-template-curly-in-string
                    '$1$2data:image/png;base64,${data}$4'
                  ),
                  url: str.replace(
                    /(\<img[^\>]*[\s\n])(src\s*=\s*[\"\'])(alert\.png|checked\.png|deny\.png)([\"\'][^\>]*\>)/,
                    '/report-preview/$3'
                  ),
                };
              })
            )
            .map(conf => {
              return new Promise((resolve, reject) => {
                resolveUrl(conf.url).then(buffer => {
                  params[conf.paramName] = params[conf.paramName].replace(
                    conf.str,
                    conf.str2.replace(
                      // eslint-disable-next-line no-template-curly-in-string
                      '${data}',
                      window.btoa(
                        [].slice
                          .call(new Uint8Array(buffer))
                          .map(b => String.fromCharCode(b))
                          .join('')
                      )
                    )
                  );
                  return resolve(true);
                }, reject);
              });
            })
        ).then(response => {
          return (
            '<div class="report-item--content">' + params.content + '</div>'
          );
        }, console.error);
      });
  }

  getCurrentData() {
    return this.currentData || null;
  }

  onEditorInit(editor) {
    this.editor = editor;
  }

  retrieveEmails(ev) {
    let emails = ev.target.value;

    let pattern = /[a-z0-9][a-z0-9\-\.\+]*[a-z0-9]@[a-z0-9][a-z0-9\-\.]*[a-z0-9]/i;
    let m,
      validEmails = [];
    do {
      m = emails.match(pattern);
      if (m) {
        m[0] = m[0].toLowerCase();
        if (validEmails.indexOf(m[0]) === -1) validEmails.push(m[0]);
        emails = emails.replace(m[0], ' ');
      }
    } while (m);

    ev.target.parentElement.parentElement.querySelector(
      '.report-item--valid-emails'
    ).innerHTML = validEmails
      .map(function(email) {
        return '<span>' + email + '</span>';
      })
      .join(' ');

    return validEmails;
  }

  loadContent(data) {
    this.currentData = data;
    data.state = data.state || 'draft';
    let reportNode = window.document.querySelector('.report-item-editor');
    let node = reportNode.querySelector('.report-item--content');

    if (this.editor) {
      let content = data.content;
      // remove style
      content = content
        .replace(/[\=\-]{15,}/g, '')
        .replace(
          /(\<img[^\>]*[\s\n])(src\s*=\s*[\"\'])(alert\.png|checked\.png|deny\.png)([\"\'][^\>]*\>)/g,
          '$1$2/report-preview/$3$4'
        )
        .replace(/^[\n\s]*/, '')
        .replace(/[\n\s]*$/, '')
        .replace(/(\<[^\>]+)(\s\=\"\")/g, '$1 ')
        .replace(/(\<[^\>]+)(\s\=\"\")/g, '$1 ')
        .replace(/^[\n\s]*/, '')
        .replace(/[\n\s]*$/, '')
        .replace(
          /^\<div[^\>]+class\s*=\s*[\"\'][\"\'\>]*report\-item\-\-content[^\>]*?\>([\s\S]*)\<\/div[^\>]*\>$/,
          '$1'
        )
        .replace(/^[\n\s]*/, '')
        .replace(/[\n\s]*$/, '');

      if (
        content.match(
          /^\s*<div[^\>]+data-name[^\>]+\>\s*\<p[^\>]*\>\s*\<\/p[^\>]*\>\s*\<div[^\>]*\>/
        ) &&
        content.match(
          /\s*<\/div[^\>]*\>\s*\<p[^\>]*\>\s*\<\/p[^\>]*\>\s*\<\/div[^\>]*\>\s*$/
        )
      ) {
        content = content
          .replace(
            /^\s*<div[^\>]+data-name[^\>]+\>\s*\<p[^\>]*\>\s*\<\/p[^\>]*\>\s*\<div[^\>]*\>/,
            ''
          )
          .replace(
            /\s*<\/div[^\>]*\>\s*\<p[^\>]*\>\s*\<\/p[^\>]*\>\s*\<\/div[^\>]*\>\s*$/,
            ''
          );
        content = '<div data-name="content">' + content + '</div>';
      }

      if (this.props.findings) {
        if (Array.isArray(this.props.findings.measures)) {
          [1, 2, 3, 4, 5, 6]
            .map(v => {
              let response = {
                placeholders: {},
              };
              let measure =
                this.props.findings.measures.filter(measure => {
                  return measure.name === 'PDM' + v;
                })[0] || null;
              response.placeholders['PDM_' + v + '_LABEL'] = measure
                ? measure.name + ' ' + measure.qName
                : '';
              response.placeholders['PDM_' + v] = measure
                ? measure.metReq
                  ? 'Met requirements'
                  : 'Not met requirements'
                : '';
              return response;
            })
            .forEach(v => {
              let placeholderId;
              for (placeholderId in v.placeholders) {
                content = content
                  .split('{{' + placeholderId + '}}')
                  .join(v.placeholders[placeholderId]);
              }
            });
        }
      }
      if (this.props.draftFindings) {
        this.props.applyTemplateFindings(
          content,
          this.props.draftFindings,
          (content => {
            this.editor.setContent(content);
            // eslint-disable-next-line no-extra-bind
          }).bind(this)
        );
      } else {
        this.editor.setContent(content);
      }
    }

    let states = {
      initial: ['address-from', 'address-to', 'content'], // fallback
      draft: ['address-from', 'address-to', 'content'],
      Edit: ['content'],
      // "reviewed"  : ["address-from", "address-to", "content"],
      // "analyzed"  : ["signature"],
      finalized: [],
    };

    let emailNode = reportNode.querySelector('.report-item--emails textarea');
    emailNode.value = data.emails || '';

    this.retrieveEmails({
      target: emailNode,
    });

    // window.edt = this.editor;
    this.editor.dataSets().forEach(function(block) {
      if ((states[data.state || 'draft'] || []).indexOf(block.name()) !== -1) {
        block.readonly(false);
      } else {
        block.readonly(true);
      }
    });

    node.setAttribute('report-state', data.state);

    if (['initial', 'draft'].indexOf(data.state) !== -1) {
      // Add Editable field
      reportNode.querySelector('.report-item--reviewer-comment').innerHTML =
        '<textarea></textarea>';
      reportNode.querySelector(
        '.report-item--reviewer-comment textarea'
      ).value = data.reviewerComment || '';
    } else {
      // Add readonly field
      reportNode.querySelector('.report-item--reviewer-comment').innerHTML = '';
      reportNode
        .querySelector('.report-item--reviewer-comment')
        .appendChild(
          window.document.createTextNode(data.reviewerComment || ' ')
        );
    }

    this.props.onDataLoad(this);
  }

  componentDidMount() {
    if (typeof this.props.onInit === 'function') {
      this.props.onInit(this);
    }

    var _self = this;
    // && this.componentLoadTemplate() === "version"
    if (_self.props.template) {
      _self.loadContent({
        content: _self.props.template.content,
        reviewerComment: '',
      });
    } else if (_self.props.reportId && _self.props.reportVersionId) {
      if (_self.props.reportVersionData) {
        let version = _self.props.reportVersionData;
        _self.loadContent({
          content: version.reportContent,
          state: version.versionStatus,
        });
      }
    } else {
    }
  }

  showComparingVersion(version) {
    this.comparingVersion = version;
    let container = document.querySelector(
      '.reports-dashboard .report-item-comparing-version-container'
    );
    container.setAttribute('report-version-id', version.reportKey);

    container.querySelector('.report-item-comparing-version').innerHTML =
      version.reportContent;
  }

  closeComparingVersion() {
    let container = document.querySelector(
      '.reports-dashboard .report-item-comparing-version-container'
    );
    if (container.hasAttribute('report-version-id')) {
      container.removeAttribute('report-version-id');
    }
  }

  componentDidUpdate() {
    var _self = this;
    this.closeComparingVersion();

    if (_self.props.template && !this.props.forcedVersion) {
      _self.loadContent({
        content: _self.props.template.content,
        reviewerComment: '',
      });
    } else if (_self.props.reportId && _self.props.reportVersionId) {
      if (_self.props.reportVersionData) {
        let version = _self.props.reportVersionData;
        _self.loadContent({
          content: version.reportContent,
          state: version.versionStatus,
        });
      }
    } else {
    }
  }
  render() {
    return (
      <div>
        <table className="report-item-comparing-version-grid">
          <tbody>
            <tr>
              <td>
                <div className="report-item-editor active-inline-chats--comments-container">
                  <ContentTools
                    onInit={this.onEditorInit}
                    className="report-item--content active-inline-chats--comments-container-context"
                  />
                  <div
                    className="report-item--reviewer-comment"
                    style={{ display: 'none' }}
                  />
                  <div
                    className="report-item--emails"
                    style={{ display: 'none' }}
                  >
                    <textarea onChange={this.retrieveEmails} />
                  </div>
                  <div className="report-item--valid-emails" />
                  <input
                    type="hidden"
                    name="comments-popup-add-message"
                    value='
                    <div class="comments-popup--add-message">
                      <div class="comments-popup-overlay"></div>
                      <div class="comments-popup-box">
                        <b>Add your message:</b>
                        <textarea autocomplete="off"></textarea>
                        <button class="btn btn-lg btn-primary comments-popup--btn-cancel">Cancel</button>&nbsp;
                        <button class="btn btn-lg btn-primary comments-popup--btn-add">Add</button>
                      </div>
                    </div>
                  '
                  />
                </div>
              </td>
              <td className="report-item-comparing-version-container">
                <div className="btn-controls">
                  <button
                    className="btn btn-primary"
                    onClick={this.closeComparingVersion}
                  >
                    Close Comparing Version
                  </button>
                </div>
                <div className="report-item-comparing-version" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, { applyTemplateFindings })(ReportItem);
