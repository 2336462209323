import React, { useEffect, useState } from 'react';
import { fetchAmsLookup } from '../actions/lookupActions';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy, filter } from 'lodash';
import { Link } from 'react-router';
import { Menu, Icon } from 'semantic-ui-react';

// Import util.
import enforceRole from '../utils/EnforceRole';

// Import config.
import { acl } from '../config';

function MenuNavBar({ location, roles }) {
  const dispatch = useDispatch();
  const items = useSelector(state => state.lookups.amsLookups.AmsNavigation);
  const [state, setState] = useState({ activeItem: null });

  const activeItems = sortBy(
    filter(items, { parent: false, active: true }).map(e => {
      return { ...e.value };
    }),
    [
      function(o) {
        return o.weight;
      },
    ]
  );

  useEffect(() => {
    dispatch(fetchAmsLookup('AmsNavigation'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({
      activeItem:
        activeItems.find(
          e =>
            e.value === location.pathname ||
            (location.pathname.includes(e.value) && e.value !== '/')
        ) || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (location.pathname.indexOf('/help') === 0) {
      return;
    }
    if (
      location.pathname.includes('reviews') &&
      !location.pathname.includes('myregionalreviews')
    ) {
      if (
        state.activeItem &&
        state.activeItem.label &&
        state.activeItem.label !== 'Reports'
      )
        setState({
          activeItem: activeItems.find(e => e.label === 'Reports'),
        });
      return;
    }

    if (
      state.activeItem &&
      state.activeItem.value !== location.pathname &&
      (!location.pathname.includes(state.activeItem.value) ||
        state.activeItem.value === '/')
    )
      setState({
        activeItem:
          activeItems.find(
            e =>
              e.value === location.pathname ||
              (location.pathname.includes(e.value) && e.value !== '/')
          ) || null,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleItemClick = (e, { name }) =>
    setState({
      activeItem: activeItems.find(e => e.label === name),
      toPath: e.value,
    });

  const renderMainMenu = () => {
    return activeItems.map((e, key) => {
      if (!e.value) {
        return null;
      }
      const routeRoles =
        acl.routes[
          `${e.value
            .substring(1)
            .toLocaleLowerCase()
            .replace(/\s/g, '')}`
        ] || acl.routes[`${e.label.toLocaleLowerCase()}`];
      return enforceRole(
        <Menu.Item
          key={key}
          to={`${e.value}`}
          name={e.label}
          target={e.target}
          rel={e.target !== '' ? 'noopener noreferrer' : ''}
          onClick={handleItemClick}
          active={state.activeItem ? state.activeItem.label === e.label : false}
          as={Link}
        >
          {e.label}
        </Menu.Item>,
        routeRoles,
        roles
      );
    });
  };

  return (
    <>
      {renderMainMenu()}
      <Menu.Menu position="right">
        <Menu.Item
          color="green"
          icon="question circle"
          content="Help"
          as={Link}
          href="/help"
        />

        <Menu.Item
          as={Link}
          to="/calendar"
          name="calendar"
          onClick={() =>
            setState({
              activeItem: { name: 'calendar' },
              toPath: '/calendar',
            })
          }
          active={
            state.activeItem ? state.activeItem.label === 'calendar' : false
          }
        >
          <Icon name="calendar" />
          Calendar
        </Menu.Item>
      </Menu.Menu>
    </>
  );
}

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
  borderBottom: '2px solid rgb(229,229,229)',
};

const fixedMenuStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
};

const MemoMenuNavBar = React.memo(MenuNavBar);

export { menuStyle, fixedMenuStyle, MemoMenuNavBar };
