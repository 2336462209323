import React, { useState, useEffect } from 'react';
import FocusTrap from 'focus-trap-react/dist/focus-trap-react';
import { Modal } from 'semantic-ui-react';

const AmsModal = props => {
  const { open } = props;
  const [activeTrap, setActiveTrap] = useState(false);

  useEffect(() => {
    setActiveTrap(open);
  }, [open]);

  const unmountTrap = () => {
    setActiveTrap(false);
  };

  const trap = activeTrap ? (
    <FocusTrap
      focusTrapOptions={{
        onDeactivate: unmountTrap,
        escapeDeactivates: false,
      }}
    >
      <Modal role="dialog" {...props}>
        {props.children}
      </Modal>
    </FocusTrap>
  ) : (
    <Modal role="dialog" {...props}>
      {props.children}
    </Modal>
  );

  return trap;
};

export default AmsModal;
