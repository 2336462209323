import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Import actions.
import {
  fetchPresiteFormSchema,
  fetchPresiteSubmission,
  performanceMeasureSelected,
  fetchPresiteFormDetail,
  clearPresiteSubmission,
} from '../../../actions/presiteActions';
import PresiteForm from './PresiteForm';

class PresiteFormContainer extends Component {
  state = {
    selectedName: '',
    formProcessing: false,
    errors: {},
    formChangeRequested: true,
    dataHasChanged: false,
  };

  componentWillReceiveProps(nextProps) {
    const { selectedPerformanceMeasure } = nextProps;
    if (!_.isEmpty(selectedPerformanceMeasure)) {
      if (!this.state.dataHasChanged) {
        if (
          this.state.selectedName !==
            (selectedPerformanceMeasure && selectedPerformanceMeasure.name) ||
          this.state.selectedName !==
            (this.props.selectedPerformanceMeasure &&
              this.props.selectedPerformanceMeasure.name)
        ) {
          if (!_.isEmpty(selectedPerformanceMeasure.forms)) {
            this.setState({
              selectedName: selectedPerformanceMeasure
                ? selectedPerformanceMeasure.name
                : this.props.selectedPerformanceMeasure.name,
              formChangeRequested: true,
            });
            this.getData(selectedPerformanceMeasure.forms);
          }
        } else {
          this.setState({ formChangeRequested: false });
        }
      } else {
        if (this.state.selectedName !== selectedPerformanceMeasure.name) {
          if (!_.isEmpty(selectedPerformanceMeasure.forms)) {
            this.setState({
              formChangeRequested: true,
            });
          }
        } else {
          this.setState({ formChangeRequested: false });
        }
      }
    }
  }

  getData = forms => {
    const { amsFormId, amsSubmissionId } = forms[0];

    // Fetch form schema.
    if (amsFormId) {
      this.setState({ formProcessing: true });
      this.props
        .fetchPresiteFormSchema({ filters: { amsFormId } })
        .then(() => this.setState({ formProcessing: false }))
        .catch(error =>
          this.setState({
            formProcessing: false,
            errors: {
              ...this.state.errors,
              fetchPresiteFormSchema: error.message,
            },
          })
        );
    }

    // Fetch form submission
    if (amsSubmissionId) {
      this.setState({ formProcessing: true });
      this.props
        .fetchPresiteSubmission({
          filters: { amsSubmissionId },
        })
        .then(() => this.setState({ formProcessing: false }))
        .catch(error =>
          this.setState({
            formProcessing: false,
            errors: {
              ...this.state.errors,
              fetchPresiteSubmission: error.message,
            },
          })
        );
    } else {
      this.props.clearPresiteSubmission();
    }
  };

  dataHasChangedSwitch = value => {
    const { selectedPerformanceMeasure, presite } = this.props;
    const reviewId = presite.reviewId;
    this.setState(
      {
        dataHasChanged: value,
      },
      value => {
        if (!value) {
          if (!_.isEmpty(selectedPerformanceMeasure)) {
            if (
              this.state.selectedName !== selectedPerformanceMeasure.name ||
              this.state.selectedName !==
                this.props.selectedPerformanceMeasure.name
            ) {
              if (!_.isEmpty(selectedPerformanceMeasure.forms)) {
                this.setState(
                  {
                    selectedName: selectedPerformanceMeasure.name,
                    formChangeRequested: true,
                  },
                  () => {
                    this.props.fetchPresiteFormDetail({ reviewId }).then(() => {
                      // Select the first performance measure at first load.
                      this.props.performanceMeasureSelected(
                        selectedPerformanceMeasure
                      );
                    });
                    this.getData(selectedPerformanceMeasure.forms);
                  }
                );
              }
            } else {
              this.setState({ formChangeRequested: false });
            }
          }
        }
      }
    );
  };

  render() {
    return (
      <>
        <PresiteForm
          formProcessing={this.state.formProcessing}
          errors={this.state.errors}
          formChangeRequested={this.state.formChangeRequested}
          selectedName={this.state.selectedName}
          dataHasChangedSwitch={this.dataHasChangedSwitch}
        />
      </>
    );
  }
}

PresiteFormContainer.propTypes = {
  selectedPerformanceMeasure: PropTypes.object,
  fetchPresiteFormSchema: PropTypes.func.isRequired,
  fetchPresiteSubmission: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedPerformanceMeasure: state.forms.presite.selectedPerformanceMeasure,
  presite: state.forms.presite,
});

export default connect(mapStateToProps, {
  fetchPresiteFormSchema,
  fetchPresiteSubmission,
  performanceMeasureSelected,
  fetchPresiteFormDetail,
  clearPresiteSubmission,
})(PresiteFormContainer);
