import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, cloneDeep, remove } from 'lodash';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

// Import Actions
import { addFUSubmission } from '../../../actions/submissions';
import FollowUpCitationsForm from './FollowUpCitationsForm';
import AmsAlert from '../../../utils/AmsAlert';

const FollowUpFormContainer = ({ fetchData, formLoading }) => {
  const [state, setState] = useState({
    errors: [],
    surveyData: {},
    citationsData: [],
    followUpTaskDetailss: {},
    formData: {},
    showAlert: false,
    alertMessage: '',
    alertType: '',
    amsSubmissionId: '',
    editMode: false,
    formDisabled: false,
    errorType: '',
    dataHasChanged: false,
  });

  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [citationAddedOrRemoved, setCitationAddedOrRemoved] = useState('ADDED');
  const [removedCitationIndex, setRemovedCitationIndex] = useState('');
  const [selectedCitationDetails, setSelectedCitationDetails] = useState({});
  const dispatch = useDispatch();
  const amsTaskRef = useRef(null);
  const [formProcessing, setFormProcessing] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const { followUpTaskDetail, selectedSubmission } = useSelector(
    state => state.submissions
  );
  const [showMovingAlert, setShowMovingAlert] = useState(false);
  const [dataModified, setDataModified] = useState(false);
  const [
    previousSelectedCitationDetails,
    setPreviousSelectedCitationDetails,
  ] = useState({});

  useEffect(() => {
    setFormProcessing(formLoading);
    if (followUpTaskDetail && !isEmpty(followUpTaskDetail.citations)) {
      let citationsDataNewList = [];
      citationsDataNewList = createCitationList(citationsDataNewList);
      setState({
        ...state,
        citationsData: citationsDataNewList,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followUpTaskDetail]);

  const createCitationList = citationsList => {
    followUpTaskDetail &&
      // eslint-disable-next-line array-callback-return
      followUpTaskDetail.citations.map((citations, index) => {
        citationsList.push({
          ...citations,
        });
      });

    return citationsList;
  };

  useEffect(() => {
    if (
      selectedSubmission &&
      selectedSubmission.data &&
      !isEmpty(selectedSubmission.amsSubmissionId)
    ) {
      let formsData = state.citationsData;

      const foundIndex = formsData.findIndex(
        x => x.amsSubmissionId == selectedSubmission.amsSubmissionId
      );

      let updatedFormData = selectedSubmission.data.surveyData;
      updatedFormData['amsSubmissionId'] = selectedSubmission.amsSubmissionId;
      updatedFormData['amsFormId'] = selectedSubmission.amsFormId;
      updatedFormData['findingId'] =
        selectedSubmission.data.surveyData.findingId;
      updatedFormData['submissionStatus'] = selectedSubmission.surveyStatus;

      formsData[foundIndex] = updatedFormData;

      if (foundIndex >= 0) {
        setState({
          ...state,
          citationsData: formsData,
        });
      }
      disableForm();
      setFormProcessing(false);
    }
    setResponseErrors();
    setDataModified(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubmission]);

  const scrollToResults = () => scrollToRef(amsTaskRef);

  const scrollToRef = ref => {
    window.scrollTo(0, ref.current.offsetTop);
    ref.current.focus();
  };

  const buildFormData = (data, index, loadData) => {
    const { citationsData } = state;
    let formData = citationsData;

    if (
      'DRAFT' === loadData ||
      'SUBMIT' === loadData ||
      'REMOVED' === loadData
    ) {
      formData[index] = {
        ...selectedCitationDetails,
        [data.name]: data.value,
      };
      setDataModified(true);
    } else if ('CLEARCITATIONDATA' === loadData) {
      formData[index] =
        data && data.isPreliminaryFinding
          ? {
              ...formData[index],
              correctedCitationReason: '',
              granteeCorrectedCitationResponse: '',
              evidences: [],
            }
          : {
              ...formData[index],
              guideName: '',
              performanceMeasure: '',
              citation: '',
              correctedCitationReason: '',
              evidences: [],
            };
    } else {
      formData[index] = {
        ...formData[index],
        [data.name]: data.value,
      };
      setDataModified(true);
    }
    setState({
      ...state,
      citationsData: formData,
      surveyData: formData[index],
    });
  };

  const addAdditionalCitation = () => {
    setShowConfirmationAlert(true);
    setCitationAddedOrRemoved('ADDED');
    setResponseErrors();
  };

  const handleDataChanged = value => {
    setState({ ...state, dataHasChanged: value });
  };

  const handleSeletedCitationDetails = citationDetails => {
    setSelectedCitationDetails(citationDetails);
  };

  const handlePreviousSeletedCitationDetails = prevCitationDetails => {
    setPreviousSelectedCitationDetails(prevCitationDetails);
  };

  const removeAdditionalCitation = index => {
    setShowConfirmationAlert(true);
    setCitationAddedOrRemoved('REMOVED');
    setRemovedCitationIndex(index);
  };

  const handleShowMovingAlert = () => {
    setShowMovingAlert(true);
  };

  const disableForm = () => {
    if (selectedSubmission && !selectedSubmission.isActive) {
      setState({
        ...state,
        formDisabled: true,
        editMode: false,
      });
      return;
    }

    if (selectedSubmission.amsSubmissionId) {
      if (
        selectedSubmission &&
        selectedSubmission.surveyStatus &&
        selectedSubmission.surveyStatus.toLowerCase() === 'draft'
      ) {
        setState({
          ...state,
          formDisabled: false,
        });
        return;
      } else {
        setState({
          ...state,
          formDisabled: true,
          editMode: false,
        });
        return;
      }
    }

    setState({
      ...state,
      formDisabled: false,
      editMode: false,
    });
  };

  const structureFileData = tempData => {
    tempData &&
      // eslint-disable-next-line array-callback-return
      Object.keys(tempData).map(key => {
        if (key.toLowerCase().includes('evidence')) {
          const all = [];
          if (Array.isArray(tempData[key])) {
            if (!isEmpty(tempData[key])) {
              tempData[key].forEach(files => {
                if (
                  typeof files !== 'undefined' &&
                  files !== null &&
                  Array.isArray(files)
                )
                  return files.forEach(file => all.push(file));
                else if (typeof files !== 'undefined' && files !== null)
                  all.push(files);
              });
              tempData[key] = all;
            }
            return tempData;
          }
        } else {
          if (
            typeof tempData[key] === 'object' &&
            !Array.isArray(tempData[key])
          ) {
            return structureFileData(tempData[key]);
          } else if (Array.isArray(tempData[key])) {
            tempData[key].forEach(file => {
              return structureFileData(file);
            });
          }
        }
      });
    return tempData;
  };

  const handleSaveDraft = () => {
    actionTriggered('DRAFT', selectedCitationDetails);

    scrollToResults();
  };

  const handleCancel = () => {
    fetchData(selectedCitationDetails.amsSubmissionId);
    setState({ ...state, formDisabled: true, editMode: false });
  };

  const handleEdit = () => {
    setState({ ...state, formDisabled: false, editMode: true });
  };

  const getPayload = (action, selectedCitationDetailsToSend) => {
    const { surveyStatus, data } = selectedSubmission;

    let structuredData = {};
    structuredData =
      selectedCitationDetailsToSend &&
      !isEmpty(selectedCitationDetailsToSend) &&
      structureFileData(selectedCitationDetailsToSend);

    return {
      isSubmitButtonClicked: action === 'DRAFT' ? false : true,
      reviewId: followUpTaskDetail.reviewId,
      surveyName: 'Follow Up Review',
      surveyType: 'Follow-up',
      amsSubmissionId:
        selectedCitationDetailsToSend &&
        !isEmpty(selectedCitationDetailsToSend.amsSubmissionId)
          ? selectedCitationDetailsToSend.amsSubmissionId
          : '',
      amsFormId:
        selectedCitationDetailsToSend &&
        !isEmpty(selectedCitationDetailsToSend.amsFormId)
          ? selectedCitationDetailsToSend.amsFormId
          : '',
      surveyData:
        action === 'REMOVED'
          ? {
              ...structuredData,
              isActive: false,
            }
          : structuredData,
      currentSubmissionStatus:
        selectedCitationDetails &&
        data &&
        !isEmpty(selectedCitationDetails.amsSubmissionId) &&
        surveyStatus
          ? surveyStatus
          : 'Pending',
    };
  };

  const actionTriggered = (action, selectedCitationDetailsToSend) => {
    const payload = getPayload(action, selectedCitationDetailsToSend);

    setFormProcessing(true);
    dispatch(addFUSubmission({ data: payload }))
      .then(res => {
        if (res) {
          fetchData(res.amsSubmissionId);
          buildFormData(
            { name: 'amsSubmissionId', value: res.amsSubmissionId },
            selectedCitationDetailsToSend.key,
            action
          );
        }
        setFormProcessing(false);
        setState({
          ...state,
          showAlert: true,
          alertType: 'success',
          alertMessage:
            action === 'SUBMIT'
              ? 'Data saved to Report'
              : action === 'DRAFT'
              ? 'Draft saved successfully'
              : action === 'REMOVED'
              ? 'Finding Dropped'
              : '',
          editMode: false,
          formDisabled: true,
        });
      })
      .catch(err => {
        setFormProcessing(false);
        scrollToResults();
        setState({
          ...state,
          showAlert: true,
          alertType: 'error',
          alertMessage:
            action === 'SUBMIT'
              ? 'Data submission was unsuccessful'
              : action === 'DRAFT'
              ? 'Draft save was unsuccessful'
              : action === 'REMOVED'
              ? 'Remove citation was unsuccessful'
              : '',
        });
      });
  };

  const validate = surveyData => {
    setResponseErrors();
    let errs = {};
    const duplicateCitationsCheck = [];

    if (!surveyData) errs.error = 'Data is required';

    const additionalCitationError = {};

    if (surveyData.isPreliminaryFinding) {
      if (
        (surveyData.granteeCorrectedCitationResponse !== undefined &&
          (surveyData.granteeCorrectedCitationResponse === '' ||
            surveyData.granteeCorrectedCitationResponse === null)) ||
        surveyData.granteeCorrectedCitationResponse === undefined
      ) {
        additionalCitationError.granteeCorrectedCitationResponse =
          'Grantee corrected citation option is a required field';
      }
    } else {
      if (!surveyData.guideName)
        additionalCitationError.guideName = !followUpTaskDetail?.isFollowupLegacy
          ? 'Guide name is required.'
          : 'Content area is required.';
      if (!surveyData.performanceMeasure)
        additionalCitationError.performanceMeasure = !followUpTaskDetail?.isFollowupLegacy
          ? 'Performance measure is required.'
          : 'Performance area is required.';
      if (!surveyData.citation)
        additionalCitationError.citation = 'Citation is required.';
    }

    if (
      (surveyData.correctedCitationReason !== undefined &&
        (surveyData.correctedCitationReason === '' ||
          surveyData.correctedCitationReason === null)) ||
      surveyData.correctedCitationReason === undefined
    ) {
      additionalCitationError.correctedCitationReason =
        'Citation explanation is a required field';
    }

    if (!surveyData.evidences || surveyData.evidences.length === 0)
      additionalCitationError.evidences = 'Supporting documents is required.';
    else {
      let anyDefined = false;
      for (let i = 0; i < surveyData.evidences.length; i++) {
        if (surveyData.evidences[i] !== undefined) {
          anyDefined = true;
        }
      }
      if (!anyDefined) {
        additionalCitationError.evidences = 'Supporting documents is required.';
      }
    }

    state.citationsData &&
      // eslint-disable-next-line array-callback-return
      state.citationsData.map(citationDetails => {
        if (
          duplicateCitationsCheck.length > 0 &&
          duplicateCitationsCheck.includes(citationDetails.citation) &&
          citationDetails.isActive
        ) {
          if ('RAN' === followUpTaskDetail.parentReviewtype) {
            if (citationDetails.citation !== '1302.102(d)(1)(ii)') {
              additionalCitationError.citation = `Duplicate citation (${citationDetails.citation}) is not allowed.`;
            }
          } else {
            additionalCitationError.citation = `Duplicate citation (${citationDetails.citation}) is not allowed.`;
          }
        }
        if (citationDetails.isActive) {
          if ('RAN' === followUpTaskDetail.parentReviewtype) {
            if (citationDetails.citation !== '1302.102(d)(1)(ii)') {
              duplicateCitationsCheck.push(citationDetails.citation);
            }
          } else {
            duplicateCitationsCheck.push(citationDetails.citation);
          }
        }
      });

    if (!isEmpty(additionalCitationError)) {
      errs.additionalCitationError = additionalCitationError;
    }

    errs = {
      ...errs,
    };

    setResponseErrors(errs);
    return errs;
  };

  const handleSubmit = () => {
    setResponseErrors();

    const { additionalCitationError } = validate(
      getPayload('SUBMIT', selectedCitationDetails).surveyData
    );

    let validationError = false;

    if (!isEmpty(additionalCitationError)) {
      validationError = true;
    }

    if (validationError) {
      // Go to the top of the summary table
      scrollToResults();

      return null;
    }

    actionTriggered('SUBMIT', selectedCitationDetails);

    setResponseErrors();
    scrollToResults();
  };

  const showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = state;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          setState({
            ...state,
            showAlert: false,
            alertMessage: '',
            alertType: '',
          });
        }}
      />
    );
  };

  const showConfirmationAlertModal = () => {
    return (
      <AmsAlert
        show={showConfirmationAlert}
        title={
          citationAddedOrRemoved === 'REMOVED'
            ? 'Are you sure you want to drop new finding?'
            : 'Are you sure you want to add new finding?'
        }
        type="warning"
        showConfirm
        showCancelButton
        confirmCancelText="Discard"
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={''}
        onConfirm={() => {
          const { citationsData } = state;

          let newAdditionalCitations = [];

          if (citationAddedOrRemoved === 'ADDED') {
            setSelectedCitationDetails();
            newAdditionalCitations = citationsData
              ? cloneDeep(citationsData)
              : [];

            newAdditionalCitations.push({
              guideName: null,
              performanceMeasure: null,
              citation: null,
              isPreliminaryFinding: false,
              isActive: true,
            });
          } else if (citationAddedOrRemoved === 'REMOVED') {
            newAdditionalCitations = citationsData
              ? cloneDeep(citationsData)
              : [];

            if (
              selectedCitationDetails &&
              !isEmpty(selectedCitationDetails.amsSubmissionId)
            ) {
              actionTriggered('REMOVED', selectedCitationDetails);
            } else {
              remove(newAdditionalCitations, (citation, cIndex) => {
                // eslint-disable-next-line eqeqeq
                return removedCitationIndex == cIndex;
              });
            }
            setResponseErrors();
          }

          setState({
            ...state,
            citationsData: newAdditionalCitations,
            dataHasChanged: true,
          });
          setShowConfirmationAlert(false);
        }}
        onCancel={() => {
          setShowConfirmationAlert(false);
        }}
      />
    );
  };

  const renderMovingConfirmation = () => {
    return (
      <AmsAlert
        show={showMovingAlert}
        title="Are you sure you want to move away from this citation page"
        text={`You selected another page, are you sure you want to leave the current page?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={
          previousSelectedCitationDetails &&
          previousSelectedCitationDetails.submissionStatus &&
          'submitted' ===
            previousSelectedCitationDetails.submissionStatus.toLowerCase()
            ? 'Submit and continue'
            : 'Save draft and continue'
        }
        cancelButtonText={'Continue without saving'}
        showConfirm
        showCancelButton
        showThirdButton
        thirdButtonText="Cancel"
        onThirdButtonAction={e => {
          e.preventDefault();
          setShowMovingAlert(false);
          setSelectedCitationDetails(previousSelectedCitationDetails);
        }}
        onCancel={e => {
          e.preventDefault();
          setShowMovingAlert(false);
          setDataModified(false);
          fetchData(selectedCitationDetails.amsSubmissionId);
        }}
        onConfirm={e => {
          e.preventDefault();
          setShowMovingAlert(false);
          previousSelectedCitationDetails &&
          previousSelectedCitationDetails.submissionStatus &&
          'submitted' ===
            previousSelectedCitationDetails.submissionStatus.toLowerCase()
            ? actionTriggered('SUBMIT', previousSelectedCitationDetails)
            : actionTriggered('DRAFT', previousSelectedCitationDetails);
        }}
      />
    );
  };

  const render = () => {
    const { surveyData, formDisabled } = state;
    return (
      <div id="task" role="article" ref={amsTaskRef} tabIndex="-1">
        {formProcessing && (
          <Dimmer active inverted>
            <Loader content="Loading..." />
          </Dimmer>
        )}
        {renderMovingConfirmation()}
        {showConfirmationAlertModal()}
        {showSuccessConfirmation()}
        <Segment basic>
          <FollowUpCitationsForm
            data={{
              handleSaveDraft: handleSaveDraft,
              handleSubmit: handleSubmit,
              handleCancel: handleCancel,
              handleEdit: handleEdit,
              addAdditionalCitation: addAdditionalCitation,
              handleSeletedCitationDetails: handleSeletedCitationDetails,
              handleDataChanged: handleDataChanged,
              handleShowMovingAlert: handleShowMovingAlert,
              handlePreviousSeletedCitationDetails: handlePreviousSeletedCitationDetails,
            }}
            buildFormData={buildFormData}
            surveyData={surveyData}
            formDisabled={formDisabled}
            dataHasChanged={state.dataHasChanged}
            removeAdditionalCitation={removeAdditionalCitation}
            fetchData={fetchData}
            errors={state.errors}
            errorType={state.errorType}
            citationsData={state.citationsData}
            editMode={state.editMode}
            responseErrors={responseErrors}
            selectedCitationDetails={selectedCitationDetails}
            dataModified={dataModified}
          />
        </Segment>
      </div>
    );
  };
  return <div>{render()}</div>;
};

export default FollowUpFormContainer;
