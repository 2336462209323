import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Button, Grid, Loader, Card, Header } from 'semantic-ui-react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Import util
import enforceRole from '../../utils/EnforceRole';

// Import config.
import { acl } from '../../config';

// Style
import './assets/style.css';

// Actions.
import { fetchCategories } from '../../actions/help/categoryActions';

const HelpCategoryTiles = props => {
  const dispatch = useDispatch();

  const { categories } = useSelector(state => state.help);
  const activeCategories =
    categories &&
    categories.filter(e => {
      return e.active === true;
    });

  useEffect(() => {
    dispatch(fetchCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAddPostButton = () => {
    const { currentUser } = props;

    return enforceRole(
      <Button color="green" size="large" as={Link} href="/help/create">
        Add Post
      </Button>,
      acl.actions.help.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  };

  const renderTiles = () => {
    const tiles =
      activeCategories &&
      _.chunk(activeCategories, 3).map((row, index) => {
        return (
          <Grid.Row index={index} stretched key={index}>
            {row.map((category, index) => {
              return (
                <Grid.Column key={index}>
                  <Card
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.router.push(`/help/${category.slug}`);
                    }}
                    as={'div'}
                    fluid
                  >
                    <Card.Content
                      style={{
                        paddingTop: '70px',
                        paddingBottom: '70px',
                      }}
                    >
                      <Card.Header textAlign="center" as="h3">
                        {category.title}
                      </Card.Header>
                      <Card.Description
                        style={{ fontSize: '18px' }}
                        textAlign="center"
                      >
                        {category.description}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        );
      });

    return tiles;
  };

  if (!activeCategories || activeCategories.length === 0) {
    return (
      <>
        <Header>No Help Categories have been added yet.</Header>
        {showAddPostButton()}
      </>
    );
  } else {
    return (
      <Grid stackable columns="equal">
        <Loader active={false} inline="centered" content="Loading features" />
        <Grid.Row columns="equal">
          <Grid.Column></Grid.Column>
          <Grid.Column textAlign="right">{showAddPostButton()}</Grid.Column>
        </Grid.Row>
        {renderTiles()}
      </Grid>
    );
  }
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
  };
}

export default connect(mapStateToProps, {})(withRouter(HelpCategoryTiles));
