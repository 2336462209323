import {
  USER_PROFILE_FETCHED,
  USER_PROFILE_UPDATED,
  USER_ROLES_FETCHED,
  USER_ROLES_UPDATED,
  USER_HISTORY_FETCHED,
  USER_CARD_FETCHED,
} from '../redux/types';

export default function currentUser(state = {}, action) {
  switch (action.type) {
    case USER_PROFILE_FETCHED: {
      return { ...state, ...action.profile };
    }

    case USER_CARD_FETCHED: {
      return { ...state, card: action.card };
    }

    case USER_HISTORY_FETCHED: {
      return { ...state, history: action.history };
    }

    case USER_PROFILE_UPDATED: {
      return { ...state, ...action.profile };
    }

    case USER_ROLES_FETCHED: {
      return { ...state, ...action.roles };
    }

    case USER_ROLES_UPDATED: {
      return { ...state, ...action.roles };
    }

    default:
      return state;
  }
}
