import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Button, Form, Item, Segment } from 'semantic-ui-react';

// Import actions.
import { fetchUsersList, selectTab } from '../../actions/adminActions';
import { userProfileFetched } from '../../actions/profileActions';

// Import components.
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import AmsTable from '../../utils/AmsTable';
import SearchUsers from '../../utils/SearchUsers';
import CreateUserModal from './CreateUserModal';

class UsersAdminPage extends Component {
  state = {
    users: [],
    responseErrors: [],
    filters: {
      name: '',
      regions: [],
      roles: [],
      status: '',
      type: [],
      onHold: '',
      languageSkills: [],
      contentAreaSkills: [],
      otherSkills: [],
    },
    page: 1,
    limit: 25,
    sortName: 'name',
    sortOrder: 'asc',
  };

  constructor(props) {
    super(props);
    this.amsTableRef = React.createRef();
  }

  componentWillMount() {
    this.props.selectTab({
      key: 3,
      pageTitle: 'Users',
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { fetchUsersList } = this.props;

    const { page, limit, filters, sortName, sortOrder } = this.state;

    let input = {
      filters,
      page,
      limit,
      sortName,
      sortOrder,
    };
    this.setState({ loading: true });

    fetchUsersList(input)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.onError(error);
      });
  }

  buildUserList() {
    const {
      usersList: { data, page, total },
    } = this.props;

    const formatName = (cell, row) => {
      return (
        <div className="user-name-wrapper">
          <div className="name">
            <Link to={`/admin/users/profile/${row.oid}`}>
              <strong>{cell}</strong>
            </Link>
          </div>
          <Item.Meta content={row.userName} />
        </div>
      );
    };

    const formatRoles = (cell, row) => {
      return <div>{(row.roles && row.roles.join(', ')) || ''} </div>;
    };

    const formatOnHold = (cell, row) => {
      if (row.onHold) {
        return <div>{'On Hold'}</div>;
      }
    };

    const formatLanguageSkills = (cell, row) => {
      return (
        <div>
          {(row.languageSkills && row.languageSkills.join(', ')) || ''}{' '}
        </div>
      );
    };

    const formatContentAreaSkills = (cell, row) => {
      return (
        <div>
          {(row.contentAreaSkills && row.contentAreaSkills.join(', ')) || ''}{' '}
        </div>
      );
    };

    const formatOtherSkills = (cell, row) => {
      return (
        <div>{(row.otherSkills && row.otherSkills.join(', ')) || ''} </div>
      );
    };

    const scrollToRef = () => {
      window.scrollTo(0, this.amsTableRef.current.offsetTop);
      this.amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onPageChange = (page, sizePerPage) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === this.state.sortName &&
        sortOrder === this.state.sortOrder
      )
        return null;
      this.setState(
        {
          sortName: sortField || this.state.sortName,
          sortOrder: sortOrder || this.state.sortOrder,
        },
        () => this.getData()
      );
    };

    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        formatter: formatName,
        sort: true,
      },
      {
        dataField: 'roles',
        text: 'Roles',
        formatter: formatRoles,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'userType',
        text: 'Type',
        sort: true,
      },
      {
        dataField: 'onHold',
        text: 'On Hold',
        sort: true,
        formatter: formatOnHold,
      },
      {
        dataField: 'languageSkills',
        text: 'Language Skills',
        formatter: formatLanguageSkills,
      },
      {
        dataField: 'contentAreaSkills',
        text: 'Content Area Skills',
        formatter: formatContentAreaSkills,
      },
      {
        dataField: 'otherSkills',
        text: 'Other Skills',
        formatter: formatOtherSkills,
      },
    ];

    const defaultSorted = [
      {
        dataField: this.state.sortName,
        order: this.state.sortOrder,
      },
    ];

    return (
      <div ref={this.amsTableRef} tabIndex="-1">
        <AmsTable
          defaultSorted={defaultSorted}
          remote
          data={data}
          page={page}
          total={total}
          limit={this.state.limit}
          loading={this.state.loading}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  }

  handleAddUser() {
    this.context.router.push(`/admin/users/create`);
  }

  showAddUserButton() {
    const { currentUser } = this.props;
    if (_.includes(currentUser.roles, 'LogisticsAssociate')) return;
    else
      return (
        <div className="add-new-wrapper">
          <Button
            size="huge"
            floated="right"
            onClick={this.handleAddUser.bind(this)}
            className="add-user-button"
            id="add-user"
          >
            {' '}
            Add User
          </Button>
        </div>
      );
  }

  handleFilterReset() {
    this.setState(
      {
        filters: {
          name: '',
          regions: [],
          roles: [],
          status: '',
          type: [],
          onHold: '',
          languageSkills: [],
          contentAreaSkills: [],
          otherSkills: [],
        },
        errors: {},
        responseErrors: [],
        loading: false,
        page: 1,
        limit: 10,
        sortName: 'name',
        sortOrder: 'asc',
      },
      () => {
        this.getData();
      }
    );
  }

  handleFilterClick = () => {
    this.setState(
      {
        page: 1,
        limit: 10,
        sortName: 'name',
        sortOrder: 'asc',
      },
      () => {
        this.getData();
      }
    );
  };

  handleChange = (e, { name, value }) =>
    this.setState({ filters: { ...this.state.filters, [name]: value } });

  handleSelectedName = (e, value) =>
    this.setState({ filters: { ...this.state.filters, name: value } });

  showUserSearch() {
    const {
      name,
      regions,
      roles,
      status,
      type,
      onHold,
      languageSkills,
      contentAreaSkills,
      otherSkills,
    } = this.state.filters;
    return (
      <Segment>
        <Form className="filter-form">
          <AmsSkipLinks
            links={[
              { title: 'Skip filters and go to results', to: 'add-user' },
            ]}
          />

          <Form.Group widths="equal">
            <Form.Field
              label={{ children: 'Name', htmlFor: 'name' }}
              placeholder="Search Name"
              value={name}
              control={SearchUsers}
              name="name"
              onValueSelected={this.handleSelectedName}
              fluid
            />
            <Form.Field
              label={{ children: 'Regions', htmlFor: 'regions' }}
              placeholder="Select Region"
              value={regions}
              name="regions"
              onChange={this.handleChange}
              control={AmsLookupDropdown}
              fluid
              selection
              multiple
              // clearable
              category={'regions'}
              id="regions"
              aria-labelledby="regions"
            />
            <Form.Field>
              <AmsFormLabel name="Roles" fieldLabel="amsUserRoles">
                <AmsLookupDropdown
                  placeholder="Select Role"
                  value={roles}
                  name="roles"
                  onChange={this.handleChange}
                  control={AmsLookupDropdown}
                  fluid
                  multiple
                  selection
                  // clearable
                  search
                  category={'amsUserRoles'}
                  id="amsUserRoles"
                  searchInput={{
                    id: 'amsUserRolesSearch',
                    title: 'Roles Search',
                    'aria-labelledby': 'amsUserRoles',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field
              label={{ children: 'Status', htmlFor: 'userStatus' }}
              placeholder="Select Status"
              value={status}
              name="status"
              onChange={this.handleChange}
              control={AmsLookupDropdown}
              fluid
              selection
              // clearable
              category={'userStatus'}
              id="userStatus"
              aria-labelledby="userStatus"
            />
            <Form.Field
              label={{ children: 'User Type', htmlFor: 'userTypes' }}
              placeholder="Select User Type"
              value={type}
              name="type"
              onChange={this.handleChange}
              control={AmsLookupDropdown}
              fluid
              multiple
              selection
              // clearable
              category={'userTypes'}
              id="userTypes"
              aria-labelledby="userTypes"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              label={{ children: 'On Hold Status', htmlFor: 'onHold' }}
              placeholder="Select On Hold Status"
              value={onHold}
              name="onHold"
              onChange={this.handleChange}
              control={AmsLookupDropdown}
              fluid
              selection
              // clearable
              category={'conditionalCategory'}
              id="onHold"
              aria-labelledby="onHold"
            />
            <Form.Field>
              <AmsFormLabel name="Language Skills" fieldLabel="languageSkills">
                <AmsLookupDropdown
                  placeholder="Select Language Skills"
                  value={languageSkills}
                  name="languageSkills"
                  onChange={this.handleChange}
                  control={AmsLookupDropdown}
                  fluid
                  search
                  multiple
                  selection
                  // clearable
                  category={'languages'}
                  id="languageSkills"
                  searchInput={{
                    id: 'languageSkillsSearch',
                    title: 'Select Language Skills',
                    'aria-labelledby': 'languageSkills',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field>
              <AmsFormLabel
                name="Content Area Skills"
                fieldLabel="contentAreaSkills"
              >
                <AmsLookupDropdown
                  placeholder="Select Content Area Skills"
                  value={contentAreaSkills}
                  name="contentAreaSkills"
                  onChange={this.handleChange}
                  control={AmsLookupDropdown}
                  fluid
                  search
                  multiple
                  selection
                  // clearable
                  category={'contentAreaSkills'}
                  id="contentAreaSkills"
                  searchInput={{
                    id: 'contentAreaSkillsSearch',
                    title: 'Select Content Area Skills',
                    'aria-labelledby': 'contentAreaSkills',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field>
              <AmsFormLabel name="Other Skills" fieldLabel="otherSkills">
                <AmsLookupDropdown
                  placeholder="Select Other Skills"
                  value={otherSkills}
                  name="otherSkills"
                  onChange={this.handleChange}
                  control={AmsLookupDropdown}
                  fluid
                  search
                  multiple
                  selection
                  // clearable
                  category={'otherSkills'}
                  id="otherSkills"
                  searchInput={{
                    id: 'otherSkillsSearch',
                    title: 'Select other Skills',
                    'aria-labelledby': 'otherSkills',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            &nbsp;
            <Form.Button
              width={2}
              className="filter-button"
              primary
              content={'Filter'}
              onClick={this.handleFilterClick.bind(this)}
              fluid
            />
            <Form.Button
              width={1}
              className="filter-button"
              content="Reset"
              fluid
              onClick={this.handleFilterReset.bind(this)}
              basic
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }

  showCreateUserModal() {
    const { action } = this.props.params;

    if (action === 'create') return <CreateUserModal />;
  }

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map(
              (errorObject, index) =>
                errorObject && (
                  <li key={index}>
                    {errorObject.message} code:(
                    {errorObject.code})
                  </li>
                )
            )}
          </ul>
        </Alert>
      );
  }

  render() {
    return (
      <div className="admin-users">
        {this.showResponseErrors()}
        {this.showUserSearch()}
        {this.showCreateUserModal()}
        {this.showAddUserButton()}
        {this.buildUserList()}
      </div>
    );
  }
}

UsersAdminPage.propTypes = {
  users: PropTypes.array.isRequired,
  userRoles: PropTypes.array.isRequired,
};

UsersAdminPage.contextTypes = {
  router: PropTypes.object.isRequired,
  userProfileFetched: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    users: state.admin.userProfiles,
    usersList: state.admin.userProfilesList,
    total: state.admin.total,
    currentUser: state.auth.user,
    userRoles: state.admin.userRoles,
  };
}

export default connect(mapStateToProps, {
  selectTab,
  fetchUsersList,
  userProfileFetched,
})(UsersAdminPage);
