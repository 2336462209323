import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, filter, find, map, remove } from 'lodash';
import {
  Modal,
  Button,
  Form as SemanticForm,
  Message,
  Radio,
  Segment,
  Grid,
} from 'semantic-ui-react';

import AmsModal from '../../../utils/AmsModal';
import AmsTable from '../../../utils/AmsTable';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import Form from '@rjsf/core';
import {
  fetchRANFormSchema,
  fetchRANFormDetail,
  ranGuideSelected,
  ranPerformanceMeasureSelected,
} from '../../../actions/ranActions';
import { allowedView } from '../../../utils/EnforceRole';
import { acl } from '../../../config';
import AmsAlert from '../../../utils/AmsAlert';
import { exportIncidents } from '../../../actions/submissions';
import GranteeIdField from '../../Shared/FormHelpers/GranteeIdField';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import { postFormSubmission } from '../../../actions/surveyActions';
import ItalicField from '../../Shared/FormHelpers/ItalicField';

const RANIncidents = props => {
  const amsTableRef = useRef(null);
  const {
    selectedPerformanceMeasure,
    selectedGuide,
    isReportFinalized,
    reviewStatus,
    readOnly,
    reviewId,
    submission,
    fiscalYear,
  } = useSelector(state => state.forms.ran);
  const { user } = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [incidentFileErrors, setIncidentFileErrors] = useState([]);
  const [selectedIncidentFile, setSelectedIncidentFile] = useState({});
  const [rowClicked, setRowClicked] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [fileEditButtonClicked, setFileEditButtonClicked] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [draftButtonClicked, setDraftButtonClicked] = useState(false);
  const [previousIncidentId, setPreviousIncidentId] = useState('');
  const [
    showDeleteConfirmationAlert,
    setShowDeleteConfirmationAlert,
  ] = useState(false);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [selectedPM, setSelectedPM] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showMovingAwayDialog, setShowMovingAwayDialog] = useState(false);
  const dispatch = useDispatch();

  // useEffect to set selected PM
  useEffect(() => {
    if (!isEmpty(selectedPerformanceMeasure)) {
      setSelectedPM(selectedPerformanceMeasure);
    }
  }, [selectedPerformanceMeasure]);

  // useEffect to set form state
  useEffect(() => {
    if (submission && !isEmpty(submission))
      setFormDisabled(!isEmpty(submission));
    else setFormDisabled(false);
  }, [submission]);

  // useEffect to stop form processing when form schema is fetched
  useEffect(() => {
    setFormProcessing(false);
    setShowModal(false);
  }, [selectedPM.formSchema]);

  // useEffect to update error state for incident file
  useEffect(() => {
    if (props.incidentFileSaveErrors) {
      setErrors(props.incidentFileSaveErrors);
    }
  }, [props.incidentFileSaveErrors]);

  // Build and render incident file listing table
  const renderIncidentFilesTable = () => {
    const data = props.formData.incidentFiles;
    const formatDate = (cell, row) =>
      AmsDateFormatters.formatDate(AmsDateFormatters.getMoment(cell));

    const rowStyle = (row, rowIndex) => {
      if (
        errors &&
        errors.incidentFiles &&
        errors.incidentFiles.length > 0 &&
        find(
          errors.incidentFiles,
          incidentId =>
            incidentId.toLowerCase().trim() ===
            row.incidentId.toLowerCase().trim()
        )
      ) {
        return {
          backgroundColor: '#e0b4b4',
        };
      }
    };

    const actionFormatter = (cell, row) => {
      return (
        <Button.Group icon>
          <Button
            onClick={e => {
              e.preventDefault();
              setFileEditButtonClicked(true);
              setSelectedIncidentFile(row);
              setShowModal(true);
              setFormDisabled(false);
              setPreviousIncidentId(row.incidentId);
              setRowClicked(true);
            }}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-pencil fa-md" />
          </Button>
          &nbsp; | &nbsp;
          {allowedView(acl.actions.survey.deleteIncidentFiles, user.roles) && (
            <Button
              onClick={e => {
                e.preventDefault();
                setShowDeleteConfirmationAlert(true);
                setSelectedIncidentFile(row);
              }}
              className="clickable"
              style={{ background: 'none' }}
            >
              <i className="fa fa-trash fa-md" />
            </Button>
          )}
        </Button.Group>
      );
    };

    const incidentFormatter = (cell, row) => {
      if (!editButtonClicked && !isEmpty(submission)) {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={e => {
              e.preventDefault();
              if (!editButtonClicked) {
                setSelectedIncidentFile(row);
                setShowModal(true);
                setRowClicked(true);
                setPreviousIncidentId(row.incidentId);
              }
            }}
            // eslint-disable-next-line no-script-url
            href="javascript:void(0);"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            {cell}
          </a>
        );
      } else return cell;
    };

    const columns = [
      {
        dataField: 'incidentId',
        text: 'Incident ID',
        sort: true,
        style: { whiteSpace: 'normal' },
        formatter: incidentFormatter,
      },
      {
        dataField: 'submitterName',
        text: 'Submitter',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'fileReviewDate',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Date Added',
      },
    ];

    if (editButtonClicked) {
      columns.push({
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: actionFormatter,
        text: 'Action',
      });
    } else if (!editButtonClicked && isEmpty(submission)) {
      columns.push({
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: actionFormatter,
        text: 'Action',
      });
    }

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (!editButtonClicked && !isEmpty(submission)) {
          setSelectedIncidentFile(row);
          setShowModal(true);
          setRowClicked(true);
          setPreviousIncidentId(row.incidentId);
        }
      },
    };

    return (
      <>
        <div className="row">
          <div className="col-sm-8">
            <h5>Added Files</h5>
          </div>
          {!isReportFinalized &&
            reviewStatus &&
            reviewStatus.toLowerCase() !== 'cancelled' &&
            (editButtonClicked ||
              !submission.amsSubmissionId ||
              (submission.amsSubmissionId && editButtonClicked)) && (
              <div className="col-sm-1">
                <Button
                  basic
                  size="tiny"
                  disabled={readOnly}
                  onClick={() => {
                    setShowModal(true);
                    setFormDisabled(false);
                    setRowClicked(false);
                  }}
                >
                  Add Incident
                </Button>
              </div>
            )}
          {/* {submission && submission.amsSubmissionId && (
            <div className="col-sm-3 export-incident-files-button">
              <Button
                primary
                size="small"
                icon
                onClick={() =>
                  dispatch(exportIncidents(submission.amsSubmissionId))
                }
              >
                Export Files
              </Button>
              <br />
            </div>
          )} */}
          <br />
          <br />
        </div>

        <AmsTable
          remote={false}
          basic
          total={data ? data.length : 0}
          columns={columns}
          rowEvents={rowEvents}
          keyField="version"
          ref={amsTableRef}
          data={data ? data : []}
          rowStyle={rowStyle}
        />
      </>
    );
  };

  /**
   * Set the form data changes for submission
   * @param {Object} data - data that comes from the form schema
   * @returns {void}
   */
  const handleChange = ({ idSchema, formData }) => {
    setSelectedIncidentFile({
      incidentData: formData,
      incidentId: selectedIncidentFile.incidentId,
      mongoId: selectedIncidentFile.mongoId,
    });
  };

  /**
   * Transform the incident file errors to custom error messages
   * @param {Object} incidentFileErrors
   * @returns {Object}
   */
  const transformErrors = incidentFileErrors => {
    if (draftButtonClicked) {
      return [];
    }

    return filter(incidentFileErrors, error => {
      if (error.name === 'minItems') {
        error.message = 'This is a required field.';
        return error.name === 'minItems';
      }
      if (error.name === 'required') {
        error.message = 'This is a required field.';
        return error.name === 'required' || error.name === 'minimum';
      } else if (error.name === 'pattern') {
        error.message =
          'Please enter a valid Grantee ID, example format - 01CH010527';
        return error.name === 'pattern';
      } else if (error.name === 'incidentId') {
        error.message =
          'The incident file already exists please check the Incident ID and try again.';
        return error.name === 'incidentId';
      } else if (error.name === 'format') {
        error.message = 'Please enter a valid date';
        return error.name === 'format';
      }
    });
  };

  /**
   * Process a single incident file addition
   * @param {Object} data - data that comes from the form schema
   * @returns {void}
   */
  const onSubmitIncidentFile = ({ idSchema, formData }) => {
    if (selectedIncidentFile && selectedIncidentFile.incidentId) {
      formData = {
        incidentData: { ...formData },
        submitterName: user.fullName,
        submitterOid: user.oid,
        incidentId: selectedIncidentFile.incidentId,
        mongoId: selectedIncidentFile.mongoId,
      };
    } else {
      formData = {
        incidentData: { ...formData },
        submitterName: user.fullName,
        submitterOid: user.oid,
      };
    }

    props.handleDataChange({
      formData,
      rowClicked,
      fileEditButtonClicked,
      previousIncidentId,
      selectedIncidentFile,
    });

    setShowModal(false);
    setSelectedIncidentFile({});
    setIncidentFileErrors([]);
    setFileEditButtonClicked(false);
    setRowClicked(false);
    if (!isEmpty(errors) && errors.incidentFiles.length > 0) {
      let remainingErrors = remove(errors.incidentFiles, incidentfileId => {
        return (
          incidentfileId.toLowerCase().trim() !==
          previousIncidentId.toLowerCase().trim()
        );
      });
      if (remainingErrors.length === 0) {
        setErrors({});
      } else setErrors({ ...errors, incidentFiles: remainingErrors });
    } else {
      setErrors({});
    }
    setPreviousIncidentId('');
  };

  const handleError = error => {
    console.log(error);

    if (error.length >= 1) {
      setShowErrorDialog(true);
    }
  };

  // Set state to default values when closing modal
  const closeModal = () => {
    setShowMovingAwayDialog(false);
    setShowModal(false);
    setRowClicked(false);
    setSelectedIncidentFile({});
    setIncidentFileErrors([]);
    setPreviousIncidentId('');
  };

  // Add custom field for gantee id in the form
  const fields = () => {
    return {
      granteeIdField: GranteeIdField,
      italicField: ItalicField,
    };
  };

  const validate = (data, errors) => {
    const today = AmsDateFormatters.getTodayDate();

    if (
      selectedPerformanceMeasure.name ===
        'Document Inappropriate Release Incident(s)' ||
      selectedPerformanceMeasure.name ===
        'Document Inappropriate Release Incident(s) Details Guide'
    ) {
      const { question11, question6 } = data;
      incidentReportedErrors(data, errors);
      if (question11.question11aYesNo && fiscalYear >= 2023) {
        validateDateError(
          question11.question11aYesNo,
          'question11aYesNo',
          errors
        );
        staffTrainingErrors(
          question11.question11aYesNo,
          'question11aYesNo',
          errors
        );
      } else if (question6.question6aYes) {
        const diff = AmsDateFormatters.getDifference(
          AmsDateFormatters.getMoment(today),
          AmsDateFormatters.getMoment(question6.question6aYes)
        );
        if (diff / 365 > 7) {
          errors.question6.question6aYes.addError('Please enter a valid date.');
        }
      }
    }

    if (
      selectedPerformanceMeasure.name ===
      'Document Supervision Incident(s) Details Guide'
    ) {
      const { question17 } = data;
      incidentReportedErrors(data, errors);
      validateDateError(
        question17.question17aYesNo,
        'question17aYesNo',
        errors
      );
      staffTrainingErrors(
        question17.question17aYesNo,
        'question17aYesNo',
        errors
      );
    }

    if (
      selectedPerformanceMeasure.name ===
      'Document Discipline Incident(s) Details Guide'
    ) {
      const { question10 } = data;
      incidentReportedErrors(data, errors);
      validateDateError(
        question10.question10aYesNo,
        'question10aYesNo',
        errors
      );
      staffTrainingErrors(
        question10.question10aYesNo,
        'question10aYesNo',
        errors
      );
    }
    return errors;
  };

  const staffTrainingErrorMessage = () => {
    return 'The date of the last training entered cannot be today or a day in the future. Please update the date for the last training to be a past date.';
  };

  const validateDateErrorMessage = () => {
    return 'Please enter a valid date.';
  };

  const incidentReportedErrorMessage = () => {
    return 'Incident report date cannot be before the incident date.';
  };

  const staffTrainingErrors = (questionKey, questionType, errors) => {
    const today = AmsDateFormatters.getTodayDate();
    if (questionKey) {
      const question = AmsDateFormatters.getMoment(questionKey);
      if (
        AmsDateFormatters.getMoment(today)
          .startOf('day')
          .isSameOrBefore(question.startOf('day'))
      ) {
        if (questionType == 'question10aYesNo') {
          errors.question10.question10aYesNo.addError(
            staffTrainingErrorMessage()
          );
        } else if (questionType == 'question17aYesNo') {
          errors.question17.question17aYesNo.addError(
            staffTrainingErrorMessage()
          );
        } else if (questionType == 'question11aYesNo') {
          errors.question11.question11aYesNo.addError(
            staffTrainingErrorMessage()
          );
        }
      }
    }
  };

  const validateDateError = (questionKey, questionType, errors) => {
    const today = AmsDateFormatters.getTodayDate();
    if (questionKey) {
      const diff = AmsDateFormatters.getDifference(
        AmsDateFormatters.getMoment(today),
        AmsDateFormatters.getMoment(questionKey)
      );
      if (diff / 365 > 7) {
        if (questionType == 'question10aYesNo') {
          errors.question10.question10aYesNo.addError(
            validateDateErrorMessage()
          );
        } else if (questionType == 'question17aYesNo') {
          errors.question17.question17aYesNo.addError(
            validateDateErrorMessage()
          );
        } else if (questionType == 'question11aYesNo') {
          errors.question11.question11aYesNo.addError(
            validateDateErrorMessage()
          );
        } else if (questionType == 'question4Date') {
          errors.question4.question4Date.addError('Please enter a valid date.');
        } else if (questionType == 'question5Date') {
          errors.question5.question5Date.addError('Please enter a valid date.');
        }
      }
    }
  };

  const incidentReportedErrors = (data, errors) => {
    const { question4, question5 } = data;
    validateDateError(question4.question4Date, 'question4Date', errors);
    validateDateError(question5.question5Date, 'question5Date', errors);
    if (question4.question4Date) {
      const q4 = AmsDateFormatters.getMoment(question4.question4Date);
      const q5 = AmsDateFormatters.getMoment(question5.question5Date);
      if (q5.isBefore(q4)) {
        errors.question5.question5Date.addError(incidentReportedErrorMessage());
      }
    }
  };

  // Render the add incident file modal
  const renderAddIncidentFiles = () => {
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        onClose={closeModal}
        size="large"
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{'Add Incident Files'}</Modal.Header>
        <Modal.Content>
          {!isEmpty(incidentFileErrors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={map(incidentFileErrors, error => error.message)}
            />
          )}

          <div className="row">
            <div
              key={
                (selectedPM &&
                  selectedPM.forms &&
                  selectedPM.forms[0] &&
                  selectedPM.forms[0].amsFormId) ||
                ''
              }
              className="form-container"
            >
              <fieldset
                disabled={
                  isReportFinalized ||
                  (reviewStatus && reviewStatus.toLowerCase() === 'cancelled')
                    ? true
                    : formDisabled
                }
              >
                <Form
                  idPrefix={
                    (selectedPM &&
                      selectedPM.forms &&
                      selectedPM.forms[0] &&
                      selectedPM.forms[0].amsFormId) ||
                    ''
                  }
                  schema={selectedPM.formSchema || {}}
                  uiSchema={selectedPM.uiSchema || {}}
                  formData={
                    selectedIncidentFile.incidentData
                      ? selectedIncidentFile.incidentData
                      : selectedIncidentFile
                  }
                  onSubmit={onSubmitIncidentFile}
                  onChange={handleChange}
                  onError={handleError}
                  validate={validate}
                  liveValidate={false}
                  showErrorList={false}
                  noHtml5Validate
                  transformErrors={transformErrors}
                  fields={fields()}
                >
                  <div className="ui form">
                    {!isReportFinalized && (
                      <SemanticForm.Group>
                        <SemanticForm.Button
                          primary
                          type="submit"
                          content={
                            fileEditButtonClicked || rowClicked
                              ? 'Save changes'
                              : 'Add'
                          }
                        />
                      </SemanticForm.Group>
                    )}
                  </div>
                </Form>
              </fieldset>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              if (formDisabled) {
                closeModal();
                return;
              }
              setShowMovingAwayDialog(true);
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  // Render the top level question for adding incident files
  const showTopQuestion = () => {
    const { topQuestion } = props.formData;
    return (
      <div className="ui form">
        <AmsFormLabel
          name={
            fiscalYear >= 2023
              ? 'Are you ready to complete the data collection task for this performance measure?'
              : 'Were you able to complete the data collection task for this performance measure?*'
          }
          fieldLabel="completedDataCollection"
          disabled={formDisabled}
        >
          <SemanticForm.Group inline>
            <SemanticForm.Field
              name={'topQuestion'}
              control={Radio}
              label="Yes"
              value={topQuestion}
              checked={topQuestion === true}
              onChange={e => {
                e.preventDefault();
                props.handleDataChange({ topQuestion: true }, 'topQuestion');
              }}
              disabled={formDisabled}
            />
            <SemanticForm.Field
              name={'topQuestion'}
              control={Radio}
              label="No"
              value={topQuestion}
              checked={topQuestion === false}
              onChange={e => {
                e.preventDefault();
                props.handleDataChange({ topQuestion: false }, 'topQuestion');
              }}
              disabled={formDisabled}
            />
          </SemanticForm.Group>
        </AmsFormLabel>
      </div>
    );
  };

  const showEditButton = () => {
    if (readOnly) return;

    if (isReportFinalized) return;

    if (editButtonClicked) return;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;
    if (
      submission.surveyStatus &&
      (submission.surveyStatus.toLowerCase() === 'submitted' ||
        submission.surveyStatus.toLowerCase() === 'draft') &&
      submission.editable
    ) {
      return (
        <Button
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            setFormDisabled(false);
            setEditButtonClicked(true);
          }}
        />
      );
    }
  };

  const showSubmitButton = () => {
    let incidentFiles = [];
    let existing =
      (submission &&
        submission.data &&
        submission.data.surveyData &&
        submission.data.surveyData.incidentFiles) ||
      [];
    const { create, update } = props.incidentFileSubmission;
    if (create || update || props.incidentFileSubmission.delete)
      incidentFiles = [
        ...(create || []),
        ...(update || []),
        ...(props.incidentFileSubmission.delete || []),
      ];
    if (existing) incidentFiles = [...existing, ...incidentFiles];
    if (!editButtonClicked && submission.amsSubmissionId) return;
    if (readOnly) return;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return;

    if (
      (editButtonClicked &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'submitted') ||
      !submission.surveyStatus ||
      (submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft')
    ) {
      return (
        <div>
          <SemanticForm.Button
            type="submit"
            primary
            disabled={
              props.formData.topQuestion === false
                ? false
                : props.formData.topQuestion && !isEmpty(incidentFiles)
                ? false
                : submission.surveyStatus &&
                  submission.surveyStatus.toLowerCase() === 'draft' &&
                  !isEmpty(incidentFiles)
                ? false
                : true
            }
            content={'Submit'}
            onClick={e => {
              e.preventDefault();
              saveData('submitted');
            }}
          />
        </div>
      );
    }
  };

  const showDraftButton = () => {
    if (!editButtonClicked && submission.amsSubmissionId) return;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (readOnly) {
      return null;
    }

    if (
      !submission.surveyStatus ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft' &&
        editButtonClicked)
    )
      return (
        <Button
          type="submit"
          content="Save as Draft"
          disabled={
            props.formData.topQuestion === false
              ? false
              : props.formData.topQuestion
              ? false
              : submission.surveyStatus &&
                submission.surveyStatus.toLowerCase() === 'draft'
              ? false
              : true
          }
          onClick={() => {
            setDraftButtonClicked(true);
            saveData('draft');
          }}
        />
      );

    return null;
  };

  const showCancelButton = () => {
    if (readOnly) return null;

    if (
      !editButtonClicked ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() !== 'submitted')
    )
      return null;
    else
      return (
        <Button
          negative
          content={'Cancel'}
          onClick={e => {
            e.preventDefault();
            setFormDisabled(true);
            setEditButtonClicked(false);
            props.handleCancelChange(
              submission && submission.data && submission.data.surveyData
            );
          }}
        />
      );
  };

  const showDeleteConfirmation = () => {
    return (
      <AmsAlert
        show={showDeleteConfirmationAlert}
        title={'Are you sure you want to delete this incident file?'}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, delete'}
        cancelButtonText={'No, cancel'}
        onCancel={() => {
          setShowDeleteConfirmationAlert(false);
          setSelectedIncidentFile({});
        }}
        onConfirm={() => {
          let deleted = [...(props.incidentFileSubmission.delete || [])];
          deleted.push(selectedIncidentFile);
          let created = remove(
            props.incidentFileSubmission.create,
            file => file.incidentId !== selectedIncidentFile.incidentId
          );
          let updated = remove(
            props.incidentFileSubmission.update,
            file => file.incidentId !== selectedIncidentFile.incidentId
          );

          props.handleIncidentFileDelete({
            update: updated,
            delete: deleted,
            create: created,
            topQuestion: props.formData.topQuestion,
            selectedIncidentFile,
          });
          if (!isEmpty(errors) && errors.incidentFiles.length > 0) {
            let remainingErrors = remove(
              errors.incidentFiles,
              incidentfileId => {
                return (
                  incidentfileId.toLowerCase().trim() !==
                  selectedIncidentFile.incidentId.toLowerCase().trim()
                );
              }
            );
            if (remainingErrors.length === 0) {
              setErrors({});
            } else setErrors({ ...errors, incidentFiles: remainingErrors });
          } else {
            setErrors({});
          }
          setShowDeleteConfirmationAlert(false);
          setSelectedIncidentFile({});
        }}
      />
    );
  };

  const showSuccessConfirmation = () => {
    return (
      <AmsAlert
        show={alert.show}
        title={alert.message}
        type={alert.type || 'success'}
        showConfirm
        onConfirm={() => {
          setAlert({
            show: false,
            message: '',
            type: '',
          });
          dispatch(fetchRANFormDetail({ reviewId })).then(() => {
            dispatch(ranGuideSelected(selectedGuide));
            dispatch(ranPerformanceMeasureSelected(selectedPerformanceMeasure));
          });
        }}
      />
    );
  };

  /**
   * Build the incident file submission data to be sent to the server
   * @param {Object} surveyStatus
   * @returns {void}
   */
  const saveData = submissionStatus => {
    let data = {
      create: props.incidentFileSubmission.create || [],
      update: props.incidentFileSubmission.update || [],
      delete:
        filter(
          props.incidentFileSubmission.delete,
          incidentFile => incidentFile.mongoId
        ) || [],
      topQuestion: props.formData.topQuestion,
      submissionStatus,
      surveyData: {},
      incidentType: 'RAN-INCIDENTS',
    };
    if (submission && submission.amsSubmissionId)
      data.amsSubmissionId = submission.amsSubmissionId;
    if (
      selectedPM.forms &&
      selectedPM.forms[0] &&
      selectedPM.forms[0].amsFormId
    )
      data.amsFormId = selectedPM.forms[0].amsFormId;
    if (reviewId) data.reviewId = reviewId;
    if (selectedPM.formCode) data.formCode = selectedPM.formCode;
    if (selectedGuide.guideCode) data.guideCode = selectedGuide.guideCode;

    dispatch(postFormSubmission({ data }))
      .then(response => {
        if (response && response.error) {
          setAlert({
            show: true,
            type: 'success',
            message: `${response.error}`,
          });
        } else
          setAlert({
            show: true,
            type: 'success',
            message: 'Data saved successfully',
          });
        props.dataHasChangedSwitch(false);
        resetState();
        dispatch(
          fetchRANFormSchema({
            filters: { amsFormId: selectedPM.forms[0].amsFormId },
          })
        );
      })
      .catch(error => {
        let errorData =
          !isEmpty(error.response.data.message) &&
          JSON.parse(error.response.data.message) &&
          !isEmpty(JSON.parse(error.response.data.message).text) &&
          JSON.parse(error.response.data.message);
        setErrors(errorData);
      });
  };

  // Set state to default values after submission
  const resetState = () => {
    setErrors({});
    setIncidentFileErrors([]);
    setFileEditButtonClicked(false);
    setDraftButtonClicked(false);
    setEditButtonClicked(false);
  };

  // Render survey submission status
  const renderSurveyStatus = () => {
    if (isEmpty(submission)) return null;

    if (!submission.amsSubmissionId) return null;

    const { editTime, editedBy } = submission.data;

    return (
      <Segment
        style={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #e3e3e3',
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
          padding: '20px',
          marginLeft: '1rem',
        }}
      >
        {editedBy && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submitted By"
            >
              Submitted By:{' '}
            </span>
            {editedBy}
          </div>
        )}
        {submission.surveyStatus && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:{' '}
            </span>
            {submission.surveyStatus.toLowerCase() === 'draft'
              ? 'Draft'
              : 'Submitted'}
          </div>
        )}
        {editTime && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Time"
            >
              Submission Time:{' '}
            </span>
            {AmsDateFormatters.formatDateTime(editTime)}
          </div>
        )}
      </Segment>
    );
  };

  const renderErrorDialogAlert = () => {
    return (
      <AmsAlert
        show={showErrorDialog}
        title="Oops!"
        text={
          'The incident form is incomplete. Ensure that all fields have been properly filled out.'
        }
        type={'error'}
        showConfirm
        onConfirm={() => {
          setShowErrorDialog(false);
        }}
      />
    );
  };

  const renderMovingAwayDialogAlert = () => {
    return (
      <AmsAlert
        show={showMovingAwayDialog}
        title="Alert!"
        text={'Changes you made may not be saved. Would you like to continue?'}
        type={'warning'}
        showConfirm
        showCancelButton={true}
        onConfirm={() => {
          closeModal();
        }}
        onCancel={() => {
          setShowMovingAwayDialog(false);
        }}
      />
    );
  };

  return (
    <div className="row form-container">
      <Segment basic loading={formProcessing}>
        {renderSurveyStatus()}
        <Grid columns={1}>
          <Grid.Column>
            <legend>{selectedPM.name}</legend>
            {showTopQuestion()}
            {showSuccessConfirmation()}
            {showDeleteConfirmation()}
            <div className="form-container form-group field field-object sm-col-12">
              {
                <>
                  {props.formData.topQuestion &&
                    submission &&
                    renderIncidentFilesTable()}
                </>
              }
              <br />
              <div className="ui form">
                {(!isReportFinalized || !readOnly) && (
                  <SemanticForm.Group>
                    {showDraftButton()}
                    {showSubmitButton()}
                    {showEditButton()}
                    {showCancelButton()}
                  </SemanticForm.Group>
                )}
              </div>
            </div>
            {renderAddIncidentFiles()}
            {renderErrorDialogAlert()}
            {renderMovingAwayDialogAlert()}
            <br />
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
};

export default RANIncidents;
