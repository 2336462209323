import React from 'react';
import { Card, Dimmer, Loader } from 'semantic-ui-react';

const FindingsNotAvailable = ({ fetchedFindingDetails }) => {
  return (
    <Card className="findings-card">
      {!fetchedFindingDetails &&
        null !== fetchedFindingDetails?.collectDataTypes && (
          <Dimmer active inverted>
            <Loader content="Loading..." />
          </Dimmer>
        )}
      {!fetchedFindingDetails &&
      null !== fetchedFindingDetails?.collectDataTypes ? (
        <>
          <Card.Description>...loading</Card.Description>
        </>
      ) : (
        <>
          <Card.Content
            style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
          >
            <Card.Header>No Finding Available</Card.Header>
          </Card.Content>
          <Card.Description>
            There is no finding data to display
          </Card.Description>
        </>
      )}
    </Card>
  );
};
export default FindingsNotAvailable;
