import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

const FollowUpCorrectiveInfo = ({
  followUpTaskDetail: { reviewStartDate, reviewEndDate, FollowInfo },
}) =>
  !_.isEmpty(FollowInfo) ? (
    <Segment basic>
      <div className="row-info">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-6">
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Corrective Action Period End Date"
              >
                Corrective Action Period End Date :{' '}
              </span>
              {(FollowInfo.correctiveActionPeriodEndDate &&
                AmsDateFormatters.formatDate(
                  FollowInfo.correctiveActionPeriodEndDate
                )) ||
                ''}
            </div>
            <div className="col-sm-6">
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Review Start and End Date"
              >
                Review Start and End Date :{' '}
              </span>
              {`${AmsDateFormatters.formatDate(
                reviewStartDate
              )} - ${AmsDateFormatters.formatDate(reviewEndDate)}` || ''}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-6">
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Corrective Action Period Days"
              >
                Corrective Action Period Days :{' '}
              </span>
              {(FollowInfo.capDays && FollowInfo.capDays) || ''}
            </div>
          </div>
        </div>
      </div>
    </Segment>
  ) : null;

FollowUpCorrectiveInfo.propTypes = {
  followUpTaskDetail: PropTypes.object,
};

const mapStateToProps = state => ({
  followUpTaskDetail: state.submissions.followUpTaskDetail,
});

export default connect(mapStateToProps)(FollowUpCorrectiveInfo);
