/* eslint-disable no-extra-bind */
import base64 from 'base64-arraybuffer';
import fileDownload from 'js-file-download';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { downloadPdfReport } from '../../actions/reportActions';

import './assets/report-comment.css';
import './assets/reports.css';

import { Dimmer, Loader } from 'semantic-ui-react';
import {
  fetchCommentFileNames,
  fetchFileContents,
  fetchReportContent,
  sendComment,
  uploadCommentFiles,
} from '../../actions/reportActions';
import { reportCommentUsers } from '../../actions/userActions';
import { settings } from '../../config';
import BrowserTitle from '../../utils/BrowserTitle';

// Set to moment timezone.
moment.tz(settings.defaultTimeZone); // We have 'America/New_York' in the config

class ReportComment extends Component {
  constructor() {
    super();

    this.sendComment = this.sendComment.bind(this);
    this.reciveReportContent = this.reciveReportContent.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.confirmTos = this.confirmTos.bind(this);
    this.commentList = this.commentList.bind(this);
    this.addFiles = this.addFiles.bind(this);

    this.state = {
      error: '',
      reportContent: null,
      commentPeriodExpired: true,
      files: [],
      downloadedFiles: [],
      filesError: null,
      isBase64Content: null,
      contentChecked: false,
      spinner: true,
      commentValue: '',
      authorFullName: '',
    };
  }

  componentDidMount() {
    this.setState({
      error: '',
      reportContent: null,
      comments: null,
      commentPeriodExpired: true,
      files: [],
      grantee: null,
      filesError: null,
    });

    this.confirmTosState = false;
    this.reciveReportContent();
    this.props.fetchCommentFileNames({
      hashContent: this.getHashId(),
    });
  }

  getHashId() {
    return decodeURIComponent(
      // eslint-disable-next-line no-useless-escape
      (window.location.href.match(/(\?|\&)key=([^\&]*)/) || [])[2] || ''
    );
  }

  reciveReportContent() {
    let hashId = this.getHashId();

    let dataRetrieve = () => {
      this.props.fetchReportContent(hashId);
    };

    this.props.reportCommentUsers(dataRetrieve);
  }

  sendComment(approvalStatus, message) {
    let _self = this;

    let hashId = this.getHashId();

    if (!this.state.authorFullName) {
      _self.setState({
        error: 'Full Name field is empty',
      });
      return;
    }

    if (this.state.isBase64Content && !this.confirmTosState) {
      _self.setState({
        error: 'Please accept terms by checking the box below',
      });
      return;
    }

    if (!this.state.commentValue) {
      this.setState({
        commentValue: 'No comments at ' + moment().format('HH:mm'),
      });
    }

    let requestInput = {
      comments: (message ? message + '\n\n' : '') + this.state.commentValue,
      hashContent: hashId,
      authorFullName: this.state.authorFullName,
      approvalStatus: approvalStatus,
      isCommented: true,
    };

    this.props.sendComment(requestInput, message, this.confirmTosState);
  }

  componentDidUpdate() {
    if ((this.props.sharedReport || {}).content && !this.state.contentChecked) {
      if (this.props.sharedReport.content.indexOf(' ') === -1) {
        this.setState({
          isBase64Content: true,
          contentChecked: true,
          spinner: false,
        });
      } else {
        this.setState({
          isBase64Content: false,
          contentChecked: true,
          spinner: false,
        });
      }
    }
  }

  confirmTos(state) {
    this.confirmTosState = !!state;
  }

  commentList(commentType) {
    return (
      <div
        className="report-comment--user-message-list"
        title={"Users' Comments:" + (commentType || 'Ungrouped')}
      >
        {(() => {
          if (!Array.isArray((this.props.sharedReport || {}).comments)) {
            return null;
          }
          let comments = (this.props.sharedReport || {}).comments.filter(
            comment => {
              return (comment.group || '') === commentType;
            }
          );
          if (!comments.length) {
            return null;
          }
          return comments.map(comment => {
            return (
              <div className="report-comment--user-message">
                <p>
                  <b>{comment.authorFullName}</b> ( {comment.author} ) »
                  <i>{comment.when.replace(/T.*/, '')}</i>
                  <br />
                  <br />
                  {comment.comment ? comment.comment : <i>empty comment...</i>}
                </p>
              </div>
            );
          });
          // eslint-disable-next-line no-extra-bind
        }).bind(this)()}
      </div>
    );
  }

  addFiles() {
    let fileInput = document.createElement('input');
    let _self = this;
    fileInput.type = 'file';
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', '.doc,.docx,.xls,.xlsx,.pdf');
    fileInput.setAttribute('multiple', 'multiple');
    fileInput.addEventListener('change', ev => {
      let files = [];
      let filesError = [];
      _self.state.files.forEach(file => {
        files.push(file);
      });
      Array.prototype.slice
        .call(fileInput.files)
        .filter(file => {
          if (file.size > 20 * 1024 * 1024) {
            filesError.push({
              file: file,
              reason: 'file size exceed 20 Mb',
            });
            return false;
          }
          if (!file.name.match(/\.(doc|docx|xls|xlsx|pdf)$/)) {
            filesError.push({
              file: file,
              reason:
                'file format is not supported, please use .doc,.docx,.xls,.xlsx,.pdf',
            });
            return false;
          }
          return true;
        })
        .forEach(file => {
          files.push(file);
        });
      _self.setState({
        files: files,
        filesError: filesError.length ? filesError : null,
      });
    });
    fileInput.addEventListener('click', ev => {
      setTimeout(ev => {
        fileInput.parentNode.removeChild(fileInput);
      }, 1000);
    });
    document.body.appendChild(fileInput);
    fileInput.click();
  }

  uploadFiles() {
    let _self = this;
    this.props
      .uploadCommentFiles(
        this.state.files,
        encodeURIComponent(this.getHashId())
      )
      .then(
        () => {
          alert('Files uploaded !');

          _self.setState({
            files: [],
          });
        },
        er => {
          _self.setState({
            filesError: [er.message || 'Files upload error'],
          });
        }
      );
  }

  downloadBigBase64(filename, content, contentType) {
    fileDownload(base64.decode(content), filename || 'file', contentType);
  }

  render() {
    return this.state.spinner ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <>
        <BrowserTitle
          title={
            'Report for Review ID ' + (this.props.sharedReport || {}).reviewId
          }
        />
        <div
          className={
            (this.state.isBase64Content &&
            (this.props.sharedReport || {}).content
              ? 'download-report-action '
              : '') + 'report-comment-container'
          }
        >
          {this.state.error ? (
            <div>
              <b>Error:</b> {this.state.error}
            </div>
          ) : (
            ''
          )}
          {(this.props.sharedReport || {}).grantee &&
          !this.state.isBase64Content &&
          (this.props.sharedReport || {}).content ? (
            <div className="report-shared--top-info">
              <div>
                <b>Grantee Name: </b>
                {(this.props.sharedReport || {}).granteeName}
                <br />
                <b>Grantee Id: </b>
                {(this.props.sharedReport || {}).granteeId}
              </div>
            </div>
          ) : null}
          {!this.state.isBase64Content ? (
            <div
              className="report-item--content"
              dangerouslySetInnerHTML={{
                __html:
                  '<br /><br /><br /><br /><br />' +
                  (this.props.sharedReport || {}).content,
              }}
            />
          ) : this.props.report.confirmedTos ? (
            <div>
              <button
                onClick={(() => {
                  fileDownload(
                    base64.decode((this.props.sharedReport || {}).content),
                    `${this.props.sharedReport.reviewId} Signed Report.pdf`,
                    'application/pdf'
                  );
                  // eslint-disable-next-line no-extra-bind
                }).bind(this)}
                className="download btn btn-primary"
              >
                Download PDF Document
              </button>
            </div>
          ) : null}

          {this.props.sharedReport &&
            isEmpty(this.props.sharedReport.conversationId) && (
              <div>
                {this.commentList('OHS')}
                {this.commentList('OGC')}
                {this.commentList('RO')}
                {this.commentList('Grantee')}
                {this.commentList('')}
              </div>
            )}
          {this.props.sharedReport &&
            isEmpty(this.props.sharedReport.conversationId) && (
              <div
                style={{
                  display: (this.props.sharedReport || {}).commentPeriodExpired
                    ? ''
                    : 'none',
                }}
              >
                Unfortunately you cannot leave a comment. Commenting period has
                expired.
              </div>
            )}
          {this.props.sharedReport &&
            isEmpty(this.props.sharedReport.conversationId) && (
              <div
                style={{
                  display:
                    (this.props.sharedReport || {}).commentPeriodExpired ||
                    this.props.report.confirmedTos
                      ? 'none'
                      : '',
                }}
              >
                {this.state.isBase64Content &&
                (this.props.sharedReport || {}).content ? null : (
                  <h3> Add comment:</h3>
                )}

                <div
                  className="well survey-header-info"
                  style={{
                    display:
                      this.props.sharedReport.isCommented && !this.commented
                        ? ''
                        : 'none',
                    color: '#db2828',
                  }}
                >
                  <div>
                    <span style={{ size: 'large' }}>
                      <i className="fa fa-exclamation-triangle" /> No further
                      action needed
                    </span>
                  </div>
                  <p />
                  <p>
                    You have already submitted feedback on the review report. No
                    further action needed at this time.
                  </p>
                </div>

                <label>Full Name</label>
                <input
                  type="text"
                  onChange={ev => {
                    this.setState({ authorFullName: ev.target.value });
                  }}
                  autoComplete="Off"
                />
                {this.state.isBase64Content &&
                (this.props.sharedReport || {}).content ? (
                  <div>
                    <label htmlFor="confirmed-tos">
                      By checking this box I acknowledge that by typing my name
                      and entering a date I am officially acknowledging the
                      official receipt of the Head Start report above for
                      download.
                    </label>
                    <input
                      type="checkbox"
                      id="confirmed-tos"
                      autoComplete="Off"
                      onChange={(ev => {
                        this.confirmTos(ev.target.checked);
                      }).bind(this)}
                      defaultChecked={false}
                    />
                  </div>
                ) : (
                  <div>
                    <label>Comment</label>
                    <textarea
                      disabled={
                        this.props.sharedReport.isCommented || this.commented
                      }
                      className="textarea-comment"
                      autoComplete="Off"
                      onChange={ev => {
                        this.setState({ commentValue: ev.target.value });
                      }}
                    />
                  </div>
                )}

                {this.state.isBase64Content &&
                (this.props.sharedReport || {}).content ? (
                  ''
                ) : (
                  <div>
                    <input
                      type="checkbox"
                      id="no-comment"
                      autoComplete="Off"
                      disabled={
                        this.props.sharedReport.isCommented ||
                        this.commented ||
                        (this.state.commentValue &&
                          !this.state.commentValue.includes(
                            'Approved with No comments at '
                          ))
                      }
                      onChange={(ev => {
                        let node = document.querySelector('.textarea-comment');
                        if (!node) return;
                        if (ev.target.checked) {
                          node.value = '';
                          this.setState(
                            {
                              commentValue:
                                'Approved with No comments at ' +
                                moment().format('HH:mm'),
                            },
                            () => {
                              node.setAttribute('disabled', 'true');
                            }
                          );
                        } else {
                          this.setState({ commentValue: '' }, () => {
                            if (node.hasAttribute('disabled')) {
                              node.removeAttribute('disabled');
                            }
                          });
                        }
                      }).bind(this)}
                    />
                    <label htmlFor="no-comment">
                      &nbsp;&nbsp;Approve with No Comments
                    </label>
                  </div>
                )}

                {this.state.isBase64Content &&
                (this.props.sharedReport || {}).content ? (
                  <div
                    className="buttons"
                    style={{
                      display: this.props.report.confirmedTos ? 'none' : '',
                    }}
                  >
                    <button
                      className="submit"
                      onClick={(() => {
                        this.sendComment(null, 'Confirm Document Download');
                      }).bind(this)}
                    >
                      Download
                    </button>
                    <button
                      onClick={() => {
                        alert('Please close this window');
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="buttons">
                    <button
                      disabled={
                        this.props.sharedReport.isCommented ||
                        this.commented ||
                        isEmpty(this.state.authorFullName)
                      }
                      style={{
                        cursor:
                          this.props.sharedReport.isCommented ||
                          this.commented ||
                          isEmpty(this.state.authorFullName)
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                      className="submit"
                      onClick={(() => {
                        this.sendComment(null);
                        this.commented = true;
                      }).bind(this)}
                    >
                      Submit
                    </button>
                    <button
                      onClick={() => {
                        alert('Please close this window');
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={(() => {
                        this.props.downloadPdfReport(
                          this.getHashId(),
                          'report'
                        );
                      }).bind(this)}
                    >
                      Download Report
                    </button>
                  </div>
                )}

                {this.state.isBase64Content &&
                (this.props.sharedReport || {}).content ? null : (
                    this.props.sharedReport || {}
                  ).group.toLowerCase() === 'ogc' ||
                  (this.props.sharedReport || {}).group.toLowerCase() ===
                    'ohs' ||
                  true ? (
                  <div className="row">
                    <br />
                    <br />
                    <br />
                    {this.state.filesError
                      ? this.state.filesError.map((data, index) => {
                          return typeof data === 'string' ? (
                            <div className="alert alert-danger">
                              <b>Error:</b> {data}
                            </div>
                          ) : (
                            <div className="alert alert-danger">
                              <b>Error:</b> File "{data.file.name}" -
                              {data.reason}
                            </div>
                          );
                        })
                      : null}

                    {(() => {
                      if (
                        Array.isArray(
                          (this.props.commentFileNames || {}).commentsFileList
                        )
                      ) {
                        return (
                          <div>
                            {this.props.commentFileNames.commentsFileList
                              .map(
                                ((conf, index) => {
                                  let content =
                                    this.props.commentFileContents[
                                      conf.binaryFileName
                                    ] || null;
                                  if (content) {
                                    if (
                                      this.state.downloadedFiles.indexOf(
                                        conf.binaryFileName
                                      ) === -1
                                    ) {
                                      let files =
                                        this.state.downloadedFiles || [];
                                      files.push(conf.binaryFileName);
                                      this.setState({
                                        downloadedFiles: files,
                                      });

                                      this.downloadBigBase64(
                                        conf.displayFileName,
                                        content,
                                        'application/octet-stream'
                                      );
                                    }
                                    return (
                                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                      <a
                                        key={index}
                                        download={conf.displayFileName}
                                        onClick={(ev => {
                                          ev.nativeEvent.preventDefault();
                                          ev.nativeEvent.stopPropagation();

                                          this.downloadBigBase64(
                                            conf.displayFileName,
                                            content,
                                            'application/octet-stream'
                                          );
                                        }).bind(this)}
                                      >
                                        {conf.displayFileName}
                                      </a>
                                    );
                                  } else {
                                    return (
                                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                      <a
                                        key={index}
                                        download={conf.displayFileName}
                                        onClick={(ev => {
                                          ev.nativeEvent.preventDefault();
                                          ev.nativeEvent.stopPropagation();

                                          this.props.fetchFileContents({
                                            hashContent: this.getHashId(),
                                            binaryFileName: conf.binaryFileName,
                                            displayFileName:
                                              conf.displayFileName,
                                          });
                                        }).bind(this)}
                                      >
                                        {conf.displayFileName}
                                      </a>
                                    );
                                  }
                                }).bind(this)
                              )
                              .map((link, index) => {
                                return <div key={index}>{link}</div>;
                              })}
                          </div>
                        );
                      }
                      return null;
                    }).bind(this)()}

                    <div className="uploading-comment-files">
                      {this.state.files.map(
                        ((file, index) => {
                          let _self = this;
                          return (
                            <div className="btn btn-primary selected-file">
                              {file.name}
                              <span
                                className="selected-file--close"
                                onClick={() => {
                                  _self.setState({
                                    files: _self.state.files.filter((f, i) => {
                                      return i !== index;
                                    }),
                                  });
                                }}
                              >
                                x
                              </span>
                            </div>
                          );
                        }).bind(this)
                      )}
                    </div>

                    {(this.props.sharedReport || {}).group.toLowerCase() ===
                      'ogc' ||
                    (this.props.sharedReport || {}).group.toLowerCase() ===
                      'ohs' ? (
                      <div className="buttons">
                        &nbsp;
                        <button
                          className="btn btn-primary"
                          onClick={this.addFiles}
                        >
                          Select files for upload
                        </button>
                        &nbsp;
                        <button
                          style={{
                            display: this.state.files.length ? '' : 'none',
                            cursor:
                              isEmpty(this.state.authorFullName) ||
                              isEmpty(this.state.commentValue)
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                          disabled={
                            isEmpty(this.state.authorFullName) ||
                            isEmpty(this.state.commentValue)
                          }
                          className="btn btn-primary"
                          onClick={(() => {
                            if (
                              this.state.files.length &&
                              window.confirm(
                                'Do you want to proceed with upload of ' +
                                  this.state.files.length +
                                  ' file(s)?'
                              )
                            ) {
                              this.uploadFiles();
                            }
                          }).bind(this)}
                        >
                          Upload Files
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            )}
        </div>
      </>
    );
  }
}

ReportComment.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    commentFilesUploaded: state.report.commentFilesUploaded,
    commentFileNames: state.report.commentFileNames,
    commentFileContents: state.report.commentFileContents,
    sharedReport: state.report.sharedReport,
    users: state.users,
    report: state.report,
  };
}

export default connect(mapStateToProps, {
  uploadCommentFiles,
  fetchCommentFileNames,
  fetchFileContents,
  downloadPdfReport,
  fetchReportContent,
  reportCommentUsers,
  sendComment,
})(ReportComment);
