import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

// Import actions
import { fetchTasks } from '../../actions/taskActions';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

class TaskTable extends Component {
  componentWillMount() {
    const { currentUser } = this.props;

    if (currentUser.oid) {
      this.props.fetchTasks(currentUser.oid);
    }
  }

  dateFormatter(cell, row) {
    return AmsDateFormatters.formatDate(cell);
  }

  handleRowClick(row) {
    window.location.href = `${process.env.REACT_APP_API_URI}/amsweb/task/complete/${row.id}`;
  }

  prepareTaskList() {
    let { tasks } = this.props;

    tasks = _.orderBy(tasks, ['priority', 'startDate'], ['desc', 'asc']);

    const options = {
      onRowClick: this.handleRowClick,
    };

    return (
      <BootstrapTable
        trClassName="clickable"
        data={tasks}
        options={options}
        tableContainerClass="table-container"
        headerContainerClass="table-header"
        hover
        condensed
        pagination
        bordered={false}
      >
        <TableHeaderColumn dataField="priority" dataSort={true}>
          Priority
        </TableHeaderColumn>
        <TableHeaderColumn dataField="id" isKey={true} dataSort={true}>
          Task ID
        </TableHeaderColumn>
        <TableHeaderColumn dataField="name" dataSort={true}>
          Description
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="startDate"
          dataSort={true}
          dataFormat={this.dateFormatter}
        >
          Start Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="endDate"
          dataSort={true}
          dataFormat={this.dateFormatter}
        >
          End Date
        </TableHeaderColumn>
        <TableHeaderColumn dataField="taskOrginator" dataSort={true}>
          {' '}
          Reporter{' '}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  render() {
    return (
      <div className="panel panel-white">
        <div className="panel-body--old">{this.prepareTaskList()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.task,
    currentUser: state.auth.user,
  };
}

TaskTable.propTypes = {
  tasks: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchTasks: PropTypes.func.isRequired,
};

TaskTable.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { fetchTasks })(TaskTable);
