import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form as SemanticForm, Segment } from 'semantic-ui-react';

// Import components.
import FollowUpAttachmentField from './FollowUpAttachmentField';

class FollowUpCitationsForm extends Component {
  state = {
    formData: {},
    furRecommendations: [],
  };

  componentWillReceiveProps(nextProps) {
    const { surveyData, formDisabled, formDisableClassName } = nextProps;
    this.setState({
      surveyData,
      formDisabled,
      formDisableClassName,
    });
  }

  handleAttachmentChange = (furRecommendation, index, { formData }) => {
    let formattedData = {
      name: 'evidences',
      value: formData.evidenceFiles,
    };
    this.props.buildFormData(formattedData, index, 'furRecommendations');
  };

  handleTextChange = (e, data, index) => {
    this.props.buildFormData(data, index, 'furRecommendations');
  };

  handleRadioButtonChange = (e, data, index) => {
    let formattedData = {
      name: data.name,
      value: data.value,
    };
    this.props.buildFormData(formattedData, index, 'furRecommendations');
  };

  renderForm = () => {
    const { formDisabled } = this.state;

    const { followUpTaskDetail, surveyData, selectedSubmission } = this.props;

    return (
      <div className={`ui form`}>
        {followUpTaskDetail &&
          followUpTaskDetail.FollowInfo &&
          surveyData &&
          surveyData.furRecommendations &&
          surveyData.furRecommendations.map((furRecommendation, index) => {
            const formattedEvidences =
              furRecommendation &&
              furRecommendation.evidences &&
              // eslint-disable-next-line array-callback-return
              furRecommendation.evidences.filter((evidence, index) => {
                if (evidence) {
                  if (evidence.includes('data:')) return evidence;
                }
              });

            return (
              <div key={index}>
                <Segment>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-6">
                          <span
                            role="heading"
                            aria-level="3"
                            className="field-title"
                            aria-label="Citation Number"
                          >
                            Citation Number :{' '}
                          </span>

                          {furRecommendation.associatedCitations || ''}
                        </div>
                        <div className="col-sm-6">
                          <span
                            role="heading"
                            aria-level="4"
                            className="field-title"
                            aria-label="Finding Type"
                          >
                            Finding Type :{' '}
                          </span>

                          {(selectedSubmission &&
                          selectedSubmission.amsSubmissionId
                            ? furRecommendation.prevFindingsType
                            : furRecommendation.findingsType) || ''}
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ whiteSpace: 'pre-wrap' }}>
                        <div className="col-sm-12">
                          <span
                            role="heading"
                            aria-level="4"
                            className="field-title"
                            aria-label="Citation Narrative"
                          >
                            Citation Narrative :{' '}
                          </span>
                          {furRecommendation.citationNarrative || ''}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <SemanticForm.Field
                    htmlFor={`corrected-radio-${index}`}
                    className={'field required'}
                    label={'Did the Grantee correct the citation?'}
                  />
                  <SemanticForm.Group className={'corrected-citations'}>
                    <SemanticForm.Checkbox
                      radio
                      id={`corrected-radio-${index}`}
                      name={'granteeCorrectedCitationResponse'}
                      label="Yes"
                      value="true"
                      checked={
                        furRecommendation &&
                        furRecommendation.granteeCorrectedCitationResponse ===
                          'true'
                      }
                      onChange={(e, data) =>
                        this.handleRadioButtonChange(e, data, index)
                      }
                    />
                    <SemanticForm.Checkbox
                      radio
                      aria-labelledby={`corrected-radio-${index}`}
                      name={'granteeCorrectedCitationResponse'}
                      label="No"
                      value="false"
                      checked={
                        furRecommendation &&
                        furRecommendation.granteeCorrectedCitationResponse ===
                          'false'
                      }
                      onChange={(e, data) =>
                        this.handleRadioButtonChange(e, data, index)
                      }
                    />
                    <SemanticForm.Checkbox
                      radio
                      aria-labelledby={`corrected-radio-${index}`}
                      name={'granteeCorrectedCitationResponse'}
                      label="Withdraw"
                      value="withdraw"
                      checked={
                        furRecommendation &&
                        furRecommendation.granteeCorrectedCitationResponse ===
                          'withdraw'
                      }
                      onChange={(e, data) =>
                        this.handleRadioButtonChange(e, data, index)
                      }
                    />
                  </SemanticForm.Group>
                  <label
                    htmlFor={`explain-textarea${index}`}
                  >{`Please explain your response ${furRecommendation.associatedCitations}`}</label>
                  <SemanticForm.TextArea
                    required
                    id={`explain-textarea${index}`}
                    className={'corrected-citation-reason'}
                    name="correctedCitationReason"
                    value={
                      (furRecommendation &&
                        furRecommendation.correctedCitationReason) ||
                      ''
                    }
                    onChange={(e, data) =>
                      this.handleTextChange(e, data, index)
                    }
                    disabled={formDisabled}
                  />

                  <FollowUpAttachmentField
                    furRecommendation={furRecommendation}
                    index={index}
                    onChange={this.handleAttachmentChange}
                    evidences={formattedEvidences}
                    formName={'furRecommendations'}
                    formDisabled={formDisabled}
                  />

                  {/* <hr /> */}
                </Segment>
                <br />
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    const { followUpTaskDetail } = this.props;

    if (!followUpTaskDetail) return null;

    return <div className="citation-form-container">{this.renderForm()}</div>;
  }
}

FollowUpCitationsForm.propTypes = {
  followUpTaskDetail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  followUpTaskDetail: state.submissions.followUpTaskDetail,
  amsForm: state.submissions.AMSForm,
  selectedSubmission: state.submissions.selectedSubmission,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {})(FollowUpCitationsForm);
