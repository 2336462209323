import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Menu } from 'semantic-ui-react';

const ReviewOCISummary = ({ selectedReview }) => {
  if (!selectedReview) return null;

  const fiscalYear =
    selectedReview.fiscalYear && selectedReview.fiscalYear.toString().slice(-2);

  return selectedReview &&
    (selectedReview.reviewType === 'FA-1' ||
      selectedReview.reviewType === 'FA1-FR' ||
      selectedReview.reviewType === 'FA-2' ||
      selectedReview.reviewType === 'FA2-CR' ||
      selectedReview.reviewType === 'ERSEA') &&
    selectedReview.fiscalYear >= 2020 &&
    selectedReview.fiscalYear < 2023 ? (
    <Menu.Item
      as={Link}
      to={`/review/fy${fiscalYear}/${selectedReview.reviewId}/performance-rating-summary`}
    >
      Performance Rating Summary
    </Menu.Item>
  ) : null;
};

ReviewOCISummary.propTypes = {
  selectedReview: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewOCISummary);
