import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import AmsTable from '../../../../utils/AmsTable';

// Import actions
import {
  fetchSurveySubmissions,
  fetchFUReviewSubmissions,
  clearReviewSubmissions,
} from '../../../../actions/fy19/reviewActions';
import { fa2GuideSelected } from '../../../../actions/surveyActions';

// Import configs.
import SurveySubmissionsFilters from './SurveySubmissionsFilters';

// Import utils
import { reviewTypeFormat } from '../../../../utils/TextFormatters';

const SurveySubmissionsList = props => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ name: '', status: '' });
  const dispatch = useDispatch();
  const selectedReview = useSelector(state => state.review.selectedReview);
  const surveySubmissions = useSelector(state => state.reviewSubmissions.list);
  const [reviewSubmissionDtls, setReviewSubmissionDtls] = useState([]);
  const [errors, setErrors] = useState({});
  const newFormReviewTypes = ['RAN', 'Special'];

  useEffect(() => {
    loadData();
    return () => {
      dispatch(clearReviewSubmissions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReviewSubmissionDtls(
      surveySubmissions?.surveyDetails?.map((item, index) => ({
        index,
        ...item,
      }))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveySubmissions]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const loadData = () => {
    const reviewId = props.selectedReview.reviewId;

    if (isEmpty(reviewId)) return null;
    if (reviewId !== props.params.reviewId) {
      return null;
    }

    setLoading(true);

    if (selectedReview && selectedReview.reviewType === 'Follow-up') {
      dispatch(fetchFUReviewSubmissions({ filters: { reviewId } }))
        .then(() => setLoading(false))
        .catch(error => {
          setLoading(false);
          setErrors({
            ...errors,
            fetchSurveySubmissions:
              (error.response && error.response.data.message) || '',
          });
        });
    } else {
      dispatch(fetchSurveySubmissions({ reviewId, filters }))
        .then(() => setLoading(false))
        .catch(error => {
          setLoading(false);
          setErrors({
            ...errors,
            fetchSurveySubmissions:
              (error.response && error.response.data.message) || '',
          });
        });
    }
  };

  const handleFilterClicked = ({ name, status }) => {
    setFilters({ name, status });
  };

  const renderTable = () => {
    const reviewId = props.selectedReview.reviewId;

    if (reviewId !== selectedReview.reviewId) {
      return null;
    }

    let reviewType = selectedReview?.reviewType || '';
    reviewType = reviewTypeFormat(reviewType);

    const formatLink = (cell, row) => {
      const reviewId = props.selectedReview.reviewId;

      if (row?.questionIds?.length > 0) {
        return (
          <Link
            to={{
              pathname: `/review/fy${selectedReview.fiscalYear
                .toString()
                .slice(-2)}/${selectedReview.reviewId}/survey/${reviewType}`,
              query: {
                g: encodeURIComponent(row.name),
              },
            }}
          >
            {cell}
          </Link>
        );
      }

      return (
        <Link
          aria-label={row?.performanceArea[0]}
          to={{
            pathname: `/task/${reviewId}/survey/${reviewType}`,
            query: {
              g: encodeURIComponent(row.name),
              pm: encodeURIComponent(row?.performanceArea[0]),
            },
          }}
        >
          {cell}
        </Link>
      );
    };

    const formatStatus = (cell, row) => {
      if (cell === 'Completed') return <Icon name="check" />;
      return cell;
    };

    const columns = [
      {
        formatter: formatLink,
        dataField: 'name',
        text: 'Guide Name',
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      { dataField: 'totalForms', text: 'Total Forms', sort: true },
      { dataField: 'formsCompleted', text: 'Forms Completed', sort: true },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: formatStatus,
      },
    ];

    return (
      <div tabIndex="-1">
        <AmsTable
          data={
            selectedReview && selectedReview.reviewType
              ? reviewSubmissionDtls
              : null
          }
          remote={false}
          total={reviewSubmissionDtls?.length}
          keyField="amsFormId"
          columns={columns}
          loading={loading}
          basic={
            selectedReview &&
            newFormReviewTypes.includes(selectedReview.reviewType) &&
            !selectedReview.isNewForms
          }
          sizePerPageOption={
            selectedReview &&
            newFormReviewTypes.includes(selectedReview.reviewType) &&
            !selectedReview.isNewForms
          }
          paginationTotalHeaderSize="h3"
        />
      </div>
    );
  };

  if (selectedReview.reviewType === 'Follow-up') {
    return null;
  }

  return (
    <>
      <SurveySubmissionsFilters onFilterClicked={handleFilterClicked} />
      {renderTable()}
    </>
  );
};

SurveySubmissionsList.propTypes = {
  selectedReview: PropTypes.object.isRequired,
  surveySubmissions: PropTypes.array.isRequired,
  fetchSurveySubmissions: PropTypes.func.isRequired,
  fa2GuideSelected: PropTypes.func.isRequired,
};

export default withRouter(SurveySubmissionsList);
