import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Message } from 'semantic-ui-react';

class GranteeCalendarSubmitted extends Component {
  showTabsAgain = () => {
    this.props.showTabsAgain();
  };

  renderSubmittedDetails() {
    let { showSubmitMessage } = this.props;
    return showSubmitMessage ? (
      <div>
        <Message
          header="The changes you have made have been submitted."
          content="You cannot make anymore changes to your unavailability. If you would like to view your unavailability now please click the View Unavailability button below."
        />
        <Button color="blue" onClick={this.showTabsAgain}>
          View Unavailability
        </Button>
      </div>
    ) : (
      <div></div>
    );
  }

  render() {
    return <div> {this.renderSubmittedDetails()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarSubmitted);
