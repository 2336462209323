import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Message,
  Modal,
  TextArea,
} from 'semantic-ui-react';

// Import component
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsModal from '../../utils/AmsModal';
import AmsTable from '../../utils/AmsTable';

//Import actions
import {
  fetchExternalUsers,
  reportShareMultipleAction,
} from '../../actions/reportActions';

class ReportItems extends Component {
  state = {
    loading: false,
    sending: false,
    reports: [],
    page: 1,
    limit: 25,
    sortName: 'dueDate',
    sortOrder: 'asc',
    filters: {},
    showModal: false,
    selectedReports: [],
    externalUserOptions: [],
    modalErrors: {},
    confirmationDialogData: {},
    showConfirmationDialog: false,
    nonSelectableReports: [],
    modalData: {
      message: '',
      shareExpirationDate: null,
      sharedExternal: [],
      sharedInternal: [],
      sharedInternalData: [],
    },
  };

  constructor(props) {
    super(props);
    this.amsTableRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, state) {
    return { ...state, ...nextProps };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (prevProps.externalUsers.length !== this.props.externalUsers.length) {
      this.setState({
        ...this.state,
        externalUserOptions: this.getEmailToOptions(this.props.externalUsers),
      });
    }
  }

  dateFormatter = cell => {
    return moment(cell).format('MM-DD-YYYY');
  };

  fetchModalData = () => {
    if (this.props.externalUsers.length === 0) {
      const reviewToUse = this.state.selectedReports[0];
      this.props.fetchExternalUsers({
        group: 'OHS',
        reviewId: reviewToUse.reviewId,
        reviewType: reviewToUse.reviewType,
      });
    }
  };

  getData = () => {
    const { page, limit, filters, sortName, sortOrder } = this.state;
    let input = {
      filters,
      pageNum: page,
      pageSize: limit,
      orderBy: sortName,
      sortOrder,
    };
    this.setState({ ...this.state, loading: true });

    this.props
      .fetchReports(input)
      .then(() => {
        this.setState({ ...this.state, loading: false });
      })
      .catch(() => {
        this.setState({ ...this.state, loading: false });
      });
  };

  assigneeFormatter = (cell, row) => {
    let assignee = {};
    assignee.name = 'None';
    if (this.props.users.users) {
      assignee = this.props.users.users.find(user => {
        return user.oid === cell;
      });
      if (!assignee && row.assigneeName) {
        assignee = {};
        assignee.name = row.assigneeName;
      }
    }

    return <>{assignee ? assignee.name : 'None'}</>;
  };

  teamStatusFormatter = (cell, row) => {
    return (
      <AmsLookupDropdown
        value={row.reportStage}
        fluid
        onChange={(e, { value }) => {
          this.props.actions.teamStatusUpdate(value, cell, row);
        }}
        selection
        category={'reportStages'}
      />
    );
  };

  daysLeftFormatter = cell => {
    return cell;
  };

  linkId = cell => {
    return <Link href={`/reports/${cell}`}>{cell}</Link>;
  };

  /*
  renderReportsTable() {
    const fetchInfo = {
      dataTotalSize: this.props.total,
    };
    const options = {
      sizePerPage: this.state.pageSize,
      onPageChange: this.onPageChange,
      onSortChange: this.onSortChange,
      sortName: this.state.orderBy,
      sortOrder: this.state.sortOrder,
      page: this.state.pageNum,
      paginationShowsTotal: this.renderPaginationShowsTotal,
      hidePageListOnlyOnePage: true,
    };
    let data;
    if (this.props.reports) {
      data = this.props.reports.map(item => ({
        ...item,
        granteeId: item.granteeIdList
          ? ((item.granteeIdList[0] || {}).granteeId || '').trim()
          : '',
        granteeName: item.granteeIdList
          ? ((item.granteeIdList[0] || {}).granteeName || '').trim()
          : '',
      }));
    }
    if (this.props.reports) {
      return this.state.loading ? (
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      ) : (
        <BootstrapTable
          trClassName="clickable"
          condensed
          remote
          data={data}
          tableContainerClass="table-container"
          headerContainerClass="table-header"
          hover
          bordered={false}
          options={options}
          fetchInfo={fetchInfo}
          pagination
        >
          <TableHeaderColumn
            dataField="reviewId"
            dataFormat={this.linkId}
            isKey={true}
            dataSort={true}
          >
            {' '}
            Review ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="granteeId" dataSort={true}>
            Grantee ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="granteeName" dataSort={true}>
            Grantee Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="reviewType" dataSort={true}>
            Review Type
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="dueDate"
            dataFormat={this.dateFormatter}
            dataSort={true}
          >
            Due Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="assignee"
            dataFormat={this.assigneeFormatter}
            dataSort={true}
          >
            Assignee
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataSort={true}>
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="numberOfDaysTheReportWithAssignee"
            dataSort={true}
          >
            # of days Report is with Assignee
          </TableHeaderColumn>
          <TableHeaderColumn dataField="outCome" dataSort={true}>
            Outcome
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="daysLeft"
            dataFormat={this.daysLeftFormatter}
            dataSort={true}
          >
            Days Left to due date
          </TableHeaderColumn>
        </BootstrapTable>
      );
    } else {
      return null;
    }
  }*/

  findReportInSelectedReports = report => {
    const { selectedReports } = this.state;
    return selectedReports.findIndex(el => el.reviewId === report.reviewId);
  };

  selectReport = (row, isSelect) => {
    const { selectedReports } = this.state;

    if (isSelect) {
      if (this.canReportBeShared(row)) selectedReports.push(row);
    } else {
      selectedReports.splice(this.findReportInSelectedReports(row), 1);
    }
    this.setState({ ...this.state, selectedReports: selectedReports });
  };

  selectReports = (isSelect, rows) => {
    let rowsSelected = [];
    let selectedRows = [];
    if (isSelect) {
      for (let k = 0; k < rows.length; k++) {
        if (this.canReportBeShared(rows[k])) {
          rowsSelected.push(rows[k].reviewId);
          selectedRows.push(rows[k]);
        }
      }

      this.setState({
        ...this.state,
        selectedReports: selectedRows,
      });
    } else {
      this.setState({
        ...this.state,
        selectedReports: [],
      });
    }
    return rowsSelected;
  };

  getEmailToOptions(input) {
    const toReturn = input.map(item => {
      return {
        text:
          item.firstName +
          ' ' +
          item.lastName +
          ', ' +
          item.group +
          ', ' +
          item.region,
        value: { email: item.email, oid: item.oid },
        key: item.email,
      };
    });

    return toReturn;
  }

  canReportBeShared = report => {
    return !(
      report.reviewStatus === 'Shipped' ||
      report.reviewStatus === 'Report Signed' ||
      report.reportFinalized
    );
  };

  renderReportsTableNext = () => {
    const { limit } = this.state;

    const total = this.props.total;

    let reports = [];

    const dateFormatter = cell => {
      return AmsDateFormatters.formatDate(cell);
    };

    const linkId = (cell, row) => {
      return (
        <Link
          to={{
            pathname: `/reports/${cell}`,
            state: { reviewType: `${row.reviewType}` },
          }}
        >
          {cell}
        </Link>
      );
    };

    if (this.props.reports) {
      reports = this.props.reports.map(item => ({
        ...item,
        granteeId: item.granteeIdList
          ? ((item.granteeIdList[0] || {}).granteeId || '').trim()
          : '',
        granteeName: item.granteeIdList
          ? ((item.granteeIdList[0] || {}).granteeName || '').trim()
          : '',
      }));
    }
    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.selectReport,
      onSelectAll: this.selectReports,
      unselectable: this.state.nonSelectableReports,
      selected: this.state.selectedReports.map(e => e.reviewId),
      selectionHeaderRenderer: ({ mode, ...rest }) => {
        return (
          <>
            <span className="visually-hidden">Select All</span>
            <input
              type={mode}
              ref={input => {
                if (input) {
                  if (
                    this.state.selectedReports.length > 0 &&
                    this.state.selectedReports.length !== limit &&
                    this.state.selectedReports.length !==
                      this.state.reports.length
                  ) {
                    input.indeterminate = true;
                  }
                }
              }}
              title="Select All Reports on Page to Add to List of Multiple Report Sharing for OHS Director"
              {...rest}
            />
          </>
        );
      },
      selectionRenderer: ({ mode, ...rest }) => {
        rest.disabled = !this.canReportBeShared(
          this.props.reports[rest.rowIndex]
        );

        return (
          <input
            type={mode}
            title="Select Report to Add to List of Multiple Report Sharing for OHS Director"
            {...rest}
          />
        );
      },
    };

    const columns = [
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      {
        dataField: 'granteeId',
        text: 'Grantee ID',
        sort: true,
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'dueDate',
        text: 'Due Date',
        formatter: dateFormatter,
        sort: true,
      },
      {
        dataField: 'assignee',
        text: 'Assignee',
        formatter: this.assigneeFormatter,
        sort: true,
      },
      {
        dataField: 'numberOfDaysTheReportWithAssignee',
        text: '# of days Report is with Assignee',
        sort: true,
      },
      {
        dataField: 'outCome',
        text: 'Outcome',
        sort: true,
      },
      {
        dataField: 'reviewStatus',
        text: 'Review Status',
        sort: true,
      },
      {
        dataField: 'daysLeft',
        text: 'Days Left to due date',
        formatter: this.daysLeftFormatter,
        sort: true,
      },
    ];

    const scrollToRef = () => {
      window.scrollTo(0, this.amsTableRef.current.offsetTop);
      this.amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, pageNum) => {
      this.setState(
        { ...this.state, page: pageNum, limit: sizePerPage },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onPageChange = (pageNum, sizePerPage) => {
      this.setState(
        {
          ...this.state,
          page: pageNum,
          limit: sizePerPage,
        },
        () => {
          this.getData();
          this.props.updatePageSize(sizePerPage);

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === this.state.sortName &&
        sortOrder === this.state.sortOrder
      )
        return null;

      if (type === 'sort') {
        this.setState(
          {
            ...this.state,
            sortName: sortField || this.state.sortName,
            sortOrder: sortOrder || this.state.sortOrder,
          },
          () => {
            this.getData();
          }
        );
      }
    };

    const defaultSorted = [
      {
        dataField: this.state.sortName,
        order: this.state.sortOrder,
      },
    ];

    return (
      <div ref={this.amsTableRef} tabIndex="-1">
        <div className="all-findings-wrapper">
          <Button
            style={{ float: 'right' }}
            disabled={this.state.selectedReports.length === 0}
            onClick={() => {
              this.setState({ ...this.state, showModal: true });
              this.fetchModalData();
            }}
          >
            Share with OHS
          </Button>
        </div>
        <AmsTable
          defaultSorted={defaultSorted}
          page={this.state.page}
          total={total}
          selectRow={selectRowProp}
          limit={limit}
          loading={this.state.loading}
          keyField="reviewId"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          data={reports}
          columns={columns}
        />
      </div>
    );
  };

  shareReportForm() {
    const { modalData } = this.state;

    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <AmsFormLabel
              name="Share with DLH"
              helpText={true}
              fieldLabel="shareWithDLH"
            >
              <AmsLookupDropdown
                control={AmsLookupDropdown}
                category={'multipleReportDlhUsers'}
                placeholder="Share with DLH"
                value={this.state.modalData.sharedInternal}
                onChange={(e, data) => {
                  //setSharedInternalUsers(value);

                  const sharedInternalData = data.options.filter(option => {
                    return data.value.includes(option.value);
                  });

                  this.setState({
                    ...this.state,
                    modalData: {
                      ...this.state.modalData,
                      sharedInternal: data.value,
                      sharedInternalData: sharedInternalData,
                    },
                  });
                }}
                fluid
                multiple
                search
                selection
                // clearable
                id="shareWithDLH"
                aria-labelledby="shareWithDLH"
                searchInput={{
                  id: 'shareWithDLHSearchInput',
                  title: 'search share with DLH',
                  'aria-labelledby': 'shareWithDLHSearchInput',
                }}
                openOnFocus={false}
              />
            </AmsFormLabel>
          </Form.Field>
          <Form.Field required>
            <AmsFormLabel
              name={`Share with OHS`}
              helpText={true}
              fieldLabel={`shareWithOHS`}
            >
              <Dropdown
                placeholder={`Share with OHS`}
                options={this.state.externalUserOptions}
                value={this.state.modalData.sharedExternal}
                onChange={(e, { value }) => {
                  this.setState({
                    ...this.state,
                    modalData: {
                      ...this.state.modalData,
                      sharedExternal: value,
                    },
                  });
                }}
                fluid
                multiple
                search
                selection
                // clearable
                id={`shareWithOHS`}
                aria-labelledby={`shareWithOHS`}
                searchInput={{
                  id: `shareWithOHSSearchInput`,
                  title: `search share with OHS`,
                  'aria-labelledby': `shareWithOHSSearchInput`,
                }}
                openOnFocus={false}
              />
            </AmsFormLabel>
          </Form.Field>
        </Form.Group>

        <Form.Field
          required
          // isClearable
          selectsStart
          control={DatePicker}
          dateFormat="YYYY-MM-DD"
          placeholderText="Select Expiration Date"
          selected={
            modalData.shareExpirationDate &&
            AmsDateFormatters.getMoment(modalData.shareExpirationDate)
          }
          fromDate={new AmsDateFormatters.getMoment()}
          onChange={date => {
            let newDate = date
              ? AmsDateFormatters.getMoment(date).format('L')
              : null;

            this.setState({
              ...this.state,
              modalData: {
                ...modalData,
                shareExpirationDate: newDate,
              },
            });
          }}
          minDate={new AmsDateFormatters.getMoment()}
          value={
            modalData.shareExpirationDate ? modalData.shareExpirationDate : ''
          }
          popperPlacement="top-start"
          id="expirationDate"
          aria-labelledby="expirationDate"
          label={{
            children: 'Expiration Date',
            htmlFor: 'expirationDate',
          }}
        ></Form.Field>
        <Form.Field
          control={TextArea}
          rows="6"
          placeholder="your message ..."
          value={this.entityToChar(modalData.message, 'textarea')}
          onChange={e => {
            this.setState({
              ...this.state,
              modalData: {
                ...modalData,
                message: e.target.value,
              },
            });
          }}
          id="message"
          aria-labelledby="message"
          label={{
            children: 'Message',
            htmlFor: 'message',
          }}
        ></Form.Field>
      </Form>
    );
  }

  entityToChar(str, elm) {
    const el = document.createElement(elm);
    el.innerHTML = str;
    return el.value;
  }

  renderConfirmationDialog = () => {
    const { title, message } = this.state.confirmationDialogData;
    return (
      <AmsAlert
        show={this.state.showConfirmationDialog}
        title={title}
        type="success"
        showConfirm
        text={message}
        onConfirm={() => {
          this.setState({
            ...this.state,
            confirmationDialogData: {},
            showConfirmationDialog: false,
          });
        }}
      />
    );
  };

  generateModalAlert = () => {
    if (!isEmpty(this.state.modalErrors)) {
      return (
        <Message
          negative
          icon={'cancel'}
          header={'Something went wrong!'}
          content={`${this.state.modalErrors}`}
        ></Message>
      );
    }
  };

  renderShareModal() {
    const { modalData } = this.state;
    return (
      <AmsModal
        size="medium"
        closeOnDimmerClick={false}
        open={this.state.showModal}
        className="ams-semantic-modal-fix"
      >
        <Header as="h2" content="Share with OHS" />
        {this.generateModalAlert()}
        <Modal.Content>
          <Modal.Description>{this.shareReportForm()}</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({
                ...this.state,
                showModal: false,
                modalErrors: {},
                modalData: {
                  message: '',
                  shareExpirationDate: null,
                  sharedExternal: [],
                  sharedInternal: [],
                  sharedInternalData: [],
                },
              });
            }}
            content="Close"
          />
          <Button
            onClick={() => {
              this.submitShareForm();
            }}
            primary
            disabled={
              !modalData.shareExpirationDate ||
              isEmpty(modalData.sharedExternal) ||
              this.state.sending
            }
            content="Send"
          />
        </Modal.Actions>
      </AmsModal>
    );
  }

  submitShareForm() {
    this.setState({ ...this.state, modalErrors: {}, sending: true });
    const { modalData } = this.state;
    const reviewList = this.state.selectedReports.map(review => {
      return {
        reviewID: review.reviewId,
        reportID: review.reportId,
      };
    });
    let input = {
      sharedGroup: 'OHS',
      sharedExternal: modalData.sharedExternal,
      sharedInternal: modalData.sharedInternalData.map(e => {
        return e.value;
      }),
      reviewList,
      message: modalData.message,
      sharedDate: new Date().toISOString(),
      expirationDate: modalData.shareExpirationDate,
      sharedBy: this.props.currentUser.email,
    };

    this.props.reportShareMultipleAction(input, result => {
      if (result instanceof Error) {
        this.setState({
          ...this.state,
          sending: false,
          modalErrors: `Error: ${result.response.data.message ||
            'unknown error'}`,
        });
      } else {
        const alertMsg =
          reviewList.length === 1
            ? 'The report has been shared.'
            : 'The reports have been shared.';
        this.getData();
        this.setState({
          ...this.state,
          sending: false,
          showConfirmationDialog: true,
          showModal: false,
          selectedReports: [],
          confirmationDialogData: {
            title: 'Success',
            message: alertMsg,
          },
          modalData: {
            message: '',
            shareExpirationDate: null,
            sharedExternal: [],
            sharedInternal: [],
            sharedInternalData: [],
          },
        });
      }
    });
  }

  render() {
    return (
      <>
        {/* {this.renderReportsTable()} */}
        {this.renderShareModal()}
        {this.renderConfirmationDialog()}
        {this.renderReportsTableNext()}
      </>
    );
  }
}

ReportItems.propTypes = {
  reports: PropTypes.array.isRequired,
  lookups: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    externalUsers: (state.report || {}).externalUsers,
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  reportShareMultipleAction,
  fetchExternalUsers,
})(ReportItems);
