import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

class ScheduleViewGranteeCalendarListItem extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    const { dates, month } = this.props;

    let data = [];

    dates.forEach(date => {
      let formatedDate = AmsDateFormatters.formatDate(date);
      if (formatedDate.startsWith(month)) {
        data.push(formatedDate);
      }
    });

    this.setState({ data: data });
  }

  render() {
    const { data } = this.state;
    const listItems = data.map(d => <List.Item key={d}>{d}</List.Item>);
    return <List>{listItems}</List>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(ScheduleViewGranteeCalendarListItem);
