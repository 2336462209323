import React, { useState } from 'react';
import { slice, chunk, take } from 'lodash';
import { Link } from 'react-router';
import {
  Header,
  Modal,
  Accordion,
  Icon,
  Table,
  Button,
} from 'semantic-ui-react';

// Import utils.
import AmsModal from '../../utils/AmsModal';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

const CurrentSelectedDates = ({ allDates }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, { index, dateType }) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const buildCellDates = (month, type) => {
    // eslint-disable-next-line array-callback-return
    return allDates[type].map(date => {
      if (AmsDateFormatters.getMoment(date).format('MMMM') === month)
        return (
          <>
            {AmsDateFormatters.formatDate(date)}
            <br />
          </>
        );
    });
  };

  const buildAccordion = () => {
    const orderedMonths = AmsDateFormatters.getMonthsList();
    const slicedMonths = slice(orderedMonths, 9, 13);
    const restOfMonths = take(orderedMonths, 9);
    const fiscalOrderedMonths = [...slicedMonths, ...restOfMonths];
    const columnHeaders = chunk(fiscalOrderedMonths, 4);
    const dateTypes = [
      { text: 'Unavailable Dates Selected', dateType: 'unavailableDates' },
      { text: 'Not in Session Dates Selected', dateType: 'notInSessionDates' },
      { text: 'Spring Break Dates Selected', dateType: 'springBreakDates' },
    ];
    return (
      <AmsModal
        size="tiny"
        className="ams-semantic-modal-fix"
        open={showModal}
        closeIcon
        onClose={() => {
          setShowModal(false);
          setActiveIndex(0);
        }}
        closeOnDimmerClick={false}
      >
        <Header>Currently Selected Dates</Header>
        <Modal.Content style={{ textAlign: 'center' }}>
          <Accordion styled fluid style={{ textAlign: 'left' }}>
            {dateTypes.map((type, index) => {
              return (
                <>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    type={type.dateType}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    {type.text}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    <Table>
                      {columnHeaders.map((headers, hindex) => {
                        return (
                          <>
                            <Table.Header>
                              <Table.Row>
                                {headers.map((month, hcindex) => {
                                  return (
                                    <Table.HeaderCell
                                      key={`${hindex}-${hcindex}`}
                                    >
                                      {month}
                                    </Table.HeaderCell>
                                  );
                                })}
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                {headers.map((month, cindex) => {
                                  return (
                                    <Table.Cell key={`${hindex}-${cindex}`}>
                                      {buildCellDates(month, type.dateType)}
                                    </Table.Cell>
                                  );
                                })}
                              </Table.Row>
                            </Table.Body>
                          </>
                        );
                      })}
                    </Table>
                  </Accordion.Content>
                </>
              );
            })}
          </Accordion>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={'Close'}
            // fluid
            basic
            onClick={() => setShowModal(false)}
            // floated="right"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  return (
    <>
      {buildAccordion()}
      <Button
        basic
        color="blue"
        size="medium"
        as={Link}
        content="View Currently Selected Dates"
        onClick={() => setShowModal(true)}
      />
    </>
  );
};

export default CurrentSelectedDates;
