import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Menu, Modal, Popup } from 'semantic-ui-react';

import { fetchAmsLookup } from '../actions/lookupActions';
import AmsModal from '../utils/AmsModal';

import './layout/assets/style.css';

class AmsHelpText extends React.Component {
  state = { modalOpen: false };

  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    const { fetchAmsLookup, amsLookups } = this.props;
    const category = this.props.category || 'helpText';

    if (category) {
      if (isEmpty(amsLookups[category]))
        fetchAmsLookup(category)
          .then()
          .catch(error => {
            if (this.props.setError)
              this.props.setError({
                responseError: error.response.data.message,
              });
          });
    }
  };

  renderPageHelp = data => {
    const handleModalOpen = () => this.setState({ modalOpen: true });

    const handleModalClose = () => this.setState({ modalOpen: false });

    return (
      <AmsModal
        closeIcon
        trigger={
          // <Menu.Menu position="right">
          //   <Menu.Item
          //     color="green"
          //     icon="question circle"
          //     content="Help"
          //     onClick={handleModalOpen}
          //   />
          // </Menu.Menu>
          // <Button
          //   basic
          //   compact
          //   size="tiny"
          //   icon="question circle"
          //   content="Help"
          //   onClick={handleModalOpen}
          // />
          <Menu.Item
            color="green"
            icon="question circle"
            content="Help"
            onClick={handleModalOpen}
          />
        }
        centered={false}
        closeOnDimmerClick={false}
        open={this.state.modalOpen}
        onClose={handleModalClose}
        className="ams-semantic-modal-fix"
        // size="large"
      >
        <Modal.Header content="Help" />
        <Modal.Content>
          <Modal.Description>
            <p
              dangerouslySetInnerHTML={{ __html: data.value }}
              style={{ whiteSpace: 'pre-line' }}
            ></p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={handleModalClose} content="Close" />
        </Modal.Actions>
      </AmsModal>
    );
  };

  renderHelpTextPopup = data => {
    const position = this.props.position || 'bottom center';

    return (
      <>
        {this.props.label}{' '}
        <Popup
          position={position}
          inverted
          content={data.value}
          trigger={
            <button
              type="button"
              aria-label={`Popover help text for ${this.props.fieldLabel}`}
              style={{ border: 'none' }}
            >
              <Icon
                link
                icon="question"
                className="help-text-icon"
                name="question circle outline"
              />
            </button>
          }
        />
      </>
    );
  };

  renderHelpButton = data => {
    if (!isEmpty(data)) {
      if (this.props.type === 'page') return this.renderPageHelp(data);

      return this.renderHelpTextPopup(data);
    }

    return null;
  };

  renderHelp = () => {
    // COMMENTED OUT TEMPORARILY
    // By MIKE

    // const { amsLookups, category, fieldLabel } = this.props;

    // const hidden = this.props.hidden || false;

    // const lookups = amsLookups[category];

    // const parent = find(lookups, { parent: true }); // Find lookup category parent.
    // const activeLookups = filter(lookups, { parent: false, active: true }); // Find active lookup items.
    // // Only render lookup if parent is active.
    // if (parent && parent.active && !hidden) {
    //   // Check if lookup list is not empty.
    //   if (activeLookups.length) {
    //     //find the help text for the specific field label
    //     if (fieldLabel) {
    //       let data = activeLookups.find(e => e.value.label === fieldLabel);
    //       data = data && data.value;

    //       return this.renderHelpButton(data);
    //     }
    //   }
    // }

    return <>{this.props.label}</>;
  };

  render() {
    return this.renderHelp();
  }
}

AmsHelpText.propTypes = {
  fetchAmsLookup: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  amsLookups: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  amsLookups: state.lookups.amsLookups,
});

export default connect(mapStateToProps, { fetchAmsLookup })(AmsHelpText);
