import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Import actions.
import { fetchFindingsDetail } from '../../actions/reviewActions';

//Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import GranteeInfo from './GranteeInfo';
import FollowUpFormContainer from './FollowUpFormContainer';
import FindingsDetail from './FindingsDetail';

class FollowUpRecommendations extends Component {
  state = {
    errors: null,
  };
  showErrors = () => {
    const { errors } = this.state;

    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        content={errors}
      />
    );
  };

  clearErrors = () => {
    this.setState({ errors: null });
  };

  setErrors = err => {
    this.setState({ errors: err });
  };

  componentDidMount() {
    this.props.fetchFindingsDetail(this.props.params.id);
  }

  reloadData = () => {
    return this.props.fetchFindingsDetail(this.props.params.id).then(() => {
      return {};
    });
  };

  render() {
    return (
      <SlidingContainer
        title={
          'Plan your Follow-up Review for Review ID ' + this.props.params.id
        }
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <GranteeInfo />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="col-sm-12 create-review-error-block">
                {this.showErrors()}
              </div>
              <FindingsDetail
                clearErrors={this.clearErrors}
                setErrors={this.setErrors}
                reloadData={this.reloadData}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FollowUpFormContainer
                clearErrors={this.clearErrors}
                setErrors={this.setErrors}
                reloadData={this.reloadData}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SlidingContainer>
    );
  }
}

FollowUpRecommendations.propTypes = {
  fetchFindingsDetail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  fetchFindingsDetail,
})(FollowUpRecommendations);
