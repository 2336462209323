/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchClassSummary } from '../../actions/reportActions';
import _ from 'lodash';

import ClassReportTemplate from './ClassReportTemplate';

const pdfServiceURL = 'https://35.185.30.249:9203/pdfconvert';

class ClassSummaryReport extends Component {
  downloadPDF() {
    // let doc = pdfConverter();
    let node = arguments[0] || this.reportItem.getEditor().containerNode();
    let wNode = window.document.createElement('div');

    // let toHTML = function(s) {
    //   return escape(s)
    //     .replace(/\%([0-9A-F]{2})/gi, '&#x$1;')
    //     .replace(/\%u([0-9A-F]{4})/gi, '&#x$1;');
    // };

    wNode.innerHTML = node.innerHTML
      .replace(
        /(\<[^\>]+)active-inline-[a-zA-Z0-9\-]+[\n\s]*\=[\n\s]*([\"\'])[\s\S]*?\2/g,
        '$1'
      )
      .replace(/\<(\/|)(div|i|b|var|em|span)[^\>]*\>/g, '')
      .replace(/\u2014/g, '-')
      .replace(/\u2019/g, "'");

    let URLAPI =
      window.URL || window.webkitURL || window.mozURL || window.msURL || null;

    fetch(pdfServiceURL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'htmlData=' + encodeURIComponent(wNode.innerHTML),
    }).then(
      response => {
        // debugger;
        response.blob().then(
          blob => {
            let link = window.document.createElement('a');
            link.download =
              new Date()
                .toISOString()
                .replace(
                  /^\d{2}(\d{2})\-(\d{2})\-(\d{2})T(\d{2})\:(\d{2})\:(\d{2}).*$/,
                  'report D$2-$3-$1 T$4-$5-$6.pdf'
                ) || 'report.pdf';
            link.href = URLAPI.createObjectURL(blob);
            window.document.body.appendChild(link);
            if (
              typeof link.click === 'function' &&
              !(
                window.navigator.userAgent.indexOf('MSIE') > 0 ||
                !!window.navigator.userAgent.match(/Trident\/7\./)
              )
            ) {
              link.click();
            } else {
              if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, link.download);
              } else {
                let evt = window.document.createEvent('MouseEvents');
                evt.initMouseEvent(
                  'click',
                  true,
                  true,
                  window,
                  0,
                  0,
                  0,
                  0,
                  0,
                  false,
                  false,
                  false,
                  false,
                  0,
                  null
                );
                link.dispatchEvent(evt);
              }
            }
          },
          err => {
            alert('Error: ' + err.toString());
          }
        );
      },
      err => {
        alert('Error: ' + err.toString());
      }
    );
  }

  componentDidMount() {
    const { currentUser } = this.props;

    const reviewId = this.context.router.params.id;

    if (_.includes(currentUser.roles, 'Reviewer')) {
      this.props.fetchClassSummary(reviewId);
    }
  }

  showClassReport() {
    const { classSummary } = this.props;

    if (classSummary)
      return <ClassReportTemplate classSummary={classSummary} />;
  }

  render() {
    return (
      <div>
        <button
          onClick={e => {
            this.downloadPDF(
              e.target.parentElement.getElementsByTagName('div')[0]
            );
          }}
        >
          Save PDF
        </button>
        {this.showClassReport()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    classSummary: state.report.classSummary,
  };
}

// ClassSummaryReport.contextTypes = {
//   router: React.propTypes.object.isRequired
// }

// ClassSummaryReport.propTypes = {

//   classSummary: React.propTypes.object,
//   fetchClassSummary: React.propTypes.func.isRequired
// }

export default connect(mapStateToProps, { fetchClassSummary })(
  ClassSummaryReport
);
