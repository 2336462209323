import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Dropdown, Modal } from 'semantic-ui-react';
import _ from 'lodash';

// Import actions.
import { addLookup } from '../../../actions/lookupActions';
import AmsModal from '../../../utils/AmsModal';

class LookupTypeAddModal extends Component {
  state = {
    show: false,
    saving: false,
    data: {
      value: '',
      valueType: '',
      label: '',
      description: '',
      parent: true,
      active: true,
    },
    errors: {},
  };

  close = () =>
    this.setState({
      show: false,
      errors: {},
      data: {
        value: '',
        valueType: '',
        parent: true,
        active: true,
        saving: false,
      },
    });

  handleChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  validate = () => {
    const errors = {};
    const { value, valueType } = this.state.data;
    if (_.isEmpty(value)) errors.value = 'Lookup Name is required.';
    if (_.isEmpty(valueType)) errors.valueType = 'Lookup Type is required.';
    return errors;
  };

  getDefaultValue = (valueType, value) => {
    switch (valueType) {
      case 'String':
        return '';
      case 'Number':
        return 0;
      case 'Boolean':
        return true;
      case 'Object':
        return {};
      case 'Array':
        return [];
      default:
        return value;
    }
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate();

    this.setState({ errors });

    // No more errors submit data.
    if (Object.keys(errors).length === 0) {
      const { valueType, value, active, parent, description } = this.state.data;

      this.setState({ saving: true });

      const data = {
        valueType,
        label: value,
        description,
        active,
        parent,
        category: _.camelCase(value),
        value: this.getDefaultValue(valueType, value),
      };

      this.props
        .addLookup(data)
        .then(() => {
          this.setState({ saving: false });
          this.props.refreshList();
          this.close();
        })
        .catch(error => {
          this.setState({
            saving: false,
            errors: { ...this.state.errors, parentError: error.response },
          });
        });
    }
  };

  render() {
    return (
      <div>
        <AmsModal
          open={this.state.show}
          onClose={this.close}
          className="ams-semantic-modal-fix"
          size="small"
          closeOnEscape={false}
        >
          <Modal.Header>New Lookup Type</Modal.Header>

          <Modal.Content>
            <Form onSubmit={this.onSubmit} noValidate>
              <Form.Field
                required
                fluid
                name="value"
                control={Input}
                value={this.state.data.value}
                error={!!this.state.errors.value}
                onChange={this.handleChange}
                label={{ children: 'Lookup Name', htmlFor: 'lookupName' }}
                id="lookupName"
                aria-labelledby="lookupName"
              />

              <Form.Field
                required
                name="valueType"
                value={this.state.data.valueType}
                error={!!this.state.errors.valueType}
                selection
                control={Dropdown}
                placeholder="Select Lookup Type"
                onChange={this.handleChange}
                options={[
                  { key: 'String', value: 'String', text: 'String' },
                  { key: 'Boolean', value: 'Boolean', text: 'Boolean' },
                  { key: 'Number', value: 'Number', text: 'Number' },
                  { key: 'Object', value: 'Object', text: 'Object' },
                  { key: 'Array', value: 'Array', text: 'Array' },
                ]}
                id="lookupType"
                label={{ children: 'Lookup Type', htmlFor: 'lookupType' }}
                aria-labelledby="lookupType"
                selectOnBlur={false}
                openOnFocus={false}
              />

              <Form.Field
                fluid
                inline
                name="description"
                value={this.state.data.description || ''}
                onChange={this.handleChange}
                control={Input}
                id="lookupDescription"
                label={{
                  children: 'Description',
                  htmlFor: 'lookupDescription',
                }}
                aria-labelledby="lookupDescription"
              />

              <Form.Group>
                <Form.Button
                  onClick={e => {
                    e.preventDefault();
                    this.close();
                  }}
                >
                  Close
                </Form.Button>
                <Form.Button primary>
                  {this.state.saving ? 'Saving...' : 'Save Lookup'}
                </Form.Button>
              </Form.Group>
            </Form>
          </Modal.Content>
        </AmsModal>

        {/* New Button */}
        <Form.Button
          fluid
          icon="add"
          color="green"
          content="Add Lookup"
          onClick={() => this.setState({ show: true })}
        />
      </div>
    );
  }
}

LookupTypeAddModal.propTypes = {
  addLookup: PropTypes.func.isRequired,
  refreshList: PropTypes.func,
};

export default connect(null, { addLookup })(LookupTypeAddModal);
