import { find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dimmer,
  Dropdown,
  Header,
  Icon,
  List,
  Loader,
  Modal,
} from 'semantic-ui-react';

import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsModal from '../../utils/AmsModal';
import SlidingContainer from '../../utils/layout/SlidingContainer';
import GranteeAvailabilityPage from './GranteeAvailabilityPage';
import { validateGranteeDetails } from './GranteeAvailabilityValidator';

import {
  fetchGranteeHash,
  saveGranteeUnavailability,
} from '../../actions/granteeActions';

const GranteeUnavailability = props => {
  const dispatch = useDispatch();
  const {
    isMultiGrant,
    granteeInfo,
    fiscalYear,
    previousFiscalYear,
    invitationId,
    linkExpiration,
    agencyId,
  } = useSelector(state => state.granteeDetails);
  const { granteeDetails } = useSelector(state => state);
  const [selectedGrantee, setSelectedGrantee] = useState({});
  const [showNextProgramYear, setShowNextProgramYear] = useState(false);
  const [granteeChanging, setGranteeChanging] = useState(false);
  const [lastEditedGrantee, setLastEditedGrantee] = useState({});
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [multiGranteeDetails, setMultiGranteeDetails] = useState([]);
  const [isNextProgramYearSelected, setIsNextProgramYearSelected] = useState(
    false
  );
  useEffect(() => {
    const getGranteeCalendarDetails = () => {
      let hash = window.location.href.split('hash=')[1];
      dispatch(fetchGranteeHash({ hash }));
    };
    getGranteeCalendarDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateSelectedGrantee = () => {
      if (granteeInfo && granteeInfo.length === 1)
        setSelectedGrantee(granteeInfo[0]);
    };
    const checkFiscalYear = () => {
      let month = AmsDateFormatters.getMoment().month();
      let currentYear = AmsDateFormatters.getMoment().year();
      if (currentYear < fiscalYear && month >= 3) setShowNextProgramYear(true);
      else setShowNextProgramYear(false);
    };

    checkFiscalYear();
    updateSelectedGrantee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeInfo]);

  useEffect(() => {
    const updateMultiGranteeDetails = () => {
      if (granteeDetails[fiscalYear])
        setMultiGranteeDetails(granteeDetails[fiscalYear].grantInfoList);
    };
    updateMultiGranteeDetails();
  }, [granteeDetails, fiscalYear]);

  const savePreviouseGrantee = value => {
    if (!isEmpty(value))
      setLastEditedGrantee({ ...lastEditedGrantee, ...value });
    else setLastEditedGrantee({});
  };
  const nextProgramYearSelected = value => {
    setIsNextProgramYearSelected(value);
  };
  const saveMultiGrantDetails = value => {
    if (multiGranteeDetails.length === 0) {
      setMultiGranteeDetails([value]);
    } else if (
      isEmpty(
        multiGranteeDetails.filter(
          grantee => grantee.granteeId === value.granteeId
        )
      )
    ) {
      let updatedData = multiGranteeDetails;
      updatedData.push(value);
      setMultiGranteeDetails(updatedData);
    } else {
      let updatedData = multiGranteeDetails.map(grantee => {
        if (grantee.granteeId === value.granteeId) grantee = { ...value };
        return grantee;
      });
      setMultiGranteeDetails(updatedData);
    }
  };

  const resetMultiGranteeDetails = () => {
    setMultiGranteeDetails(granteeDetails[fiscalYear].grantInfoList);
  };

  const resetGranteeChanging = value => {
    setGranteeChanging(value);
  };

  const handleAllGranteeSubmit = () => {
    let errors = [];
    // eslint-disable-next-line array-callback-return
    multiGranteeDetails.map(grantee => {
      let error = validateGranteeDetails(
        grantee.hsStartDate,
        grantee.hsEndDate,
        grantee.ehsStartDate,
        grantee.ehsEndDate,
        grantee.selectedMonths,
        grantee.granteeType
      );
      if (!isEmpty(error))
        errors.push({ ...error, granteeId: grantee.granteeId });
    });
    if (isEmpty(errors)) {
      setDialogContent({
        type: 'warning',
        showCancelButton: true,
        showConfirm: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        title: 'Submit for all Grantees',
        text:
          'You will not be able to edit Grantee Unavailability after submitting your final changes. Are you sure you want to submit your final changes?',
        onConfirm: () => {
          setShowDialogModal(false);
          setDialogContent({});
          submitGrantee();
        },
        onCancel: () => {
          setShowDialogModal(false);
          setDialogContent({});
        },
      });
      setShowDialogModal(true);
    } else {
      setDialogContent({
        type: 'warning sign',
        showCancelButton: false,
        showConfirm: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'No',
        title:
          'The following missing items must be completed before you can submit',
        text: (
          <>
            {errors.map(error => {
              return (
                <>
                  Please correct the following for grantee {error.granteeId}
                  <List bulleted>
                    {!isEmpty(error) &&
                      // eslint-disable-next-line array-callback-return
                      Object.keys(error).map(key => {
                        if (key !== 'granteeId')
                          return <List.Item>{error[key].content}</List.Item>;
                      })}
                  </List>
                </>
              );
            })}
          </>
        ),
        onConfirm: () => {
          setShowDialogModal(false);
          setDialogContent({});
        },
        onCancel: () => {
          setShowDialogModal(false);
          setDialogContent({});
        },
      });
      setShowDialogModal(true);
    }
  };

  const submitGrantee = () => {
    let data = multiGranteeDetails.map(grantee => {
      return {
        ...grantee,
        finalUpdate: true,
        isMultiGrant,
        fiscalYear,
        agencyId,
      };
    });
    dispatch(
      saveGranteeUnavailability({ grantees: data, invitationId }, response => {
        if (response.status === 200) {
          let hash = window.location.href.split('hash=')[1];
          setDialogContent({
            type: 'success',
            showCancelButton: false,
            showConfirm: true,
            confirmButtonText: 'Ok',
            title: 'Grantee Submitted',
            text: response.data.success,
            onConfirm: () => {
              setShowDialogModal(false);
              setDialogContent({});
            },
          });
          setShowDialogModal(true);
          dispatch(fetchGranteeHash({ hash }));
        } else {
          setDialogContent({
            type: 'error',
            showCancelButton: false,
            showConfirm: true,
            confirmButtonText: 'Ok',
            title: 'Something went wrong',
            text: response.response.data.message,
            onConfirm: () => {
              setShowDialogModal(false);
              setDialogContent({});
            },
          });
          setShowDialogModal(true);
        }
      })
    );
  };

  const dialogModal = () => {
    return (
      <AmsModal
        size="tiny"
        className="ams-semantic-modal-fix"
        open={showDialogModal}
      >
        <Modal.Content style={{ textAlign: 'center' }}>
          <Header>
            {renderTypeIcon(dialogContent.type)}
            {dialogContent.title}
          </Header>
          {dialogContent.text}
          <div style={{ display: 'block', marginTop: '20px' }}>
            {dialogContent.showCancelButton && (
              <Button onClick={dialogContent.onCancel}>
                {dialogContent.cancelButtonText}
              </Button>
            )}
            <Button primary onClick={dialogContent.onConfirm}>
              {dialogContent.confirmButtonText}
            </Button>
          </div>
        </Modal.Content>
      </AmsModal>
    );
  };

  const renderTypeIcon = type => {
    let color;
    let icon;

    if (type === 'warning') {
      color = 'yellow';
      icon = 'warning';
    } else if (type === 'error') {
      color = 'red';
      icon = 'x';
    } else if (type === 'success') {
      color = 'green';
      icon = 'check';
    } else if (type === 'warning sign') {
      color = 'orange';
      icon = 'warning sign';
    }

    return (
      <Icon.Group size="big" style={{ display: 'block', marginBottom: '10px' }}>
        <Icon size="big" name="circle outline" color={color} />
        <Icon name={icon} color={color} />
      </Icon.Group>
    );
  };

  return (
    <SlidingContainer
      title={'Grantee Availability Calendar Form'}
      breadcrumbEnabled={false}
      subheader={
        !isEmpty(selectedGrantee) &&
        `${selectedGrantee.granteeName} (${selectedGrantee.granteeId})`
      }
    >
      {dialogModal()}
      {isEmpty(granteeInfo) ? (
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      ) : (
        <>
          {isMultiGrant && (
            <div>
              <span style={{ marginRight: '15px' }}>
                <Dropdown
                  placeholder="Select a Grantee to View/Edit"
                  label="Select Grantee:"
                  // fluid
                  search
                  selection
                  onChange={(data, { value }) => {
                    setSelectedGrantee(
                      find(granteeInfo, grantee => grantee.granteeId === value)
                    );
                    if (!isEmpty(selectedGrantee)) setGranteeChanging(true);
                  }}
                  options={granteeInfo.map(grantee => {
                    return {
                      key: grantee.granteeId,
                      value: grantee.granteeId,
                      text: `${grantee.granteeName} (${grantee.granteeId})`,
                    };
                  })}
                  value={selectedGrantee.granteeId}
                />
              </span>
            </div>
          )}
          {!isEmpty(selectedGrantee) && (
            <GranteeAvailabilityPage
              selectedGrantee={selectedGrantee}
              showNextProgramYear={showNextProgramYear}
              fiscalYear={fiscalYear}
              previousFiscalYear={previousFiscalYear}
              granteeChanging={granteeChanging}
              savePreviouseGrantee={data => savePreviouseGrantee(data)}
              lastEditedGrantee={lastEditedGrantee}
              resetGranteeChanging={resetGranteeChanging}
              saveMultiGrantDetails={saveMultiGrantDetails}
              resetMultiGranteeDetails={resetMultiGranteeDetails}
              nextProgramYearSelected={nextProgramYearSelected}
            />
          )}
        </>
      )}
      {!isEmpty(selectedGrantee) && isNextProgramYearSelected && isMultiGrant && (
        <div align="right">
          <span>
            {!granteeDetails[fiscalYear].grantInfoList[0].finalUpdate &&
              !(
                AmsDateFormatters.getMoment(linkExpiration).endOf('day') <=
                AmsDateFormatters.getMoment()
              ) && (
                <Button
                  // width={5}
                  size="large"
                  primary
                  content={'Submit Unavailability for all Grantees'}
                  // fluid
                  onClick={handleAllGranteeSubmit}
                />
              )}
          </span>
        </div>
      )}
    </SlidingContainer>
  );
};

export default GranteeUnavailability;
