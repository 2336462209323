/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import Modal from 'react-modal';
import _ from 'lodash';

import {
  FieldGroupField,
  FieldGroupDatePicker,
  FieldGroupSelect,
} from '../../utils/FieldGroup';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

import { fetchTasksList, addTask } from '../../actions/taskActions';

const asigneeList = [
  {
    label: 'John Doe (Reviewer)',
    value: 'b07b30d7-ded6-47ec-a67b-2e1517f19384',
  },
  {
    label: 'Susan Gabriel (Reviewer)',
    value: '3df5d619-f7aa-4592-862d-ce8545c565fd',
  },
  {
    label: 'Jane Doe (Analyst)',
    value: '5224421a-a778-4655-a763-ae6c3369c9f4',
  },
  {
    label: 'Miller Jordan (Analyst)',
    value: '3c768e0d-053e-46e7-8e98-ec5cf044e1db',
  },
  { label: 'Chris Green (RTL)', value: 'f2302cd8-708c-4121-b190-7db5ec4026ac' },
  {
    label: 'David Hoffman (RTL)',
    value: '14a1b9cf-a70a-4c77-a08b-bc4fd416c26d',
  },
];

const taskList = [
  { label: 'IDV4123', value: 'idv4123' },
  { label: 'IDV4321', value: 'idv4321' },
  { label: 'IDV4231', value: 'idv4231' },
  { label: 'IDV4132', value: 'idv4132' },
];

const taskPriorityList = [
  { label: 'Vary High Priority', value: 'veryhighpriority' },
  { label: 'High Priority', value: 'highpriority' },
  { label: 'Medium Priority', value: 'mediumpriority' },
  { label: 'Low Priority', value: 'lowpriority' },
  { label: 'very Low Priority', value: 'verylowpriority' },
];

const taskCategoryList = [
  { label: 'Category 1', value: 'Category 1' },
  { label: 'Category 2', value: 'Category 2' },
  { label: 'Category 3', value: 'Category 3' },
  { label: 'Category 4', value: 'Category 4' },
  { label: 'Category 5', value: 'Category 5' },
];

class AddTask extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      taskTitle: '',
      taskDescription: '',
      taskStartDate: null,
      taskEndDate: null,
      taskAsignee: [], // Multi select
      taskLink: [], // Multi select
      taskCategory: '', // Single select
      taskPriority: '', // Single select
      files: [],
      assigneeList: [],
      linkList: [],
      taskComment: '',
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleInputValueChage = this.handleInputValueChage.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleTaskSave = this.handleTaskSave.bind(this);

    // Select handlers
    this.handleAsigneeChange = this.handleAsigneeChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleTaskChange = this.handleTaskChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  componentWillMount() {
    const { currentUser } = this.props;

    if (currentUser.oid) {
      this.props.fetchTasks(currentUser.oid);
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  buildTask() {
    const { tasks } = this.props;
    if (tasks.length)
      return tasks.map(task => {
        return (
          <li key={task.id}>
            {' '}
            &#8226; {task.name}{' '}
            <a
              onClick={this.handleTaskDetail.bind(this, task.id)}
              style={{ cursor: 'pointer' }}
            >
              ID# {task.id}
            </a>
          </li>
        );
      });
  }

  showAddTaskButton() {
    if (
      this.props.allowAdd &&
      (_.includes(this.props.currentUser.roles, 'Analyst') ||
        _.includes(this.props.currentUser.roles, 'TechnicalWriter') ||
        _.includes(this.props.currentUser.roles, 'Editor'))
    )
      return (
        <button
          onClick={this.handleAddTaskClick.bind(this)}
          className="btn btn-primary btn-addon m-b-sm"
        >
          <i className="fa fa-plus" /> Add task
        </button>
      );
  }

  handleAddTaskClick() {
    this.openModal();
  }

  handleInputValueChage(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleStartDateChange(value, formattedValue) {
    this.setState({
      taskStartDate: AmsDateFormatters.getMoment(formattedValue).format(),
    });
  }

  handleEndDateChange(value, formattedValue) {
    this.setState({
      taskEndDate: AmsDateFormatters.getMoment(formattedValue).format(),
    });
  }

  handleAsigneeChange(values) {
    this.setState({ taskAsignee: values });

    let selectedAsignee = [];
    // eslint-disable-next-line array-callback-return
    values.map(value => {
      selectedAsignee.push(value.value);
    });
    this.setState({ assigneeList: selectedAsignee });
  }

  handleCategoryChange(value) {
    this.setState({ taskCategory: value });
  }

  handleTaskChange(values) {
    this.setState({ taskLink: values });

    let selectedLink = [];
    // eslint-disable-next-line array-callback-return
    values.map(value => {
      selectedLink.push(value.value);
    });
    this.setState({ linkList: selectedLink });
  }

  handlePriorityChange(value) {
    this.setState({ taskPriority: value });
  }

  /**
   * Return Lists, replace valuses with actual lists coming from endpoints.
   */

  getAsigneeList() {
    return asigneeList;
  }

  getCategoryList() {
    return taskCategoryList;
  }

  getTaskList() {
    return taskList;
  }

  getPriorityList() {
    return taskPriorityList;
  }

  /**
   * end of listing functions.
   */

  onDrop(files) {
    this.setState({
      files,
    });
  }

  showAttached() {
    const { files } = this.state;
    if (!files.length) return;

    return (
      <aside>
        <h3>Attached files</h3>
        <ul>
          {files.map(file => (
            <li key={file.name}>
              {file.name} - {file.size} bytes &nbsp;&nbsp;
              <span
                onClick={this.removeAttachment.bind(this, file)}
                className="clickable"
              >
                <i className="fa fa-close" />
              </span>
            </li>
          ))}
        </ul>
      </aside>
    );
  }

  removeAttachment(file, event) {
    const { files } = this.state;
    // Remove file
    this.setState({ files: _.pull(files, file) });
  }

  handleTaskSave() {
    const {
      taskTitle,
      taskDescription,
      taskStartDate,
      taskEndDate,
      taskCategory,
      taskPriority,
      files,
      assigneeList,
      linkList,
      taskComment,
    } = this.state;
    const { currentUser } = this.props;

    if (!currentUser) return;

    const taskObject = {
      title: taskTitle,
      description: taskDescription,
      assigneeids: assigneeList,
      startDate: taskStartDate,
      dueDate: taskEndDate,
      category: taskCategory.value,
      link: linkList,
      priority: taskPriority.value,
      comment: taskComment,
      taskOriginator: currentUser.oid,
    };

    if (files) {
      taskObject.file = files;
    }

    // console.log(JSON.stringify(taskObject))
    // TODO: Add validation
    this.props.addTask(taskObject);

    this.clearForm();

    this.closeModal();
  }

  handleFile(file) {
    this.setState({ files: file[0] });
  }

  clearSelection() {
    this.setState({ value: '' });
  }

  clearForm() {
    this.setState({
      title: '',
      description: '',
      assign: '',
      startDate: '',
      endDate: '',
      category: '',
      link: '',
      priority: '',
      attachments: '',
      comment: '',
    });
  }

  showModal() {
    const {
      taskTitle,
      taskDescription,
      taskAsignee,
      taskStartDate,
      taskEndDate,
      taskCategory,
      taskLink,
      taskPriority,
      taskComment,
    } = this.state;

    return (
      <Modal
        className="modal-container"
        overlayClassName="modal-overlay-container"
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Task Modal"
      >
        <div className="row" style={{ overflow: 'visible' }}>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-11">
                <h2 className="no-top-margin">Add New Task</h2>
              </div>
              <div className="col-sm-1">
                <span onClick={this.closeModal} className="clickable">
                  <i className="fa fa-close fa-2x" />
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <hr />
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-12">
                <FieldGroupField
                  id="taskTitle"
                  value={taskTitle}
                  type="text"
                  onChange={this.handleInputValueChage}
                  label="Title"
                  placeholder="Task title"
                />

                <FieldGroupField
                  id="taskDescription"
                  value={taskDescription}
                  type="textarea"
                  onChange={this.handleInputValueChage}
                  label="Description"
                  componentClass="textarea"
                  placeholder="Task description"
                />

                <div className="row">
                  <div className="col-sm-4">
                    <FieldGroupSelect
                      id="taskAsignee"
                      value={taskAsignee}
                      placeholder="Assign to"
                      options={this.getAsigneeList()}
                      onChange={this.handleAsigneeChange}
                      label="Assign to"
                      multi
                      // clearable
                    />
                  </div>

                  <div className="col-sm-4">
                    <FieldGroupDatePicker
                      id="taskStartDate"
                      value={taskStartDate}
                      onChange={this.handleStartDateChange}
                      label="Start Date"
                    />
                  </div>

                  <div className="col-sm-4">
                    <FieldGroupDatePicker
                      id="taskEndDate"
                      value={taskEndDate}
                      onChange={this.handleEndDateChange}
                      label="End Date"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <FieldGroupSelect
                      id="taskCategory"
                      options={this.getCategoryList()}
                      value={taskCategory}
                      onChange={this.handleCategoryChange}
                      placeholder="Category"
                      label="Category"
                      // clearable
                    />
                  </div>

                  <div className="col-sm-4">
                    <FieldGroupSelect
                      id="taskLink"
                      value={taskLink}
                      options={this.getTaskList()}
                      onChange={this.handleTaskChange}
                      placeholder="Add link"
                      label="Add Link"
                      multi
                      // clearable
                    />
                  </div>

                  <div className="col-sm-4">
                    <FieldGroupSelect
                      id="taskPriority"
                      value={taskPriority}
                      options={this.getPriorityList()}
                      onChange={this.handlePriorityChange}
                      placeholder="Priority"
                      label="Priority"
                      // clearable
                    />
                  </div>
                </div>

                <FieldGroupField
                  id="taskComment"
                  value={taskComment}
                  type="textarea"
                  onChange={this.handleInputValueChage}
                  label="Comment"
                  componentClass="textarea"
                  placeholder="Comment"
                />

                {/*<div className='row'>

                <div className='col-sm-12'>
                  <lable className='control-label'>Attachments</lable>
                  <Dropzone
                    className='upload-area'
                    onDrop={this.onDrop.bind(this)}
                    accept='application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 	application/vnd.ms-excel' >
                    <div>
                      <h5>Drag files here or ...</h5>
                      <button
                        className='btn btn-primary btn-sm'>
                        Select Attachement
                    </button>
                    </div>
                  </Dropzone>


                  {this.showAttached()}
                </div>
              </div>*/}
              </div>
            </div>
          </div>

          <Button
            onClick={this.handleTaskSave}
            className="push-to-bottom"
            bsSize="large"
            bsStyle="primary"
          >
            Save Task{' '}
          </Button>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.showModal()}
        <ul className="scrolling list-unstyled" />
        {this.showAddTaskButton()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.task,
    currentUser: state.auth.user,
  };
}

AddTask.propTypes = {
  tasks: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  addTask: PropTypes.func.isRequired,
  allowAdd: PropTypes.bool,
};

AddTask.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { fetchTasksList, addTask })(AddTask);
