import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Button,
  Dimmer,
  Accordion,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import AmsAlert from '../../../utils/AmsAlert';
import { updateAnalysisNarrative } from '../../../actions/reviewActions';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

import Wysiwyg from '../../Shared/Wysiwyg';
import { determineFindingStatus, findingCardClasses } from './FindingsUtils';

const FindingsNarrativeCard = props => {
  const { citation } = props;
  const { selectedReview } = useSelector(state => state.review);
  const [showAlert, setShowAlert] = useState(false);
  const [narrative, setNarrative] = useState('');
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setNarrative(citation?.analysisNarrative);
  }, [citation]);

  const handleTextAreaChange = event => {
    setNarrative(event);
  };

  const updateNarrative = questionId => {
    setLoading(true);

    const requestInput = {
      findingId: citation?.findingId,
      analysisNarrative: narrative,
    };
    dispatch(
      updateAnalysisNarrative(
        requestInput,
        { reviewId: selectedReview.reviewId },
        { reviewType: selectedReview.reviewType },
        { isNewForms: selectedReview.isNewForms }
      )
    )
      .then(data => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  if (
    !selectedReview ||
    !selectedReview.grantees ||
    selectedReview.grantees.length === 0
  ) {
    return null;
  }

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`Are you sure?`}
        onConfirm={() => {
          updateNarrative();
          setShowAlert(false);
          props.handleSaveButtonAction('SAVE');
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const renderHistory = () => {
    return (
      <ul className="eas-ul">
        {citation.analysisNarrativeHistory.map((e, index) => {
          return (
            <li key={index} className="history-item eas-li">
              <span class="line"></span>
              <span class="number"></span>
              <div class="blob">
                Narrative updated by <span className="bold">{e.who}</span> on{' '}
                <span className="bold">
                  {AmsDateFormatters.getMoment(e.when).format(
                    'M/D/YYYY, h:mm A'
                  )}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Card
      className={findingCardClasses(
        'findings-card',
        determineFindingStatus(selectedReview, citation)
      )}
    >
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted>
          Loading
        </Loader>
      </Dimmer>
      {showConfirmationAlert()}
      <Card.Content
        style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
        header="Narrative"
      />
      <Form
        style={{ margin: '10px' }}
        className={selectedReview.readOnly ? 'eas-readonly' : ''}
      >
        <Form.Field>
          <label class="visually-hidden">Narrative</label>
          <Wysiwyg
            disabled={selectedReview.readOnly}
            id={'trix-narrative-editor'}
            value={narrative}
            onChange={handleTextAreaChange}
          />
        </Form.Field>
        <Button
          disabled={selectedReview.readOnly}
          style={{
            width: '250px',
            backgroundColor: '#245E9D',
            color: 'white',
            marginTop: '10px',
          }}
          onClick={() => {
            setShowAlert(true);
          }}
        >
          Save Narrative
        </Button>
      </Form>
      {citation?.analysisNarrativeHistory &&
        citation.analysisNarrativeHistory.length > 0 && (
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <Accordion className={'enabled-section'}>
              <Accordion.Title
                active={showHistory}
                index={0}
                onClick={() => {
                  setShowHistory(!showHistory);
                }}
              >
                {!showHistory ? 'See History' : 'Hide History'}
                <Icon name="dropdown" />
              </Accordion.Title>
              <Accordion.Content active={showHistory}>
                <div style={{ textAlign: 'left' }}>{renderHistory()}</div>
              </Accordion.Content>
            </Accordion>
          </div>
        )}
    </Card>
  );
};

export default FindingsNarrativeCard;
