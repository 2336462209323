import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Header, Button } from 'semantic-ui-react';

class GranteeCalendarCopyModal extends Component {
  state = {
    showCopyModal: false,
  };

  handleShowModal = () => {
    this.setState({ showCopyModal: true });
  };

  handleCancelCloseAction = () => {
    this.setState({ showCopyModal: false });
  };

  handleContinueCloseAction = () => {
    const { copyAction } = this.props;
    this.setState({ showCopyModal: false });
    copyAction();
  };

  render() {
    const { showCopyModal } = this.state;
    const { finalUpdate } = this.props;
    return (
      <div>
        <Button
          disabled={finalUpdate}
          color="green"
          onClick={this.handleShowModal}
        >
          Copy Previous Saved Grantee Details
        </Button>
        <Modal show={showCopyModal} onHide={this.handleCancelCloseAction}>
          <Modal.Header closeButton>
            <Modal.Title>Copy Previous Grantee Details?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Header as="h3">
              Are you sure you want to copy the previous Grantee Details? This
              will overwrite the current grantee details for this grantee.
            </Header>
          </Modal.Body>
          <Modal.Footer>
            <Button color="green" onClick={this.handleContinueCloseAction}>
              Continue
            </Button>
            <Button color="red" onClick={this.handleCancelCloseAction}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(GranteeCalendarCopyModal);
