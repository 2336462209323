import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import AmsModal from '../../../utils/AmsModal';

const ShowRemoveCitationDialog = props => {
  return (
    <AmsModal
      size="small"
      centered={false}
      closeOnDimmerClick={false}
      onClose={() => {
        props.close();
      }}
      open={props.showCitationRemoveAlert}
      className="ams-semantic-modal-fix"
    >
      <Modal.Content>
        <Header content="Are you sure you want to remove this citation?" />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.close();
          }}
          primary
          content="Cancel"
        />
        <Button
          onClick={() => {
            props.save();
          }}
          negative
          content="Remove Citation"
        />
      </Modal.Actions>
    </AmsModal>
  );
};

export default ShowRemoveCitationDialog;
