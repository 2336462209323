import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, TextArea } from 'semantic-ui-react';

// Import configs.

//Import Components
import GranteeCalendarCloseModal from './GranteeCalendarCloseModal';
import GranteeCalendarCopyModal from './GranteeCalendarCopyModal';
import GranteeCalendarModal from './GranteeCalendarModal';
import GranteeCalendarRadioButton from './GranteeCalendarRadioButton';
import GranteeCalendarSaveModal from './GranteeCalendarSaveModal';
import GranteeCalendarSchoolDates from './GranteeCalendarSchoolDates';
import GranteeCalendarSubmitModal from './GranteeCalendarSubmitModal';
import GranteeCurrentlySelectedDatesModal from './GranteeCurrentlySelectedDatesModal';
import GranteeViewExpiredMessage from './GranteeViewExpiredMessage';
import GranteeViewFinalizedMessage from './GranteeViewFinalizedMessage';

import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

// Import action
import { updateGrantee } from '../../../actions/granteeActions';

class GranteeViewCurrentYearTab extends Component {
  state = {
    currentlySelectedMonth: '',
    currentlySelectedMonthYear: AmsDateFormatters.getMoment(),
    selectedYear: AmsDateFormatters.getMoment().year(),
    currentDate: AmsDateFormatters.getMoment().startOf('day'),
    unavailableDates: [],
    sessionDates: [],
    breakDates: [],
    holidays: [],
    hsStartDate: null,
    hsEndDate: null,
    ehsStartDate: null,
    ehsEndDate: null,
    comments: undefined,
    finalUpdate: null,
    monthsSelected: {
      October: false,
      November: false,
      December: false,
      January: false,
      February: false,
      March: false,
      April: false,
      May: false,
      June: false,
      July: false,
      August: false,
      September: false,
    },
    showExpiredMessage: false,
    showFinalizedMessage: false,
    isHSRequired: false,
    isEHSRequired: false,
    changesMade: false,
    showCalendarModal: false,
    showCopyPreviousGranteeButton: false,
    showCancelUpdateMessage: false,
  };

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    const { changesMade } = this.state;
    const { showAlert } = this.props;
    if (changesMade) showAlert(true, this.generateGranteeDetails(false));
  }

  loadData() {
    const { selectedGrantee } = this.props;

    let comments = '';
    let finalUpdate = false;

    if (selectedGrantee !== undefined) {
      comments = selectedGrantee.comments;
      finalUpdate = selectedGrantee.finalUpdate;
    }

    if (this.state.comments === undefined)
      this.setState({ comments: comments !== undefined ? comments : '' });
    if (this.state.finalUpdate === null)
      this.setState({
        finalUpdate: finalUpdate !== undefined ? finalUpdate : false,
      });

    this.getDatesFromHash();
    this.getMonthsSelectedFromHash();
    this.getSchoolDatesfromHash();
    this.checkLoadValidations();
    this.checkForPreviouslySaveGrantee();
  }

  getDatesFromHash() {
    const { fedHolidays, selectedGrantee } = this.props;

    if (selectedGrantee !== undefined) {
      this.setState({
        unavailableDates: this.convertDatesToDisplayFormat(
          selectedGrantee.unavailabilityDates
        ),
        sessionDates: this.convertDatesToDisplayFormat(
          selectedGrantee.notInSessionDates
        ),
        breakDates: this.convertDatesToDisplayFormat(
          selectedGrantee.springBreakDates
        ),
      });
    }
    this.setState({
      holidays: fedHolidays !== undefined ? Array.from(fedHolidays) : [],
    });
  }

  convertDatesToDisplayFormat(dates) {
    let tempDates = [];
    if (!_.isEmpty(dates)) {
      Array.from(dates).forEach(function(day) {
        tempDates.push(AmsDateFormatters.getMoment(day));
      });
    }
    return tempDates;
  }

  getMonthsSelectedFromHash() {
    const { selectedGrantee } = this.props;

    if (!_.isEmpty(selectedGrantee)) {
      if (!_.isEmpty(selectedGrantee.selectedMonths)) {
        this.setState({
          monthsSelected: _.clone(selectedGrantee.selectedMonths),
        });
      }
    }
  }

  getSchoolDatesfromHash() {
    const { selectedGrantee } = this.props;

    if (selectedGrantee !== undefined) {
      if (!_.isEmpty(selectedGrantee.hsStartDate))
        this.setState({
          hsStartDate: AmsDateFormatters.getMoment(selectedGrantee.hsStartDate),
        });
      if (!_.isEmpty(selectedGrantee.hsEndDate))
        this.setState({
          hsEndDate: AmsDateFormatters.getMoment(selectedGrantee.hsEndDate),
        });
      if (!_.isEmpty(selectedGrantee.ehsStartDate))
        this.setState({
          ehsStartDate: AmsDateFormatters.getMoment(
            selectedGrantee.ehsStartDate
          ),
        });
      if (!_.isEmpty(selectedGrantee.ehsEndDate))
        this.setState({
          ehsEndDate: AmsDateFormatters.getMoment(selectedGrantee.ehsEndDate),
        });
    }
  }

  checkLoadValidations() {
    const { selectedGrantee, linkExpiration } = this.props;
    let finalUpdate = false;
    let type = '';

    if (selectedGrantee !== undefined)
      if (selectedGrantee.finalUpdate !== undefined)
        finalUpdate = selectedGrantee.finalUpdate;

    if (finalUpdate)
      this.setState({ finalUpdate: true, showFinalizedMessage: true });

    let expirationDate = AmsDateFormatters.getMoment(linkExpiration).endOf(
      'day'
    );
    if (expirationDate <= this.state.currentDate) {
      if (!finalUpdate) this.setState({ showExpiredMessage: true });
      this.setState({ finalUpdate: true });
    }

    if (selectedGrantee.granteeType !== undefined)
      type = selectedGrantee.granteeType;

    if (type.toLowerCase().indexOf('and') >= 0) {
      this.setState({ isHSRequired: true, isEHSRequired: true });
    } else if (type.toLowerCase().startsWith('early')) {
      this.setState({ isEHSRequired: true });
    } else if (type.toLowerCase().startsWith('head')) {
      this.setState({ isHSRequired: true });
    } else {
      this.setState({ isHSRequired: false, isEHSRequired: false });
    }
  }

  checkForPreviouslySaveGrantee = () => {
    const { previouslySavedGrantee } = this.props;
    if (!_.isEmpty(previouslySavedGrantee))
      this.setState({ showCopyPreviousGranteeButton: true });
  };

  generateGranteeDetails = finalUpdate => {
    const {
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      comments,
      unavailableDates,
      sessionDates,
      breakDates,
      monthsSelected,
    } = this.state;
    //API Data has changed so need to make changes for it
    const { fiscalYear, agencyId, isMultiGrant } = this.props.granteeDetails;
    const { granteeId, granteeType, granteeName } = this.props.selectedGrantee;
    let granteeDetails = {
      agencyId: agencyId,
      granteeId: granteeId,
      fiscalYear: fiscalYear,
      finalUpdate: finalUpdate,
      granteeName: granteeName,
      //Added for backend purposes
      isMultiGrant: isMultiGrant,
      unavailabilityDates: this.convertDatesToAPIFormat(unavailableDates),
      unavailableDates: this.convertDatesToAPIFormat(unavailableDates),
      notInSessionDates: this.convertDatesToAPIFormat(sessionDates),
      springBreakDates: this.convertDatesToAPIFormat(breakDates),
      hsStartDate:
        hsStartDate != null
          ? AmsDateFormatters.getMoment(hsStartDate).utc()
          : '',
      hsEndDate:
        hsEndDate != null ? AmsDateFormatters.getMoment(hsEndDate).utc() : '',
      ehsStartDate:
        ehsStartDate != null
          ? AmsDateFormatters.getMoment(ehsStartDate).utc()
          : '',
      ehsEndDate:
        ehsEndDate != null ? AmsDateFormatters.getMoment(ehsEndDate).utc() : '',
      comments: comments,
      selectedMonths: _.clone(monthsSelected),
      granteeType: granteeType,
    };
    //Added because backend wants an array of data returned instead now.
    let returnData = [];
    returnData.push(granteeDetails);
    return { grantees: returnData };
  };

  copyPreviousGranteeDetails = () => {
    const { previouslySavedGrantee } = this.props;
    this.setState({
      hsStartDate: !_.isEmpty(previouslySavedGrantee.hsStartDate)
        ? previouslySavedGrantee.hsStartDate
        : null,
      hsEndDate: !_.isEmpty(previouslySavedGrantee.hsEndDate)
        ? previouslySavedGrantee.hsEndDate
        : null,
      ehsStartDate: !_.isEmpty(previouslySavedGrantee.ehsStartDate)
        ? previouslySavedGrantee.ehsStartDate
        : null,
      ehsEndDate: !_.isEmpty(previouslySavedGrantee.ehsEndDate)
        ? previouslySavedGrantee.ehsEndDate
        : null,
      comments: previouslySavedGrantee.comments,
      unavailableDates: this.convertDatesToDisplayFormat(
        previouslySavedGrantee.unavailableDates
      ),
      sessionDates: this.convertDatesToDisplayFormat(
        previouslySavedGrantee.notInSessionDates
      ),
      breakDates: this.convertDatesToDisplayFormat(
        previouslySavedGrantee.springBreakDates
      ),
      monthsSelected: _.clone(previouslySavedGrantee.selectedMonths),
      changesMade: true,
    });
  };

  convertDatesToAPIFormat(dates) {
    let tempDates = [];
    Array.from(dates).forEach(function(day) {
      tempDates.push(AmsDateFormatters.getMoment(day).utc());
    });
    return tempDates;
  }

  handleSubmitClick = () => {
    this.setState({ changesMade: false });
    const { updateGrantee } = this.props;
    const granteeData = this.generateGranteeDetails(true);
    updateGrantee(granteeData);
    this.props.submitAction();
  };

  handleSaveAndClose = () => {
    this.setState({ changesMade: false });
    const { updateGrantee } = this.props;
    const granteeData = this.generateGranteeDetails(false);
    updateGrantee(granteeData);
    this.props.saveAction();
  };

  handleCloseClick = () => {
    this.setState({ changesMade: false });
    this.props.closeAction();
  };

  handleCopyClick = () => {
    this.copyPreviousGranteeDetails();
  };

  handleHideCalendarModal = () => {
    this.setState({
      showCalendarModal: false,
      currentlySelectedMonth: '',
      currentlySelectedMonthYear: AmsDateFormatters.getMoment(),
    });
  };

  handleMonthClick = month => {
    let selectedMonthYear = AmsDateFormatters.getMoment();
    if (month === 'October' || month === 'November' || month === 'December') {
      selectedMonthYear = AmsDateFormatters.getMoment(
        month + AmsDateFormatters.getMoment().year()
      );
    } else {
      selectedMonthYear = AmsDateFormatters.getMoment(
        month +
          AmsDateFormatters.getMoment()
            .add(1, 'y')
            .year()
      );
    }
    this.setState({
      currentlySelectedMonth: month,
      showCalendarModal: true,
      currentlySelectedMonthYear: selectedMonthYear,
    });
  };

  handleUpdateMonth = (unavailableDates, sessionDates, breakDates) => {
    let { monthsSelected, currentlySelectedMonth } = this.state;
    monthsSelected[currentlySelectedMonth] = true;
    this.setState({
      unavailableDates: unavailableDates,
      sessionDates: sessionDates,
      breakDates: breakDates,
      monthsSelected: monthsSelected,
      selectedMonth: currentlySelectedMonth,
      changesMade: true,
      showCalendarModal: false,
      currentlySelectedMonth: '',
      currentlySelectedMonthYear: AmsDateFormatters.getMoment(),
    });
  };

  handleCancelUpdate = month => {
    this.setState({ selectedMonth: month, changesMade: true });
  };

  handleUpdateHsStartDate = date => {
    this.setState({ hsStartDate: date, changesMade: true });
  };

  handleUpdateHsEndDate = date => {
    this.setState({ hsEndDate: date, changesMade: true });
  };

  handleUpdateEhsStartDate = date => {
    this.setState({ ehsStartDate: date, changesMade: true });
  };

  handleUpdateEHsEndDate = date => {
    this.setState({ ehsEndDate: date, changesMade: true });
  };

  handleUpdateComments = event => {
    this.setState({ comments: event.target.value, changesMade: true });
  };

  handleSaveGranteeUpdates = () => {
    const { updateGranteeData } = this.props;
    this.setState({ changesMade: false }, () => {
      const granteeData = this.generateGranteeDetails(false);
      updateGranteeData(granteeData);
    });
  };

  handleCancelGranteeUpdateConfirmation = () => {
    const { changesMade } = this.state;
    const { closeUpdateAction } = this.props;
    if (!changesMade) closeUpdateAction();
    else this.setState({ showCancelUpdateMessage: true });
  };

  handleCancelGranteeUpdate = () => {
    const { closeUpdateAction } = this.props;
    this.setState({
      changesMade: false,
      showCancelUpdateMessage: false,
    });
    closeUpdateAction();
  };

  renderMovingConfirmation = () => {
    const { showCancelUpdateMessage } = this.state;
    return (
      <AmsAlert
        show={showCancelUpdateMessage}
        title="Are you sure you want to cancel your changes!"
        text={`The changes you have made will be lost if you do not save your changes. Are you sure you want to cancel your changes?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showCancelUpdateMessage: false });
        }}
        onConfirm={() => {
          this.handleCancelGranteeUpdate();
        }}
      />
    );
  };

  render() {
    const {
      finalUpdate,
      showFinalizedMessage,
      showExpiredMessage,
      comments,
      monthsSelected,
      currentlySelectedMonth,
      unavailableDates,
      sessionDates,
      breakDates,
      currentlySelectedMonthYear,
      showCalendarModal,
      holidays,
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      isHSRequired,
      isEHSRequired,
      startDate,
    } = this.state;
    const { previouslySavedGrantee, selectedGrantee } = this.props;
    const { isMultiGrant, grantInfoList } = this.props.granteeDetails;
    return (
      <Form id="grantee-unavailability-form">
        {this.renderMovingConfirmation()}
        <GranteeViewFinalizedMessage
          showFinalizedMessage={showFinalizedMessage}
        />
        <GranteeViewExpiredMessage showExpiredMessage={showExpiredMessage} />
        {!_.isEmpty(previouslySavedGrantee) &&
        previouslySavedGrantee.granteeId != selectedGrantee.granteeId ? (
          <GranteeCalendarCopyModal
            finalUpdate={finalUpdate}
            copyAction={this.handleCopyClick}
          />
        ) : (
          ''
        )}
        <br />
        <GranteeCalendarSchoolDates
          hsStartDate={hsStartDate}
          hsEndDate={hsEndDate}
          ehsStartDate={ehsStartDate}
          ehsEndDate={ehsEndDate}
          isHSRequired={isHSRequired}
          isEHSRequired={isEHSRequired}
          startDate={startDate}
          finalUpdate={finalUpdate}
          handleHsStartDate={this.handleUpdateHsStartDate}
          handleHsEndDate={this.handleUpdateHsEndDate}
          handleEhsStartDate={this.handleUpdateEhsStartDate}
          handleEhsEndDate={this.handleUpdateEHsEndDate}
        />
        <GranteeCalendarModal
          unavailableDates={unavailableDates}
          sessionDates={sessionDates}
          breakDates={breakDates}
          currentlySelectedMonthYear={currentlySelectedMonthYear}
          currentlySelectedMonth={currentlySelectedMonth}
          finalUpdate={finalUpdate}
          showCalendarModal={showCalendarModal}
          handleHideCalendarModal={this.handleHideCalendarModal}
          holidays={holidays}
          handleUpdateMonth={this.handleUpdateMonth}
        />
        <Form.Group>
          <GranteeCalendarRadioButton
            month={'October'}
            monthWasEdited={monthsSelected['October']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'November'}
            monthWasEdited={monthsSelected['November']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'December'}
            monthWasEdited={monthsSelected['December']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'January'}
            monthWasEdited={monthsSelected['January']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
        </Form.Group>
        <Form.Group>
          <GranteeCalendarRadioButton
            month={'February'}
            monthWasEdited={monthsSelected['February']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'March'}
            monthWasEdited={monthsSelected['March']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'April'}
            monthWasEdited={monthsSelected['April']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'May'}
            monthWasEdited={monthsSelected['May']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
        </Form.Group>
        <Form.Group>
          <GranteeCalendarRadioButton
            month={'June'}
            monthWasEdited={monthsSelected['June']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'July'}
            monthWasEdited={monthsSelected['July']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'August'}
            monthWasEdited={monthsSelected['August']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
          <GranteeCalendarRadioButton
            month={'September'}
            monthWasEdited={monthsSelected['September']}
            currentlySelectedMonth={currentlySelectedMonth}
            handleMonthClick={this.handleMonthClick}
          />
        </Form.Group>
        <GranteeCurrentlySelectedDatesModal {...this.state} />
        <Form.Group>
          <Form.Field width={16}>
            <Form.Field
              disabled={finalUpdate}
              control={TextArea}
              label="Grant Recipient Comments:"
              placeholder=""
              onChange={this.handleUpdateComments}
              value={comments}
            />
          </Form.Field>
        </Form.Group>
        {isMultiGrant && grantInfoList && grantInfoList.length > 1 ? (
          <Form.Group>
            {!finalUpdate ? (
              <Button color="green" onClick={this.handleSaveGranteeUpdates}>
                Update Grantee Unavailability
              </Button>
            ) : (
              ''
            )}
            <Button
              color="yellow"
              onClick={this.handleCancelGranteeUpdateConfirmation}
            >
              {!finalUpdate ? 'Cancel Changes' : 'Close'}
            </Button>
          </Form.Group>
        ) : (
          <Form.Group>
            <GranteeCalendarSubmitModal
              finalUpdate={finalUpdate}
              monthsSelected={monthsSelected}
              isHSRequired={isHSRequired}
              isEHSRequired={isEHSRequired}
              hsStartDate={hsStartDate}
              hsEndDate={hsEndDate}
              ehsStartDate={ehsStartDate}
              ehsEndDate={ehsEndDate}
              closeAction={this.handleSubmitClick}
              unavailableDates={unavailableDates}
              sessionDates={sessionDates}
              breakDates={breakDates}
            />
            <GranteeCalendarSaveModal
              finalUpdate={finalUpdate}
              closeAction={this.handleSaveAndClose}
            />
            <GranteeCalendarCloseModal
              finalUpdate={finalUpdate}
              closeAction={this.handleCloseClick}
            />
          </Form.Group>
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    granteeDetails: state.granteeDetails,
  };
};

export default connect(mapStateToProps, { updateGrantee })(
  GranteeViewCurrentYearTab
);
