import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Form, Segment } from 'semantic-ui-react';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import { find } from 'lodash';

export default function RANGuides(props) {
  const [selectedName, setSelectedName] = useState('');
  const [guidesOptions, setGuidesOptions] = useState([]);
  const { guides, selectedGuide } = useSelector(state => state.forms.ran);

  // useEffect to set the guide list options
  useEffect(() => {
    if (guides) {
      setGuidesOptions(
        guides.map(guide => ({
          key: guide.id,
          text: guide.name,
          value: guide.name,
          'aria-label': guide.name,
        }))
      );
    }
  }, [guides]);

  // useEffect to set the selected guide name
  useEffect(() => {
    if (selectedGuide) {
      setSelectedName(selectedGuide.name);
    }
  }, [selectedGuide]);

  return (
    <>
      <Segment basic>
        <Form.Field>
          <AmsFormLabel name="Select Guide" fieldLabel="selectGuide">
            <Dropdown
              // aria-labelledby="selectGuide"
              searchInput={{ id: 'selectGuide' }}
              placeholder="Select Guide"
              title="Select Guide"
              value={selectedName}
              selectOnBlur={false}
              options={guidesOptions}
              selection
              search
              fluid
              onChange={(e, { value }) => {
                const selected = find(guides, { name: value });
                props.changeGuide(selected);
              }}
              openOnFocus={false}
              selectOnNavigation={false}
            />
          </AmsFormLabel>
        </Form.Field>
      </Segment>
    </>
  );
}
