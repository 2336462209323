import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { Button, Divider, Icon } from 'semantic-ui-react';

import { login, logout } from '../../actions/authActions';

import enforceRole from '../../utils/EnforceRole';

// Config
import { acl } from '../../config';

class ProfileNav extends Component {
  showUserProfile() {
    const { fullName } = this.props.currentUser;

    if (fullName) {
      return (
        <div className="dropdown">
          <Button
            className="user-name"
            id="dropdownMenu1"
            data-toggle="dropdown"
            style={{ background: 'none', borderStyle: 'none', color: 'white' }}
            size="medium"
            as={Link}
            to={`#`}
          >
            {fullName}
            <span className="caret"></span>
          </Button>
          <div className="dropdown-menu">
            <Button.Group vertical>
              <Button
                style={{
                  background: 'none',
                  borderStyle: 'none',
                  textAlign: 'left',
                }}
                aria-label="Go to my calendar"
                as={Link}
                to={'/calendar'}
                className="dropdown-item"
              >
                <Icon name="calendar" />
                My Calendar
              </Button>
              <Divider />
              <Button
                style={{
                  background: 'none',
                  borderStyle: 'none',
                  textAlign: 'left',
                }}
                aria-label="Go to profile"
                as={Link}
                to={'/profile'}
                className="dropdown-item"
              >
                <Icon name="user" />
                Profile
              </Button>
              {this.showAdminMenu()}
              {this.showLoginLogout()}
            </Button.Group>
          </div>
        </div>
      );
    }

    return null;
  }

  showAdminMenu() {
    const { currentUser } = this.props;
    const linkPath = _.includes(currentUser.roles, 'LogisticsAssociate')
      ? '/admin/users'
      : '/admin';
    return enforceRole(
      // <li>
      <Button
        style={{ background: 'none', borderStyle: 'none', textAlign: 'left' }}
        aria-label="Go to administration"
        as={Link}
        to={linkPath}
        className="dropdown-item"
      >
        <Icon name="users" />
        Administration{' '}
      </Button>,
      // </li>
      acl.actions.profile.adminPanel,
      currentUser.roles
    );
  }

  handleLogInClick(e) {
    e.preventDefault();
    this.props.login();
  }

  handleLogoutClick(e) {
    e.preventDefault();
    this.props.logout();
  }

  showLoginLogout() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated)
      return (
        // <li>

        <Button
          style={{ background: 'none', borderStyle: 'none', textAlign: 'left' }}
          aria-label="Sign out"
          onClick={this.handleLogoutClick.bind(this)}
          className="dropdown-item"
        >
          <Icon name="sign-out" />
          Sign out
        </Button>
        // {/* </li> */}
      );

    return (
      // <li>
      <Button
        style={{ background: 'none', borderStyle: 'none', textAlign: 'left' }}
        aria-label="Sign in"
        onClick={this.handleLogInClick.bind(this)}
        className="dropdown-item"
      >
        <Icon name="sign-in" />
        Sign in
      </Button>
      // {/* </li> */}
    );
  }

  render() {
    return this.showUserProfile();
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.user,
  };
}

ProfileNav.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { login, logout })(ProfileNav);
