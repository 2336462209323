import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

class GranteeCalendarShoolDates extends Component {
  state = {
    hsStartDateValue: null,
    hsStopDateValue: null,
    ehsStartDateValue: null,
    ehsStopDateValue: null,
  };

  handleHsStartChange = hsStartDate => {
    const { handleHsStartDate, handleHsEndDate } = this.props;
    if (!hsStartDate) {
      this.setState({ hsStartDateValue: '', hsEndDateValue: '' });
      handleHsStartDate(null);
      handleHsEndDate(null);
      return;
    }
    handleHsStartDate(AmsDateFormatters.getMoment(hsStartDate).startOf('day'));
    this.setState({
      hsStartDateValue: AmsDateFormatters.getMoment(hsStartDate)
        .startOf('day')
        .format('MM/DD/YYYY'),
    });
  };

  handleHsStartChangeRaw = value => {
    this.setState({ hsStartDateValue: value });
  };

  handleHsStartFocousOut = value => {
    const { handleHsStartDate } = this.props;
    if (!this.validateDate(value)) {
      this.setState({ hsStartDateValue: '' });
      handleHsStartDate(null);
    } else {
      handleHsStartDate(AmsDateFormatters.getMoment(value).startOf('day'));
    }
  };

  handlehsEndChange = hsEndDate => {
    const { handleHsEndDate } = this.props;
    if (!hsEndDate) {
      this.setState({ hsEndDateValue: '' });
      handleHsEndDate(null);
      return;
    }
    handleHsEndDate(AmsDateFormatters.getMoment(hsEndDate).startOf('day'));
    this.setState({
      hsEndDateValue: AmsDateFormatters.getMoment(hsEndDate)
        .startOf('day')
        .format('MM/DD/YYYY'),
    });
  };

  handleHsEndChangeRaw = value => {
    this.setState({ hsEndDateValue: value });
  };

  handleHsEndFocousOut = value => {
    const { handleHsEndDate, hsStartDate } = this.props;
    if (!this.validateDate(value)) {
      this.setState({ hsEndDateValue: '' });
      handleHsEndDate(null);
      return;
    }
    if (hsStartDate.isAfter(AmsDateFormatters.getMoment(value).endOf('day'))) {
      this.setState({ hsEndDateValue: '' });
      handleHsEndDate(null);
    } else {
      handleHsEndDate(AmsDateFormatters.getMoment(value).startOf('day'));
    }
  };

  handleEhsStartChange = ehsStartDate => {
    const { handleEhsStartDate, handleEhsEndDate } = this.props;
    if (!ehsStartDate) {
      this.setState({ ehsStartDateValue: '', ehsEndDateValue: '' });
      handleEhsStartDate(null);
      handleEhsEndDate(null);
      return;
    }
    handleEhsStartDate(
      AmsDateFormatters.getMoment(ehsStartDate).startOf('day')
    );
    this.setState({
      ehsStartDateValue: AmsDateFormatters.getMoment(ehsStartDate)
        .startOf('day')
        .format('MM/DD/YYYY'),
    });
  };

  handleEhsStartChangeRaw = value => {
    this.setState({ ehsStartDateValue: value });
  };

  handleEhsStartFocousOut = value => {
    const { handleEhsStartDate } = this.props;
    if (!this.validateDate(value)) {
      this.setState({ ehsStartDateValue: '' });
      handleEhsStartDate(null);
    } else {
      handleEhsStartDate(AmsDateFormatters.getMoment(value).startOf('day'));
    }
  };

  handleEhsEndChange = ehsEndDate => {
    const { handleEhsEndDate } = this.props;
    if (!ehsEndDate) {
      this.setState({ ehsEndDateValue: '' });
      handleEhsEndDate(null);
      return;
    }
    handleEhsEndDate(AmsDateFormatters.getMoment(ehsEndDate).startOf('day'));
    this.setState({
      ehsEndDateValue: AmsDateFormatters.getMoment(ehsEndDate)
        .startOf('day')
        .format('MM/DD/YYYY'),
    });
  };

  handleEhsEndChangeRaw = value => {
    this.setState({ ehsEndDateValue: value });
  };

  handleEhsEndFocousOut = value => {
    const { handleEhsEndDate, ehsStartDate } = this.props;
    if (!this.validateDate(value)) {
      this.setState({ ehsEndDateValue: '' });
      handleEhsEndDate(null);
      return;
    }
    if (ehsStartDate.isAfter(AmsDateFormatters.getMoment(value).endOf('day'))) {
      this.setState({ ehsEndDateValue: '' });
      handleEhsEndDate(null);
    } else {
      handleEhsEndDate(AmsDateFormatters.getMoment(value).startOf('day'));
    }
  };

  //TODO: revisit at a later date
  validateDate(testdate) {
    var date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return date_regex.test(testdate);
  }

  render() {
    const {
      hsStartDateValue,
      hsEndDateValue,
      ehsStartDateValue,
      ehsEndDateValue,
    } = this.state;
    const {
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      isHSRequired,
      isEHSRequired,
      startDate,
      finalUpdate,
    } = this.props;
    return (
      <div>
        {isHSRequired ? (
          <Form.Group widths="equal">
            <Form.Field
              required
              width={2}
              name="hsStartDate"
              control={DatePicker}
              label={'HS:School Year Start Date'}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              selectsStart
              disabled={finalUpdate}
              selected={hsStartDate}
              value={hsStartDateValue}
              minDate={startDate}
              startDate={hsStartDate}
              onChange={this.handleHsStartChange}
              onChangeRaw={event =>
                this.handleHsStartChangeRaw(event.target.value)
              }
              onBlur={event => this.handleHsStartFocousOut(event.target.value)}
            />
            <Form.Field
              required
              width={2}
              name="hsEndDate"
              control={DatePicker}
              label={'HS:School Year End Date'}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              disabled={!hsStartDate || finalUpdate}
              selected={hsEndDate}
              value={hsEndDateValue}
              selectsEnd
              startDate={hsStartDate}
              minDate={hsStartDate}
              endDate={hsEndDate}
              onChange={this.handlehsEndChange}
              onChangeRaw={event =>
                this.handleHsEndChangeRaw(event.target.value)
              }
              onBlur={event => this.handleHsEndFocousOut(event.target.value)}
            />
          </Form.Group>
        ) : (
          ''
        )}
        {isEHSRequired ? (
          <Form.Group widths="equal">
            <Form.Field
              required
              width={2}
              name="ehsStartDate"
              control={DatePicker}
              label={'EHS:School Year Start Date'}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              selectsStart
              disabled={finalUpdate}
              selected={ehsStartDate}
              value={ehsStartDateValue}
              minDate={startDate}
              startDate={ehsStartDate}
              onChange={this.handleEhsStartChange}
              onChangeRaw={event =>
                this.handleEhsStartChangeRaw(event.target.value)
              }
              onBlur={event => this.handleEhsStartFocousOut(event.target.value)}
            />
            <Form.Field
              required
              width={2}
              name="ehsEndDate"
              control={DatePicker}
              label={'EHS:School Year End Date'}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              disabled={!ehsStartDate || finalUpdate}
              selected={ehsEndDate}
              value={ehsEndDateValue}
              selectsEnd
              startDate={ehsStartDate}
              minDate={ehsStartDate}
              endDate={ehsEndDate}
              onChange={this.handleEhsEndChange}
              onChangeRaw={event =>
                this.handleEhsEndChangeRaw(event.target.value)
              }
              onBlur={event => this.handleEhsEndFocousOut(event.target.value)}
            />
          </Form.Group>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarShoolDates);
