import moment from 'moment-timezone';

// Import the settings.
import { settings } from '../config';

// Set to moment timezone.
moment.tz(settings.defaultTimeZone);

// Set the default format.
const defaultDateFormat = settings.defaultDateFormat;
const defaultDateTimeFormat = `${defaultDateFormat} ${settings.defaultTimeFormat}`;

const defaultTimeFormat = settings.defaultTimeFormat;
const defaultYearFormat = settings.defaultYearFormat;

const getMoment = date => moment(date);

const getTodayDate = (format = defaultDateFormat) =>
  moment.utc().format(format);

const getYesterdayDate = (format = defaultDateFormat) =>
  moment.utc().format(format);

const getUTCDate = date => moment(new Date(date)).utc();

const formatDate = (date, format = defaultDateFormat) =>
  moment(new Date(date)).format(format);

const formatDateTime = (date, format = defaultDateTimeFormat) =>
  moment(new Date(date)).format(format);

const formatTime = (date, format = defaultTimeFormat) =>
  moment(new Date(date)).format(format);

const formatUtcDate = (date, format = defaultDateFormat) =>
  moment(new Date(date))
    .utc()
    .format(format);

const formatUtcDateTime = (date, format = defaultDateTimeFormat) =>
  moment(new Date(date))
    .utc()
    .format(format);

const getFutureDate = (days, day) => {
  let date = moment()
    .add(days, 'days')
    .format(defaultDateFormat);

  if (day) {
    date = moment(new Date(day))
      .add(days, 'days')
      .format(defaultDateFormat);
  }

  return date;
};

const formatYear = date => {
  let year = moment().format(defaultDateFormat);

  if (year) {
    year = moment(new Date(date)).format(defaultYearFormat);
  }

  return year;
};

const getPerviousMonthsDate = n => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const d = date.getDate();

  let firstDay =
    moment(new Date(y, m - n, d)).format(defaultDateFormat) + 'T00:00:00Z';

  return new Date(firstDay);
};

const getThisMonthDatesQuery = () => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay =
    moment(new Date(y, m, 1)).format(defaultDateFormat) + 'T00:00:00.000Z';
  const lastDay =
    moment(new Date(y, m + 1, 0)).format(defaultDateFormat) + 'T23:59:59.000Z';
  // const newDate = new Date(y, m, 1);

  return { $lte: new Date(lastDay), $gte: new Date(firstDay) };
};

const getPerviousMonthsDatesQuery = n => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay =
    moment(new Date(y, m - n, 1)).format(defaultDateFormat) + 'T00:00:00Z';
  const lastDay =
    moment(new Date(y, m, 0)).format(defaultDateFormat) + 'T23:59:59Z';

  return { $lte: new Date(lastDay), $gte: new Date(firstDay) };
};

const getExactMonthBackQuery = n => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const d = date.getDate();
  const firstDay =
    moment(new Date(y, m - n, d)).format(defaultDateFormat) + 'T00:00:00Z';
  const lastDay =
    moment(new Date(y, m, d)).format(defaultDateFormat) + 'T23:59:59Z';

  return { $lte: new Date(lastDay), $gte: new Date(firstDay) };
};

const getMonthsDatesQuery = m => {
  const date = new Date();
  const y = date.getFullYear();
  const firstDay =
    moment(new Date(y, m, 1)).format(defaultDateFormat) + 'T00:00:00Z';
  const lastDay =
    moment(new Date(y, m + 1, 0)).format(defaultDateFormat) + 'T23:59:59Z';

  return { $lte: new Date(lastDay), $gte: new Date(firstDay) };
};

const getFirstDayOfMonthsDatesQuery = m => {
  const date = new Date();
  const y = date.getFullYear();
  const firstDay =
    moment(new Date(y, m, 1)).format(defaultDateFormat) + 'T00:00:00Z';
  // const lastDay =
  //   moment(new Date(y, m + 1, 0)).format(defaultDateFormat) + 'T23:59:59Z';

  return { $lte: new Date(firstDay) };
};

const getBalanceDueDate = req => {
  const date = new Date();
  const day = date.getDate();

  if (req.setting) {
    let paymentPeriod = req.setting.payments;
    let startDay;
    if (paymentPeriod === 'BiMonthly') {
      if (day <= 15) {
        startDay = 1;
      } else {
        startDay = 16;
      }
    } else {
      startDay = 1;
    }

    date.setDate(startDay);

    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();
    const firstDay =
      moment(new Date(y, m, d)).format(defaultDateFormat) + 'T00:00:00Z';
    const lastDay =
      moment(new Date(y, m, day)).format(defaultDateFormat) + 'T23:59:59Z';

    return { $lte: new Date(lastDay), $gte: new Date(firstDay) };
  }
};

const getDifference = (start, end) => {
  const a = moment(start);
  const b = moment(end);

  let days = a.diff(b, 'days');

  return days;
};

const getTimestampDate = dat => {
  const date = new Date(dat);
  const y = date.getFullYear();
  const m = date.getMonth();
  const d = date.getDate();

  const newDate = new Date(y, m, d, 0, 0, 0);

  return newDate.getTime() / 1000;
};

const getMonthsList = () => moment.months();

const formatCalendarEventDate = (date, format = 'YYYY-MM-DD') =>
  moment(new Date(date)).format(format);

const getFormattedMoment = (date, format = defaultDateFormat) =>
  moment(date, format);

const formatGranteeAvailabilityDates = date => moment.utc(date).endOf('day');

const formatDateWithSeconds = (date, format = 'MM/DD/YYYY HH:mm:ss A') =>
  moment(new Date(date)).format(format);

export default {
  getMoment,
  getTodayDate,
  getYesterdayDate,
  getFutureDate,
  formatDate,
  formatDateTime,
  formatTime,
  formatYear,
  getThisMonthDatesQuery,
  getPerviousMonthsDatesQuery,
  getMonthsDatesQuery,
  getFirstDayOfMonthsDatesQuery,
  getExactMonthBackQuery,
  getPerviousMonthsDate,
  getBalanceDueDate,
  getDifference,
  getTimestampDate,
  formatUtcDate,
  formatUtcDateTime,
  getMonthsList,
  formatCalendarEventDate,
  getFormattedMoment,
  formatGranteeAvailabilityDates,
  getUTCDate,
  formatDateWithSeconds,
};
