import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router';
import {
  Button,
  Form,
  Grid,
  Input,
  Message,
  Radio,
  Segment,
  Select,
} from 'semantic-ui-react';
import 'trix/dist/trix.css';
import Wysiwyg from '../Shared/Wysiwyg';

//Import components
import HelpCategoryModal from './HelpCategoryModal';
import HelpTagModal from './HelpTagModal';

// Import util
import AmsFormLabel from '../../utils/AmsFormLabel';
import enforceRole from '../../utils/EnforceRole';
import SlidingContainer from '../../utils/layout/SlidingContainer';

// Import config.
import { acl } from '../../config';

// Actions
import { fetchCategories } from '../../actions/help/categoryActions';
import {
  addPost,
  createFileModel,
  fetchPost,
  processFiles,
  updatePost,
  uploadAttachment,
} from '../../actions/help/postActions';
import { fetchTags } from '../../actions/help/tagActions';

// Style
import './assets/style.css';

const HelpPostCreatePage = props => {
  const { categories, tags, post } = useSelector(state => state.help);
  const [id, setId] = useState(undefined);
  const [content, setContent] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [postData, setData] = useState({
    title: '',
    tag: null,
    category: null,
    published: false,
    files: [],
  });

  const dispatch = useDispatch();

  const activeCategories =
    categories &&
    categories.filter(e => {
      return e.active === true;
    });

  useEffect(() => {
    setId(null);
    if (props.router.params.id) {
      setId(props.router.params.id);
      dispatch(fetchPost(props.router.params.id));
    }
    dispatch(fetchCategories());
    dispatch(fetchTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (post !== undefined) {
      setData({
        title: post.title,
        tag: post.tag,
        category: post.category._id,
        published: post.published,
        files: post.files,
      });
      setContent(post.content);
    }
  }, [post]);

  const postFileManagement = (file, action, postDetails) => {
    if (action === 'add') {
      const fileData = {
        postId: 0,
        type: file.mimetype,
        size: file.size,
        path: file.filename,
      };
      dispatch(createFileModel(fileData))
        .then(e => {
          fileArray.push({
            id: e._id,
            path: file.filename,
            type: 'add',
          });
          const newArr = [].concat(fileArray);
          setFileArray(newArr);
        })
        .catch(e => {
          console.log(e);
        });
    } else if (action === 'delete') {
      const path = file.attachment.attributes.values.path;
      if (id) {
        const processedFile = post.files.find(e => {
          return e.path === path;
        });
        if (processedFile) {
          fileArray.push({
            id: processedFile._id,
            path: path,
            type: 'delete',
          });
          const newArr = [].concat(fileArray);
          setFileArray(newArr);
          return;
        }
      }
      const unprocessedIndex = _.findIndex(fileArray, function(o) {
        return o.path === path;
      });

      if (unprocessedIndex !== -1) {
        fileArray.splice(unprocessedIndex, 1);
        setFileArray(fileArray);
      }
    }
  };

  const manageFileUpload = attachment => {
    if (attachment.file) {
      dispatch(uploadAttachment(attachment))
        .then(result => {
          const baseURL = `${window.location.protocol}//${window.location.host}/files/help`;
          attachment.setUploadProgress(100);

          attachment.setAttributes({
            path: result.filename,
            url: `${baseURL}/${result.filename}`,
            href: `${baseURL}/${result.filename}?content-disposition=attachment`,
          });

          postFileManagement(result, 'add');
        })
        .catch(e => console.error(e));
    }
  };

  const handleContentChange = content => {
    if (!_.isEmpty(errors.content)) delete errors.content;
    setContent(content);
  };

  const validate = () => {
    const errors = {};

    if (!postData.title) errors.title = 'Title is required.';
    if (!postData.category) errors.category = 'Category is required.';
    if (!postData.tag) errors.tag = 'Tag is required.';
    if (content.length === 0) {
      errors.content = 'Please provide content to the editor.';
    }

    return errors;
  };

  const saveHelpPost = () => {
    const errors = validate();
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    postData.content = content;
    setLoading(true);
    if (!id) {
      dispatch(addPost(postData))
        .then(response => {
          setLoading(false);
          dispatch(processFiles(fileArray, response._id)).then(e =>
            props.router.push(`/help/${response.category.slug}/${response._id}`)
          );
        })
        .catch(error => {
          setLoading(false);
          setErrors({ content: error.message });
        });
    } else {
      dispatch(updatePost(postData, id))
        .then(res => {
          setLoading(false);
          dispatch(processFiles(fileArray, res._id)).then(e =>
            props.router.push(`/help/${res.category.slug}/${res._id}`)
          );
        })
        .catch(error => {
          setLoading(false);
          setErrors({ content: error.message });
        });
    }
  };

  const showActionButtons = () => {
    const { currentUser } = props;

    return enforceRole(
      <>
        <Button
          primary
          onClick={() => {
            saveHelpPost();
          }}
        >
          Save
        </Button>
        <Button as={Link} href="/help">
          Cancel
        </Button>
      </>,
      acl.actions.help.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  };

  const handleValueChange = (e, { name, value, checked }) => {
    if (name === 'published') {
      value = checked;
    }
    if (!_.isEmpty(errors[name])) delete errors[name];
    setData({ ...postData, [name]: value });
  };

  const generateEditor = () => {
    if (id === undefined || (id !== null && !post)) {
      return;
    }
    return (
      <>
        {showErrorMessage()}
        <Form loading={loading} style={{ marginBottom: '2em' }}>
          <Form.Field
            onChange={(e, data) => {
              handleValueChange(e, data);
            }}
            error={errors.title}
            control={Input}
            placeholder="Title"
            aria-label="Title for Help Article"
            label="Title"
            name="title"
            required
            value={postData.title}
          />
          <Wysiwyg
            onFileUpload={file => {
              if ((post && id !== null) || id !== undefined) {
                manageFileUpload(file);
              }
            }}
            onFileDelete={path => {
              if ((post && id !== null) || id !== undefined) {
                postFileManagement(path, 'delete', post);
              }
            }}
            value={content}
            onChange={handleContentChange}
          />
          <input type="submit" value="submit" hidden />
        </Form>
      </>
    );
  };

  const additionalFormDetails = () => {
    const categoryOptions =
      activeCategories &&
      activeCategories.map(e => {
        return { key: e._id, value: e._id, text: e.title };
      });

    const tagOptions =
      tags &&
      tags.map(e => {
        return {
          key: e._id,
          value: e._id,
          text: e.active ? e.title : `${e.title}(Inactive)`,
        };
      });
    return (
      <Form>
        <Form.Field required error={errors.category}>
          <AmsFormLabel name="Category" fieldLabel="selectCategory">
            <Select
              value={postData.category}
              fluid
              aria-labelledby="selectCategory"
              searchInput={{ id: 'selectCategory' }}
              name="category"
              onChange={(e, data) => {
                handleValueChange(e, data);
              }}
              options={categoryOptions || []}
              placeholder="Select category"
              selection
              search
            />
          </AmsFormLabel>
        </Form.Field>
        <HelpCategoryModal />
        <Form.Field required error={errors.tag}>
          <AmsFormLabel name="Tag" fieldLabel="selectTag">
            <Select
              required
              fluid
              aria-labelledby="selectTag"
              searchInput={{ id: 'selectTag' }}
              value={postData.tag}
              name="tag"
              onChange={(e, data) => {
                handleValueChange(e, data);
              }}
              options={tagOptions || []}
              placeholder="Select Tag"
              selection
              search
            />
          </AmsFormLabel>
        </Form.Field>
        <HelpTagModal />
        <Form.Field
          label={{
            children: 'Published',
            htmlFor: 'articlePublishedToggle',
          }}
          id="articlePublishedToggle"
          checked={postData.published}
          name="published"
          onClick={togglePublish}
          toggle
          control={Radio}
        />
        {showActionButtons()}
      </Form>
    );
  };

  const togglePublish = () => {
    const published = !postData.published;
    setData({ ...postData, published: published });
  };

  const showErrorMessage = () => {
    if (!_.isEmpty(errors.content)) {
      return (
        <Message
          negative
          icon={'cancel'}
          header={'Something went wrong!'}
          content={errors.content}
        />
      );
    }
  };

  return (
    <>
      <SlidingContainer
        calendar
        title={id ? `Edit - ${postData.title}` : 'Create Help Article'}
      >
        <Grid stackable>
          <Grid.Row columns="equal" stretched>
            <Grid.Column width={12}>
              <Segment basic>{generateEditor()}</Segment>
            </Grid.Column>
            <Grid.Column width={4}>{additionalFormDetails()}</Grid.Column>
          </Grid.Row>
        </Grid>

        {/*showErrorMessage()}
        {generateFormInput()}
       
  <Segment basic>{showActionButtons()}</Segment>*/}
      </SlidingContainer>
    </>
  );
};

export default withRouter(HelpPostCreatePage);
