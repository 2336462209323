import React from 'react';
import { Button } from 'react-bootstrap';

// Import utils.
import AmsDateFormatters from './AmsDateFormatters';

import { FieldGroupDatePicker, FieldGroupField } from './FieldGroup';

const statusList = ['Complete', 'In-progress', 'Done'];
const typeList = ['type1', 'type2', 'type3'];

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: AmsDateFormatters.getMoment()
        .startOf('day')
        .subtract(30, 'days')
        .format(),
      endDate: AmsDateFormatters.getMoment()
        .endOf('day')
        .format(),
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handeleDropdownSelect = this.handeleDropdownSelect.bind(this);
  }

  handleStartDateChange(value) {
    this.setState({ startDate: value });
  }

  handleEndDateChange(value) {
    this.setState({ endDate: value });
  }

  handleFilterClick() {
    const { filterData } = this.props;
    filterData(this.state);
  }

  handleResetClick() {
    this.setState({
      startDate: AmsDateFormatters.getMoment()
        .startOf('day')
        .subtract(30, 'days')
        .format(),
      endDate: AmsDateFormatters.getMoment()
        .endOf('day')
        .format(),
      status: '',
      type: '',
    });
  }

  handeleDropdownSelect(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  addStatusFilter() {
    const { status } = this.props;

    if (!status) return;

    return (
      <div className="col-sm-2">
        <FieldGroupField
          id="status"
          bsSize="small"
          label="Status"
          onChange={this.handeleDropdownSelect}
          componentClass="select"
        >
          {statusList.map(status => {
            return (
              <option key={status} value={status}>
                {' '}
                {status}
              </option>
            );
          })}
        </FieldGroupField>
      </div>
    );
  }

  addTypeFilter() {
    const { type } = this.props;

    if (!type) return;

    return (
      <div className="col-sm-2">
        <FieldGroupField
          id="type"
          bsSize="small"
          onChange={this.handeleDropdownSelect}
          label="Type"
          componentClass="select"
        >
          {typeList.map(status => {
            return (
              <option key={status} value={status}>
                {' '}
                {status}
              </option>
            );
          })}
        </FieldGroupField>
      </div>
    );
  }

  addDateFilter() {
    const { date } = this.props;

    if (!date) return;

    return (
      <div>
        <div className="col-sm-4">
          <FieldGroupDatePicker
            bsSize="small"
            placeholder="From"
            value={this.state.startDate}
            onChange={this.handleStartDateChange}
          />
        </div>

        <div className="col-sm-4">
          <FieldGroupDatePicker
            bsSize="small"
            placeholder="To"
            value={this.state.endDate}
            onChange={this.handleEndDateChange}
          />
        </div>
      </div>
    );
  }

  addFilterButton() {
    const { date, status, type } = this.props;

    // Show buttons only if filter props are available.
    if (date || status || type)
      return (
        <div>
          <div className="col-sm-2 align-middle">
            <Button
              className="btn-block"
              onClick={this.handleFilterClick}
              bsStyle="primary"
            >
              Filter
            </Button>
          </div>

          <div className="col-sm-2 align-middle">
            <Button className="btn-block" onClick={this.handleResetClick}>
              Reset
            </Button>
          </div>
        </div>
      );
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 center">
          {this.addStatusFilter()}
          {this.addTypeFilter()}
        </div>
        <div className="col-sm-12">
          {this.addDateFilter()}
          {this.addFilterButton()}
        </div>
      </div>
    );
  }
}

export default Filters;
