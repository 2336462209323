/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Message, Dropdown, Icon } from 'semantic-ui-react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AmsAlert from '../../utils/AmsAlert';
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsTable from '../../utils/AmsTable';

// Import Actions
import {
  fetchClassAssignments,
  addClassAssignments,
  exportClassAssignments,
  resetCenterAssignments,
  resetScores,
  approveSkipClass,
  approveObservationSkip,
  removeClassAssignments,
} from '../../actions/submissions';

import { fetchReviewDetail } from '../../actions/reviewActions';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import enforceRole from '../../utils/EnforceRole.js';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import { acl } from '../../config';
import { Button } from 'react-bootstrap';

class CLASSCenterAssignments extends Component {
  state = {
    assignments: {},
    selectedAssignments: [],
    oid: '',
    classSampleId: '',
  };

  componentDidMount() {
    const reviewId = this.context.router.params.id;
    let input = {
      page: 1,
      limit: 10,
      sortName: 'centerName',
      sortOrder: 'Asc',
      filters: {
        reviewId: reviewId, //"1805555C"
      },
    };
    this.props.fetchClassAssignments(input).then(data => {
      this.setState({
        assignments: {
          ...data.classAssignments,
        },
      });
    });

    this.props.fetchReviewDetail({ reviewId: reviewId });
  }

  handleChange = (e, { name, value }, row, cell) => {
    const data = _.cloneDeep(this.state.assignments.data);
    const index = _.indexOf(
      data,
      _.find(data, { classSampleId: row.classSampleId })
    );
    data[index][name] = value;
    this.setState(
      {
        ...this.state,
        // assignments: {
        //   ...this.state.assignments,
        //    data: data
        // }
      },
      () => {
        const newData = [data[index]].map(row => {
          return _.omit(row, [
            'observationCycleOneFormId',
            'observationCycleTwoFormId',
            'submissions',
            'submissionStatus',
          ]);
        });
        const input = {
          filters: {
            reviewId: this.context.router.params.id,
          },
          data: newData,
        };
        this.props.addClassAssignments(input).then(response => {
          if (response.classAssignmentAdded.result === 'Success') {
            let input = {
              page: 1,
              limit: 10,
              sortName: 'centerName',
              sortOrder: 'Asc',
              filters: {
                reviewId: this.context.router.params.id, //"1805555C"
              },
            };
            if (name === 'obCycleSkipApprove') {
              let skipInput = {
                reviewId: this.context.router.params.id,
                classSampleId: row.classSampleId,
                obCycleSkipApprove: value,
              };
              this.props.approveObservationSkip(skipInput).then(response => {});
            } else if (name === 'classroomSkipApproved') {
              let skipInput = {
                reviewId: this.context.router.params.id,
                classSampleId: row.classSampleId,
                skipApprove: value,
              };
              this.props.approveSkipClass(skipInput).then(response => {
                console.log(response);
              });
            }
            this.props.fetchClassAssignments(input).then(data => {
              this.setState({
                assignments: {
                  ...data.classAssignments,
                },
              });
            });
          }
        });
      }
    );
  };

  resetScoreStatus = () => {
    const input = {
      reviewId: this.context.router.params.id,
      classSampleId: this.state.classSampleId,
      oid: this.state.oid,
    };
    this.props.resetScores(input).then(data => {
      if (data.result === 'Success') {
        let input = {
          page: 1,
          limit: 10,
          sortName: 'centerName',
          sortOrder: 'Asc',
          filters: {
            reviewId: this.context.router.params.id,
          },
        };
        this.props.fetchClassAssignments(input).then(data => {
          this.setState({
            assignments: {
              ...data.classAssignments,
            },
          });
        });
      }
    });
  };

  showResetScoreConfirmationAlert = () => {
    const { userRole } = this.state;
    return (
      <AmsAlert
        show={this.state.showResetScoreConfirmation}
        title={
          userRole === 'Reviewer'
            ? 'Reset Reviewer Scores'
            : 'Reset Dual Coder Scores'
        }
        text={`Note: You are about to reset ${userRole} scores, any data that has been entered will be lost. Are you sure you want to continue?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Continue'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({
            showResetScoreConfirmation: false,
          });
        }}
        onConfirm={() => {
          this.setState(
            {
              showResetScoreConfirmation: false,
            },
            () => this.resetScoreStatus()
          );
        }}
      />
    );
  };

  assigneeReviewerFormatter = (cell, row) => {
    const { assignments } = this.state;
    const reviewers = assignments.reviewerList.map((reviewer, index) => {
      return { key: index, text: reviewer.name, value: reviewer.oid };
    });
    if (row && row.status.toLowerCase() === 'removed') return;
    else
      return (
        <div className="col-sm-12">
          <div className="row">
            <Form.Field>
              <AmsFormLabel
                hidden
                table
                name={`Select Reviwer for ${row.center}`}
                fieldLabel={`reviewerSelect${row.classSampleId}`}
              >
                <Dropdown // label="Select Center"
                  loading={this.props.classAssignmentLoading}
                  fluid
                  searchInput={{ id: `reviewerSelect${row.classSampleId}` }}
                  aria-labelledby={`reviewerSelect${row.classSampleId}`}
                  name="reviewer"
                  onChange={(e, data) => this.handleChange(e, data, row, cell)}
                  options={reviewers}
                  value={row.reviewer}
                  placeholder="Reviewer"
                  selection
                  search
                  selectOnBlur={false}
                  openOnFocus={false}
                />
              </AmsFormLabel>
            </Form.Field>
          </div>
          <div className="row">
            <a
              ref=""
              className="clickable"
              onClick={e => {
                this.setState({
                  showResetScoreConfirmation: true,
                  oid: row.reviewer,
                  classSampleId: row.classSampleId,
                  userRole: 'Reviewer',
                });
              }}
            >
              Reset Observation
            </a>
          </div>
        </div>
      );
  };

  assigneeDualCoderFormatter = (cell, row) => {
    const { assignments } = this.state;
    const dualCoders = assignments.dualCoderList
      ? assignments.dualCoderList.map((dualCoder, index) => {
          return {
            key: index,
            text: dualCoder.name,
            value: dualCoder.oid,
          };
        })
      : [];

    if (row && row.status.toLowerCase() === 'removed') return;
    else
      return (
        <div className="col-sm-12">
          <div className="row">
            <Form.Field>
              <AmsFormLabel
                hidden
                table
                name={`Select Dual Coder for ${row.center}`}
                fieldLabel={`dualCoderSelect${row.classSampleId}`}
              >
                <Dropdown
                  loading={this.props.classAssignmentLoading}
                  aria-labelledby={`dualCoderSelect${row.classSampleId}`}
                  searchInput={{ id: `dualCoderSelect${row.classSampleId}` }}
                  fluid
                  name="dualCoder"
                  onChange={(e, data) => this.handleChange(e, data, row, cell)}
                  options={dualCoders}
                  value={row.dualCoder}
                  placeholder="Dual Coder"
                  selection
                  search
                  selectOnBlur={false}
                  openOnFocus={false}
                />
              </AmsFormLabel>
            </Form.Field>
          </div>
          <div className="row">
            <a
              ref=""
              className="clickable"
              onClick={() =>
                this.setState({
                  showResetScoreConfirmation: true,
                  oid: row.dualCoder,
                  classSampleId: row.classSampleId,
                  userRole: 'Dual Coder',
                })
              }
            >
              Reset Observation
            </a>
          </div>
        </div>
      );
  };

  centerFormatter = (cell, row) => {
    const { assignments } = this.state;

    const centers = assignments.centerClassesList
      ? assignments.centerClassesList.map(center => {
          return {
            key: center.center,
            text: center.center,
            value: center.center,
          };
        })
      : [];
    return (
      <AmsFormLabel
        fieldLabel="replacedCenterDropdown"
        name="Center Dropdown"
        hidden
        table
      >
        <Form.Field
          // label="Select Center"
          loading={this.props.classAssignmentLoading}
          as={Dropdown}
          fluid
          aria-labelledby="replacedCenterDropdown"
          searchInput={{ id: 'replacedCenterDropdown' }}
          name="replacedCenter"
          onChange={(e, data) => this.handleChange(e, data, row)}
          options={centers}
          value={row.replacedCenter}
          placeholder="Center"
          selection
          search
          selectOnBlur={false}
          openOnFocus={false}
        />
      </AmsFormLabel>
    );
  };

  classroomFormatter = (cell, row) => {
    const { assignments } = this.state;
    const center = row.replacedCenter
      ? _.find(assignments.centerClassesList, { center: row.replacedCenter })
      : {};
    const classrooms = center.classes
      ? center.classes.map(classroom => {
          return { key: classroom, text: classroom, value: classroom };
        })
      : [];
    return (
      <Form.Field
        // label="Select Center"
        loading={this.props.classAssignmentLoading}
        as={Dropdown}
        fluid
        name="replacedClassroom"
        onChange={(e, data) => this.handleChange(e, data, row)}
        options={classrooms}
        value={row.replacedClassroom}
        placeholder="Classroom"
        selection
        search
        selectOnBlur={false}
        openOnFocus={false}
      />
    );
  };

  classroomSkipApproval = (cell, row) => {
    if (_.isBoolean(row.classroomSkipApproved)) {
      if (row.classroomSkipApproved) return 'Yes';
      else return 'No';
    } else
      return (
        <AmsFormLabel
          hidden
          table
          name="Select Classroom Skip"
          fieldLabel="skipApprove"
        >
          <Dropdown
            // label="Select Center"
            aria-labelledby="skipApprove"
            loading={this.props.classAssignmentLoading}
            fluid
            name="classroomSkipApproved"
            onChange={(e, data) => this.handleChange(e, data, row)}
            options={[
              { key: 'no', text: 'No', value: false },
              { key: 'yes', text: 'Yes', value: true },
            ]}
            placeholder="Approval"
            selection
            searchInput={{ id: 'skipApprove' }}
            search
            value={row.classroomSkipApproved}
            selectOnBlur={false}
            openOnFocus={false}
          />
        </AmsFormLabel>
      );
  };

  observationSkipApproval = (cell, row) => {
    if (_.isBoolean(row.obCycleSkipApproved)) {
      if (row.obCycleSkipApproved) return 'Yes';
      else return 'No';
    } else
      return (
        <AmsFormLabel
          name="Approval Observation"
          fieldLabel="cycleSkipApprove"
          table
          hidden
        >
          <Dropdown
            // label="Select Center"
            aria-labelledby="cycleSkipApprove"
            searchInput={{ id: 'cycleSkipApprove' }}
            loading={this.props.classAssignmentLoading}
            fluid
            name="obCycleSkipApprove"
            onChange={(e, data) => this.handleChange(e, data, row)}
            options={[
              { key: 'no', text: 'No', value: false },
              { key: 'yes', text: 'Yes', value: true },
            ]}
            placeholder="Approval"
            selection
            search
            value={row.obCycleSkipApproved}
            selectOnBlur={false}
            openOnFocus={false}
          />
        </AmsFormLabel>
      );
  };

  isExpandableRow = row => {
    if (row.status.toLowerCase() === 'removed') return false;
    else if (
      row.classroomSkipRequested === true ||
      row.replacementRequested === true ||
      row.obCycleSkipRequested === true
    )
      return true;
    else return false;
  };

  skipReasonLogic = (cell, row) => {
    if (row.obCycleSkipRequested) return row.obCycleSkipJustification;
    else if (!row.obCycleSkipRequested && row.classroomSkipRequested)
      return row.classroomSkipReason;
  };

  skipJustificationLogic = (cell, row) => {
    if (row.obCycleSkipRequested) return row.obCycleSkipJustificationReason;
    else if (!row.obCycleSkipRequested && row.classroomSkipRequested)
      return row.classroomSkipReasonGiven;
  };

  // Handle single select
  handleRowSelect = (row, isSelected, e) => {
    const { selectedAssignments } = this.state;
    let selections = [];

    if (isSelected) {
      selections = [...selectedAssignments, row];
    }
    if (!isSelected) {
      selections = _.pull(selectedAssignments, row);
    }

    this.setState({
      ...this.state,
      selectedAssignments: selections,
    });
  };

  // Handle multi select
  handleSelectAll = (isSelected, rows) => {
    const { selectedAssignments } = this.state;
    let selections = [];

    if (isSelected) {
      selections = rows;
    }
    if (!isSelected) {
      selections = _.pull(selectedAssignments, ...rows);
    }

    this.setState({
      ...this.state,
      selectedAssignments: selections,
    });
  };

  resetStatus = () => {
    const input = {
      reviewId: this.context.router.params.id,
      classSampleIdList: this.state.selectedAssignments.map(assignment => {
        return assignment.classSampleId;
      }),
    };
    this.props.resetCenterAssignments(input).then(data => {
      if (data.result === 'Success') {
        let input = {
          page: 1,
          limit: 10,
          sortName: 'centerName',
          sortOrder: 'Asc',
          filters: {
            reviewId: this.context.router.params.id, //"1805555C"
          },
        };
        this.props.fetchClassAssignments(input).then(data => {
          this.setState({
            selectedAssignments: [],
            assignments: {
              ...data.classAssignments,
            },
          });
        });
      }
    });
  };

  removeClassAssignments = () => {
    const input = {
      reviewId: this.context.router.params.id,
      classSampleIdList: this.state.selectedAssignments.map(assignment => {
        return assignment.classSampleId;
      }),
    };
    this.props.removeClassAssignments(input).then(data => {
      if (data.result === 'Success') {
        let input = {
          page: 1,
          limit: 10,
          sortName: 'centerName',
          sortOrder: 'Asc',
          filters: {
            reviewId: this.context.router.params.id, //"1805555C"
          },
        };
        this.props.fetchClassAssignments(input).then(data => {
          this.setState({
            selectedAssignments: [],
            assignments: {
              ...data.classAssignments,
            },
          });
        });
      }
    });
  };

  showResetConfirmationAlert() {
    // Check if Required fileds are added

    return (
      <AmsAlert
        show={this.state.showResetConfirmation}
        title="Reset Assignments"
        text={`Note: You are about to reset this Classroom, any data that has been entered will be lost. Are you sure you want to continue?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Continue'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({
            showResetConfirmation: false,
            skipClassroom: false,
          });
        }}
        onConfirm={() => {
          this.setState(
            {
              showResetConfirmation: false,
            },
            () => this.resetStatus()
          );
        }}
      />
    );
  }

  showRemoveClassroomConfirmationAlert() {
    // Check if Required fileds are added

    return (
      <AmsAlert
        show={this.state.showRemoveClassroomConfirmation}
        title="Remove Classroom Assignments"
        text={`Note: You are about to Remove this Classroom from getting assigned, any data that has been entered will be lost. Are you sure you want to continue?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Continue'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({
            showRemoveClassroomConfirmation: false,
            skipClassroom: false,
          });
        }}
        onConfirm={() => {
          this.setState(
            {
              showRemoveClassroomConfirmation: false,
            },
            () => this.removeClassAssignments()
          );
        }}
      />
    );
  }

  freezeSampleBanner = () => {
    const { selectedReview } = this.props;
    if (
      selectedReview &&
      selectedReview.freezAdhocRpt &&
      (selectedReview.reviewType === 'CLASS' ||
        selectedReview.reviewType === 'AIAN-CLASS' ||
        selectedReview.reviewType === 'PR-CLASS' ||
        selectedReview.reviewType === 'VP-CLASS' ||
        selectedReview.reviewType === 'CLASS-Video' ||
        selectedReview.reviewType === 'AIAN CLASS Self-Observations')
    )
      return (
        <Message info>
          <Icon name="info" />
          <span>
            The CLASS Sample auto-generation process has been frozen as of{' '}
            {AmsDateFormatters.getMoment(selectedReview.freezDate)
              .startOf('day')
              .format('MM/DD/YYYY')}
          </span>
        </Message>
      );
  };

  expandComponent = row => (
    <BootstrapTable bodyContainerClass="no-overflow-table" data={[row]}>
      <TableHeaderColumn dataField="classSampleId" isKey={true} hidden={true}>
        id
      </TableHeaderColumn>
      {(row.obCycleSkipRequested || row.classroomSkipRequested) && (
        <TableHeaderColumn
          dataField={
            row.classroomSkipRequested
              ? '"classroomSkipReason"'
              : 'obCycleSkipJustification'
          }
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataFormat={this.skipReasonLogic}
        >
          Classroom Skip Reason
        </TableHeaderColumn>
      )}
      {(row.obCycleSkipRequested || row.classroomSkipRequested) && (
        <TableHeaderColumn
          dataField={
            row.classroomSkipRequested
              ? 'classroomSkipReasonGiven'
              : 'obCycleSkipJustificationReason'
          }
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataFormat={this.skipJustificationLogic}
        >
          Classroom Skip Justification
        </TableHeaderColumn>
      )}
      {(row.obCycleSkipRequested || row.classroomSkipRequested) && (
        <TableHeaderColumn
          dataField={
            row.classroomSkipRequested
              ? 'classroomSkipApproved'
              : 'obCycleSkipApproved'
          }
          tdAttr={{ className: 'td-dropdown' }}
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataFormat={
            row.obCycleSkipRequested
              ? this.observationSkipApproval
              : this.classroomSkipApproval
          }
        >
          Classroom Skip Approved
        </TableHeaderColumn>
      )}
      {row.replacementRequested && (
        <TableHeaderColumn
          dataField="replacementReason"
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
        >
          Replacement Reason
        </TableHeaderColumn>
      )}
      {row.replacementRequested && (
        <TableHeaderColumn
          dataField="replacementReasonGiven"
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
        >
          Replacement Justification
        </TableHeaderColumn>
      )}
      {row.replacementRequested && (
        <TableHeaderColumn
          dataField="replacedCenter"
          tdAttr={{ className: 'td-dropdown' }}
        >
          Replaced Center
        </TableHeaderColumn>
      )}
      {row.replacementRequested && (
        <TableHeaderColumn
          dataField="replacedClassroom"
          tdAttr={{ className: 'td-dropdown' }}
          tdStyle={{ whiteSpace: 'normal' }}
          thStyle={{ whiteSpace: 'normal' }}
        >
          Replaced Classroom
        </TableHeaderColumn>
      )}
    </BootstrapTable>
  );

  prepareClassAssignmentList = () => {
    const { assignments, selectedAssignments } = this.state;

    const selectRow = {
      mode: 'checkbox', // multi select,
      selected: selectedAssignments.map(item => item.classSampleId),
      unselectable: [],
      columnWidth: 50,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleSelectAll,
      clickToExpand: true,
      clickToSelect: false,
      selectionHeaderRenderer: ({ mode, ...rest }) => {
        return (
          <>
            <span className="visually-hidden">Select All</span>
            <input type={mode} title="Select All Centers" {...rest} />
          </>
        );
      },
      selectionRenderer: ({ mode, ...rest }) => {
        return <input type={mode} title="Select Center" {...rest} />;
      },
    };

    const booleanConvert = (cell, row) => {
      if (cell) return 'Yes';
      else return 'No';
    };

    // eslint-disable-next-line no-unused-vars
    const classroomName = (cell, row) => {
      if (row.fromResidualClassListFlag) return `${cell} (R)`;
      else return cell;
    };

    // eslint-disable-next-line no-unused-vars
    const centerName = (cell, row) => {
      if (row.fromResidualClassListFlag) return `${cell} (R)`;
      else return cell;
    };

    const skipLogic = (cell, row) => {
      if (row.classroomSkipRequested || row.obCycleSkipRequested) return 'Yes';
      else return 'No';
    };

    const columns = [
      { dataField: 'classSampleId', hidden: true, text: 'id' },
      {
        text: 'Center',
        dataField: 'centerName',
      },
      {
        dataField: 'classroomName',
        text: 'Classroom ',
      },
      {
        dataField: 'programOptionVariation',
        text: 'Program Option Variation',
      },
      {
        dataField: 'reviewer',
        text: 'Reviewer',
        headerClasses: 'td-dropdown',
        formatter: this.assigneeReviewerFormatter,
      },
      {
        dataField: 'dualCoder',
        text: 'Dual Coder',
        headerClasses: 'td-dropdown',
        formatter: this.assigneeDualCoderFormatter,
      },
      {
        text: 'Status',
        dataField: 'status',
      },
      {
        dataField: 'classroomSkipRequested',
        formatter: skipLogic,
        text: 'Classroom Skip Requested',
      },
      {
        dataField: 'replacementRequested',
        text: 'Replacement Requested',
        formatter: booleanConvert,
      },
    ];

    const nonExpandable = [];

    if (assignments && assignments.data) {
      for (let m = 0; m < assignments.data.length; m++) {
        if (!this.isExpandableRow(assignments.data[m]))
          nonExpandable.push(assignments.data[m].classSampleId);
      }
    }
    const columRenderer = ({ expanded, rowKey, expandable }) => {
      let content = '';

      if (expandable) {
        content = expanded ? '-' : '+';
      } else {
        content = ' ';
      }
      return <div> {content} </div>;
    };

    const expandRow = {
      renderer: this.expandComponent,
      showExpandColumn: true,
      expandColumnRenderer: columRenderer,
      nonExpandable: nonExpandable,
    };

    return (
      <AmsTable
        data={assignments ? assignments.data : []}
        remote={false}
        total={assignments.data ? assignments.data.length : 0}
        columns={columns}
        keyField="classSampleId"
        loading={this.state.loading}
        expandRow={expandRow}
        selectRow={selectRow}
        pagination={assignments.data ? assignments.data.length > 10 : false}
      />
    );
  };

  exportAssignments = () => {
    this.props.exportClassAssignments(this.context.router.params.id);
  };

  render() {
    const { assignments, currentUser, selectedReview } = this.props;
    const { grantInfo, reviewId } = assignments;
    const grantee = grantInfo ? grantInfo[0] : {};
    return (
      <div>
        <SlidingContainer
          // calendar
          title={
            'Center and Classroom Assignments for Review ID ' +
            this.context.router.params.id
          }
        >
          <div className="well survey-header-info">
            {(grantee || {}).granteeName} ( {(grantee || {}).granteeId} ) |{' '}
            {(selectedReview || {}).reviewType} ( {reviewId} )
          </div>
          {assignments.assignmentChangeFlag && (
            <Message
              negative
              icon="warning"
              header="Notice"
              content={`The New ${
                assignments.sampleLabel
              } Class Sampling report has been generated on ${AmsDateFormatters.formatDate(
                assignments.sampleGenrationDate
              )}. All the earlier assignments made if any based on older version of samples will be replaced.`}
            />
          )}
          {this.showResetConfirmationAlert()}
          {this.showResetScoreConfirmationAlert()}
          {this.showRemoveClassroomConfirmationAlert()}
          {this.freezeSampleBanner()}
          {this.state.assignments && (
            <div className="row">
              <div className="col-sm-12">
                <div className="col-md-2 export-assignments-button">
                  <Button
                    // primary
                    size="small"
                    icon
                    onClick={this.exportAssignments}
                  >
                    Export Assignments
                  </Button>
                </div>
                <div className="col-md-2">
                  <Button
                    // primary
                    size="small"
                    icon
                    onClick={() =>
                      this.setState({ showResetConfirmation: true })
                    }
                  >
                    Reset All Assignments
                  </Button>
                </div>
                {enforceRole(
                  <div className="col-md-2">
                    <Button
                      // primary
                      size="small"
                      icon
                      onClick={() =>
                        this.setState({ showRemoveClassroomConfirmation: true })
                      }
                    >
                      Remove Classrooms
                    </Button>
                  </div>,
                  acl.actions.classDetailButtons.removeClassroom,
                  currentUser.roles
                )}
              </div>
            </div>
          )}
          {this.state.assignments && this.prepareClassAssignmentList()}
        </SlidingContainer>
      </div>
    );
  }
}

CLASSCenterAssignments.propTypes = {};

CLASSCenterAssignments.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    assignments: state.submissions.classAssignments,
    classAssignmentLoading: state.submissions.classAssignmentLoading,
    selectedReview: state.review.selectedReview,
  };
}

export default connect(mapStateToProps, {
  fetchClassAssignments,
  addClassAssignments,
  exportClassAssignments,
  resetCenterAssignments,
  resetScores,
  approveSkipClass,
  approveObservationSkip,
  removeClassAssignments,
  fetchReviewDetail,
})(CLASSCenterAssignments);
