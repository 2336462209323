import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import { Button, Dimmer, Grid, List, Loader, Menu } from 'semantic-ui-react';

//import Layout
import SlidingContainer from '../../utils/layout/SlidingContainer';

// Import util
import enforceRole from '../../utils/EnforceRole';
import { generateActivePostsObject, tagIsActive } from './utils';

// Import config.
import { acl } from '../../config';

import 'trix/dist/trix.css';
import './assets/style.css';

// Actions.
import {
  fetchCategories,
  fetchCategoryDetailsById,
} from '../../actions/help/categoryActions';
import { fetchPost } from '../../actions/help/postActions';
import { fetchTags } from '../../actions/help/tagActions';

const HelpPostPage = ({ router }) => {
  //state management
  const [loading, setLoading] = useState(true);
  const [activePosts, setActivePosts] = useState({});
  const [tagActive, setTagActive] = useState(false);

  //map redux state
  const { post } = useSelector(state => state.help);
  const currentUser = useSelector(state => state.auth.user);
  const { tags } = useSelector(state => state.help);
  const { categoryDetails } = useSelector(state => state.help);

  const { params } = router;
  const dispatch = useDispatch();

  const activeTags =
    tags &&
    tags.filter(e => {
      return e.active === true;
    });

  //fetch post info and tags
  useEffect(() => {
    setLoading(true);
    dispatch(fetchPost(router.params.id));
    dispatch(fetchTags());
    dispatch(fetchCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  //fetch category details for sidebar once post data is present
  useEffect(() => {
    if (post && post._id) {
      setLoading(false);
      dispatch(fetchCategoryDetailsById(post.category._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  //generates data for sidebar once category details is fetched
  useEffect(() => {
    if (
      categoryDetails &&
      activeTags &&
      activeTags.length > 0 &&
      isEmpty(activePosts) &&
      post
    ) {
      const activePostsObj = generateActivePostsObject(
        categoryDetails,
        activeTags,
        false
      );
      setActivePosts(activePostsObj);
      setTagActive(tagIsActive(post.tag, activeTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetails, tags]);

  const renderPost = () => {
    if (post && post.content) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: post.content,
          }}
          className="trix-content"
        ></div>
      );
    }
  };

  const editButton = () => {
    return enforceRole(
      <Button
        color="green"
        content="Edit Post"
        size="large"
        onClick={() =>
          router.push({
            pathname: `/help/${router.params.slug}/${router.params.id}/edit`,
          })
        }
      />,
      acl.actions.help.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  };

  const actionButtons = (
    <>
      <List.Item>
        <List.Content>{editButton()}</List.Content>
      </List.Item>
      {/* <List.Item>
        <List.Content>
          <Label
            circular
            color="orange"
            size="huge"
            style={{
              paddingLeft: '1rem !important',
              paddingRight: '1rem !important',
            }}
          >
            {selectedReview.reviewStatus}
          </Label>
        </List.Content>
      </List.Item> */}
    </>
  );

  const sidebar = (
    <Menu className="faq-sidebar-header" fluid vertical>
      {Object.keys(activePosts).map(e => {
        return activePosts[e].map(p => {
          return (
            <Menu.Item
              key={p._id}
              as={Link}
              href={`/help/${router.params.slug}/${p._id}`}
              name={p.title}
              active={p._id === post._id}
            />
          );
        });
      })}
    </Menu>
  );

  return (
    <SlidingContainer
      sidebarComponent={tagActive && !isEmpty(activePosts) ? sidebar : null}
      calendar
      actionButtons={actionButtons}
      title={post ? post.title : 'Loading...'}
    >
      <Grid stackable>
        <Grid.Row stretched>
          <Grid.Column>
            <Dimmer active={loading} inverted>
              <Loader inverted content="Loading" />
            </Dimmer>
            {renderPost()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </SlidingContainer>
  );
};

export default HelpPostPage;
