import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Row, Col, Nav, NavItem, Tab } from 'react-bootstrap';
import { Header } from 'semantic-ui-react';

// Import actions.
import { getAmsRoles } from '../../actions/adminActions';
import {
  fetchNotes,
  fetchProfileUpdatedDateNotes,
} from '../../actions/noteActions';
import {
  fetchUserProfile,
  fetchUserRoles,
  userProfileFetched,
  userProfileTabSelected,
} from '../../actions/profileActions';

// Import style.
import './assets/style.css';

// Import helper function.

// Import acl.
import { acl } from '../../config';

// Import tabs.
import Tabs from '../Shared/Tabs/Tabs.js';
import { getForm, profileTabs } from './Tabs';

class UserProfile extends Component {
  componentDidMount() {
    const { roles } = this.props.currentUser;
    const { oid } = this.props;

    this.fetchUserSettingsNotes(oid);
    this.props.fetchUserProfile(oid);
    this.props.userProfileTabSelected(this.getDefaultTab());
    // Call Roles only if authorized to access userSettings.
    if (_.intersection(roles, acl.actions.profile.userSettings).length > 0) {
      this.props.getAmsRoles();
      this.props.fetchUserRoles(oid);
    }
  }

  fetchUserSettingsNotes(oid) {
    if (
      this.props.currentUser.roles.includes('AmsAdmi') ||
      this.props.currentUser.roles.includes('HelpDesk') ||
      this.props.currentUser.roles.includes('LogisticsAssociate')
    ) {
      const body = {
        filters: {
          tags: {
            userId: oid,
            type: 'admin_user_settings',
          },
        },
      };

      this.props.fetchNotes(body);
    }
    this.props.fetchProfileUpdatedDateNotes({
      filters: {
        tags: {
          userId: oid,
          type: 'profile_update_date',
        },
      },
    });
  }

  componentWillUnmount() {
    // Clear selection.
    this.props.userProfileFetched({});
  }

  tabClicked = tab => {
    this.props.userProfileTabSelected(tab.key);
  };

  getDefaultTab() {
    const { roles } = this.props.currentUser;
    if (_.intersection(roles, acl.actions.profile.userSettings).length > 0) {
      return 'calendar';
    }
    return 'calendar';
  }

  render() {
    const settings = {
      oid: this.props.oid,
      getForm,
      tabs: profileTabs,
    };
    let userName = this.props.profiles.firstName
      ? `${this.props.profiles.firstName} ${this.props.profiles.lastName}`
      : null;
    return (
      <>
        <Header>{userName}</Header>
        <Tabs type="profile" settings={settings} />
      </>
    );
  }

  /*render() {
    const { roles } = this.props.currentUser;
    const mWidth = Responsive.onlyMobile.maxWidth;

    const gridSettings = (width <= mWidth ? 	{ paneWidth: 16, tabWidth: 16 } : 	{ paneWidth: 12, tabWidth: 4 });

    const panes = profileTabs.map(tab => {
      const menuItem = tab.name;

      const tabContent = enforceRole(
        <Tab.Pane>
          {tab.key === 'calendar'
            ? getForm(tab.key, this.props.oid)
            : getForm(tab.key)}
        </Tab.Pane>,
        tab.allowed,
        roles
      );

      return {
        menuItem,
        render: () => tabContent,
      };
    });


    return (

      <Tab
        grid={gridSettings}
        menu={{ fluid: true, vertical: true }}
        menuPosition="left"
        panes={panes}
      />
    );
  }*/
}

UserProfile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  oid: PropTypes.string.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  fetchUserRoles: PropTypes.func.isRequired,
  userProfileTabSelected: PropTypes.func.isRequired,
  getAmsRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  profiles: state.profile,
});

export default connect(mapStateToProps, {
  fetchUserProfile,
  fetchUserRoles,
  userProfileFetched,
  userProfileTabSelected,
  getAmsRoles,
  fetchNotes,
  fetchProfileUpdatedDateNotes,
})(UserProfile);
