import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
  Button,
  Dimmer,
  Grid,
  Loader,
  Responsive,
  Sidebar,
} from 'semantic-ui-react';

// Import components
import PageTitle from '../../components/Shared/PageTitle/PageTitle';
import GranteeStatusBanner from '../../utils/GranteeStatusBanner';

// Import utils
import AmsSkipLinks from '../../utils/AmsSkipLinks';

// Import style.
import './assets/style.css';

class SlidingContainer extends Component {
  state = { visible: false };

  toggleVisibility = e => {
    e.preventDefault();
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { visible } = this.state;
    const {
      calendar,
      sidebarComponent,
      loading,
      granteeStatusBanner,
    } = this.props;

    const width = sidebarComponent ? 13 : 16;

    const calendarButton = calendar && (
      <>
        <Button
          basic
          icon="calendar"
          onClick={this.toggleVisibility}
          content={visible ? 'Hide Calendar' : 'Show Calendar'}
        />
      </>
    );

    return (
      <>
        <Sidebar.Pushable>
          <Sidebar.Pusher>
            {loading && loading.loader ? (
              <div style={{ height: '100vh' }}>
                <Dimmer active inverted>
                  <Loader
                    inline="centered"
                    content={loading.loadingMessage || 'Loading...'}
                  />
                </Dimmer>
              </div>
            ) : (
              <Responsive>
                <>
                  {this.props.router.location.pathname !== '/' && (
                    <PageTitle
                      calendarButton={calendarButton}
                      {...this.props}
                    />
                  )}

                  <div className="global-spacer">
                    <Grid stackable style={{ minHeight: '450px' }}>
                      {sidebarComponent && (
                        <Grid.Column
                          width={3}
                          role="navigation"
                          aria-label="Page Options"
                        >
                          <AmsSkipLinks
                            links={[
                              {
                                title: 'Skip page options',
                                to: 'content',
                              },
                            ]}
                          />

                          {sidebarComponent}
                        </Grid.Column>
                      )}
                      <Grid.Column
                        id="content"
                        width={width}
                        role="region"
                        aria-label="Main Content"
                      >
                        {granteeStatusBanner && (
                          <GranteeStatusBanner
                            id={this.props.granteeStatusData.id}
                            type={this.props.granteeStatusData.type}
                            view={this.props.granteeStatusData.view || null}
                          />
                        )}
                        {this.props.children}
                      </Grid.Column>
                    </Grid>
                  </div>
                </>
              </Responsive>
            )}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}

SlidingContainer.propTypes = {
  calendar: PropTypes.bool,
  title: PropTypes.string,
};

export default withRouter(SlidingContainer);
