import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import { Helmet } from 'react-helmet';

import {
  Step,
  Checkbox,
  Grid,
  Container,
  Divider,
  Segment,
  Popup,
  Icon,
  Modal,
  Dimmer,
  Loader,
  Form,
  Input,
  TextArea,
  Button,
  Comment,
  Tab,
  Dropdown,
  Header,
  List,
  Message,
} from 'semantic-ui-react';
import { Link } from 'react-router';
import _ from 'lodash';
import DatePicker from 'react-datepicker';

// Import components
import AmsAlert from '../../../utils/AmsAlert';
import ReviewLayout from '../../../utils/layout/ReviewLayout';
import AmsTable from '../../../utils/AmsTable';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsModal from '../../../utils/AmsModal';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';

// Import actions.
import {
  fetchFollowUpFindingDetails,
  fetchFURTeamInfo,
  updateCitationExtendCapDays,
  updateFURs,
  savePlanFollowUpTeamUpdates,
} from '../../../actions/reviewActions';

import { fetchRegionalUsers } from '../../../actions/userActions';

import '../assets/style.css';

const ReviewFollowUpPage = props => {
  const rpmComment = useRef(null);
  const dispatch = useDispatch();
  const followUpFindingDetails = useSelector(
    state => state.review.followupFindingDetails
  );
  const followUpReviews = useSelector(
    state => state.review.furTeamInfo.followUpReviews
  );
  const furTeamInfo = useSelector(state => state.review.furTeamInfo);
  const currentUser = useSelector(state => state.auth.user);

  const [step, setStep] = useState('findingsDetail');
  const [errors, setErrors] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [reviewId, setReviewId] = useState('');
  const [findingDetails, setFindingDetails] = useState({});

  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [textAreaHasValue, setTextAreaHasValue] = useState(false);
  const [followupLeads, setFollowupLeads] = useState([]);
  const [programSpecialists, setProgramSpecialists] = useState([]);

  const [modalData, setModalData] = useState({
    paneNum: 0,
    row: {},
    reason: '',
    dateChange: false,
  });
  const [extendByData, setExtendByData] = useState({
    capDays: 0,
    endDate: null,
    dateChange: false,
    reason: '',
  });
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reasonModalData, setReasonModalData] = useState({});

  const [showCitationModal, setShowCitationModal] = useState(false);
  const [citationModalData, setCitationModalData] = useState({});

  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmationDialogMsg, setConfirmationDialogMsg] = useState('');

  useEffect(() => {
    if (props && props.params && props.params.id) {
      setReviewId(props.params.id);
    }
  }, [props]);

  useEffect(() => {
    if (reviewId && reviewId !== '') {
      dispatch(fetchFollowUpFindingDetails(reviewId));
      dispatch(fetchFURTeamInfo(reviewId));
      fetchUsers();
    }
  }, [reviewId]);

  useEffect(() => {
    if (followUpFindingDetails && !isEmpty(followUpFindingDetails)) {
      const copyFindingDetails = { ...followUpFindingDetails };
      const findingsWithIndex = followUpFindingDetails.findings.map(
        (e, index) => {
          return {
            ...e,
            index,
          };
        }
      );
      copyFindingDetails.findings = findingsWithIndex;
      setFindingDetails(copyFindingDetails);
    }
  }, [followUpFindingDetails]);

  const resetModalData = () => {
    setModalData({
      paneNum: 0,
      row: {},
      reason: '',
      dateChange: false,
      loading: false,
    });
    setErrors({});
    setExtendByData({
      capDays: 0,
      endDate: null,
      dateChange: false,
      reason: '',
    });
  };

  const fetchUsers = () => {
    dispatch(
      fetchRegionalUsers({ reviewId, roleName: 'programSpecialist' })
    ).then(result => {
      setProgramSpecialists(result.payload);
    });
    dispatch(fetchRegionalUsers({ reviewId, roleName: 'followupLead' })).then(
      result => {
        setFollowupLeads(result.payload);
      }
    );
  };

  const renderConfirmation = () => {
    return (
      <AmsAlert
        show={showConfirmationDialog}
        title={'Success!'}
        type="success"
        showConfirm
        text={confirmationDialogMsg}
        onConfirm={() => {
          resetModalData();
          setShowConfirmationDialog(false);
          setShowModal(false);
          dispatch(fetchFollowUpFindingDetails(reviewId));
          dispatch(fetchFURTeamInfo(reviewId));
        }}
      />
    );
  };

  const formatDate = (cell, row) => {
    return cell ? AmsDateFormatters.formatDate(cell) : '';
  };

  const renderExtendTFCModal = () => {
    const { paneNum } = modalData;
    const tfcEndDateMoment = AmsDateFormatters.getMoment(
      AmsDateFormatters.getMoment(modalData.row.tfcEndDate).format('YYYY-MM-DD')
    );
    const panes = [
      {
        menuItem: 'Extend Further',
        render: () => (
          <Tab.Pane>
            {!isEmpty(errors) && (
              <Message
                style={{ width: '95%', margin: '0 auto' }}
                negative
                header={'Error: Something went wrong!'}
              >
                <Icon name="cancel" />
                {errors}
              </Message>
            )}
            <Dimmer active={modalData.loading} inverted>
              <Loader active={modalData.loading} inverted>
                Loading
              </Loader>
            </Dimmer>
            <Form>
              <Form.Field>
                <Checkbox
                  label={{
                    children:
                      'Would you like to change the Follow-up review start and end date without extending the TFC days?',
                  }}
                  name="enabled"
                  toggle
                  aria-label="extendTFCDialog"
                  checked={extendByData.dateChange === true}
                  onChange={(e, { checked }) => {
                    setExtendByData({
                      ...extendByData,
                      dateChange: checked,
                    });
                  }}
                  value={extendByData.dateChange}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field
                  required
                  control={Input}
                  type="number"
                  min="1"
                  step="1"
                  label={{
                    children: 'Enter number of Days',
                    htmlFor: 'capDaysInput',
                  }}
                  aria-labelledby="capDaysInput"
                  value={extendByData.capDays ? extendByData.capDays : null}
                  onChange={(e, { value }) => {
                    if (value !== '' && (value < 1 || value > 365)) {
                      value = value < 0 ? Math.abs(value) : 1;
                    }
                    value = Math.floor(value);
                    const newEndDate =
                      value !== '' ? tfcEndDateMoment.add(value, 'days') : null;
                    setExtendByData({
                      ...extendByData,
                      capDays: value,
                      endDate: newEndDate,
                    });
                  }}
                  id="capDaysInput"
                />
                <Form.Field
                  // width={2}
                  required
                  dateFormat="MM/DD/YYYY"
                  selected={
                    extendByData.endDate
                      ? AmsDateFormatters.getMoment(extendByData.endDate)
                      : AmsDateFormatters.getMoment(modalData.row.tfcEndDate)
                  }
                  onChange={date => {
                    if (date) {
                      setExtendByData({
                        ...extendByData,
                        endDate: AmsDateFormatters.getMoment(date).format(
                          'MM/DD/YYYY'
                        ),
                        capDays: AmsDateFormatters.getMoment(date).diff(
                          tfcEndDateMoment,
                          'days'
                        ),
                      });
                    } else
                      setExtendByData({
                        ...extendByData,
                        endDate: null,
                      });
                  }}
                  value={extendByData.endDate ? extendByData.endDate : ''}
                  name="tfcEndDate"
                  control={DatePicker}
                  fromDate={AmsDateFormatters.getMoment(
                    modalData.row.tfcEndDate
                  ).add(1, 'day')}
                  minDate={AmsDateFormatters.getMoment(
                    modalData.row.tfcEndDate
                  ).add(1, 'day')}
                  label={{
                    children: 'Enter TFC End Date',
                    htmlFor: 'tfcEndDate',
                  }}
                  selectsStart
                  id="tfcEndDate"
                  aria-labelledby="tfcEndDate"
                />
              </Form.Group>
              <Form.Field
                required
                label={'Enter a reason'}
                value={extendByData.reason}
                onChange={(e, { value }) =>
                  setExtendByData({ ...extendByData, reason: value })
                }
                placeholder="Explain the reason(s)"
                control={TextArea}
                name="inputReason"
                rows={5}
              />
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'View Extension History',
        render: () => (
          <Tab.Pane>
            {' '}
            {modalData.row.extensionHistory.length > 0 ? (
              <Comment.Group>
                {modalData.row.extensionHistory.map((e, index) => {
                  return (
                    <Comment>
                      <Comment.Content>
                        {/* <Comment.Metadata>
                          <div>
                          
                          </div>
                  </Comment.Metadata>*/}

                        <Comment.Text>
                          Updated By: {e.who.fullName}
                          <br />
                          Updated Date/Time:{' '}
                          {AmsDateFormatters.formatDateTime(e.updatedTime)}
                          <br />
                          Extended by: {e.extendedBy} day
                          {e.extendedBy > 1 ? 's' : ''}
                          <br />
                          Reason: {e.reason}
                        </Comment.Text>
                      </Comment.Content>
                    </Comment>
                  );
                })}
              </Comment.Group>
            ) : (
              'No extension history found.'
            )}
          </Tab.Pane>
        ),
      },
    ];

    return (
      <AmsModal
        size="tiny"
        className="ams-semantic-modal-fix plan-fu-extension"
        open={showModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowModal(false);
          resetModalData();
        }}
      >
        <Modal.Header>Extension</Modal.Header>

        <Modal.Content>
          <Tab
            panes={panes}
            activeIndex={paneNum}
            onTabChange={(e, { activeIndex }) => {
              setModalData({ ...modalData, paneNum: activeIndex });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              resetModalData();
            }}
            content={paneNum === 0 ? 'Cancel' : 'Close'}
          />
          {paneNum === 0 ? (
            <Button
              disabled={!extendByData.reason || !extendByData.endDate}
              onClick={() => {
                setErrors({});
                setModalData({ ...modalData, loading: true });
                updateCitationExtendCapDaysAction();
              }}
              primary
              content="Submit"
            />
          ) : null}
        </Modal.Actions>
      </AmsModal>
    );
  };

  const updateCitationExtendCapDaysAction = () => {
    const body = {
      _id: modalData.row._id,
      findingIds: modalData.row.findingIds,
      extendedBy: extendByData.capDays,
      newTFCEndDate: extendByData.endDate,
      reviewId: followUpFindingDetails.reviewId,
      dateChangeReason: extendByData.reason,
      dateChange: extendByData.dateChange,
    };

    dispatch(updateCitationExtendCapDays(body))
      .then(() => {
        resetModalData();
        setShowConfirmationDialog(true);
        setConfirmationDialogMsg(
          'TFC End date has been successfully extended.'
        );
      })
      .catch(error => {
        setModalData({ ...modalData, loading: false });
        setErrors('Something went wrong!');
      });
  };

  const isReviewTeamDisabled = row => {
    return ['Shipped', 'Report Signed'].includes(row?.reviewStatus);
  };

  const generateGranteeInfoLink = () => {
    if (isEmpty(findingDetails)) {
      return null;
    }
    let reviewType = findingDetails && findingDetails.reviewType;
    let grantees = ((findingDetails || {}).grantees || []).map(grantee => {
      return ` ${grantee.granteeName} (${grantee.granteeId})`;
    });

    const info = !isEmpty(grantees)
      ? grantees + ' | ' + reviewType + ' ( ' + findingDetails.reviewId + ' ) '
      : null;
    return info ? (
      <div style={{ width: '100%' }}>
        <span style={{ fontSize: '18px', fontFamily: 'PublicSans-Bold' }}>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/review/${findingDetails.reviewId}`}
          >
            {info}
          </Link>
        </span>
        <span style={{ float: 'right' }}>
          <span style={{ fontFamily: 'PublicSans-Bold' }}>Review Status:</span>{' '}
          {findingDetails && findingDetails.reviewStatus}
        </span>
      </div>
    ) : null;
  };

  const findingNumber =
    findingDetails &&
    findingDetails.findings &&
    String(findingDetails.findings.length).padStart(2, '0');

  const renderFindingDetails = () => {
    if (!(findingDetails && findingDetails.findings)) {
      return null;
    }

    const total = findingDetails.findings.length;

    const linkVersion = (cell, row) => {};

    const formatAction = (cell, row, index) => {
      const hasHistory =
        row && row.extensionHistory && row.extensionHistory.length > 0;
      return (
        <div>
          <Popup
            content={'Extend Further'}
            position="top center"
            basic
            on={['hover', 'focus']}
            trigger={
              <Link
                to="#"
                style={{ fontSize: '20px' }}
                onClick={e => {
                  setShowModal(true);
                  setModalData({ paneNum: 0, row, index });
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Icon name="calendar plus outline" />
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Select to Extend Further
                </span>
              </Link>
            }
          />
          {hasHistory && (
            <Popup
              content={'View Extension History'}
              position="top center"
              basic
              on={['hover', 'focus']}
              trigger={
                <Link
                  to="#"
                  style={{
                    fontSize: '20px',
                    textDecoration: 'none',
                    marginLeft: '20px',
                  }}
                  onClick={e => {
                    e.preventDefault();
                    setShowModal(true);
                    setModalData({ paneNum: 1, row, index });
                    e.stopPropagation();
                  }}
                >
                  <Icon name="eye" />
                  <span
                    style={{ transition: 'none 0s ease 0s' }}
                    className="visually-hidden"
                  >
                    Select to View Extension History
                  </span>
                </Link>
              }
            />
          )}
        </div>
      );
    };

    const formatExtendCAPDays = (row, cell) => {
      return <span>{row} Days </span>;
    };

    const formatCitationNumber = (row, cell) => {
      return (
        <div>
          {Array.isArray(row)
            ? row.map((e, index) => {
                return (
                  <span key={index}>
                    <span>{e}</span>
                    <br />
                  </span>
                );
              })
            : null}
        </div>
      );
    };
    const columns = [
      {
        dataField: 'citations',
        headerStyle: { width: '12%' },
        sort: true,
        formatter: formatCitationNumber,
        text: 'Citation #',
      },
      {
        headerStyle: { width: '8%' },
        dataField: 'granteeIds',
        formatter: (row, cell) => (Array.isArray(row) ? row.join(', ') : null),
        sort: true,
        text: 'Grantee ID',
      },
      {
        headerStyle: { width: '5%' },
        dataField: 'findingType',
        sort: true,
        text: 'Type',
      },
      {
        headerStyle: { width: '5%' },
        formatter: (cell, row) => {
          return <span>{String(cell).padStart(2, '0')}</span>;
        },
        dataField: 'totalFindings',
        sort: true,
        text: 'Total Findings',
      },
      {
        formatter: formatExtendCAPDays,
        headerStyle: { width: '12%' },
        text: 'Assigned CAP Days',
        sort: true,
        dataField: 'assignedCapDays',
      },
      {
        formatter: formatExtendCAPDays,
        headerStyle: { width: '12%' },
        text: 'Extended CAP Days',
        sort: true,
        dataField: 'extendedCapDays',
      },
      {
        formatter: formatExtendCAPDays,
        headerStyle: { width: '12%' },
        text: 'Total CAP Days',
        sort: true,
        dataField: 'totalCapDays',
      },

      {
        formatter: row => (row ? AmsDateFormatters.formatDate(row) : 'N/A'),
        dataField: 'tfcStartDate',
        headerStyle: { width: '10%' },
        sort: true,
        text: 'TFC Start Date',
      },
      {
        formatter: row => (row ? AmsDateFormatters.formatDate(row) : 'N/A'),
        headerStyle: { width: '10%' },
        dataField: 'tfcEndDate',
        sort: true,
        text: 'TFC End Date',
      },
      {
        formatter: formatAction,
        text: 'Action',
        headerStyle: { width: '20%' },
        dataField: 'dummy',
        isDummy: true,
      },
    ];

    return (
      <div
        style={{
          overflowX: 'scroll',
        }}
        className="findingDetails-table"
      >
        <AmsTable
          remote={false}
          keyField="index"
          data={findingDetails && findingDetails.findings}
          basic={true}
          sizePerPageOption={false}
          pagination={false}
          total={total}
          columns={columns}
          loading={false}
          showTotal={false}
        />
      </div>
    );
  };

  const renderPlanFollowUpModal = () => {
    const { type, row } = modalData;
    const title = type === 'startDate' ? 'Start Date' : 'End Date';

    const dataToCheckMoment = AmsDateFormatters.getMoment(
      AmsDateFormatters.getMoment(row[type]).format('YYYY-MM-DD')
    );

    return (
      <AmsModal
        size="medium"
        className="ams-semantic-modal-fix plan-fu-team-modal"
        open={showModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowModal(false);
          resetModalData();
        }}
      >
        <Modal.Header>{'Edit Start/End Date'}</Modal.Header>

        <Modal.Content>
          {!isEmpty(errors) && (
            <Message
              style={{
                width: '95%',
                margin: '0 auto',
              }}
              negative
            >
              <Message.Content>
                <Message.Header>Something went wrong!</Message.Header>
                <p>{errors}</p>
              </Message.Content>
            </Message>
          )}
          <Form style={{ marginTop: '10px' }}>
            <Form.Group widths="equal">
              <Form.Field
                label={'Start Date'}
                style={{ marginTop: '10px' }}
                dateFormat="MM/DD/YYYY"
                selected={
                  extendByData['startDate']
                    ? AmsDateFormatters.getMoment(extendByData['startDate'])
                    : AmsDateFormatters.getMoment(row['startDate'])
                }
                onChange={date => {
                  if (date) {
                    setExtendByData({
                      ...extendByData,
                      startDate: AmsDateFormatters.getMoment(date),
                    });
                  } else
                    setExtendByData({
                      ...extendByData,
                      startDate: null,
                    });
                }}
                value={
                  extendByData['startDate'] ? extendByData['startDate'] : null
                }
                placeholder={AmsDateFormatters.getMoment(
                  row['startDate']
                ).format('MM/DD/YYYY')}
                name="dateChange"
                control={DatePicker}
                fromDate={AmsDateFormatters.getMoment(row['startDate']).add(
                  1,
                  'day'
                )}
                minDate={AmsDateFormatters.getMoment(row['startDate']).add(
                  1,
                  'day'
                )}
                selectsStart
                id="dateChangeStartDate"
                aria-labelledby="dateChangeStartDate"
              />
              <Form.Field
                label={'End Date'}
                style={{ marginTop: '10px' }}
                dateFormat="MM/DD/YYYY"
                selected={
                  extendByData['endDate']
                    ? AmsDateFormatters.getMoment(extendByData['endDate'])
                    : AmsDateFormatters.getMoment(row['endDate'])
                }
                onChange={date => {
                  if (date) {
                    setExtendByData({
                      ...extendByData,
                      endDate: AmsDateFormatters.getMoment(date),
                    });
                  } else
                    setExtendByData({
                      ...extendByData,
                      endDate: null,
                    });
                }}
                value={extendByData['endDate'] ? extendByData['endDate'] : null}
                placeholder={AmsDateFormatters.getMoment(row['endDate']).format(
                  'MM/DD/YYYY'
                )}
                name="dateChange"
                control={DatePicker}
                fromDate={AmsDateFormatters.getMoment(row['startDate']).add(
                  1,
                  'day'
                )}
                minDate={AmsDateFormatters.getMoment(row['startDate']).add(
                  1,
                  'day'
                )}
                selectsStart
                id="dateChangeEndDate"
                aria-labelledby="dateChangeEndDate"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label
                  style={{
                    fontFamily: 'PublicSans-Bold',
                    marginBottom: '6px',
                  }}
                  htmlFor={'dateChangeReasonStartDate'}
                >
                  Reason for change(optional):
                </label>

                <Form.TextArea
                  id={'dateChangeReasonStartDate'}
                  control="textarea"
                  style={{ margin: 0, width: '100%' }}
                  placeholder=""
                  rows={6}
                  onChange={e => {
                    setExtendByData({
                      ...extendByData,
                      startDateReason: e.target.value,
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label
                  style={{
                    fontFamily: 'PublicSans-Bold',
                    marginBottom: '6px',
                  }}
                  htmlFor={'dateChangeReasonEndDate'}
                >
                  Reason for change(optional):
                </label>

                <Form.TextArea
                  id={'dateChangeReasonEndDate'}
                  control="textarea"
                  style={{ margin: 0, width: '100%' }}
                  placeholder=""
                  rows={6}
                  onChange={e => {
                    setExtendByData({
                      ...extendByData,
                      endDateReason: e.target.value,
                    });
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
              resetModalData();
            }}
            content={'Cancel'}
          />
          <Button
            disabled={!extendByData['startDate'] && !extendByData['endDate']}
            onClick={() => {
              setErrors({});
              saveEditDateChanges();
            }}
            primary
            content="Save"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const saveEditDateChanges = () => {
    const { type, row, index } = modalData;

    setErrors({});

    const newStartDate = extendByData['startDate']
      ? AmsDateFormatters.getMoment(extendByData['startDate'])
      : AmsDateFormatters.getMoment(row['startDate']);

    const newEndDate = extendByData['endDate']
      ? AmsDateFormatters.getMoment(extendByData['endDate'])
      : AmsDateFormatters.getMoment(row['endDate']);

    if (newStartDate.isSameOrAfter(newEndDate)) {
      setErrors(
        'Please select a start date that is at least a day before the end date.'
      );
      return;
    }
    const followUpReviewsCopy = _.cloneDeep(followUpReviews);

    if (extendByData['startDate'] !== null) {
      followUpReviewsCopy[index]['startDate'] = newStartDate;
    }

    if (extendByData['endDate'] !== null) {
      followUpReviewsCopy[index]['endDate'] = newEndDate;
    }

    if (
      extendByData.startDateReason &&
      extendByData.startDateReason.length > 0
    ) {
      followUpReviewsCopy[index]['startDateChangeReason'] =
        extendByData.startDateReason;
    }

    if (extendByData.endDateReason && extendByData.endDateReason.length > 0) {
      followUpReviewsCopy[index]['endDateChangeReason'] =
        extendByData.endDateReason;
    }

    setDataHasChanged(true);
    setShowModal(false);
    resetModalData();

    dispatch(updateFURs(followUpReviewsCopy, index));
  };

  const renderPlanFollowUpHistoryModal = () => {
    return (
      <AmsModal
        size="tiny"
        className="ams-semantic-modal-fix plan-fu-team-modal"
        open={showHistoryModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowHistoryModal(false);
          setReasonModalData({});
        }}
      >
        <Modal.Header>View History</Modal.Header>
        <Modal.Content>
          {reasonModalData.actionHistory &&
            reasonModalData.actionHistory.map((e, index) => {
              const { type, tags, updatedTime, who } = e;
              const last = index === reasonModalData.actionHistory.length - 1;

              const title =
                type === 'reviewerRequestReasons'
                  ? 'REVIEWER REQUIRED REASONS'
                  : type === 'dateChange'
                  ? 'DATE CHANGED'
                  : 'REVIEWER REQUEST ACCEPTANCE';

              let content;

              if (type === 'reviewerRequestReasons') {
                content = (
                  <div>
                    {tags &&
                      tags.reasons &&
                      tags.reasons.map((e, index) => {
                        let toReturn = `${e}${
                          e === 'Other' ? '(' + tags.otherReason + ')' : ''
                        }${index !== tags.reasons.length - 1 ? ', ' : ''}`;
                        return toReturn;
                      })}
                  </div>
                );
              } else if (type === 'reviewerRequiredAccepted') {
                content = (
                  <div>{tags.accepted === true ? 'Accepted' : 'Declined'}</div>
                );
              } else if (type === 'dateChange') {
                content = (
                  <>
                    <div>
                      <span style={{ fontFamily: 'PublicSans-Bold' }}>
                        {tags.dateChangeType === 'startDate'
                          ? 'Start Date'
                          : 'End Date'}
                      </span>{' '}
                      {tags.initialDate} → {tags.changedDate}
                    </div>
                    <div>
                      Reason:{' '}
                      {tags.description.length === 0 ? 'N/A' : tags.description}
                    </div>
                  </>
                );
              }
              return (
                <>
                  <div
                    style={{
                      textDecoration: 'underline',
                      fontFamily: 'PublicSans-Bold',
                    }}
                  >
                    {title}
                  </div>
                  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    {content}
                  </div>
                  <div>
                    Updated By: {who.fullName} <br></br>
                    Updated Date/Time:{' '}
                    {AmsDateFormatters.formatDateTime(updatedTime)}
                  </div>
                  {!last && <Divider />}
                </>
              );
            })}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowHistoryModal(false);
              setReasonModalData({});
            }}
            content={'Close'}
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const renderUpdateAlert = () => {
    return (
      <AmsAlert
        show={showUpdateAlert}
        title=""
        type="success"
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={
          currentUser.roles.includes('OHSStaff') &&
          currentUser.roles.length === 1
            ? `Reviewer request feedbacks have been sucessfully submitted`
            : `The Follow-up review has been successfully updated`
        }
        onConfirm={() => {
          setShowUpdateAlert(false);
        }}
      />
    );
  };

  const reviewerRequiredReasonModalForm = () => {
    const { row, acceptDeclineFlag, index } = reasonModalData;
    const val =
      row &&
      (row.index || row.index === 0) &&
      followUpReviews[row.index] &&
      followUpReviews[row.index].reviewerRequestReasons &&
      followUpReviews[row.index].reviewerRequestReasons;
    if (!followUpReviews) {
      return null;
    }
    return (
      <>
        <Form noValidate>
          <Form.Field
            label="Select one or many reasons the Regional Office is requesting Additional onsite support"
            placeholder="Select one or many reasons the Regional Office is requesting Additional onsite support"
            style={{
              pointerEvents: acceptDeclineFlag ? 'none' : 'all',
            }}
            value={val}
            search
            selection
            multiple
            name={'reviewerRequestReasons'}
            category={'reviewerRequestReasons'}
            control={AmsLookupDropdown}
            onChange={(e, { value, name }) => {
              const newReviews = followUpReviews.map((review, i) => {
                if (row.index === i) {
                  return { ...review, reviewerRequestReasons: value };
                }
                return review;
              });
              dispatch(updateFURs(newReviews, row.index));
            }}
          />
          {row &&
            (row.index || row.index === 0) &&
            followUpReviews[row.index] &&
            !_.isEmpty(followUpReviews[row.index].reviewerRequestReasons) &&
            followUpReviews[row.index].reviewerRequestReasons.includes(
              'Other'
            ) && (
              <Form.Field
                label="Other, please explain"
                placeholder="please explain the reason(s)"
                style={{
                  pointerEvents: acceptDeclineFlag ? 'none' : 'all',
                }}
                value={
                  row &&
                  (row.index || row.index === 0) &&
                  followUpReviews[row.index] &&
                  followUpReviews[row.index].otherReason &&
                  followUpReviews[row.index].otherReason
                }
                control={TextArea}
                name="otherReason"
                rows={5}
                onChange={(e, { value, name }) => {
                  const FURcopy = _.cloneDeep(followUpReviews);
                  FURcopy[row.index] = {
                    ...FURcopy[row.index],
                    [name]: value,
                  };
                  dispatch(updateFURs(FURcopy, row.index));
                }}
              />
            )}
        </Form>
      </>
    );
  };

  const renderReviewerRequiredReasonModal = () => {
    const { row, acceptDeclineFlag } = reasonModalData;
    return (
      <AmsModal
        size="small"
        centered={false}
        closeOnDimmerClick={false}
        open={showReasonModal}
        className="ams-semantic-modal-fix"
      >
        <Header
          content={!acceptDeclineFlag ? 'Select Reasons' : 'Selected Reasons'}
        />
        <Modal.Content>
          <Modal.Description>
            {reviewerRequiredReasonModalForm()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {!acceptDeclineFlag && (
            <Button
              onClick={() => {
                setReasonModalData({});
                setShowReasonModal(false);
              }}
              content="Add Reason(s)"
              primary
            />
          )}
          {!acceptDeclineFlag && (
            <Button
              onClick={() => {
                const FURcopy = _.cloneDeep(followUpReviews);
                FURcopy[row.index].reviewerRequired = false;
                FURcopy[row.index].reviewerRequestReasons = [];
                FURcopy[row.index].otherReason = '';

                dispatch(updateFURs(FURcopy, row.index));
                setShowReasonModal(false);
                setReasonModalData({});
                setDataHasChanged(true);
              }}
              content="Cancel"
            />
          )}
          {acceptDeclineFlag && (
            <Button
              disabled={row.reviewerRequestAccepted === true ? true : false}
              onClick={() => {
                const FURcopy = _.cloneDeep(followUpReviews);
                FURcopy[row.index] = {
                  ...FURcopy[row.index],
                  reviewerRequestAccepted: true,
                };

                dispatch(updateFURs(FURcopy, row.index));
                setShowReasonModal(false);
                setReasonModalData({});
                setDataHasChanged(true);
              }}
              content={
                row.reviewerRequestAccepted === true ? 'Accepted' : 'Accept'
              }
              primary
            />
          )}
          {acceptDeclineFlag && (
            <Button
              disabled={
                row.reviewerRequestAccepted === true
                  ? false
                  : row.reviewerRequestAccepted === false
                  ? true
                  : false
              }
              onClick={() => {
                const FURcopy = _.cloneDeep(followUpReviews);
                FURcopy[row.index] = {
                  ...FURcopy[row.index],
                  reviewerRequestAccepted: false,
                };

                dispatch(updateFURs(FURcopy, row.index));
                setShowReasonModal(false);
                setReasonModalData({});
                setDataHasChanged(true);
              }}
              content={
                row.reviewerRequestAccepted === false ? 'Declined' : 'Decline'
              }
              negative
            />
          )}
          {acceptDeclineFlag && (
            <Button
              onClick={() => {
                setShowReasonModal(false);
                setReasonModalData({});
              }}
              content="Cancel"
            />
          )}
        </Modal.Actions>
      </AmsModal>
    );
  };

  const userStructure = (oids, users, row) => {
    const team =
      oids &&
      oids.map(oid => {
        let t = {};

        if (users) t = _.find(users, { oid });
        if (_.isEmpty(t)) {
          t = _.find(row, { oid });
        }

        return t;
      });
    return team;
  };

  const handleProgramSpecialistSelect = (ev, data) => {
    const { index } = data;

    const newErrors = { ...errors };

    if (index === -1) return;

    if (!_.isEmpty(errors[index])) {
      newErrors[index].programSpecialist = '';
      setErrors(newErrors);
    }

    const copyFollowUpReviews = _.cloneDeep(followUpReviews);
    const row = copyFollowUpReviews[data.index];

    if (row && row.hasOwnProperty(data.type)) {
      var users = userStructure(data.value, programSpecialists, row);
      row[data.type] = users?.filter(x => x);
    }

    dispatch(updateFURs(copyFollowUpReviews, data.index));
    setDataHasChanged(true);
  };

  const handleFollowupLeadSelect = (ev, data) => {
    const { index } = data;

    const newErrors = { ...errors };

    if (index === -1) return;

    if (!_.isEmpty(errors[index])) {
      newErrors[index].followupLead = '';
      setErrors(newErrors);
    }

    const copyFollowUpReviews = _.cloneDeep(followUpReviews);
    const row = copyFollowUpReviews[data.index];

    if (row && row.hasOwnProperty(data.type)) {
      var users = userStructure(data.value, followupLeads, row);
      row[data.type] = users?.filter(x => x);
    }

    dispatch(updateFURs(copyFollowUpReviews, data.index));
    setDataHasChanged(true);
  };

  const formatProgramSpecialistDropdown = (cell, row, index) => {
    const hasError = errors[index] && !!errors[index].programSpecialist;
    var inactiveProgramSpecialists =
      followUpReviews[index].programSpecialist?.filter(x => x && !x.active) ??
      [];
    var innerProgramSpecialists = inactiveProgramSpecialists.concat(
      programSpecialists
    );

    return currentUser.roles.includes('OHSStaff') &&
      currentUser.roles.length === 1 ? (
      <>
        {followUpReviews[index].programSpecialist
          .map(user => user.fullName)
          .join(',')}
      </>
    ) : (
      <AmsFormLabel
        table
        hidden
        name={`Select Program Specialist for ${row.reviewId}`}
        fieldLabel={`programSpecialistDropdown${index}`}
      >
        <Dropdown
          disabled={!row.isActive || isReviewTeamDisabled(row)}
          className="table-extend-dropdown"
          error={hasError}
          aria-labelledby={`programSpecialistDropdown${index}`}
          searchInput={{
            id: `programSpecialistDropdown${index}`,
          }}
          name="programSpecialists"
          roleName="programSpecialist"
          type="programSpecialist"
          index={index}
          hasSource={index !== 0}
          options={populateUsers(innerProgramSpecialists)}
          value={followUpReviews[index].programSpecialist.map(e => e?.oid)}
          onChange={handleProgramSpecialistSelect}
          placeholder="Select Program Specialist"
          multiple
          selection
          search
          reviewId={findingDetails && findingDetails.reviewId}
        />
      </AmsFormLabel>
    );
  };

  const formatFollowupLeadDropdown = (cell, row, index) => {
    const hasError = errors[index] && !!errors[index].followupLead;
    var inactiveFollowupLeads =
      followUpReviews[index].followupLead?.filter(x => x && !x.active) ?? [];
    var innerFollowupLeads = inactiveFollowupLeads.concat(followupLeads);

    return currentUser.roles.includes('OHSStaff') &&
      currentUser.roles.length === 1 ? (
      <>
        {followUpReviews[index].followupLead
          .map(user => user.fullName)
          .join(',')}
      </>
    ) : (
      <AmsFormLabel
        table
        hidden
        name={`Select Followup Lead for ${row.reviewId}`}
        fieldLabel={`followupLeadDropdown${index}`}
      >
        <Dropdown
          disabled={!row.isActive || isReviewTeamDisabled(row)}
          className="table-extend-dropdown"
          upward={false}
          error={hasError}
          aria-labelledby={`followupLeadDropdown${index}`}
          searchInput={{
            id: `followupLeadDropdown${index}`,
          }}
          name="followupLeads"
          roleName="followupLead"
          type="followupLead"
          index={index}
          hasSource={index !== 0}
          options={populateUsers(innerFollowupLeads)}
          value={followUpReviews[index].followupLead.map(e => e?.oid)}
          onChange={handleFollowupLeadSelect}
          placeholder="Select Followup Lead"
          multiple
          selection
          search
          reviewId={findingDetails && findingDetails.reviewId}
        />
      </AmsFormLabel>
    );
  };

  const populateUsers = users => {
    return users && users.length
      ? users.map(user => ({
          key: user.oid,
          text: `${user.name}`,
          value: user.oid,
        }))
      : [];
  };
  const citationInfoModal = () => {
    const citations = citationModalData && citationModalData.citations;

    const info =
      citations &&
      citations.map(e => {
        for (let i = 0; i < findingDetails.findings.length; i++) {
          let finding = findingDetails.findings[i];

          if (finding.citations.includes(e)) {
            return {
              name: e,
              findingType: finding.findingType,
              startDate: finding.tfcStartDate
                ? formatDate(finding.tfcStartDate)
                : 'N/A',
              endDate: finding.tfcEndDate
                ? formatDate(finding.tfcEndDate)
                : 'N/A',
            };
          }
        }
      });

    return (
      <AmsModal
        size="small"
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowCitationModal(false);
          setCitationModalData(null);
        }}
        open={showCitationModal}
        className="ams-semantic-modal-fix"
      >
        <Header content={'Citation Info'} />
        <Modal.Content>
          <Modal.Description>
            <List divided relaxed>
              {info &&
                info.map(e => {
                  if (!e) {
                    return null;
                  }
                  return (
                    <List.Item>
                      <List.Content>
                        <List.Header>{e.name}</List.Header>
                        <strong> Finding Type:</strong> {e.findingType}
                        <br></br>
                        <strong>TFC Start Date:</strong> {e.startDate}
                        <br></br>
                        <strong>TFC End Date:</strong> {e.endDate}
                      </List.Content>
                    </List.Item>
                  );
                })}
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowCitationModal(false);
              setCitationModalData(null);
            }}
            content="Cancel"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const formatReviewName = (cell, row, index) => {
    return (
      <>
        <span>{cell}</span>
        <br></br>
        <span>
          {row.citations && row.citations.length > 0 && row.isActive && (
            <a
              href="#"
              onClick={e => {
                e.preventDefault();

                setShowCitationModal(true);
                setCitationModalData(row);
              }}
            >
              (View Citations)
            </a>
          )}
        </span>
      </>
    );
  };

  const formatStartDate = (cell, row, index) => {
    return cell ? (
      <span>
        {AmsDateFormatters.formatDate(cell)}{' '}
        {row.isActive && !isReviewTeamDisabled(row) && (
          <Popup
            content={'Edit Start Date'}
            position="top center"
            basic
            on={['hover', 'focus']}
            trigger={
              <Link
                to="#"
                style={{
                  fontSize: '20px',
                  textDecoration: 'none',
                  marginLeft: '20px',
                }}
                onClick={e => {
                  e.preventDefault();
                  setShowModal(true);
                  setModalData({ ...modalData, row, type: 'startDate', index });
                  e.stopPropagation();
                }}
              >
                <Icon name="pencil" />
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Select to Edit Start Date
                </span>
              </Link>
            }
          />
        )}
      </span>
    ) : (
      ''
    );
  };

  const formatEndDate = (cell, row, index) => {
    return cell ? (
      <span>
        {AmsDateFormatters.formatDate(cell)}{' '}
        {row.isActive && !isReviewTeamDisabled(row) && (
          <Popup
            content={'Edit End Date'}
            position="top center"
            basic
            on={['hover', 'focus']}
            trigger={
              <Link
                to="#"
                style={{
                  fontSize: '20px',
                  textDecoration: 'none',
                  marginLeft: '20px',
                }}
                onClick={e => {
                  e.preventDefault();
                  setShowModal(true);
                  setModalData({ ...modalData, row, type: 'endDate', index });
                  e.stopPropagation();
                }}
              >
                <Icon name="pencil" />
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Select to Edit End Date
                </span>
              </Link>
            }
          />
        )}
      </span>
    ) : (
      ''
    );
  };

  const handleReviewTypeSelect = (ev, data) => {
    const { index } = data;

    let newErrors = { ...errors };

    if (index === -1) return;

    if (!_.isEmpty(errors[index])) {
      newErrors[index].reviewType = '';
      setErrors(newErrors);
    }

    handleChange(ev, data);
  };

  const handleChange = (ev, data) => {
    const { index } = data;

    if (index === -1) return;

    const copyFollowUpReviews = _.cloneDeep(followUpReviews);
    const row = copyFollowUpReviews[data.index];

    if (row && row.hasOwnProperty(data.type) && row[data.type] !== data.value) {
      row[data.type] = data.value;
    }
    dispatch(updateFURs(copyFollowUpReviews, data.index));
    setDataHasChanged(true);
  };

  const formatReviewTypeDropdown = (cell, row, index) => {
    const hasError = errors[index] && !errors[index].reviewType;

    return currentUser.roles.includes('OHSStaff') &&
      currentUser.roles.length === 1 ? (
      <>{cell}</>
    ) : (
      <AmsFormLabel
        table
        hidden
        name={`Select recommended review type for ${row.reviewId}`}
        fieldLabel={`recommendedReviewTypeDropdown${index}`}
      >
        <Dropdown
          disabled={!row.isActive || isReviewTeamDisabled(row)}
          value={cell}
          fluid
          aria-labelledby={`recommendedReviewTypeDropdown${index}`}
          upward
          error={hasError}
          selection
          index={index}
          type="reviewType"
          onChange={handleReviewTypeSelect}
          options={[
            { value: 'Onsite', key: 'Onsite', text: 'Onsite' },
            { value: 'Desk', key: 'Desk', text: 'Desk' },
          ]}
          openOnFocus={false}
          defaultOpen={false}
          selectOnBlur={false}
          search
          searchInput={{
            id: `recommendedReviewTypeDropdown${index}`,
          }}
          placeholder={'Select Review Type'}
        />
      </AmsFormLabel>
    );
  };

  const formatRequiredDropdown = (cell, row, index) => {
    if (row.reviewerRequestAccepted || row.reviewerRequestAccepted === false)
      return <>{row.reviewerRequired ? 'Yes' : 'No'}</>;
    else
      return (
        <Dropdown
          disabled={!row.isActive || isReviewTeamDisabled(row)}
          title={'Reviewer Required ' + index}
          value={cell}
          fluid
          selection
          index={index}
          type="reviewerRequired"
          onChange={(ev, data) => {
            const { index } = data;
            if (index === -1) return;

            const followUpRow = followUpReviews[index];
            if (followUpRow['reviewerRequired'] !== data.value) {
              if (data.value === true) {
                setReasonModalData({ row: data });
                setShowReasonModal(true);
              } else {
                setShowReasonModal(false);
                setReasonModalData({});
              }
              const newReviews = followUpReviews.map((review, i) => {
                if (i === index) {
                  return {
                    ...review,
                    reviewerRequestReasons: [],
                    otherReason: '',
                    reviewerRequestAccepted: null,
                    reviewerRequired: data.value,
                  };
                }
                return review;
              });
              setDataHasChanged(true);
              dispatch(updateFURs(newReviews, index));
            }
          }}
          options={[
            { value: true, key: true, text: 'Yes' },
            { value: false, key: false, text: 'No' },
          ]}
        />
      );
  };

  const formatRequestAcceptDecline = (cell, row, index) => {
    return row &&
      row.isActive &&
      row.reviewerRequired &&
      row.reviewerRequestReasons &&
      row.reviewerRequestReasons.length > 0 &&
      (!_.has(row, 'reviewerRequestAccepted') ||
        row.reviewerRequestAccepted === null ||
        row.reviewerRequestAccepted === undefined) &&
      currentUser &&
      (currentUser.roles.includes('AmsAdmi') ||
        currentUser.roles.includes('RegionalProgramManager') ||
        currentUser.roles.includes('OHSStaff') ||
        currentUser.roles.includes('Analyst') ||
        currentUser.roles.includes('TechnicalWriter') ||
        currentUser.roles.includes('Editor') ||
        currentUser.roles.includes('FieldOperationsManager')) ? (
      <Link
        onClick={e => {
          const copyRow = { ...row };
          copyRow.index = index;
          setShowReasonModal(true);
          setReasonModalData({
            ...reasonModalData,
            row: copyRow,
            acceptDeclineFlag: true,
          });
        }}
      >
        {row.reviewerRequestAccepted
          ? 'Accepted'
          : row.reviewerRequestAccepted === false
          ? 'Declined'
          : 'Accept/Decline'}
      </Link>
    ) : (
      <>
        {row && row.reviewerRequestAccepted
          ? 'Accepted'
          : row && row.reviewerRequestAccepted === false
          ? 'Declined'
          : 'N/A'}
      </>
    );
  };

  const formatViewHistoryAction = (cell, row, index) => {
    if (
      (row.actionHistory && row.actionHistory.length === 0) ||
      row.actionHistory === undefined
    ) {
      return null;
    }
    return (
      <Popup
        content={'View History'}
        position="top center"
        basic
        on={['hover', 'focus']}
        trigger={
          <Link
            to="#"
            style={{
              fontSize: '20px',
              textDecoration: 'none',
              marginLeft: '20px',
            }}
            onClick={e => {
              e.preventDefault();
              setShowHistoryModal(true);
              setReasonModalData(row);
              e.stopPropagation();
            }}
          >
            <Icon name="eye" />
            <span
              style={{ transition: 'none 0s ease 0s' }}
              className="visually-hidden"
            >
              Select to View History
            </span>
          </Link>
        }
      />
    );
  };

  const formatReviewId = (cell, row) => {
    if (row.reviewId && row.isActive) {
      return <Link to={`/review/${row.reviewId}`}>{row.reviewId}</Link>;
    } else if (row.reviewId && !row.isActive) {
      return (
        <>
          <Link to={`/review/${row.reviewId}`}>{row.reviewId}</Link>
          <br></br>(Cancelled)
        </>
      );
    } else {
      return 'N/A';
    }
  };

  const renderPlanFollowUpForm = () => {
    const columns = [
      {
        dataField: 'reviewName',
        text: 'Finding Type',
        formatter: formatReviewName,
        headerStyle: { width: '20%' },
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'reviewId',
        text: 'Follow-up Review',
        formatter: formatReviewId,
        headerStyle: { width: '10%' },
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'startDate',
        text: 'Start Date',
        formatter: formatStartDate,
        headerStyle: { width: '20%' },
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'endDate',
        text: 'End Date',
        formatter: formatEndDate,

        headerStyle: { width: '20%' },
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'reviewType',
        text: 'Recommended Review Type',
        formatter: formatReviewTypeDropdown,
        classes: 'always-visible',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'programSpecialist',
        text: 'Program Specialist',
        headerStyle: { width: '20%' },
        formatter: formatProgramSpecialistDropdown,
        classes: 'always-visible',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'followupLead',
        text: 'Assigned FUL',
        headerStyle: { width: '20%' },
        formatter: formatFollowupLeadDropdown,
        classes: 'always-visible',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'reviewerRequired',
        text: 'Reviewer Required',
        headerStyle: { width: '12%' },
        formatter: formatRequiredDropdown,
        classes: 'always-visible',
        headerAttrs: { scope: 'col', tabIndex: -1 },
      },
      {
        dataField: 'acceptDecline',
        isDummyField: true,
        text: 'Accept/Decline',
        formatter: formatRequestAcceptDecline,
        headerAttrs: { scope: 'col', tabIndex: -1 },
        // align: 'right',
      },
      {
        dataField: 'viewHistory',
        isDummyField: true,
        text: 'View History',
        formatter: formatViewHistoryAction,
        headerAttrs: { scope: 'col', tabIndex: -1 },
        // align: 'right',
      },
    ];
    var deactivatedPS = [];
    var deactivatedFUL = [];
    for (var i = 0; i < followUpReviews?.length; i++) {
      if (followUpReviews[i].isActive) {
        deactivatedPS.push.apply(
          deactivatedPS,
          followUpReviews[i].programSpecialist?.filter(ps => ps && !ps.active)
        );
        deactivatedFUL.push.apply(
          deactivatedFUL,
          followUpReviews[i].followupLead?.filter(ps => ps && !ps.active)
        );
      }
    }

    return (
      <>
        {citationInfoModal()}
        <div
          className="planFollowUp-table"
          style={{
            overflowX: 'scroll',
            paddingTop: '150px',
            marginBottom: '10px',
            marginTop: '-150px',
          }}
        >
          {!_.isEmpty(formErrors) ? (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              content={formErrors}
            />
          ) : null}

          {!_.isEmpty(deactivatedPS) ? (
            <Message
              negative
              icon="cancel"
              header=""
              content={[
                'The following Program Specialist(s) [' +
                  uniqBy(deactivatedPS, 'name')
                    .map(x => x.name)
                    .join(', ') +
                  '], assigned on the Follow-up reviews have been deactivated. Please update as appropriate',
              ]}
            />
          ) : null}

          {!_.isEmpty(deactivatedFUL) ? (
            <Message
              negative
              icon="cancel"
              header=""
              content={[
                'The following Follow-up Lead(s) [' +
                  uniqBy(deactivatedFUL, 'name')
                    .map(x => x.name)
                    .join(', ') +
                  '], assigned on the Follow-up reviews have been deactivated. Please update as appropriate',
              ]}
            />
          ) : null}

          <Dimmer active={loading} inverted>
            <Loader active={loading} inverted>
              Loading
            </Loader>
          </Dimmer>

          <AmsTable
            remote={false}
            keyField="_id"
            data={followUpReviews && followUpReviews}
            basic={true}
            sizePerPageOption={false}
            pagination={false}
            total={(followUpReviews && followUpReviews.length) || 0}
            columns={columns}
            showTotal={false}
          />
          <Header as="h2">Notes(*)</Header>
          <span>* indicates a required field</span>
          {generateRPMNotes()}
          <Form>
            <Form.Field>
              <TextArea
                rows="5"
                style={{ width: '100%' }}
                title="Add a note"
                ref={rpmComment}
                placeholder=""
                onChange={(e, data) => {
                  if (!dataHasChanged) setDataHasChanged(true);

                  if (data.value === '') {
                    setTextAreaHasValue(false);
                  } else {
                    setTextAreaHasValue(true);
                  }
                }}
              />
            </Form.Field>
            <Form.Group>
              <Form.Button
                type="submit"
                disabled={!dataHasChanged}
                primary
                onClick={e => {
                  onSubmit();
                }}
                content={'Submit'}
              />
              <Form.Button
                onClick={e => {
                  setLoading(true);
                  setDataHasChanged(false);
                  dispatch(fetchFollowUpFindingDetails(reviewId));
                  dispatch(fetchFURTeamInfo(reviewId)).then(e => {
                    setLoading(false);
                  });
                  fetchUsers();
                }}
                disabled={!dataHasChanged}
                content={'Cancel'}
              />
            </Form.Group>
          </Form>
        </div>
      </>
    );
  };

  const validate = data => {
    const errors = {};

    data.forEach((e, index) => {
      if (_.isEmpty(e.reviewType)) {
        errors[index] = {};
        errors[index].reviewType = 'Please select a Review Type';
      }
      if (_.isEmpty(e.programSpecialist)) {
        errors[index] = errors[index] || {};
        errors[index].programSpecialist = 'Please select a Program Specialist';
      }
      if (_.isEmpty(e.followupLead)) {
        errors[index] = errors[index] || [];
        errors[index].followupLead = 'Please select a Followup Lead';
      }
      if (!isReviewTeamDisabled(e) && e.isActive) {
        var inactivePS = e.programSpecialist?.filter(ps => ps && !ps.active);
        if (!_.isEmpty(inactivePS)) {
          errors[index] = errors[index] || [];
          errors[index].programSpecialist =
            'The following Program Specialist(s) [' +
            uniqBy(inactivePS, 'name')
              .map(x => x.name)
              .join(', ') +
            '], have been deactivated.';
        }
        var inactiveFUL = e.followupLead?.filter(ps => ps && !ps.active);
        if (!_.isEmpty(inactiveFUL)) {
          errors[index] = errors[index] || [];
          errors[index].followupLead =
            'The following Followup Lead(s) [' +
            uniqBy(inactiveFUL, 'name')
              .map(x => x.name)
              .join(', ') +
            '], have been deactivated.';
        }
      }
    });

    return errors;
  };

  const onSubmit = e => {
    setFormErrors({});
    const errors = validate(followUpReviews);

    if (_.isEmpty(errors) && textAreaHasValue === true) {
      let { value } = rpmComment.current.ref.current;
      setLoading(true);

      if (value !== '') {
        const commentObject =
          value === ''
            ? {}
            : {
                note: value,
                submittedBy: _.pick(currentUser, [
                  'oid',
                  'userName',
                  'fullName',
                ]),
                timestamp: AmsDateFormatters.getMoment().utc(),
              };
        furTeamInfo.rpmNotes.push(commentObject);
        rpmComment.current.ref.current.value = '';
      }
      const dataToSend = {
        reviewId,
        rpmNotes: furTeamInfo.rpmNotes,
        followUpReviews,
      };

      dispatch(savePlanFollowUpTeamUpdates(dataToSend))
        .then(result => {
          if (result.status === 200) {
            setShowUpdateAlert(true);
            setDataHasChanged(false);
            dispatch(fetchFollowUpFindingDetails(reviewId));
            dispatch(fetchFURTeamInfo(reviewId)).then(e => {
              setLoading(false);
            });
            fetchUsers();
          }
        })
        .catch(error => {
          const { message } = error;
          setFormErrors(message || 'This request could not be processed.');
          setLoading(false);
        });
    } else {
      setFormErrors(
        'Please select Program Specialist/Followup Lead and Review Type for all Followup Reviews and include a note for every submission.'
      );
    }
  };

  /*updateFollowUpReviews = () => {
    return;
    if (value !== '') {
      const commentObject =
        value === ''
          ? {}
          : {
              note: value,
              submittedBy: _.pick(this.props.currentUser, [
                'oid',
                'userName',
                'fullName',
              ]),
              timestamp: AmsDateFormatters.getMoment().utc(),
            };
      data.rpmNotes.push(commentObject);
      this.rpmComment.ref.current.value = '';
    }
  };*/

  const generateRPMNotes = () => {
    if (!furTeamInfo) {
      return null;
    }
    return furTeamInfo &&
      !_.isEmpty(furTeamInfo) &&
      furTeamInfo.rpmNotes &&
      !_.isEmpty(furTeamInfo.rpmNotes) ? (
      <Comment.Group>
        {furTeamInfo.rpmNotes.map((e, index) => (
          <Comment key={index}>
            <Comment.Content>
              <Comment.Author>
                Added by: {e.submittedBy && e.submittedBy.fullName}
              </Comment.Author>
              <Comment.Text>
                {' '}
                Added Date/Time Stamp:{' '}
                {e.timestamp
                  ? AmsDateFormatters.formatDateTime(e.timestamp)
                  : ''}
                <br />
                Note: {e.note}
              </Comment.Text>
            </Comment.Content>
          </Comment>
        ))}
      </Comment.Group>
    ) : null;
  };

  return (
    <>
      <Helmet>
        <body className="plan-followup-page" />
      </Helmet>

      <ReviewLayout
        type={'Follow-up Reviews'}
        // title={formatReviewPageTitle(selectedReview)}
        title={`Plan your Follow-up Review for  Review ID ${reviewId}`}
        // errors={.errors}
      >
        <Container fluid>
          {renderConfirmation()}
          <Grid stackable>
            <Grid.Row style={{ margin: '5px' }}>
              <>{generateGranteeInfoLink()}</>
            </Grid.Row>
            <Divider
              style={{
                marginLeft: '0px',
                marginRight: '3px',
                marginTop: '0px',
              }}
            />
            <Grid.Row style={{ margin: '5px', zIndex: 100 }}>
              <Step.Group style={{ height: '80px' }} size="large">
                <Step
                  link
                  active={step === 'findingsDetail'}
                  style={{ textDecoration: 'none' }}
                  value="findingsDetail"
                  href="#"
                  onClick={() => {
                    setStep('findingsDetail');
                  }}
                  tabIndex={step === 'findingsDetail' ? '-1' : '0'}
                >
                  <Step.Content>
                    <Step.Title
                      style={
                        step === 'findingsDetail'
                          ? {
                              color: '#245e9d !important',
                            }
                          : {}
                      }
                    >
                      FINDINGS DETAIL ({findingNumber})
                    </Step.Title>
                  </Step.Content>
                </Step>

                <Step
                  value="planFollowUp"
                  active={step === 'planFollowUp'}
                  style={{ textDecoration: 'none' }}
                  href="#"
                  link
                  onClick={() => {
                    setStep('planFollowUp');
                  }}
                  tabIndex={step === 'planFollowUp' ? '-1' : '0'}
                >
                  <Step.Content>
                    <Step.Title
                      style={
                        step === 'findingsDetail'
                          ? {
                              color: '#245e9d !important',
                            }
                          : {}
                      }
                    >
                      PLAN FOLLOW-UP REVIEW TEAM
                    </Step.Title>
                  </Step.Content>
                </Step>
              </Step.Group>
            </Grid.Row>
            <Grid.Row style={{ margin: '5px' }}>
              <Segment basic style={{ width: '100%', maxWidth: '100%' }}>
                {step === 'findingsDetail' ? (
                  <>
                    {renderExtendTFCModal()}

                    {renderFindingDetails()}
                  </>
                ) : step === 'planFollowUp' ? (
                  <>
                    {renderPlanFollowUpForm()}
                    {renderUpdateAlert()}
                    {renderPlanFollowUpHistoryModal()}
                    {renderReviewerRequiredReasonModal()}
                    {renderPlanFollowUpModal()}
                  </>
                ) : null}
              </Segment>
            </Grid.Row>
          </Grid>
        </Container>
      </ReviewLayout>
    </>
  );
};

export default ReviewFollowUpPage;
