import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { List } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router';
import { Message, Icon, Modal, Button } from 'semantic-ui-react';

import { isEmpty } from 'lodash';
// Import utils
import SlidingContainer from './SlidingContainer';
import AmsSlidingContainer from './AmsSlidingContainer';
import ShowErrors from '../ShowErrors';
import AmsModal from '../AmsModal';

// Import utils
import { formatReviewPageTitle, renderSubheader } from '../TextFormatters';
import AmsDateFormatters from '../AmsDateFormatters';
import { granteeName } from '../TextFormatters';

// Import components
import ReviewCards from '../../components/Review/FY19/ReviewCards';
import ReviewEditButton from '../../components/Review/FY19/ReviewInfo/ReviewEditButton';

//import Actions
import { fetchReviewDetail } from '../../actions/reviewActions';

const ReviewLayout = ({
  router,
  children,
  title,
  subheader,
  legacy,
  sidebar,
  selectedReview,
  errors,
  actionButtons,
  loading,
  type,
  granteeDetails,
  enableSideBar,
  handleShowSideBar,
}) => {
  const { params } = router;
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [modalDetails, setModalDetails] = useState([]);
  const [statusText, setStatusText] = useState();
  const [consolidatedModalDetails, setConsolidatedModalDetails] = useState([]);
  const [consolidatedText, setConsolidatedText] = useState();
  const [showModalType, setShowModalType] = useState('');
  const formatDate = input => {
    return AmsDateFormatters.formatDate(input);
  };

  const style = {
    boldInfo: {
      fontFamily: 'PublicSans-Bold',
    },
  };

  useEffect(() => {
    const id = router.params.id || router.params.reviewId;
    if (!selectedReview.reviewId || selectedReview.reviewId !== id) {
      dispatch(fetchReviewDetail({ reviewId: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, selectedReview]);

  useEffect(() => {
    if (granteeDetails) {
      const countArr = [];
      granteeDetails.forEach(e => {
        if (e.type === 'Replaced') {
          countArr.push(e);
        }
      });

      if (countArr.length > 1) {
        setModalDetails(countArr);
        setStatusText(
          `Note: The grants associated with this review have been replaced as of ${formatDate(
            countArr[0].updatedAt
          )}. `
        );
      } else if (countArr.length === 1) {
        const tempGrant = countArr[0];
        setStatusText(`Note: The grant ${
          tempGrant.precedingGranteeId
        } has been replaced
          by ${tempGrant.granteeId} on ${formatDate(tempGrant.updatedAt)}.`);
        setModalDetails([]);
      } else {
        setStatusText('');
      }
    } else if (selectedReview && selectedReview.precedingGranteeId) {
      const grantees = selectedReview.grantees;
      if (isEmpty(grantees)) return null;
      const firstGrantee = grantees[0] || [];
      setModalDetails([]);
      setStatusText(`Note: The grantee ${
        selectedReview.precedingGranteeId
      } has been replaced by{' '}
      ${firstGrantee.granteeId} on ${formatDate(
        selectedReview.granteeReplacementDate
      )}.`);
    } else {
      setStatusText('');
    }
  }, [selectedReview, granteeDetails]);

  useEffect(() => {
    if (granteeDetails) {
      const countArr1 = [];
      granteeDetails.forEach(e => {
        if (e.type === 'Consolidated') {
          countArr1.push(e);
        }
      });

      if (countArr1.length > 1) {
        setConsolidatedModalDetails(countArr1);
        setConsolidatedText(
          `Note: The grants associated with this review have been consolidated as of ${formatDate(
            countArr1[0].updatedAt
          )}. `
        );
      } else if (countArr1.length === 1) {
        const tempGrant = countArr1[0];
        setConsolidatedText(`Note: The grant ${
          tempGrant.precedingGranteeId
        } has been consolidated
          into ${tempGrant.granteeId} on ${formatDate(tempGrant.updatedAt)}.`);
        setConsolidatedModalDetails([]);
      } else {
        setConsolidatedText('');
      }
    } else if (selectedReview && selectedReview.precedingGranteeId) {
      const grantees = selectedReview.grantees;
      if (isEmpty(grantees)) return null;
      const firstGrantee = grantees[0] || [];
      setConsolidatedModalDetails([]);
      setConsolidatedText(`Note: The grantee ${
        selectedReview.precedingGranteeId
      } has been consolidated into{' '}
      ${firstGrantee.granteeId} on ${formatDate(
        selectedReview.granteeReplacementDate
      )}.`);
    } else {
      setConsolidatedText('');
    }
  }, [selectedReview, granteeDetails]);

  const defaultActionButtons = (
    <>
      <List.Item>
        <List.Content>
          <ReviewEditButton />
        </List.Content>
      </List.Item>
    </>
  );

  const generateReviewDateBanner = () => {
    if (
      AmsDateFormatters.getMoment(selectedReview.startDate)
        .startOf('day')
        .isSameOrBefore(AmsDateFormatters.getMoment().startOf('day'))
    ) {
      return null;
    }

    let prevStart;
    let prevEnd;

    if (
      selectedReview &&
      selectedReview.ReviewDateHistory &&
      selectedReview.ReviewDateHistory.length > 0
    ) {
      const history = selectedReview.ReviewDateHistory;
      const last = history[history.length - 1];

      prevStart = last.prevStartDate;
      prevEnd = last.prevEndDate;

      if (!prevStart || !prevEnd) {
        return null;
      }

      return (
        <Message style={style.boldInfo} info>
          <Icon name="info" />
          <span>Note:</span> The review dates have been changed from{' '}
          <span>
            ({formatDate(prevStart)} - {formatDate(prevEnd)})
          </span>{' '}
          to{' '}
          <span>
            ({formatDate(selectedReview.startDate)} -{' '}
            {formatDate(selectedReview.endDate)})
          </span>
          .
        </Message>
      );
    }
  };

  const generateGranteeBanner = () => {
    if (!statusText) {
      return null;
    }
    return (
      <>
        <Message style={style.boldInfo} info>
          <Icon name="info" />
          <span>
            {statusText}
            {modalDetails.length > 0 && (
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  setShowModal(true);
                  setShowModalType('replaced');
                }}
              >
                Click for more information.
              </Link>
            )}
          </span>
        </Message>
      </>
    );
  };

  const generateConsolidatedGranteeBanner = () => {
    if (!consolidatedText) {
      return null;
    }
    return (
      <>
        <Message style={style.boldInfo} info>
          <Icon name="info" />
          <span>
            {consolidatedText}
            {consolidatedModalDetails.length > 0 && (
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  setShowModal(true);
                  setShowModalType('consolidated');
                }}
              >
                Click for more information.
              </Link>
            )}
          </span>
        </Message>
      </>
    );
  };

  const renderModal = () => {
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {showModalType === 'replaced'
          ? renderGranteeDetails('Replaced')
          : showModalType === 'consolidated'
          ? renderGranteeDetails('Consolidated')
          : null}
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            content="Close"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const renderGranteeDetails = type => {
    const listDetails =
      type === 'Replaced' ? modalDetails : consolidatedModalDetails;
    const verb = type === 'Replaced' ? 'by' : 'into';
    return (
      <>
        <Modal.Header>Grantee Details</Modal.Header>
        <Modal.Content>
          <List divided relaxed>
            {listDetails.map((e, index) => {
              return (
                <List.Item key={index}>
                  <List.Content>
                    <List.Header>
                      {granteeName({
                        granteeId: e.precedingGranteeId,
                        granteeName: e.granteeName,
                      })}
                    </List.Header>
                    {e.type} {verb} {e.granteeId} on {formatDate(e.updatedAt)}
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        </Modal.Content>
      </>
    );
  };

  return enableSideBar ? (
    <AmsSlidingContainer
      sidebarComponent={!legacy && <ReviewCards />}
      calendar
      title={title || formatReviewPageTitle(selectedReview, type || true)}
      subheader={selectedReview.grantees && renderSubheader(selectedReview.grantees)}
      actionButtons={actionButtons || defaultActionButtons}
      loading={loading}
      granteeStatusBanner={true}
      granteeStatusData={{
        type: 'review',
        id: selectedReview.reviewId,
        view: 'reviewDetail',
      }}
      router={router}
      children={children}
      params={params}
      handleShowSideBar={handleShowSideBar}
    >
      {errors && <ShowErrors errors={errors} />}
      {/*renderGranteeDetailsModal()*/}
      {generateReviewDateBanner()}
      {generateGranteeBanner()}
      {generateConsolidatedGranteeBanner()}
      {renderModal()}

      {children}
    </AmsSlidingContainer>
  ) : (
    <SlidingContainer
      sidebarComponent={!legacy && <ReviewCards />}
      calendar
      title={title || formatReviewPageTitle(selectedReview, type || true)}
      subheader={selectedReview.grantees && renderSubheader(selectedReview.grantees)}
      actionButtons={actionButtons || defaultActionButtons}
      loading={loading}
      granteeStatusBanner={true}
      granteeStatusData={{
        type: 'review',
        id: selectedReview.reviewId,
        view: 'reviewDetail',
      }}
    >
      {errors && <ShowErrors errors={errors} />}
      {/*renderGranteeDetailsModal()*/}
      {generateReviewDateBanner()}
      {generateGranteeBanner()}
      {generateConsolidatedGranteeBanner()}
      {renderModal()}

      {children}
    </SlidingContainer>
  );
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  granteeDetails: state.granteeDetails.status,
});

export default connect(mapStateToProps, {})(withRouter(ReviewLayout));
