import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router';
import { Grid, List } from 'semantic-ui-react';

// Import components.
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import { shortenText } from '../../utils/TextFormatters';

const HelpSearchResults = ({
  results: { page, limit, total, query, data },
}) => {
  // <List.Item>
  //     <List.Description>No results found</List.Description>
  //   </List.Item>

  const resultsList =
    !isEmpty(data) &&
    data.map((post, key) => {
      const slug = post && post.category && post.category.slug;

      return (
        <List.Item key={key}>
          <List.Content>
            <List.Header>
              <Link to={`/help/${slug}/${post._id}`}>{post.title}</Link>
            </List.Header>
            <List.Description>{`Last Updated: ${AmsDateFormatters.formatDate(
              post.updatedDate
            )}`}</List.Description>
          </List.Content>
          <List.Content style={{ marginTop: '0.5em' }}>
            <List.Description>
              {shortenText(post.content, 250)}
            </List.Description>
          </List.Content>
        </List.Item>
      );
    });

  return (
    <Grid>
      <Grid.Row>
        <List relaxed="very">
          {resultsList && (
            <>
              <List.Item>
                <List.Header as="h2">{`Showing ${total} results for "${query}"`}</List.Header>
              </List.Item>

              {resultsList}
            </>
          )}
        </List>
      </Grid.Row>
    </Grid>
  );
};

export default HelpSearchResults;
