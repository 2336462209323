import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserProfile from '../User/UserProfile';

import { selectTab } from '../../actions/adminActions';

class UserProfilePage extends Component {
  componentWillMount() {
    this.props.selectTab({
      key: 3,
      pageTitle: 'Users',
    });
  }

  renderUserProfile() {
    const { userId } = this.props.params;

    if (userId) {
      return <UserProfile oid={userId} />;
    }
    return null;
  }

  render() {
    return this.renderUserProfile();
  }
}

UserProfilePage.propTypes = {
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  { selectTab }
)(UserProfilePage);
