import React, { Component } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import { fetchGranteeStatus } from '../../../actions/granteeActions';
import { uniq } from 'lodash';

class GranteeIdField extends Component {
  state = {
    granteeId: this.props.formData,
  };

  componentDidMount = () => {
    const reviewId = this.context.router.params.reviewId;
    this.props.fetchGranteeStatus(reviewId, 'reviewId');
  };

  render() {
    const { granteeStatus, schema } = this.props;
    const granteeList =
      granteeStatus && uniq(granteeStatus.map(g => g.granteeId));
    const granteeListOptions =
      granteeList &&
      granteeList.map((granteeId, index) => ({
        key: granteeId,
        value: granteeId,
        text: granteeId,
      }));
    return (
      <>
        <Form.Group widths="equal">
          <Form.Field>
            <AmsFormLabel
              name={schema && schema.title}
              helpText={true}
              fieldLabel="granteeId"
            >
              {granteeList && granteeList.length > 1 ? (
                <Dropdown
                  width={5}
                  value={this.state.granteeId}
                  onChange={(e, { value }) => {
                    this.setState({
                      granteeId: value,
                    });
                    this.props.onChange(value);
                  }}
                  options={granteeListOptions}
                  fluid
                  search
                  selection
                  id="granteeId"
                  aria-labelledby="granteeId"
                  openOnFocus={false}
                />
              ) : granteeList && granteeList.length === 1 ? (
                <>
                  <div>{granteeList && granteeList[0]}</div>
                  {this.props.onChange(granteeList[0])}
                </>
              ) : (
                <>{this.props.onChange('')}</>
              )}
            </AmsFormLabel>
          </Form.Field>
        </Form.Group>
      </>
    );
  }
}

GranteeIdField.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    granteeStatus: state.granteeDetails.status,
  };
};

export default connect(mapStateToProps, {
  fetchGranteeStatus,
})(GranteeIdField);
