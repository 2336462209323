import React from 'react';
import { isEmpty } from 'lodash';
export function renderLabelWithCheckbox(
  item,
  handleCheckboxChange,
  questionId,
  index,
  disabled
) {
  return (
    <label
      className={/^[0-9]_/.test(item.label) ? 'sub-options' : 'custom-label'}
    >
      <div className="ui checkbox">
        <input
          className="hidden"
          readOnly
          disabled={disabled}
          tabIndex="0"
          type="checkbox"
          //value=""
          checked={item.checked}
          onChange={() => handleCheckboxChange(questionId, index)}
        />
        <div
          className="custom-checkbox"
          dangerouslySetInnerHTML={{ __html: formatCheckBoxString(item.label) }}
        />
      </div>
    </label>
  );
}

const formatCheckBoxString = label => {
  return label.includes('_')
    ? label.substring(label.lastIndexOf('_') + 1)
    : label;
};

const findingCardClasses = (className, findingStatus) => {
  return `${className} ${
    'DROPPED' === findingStatus || 'Abandoned' === findingStatus
      ? 'disabled-finding-card'
      : ''
  }`;
};

const IsFindingsAvailable = data => {
  return (
    data?.collectDataTypes &&
    Object.values(data).some(obj => Object.keys(obj).length > 0)
  );
};

const determineFindingStatus = (selectedReview, citation) => {
  const isAllParentUnchecked =
    isAllUnchecked(selectedReview, citation) &&
    'New Findings' !== citation.collectDataSubType;

  const isAllNewUnchecked =
    isAllUnchecked(selectedReview, citation) &&
    'New Findings' === citation.collectDataSubType;

  if (
    ['signed', 'Shipped'].includes(selectedReview?.reviewStatus) ||
    ['finalized'].includes(selectedReview?.reportStage) ||
    isAllParentUnchecked ||
    isAllNewUnchecked
  ) {
    return 'DROPPED';
  }
  return citation?.findingStatus;
};

const isAllUnchecked = (selectedReview, citation) => {
  return (
    'Follow-up' === selectedReview.reviewType &&
    !isEmpty(citation?.collectedData) &&
    citation.collectedData.every(arr =>
      arr.collectedOptions.every(item => !item.checked)
    )
  );
};

const prependStringToKeys = (obj, prefix) => {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = `${prefix} - ${key}`;
      result[newKey] = obj[key];
    }
  }
  return result;
};

export {
  formatCheckBoxString,
  findingCardClasses,
  IsFindingsAvailable,
  determineFindingStatus,
  prependStringToKeys,
  isAllUnchecked,
};
