import React from 'react';

function ItalicField({ schema }) {
  let title = schema.title;
  if (
    title.includes('INAPPROPRIATE DISCIPLINE') ||
    title.includes('UNACCEPTABLE DISCIPLINE') ||
    title.includes('Inappropriate Discipline') ||
    title.includes('Potential Child Abuse and/or Maltreatment')
  ) {
    title = makeBold(title);
  }
  return <i>{title}</i>;
}

const makeBold = item => {
  const splitArray = item.split('*');
  return (
    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>
      <b style={{ color: 'rgba(0, 0, 0, 1)' }}>{splitArray[0]}</b>
      {splitArray[1]}
    </span>
  );
};

export default ItalicField;
