import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Radio } from 'semantic-ui-react';

class GranteeCalendarRadioButton extends Component {
  handleClick = () => {
    const { handleMonthClick, month } = this.props;
    handleMonthClick(month);
  };

  render() {
    const { monthWasEdited, currentlySelectedMonth, month } = this.props;
    return (
      <Form.Field width={3}>
        <Radio
          label={
            monthWasEdited ? (
              <label>
                {month} <span className="fa fa-check" />
              </label>
            ) : (
              month
            )
          }
          name="selectedStateGroup"
          value={month}
          className="radio-toolbar"
          checked={currentlySelectedMonth === month}
          onChange={this.handleClick}
        />
      </Form.Field>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(GranteeCalendarRadioButton);
