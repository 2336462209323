import axios from 'axios';

export const GET_SURVEY = 'GET_SURVEY';

export function getSurvey(surveys) {
  return {
    type: GET_SURVEY,
    surveys,
  };
}

export function fetchSurvey(userId) {
  return dispatch => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/survey/mySurveys/${userId}`)
      // axios.post(`/survey`, surveyFilter)
      .then(function(response) {
        dispatch(getSurvey(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}
