import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

class GranteecalendarSaveMultiMessage extends Component {
  handleDismiss = () => {
    const { closeSaveMultiMessage } = this.props;
    closeSaveMultiMessage();
  };

  renderGranteecalendarSaveMultiMessage() {
    let { showSavedMultiMessage } = this.props;
    return showSavedMultiMessage ? (
      <div>
        <Message
          onDismiss={this.handleDismiss}
          header="The changes you have made have been saved."
          content="This was not a final submit and you can continue making changes to grantees.  Please select another Grantee to update from the drop down above."
        />
      </div>
    ) : (
      ''
    );
  }

  render() {
    return <div> {this.renderGranteecalendarSaveMultiMessage()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteecalendarSaveMultiMessage);
