import React from 'react';
import { Card, Menu } from 'semantic-ui-react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

const ReviewPlanFollowUp = ({ selectedReview }) => {
  if (selectedReview.showPlanFollowup) {
    return (
      <Menu.Item
        as={Link}
        to={`/review/${selectedReview.reviewId}/plan-follow-up`}
      >
        Plan Follow-up Review
      </Menu.Item>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  survey: state.survey,
});

export default connect(mapStateToProps)(ReviewPlanFollowUp);
