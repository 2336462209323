import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  Item,
  List,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';

// Import components.
import AmsFormLabel from './AmsFormLabel';
import AmsLookupDropdown from './AmsLookupDropdown';
import AmsModal from './AmsModal';
import AmsTable from './AmsTable';
import AmsUserCard from './AmsUserCard';

// Import actions.
import {
  fetchUnscheduledReviewers,
  reviewerPositionFetched,
  unscheduledReviewersCleared,
} from '../actions/reviewActions';

//Import utils
import AmsAlert from '../utils/AmsAlert';
import AmsDateFormatters from '../utils/AmsDateFormatters';
import enforceRole from '../utils/EnforceRole';
import { formatDatesArray } from '../utils/TextFormatters';

// Import configs
import { acl } from '../config';
import './assets/style.css';

const style = {
  skillHeader: {
    fontSize: '.8125rem',
    fontWeight: '400',
    textTransform: 'upperCase',
    color: 'rgba(0,0,0,.6)',
    letterSpacing: '.05em',
    marginBottom: '.5rem',
    marginTop: 0,
  },
};

class ReviewerFilter extends Component {
  state = {
    startDate: null,
    endDate: null,
    reviewType: '',
    grantee: [],
    pendingReviewersLoaded: false,
    filtering: false,
    showSearchModal: false,
    errors: {},
    reviewers: {},
    reviewer: {},
    reviewerDates: [],
    filteredReviewers: [],
    selectedReviewers: [],
    reviewerSearch: {
      name: '',
      selectedReviewers: [],
      checked: [],
      languageSkills: ['English'],
      contentAreaSkills: [],
      otherSkills: [],
    },
    wizardStep: 'numberOfReviewers',
    currentStepIndex: 1,
    duplicateReviewer: false,
    name: '',
    advancedSearch: false,
    willingToTravel: [],
    scheduleTypes: ['available'],
    userTypes: ['Peer Reviewer', 'Consultant'],
    regions: [],
    roles: [],
    advancedStartDate: null,
    advancedEndDate: null,
    page: 1,
    limit: 10,
    sortName: 'currentFY',
    sortOrder: 'asc',
    editReviewer: false,
  };

  componentWillReceiveProps = nextProps => {
    const {
      pendingReviewers,
      pendingReviewerOptions,
      startDate,
      endDate,
      edit,
      reviewType,
      grantee,
      runPresetSearch,
      selectedPosition,
      granteeRegions,
      editReviewer,
    } = nextProps;

    if (editReviewer !== false) {
      if (this.state.editReviewer === true) return;
      this.setState(
        {
          editReviewer: true,
          selectedPosition: selectedPosition ? selectedPosition : {},
        },
        () => {
          this.fetchData();
        }
      );
      return;
    } else {
      this.setState({
        editReviewer: false,
      });
    }

    if (
      !_.isEmpty(selectedPosition) &&
      runPresetSearch &&
      !this.state.filtering
    ) {
      this.setState(
        {
          showSearchModal: nextProps.showSearchModal,
          selectedPosition,
          runPresetSearch,
          regions: granteeRegions,
        },
        () => {
          this.fetchData();
        }
      );
    }

    // Preload unscheduled reviewers during new review creation
    if (
      !this.state.pendingReviewersLoaded ||
      this.state.startDate !== startDate ||
      this.state.endDate !== endDate
    ) {
      // this.setState({ startDate, endDate }, () => this.fetchData());
      this.setState({
        startDate,
        endDate,
        reviewType,
        grantee,
      });
    }

    // Load pending reviewers during edit.
    // Set pendingReviewersLoaded to true to prevent unnecessary setState.
    if (
      !this.state.pendingReviewersLoaded &&
      edit &&
      pendingReviewerOptions &&
      pendingReviewerOptions.length &&
      pendingReviewers &&
      pendingReviewers.length
    ) {
      const filteredReviewers = _.uniqBy(pendingReviewerOptions, 'key');
      this.setState({
        filteredReviewers,
        reviewers: filteredReviewers.map(reviewer => reviewer.value),
        pendingReviewersLoaded: true,
      });
    }
  };

  handleSearchFilterChange = (e, { name, value }) => {
    this.setState({
      reviewerSearch: { ...this.state.reviewerSearch, [name]: value },
    });
  };

  handleFilterClick = () => {
    this.setState(
      {
        page: 1,
        limit: 10,
        sortName: 'currentFY',
        sortOrder: 'asc',
      },
      () => {
        this.fetchData();
      }
    );
  };

  // Preload data.
  fetchData = () => {
    const {
      startDate,
      endDate,
      reviewType,
      grantee,
      showSearchModal,
      reviewerSearch: { name },
      selectedPosition: { languageSkills, contentAreaSkills, otherSkills },
      advancedSearch,
      advancedEndDate,
      advancedStartDate,
      scheduleTypes,
      userTypes,
      page,
      sortName,
      sortOrder,
      limit,
      regions,
      roles,
      willingToTravel,
    } = this.state;
    if (!_.isEmpty(endDate) && !_.isEmpty(startDate)) {
      let request =
        advancedSearch &&
        !_.isEmpty(advancedEndDate) &&
        !_.isEmpty(advancedStartDate)
          ? {
              startDate: AmsDateFormatters.getMoment(advancedStartDate)
                .startOf('day')
                .format(),
              endDate: AmsDateFormatters.getMoment(advancedEndDate)
                .endOf('day')
                .format(),
            }
          : {
              startDate: AmsDateFormatters.getMoment(startDate)
                .startOf('day')
                .format(),
              endDate: AmsDateFormatters.getMoment(endDate)
                .endOf('day')
                .format(),
            };

      this.setState({ filtering: true });

      // Add filtering parameters.
      if (name) request.name = name;
      if (languageSkills && languageSkills.length)
        request.languageSkills = languageSkills;
      if (contentAreaSkills && contentAreaSkills.length)
        request.contentAreaSkills = contentAreaSkills;
      if (otherSkills && otherSkills.length) request.otherSkills = otherSkills;
      if (reviewType) request.reviewType = reviewType;
      if (!_.isEmpty(grantee)) request.grantee = grantee;

      if (!this.props.editReviewer) {
        if (scheduleTypes) request.scheduleType = scheduleTypes;
        if (userTypes) request.userTypes = userTypes;
        if (regions.length > 0) {
          request.regions = regions.map(region => {
            return Number(region);
          });
        }
        if (willingToTravel !== null) {
          request.willingToTravel = willingToTravel;
        }
      } else {
        request.name = this.props.editReviewer.fullName;
      }
      if (roles.length > 0) {
        request.roles = roles;
      }

      // Only show loader if modal is open.
      if (showSearchModal) this.setState({ filtering: true });
      // this.props.searchFlagToggle(true);
      this.props
        .fetchUnscheduledReviewers({
          filters: { ...request },
          sortName,
          sortOrder,
          limit,
          page,
        })
        .then(() => {
          this.props.reviewerPositionFetched();
          this.setState({ filtering: false });
        })
        .catch(error => {
          this.setState(
            {
              filtering: false,
              errors: {
                ...this.state.errors,
                responseError:
                  error.response &&
                  error.response.data &&
                  error.response.data.message,
              },
            },
            console.log(error)
          );
        });

      this.setState({ pendingReviewersLoaded: true });
    }
  };

  clearSelections = () => {
    this.setState(
      {
        reviewerSearch: {
          name: '',
          selectedReviewers: [],
          checked: [],
          languageSkills: ['English'],
          contentAreaSkills: [],
          otherSkills: [],
        },
        numberOfReviewers: null,
        reviewer: {},
        reviewers: {},
        reviewerDates: [],
        wizardStep: 'numberOfReviewers',
        currentStepIndex: 1,
        advancedSearch: false,
        advancedEndDate: null,
        advancedStartDate: null,
        regions: [],
        roles: [],
        scheduleTypes: ['available'],
        userTypes: ['Peer Reviewer', 'Consultant'],
      },
      () => this.props.unscheduledReviewersCleared([])
    );
  };

  showError = () => {
    const { errors } = this.state;

    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(errorKey => (
          <li key={errorKey}>{errors[errorKey]}</li>
        ))}
      />
    );
  };

  handleStartDateChange = date => {
    this.setState({ ...this.state, advancedStartDate: date });
  };

  handleEndDateChange = date => {
    this.setState({ ...this.state, advancedEndDate: date });
  };

  searchForm = () => {
    const { selectedPosition, advancedSearch } = this.state;

    const willingToTravelOptions = [
      { key: 'yes', text: 'Yes', value: 'Yes' },
      { key: 'no', text: 'No', value: 'No' },
    ];

    return (
      <>
        {/* <Well className=""> */}

        <List horizontal>
          {selectedPosition && !_.isEmpty(selectedPosition.contentAreaSkills) && (
            <List.Item>
              <List.Content>
                <List.Header style={style.skillHeader}>
                  Content Skills
                </List.Header>
                <Header as="h3">
                  {selectedPosition.contentAreaSkills.join(', ')}
                </Header>
              </List.Content>
            </List.Item>
          )}

          {selectedPosition && !_.isEmpty(selectedPosition.languageSkills) && (
            <List.Item>
              <List.Content>
                <List.Header style={style.skillHeader}>
                  Language Skills
                </List.Header>
                <Header as="h3">
                  {selectedPosition.languageSkills.join(', ')}
                </Header>
              </List.Content>
            </List.Item>
          )}

          {selectedPosition && !_.isEmpty(selectedPosition.otherSkills) && (
            <List.Item>
              <List.Content>
                <List.Header style={style.skillHeader}>
                  Other Skills
                </List.Header>
                <Header as="h3">
                  {selectedPosition.otherSkills.join(', ')}
                </Header>
              </List.Content>
            </List.Item>
          )}
        </List>

        <Form>
          <Form.Group>
            <Form.Field
              label={{
                children: 'Name',
                htmlFor: 'name',
              }}
              width={advancedSearch ? 4 : 6}
              // required={
              //   _.isEmpty(this.state.reviewerSearch.contentAreaSkills)
              //     ? true
              //     : false
              // }
              name="name"
              control={Input}
              placeholder="Search Name"
              fluid
              disabled={this.props.editReviewer}
              value={
                this.props.editReviewer
                  ? this.props.editReviewer.fullName
                  : this.state.reviewerSearch.name
              }
              onChange={this.handleSearchFilterChange}
              id={'name'}
              aria-labelledby={'name'}
            ></Form.Field>
            {advancedSearch && (
              <>
                <Form.Field
                  width={4}
                  // error={!!errors.startDate}
                  name="startDate"
                  label={{ children: 'Start Date', htmlFor: 'startDate' }}
                  id="startDate"
                  control={DatePicker}
                  placeholder="Please select start Date"
                  selectsStart
                  minDate={this.state.startDate}
                  selected={this.state.advancedStartDate}
                  startDate={this.state.startDate}
                  maxDate={this.state.endDate}
                  onChange={this.handleStartDateChange}
                />
                <Form.Field
                  width={4}
                  // error={!!errors.endDate}
                  name="endDate"
                  label={{ children: 'End Date', htmlFor: 'endDate' }}
                  id="endDate"
                  control={DatePicker}
                  placeholder="Please select end date"
                  selected={this.state.advancedEndDate}
                  selectsEnd
                  startDate={
                    this.state.advancedStartDate
                      ? this.state.advancedStartDate
                      : this.state.startDate
                  }
                  minDate={
                    this.state.advancedStartDate
                      ? this.state.advancedStartDate
                      : this.state.startDate
                  }
                  endDate={this.state.endDate}
                  maxDate={this.state.endDate}
                  onChange={this.handleEndDateChange}
                />
                <Form.Field width={4}>
                  <AmsFormLabel
                    name="Willing to Travel"
                    fieldLabel="willingToTravel"
                  >
                    <Form.Dropdown
                      value={this.state.willingToTravel}
                      options={willingToTravelOptions}
                      onChange={(e, { value }) =>
                        this.setState({
                          willingToTravel: value,
                        })
                      }
                      multiple
                      clearable
                      selection
                      fluid
                      placeholder="Please select traveling preference"
                      setError={this.setError}
                      id="willingToTravel"
                      aria-labelledby="willingToTravel"
                    />
                  </AmsFormLabel>
                </Form.Field>
              </>
            )}
          </Form.Group>
          {advancedSearch && (
            <Form.Group widths={'equal'}>
              <Form.Field>
                <AmsFormLabel name="Schedule Types" fieldLabel="scheduleType">
                  <AmsLookupDropdown
                    title="Please select schedule type"
                    value={this.state.scheduleTypes}
                    category={'scheduleType'}
                    onChange={(e, { value }) =>
                      this.setState({
                        scheduleTypes: value,
                      })
                    }
                    multiple
                    selection
                    fluid
                    placeholder="Please select schedule type"
                    setError={this.setError}
                    id="scheduleType"
                    aria-labelledby="scheduleType"
                  />
                </AmsFormLabel>
              </Form.Field>
              <Form.Field>
                <AmsFormLabel name="User Types" fieldLabel="userTypes">
                  <AmsLookupDropdown
                    title="Please select user types"
                    value={this.state.userTypes}
                    category={'userTypes'}
                    onChange={(e, { value }) =>
                      this.setState({
                        userTypes: value,
                      })
                    }
                    label={{
                      children: 'User Types',
                      htmlFor: 'userTypes',
                    }}
                    multiple
                    selection
                    fluid
                    placeholder="Please select user types"
                    setError={this.setError}
                    id="userTypes"
                    aria-labelledby="userTypes"
                  />
                </AmsFormLabel>
              </Form.Field>
              <Form.Field>
                <AmsFormLabel name="Roles" fieldLabel="roles">
                  <AmsLookupDropdown
                    title="Please select roles"
                    value={this.state.roles}
                    category={'amsUserRoles'}
                    onChange={(e, { value }) =>
                      this.setState({
                        roles: value,
                      })
                    }
                    label={{
                      children: 'Roles',
                      htmlFor: 'roles',
                    }}
                    multiple
                    selection
                    fluid
                    placeholder="Please select roles"
                    setError={this.setError}
                    id="roles"
                    aria-labelledby="roles"
                  />
                </AmsFormLabel>
              </Form.Field>
              <Form.Field>
                <AmsFormLabel name="Region" fieldLabel="regions">
                  <AmsLookupDropdown
                    title="Please select a region"
                    value={this.state.regions}
                    category={'regions'}
                    onChange={(e, { value }) =>
                      this.setState({
                        regions: value,
                      })
                    }
                    multiple
                    selection
                    placeholder="Please select a region"
                    setError={this.setError}
                    label={{
                      children: 'Region',
                      htmlFor: 'regions',
                    }}
                    id="regions"
                    aria-labelledby="regions"
                  />
                </AmsFormLabel>
              </Form.Field>
            </Form.Group>
          )}
          {!this.props.editReviewer && (
            <Form.Group width={6}>
              <Form.Button
                primary
                content={'Filter'}
                onClick={e => {
                  e.preventDefault();
                  this.handleFilterClick();
                }}
              />

              <Form.Button
                basic
                content="Reset"
                onClick={() => {
                  this.setState(
                    {
                      reviewerSearch: {
                        name: '',
                      },
                      reviewer: {},
                      advancedEndDate: this.state.endDate
                        ? this.state.endDate
                        : null,
                      advancedStartDate: this.state.startDate
                        ? this.state.startDate
                        : null,
                      scheduleTypes: ['available'],
                      roles: advancedSearch ? ['Reviewer'] : [],
                      userTypes: ['Peer Reviewer', 'Consultant'],
                      regions: this.props.granteeRegions,
                      page: 1,
                      limit: 10,
                      sortName: 'currentFY',
                      sortOrder: 'asc',
                    },
                    () => {
                      this.fetchData();
                    }
                  );
                }}
              />

              <Form.Button
                style={{ border: 'none', color: '#245e9d', background: 'none' }}
                content={!advancedSearch ? 'Advanced Search' : 'Basic Search'}
                onClick={() => {
                  this.setState({
                    advancedSearch: !advancedSearch,
                    granteeRegions: this.props.granteeRegions,
                    scheduleTypes: ['available'],
                    userTypes: ['Peer Reviewer', 'Consultant'],
                    roles: advancedSearch ? [] : ['Reviewer'],
                    regions: this.props.granteeRegions,
                    advancedEndDate: this.state.endDate
                      ? this.state.endDate
                      : null,
                    advancedStartDate: this.state.startDate
                      ? this.state.startDate
                      : null,
                  });
                }}
              />
            </Form.Group>
          )}
        </Form>

        {/* </Well> */}
        <Divider />
      </>
    );
  };

  cancelButton = () => {
    return (
      <Button
        basic
        content="Cancel"
        onClick={() => {
          this.clearSelections();
          this.props.searchModalToggle(false);
        }}
      />
    );
  };

  inviteReviewerButton = () => {
    const { reviewerDates, reviewer, selectedPosition } = this.state;

    return (
      <Button
        primary
        content={'Invite Reviewer'}
        disabled={_.isEmpty(reviewer) ? true : false}
        onClick={e => {
          e.preventDefault();
          const {
            selectedPosition: {
              _id,
              languageSkills,
              otherSkills,
              contentAreaSkills,
            },
          } = this.state;

          if (selectedPosition) {
            let selectedReviewer = {
              ...reviewer,
              firstName: reviewer.fullName.split(/\b/)[0],
              lastName: reviewer.fullName.split(/\b/)[2],
              name: reviewer.fullName,
              languageSkills,
              otherSkills,
              contentAreaSkills,
              dates: reviewerDates,
              status: 'pending',
              resendInvite: false,
              invitationDate: AmsDateFormatters.formatDate(
                AmsDateFormatters.getMoment()
              ),
              position: _id,
            };
            this.props.selectedReviewer(selectedReviewer);
            this.clearSelections();
          }
        }}
      />
    );
  };

  addReviewerButton = () => {
    const { reviewerDates, reviewer, selectedPosition } = this.state;

    return (
      <>
        <Button
          primary
          content={'Invite Reviewer'}
          disabled={_.isEmpty(reviewer) ? true : false}
          onClick={e => {
            e.preventDefault();
            if (this.state.editReviewer) {
              this.props.updateReviewer(this.props.editReviewer, {
                dates: this.state.reviewerDates,
                invitationDate: AmsDateFormatters.formatDate(
                  AmsDateFormatters.getMoment()
                ),
                reviewerDatesUpdated: true,
              });
              this.setState({
                reviewerSelected: false,
                editReviewer: false,

                reviewer: {},
                reviewerDates: [],
              });

              return;
            }

            const {
              selectedPosition: {
                _id,
                languageSkills,
                otherSkills,
                contentAreaSkills,
              },
              // reviewerDates,
            } = this.state;

            if (selectedPosition) {
              let selectedReviewer = {
                ...reviewer,
                firstName: reviewer.fullName.split(/\b/)[0],
                lastName: reviewer.fullName.split(/\b/)[2],
                name: reviewer.fullName,
                languageSkills,
                otherSkills,
                contentAreaSkills,
                dates: reviewerDates,
                status: 'pending',
                resendInvite: false,
                invitationDate: AmsDateFormatters.formatDate(
                  AmsDateFormatters.getMoment()
                ),
                position: _id,
              };
              this.props.selectedReviewer(selectedReviewer);
              this.clearSelections();
            }
          }}
        />

        <Button
          primary
          content={'Add Reviewer without Invitation'}
          disabled={_.isEmpty(reviewer) ? true : false}
          onClick={e => {
            e.preventDefault();

            if (this.state.editReviewer) {
              this.props.updateReviewer(this.props.editReviewer, {
                dates: this.state.reviewerDates,
                status: 'assigned',
                invitationDate: AmsDateFormatters.formatDate(
                  AmsDateFormatters.getMoment()
                ),
                reviewerDatesUpdated: true,
              });
              this.setState({
                reviewerSelected: false,
                editReviewer: false,
                reviewer: {},
                reviewerDates: [],
              });

              return;
            }

            const {
              selectedPosition: {
                _id,
                languageSkills,
                otherSkills,
                contentAreaSkills,
              },
              // reviewerDates,
            } = this.state;

            if (selectedPosition) {
              let selectedReviewer = {
                ...reviewer,
                firstName: reviewer.fullName.split(/\b/)[0],
                lastName: reviewer.fullName.split(/\b/)[2],
                name: reviewer.fullName,
                languageSkills,
                otherSkills,
                contentAreaSkills,
                dates: reviewerDates,
                status: 'assigned',
                resendInvite: false,
                invitationDate: AmsDateFormatters.formatDate(
                  AmsDateFormatters.getMoment()
                ),
                position: _id,
              };
              this.props.selectedReviewer(selectedReviewer);
              this.clearSelections();
            }
          }}
        />
      </>
    );
  };

  searchView = () => {
    const { filtering } = this.state;
    return filtering ? (
      <Dimmer active inverted>
        <Loader content="Loading  reviewers..." />
      </Dimmer>
    ) : (
      <>
        {this.searchForm()}
        {/* Users Table */}
        {this.renderReviewersList()}
        <br />
      </>
    );
  };

  renderSearchModal = () => {
    const { startDate, endDate, showSearchModal } = this.props;

    const start = AmsDateFormatters.getMoment(startDate)
      .startOf('day')
      .format('MM/DD/YYYY');
    const end = AmsDateFormatters.getMoment(endDate)
      .endOf('day')
      .format('MM/DD/YYYY');

    let content;
    if (this.props.editReviewer) {
      content = 'Edit Dates for ' + this.props.editReviewer.fullName;
    } else {
      content = `Search Unscheduled Reviewers (${start} - ${end})`;
    }

    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showSearchModal || this.props.editReviewer}
        closeIcon
        size="fullscreen"
        onClose={() => {
          this.clearSelections();
          this.props.searchModalToggle(false);
        }}
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header content={content} />

        <Modal.Content scrolling>
          {this.showError()}
          {this.searchView()}
        </Modal.Content>

        <Modal.Actions>
          {this.addReviewerButton()}
          {this.cancelButton()}
        </Modal.Actions>
      </AmsModal>
    );
  };

  showWeekSelectionModal = () => {
    const {
      reviewerSelected,
      reviewer,
      reviewerDates,
      reviewers,
      currentStepIndex,
      advancedSearch,
    } = this.state;
    const { startDate, endDate } = this.props;

    const nonAvailableDays =
      reviewer.availabilityInfo &&
      reviewer.availabilityInfo.filter(e => e.availabilityType !== 'available');

    const availableDays =
      reviewer.availabilityInfo &&
      reviewer.availabilityInfo.find(e => e.availabilityType === 'available');

    const start = AmsDateFormatters.getMoment(startDate)
      .startOf('day')
      .format('MM/DD/YYYY');
    const end = AmsDateFormatters.getMoment(endDate)
      .endOf('day')
      .format('MM/DD/YYYY');

    var enumerateDaysBetweenDates = function(start, end) {
      var now = AmsDateFormatters.getMoment(start).clone(),
        dates = [];

      while (now.isSameOrBefore(end)) {
        if (now.isoWeekday() !== 6 && now.isoWeekday() !== 7)
          dates.push(now.format('MM/DD/YYYY'));
        now.add(1, 'days');
      }
      return dates;
    };

    const buildScheduledDates = reviewer => {
      if (
        reviewer &&
        reviewer.availabilityInfo &&
        nonAvailableDays.length > 0
      ) {
        return (
          <Message.List>
            {nonAvailableDays.map(info => {
              const { dates } = info;
              let dateText = formatDatesArray(dates);

              return (
                <Message.Item>{`${info.availabilityType
                  .charAt(0)
                  .toUpperCase() +
                  info.availabilityType.slice(1)} for Review ID ${
                  info.source
                } on ${dateText}`}</Message.Item>
              );
            })}
          </Message.List>
        );
      } else {
        return null;
      }
    };

    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={reviewerSelected}
        closeIcon
        onClose={() => {
          this.setState({
            reviewerSelected: false,
            reviewer:
              reviewers && reviewers[currentStepIndex]
                ? reviewers[currentStepIndex]
                : {},
            reviewerDates:
              reviewers && reviewers[currentStepIndex]
                ? reviewers[currentStepIndex].dates
                : [],
          });
        }}
        backdrop="static"
      >
        <Modal.Header>
          Select the days {reviewer.fullName} is needed
        </Modal.Header>
        <Modal.Content>
          {reviewer.availabilityInfo && nonAvailableDays.length > 0 ? (
            <Message info>
              <Message.Header style={{ marginBottom: '10px' }}>
                Note:
              </Message.Header>
              <Message.Content>{buildScheduledDates(reviewer)}</Message.Content>
              {availableDays &&
                availableDays.dates &&
                availableDays.dates.length > 0 && (
                  <>
                    <p>and is available for the following days listed:</p>
                    <p> {formatDatesArray(availableDays.dates)}</p>
                  </>
                )}
            </Message>
          ) : advancedSearch === true ? (
            <Message info>
              <Message.Header style={{ marginBottom: '10px' }}>
                Note:
              </Message.Header>
              <Message.Content>
                {availableDays &&
                  availableDays.dates &&
                  availableDays.dates.length > 0 && (
                    <>
                      <p>Available for the following days listed:</p>
                      <p> {formatDatesArray(availableDays.dates)}</p>
                    </>
                  )}
              </Message.Content>
            </Message>
          ) : null}
          <br />
          {enumerateDaysBetweenDates(start, end).map((date, index) => {
            return (
              <Button
                key={index}
                basic={reviewerDates.includes(date) ? false : true}
                primary={reviewerDates.includes(date) ? true : false}
                onClick={() => {
                  let dates = reviewerDates;
                  if (reviewerDates.includes(date)) {
                    dates = _.remove(reviewerDates, selectedDate => {
                      return selectedDate !== date;
                    });
                  } else {
                    dates.push(date);
                  }

                  this.setState({
                    reviewerDates: dates,
                  });
                }}
                // color={reviewerDates.includes(date) ? 'blue' : 'grey'}
              >
                {AmsDateFormatters.getMoment(date)
                  .format('dddd')
                  .substring(0, 3)}{' '}
                {AmsDateFormatters.getMoment(date).format('Do')}
              </Button>
            );
          })}
        </Modal.Content>
        <Modal.Actions>
          <div style={{ textAlign: 'center' }}>
            <Button
              primary
              onClick={() => {
                this.setState({
                  reviewerSelected: false,
                });
              }}
              disabled={_.isEmpty(reviewerDates) ? true : false}
            >
              Finished
            </Button>
          </div>
        </Modal.Actions>
      </AmsModal>
    );
  };

  // Handle single select
  handleReviewerSelect = (row, isSelected, e) => {
    const { reviewerDates, currentStepIndex, reviewers } = this.state;
    let availability = _.find(
      row.availabilityInfo,
      info => info.availabilityType === 'available'
    );
    if (availability && !availability.partial) {
      this.setState({
        ...this.state,
        reviewerSelected: true,
        reviewer: row,
        reviewerDates:
          reviewerDates &&
          reviewers[currentStepIndex] &&
          reviewers[currentStepIndex].oid === row.oid
            ? reviewerDates
            : [],
        reviewerSearch: {
          ...this.state.reviewerSearch,
        },
      });
    } else if (this.state.conflictConfimed) {
      this.setState({
        ...this.state,
        reviewerSelected: true,
        reviewer: row,
        reviewerDates:
          reviewerDates &&
          reviewers[currentStepIndex] &&
          reviewers[currentStepIndex].oid === row.oid
            ? reviewerDates
            : [],
        reviewerSearch: {
          ...this.state.reviewerSearch,
        },
        conflictConfimed: false,
      });
    } else {
      this.setState({ showAlert: true, row });
    }
  };

  showConfirmationAlert = row => {
    return (
      <AmsAlert
        show={this.state.showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`The reviewer you selected has conflicting schedules for the review time period you selected. Are you sure you want to continue`}
        onConfirm={() => {
          this.setState(
            {
              ...this.state,
              conflictConfimed: true,
              showAlert: false,
            },
            () => this.handleReviewerSelect(this.state.row)
          );
        }}
        onCancel={() => {
          this.setState({ ...this.state, showAlert: false });
        }}
      />
    );
  };

  renderReviewersList = () => {
    const {
      reviewers: { unscheduled },
      startDate,
      endDate,
      currentUser,
      reviewersList,
      excludeList,
    } = this.props;
    const { reviewer, page, advancedSearch } = this.state;

    const start = AmsDateFormatters.getMoment(startDate)
      .startOf('day')
      .format('MM/DD/YYYY');
    const end = AmsDateFormatters.getMoment(endDate)
      .endOf('day')
      .format('MM/DD/YYYY');

    const unselectable = reviewersList
      ? Object.values(reviewersList).map(reviewer => {
          return reviewer.oid;
        })
      : [];

    const onSizePerPageChange = (sizePerPage, page) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.fetchData();
        }
      );
    };

    const onPageChange = (page, sizePerPage) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.fetchData();
        }
      );
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === this.state.sortName &&
        sortOrder === this.state.sortOrder
      )
        return null;
      this.setState(
        {
          sortName: sortField || this.state.sortName,
          sortOrder: sortOrder || this.state.sortOrder,
        },
        () => this.fetchData()
      );
    };

    const selectRow = {
      onSelect: this.handleReviewerSelect,
      selected: reviewer && [reviewer.id],
      // unselectable: unselectable,
      hideSelectColumn: false,
      clickToSelect: false,
      mode: 'radio',
      className: (row, isSelect) => {
        if (unselectable.includes(row.oid))
          return 'reviewer-filter-disabled-row';
        else return null;
      },
      style: { width: '6%' },
      selectionRenderer: ({ mode, checked, disabled }) => {
        if (checked) return <Icon name="check circle" size="large" />;
      },

      selectionHeaderRenderer: ({ mode, ...rest }) => {
        return (
          <>
            <span className="visually-hidden">Selected</span>
          </>
        );
      },
    };

    const availabilityInfo = (cell, row) => {
      if (cell && cell.length > 0) {
        return (
          <>
            {cell.map(availability => {
              return (
                <>
                  <h4>{availability.availabilityType}:</h4>
                  {availability.dates.join(', ')}
                </>
              );
            })}
          </>
        );
      }
    };

    const nameFormatter = (cell, row) => {
      // let availability = _.find(row.availabilityInfo, info => {
      //   return info.availabilityType === 'available';
      // });
      return (
        row && (
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header as={'h5'}>{row.fullName}</Item.Header>
                <Item.Meta content={row.userName} />
              </Item.Content>
            </Item>
          </Item.Group>
        )
      );
    };

    const actionFormatter = (cell, row) => {
      return (
        <span>
          <Link
            href="#"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.handleReviewerSelect(row);
            }}
          >
            Schedule
          </Link>
          {enforceRole(
            <>
              &nbsp; | &nbsp;
              <AmsUserCard
                linkText={'View Profile'}
                user={row}
                oid={row.oid}
                displayName={row.fullName}
              ></AmsUserCard>
            </>,
            acl.actions.profile.adminPanel, // Allowed roles
            currentUser.roles // Current user roles
          ) || null}
        </span>
      );
    };

    const reviewerState = (cell, row) => {
      return cell === '' ? 'N/A' : cell;
    };

    // Ignore data without fullName and is in excluded list
    let filteredUnscheduledReviewers;
    if (!this.props.editReviewer) {
      filteredUnscheduledReviewers =
        unscheduled &&
        unscheduled.data &&
        this.state.scheduleTypes.includes('available')
          ? unscheduled.data.filter(u => {
              return !excludeList.find(x => u.oid === x.oid);
            })
          : unscheduled.data;
    } else {
      filteredUnscheduledReviewers =
        unscheduled &&
        unscheduled.data &&
        unscheduled.data.filter(u => {
          return u.oid === this.props.editReviewer.oid;
        });
    }

    const columns = [
      { dataField: 'id', hidden: true, text: 'ID' },
      {
        dataField: 'fullName',
        sort: true,
        formatter: nameFormatter,
        text: 'Name',
      },
      {
        dataField: 'homeState',
        sort: true,
        headerStyle: { width: '6%' },
        text: 'State',
      },
      {
        dataField: 'status',
        sort: true,
        headerStyle: { width: '10%' },
        text: 'Status',
      },
      {
        dataField: 'type',
        sort: true,
        text: 'Type',
      },
      {
        dataField: 'willingToTravel',
        sort: true,
        text: 'Willing to Travel',
      },
      {
        dataField: 'previousWeek',
        sort: true,
        formatter: reviewerState,
        hidden: enforceRole(
          true,
          acl.actions.review.viewReviewerState, // Allowed roles
          currentUser.roles // Current user roles
        )
          ? false
          : true,
        text: 'Previous Week',
      },
      {
        dataField: 'nextWeek',
        sort: true,
        formatter: reviewerState,
        hidden: enforceRole(
          true,
          acl.actions.review.viewReviewerState, // Allowed roles
          currentUser.roles // Current user roles
        )
          ? false
          : true,
        text: 'Next Week',
      },
      {
        dataField: 'previousFY',
        sort: true,
        text: 'Total Reviews (Previous FY)',
      },
      {
        dataField: 'currentFY',
        sort: true,
        text: 'Total Reviews (Current FY)',
      },
      {
        hidden: !advancedSearch,
        dataField: 'availabilityInfo',
        formatter: availabilityInfo,
        text: 'Type of Availability',
      },
      {
        dataField: 'action',
        isDummyField: true,
        formatter: actionFormatter,
        text: 'Action',
        headerStyle: { width: '12%' },
      },
    ];
    const defaultSorted = [
      {
        dataField: this.state.sortName,
        order: this.state.sortOrder,
      },
    ];

    return (
      <div style={{ marginBottom: '20px' }}>
        <AmsTable
          noDataIndication={`No reviewers available between ${start} - ${end}`}
          sizePerPageOption={false}
          basic
          defaultSorted={defaultSorted}
          data={filteredUnscheduledReviewers}
          selectRow={selectRow}
          total={unscheduled ? unscheduled.total : 0}
          keyField="id"
          pagination={unscheduled && unscheduled.total > 10}
          remote
          page={page}
          limit={this.state.limit}
          loading={this.state.loading}
          columns={columns}
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <Helmet>
          <body className="reviewer-search" />
        </Helmet>

        {this.renderSearchModal()}
        {this.showWeekSelectionModal()}
        {this.showConfirmationAlert()}
      </div>
    );
  }
}

ReviewerFilter.propTypes = {
  reviewers: PropTypes.object.isRequired,
  fetchUnscheduledReviewers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  reviewers: state.reviewers,
  languages: state.lookups.amsLookups.languages,
  currentUser: state.auth.user,
  selectedPosition: state.review.selectedPosition,
  runPresetSearch: state.review.runPresetSearch,
});

export default connect(mapStateToProps, {
  fetchUnscheduledReviewers,
  unscheduledReviewersCleared,
  reviewerPositionFetched,
})(ReviewerFilter);
