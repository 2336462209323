import axios from 'axios';
export const SET_TODOS = 'SET_TODOS';
export const SET_TODO_ERROR = 'SET_TODO_ERROR';

export function setTodos(todos) {
  return {
    type: SET_TODOS,
    todos,
  };
}

export function setTodoError(error) {
  return {
    type: SET_TODO_ERROR,
    error,
  };
}

export function fetchTodos() {
  return dispatch => {
    axios
      .get('/todos')
      .then(function(response) {
        dispatch(setTodos(response.data));
      })
      .catch(function(error) {
        console.log(error);
        // dispatch(setNotificationsError(error));
      });
  };
}
