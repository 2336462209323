import {
  NOTES_FETCHED,
  USER_PROFILE_UPDATED_NOTES_FETCHED,
} from '../redux/types';

const initialState = {
  notes: [],
  userProfileUpdatedNotes: [],
};

export default function note(state = initialState, action) {
  switch (action.type) {
    case NOTES_FETCHED:
      return { ...state, notes: action.notes.reverse() };
    case USER_PROFILE_UPDATED_NOTES_FETCHED:
      return { ...state, userProfileUpdatedNotes: action.notes.reverse() };
    default:
      return state;
  }
}
