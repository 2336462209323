import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
// Import actions.
import {
  ranGuideSelected,
  ranPerformanceMeasureSelected,
  fetchRANFormDetail,
} from '../../../actions/ranActions';
import { filter, isEmpty } from 'lodash';
import ShowErrors from '../../../utils/ShowErrors';
import {
  Segment,
  Grid,
  Button,
  Form as SemanticForm,
  Header,
  Popup,
  Label,
} from 'semantic-ui-react';
import Form from '@rjsf/core';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';
import AttachmentField from '../../Shared/FormHelpers/AttachmentField';
import ListField from '../../Shared/FormHelpers/ListField';
import { Link } from 'react-router';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { postFormSubmission } from '../../../actions/surveyActions';
import OrderedListField from '../../Shared/FormHelpers/OrderedListField';
import ItalicField from '../../Shared/FormHelpers/ItalicField';

export default function RANForm(props) {
  const dispatch = useDispatch();
  const {
    selectedPerformanceMeasure,
    selectedGuide,
    isReportFinalized,
    reviewStatus,
    readOnly,
    reviewId,
    isReviewAccessible,
    submission,
  } = useSelector(state => state.forms.ran);
  const { selectedReview } = useSelector(state => state.review);
  const [selectedPM, setSelectedPM] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [draftButtonClicked, setDraftButtonClicked] = useState(false);
  const [alertData, setAlertData] = useState({
    showAlert: false,
    alertType: '',
    alertMessage: '',
  });
  const [incidentAlert, setIncidentAlert] = useState(false);

  const findingPerformanceMeasures = [
    'Supervision Findings Guide',
    'Inappropriate Release Findings Guide',
    'Discipline Findings Guide',
  ];

  // useEffect to set selectedPerformanceMeasure
  useEffect(() => {
    if (!isEmpty(selectedPerformanceMeasure)) {
      setSelectedPM(selectedPerformanceMeasure);
    }
  }, [selectedPerformanceMeasure]);

  // useEffect to set form loading process when form schema is fetched
  useEffect(() => {
    setFormProcessing(false);
  }, [selectedPM.formSchema]);

  // useEffect to set form state
  useEffect(() => {
    if (submission && !isEmpty(submission)) {
      setFormDisabled(!isEmpty(submission));
    } else {
      setFormDisabled(false);
    }
  }, [submission]);

  useEffect(() => {
    $('select')
      .parent()
      .parents('.panel-body')
      .attr('style', 'display:none');
  });

  /**
   * Customize the form schema with custom descriptions and helpers
   * @param {string} id
   * @param {array | string} description
   * @returns
   */
  const customDescriptionField = ({ id, description }) => {
    // IF there is no description, hide the link
    if (isEmpty(description) || (description && isEmpty(description[0].text)))
      return null;

    return (
      <span>
        {description.map((d, i) => [
          i > 0 && '; ',
          <Link
            to={{
              pathname: `/review/citations/definition`,
              query: { citation: d.citationNumber },
            }}
            target="_blank"
          >
            <Popup
              trigger={
                <Label
                  style={{
                    color: 'blue',
                    background: 'white',
                    textDecorationLine: 'underline',
                  }}
                >
                  {' '}
                  {d ? d.citationNumber : ''}
                </Label>
              }
              content="Select to see Citation Definition"
            />
          </Link>,
        ])}
      </span>
    );
  };

  /**
   * Set the custom fields to use for the form schema
   * @returns {Object}
   */
  const fields = () => {
    return {
      DescriptionField: customDescriptionField,
      listField: ListField,
      orderedListField: OrderedListField,
      attachmentField: AttachmentField,
      italicField: ItalicField,
    };
  };

  /**
   * Submit the form data
   * @returns {void}
   */
  const handleSubmit = () => {
    if (incidentAlert) return;
    if (!props.amsFormId || !reviewId) return;

    let requestObject = {
      reviewId,
      amsFormId: props.amsFormId,
      surveyData: props.formData,
    };

    if (props.formData.topQuestion === false) {
      requestObject = {
        reviewId,
        amsFormId: props.amsFormId,
        surveyData: { topQuestion: false },
      };
    }

    if (submission.amsSubmissionId)
      requestObject.amsSubmissionId = submission.amsSubmissionId;

    setFormProcessing(true);

    // draft
    if (draftButtonClicked) {
      dispatch(
        postFormSubmission({
          data: { ...requestObject, submissionStatus: 'Draft' },
        })
      )
        .then(response => {
          setDraftButtonClicked(false);
          setErrors({});
          setAlertData({
            showAlert: true,
            alertType: 'success',
            alertMessage: 'Draft saved successfully',
          });
          setEditButtonClicked(false);
          props.dataHasChangedSwitch(false);
        })
        .catch(error => {
          setErrors({ ...errors, fa2SaveDraft: error.message });
          setFormProcessing(false);
          setDraftButtonClicked(false);
        });
    } else {
      // update
      if (
        !draftButtonClicked &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'submitted'
      ) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(response => {
            setErrors({});
            setAlertData({
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Updated successfully',
            });
            props.dataHasChangedSwitch(false);
            setEditButtonClicked(false);
          })
          .catch(error => {
            setErrors({ ...errors, fa2SaveDraft: error.message });
            setFormProcessing(false);
          });
        return; // End of update exit.
      }

      // new
      if (!draftButtonClicked) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(response => {
            setErrors({});
            setAlertData({
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Updated successfully',
            });
            props.dataHasChangedSwitch(false);
            setEditButtonClicked(false);
          })
          .catch(error => {
            setErrors({ ...errors, fa2SaveDraft: error.message });
            setFormProcessing(false);
          });
      }
    }
  };

  const showEditButton = () => {
    if (readOnly) return;

    if (isReportFinalized) return;

    if (!isReviewAccessible) return;

    if (editButtonClicked) return;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (submission.editable) {
      return (
        <Button
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            setEditButtonClicked(true);
            setFormDisabled(false);
          }}
          style={{ display: 'inline-block' }}
        />
      );
    }
  };

  const showSubmitButton = () => {
    if (readOnly) return null;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (!isReviewAccessible) return;

    if (reviewStatus.toLowerCase() === 'cancelled') return null;

    if (
      !submission.surveyStatus ||
      (props.formData && submission.surveyStatus && editButtonClicked)
    )
      return (
        <Button
          disabled={isEmpty(props.formData)}
          type="submit"
          primary
          content={'Submit'}
        />
      );
  };

  const showDraftButton = () => {
    if (isEmpty(selectedPM.formSchema)) return null;

    if (!isReviewAccessible) return;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (readOnly) {
      return null;
    }

    if (
      !submission.surveyStatus ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft' &&
        editButtonClicked)
    )
      return (
        <Button
          type="submit"
          content="Save as Draft"
          disabled={isEmpty(props.formData)}
          onClick={() => {
            setDraftButtonClicked(true);
          }}
        />
      );

    return null;
  };

  const showCancelButton = () => {
    if (readOnly) return null;

    if (
      !editButtonClicked ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() !== 'submitted')
    )
      return null;
    else
      return (
        <Button
          negative
          // size="large"
          content={'Cancel'}
          onClick={() => {
            props.handleCancelChange(
              submission && submission.data && submission.data.surveyData
            );
            setEditButtonClicked(false);
            setFormDisabled(true);
          }}
        />
      );
  };

  const showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = alertData;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          setAlertData({ showAlert: false, alertType: '', alertMessage: '' });
          dispatch(fetchRANFormDetail({ reviewId })).then(() => {
            dispatch(ranGuideSelected(selectedGuide));
            dispatch(ranPerformanceMeasureSelected(selectedPerformanceMeasure));
            window.scrollTo(0, 0);
          });
        }}
      />
    );
  };

  // Render survey submission status
  const renderSurveyStatus = () => {
    if (isEmpty(submission)) return null;

    if (!submission.amsSubmissionId) return null;

    const { editTime, editedBy } = submission.data;

    return (
      <Segment
        style={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #e3e3e3',
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
          padding: '20px',
          marginLeft: '1rem',
        }}
      >
        {editedBy && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submitted By"
            >
              Submitted By:{' '}
            </span>
            {editedBy}
          </div>
        )}
        {submission.surveyStatus && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:{' '}
            </span>
            {submission.surveyStatus}
          </div>
        )}
        {editTime && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Time"
            >
              Submission Time:{' '}
            </span>
            {AmsDateFormatters.formatDateTime(editTime)}
          </div>
        )}
      </Segment>
    );
  };

  // Render survey PM description
  const renderDescription = () => {
    if (!selectedPerformanceMeasure) return;

    const { description } = selectedPerformanceMeasure;

    if (description) return <Header as="h2">{description}</Header>;
  };
  const handleError = e => {};

  /**
   * Custom Form validation associated with form schema submission
   * @param {Object} data
   * @param {Object} errors
   * @returns {Object}
   */
  const validate = (data, errors) => {
    if (
      selectedReview &&
      selectedReview.isRANMulti &&
      findingPerformanceMeasures.includes(selectedPM.name) &&
      selectedGuide &&
      selectedGuide.performanceMeasures[0] &&
      selectedGuide.performanceMeasures[0].forms &&
      selectedGuide.performanceMeasures[0].forms.filter(f => f.isActive)
        .length < 1
    ) {
      setIncidentAlert(true);
    }

    if (draftButtonClicked) {
      return {};
    }
    return errors;
  };

  /**
   * Transform form errors to custom error descriptions
   * @param {Object} errors
   * @returns {Object}
   */
  const transformErrors = errors => {
    if (draftButtonClicked) {
      return [];
    }

    return filter(errors, error => {
      if (error.name === 'minItems') {
        error.message = 'This is a required field.';
      }
      if (error.name === 'required')
        error.message = 'This is a required field.';
      if (error.params.type === 'integer')
        error.message =
          'This field only accepts whole numbers. Please enter a whole number.';
      if (error.name === 'minimum')
        error.message =
          'This field only accept values greater than 0. Please enter a whole number.';
      return (
        error.name === 'required' ||
        error.name === 'minItems' ||
        error.name === 'minimum' ||
        error.params.type === 'integer'
      );
    });
  };

  const showIncidentError = () => {
    return (
      <AmsAlert
        show={incidentAlert}
        title="Alert"
        type="error"
        showConfirm
        text={`The finding cannot be submitted since no incidents exist. Please add an incident to create findings.`}
        onConfirm={() => {
          setIncidentAlert(false);
        }}
      />
    );
  };

  return (
    <>
      <div className="row form-container">
        <ShowErrors errors={errors} />
        {showSuccessConfirmation()}
        {showIncidentError()}
        <Segment basic loading={formProcessing}>
          {renderSurveyStatus()}
          {renderDescription()}

          <Grid columns={1}>
            <Grid.Column style={{ width: '100%' }}>
              <div className="text-right">
                <div style={{ display: 'inline-block' }}>
                  {showEditButton()}
                </div>
              </div>
              <fieldset
                id="form-container"
                disabled={
                  isReportFinalized ||
                  (reviewStatus &&
                    reviewStatus.toLowerCase() === 'cancelled') ||
                  readOnly ||
                  !isReviewAccessible
                    ? true
                    : formDisabled
                }
              >
                {!isEmpty(selectedPM.formSchema) &&
                  !isEmpty(selectedPM.uiSchema) && (
                    <Form
                      ArrayFieldTemplate={ArrayFieldTemplate}
                      key={props.amsFormId || ''}
                      schema={selectedPM.formSchema}
                      uiSchema={selectedPM.uiSchema}
                      formData={
                        (selectedPM.uiSchema &&
                          selectedPM.formSchema &&
                          props.formData) ||
                        {}
                      }
                      onSubmit={handleSubmit}
                      onChange={props.handleDataChange}
                      onError={handleError}
                      validate={validate}
                      liveValidate={false}
                      transformErrors={transformErrors}
                      showErrorList={false}
                      noHtml5Validate
                      fields={fields()}
                    >
                      <div className="ui form">
                        {!isReportFinalized &&
                          reviewStatus &&
                          reviewStatus.toLowerCase() !== 'cancelled' && (
                            <SemanticForm.Group>
                              {showDraftButton()}
                              {showSubmitButton()}
                              {showCancelButton()}
                            </SemanticForm.Group>
                          )}
                      </div>
                    </Form>
                  )}
              </fieldset>
            </Grid.Column>
          </Grid>
          {showEditButton()}
        </Segment>
      </div>
    </>
  );
}
