import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';

const ReviewCenterExploration = ({ selectedReview }) => {
  const newFormReviewTypes = ['Special'];
  if (
    isEmpty(selectedReview.reviewId) ||
    (selectedReview.reviewType !== 'FA-2' &&
      selectedReview.reviewType !== 'Special' &&
      selectedReview.reviewType !== 'AIAN-DEF') ||
    selectedReview.fiscalYear < 2023 ||
    (newFormReviewTypes.includes(selectedReview.reviewType) &&
      selectedReview?.isNewForms)
  )
    return null;

  const fiscalYear =
    selectedReview.fiscalYear && selectedReview.fiscalYear.toString().slice(-2);

  return (
    <Menu.Item
      as={Link}
      to={`/review/fy${fiscalYear}/${selectedReview.reviewId}/center-classroom-fa2-exploration-summary`}
    >
      Center/Classroom Exploration Summary
    </Menu.Item>
  );
};

ReviewCenterExploration.propTypes = {
  selectedReview: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewCenterExploration);
