import {
  REVIEW_SUBMISSIONS_FETCHED,
  REVIEW_SUBMISSIONS_CLEARED,
  REVIEW_FU_SUBMISSIONS_FETCHED,
  SURVEY_SUBMISSIONS_FETCHED,
} from '../redux/types';

const initialState = {
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REVIEW_SUBMISSIONS_FETCHED:
      return { ...state, list: action.reviewSubmissions };

    case REVIEW_FU_SUBMISSIONS_FETCHED: {
      if (!Array.isArray(action.reviewFUSubmissions)) {
        return { ...state, newFollowUpDetails: action.reviewFUSubmissions };
      }
      return { ...state, list: action.reviewFUSubmissions };
    }

    case REVIEW_SUBMISSIONS_CLEARED: {
      return { ...state, list: [] };
    }
    case SURVEY_SUBMISSIONS_FETCHED:
      return { ...state, list: action.surveySubmissions };

    default:
      return state;
  }
};
