import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Message,
  Dimmer,
  Loader,
  TextArea,
  Checkbox,
} from 'semantic-ui-react';
import _ from 'lodash';

// Import components
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsAlert from '../../utils/AmsAlert';

// Import actions
import {
  fetchInvitation,
  processInvitation,
} from '../../actions/invitationActions';

class InvitationPage extends Component {
  state = {
    confirming: false,
    confirmed: false,
    accept: false,
    invitationResponse: '',
    comment: '',
    errors: {},
    message: '',
    formErrors: {},
    showAlert: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const invitationId = this.context.router.params.id;
    if (invitationId)
      this.props.fetchInvitation(invitationId).catch(error => {
        this.setState({ errors: { fetchError: error.response.data.message } });
      });
  };

  invitationSubmitAlert = () => {
    const { invitationResponse } = this.state;
    return (
      <AmsAlert
        show={this.state.showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        cancelButtonText="No"
        confirmButtonText="Yes"
        confirmButtonColor={'#DD6B55'}
        text={
          <span>
            You are
            {invitationResponse == 'Declined' ? ' declining' : ' accepting'} the
            invitation
            {invitationResponse == 'Accepted with changes'
              ? ' with changes'
              : ''}
            . Are you sure you want to continue?
          </span>
        }
        onConfirm={() => {
          this.setState({ showAlert: false });
          this.confirmInvitation();
        }}
        onCancel={() => {
          this.setState({ showAlert: false });
        }}
      />
    );
  };

  confirmInvitation = () => {
    const { comment, accept, invitationResponse } = this.state;
    const {
      invitation: { invitationId },
    } = this.props;
    if (invitationId) {
      this.setState({ confirming: true });
      this.props
        .processInvitation(invitationId, {
          accept,
          comment,
          invitationResponse,
        })
        .then(() => {
          const status = invitationResponse;
          this.setState({
            confirming: false,
            confirmed: true,
            message: `Thanks for responding to the invitation. You have ${status} this invitation.`,
          });
        })
        .catch(error => {
          this.setState({
            confirming: false,
            confirmed: false,
            errors: { processError: error.response.data.message },
          });
        });
    }
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  renderInvitation = () => {
    const { confirmed, confirming, invitationResponse } = this.state;

    const {
      invitationId,
      reviewId,
      fullName,
      granteeName,
      granteeState,
      reviewStartDate,
      reviewEndDate,
      willingToTravel,
      reviewSitedDescription,
      reviewSitedContent,
      unannounced,
      inviteDates,
    } = this.props.invitation;
    if (confirming)
      return (
        <Dimmer active inverted>
          <Loader inverted>Processing Response...</Loader>
        </Dimmer>
      );

    return (
      invitationId &&
      !confirmed && (
        <Form>
          <div>
            <h4>
              <em>Reviewer Response To Invitation Email</em>
            </h4>
            <p>
              Reviewer: <b>{fullName}</b>
            </p>
            {unannounced && (
              <p>
                Response: Availability to participate in {reviewSitedContent}{' '}
                {reviewSitedDescription} <b>unannounced</b> review in{' '}
                <b>{granteeState || '<Grantee State>'}</b>
                {'. '}If assigned, your dates of participation will be{' '}
                <b>
                  {inviteDates} {'. '}
                </b>
                {' ('}Recipient details will be provided once assigned to the
                review{'.'}
                {')'}
              </p>
            )}
            {!unannounced && (
              <p>
                Response: {reviewSitedDescription} invitation to participate in
                review of <b>{granteeName || '<Grantee Name>'}</b>/
                <b>{reviewId}</b>
                {'. '} If assigned, your dates of participation will be{' '}
                <b>{inviteDates}</b>.
              </p>
            )}

            <p>
              {willingToTravel && (
                <>
                  If accepting this review assignment will place you on
                  consecutive (back to back) reviews, please indicate if you
                  will be staying in the field or returning home between
                  reviews. Note: You may remain in the field between reviews if
                  there are 2 or fewer days between review assignments.
                </>
              )}
            </p>
            <p>
              {willingToTravel && !unannounced && (
                <>
                  Also, once flights have been booked, we are unable to change
                  your travel arrangements. Please include any travel related
                  notes in the comment section below: If you have questions
                  regarding Danya's travel guidelines, please refer to the
                  "Review Requirements and Logistics” training on the HS
                  Monitoring E-Learning Center and the “Review Requirements and
                  Travel Policies” that you received from Danya.
                </>
              )}
              {willingToTravel && unannounced && (
                <>
                  Also, once flights have been booked, we are unable to change
                  your travel arrangements. Please include any travel related
                  notes in the comment section below: If you have questions
                  regarding Danya's travel guidelines, please refer to the
                  "Review Logistics Field Guide" on the Learning Management
                  System, Canvas.
                </>
              )}
            </p>
          </div>

          <div>
            <hr />
          </div>
          <fieldset
            style={{
              padding: 0,
            }}
          >
            <label>Please select one of the following:*</label>
            <legend className="visually-hidden">Invitation Response</legend>
            <Form.Field>
              <Checkbox
                style={{
                  marginBottom: '-15px',
                }}
                radio
                disabled={confirming}
                name="invitationResponse"
                value="Accepted"
                label={`Accept (Select this option to Accept participation as ${reviewSitedContent} ${reviewSitedDescription} Reviewer)`}
                checked={invitationResponse === 'Accepted'}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                style={{
                  marginBottom: '20px',
                }}
                radio
                disabled={confirming}
                name="invitationResponse"
                value="Declined"
                label="Decline (Select this option to Decline participation - Reviewer comments required)"
                checked={invitationResponse === 'Declined'}
                onChange={this.handleChange}
              />
            </Form.Field>
          </fieldset>
          <Form.Field
            value={this.state.comment}
            error={this.state.formErrors.comment}
            label="Reviewer comments to Danya (including Mode of travel and preference for consecutive reviews):"
            name="comment"
            onChange={this.handleChange}
            rows={6}
            control={TextArea}
          />

          <Form.Button
            disabled={
              confirming ||
              invitationResponse == '' ||
              ((invitationResponse == 'Declined' ||
                invitationResponse == 'Accepted with changes') &&
                this.state.comment === '')
            }
            primary
            size="large"
            content={'Submit'}
            onClick={e => {
              if (e) e.preventDefault();
              this.setState({ showAlert: true });
              if (invitationResponse.indexOf('Accept') !== -1)
                this.setState({ accept: true });
            }}
          />
        </Form>
      )
    );
  };

  showError = () => {
    const { errors } = this.state;

    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(errorKey => (
          <li key={errorKey}>{errors[errorKey]}</li>
        ))}
      />
    );
  };

  showConfirmation = () => {
    const { message } = this.state;
    if (_.isEmpty(message)) return;

    return (
      <Message positive icon="check" header="Responded" content={message} />
    );
  };

  render() {
    return (
      <div className="invitation-page">
        <SlidingContainer calendar={false} title="Respond to Invitation">
          {this.showConfirmation()}
          {this.showError()}
          {this.renderInvitation()}
          {this.invitationSubmitAlert()}
        </SlidingContainer>
      </div>
    );
  }
}

InvitationPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

InvitationPage.propTypes = {
  invitation: PropTypes.object.isRequired,
  fetchInvitation: PropTypes.func.isRequired,
  processInvitation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  invitation: state.invitation,
});

export default connect(mapStateToProps, { fetchInvitation, processInvitation })(
  InvitationPage
);
