import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Message,
  Segment,
  Header,
  Form,
  Loader,
  Dimmer,
  Modal,
  Input,
  Icon,
  Button,
  Checkbox,
  TextArea,
  List,
} from 'semantic-ui-react';
import _ from 'lodash';

import './assets/style.css';

// Import utils.
import AmsAlert from '../../utils/AmsAlert';
import AmsTable from '../../utils/AmsTable';
import AmsModal from '../../utils/AmsModal';
import AmsRowAction from '../../utils/AmsRowAction';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import actions
import {
  updateCitationTFCDate,
  updatePlanFollowUpReviewTeam,
} from '../../actions/reviewActions';

const FindingsDetail = ({
  findings,
  updateCitationTFCDate,
  reloadData,
  setErrors,
  clearErrors,
  reviewId,
}) => {
  const [state, setState] = useState({
    showAlert: false,
    showExtendModal: false,
    showHistoryModal: false,
    modalData: null,
    extendByValue: null,
    loading: false,
    inputDays: null,
    validInput: true,
    inputDialog: false,
  });

  const previousSubmit =
    findings &&
    findings.followUpReviews &&
    !_.isEmpty(findings.followUpReviews) &&
    findings.followUpReviews[0].reviewId !== '';

  const showConfirmationAlert = () => {
    let text;

    if (!previousSubmit) {
      text = `The Corrective Action Period will be extended by ${state.extendByValue} days and Follow-up reviews will be generated based on the new Corrective Action Period. Are you sure? Note: Please continue to select and submit the remaining fields on this form to complete the team assignments.`;
    } else {
      if (state.inputDialog === false) {
        text = `Corrective Action Period will be extended by ${state.extendByValue} days and previously generated Follow-up reviews start and end dates will be updated based on the new Corrective Action Period. Are you sure? `;
      } else {
        text = `Previously generated Follow-up reviews start and end dates will be updated by ${state.extendByValue} days, however, Corrective Action Period will remain unchanged. Are you sure?`;
      }
    }

    return (
      <AmsAlert
        show={state.showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={text}
        onConfirm={() => {
          extendCitationAction();
        }}
        onCancel={() => {
          setState({ ...state, showExtendModal: true, showAlert: false });
        }}
      />
    );
  };

  const extendCitationAction = () => {
    const { modalData, extendByValue, inputDialog, inputDialogReason } = state;
    const body = {
      findingIds: modalData.findingIds,
      extendedBy: Number(extendByValue),
      reviewId: findings.reviewId,
      dateChangeReason: inputDialogReason,
      dateChange: inputDialog,
    };

    setState({
      ...state,
      showExtendModal: false,
      showAlert: false,
      loading: true,
      inputDialog: false,
      inputDialogReason: null,
    });
    updateCitationTFCDate(body)
      .then(() => {
        setState({
          ...state,
          showExtendModal: false,
          showAlert: false,
          loading: false,
        });
        clearErrors();
        reloadData();
      })
      .catch(error => {
        const { message } = error.response.data;
        setState({
          ...state,
          showExtendModal: false,
          showAlert: false,
          loading: false,
        });
        setErrors(message || 'This request could not be processed.');
      });
  };

  const toggleModal = (e, data) => {
    if (e) e.preventDefault();

    clearErrors();

    setState({
      ...state,
      showExtendModal: !state.showExtendModal,
      modalData: data || null,
      inputDialog: false,
      inputDialogReason: null,
    });
  };

  const generateCAPInputAlert = () => {
    if (!state.validInput) {
      return (
        <Message
          style={{ width: '95%', margin: '0 auto' }}
          negative
          header={'Something went wrong!'}
        >
          <Icon name="cancel" />
          Please enter a valid number. CAP Days can only be extended a total of
          365 days. Please ensure the extended cap days and new input does not
          exceed 365.
        </Message>
      );
    }
  };

  const renderExtendTFCModal = () => {
    const { modalData } = state;
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={state.showExtendModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setState({ ...state, showExtendModal: false });
        }}
      >
        <Modal.Header>Extend CAP and/or Review Dates</Modal.Header>
        {generateCAPInputAlert()}
        <Modal.Content>
          <Form>
            {modalData &&
              modalData.followUpReview &&
              modalData.followUpReview.reviewId && (
                <>
                  <Checkbox
                    label={{
                      children:
                        'Would you like to change the Follow-up review start and end date without extending the TFC cap days?',
                    }}
                    name="enabled"
                    toggle
                    aria-label="extendTFCDialog"
                    checked={state.inputDialog}
                    onChange={(e, data) => {
                      setState({ ...state, inputDialog: data.checked });
                    }}
                  />
                </>
              )}
            <Form.Field
              required
              label="Enter CAP days"
              placeholder="Enter CAP days"
              control={Input}
              style={{ width: '100%' }}
              type="number"
              onChange={(e, data) =>
                setState({ ...state, validInput: true, inputDays: data.value })
              }
            ></Form.Field>
            <Form.Field
              required
              label={'Enter a reason'}
              value={state.inputDialogReason}
              onChange={(e, { value }) =>
                setState({ ...state, inputDialogReason: value })
              }
              placeholder="Explain the reason(s)"
              control={TextArea}
              name="inputReason"
              rows={5}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setState({
                ...state,
                validInput: true,
                inputDays: null,
                showExtendModal: false,
                inputDialog: false,
                inputDialogReason: null,
              });
            }}
            content="Cancel"
          />
          <Button
            disabled={!state.inputDialogReason}
            onClick={() => {
              validateUserInput();
            }}
            primary
            content="Submit"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const validateUserInput = () => {
    const input = Number(state.inputDays);

    const prevExtend = findings.findings.find(e => {
      return _.isEqual(e.findingIds, state.modalData.findingIds);
    });

    if (
      Number.isNaN(input) ||
      input < 1 ||
      input + prevExtend.extendedCapDays > 365
    ) {
      setState({ ...state, validInput: false, inputDays: null });
      return;
    } else {
      setState({
        ...state,
        validInput: true,
        showExtendModal: false,
        extendByValue: state.inputDays,
        showAlert: true,
        inputDays: null,
      });
    }
  };

  const formatExtendCAPDays = (row, cell) => {
    return <span>{row} Days </span>;
  };

  const formatAction = (row, cell, index) => {
    const actionsArr = [
      { text: 'Extend Further', onClick: e => toggleModal(e, cell) },
    ];

    if ((cell.reasonHistory || []).length) {
      let reversedStatusHistory = [...cell.reasonHistory].reverse();
      actionsArr.push({
        text: 'View Extension History',
        onClick: e => {
          e.preventDefault();
          setState({
            ...state,
            showHistoryModal: true,
            modalData: reversedStatusHistory,
          });
        },
      });
    }

    return <AmsRowAction pointing="top right" actions={actionsArr} />;
  };

  const formatCitationNumber = (row, cell) => {
    return (
      <div>
        {Array.isArray(row)
          ? row.map(e => {
              return (
                <>
                  <span>{e}</span>
                  <br />
                </>
              );
            })
          : null}
      </div>
    );
  };

  const renderHistoryModal = () => {
    if (!state || !state.modalData || !Array.isArray(state.modalData)) {
      return null;
    }

    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={state.showHistoryModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setState({ ...state, showHistoryModal: false, modalData: null });
        }}
      >
        <Modal.Header>Extension History</Modal.Header>
        <Modal.Content>
          <List divided relaxed>
            {state.modalData &&
              state.modalData.map(e => {
                const { who, reason, dateChange } = e;
                let displayName = _.isObject(who)
                  ? `${who.firstName} ${who.lastName}`
                  : who;
                return (
                  <List.Item>
                    <List.Content>
                      <List.Header>
                        {displayName} (
                        {AmsDateFormatters.formatDate(e.updatedTime)})
                      </List.Header>
                      <div>
                        <strong>Extended By:</strong> {e.extendedBy} Days{'\n'}
                      </div>
                      {dateChange ? (
                        <div>
                          <strong>
                            Reason (Change the Follow-up review start and end
                            date without extending the TFC cap days):
                          </strong>{' '}
                          {reason}
                        </div>
                      ) : (
                        <div>
                          <strong>Reason:</strong> {reason}
                        </div>
                      )}
                    </List.Content>
                  </List.Item>
                );
              })}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setState({
                ...state,
                showHistoryModal: false,
                modalData: null,
              });
            }}
            content="Close"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const columns = [
    {
      dataField: 'citationNumbers',
      headerStyle: { width: '10%' },
      sort: true,
      formatter: formatCitationNumber,
      text: 'Citation #',
    },
    {
      headerStyle: { width: '15%' },
      dataField: 'granteeIds',
      formatter: (row, cell) => (Array.isArray(row) ? row.join(', ') : null),
      sort: true,
      text: 'Grantee ID',
    },
    {
      headerStyle: { width: '10%' },
      dataField: 'findingType',
      sort: true,
      text: 'Type',
    },
    {
      headerStyle: { width: '5%' },
      dataField: 'total',
      sort: true,
      text: 'Total',
    },
    {
      formatter: formatExtendCAPDays,
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      text: 'Extended CAP Days',
      sort: true,
      dataField: 'extendedCapDays',
    },

    {
      formatter: row => AmsDateFormatters.formatDate(row),
      dataField: 'tfcStartDate',
      sort: true,
      text: 'TFC Start Date',
    },
    {
      formatter: row => AmsDateFormatters.formatDate(row),
      dataField: 'tfcEndDate',
      sort: true,
      text: 'TFC End Date',
    },
    {
      formatter: formatAction,
      text: 'Action',
      dataField: 'dummy',
      isDummy: true,
    },
  ];

  const total = findings && findings.findings ? findings.findings.length : 0;
  const generateFindingsCitationTable = () => {
    return state.loading ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : findings ? (
      <AmsTable
        keyField="citationNumbers"
        remote={false}
        loading={state.loading}
        total={total}
        columns={columns}
        basic
        sizePerPageOption={false}
        data={findings.findings}
      />
    ) : null;
  };

  return (
    <Segment basic>
      {renderExtendTFCModal()}
      {renderHistoryModal()}
      {showConfirmationAlert()}
      <Header as="h2">Findings Details</Header>
      {generateFindingsCitationTable()}
    </Segment>
  );
};

FindingsDetail.propTypes = {};

const mapStateToProps = state => ({
  findings: state.review.fetchedFindingDetails,
});

export default connect(mapStateToProps, {
  updateCitationTFCDate,
  updatePlanFollowUpReviewTeam,
})(FindingsDetail);
