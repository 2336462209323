import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Checkbox, Input } from 'semantic-ui-react';

// Import actions.
import { updateLookup } from '../../../actions/lookupActions';

class LookupParentForm extends Component {
  state = {
    selectedLookup: {},
    updating: false,
    data: {
      value: '',
      valueType: '',
      label: '',
      description: '',
      parent: false,
      active: true,
    },
    errors: {},
  };

  componentWillReceiveProps = nextProps => {
    const { selectedLookup } = nextProps;
    this.setState({
      data: selectedLookup,
    });
  };

  getDefaultValue = (valueType, value) => {
    switch (valueType) {
      case 'String':
        return value;
      case 'Number':
        return 0;
      case 'Boolean':
        return true;
      case 'Object':
        return {};
      case 'Array':
        return [];
      default:
        return value;
    }
  };

  validate = data => {
    const errors = {};
    // if (_.isEmpty(data.value)) errors.value = "Lookup Name is required.";
    // if (_.isEmpty(data.valueType)) errors.valueType = "Lookup Type is required.";
    return errors;
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.data);

    this.setState({ errors });

    // No more errors submit data.
    if (Object.keys(errors).length === 0) {
      const {
        _id,
        value,
        active,
        valueType,
        description,
        label,
      } = this.state.data;

      const data = { active, description, value, valueType, label };

      if (!_id) return;
      this.setState({ updating: true });

      this.props
        .updateLookup(_id, data)
        .then(() => this.setState({ updating: false }))
        .catch(error => {
          this.setState({
            updating: false,
            errors: { ...this.state.errors, parentError: error.response },
          });
        });
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  handleToggle = () =>
    this.setState({
      data: { ...this.state.data, active: !this.state.data.active },
    });

  render() {
    const { data, updating } = this.state;

    return (
      !_.isEmpty(data._id) && (
        <Form onSubmit={this.onSubmit} noValidate>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Lookup Name</label>
              <span className="lookup-type-container">
                {data.label || data.value}
              </span>
            </Form.Field>
            <Form.Field style={{ textAlign: 'center' }}>
              <label>Values Type</label>
              <span className="lookup-type-container">{data.valueType}</span>
            </Form.Field>
            <Form.Field>
              <label>Category</label>
              <span className="lookup-type-container">
                {data.category || ''}
              </span>
            </Form.Field>
            <Form.Field
              label={{ children: 'Enabled', htmlFor: 'parentEnabled' }}
              style={{ textAlign: 'center' }}
              required
              role="group"
              name="active"
              onChange={this.handleToggle}
              toggle
              checked={data.active}
              control={Checkbox}
              id="parentEnabled"
              aria-labelledby="parentEnabled"
            />
            <Form.Field style={{ textAlign: 'right' }}>
              <Form.Button
                color="green"
                size="tiny"
                disabled={updating}
                content={updating ? 'Updating...' : 'Update'}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              label={{ children: 'Description', htmlFor: 'description' }}
              fluid
              inline
              name="description"
              value={data.description || ''}
              onChange={this.handleChange}
              control={Input}
              id="description"
              aria-labelledby="description"
            />
          </Form.Group>
        </Form>
      )
    );
  }
}

LookupParentForm.propTypes = {
  updateLookup: PropTypes.func.isRequired,
  selectedLookup: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  selectedLookup: state.lookups.selectedLookup,
});

export default connect(mapStateToProps, { updateLookup })(LookupParentForm);
