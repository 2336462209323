import { GET_AGREEMENT, GET_CURRENT_USER_AGREEMENTS } from '../redux/types';

const initialState = {
  agreement: {},
  agreements: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_AGREEMENT:
      let agreement = { ...action.agreement };
      return { ...state, agreement };
    case GET_CURRENT_USER_AGREEMENTS:
      let agreements = action.agreements.agreements;
      return { ...state, agreements };
    default:
      return state;
  }
};
