import { GRANTEES_FILTERED, GRANTEE_FILTER_CLEARED } from '../redux/types';

export default function granteeFilter(state = [], action) {
  switch (action.type) {
    case GRANTEES_FILTERED: {
      return [...action.grantees];
    }

    case GRANTEE_FILTER_CLEARED: {
      return [];
    }

    default:
      return state;
  }
}
