import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

class GranteeCalendarListColumn extends Component {
  state = {
    data: '',
  };

  componentDidMount() {
    const { dates, month } = this.props;

    let data = '';

    if (!_.isEmpty(dates)) {
      dates.forEach(date => {
        let formatedDate = AmsDateFormatters.formatDate(date);
        if (formatedDate.startsWith(month)) {
          data = data + formatedDate + ' <br />';
        }
      });
    }

    this.setState({ data: data });
  }

  render() {
    const { data } = this.state;
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarListColumn);
