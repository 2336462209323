import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Tab,
  Form,
  Checkbox,
  Label,
  Menu,
  Header,
  Divider,
} from 'semantic-ui-react';

// Import actions.
import { fetchSpecialAssignments } from '../../../actions/specialActions';

class GuidesPerformanceMeasure extends Component {
  state = {
    data: {
      pms: [],
    },
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props
      .fetchSpecialAssignments({
        reviewId: this.props.reviewId,
      })
      .then(pms => {
        this.setState({ data: { pms: pms.assignments } });
      });
  };

  renderPerformanceMeasures = (guideCode = 'PMQ') => {
    const {
      data: {
        pms: { guides },
      },
    } = this.state;

    const guide = guides && _.find(guides, { guideCode });

    return (
      guide &&
      guide.performanceMeasures.map((p, i) => {
        const key = `${guideCode}-${i}`;

        return (
          <Form.Field
            key={key}
            label={{
              children: p.perfName,
              htmlFor: p.perfName,
            }}
            id={p.perfName}
            name={key}
            value={p.perfName}
            checked={p.isActive}
            onChange={(e, { name }) => {
              // Toggle the performance measures.
              const updatedPMs = [];

              guides &&
                guides.forEach(guide => {
                  const updatedPerformanceMeasures = [];

                  guide.performanceMeasures.forEach(g => {
                    if (g.key === name) {
                      updatedPerformanceMeasures.push({
                        ...g,
                        isActive: !g.isActive,
                      });
                    } else {
                      updatedPerformanceMeasures.push({ ...g });
                    }
                  });

                  updatedPMs.push({
                    ...guide,
                    performanceMeasures: updatedPerformanceMeasures,
                  });
                });

              const pms = {
                reviewId: this.props.reviewId,
                guides: updatedPMs,
              };

              this.setState(
                {
                  data: {
                    pms,
                  },
                },
                () => {
                  this.props.handlePMCheckboxValueChange(pms);
                }
              );
            }}
            control={Checkbox}
          />
          // <Form.Field key={key}>
          //   <Checkbox
          //     label={p.perfName}
          //     name={key}
          //     value={p.perfName}
          //     checked={p.isActive}
          //     onChange={(e, { name }) => {
          //       // Toggle the performance measures.
          //       const updatedPMs = [];

          //       guides &&
          //         guides.forEach(guide => {
          //           const updatedPerformanceMeasures = [];

          //           guide.performanceMeasures.forEach(g => {
          //             if (g.key === name) {
          //               updatedPerformanceMeasures.push({
          //                 ...g,
          //                 isActive: !g.isActive,
          //               });
          //             } else {
          //               updatedPerformanceMeasures.push({ ...g });
          //             }
          //           });

          //           updatedPMs.push({
          //             ...guide,
          //             performanceMeasures: updatedPerformanceMeasures,
          //           });
          //         });

          //       const pms = {
          //         reviewId: this.props.reviewId,
          //         guides: updatedPMs,
          //       };

          //       this.setState(
          //         {
          //           data: {
          //             pms,
          //           },
          //         },
          //         () => {
          //           this.props.handlePMCheckboxValueChange(pms);
          //         }
          //       );
          //     }}
          //   />
          // </Form.Field>
        );
      })
    );
  };

  renderGuides = () => {
    const {
      data: {
        pms: { guides },
      },
    } = this.state;

    const panes = [];

    guides &&
      guides.map(d => {
        const count = _.filter(d.performanceMeasures, o => {
          if (o.isActive === true) return o;
        }).length;

        const pane = {
          menuItem: (
            <Menu.Item key={d.guideCode}>
              {d.guideName} <Label color="blue">{count}</Label>
            </Menu.Item>
          ),
          render: () => this.renderPerformanceMeasures(d.guideCode),
        };

        return panes.push(pane);
      });

    return panes;
  };

  render() {
    return (
      <>
        <Header>Select Guides and Performance Measure</Header>
        <Divider />
        <>
          <Tab
            menu={{
              fluid: true,
              vertical: true,
              secondary: true,
              size: 'large',
            }}
            grid={{ paneWidth: 8, tabWidth: 8 }}
            panes={this.renderGuides()}
          />
        </>
      </>
    );
  }
}

GuidesPerformanceMeasure.propTypes = {
  fetchSpecialAssignments: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  assignments: state.survey.assignments,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { fetchSpecialAssignments })(
  GuidesPerformanceMeasure
);
