import { Component } from 'react';

class ReviewReportTemplate extends Component {
  componentDidMount = () => {};

  render() {
    return null;
    // 9/12 - [Alex] - commented this out due to AMS-4395 bug complaining about this message
    //   (
    //   <div>
    //     loading report template...
    //   </div>
    // );
  }
}

ReviewReportTemplate.propTypes = {};

export default ReviewReportTemplate;
