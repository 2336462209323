import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

// Import actions
import { fetchAllUsers } from '../actions/userActions';

class AllUserListPreloadDropdown extends Component {
  state = {
    loading: false,
  };

  componentWillReceiveProps = props => {
    const {
      userType,
      hasSource,
      startDate,
      endDate,
      fetchAllUsers,
      usersFilter,
    } = props;
    if (hasSource && usersFilter) {
      const isEmpty = _.isEmpty(usersFilter['shadowReviewer']);
      this.setState({ loading: isEmpty });
    }

    if (
      !_.isEmpty(startDate) &&
      !_.isEmpty(endDate) &&
      (!_.isEqual(startDate, this.props.startDate) ||
        !_.isEqual(endDate, this.props.endDate))
    ) {
      let options = { startDate, endDate };
      fetchAllUsers(userType, options);
      this.setState({ loading: true });
    } else if (
      usersFilter &&
      !usersFilter['shadowReviewer'] &&
      !_.isEmpty(startDate) &&
      !_.isEmpty(endDate) &&
      !this.state.loading
    ) {
      let options = { startDate, endDate };
      fetchAllUsers(userType, options);
      this.setState({ loading: true });
    }

    if (this.state.loading && usersFilter['shadowReviewer']) {
      this.setState({ loading: false });
    }
  };

  componentDidMount = () => {
    const {
      userType,
      hasSource,
      startDate,
      endDate,
      fetchAllUsers,
      usersFilter,
    } = this.props;
    if (hasSource && usersFilter) {
      const isEmpty = _.isEmpty(usersFilter['shadowReviewer']);
      this.setState({ loading: isEmpty });
    }

    if (
      !_.isEmpty(startDate) &&
      !_.isEmpty(endDate) &&
      (!_.isEqual(startDate, this.props.startDate) ||
        !_.isEqual(endDate, this.props.endDate))
    ) {
      let options = { startDate, endDate };
      fetchAllUsers(userType, options);
      this.setState({ loading: true });
    } else if (
      usersFilter &&
      !usersFilter['shadowReviewer'] &&
      !_.isEmpty(startDate) &&
      !_.isEmpty(endDate) &&
      !this.state.loading
    ) {
      let options = { startDate, endDate };
      fetchAllUsers(userType, options);
      this.setState({ loading: true });
    }

    if (this.state.loading && usersFilter['shadowReviewer']) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { userType, usersFilter } = this.props;

    const dropDownProps = _.omit(this.props, [
      'userType',
      'usersFilter',
      'startDate',
      'endDate',
      'fetchAllUsers',
      'additionalOptions',
      'hasSource',
    ]); // exclude props not necessary for Dropdown.

    return (
      <Dropdown
        {...dropDownProps}
        loading={this.state.loading}
        disabled={this.state.loading}
        options={
          usersFilter && usersFilter[userType] && usersFilter[userType].length
            ? _.uniqBy(
                [...usersFilter[userType], ...this.props.additionalOptions].map(
                  user => ({
                    key: user.oid,
                    text: `${user.firstName} ${user.lastName}`,
                    value: user.oid,
                  })
                ),
                'key'
              )
            : []
        }
      />
    );
  }
}

AllUserListPreloadDropdown.propTypes = {
  userType: PropTypes.string.isRequired,
  usersFilter: PropTypes.object,
  fetchAllUsers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  usersFilter: state.usersFilter,
});

export default connect(mapStateToProps, { fetchAllUsers })(
  AllUserListPreloadDropdown
);
