import { ADD_MESSAGE, REMOVE_MESSAGE } from '../redux/types';

export default function message(state = {}, action) {
  switch (action.type) {
    case ADD_MESSAGE: {
      return action.message;
    }

    case REMOVE_MESSAGE: {
      return {};
    }

    default:
      return state;
  }
}
