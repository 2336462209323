import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';

// Actions
import { fetchNotifications } from '../../actions/notificationActions';
import { fetchWorkflowStatus } from '../../actions/workflowActions';

// Custom components
import BrowserTitle from '../../utils/BrowserTitle';
import Resources from '../Resources/Resources';
import CarouselTile from '../Shared/Carousel/CarouselTiles';
import TaskList from '../Tasks/TaskList';
import Workflow from './../Workflow/Workflow';

import fa1ReviewProcess from './assets/FocusArea1-2019.png';
import fa2ReviewProcess from './assets/FocusArea2-2019.png';
import surveyWorkflowStatusImage from './assets/survey-workflow-status.png';

// Style
require('./assets/style.css');

class Landing extends Component {
  componentDidMount() {
    /*document.getElementById('sidebar').classList.add('hidden');
    document.getElementById('page-inner').classList.add('landing');
    */
    this.getData();
  }

  componentWillUnmount() {
    /*document.getElementById('sidebar').classList.remove('hidden');
    document.getElementById('page-inner').classList.remove('landing');*/
  }

  getData() {
    // Only get status if not already fetched.
    // if (_.isEmpty(workflowStatus))
    //   this.props.fetchWorkflowStatus();
  }

  showWorkFlowStatus() {
    const { roles } = this.props.currentUser;

    if (includes(roles, 'Reviewer')) return;

    return (
      <div className="col-sm-8">
        <h2>Workflow Status</h2>
        <Workflow type="review" title="Reviews" />
      </div>
    );
  }

  showStatus() {
    const { roles } = this.props.currentUser;

    if (roles !== 'Reviewer')
      return (
        <div className="col-sm-8">
          <h2>Survey List</h2>
          <h2>Survey Workflow Status</h2>
          <p>&nbsp;</p>
          <p>
            <img
              src={surveyWorkflowStatusImage}
              className="img-responsive"
              alt="Survey workflow status"
            />
          </p>
        </div>
      );
  }

  renderFa2ReviewProcess() {
    return (
      <div className="col-sm-7">
        <img
          src={fa1ReviewProcess}
          className="img-responsive"
          alt="Workflow Diagram"
        />
        <img
          src={fa2ReviewProcess}
          className="img-responsive"
          alt="Workflow Diagram"
        />
      </div>
    );
  }

  // render() {
  //   return (
  //     <div className="container">
  //       <div id="content" className="col-sm-12 landing-page-top-content">
  //         <div className="col-sm-5 task-list">
  //           <TaskList />
  //         </div>

  //         {/* {this.renderFa2ReviewProcess()} */}

  //         {/*  {this.showWorkFlowStatus()}*/}

  //         <Resources />
  //       </div>
  //     </div>
  //   );
  // }

  render() {
    return (
      <>
        <BrowserTitle />
        <span className="sr-only">
          <h1>Home| Head Start - Aligned Monitoring System</h1>
        </span>

        <div
          style={{
            background: 'rgb(246, 246, 246)',
            padding: '2rem 0',
            marginBottom: '3rem',
          }}
        >
          <Container>
            <CarouselTile />
          </Container>
        </div>
        <Container>
          <Grid stackable columns="equal" relaxed>
            <Grid.Row>
              <Grid.Column>
                <TaskList />
              </Grid.Column>
              <Grid.Column>
                <Resources />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    workflowStatus: state.workflow.workflowStatus,
  };
}

Landing.propTypes = {
  currentUser: PropTypes.object.isRequired,
  workflowStatus: PropTypes.object.isRequired,
  fetchWorkflowStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchNotifications,
  fetchWorkflowStatus,
})(Landing);
