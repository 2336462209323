import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default function ReviewStatusItems({ reviews }) {
  const linkId = (cell, row) => {
    return <Link to={`/review/${cell}`}>{cell}</Link>;
  };

  const options = {};

  return (
    <BootstrapTable
      trClassName="review-status-row"
      data={reviews}
      tableContainerClass="table-container"
      headerContainerClass="table-header"
      hover
      condensed
      bordered={false}
      options={options}
      pagination={reviews && reviews.length > 10}
    >
      <TableHeaderColumn
        dataField="reviewId"
        dataFormat={linkId}
        isKey={true}
        dataSort={true}
      >
        {' '}
        Review
      </TableHeaderColumn>
      <TableHeaderColumn dataField="workFlowStatus" dataSort={true}>
        Status
      </TableHeaderColumn>
      <TableHeaderColumn dataField="manager" dataSort={true}>
        Manager
      </TableHeaderColumn>
      <TableHeaderColumn dataField="progress" dataSort={true}>
        Progress
      </TableHeaderColumn>
    </BootstrapTable>
  );
}
ReviewStatusItems.propTypes = {
  reviews: PropTypes.array.isRequired,
};
