import {
  GRANTEE_HASH_FETCHED,
  GRANTEE_SCHEDULE_RESULTS,
  GRANTEE_STATUS_FETCHED,
} from '../redux/types';

export default function grantee(state = {}, action) {
  switch (action.type) {
    case GRANTEE_HASH_FETCHED: {
      return { ...state, ...action.granteeDetails };
    }

    case GRANTEE_SCHEDULE_RESULTS: {
      return {
        ...state,
        results: action.granteeResults,
      };
    }

    case GRANTEE_STATUS_FETCHED: {
      return {
        ...state,
        status: action.granteeStatus,
      };
    }

    default:
      return state;
  }
}
