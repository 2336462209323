import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  Button,
  Sidebar,
  Grid,
  Responsive,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

// Import components
import PageTitle from '../../components/Shared/PageTitle/PageTitle';
import GranteeStatusBanner from '../../utils/GranteeStatusBanner';

// Import utils
import AmsSkipLinks from '../../utils/AmsSkipLinks';

// Import style.
import './assets/style.css';

const AmsSlidingContainer = ({
  calendar,
  loading,
  granteeStatusBanner,
  sidebarComponent,
  router,
  children,
  granteeStatusData,
  routes,
  params,
  title,
  subheader,
  actionButtons,
  handleShowSideBar,
}) => {
  const [visible, setVisible] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);

  // Add event listener to handle closing sidebar with ESC key
  useEffect(() => {
    const ESC_KEY_CODE = 27;

    const handleKeyDown = event => {
      if (event.keyCode === ESC_KEY_CODE && showSideBar) {
        setShowSideBar(false);
        handleShowSideBar(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSideBar]);

  const toggleVisibility = e => {
    e.preventDefault();
    setVisible(!visible);
  };

  const width = sidebarComponent ? 13 : 16;
  const calendarButton = calendar && (
    <>
      <Button
        basic
        icon="calendar"
        onClick={toggleVisibility}
        content={visible ? 'Hide Calendar' : 'Show Calendar'}
      />
    </>
  );

  return (
    <>
      <Sidebar.Pushable>
        <Sidebar.Pusher>
          {loading && loading.loader ? (
            <div style={{ height: '100vh' }}>
              <Dimmer active inverted>
                <Loader
                  inline="centered"
                  content={loading.loadingMessage || 'Loading...'}
                />
              </Dimmer>
            </div>
          ) : (
            <Responsive>
              <>
                {router.location.pathname !== '/' && (
                  <PageTitle
                    router={router}
                    routes={routes}
                    params={params}
                    title={title}
                    subheader={subheader}
                    calendarButton={calendarButton}
                    actionButtons={actionButtons}
                  />
                )}

                <div className="global-spacer">
                  {!showSideBar && (
                    <Button
                      onClick={() => {
                        setShowSideBar(true);
                        handleShowSideBar(true);
                      }}
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'left',
                        color: '#1D3665',
                        marginLeft: '-0.5%',
                        marginRight: 'auto%',
                      }}
                      aria-label="Show Menu"
                    >
                      Show Menu &gt;
                    </Button>
                  )}
                  <Grid stackable style={{ minHeight: '450px' }}>
                    {showSideBar && (
                      <Grid.Column
                        width={3}
                        role="navigation"
                        aria-label="Page Options"
                      >
                        {showSideBar && (
                          <Button
                            onClick={() => {
                              setShowSideBar(false);
                              handleShowSideBar(false);
                            }}
                            style={{
                              background: 'none',
                              border: 'none',
                              cursor: 'pointer',
                              textAlign: 'right',
                              marginLeft: 'auto',
                              marginRight: '-8%',
                              marginTop: '15px',
                              //marginBottom: '0px',
                              display: 'block',
                              color: '#1D3665',
                            }}
                            aria-label="Hide Menu"
                          >
                            &lt; Hide Menu
                          </Button>
                        )}
                        <AmsSkipLinks
                          links={[
                            {
                              title: 'Skip page options',
                              to: 'content',
                            },
                          ]}
                        />

                        {sidebarComponent}
                      </Grid.Column>
                    )}

                    <Grid.Column
                      id="content"
                      width={width}
                      role="region"
                      aria-label="Main Content"
                    >
                      {granteeStatusBanner && (
                        <GranteeStatusBanner
                          id={granteeStatusData.id}
                          type={granteeStatusData.type}
                          view={granteeStatusData.view || null}
                        />
                      )}
                      {children}
                    </Grid.Column>
                  </Grid>
                </div>
              </>
            </Responsive>
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

AmsSlidingContainer.propTypes = {
  router: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.shape({
    loader: PropTypes.bool,
    loadingMessage: PropTypes.string,
  }),
  granteeStatusBanner: PropTypes.shape({
    status: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  sidebarComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  calendar: PropTypes.bool,
};

export default withRouter(AmsSlidingContainer);
