import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';

// Import actions.
import {
  fetchRANFormSchema,
  fetchRANSubmission,
  ranCenterSelected,
  ranClassroomSelected,
} from '../../../actions/ranActions';
import RANForm from './RANForm';

class RANFormContainer extends Component {
  state = {
    selectedName: '',
    formProcessing: false,
    centers: [],
    centerOptions: [],
    classrooms: [],
    classroomOptions: [],
    selectedCenter: '',
    selectedClassroom: '',
    errors: {},
    dataHasChanged: false,
    formChangeRequested: true,
    data: {},
    showAlert: false,
  };

  componentWillReceiveProps(nextProps) {
    const {
      ran: { selectedPerformanceMeasure, selectedGuide },
    } = nextProps;

    if (
      this.state.dataHasChanged &&
      this.state.selectedName !== selectedPerformanceMeasure.name
    ) {
      this.setState({
        formChangeRequested: true,
        changedGuideName: this.state.selectedGuideName,
        changedPerformanceName: this.state.selectedName,
        changedSubmission: this.state.submission,
        changedAmsFormId: this.state.amsFormId,
      });
    } else {
      if (!_.isEmpty(selectedPerformanceMeasure)) {
        if (this.state.selectedName !== selectedPerformanceMeasure.name) {
          this.setState({
            selectedName: selectedPerformanceMeasure.name,
            selectedGuideName: selectedGuide.name,
            selectedPerformanceMeasure: selectedPerformanceMeasure,
            ...this.extractCentersAndClassrooms(selectedPerformanceMeasure),
            formChangeRequested: true,
          });

          if (!_.isEmpty(selectedPerformanceMeasure.forms)) {
            this.getData(selectedPerformanceMeasure.forms);
            return;
          }

          // Center Exploration & Classroom Exploration
          if (!_.isEmpty(selectedPerformanceMeasure.centers)) {
            const centers = selectedPerformanceMeasure.centers;

            // Center exploration
            if (!_.isEmpty(centers[0].forms)) this.getData(centers[0].forms);

            // Classroom exploration
            if (
              !_.isEmpty(centers[0].classrooms) &&
              !_.isEmpty(centers[0].classrooms[0].forms)
            )
              this.getData(centers[0].classrooms[0].forms);
          }
        }
      } else {
        this.setState({ formChangeRequested: false });
      }
    }
  }

  getData = forms => {
    const { amsFormId, amsSubmissionId } = forms[0];

    // Fetch form schema.
    if (amsFormId) {
      this.setState({ formProcessing: true, errors: {}, amsFormId });
      this.props
        .fetchRANFormSchema({ filters: { amsFormId } })
        .then(() => {
          // If there's not submission id, keep on showing loader until data is fetched.
          if (!amsSubmissionId) this.setState({ formProcessing: false });
        })
        .catch(error => {
          this.setState({
            formProcessing: false,
            errors: {
              ...this.state.errors,
              fetchRANFormSchema: error.message,
            },
          });
        });
    }

    // Fetch form submission
    if (amsSubmissionId) {
      this.setState({ formProcessing: true });
      this.props
        .fetchRANSubmission({
          filters: { amsSubmissionId },
        })
        .then(response =>
          this.setState({
            formProcessing: false,
            submission: response.submission,
          })
        )
        .catch(error => {
          this.setState({
            formProcessing: false,
            errors: {
              ...this.state.errors,
              fetchRANSubmission: error.message,
            },
          });
        });
    } else {
      this.setState({
        submission: {},
        changedGuideName: '',
        changedPerformanceName: '',
        changedSubmission: {},
        changedAmsFormId: '',
      });
    }
  };

  extractCentersAndClassrooms = selectedPerformanceMeasure => {
    const { centers } = selectedPerformanceMeasure;
    if (_.isEmpty(centers)) {
      this.resetClassRoomAndCenters();
      return;
    }
    return {
      centers: centers || [],
      centerOptions: centers
        ? centers.map(center => ({
            key: center.centerName,
            text: center.centerName,
            value: center.centerName,
          }))
        : [],
      selectedCenter: centers ? centers[0].centerName : '',
      classrooms:
        centers && centers[0].classrooms && centers[0].classrooms.length
          ? centers[0].classrooms
          : [],
      classroomOptions:
        centers && centers[0].classrooms && centers[0].classrooms.length
          ? centers[0].classrooms.map(classroom => ({
              key: classroom.classSampleId,
              text: classroom.classroomName,
              value: classroom.classSampleId,
            }))
          : [],
      selectedClassroom:
        centers && centers[0].classrooms && centers[0].classrooms.length
          ? centers[0].classrooms[0].classSampleId
          : '',
    };
  };

  dataHasChangedSwitch = value => {
    const {
      ran: { selectedPerformanceMeasure },
    } = this.props;
    this.setState(
      {
        dataHasChanged: value,
      },
      () => {
        if (!value) {
          if (!_.isEmpty(selectedPerformanceMeasure.forms)) {
            this.getData(selectedPerformanceMeasure.forms);
            return;
          }

          // Center Exploration & Classroom Exploration
          if (!_.isEmpty(selectedPerformanceMeasure.centers)) {
            const centers = selectedPerformanceMeasure.centers;

            // Center exploration
            if (!_.isEmpty(centers[0].forms)) this.getData(centers[0].forms);

            // Classroom exploration
            if (
              !_.isEmpty(centers[0].classrooms) &&
              !_.isEmpty(centers[0].classrooms[0].forms)
            )
              this.getData(centers[0].classrooms[0].forms);
          }
        }
      }
    );
  };

  handleCenterChange = (e, { name, value }) => {
    const center = _.find(this.state.centers, { centerName: value });

    if (center && center.centerName)
      this.props.ranCenterSelected(center.centerName);

    if (center && center.classrooms) {
      this.setState(
        {
          [name]: value,
          classrooms: center.classrooms,
          selectedClassroom: center.classrooms
            ? center.classrooms[0].classSampleId
            : '',
          classroomOptions: center
            ? center.classrooms.map(classroom => ({
                key: classroom.classSampleId,
                text: classroom.classroomName,
                value: classroom.classSampleId,
              }))
            : [],
        },
        () => {
          if (!_.isEmpty(center.classrooms[0].forms))
            this.getData(center.classrooms[0].forms);
        }
      );
    } else {
      this.setState({ [name]: value }, this.getData(center.forms));
    }
  };

  handleClassroomChange = (e, { name, value }) => {
    const classroom = _.find(this.state.classrooms, { classSampleId: value });

    if (classroom && classroom.classroomName)
      this.props.ranClassroomSelected(classroom.classroomName);

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (!_.isEmpty(classroom.forms)) this.getData(classroom.forms);
      }
    );
  };

  resetClassRoomAndCenters = () => {
    this.setState({
      centers: [],
      centerOptions: [],
      classrooms: [],
      classroomOptions: [],
      selectedCenter: '',
      selectedClassroom: '',
    });
  };

  renderCentersAndClassrooms = () => {
    const {
      centerOptions,
      selectedCenter,
      classroomOptions,
      selectedClassroom,
    } = this.state;

    return (
      <Form>
        {!_.isEmpty(centerOptions) && (
          <Form.Dropdown
            name="selectedCenter"
            label="Select Center"
            options={centerOptions}
            value={selectedCenter}
            onChange={this.handleCenterChange}
            selectOnBlur={false}
            selection
            search
            fluid
          />
        )}

        {!_.isEmpty(classroomOptions) && (
          <Form.Dropdown
            name="selectedClassroom"
            label="Select Classroom"
            options={classroomOptions}
            value={selectedClassroom}
            onChange={this.handleClassroomChange}
            selectOnBlur={false}
            selection
            search
            fluid
          />
        )}
      </Form>
    );
  };

  render() {
    const {
      formChangeRequested,
      changedGuideName,
      changedPerformanceName,
      changedAmsFormId,
      changedSubmission,
    } = this.state;
    return (
      <div>
        {this.renderCentersAndClassrooms()}
        <RANForm
          formProcessing={this.state.formProcessing}
          selectedCenter={this.state.selectedCenter}
          selectedClassroom={this.state.selectedClassroom}
          errors={this.state.errors}
          // showAlert={this.showAlert}
          formChangeRequested={formChangeRequested}
          dataHasChangedSwitch={this.dataHasChangedSwitch}
          changedGuideName={changedGuideName}
          changedPerformanceName={changedPerformanceName}
          changedAmsFormId={changedAmsFormId}
          changedSubmission={changedSubmission}
        />
      </div>
    );
  }
}

RANFormContainer.propTypes = {
  ran: PropTypes.object,
  fetchRANFormSchema: PropTypes.func.isRequired,
  fetchRANSubmission: PropTypes.func.isRequired,
  ranCenterSelected: PropTypes.func.isRequired,
  ranClassroomSelected: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ran: state.forms.ran,
});

export default connect(mapStateToProps, {
  fetchRANFormSchema,
  fetchRANSubmission,
  ranCenterSelected,
  ranClassroomSelected,
})(RANFormContainer);
