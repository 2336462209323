import {
  GRANTEE_TO_EXPORT,
  GRANTEES_FETCHED,
  GRANTEE_INVITED,
  RESET_GRANTEES_INVITED,
} from '../redux/types';

const initialState = {
  total: 0,
  granteeList: [],
  granteeToExport: [],
  invitedGrantees: [],
};

export default function grantees(state = initialState, action) {
  switch (action.type) {
    case GRANTEES_FETCHED: {
      return {
        ...state,
        granteeList: action.grantees.data,
        total: action.grantees.total,
        totalSelectable: action.grantees.totalSelectable,
      };
    }
    case GRANTEE_TO_EXPORT:
      return {
        ...state,
        granteeToExport: action.payload,
      };
    case GRANTEE_INVITED:
      return {
        ...state,
        invitedGrantees: action.invitedGrantees,
      };
    case RESET_GRANTEES_INVITED:
      return {
        ...state,
        invitedGrantees: [],
      };
    default:
      return state;
  }
}
