import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

class GranteeViewFinalizedMessage extends Component {
  renderDetails() {
    let { showFinalizedMessage } = this.props;
    return showFinalizedMessage ? (
      <Message
        header="Your unavailability has already been finalized!"
        content="You may view your unavailability but you cannot make any changes."
      />
    ) : (
      <div></div>
    );
  }

  render() {
    return <div> {this.renderDetails()} </div>;
  }
}

export default GranteeViewFinalizedMessage;
