import _, { isEmpty, pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  Icon,
  Accordion,
  Divider,
  Form,
  Modal,
  Button,
  Comment,
  Grid,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { managementSystemAnalysisReport } from '../../../../actions/reviewActions';
import ReviewLayout from '../../../../utils/layout/ReviewLayout';
import { Header } from 'semantic-ui-react';
import AmsModal from '../../../../utils/AmsModal';
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';
import { addNote } from '../../../../actions/noteActions';
import { Link } from 'react-router';
import DOMPurify from 'dompurify';

const ReviewManagementAnalysisReportPage = ({
  props,
  router,
  selectedReview,
}) => {
  const { params } = router;
  const dispatch = useDispatch();
  const [citation, setCitation] = useState('');
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [showSideBar, setShowSideBar] = useState(true);
  const [tags, setTags] = useState({});
  const [showNotesAlert, setShowNotesAlert] = useState(false);
  const currentUser = useSelector(state => state.auth.user);
  const [alertNoteBoxText, setAlertNoteBoxText] = useState('');
  const [modalDetailsIndex, setModalDetailsIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const managementSystemAnalysisSummary = useSelector(
    state => state.review.managementSystemAnalysisSummary
  );
  const [activeSummaryNote, setActiveSummaryNote] = useState('');

  useEffect(() => {
    setActiveIndexes([0]);
    setLoading(true);
    dispatch(managementSystemAnalysisReport({ reviewId: params.id })).then(
      e => {
        setLoading(false);
      }
    );
  }, []);

  const handleNoteChanges = (event, setter) => setter(event.target.value);

  const renderAddNotes = () => {
    const inlineStyle = {
      modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
      },
    };
    return (
      <AmsModal
        centered
        size="tiny"
        style={inlineStyle.modal}
        open={showNotesAlert}
      >
        <Header content={alertNoteBoxText} />
        <Modal.Content>
          <Form style={{ width: '150%', margin: '0 auto' }}>
            <Form.Field
              required
              id={'notes'}
              value={activeSummaryNote}
              control="textarea"
              placeholder="Enter the notes"
              rows={6}
              onChange={e => {
                handleNoteChanges(e, setActiveSummaryNote);
                managementSystemAnalysisNotes();
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setShowNotesAlert(false);
            }}
          >
            Close
          </Button>
          <Button
            disabled={activeSummaryNote.trim().length === 0}
            color="green"
            onClick={() => {
              submitNote();
              setShowNotesAlert(false);
              setLoading(true);
              dispatch(
                managementSystemAnalysisReport({ reviewId: params.id })
              ).then(e => {
                setLoading(false);
              });
            }}
          >
            Submit
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  const handleAccordionClick = index => {
    setActiveIndexes(prevIndexes => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(item => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  const handleShowSideBar = showSideBarInd => {
    setShowSideBar(showSideBarInd);
  };

  const renderManagementAnalysisReport = () => {
    if (
      managementSystemAnalysisSummary &&
      !_.isEmpty(managementSystemAnalysisSummary)
    ) {
      const summaries =
        managementSystemAnalysisSummary &&
        managementSystemAnalysisSummary.summaries;
      const accordionForm =
        summaries &&
        summaries.map((e, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: showSideBar ? '98%' : '125%',
                marginLeft: '-1px',
                marginTop: 20,
                borderWidth: '0px 1px 1px 1px',
                borderStyle: 'solid',
                borderColor: '#D9D9D9',
              }}
            >
              <React.Fragment key={idx}>
                <Accordion fluid>
                  <Accordion.Title
                    active={activeIndexes.includes(idx)}
                    index={idx}
                    style={{ backgroundColor: '#DCDCDC', marginTop: 20 }}
                    onClick={() => handleAccordionClick(idx)}
                  >
                    <h3 style={{ marginLeft: '10px' }}>
                      {e.title}
                      <Icon
                        style={{ float: 'right' }}
                        name={activeIndexes.includes(idx) ? 'minus' : 'plus'}
                      />
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndexes.includes(idx)}>
                    {renderManagementReportDetails(e)}
                  </Accordion.Content>
                </Accordion>
              </React.Fragment>
            </div>
          );
        });
      return accordionForm;
    }
  };

  const managementSystemAnalysisNotes = () => {
    setTags({
      type: 'management_analysis_summary_note',
      reviewId: params.id,
      citation,
    });
  };

  const submitNote = () => {
    const data = {
      note: activeSummaryNote,
      createdAt: AmsDateFormatters.getMoment().utc(),
      updateAt: AmsDateFormatters.getMoment().utc(),
      submittedBy: pick(currentUser, ['oid', 'userName', 'fullName']),
    };

    data.tags = tags;
    dispatch(addNote(data));
  };

  const renderManagementReportDetails = e => {
    const readOnly =
      (selectedReview || {}).reviewAccessLevel &&
      (selectedReview || {}).reviewAccessLevel.isReviewAccessible === false &&
      (selectedReview || {}).reviewStatus !== 'Report Signed' &&
      (selectedReview || {}).reviewStatus !== 'Shipped';

    return (
      <div
        className="management-analysis-report-card"
        style={{ wordWrap: 'break-word' }}
      >
        {e.evidenceSourceSummaries?.map((evidenceSourceSummary, index) => {
          const {
            evidenceSource,
            tqNumber,
            tqLanguage,
            tqResponse,
            tqCheckBoxes,
            tqNotes,
          } = evidenceSourceSummary;
          return (
            <div key={index} style={{ margin: '10px', marginBottom: '-1px' }}>
              <Form>
                <Form.Field width={16}>
                  <label>
                    <span style={{ fontFamily: 'PublicSans-Bold' }}>
                      {'Guide: '}
                    </span>
                    <span
                      style={{ fontWeight: 400, fontFamily: 'PublicSans-Bold' }}
                    >
                      {evidenceSource}
                    </span>
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span
                      style={{ fontWeight: 400, fontFamily: 'PublicSans-Bold' }}
                    >
                      {tqNumber}
                    </span>
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span
                      style={{ fontWeight: 400 }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(tqLanguage),
                      }}
                    />
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span style={{ fontFamily: 'PublicSans-Bold' }}>
                      {'Selected Answers: '}
                    </span>
                    <span style={{ fontWeight: 400 }}>{tqResponse}</span>
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span style={{ fontWeight: 400 }}>
                      <ul>
                        {tqCheckBoxes.map((tqCheckBox, idx) => (
                          <li key={idx}>
                            <div
                              dangerouslySetInnerHTML={{ __html: tqCheckBox }}
                            />
                          </li>
                        ))}
                      </ul>
                    </span>
                  </label>
                </Form.Field>
                <Form.Field width={16}>
                  <label>
                    <span style={{ fontFamily: 'PublicSans-Bold' }}>
                      {'Notes: '}
                    </span>
                    <br />
                    <span style={{ fontWeight: 400 }}>
                      <div dangerouslySetInnerHTML={{ __html: tqNotes }} />
                    </span>
                  </label>
                </Form.Field>
                <Divider />
              </Form>
            </div>
          );
        })}
        <Form>
          <Form.Field width={12}>
            <div
              style={{
                background: '#fff5e0',
                marginLeft: '40px',
                width: '120%',
                padding: '10px',
              }}
            >
              <label>
                <span style={{ fontFamily: 'PublicSans-Bold' }}>
                  {'Prompts'}
                </span>
              </label>
              <br />
              <ol>
                {e.questionTexts.map(questionText => (
                  <li key={questionText}>{questionText}</li>
                ))}
              </ol>
            </div>
          </Form.Field>
        </Form>
        <br />
        {e.summaryNotes && !isEmpty(e.summaryNotes) && (
          <Form>
            <Form.Field width={12}>
              <div
                style={{
                  marginLeft: '40px',
                  width: '120%',
                  padding: '10px',
                }}
              >
                <label>
                  <span style={{ fontFamily: 'PublicSans-Bold' }}>
                    {'RL/FOM Notes'}
                  </span>
                </label>
                <br />
                {e.summaryNotes.map(summaryNote => (
                  <div key={summaryNote.noteId}>
                    <span>
                      <Comment.Content>
                        <>
                          <Comment.Metadata>
                            <div>
                              Added By:{' '}
                              {summaryNote.userOid === currentUser.oid
                                ? 'You'
                                : summaryNote.userName}
                            </div>
                          </Comment.Metadata>
                          <Comment.Metadata>
                            <div>
                              Added On:{' '}
                              {AmsDateFormatters.formatDateTime(
                                summaryNote.addedOn
                              )}
                            </div>
                          </Comment.Metadata>
                        </>
                        <br />
                        <Comment.Text>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: summaryNote.note,
                            }}
                            style={{ whiteSpace: 'pre-line' }}
                          ></p>
                        </Comment.Text>
                        <br />
                        <Comment.Metadata>
                          {summaryNote.userOid === currentUser.oid && (
                            <Link>
                              <button
                                type="button"
                                className="add-option-link-button"
                                onClick={() => {
                                  setShowNotesAlert(true);
                                  setActiveSummaryNote(summaryNote.note);
                                  setCitation(e.citation);
                                  setAlertNoteBoxText('Edit Notes *');
                                }}
                              >
                                Edit
                              </button>
                            </Link>
                          )}
                        </Comment.Metadata>
                      </Comment.Content>
                    </span>
                    <br />
                  </div>
                ))}
              </div>
            </Form.Field>
          </Form>
        )}
        {((managementSystemAnalysisSummary.addNotesUsers &&
          managementSystemAnalysisSummary.addNotesUsers.includes(
            currentUser.oid
          )) ||
          currentUser.roles.includes('AmsAdmi')) &&
          !e.editNotesUsers.includes(currentUser.oid) &&
          !readOnly && (
            <Form>
              <Form.Field width={16}>
                <div
                  style={{
                    marginLeft: '40px',
                    width: '120%',
                    padding: '10px',
                  }}
                >
                  <button
                    type="button"
                    className="add-option-link-button"
                    onClick={() => {
                      setShowNotesAlert(true);
                      setCitation(e.citation);
                      setAlertNoteBoxText('Add Notes *');
                      setActiveSummaryNote('');
                    }}
                  >
                    Add Notes
                  </button>
                </div>
              </Form.Field>
              <br />
            </Form>
          )}
      </div>
    );
  };
  return (
    <ReviewLayout
      type="Management Systems Analysis Summary"
      enableSideBar={true}
      handleShowSideBar={handleShowSideBar}
    >
      <fieldset>
        <div
          style={{
            marginLeft: '18px',
          }}
        >
          {
            <>
              <Dimmer active={loading} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    as="h2"
                    content="Management Systems Analysis Summary"
                  />
                  {renderManagementAnalysisReport()}
                  {renderAddNotes()}
                </Grid.Column>
              </Grid.Row>
            </>
          }
        </div>
      </fieldset>
    </ReviewLayout>
  );
};
const mapStateToProps = state => {
  return {
    selectedReview: state.review.selectedReview,
  };
};

export default connect(mapStateToProps)(ReviewManagementAnalysisReportPage);
