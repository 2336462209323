export const config = {
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? `http://localhost:1337`
      : `http://localhost:1337`,
  awsUrl: 'http://35.185.30.249:8880',
  API_PORT: 1337,
};

export const timeout = {
  time: 3599999,
};

export const acl = {
  administrator: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
  manager: [
    'MonitoringSupportLead',
    'FieldOperationsManager',
    'OHSCOR',
    'OHSStaff',
    'Analyst',
    'TechnicalWriter',
    'Editor',
    'SeniorDirector',
    'ReviewLead',
  ],
  reviewer: ['Reviewer'],
  routes: {
    home: [
      'AmsAdmi',
      'HelpDesk',
      'ContentEditor',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'OHSStaff',
      'SeniorDirector',
      'FollowupLead',
      'RegionalOfficeStaff',
      'OfficeofGeneralCouncil',
      'DualCoder',
      'ProgramSpecialist',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalProgramManager',
      'AnalyticsUser',
    ],
    help: [
      'AmsAdmi',
      'HelpDesk',
      'ContentEditor',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'OHSStaff',
      'SeniorDirector',
      'FollowupLead',
      'RegionalOfficeStaff',
      'OfficeofGeneralCouncil',
      'DualCoder',
      'ProgramSpecialist',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalProgramManager',
      'AnalyticsUser',
    ],
    workbench: [
      'AmsAdmi',
      'HelpDesk',
      'ContentEditor',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'OHSStaff',
      'SeniorDirector',
      'FollowupLead',
      'RegionalOfficeStaff',
      'OfficeofGeneralCouncil',
      'DualCoder',
      'ProgramSpecialist',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalProgramManager',
    ],
    profile: [
      'AmsAdmi',
      'HelpDesk',
      'ContentEditor',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'OHSStaff',
      'SeniorDirector',
      'FollowupLead',
      'RegionalOfficeStaff',
      'OfficeofGeneralCouncil',
      'DualCoder',
      'ProgramSpecialist',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalProgramManager',
      'AnalyticsUser',
    ],
    task: [
      'AmsAdmi',
      'HelpDesk',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'DualCoder',
      'OfficeofGeneralCouncil',
      'ProgramSpecialist',
      'FollowupLead',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalOfficeStaff',
      'RegionalProgramManager',
    ],
    review: [
      'AmsAdmi',
      'HelpDesk',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'OHSStaff',
      'SeniorDirector',
      'FollowupLead',
      'RegionalOfficeStaff',
      'OfficeofGeneralCouncil',
      'DualCoder',
      'ProgramSpecialist',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalProgramManager',
    ],
    report: [
      'AmsAdmi',
      'HelpDesk',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'SeniorDirector',
      'OHSStaff',
      'RegionalProgramManager',
      'OfficeofGeneralCouncil',
      'FollowupLead',
      'ReviewLead',
      'ProgramSpecialist',
    ],
    grantee: [
      'AmsAdmi',
      'HelpDesk',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'OHSStaff',
      'SeniorDirector',
      'FollowupLead',
      'RegionalOfficeStaff',
      'OfficeofGeneralCouncil',
      'DualCoder',
      'ProgramSpecialist',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalProgramManager',
    ],
    myregionalreviews: ['RegionalProgramManager', 'AmsAdmi', 'HelpDesk'],
    calendar: [
      'AmsAdmi',
      'HelpDesk',
      'ContentEditor',
      'Reviewer',
      'ReviewLead',
      'LogisticsAssociate',
      'FieldOperationsManager',
      'Analyst',
      'TechnicalWriter',
      'Editor',
      'OHSCOR',
      'OHSStaff',
      'SeniorDirector',
      'OfficeofGeneralCouncil',
      'DualCoder',
      'ProgramSpecialist',
      'FiscalSpecialist',
      'GrantSpecialist',
      'RegionalProgramManager',
    ],
    planning: ['AmsAdmi', 'HelpDesk', 'ReviewLead'],
    admin: {
      tasks: ['AmsAdmi', 'HelpDesk'],
      surveys: ['AmsAdmi', 'HelpDesk'],
      reports: ['AmsAdmi', 'HelpDesk'],
      users: ['AmsAdmi', 'HelpDesk', 'ContentEditor', 'LogisticsAssociate'],
      templates: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      parameters: ['AmsAdmi', 'HelpDesk'],
    },
    dashboard: ['AnalyticsUser'],
  },
  actions: {
    caraousel: {
      create: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      update: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      delete: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
    },
    help: {
      create: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      update: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      delete: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
    },
    profile: {
      userSettings: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      checkBoxValidation: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      adminPanel: [
        'AmsAdmi',
        'HelpDesk',
        'ContentEditor',
        'LogisticsAssociate',
      ],
    },
    resource: {
      create: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      update: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
      delete: ['AmsAdmi', 'HelpDesk', 'ContentEditor'],
    },
    review: {
      create: ['AmsAdmi', 'HelpDesk', 'LogisticsAssociate'],
      update: ['AmsAdmi', 'HelpDesk', 'LogisticsAssociate'],
      specialAction: [
        'AmsAdmi',
        'HelpDesk',
        'LogisticsAssociate',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'FieldOperationsManager',
        'FollowupLead',
        'OfficeofGeneralCouncil',
        'RegionalProgramManager',
        'ProgramSpecialist',
        'ReviewLead',
      ],
      delete: ['AmsAdmi', 'HelpDesk'],
      finalize: ['AmsAdmi', 'HelpDesk', 'ReviewLead'],
      findings: [
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'AmsAdmi',
        'FieldOperationsManager',
        'LogisticsAssociate',
        'HelpDesk',
        'SeniorDirector',
        'FollowupLead',
        'GrantSpecialist',
        'RegionalProgramManager',
        'RegionalOfficeStaff',
        'OfficeofGeneralCouncil',
        'FiscalSpecialist',
        'OHSCOR',
        'ProgramSpecialist',
        'ReviewLead',
        'OHSStaff',
      ],
      surveyFindings: [
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'AmsAdmi',
        'FieldOperationsManager',
        'LogisticsAssociate',
        'HelpDesk',
        'SeniorDirector',
        'FollowupLead',
        'GrantSpecialist',
        'RegionalProgramManager',
        'RegionalOfficeStaff',
        'OfficeofGeneralCouncil',
        'FiscalSpecialist',
        'OHSCOR',
        'ProgramSpecialist',
        'ReviewLead',
        'OHSStaff',
        'Reviewer',
      ],
      viewBlock: {
        specialEdit: ['AmsAdmi', 'HelpDesk', 'LogisticsAssociate'],
      },
      viewReviewerState: ['AmsAdmi', 'HelpDesk', 'LogisticsAssociate'],
      CAPUpdate: [
        'AmsAdmi',
        'HelpDesk',
        'LogisticsAssociate',
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'FieldOperationsManager',
      ],
      journal: [
        'ReviewLead',
        'ProgramSpecialist',
        'FollowupLead',
        'FieldOperationsManager',
        'RegionalProgramManager',
        'LogisticsAssociate',
        'AmsAdmi',
        'HelpDesk',
        'Analyst',
        'TechnicalWriter',
        'Editor',
      ],
    },
    survey: {
      update: [
        'AmsAdmi',
        'HelpDesk',
        'Reviewer',
        'ReviewLead',
        'LogisticsAssociate',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSStaff',
      ],
      delete: ['AmsAdmi', 'HelpDesk'],
      finalize: ['AmsAdmi', 'HelpDesk', 'ReviewLead'],
      outcomes: [
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'AmsAdmi',
        'FieldOperationsManager',
        'LogisticsAssociate',
        'HelpDesk',
        'SeniorDirector',
        'GrantSpecialist',
        'RegionalProgramManager',
      ],
      classroomExplorationCard: [
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'AmsAdmi',
        'FieldOperationsManager',
        'LogisticsAssociate',
        'HelpDesk',
        'SeniorDirector',
        'FollowupLead',
        'GrantSpecialist',
        'RegionalProgramManager',
        'RegionalOfficeStaff',
      ],
      centerAndFacilitiesExplorationCard: [
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'AmsAdmi',
        'FieldOperationsManager',
        'LogisticsAssociate',
        'HelpDesk',
        'SeniorDirector',
        'FollowupLead',
        'GrantSpecialist',
        'RegionalProgramManager',
        'RegionalOfficeStaff',
      ],
      childFilesCard: [
        'Reviewer',
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'AmsAdmi',
        'FieldOperationsManager',
        'LogisticsAssociate',
        'HelpDesk',
        'SeniorDirector',
        'FollowupLead',
        'GrantSpecialist',
        'RegionalProgramManager',
        'RegionalOfficeStaff',
      ],
      reportTemplateCard: [
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'AmsAdmi',
        'FieldOperationsManager',
        'LogisticsAssociate',
        'HelpDesk',
        'SeniorDirector',
        'FollowupLead',
        'OHSStaff',
        'GrantSpecialist',
        'RegionalProgramManager',
        'RegionalOfficeStaff',
      ],
      dataCollectionOutcomes: [
        'AmsAdmi',
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'HelpDesk',
        'ContentEditor',
        'LogisticsAssociate',
        'GrantSpecialist',
        'RegionalProgramManager',
      ],
      deleteChildFiles: [
        'AmsAdmi',
        'ReviewLead',
        'Reviewer',
        'HelpDesk',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
      ],
      deleteIncidentFiles: [
        'AmsAdmi',
        'ReviewLead',
        'Reviewer',
        'HelpDesk',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'FollowupLead',
      ],
      manageCenterAndClassrooms: [
        'AmsAdmi',
        'ReviewLead',
        'HelpDesk',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'LogisticsAssociate',
      ],
    },
    task: {
      delete: ['AmsAdmi', 'HelpDesk'],
      showAssignee: ['AmsAdmi', 'HelpDesk'],
      presiteTab: [
        'AmsAdmi',
        'ReviewLead',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'HelpDesk',
        'ContentEditor',
      ],
    },
    users: {
      create: ['AmsAdmi', 'HelpDesk'],
      edit: ['AmsAdmi', 'HelpDesk'],
      delete: ['AmsAdmi', 'HelpDesk'],
    },
    report: {
      create: ['AmsAdmi', 'HelpDesk', 'Analyst', 'TechnicalWriter', 'Editor'],
      update: ['AmsAdmi', 'HelpDesk', 'Analyst', 'TechnicalWriter', 'Editor'],
      comment: [
        'AmsAdmi',
        'HelpDesk',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
        'OHSStaff',
      ],
      share: ['AmsAdmi', 'HelpDesk', 'Analyst', 'TechnicalWriter', 'Editor'],
      finalize: [
        'AmsAdmi',
        'HelpDesk',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
      ],
      sign: [
        'AmsAdmi',
        'HelpDesk',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
      ],
      download: [
        'AmsAdmi',
        'HelpDesk',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
        'OHSStaff',
      ],
    },
    manifest: {
      recreate: ['AmsAdmi', 'HelpDesk'],
    },
    classDetailButtons: {
      attachDocuments: ['AmsAdmi'],
      assignments: [
        'AmsAdmi',
        'ReviewLead',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
        'HelpDesk',
      ],
      observationSampleSummary: [
        'AmsAdmi',
        'ReviewLead',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
        'HelpDesk',
      ],
      methodologySummary: [
        'AmsAdmi',
        'ReviewLead',
        'Reviewer',
        'DualCoder',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
        'HelpDesk',
      ],
      dualCoderComparisonSummary: [
        'AmsAdmi',
        'ReviewLead',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'DualCoder',
        'OHSCOR',
        'HelpDesk',
      ],
      passFailSummary: [
        'AmsAdmi',
        'ReviewLead',
        'FieldOperationsManager',
        'DualCoder',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OHSCOR',
        'HelpDesk',
      ],
      draftReport: [
        'AmsAdmi',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'HelpDesk',
        'OHSCOR',
      ],
      approveSurveys: ['AmsAdmi', 'ReviewLead', 'FieldOperationsManager'],
      editSubmissions: [
        'AmsAdmi',
        'ReviewLead',
        'FieldOperationsManager',
        'DualCoder',
        'Reviewer',
        'Analyst',
        'TechnicalWriter',
        'Editor',
      ],
      submitToFOM: [
        'AmsAdmi',
        'ReviewLead',
        'HelpDesk',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'ProgramSpecialist',
        'FiscalSpecialist',
        'FollowupLead',
        'FieldOperationsManager',
        'RegionalProgramManager',
      ],
      submitToManager: [
        'AmsAdmi',
        'ReviewLead',
        'HelpDesk',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'OfficeofGeneralCouncil',
        'ProgramSpecialist',
        'FiscalSpecialist',
        'FollowupLead',
        'FieldOperationsManager',
        'RegionalProgramManager',
      ],
      viewVersionHistory: [
        'AmsAdmi',
        'ReviewLead',
        'FieldOperationsManager',
        'Analyst',
        'TechnicalWriter',
        'Editor',
      ],
      removeClassroom: ['AmsAdmi', 'HelpDesk'],
    },
    followUp: {
      editSubmissions: [
        'AmsAdmi',
        'FollowupLead',
        'OfficeofGeneralCouncil',
        'ProgramSpecialist',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'HelpDesk',
        'FieldOperationsManager',
        'RegionalProgramManager',
        'Reviewer',
      ],
    },
    adhoc: {
      generate: [
        'AmsAdmi',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'FieldOperationsManager',
        'HelpDesk',
      ],
      downloadSampleReport: [
        'AmsAdmi',
        'Analyst',
        'TechnicalWriter',
        'Editor',
        'FieldOperationsManager',
        'ReviewLead',
        'HelpDesk',
        'Reviewer',
        'LogisticsAssociate',
        'DualCoder',
      ],
    },
    grantee: {
      sendInvites: ['AmsAdmi', 'HelpDesk', 'LogisticsAssociate'],
    },
  },
};

export const menu = {
  default: [
    {
      link: '/workbench',
      title: 'Workbench',
      iconName: 'ams-icon ams-icon--workbench',
    },
    {
      link: '/profile',
      title: 'Profile',
      iconName: 'ams-icon ams-icon--profile',
    },
    {
      link: '/task',
      title: 'Task',
      iconName: 'ams-icon ams-icon--task',
    },
    {
      link: '/review',
      title: 'Review',
      iconName: 'ams-icon ams-icon--review',
    },
    {
      link: '/report',
      title: 'Report',
      iconName: 'ams-icon ams-icon--report',
    },
    {
      link: '/grantee',
      title: 'Grantee',
      iconName: 'ams-icon ams-icon--grantee',
    },
    {
      link: '/myregionalreviews',
      title: 'My Regional Reviews',
    },
    {
      link: '/surveyquestions',
      title: 'Survey Questions',
    },
  ],
  administrator: [],
  manager: [],
  reviewer: [],
};

export const filters = {
  reviewTypes: ['CLASS', 'FA-1', 'FA-2', 'Other', 'Follow-up'],
  reviewStatus: [
    'Initialized',
    'Scheduled',
    'Ready for Report',
    'In Progress',
    'In Analysis',
    'Completed',
  ],
  teamStatus: [
    'initial',
    'inprogress',
    'finalized',
    'signed',
    'Edit',
    'Proof',
    'Regional Review',
    'OHS/OGC Review',
    'Proof II',
    'Shipped',
  ],
  teamUsers: [
    'Timothy McCormack',
    'Paula Straub',
    'Stacey Feaster',
    'Julie Heyneman',
    'Max Magana',
    'Barb Miller',
    'Regional Office',
    'OHS',
    'OGC',
  ],
  // surveyNames: [ Old List
  //   { label: 'FA1 Program Management and Fiscal Infrastructure' },
  //   { label: 'FA1 Program Structure and Design' },
  //   { label: 'FA1 ECD-Health-FCE 2' },
  //   { label: 'FA1 Effective Recruitment and Selection Strategies' },
  //   { label: 'FA1 Interview Schedule' },
  //   { label: 'FA1 Program Specialist Conversation' },
  //   { label: 'FA1 Preliminary Document Review' },
  //   { label: 'FA1 Correspondence Tracker' },
  //   { label: 'FA1 Additional Notes' },
  //   { label: 'FA2 Pre-planning Conversation' },
  //   { label: 'ERSEA Child File Survey' }
  // ],
  surveyNames: [
    { label: 'FA2 Center and Facility Exploration' },
    { label: 'FA2 Child Files' },
    { label: 'FA2 Classroom Exploration' },
    { label: 'FA2 Closeout Survey' },
    { label: 'FA2 Grantee Introductory Call' },
    { label: 'FA2 Grantee Planning Call' },
    { label: 'FA2 Home-Visit Exploration' },
    { label: 'FA2 Initial HSES Check' },
    { label: 'FA2 OHS Notes' },
    { label: 'FA2 Pre-Site Data Analysis Summary' },
    { label: 'FA2 RAN Survey' },
    { label: 'FA2 Regional Office Call' },
    { label: 'FA2 Report Analyst Survey' },
    { label: 'FA2 Review Sample Sizes' },
    { label: 'FA2 Socialization Exploration' },
    { label: 'FA2 Tracking Systems' },
    { label: 'FA2 PDM EAS' },
    { label: 'FA2 ECD EAS' },
    { label: 'FA2 FCE EAS' },
    { label: 'FA2 FIS ERSEA EAS' },
    { label: 'FA2 Health EAS' },
    { label: 'FA2 Multi-Grant ECD EAS' },
    { label: 'FA2 Multi-Grant FCE EAS' },
    { label: 'FA2 Multi-Grant FIS ERSEA EAS' },
    { label: 'FA2 Multi-Grant Health EAS' },
    { label: 'FA2 Multi-Grant PDM EAS' },
  ],
  surveyStatus: [{ label: 'Pending' }, { label: 'Completed' }],
  regions: [
    { label: 'Region 1 - Boston', value: '1' },
    { label: 'Region 2 - New York', value: '2' },
    { label: 'Region 3 - Philadelphia', value: '3' },
    { label: 'Region 4 - Atlanta', value: '4' },
    { label: 'Region 5 - Chicago', value: '5' },
    { label: 'Region 6 - Dallas', value: '6' },
    { label: 'Region 7 - Kansas City', value: '7' },
    { label: 'Region 8 - Denver', value: '8' },
    { label: 'Region 9 - San Francisco', value: '9' },
    { label: 'Region 10 - Seattle', value: '10' },
    { label: 'Region 11 - American Indian, Alaska Native Region', value: '11' },
    { label: 'Region 12 - Migrant and Seasonal Programs', value: '12' },
  ],

  userTypes: [
    { label: 'Federal', value: 'Federal' },
    { label: 'Consultant', value: 'Consultant' },
    { label: 'Peer Reviewer', value: 'Peer Reviewer' },
    { label: 'Not a Reviewer', value: 'Not a Reviewer' },
  ],

  hsesStatus: [
    { label: 'Grantee Outcome', value: 'Grantee Outcome' },
    { label: 'Compliant', value: 'Compliant' },
    { label: 'Deficient', value: 'Deficient' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Noncompliant', value: 'Noncompliant' },
    { label: 'Not Available', value: 'Not Available' },
    { label: 'Relinquished', value: 'Relinquished' },
    { label: 'Terminated', value: 'Terminated' },
  ],
  currentGranteeStatus: [
    { label: 'Grantee Outcome', value: 'Grantee Outcome' },
    { label: 'Compliant', value: 'Compliant' },
    { label: 'Deficient', value: 'Deficient' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Noncompliant', value: 'Noncompliant' },
    { label: 'Not Available', value: 'Not Available' },
    { label: 'Relinquished', value: 'Relinquished' },
    { label: 'Terminated', value: 'Terminated' },
  ],
  taskTitles: [
    { label: 'Perform Data Collection', value: 'Perform Data Collection' },
    { label: 'Finalize Data Collection', value: 'Finalize Data Collection' },
    { label: 'Perform Report', value: 'Perform Report' },
  ],
  taskStatus: [
    { label: 'Completed', value: 'Completed' },
    { label: 'In Progress', value: 'In Progress' },
  ],
  surveyTypes: ['Pre-site', 'Onsite', 'EAS'],
};

export const appRoles = [
  { label: 'AMS Administrator', value: 'AmsAdmi' },
  { label: 'Analyst', value: 'Analyst' },
  { label: 'Content Editor', value: 'ContentEditor' },
  { label: 'Field Operations Manager', value: 'FieldOperationsManager' },
  { label: 'Help Desk', value: 'HelpDesk' },
  { label: 'Monitoring Support Lead', value: 'MonitoringSupportLead' },
  { label: 'OHS COR', value: 'OHSCOR' },
  { label: 'OHS Staff', value: 'OHSStaff' },
  {
    label: 'Regional Office Program Specialist',
    value: 'RegionalOfficeProgramSpecialist',
  },
  { label: 'Review Lead', value: 'ReviewLead' },
  { label: 'Reviewer', value: 'Reviewer' },
  { label: 'Senior Director', value: 'SeniorDirector' },
  { label: 'Technical Writer', value: 'TechnicalWriter' },
  { label: 'Editor', value: 'Editor' },
];

export const tabs = {
  adminTabs: [
    'Tasks',
    'Surveys',
    'Reports',
    'Users',
    'Templates',
    'Parameters',
    'Files',
    'Logs',
    'SurveyQuestions',
  ],
};

export const restrictedComponents = {
  presiteTab: ['ReviewLead'],
  debriefOutcomes: ['ReviewLead', 'Analyst', 'AmsAdmi'],
};

export const restrictedButtons = {
  surveyEditButton: ['Analyst', 'TechnicalWriter', 'Editor'],
  surveyFinalizeButton: ['ReviewLead'],
  reviewFinalizeButton: ['Analyst', 'TechnicalWriter', 'Editor'],
  surveyEASEditButton: ['Analyst', 'TechnicalWriter', 'Editor', 'ReviewLead'],
  editSurveyButton: ['ReviewLead', 'Reviewer'],
};

export const salutations = [
  { key: 'none', text: '-- None --', value: '' },
  { key: 'dr', text: 'Dr.', value: 'Dr' },
  { key: 'mr', text: 'Mr.', value: 'Mr' },
  { key: 'mrs', text: 'Mrs.', value: 'Mrs' },
  { key: 'ms', text: 'Ms.', value: 'Ms' },
];

export const usStates = [
  { key: 'none', value: '', text: 'None' },
  { key: 'AL', value: 'AL', text: 'Alabama' },
  { key: 'AK', value: 'AK', text: 'Alaska' },
  { key: 'AZ', value: 'AZ', text: 'Arizona' },
  { key: 'AR', value: 'AR', text: 'Arkansas' },
  { key: 'CA', value: 'CA', text: 'California' },
  { key: 'CO', value: 'CO', text: 'Colorado' },
  { key: 'CT', value: 'CT', text: 'Connecticut' },
  { key: 'DE', value: 'DE', text: 'Delaware' },
  { key: 'DC', value: 'DC', text: 'District of Columbia' },
  { key: 'FL', value: 'FL', text: 'Florida' },
  { key: 'GA', value: 'GA', text: 'Georgia' },
  { key: 'HI', value: 'HI', text: 'Hawaii' },
  { key: 'ID', value: 'ID', text: 'Idaho' },
  { key: 'IL', value: 'IL', text: 'Illinois' },
  { key: 'IN', value: 'IN', text: 'Indiana' },
  { key: 'IA', value: 'IA', text: 'Iowa' },
  { key: 'KS', value: 'KS', text: 'Kansas' },
  { key: 'KY', value: 'KY', text: 'Kentucky' },
  { key: 'LA', value: 'LA', text: 'Louisiana' },
  { key: 'ME', value: 'ME', text: 'Maine' },
  { key: 'MD', value: 'MD', text: 'Maryland' },
  { key: 'MA', value: 'MA', text: 'Massachusetts' },
  { key: 'MI', value: 'MI', text: 'Michigan' },
  { key: 'MN', value: 'MN', text: 'Minnesota' },
  { key: 'MS', value: 'MS', text: 'Mississippi' },
  { key: 'MO', value: 'MO', text: 'Missouri' },
  { key: 'MT', value: 'MT', text: 'Montana' },
  { key: 'NE', value: 'NE', text: 'Nebraska' },
  { key: 'NV', value: 'NV', text: 'Nevada' },
  { key: 'NH', value: 'NH', text: 'New Hampshire' },
  { key: 'NJ', value: 'NJ', text: 'New Jersey' },
  { key: 'NM', value: 'NM', text: 'New Mexico' },
  { key: 'NY', value: 'NY', text: 'New York' },
  { key: 'NC', value: 'NC', text: 'North Carolina' },
  { key: 'ND', value: 'ND', text: 'North Dakota' },
  { key: 'OH', value: 'OH', text: 'Ohio' },
  { key: 'OK', value: 'OK', text: 'Oklahoma' },
  { key: 'OR', value: 'OR', text: 'Oregon' },
  { key: 'PA', value: 'PA', text: 'Pennsylvania' },
  { key: 'RI', value: 'RI', text: 'Rhode Island' },
  { key: 'SC', value: 'SC', text: 'South Carolina' },
  { key: 'SD', value: 'SD', text: 'South Dakota' },
  { key: 'TN', value: 'TN', text: 'Tennessee' },
  { key: 'TX', value: 'TX', text: 'Texas' },
  { key: 'UT', value: 'UT', text: 'Utah' },
  { key: 'VT', value: 'VT', text: 'Vermont' },
  { key: 'VA', value: 'VA', text: 'Virginia' },
  { key: 'WA', value: 'WA', text: 'Washington' },
  { key: 'WV', value: 'WV', text: 'West Virginia' },
  { key: 'WI', value: 'WI', text: 'Wisconsin' },
  { key: 'WY', value: 'WY', text: 'Wyoming' },
];

export const ethnicity = [
  { key: '0', text: '-- None --', value: '' },
  { key: 'afican', text: 'African American', value: 'African' },
  { key: 'asian', text: 'Asian', value: 'Asian' },
  { key: 'caucasian', text: 'Caucasian', value: 'Caucasian' },
  { key: 'alaskaNative', text: 'Alaska Native', value: 'Alaska Native' },
  {
    key: 'pacificIslander',
    text: 'Pacific Islander',
    value: 'Pacific Islander',
  },
  {
    key: 'biRacialMultiRacial',
    text: 'Bi-racial/Multi-racial',
    value: 'Bi-racial/Multi-racial',
  },
  { key: 'nativeAmerican', text: 'Native American', value: 'Native American' },
  { key: 'other', text: 'Other', value: 'Other' },
];

export const languageList = [
  { key: 'Africans', text: 'Africans', value: 'Africans' },
  { key: 'Apache', text: 'Apache', value: 'Apache' },
  { key: 'Arabic', text: 'Arabic', value: 'Arabic' },
  { key: 'Cantonese', text: 'Cantonese', value: 'Cantonese' },
  { key: 'Cherokee', text: 'Cherokee', value: 'Cherokee' },
  { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
  { key: 'Choctaw', text: 'Choctaw', value: 'Choctaw' },
  { key: 'Creole', text: 'Creole', value: 'Creole' },
  { key: 'Dakota', text: 'Dakota', value: 'Dakota' },
  { key: 'English', text: 'English', value: 'English' },
  { key: 'Farsi', text: 'Farsi', value: 'Farsi' },
  { key: 'French', text: 'French', value: 'French' },
  { key: 'German', text: 'German', value: 'German' },
  { key: 'Hawaiian', text: 'Hawaiian', value: 'Hawaiian' },
  { key: 'Hindi', text: 'Hindi', value: 'Hindi' },
  { key: 'Hungarian', text: 'Hungarian', value: 'Hungarian' },
  { key: 'Italian', text: 'Italian', value: 'Italian' },
  { key: 'Japanese', text: 'Japanese', value: 'Japanese' },
  { key: 'Latin', text: 'Latin', value: 'Latin' },
  { key: 'Ojibwa', text: 'Ojibwa', value: 'Ojibwa' },
  { key: 'Polish', text: 'Polish', value: 'Polish' },
  { key: 'Portuguese', text: 'Portuguese', value: 'Portuguese' },
  { key: 'Samoan', text: 'Samoan', value: 'Samoan' },
  { key: 'Sanskrit', text: 'Sanskrit', value: 'Sanskrit' },
  { key: 'Sign (asl)', text: 'Sign (asl)', value: 'Sign (asl)' },
  { key: 'Spanish', text: 'Spanish', value: 'Spanish' },
  { key: 'Tagalog', text: 'Tagalog', value: 'Tagalog' },
  { key: 'Taiwanese', text: 'Taiwanese', value: 'Taiwanese' },
  { key: 'Thai', text: 'Thai', value: 'Thai' },
  { key: 'Turkish', text: 'Turkish', value: 'Turkish' },
  { key: 'Urdu', text: 'Urdu', value: 'Urdu' },
  { key: 'Vietnamese', text: 'Vietnamese', value: 'Vietnamese' },
  { key: 'Yiddish', text: 'Yiddish', value: 'Yiddish' },
];

export const languageProficiency = [
  { key: 'Fully Fluent', text: 'Fully Fluent', value: 'Fully Fluent' },
  { key: 'Conversational', text: 'Conversational', value: 'Conversational' },
];

export const highestEducationLevelsList = [
  { key: 'High School', text: 'High School', value: 'High School' },
  {
    key: 'Undergraduate Degree',
    text: 'Undergraduate Degree',
    value: 'Undergraduate Degree',
  },
  { key: 'Graduate Degree', text: 'Graduate Degree', value: 'Graduate Degree' },
];

export const regions = [
  { key: '1', text: 'Region 1 - Boston', value: '1' },
  { key: '2', text: 'Region 2 - New York', value: '2' },
  { key: '3', text: 'Region 3 - Philadelphia', value: '3' },
  { key: '4', text: 'Region 4 - Atlanta', value: '4' },
  { key: '5', text: 'Region 5 - Chicago', value: '5' },
  { key: '6', text: 'Region 6 - Dallas', value: '6' },
  { key: '7', text: 'Region 7 - Kansas City', value: '7' },
  { key: '8', text: 'Region 8 - Denver', value: '8' },
  { key: '9', text: 'Region 9 - San Francisco', value: '9' },
  { key: '10', text: 'Region 10 - Seattle', value: '10' },
  {
    key: '11',
    text: 'Region 11 - American Indian, Alaska Native Region',
    value: '11',
  },
  { key: '12', text: 'Region 12 - Migrant and Seasonal Programs', value: '12' },
];

export const currentGranteeStatus = [
  { key: 'Grantee Outcome', text: 'Grantee Outcome', value: 'Grantee Outcome' },
  { key: 'Compliant', text: 'Compliant', value: 'Compliant' },
  { key: 'Deficient', text: 'Deficient', value: 'Deficient' },
  { key: 'Inactive', text: 'Inactive', value: 'Inactive' },
  { key: 'Noncompliant', text: 'Noncompliant', value: 'Noncompliant' },
  { key: 'Not Available', text: 'Not Available', value: 'Not Available' },
  { key: 'Relinquished', text: 'Relinquished', value: 'Relinquished' },
  { key: 'Terminated', text: 'Terminated', value: 'Terminated' },
];

export const settings = {
  defaultTimeZone: 'America/New_York',
  defaultDateFormat: 'MM/DD/YYYY',
  defaultTimeFormat: 'h:mm A',
  defaultYearFormat: 'YYYY',
  calendar: {
    events: {
      colors: {
        unavailable: '#D63900',
        withdrawn: '#6D62EA',
        declined: '#590000',
        review: '#006600',
        federalHolidays: '#205493',
        invited: '#59037a',
      },
    },
  },
};
