import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Icon, Segment } from 'semantic-ui-react';
import $ from 'jquery';

// Import configs
import { settings } from '../../config';

// Import jQueyr Full Calendar.
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar/dist/fullcalendar.js';

import './assets/style.css';

// Import actions.
import {
  fetchHolidays,
  fetchUserEvents,
  fetchAdministeredUserEvents,
} from '../../actions/calendarAction';

// Import components.
import CalendarUnavailableModal from './CalendarUnavailableModal';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

class Calendar extends Component {
  state = {
    showUnavailabilityModal: false,
    currentYear: AmsDateFormatters.getMoment().year(),
    selectedEvent: {},
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    this.buildCalendar();
    $('.fc-prev-button').attr('aria-label', 'previous month');
    $('.fc-next-button').attr('aria-label', 'next month');
  }

  buildCalendar() {
    const { holidays, events, simple } = this.props;

    $('#calendar').fullCalendar({
      header: {
        left: 'title',
        // right: 'prev,next today  addUnavailability, month,listMonth',
        right:
          simple && simple
            ? 'prev,next today'
            : 'prev,next today  addUnavailability, month,listMonth',
      },
      contentHeight: 'auto',
      eventLimit: 3,
      weekends: true,
      customButtons: {
        addUnavailability: {
          text: 'Add Unavailability',
          click: () => {
            this.setState({ showUnavailabilityModal: true });
          },
        },
      },
      timezone: 'America/New_York', // We're enforcing Easter Timezone.
      showNonCurrentDates: false,
      eventRender: this.eventRender.bind(this),
      eventClick: this.handleEventClick.bind(this),
      viewRender: this.renderCalendarView.bind(this),
      eventMouseover: this.handleEventMouseover.bind(this),
      displayEventTime: false,
    });

    // Create a link for review events
    // eslint-disable-next-line array-callback-return
    events.map(event => {
      if (event.type === 'review' || event.type === 'invited')
        event.url = `/review/${event.title.trim()}`;
    });

    // Create title for Holiday Events
    // eslint-disable-next-line array-callback-return
    holidays.map(holiday => {
      let title = holiday.description;
      holiday.title = title;
      holiday.color = settings.calendar.events.colors.federalHolidays;
    });

    $('#calendar').fullCalendar('removeEvents');
    $('#calendar').fullCalendar('addEventSource', [...holidays, ...events]);
  }

  getData() {
    const { currentYear } = this.state;
    this.props.fetchHolidays(currentYear);
    if (!this.props.userAdministration) {
      this.props.fetchUserEvents(currentYear);
    } else {
      this.props.fetchAdministeredUserEvents(currentYear, this.props.oid);
    }
  }

  getHolidays() {
    const { currentYear } = this.state;
    this.props.fetchHolidays(currentYear);
  }

  getEvents() {
    const { currentYear } = this.state;
    if (!this.props.userAdministration) {
      this.props.fetchUserEvents(currentYear);
    } else {
      this.props.fetchAdministeredUserEvents(currentYear, this.props.oid);
    }
  }

  handleEventClick(calEvent, jsEvent, view) {
    if (calEvent && calEvent.title.toLowerCase() === 'unavailable') {
      this.setState({ showUnavailabilityModal: true, selectedEvent: calEvent });
    }
  }

  handleEventMouseover(calEvent, jsEvent, view) {
    // alert(calEvent.title);
  }

  popoverHoverFocus() {}

  eventRender(event, element) {}

  renderCalendarView(view, element) {
    // When Year changes, get that year's holidays end events.
    const { currentYear } = this.state;
    const intervalYear = view.intervalStart.year();

    if (intervalYear && intervalYear !== currentYear) {
      this.setState({ currentYear: intervalYear }, () => {
        this.getHolidays(intervalYear);
        this.getEvents(intervalYear);
      });
    }
  }

  renderUnavailabilityModal() {
    const { showUnavailabilityModal, selectedEvent } = this.state;

    return (
      <CalendarUnavailableModal
        showModal={showUnavailabilityModal}
        selectedEvent={selectedEvent}
        closeEditModal={this.closeUnavailabilityModal.bind(this)}
        refreshCalendar={this.getData.bind(this)}
        oid={this.props.oid || ''}
      />
    );
  }

  closeUnavailabilityModal() {
    this.setState({
      selectedEvent: {}, // Clear selection
      showUnavailabilityModal: false,
    });
  }

  renderLegend() {
    const lastUpdatedDate =
      this.props.lastUpdatedDate &&
      AmsDateFormatters.formatDate(this.props.lastUpdatedDate);

    return (
      <>
        <Segment basic textAlign="center">
          <List horizontal>
            <List.Item>
              <List.Content>Legend:</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Icon circular name="circle" style={{ color: '#ffffff' }} />{' '}
                Available
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Icon
                  name="circle"
                  style={{ color: '#205493' }}
                  className="federal-holiday"
                />{' '}
                Federal Holiday
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Icon
                  name="circle"
                  style={{ color: '#d63900' }}
                  className="unavailable"
                />{' '}
                Unavailable
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Icon
                  name="circle"
                  style={{ color: '#6d62ea' }}
                  className="withdrawn"
                />{' '}
                Withdrawn
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Icon
                  name="circle"
                  style={{ color: '#590000' }}
                  className="declined"
                />{' '}
                Declined
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Icon
                  name="circle"
                  style={{ color: '#006600' }}
                  className="review"
                />{' '}
                Review
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Icon
                  name="circle"
                  style={{ color: '#59037a' }}
                  className="invited"
                />{' '}
                Invited
              </List.Content>
            </List.Item>
          </List>
        </Segment>

        <Segment basic textAlign="center">
          <div>Last updated on: {lastUpdatedDate}</div>
        </Segment>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderUnavailabilityModal()}

        <div className="calendar-widget" id="calendar" ref="calendar" />

        {this.renderLegend()}
      </div>
    );
  }
}

Calendar.propTypes = {
  holidays: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  fetchHolidays: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    holidays: state.calendar.holidays,
    events: state.calendar.events,
    lastUpdatedDate: state.calendar.lastUpdatedDate,
  };
}

export default connect(mapStateToProps, {
  fetchHolidays,
  fetchUserEvents,
  fetchAdministeredUserEvents,
})(Calendar);
