import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

/**
 * Implement the methods below in components that use this helper componetn (Do not implement any method here).
 * Look at the examples below;
 */

// handleMapLoad(map) {
//   this._mapComponent = map;
//   if (map) {
//     console.error(map.getZoom());
//   }
// }

// /*
//  * This is called when you click on the map.
//  * Go and try click now.
//  */
// handleMapClick(event) {
//   const nextMarkers = [
//     ...this.state.markers,
//     {
//       position: event.latLng,
//       defaultAnimation: 2,
//       key: Date.now(), // Add a key property for: http://fb.me/react-warning-keys
//     },
//   ];
//   this.setState({
//     markers: nextMarkers,
//   });

//   if (nextMarkers.length === 3) {
//     this.props.toast(
//       `Right click on the marker to remove it`,
//       `Also check the code!`
//     );
//   }
// }

// handleMarkerRightClick(targetMarker) {
//   /*
//    * All you modify is data, and the view is driven by data.
//    * This is so called data-driven-development. (And yes, it's now in
//    * web front end and even with google maps API.)
//    */
//   const nextMarkers = this.state.markers.filter(marker => marker !== targetMarker);
//   this.setState({
//     markers: nextMarkers,
//   });
// }

/**
 * Example using this helper component in other components
 *
 *
 * class ExampleComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {

      markers: [{
        position: {
          lat: 25.0112183,
          lng: 121.52067570000001,
        },
        key: `Taiwan`,
        defaultAnimation: 2,
      }],
    };

  }

  render() {
    return (
            <AmsGoogleMap
              containerElement={<div style={{ height: `400px` }} />} // Required.
              mapElement={<div style={{ height: `100%` }} />} // Required.
              markers={this.state.markers} // Required.
              defaultCenter={{ lat: -25.363882, lng: 131.044922 }}
              onMapLoad={this.handleMapLoad}
              onMapClick={this.handleMapClick}
              onMarkerRightClick={this.handleMarkerRightClick} />
    )
  }

}
*
*
**/

export const AmsGoogleMap = withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={props.defaultZoom || 10}
    defaultCenter={props.defaultCenter || { lat: 38.907192, lng: -77.036871 }}
    onClick={props.onMapClick}
  >
    {props.markers.map(marker => (
      <Marker
        {...marker}
        onRightClick={() => props.onMarkerRightClick(marker)}
      />
    ))}
  </GoogleMap>
));
