import { USER_PROFILE_TAB_SELECTED } from '../redux/types';

export default function profileTab(state = '', action) {
  switch (action.type) {
    case USER_PROFILE_TAB_SELECTED: {
      return action.profileTab;
    }

    default:
      return state;
  }
}
