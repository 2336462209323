import React from 'react';
import ArrayFieldTemplate from './ArrayFieldTemplate';
import ArrayAttachmentTemplate from './FileUpload/ArrayAttachmentTemplate';

const EASArrayFieldTemplate = props => {
  if (props.schema.format == 'multiFileUpload') {
    return <ArrayAttachmentTemplate {...props} />;
  }
  return <ArrayFieldTemplate {...props} />;
};

export default EASArrayFieldTemplate;
