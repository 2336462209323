import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import utils.
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';

const ReviewDates = ({ selectedReview }) =>
  selectedReview ? (
    <div>
      {selectedReview.startDate && (
        <div>
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Start Date"
          >
            Start Date:{' '}
          </span>{' '}
          {AmsDateFormatters.formatDate(selectedReview.startDate)}
        </div>
      )}

      {selectedReview.endDate && (
        <div>
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="End Date"
          >
            End Date:{' '}
          </span>{' '}
          {AmsDateFormatters.formatDate(selectedReview.endDate)}
        </div>
      )}
    </div>
  ) : null;

ReviewDates.propTypes = {
  selectedReview: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewDates);
