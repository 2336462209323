import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Well } from 'react-bootstrap';
import _ from 'lodash';
import AmsAlert from '../../utils/AmsAlert';
// eslint-disable-line import/no-extraneous-dependencies
import DatePicker from 'react-datepicker';
import {
  Message,
  Form,
  Input,
  Dimmer,
  Loader,
  Button,
} from 'semantic-ui-react';
import Modal from 'react-modal';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

// Import components.
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import UsersListDropdown from '../../utils/UsersListDropdown';
import GranteeListDropdown from '../../utils/GranteeListDropdown';
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import actions.
import { fetchGranteeNoDetail } from '../../actions/granteeActions';
import { fetchUsers, filterUsers } from '../../actions/userActions';
import {
  addReview,
  clearMessage,
  reviewInfoFetch,
  updateReview,
  fetchUnscheduledReviewers,
} from '../../actions/reviewActions';

class ReviewCreateForm extends Component {
  state = {
    reviewId: '',
    granteeIds: [],
    granteeInfo: [],
    reviewType: null,

    reviewer: [],
    programSpecialist: [],
    followUpLead: [],
    dualCoder: [],
    reviewLead: [],
    logisticsAssociate: [],

    startDate: null,
    endDate: null,
    isSaving: false,
    showAlert: false,
    errors: {},
    edit: false,
    showSearchModal: false,
    filtering: false,
    reviewerSearch: {
      name: '',
      selectedReviewers: [],
      checked: [],
      languageSkills: [],
      contentAreaSkills: [],
      otherSkills: [],
    },
  };

  extractTeam = team => {
    const groupedTeam = _.groupBy(team, 'role');
    let members = {};

    Object.keys(groupedTeam).forEach(key => {
      if (!_.isEmpty(groupedTeam[key][0].members)) {
        members[_.camelCase(key)] = groupedTeam[key][0].members.forEach(
          (member, index) => {
            if (member.name) {
              this.props.filterUsers(member.name, _.camelCase(key));
            }
            return member.oid;
          }
        );
      }
    });
    return members;
  };

  componentWillReceiveProps = nextProps => {
    const {
      reviewInfo: { startDate, endDate, grantInfo, reviewId, reviewType, team },
    } = nextProps;

    if (reviewId && !this.state.reviewId) {
      const members = this.extractTeam(team);

      this.setState({
        reviewId,
        reviewType,
        startDate: AmsDateFormatters.getMoment(startDate),
        endDate: AmsDateFormatters.getMoment(endDate),
        ...members,
        granteeIds:
          grantInfo && grantInfo.length
            ? grantInfo.map(grantee => grantee.granteeId)
            : [],
      });
    }
  };

  componentDidMount() {
    // Edit Mode.
    if (this.props.location.state) {
      if (this.props.location.state.edit) {
        const reviewId = this.context.router.params.id;
        if (reviewId) this.props.reviewInfoFetch(this.context.router.params.id);
      }
    }
  }

  toggleModal = () =>
    this.setState({ showSearchModal: !this.state.showSearchModal, errors: {} });

  closeCreateReviewForm = () => {
    this.setState({
      reviewId: '',
      granteeIds: [],
      granteeInfo: [],
      reviewType: null,
      reviewers: [],
      dualCoders: [],
      programSpecialist: [],
      followUpLead: [],
      rtls: [],
      las: [],
      teamreviewers: [],
      teamdualCoders: [],
      teamprogramSpecialist: [],
      teamfollowUpLead: [],
      teamrtls: [],
      teamlas: [],
      startDate: null,
      endDate: null,
      isSaving: false,
      showAlert: false,
      reviewerSearch: {
        checked: [],
        selectedReviewers: [],
        name: '',
        languageSkills: [],
        contentAreaSkills: [],
        otherSkills: [],
      },
    });
    this.context.router.push('/review');
  };

  validForm() {
    const {
      granteeIds,
      startDate,
      endDate,
      reviewType,
      programSpecialist,
      followUpLead,
      reviewer,
      reviewLead,
      logisticsAssociate,
    } = this.state;

    // dualCoder, reviewLead, logisticsAssociate

    switch (reviewType) {
      case 'CLASS':
      case 'FA-2':
        return true;
      case 'Other':
        if (
          _.isEmpty(granteeIds) ||
          _.isEmpty(startDate) ||
          _.isEmpty(endDate) ||
          _.isEmpty(reviewType) ||
          _.isEmpty(reviewer) ||
          _.isEmpty(reviewLead) ||
          _.isEmpty(logisticsAssociate)
        )
          return false;
        else return true;
      case 'FA-1': {
        if (
          _.isEmpty(granteeIds) ||
          _.isEmpty(startDate) ||
          _.isEmpty(endDate) ||
          _.isEmpty(reviewType) ||
          _.isEmpty(logisticsAssociate) ||
          _.isEmpty(reviewLead)
        ) {
          return false;
        } else {
          return true;
        }
      }
      case 'Follow-up':
        if (
          _.isEmpty(granteeIds) ||
          _.isEmpty(startDate) ||
          _.isEmpty(endDate) ||
          _.isEmpty(reviewType) ||
          _.isEmpty(reviewer) ||
          _.isEmpty(programSpecialist) ||
          _.isEmpty(followUpLead) ||
          _.isEmpty(logisticsAssociate)
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return false;
    }
  }

  renderSearchModal = () => {
    const { unscheduled } = this.props.reviewers;

    return (
      <Modal
        className="modal-container"
        overlayClassName="modal-overlay-container"
        isOpen={this.state.showSearchModal}
        onRequestClose={() => this.toggleModal()}
        contentLabel="Search Unscheduled Reviewers"
      >
        <div className="row" style={{ overflow: 'visible' }}>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-11">
                <h2 className="no-top-margin">Search Unscheduled Reviewers</h2>
              </div>
              <div className="col-sm-1">
                <span onClick={() => this.toggleModal()} className="clickable">
                  <i className="fa fa-close fa-2x" />
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <hr />
          </div>

          <div className="col-sm-12 create-review-error-block">
            {this.showError()}
          </div>
        </div>
        <Well className="">
          <div className="filter-header">
            <h2>Select filters</h2>
          </div>
          <br />
          <br />
          <Form>
            <Form.Input
              label="Name"
              placeholder="Search Name"
              value={this.state.reviewerSearch.name}
              onChange={({ value }) => {
                this.setState({
                  reviewerSearch: { ...this.state.reviewerSearch, name: value },
                });
              }}
              fluid
            />

            <Form.Field
              name="languageSkills"
              label="Language Skills"
              placeholder="Select Language Skills"
              value={this.state.reviewerSearch.languageSkills}
              onChange={this.handleSearchFilterChange}
              category={'languages'}
              control={AmsLookupDropdown}
              fluid
              multiple
              search
              selection
            />

            <Form.Field
              name="contentAreaSkills"
              label="Content Area Skills"
              placeholder="Select Content Area Skills"
              value={this.state.reviewerSearch.contentAreaSkills}
              onChange={this.handleSearchFilterChange}
              category={'contentAreaSkills'}
              control={AmsLookupDropdown}
              fluid
              multiple
              search
              selection
            />

            <Form.Group>
              <Form.Field
                name="otherSkills"
                width={14}
                label="Other Skills"
                placeholder="Select Other Skills"
                value={this.state.reviewerSearch.otherSkills}
                onChange={this.handleSearchFilterChange}
                category={'otherSkills'}
                control={AmsLookupDropdown}
                fluid
                multiple
                search
                selection
              />

              <Form.Group width={2}>
                <Form.Button
                  primary
                  label="&nbsp;"
                  content={'Filter'}
                  onClick={() => {
                    const {
                      startDate,
                      endDate,
                      reviewerSearch: {
                        name,
                        languageSkills,
                        contentAreaSkills,
                        otherSkills,
                      },
                    } = this.state;

                    const request = {
                      startDate: startDate.format(),
                      endDate: endDate.format(),
                    };

                    // Only attach if values are set.
                    if (!_.isEmpty(name)) request.name = name;
                    if (!_.isEmpty(languageSkills))
                      request.languageSkills = languageSkills;
                    if (!_.isEmpty(contentAreaSkills))
                      request.contentAreaSkills = contentAreaSkills;
                    if (!_.isEmpty(otherSkills))
                      request.otherSkills = otherSkills;

                    this.setState({ filtering: true });
                    this.props
                      .fetchUnscheduledReviewers(request)
                      .then(() => this.setState({ filtering: false }))
                      .catch(error => {
                        this.setState({
                          filtering: false,
                          errors: {
                            ...this.state.errors,
                            responseError: error.response.data.message,
                          },
                        });
                      });
                  }}
                />

                <Form.Button
                  label="&nbsp;"
                  basic
                  content="Reset"
                  onClick={() => {
                    this.setState({
                      reviewerSearch: {
                        name: '',
                        selectedReviewers: [],
                        checked: [],
                        languageSkills: [],
                        contentAreaSkills: [],
                        otherSkills: [],
                      },
                    });
                  }}
                />
              </Form.Group>
            </Form.Group>
          </Form>
        </Well>

        {/* Users Table */}
        {this.renderReviewersList()}

        {unscheduled && unscheduled.length > 0 && (
          <Button
            primary
            onClick={() => {
              const list = this.state.reviewerSearch.selectedReviewers.map(
                reviewer => ({
                  key: reviewer.id,
                  value: reviewer.oid,
                  text: reviewer.name,
                })
              );
              const checked = list.map(reviewer => reviewer.value);

              this.setState(
                {
                  ...this.state,
                  reviewerSearch: {
                    ...this.state.reviewerSearch,
                    selectedReviewers: list,
                  },
                },
                () =>
                  this.setState({
                    ...this.state,
                    reviewerSearch: { ...this.state.reviewerSearch, checked },
                  })
              );

              this.toggleModal();
            }}
          >
            Add
          </Button>
        )}
      </Modal>
    );
  };

  handleSearchFilterChange = (e, { name, value }) =>
    this.setState({
      reviewerSearch: { ...this.state.reviewerSearch, [name]: value },
    });

  // Handle single select
  handleReviewerSelect(row, isSelected, e) {
    const { selectedReviewers } = this.state.reviewerSearch;
    let selections = [];

    if (isSelected) {
      selections = [...selectedReviewers, row];
    }
    if (!isSelected) {
      selections = _.pull(selectedReviewers, row);
    }

    this.setState({
      ...this.state,
      reviewerSearch: {
        ...this.state.reviewerSearch,
        selectedReviewers: selections,
      },
    });
  }

  // Handle multi select
  handleReviewerSelectAll(isSelected, rows) {
    const { selectedReviewers } = this.state.reviewerSearch;
    let selections = [];

    if (isSelected) {
      selections = rows;
    }
    if (!isSelected) {
      selections = _.pull(selectedReviewers, ...rows);
    }

    this.setState({
      ...this.state,
      reviewerSearch: {
        ...this.state.reviewerSearch,
        selectedReviewers: selections,
      },
    });
  }

  handleReviewersChange = (e, { name, value }) => {
    this.setState({
      ...this.state,
      reviewer: value,
    });
  };

  renderReviewersList = () => {
    const { unscheduled } = this.props.reviewers;

    const options = {};

    const selectRow = {
      mode: 'checkbox', // multi select,
      selected: this.state.reviewerSearch.selectedReviewers.map(
        item => item.id
      ),
      columnWidth: '50px',
      onSelect: this.handleReviewerSelect.bind(this),
      onSelectAll: this.handleReviewerSelectAll.bind(this),
    };

    const formatPhoto = (cell, row) => {
      if (row && row.name)
        return (
          <span className="circle-avatar">
            {' '}
            {`${row.name.split(' ')[0].charAt(0) || 'F'}${row.name
              .split(' ')[1]
              .charAt(0) || 'L'}`}
          </span>
        );
    };

    return (
      <div>
        {this.state.filtering && (
          <Dimmer active inverted>
            <Loader inverted>Loading Reviewers...</Loader>
          </Dimmer>
        )}

        <BootstrapTable
          ref="table"
          data={unscheduled}
          headerContainerClass="table-header"
          hover
          condensed
          bordered={false}
          options={options}
          selectRow={selectRow}
          pagination={unscheduled && unscheduled.length > 10}
        >
          <TableHeaderColumn dataField="id" isKey={true} hidden>
            {' '}
            ID
          </TableHeaderColumn>
          <TableHeaderColumn width="20%" dataFormat={formatPhoto} />
          <TableHeaderColumn dataField="name" dataSort={true}>
            Name
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  };

  renderCreateNewForm = () => {
    const {
      reviewId,
      granteeIds,
      startDate,
      endDate,
      reviewType,
      isSaving,
      followUpLead,
      programSpecialist,
      edit,
      dualCoder,
      reviewLead,
      logisticsAssociate,
      reviewer,
    } = this.state;

    return (
      <div className="row" style={{ overflow: 'visible' }}>
        <div className="col-sm-12">
          <Form>
            <Form.Field
              disabled={edit ? true : false}
              width={6}
              control={AmsLookupDropdown}
              value={reviewType}
              category={'reviewTypes'}
              onChange={(e, { value }) =>
                this.setState({
                  reviewType: value,
                })
              }
              selection
              fluid
              search
              label="Review Type"
              placeholder="Please select Review Type"
            />
            {reviewType && (
              <div>
                <Form.Group widths="equal">
                  <Form.Field
                    disabled={
                      edit ||
                      reviewType === 'Follow-up' ||
                      reviewType === 'Other'
                        ? true
                        : false
                    }
                    control={Input}
                    name="reviewId"
                    label={'Review ID'}
                    onChange={this.handleInputValueChange}
                    value={reviewId}
                  />
                  <Form.Field
                    disabled={edit ? true : false}
                    control={GranteeListDropdown}
                    dropdownLabel="Grantee"
                    placeholder="Type Grantee Name or ID to filter"
                    value={granteeIds}
                    selection
                    multiple
                    search
                    onValueSelected={value =>
                      this.setState({
                        granteeIds: value,
                      })
                    }
                    onError={this.setError}
                    // clearable
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    name="startDate"
                    label={'Start Date'}
                    control={DatePicker}
                    placeholder="Please select start Date"
                    // isClearable={true}
                    selected={startDate && startDate}
                    selectsStart
                    minDate={AmsDateFormatters.getMoment()}
                    startDate={startDate && startDate}
                    onChange={this.handleStartDateChange}
                  />
                  <Form.Field
                    name="endDate"
                    label={'End Date'}
                    control={DatePicker}
                    placeholder="Please select end date"
                    // isClearable={true}
                    selected={endDate && endDate}
                    selectsEnd
                    startDate={startDate && startDate}
                    minDate={startDate && startDate}
                    endDate={endDate}
                    onChange={this.handleEndDateChange}
                  />
                </Form.Group>

                <Form.Group>
                  {reviewType !== 'Follow-up' && (
                    <Form.Field
                      name="reviewLead"
                      width={8}
                      control={UsersListDropdown}
                      userType="reviewLead"
                      value={reviewLead}
                      onValueSelected={this.handleDropdownChange}
                      dropdownLabel="Review Team Lead"
                      placeholder="Select Review Team Leads"
                      multiple
                      selection
                      search
                      onError={this.showError}
                    />
                  )}

                  <Form.Field
                    name="logisticsAssociate"
                    width={8}
                    control={UsersListDropdown}
                    userType="logisticsAssociate"
                    value={logisticsAssociate}
                    onValueSelected={this.handleDropdownChange}
                    dropdownLabel="Logistics Associate"
                    placeholder="Select Logistics Associate"
                    multiple
                    selection
                    search
                    onError={this.setError}
                  />
                </Form.Group>

                <Form.Group>
                  {reviewType !== 'FA-1' && (
                    <Form.Dropdown
                      width={7}
                      label="Reviewers"
                      name="reviewer"
                      placeholder="Select Reviewers"
                      disabled={!(this.state.startDate && this.state.endDate)}
                      value={reviewer}
                      options={this.state.reviewerSearch.selectedReviewers}
                      onChange={this.handleReviewersChange}
                      multiple
                      selection
                      search
                    />
                  )}

                  {reviewType !== 'FA-1' && (
                    <Form.Button
                      primary
                      size="small"
                      label="&nbsp;"
                      disabled={!(this.state.startDate && this.state.endDate)}
                      onClick={() => this.toggleModal()}
                    >
                      Search
                    </Form.Button>
                  )}

                  {reviewType === 'CLASS' && (
                    <Form.Field
                      name="dualCoder"
                      width={8}
                      control={UsersListDropdown}
                      userType="dualCoder"
                      value={dualCoder}
                      onValueSelected={this.handleDropdownChange}
                      dropdownLabel="Dual Coders"
                      placeholder="Select Dual Coders"
                      multiple
                      selection
                      search
                      onError={this.setError}
                    />
                  )}
                </Form.Group>
                <Form.Group>
                  {reviewType === 'Follow-up' && (
                    <Form.Field
                      name="programSpecialist"
                      width={8}
                      control={UsersListDropdown}
                      userType="programSpecialist"
                      value={programSpecialist}
                      onValueSelected={this.handleDropdownChange}
                      dropdownLabel="Program Specialist"
                      placeholder="Select Program Specialist"
                      multiple
                      selection
                      search
                      onError={this.setError}
                    />
                  )}
                  {reviewType === 'Follow-up' && (
                    <Form.Field
                      name="followUpLead"
                      width={8}
                      control={UsersListDropdown}
                      userType="followUpLead"
                      value={followUpLead}
                      onValueSelected={this.handleDropdownChange}
                      dropdownLabel="Follow-up Lead"
                      placeholder="Select Follow-up Lead"
                      multiple
                      selection
                      search
                      onError={this.setError}
                    />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Checkbox
                    name="teamComplete"
                    label="Team planning is complete"
                    onChange={this.handleInputValueChange}
                  />
                </Form.Group>
              </div>
            )}
          </Form>
          <br />
          <br />
        </div>
        <div className="col-xs-12">
          {!edit && (
            <div className="col-sm-2">
              <Button
                disabled={!this.validForm() || isSaving}
                onClick={this.handleReviewSave}
                primary
              >
                {isSaving ? 'Creating ...' : 'Create Review'}{' '}
              </Button>
            </div>
          )}
          {edit && (
            <div className="col-sm-2">
              <Button
                disabled={!this.validForm() || isSaving}
                onClick={this.handleReviewEdit}
                primary
              >
                {isSaving ? 'Editing ...' : 'Edit Review'}{' '}
              </Button>
            </div>
          )}
          <div className="col-sm-2">
            <Button
              disabled={isSaving}
              onClick={this.closeCreateReviewForm}
              negative
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  };

  handleReviewSave = () => {
    const {
      rtls,
      reviewerSearch: { checked },
      dualCoders,
      programSpecialist,
      followUpLead,
      las,
      reviewId,
      granteeIds,
      reviewType,
      startDate,
      endDate,
    } = this.state;

    let team = [
      {
        role: 'reviewLead',
        members: rtls,
      },
      {
        role: 'reviewer',
        members: checked,
      },
      {
        role: 'dualCoder',
        members: dualCoders,
      },
      {
        role: 'programSpecialist',
        members: programSpecialist,
      },
      {
        role: 'followupLead',
        members: followUpLead,
      },
      {
        role: 'logisticsAssociate',
        members: las,
      },
    ];

    let reviewObject = {
      // updateExistingReview: false,
      granteeIds: granteeIds || [],
      reviewType: reviewType || '',
      team: team,
      startDate: startDate.startOf('day').format(),
      endDate: endDate.endOf('day').format(),
    };

    if (reviewId) reviewObject.reviewId = reviewId;

    // this.setState({ isSaving: true, errors: {} }, (() => {
    //   this.props.addReview(reviewObject)
    //     .then(() => this.setState({ showAlert: true }))
    //     .catch((error) => {
    //       this.setState({ isSaving: false, errors: { ...this.state.errors, responseError: error.response.data.message } })
    //     })
    // }))
  };

  handleReviewEdit = () => {
    const {
      reviewId,
      teamreviewers,
      teamrtls,
      startDate,
      endDate,
      teamdualCoders,
      teamprogramSpecialist,
      teamfollowUpLead,
      teamlas,
    } = this.state;

    let team = [
      {
        role: 'ReviewLead',
        members: teamrtls,
      },
      {
        role: 'Reviewer',
        members: teamreviewers,
      },
      {
        role: 'DualCoder',
        members: teamdualCoders,
      },
      {
        role: 'ProgramSpecialist',
        members: teamprogramSpecialist,
      },
      {
        role: 'FollowupLead',
        members: teamfollowUpLead,
      },
      {
        role: 'LogisticsAssociate',
        members: teamlas,
      },
    ];

    let reviewObject = {
      // updateExistingReview: false,
      reviewId: reviewId,
      // granteeIds: granteeIds || [],
      // reviewType: reviewType || "",
      team: team,
      startDate: startDate.format(),
      endDate: endDate.endOf('day').format(),
    };

    if (reviewId) reviewObject.reviewId = reviewId;

    // this.setState({ isSaving: true, errors: {} }, (() => {
    //   this.props.updateReview(reviewObject)
    //     .then(() => this.setState({ showAlert: true }))
    //     .catch((error) => {
    //       this.setState({ isSaving: false, errors: { ...this.state.errors, responseError: error.response.data.message } })
    //     })
    // }))
  };

  handleInputValueChange = (e, { name, value, checked }) => {
    if (name === 'teamComplete') {
      this.setState({
        ...this.state,
        [name]: checked,
      });
      return;
    }
    this.setState({ ...this.state, [name]: value });
  };

  handleStartDateChange = date => {
    if (!date) {
      this.setState({ startDate: null, endDate: null });
      return;
    }

    this.setState({ startDate: date });
  };

  handleEndDateChange = date => {
    if (!date) {
      this.setState({ endDate: null });
      return;
    }

    this.setState({ endDate: date });
  };

  handleGranteeChange = (value, options) => {
    let info = value.map(granteeId => {
      return {
        granteeId: granteeId,
        granteeName: _.find(options, option => {
          return option.value === granteeId;
        }).text,
      };
    });
    this.setState({ granteeInfo: info });
  };

  handleTypeChange = value => {
    this.setState({ reviewType: value });
  };

  handleDropdownChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  showConfirmation = () => {
    const { message } = this.props;

    return (
      <AmsAlert
        show={this.state.showAlert || !_.isEmpty(message)}
        title="Review created"
        type="success"
        showConfirm
        text={`Review with ID: ${message.reviewId} created successfully.`}
        onConfirm={() => {
          this.setState({
            reviewId: '',
            granteeId: '',
            reviewType: '',
            reviewers: [],
            rtls: '',
            startDate: null,
            endDate: null,
            isSaving: false,
            showAlert: false,
            errors: {},
          });
          this.props.clearMessage();
        }}
      />
    );
  };

  setError = error => {
    if (error && error.response && error.response.data)
      this.setState({
        errors: { ...this.state.errors, responseError: error.response.data },
      });
  };

  showError = () => {
    const { errors } = this.state;

    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(errorKey => (
          <li key={errorKey}>{errors[errorKey]}</li>
        ))}
      />
    );
  };

  render() {
    const { edit } = this.state;
    return (
      <div className="create-review-page">
        {this.showConfirmation()}
        {this.showError()}
        <SlidingContainer
          calendar
          title={edit ? 'Edit Review' : 'Create Review'}
        >
          {this.renderCreateNewForm()}
          {this.renderSearchModal()}
        </SlidingContainer>
      </div>
    );
  }
}

ReviewCreateForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  grantees: PropTypes.array.isRequired,
  lookups: PropTypes.object.isRequired,
  reviewers: PropTypes.object.isRequired,
  fetchGranteeNoDetail: PropTypes.func.isRequired,
  addReview: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
  fetchUnscheduledReviewers: PropTypes.func.isRequired,
  reviewInfo: PropTypes.object.isRequired,
};

ReviewCreateForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    grantees: state.granteeOptions,
    users: state.users.users,
    lookups: state.lookups,
    message: state.message,
    reviewers: state.reviewers,
    reviewInfo: state.review.reviewInfo,
  };
}

export default connect(mapStateToProps, {
  filterUsers,
  fetchGranteeNoDetail,
  fetchUsers,
  addReview,
  clearMessage,
  reviewInfoFetch,
  updateReview,
  fetchUnscheduledReviewers,
})(ReviewCreateForm);
