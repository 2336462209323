import jwtDecode from 'jwt-decode';
import axios from 'axios';

import {
  SET_CURRENT_USER,
  ADD_ERROR,
  GET_CURRENT_USER_AGREEMENTS,
} from '../redux/types';
import { config } from '../config';

export function setAuthError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function getAgreements(agreements) {
  return {
    type: GET_CURRENT_USER_AGREEMENTS,
    agreements,
  };
}

export function removeToken() {
  return dispatch => {
    localStorage.removeItem('token');
    dispatch(setCurrentUser({}));
  };
}

export function addToken(token) {
  return dispatch => {
    if (token) {
      localStorage.setItem('token', token);
      const user = jwtDecode(token);
      dispatch(setCurrentUser(user));
      dispatch(fetchAgreements());
    }
  };
}

export function login() {
  return dispatch => {
    if (process.env.NODE_ENV === 'development') {
      window.location.href = `http://localhost:${config.API_PORT}/auth/login`;
    } else {
      window.location.href = '/auth/login';
    }
  };
}

export function logout() {
  return dispatch => {
    localStorage.removeItem('token');

    if (typeof window !== 'undefined') {
      if (process.env.NODE_ENV === 'development') {
        window.location.href = `http://localhost:${config.API_PORT}/auth/logout`;
      } else {
        window.location.href = '/auth/logout';
      }
    }
  };
}

export const fetchAgreements = () => dispatch =>
  axios
    .get(`/agreements/agreements`)
    .then(response => dispatch(getAgreements(response.data)));
