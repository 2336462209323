import React, { useEffect, useState } from 'react';
import { Segment, Tab, Grid, Message } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, find } from 'lodash';

// Import availability views
import GranteeAvailabiltiyInstructionTab from './GranteeAvailabilityInstructionTab';
import GranteeAvailabiltiyCalendarForm from './GranteeAvailabilityCalendarForm';

// Import actions
import {
  saveGranteeUnavailability,
  fetchGranteeHash,
} from '../../actions/granteeActions';

// Import utilities
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

const GranteeAvailabilityPage = ({
  selectedGrantee,
  showNextProgramYear,
  fiscalYear,
  previousFiscalYear,
  granteeChanging,
  lastEditedGrantee,
  savePreviouseGrantee,
  resetGranteeChanging,
  saveMultiGrantDetails,
  resetMultiGranteeDetails,
  nextProgramYearSelected,
}) => {
  const { granteeDetails } = useSelector(state => state);
  const [programYearPanes, setProgramYearPanes] = useState([]);
  const [tabEdited, setTabEdited] = useState(false);
  const [programYearIndex, setProgramYearIndex] = useState(0);
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const dispatch = useDispatch();
  const [isNextProgramYearSelected, setIsNextProgramYearSelected] = useState(
    false
  );
  useEffect(() => {
    const buildProgramPanes = () => {
      let panes = showNextProgramYear
        ? [
            {
              menuItem: 'Current Program Year',
              render: () => (
                <Tab.Pane>
                  {!isEmpty(granteeDetails[previousFiscalYear].grantInfoList) &&
                  find(
                    granteeDetails[previousFiscalYear].grantInfoList,
                    o => o.granteeId === selectedGrantee.granteeId
                  ) ? (
                    <GranteeAvailabiltiyCalendarForm
                      selectedGrantee={selectedGrantee}
                      fiscalYear={previousFiscalYear}
                      granteeChanging={granteeChanging}
                      savePreviouseGrantee={data => savePreviouseGrantee(data)}
                      lastEditedGrantee={lastEditedGrantee}
                      resetGranteeChanging={resetGranteeChanging}
                      changeTabEdited={changeTabEdited}
                      saveMultiGrantDetails={saveMultiGrantDetails}
                      resetMultiGranteeDetails={resetMultiGranteeDetails}
                    />
                  ) : (
                    <>
                      There were no invitations sent to the grantee for the
                      current program year
                    </>
                  )}
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Next Program Year',
              render: () => (
                <Tab.Pane>
                  <GranteeAvailabiltiyCalendarForm
                    selectedGrantee={selectedGrantee}
                    fiscalYear={fiscalYear}
                    granteeChanging={granteeChanging}
                    savePreviouseGrantee={data => savePreviouseGrantee(data)}
                    lastEditedGrantee={lastEditedGrantee}
                    resetGranteeChanging={resetGranteeChanging}
                    changeTabEdited={changeTabEdited}
                    saveMultiGrantDetails={saveMultiGrantDetails}
                    resetMultiGranteeDetails={resetMultiGranteeDetails}
                    nextProgramYearSelected={true}
                  />
                  {/* <GranteeViewNextYearTab /> */}
                </Tab.Pane>
              ),
            },
          ]
        : [
            {
              menuItem: 'Current Program Year',
              render: () => (
                <Tab.Pane>
                  <GranteeAvailabiltiyCalendarForm
                    selectedGrantee={selectedGrantee}
                    fiscalYear={fiscalYear}
                    granteeChanging={granteeChanging}
                    savePreviouseGrantee={data => savePreviouseGrantee(data)}
                    lastEditedGrantee={lastEditedGrantee}
                    resetGranteeChanging={resetGranteeChanging}
                    changeTabEdited={changeTabEdited}
                    saveMultiGrantDetails={saveMultiGrantDetails}
                    resetMultiGranteeDetails={resetMultiGranteeDetails}
                  />
                </Tab.Pane>
              ),
            },
          ];
      setProgramYearPanes(panes);
    };
    buildProgramPanes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fiscalYear,
    granteeChanging,
    granteeDetails,
    previousFiscalYear,
    lastEditedGrantee,
    selectedGrantee,
    showNextProgramYear,
  ]);
  const changeTabEdited = value => {
    setTabEdited(value);
  };

  const saveWhenMoving = payload => {
    let data = [];
    data.push({ ...payload });
    if (!isEmpty(payload))
      dispatch(
        saveGranteeUnavailability(
          { grantees: data, invitationId: granteeDetails.invitationId },
          response => {
            if (response.status === 200) {
              let hash = window.location.href.split('hash=')[1];
              setDialogContent({
                type: 'success',
                showCancelButton: false,
                showConfirm: true,
                confirmButtonText: 'Ok',
                title: 'Progress Saved',
                text: response.data.success,
                onConfirm: () => {
                  setShowDialogModal(false);
                  setDialogContent({});
                },
              });
              setShowDialogModal(true);
              dispatch(fetchGranteeHash({ hash }));
            } else {
              setDialogContent({
                type: 'error',
                showCancelButton: false,
                showConfirm: true,
                confirmButtonText: 'Ok',
                title: 'Something went wrong',
                text: response.response.data.message,
                onConfirm: () => {
                  setShowDialogModal(false);
                  setDialogContent({});
                },
              });
              setShowDialogModal(true);
            }
          }
        )
      );
  };

  const showTabChangeModal = () => {
    return (
      <AmsAlert
        show={showDialogModal}
        {...dialogContent}
        confirmButtonColor={'#DD6B55'}
      />
    );
  };
  const onTabChangeOfSideMenu = (e, { activeIndex }) => {
    if (activeIndex === 1 && isNextProgramYearSelected) {
      nextProgramYearSelected(true);
    } else {
      nextProgramYearSelected(false);
    }
  };
  const onTabChange = (e, { activeIndex }) => {
    if (activeIndex === 1) {
      nextProgramYearSelected(true);
      setIsNextProgramYearSelected(true);
    } else {
      nextProgramYearSelected(false);
      setIsNextProgramYearSelected(false);
    }

    if (activeIndex !== programYearIndex) {
      if (tabEdited) {
        setDialogContent({
          type: 'warning',
          showCancelButton: true,
          showConfirm: true,
          confirmButtonText: 'Save and continue',
          cancelButtonText: 'Continue without saving',
          title: 'Continue to selected grantee',
          text:
            'You have selected another program year. The current changes you have made have not been saved. Do you want to continue to your selected program year?',
          onConfirm: () => {
            let data = lastEditedGrantee;
            saveWhenMoving(data);
            setTabEdited(false);
            setDialogContent({});
            setShowDialogModal(false);
            resetGranteeChanging(false);
          },
          onCancel: () => {
            setTabEdited(false);
            setDialogContent({});
            setShowDialogModal(false);
            savePreviouseGrantee({});
            resetGranteeChanging(false);
            if (granteeDetails.isMultiGrant) resetMultiGranteeDetails();
          },
        });
        setShowDialogModal(true);
      }
      setProgramYearIndex(activeIndex);
    }
  };

  return (
    <div>
      {showTabChangeModal()}
      <Grid>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment basic>
              <Tab
                onTabChange={onTabChangeOfSideMenu}
                menu={{ color: 'blue', fluid: true, vertical: true }}
                panes={[
                  {
                    menuItem: 'Instructions',
                    render: () => (
                      <Tab.Pane>
                        <GranteeAvailabiltiyInstructionTab />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Availability Calendar',
                    render: () => (
                      <Tab.Pane>
                        {granteeDetails[fiscalYear].grantInfoList[0]
                          .finalUpdate && (
                          <Message
                            info
                            icon="info"
                            header="Your unavailability has been finalized!"
                            content="You may view your unavailability but you cannot make any changes."
                          />
                        )}
                        {!granteeDetails[fiscalYear].grantInfoList[0]
                          .finalUpdate &&
                          AmsDateFormatters.getMoment(
                            granteeDetails.linkExpiration
                          ).endOf('day') <= AmsDateFormatters.getMoment() && (
                            <Message
                              info
                              icon="info"
                              header="Grantee Unavailability Expired."
                              content="The time to submit grantee unavailability has expired and you cannot make any changes. You may view your unavailability but you cannot make any changes."
                            />
                          )}
                        <Tab
                          activeIndex={programYearIndex}
                          panes={programYearPanes}
                          onTabChange={onTabChange}
                        />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default GranteeAvailabilityPage;
