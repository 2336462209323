import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserProfile from '../User/UserProfile';
import SlidingContainer from '../../utils/layout/SlidingContainer';

//actions
import {
  fetchUserProfile,
  updateUserProfile,
} from '../../actions/profileActions';

// Style
import './assets/style.css';

class ProfilePage extends Component {
  showProfile() {
    const { name, unique_name } = this.props.currentUser;

    return (
      <div>
        {name && <p>Name: {name}</p>}
        {unique_name && <div>Email: {unique_name}</div>}
        {/*{jobTitle && <div>Job Title: {jobTitle}</div>}*/}
      </div>
    );
  }

  showRoles() {
    const { roles } = this.props.currentUser;
    if (!roles) return;

    return roles.map((role, index) => {
      return <li key={index}>{role}</li>;
    });
  }

  showprofileImgView() {
    const { fullName, roles } = this.props.currentUser;
    return (
      <div className="profileImgView">
        <span className="img-circle avatar">
          {fullName.split(' ')[0].charAt(0) + fullName.split(' ')[1].charAt(0)}
        </span>
        <div className="profileName">
          <p>{fullName}</p>
          <p>{roles}</p>
        </div>
      </div>
    );
  }

  render() {
    const { oid } = this.props.currentUser;

    return (
      <div className="profile-page">
        {this.showprofileImgView()}
        <SlidingContainer>
          <UserProfile oid={oid} />
        </SlidingContainer>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
  };
}

export default connect(
  mapStateToProps,
  { fetchUserProfile, updateUserProfile }
)(ProfilePage);
