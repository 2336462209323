import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Header,
  Modal,
  Grid,
  Menu,
  Segment,
  Label,
  List,
} from 'semantic-ui-react';
import AllUserListPreloadDropdown from '../../../utils/AllUserListPreloadDropdown';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsModal from '../../../utils/AmsModal';
import UserListPreloadDropdown from '../../../utils/UserListPreloadDropdown';
import { Link } from 'react-router';
import { intersection, isEmpty, find, sortBy, filter } from 'lodash';
import {
  addPosition,
  removePrimaryUser,
  updateTeam,
  updateTeamPositions,
} from '../../../actions/reviewActions';
import AmsAlert from '../../../utils/AmsAlert';
import ReviewerPositionForm from './ReviewerPositionForm';

const AddTeamModal = ({
  selectedTeamRole,
  showAddTeamModal,
  showOnlyDropdown,
  teamRoles,
  handleTeamRoleSelection,
  hasMultiple,
  updatePositionPrimaryLead,
  closeAddTeamModal,
  editMode,
  updateAlertModal,
  updateShowOnlyDropdown,
  dataHasChangedSwitch,
}) => {
  const [errors, setErrors] = useState({});
  const {
    startDate,
    endDate,
    pendingShadowReviewers,
    positions,
    teamPositions,
    reviewId,
    grantInfo,
  } = useSelector(state => state.review.reviewInfo);
  const { reviewInfo } = useSelector(state => state.review);
  const { usersFilter } = useSelector(state => state);
  const [updatedTeams, setUpdatedTeams] = useState([]);
  const [updatedTeamPositions, setUpdatedTeamPositions] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [
    showReviewerPositionAddModal,
    setShowReviewerPositionAddModal,
  ] = useState(false);
  const [currentReviewerPositions, setCurrentReviewerPositions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (positions && positions.length > 0)
      setCurrentReviewerPositions(positions);
  }, [positions]);

  const handleBuildRoles = (oids, userType) => {
    const newTeam =
      oids &&
      oids
        .map(oid => {
          let t = {};

          // Find the user by OID
          if (
            usersFilter &&
            usersFilter[
              userType === 'pendingShadowReviewer' ? 'shadowReviewer' : userType
            ]
          ) {
            t = find(
              usersFilter[
                userType === 'pendingShadowReviewer'
                  ? 'shadowReviewer'
                  : userType
              ],
              { oid }
            );
          } else if (isEmpty(t) && editMode) {
            t = find(reviewInfo[`${userType}s`], { oid });
          } else if (isEmpty(t)) {
            t = find(pendingShadowReviewers, { oid });
          }
          if (isEmpty(t) && userType === 'fieldOperationsManager') {
            t = find(usersFilter['regionalProgramManager'], { oid });
          }

          // Check if the user is active before adding to the team
          if (!t || !(t.isActive === true || t.active === true)) {
            console.log(
              `User with OID ${oid} is not active or does not exist.`
            );
            return null; // Skip adding this user
          }

          // TODO: Fix this issue of fullName and name difference in the payload
          let name = '';
          if (t && t.name) name = t.name;
          if (t && t.fullName) name = t.fullName;
          let teamMember =
            reviewInfo[selectedTeamRole.value] &&
            reviewInfo[selectedTeamRole.value].filter(
              member => member.oid === t.oid
            );
          let previousInfo = {};
          if (teamMember && teamMember.length === 1)
            previousInfo = teamMember[0];

          return {
            ...previousInfo,
            oid,
            firstName: t.firstName,
            lastName: t.lastName,
            name,
            userName: t.userName,
            status:
              userType === 'pendingShadowReviewer' ? 'invited' : 'assigned',
            key: userType,
          };
        })
        .filter(user => user != null); // Remove null entries for inactive or non-existent users

    // Update the state with the new team, excluding inactive users
    let newTeams = [...updatedTeams];
    if (isEmpty(updatedTeams.find(team => team.key === userType))) {
      newTeams.push({ ...selectedTeamRole, team: newTeam });
    } else {
      newTeams = newTeams.map(team => {
        if (team.key === userType) team.team = newTeam;
        return team;
      });
    }
    setDataChanged(true);
    setUpdatedTeams(newTeams);
  };

  const enableTeamPosition = () => {
    setDataChanged(true);
    let newTeamPositions = [...updatedTeamPositions];
    let newUpdatedTeams = [...updatedTeams];
    if (
      newTeamPositions.find(
        position => position.type === selectedTeamRole.value
      )
    ) {
      newTeamPositions = newTeamPositions.map(position => {
        const newPosition = { ...position };
        if (newPosition.type === selectedTeamRole.value)
          newPosition.active = true;
        return newPosition;
      });
    } else {
      newTeamPositions.push({
        type: selectedTeamRole.value,
        languageSkills: [],
        otherSkills: [],
        contentAreaSkills: [],
        active: true,
      });
      newUpdatedTeams.push({ ...selectedTeamRole, team: [] });
    }
    setUpdatedTeamPositions(newTeamPositions);
    setUpdatedTeams(newUpdatedTeams);
  };

  const disableTeamPosition = () => {
    setDataChanged(true);
    let newTeamPositions = [...updatedTeamPositions];
    newTeamPositions = newTeamPositions.map(position => {
      const newPosition = { ...position };
      if (newPosition.type === selectedTeamRole.value)
        newPosition.active = false;
      return newPosition;
    });
    // TODO: Enable code to track history of users
    let newTeams = [...updatedTeams];
    newTeams = newTeams.map(team => {
      if (team.key === selectedTeamRole.key) team.team = [];
      return team;
    });
    setUpdatedTeams(newTeams);
    setUpdatedTeamPositions(newTeamPositions);
  };

  const showCloseFormAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        type={'warning'}
        showConfirm
        showCancelButton
        title={'Are you sure you want to close the form?'}
        text={
          'There have been changes made to the teams, are you sure you want to close this form without saving the changes?'
        }
        confirmButtonText="Yes, close"
        cancelButtonText="No"
        onConfirm={() => {
          setShowAlert(false);
          closeAddTeamModal();
          updateShowOnlyDropdown(false);
          setUpdatedTeams([]);
          setUpdatedTeamPositions([]);
          setCurrentReviewerPositions(positions);
          setDataChanged(false);
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const addTeamModal = () => (
    <AmsModal
      size="large"
      centered={false}
      closeOnDimmerClick={false}
      onMount={() => {
        if (updatedTeams.length === 0) {
          const existingTeams = sortBy(teamRoles, ['value'])
            // eslint-disable-next-line array-callback-return
            .map(role => {
              if (
                (reviewInfo[role.value] && reviewInfo[role.value].length > 0) ||
                teamPositions.find(p => p.type === role.value)
              ) {
                role = { ...role, team: reviewInfo[role.value] };
                return role;
              }
            })
            .filter(team => team !== undefined);
          setUpdatedTeams(existingTeams);
        } else if (updatedTeamPositions.length === 0) {
          if (teamPositions.length > 0) {
            setUpdatedTeamPositions(teamPositions);
          } else {
            let tp = updatedTeams.map(team => {
              return {
                type: team.value,
                languageSkills: [],
                otherSkills: [],
                contentAreaSkills: [],
                active: true,
              };
            });
            setUpdatedTeamPositions(tp);
          }
        }
      }}
      open={showAddTeamModal}
      className="ams-semantic-modal-fix"
    >
      <Header
        content={
          showOnlyDropdown
            ? 'Update Team'
            : `Add a new team - ${reviewId}/${grantInfo &&
                grantInfo[0] &&
                grantInfo[0].granteeName} (${grantInfo &&
                grantInfo[0] &&
                grantInfo[0].granteeId})`
        }
      />
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Grid>
              {!showOnlyDropdown && (
                <Grid.Column width={4}>
                  <Menu fluid vertical defaultActiveIndex={0}>
                    {teamRoles.map((item, index) => (
                      <Menu.Item
                        key={index}
                        to="#"
                        link
                        as={Link}
                        name={item.text}
                        // content=
                        active={selectedTeamRole.value === item.value}
                        onClick={e => {
                          e.preventDefault();
                          handleTeamRoleSelection(e, item);
                        }}
                      >
                        {updatedTeams.filter(t => t.value === item.value)
                          .length > 0 &&
                          updatedTeams.filter(t => t.value === item.value)[0]
                            .team &&
                          !isEmpty(
                            updatedTeams.filter(t => t.value === item.value)[0]
                              .team[0]
                          ) &&
                          updatedTeamPositions.find(
                            t => t.type === item.value
                          ) &&
                          updatedTeamPositions.find(t => t.type === item.value)
                            .active && (
                            <Label color="blue">
                              {
                                updatedTeams.filter(
                                  t => t.value === item.value
                                )[0].team.length
                              }
                            </Label>
                          )}
                        {item.text}
                      </Menu.Item>
                    ))}
                  </Menu>
                </Grid.Column>
              )}
              <Grid.Column width={showOnlyDropdown ? 16 : 12} stretched>
                {!isEmpty(selectedTeamRole) &&
                ((updatedTeamPositions.find(
                  teamPosition => teamPosition.type === selectedTeamRole.value
                ) &&
                  !updatedTeamPositions.find(
                    teamPosition => teamPosition.type === selectedTeamRole.value
                  ).active) ||
                  (isEmpty(
                    updatedTeamPositions.find(
                      teamPosition =>
                        teamPosition.type === selectedTeamRole.value
                    )
                  ) &&
                    selectedTeamRole.value !== 'reviewers')) ? (
                  <span>
                    This team position is currently disabled. Please enable it
                    to continue to add new members to the team
                  </span>
                ) : (
                  <Form.Group widths="equal">
                    {selectedTeamRole.value &&
                      selectedTeamRole.value !== 'reviewers' &&
                      selectedTeamRole.value !== 'shadowReviewers' &&
                      selectedTeamRole.value !== 'pendingShadowReviewers' && (
                        <Form.Field>
                          <AmsFormLabel
                            name={selectedTeamRole.text}
                            fieldLabel={selectedTeamRole.ariaLabelledBy}
                          >
                            <UserListPreloadDropdown
                              name={selectedTeamRole.value}
                              userType={selectedTeamRole.key}
                              multipleUserTypes={
                                hasMultiple &&
                                selectedTeamRole.value ===
                                  'fieldOperationsManagers'
                                  ? [
                                      'fieldOperationsManager',
                                      'regionalProgramManager',
                                    ]
                                  : false
                              }
                              value={
                                updatedTeams &&
                                updatedTeams.find(
                                  team => team.key === selectedTeamRole.key
                                ) &&
                                !isEmpty(
                                  updatedTeams.find(
                                    team => team.key === selectedTeamRole.key
                                  ).team[0]
                                )
                                  ? updatedTeams
                                      .find(
                                        team =>
                                          team.key === selectedTeamRole.key
                                      )
                                      .team.map(member => member.oid)
                                  : []
                              }
                              onChange={(e, { value }) => {
                                handleBuildRoles(value, selectedTeamRole.key);
                              }}
                              aria-labelledby={selectedTeamRole.ariaLabelledBy}
                              searchInput={{
                                id: selectedTeamRole.ariaLabelledBy,
                              }}
                              placeholder={`Select ${selectedTeamRole.text}`}
                              multiple
                              selection
                              search
                              openOnFocus={false}
                            />
                          </AmsFormLabel>
                        </Form.Field>
                      )}
                    {selectedTeamRole.value &&
                      selectedTeamRole.value === 'reviewers' && (
                        <>
                          {currentReviewerPositions &&
                          currentReviewerPositions.length > 0 ? (
                            <div style={{ width: '100%' }}>
                              <span style={{ fontSize: '16px' }}>
                                Current Reviewer Positions
                              </span>
                              <List
                                divided
                                relaxed
                                fluid
                                style={{ width: '100%' }}
                              >
                                {currentReviewerPositions.map(position => {
                                  return (
                                    <List.Item>
                                      <List.Content>
                                        <List.Header>
                                          {position.name}
                                        </List.Header>
                                        <List.Description>
                                          {position.active
                                            ? 'Active'
                                            : 'Disabled'}
                                        </List.Description>
                                      </List.Content>
                                    </List.Item>
                                  );
                                })}
                              </List>
                            </div>
                          ) : (
                            <Segment
                              placeholder
                              textAlign="center"
                              style={{ width: '100%' }}
                            >
                              <Header>Add a reviewer position</Header>
                              <strong style={{ fontSize: '20px' }}>
                                Select the required skills for the position
                              </strong>

                              <Segment.Inline>
                                <br />
                                <Button
                                  primary
                                  onClick={() => {
                                    setShowReviewerPositionAddModal(true);
                                    // closeAddTeamModal();
                                  }}
                                >
                                  Add New Reviewer Position
                                </Button>
                              </Segment.Inline>
                            </Segment>
                          )}
                        </>
                      )}
                    {selectedTeamRole.value &&
                      selectedTeamRole.value === 'shadowReviewers' && (
                        <Form.Field>
                          <AmsFormLabel
                            name={selectedTeamRole.text}
                            fieldLabel={selectedTeamRole.ariaLabelledBy}
                          >
                            <AllUserListPreloadDropdown
                              name={selectedTeamRole.value}
                              userType={selectedTeamRole.key}
                              value={
                                updatedTeams &&
                                updatedTeams.find(
                                  team => team.key === selectedTeamRole.key
                                ) &&
                                !isEmpty(
                                  updatedTeams.find(
                                    team => team.key === selectedTeamRole.key
                                  ).team[0]
                                )
                                  ? updatedTeams
                                      .find(
                                        team =>
                                          team.key === selectedTeamRole.key
                                      )
                                      .team.map(member => member.oid)
                                  : []
                              }
                              // error={!!errors.shadowReviewer}
                              onChange={(e, { name, value, options }) => {
                                let error = '';

                                for (let t of updatedTeams) {
                                  if (t.key !== 'shadowReviewer') {
                                    if (
                                      !isEmpty(
                                        intersection(
                                          t.team.map(user => user.oid),
                                          value
                                        )
                                      )
                                    ) {
                                      error =
                                        'Shadow reviewers cannot be included in other roles';
                                      setErrors({
                                        ...errors,
                                        shadowReviewer: error,
                                      });
                                      break;
                                    } else {
                                      setErrors({
                                        ...errors,
                                        shadowReviewer: '',
                                      });
                                    }
                                  }
                                }
                                handleBuildRoles(value, selectedTeamRole.key);
                              }}
                              aria-labelledby={selectedTeamRole.ariaLabelledBy}
                              searchInput={{
                                id: selectedTeamRole.ariaLabelledBy,
                              }}
                              placeholder={`Select ${selectedTeamRole.text}`}
                              multiple
                              selection
                              search
                              disabled={!(startDate && endDate)}
                              startDate={startDate || {}}
                              endDate={endDate || {}}
                              additionalOptions={pendingShadowReviewers}
                              openOnFocus={false}
                            />
                          </AmsFormLabel>
                        </Form.Field>
                      )}
                    {selectedTeamRole.value &&
                      selectedTeamRole.value === 'pendingShadowReviewers' && (
                        <Form.Field>
                          <AmsFormLabel
                            name={selectedTeamRole.text}
                            fieldLabel={selectedTeamRole.ariaLabelledBy}
                          >
                            <AllUserListPreloadDropdown
                              name={selectedTeamRole.value}
                              userType={'shadowReviewer'}
                              value={
                                updatedTeams &&
                                updatedTeams.find(
                                  team => team.key === selectedTeamRole.key
                                ) &&
                                !isEmpty(
                                  updatedTeams.find(
                                    team => team.key === selectedTeamRole.key
                                  ).team[0]
                                )
                                  ? updatedTeams
                                      .find(
                                        team =>
                                          team.key === selectedTeamRole.key
                                      )
                                      .team.map(member => member.oid)
                                  : []
                              }
                              // error={!!errors.shadowReviewer}
                              onChange={(e, { name, value, options }) => {
                                let error = '';

                                for (let team of updatedTeams) {
                                  if (team.key !== 'pendingShadowReviewer') {
                                    if (
                                      !isEmpty(
                                        intersection(
                                          team.team.map(user => user.oid),
                                          value
                                        )
                                      )
                                    ) {
                                      error =
                                        'Shadow reviewers cannot be included in other roles';
                                      setErrors({
                                        ...errors,
                                        shadowReviewer: error,
                                      });
                                      break;
                                    } else {
                                      setErrors({
                                        ...errors,
                                        shadowReviewer: '',
                                      });
                                    }
                                  }
                                }
                                handleBuildRoles(value, selectedTeamRole.key);
                              }}
                              aria-labelledby={selectedTeamRole.ariaLabelledBy}
                              searchInput={{
                                id: selectedTeamRole.ariaLabelledBy,
                              }}
                              placeholder={`Select ${selectedTeamRole.text}`}
                              multiple
                              selection
                              search
                              disabled={!(startDate && endDate)}
                              startDate={startDate || {}}
                              endDate={endDate || {}}
                              additionalOptions={pendingShadowReviewers}
                              openOnFocus={false}
                            />
                          </AmsFormLabel>
                        </Form.Field>
                      )}
                  </Form.Group>
                )}
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {!isEmpty(selectedTeamRole) &&
        selectedTeamRole.value !== 'reviewers' ? (
          (updatedTeamPositions.find(
            teamPosition => teamPosition.type === selectedTeamRole.value
          ) &&
            !updatedTeamPositions.find(
              teamPosition => teamPosition.type === selectedTeamRole.value
            ).active) ||
          isEmpty(
            updatedTeamPositions.find(
              teamPosition => teamPosition.type === selectedTeamRole.value
            )
          ) ? (
            <Button positive onClick={() => enableTeamPosition()}>
              Enable {selectedTeamRole.text} team
            </Button>
          ) : (
            <Button negative onClick={() => disableTeamPosition()}>
              Disable {selectedTeamRole.text} team
            </Button>
          )
        ) : selectedTeamRole.value === 'reviewers' &&
          (positions.length > 0 || currentReviewerPositions.length > 0) ? (
          <Button
            positive
            onClick={() => setShowReviewerPositionAddModal(true)}
          >
            Add New Reviewer Position
          </Button>
        ) : null}

        <Button
          onClick={() => {
            if (!dataChanged) {
              closeAddTeamModal();
              updateShowOnlyDropdown(false);
              setUpdatedTeams([]);
              setUpdatedTeamPositions([]);
            } else {
              setShowAlert(true);
            }
          }}
          content="Close"
        />

        {!showOnlyDropdown ? (
          <Button
            onClick={() => {
              if (!isEmpty(errors.shadowReviewer)) {
                updateAlertModal(errors.shadowReviewer, true, 'error');
                return;
              }
              if (updatedTeams.length > 0) {
                updatedTeams.forEach(team => {
                  if (
                    (team.team && isEmpty(team.team[0])) ||
                    team.team.length === 0
                  )
                    dispatch(removePrimaryUser({ type: team.value }));
                  if (
                    team.team &&
                    team.team.length === 1 &&
                    !isEmpty(team.team[0]) &&
                    team.value !== 'shadowReviewers' &&
                    team.value !== 'pendingShadowReviewers' &&
                    team.value !== 'dualCoders' &&
                    team.value !== 'reviewers'
                  )
                    updatePositionPrimaryLead(team.team[0], team.value);
                  const activeOnly = team.team.filter(e => {
                    return e.isActive !== false;
                  });
                  dispatch(updateTeam(team.value, activeOnly));
                });
              } else {
                dispatch(updateTeam([selectedTeamRole.value], []));
              }
              if (updatedTeamPositions.length > 0) {
                dispatch(updateTeamPositions(updatedTeamPositions));
              }
              if (currentReviewerPositions.length > 0) {
                dispatch(addPosition(currentReviewerPositions));
              }
              closeAddTeamModal();
              setUpdatedTeams([]);
              setUpdatedTeamPositions([]);
              setDataChanged(false);
              dataHasChangedSwitch(true);
            }}
            primary
            content="Save team(s)"
          />
        ) : (
          <Button
            onClick={() => {
              if (!isEmpty(errors.shadowReviewer)) {
                updateAlertModal(errors.shadowReviewer, true, 'error');
                return;
              }
              if (updatedTeams.length === 0)
                dispatch(updateTeam([selectedTeamRole.value], []));
              updatedTeams.forEach(team => {
                if (
                  (team.team && isEmpty(team.team[0])) ||
                  team.team.length === 0
                )
                  dispatch(removePrimaryUser({ type: team.value }));
                if (
                  team.team &&
                  team.team.length === 1 &&
                  !isEmpty(team.team[0]) &&
                  team.value !== 'shadowReviewers' &&
                  team.value !== 'pendingShadowReviewers' &&
                  team.value !== 'dualCoders' &&
                  team.value !== 'reviewers'
                )
                  updatePositionPrimaryLead(team.team[0], team.value);
                const activeOnly = team.team.filter(e => {
                  return e.isActive !== false;
                });
                console.log(activeOnly, team.team);
                dispatch(updateTeam(team.value, activeOnly));
              });
              if (updatedTeamPositions.length > 0) {
                dispatch(updateTeamPositions(updatedTeamPositions));
              }
              if (currentReviewerPositions.length > 0) {
                dispatch(addPosition(currentReviewerPositions));
              }

              closeAddTeamModal();
              updateShowOnlyDropdown(false);
              setUpdatedTeams([]);
              setUpdatedTeamPositions([]);
              setDataChanged(false);
            }}
            primary
            content="Save"
          />
        )}
      </Modal.Actions>
    </AmsModal>
  );

  return (
    <>
      {addTeamModal()}
      {showCloseFormAlert()}
      {
        <ReviewerPositionForm
          dataHasChangedSwitch={dataHasChangedSwitch}
          showReviewerPositionAddModal={showReviewerPositionAddModal}
          handleModalClose={() => setShowReviewerPositionAddModal(false)}
          reviewerPositionSkills={{}}
          setUpdatedPositions={newPositions => {
            let updatedPositions = [...currentReviewerPositions];
            updatedPositions.push(newPositions[newPositions.length - 1]);
            updatedPositions = updatedPositions.map((position, index) => {
              position = {
                ...position,
                _id: index.toString(),
                name: `Position ${index + 1}`,
              };

              return position;
            });
            setCurrentReviewerPositions(updatedPositions);
            setDataChanged(true);
          }}
          editPosition={false}
        />
      }
    </>
  );
};

export default AddTeamModal;
