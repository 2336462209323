import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

// Import components.
import ResponsiveContainer from '../components/Layouts/ResponsiveContainer';

class ExternalLinkPage extends Component {
  state = {
    continueToExternalLink: false,
    count: 10,
  };

  componentDidMount() {
    const { url } = this.context.router.location.query;
    this.setState({
      url: url,
    });
    this.timer = setInterval(() => {
      if (this.state.count <= 0) {
        clearInterval(this.timer);
        this.setState({
          continueToExternalLink: true,
        });
        window.location = this.state.url;
      } else {
        this.setState(prevState => ({ count: prevState.count - 1 }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  loadExternalLink = () => {};

  render() {
    return (
      <ResponsiveContainer {...this.props}>
        {this.loadExternalLink()}
        <div style={{ textAlign: 'center' }}>
          <br />
          <Message info compact>
            <Message.Header>
              You are leaving the Aligned Monitoring System website
            </Message.Header>
            {`You are being connected to ${this.state.url} in ${this.state.count} seconds`}
            <br />
            {
              "If you do not wish to leave the Aligned Monitoring System site, please use your browser's back button to return to the previous page."
            }
          </Message>
        </div>
      </ResponsiveContainer>
    );
  }
}

ExternalLinkPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

ExternalLinkPage.propTypes = {};

export default connect(null, {})(ExternalLinkPage);
