import _ from 'lodash';

/**
 * Show/Hide component demending on the allowed roles.
 *
 * @param {Array} authorized
 * @param {Array} userRoles
 * @param {Component} wrappedComponent
 * @returns {Component}
 */
const enforceRole = (wrappedComponent, authorized, userRoles) => {
  if (!authorized || !userRoles || !wrappedComponent) return null;

  if (_.includes(authorized, 'All')) return wrappedComponent;

  if (_.intersection(userRoles, authorized).length > 0) return wrappedComponent;

  return null;
};

export const allowedView = (authorized, userRoles) => {
  if (!authorized || !userRoles) return false;

  if (_.includes(authorized, 'All')) return true;

  if (_.intersection(userRoles, authorized).length > 0) return true;

  return false;
};

export default enforceRole;
