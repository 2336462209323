import axios from 'axios';

import {
  RAN_PERFORMANCE_MEASURE_SELECTED,
  RAN_FORM_DETAIL_FETCHED,
  RAN_DRAFT_SAVED,
  RAN_SUBMISSION_SAVED,
  RAN_SUBMISSION_UPDATED,
  RAN_SUBMISSION_FETCHED,
  RAN_FORM_SCHEMA_FETCHED,
  RAN_GUIDE_SELECTED,
  RAN_CENTER_SELECTED,
  RAN_CLASSROOM_SELECTED,
  RAN_UPDATE_GUIDE,
  RAN_CLEAR_FORMS,
  RAN_INCIDENTS_SUBMISSION_SAVED,
} from '../redux/types';

export const ranPerformanceMeasureSelected = selected => ({
  type: RAN_PERFORMANCE_MEASURE_SELECTED,
  selected,
});

export const ranCenterSelected = center => ({
  type: RAN_CENTER_SELECTED,
  center,
});

export const ranClassroomSelected = classroom => ({
  type: RAN_CLASSROOM_SELECTED,
  classroom,
});

export const ranFormDetailFetched = ran => ({
  type: RAN_FORM_DETAIL_FETCHED,
  ran,
});

export const ranFormSchemaFetched = schema => ({
  type: RAN_FORM_SCHEMA_FETCHED,
  schema,
});

export const ranSubmissionFetched = submission => ({
  type: RAN_SUBMISSION_FETCHED,
  submission,
});

export const ranDraftSaved = () => ({
  type: RAN_DRAFT_SAVED,
});

export const ranSubmissionSaved = () => ({
  type: RAN_SUBMISSION_SAVED,
});

export const ranSubmissionUpdated = () => ({
  type: RAN_SUBMISSION_UPDATED,
});

export const ranGuideSelected = (guide, performanceMeasure) => ({
  type: RAN_GUIDE_SELECTED,
  guide,
  performanceMeasure,
});

export const ranUpdateGuide = (guide, performanceMeasure, submissionId) => ({
  type: RAN_UPDATE_GUIDE,
  guide,
  performanceMeasure,
  submissionId,
});

export const clearRANForms = () => ({
  type: RAN_CLEAR_FORMS,
});

export const ranIncidentSubmissionSaved = () => ({
  type: RAN_INCIDENTS_SUBMISSION_SAVED,
});

export const fetchRANFormDetail = request => dispatch =>
  axios
    .post(`/survey/ran/fetch-form-detail`, request)
    .then(response => dispatch(ranFormDetailFetched(response.data)));

export const fetchRANFormSchema = formId => dispatch =>
  axios
    .post(`/survey/ran/fetch-form-schema`, formId)
    .then(response => dispatch(ranFormSchemaFetched(response.data)));

export const fetchRANSubmission = request => dispatch =>
  axios
    .post(`/survey/ran/fetch-submission`, request)
    .then(response => dispatch(ranSubmissionFetched(response.data)));

export const ranSaveDraft = request => dispatch =>
  axios.post(`/survey/ran/save-draft`, request).then(response => {
    dispatch(ranDraftSaved());
    return response.data;
  });

export const ranSaveSubmission = request => dispatch =>
  axios.post(`/survey/ran/save-submission`, request).then(response => {
    dispatch(ranSubmissionSaved());
    return response.data;
  });

export const ranUpdateSubmission = request => dispatch =>
  axios.post(`/survey/ran/update-submission`, request).then(response => {
    dispatch(ranSubmissionSaved());
    return response.data;
  });
