import axios from 'axios';
import fileDownload from 'js-file-download';

import { downloadBlob } from '../components/Shared/FormHelpers/helpers/utils';

import {
  AGENCY_LOOKUP,
  GRANTEES_FETCHED,
  GRANTEES_FILTERED,
  GRANTEE_EXTENSION_FETCHED,
  GRANTEE_EXTENSION_HISTORY_FETCHED,
  GRANTEE_FETCHED,
  GRANTEE_FILTER_CLEARED,
  GRANTEE_HASH_FETCHED,
  GRANTEE_INVITED,
  GRANTEE_OPTIONS_FETCHED,
  GRANTEE_SCHEDULE_RESULTS,
  GRANTEE_SCHEDULE_UPDATED,
  GRANTEE_STATUS_FETCHED,
  GRANTEE_TO_EXPORT,
  GRANTEE_UNAVAILABILTY_UPDATED,
  RESET_GRANTEES_INVITED,
} from '../redux/types';

export const granteesFetched = grantees => ({
  type: GRANTEES_FETCHED,
  grantees,
});

export const granteesFiltered = grantees => ({
  type: GRANTEES_FILTERED,
  grantees,
});

export const granteeFilterCleared = grantees => ({
  type: GRANTEE_FILTER_CLEARED,
});

export const granteeFetched = grantee => ({
  type: GRANTEE_FETCHED,
  grantee,
});

export const getGranteeOptions = granteeOptions => ({
  type: GRANTEE_OPTIONS_FETCHED,
  granteeOptions,
});

export const granteeHashFetched = granteeDetails => ({
  type: GRANTEE_HASH_FETCHED,
  granteeDetails,
});

export const granteeStatusFetched = granteeStatus => ({
  type: GRANTEE_STATUS_FETCHED,
  granteeStatus,
});

export const granteeScheduleResults = granteeResults => ({
  type: GRANTEE_SCHEDULE_RESULTS,
  granteeResults,
});

export const granteeUnavialabilityUpdated = granteeUpdated => ({
  type: GRANTEE_UNAVAILABILTY_UPDATED,
  granteeUpdated,
});

export const granteeToExport = payload => ({
  type: GRANTEE_TO_EXPORT,
  payload,
});

export const granteeInvited = invitedGrantees => ({
  type: GRANTEE_INVITED,
  invitedGrantees,
});

export const resetInvitedGrantees = () => ({
  type: RESET_GRANTEES_INVITED,
});

export const SchedulerUpdatedGrantee = granteeScheduleUpdated => ({
  type: GRANTEE_SCHEDULE_UPDATED,
  granteeScheduleUpdated,
});

// export const getGranteeUnavailabilty = granteeDetails => ({
//   type: GRANTEE_UNAVAILABILTY_UPDATED,
//   granteeDetails
// })

export const granteeExtensionFetched = extensionRequests => ({
  type: GRANTEE_EXTENSION_FETCHED,
  extensionRequests,
});

export const granteeExtensionHistoryFetched = extensionRequestHistory => ({
  type: GRANTEE_EXTENSION_HISTORY_FETCHED,
  extensionRequestHistory,
});

export const setAgencies = payload => {
  return { type: AGENCY_LOOKUP, agencies: payload };
};

export const fetchGrantee = granteeId => dispatch =>
  axios
    .post(`/grantee/detail`, { granteeId })
    .then(response => dispatch(granteeFetched(response.data)));

export const fetchGrantees = input => dispatch =>
  axios
    .post(`/grantee`, input)
    .then(response => dispatch(granteesFetched(response.data)));

export const filterGrantees = (query, status) => dispatch => {
  const body = status ? { status: status.join(',') } : {};
  return axios
    .post(`/grantee/filter/${query}`, body)
    .then(response => dispatch(granteesFiltered(response.data)));
};

export function fetchGranteeNoDetail() {
  return dispatch => {
    axios.post(`/grantee/noDetail`).then(function(response) {
      dispatch(getGranteeOptions(response.data));
    });
  };
}

export const updateGeocode = geocodeObject => dispatch =>
  axios
    .post(`/grantee/addGeocode`, geocodeObject)
    .then(response => {
      granteeFetched(response.data);
    })
    .catch(error => console.error(error));

export const downloadGranteeReviewReport = (reviewId, fileName) => dispatch =>
  axios
    .post(`/search/downloadreport`, fileName, { responseType: 'arraybuffer' }) // This is required for file download to work
    .then(response => {
      const { data } = response;

      if (!data) return;
      const blob = new Blob([data], {
        type: 'MIME',
      });

      downloadBlob(blob, `${reviewId} - Signed Report.pdf`);

      return response;
    });

export const fetchAgencies = payload => dispatch => {
  return axios
    .post('/grantee/agencyFilter', payload)
    .then(response => dispatch(setAgencies(response.data)))
    .catch(err => console.error(err));
};

export const sendInvites = invitedGrantees => dispatch => {
  return axios
    .post('/grantee/sendInvite', invitedGrantees)
    .then(response => granteeInvited(response.data));
};

export const sendReminders = grantees => dispatch => {
  return axios
    .post('/grantee/sendReminder', grantees)
    .then(response => response.data);
};

export const resetInvites = () => dispatch => {
  dispatch(resetInvitedGrantees());
};

export const submitExtReq = payload => dispatch => {
  return axios
    .post('/grantee-noauth/extension-request', payload)
    .then(response => response)
    .catch(err => err);
};

export const fetchGranteeHash = granteeHash => dispatch =>
  axios
    .post(`/grantee-noauth/grantee-unavailability-hash`, granteeHash)
    .then(response => dispatch(granteeHashFetched(response.data)));

export const updateGrantee = granteeUpdated => dispatch =>
  axios
    .post(`/grantee-noauth/grantee-unavailability-add`, granteeUpdated)
    .then(response => response.data)
    .then(data => data);

export const granteeHash = hash => dispatch => {
  return axios
    .post('grantee-noauth/e-hash', hash)
    .then(res => res.data)
    .catch(err => console.error(err));
};

export const granteeExportCSV = payload => dispatch => {
  return axios
    .post('grantee/exportGrantees', payload, { responseType: 'arraybuffer' })
    .then(response => {
      const { data } = response;

      const contentType = response.headers['content-type'];
      if (!response) return;

      fileDownload(data, `grantee_export.xlsx`, contentType);
      return response;
    });
};

export const schedulerUpdateGranteeSchedule = granteeScheduleUpdated => dispatch => {
  axios
    .post(`/grantee/scheduler-update-grantee-schedule`, granteeScheduleUpdated)
    .then(response => dispatch(SchedulerUpdatedGrantee(response.data)));
};

export const fetchGranteeRequests = input => dispatch => {
  axios
    .post(`/grantee/fetchGranteeRequests`, input)
    .then(response => dispatch(granteeExtensionFetched(response.data)));
};

export const saveGranteeExtensionRequest = input => dispatch => {
  axios
    .post(`/grantee/saveGranteeExtensionRequest`, input)
    .then(response =>
      dispatch(fetchGranteeRequests({ invitationId: input.invitationId }))
    );
};

export const saveGranteeUnavailability = (
  granteeUpdated,
  callback
) => dispatch =>
  axios
    .post(`/grantee-noauth/grantee-unavailability-add`, granteeUpdated)
    .then(response => callback(response), callback || console.error)
    .catch(error => callback(error));

export const fetchGranteeAvailability = input => dispatch => {
  axios
    .post(`/grantee/fetchGranteeAvailability`, input)
    .then(response => dispatch(granteeHashFetched(response.data)));
};

export const updateGranteeSchedule = granteeUpdated => dispatch =>
  axios
    .post(`/grantee/scheduler-update-grantee-schedule`, granteeUpdated)
    .then(response => dispatch(granteeScheduleResults(response.data)));

export const fetchGranteeExtensionHistory = granteeId => dispatch => {
  axios
    .post(`/grantee/fetchGranteeExtensionHistory/${granteeId}`)
    .then(response => dispatch(granteeExtensionHistoryFetched(response.data)));
};

export const fetchGranteeStatus = (id, type) => dispatch => {
  const data = {};
  type === 'grantee' ? (data.granteeId = id) : (data.reviewId = id);
  axios
    .post(`/grantee/fetchGranteeStatus`, data)
    .then(response => dispatch(granteeStatusFetched(response.data)));
};
