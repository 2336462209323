import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import Form from '@rjsf/core';

import AttachmentField from '../../Shared/FormHelpers/AttachmentField';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';
import {
  fileUploadFormSchema,
  fileUploadUISchema,
} from './FollowUpSurveyFileSchema';

class FollowUpAttachmentField extends Component {
  state = {
    formData: {},
  };

  handleFurRecommendationChange = (furRecommendation, index, { formData }) => {
    this.props.onChange(furRecommendation, index, { formData });
  };

  handleAdditionalCitationChange = (
    additionalCitation,
    index,
    { formData }
  ) => {
    this.props.onChange(additionalCitation, index, { formData });
  };

  validate = (data, errors) => {
    if (this.state.draftButtonClicked) {
      return {};
    }
    return errors;
  };

  fields = () => {
    return {
      attachmentField: AttachmentField,
    };
  };

  log = type => console.log.bind(console, type);

  renderFURRecommendationForm = () => {
    const { furRecommendation, index, formName } = this.props;

    return (
      <Form
        id={`${formName}-${index}`}
        schema={fileUploadFormSchema}
        ArrayFieldTemplate={ArrayFieldTemplate}
        uiSchema={fileUploadUISchema}
        onChange={e =>
          this.handleFurRecommendationChange(furRecommendation, index, e)
        }
        onSubmit={this.onSubmit}
        onError={this.log('errors')}
        validate={this.validate}
        liveValidate={false}
        showErrorList={false}
        formData={{
          evidenceFiles: furRecommendation && furRecommendation.evidences,
        }}
        noHtml5Validate={true}
        fields={this.fields()}
      >
        <div hidden>
          <Button
            size="large"
            type="submit"
            content={'Submit'}
            hidden
            disabled
            onClick={e => {
              e.preventDefault();
            }}
          />
        </div>
      </Form>
    );
  };

  renderAdditionalCitationForm = () => {
    const { index, additionalCitation, formName } = this.props;

    return (
      <Form
        id={`${formName}-${index}`}
        ArrayFieldTemplate={ArrayFieldTemplate}
        schema={fileUploadFormSchema}
        uiSchema={fileUploadUISchema}
        onChange={e =>
          this.handleAdditionalCitationChange(additionalCitation, index, e)
        }
        onSubmit={this.onSubmit}
        onError={this.log('errors')}
        validate={this.validate}
        liveValidate={false}
        showErrorList={false}
        formData={{
          evidenceFiles: additionalCitation && additionalCitation.evidenceFiles,
        }}
        noHtml5Validate={true}
        fields={this.fields()}
      >
        <div hidden>
          <Button
            size="large"
            type="submit"
            content={'Submit'}
            hidden
            disabled
            onClick={e => {
              e.preventDefault();
            }}
          />
        </div>
      </Form>
    );
  };

  render() {
    const { furRecommendation, additionalCitation } = this.props;

    if (!_.isEmpty(furRecommendation))
      return this.renderFURRecommendationForm();
    if (!_.isEmpty(additionalCitation))
      return this.renderAdditionalCitationForm();

    return null;
  }
}

FollowUpAttachmentField.propTypes = {
  followUpTaskDetail: PropTypes.object,
};

const mapStateToProps = state => ({
  followUpTaskDetail: state.submissions.followUpTaskDetail,
});

export default connect(mapStateToProps)(FollowUpAttachmentField);
