import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';

import { downloadBlob } from '../components/Shared/FormHelpers/helpers/utils';

import {
  SUBMISSION_UPLOAD,
  SUBMISSIONS_FETCHED,
  SUBMISSION_SELECTED,
  CLASS_ASSIGNMENTS_FETCHED,
  CLASS_FORM_FETCHED,
  CLASS_TASK_DETAIL_FETCHED,
  CLASS_ASSIGNMENTS_ADDED,
  CLASS_SUBMISSIONS_DETAIL_FETCHED,
  CLASS_SAMPLE_REPORT_FETCHED,
  DUAL_COMPARISON_REPORT_FETCHED,
  CLASS_METHODOLOGY_FETCHED,
  PASS_FAIL_REPORT_FETCHED,
  FOLLOWUP_TASK_DETAIL_FETCHED,
  CLASS_VERSION_HISTORY_FETCHED,
  SUBMISSION_FU_SELECTED,
  FU_TASK_DETAIL_FETCHED,
  CLASS_FREEZE_STATUS_FETCHED,
} from '../redux/types';

export function getSubmissions(submissions) {
  return {
    type: SUBMISSIONS_FETCHED,
    submissions,
  };
}

export function selectSubmission(submission) {
  return {
    type: SUBMISSION_SELECTED,
    submission,
  };
}

export function selectFUSubmission(submission) {
  return {
    type: SUBMISSION_FU_SELECTED,
    submission,
  };
}

export function uploadSubmission(submission) {
  return {
    type: SUBMISSION_UPLOAD,
    submission,
  };
}

export function getAssignments(classAssignments) {
  return {
    type: CLASS_ASSIGNMENTS_FETCHED,
    classAssignments,
  };
}

export function addAssignments(classAssignmentAdded) {
  return {
    type: CLASS_ASSIGNMENTS_ADDED,
    classAssignmentAdded,
  };
}

export function getAMSForm(AMSForm) {
  return {
    type: CLASS_FORM_FETCHED,
    AMSForm,
  };
}

export function getClassTaskDetail(classTaskDetail) {
  return {
    type: CLASS_TASK_DETAIL_FETCHED,
    classTaskDetail,
  };
}

export function getFollowUpTaskDetail(followUpTaskDetail) {
  return {
    type: FOLLOWUP_TASK_DETAIL_FETCHED,
    followUpTaskDetail,
  };
}

export function getFUTaskDetail(followUpTaskDetail) {
  return {
    type: FU_TASK_DETAIL_FETCHED,
    followUpTaskDetail,
  };
}

export function getClassSubmissionsDetail(classSubmissionsDetail) {
  return {
    type: CLASS_SUBMISSIONS_DETAIL_FETCHED,
    classSubmissionsDetail,
  };
}

export function getClassSampleReportList(classSamplingReportList) {
  return {
    type: CLASS_SAMPLE_REPORT_FETCHED,
    classSamplingReportList,
  };
}

export function getDualComparisonReport(dualComparisonReport) {
  return {
    type: DUAL_COMPARISON_REPORT_FETCHED,
    dualComparisonReport,
  };
}

export function getClassMethodologyReportList(classMethodologyReportList) {
  return {
    type: CLASS_METHODOLOGY_FETCHED,
    classMethodologyReportList,
  };
}

export function getPassFailReport(passFailReport) {
  return {
    type: PASS_FAIL_REPORT_FETCHED,
    passFailReport,
  };
}

export function getVersionHistory(resetVersionHistory) {
  return {
    type: CLASS_VERSION_HISTORY_FETCHED,
    resetVersionHistory,
  };
}

export function getClassFreezeDetails(classFreezeDetails) {
  return {
    type: CLASS_FREEZE_STATUS_FETCHED,
    classFreezeDetails,
  };
}

export const addSubmission = obj => dispatch =>
  axios.post(`/submissions/addSubmission`, obj).then(response => {
    return response.data;
  });

export const fetchCLASSSurveys = requestInput => dispatch =>
  axios
    .post(`/submissions/${requestInput.reviewId}`, requestInput)
    .then(response => dispatch(getSubmissions(response.data)));

export const fetchClassAssignments = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchAssignments`, requestInput)
    .then(response => dispatch(getAssignments(response.data)));

export const addClassAssignments = requestInput => dispatch =>
  axios
    .post(`/submissions/addAssignments`, requestInput)
    .then(response => dispatch(addAssignments(response.data)));

export const fetchAMSForm = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchAMSForm`, requestInput)
    .then(response => dispatch(getAMSForm(response.data)));
export const fetchClassForm = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchAMSForm`, requestInput)
    .then(response => dispatch());

export const fetchClassTaskDetail = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchClassTaskDetail`, requestInput)
    .then(response => dispatch(getClassTaskDetail(response.data)));

export const addClassSubmission = requestInput => dispatch =>
  axios.post(`/submissions/addClassSubmission`, requestInput).then(response => {
    return response.data;
  });

export const fetchSubmission = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchSubmission`, requestInput)
    .then(response => dispatch(selectSubmission(response.data)));

export const fetchClassSubmissionsDetail = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchClassSubmissionsDetail`, requestInput)
    .then(response => dispatch(getClassSubmissionsDetail(response.data.data)));

export const fetchClassReviewDetail = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchClassReviewDetail`, requestInput)
    .then(response => {
      console.log(response.data);
    });

export const exportClassAssignments = reviewId => dispatch =>
  axios
    .post(
      `/submissions/exportClassAssignments/${reviewId}`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;
      const contentType = response.headers['content-type'];
      if (!response) return;

      fileDownload(
        data,
        `${reviewId}_CLASS-Assignments_${moment().format('x')}.xlsx`,
        contentType
      );
      return response;
    });

export const selectedSubmission = submission => dispatch =>
  dispatch(selectSubmission(submission));

export const fetchFollowUpTaskDetail = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchFollowUpTaskDetail`, requestInput)
    .then(response => dispatch(getFollowUpTaskDetail(response.data)));

export const uploadDocuments = (files, obj, questionIndex) => dispatch =>
  Promise.all(
    files.map(file => {
      let formDataContent = new FormData();
      formDataContent.append('file', file, file.name);
      formDataContent.append('reviewType', obj.reviewType);
      formDataContent.append('reviewId', obj.reviewId);
      return new Promise((resolve, reject) => {
        axios
          .post(`/submissions/uploadDocuments`, formDataContent)
          .then(response => {
            // responseData.push(response.data)
            resolve(response.data);
          });
      });
    })
  ).then(
    res => {
      return { uploadedFiles: res, questionIndex };
    },
    err => {
      console.log(err);
    }
  );

export const runAdHocSampleGenerate = requestInput => dispatch =>
  axios
    .post(`/submissions/runAdHocSampleGenerate`, requestInput)
    .then(response => {
      return response.data;
    });

export const getClassSamplingReport = requestInput => dispatch =>
  axios
    .post(
      `/submissions/getClassSamplingReport/${requestInput.reviewId}`,
      requestInput
    )
    .then(response => dispatch(getClassSampleReportList(response.data)));

export const downloadClassSamplingReport = fileName => dispatch =>
  axios
    .post(
      `/submissions/downloadClassSamplingReport/${fileName}`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;

      if (!data) return;
      const blob = new Blob([data], {
        type: 'MIME',
      });

      downloadBlob(blob, `${fileName}.pdf`);

      return data;
    });

export const approveClassSurveys = requestInput => dispatch =>
  axios
    .post(`/submissions/approveClassSurveys`, requestInput)
    .then(response => {
      return response.data;
    });

export const fetchDualComparisonReport = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchDualComparisonReport`, requestInput)
    .then(response => dispatch(getDualComparisonReport(response.data)));

export const resetCenterAssignments = requestInput => dispatch =>
  axios
    .post(`/submissions/resetCenterAssignments`, requestInput)
    .then(response => {
      return response.data;
    });

export const resetScores = requestInput => dispatch =>
  axios.post(`/submissions/resetScores`, requestInput).then(response => {
    return response.data;
  });

export const fetchClassMethodologyReport = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchClassMethodologyReport`, requestInput)
    .then(response => dispatch(getClassMethodologyReportList(response.data)));

export const fetchPassFailReport = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchPassFailReport`, requestInput)
    .then(response => dispatch(getPassFailReport(response.data)));

export const requestReplaceClass = requestInput => dispatch =>
  axios
    .post(`/submissions/requestReplaceClass`, requestInput)
    .then(response => {
      return response.data;
    });

export const requestSkipClass = requestInput => dispatch =>
  axios.post(`/submissions/requestSkipClass`, requestInput).then(response => {
    return response.data;
  });

export const approveSkipClass = requestInput => dispatch =>
  axios.post(`/submissions/approveSkipClass`, requestInput).then(response => {
    return response.data;
  });

export const exportChildFiles = amsSubmissionId => dispatch =>
  axios
    .post(
      `/submissions/exportChildFiles/${amsSubmissionId}`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;
      const contentType = response.headers['content-type'];
      if (!response) return;

      fileDownload(
        data,
        `${amsSubmissionId}_Child-Files${moment().format('x')}.xlsx`,
        contentType
      );
      return response;
    });

export const saveDraft = obj => dispatch =>
  axios.post(`/submissions/saveDraft`, obj).then(response => {
    return response.data;
  });

export const requestSkipSecondObservation = requestInput => dispatch =>
  axios
    .post(`/submissions/requestSkipSecondObservation`, requestInput)
    .then(response => {
      return response.data;
    });

export const approveObservationSkip = requestInput => dispatch =>
  axios
    .post(`/submissions/approveObservationSkip`, requestInput)
    .then(response => {
      return response.data;
    });

export const removeClassAssignments = requestInput => dispatch =>
  axios
    .post(`/submissions/removeClassAssignments`, requestInput)
    .then(response => {
      return response.data;
    });

export const fetchVersionHistory = requestInput => dispatch =>
  axios
    .post(`/submissions/resetScore/versionHistory`, requestInput)
    .then(response => dispatch(getVersionHistory(response.data)));

export const fetchFUSubmission = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchFUSubmission`, requestInput)
    .then(response => dispatch(selectFUSubmission(response.data)));

export const addFUSubmission = obj => dispatch =>
  axios.post(`/submissions/addFUSubmission`, obj).then(response => {
    return response.data;
  });

export const fetchFUTaskDetail = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchFUTaskDetail`, requestInput)
    .then(response => dispatch(getFUTaskDetail(response.data)));

export const freezAdhocReport = requestInput => dispatch =>
  axios
    .post(`/submissions/freezeClassSample`, requestInput)
    .then(response => dispatch(getClassFreezeDetails(response.data)));

export const exportIncidents = amsSubmissionId => dispatch =>
  axios
    .post(
      `/submissions/exportIncidents/${amsSubmissionId}`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;
      const contentType = response.headers['content-type'];
      if (!response) return;

      fileDownload(
        data,
        `${amsSubmissionId}_Incidents${moment().format('x')}.xlsx`,
        contentType
      );
      return response;
    });
