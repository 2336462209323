import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import _ from 'lodash';

// Import Actions
import {
  addSubmission,
  fetchAMSForm,
  uploadDocuments,
  fetchFollowUpTaskDetail,
  fetchSubmission,
  saveDraft,
} from '../../../actions/submissions';
import { fetchNotes } from '../../../actions/noteActions';
import { fetchCitations } from '../../../actions/reviewActions';

//Import Helper functions
import { formatUserNotes } from '../../Notes/util.js';

// Import components
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import NotesContainer from '../../Notes';
import FollowUpFormContainer from './FollowUpFormContainer';
import FollowUpGranteeInfo from './FollowUpGranteeInfo';
import FollowUpCorrectiveInfo from './FollowUpCorrectiveInfo';

class FollowUpPage extends Component {
  state = {
    formProcessing: false,
    isReportFinalized: false,
  };

  componentDidMount = () => {
    const reviewId = this.context.router.params.reviewId;
    this.fetchFollowUpTaskDetail(reviewId);
    this.fetchSurveyNotes();
    this.props.fetchCitations({});
  };

  fetchSurveyNotes = () => {
    const body = {
      filters: {
        tags: {
          reviewId: this.context.router.params.reviewId,
        },
      },
    };

    this.props.fetchNotes(body);
  };

  fetchFollowUpTaskDetail = reviewId => {
    this.setState({
      formProcessing: true,
    });
    if (!reviewId) return;
    let input = { reviewId };
    this.props.fetchFollowUpTaskDetail(input).then(
      data => {
        if (
          data.followUpTaskDetail &&
          data.followUpTaskDetail.amsSubmissionId
        ) {
          this.fetchData(data.followUpTaskDetail.amsSubmissionId);
        } else {
          this.setState({ formProcessing: false });
        }

        this.setState({
          isReportFinalized: data.followUpTaskDetail.isReportFinalized,
        });
      },
      error => {
        this.setState({ formProcessing: false });
        console.log(error);
      }
    );
  };

  fetchData = (amsSubmissionId, version) => {
    if (!amsSubmissionId) return;
    let input = {};
    if (_.isNumber(version))
      input = {
        filters: {
          amsSubmissionId,
          version,
        },
      };
    else
      input = {
        filters: {
          amsSubmissionId,
        },
      };
    this.props.fetchSubmission(input).then(
      data => {
        if (data) this.setState({ formProcessing: false });
      },
      error => {
        this.setState({ formProcessing: false });
        console.log(error);
      }
    );
  };

  render = () => {
    const { notes } = this.props;

    const tags = {
      type: 'survey_collection',
      reviewId: this.context.router.params.reviewId,
    };

    const surveyNotes = notes && formatUserNotes(notes);

    return (
      <SlidingContainer
        calendar
        granteeStatusBanner={true}
        granteeStatusData={{
          id: this.context.router.params.reviewId,
          type: 'review',
        }}
        title={`Follow-up Survey for Review ID ${this.context.router.params.reviewId}`}
        actionButtons={
          <NotesContainer
            tags={tags}
            list={surveyNotes && surveyNotes}
            enabled={!this.state.isReportFinalized && surveyNotes}
            type={'Notes'}
            title={'Follow-up Survey'}
            reloadData={() => {
              this.fetchSurveyNotes();
            }}
          />
        }
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <FollowUpGranteeInfo />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FollowUpCorrectiveInfo />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FollowUpFormContainer
                fetchData={this.fetchData}
                formProcessing={this.state.formProcessing}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SlidingContainer>
    );
  };
}

FollowUpPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

FollowUpPage.propTypes = {
  fetchFollowUpTaskDetail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  followUpTaskDetail: state.submissions.followUpTaskDetail,
  amsForm: state.submissions.AMSForm,
  selectedSubmission: state.submissions.selectedSubmission,
  currentUser: state.auth.user,
  notes: state.note.notes,
});

export default connect(mapStateToProps, {
  addSubmission,
  uploadDocuments,
  fetchAMSForm,
  fetchFollowUpTaskDetail,
  fetchSubmission,
  saveDraft,
  fetchCitations,
  fetchNotes,
})(FollowUpPage);
