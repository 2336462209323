import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Dimmer, Header, Loader } from 'semantic-ui-react';

// React Bootstrap Table 2
import BootstrapTableNext from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

// Import actions.
import { fetchReviews } from '../../actions/reviewActions';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsTable from '../../utils/AmsTable';

class ReviewItems extends Component {
  state = {
    loading: false,
    reviews: [],
    page: 1,
    limit: 25,
    sortName: 'dueDate',
    sortOrder: 'desc',
    filters: { reviewStatus: [] },
  };

  constructor(props) {
    super(props);
    this.amsTableRef = React.createRef();
  }

  componentDidMount() {
    this.props.onFilter(this); // It registers and calls getData.
  }

  componentWillUnmount() {
    this.props.onFilter(null);
  }

  componentWillReceiveProps(props) {
    const { reviews, total, filters } = props;
    this.setState({ reviews, total, filters });
  }

  getData = () => {
    const { fetchReviews } = this.props;
    const { page, limit, filters, sortName, sortOrder } = this.state;

    let input = {
      filters,
      page,
      limit,
      sortName,
      sortOrder,
    };

    this.setState({ loading: true });

    fetchReviews(input)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.onError(error);
      });
  };

  renderCounter = () => {
    const {
      reviewsList: { limit, page, total },
      filters,
    } = this.props;

    if (total < 0) return null;

    const counterStart = (page - 1) * limit + 1;
    const counterEnd = limit * page <= total ? limit * page : total;

    const searchConditions =
      filters &&
      Object.keys(filters)
        // eslint-disable-next-line array-callback-return
        .map(key => {
          const field = filters[key];

          if (field) {
            if (Array.isArray(field)) return field.join(' or ');
            else if (typeof field === 'string') return field;
          }
        })
        .join(' : ');

    const results =
      total > 0
        ? `${counterStart}-${counterEnd} of ${total} results for`
        : 'No results found for';

    return <Header as="h5" content={`${results} ${searchConditions}`} />;
  };

  renderReviewsTable = () => {
    const {
      reviews,
      total,
      limit,
      page,
      loading,
      sortName,
      sortOrder,
    } = this.state;

    const dateFormatter = (cell, row) => {
      return AmsDateFormatters.formatDate(cell);
    };

    const linkId = (cell, row) => {
      if ((row && row.newFAFlowFlag) || (row && row.reviewType === 'RAN')) {
        let easReview = row.reviewType === 'FA1-R' ? '/new' : '';
        let fiscalYear = row.fiscalYear
          ? `fy` + row.fiscalYear.toString().slice(-2)
          : 'fy19';
        return (
          <Link
            to={{
              pathname: `/review${easReview}/${fiscalYear}/${cell}`,
              state: { reviewType: `${row.reviewType}` },
            }}
          >
            {cell}
          </Link>
        );
      }
      return (
        <Link
          to={{
            pathname: `/review/${cell}`,
            state: { reviewType: `${row.reviewType}` },
          }}
        >
          {cell}
        </Link>
      );
    };

    const onPageChange = (page, sizePerPage) => {
      this.setState({ page, limit: sizePerPage }, () => this.getData());
    };

    const onSortChange = (sortName, sortOrder) => {
      this.setState({ sortName, sortOrder }, () => this.getData());
    };

    const renderPaginationShowsTotal = (start, to, total) => {
      return (
        <div className="pagination-total">
          Showing rows {start} to {to} of {total}
        </div>
      );
    };

    const options = {
      sizePerPage: limit,
      onPageChange: onPageChange,
      onSortChange: onSortChange,
      sortName: sortName,
      sortOrder: sortOrder,
      page: page,
      paginationShowsTotal: renderPaginationShowsTotal,
      hidePageListOnlyOnePage: true,
    };

    return loading ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <BootstrapTable
        trClassName="clickable"
        data={reviews}
        pagination
        remote
        options={options}
        fetchInfo={{ dataTotalSize: total }}
        tableContainerClass="table-container"
        headerContainerClass="table-header"
        hover
        condensed
        bordered={false}
      >
        <TableHeaderColumn
          dataField="reviewId"
          dataFormat={linkId}
          isKey={true}
          dataSort={true}
        >
          {' '}
          Review ID
        </TableHeaderColumn>
        <TableHeaderColumn dataField="granteeId" dataSort={true}>
          Grantee ID
        </TableHeaderColumn>
        <TableHeaderColumn dataField="granteeName" dataSort={true}>
          Grantee Name
        </TableHeaderColumn>
        <TableHeaderColumn dataField="numberOfGrants" dataSort={true}>
          Number of Grants
        </TableHeaderColumn>
        <TableHeaderColumn dataField="reviewType" dataSort={true}>
          Review Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="dueDate"
          dataFormat={dateFormatter}
          dataSort={true}
        >
          Due Date
        </TableHeaderColumn>
        <TableHeaderColumn dataField="outCome" dataSort={true}>
          Outcome
        </TableHeaderColumn>
        <TableHeaderColumn dataField="workFlowStatus" dataSort={true}>
          Review Status
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  renderReviewsTableNextOld = () => {
    const {
      reviews,
      total,
      limit,
      page,
      loading,
      sortName,
      sortOrder,
    } = this.state;

    const dateFormatter = (cell, row) => {
      return AmsDateFormatters.formatDate(cell) || 'N/A';
    };

    const linkId = (cell, row) => {
      if ((row && row.newFAFlowFlag) || (row && row.reviewType === 'RAN')) {
        let easReview = row.reviewType === 'FA1-R' ? '/new' : '';
        let fiscalYear = row.fiscalYear
          ? `fy` + row.fiscalYear.toString().slice(-2)
          : 'fy19';
        return (
          <Link
            to={{
              pathname: `/review${easReview}/${fiscalYear}/${cell}`,
              state: { reviewType: `${row.reviewType}` },
            }}
          >
            {cell}
          </Link>
        );
      }
      return (
        <Link
          to={{
            pathname: `/review/${cell}`,
            state: { reviewType: `${row.reviewType}` },
          }}
        >
          {cell}
        </Link>
      );
    };

    const onPageChange = (page, sizePerPage) =>
      this.setState({ page, limit: sizePerPage }, () => this.getData());

    const onSortChange = (sortName, sortOrder) => {
      let sort;
      if (this.state.sortName !== sortName) {
        sort = 'desc';
      } else {
        sort = this.state.sortOrder === 'desc' ? 'asc' : 'desc';
      }
      this.setState({ sortName, sortOrder: sort }, () => this.getData());
    };

    const renderPaginationShowsTotal = (start, to, total) => (
      <div className="pagination-total">
        Showing rows {start} to {to} of {total}
      </div>
    );

    const handleTableChange = (type, data) => {
      const { sortField, sortOrder, sizePerPage, page } = data;
      if (type === 'sort' && sortField && sortOrder)
        onSortChange(sortField, sortOrder);
      else if (type === 'pagination' && (sizePerPage, page)) {
        onPageChange(page, sizePerPage);
      }
    };

    // const options = {
    //   sizePerPage: limit,
    //   onPageChange: onPageChange,
    //   onSortChange: onSortChange,
    //   sortName: sortName,
    //   sortOrder: sortOrder,
    //   page: page,
    //   paginationShowsTotal: renderPaginationShowsTotal,
    //   totalSize: total,
    //   hidePageListOnlyOnePage: true,
    // };

    const paginationOptions = {
      page,
      sizePerPage: limit,
      totalSize: total,
      sortOrder: sortOrder,
      onPageChange: onPageChange,
    };

    const columns = [
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      {
        dataField: 'granteeId',
        text: 'Grantee ID',
        sort: true,
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
      },
      {
        dataField: 'numberOfGrants',
        text: 'Number of Grants',
        sort: true,
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'dueDate',
        text: 'Due Date',
        formatter: dateFormatter,
        sort: true,
      },
      {
        dataField: 'outCome',
        text: 'Outcome',
        sort: true,
      },
      {
        dataField: 'workFlowStatus',
        text: 'Review Status',
        sort: true,
      },
    ];

    return loading ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <BootstrapTableNext
        striped
        hover
        keyField="reviewId"
        data={reviews}
        columns={columns}
        bordered={false}
        remote={{ pagination: true, sort: true }}
        pagination={paginationFactory(paginationOptions)}
        onTableChange={handleTableChange}
      />
    );
  };

  renderReviewsResult = () => {
    const {
      reviewsList: { data, page, total },
    } = this.props;

    const dateFormatter = (cell, row) => {
      return cell ? AmsDateFormatters.formatDate(cell) : 'N/A';
    };

    const linkId = (cell, row) => {
      if (
        (row && row.newFAFlowFlag) ||
        (row && row.reviewType === 'RAN') ||
        (row && row.reviewType === 'Follow-up')
      ) {
        let easReview = row.reviewType === 'FA1-R' ? '/new' : '';
        let fiscalYear = row.fiscalYear
          ? `fy` + row.fiscalYear.toString().slice(-2)
          : 'fy19';

        return (
          <Link
            to={{
              pathname: `/review${easReview}/${fiscalYear}/${cell}`,
              state: { reviewType: `${row.reviewType}` },
            }}
          >
            {cell}
          </Link>
        );
      }
      return (
        <Link
          to={{
            pathname: `/review/${cell}`,
            state: { reviewType: `${row.reviewType}` },
          }}
        >
          {cell}
        </Link>
      );
    };

    const scrollToRef = () => {
      window.scrollTo(0, this.amsTableRef.current.offsetTop);
      this.amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onPageChange = (page, sizePerPage) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === this.state.sortName &&
        sortOrder === this.state.sortOrder
      )
        return null;
      this.setState(
        {
          sortName: sortField || this.state.sortName,
          sortOrder: sortOrder || this.state.sortOrder,
        },
        () => this.getData()
      );
    };

    const columns = [
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      {
        dataField: 'granteeId',
        text: 'Grantee ID',
        sort: true,
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
      },
      {
        dataField: 'numberOfGrants',
        text: 'Number of Grants',
        sort: true,
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'dueDate',
        text: 'Due Date',
        formatter: dateFormatter,
        sort: true,
      },
      {
        dataField: 'outCome',
        text: 'Outcome',
        sort: true,
      },
      {
        dataField: 'workFlowStatus',
        text: 'Review Status',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: this.state.sortName,
        order: this.state.sortOrder,
      },
    ];

    return (
      <div ref={this.amsTableRef} tabIndex="-1">
        <AmsTable
          defaultSorted={defaultSorted}
          remote
          data={data}
          page={page}
          total={total}
          limit={this.state.limit}
          loading={this.state.loading}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderReviewsResult()}
        {/* {this.renderReviewsTableNextOld()} */}

        {/* {this.renderReviewsTableNext()} */}
        {/* {this.renderReviewsTable()} */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  reviews: state.review.reviews,
  reviewList: state.review.reviewList,
  reviewsList: state.review.reviewsList,
  total: state.review.total,
});

export default connect(mapStateToProps, { fetchReviews })(ReviewItems);
