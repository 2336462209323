import {
  USERS_FETCHED,
  USERS_INTERNAL_FETCHED,
  USERS_INTERNAL_TEAM_FETCHED,
  REGIONAL_USERS_FETCHED,
  FOM_USERS_FETCHED,
  REPORT_COMMENT_USERS,
} from '../redux/types';

const initialState = {
  selectedUser: {},
  users: [],
  internalUsers: [],
  regionalUsers: null,
  fomUsers: [],
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case USERS_FETCHED: {
      return { ...state, users: action.users };
    }

    case USERS_INTERNAL_FETCHED: {
      return { ...state, internalUsers: action.internalUsers };
    }

    case USERS_INTERNAL_TEAM_FETCHED: {
      return { ...state, internalTeamUsers: action.internalTeamUsers };
    }

    case REGIONAL_USERS_FETCHED: {
      return { ...state, regionalUsers: action.payload };
    }

    case FOM_USERS_FETCHED: {
      return { ...state, fomUsers: action.fomUsers };
    }

    case REPORT_COMMENT_USERS: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
}
