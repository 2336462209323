import React from 'react';

function DefinitionsListField({ schema }) {
  const renderList = () => {
    if (schema && schema.enum && schema.enum.length > 0) {
      return schema.enum.map(e => {
        if (e === 'Definitions:') {
          return (
            <li style={no_bullets}>
              <b>{e}</b>
            </li>
          );
        } else {
          const [label, value] = e.split(':');
          return (
            <li>
              <b>
                <u>{label}</u>
              </b>
              :{value}
            </li>
          );
        }
      });
    }
  };
  return <ul>{renderList()}</ul>;
}

const no_bullets = {
  listStyleType: 'none',
};

export default DefinitionsListField;
