import { chunk, filter, find, isEmpty, slice, take } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Label,
  Message,
  Modal,
  Radio,
  TextArea,
} from 'semantic-ui-react';

// FullCalendar new react module
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';

// Import utils.
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsModal from '../../utils/AmsModal';

// Import actions
import {
  fetchGranteeHash,
  saveGranteeUnavailability,
} from '../../actions/granteeActions';

import CurrentSelectedDates from './CurrentSelectedDates';
import {
  areTwoWeeksAvailable,
  validateGranteeDetails,
} from './GranteeAvailabilityValidator';

const GranteeAvailabiltiyCalendarForm = ({
  selectedGrantee,
  fiscalYear,
  granteeChanging,
  lastEditedGrantee,
  savePreviouseGrantee,
  resetGranteeChanging,
  changeTabEdited,
  saveMultiGrantDetails,
  resetMultiGranteeDetails,
}) => {
  const { invitationId, isMultiGrant, linkExpiration } = useSelector(
    state => state.granteeDetails
  );
  const { granteeDetails } = useSelector(state => state);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [showMonthModal, setShowMonthModal] = useState(false);
  const [monthUnavailabilityType, setMonthUnavailabilityType] = useState(
    'unavailableDates'
  );
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [notInSessionDates, setNotInSessionDates] = useState([]);
  const [springBreakDates, setSpringBreakDates] = useState([]);
  const [dialogContent, setDialogContent] = useState({});
  const [updatedDates, setUpdatedDates] = useState({});
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState({});
  const [hsStartDate, setHSStartDate] = useState(''); // Head Start - hs
  const [hsEndDate, setHSEndDate] = useState('');
  const [ehsStartDate, setEHSStartDate] = useState(''); // Early Head Start - ehs
  const [ehsEndDate, setEHSEndDate] = useState('');
  const [comments, setComments] = useState('');
  const [errors, setErrors] = useState({});
  const [calendarEditable, setCalendarEditable] = useState(false);
  const [edited, setEdited] = useState(false);
  const [copying, setCopying] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const calendarComponentRef = useRef();

  useEffect(() => {
    updateCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedMonth,
    unavailableDates,
    notInSessionDates,
    springBreakDates,
    updatedDates.unavailableDates,
    updatedDates.notInSessionDates,
    updatedDates.springBreakDates,
  ]);

  useEffect(() => {
    const loadGranteeDetails = () => {
      if (fiscalYear && granteeDetails) {
        const grantee = !granteeDetails.isMultiGrant
          ? granteeDetails[fiscalYear].grantInfoList &&
            granteeDetails[fiscalYear].grantInfoList.length === 1 &&
            granteeDetails[fiscalYear].grantInfoList[0]
          : find(
              granteeDetails[fiscalYear].grantInfoList,
              grantee => grantee.granteeId === selectedGrantee.granteeId
            );
        if (grantee) {
          setEdited(false);
          changeTabEdited(false);
          loadDefault();
        } else {
          setEdited(false);
          changeTabEdited(false);
          setUnavailableDates([]);
          setNotInSessionDates([]);
          setSpringBreakDates([]);
          setSelectedMonths({});
          setHSStartDate('');
          setHSEndDate('');
          setEHSStartDate('');
          setEHSEndDate('');
          setComments('');
          if (
            AmsDateFormatters.getMoment(linkExpiration).endOf('day') >=
            AmsDateFormatters.getMoment().endOf('day')
          )
            setCalendarEditable(true);
        }
      }
      resetGranteeChanging(false);
    };
    loadGranteeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeDetails, fiscalYear, selectedGrantee.granteeId]);

  useEffect(() => {
    if (granteeChanging && edited) {
      setDialogContent({
        type: 'warning',
        showCancelButton: true,
        showConfirm: true,
        confirmButtonText: 'Save and continue',
        cancelButtonText: 'Continue without saving',
        title: 'Continue to selected grantee',
        text:
          'You have selected another grantee. The current changes you have made have not been saved. Do you want to continue to your selected grantee?',
        onConfirm: () => {
          let data = lastEditedGrantee;
          saveWhenMoving(data);
          setEdited(false);
          changeTabEdited(false);
          setDialogContent({});
          setShowDialogModal(false);
          resetGranteeChanging(false);
        },
        onCancel: () => {
          setEdited(false);
          changeTabEdited(false);
          setDialogContent({});
          setShowDialogModal(false);
          savePreviouseGrantee({});
          resetGranteeChanging(false);
          if (granteeDetails.isMultiGrant) resetMultiGranteeDetails();
        },
      });
      setShowDialogModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeChanging]);

  useEffect(() => {
    if (edited) storeCurrentGranteeChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedMonths,
    ehsStartDate,
    ehsEndDate,
    hsStartDate,
    hsEndDate,
    comments,
    copying,
  ]);

  const loadDefault = () => {
    const grantee = !granteeDetails.isMultiGrant
      ? granteeDetails[fiscalYear].grantInfoList &&
        granteeDetails[fiscalYear].grantInfoList.length === 1 &&
        granteeDetails[fiscalYear].grantInfoList[0]
      : find(
          granteeDetails[fiscalYear].grantInfoList,
          grantee => grantee.granteeId === selectedGrantee.granteeId
        );
    if (grantee) {
      if (!isEmpty(grantee.unavailableDates))
        setUnavailableDates([...grantee.unavailableDates]);
      else setUnavailableDates([]);
      if (!isEmpty(grantee.notInSessionDates))
        setNotInSessionDates([...grantee.notInSessionDates]);
      else setNotInSessionDates([]);
      if (!isEmpty(grantee.springBreakDates))
        setSpringBreakDates([...grantee.springBreakDates]);
      else setSpringBreakDates([]);
      if (!isEmpty(grantee.selectedMonths))
        setSelectedMonths(grantee.selectedMonths);
      else setSelectedMonths({});
      if (grantee.hsStartDate) setHSStartDate(grantee.hsStartDate);
      else setHSStartDate('');
      if (grantee.hsEndDate) setHSEndDate(grantee.hsEndDate);
      else setHSEndDate('');
      if (grantee.ehsStartDate) setEHSStartDate(grantee.ehsStartDate);
      else setEHSStartDate('');
      if (grantee.ehsEndDate) setEHSEndDate(grantee.ehsEndDate);
      else setEHSEndDate('');
      if (grantee.comments) setComments(grantee.comments);
      else setComments('');
      if (
        AmsDateFormatters.getMoment(linkExpiration).endOf('day') >=
        AmsDateFormatters.getMoment().endOf('day')
      )
        setCalendarEditable(grantee.isEditable);
      setSubmitted(grantee.finalUpdate);
      setErrors({});
    }
  };

  const storeCurrentGranteeChanges = () => {
    let details = {
      agencyId: granteeDetails.agencyId,
      fiscalYear: fiscalYear,
      finalUpdate: false,
      //Added for backend purposes
      isMultiGrant: isMultiGrant,
      unavailableDates,
      notInSessionDates,
      springBreakDates,
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      comments,
      selectedMonths,
      granteeType: selectedGrantee.granteeType,
    };
    if (!copying) {
      details.granteeName = selectedGrantee.granteeName;
      details.granteeId = selectedGrantee.granteeId;
    }
    savePreviouseGrantee(details);
    if (isMultiGrant) {
      saveMultiGrantDetails({
        ...details,
        granteeName: selectedGrantee.granteeName,
        granteeId: selectedGrantee.granteeId,
      });
    }
    setCopying(false);
  };

  const handleMonthSelection = (e, value) => {
    e.preventDefault();
    setSelectedMonth(value);
    setShowMonthModal(true);
  };

  const handleUnavailabilityTypeChange = (e, { value }) => {
    setMonthUnavailabilityType(value);
  };

  const handleMonthSubmit = () => {
    if (
      areTwoWeeksAvailable(
        selectedMonth,
        [...(updatedDates.unavailableDates || []), ...unavailableDates],
        [...(updatedDates.notInSessionDates || []), ...notInSessionDates],
        [...(updatedDates.springBreakDates || []), ...springBreakDates],
        granteeDetails[fiscalYear].fedHolidays
      )
    ) {
      setSelectedMonths({ ...selectedMonths, [selectedMonth]: true });
      setEdited(true);
      changeTabEdited(true);
      setUnavailableDates([
        ...(updatedDates.unavailableDates || []),
        ...unavailableDates,
      ]);
      setNotInSessionDates([
        ...(updatedDates['notInSessionDates'] || []),
        ...notInSessionDates,
      ]);
      setSpringBreakDates([
        ...(updatedDates.springBreakDates || []),
        ...springBreakDates,
      ]);
      setShowMonthModal(false);
      setSelectedMonth('');
      setUpdatedDates({});
    } else {
      setDialogContent({
        type: 'warning',
        showCancelButton: false,
        showConfirm: true,
        confirmButtonText: 'Ok',
        title: 'You have selected too many days to be unavailable',
        text:
          'At least 4 days in a week and two weeks in a month should be available.',
        onConfirm: () => {
          setShowDialogModal(false);
          setDialogContent({});
        },
      });
      setShowDialogModal(true);
    }
  };

  const dialogModal = () => {
    return (
      <AmsAlert
        show={showDialogModal}
        {...dialogContent}
        confirmButtonColor={'#DD6B55'}
      />
    );
  };

  // const dialogModal = () => {
  //   return (
  //     <Modal open={showDialogModal} className="ams-semantic-modal-fix">
  //       <Header icon="warning" content={dialogContent.title} />
  //       <Modal.Content>{dialogContent.text}</Modal.Content>
  //       <Modal.Actions>
  //         {dialogContent.showCancelButton && (
  //           <Button color="red" onClick={dialogContent.onCancel}>
  //             {' '}
  //             {dialogContent.cancelButtonText}
  //           </Button>
  //         )}
  //         <Button primary onClick={dialogContent.onConfirm}>
  //           {dialogContent.confirmButtonText}
  //         </Button>
  //       </Modal.Actions>
  //     </Modal>
  //   );
  // };

  const updateCalendar = () => {
    let unavailableEvents = updateIcons(
      [...(updatedDates.unavailableDates || []), ...(unavailableDates || [])],
      'Unavailable',
      '#323a45'
    );
    let sessionEvents = updateIcons(
      [
        ...(updatedDates['notInSessionDates'] || []),
        ...(notInSessionDates || []),
      ],
      'Not in session',
      '#205493'
    );
    let breakEvents = updateIcons(
      [...(updatedDates.springBreakDates || []), ...(springBreakDates || [])],
      'Spring Break',
      '#2e8540'
    );
    let holidays = updateIcons(
      granteeDetails[fiscalYear].fedHolidays,
      '',
      '#4c2c92'
    );
    setCalendarEvents([
      ...breakEvents,
      ...holidays,
      ...sessionEvents,
      ...unavailableEvents,
    ]);
  };

  const updateIcons = (dates, eventType, color) => {
    let returnDays = [];
    dates &&
      // eslint-disable-next-line array-callback-return
      dates.map(day => {
        if (eventType !== '') {
          returnDays.push({
            start: AmsDateFormatters.formatGranteeAvailabilityDates(day).format(
              'YYYY-MM-DD'
            ),
            title: eventType,
            color: color,
            allDay: true,
          });
        } else {
          //holidays
          returnDays.push({
            start: day.date,
            title: day.description,
            color: color,
            allDay: true,
          });
        }
      });
    return returnDays;
  };

  const handleDayClick = data => {
    if (!calendarEditable) return;
    let utcDate = AmsDateFormatters.formatGranteeAvailabilityDates(
      data.date.toUTCString()
    ).format();
    let tempUpdatedDates = [];
    if (!doesEventExistOnThisDay(data.date)) {
      switch (monthUnavailabilityType) {
        case 'unavailableDates':
          tempUpdatedDates = [
            ...(updatedDates[monthUnavailabilityType] || []),
            utcDate,
          ];
          setUpdatedDates({
            ...updatedDates,
            [monthUnavailabilityType]: tempUpdatedDates,
          });
          return;
        case 'notInSessionDates':
          tempUpdatedDates = [
            ...(updatedDates[monthUnavailabilityType] || []),
            utcDate,
          ];
          setUpdatedDates({
            ...updatedDates,
            [monthUnavailabilityType]: [...tempUpdatedDates],
          });
          return;
        case 'springBreakDates':
          tempUpdatedDates = [
            ...(updatedDates[monthUnavailabilityType] || []),
            utcDate,
          ];
          setUpdatedDates({
            ...updatedDates,
            [monthUnavailabilityType]: [...tempUpdatedDates],
          });
          return;
        default:
          return;
      }
    }
  };

  const doesEventExistOnThisDay = date => {
    let d = AmsDateFormatters.formatGranteeAvailabilityDates(date);
    let day = d.format('dddd');
    if (day === 'Sunday' || day === 'Saturday') return true;
    if (
      !![...(updatedDates.unavailableDates || []), ...unavailableDates].find(
        item => {
          return (
            AmsDateFormatters.getMoment(item).format('YYYY/MM/DD') ===
            d.format('YYYY/MM/DD')
          );
        }
      ) ||
      !![...(updatedDates.notInSessionDates || []), ...notInSessionDates].find(
        item => {
          return (
            AmsDateFormatters.getMoment(item).format('YYYY/MM/DD') ===
            d.format('YYYY/MM/DD')
          );
        }
      ) ||
      !![...(updatedDates.springBreakDates || []), ...springBreakDates].find(
        item => {
          return (
            AmsDateFormatters.getMoment(item).format('YYYY/MM/DD') ===
            d.format('YYYY/MM/DD')
          );
        }
      )
    )
      return true;
    return false;
  };

  const eventClick = data => {
    if (!calendarEditable) return;
    let eventDate = AmsDateFormatters.formatGranteeAvailabilityDates(
      data.event.start
    ).format('YYYY/MM/DD');
    let tempupdatedDates = [];
    let tempsavedDates = [];
    switch (data.event.title) {
      case 'Unavailable':
        tempupdatedDates = filter(
          [...(updatedDates.unavailableDates || [])],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        tempsavedDates = filter(
          [...unavailableDates],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        setUpdatedDates({
          ...updatedDates,
          unavailableDates: tempupdatedDates,
        });
        setUnavailableDates(tempsavedDates);
        break;
      case 'Not in session':
        tempupdatedDates = filter(
          [...(updatedDates.notInSessionDates || [])],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        tempsavedDates = filter(
          [...notInSessionDates],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        setUpdatedDates({
          ...updatedDates,
          notInSessionDates: tempupdatedDates,
        });
        setNotInSessionDates(tempsavedDates);
        break;
      case 'Spring Break':
        tempupdatedDates = filter(
          [...(updatedDates.springBreakDates || [])],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        tempsavedDates = filter(
          [...springBreakDates],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        setUpdatedDates({
          ...updatedDates,
          springBreakDates: tempupdatedDates,
        });
        setSpringBreakDates(tempsavedDates);
        break;
      default:
        break;
    }
  };

  const monthModal = () => {
    let selectedMonthYear = AmsDateFormatters.formatGranteeAvailabilityDates();
    if (
      selectedMonth === 'October' ||
      selectedMonth === 'November' ||
      selectedMonth === 'December'
    ) {
      selectedMonthYear = AmsDateFormatters.formatGranteeAvailabilityDates(
        selectedMonth + (fiscalYear - 1)
      );
    } else {
      selectedMonthYear = AmsDateFormatters.formatGranteeAvailabilityDates(
        selectedMonth + fiscalYear
      );
    }
    return (
      <AmsModal
        size="small"
        closeOnDimmerClick={false}
        onClose={() => {
          setUpdatedDates({});
          setMonthUnavailabilityType('unavailableDates');
          setShowMonthModal(false);
          setSelectedMonth('');
        }}
        open={showMonthModal}
        className="ams-semantic-modal-fix"
        closeIcon
      >
        <Header>
          Select Unavailabilty Dates for {selectedMonth}{' '}
          {selectedMonth === 'October' ||
          selectedMonth === 'November' ||
          selectedMonth === 'December'
            ? fiscalYear - 1
            : fiscalYear}
        </Header>
        <Modal.Content>
          <Modal.Description>
            <Header as="h5">Weekends will be blocked.</Header>
            <Form>
              <Form.Group inline>
                <Form.Field width={6}>
                  <Radio
                    label="Grant Recipient Unavailable"
                    name="selectedStateGroup"
                    value="unavailableDates"
                    className="radio-toolbar width-adjustment"
                    checked={monthUnavailabilityType === 'unavailableDates'}
                    onChange={handleUnavailabilityTypeChange}
                  />
                </Form.Field>
                <Form.Field width={6}>
                  <Radio
                    label="Children not in Session"
                    name="selectedStateGroup"
                    value="notInSessionDates"
                    className="radio-toolbar width-adjustment"
                    checked={monthUnavailabilityType === 'notInSessionDates'}
                    onChange={handleUnavailabilityTypeChange}
                  />
                </Form.Field>
                <Form.Field width={5}>
                  <Radio
                    label="Spring Break"
                    name="selectedStateGroup"
                    value="springBreakDates"
                    className="radio-toolbar width-adjustment"
                    checked={monthUnavailabilityType === 'springBreakDates'}
                    onChange={handleUnavailabilityTypeChange}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <FullCalendar
              timeZone={'UTC'}
              header={{
                left: '',
                center: 'title',
                right: '',
              }}
              defaultView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              dateClick={handleDayClick}
              events={calendarEvents}
              defaultDate={selectedMonthYear.format()}
              weekends={true}
              ref={calendarComponentRef}
              showNonCurrentDates={false}
              eventClick={eventClick}
            />
            <br />
            <Form.Group>
              <Form.Field>
                <Header as="h4">Key</Header>
                <Form.Group inline>
                  <Label
                    className="grantee-calendar-available-color"
                    horizontal
                  >
                    Available
                  </Label>
                  <Label
                    className="grantee-calendar-unavailable-color"
                    horizontal
                  >
                    Unavailable
                  </Label>
                  <Label className="grantee-calendar-session-color" horizontal>
                    Not In Session
                  </Label>
                  <Label className="grantee-calendar-break-color" horizontal>
                    Spring Break
                  </Label>
                  <Label className="grantee-calendar-holiday-color" horizontal>
                    Holiday
                  </Label>
                </Form.Group>
              </Form.Field>
            </Form.Group>
          </Modal.Description>
        </Modal.Content>
        {calendarEditable ? (
          <Modal.Actions>
            <Button
              // disabled={finalUpdate}
              color="blue"
              onClick={handleMonthSubmit}
            >
              Done For {selectedMonth}
            </Button>
            <Button
              color="red"
              onClick={() => {
                setShowMonthModal(false);
                setUpdatedDates({});
                setMonthUnavailabilityType('unavailableDates');
                setSelectedMonth('');
              }}
            >
              Cancel
            </Button>
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                setShowMonthModal(false);
                setUpdatedDates({});
                setMonthUnavailabilityType('unavailableDates');
                setSelectedMonth('');
              }}
            >
              Close
            </Button>
          </Modal.Actions>
        )}
      </AmsModal>
    );
  };

  const buildMonthList = () => {
    const orderedMonths = AmsDateFormatters.getMonthsList();
    const slicedMonths = slice(orderedMonths, 9, 13);
    const restOfMonths = take(orderedMonths, 9);
    const fiscalOrderedMonths = [...slicedMonths, ...restOfMonths];
    return chunk(fiscalOrderedMonths, 4).map((months, index) => {
      return (
        <Form.Group widths="equal" key={index}>
          {months.map((month, index) => {
            return (
              <Form.Field width={3} key={index}>
                <Form.Button
                  basic
                  style={{
                    textAlign: 'left',
                    fontSize: 'inherit',
                    width: '60%',
                  }}
                  onClick={e => handleMonthSelection(e, month)}
                  size="large"
                >
                  {selectedMonths[month] && (
                    <Icon
                      name="check circle outline"
                      color="green"
                      size="large"
                    ></Icon>
                  )}
                  {month}
                </Form.Button>
              </Form.Field>
            );
          })}
        </Form.Group>
      );
    });
  };

  const buildDateInputs = () => {
    const handleRawChange = e => {
      e.preventDefault();
    };
    const hsDates = () => (
      <Form.Group widths="equal">
        <Form.Field
          autoComplete="false"
          noValidate
          error={!isEmpty(errors.hsStartDate)}
          required
          name="hsStartDate"
          control={DatePicker}
          label={'HS: School Year Start Date'}
          placeholderText="Enter Date in valid Format: MM/DD/YYYY"
          value={hsStartDate ? AmsDateFormatters.formatDate(hsStartDate) : null}
          onChange={date => {
            if (!calendarEditable) return;
            setHSStartDate(AmsDateFormatters.formatDate(date));
            setEdited(true);
            changeTabEdited(true);
          }}
          onChangeRaw={handleRawChange}
        />
        <Form.Field
          autoComplete="false"
          error={!!errors.hsEndDate}
          noValidate
          required
          name="hsEndDate"
          control={DatePicker}
          label={'HS: School Year End Date'}
          placeholderText="Enter Date in valid Format: MM/DD/YYYY"
          disabled={!hsStartDate}
          value={hsEndDate ? AmsDateFormatters.formatDate(hsEndDate) : null}
          minDate={AmsDateFormatters.getMoment(hsStartDate)}
          onChange={date => {
            if (!calendarEditable) return;
            setEdited(true);
            changeTabEdited(true);
            setHSEndDate(AmsDateFormatters.formatDate(date));
          }}
          onChangeRaw={handleRawChange}
        />
      </Form.Group>
    );
    const ehsDates = () => (
      <Form.Group widths="equal">
        <Form.Field
          autoComplete="false"
          noValidate
          error={!!errors.ehsStartDate}
          required
          name="ehsStartDate"
          control={DatePicker}
          label={'EHS: School Year Start Date'}
          placeholderText="Enter Date in valid Format: MM/DD/YYYY"
          value={
            ehsStartDate ? AmsDateFormatters.formatDate(ehsStartDate) : null
          }
          onChange={date => {
            if (!calendarEditable) return;
            setEdited(true);
            changeTabEdited(true);
            setEHSStartDate(AmsDateFormatters.formatDate(date));
          }}
          onChangeRaw={handleRawChange}
        />
        <Form.Field
          autoComplete="false"
          noValidate
          error={!!errors.ehsEndDate}
          required
          name="ehsEndDate"
          control={DatePicker}
          label={'EHS: School Year End Date'}
          placeholderText="Enter Date in valid Format: MM/DD/YYYY"
          disabled={!ehsStartDate}
          value={ehsEndDate ? AmsDateFormatters.formatDate(ehsEndDate) : null}
          minDate={AmsDateFormatters.getMoment(ehsStartDate)}
          // endDate={hsEndDate}
          onChange={date => {
            if (!calendarEditable) return;
            setEdited(true);
            changeTabEdited(true);
            setEHSEndDate(
              AmsDateFormatters.getMoment(date).format('MM/DD/YYYY')
            );
          }}
          onChangeRaw={handleRawChange}
        />
      </Form.Group>
    );
    switch (selectedGrantee.granteeType) {
      case 'Head Start':
        return hsDates();
      case 'Early Head Start':
        return ehsDates();
      case 'Head Start and Early Head Start':
        return (
          <>
            {hsDates()}
            {ehsDates()}
          </>
        );
      default:
        break;
    }
  };

  const handleGranteeSubmit = e => {
    e.preventDefault();
    const errors = validateGranteeDetails(
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      selectedMonths,
      selectedGrantee.granteeType
    );
    setErrors(errors);
    if (isEmpty(errors)) {
      setDialogContent({
        type: 'warning',
        showCancelButton: true,
        showConfirm: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        title: 'Submit Grantee Unavailability',
        text:
          'You will not be able to edit Grantee Unavailability after submitting your final changes. Are you sure you want to submit your final changes?',
        onConfirm: () => {
          handleGranteeSave(true);
          setShowDialogModal(false);
          setDialogContent({});
        },
        onCancel: () => {
          setShowDialogModal(false);
          setDialogContent({});
        },
      });
      setShowDialogModal(true);
    }
  };

  const saveWhenMoving = payload => {
    let data = [];
    data.push({ ...payload });
    if (!isEmpty(payload))
      dispatch(
        saveGranteeUnavailability(
          { grantees: data, invitationId },
          response => {
            if (response.status === 200) {
              let hash = window.location.href.split('hash=')[1];
              setUpdatedDates({});
              setDialogContent({
                type: 'success',
                showCancelButton: false,
                showConfirm: true,
                confirmButtonText: 'Ok',
                title: 'Progress Saved',
                text: response.data.success,
                onConfirm: () => {
                  setShowDialogModal(false);
                  setDialogContent({});
                },
              });
              setShowDialogModal(true);
              dispatch(fetchGranteeHash({ hash }));
            } else {
              setDialogContent({
                type: 'error',
                showCancelButton: false,
                showConfirm: true,
                confirmButtonText: 'Ok',
                title: 'Something went wrong',
                text: response.response.data.message,
                onConfirm: () => {
                  setShowDialogModal(false);
                  setDialogContent({});
                },
              });
              setShowDialogModal(true);
            }
          }
        )
      );
  };

  const handleGranteeSave = finalized => {
    setErrors({});
    let granteePayload = {
      agencyId: granteeDetails.agencyId,
      granteeId: selectedGrantee.granteeId,
      fiscalYear: fiscalYear,
      finalUpdate: finalized,
      granteeName: selectedGrantee.granteeName,
      //Added for backend purposes
      isMultiGrant: isMultiGrant,
      unavailableDates,
      notInSessionDates,
      springBreakDates,
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      comments,
      selectedMonths,
      granteeType: selectedGrantee.granteeType,
    };
    //Added because backend wants an array of data returned instead now.
    let data = [];
    data.push(granteePayload);
    dispatch(
      saveGranteeUnavailability({ grantees: data, invitationId }, response => {
        if (response.status === 200) {
          let hash = window.location.href.split('hash=')[1];
          setUpdatedDates({});
          setDialogContent({
            type: 'success',
            showCancelButton: false,
            showConfirm: true,
            confirmButtonText: 'Ok',
            title: finalized ? 'Grantee Submitted' : 'Progress Saved',
            text: response.data.success,
            onConfirm: () => {
              setShowDialogModal(false);
              setDialogContent({});
            },
          });
          setShowDialogModal(true);
          dispatch(fetchGranteeHash({ hash }));
        } else {
          setDialogContent({
            type: 'error',
            showCancelButton: false,
            showConfirm: true,
            confirmButtonText: 'Ok',
            title: 'Something went wrong',
            text: response.response.data.message,
            onConfirm: () => {
              setShowDialogModal(false);
              setDialogContent({});
            },
          });
          setShowDialogModal(true);
        }
      })
    );
  };

  const discardChanges = () => {
    setDialogContent({
      type: 'warning',
      showCancelButton: true,
      showConfirm: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      title: 'Discard Changes',
      text:
        'All your unsaved changes will be lost. Are you sure you want to discard your changes?',
      onConfirm: () => {
        loadDefault();
        setShowDialogModal(false);
        setDialogContent({});
        setEdited(false);
        changeTabEdited(false);
        setUpdatedDates({});
        savePreviouseGrantee({});
      },
      onCancel: () => {
        setShowDialogModal(false);
        setDialogContent({});
      },
    });
    setShowDialogModal(true);
  };

  const copyPreviousGranteeDetails = () => {
    setDialogContent({
      type: 'warning',
      showCancelButton: true,
      showConfirm: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      title: 'Copy previous grantee unavailability details',
      text:
        'This will overwrite the current grantee details for this grantee. Are you sure you want to copy the previous grantee unavailability details?',
      onConfirm: () => {
        setEHSEndDate(lastEditedGrantee.ehsEndDate);
        setEHSStartDate(lastEditedGrantee.ehsStartDate);
        setHSStartDate(lastEditedGrantee.hsStartDate);
        setHSEndDate(lastEditedGrantee.hsEndDate);
        setSelectedMonths(lastEditedGrantee.selectedMonths);
        setUnavailableDates(lastEditedGrantee.unavailableDates);
        setNotInSessionDates(lastEditedGrantee.notInSessionDates);
        setSpringBreakDates(lastEditedGrantee.springBreakDates);
        setComments(lastEditedGrantee.comments);
        setShowDialogModal(false);
        setEdited(true);
        changeTabEdited(true);
        setCopying(true);
      },
      onCancel: () => {
        setShowDialogModal(false);
      },
    });
    setShowDialogModal(true);
  };

  return (
    <>
      {monthModal()}
      {dialogModal()}
      <Form
        id="grantee-unavailability-form"
        error={!isEmpty(errors)}
        noValidate
      >
        {errors && (
          <Message
            error
            header="Please correct the following"
            list={Object.keys(errors).map(key => (
              <li key={key}>{errors[key].content}</li>
            ))}
          />
        )}
        {isMultiGrant &&
          !isEmpty(lastEditedGrantee) &&
          selectedGrantee.granteeId !== lastEditedGrantee.granteeId &&
          fiscalYear === granteeDetails.fiscalYear &&
          !submitted && (
            <Form.Button
              // floated="left"
              // width={7}
              size="medium"
              basic
              style={{ textAlign: 'left', fontSize: 'inherit' }}
              // fluid
              color={'blue'}
              onClick={copyPreviousGranteeDetails}
            >
              <Icon name="copy outline" size="large"></Icon>
              Copy Previously Saved Grant Recipient Unavailability
            </Form.Button>
          )}
        <br />
        {buildDateInputs()}
        {buildMonthList()}
        <br />
        <CurrentSelectedDates
          allDates={{ unavailableDates, notInSessionDates, springBreakDates }}
          unavailableDates={unavailableDates}
          notInSessionDates={notInSessionDates}
          springBreakDates={springBreakDates}
        />
        <Divider />
        <Form.Field width={16}>
          <Form.Field
            control={TextArea}
            label="Grant Recipient Comments:"
            placeholder=""
            onChange={e => {
              if (!calendarEditable) return;
              setComments(e.target.value);
              setEdited(true);
              changeTabEdited(true);
            }}
            value={comments}
          />
        </Form.Field>
        {calendarEditable && (
          <Form.Group>
            <Form.Button
              // width={3}
              content={'Save Current Progress'}
              // fluid
              color="blue"
              onClick={() => handleGranteeSave(false)}
            />
            <Form.Button
              // width={3}
              content={'Discard Changes'}
              // fluid
              basic
              disabled={!edited}
              onClick={discardChanges}
            />
            {!isMultiGrant && (
              <Form.Field
                // width={5}
                // floated="right"
                as={Button}
                primary
                style={{ marginLeft: 'auto' }}
                content={'Submit Unavailability'}
                // fluid
                onClick={handleGranteeSubmit}
              />
            )}
          </Form.Group>
        )}
      </Form>
    </>
  );
};

export default GranteeAvailabiltiyCalendarForm;
