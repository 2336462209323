/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Button } from 'semantic-ui-react';

// Style
import './assets/style.css';

const labelColors = [
  'lightbrown',
  'cyan',
  'purple',
  'brown',
  'blue',
  'red',
  'black',
];

class Workflow extends Component {
  state = {
    selectedBubble: this.props.bubble,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.bubble !== this.props.bubble && nextProps.type === 'report') {
      this.setState({
        selectedBubble:
          nextProps.bubble === 'Shipped'
            ? 'Shipped (Signed)'
            : nextProps.bubble,
      });
    }
  }

  renderWorkflow() {
    const { title, type } = this.props;
    const { review } = this.props.workflowStatus;
    const { statusCount } = this.props;

    let reviewStatusData = [];
    let reportStatusData = [];

    if (!_.isEmpty(review)) {
      reviewStatusData = Object.keys(review).map(key => {
        switch (key) {
          case 'totalCount':
            return {
              label: 'Total',
              value: review[key],
              path: '/review?reviewStatus=total',
            };
          case 'notStartedCount':
            return {
              label: 'Not Started',
              value: review[key],
              path: '/review?reviewStatus=not-started',
            };
          case 'dataCollectionInProgresssCount':
            return {
              label: 'Data Collection In Progress',
              value: review[key],
              path: '/review?reviewStatus=data-collection-in-progress',
            };
          case 'dataCollectionCompletedCount':
            return {
              label: 'Data Collection Completed',
              value: review[key],
              path: '/review?reviewStatus=data-collection-completed',
            };
          case 'reportAnalysisCount':
            return {
              label: 'Report Analysis',
              value: review[key],
              path: '/report?reportStatus=report-analysis',
            };
          case 'OHSSignedCount':
            return {
              label: 'OHS Signed',
              value: review[key],
              path: '/report?reportStatus=ohs-signed',
            };
          case 'finalReportDeliveredCount':
            return {
              label: 'Final Report Delivered',
              value: review[key],
              path: '/report?reportStatus=final-report-delevered',
            };
          default:
            return null;
        }
      });
    }

    if (reviewStatusData.length && type === 'review') {
      return (
        <div>
          {title && <h2>{title}</h2>}
          <ul>
            {reviewStatusData.map((data, index) => {
              return (
                <li key={index}>
                  <p>
                    <span className={labelColors[index]}>
                      {data ? data.value : 0}
                    </span>
                  </p>
                  <Link to={data.path}>{data.label}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    // TODO: Build the report status here. Same as above.

    if (!_.isEmpty(statusCount)) {
      const { DLH, RO, OHS, OGC, Shipped, totalReportsCount } = statusCount;
      reportStatusData = Object.keys(statusCount).map(key => {
        switch (key) {
          case 'OGC':
            return { label: 'OGC', order: 4, key: 'OGC', value: OGC };
          case 'OHS':
            return { label: 'OHS', order: 2, key: 'OHS', value: OHS };
          case 'Shipped':
            return {
              label: 'Shipped (Signed)',
              order: 5,
              key: 'Shipped',
              value: Shipped,
            };
          case 'DLH':
            return {
              label: 'DLH',
              order: 1,
              key: 'DLH',
              value: DLH,
            };
          case 'RO':
            return { label: 'RO', order: 3, key: 'RO', value: RO };
          case 'totalReportsCount':
            return {
              label: 'Total Reports Currently in Production: ',
              viewMode: 'row',
              order: 10,
              key: 'totalReportsCount',
              value: totalReportsCount,
            };
          default:
            return null;
        }
      });
    }

    if (reportStatusData.length && type === 'report') {
      return (
        <div>
          {title && <h2>{title}</h2>}
          <ul>
            {reportStatusData
              .sort((a, b) => a.order - b.order)
              .filter(data => {
                return data.viewMode !== 'row';
              })
              .map((data, index) => {
                return (
                  <li key={index} className={'status-color-' + data.key}>
                    <a
                      href=""
                      onClick={this.handleStatusClick.bind(this, data.key)}
                    >
                      <span
                        className={
                          this.state.selectedBubble === data.label
                            ? 'highlighted-bubble ' + labelColors[index]
                            : labelColors[index]
                        }
                      >
                        {data.label}
                        <br />
                        {data ? data.value : 0}
                      </span>
                    </a>
                  </li>
                );
              })}
          </ul>
          {reportStatusData
            .sort((a, b) => a.order - b.order)
            .filter(data => {
              return data.viewMode === 'row';
            })
            .map((data, index) => {
              return (
                <div key={index} className={'status-color-' + data.key}>
                  <strong>{data.label}</strong>

                  <Button
                    primary
                    style={{ marginLeft: '3em' }}
                    onClick={this.handleStatusClick.bind(this, data.key)}
                    content={data ? data.value : 0}
                  />
                </div>
              );
            })}
        </div>
      );
    }

    return 'Loading....';
  }

  handleStatusClick(filter, event) {
    event.preventDefault();
    let bubble;
    if (filter === 'Shipped') {
      bubble = 'Shipped (Signed)';
    } else if (filter === 'DLH') {
      bubble = 'DLH';
    } else {
      bubble = filter;
    }
    this.setState({ selectedBubble: bubble });
    if (this.props.applyFilter) {
      this.props.applyFilter(filter);
    }
  }

  render() {
    return <div className="workflow-list">{this.renderWorkflow()}</div>;
  }
}

Workflow.propTypes = {
  title: PropTypes.string,
  applyFilter: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    workflowStatus: state.workflow.workflowStatus,
    statusCount: state.report.reports.statusCount,
  };
}

export default connect(mapStateToProps, {})(Workflow);
