import { chunk, filter, isEmpty, slice, take } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Message,
  Modal,
  Popup,
  Radio,
  TextArea,
} from 'semantic-ui-react';

// FullCalendar new react module
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';

// Import utilities
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsModal from '../../../utils/AmsModal';

// Import actions
import {
  fetchGranteeAvailability,
  updateGranteeSchedule,
} from '../../../actions/granteeActions';

import CurrentSelectedDates from '../CurrentSelectedDates';
import { areTwoWeeksAvailable } from '../GranteeAvailabilityValidator';

const GranteeDetailCalendarForm = ({
  fiscalYear,
  editMode,
  savePreviouseGrantee,
  resetEditMode,
  changeTabEdited,
  paneTitle,
}) => {
  const { granteeType, granteeId, agencyId, results } = useSelector(
    state => state.granteeDetails
  );
  const { granteeDetails } = useSelector(state => state);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [showMonthModal, setShowMonthModal] = useState(false);
  const [monthUnavailabilityType, setMonthUnavailabilityType] = useState(
    'unavailableDates'
  );
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [notInSessionDates, setNotInSessionDates] = useState([]);
  const [springBreakDates, setSpringBreakDates] = useState([]);
  const [dialogContent, setDialogContent] = useState({});
  const [updatedDates, setUpdatedDates] = useState({});
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState({});
  const [hsStartDate, setHSStartDate] = useState(''); // Head Start - hs
  const [hsEndDate, setHSEndDate] = useState('');
  const [ehsStartDate, setEHSStartDate] = useState(''); // Early Head Start - ehs
  const [ehsEndDate, setEHSEndDate] = useState('');
  const [comments, setComments] = useState('');
  const [schedulerComments, setSchedulerComments] = useState('');
  const [edited, setEdited] = useState(false);
  const [availabilityEdited, setAvailabilityEdited] = useState(false);
  const [updateGrantees, setUpdateGrantees] = useState([]);
  const [showMultiGranteeModal, setShowMultiGranteeModal] = useState(false);
  const dispatch = useDispatch();
  const calendarComponentRef = useRef();

  useEffect(() => {
    updateCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedMonth,
    unavailableDates,
    notInSessionDates,
    springBreakDates,
    updatedDates.unavailableDates,
    updatedDates.notInSessionDates,
    updatedDates.springBreakDates,
  ]);

  useEffect(() => {
    const loadGranteeDetails = () => {
      let grantee = granteeDetails[fiscalYear].granteeInfo;
      if (fiscalYear && granteeDetails) {
        if (grantee) {
          setEdited(false);
          setAvailabilityEdited(false);
          changeTabEdited(false);
          loadDefault();
        } else {
          setEdited(false);
          setAvailabilityEdited(false);
          changeTabEdited(false);
          setUnavailableDates([]);
          setNotInSessionDates([]);
          setSpringBreakDates([]);
          setSelectedMonths({});
          setHSStartDate('');
          setHSEndDate('');
          setEHSStartDate('');
          setEHSEndDate('');
          setComments('');
          setSchedulerComments('');
        }
      }
    };
    loadGranteeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeDetails, fiscalYear, editMode]);

  useEffect(() => {
    if (edited) storeCurrentGranteeChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonths, ehsStartDate, ehsEndDate, hsStartDate, hsEndDate]);

  const loadDefault = () => {
    let grantee = granteeDetails[fiscalYear].granteeInfo;
    if (grantee) {
      if (!isEmpty(grantee.unavailableDates))
        setUnavailableDates([...grantee.unavailableDates]);
      else setUnavailableDates([]);
      if (!isEmpty(grantee.notInSessionDates))
        setNotInSessionDates([...grantee.notInSessionDates]);
      else setNotInSessionDates([]);
      if (!isEmpty(grantee.springBreakDates))
        setSpringBreakDates([...grantee.springBreakDates]);
      else setSpringBreakDates([]);
      if (!isEmpty(grantee.selectedMonths))
        setSelectedMonths(grantee.selectedMonths);
      else setSelectedMonths({});
      if (grantee.hsStartDate) setHSStartDate(grantee.hsStartDate);
      else setHSStartDate('');
      if (grantee.hsEndDate) setHSEndDate(grantee.hsEndDate);
      else setHSEndDate('');
      if (grantee.ehsStartDate) setEHSStartDate(grantee.ehsStartDate);
      else setEHSStartDate('');
      if (grantee.ehsEndDate) setEHSEndDate(grantee.ehsEndDate);
      else setEHSEndDate('');
      if (grantee.comments) setComments(grantee.comments);
      else setComments('');
      if (grantee.schedulerComments)
        setSchedulerComments(grantee.schedulerComments);
      else setSchedulerComments('');
      if (!isEmpty(grantee.grantees)) setUpdateGrantees(grantee.grantees);
      else setUpdateGrantees([]);
    }
  };

  const storeCurrentGranteeChanges = () => {
    let details = {
      fiscalYear: fiscalYear,
      finalUpdate: granteeDetails[fiscalYear].granteeInfo.finalUpdate || false,
      //Added for backend purposes
      unavailableDates,
      notInSessionDates,
      springBreakDates,
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      comments,
      schedulerComments,
      selectedMonths,
      granteeId,
      agencyId,
      sendEmail: true,
      invitationId: granteeDetails[fiscalYear].granteeInfo.invitationId,
    };
    savePreviouseGrantee(details);
  };

  const handleMonthSelection = (e, value) => {
    e.preventDefault();
    setSelectedMonth(value);
    setShowMonthModal(true);
  };

  const handleUnavailabilityTypeChange = (e, { value }) => {
    setMonthUnavailabilityType(value);
  };

  const handleMonthSubmit = () => {
    if (
      areTwoWeeksAvailable(
        selectedMonth,
        [...(updatedDates.unavailableDates || []), ...unavailableDates],
        [...(updatedDates.notInSessionDates || []), ...notInSessionDates],
        [...(updatedDates.springBreakDates || []), ...springBreakDates],
        granteeDetails[fiscalYear].fedHolidays
      )
    ) {
      setSelectedMonths({ ...selectedMonths, [selectedMonth]: true });
      setEdited(true);
      setAvailabilityEdited(true);
      changeTabEdited(true);
      setUnavailableDates([
        ...(updatedDates.unavailableDates || []),
        ...unavailableDates,
      ]);
      setNotInSessionDates([
        ...(updatedDates['notInSessionDates'] || []),
        ...notInSessionDates,
      ]);
      setSpringBreakDates([
        ...(updatedDates.springBreakDates || []),
        ...springBreakDates,
      ]);
      setShowMonthModal(false);
      setSelectedMonth('');
      setUpdatedDates({});
    } else {
      setDialogContent({
        type: 'warning',
        showCancelButton: false,
        showConfirm: true,
        confirmButtonText: 'Ok',
        title: 'You have selected too many days to be unavailable',
        text:
          'At least 4 days in a week and two weeks in a month should be available.',
        onConfirm: () => {
          setShowDialogModal(false);
          setDialogContent({});
        },
      });
      setShowDialogModal(true);
    }
  };

  const dialogModal = () => {
    return (
      <AmsAlert
        show={showDialogModal}
        {...dialogContent}
        confirmButtonColor={'#DD6B55'}
      />
    );
  };

  const updateCalendar = () => {
    let unavailableEvents = updateIcons(
      [...(updatedDates.unavailableDates || []), ...(unavailableDates || [])],
      'Unavailable',
      '#323a45'
    );
    let sessionEvents = updateIcons(
      [
        ...(updatedDates['notInSessionDates'] || []),
        ...(notInSessionDates || []),
      ],
      'Not in session',
      '#205493'
    );
    let breakEvents = updateIcons(
      [...(updatedDates.springBreakDates || []), ...(springBreakDates || [])],
      'Spring Break',
      '#2e8540'
    );
    let holidays = updateIcons(
      granteeDetails[fiscalYear] && granteeDetails[fiscalYear].fedHolidays,
      '',
      '#4c2c92'
    );
    setCalendarEvents([
      ...breakEvents,
      ...holidays,
      ...sessionEvents,
      ...unavailableEvents,
    ]);
  };

  const updateIcons = (dates, eventType, color) => {
    let returnDays = [];
    dates &&
      // eslint-disable-next-line array-callback-return
      dates.map(day => {
        if (eventType !== '') {
          returnDays.push({
            start: AmsDateFormatters.formatGranteeAvailabilityDates(day).format(
              'YYYY-MM-DD'
            ),
            title: eventType,
            color: color,
            allDay: true,
          });
        } else {
          //holidays
          returnDays.push({
            start: day.date,
            title: day.description,
            color: color,
            allDay: true,
          });
        }
      });
    return returnDays;
  };

  const handleDayClick = data => {
    if (!editMode) return;
    let utcDate = AmsDateFormatters.formatGranteeAvailabilityDates(
      data.date.toUTCString()
    ).format();
    let tempUpdatedDates = [];
    if (!doesEventExistOnThisDay(data.date)) {
      switch (monthUnavailabilityType) {
        case 'unavailableDates':
          tempUpdatedDates = [
            ...(updatedDates[monthUnavailabilityType] || []),
            utcDate,
          ];
          setUpdatedDates({
            ...updatedDates,
            [monthUnavailabilityType]: tempUpdatedDates,
          });
          return;
        case 'notInSessionDates':
          tempUpdatedDates = [
            ...(updatedDates[monthUnavailabilityType] || []),
            utcDate,
          ];
          setUpdatedDates({
            ...updatedDates,
            [monthUnavailabilityType]: [...tempUpdatedDates],
          });
          return;
        case 'springBreakDates':
          tempUpdatedDates = [
            ...(updatedDates[monthUnavailabilityType] || []),
            utcDate,
          ];
          setUpdatedDates({
            ...updatedDates,
            [monthUnavailabilityType]: [...tempUpdatedDates],
          });
          return;
        default:
          return;
      }
    }
  };

  const doesEventExistOnThisDay = date => {
    let d = AmsDateFormatters.formatGranteeAvailabilityDates(date);
    let day = d.format('dddd');
    if (day === 'Sunday' || day === 'Saturday') return true;
    if (
      !![...(updatedDates.unavailableDates || []), ...unavailableDates].find(
        item => {
          return (
            AmsDateFormatters.getMoment(item).format('YYYY/MM/DD') ===
            d.format('YYYY/MM/DD')
          );
        }
      ) ||
      !![...(updatedDates.notInSessionDates || []), ...notInSessionDates].find(
        item => {
          return (
            AmsDateFormatters.getMoment(item).format('YYYY/MM/DD') ===
            d.format('YYYY/MM/DD')
          );
        }
      ) ||
      !![...(updatedDates.springBreakDates || []), ...springBreakDates].find(
        item => {
          return (
            AmsDateFormatters.getMoment(item).format('YYYY/MM/DD') ===
            d.format('YYYY/MM/DD')
          );
        }
      )
    )
      return true;
    return false;
  };

  const eventClick = data => {
    if (!editMode) return;
    let eventDate = AmsDateFormatters.formatGranteeAvailabilityDates(
      data.event.start
    ).format('YYYY/MM/DD');
    let tempupdatedDates = [];
    let tempsavedDates = [];
    switch (data.event.title) {
      case 'Unavailable':
        tempupdatedDates = filter(
          [...(updatedDates.unavailableDates || [])],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        tempsavedDates = filter(
          [...unavailableDates],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        setUpdatedDates({
          ...updatedDates,
          unavailableDates: tempupdatedDates,
        });
        setUnavailableDates(tempsavedDates);
        break;
      case 'Not in session':
        tempupdatedDates = filter(
          [...(updatedDates.notInSessionDates || [])],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        tempsavedDates = filter(
          [...notInSessionDates],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        setUpdatedDates({
          ...updatedDates,
          notInSessionDates: tempupdatedDates,
        });
        setNotInSessionDates(tempsavedDates);
        break;
      case 'Spring Break':
        tempupdatedDates = filter(
          [...(updatedDates.springBreakDates || [])],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        tempsavedDates = filter(
          [...springBreakDates],
          date =>
            AmsDateFormatters.formatGranteeAvailabilityDates(date).format(
              'YYYY/MM/DD'
            ) !== eventDate
        );
        setUpdatedDates({
          ...updatedDates,
          springBreakDates: tempupdatedDates,
        });
        setSpringBreakDates(tempsavedDates);
        break;
      default:
        break;
    }
  };

  const monthModal = () => {
    let selectedMonthYear = AmsDateFormatters.formatGranteeAvailabilityDates();
    if (
      selectedMonth === 'October' ||
      selectedMonth === 'November' ||
      selectedMonth === 'December'
    ) {
      selectedMonthYear = AmsDateFormatters.formatGranteeAvailabilityDates(
        selectedMonth + (fiscalYear - 1)
      );
    } else {
      selectedMonthYear = AmsDateFormatters.formatGranteeAvailabilityDates(
        selectedMonth + fiscalYear
      );
    }
    return (
      <AmsModal
        size="small"
        closeOnDimmerClick={false}
        onClose={() => {
          setUpdatedDates({});
          setMonthUnavailabilityType('unavailableDates');
          setShowMonthModal(false);
          setSelectedMonth('');
        }}
        open={showMonthModal}
        className="ams-semantic-modal-fix"
        closeIcon
      >
        <Header>
          Select Unavailabilty Dates for {selectedMonth}{' '}
          {selectedMonth === 'October' ||
          selectedMonth === 'November' ||
          selectedMonth === 'December'
            ? fiscalYear - 1
            : fiscalYear}
        </Header>
        <Modal.Content>
          <Modal.Description>
            <Header as="h5">Weekends will be blocked.</Header>
            <Form>
              <Form.Group inline>
                <Form.Field width={6}>
                  <Radio
                    label="Grant Recipient Unavailable"
                    name="selectedStateGroup"
                    value="unavailableDates"
                    className="radio-toolbar width-adjustment"
                    checked={monthUnavailabilityType === 'unavailableDates'}
                    onChange={handleUnavailabilityTypeChange}
                  />
                </Form.Field>
                <Form.Field width={6}>
                  <Radio
                    label="Children not in Session"
                    name="selectedStateGroup"
                    value="notInSessionDates"
                    className="radio-toolbar width-adjustment"
                    checked={monthUnavailabilityType === 'notInSessionDates'}
                    onChange={handleUnavailabilityTypeChange}
                  />
                </Form.Field>
                <Form.Field width={5}>
                  <Radio
                    label="Spring Break"
                    name="selectedStateGroup"
                    value="springBreakDates"
                    className="radio-toolbar width-adjustment"
                    checked={monthUnavailabilityType === 'springBreakDates'}
                    onChange={handleUnavailabilityTypeChange}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <FullCalendar
              timeZone={'UTC'}
              header={{
                left: '',
                center: 'title',
                right: '',
              }}
              defaultView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              dateClick={handleDayClick}
              events={calendarEvents}
              defaultDate={selectedMonthYear.format()}
              weekends={true}
              ref={calendarComponentRef}
              showNonCurrentDates={false}
              eventClick={eventClick}
            />
            <br />
            <Form.Group>
              <Form.Field>
                <Header as="h4">Key</Header>
                <Form.Group inline>
                  <Label
                    className="grantee-calendar-available-color"
                    horizontal
                  >
                    Available
                  </Label>
                  <Label
                    className="grantee-calendar-unavailable-color"
                    horizontal
                  >
                    Unavailable
                  </Label>
                  <Label className="grantee-calendar-session-color" horizontal>
                    Not In Session
                  </Label>
                  <Label className="grantee-calendar-break-color" horizontal>
                    Spring Break
                  </Label>
                  <Label className="grantee-calendar-holiday-color" horizontal>
                    Holiday
                  </Label>
                </Form.Group>
              </Form.Field>
            </Form.Group>
          </Modal.Description>
        </Modal.Content>
        {editMode ? (
          <Modal.Actions>
            <Button
              // disabled={finalUpdate}
              color="blue"
              onClick={handleMonthSubmit}
            >
              Done For {selectedMonth}
            </Button>
            <Button
              color="red"
              onClick={() => {
                setShowMonthModal(false);
                setUpdatedDates({});
                setMonthUnavailabilityType('unavailableDates');
                setSelectedMonth('');
              }}
            >
              Cancel
            </Button>
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                setShowMonthModal(false);
                setUpdatedDates({});
                setMonthUnavailabilityType('unavailableDates');
                setSelectedMonth('');
              }}
            >
              Close
            </Button>
          </Modal.Actions>
        )}
      </AmsModal>
    );
  };

  const buildMonthList = () => {
    const orderedMonths = AmsDateFormatters.getMonthsList();
    const slicedMonths = slice(orderedMonths, 9, 13);
    const restOfMonths = take(orderedMonths, 9);
    const fiscalOrderedMonths = [...slicedMonths, ...restOfMonths];
    return chunk(fiscalOrderedMonths, 4).map((months, index) => {
      return (
        <Form.Group widths="equal" key={index}>
          {months.map((month, index) => {
            return (
              <Form.Field width={2} key={index}>
                <Form.Button
                  basic
                  style={{
                    textAlign: 'left',
                    fontSize: 'inherit',
                    width: '60%',
                  }}
                  onClick={e => handleMonthSelection(e, month)}
                  size="large"
                >
                  {selectedMonths[month] && (
                    <Icon
                      name="check circle outline"
                      color="green"
                      size="large"
                    ></Icon>
                  )}
                  {month}
                </Form.Button>
              </Form.Field>
            );
          })}
        </Form.Group>
      );
    });
  };

  const buildDateInputs = () => {
    const handleRawChange = e => {
      e.preventDefault();
    };
    const hsDates = () => (
      <Form.Group widths="equal">
        {editMode ? (
          <>
            <Form.Field
              autoComplete="false"
              name="hsStartDate"
              control={DatePicker}
              label={{
                children: 'HS: School Year Start Date',
                htmlFor: 'hsStartDate',
              }}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              value={
                hsStartDate ? AmsDateFormatters.formatDate(hsStartDate) : null
              }
              onChange={date => {
                if (!editMode) return;
                setHSStartDate(AmsDateFormatters.formatDate(date));
                setEdited(true);
                changeTabEdited(true);
              }}
              onChangeRaw={handleRawChange}
              id="hsStartDate"
              aria-labelledby="hsStartDate"
            />
            <Form.Field
              autoComplete="false"
              name="hsEndDate"
              control={DatePicker}
              label={{
                children: 'HS: School Year End Date',
                htmlFor: 'hsEndDate',
              }}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              disabled={!hsStartDate}
              value={hsEndDate ? AmsDateFormatters.formatDate(hsEndDate) : null}
              minDate={AmsDateFormatters.getMoment(hsStartDate)}
              // endDate={hsEndDate}
              onChange={date => {
                if (!editMode) return;
                setEdited(true);
                changeTabEdited(true);
                setHSEndDate(AmsDateFormatters.formatDate(date));
              }}
              onChangeRaw={handleRawChange}
              id="hsEndDate"
              aria-labelledby="hsEndDate"
            />
          </>
        ) : (
          <>
            <Form.Field>
              <label>HS: School Year Start Date:</label>
              {hsStartDate ? AmsDateFormatters.formatDate(hsStartDate) : ''}
            </Form.Field>
            <Form.Field>
              <label>HS: School Year End Date:</label>
              {hsEndDate ? AmsDateFormatters.formatDate(hsEndDate) : ''}
            </Form.Field>
          </>
        )}
      </Form.Group>
    );
    const ehsDates = () => (
      <Form.Group widths="equal">
        {editMode ? (
          <>
            <Form.Field
              autoComplete="false"
              name="ehsStartDate"
              control={DatePicker}
              label={{
                children: 'EHS: School Year Start Date',
                htmlFor: 'ehsStartDate',
              }}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              value={
                ehsStartDate ? AmsDateFormatters.formatDate(ehsStartDate) : null
              }
              onChange={date => {
                if (!editMode) return;
                setEdited(true);
                changeTabEdited(true);
                setEHSStartDate(AmsDateFormatters.formatDate(date));
              }}
              onChangeRaw={handleRawChange}
              id="ehsStartDate"
              aria-labelledby="ehsStartDate"
            />
            <Form.Field
              autoComplete="false"
              name="ehsEndDate"
              control={DatePicker}
              label={{
                children: 'EHS: School Year End Date',
                htmlFor: 'ehsEndDate',
              }}
              placeholderText="Enter Date in valid Format: MM/DD/YYYY"
              disabled={!ehsStartDate}
              value={
                ehsEndDate ? AmsDateFormatters.formatDate(ehsEndDate) : null
              }
              minDate={AmsDateFormatters.getMoment(ehsStartDate)}
              // endDate={hsEndDate}
              onChange={date => {
                if (!editMode) return;
                setEdited(true);
                changeTabEdited(true);
                setEHSEndDate(AmsDateFormatters.formatDate(date));
              }}
              onChangeRaw={handleRawChange}
              id="ehsEndDate"
              aria-labelledby="ehsEndDate"
            />
          </>
        ) : (
          <>
            <Form.Field>
              <label>EHS: School Year Start Date:</label>
              {ehsStartDate ? AmsDateFormatters.formatDate(ehsStartDate) : ''}
            </Form.Field>
            <Form.Field>
              <label>EHS: School Year End Date:</label>
              {ehsEndDate ? AmsDateFormatters.formatDate(ehsEndDate) : ''}
            </Form.Field>
          </>
        )}
      </Form.Group>
    );
    switch (granteeType) {
      case 'Head Start':
        return hsDates();
      case 'Early Head Start':
        return ehsDates();
      case 'Head Start and Early Head Start':
        return (
          <>
            {hsDates()}
            {ehsDates()}
          </>
        );
      default:
        return (
          <>
            {ehsStartDate && (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>EHS: School Year Start Date:</label>
                    {ehsStartDate
                      ? AmsDateFormatters.formatDate(ehsStartDate)
                      : ''}
                  </Form.Field>
                  <Form.Field>
                    <label>EHS: School Year End Date:</label>
                    {ehsEndDate ? AmsDateFormatters.formatDate(ehsEndDate) : ''}
                  </Form.Field>
                </Form.Group>
              </>
            )}
            {hsStartDate && (
              <>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>HS: School Year Start Date:</label>
                    {hsStartDate
                      ? AmsDateFormatters.formatDate(hsStartDate)
                      : ''}
                  </Form.Field>
                  <Form.Field>
                    <label>HS: School Year End Date:</label>
                    {hsEndDate ? AmsDateFormatters.formatDate(hsEndDate) : ''}
                  </Form.Field>
                </Form.Group>
              </>
            )}
          </>
        );
    }
  };

  const handleGranteeSubmit = e => {
    e.preventDefault();
    setDialogContent({
      type: 'warning',
      showCancelButton: true,
      showConfirm: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      title: 'Save Updates',
      text:
        'You are about to save changes to the grantee`s unavailability. Are you sure you want to continue? Click Submit if you want to update the grantee`s unavailability. Click Cancel if you want to continue working on changes.',
      onConfirm: () => {
        handleGranteeSave(granteeDetails[fiscalYear].granteeInfo.finalUpdate);
        resetEditMode(false);
        setShowDialogModal(false);
        setDialogContent({});
      },
      onCancel: () => {
        setShowDialogModal(false);
        setDialogContent({});
      },
    });
    setShowDialogModal(true);
    // }
  };

  const handleGranteeSave = finalized => {
    let granteePayload = {
      agencyId: agencyId,
      granteeId: granteeId,
      fiscalYear: fiscalYear,
      finalUpdate: finalized,
      //Added for backend purpose
      unavailableDates,
      notInSessionDates,
      springBreakDates,
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      comments,
      schedulerComments,
      selectedMonths,
      granteeType: granteeType,
      sendEmail: true,
    };
    let filteredUpdateGrantees = filter(
      updateGrantees,
      grantee => grantee.checked
    ).map(grantee => grantee.granteeId);
    //Added because backend wants an array of data returned instead now.
    let data = [];
    let invitationId =
      granteeDetails[fiscalYear].granteeInfo.invitationId || '';
    let generateInvitationId = granteeDetails[fiscalYear].granteeInfo.invited
      ? false
      : true;
    data.push(granteePayload);
    dispatch(
      updateGranteeSchedule({
        grantees: data,
        invitationId,
        generateInvitationId,
        updateGrantees: !isEmpty(filteredUpdateGrantees)
          ? filteredUpdateGrantees
          : [],
      })
    )
      .then(() => {
        setUpdatedDates({});
        setDialogContent({
          type: 'success',
          showCancelButton: false,
          showConfirm: true,
          confirmButtonText: 'Ok',
          title: finalized ? 'Grantee Submitted' : 'Progress Saved',
          text: results
            ? results.success
            : 'Grantee calendar saved Successfully.',
          onConfirm: () => {
            setShowDialogModal(false);
            setDialogContent({});
            setUpdateGrantees([]);
          },
        });
        setShowDialogModal(true);
        dispatch(fetchGranteeAvailability({ granteeId }));
      })
      .catch(error => {
        setDialogContent({
          type: 'error',
          showCancelButton: false,
          showConfirm: true,
          confirmButtonText: 'Ok',
          title: 'Something went wrong',
          text: error.response.data.message || 'Submission was unsuccessful',
          onConfirm: () => {
            setShowDialogModal(false);
            setDialogContent({});
            setUpdateGrantees([]);
          },
        });
        setShowDialogModal(true);
      });
  };

  const discardChanges = () => {
    if (edited) {
      setDialogContent({
        type: 'warning',
        showCancelButton: true,
        showConfirm: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        title: 'Discard Changes',
        text:
          'All your unsaved changes will be lost. Are you sure you want to discard your changes?',
        onConfirm: () => {
          loadDefault();
          setShowDialogModal(false);
          setDialogContent({});
          setEdited(false);
          setAvailabilityEdited(false);
          changeTabEdited(false);
          setUpdatedDates({});
          savePreviouseGrantee({});
          resetEditMode(false);
        },
        onCancel: () => {
          setShowDialogModal(false);
          setDialogContent({});
        },
      });
      setShowDialogModal(true);
    } else {
      resetEditMode(false);
    }
  };

  const granteeSelectOnChange = value => {
    let temp = updateGrantees.map(grantee => {
      if (grantee.granteeId === value) grantee['checked'] = !grantee['checked'];
      return grantee;
    });
    setUpdateGrantees(temp);
  };

  const selectMultiGranteeModal = () => {
    return (
      <Modal
        open={showMultiGranteeModal}
        size="small"
        className="ams-semantic-modal-fix"
      >
        <Header content="Grantee is part of multi grant agency" />
        <Modal.Content>
          <>
            This grantee is part of multi grant agency, please select the
            grantees you would like to copy the updates/edits that you have
            made? If you do not select any grantees, changes will only be
            applied to current grantee
          </>
          <br />
          <List>
            {updateGrantees &&
              updateGrantees.length > 0 &&
              updateGrantees.map(grantee => {
                return (
                  <List.Item>
                    <Checkbox
                      label={`${grantee.granteeName} (${grantee.granteeId})`}
                      value={grantee.granteeId}
                      onChange={(e, { value }) => granteeSelectOnChange(value)}
                      checked={grantee.checked}
                      aria-labelledby={grantee.granteeId}
                      id={grantee.granteeId}
                    />
                  </List.Item>
                );
              })}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              setShowMultiGranteeModal(false);
            }}
          >
            Finished
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <>
      {monthModal()}
      {dialogModal()}
      {selectMultiGranteeModal()}
      <Grid>
        <Grid.Row style={{ marginLeft: '0px' }}>
          <Grid.Column width={10}>
            <h2>{paneTitle}</h2>
          </Grid.Column>
          <Grid.Column width={6}>
            <Grid.Row>
              {!editMode &&
                (isEmpty(notInSessionDates) &&
                isEmpty(springBreakDates) &&
                isEmpty(unavailableDates) ? (
                  <Popup
                    trigger={
                      <Button
                        basic
                        size="medium"
                        color="blue"
                        content="Schedule View"
                      />
                    }
                    content="There are no unavailability dates entered."
                    size="mini"
                    inverted
                  />
                ) : (
                  <Button
                    basic
                    color="blue"
                    size="medium"
                    as={Link}
                    to={`/grantee/${granteeId &&
                      granteeId}/availability/schedule/${fiscalYear &&
                      fiscalYear}`}
                    content="Schedule View"
                  />
                ))}
              <CurrentSelectedDates
                allDates={{
                  unavailableDates,
                  notInSessionDates,
                  springBreakDates,
                }}
                unavailableDates={unavailableDates}
                notInSessionDates={notInSessionDates}
                springBreakDates={springBreakDates}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider />
      <Form id="grantee-unavailability-form">
        <br />
        {buildDateInputs()}
        <br />
        {availabilityEdited && updateGrantees && updateGrantees.length > 0 && (
          <Message info>
            <Icon name="info" />
            <span>
              This grantee is part of multi grant agency, please select the
              grantees you would like to copy the updates/edits that you have
              made?{' '}
              <Link onClick={() => setShowMultiGranteeModal(true)}>
                Click here to select/edit the grantees list
              </Link>
            </span>
            {updateGrantees &&
              filter(updateGrantees, grantee => grantee.checked).length > 0 && (
                <span>
                  <br />
                  <br />
                  Here are the selected grantees:{' '}
                  {filter(updateGrantees, grantee => grantee.checked)
                    // eslint-disable-next-line array-callback-return
                    .map(grantee => {
                      if (grantee.checked) return `${grantee.granteeId}`;
                    })
                    .join(', ')}
                </span>
              )}
          </Message>
        )}
        {/* <Divider /> */}
        {buildMonthList()}
        <br />
        <Divider />
        {editMode ? (
          <Form.Field width={16}>
            <Form.Field
              control={TextArea}
              label={{
                children: 'Scheduler Comments:',
                htmlFor: 'schedulerComments',
              }}
              placeholder=""
              onChange={e => {
                if (!editMode) return;
                setSchedulerComments(e.target.value);
              }}
              value={schedulerComments}
              id="schedulerComments"
              aria-labelledby="schedulerComments"
            />
          </Form.Field>
        ) : (
          <>
            <Form.Field>
              <label>Grant Recipient Comments:</label>
              {comments}
            </Form.Field>
            <br />
            <Form.Field>
              <label>Scheduler Comments:</label>
              {schedulerComments}
            </Form.Field>
            <br />
          </>
        )}
        {editMode && (
          <Form.Group>
            <Form.Button
              // width={3}
              // size="large"
              primary
              content={'Save Changes'}
              // fluid
              onClick={handleGranteeSubmit}
            />
            <Form.Button
              // width={3}
              content={'Discard Changes'}
              // fluid
              basic
              // disabled={!edited}
              onClick={discardChanges}
            />
          </Form.Group>
        )}
      </Form>
    </>
  );
};

export default GranteeDetailCalendarForm;
