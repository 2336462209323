export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_ERROR = 'SET_NOTIFICATIONS_ERROR';

export function setNotifications(notifications) {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
}
export function setNotificationsError(error) {
  return {
    type: SET_NOTIFICATIONS_ERROR,
    error,
  };
}

export function fetchNotifications(userId) {
  return dispatch => {
    //   axios.get(`${process.env.REACT_APP_API_URI}/amsweb/notification/${userId}`)
    //     .then(function (response) {
    //       dispatch(setNotifications(response.data));
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     });
  };
}
