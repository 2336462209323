import React from 'react';
import { Button } from 'semantic-ui-react';
import Form from '@rjsf/core';
import { isEmpty } from 'lodash';

import AttachmentField from '../../Shared/FormHelpers/AttachmentField';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';

// File upload form schema
const fileUploadFormSchema = {
  type: 'object',
  required: ['evidenceFiles'],
  properties: {
    evidenceFiles: {
      type: 'array',
      title: 'Attach Supporting Documents',
      items: {
        type: 'string',
      },
    },
  },
};

// File upload form ui schema
const fileUploadUISchema = {
  evidenceFiles: {
    'ui:options': {
      orderable: false,
      removable: true,
    },
    items: {
      'ui:field': 'attachmentField',
    },
  },
};

const FA2FindingsOutsideProtocolAttachmentField = props => {
  // Pass the citation changes to the parent component
  const handleAdditionalCitationChange = (
    additionalCitation,
    index,
    { formData }
  ) => {
    props.onChange(additionalCitation, index, { formData });
  };

  // Validate the form
  const validate = (data, errors) => {
    return errors;
  };

  // Add the custom file attachment field
  const fields = () => {
    return {
      attachmentField: AttachmentField,
    };
  };

  const log = type => console.log.bind(console, type);

  // Render the additional citations form
  const renderAdditionalCitationForm = () => {
    const { index, additionalCitation, formName } = props;

    return (
      <Form
        id={`${formName}-${index}`}
        ArrayFieldTemplate={ArrayFieldTemplate}
        schema={fileUploadFormSchema}
        uiSchema={fileUploadUISchema}
        onChange={e =>
          handleAdditionalCitationChange(additionalCitation, index, e)
        }
        onError={log('errors')}
        validate={validate}
        liveValidate={false}
        showErrorList={false}
        formData={{
          evidenceFiles: additionalCitation && additionalCitation.evidenceFiles,
        }}
        noHtml5Validate={true}
        fields={fields()}
      >
        <div hidden>
          <Button
            size="large"
            type="submit"
            content={'Submit'}
            hidden
            disabled
            onClick={e => {
              e.preventDefault();
            }}
          />
        </div>
      </Form>
    );
  };

  const renderField = () => {
    const { additionalCitation } = props;
    if (!isEmpty(additionalCitation)) return renderAdditionalCitationForm();
    return null;
  };

  return <>{renderField()}</>;
};

export default FA2FindingsOutsideProtocolAttachmentField;
