import { isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Header, Message, Modal } from 'semantic-ui-react';

// Import Components
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsModal from '../../utils/AmsModal';
import SlidingContainer from '../../utils/layout/SlidingContainer';

// Import Actions
import {
  fetchGranteeRequests,
  saveGranteeExtensionRequest,
} from '../../actions/granteeActions';

export default function GranteeExtensionRequest(props) {
  const { extensionRequests } = useSelector(state => state.grantee);
  const [request, setRequest] = useState([]);
  const [previousRequests, setPreviousRequests] = useState([]);
  const [responseComment, setResponseComment] = useState('');
  const [newExpirationDate, setNewExpirationDate] = useState({});
  const [originalExpirationDate, setOriginalExpirationDate] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [buttonPressed, setButtonPressed] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const buildRequestCards = () => {
      if (extensionRequests) {
        setRequest(extensionRequests.agencyInvitation);
        setPreviousRequests(
          orderBy(
            extensionRequests.previousInvitations,
            ['submittedAt'],
            ['desc']
          )
        );
      }
    };
    buildRequestCards();
  }, [extensionRequests]);

  useEffect(() => {
    const granteeAvailability = () => {
      const invitationId = props.params.id;
      dispatch(fetchGranteeRequests({ invitationId }));
    };
    granteeAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSubmitModal = () => (
    <AmsModal
      size="small"
      closeOnDimmerClick={false}
      onClose={() => {
        setShowModal(false);
        setButtonPressed('');
        setNewExpirationDate({});
      }}
      open={showModal}
      className="ams-semantic-modal-fix"
      closeIcon
    >
      <Header content="Select New Expiration Date" />
      <Modal.Content>
        <Modal.Description>
          <Form>
            {buttonPressed === 'accept' && (
              <Form.Field
                name="expirationDate"
                label={'Expiration Date'}
                control={DatePicker}
                selected={
                  !isEmpty(newExpirationDate)
                    ? AmsDateFormatters.getMoment(newExpirationDate)
                    : AmsDateFormatters.getMoment(originalExpirationDate)
                        .utc(originalExpirationDate)
                        .add('1', 'day')
                }
                dateFormat="MM/DD/YYYY"
                minDate={AmsDateFormatters.getMoment(
                  originalExpirationDate
                ).add('1', 'day')}
                onChange={setExpirationDate}
              />
            )}

            <Form.Field>
              <label>Note</label>
              <Form.TextArea
                control="textarea"
                style={{ margin: 0 }}
                placeholder=""
                rows={6}
                onChange={e => {
                  setResponseComment(e.target.value);
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={submit} content="Submit" />
      </Modal.Actions>
    </AmsModal>
  );

  const setExpirationDate = date => {
    setNewExpirationDate(date);
  };

  const submit = () => {
    const input = {
      approved: buttonPressed === 'accept' ? true : false,
      invitationId: request._id,
      responseComment: responseComment,
      agencyId: request.agencyId,
      fiscalYear: request.fiscalYear,
    };
    if (buttonPressed === 'accept') {
      input['newExpirationDate'] = !isEmpty(newExpirationDate)
        ? newExpirationDate
        : AmsDateFormatters.getMoment(originalExpirationDate)
            .utc(originalExpirationDate)
            .add('1', 'day');

      //ensures date is EST/EDT start of day
      let utcDate = input.newExpirationDate.startOf('day');
      const addHrs = AmsDateFormatters.getMoment(utcDate.format()).isDST()
        ? 4
        : 5;
      utcDate = utcDate.add(addHrs, 'hours');
      input['newExpirationDate'] = utcDate;
    }

    dispatch(saveGranteeExtensionRequest(input));
    setShowModal(false);
  };

  const currentCard = () => {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header
            content={`1. Extension Request ${
              request.hasOwnProperty('approved') && request.approved
                ? ' - Approved'
                : request.approved === false
                ? ' - Declined'
                : ''
            }`}
          />
          <Card.Description>
            <b>Submitted by:</b> {request.submittedBy}
            <br />
            <b>Submitted at:</b>{' '}
            {AmsDateFormatters.formatDateTime(request.submittedAt)}
            <br />
            <b>Reason for Extension:</b> {request.extensionReason}
            <br />
            {request.multiGrant && (
              <>
                <Message info>
                  The following extension request is an extension for the
                  following grantees.
                  <Message.List>
                    {request.grantees.map(grantee => (
                      <Message.Item>
                        {grantee.granteeName} ({grantee.granteeId})
                      </Message.Item>
                    ))}
                  </Message.List>
                </Message>
              </>
            )}
            {request.hasOwnProperty('approved') && request.responseBy && (
              <>
                <h3>Response</h3>
                <br />
                <b>Response Comment:</b> {request.responseComment}
                <br />
                <b>Response Date:</b>{' '}
                {AmsDateFormatters.formatDateTime(request.responseAt)}
                <br />
                <b>Response By:</b> {request.responseBy.fullName}
              </>
            )}
          </Card.Description>
        </Card.Content>
        {!request.hasOwnProperty('approved') && (
          <Card.Content extra textAlign="right">
            <>
              <Button
                primary
                basic
                onClick={() => {
                  // setSelectedIndex(index);
                  setShowModal(true);
                  setOriginalExpirationDate(request.originalExpirationDate);
                  setButtonPressed('accept');
                }}
              >
                Accept
              </Button>
              <Button
                negative
                basic
                onClick={() => {
                  // setSelectedIndex(index);
                  setShowModal(true);
                  setButtonPressed('decline');
                }}
              >
                Decline
              </Button>
            </>
          </Card.Content>
        )}
      </Card>
    );
  };

  return (
    <>
      <SlidingContainer
        title={
          request &&
          request.grantees &&
          `${request.grantees
            .map(grantee => `${grantee.granteeName} (${grantee.granteeId})`)
            .join(',')}`
        }
        breadcrumbEnabled={false}
      >
        {showSubmitModal()}

        <Card.Group itemsPerRow={2}>
          {currentCard()}
          {previousRequests &&
            previousRequests.map((request, index) => {
              return (
                <Card fluid>
                  <Card.Content>
                    <Card.Header
                      content={`${index + 2}. Extension Request  ${
                        request.hasOwnProperty('approved') && request.approved
                          ? ' - Approved'
                          : request.approved === false
                          ? ' - Declined'
                          : ''
                      }`}
                    />
                    <Card.Description>
                      <b>Submitted by:</b> {request.submittedBy}
                      <br />
                      <b>Submitted at:</b>{' '}
                      {AmsDateFormatters.formatDateTime(request.submittedAt)}
                      <br />
                      <b>Reason for Extension:</b> {request.extensionReason}
                      <br />
                      {request.multiGrant && (
                        <>
                          <Message info>
                            The following extension request is an extension for
                            the following grantees.
                            <Message.List>
                              {request.grantees.map(grantee => (
                                <Message.Item>
                                  {grantee.granteeName} ({grantee.granteeId})
                                </Message.Item>
                              ))}
                            </Message.List>
                          </Message>
                        </>
                      )}
                      <br />
                      {request.hasOwnProperty('approved') &&
                        request.responseBy && (
                          <>
                            <h3>Response</h3>
                            <br />
                            <b>Response Comment:</b> {request.responseComment}
                            <br />
                            <b>Response Date:</b>{' '}
                            {AmsDateFormatters.formatDateTime(
                              request.responseAt
                            )}
                            <br />
                            <b>Response By:</b> {request.responseBy.fullName}
                          </>
                        )}
                    </Card.Description>
                  </Card.Content>
                  {!request.hasOwnProperty('approved') && (
                    <Card.Content extra textAlign="right">
                      <>
                        <Button
                          primary
                          basic
                          onClick={() => {
                            // setSelectedIndex(index);
                            setShowModal(true);
                            setOriginalExpirationDate(
                              request.originalExpirationDate
                            );
                            setButtonPressed('accept');
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          negative
                          basic
                          onClick={() => {
                            // setSelectedIndex(index);
                            setShowModal(true);
                            setButtonPressed('decline');
                          }}
                        >
                          Decline
                        </Button>
                      </>
                    </Card.Content>
                  )}
                </Card>
              );
            })}
        </Card.Group>
      </SlidingContainer>
    </>
  );
}
