import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Button } from 'semantic-ui-react';
import { submitExtReq, granteeHash } from '../../actions/granteeActions';
import _ from 'lodash';

class GranteeExtension extends Component {
  state = {
    grantee: {
      agencyName: '',
      agencyId: null,
      granteeName: '',
      granteeId: '',
      linkExpiration: '',
      fiscalYear: null,
    },

    name: null,
    reason: '',
    intention: null,
    showAlert: false,
    nameError: false,
    reasonError: false,
    successAlert: false,
    submitted: false,
  };

  componentDidMount() {
    let payload = { hash: window.location.href.split('hash=')[1] };
    this.props
      .granteeHash(payload)
      .then(grantee => this.setState({ grantee: grantee }));
  }

  submitRequest = () => {
    if (!this.state.name && !this.state.reason) {
      this.setState({ nameError: true, reasonError: true });
    } else if (!this.state.reason) {
      this.setState({ reasonError: true });
    } else if (!this.state.name) {
      this.setState({ nameError: true });
    } else {
      this.setState({ intention: 'submit', showAlert: true });
    }
  };

  cancelAndClose = () => {
    this.setState({ intention: 'cancel', showAlert: true });
  };

  provideName = e => {
    this.setState({ name: e.target.value, nameError: false });
  };
  provideReason = e => {
    let value = e.target.value;
    let newValue = value.replace(/[^a-z0-9/.,!?:() -]*/gi, '');
    this.setState({ reason: newValue, reasonError: false });
    let textarea = document.getElementById('reason-input');
    if (textarea) {
      textarea.value = newValue;
    }
  };

  closeAlert = () => {
    this.setState({ intention: null, showAlert: false });
  };

  fiscalYear = () => {
    if (new Date().getMonth() < 9) {
      return +new Date().getFullYear();
    } else {
      return +new Date().getFullYear() + 1;
    }
  };

  confirmAndSubmit = () => {
    const { grantee } = this.state;
    let fiscalYear = null;
    let payload = [];

    if (new Date().getMonth() < 9) {
      fiscalYear = new Date().getYear() + 1900;
    } else {
      fiscalYear = new Date().getYear() + 1901;
    }

    for (let i = 0; i < grantee.granteeInfo.length; i++) {
      payload.push({
        agencyId: grantee.agencyId,
        granteeId: grantee.granteeInfo[i].granteeId,
        name: this.state.name,
        comment: this.state.reason,
        fiscalYear: grantee.fiscalYear,
        isMultiGrant: grantee.isMultiGrant,
      });
    }

    this.props
      .submitExtReq({ grantees: payload, invitationId: grantee.invitationId })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          this.setState({ successAlert: true, submitted: true });
          setTimeout(() => {
            this.setState({ showAlert: false, successAlert: false });
          }, 1500);
        } else {
          this.setState({ showAlert: false });
          alert('Error');
        }
      });
  };

  alertPopup = () => {
    if (this.state.showAlert) {
      return (
        <div className="extension-alert">
          {this.state.successAlert ? (
            <div>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="150px"
                height="150px"
                viewBox="0 0 512 512"
                style={{
                  enableBackground: 'new 0 0 512 512',
                  display: 'block',
                  margin: 'auto',
                }}
              >
                <path
                  d="M256,128c-70.688,0-128,57.312-128,128c0,70.688,57.312,128,128,128c70.688,0,128-57.312,128-128
		              C384,185.312,326.688,128,256,128z M240,320l-48-48l22.398-25.594L240,272l72-80l24,25.594L240,320z"
                  fill="green"
                />
              </svg>
              <h2>Submitted Successfully</h2>
            </div>
          ) : (
            <div>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="70"
                height="70"
                viewBox="0 0 512 512"
                style={{ enableBackground: 'new 0 0 512 512' }}
              >
                <path
                  d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
			C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
			c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
                  fill="rgb(33,68,96)"
                />

                <path
                  d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877
			C276,137.831,267.046,128.877,256,128.877z"
                  fill="rgb(33,68,96)"
                />

                <circle cx="256" cy="349.16" r="27" fill="rgb(33,68,96)" />
              </svg>
              <h2>Are you sure?</h2>
              <div className="extension-message">
                {this.state.intention === 'submit'
                  ? 'You want to submit the request for the extension'
                  : 'You want to cancel the request for the extension'}
              </div>
              <div className="f-alert-buttons flex-div">
                <button
                  className="f-alert-ok margin-right-20"
                  onClick={
                    this.state.intention === 'submit'
                      ? this.confirmAndSubmit
                      : this.closeAlert
                  }
                >
                  Ok
                </button>
                <button className="f-alert-ok" onClick={this.closeAlert}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  render() {
    const { grantee } = this.state;
    const displayName = grantee.granteeInfo
      ? grantee.granteeInfo.length === 1
        ? grantee.granteeInfo[0].granteeName
        : grantee.agencyName
      : '';
    const displayId = grantee.granteeInfo
      ? grantee.granteeInfo.length === 1
        ? grantee.granteeInfo[0].granteeId
        : grantee.agencyId
      : '';

    return !_.isEmpty(grantee) ? (
      <div>
        {this.state.grantee ? (
          <div className="extension-page">
            <div className="extension-page-header">
              {displayName} ({displayId})
            </div>
            <div className="extension-wrapper">
              <label
                style={{ color: this.state.nameError ? 'red' : 'black' }}
                id="name-label"
                htmlFor="name"
              >
                Name of the Requestor<span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <input
                type="text"
                id="name-input"
                name="name"
                className="extension-input"
                style={{ borderColor: this.state.nameError ? 'red' : 'black' }}
                onChange={this.provideName}
              />
              <br />
              <label
                id="reason-label"
                style={{ color: this.state.reasonError ? 'red' : 'black' }}
                htmlFor="reason"
              >
                Enter a reason for Extension
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <textarea
                name="reason"
                id="reason-input"
                rows="4"
                className="extension-input"
                style={{
                  borderColor: this.state.reasonError ? 'red' : 'black',
                }}
                onChange={this.provideReason}
              ></textarea>
              {this.state.nameError || this.state.reasonError ? (
                <ul id="helper-text">
                  <li>This is a required field</li>
                </ul>
              ) : null}
              <br />
              <div className="f-alert-buttons flex-div">
                <Button
                  floated={'left'}
                  primary
                  className=" margin-right-20"
                  onClick={this.submitRequest}
                  disabled={this.state.submitted}
                >
                  Submit
                </Button>
                <Button
                  floated={'right'}
                  disabled={this.state.submitted}
                  onClick={this.cancelAndClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
            {this.alertPopup()}
          </div>
        ) : (
          <Dimmer active inverted>
            <Loader inverted>Loading...</Loader>
          </Dimmer>
        )}
      </div>
    ) : (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    );
  }
}

const mapStateToProps = state => {
  return {
    grantee: state.grantee,
  };
};

export default connect(mapStateToProps, { submitExtReq, granteeHash })(
  GranteeExtension
);
