import React from 'react';
import { Message } from 'semantic-ui-react';

const AmsAlertMessage = ({ children }) => {
  // const message = content && content.response && content.response.message;

  return (
    <Message error>
      <Message.Content>
        <Message.Header>Something went wrong!</Message.Header>
        {children}
      </Message.Content>
    </Message>
  );

  // return (
  //   <>
  //     <Message
  //       negative
  //       icon="cancel"
  //       header="Something went wrong!"
  //       // content={message}
  //       // list={Object.keys(content).map(key => (
  //       //   <li key={key}>{content[key]}</li>
  //       // ))}
  //     >
  //       {message}
  //     </Message>
  //   </>
  // );
};

export default AmsAlertMessage;
