import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';

class CLASSReplacementForm extends Component {
  handleSubmit = () => {
    console.log('submitted');
  };

  renderFormContent = () => {
    return <Form onSubmit={this.handleSubmit} />;
  };

  render() {
    return (
      <div>
        <SlidingContainer calendar title={'CLASS Survey Replacement Form'}>
          Class replacement questions
        </SlidingContainer>
      </div>
    );
  }
}

CLASSReplacementForm.propTypes = {};

export default CLASSReplacementForm;
