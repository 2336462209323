import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Dimmer, Loader, Grid } from 'semantic-ui-react';

// Import Components
import ReviewLayout from '../../../../utils/layout/ReviewLayout';
import ReviewManifestDetails from './ReviewManifestDetails';
// Import Actions
import { fetchManifest } from '../../../../actions/reviewActions';

const ReviewManifestPage = ({ router }) => {
  const { params } = router;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const reviewId = params.id || params.reviewId;

    const loadData = async () => {
      try {
        await dispatch(fetchManifest(reviewId));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    loadData();
  }, [dispatch, params.id, params.reviewId]);

  return (
    <ReviewLayout type="Manifest">
      {loading ? (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      ) : (
        <>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2" content="Manifest" />
              <br />
            </Grid.Column>
          </Grid.Row>

          <ReviewManifestDetails />
        </>
      )}
    </ReviewLayout>
  );
};

export default ReviewManifestPage;
