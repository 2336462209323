import React, { useState } from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router';
import DOMPurify from 'dompurify';

const AmsPopup = ({
  content,
  label,
  type,
  style,
  dangerouslySetInnerHTML = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const triggerButton = (
    <Button
      content={label}
      onClick={() => setIsOpen(true)}
      style={style}
      aria-label={label}
    />
  );

  const handleKeyDown = e => {
    if (e.key === ' ' || e.key === 'Return' || e.key === 'Enter') {
      e.preventDefault();
      setIsOpen(true);
    }
  };

  const triggerIcon = (
    <Link
      style={{ color: '#000000', textDecoration: 'none' }}
      href="#"
      onClick={e => e.preventDefault()}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <Icon
        name={label}
        size="large"
        onClick={() => setIsOpen(true)}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      />
    </Link>
  );

  return (
    <Popup
      trigger={
        type === 'Button' ? triggerButton : type === 'Icon' ? triggerIcon : null
      }
      open={isOpen}
      size="small"
      position="fixed"
    >
      <Popup.Content>
        {dangerouslySetInnerHTML ? (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
          ></div>
        ) : (
          content
        )}
        <Button floated="right" icon="close" size="tiny" onClick={handleClose}>
          Close
        </Button>
      </Popup.Content>
    </Popup>
  );
};

export default AmsPopup;
