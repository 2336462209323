import axios from 'axios';
import {
  INVITATION_LOADED,
  INVITATION_ACCEPTED,
  INVITATION_DECLINED,
} from '../redux/types';

export const invitationLoaded = invitation => ({
  type: INVITATION_LOADED,
  invitation,
});

export const invitationProcessed = accepted => ({
  type: accepted ? INVITATION_ACCEPTED : INVITATION_DECLINED,
});

export const processInvitation = (invitationId, requestInput) => dispatch =>
  axios
    .post(`/invitations/process/${invitationId}`, requestInput)
    .then(response =>
      dispatch(invitationProcessed(requestInput.accepted, response.data))
    );

export const fetchInvitation = invitationId => dispatch =>
  axios
    .post(`/invitations/${invitationId}`)
    .then(response => dispatch(invitationLoaded(response.data)));
