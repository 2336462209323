import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Import actions.
import { fetchReviewDetail } from '../../../actions/reviewActions';
import RANIncidentPage from './index';
import RANPage from './../RANSurvey/index';
import RANSurvey from './../../../components/Survey/RANSurvey';

export default function RANSurveyPaths(props) {
  const dispatch = useDispatch();
  const { selectedReview } = useSelector(state => state.review);
  const { params } = props.router;
  const [reviewDetailsFetched, setReviewDetailsFetched] = useState(false);

  // useEffect to fetch the review detail
  useEffect(() => {
    const reviewId = params.reviewId;
    if (reviewId && selectedReview && isEmpty(selectedReview.reviewId)) {
      try {
        // Fetch Review Detail.
        dispatch(fetchReviewDetail({ reviewId }));
        setReviewDetailsFetched(true);
      } catch (err) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {selectedReview && selectedReview.isRANIncident ? (
        <RANIncidentPage
          {...props}
          reviewDetailsFetched={reviewDetailsFetched}
        />
      ) : selectedReview && selectedReview.isNewForms ? (
        <RANSurvey {...props} />
      ) : (
        <RANPage {...props} />
      )}
    </>
  );
}
