import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Label, Menu, Popup, Tab } from 'semantic-ui-react';
import ReviewLayout from '../../utils/layout/ReviewLayout';

// Import style
import './assets/style.css';

// Import actions
import {
  fetchExplorationSummary,
  fetchReviewDetail,
} from '../../actions/reviewActions';

import { fetchReviewSubmissions } from '../../actions/fy19/reviewActions';

import AmsTable from '../../utils/AmsTable';

export default function ReviewsFA2CenterAndExplorationSummary(props) {
  const dispatch = useDispatch();

  const [pane, setPane] = useState(1);

  const { selectedReview } = useSelector(state => state.review);
  const { explorationSummary } = useSelector(state => state.review);
  const { list } = useSelector(state => state.reviewSubmissions);

  useEffect(() => {
    const { router } = props;
    if (
      !selectedReview.reviewId ||
      selectedReview.reviewId !== router.params.id
    ) {
      dispatch(fetchReviewDetail({ reviewId: router.params.id }));
    }
  }, [props]);

  useEffect(() => {
    const { router } = props;
    if (
      selectedReview.reviewId &&
      selectedReview.reviewId === router.params.id
    ) {
      dispatch(
        fetchReviewSubmissions({
          reviewId: selectedReview.reviewId,
          filters: { guideName: '', performanceMeasure: '', status: '' },
        })
      );
    }
  }, [selectedReview.reviewId]);

  useEffect(() => {
    const { router } = props;
    if (
      selectedReview.reviewId &&
      selectedReview.reviewId === router.params.id
    ) {
      let centerAmsFormId = null;
      let classAmsFormId = null;

      list.forEach(e => {
        if (e.performanceMeasure === 'Safety Practices (Center Exploration)')
          centerAmsFormId = e.amsFormId;

        if (
          e.performanceMeasure ===
          'Classroom Exploration (Effective and Intentional Teaching Practices)'
        )
          classAmsFormId = e.amsFormId;
      });

      if (centerAmsFormId && classAmsFormId) {
        dispatch(
          fetchExplorationSummary({
            reviewId: selectedReview.reviewId,
            centerAmsFormId,
            classAmsFormId,
          })
        );
      }
    }
  }, [list]);

  const definitionText = (cell, row, type) => {
    let definiation =
      type === 'parent' ? row.parentCitationDefinition : row.citationDefinition;
    return (
      <span>
        <Popup
          wide={'very'}
          size={'tiny'}
          on={['hover', 'focus']}
          trigger={
            <Label
              style={{
                color: 'blue',
                background: 'transparent',
                textDecorationLine: 'underline',
              }}
            >
              {cell}
            </Label>
          }
          content={definiation}
        />
      </span>
    );
  };

  const populateTotalnos = (cell, row) => {
    let noCount = cell == null ? 0 : cell;
    let totalcount = row.totalCount == null ? 0 : row.totalCount;
    return noCount + '/' + totalcount;
  };

  const populateCentersAndClassRooms = (cell, row) => {
    if (
      typeof row.centers === 'object' &&
      row.centers !== null &&
      row.centers.length > 0
    ) {
      return row.centers.map(item => {
        return Object.keys(item).map(key => {
          return (
            <>
              <ul>
                <li>
                  {key}
                  {item[key] != null &&
                    item[key].map(value => (
                      <ul>
                        <li>{value}</li>
                      </ul>
                    ))}
                </li>
              </ul>
            </>
          );
        });
      });
    }
  };

  const renderExplorationSummaryDetails = () => {
    const centerColumns = [
      {
        dataField: 'questionId',
        text: 'ID',

        headerStyle: {
          width: '5%',
        },
      },
      {
        dataField: 'questionText',
        text: 'Question Text',

        headerStyle: {
          width: '30%',
        },
      },
      {
        dataField: 'citation',
        text: 'Citation',
        formatter: (cell, row) => definitionText(cell, row, 'child'),
      },
      {
        dataField: 'noCount',
        text: 'Total # of Nos Selected',
        formatter: (cell, row) => populateTotalnos(cell, row),
        headerStyle: {
          width: '15%',
        },
      },
      {
        dataField: 'centers',
        text: 'Centers Noted',
        formatter: (cell, row) => populateCentersAndClassRooms(cell, row),
        headerStyle: {
          width: '30%',
        },
      },
    ];

    const classroomColumns = [...centerColumns];
    classroomColumns.splice(4, 1);
    classroomColumns[4] = {
      dataField: 'centers',
      text: 'Classrooms Noted',
      formatter: (cell, row) => populateCentersAndClassRooms(cell, row),
      headerStyle: {
        width: '30%',
      },
    };

    let { centerExploration, classRoomExploration } = explorationSummary;

    const panes = [
      {
        menuItem: <Menu.Item key={1}>Center Exploration</Menu.Item>,
        render: () => (
          <Tab.Pane style={{ overflowX: 'scroll' }}>
            <div>
              <AmsTable
                sizePerPageOption={false}
                remote={false}
                basic
                total={centerExploration ? centerExploration.length : 0}
                columns={centerColumns}
                keyField="questionId"
                data={centerExploration ? centerExploration : []}
              />
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: <Menu.Item key={2}>Classroom Exploration</Menu.Item>,
        render: () => (
          <Tab.Pane style={{ overflowX: 'scroll' }}>
            <div>
              <AmsTable
                remote={false}
                basic
                sizePerPageOption={false}
                total={classRoomExploration ? classRoomExploration.length : 0}
                columns={classroomColumns}
                keyField="questionId"
                data={classRoomExploration ? classRoomExploration : []}
              />{' '}
            </div>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Tab
        onTabChange={handleChange}
        style={{ marginTop: '20px' }}
        panes={panes}
      ></Tab>
    );
  };

  const handleChange = (e, { activeIndex }) => {
    setPane(activeIndex);
  };

  return (
    <ReviewLayout type={'Performance Rating Summary'}>
      <Header as="h2" content="Center / Classroom Exploration Summary" />
      {explorationSummary !== null ? (
        renderExplorationSummaryDetails()
      ) : (
        <div>No data found!</div>
      )}
    </ReviewLayout>
  );
}
