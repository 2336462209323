import Form from '@rjsf/core';
import _, { throttle, isArray, isEmpty } from 'lodash';
import { retrieveSchema, toPathSchema } from '@rjsf/core/lib/utils.js';

//util
import { differenceInObjects } from '../../Shared/FormHelpers/helpers/utils.js';
import * as Constants from './Shared/SurveyFormConstants.js';

class EASForm extends Form {
  constructor(props) {
    super(props);
    const superOnBlur = this.onBlur;
    const superOnChange = this.onChange;

    this.state = {
      ...this.state,
      autosaveQueue: [],
    };

    this.throttledOnChange = throttle(this.throttledOnChange.bind(this), 3000, {
      leading: false,
      trailing: true,
    });

    this.onChange = formData => {
      if (this.props.readOnly) {
        return;
      }
      this.updateFormData('onChange', formData);
      superOnChange(formData);
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  // Remove the event listener in componentWillUnmount to avoid memory leaks
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  // Function to handle the beforeunload event
  handleBeforeUnload = event => {
    const { formData, autosaveTriggered } = this.state;
    const { originalData } = this.props;

    // Check if there are any pending changes
    if (autosaveTriggered) {
      event.preventDefault();
      event.returnValue = ''; // Chrome requires this to be set for the dialog to show

      // Trigger the throttled autosave function manually

      return 'You have unsaved changes. Are you sure you want to leave the page?';
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const diff = differenceInObjects(
      this.props.originalData,
      prevProps.originalData
    );
    if (!_.isEmpty(this.props.originalData) && !_.isEmpty(diff)) {
      this.setState({ ...this.state, originalData: this.props.originalData });
    }

    const easForm = document.getElementById('eas-form');
    if (easForm && easForm.classList.contains('eas-readonly')) {
      const trixEditors = document.querySelectorAll('trix-editor');
      trixEditors.forEach(editor => {
        editor.editor.element.setAttribute('contentEditable', false);
      });
    }

    if (prevProps.readOnly === true && this.props.readOnly === false) {
      const trixEditors = document.querySelectorAll('trix-editor');
      trixEditors.forEach(editor => {
        editor.editor.element.setAttribute('contentEditable', true);
      });
    }

    if (
      this.props.title === 'Original Data Collection Responses' &&
      this.props.containsOriginalResponse
    ) {
      const ids = Object.keys(this.props.uiSchema);

      const trixEditors = document.querySelectorAll('trix-editor');
      trixEditors.forEach(editor => {
        let match =
          editor.title.match(/^root_([a-fA-F0-9-]+_\d+)/) === null
            ? editor.title.match(/^root_([0-9a-fA-F-]+)_/)
            : editor.title.match(/^root_([a-fA-F0-9-]+_\d+)/);
        ids.forEach(id => {
          if (match && match[1] === id) {
            editor.editor.element.setAttribute('contentEditable', false);
          }
        });
      });
    }
  }

  ShouldUpdateOnBlur = id => {
    var element = document.getElementById(id);

    if (!element) return false;

    let elementTagType = element.tagName.toLowerCase();
    if (elementTagType === 'input' && element.getAttribute('type') === 'text')
      return true;

    if (elementTagType === 'textarea' || elementTagType === 'trix-editor')
      return true;

    return false;
  };

  skipAutoSaveForFiles = id => {
    var element = document.getElementById(id);

    if (!element) return false;

    // Check if the element has 'evidence-files' class
    if (element.classList.contains('evidence-files')) return true;

    return false;
  };

  BuildId = (id, structure) => {
    for (var item in structure) {
      if (structure.hasOwnProperty(item)) {
        var nextId = id + '_' + item;
        var nextStructure = structure[item];
        if (
          typeof nextStructure == 'object' &&
          !Array.isArray(nextStructure) &&
          nextStructure != null
        ) {
          return this.BuildId(nextId, nextStructure);
        } else {
          for (var input in structure) {
            return id + '_' + input;
          }
        }
      }
    }
    return nextId;
  };

  differenceInObjects = (object, base) => {
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(value, base[key])) {
          if (_.isObject(value) && _.isObject(base[key])) {
            result[key] = changes(value, base[key]);
          } else {
            result[key] = value;
          }
        }
      });
    }
    return changes(object, base);
  };

  getChangedSection(id, base, structure) {
    var restults = {},
      nextBase,
      item,
      nextStructure,
      nextId;
    for (item in structure) {
      if (base.hasOwnProperty(item)) {
        nextId = id + '_' + item;
        nextBase = base[item];
        nextStructure = structure[item];
        if (
          typeof nextBase == 'object' &&
          !Array.isArray(nextBase) &&
          nextBase != null
        ) {
          restults[item] = this.getChangedSection(
            nextId,
            nextBase,
            nextStructure
          );
        } else {
          var inputsList = {};
          // check if inputs exist
          for (var input in base) {
            var element = document.getElementById(id + '_' + input);
            if (
              (typeof element != 'undefined' && element != null) ||
              input == 'CitationPicker' ||
              input == 'CAPA'
            ) {
              inputsList[input] = base[input];
            }
          }
          return inputsList;
        }
      }
    }
    return restults;
  }

  trimObj = e => {
    let copyObj = { ...e };
    Object.keys(e).forEach(i => {
      if (
        i === 'Citation' ||
        i === 'amsFormId' ||
        i === 'performanceArea' ||
        i === 'evidence_source' ||
        i === 'contentArea' ||
        i === 'citations' ||
        i === 'editedTime' ||
        i === 'isValidated' ||
        i === 'questionId' ||
        i === 'editedBy' ||
        (copyObj[i] && copyObj[i].length === 0)
      ) {
        delete copyObj[i];
        delete e[i];
      }
    });

    return _.isEmpty(copyObj);
  };

  throttledOnChange(parentSection, validated, invalidQuestiosns, formData) {
    console.log('throttle', this.state.autosaveQueue);
    for (const questionId of this.state.autosaveQueue) {
      this.props.onAutoSave(
        questionId.parentSection,
        questionId.validated,
        questionId.invalidQuestiosns,
        formData
      );
    }
    //this.props.onAutoSave(parentSection, validated, formData);
    this.setState({
      ...this.state,
      originalData: formData,
      autosaveTriggered: false,
      autosaveQueue: [],
    });
  }

  omitExtraData(formData) {
    const retrievedSchema = retrieveSchema(
      this.state.schema,
      this.state.schema,
      formData
    );

    const pathSchema = toPathSchema(
      retrievedSchema,
      '',
      this.state.schema,
      formData
    );

    const fieldNames = this.getFieldNames(pathSchema, formData);
    return this.getUsedFormData(formData, fieldNames);
  }

  async updateFormData(eventName, formData) {
    let modifiedFormData = _.omitBy(formData, this.trimObj);
    let modifiedData = _.omitBy(this.state.originalData, this.trimObj);

    if (this.props.onChangeCallback) this.props.onChangeCallback(formData);

    var changedStructure = this.differenceInObjects(
      modifiedFormData,
      modifiedData
    );

    //check if wsywig issue
    const changedKeys = Object.keys(changedStructure);
    if (changedKeys.length === 1) {
      const question = changedStructure[changedKeys[0]];
      let originalNotes =
        modifiedData &&
        modifiedData[changedKeys[0]] &&
        modifiedData[changedKeys[0]].notes
          ? modifiedData[changedKeys[0]].notes
          : '';
      let cleanedQuestionNotes =
        question.notes &&
        question.notes.length > 0 &&
        question.notes.replace('<div>', '').replace('</div>', '');

      // Compare cleanedQuestionNotes with modifiedNotes
      if (cleanedQuestionNotes === originalNotes) return;
    }

    if (this.props.readOnly || this.props.containsOriginalResponse)
      console.log('readonly change, no autosave');
    if (this.props.readOnly || this.props.containsOriginalResponse) return;

    if (
      !_.isEmpty(changedStructure) &&
      _.isEmpty(changedStructure[Object.keys(changedStructure)[0]])
    ) {
      changedStructure = {
        [Object.keys(changedStructure)[0]]:
          modifiedFormData[Object.keys(changedStructure)[0]],
      };
    }

    if (_.isEmpty(changedStructure)) {
      var changedStructure = this.differenceInObjects(
        modifiedData,
        modifiedFormData
      );
    }

    var id = this.BuildId('root', changedStructure);

    var parentSection = this.getChangedSection(
      'root',
      formData,
      changedStructure
    );

    const validated = this.validateQuestion(
      Object.keys(parentSection)[0],
      formData
    );
    let invalidQuestiosnsIds = [];
    if (this.props.isMultiForm) {
      for (var key in Object.keys(this.state.schema.properties)) {
        var questionId = Object.keys(this.state.schema.properties)[key];
        if (!this.validateQuestion(questionId, formData)) {
          invalidQuestiosnsIds.push(questionId);
        }
      }
    }
    let invalidQuestiosns = {
      title: this.props.title,
      ids: invalidQuestiosnsIds,
    };

    //if text area, then throttle autosave changes and check for other reason box
    let otherReasonChanged = false;
    var changedStructureKeys = Object.keys(changedStructure);
    if (changedStructureKeys.length > 0) {
      var key = changedStructureKeys[0];
      var modifiedDataNoCheckboxList = _.get(
        modifiedData,
        `${key}.no_checkbox_list`
      );
      var modifiedFormDataNoCheckboxList = _.get(
        modifiedFormData,
        `${key}.no_checkbox_list`
      );

      var modifiedFormDataValue =
        modifiedFormDataNoCheckboxList &&
        modifiedFormDataNoCheckboxList.find(value =>
          value.startsWith('opt-other-reason')
        );

      var modifiedDataValue =
        modifiedDataNoCheckboxList &&
        modifiedDataNoCheckboxList.find(value =>
          value.startsWith('opt-other-reason')
        );

      otherReasonChanged =
        (modifiedFormDataValue && !modifiedDataValue) ||
        (modifiedDataValue && !modifiedFormDataValue) ||
        (modifiedFormDataValue &&
          modifiedDataValue &&
          modifiedFormDataValue !== modifiedDataValue);
    }

    if (eventName === 'onChange' && this.skipAutoSaveForFiles(id)) {
      const key = Object.keys(changedStructure)[0];
      const array = changedStructure[key].evidence;
      const allNull = array.every(
        element => element === null || element === undefined
      );

      if (allNull) return;

      if (
        changedStructure[key] &&
        changedStructure[key].evidence &&
        changedStructure[key].evidence[0]
      ) {
        let first = changedStructure[key].evidence[0];
        first = first.split('|');
        if (first && first.length > 4 && first[4] === 'autosave') {
          first.pop();
          first = first.join('|');
          formData[key].evidence[0] = first;
        }
      }
    }

    if (
      (eventName === 'onChange' && this.ShouldUpdateOnBlur(id)) ||
      otherReasonChanged
    ) {
      if (
        otherReasonChanged &&
        modifiedFormDataValue === undefined &&
        modifiedDataValue.length > 0
      ) {
        console.log('other reason cleared, process autosave without throttle');
      } else {
        const { autosaveQueue } = this.state;

        // Find index of the item with the same key
        const index = autosaveQueue.findIndex(item => item.key === key);

        // If found, replace that item with the new data; otherwise add the new item
        let updatedQueue;
        if (index !== -1) {
          updatedQueue = [...autosaveQueue];
          updatedQueue[index] = {
            key,
            parentSection,
            validated,
            invalidQuestiosns,
          };
        } else {
          updatedQueue = [
            ...autosaveQueue,
            { key, parentSection, validated, invalidQuestiosns },
          ];
        }

        this.setState({
          ...this.state,
          autosaveTriggered: true,
          autosaveQueue: updatedQueue,
        });

        await this.throttledOnChange(
          parentSection,
          validated,
          invalidQuestiosns,
          formData
        );
        console.log('textarea throttle');
        return;
      }
    }

    console.log('non throttle change');
    if (this.state.autosaveQueue.some(item => item.key === key)) {
      const updatedAutosaveQueue = this.state.autosaveQueue.filter(
        item => item.key !== key
      );
      this.setState({
        ...this.state,
        autosaveQueue: updatedAutosaveQueue,
        originalData: formData,
      });
    } else {
      this.setState({ ...this.state, originalData: formData });
    }
    await this.props.onAutoSave(
      parentSection,
      validated,
      invalidQuestiosns,
      formData
    );
  }

  validateQuestion = (field, formData) => {
    const errorObj = this.validate(formData);
    const { errorSchema } = errorObj;
    const questionData = this.omitExtraData(formData);
    const cleanQuestionData = questionData[field];

    var obj = errorSchema[field];
    if (!obj && errorSchema.properties) obj = errorSchema.properties[field];

    // clear any errors for CAPA and do it manually
    if (obj?.CAPA && formData[field]?.CAPA) delete obj.CAPA;

    const parentErrors = this.objectFlatten(obj);

    return (
      parentErrors.length === 0 &&
      this.validateCAPA(formData, field) &&
      this.validateCheckBoxes(cleanQuestionData, formData, field) &&
      this.validateCitationResponse(formData, field) &&
      this.validateNarrativeResponse(formData, field)
    );
  };

  validateCAPA = (formData, field) => {
    if (formData && formData[field] && formData[field].CAPA) {
      if (
        formData[field].CAPA.content_area &&
        formData[field].CAPA.performance_area
      )
        return true;
      else return false;
    }
    return true;
  };

  validateOther = questionData => {
    var results = true;
    if (!questionData) return results;
    Object.keys(questionData).forEach(key => {
      if (typeof questionData[key] === 'object' && questionData[key] !== null) {
        if (
          isArray(questionData[key]) &&
          questionData[key].includes(Constants.OTHER_KEY)
        ) {
          var otherReason = questionData[key].find(el =>
            el?.toString().startsWith(Constants.OTHER_REASON_KEY)
          );
          results = otherReason ? true : false;
          return results;
        } else {
          results = this.validateOther(questionData[key]);
          return results;
        }
      }
    });
    return results;
  };

  validateCheckBoxes = (questionData, formData, field) => {
    const retrievedSchema = retrieveSchema(
      this.state.schema,
      this.state.schema,
      formData
    );
    const selectedQuestionData =
      retrievedSchema &&
      retrievedSchema.properties[field] &&
      retrievedSchema.properties[field].properties;
    let results = true;
    if (isEmpty(questionData) && !retrievedSchema) {
      return;
    }
    if (this.hasContingentCheckboxes(selectedQuestionData)) {
      for (const key in questionData) {
        if (
          typeof questionData[key] === 'object' &&
          questionData[key] !== null
        ) {
          if (Array.isArray(questionData[key])) {
            const filteredData = questionData[key].filter(
              item => !/^\d+_.*/.test(item)
            );
            if (!isEmpty(filteredData)) {
              for (const optionText of filteredData) {
                if (
                  this.checkContingentHasNoSubOptsSelected(
                    selectedQuestionData,
                    optionText,
                    key,
                    questionData[key]
                  )
                ) {
                  results = false;
                  return results;
                }
              }
            } else {
              results = false;
              return results;
            }

            if (questionData[key].includes(Constants.OTHER_KEY)) {
              const otherReason = questionData[key].find(el =>
                el?.toString().startsWith(Constants.OTHER_REASON_KEY)
              );
              if (!otherReason) {
                results = false;
                return results;
              }
            }
          }
        }
      }
      return results;
    } else {
      return this.validateOther(questionData);
    }
  };

  checkContingentHasNoSubOptsSelected = (
    selectedQuestionData,
    optionText,
    checkboxType,
    answeredCheckboxList
  ) => {
    if (checkboxType in selectedQuestionData) {
      const enumList = selectedQuestionData[checkboxType].items.enum;
      if (!enumList) return false;

      const index = enumList.indexOf(optionText);
      if (index !== -1) {
        const key = 'c' + index;
        const contingentItems =
          selectedQuestionData[checkboxType].contingentItems;
        const modifiedContingentItems = contingentItems[key]?.map(
          item => `${index}_${item}`
        );
        return (
          contingentItems.hasOwnProperty(key) &&
          contingentItems[key]?.length !== 0 &&
          !modifiedContingentItems.some(item =>
            answeredCheckboxList.includes(item)
          )
        );
      }
    }
    return false;
  };

  hasContingentCheckboxes = data => {
    if (!data) return false;
    const searchForContingent = obj => {
      for (const key in obj) {
        if (obj?.hasContingentCheckboxes === 1) {
          return true;
        }
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (searchForContingent(obj[key])) {
            return true;
          }
        }
      }
      return false;
    };

    return Object.values(data).some(searchForContingent);
  };

  hasItemStartingWithNumberUnderscore = data => {
    if (Array.isArray(data)) {
      return data.some(item => /^[0-9_]/.test(item));
    }
    return false;
  };

  objectFlatten = obj => {
    const result = [];
    for (const prop in obj) {
      const value = obj[prop];
      if (typeof value === 'object') {
        result.push(this.objectFlatten(value));
      } else {
        result.push(value);
      }
    }
    return _.flattenDeep(result);
  };

  validateCitationResponse = (formData, field) => {
    if (formData?.[field]?.hasOwnProperty('citationResponse')) {
      if (!isEmpty(formData[field].citationResponse)) return true;
      else return false;
    }
    return true;
  };

  validateNarrativeResponse = (formData, field) => {
    if (formData?.[field]?.hasOwnProperty('narrative')) {
      if (!isEmpty(formData[field].narrative)) return true;
      else return false;
    }
    return true;
  };
}
export default EASForm;
