import React, { useEffect, useState } from 'react';
import { Grid, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

// Import actions.
import { fetchReviewOCISummary } from '../../../../actions/fy19/reviewActions';

const ReviewOCISummaryDetails = props => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { selectedReview } = props;

    if (
      selectedReview &&
      selectedReview.reviewId &&
      !selectedReview.ociSummaryDetails
    ) {
      setLoading(true);
      props.fetchReviewOCISummary({
        reviewId: selectedReview.reviewId,
        reviewType: selectedReview.reviewType,
      });
    } else if (selectedReview.ociSummaryDetails) {
      setDetails(selectedReview.ociSummaryDetails);
      setLoading(false);
    }
  }, [props]);

  const generateSummaryDetails = () => {
    return (
      details &&
      details.data &&
      details.data.map((e, key) => {
        return (
          <React.Fragment key={key}>
            <Grid.Row>
              <Grid.Column>
                <p>
                  <strong>{e.name}</strong>
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={10}>
                <p>
                  <strong>Performance Measure</strong>
                </p>
                {e.performanceMeasure.map((i, index) => {
                  return <p key={index}>{i.name}</p>;
                })}
              </Grid.Column>
              <Grid.Column width={6}>
                <p>
                  <strong>Rating</strong>
                </p>
                {e.performanceMeasure.map((i, index) => {
                  return <p key={index}>{i.rating}</p>;
                })}
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      })
    );
  };

  return (
    <Segment basic>
      {loading ? (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      ) : (
        <Grid>{generateSummaryDetails()}</Grid>
      )}
    </Segment>
  );
};

const mapStateToProps = state => {
  return {
    selectedReview: state.review.selectedReview,
  };
};

export default connect(mapStateToProps, { fetchReviewOCISummary })(
  ReviewOCISummaryDetails
);
