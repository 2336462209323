import React, { Component } from 'react';
import SlidingContainer from '../../utils/layout/SlidingContainer';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { FieldGroupSelect, FieldGroupDatePicker } from '../../utils/FieldGroup';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import { fetchUsers } from '../../actions/userActions';
import {
  saveFollowupRecommendations,
  fetchReportVersionData,
  fetchReportStatusData,
} from '../../actions/reportActions';
import {
  fetchFollowupRecommendations,
  fetchCreateFollowupReview,
} from '../../actions/reviewActions';
import _ from 'lodash';

import {
  fetchReviewDetail,
  fetchFindingDetails,
  fetchEmailNotifyMpsRequest,
  finalizeFollowupRecommendations,
} from '../../actions/reviewActions';

// Import style
import './assets/style.css';
class ReviewFindingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnlyUsers: [
        'GrantSpecialist',
        'RegionalOfficeStaff',
        'FiscalSpecialist',
        'RegionalProgramManager',
        'ProgramSpecialist',
      ],
      formData: {
        ncCount: 0,
        defCount: 0,
        overallRecommendations: '',
        furRecommendations: [],
        furRecommendationIndex: 0,
      },
      rpmFormRows: [
        {
          NumberOfANCs: 0,
          CorrectiveActionPeriod: 0,
        },
        {
          NumberOfANCs: 0,
          CorrectiveActionPeriod: 0,
        },
      ],
    };

    this.getRecommendations = this.getRecommendations.bind(this);
    this.currentCitation = this.currentCitation.bind(this);
    this.getData = this.getData.bind(this);
  }

  adjustFindingDetails(details, reviewDetails) {
    if (!details) return details;
    let newDetails = [];

    details.forEach(finding => {
      if ('associatedCitations' in finding || 'nonAssocCitations' in finding) {
        (finding.associatedCitations || []).forEach(item => {
          newDetails.push({
            citation: item.associatedCitation || item.nonAssocCitations || null,
            findingId: item.findingId,
            confirmedGrantees: item.confirmedGrantees || [],
            guideName: null,
            performanceMeasure: null,
            findingStatus: (() => {
              let type = finding.type + '';
              if (type.match(/Deficiency/)) return 'DEF';
              if (type.match(/Area\s+of\s+Noncompliance/)) return 'ANC';
              if (type.match(/Area\s+of\s+Concern/)) return 'AOC';

              return type;
            })(finding.type),
            narrative: item.narrative,
            reportDeliveryDate: finding.reportDeliveryDate,
            correctiveActionTimeFrame:
              item.correctiveActionPeriod || finding.correctiveActionPeriod,
            correctiveActionDueDate:
              item.correctionDueDate || finding.correctionDueDate,
            followUpDetermination: null,
          });
        });
      } else {
        newDetails.push(finding);
      }
    });
    return newDetails;
  }

  getRecommendations() {
    let recommendations = [];
    this.state.formData.furRecommendations.forEach(recommendation => {
      (Array.isArray(recommendation.associatedCitations)
        ? recommendation.associatedCitations
        : [recommendation.associatedCitations]
      ).forEach(citation => {
        let i,
          item = {};

        for (i in recommendation) {
          if (i === 'associatedCitations') {
            item[i] =
              typeof citation === 'string'
                ? citation
                : (citation || {}).associatedCitation;
          } else {
            item[i] = recommendation[i];
          }
        }

        recommendations.push(item);
      });
    });

    return recommendations;
  }

  getData() {
    let citations = this.getRecommendations();
    let citsWithDates = [];
    let diffDates = null;
    for (let i = 0; i < citations.length; i++) {
      if (citations[i].furStartDate) {
        citsWithDates.push(citations[i]);
      }
    }

    for (let j = 0; j < citsWithDates.length; j++) {
      // eslint-disable-next-line array-callback-return
      diffDates = citsWithDates.find(el => {
        if (
          el.capDays === citsWithDates[j].capDays &&
          AmsDateFormatters.getMoment(el.furStartDate).format('MM-DD-YY') !==
            AmsDateFormatters.getMoment(citsWithDates[j].furStartDate).format(
              'MM-DD-YY'
            )
        ) {
          j = citsWithDates.length;
          return el;
        }
      });
    }

    if (diffDates) {
      return new Error('Dates do not match');
    } else {
      return {
        reviewId: this.props.params.id, // Review ID
        FollowInfo: {
          ncCount: this.state.formData.ncCount, // Non compliant findings count
          defCount: this.state.formData.defCount,
          // Deficiency Count
          overallRecommendations: this.state.formData.overallRecommendations, //
          furRecommendations: this.getRecommendations()
            .map(citation => {
              // Array of citations
              return JSON.parse(JSON.stringify(citation));
            })
            .map(citation => {
              citation.furLead = citation.furLead || null;
              citation.findingId = citation.findingId || null;
              citation.capDays =
                citation.capDays === undefined ? null : citation.capDays;
              citation.furStartDate =
                citation.furStartDate && citation.furStartDate.toISOString
                  ? citation.furStartDate.toISOString()
                  : citation.furStartDate || null;
              citation.furType = citation.furType || null;

              return citation;
            }),
        },
      };
    }
  }

  componentDidMount() {
    this.props.fetchFollowupRecommendations(this.props.params.id);
    this.props.fetchUsers();
    this.findingDetailsRequested = null;
    this.props.fetchReviewDetail({
      reviewId: this.props.params.id,
    });
    // this.props.fetchReportStatusData(this.props.params.id);
  }

  associatedCitationId(data, compareId) {
    if (data === undefined) {
      throw Error('Undefined associatedCitationId');
    }

    if (typeof data === 'string') {
      data = data.split('§').sort();
    } else if (Array.isArray(data)) {
      data = data.map(item => item.associatedCitation).sort();
    } else if ('associatedCitation' in data) {
      data = data.associatedCitation.split('§');
    }

    if (typeof compareId === 'string') {
      compareId = compareId.split('§').sort();
    } else if (Array.isArray(compareId)) {
      compareId = compareId.map(item => item.associatedCitation).sort();
    } else {
      return data.join('§');
    }

    // find intersection
    let intersection = false;

    []
      .concat(data, compareId)
      .filter(item => !!item)
      .forEach((item, index, arr) => {
        if (index !== arr.indexOf(item)) {
          intersection = true;
        }
      });

    return intersection;
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      !Object.keys(this.props.reportVersionData || {}).length &&
      Object.keys(nextProps.reportVersionData || {}).length
    ) {
    }

    let details =
      ((nextProps || {}).review || {}).fetchedFollowupRecommendations || null;
    let allDetails =
      ((nextProps || {}).review || {}).fetchedFindingDetails || null;

    details = (details || {}).FollowInfo || {};
    if (!this.detailsLoaded /* && details */ && allDetails) {
      allDetails.findingsList = this.adjustFindingDetails(
        allDetails.findingsList || allDetails.findings,
        (this.props.review || {}).selectedReview || {}
      );

      this.detailsLoaded = true;

      let formData = { ...this.state.formData };
      formData.ncCount =
        ['string', 'number'].indexOf(typeof details.ncCount) !== -1
          ? details.ncCount
          : formData.ncCount;
      formData.defCount =
        ['string', 'number'].indexOf(typeof details.defCount) !== -1
          ? details.defCount
          : formData.defCount;
      formData.furRecommendations = details.furRecommendations || [];
      formData.ncCount = allDetails.findingsList.filter(citation =>
        (citation.findingStatus + '').match(/^ANC$/i)
      ).length;
      formData.defCount = allDetails.findingsList.filter(citation =>
        (citation.findingStatus + '').match(/^DEF$/i)
      ).length;

      let citationId = this.props.params.citationId;

      let allDetailsMapped = (allDetails.findingsList || [])
        // .filter(citation => !(citation.type + '').match(/area\s+of\s+concern/i))
        .map(citation => {
          return {
            associatedCitations: citation.citation,
            findingId: citation.findingId,
            citationDueDate:
              citation.correctiveActionDueDate || new Date().toISOString(),
            citationLanguage: citation.associatedCitationDefinition || '',
            citationNarrative: citation.narrative || '',
            supportCorrectionText: '',
            verifyCorrectionText: '',
            findingsType: citation.findingStatus,
            capDays: parseInt(citation.correctiveActionTimeFrame || 0, 10) || 0,
            furStartDate: new Date().toISOString(),
          };
        });

      if (citationId) {
        let finding = allDetails.findingsList.filter(item =>
          this.associatedCitationId(item.citation, citationId)
        )[0];
        formData.defCapDays =
          parseInt(
            ((finding.correctiveActionTimeFrame || '') + '').replace(
              /^\s+/,
              ''
            ) + '',
            10
          ) || 0;

        if (
          formData.furRecommendations.filter(citation =>
            this.associatedCitationId(citation.associatedCitations, citationId)
          ).length === 0
        ) {
          formData.furRecommendations.push({
            associatedCitations: citationId,
            citationDueDate: new Date(
              (
                allDetailsMapped.filter(citation =>
                  this.associatedCitationId(
                    citation.associatedCitations,
                    citationId
                  )
                )[0] || {}
              ).citationDueDate || new Date().valueOf()
            ).toISOString(),
            citationLanguage: '',
            citationNarrative:
              (
                allDetailsMapped.filter(citation =>
                  this.associatedCitationId(
                    citation.associatedCitations,
                    citationId
                  )
                )[0] || {}
              ).citationNarrative || '',
            findingsType:
              (
                allDetailsMapped.filter(citation =>
                  this.associatedCitationId(
                    citation.associatedCitations,
                    citationId
                  )
                )[0] || {}
              ).findingsType || '',
            supportCorrectionText: '',
            verifyCorrectionText: '',
            capDays:
              (
                allDetailsMapped.filter(citation =>
                  this.associatedCitationId(
                    citation.associatedCitations,
                    citationId
                  )
                )[0] || {}
              ).capDays || 0,
            furStartDate: new Date().toISOString(),
          });
        }
      }

      allDetailsMapped.forEach(citation => {
        if (
          formData.furRecommendations.filter(item =>
            this.associatedCitationId(
              item.associatedCitations,
              this.associatedCitationId(citation.associatedCitations)
            )
          ).length === 0
        ) {
          formData.furRecommendations.push({
            associatedCitations: citation.associatedCitations,
            findingId: citation.findingId,
            citationDueDate:
              citation.citationDueDate || new Date().toISOString(),
            citationLanguage: citation.citationLanguage || '',
            citationNarrative: citation.citationNarrative || '',
            supportCorrectionText: '',
            verifyCorrectionText: '',
            capDays: citation.capDays || 0,
            findingsType: citation.findingsType,
            furStartDate: new Date().toISOString(),
          });
        }
      });

      let citationOrder = allDetailsMapped.map(
        citation => citation.associatedCitations
      );

      formData.furRecommendations = formData.furRecommendations.sort((a, b) => {
        let va = citationOrder.indexOf(
          this.associatedCitationId(a.associatedCitations, true)
        );
        let vb = citationOrder.indexOf(
          this.associatedCitationId(b.associatedCitations, true)
        );

        return va - vb;
      });

      if (citationId) {
        formData.furRecommendations.forEach((citation, index) => {
          if (
            this.associatedCitationId(citation.associatedCitations, citationId)
          ) {
            formData.furRecommendationIndex = index;
          }
        });
      }
      formData.overallRecommendations = (
        ((this.props.review || {}).fetchedFollowupRecommendations || {})
          .FollowInfo || {}
      ).overallRecommendations;
      formData.furRecommendations = [...formData.furRecommendations].filter(
        cit => cit.findingsType !== 'DROPPED' && cit.findingsType !== 'AOC'
      );
      this.setState({ formData: formData });
    }
  }

  currentCitation(prop) {
    let formData = this.state.formData;
    let citation = null;
    let citationId = this.associatedCitationId(
      (formData.furRecommendations[formData.furRecommendationIndex] || {})
        .associatedCitations || ''
    );

    if (citationId !== undefined) {
      let allDetails =
        ((this.props || {}).review || {}).fetchedFindingDetails || null;

      if (allDetails) {
        allDetails.findingsList = this.adjustFindingDetails(
          allDetails.findingsList || allDetails.findings,
          (this.props.review || {}).selectedReview || {}
        );

        citation =
          allDetails.findingsList
            // .filter(citation => !(citation.type + '').match(/area\s+of\s+concern/i))
            .filter(citation =>
              this.associatedCitationId(citation.citation, citationId)
            )[0] || null;
      }
    }

    if (prop) {
      return (citation || {})[prop];
    }

    return citation;
  }

  specialistLookup = param => {
    let specialist = null;
    let specialistIndex = this.props.users.findIndex(function(user) {
      return user.oid === param;
    });
    if (specialistIndex === -1) {
      specialist = param;
    } else {
      specialist = this.props.users[specialistIndex].name;
    }
    return specialist;
  };

  statusFormatter = status => {
    let findingStatus = null;
    if (status === 'Preliminary Area of Noncompliance') {
      findingStatus = 'PANC';
    }
    if (status === 'Area of Noncompliance') {
      findingStatus = 'ANC';
    }
    if (status === 'Area of Concern') {
      findingStatus = 'AOC';
    }
    if (status === 'Deficiency') {
      findingStatus = 'DEF';
    }
    return findingStatus;
  };

  render() {
    let validatedFields = (
      (((this.props || {}).review || {}).fetchedFollowupRecommendations || {})
        .FollowInfo || {}
    ).furRecommendations;
    let validator = [];
    let isValidForRPM = null;
    if (validatedFields) {
      validator = validatedFields.map(fur => {
        if (!fur.furStartDate || !fur.furLead || !fur.furType) {
          return false;
        }
        return true;
      });
      isValidForRPM = validator.findIndex(function(el) {
        return el === false;
      });
    }
    let details =
      (this.props.review || {}).fetchedFollowupRecommendations || null;
    let reviewType =
      ((this.props.review || {}).selectedReview || {}).reviewType || '';

    if (
      reviewType &&
      !this.findingDetailsRequested &&
      !isEmpty(this.props.params.id)
    ) {
      this.findingDetailsRequested = true;
      this.props.fetchFindingDetails(
        {
          reviewId: this.props.params.id,
        },
        !this.props.review.selectedReview.newFAFlowFlag,
        { reviewType: reviewType }
      );
    }

    let daysLeft = Math.max(
      0,
      ((this.props.review || {}).fetchedFindingDetails || {}).daysToComplete ||
        0
    );
    if (daysLeft === 0) {
      daysLeft = 'past due';
    }

    if (!details) return <div className="well">Loading...</div>;

    if (!this.state.formData.furRecommendations.length)
      return <div className="well">Loading Recommendations...</div>;

    let roData = null;

    if (
      (
        (
          (this.props.review || {}).fetchedFollowupRecommendations.FollowInfo ||
          {}
        ).RPMFeedbackInfo || {}
      ).followUpReviewer === 'RO'
    ) {
      roData = (
        <span>
          <p>
            Program Specialist is{' '}
            {this.specialistLookup(
              (
                (
                  (this.props.review || {}).fetchedFollowupRecommendations
                    .FollowInfo || {}
                ).RPMFeedbackInfo || {}
              ).programSpecialist
            )}
          </p>
          <p style={{ marginBottom: '1em' }}>
            Grantee Specialist is{' '}
            {this.specialistLookup(
              (
                (
                  (this.props.review || {}).fetchedFollowupRecommendations
                    .FollowInfo || {}
                ).RPMFeedbackInfo || {}
              ).grantSpecialist || 'not applicable'
            )}
          </p>
        </span>
      );
    }

    let grantees = (
      ((this.props.review || {}).selectedReview || {}).grantees || []
    ).map(grantee => {
      return ` ${grantee.granteeName} (${grantee.granteeId})`;
    });

    return (
      <SlidingContainer
        calendar
        title={
          grantees + '| ' + reviewType + ' ( ' + this.props.params.id + ' ) '
        }
      >
        <div className="row">
          <div className="col-sm-12">
            <p>
              <b>
                Review status:{' '}
                {((this.props.review || {}).selectedReview || {}).reviewStatus}
              </b>
            </p>
            <p>
              <b>
                Date report received by Grantee:{' '}
                {((this.props.review || {}).selectedReview || {})
                  .reportReceivedDate ? (
                  AmsDateFormatters.formatDate(
                    ((this.props.review || {}).selectedReview || {})
                      .reportReceivedDate
                  )
                ) : (
                  <i>no date recieved from backend</i>
                )}
              </b>
            </p>
            <p>
              <b>Days till due date: {daysLeft}</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">&nbsp;</div>
        </div>
        <div
          className={
            'row' +
            (!this.state.editableCitations ? ' readonly-citattion-form' : '')
          }
        >
          <div className="col-sm-6 label-block">
            <div>
              <label className="label label-default">Non-Compliance</label>

              <div className="row">
                <div className="col-sm-6">
                  <p>Number of Non-Compliance(S):</p>
                </div>
                <div className="col-sm-6">
                  <p>{this.state.formData.ncCount}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <p>Corrective Action Period Days:</p>
                </div>
                <div className="col-sm-6">
                  <FieldGroupSelect
                    closeOnSelect={true}
                    className={
                      (this.currentCitation('findingStatus') + '').match(/DEF/i)
                        ? 'inactive'
                        : ''
                    }
                    value={
                      (this.currentCitation('findingStatus') + '').match(/DEF/i)
                        ? 0
                        : (
                            this.state.formData.furRecommendations[
                              this.state.formData.furRecommendationIndex
                            ] || {}
                          ).capDays ||
                          parseInt(this.currentCitation('capDays'), 10) ||
                          0
                    }
                    multi={false}
                    matchProp={'label'}
                    options={[...Array(367).keys()].map(v => {
                      return {
                        value: v,
                        label: v || '-',
                      };
                    })}
                    onChange={ev => {
                      let formData = this.state.formData;
                      formData.capDays = ev.value;
                      if (
                        formData.furRecommendations[
                          formData.furRecommendationIndex
                        ]
                      ) {
                        formData.furRecommendations[
                          formData.furRecommendationIndex
                        ].capDays = ev.value;
                      }
                      this.setState({ formData: formData });
                    }}
                    label=""
                    placeholder="number of days"
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 label-block">
            <div>
              <label className="label label-default">Deficiency</label>

              <div className="row">
                <div className="col-sm-6">
                  <p>Number of Deficiency(s):</p>
                </div>
                <div className="col-sm-6">
                  <p>{this.state.formData.defCount}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <p>Corrective Action Period Days:</p>
                </div>
                <div className="col-sm-6">
                  <FieldGroupSelect
                    closeOnSelect={true}
                    className={
                      (this.currentCitation('findingStatus') + '').match(
                        /^ANC$/i
                      )
                        ? 'inactive'
                        : ''
                    }
                    value={
                      (this.currentCitation('findingStatus') + '').match(
                        /^ANC$/i
                      )
                        ? 0
                        : (
                            this.state.formData.furRecommendations[
                              this.state.formData.furRecommendationIndex
                            ] || {}
                          ).capDays ||
                          parseInt(this.currentCitation('capDays'), 10) ||
                          0
                    }
                    multi={false}
                    matchProp={'label'}
                    options={[...Array(367).keys()].map(v => {
                      return {
                        value: v,
                        label: v || '-',
                      };
                    })}
                    onChange={ev => {
                      let formData = this.state.formData;
                      formData.capDays = ev.value;
                      if (
                        formData.furRecommendations[
                          formData.furRecommendationIndex
                        ]
                      ) {
                        formData.furRecommendations[
                          formData.furRecommendationIndex
                        ].capDays = ev.value;
                      }
                      this.setState({ formData: formData });
                    }}
                    label=""
                    placeholder="number of days"
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            'row' +
            (!this.state.editableCitations ? ' readonly-citattion-form' : '')
          }
        >
          <div className="col-sm-6 label-block">
            <div>
              <label className="label label-default">
                Follow-up Review data
              </label>

              <div className="row">
                <div className="col-sm-6">
                  <p>Recommended Review Type:</p>
                </div>
                <div className="col-sm-6">
                  <FieldGroupSelect
                    closeOnSelect={true}
                    value={
                      (
                        this.state.formData.furRecommendations[
                          this.state.formData.furRecommendationIndex
                        ] || {}
                      ).furType || null
                    }
                    multi={false}
                    matchProp={'label'}
                    options={[
                      'Desk/FTL Solo',
                      'Desk Review',
                      'FTL Solo',
                      'OnSite Follow-up',
                    ].map(v => {
                      return {
                        value: v,
                        label: v,
                      };
                    })}
                    onChange={ev => {
                      let formData = this.state.formData;
                      formData.furRecommendations[
                        formData.furRecommendationIndex
                      ].furType = ev.value;
                      this.setState({ formData: formData });
                    }}
                    label=""
                    placeholder="Review Type"
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <p>Follow-up Lead:</p>
                </div>
                <div className="col-sm-6">
                  <FieldGroupSelect
                    closeOnSelect={true}
                    value={
                      (
                        this.state.formData.furRecommendations[
                          this.state.formData.furRecommendationIndex
                        ] || {}
                      ).furLead || null
                    }
                    multi={false}
                    matchProp={'label'}
                    options={(() => {
                      let users = this.props.users;
                      if (Array.isArray(users) && users.length) {
                        return users
                          .filter(
                            user =>
                              (user.roles || []).indexOf('FollowupLead') !== -1
                          )
                          .map(user => {
                            return {
                              value: user.oid,
                              label:
                                user.name ||
                                [user.firstName, user.lastName]
                                  .filter(v => !!v)
                                  .join(' ') ||
                                user.oid,
                            };
                          });
                      }
                      return [];
                    })()}
                    onChange={ev => {
                      let formData = this.state.formData;
                      formData.furRecommendations[
                        formData.furRecommendationIndex
                      ].furLead = ev.value;
                      this.setState({ formData: formData });
                    }}
                    label=""
                    placeholder="Follow-up Lead"
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <p>Proposed Start Date of Review:</p>
                </div>
                <div className="col-sm-6">
                  <FieldGroupDatePicker
                    value={new Date(
                      new Date(
                        (
                          this.state.formData.furRecommendations[
                            this.state.formData.furRecommendationIndex
                          ] || {}
                        ).furStartDate
                      ).valueOf() || new Date()
                    ).toISOString()}
                    dateFormat="MM-DD-YYYY"
                    onChange={date => {
                      let formData = this.state.formData;
                      formData.furRecommendations[
                        formData.furRecommendationIndex
                      ].furStartDate = date;
                      this.setState({ formData: formData });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 label-block">
            <div>
              <label className="label label-default">
                Overall Follow-up Recommendation
              </label>

              <div className="row">
                <div className="col-sm-12">
                  <textarea
                    className="col-sm-12"
                    value={this.state.formData.overallRecommendations || ''}
                    onChange={ev => {
                      let formData = this.state.formData;
                      formData.overallRecommendations = ev.target.value;
                      this.setState({
                        formData: formData,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 label-block">
            <div>
              <label className="label label-default">Citations</label>
            </div>
          </div>
        </div>

        <div className="row progress-bar-blocks">
          {(stepsNum => {
            // let details = (this.props.review || {}).fetchedFindingDetails || null;
            //
            // if (!details) {
            //   return (
            //     <div>Loading ...</div>
            //   );
            // }

            let steps = [];
            let showAddButton = 0;
            let width =
              Math.floor(100 / (stepsNum + showAddButton)).toFixed(1) + '%';
            if (showAddButton)
              steps.push(
                <div
                  onClick={ev => {
                    let formData = this.state.formData;
                    formData.furRecommendations.push({
                      associatedCitations: new Date().valueOf().toString(36), // Associated Citation
                      citationDueDate: new Date().toISOString(), // due date
                      citationLanguage: '', // citation language
                      citationNarrative: '', // citation narrative
                      supportCorrectionText: '', // supported correction text
                      verifyCorrectionText: '', // verify correction text
                    });
                    formData.furRecommendationIndex =
                      formData.furRecommendations.length - 1;
                    this.setState({
                      formData: formData,
                    });
                  }}
                  key={0}
                  style={{ width: width }}
                >
                  <u>Add Recommendation</u>
                </div>
              );
            for (let i = 0; i < stepsNum; i++) {
              steps.push(
                <div
                  style={{ width: width }}
                  className={
                    this.state.formData.furRecommendationIndex === i
                      ? 'selected'
                      : ''
                  }
                  key={i + 1}
                  onClick={(index => {
                    return () => {
                      let formData = this.state.formData;
                      let citation = formData.furRecommendations[index];
                      let allDetails =
                        (this.props.review || {}).fetchedFindingDetails || null;

                      allDetails.findingsList = this.adjustFindingDetails(
                        allDetails.findingsList || allDetails.findings,
                        (this.props.review || {}).selectedReview || {}
                      );

                      let finding = allDetails.findingsList
                        //.filter(citation => !(citation.type + '').match(/area\s+of\s+concern/i))
                        .filter(item =>
                          this.associatedCitationId(
                            item.citation,
                            this.associatedCitationId(
                              citation.associatedCitations
                            )
                          )
                        )[0];
                      if (!finding) {
                        alert(
                          'Info » WILL BE REMOVED. incorrectly created item from previous logic'
                        );
                        formData.furRecommendations.splice(index, 1);
                        formData.furRecommendationIndex = Math.max(
                          0,
                          Math.min(
                            formData.furRecommendationIndex,
                            formData.furRecommendations.length - 1
                          )
                        );
                        this.setState({
                          formData: formData,
                        });
                        return;
                      }
                      formData.defCapDays =
                        parseInt(
                          (
                            (finding.correctiveActionTimeFrame || '0') + ''
                          ).replace(/^\s+/, '') + '',
                          10
                        ) || 0;
                      formData.furRecommendationIndex = index;
                      this.setState({
                        formData: formData,
                        furRecommendationIndex: index,
                      });
                    };
                  })(i + 0)}
                >
                  {Array.isArray(
                    this.state.formData.furRecommendations[i]
                      .associatedCitations
                  )
                    ? this.state.formData.furRecommendations[i]
                        .associatedCitations
                    : [
                        this.state.formData.furRecommendations[i]
                          .associatedCitations,
                      ].map((item, index) => {
                        return (
                          <b key={index}>
                            {' '}
                            {typeof item === 'string' ? (
                              <div>
                                <span className="citation-type-title">
                                  {
                                    this.state.formData.furRecommendations[i]
                                      .findingsType
                                  }
                                </span>{' '}
                                {item}
                              </div>
                            ) : (
                              item.associatedCitation
                            )}{' '}
                          </b>
                        );
                      })}{' '}
                  <span className="item-of-items">
                    ({i + 1} of {stepsNum})
                  </span>
                </div>
              );
            }
            return steps;
          })(this.state.formData.furRecommendations.length)}
        </div>

        {this.state.formData.furRecommendations[
          this.state.formData.furRecommendationIndex
        ] ? (
          <div
            className={
              'row' +
              (!this.state.editableCitations ? ' readonly-citattion-form' : '')
            }
          >
            <div className="col-sm-12 label-block">
              <div>
                <p>
                  <b>
                    Citation due date:{' '}
                    {AmsDateFormatters.formatDate(
                      this.state.formData.furRecommendations[
                        this.state.formData.furRecommendationIndex
                      ].citationDueDate
                    )}
                  </b>
                </p>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.formData.furRecommendations[
          this.state.formData.furRecommendationIndex
        ] ? (
          <div
            className={
              'row' +
              (!this.state.editableCitations ? ' readonly-citattion-form' : '')
            }
          >
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">Citation Language</label>
                <p>
                  {this.state.formData.furRecommendations[
                    this.state.formData.furRecommendationIndex
                  ].citationLanguage || ''}
                </p>
              </div>
            </div>
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">
                  Citation Narrative
                </label>
                <textarea
                  className="col-sm-12"
                  value={
                    this.state.formData.furRecommendations[
                      this.state.formData.furRecommendationIndex
                    ].citationNarrative || ''
                  }
                  onChange={ev => {
                    let formData = this.state.formData;
                    formData.furRecommendations[
                      this.state.formData.furRecommendationIndex
                    ].citationNarrative = ev.target.value;
                    this.setState({
                      formData: formData,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}

        {this.state.formData.furRecommendations[
          this.state.formData.furRecommendationIndex
        ] ? (
          <div
            className={
              'row' +
              (!this.state.editableCitations ? ' readonly-citattion-form' : '')
            }
          >
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">
                  Activities to support Correction
                </label>
                <textarea
                  className="col-sm-12"
                  value={
                    this.state.formData.furRecommendations[
                      this.state.formData.furRecommendationIndex
                    ].supportCorrectionText || ''
                  }
                  onChange={ev => {
                    let formData = this.state.formData;
                    formData.furRecommendations[
                      this.state.formData.furRecommendationIndex
                    ].supportCorrectionText = ev.target.value;
                    this.setState({
                      formData: formData,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 label-block">
              <div>
                <label className="label label-default">
                  Activities to verify Correction
                </label>
                <textarea
                  className="col-sm-12"
                  value={
                    this.state.formData.furRecommendations[
                      this.state.formData.furRecommendationIndex
                    ].verifyCorrectionText || ''
                  }
                  onChange={ev => {
                    let formData = this.state.formData;
                    formData.furRecommendations[
                      this.state.formData.furRecommendationIndex
                    ].verifyCorrectionText = ev.target.value;
                    this.setState({
                      formData: formData,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-sm-12" style={{ 'text-align': 'center' }}>
            {Array.isArray(this.state.errorMessages) ? (
              <div className="alert alert-danger">
                {this.state.errorMessages.map((message, index) => {
                  return <p key={index}>{message}</p>;
                })}
              </div>
            ) : null}

            {(() => {
              if (
                (
                  (
                    (this.props.review || {}).fetchedFollowupRecommendations ||
                    {}
                  ).FollowInfo || {}
                ).status === 'rpmCommented' ||
                (
                  (
                    (this.props.review || {}).fetchedFollowupRecommendations ||
                    {}
                  ).FollowInfo || {}
                ).status === 'Finalized'
              ) {
                return (
                  <div className="row label-row">
                    <label className="rpm-input-label">RPM input</label>
                    <hr />
                    <span className="rpm-review-info align-left">
                      <p>
                        Review ID:{' '}
                        {
                          ((this.props.review || {}).selectedReview || {})
                            .reviewId
                        }
                      </p>
                      <p>
                        Total number of ANC Findings:{' '}
                        {this.state.formData.ncCount}
                      </p>
                      <p>
                        Total number of DEF Findings:{' '}
                        {this.state.formData.defCount}
                      </p>
                    </span>
                    <br />
                    <span className="rpm-review-info align-left">
                      <p>
                        Follow-up review for these findings is conducted by{' '}
                        {
                          (
                            (
                              (this.props.review || {})
                                .fetchedFollowupRecommendations.FollowInfo || {}
                            ).RPMFeedbackInfo || {}
                          ).followUpReviewer
                        }
                      </p>
                      {roData}
                      <p>
                        Point of contact Program Specialist is{' '}
                        {this.specialistLookup(
                          (
                            (
                              (this.props.review || {})
                                .fetchedFollowupRecommendations.FollowInfo || {}
                            ).RPMFeedbackInfo || {}
                          ).pocProgramSpecialist
                        )}
                      </p>
                    </span>
                  </div>
                );
              }
            })()}

            {(() => {
              if (
                (
                  (
                    (this.props.review || {}).fetchedFollowupRecommendations ||
                    {}
                  ).FollowInfo || {}
                ).status === 'Finalized'
              )
                return null;

              if (
                ((this.props.currentUser || {}).roles || []).indexOf(
                  'FollowupLead'
                ) === -1 &&
                ((this.props.currentUser || {}).roles || []).indexOf(
                  'AmsAdmi'
                ) === -1
              ) {
                return null;
              }
              let editButton = (
                <button
                  className="btn btn-sm btn-primary"
                  style={{
                    display:
                      this.props.notificationStatus.followInfoStatus ===
                      'rpmNotified'
                        ? 'none'
                        : 'inline-block',
                  }}
                  onClick={ev => {
                    if (this.state.editableCitations) {
                      let errs = [];
                      let data = this.getData();
                      if (errs.length) {
                        return this.setState({
                          errorMessages: errs,
                        });
                      }
                      if (!(data instanceof Error)) {
                        this.props.saveFollowupRecommendations(data, function(
                          data
                        ) {
                          if (data instanceof Error) {
                            alert('Error: ' + data.message);
                          } else {
                            if (
                              ((data || {}).data || {}).result === 'Success'
                            ) {
                              alert('Successfully saved');
                            } else {
                              alert('Warning: saved, unclear status');
                            }
                          }
                        });
                      } else {
                        alert(
                          'Same cap days citations must have same Proposed Start Date of Review!'
                        );
                      }
                      this.setState({
                        errorMessages: null,
                        editableCitations: false,
                      });
                    } else {
                      this.setState({
                        editableCitations: true,
                      });
                    }
                  }}
                >
                  {this.state.editableCitations ? 'Save and update' : 'Edit'}
                </button>
              );
              let recsStatus =
                ((this.props.review || {}).fetchedFollowupRecommendations || {})
                  .FollowInfo || {}.status;
              let fetchedFurRecs =
                (this.props.review || {}).fetchedFollowupRecommendations ||
                {}.FollowInfo ||
                {};
              if (fetchedFurRecs.FollowInfo) {
                if (
                  recsStatus.status === 'rpmCommented' ||
                  recsStatus.status === 'rpmNotified' ||
                  _.intersection(
                    this.state.readOnlyUsers,
                    (this.props.currentUser || {}).roles
                  ).length !== 0
                ) {
                  editButton = null;
                }
              }

              return <div>{editButton}</div>;
            })()}

            <br />
            <br />
            {(() => {
              if (
                (
                  (
                    (this.props.review || {}).fetchedFollowupRecommendations ||
                    {}
                  ).FollowInfo || {}
                ).status === 'Finalized'
              )
                return null;

              if (
                ((this.props.currentUser || {}).roles || []).indexOf(
                  'FollowupLead'
                ) === -1 &&
                ((this.props.currentUser || {}).roles || []).indexOf(
                  'AmsAdmi'
                ) === -1
              ) {
                return null;
              }

              let sendToRPM = (
                <span>
                  <button
                    className="btn btn-sm btn-default"
                    style={
                      this.props.notificationStatus.followInfoStatus ===
                      'rpmNotified'
                        ? { display: 'none' }
                        : { display: 'inline-block' }
                    }
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to send this FUR for regional feedback and planning?'
                        )
                      ) {
                        this.props.fetchEmailNotifyMpsRequest(
                          {
                            reviewId: this.props.params.id,
                          },
                          data => {
                            if (data instanceof Error) {
                              alert('Error: ' + data.message);
                            } else {
                              alert('Notification submitted');
                            }
                          }
                        );
                      }
                    }}
                    disabled={
                      (
                        (
                          (this.props.review || {})
                            .fetchedFollowupRecommendations || {}
                        ).FollowInfo || {}
                      ).status === 'rpmCommented' ||
                      (
                        (
                          (this.props.review || {})
                            .fetchedFollowupRecommendations || {}
                        ).FollowInfo || {}
                      ).status === 'rpmNotified' ||
                      isValidForRPM === null ||
                      isValidForRPM !== -1 ||
                      this.state.editableCitations
                    }
                  >
                    Send to Regional Planning
                  </button>
                </span>
              );
              let rpmStatus = (
                ((this.props.review || {}).fetchedFollowupRecommendations || {})
                  .FollowInfo || {}
              ).status;

              if (
                rpmStatus === 'rpmNotified' ||
                rpmStatus === 'rpmCommented' ||
                rpmStatus === 'Fur Recs Complete' ||
                _.intersection(
                  this.state.readOnlyUsers,
                  (this.props.currentUser || {}).roles
                ).length !== 0
              ) {
                sendToRPM = null;
              }

              return <span>{sendToRPM}</span>;
            })()}
            {(() => {
              let rpmStatus = (
                ((this.props.review || {}).fetchedFollowupRecommendations || {})
                  .FollowInfo || {}
              ).status;

              if (rpmStatus === 'Finalized') {
                return (
                  <a
                    href={`/review/downloadFurRecs/${this.props.params.id}`}
                    download={`${this.props.params.id}.pdf`}
                    className="btn btn-sm btn-default margin-top-10"
                  >
                    Download PDF FURs
                  </a>
                );
              }

              if (
                rpmStatus === 'rpmNotified' ||
                rpmStatus === 'rpmCommented' ||
                this.props.notificationStatus.followInfoStatus === 'rpmNotified'
              ) {
                return (
                  <span>
                    <span>
                      <button
                        disabled={
                          rpmStatus !== 'rpmCommented' ||
                          this.state.finalizedClicked
                        }
                        style={
                          (this.props.notificationStatus || {})
                            .followInfoStatus === 'Finalized' ||
                          rpmStatus === 'rpmNotified' ||
                          (this.props.notificationStatus || {})
                            .followInfoStatus === 'rpmNotified' ||
                          _.intersection(
                            this.state.readOnlyUsers,
                            (this.props.currentUser || {}).roles
                          ).length !== 0
                            ? { display: 'none' }
                            : null
                        }
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to submit the Follow-up recommendations? This will result in the creation of review(s) based on your recommendations and assign selected team members.'
                            )
                          ) {
                            this.props.finalizeFollowupRecommendations({
                              reviewId: this.props.params.id,
                            });
                            this.setState({ finalizedClicked: true });
                          } else {
                            return null;
                          }
                        }}
                        className="btn btn-sm btn-default margin-left-10"
                      >
                        Finalize
                      </button>
                    </span>
                    <br />
                    <a
                      href={`/review/downloadFurRecs/${this.props.params.id}`}
                      download={`${this.props.params.id}.pdf`}
                      className="btn btn-sm btn-default margin-top-10"
                    >
                      Download PDF FURs
                    </a>
                  </span>
                );
              }
            })()}
          </div>
        </div>
      </SlidingContainer>
    );
  }
}

const mapStateToProps = state => ({
  review: state.review,
  users: state.users.users,
  currentUser: state.auth.user,
  savedFollowupRecommendations: state.report.savedFollowupRecommendations,
  reportVersionData: state.report.reportVersionData,
  reportStatusData: state.report.reportStatusData,
  notificationStatus: state.review.notificationStatus,
});

export default connect(mapStateToProps, {
  fetchUsers,
  saveFollowupRecommendations,
  fetchReviewDetail,
  fetchFollowupRecommendations,
  fetchFindingDetails,
  fetchReportVersionData,
  fetchReportStatusData,
  fetchEmailNotifyMpsRequest,
  fetchCreateFollowupReview,
  finalizeFollowupRecommendations,
})(ReviewFindingsPage);
