import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Loader,
  Dimmer,
  Form,
  Segment,
  Dropdown,
  Button,
} from 'semantic-ui-react';

// Import utils.
import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import AmsTable from '../../utils/AmsTable';

// Import actions.
import { selectTab } from '../../actions/adminActions';
import {
  deleteSurveySubmission,
  fetchAllSubmissions,
} from '../../actions/surveyActions';

// Import components.

const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

class SurveyAdminPage extends Component {
  state = {
    selecterReviewTypes: [],
    names: '',
    status: '',
    reviewId: '',
    fromDate: null,
    toDate: null,
    start: 0,
    rows: 99,
    showErrorAlert: false,
    loading: true,
    orderBy: 'reviewType',
    responseErrors: [],
    deleteData: {},
  };

  componentWillMount() {
    this.props.selectTab({
      key: 1,
      pageTitle: 'Surveys',
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const {
      selecterReviewTypes,
      names,
      status,
      reviewId,
      fromDate,
      toDate,
      rows,
      start,
      orderBy,
      granteeSelected,
    } = this.state;

    let requestInput = {
      rows,
      start,
      orderBy,
    };

    if (!_.isEmpty(selecterReviewTypes)) {
      requestInput.filters = {
        ...requestInput.filters,
        reviewTypes: selecterReviewTypes,
      };
    }

    if (!_.isEmpty(names)) {
      requestInput.filters = { ...requestInput.filters, surveyName: names };
    }

    if (!_.isEmpty(status)) {
      requestInput.filters = { ...requestInput.filters, surveyStatus: status };
    }

    if (!_.isEmpty(fromDate)) {
      requestInput.filters = {
        ...requestInput.filters,
        fromDate: AmsDateFormatters.getMoment(fromDate).format('YYYY-MM-DD'),
      };
    }

    if (!_.isEmpty(toDate)) {
      requestInput.filters = {
        ...requestInput.filters,
        toDate: AmsDateFormatters.getMoment(toDate).format('YYYY-MM-DD'),
      };
    }

    if (!_.isEmpty(reviewId)) {
      requestInput.filters = { ...requestInput.filters, reviewId };
    }

    if (!_.isEmpty(granteeSelected)) {
      requestInput.filters = {
        ...requestInput.filters,
        granteeName: granteeSelected.value,
      };
    }

    this.props
      .fetchAllSubmissions(requestInput)
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({
          loading: false,
          responseErrors: [...this.state.responseErrors, error.response.data],
        });
      });
  }

  handleFilterSubmit() {
    this.getData();
  }

  handleFilterReset() {
    this.setState(
      {
        selecterReviewTypes: [],
        names: '',
        status: '',
        reviewId: '',
        fromDate: null,
        toDate: null,
        granteeName: null,
      },
      () => this.handleFilterSubmit()
    );
  }

  showErrorAlert() {
    const { showErrorAlert, errorMessage } = this.state;

    return (
      <AmsAlert
        show={showErrorAlert}
        title="Oops!"
        text={errorMessage}
        type={'error'}
        showConfirm
        onCancel={() => {
          this.setState({ showErrorAlert: false, errorMessage: '' });
        }}
        onConfirm={() => {
          this.setState({ showErrorAlert: false, errorMessage: '' });
        }}
      />
    );
  }

  handleSurveyNameChange(value) {
    this.setState({ names: value });
  }

  handleSurveyStatusChange(value) {
    this.setState({ status: value });
  }

  handleFromDateChange = value => {
    if (!value) {
      this.setState({ fromDate: null });
      return;
    }
    this.setState({
      fromDate: AmsDateFormatters.getMoment(value).startOf('day'),
    });
  };

  handleToDateChange = (value, formattedValue) => {
    if (!value) {
      this.setState({ toDate: null });
      return;
    }
    this.setState({
      toDate: AmsDateFormatters.getMoment(value).startOf('day'),
    });
  };

  showPageFilters() {
    const {
      selecterReviewTypes,
      names,
      status,
      reviewId,
      fromDate,
      toDate,
      granteeSelected,
    } = this.state;

    return (
      <Segment>
        {/* <Well className="page-filters"> */}
        <Form className="filter-form">
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />

          <Form.Group widths="equal">
            <Form.Field
              name="fromDate"
              control={DatePicker}
              label={{
                children: 'Start date',
                htmlFor: 'fromDate',
              }}
              selectsStart
              placeholderText="Select Start Date"
              selected={fromDate}
              fromDate={fromDate}
              onChange={this.handleFromDateChange}
              id="fromDate"
              aria-labelledby="fromDate"
            />

            <Form.Field
              name="toDate"
              control={DatePicker}
              label={{
                children: 'End date',
                htmlFor: 'toDate',
              }}
              selectsStart
              placeholderText="Select End Date"
              selected={toDate}
              fromDate={fromDate}
              minDate={fromDate}
              onChange={this.handleToDateChange}
              id="toDate"
              aria-labelledby="toDate"
            />
            <Form.Field>
              <Form.Input
                label={{ children: 'Review ID', htmlFor: 'reviewId' }}
                value={reviewId}
                onChange={(e, { value }) => this.setState({ reviewId: value })}
                id="reviewId"
                placeholder="Type Review ID"
                aria-labelledby="reviewId"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <AmsFormLabel name="Review Type" fieldLabel="filterReviewType">
                <AmsLookupDropdown
                  aria-labelledby="filterReviewType"
                  placeholder="Review Types"
                  value={selecterReviewTypes}
                  multiple
                  search
                  selection
                  category={'reviewTypes'}
                  onChange={(e, { value }) =>
                    this.setState({
                      selecterReviewTypes: value,
                    })
                  }
                  searchInput={{
                    id: 'filterReviewType',
                    title: 'Select a Review Type',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>

            <Form.Field
              label={{
                children: 'Survey Name',
                htmlFor: 'surveyNames',
              }}
              placeholder="Select Survey Name"
              value={names}
              selection
              control={AmsLookupDropdown}
              category={'surveyNames'}
              onChange={(e, { value }) =>
                this.setState({
                  names: value,
                })
              }
              id="surveyNames"
              aria-labelledby="surveyNames"
            />
            <Form.Field
              label={{
                children: 'Survey Status',
                htmlFor: 'surveyStatus',
              }}
              placeholder="Select Survey Status"
              value={status}
              selection
              category={'surveyStatus'}
              control={AmsLookupDropdown}
              onChange={(e, { value }) =>
                this.setState({
                  status: value,
                })
              }
              id="surveyStatus"
              aria-labelledby="surveyStatus"
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              width={5}
              label={{
                children: 'Grantee Name',
                htmlFor: 'grantee',
              }}
              placeholder="Select Grantee Name"
              value={this.state.granteeSelected}
              options={(this.props.allSubmission || []).map((v, index) => {
                return {
                  text: v.granteeName,
                  value: v.granteeName,
                  key: index,
                };
              })}
              onChange={(e, { value }) => {
                this.setState({ granteeSelected: value });
              }}
              control={Dropdown}
              fluid
              // clearable
              selection
              id="grantee"
              aria-labelledby="grantee"
              selectOnBlur={false}
              openOnFocus={false}
            />

            <Form.Group>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  primary
                  disabled={
                    !(
                      selecterReviewTypes.length ||
                      granteeSelected ||
                      names ||
                      status ||
                      reviewId ||
                      (fromDate && toDate)
                    )
                  }
                  onClick={this.handleFilterSubmit.bind(this)}
                >
                  Filter
                </Form.Button>
              </Form.Field>

              <Form.Field>
                <Form.Button
                  className="filter-button"
                  basic
                  onClick={this.handleFilterReset.bind(this)}
                >
                  Reset
                </Form.Button>
              </Form.Field>
            </Form.Group>
          </Form.Group>

          {/* <div className="row">
            <div className="col-sm-4 field-form-group">
              <FieldGroupSelect
                closeOnSelect={true}
                id="grantee"
                value={this.state.granteeSelected}
                multi={false}
                matchProp={'label'}
                options={(this.props.allSubmission || []).map(v => {
                  return { label: v.granteeName, value: v.granteeName };
                })}
                onChange={value => {
                  this.setState({ granteeSelected: value });
                }}
                label="Grantee Name"
                placeholder="Select Grantee Name"
                searchable={false}
                clearable
              />
            </div>
            <div className="col-sm-8">
              <Form.Group className="filter-wrapper">
                <Form.Field>
                  <Form.Button
                    className="filter-button"
                    primary
                    disabled={
                      !(
                        selecterReviewTypes.length ||
                        granteeSelected ||
                        names ||
                        status ||
                        reviewId ||
                        (fromDate && toDate)
                      )
                    }
                    onClick={this.handleFilterSubmit.bind(this)}
                  >
                    Filter
                  </Form.Button>
                </Form.Field>

                <Form.Field>
                  <Form.Button
                    className="filter-button"
                    basic
                    onClick={this.handleFilterReset.bind(this)}
                  >
                    Reset
                  </Form.Button>
                </Form.Field>
              </Form.Group>
            </div>
          </div>

          <div className="clearfix" /> */}
        </Form>
        {/* </Well> */}
      </Segment>
    );
  }

  renderSurveyTable() {
    const { allSubmission } = this.props;

    const linkId = (cell, row) => <Link to={`/review/${cell}`}>{cell}</Link>;

    const deleteFormatter = (cell, row) =>
      !_.isEmpty(row) &&
      row.reviewId &&
      row.koboFormId &&
      row.submissionId && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className="delete-link"
          href=""
          onClick={event => {
            event.preventDefault();
            this.setState({
              showAlert: true,
              deleteData: {
                reviewId: row.reviewId,
                koboFormId: row.koboFormId,
                submissionId: row.submissionId,
              },
            });
          }}
        >
          <i className="fa fa-trash" aria-hidden="true" /> Delete
        </a>
      );

    const columns = [
      {
        dataField: 'submissionId',
        text: 'Submission ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      {
        dataField: 'granteeName',
        sort: true,
        text: 'Grantee Name',
      },
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'submitterUserName',
        text: 'Submitter',
        sort: true,
      },
      {
        dataField: 'submitedDate',
        text: 'Date/time stamp',
        sort: true,
        formatter: formatDate,
      },
      {
        dataField: 'surveyName',
        sort: true,
        text: 'Survey Name',
      },

      {
        isDummyField: true,
        dataField: 'dummy',
        formatter: deleteFormatter,
        text: 'Action',
      },
    ];

    return (
      <div ref={this.amsTableRef} tabIndex="-1">
        <div className="all-findings-wrapper">
          <Button onClick={this.createNewForm}>Create</Button>
        </div>
        <AmsTable
          data={allSubmission}
          remote={false}
          total={allSubmission.length}
          columns={columns}
          keyField="index"
          loading={this.state.loading}
        />
      </div>
    );
  }

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:({errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  showDeleteConfirmation() {
    const { showAlert } = this.state;

    return (
      <AmsAlert
        show={showAlert}
        title="Delete Submission"
        text={`You are about to delete a survey submission. Are you sure?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, delete it!'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showAlert: false, deleteData: {} });
        }}
        onConfirm={() => {
          this.setState({ showAlert: false });

          this.props
            .deleteSurveySubmission(this.state.deleteData)
            .then(() => {
              this.getData();
            })
            .catch(error => {
              this.setState({
                deleteData: {},
                responseErrors: [
                  ...this.state.responseErrors,
                  error.response.data,
                ],
              });
            });
        }}
      />
    );
  }
  handleCreateNewButton = () => {};

  render() {
    const { loading } = this.state;
    return (
      <div className="admin-surveys">
        {this.showDeleteConfirmation()}
        {this.showResponseErrors()}
        {this.showPageFilters()}

        {loading ? (
          <Dimmer active inverted>
            <Loader inverted>Loading...</Loader>
          </Dimmer>
        ) : (
          this.renderSurveyTable()
        )}
      </div>
    );
  }
}

SurveyAdminPage.propTypes = {
  lookups: PropTypes.object.isRequired,
  fetchAllSubmissions: PropTypes.func.isRequired,
  deleteSurveySubmission: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedSubmission: state.survey.selectedSubmission,
    allSubmission: state.survey.allSubmission,
    lookups: state.lookups,
    grantees: state.grantee.grantees,
  };
}

export default connect(mapStateToProps, {
  selectTab,
  fetchAllSubmissions,
  deleteSurveySubmission,
})(SurveyAdminPage);
