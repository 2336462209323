import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Header, Grid, Segment, Modal, Button } from 'semantic-ui-react';

// Import utils
import AmsModal from '../../../../utils/AmsModal';
import AmsDateFormatters from '../../../../utils/AmsDateFormatters';
import AmsTable from '../../../../utils/AmsTable';
import AmsSkipLinks from '../../../../utils/AmsSkipLinks';
import ReviewLayout from '../../../../utils/layout/ReviewLayout';
import AmsLookupDropdown from '../../../../utils/AmsLookupDropdown';
import ReviewJournalDetails from './ReviewJournalDetails';

//Import actions
import { fetchJournalLogs } from '../../../../actions/reviewActions';

const ReviewJournal = ({ props, router }) => {
  const { params } = router;
  const dispatch = useDispatch();
  const { journalLogs } = useSelector(state => state.review);

  const { data, total } = journalLogs ? journalLogs : {};
  const [requestInput, setRequestInput] = useState({
    limit: 25,
    page: 1,
    sortName: 'eventTime',
    sortOrder: 'desc',
    filters: { 'tags.reviewId': params.id || params.reviewId },
  });

  const [rowDetails, setRowDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const amsTableRef = useRef();

  useEffect(() => {
    const getJournalLogs = () => {
      dispatch(fetchJournalLogs(requestInput));
    };
    getJournalLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchJournalLogs(requestInput));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestInput.limit,
    requestInput.page,
    requestInput.sortOrder,
    requestInput.sortName,
  ]);

  const resetFilters = () => {
    let input = {
      limit: 25,
      page: 1,
      sortName: 'eventTime',
      sortOrder: 'desc',
      filters: { 'tags.reviewId': params.id || params.reviewId },
    };
    setRequestInput(input);
    dispatch(fetchJournalLogs(input));
  };

  const renderFilters = () => {
    return (
      <Segment>
        <AmsSkipLinks
          links={[{ title: 'Skip filters and go to results', to: 'results' }]}
        />

        <Form className="filter-form">
          <Form.Group>
            <Form.Field
              width="5"
              label={{ children: 'Category Type', htmlFor: 'categoryType' }}
              placeholder="Select Category Type"
              control={AmsLookupDropdown}
              value={
                requestInput && requestInput.filters.category
                  ? requestInput.filters.category
                  : ''
              }
              selection
              category={'reviewJournalEventTypes'}
              onChange={(e, { value }) =>
                setRequestInput({
                  ...requestInput,
                  page: 1,
                  filters: {
                    ...requestInput.filters,
                    category: value,
                    'tags.eventType': value,
                  },
                })
              }
              id="categoryType"
              aria-labelledby="categoryType"
            />

            <Form.Group>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  primary
                  onClick={() => {
                    dispatch(fetchJournalLogs(requestInput));
                  }}
                >
                  Filter
                </Form.Button>
              </Form.Field>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  basic
                  onClick={resetFilters}
                >
                  Reset
                </Form.Button>
              </Form.Field>
            </Form.Group>
          </Form.Group>
        </Form>
      </Segment>
    );
  };

  const renderLogs = () => {
    const dateFormatter = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const scrollToRef = () => {
      window.scrollTo(0, amsTableRef.current.offsetTop);
      amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      setRequestInput({ ...requestInput, limit: sizePerPage, page });
      scrollToRef();
    };

    const onPageChange = (page, sizePerPage) => {
      setRequestInput({ ...requestInput, sizePerPage, page });
      scrollToRef();
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === requestInput.sortName &&
        sortOrder === requestInput.sortOrder
      )
        return null;
      setRequestInput({
        ...requestInput,
        sortOrder,
        page: 1,
        sortName: sortField,
      });
      scrollToRef();
    };

    const addedBy = (cell, row) => {
      return cell.fullName;
    };

    const deleteFormatter = (cell, row) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          setShowModal(true);
          setRowDetails(row);
        }}
      >
        Detail
      </a>
    );

    const columns = [
      {
        dataField: 'description',
        text: 'Category',
        sort: true,
      },
      {
        dataField: 'eventDescription',
        text: 'Description',
        sort: true,
      },
      {
        dataField: 'addedBy',
        text: 'Source',
        sort: true,
        formatter: addedBy,
      },
      {
        dataField: 'eventTime',
        text: 'Timestamp',
        sort: true,
        formatter: dateFormatter,
      },
      {
        isDummyField: true,
        dataField: 'dummy',
        formatter: deleteFormatter,
        text: 'Action',
      },
    ];

    const defaultSorted = [
      {
        dataField: requestInput.sortName,
        order: requestInput.sortOrder,
      },
    ];

    return (
      <div ref={amsTableRef} tabIndex="-1">
        <AmsTable
          defaultSorted={defaultSorted}
          remote
          data={data || []}
          page={requestInput.page}
          total={total || 0}
          limit={requestInput.limit}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  const rowDetailsModal = () => (
    <AmsModal
      open={showModal}
      size="small"
      closeOnDimmerClick={false}
      onClose={() => {
        setShowModal(false);
        setRowDetails({});
      }}
      className="ams-semantic-modal-fix"
    >
      <Modal.Header>Journal Details</Modal.Header>
      <Modal.Content>
        <>
          <ReviewJournalDetails journalData={rowDetails} />
        </>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content="Close"
          onClick={() => {
            setRowDetails({});
            setShowModal(false);
          }}
        />
      </Modal.Actions>
    </AmsModal>
  );

  return (
    <ReviewLayout type="Journal">
      <>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2" content="Journal" />
            <br />
          </Grid.Column>
        </Grid.Row>
        {
          <>
            {renderFilters()}
            {renderLogs()}
            {rowDetailsModal()}
          </>
        }
      </>
    </ReviewLayout>
  );
};

export default ReviewJournal;
