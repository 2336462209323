import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

// Import helper function.
import enforceRole from '../../../utils/EnforceRole';

//Import components
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsTable from '../../../utils/AmsTable';

// Import actions.
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

// Import settings.
import { acl } from '../../../config';
import AmsModal from '../../../utils/AmsModal';

class AdditionalCredentialsForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    showModal: false,
    credentialAdd: false,
    credentialEdit: false,
    credentialDelete: false,
    modalTitle: '',
    selectedCredential: {
      credentialName: '',
      issuer: '',
      dateReceived: '',
      verified: '',
    },
    updatedCredential: {
      credentialName: '',
      issuer: '',
      dateReceived: '',
      verified: '',
    },
    errors: {},
    credentialErrors: {},
    data: {
      oid: '',
      additionalCredentials: [],
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.profile.oid !== state.data.oid ||
      !_.isEqual(
        props.profile.additionalCredentials,
        state.data.additionalCredentials
      )
    ) {
      const { profileTab } = props;
      const { oid, additionalCredentials } = props.profile;

      if (profileTab !== 'additionalCredentials') {
        this.cancelEdit();
      }
      return {
        data: {
          oid,
          additionalCredentials,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      const { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};
    return errors;
  };

  handleChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  handleCredentialEditChange = event =>
    this.setState({
      ...this.state,
      updatedCredential: {
        ...this.state.updatedCredential,
        [event.target.name]: event.target.value,
      },
      credentialErrors: _.omit(this.state.credentialErrors, event.target.name),
    });

  handleCredentialVerifiedChange = (e, { name, checked }) =>
    this.setState({
      ...this.state,
      updatedCredential: { ...this.state.updatedCredential, [name]: checked },
      errors: _.omit(this.state.credentialErrors, name),
    });

  addCredential = e => {
    e.preventDefault();

    this.setState({
      showModal: true,
      credentialAdd: true,
      credentialEdit: false,
      credentialDelete: false,
      modalTitle: 'Add Credential',
      selectedCredential: {},
      updatedCredential: {},
    });
  };

  handleCertificationEdit(selectedCredential, e) {
    e.preventDefault();
    this.setState({
      selectedCredential,
      updatedCredential: selectedCredential,
      showModal: true,
      modalTitle: 'Edit Credential',
      credentialEdit: true,
      credentialAdd: false,
      credentialDelete: false,
    });
  }

  handleCertificationRemove(selectedCredential, e) {
    e.preventDefault();
    this.setState({
      showDeleteConfirm: true,
      credentialDelete: true,
      selectedCredential,
      updatedCredential: selectedCredential,
    });
  }

  closeDeleteConfirmModal() {
    this.setState({ showDeleteConfirm: false });
  }

  closeEditModal = () => {
    this.setState({
      showModal: false,
      credentialAdd: false,
      credentialEdit: false,
      credentialDelete: false,
      credentialErrors: {},
      modalTitle: '',
      selectedCredential: {},
      updatedCredential: {},
    });
  };

  setCredentialChanges = () => {
    const {
      selectedCredential,
      updatedCredential,
      data,
      credentialAdd,
      credentialDelete,
    } = this.state;

    if (!credentialDelete) {
      if (updatedCredential && updatedCredential.credentialName) {
        updatedCredential.credentialName = updatedCredential.credentialName.trim();
      }

      if (!updatedCredential || _.isEmpty(updatedCredential.credentialName)) {
        this.setState({
          credentialErrors: {
            ...this.state.credentialErrors,
            credentialName: 'Credential name can not be empty',
          },
        });
        return null;
      }
    }

    let updatedCredentials = data.additionalCredentials
      ? data.additionalCredentials.map(credential => {
          if (
            updatedCredential.additionalCredentialsUuid ===
            credential.additionalCredentialsUuid
          ) {
            credential = updatedCredential;
          }
          return credential;
        })
      : [];

    // During new credential add
    if (credentialAdd) {
      updatedCredential.additionalCredentialsUuid = uuidv4();
      updatedCredentials.push(updatedCredential);
    }

    // During credential delete.
    if (credentialDelete) {
      updatedCredentials = _.pull(updatedCredentials, selectedCredential);
    }

    this.saveCredentials({
      oid: data.oid,
      additionalCredentials: updatedCredentials,
    });
  };

  saveCredentials = credentialData => {
    this.setState({ loading: true });
    this.props
      .updateUserProfile(credentialData)
      .then(() =>
        this.setState({
          showSuccess: true,
          loading: false,
          credentialAdd: false,
          credentialEdit: false,
          credentialDelete: false,
          showModal: false,
          showDeleteConfirm: false,
          modalTitle: '',
        })
      )
      .catch(err => {
        this.setState({
          showSuccess: false,
          loading: false,
          credentialAdd: false,
          credentialEdit: false,
          credentialDelete: false,
          showModal: false,
          showDeleteConfirm: false,
          modalTitle: '',
          errors: { ...this.state.errors, profile: err.response.data.message },
        });
      });

    this.closeEditModal();
  };

  showCredentialModal = () => {
    const { currentUser } = this.props;
    const {
      showModal,
      updatedCredential,
      loading,
      modalTitle,
      credentialErrors,
    } = this.state;

    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Content>
          {!_.isEmpty(credentialErrors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={Object.keys(credentialErrors).map(errorKey => (
                <li key={errorKey}>{credentialErrors[errorKey]}</li>
              ))}
            />
          )}

          <Form loading={loading}>
            <Form.Group widths="2">
              <Form.Field
                name="credentialName"
                onChange={this.handleCredentialEditChange}
                value={updatedCredential.credentialName || ''}
                placeholder="Credential Name"
                required
                control={Input}
                id="credentialName"
                aria-labelledby="credentialName"
                label={{
                  children: 'Credential Name',
                  htmlFor: 'credentialName',
                }}
              />

              <Form.Field
                name="issuer"
                onChange={this.handleCredentialEditChange}
                value={updatedCredential.issuer || ''}
                placeholder="Issuer Institute"
                control={Input}
                id="issuer"
                aria-labelledby="issuer"
                label={{
                  children: 'Issuer',
                  htmlFor: 'issuer',
                }}
              />
            </Form.Group>

            <Form.Group widths="2">
              <Form.Field
                name="dateReceived"
                control={DatePicker}
                onChangeRaw={e => e.preventDefault()}
                placeholder="Please select date received"
                // isClearable={true}
                selected={
                  updatedCredential.dateReceived
                    ? AmsDateFormatters.getMoment(
                        updatedCredential.dateReceived
                      )
                    : null
                }
                onChange={e =>
                  this.setState({
                    ...this.state,
                    updatedCredential: {
                      ...this.state.updatedCredential,
                      dateReceived: e.endOf('day').format(),
                    },
                    credentialErrors: _.omit(
                      this.state.credentialErrors,
                      'dateReceived'
                    ),
                  })
                }
                id="dateReceived"
                aria-labelledby="dateReceived"
                label={{
                  children: 'Date Received',
                  htmlFor: 'dateReceived',
                }}
              />

              {enforceRole(
                <Form.Field
                  name="verified"
                  onChange={this.handleCredentialVerifiedChange}
                  checked={updatedCredential.verified === true ? true : false}
                  toggle
                  role="group"
                  control={Checkbox}
                  id="verified"
                  aria-labelledby="verified"
                  label={{
                    children: 'Verified',
                    htmlFor: 'verified',
                  }}
                />,
                acl.actions.profile.checkBoxValidation,
                currentUser.roles
              ) || (
                <Form.Field>
                  <label>Verified</label>
                  {updatedCredential.verified ? 'Yes' : 'No'}
                </Form.Field>
              )}
            </Form.Group>
            <input type="submit" value="submit" hidden />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.setCredentialChanges}>
            Save
          </Button>
          <Button onClick={this.closeEditModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  showDeleteConfirmModal = () => {
    const { showDeleteConfirm, selectedCredential } = this.state;
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showDeleteConfirm}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Remove Credential</Modal.Header>
        <Modal.Content>
          <p>
            You are about to remove {selectedCredential.credentialName} from the
            Credentials list. Are you sure?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.setCredentialChanges}>
            Remove
          </Button>
          <Button onClick={this.closeDeleteConfirmModal.bind(this)}>
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.userSettings.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  render() {
    const { editMode, loading, errors, data, showSuccess } = this.state;

    const certificateVerifiedFormatter = (cell, row) =>
      cell === true ? 'Yes' : 'No';

    const dateFormatter = (cell, row) => {
      return cell ? AmsDateFormatters.formatDate(cell) : '';
    };

    const actionFormatter = (cell, row) => {
      return (
        <Button.Group icon>
          <Button
            onClick={this.handleCertificationEdit.bind(this, row)}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-pencil fa-md" />
          </Button>

          <Button
            onClick={this.handleCertificationRemove.bind(this, row)}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-trash fa-md" />
          </Button>
        </Button.Group>
      );
    };

    const columns = [
      {
        dataField: 'additionalCredentialsUuid',
        hidden: true,
        text: 'ID',
      },
      {
        dataField: 'credentialName',
        sort: true,

        text: 'Credential',
        headerStyle: { width: '25%' },
        style: {
          maxWidth: '250px',

          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
      {
        dataField: 'issuer',
        sort: true,
        text: 'Issuer',
        headerStyle: { width: '25%' },
      },
      {
        dataField: 'dateReceived',
        formatter: dateFormatter,
        sort: true,
        text: 'Date Received',
        headerStyle: { width: '25%' },
      },
      {
        dataField: 'verified',
        formatter: certificateVerifiedFormatter,
        sort: true,
        text: 'Verified',
        headerStyle: { width: '25%' },
      },
      {
        hidden: !editMode,
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: actionFormatter,
        text: 'Action',
      },
    ];
    const total = data.additionalCredentials
      ? data.additionalCredentials.length
      : 0;

    return (
      <div>
        {this.showCredentialModal()}
        {this.showDeleteConfirmModal()}

        <div className="profile-form-title-wrapper">
          <h2>Additional Credentials</h2>
        </div>

        <Form onSubmit={this.onSubmit} loading={loading}>
          <div className="profile-form-fields-wrapper">
            {showSuccess && (
              <Message
                positive
                icon="check"
                header="Success!"
                content="Data saved successfully."
              />
            )}

            {!_.isEmpty(errors) && (
              <Message
                negative
                icon="cancel"
                header="Something went wrong!"
                list={Object.keys(errors).map(errorKey => (
                  <li key={errorKey}>{errors[errorKey]}</li>
                ))}
              />
            )}

            <br />

            <Form.Group widths="equal">
              <Form.Field>
                <div className="row">
                  <div className="col-sm-9">
                    <h5>
                      Credentials
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="additionalCredentials"
                      />
                    </h5>
                  </div>
                  {editMode && (
                    <div className="col-sm-1">
                      <Button basic size="tiny" onClick={this.addCredential}>
                        {' '}
                        Add
                      </Button>
                    </div>
                  )}
                  <br />
                  <br />
                </div>
                <Col className="row language-table" xs={12} sm={10}>
                  <AmsTable
                    data={data.additionalCredentials}
                    basic
                    columns={columns}
                    keyField="additionalCredentialsUuid"
                    remote={false}
                    sizePerPageOption={false}
                    total={total}
                    pagination={
                      data.additionalCredentials &&
                      data.additionalCredentials.length > 10
                    }
                  />
                </Col>
              </Form.Field>
            </Form.Group>

            <Segment basic floated="right">
              {editMode ? (
                <div>
                  <Button size="large" primary>
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={e => {
                      e.preventDefault();
                      this.cancelEdit();
                    }}
                    basic
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                this.showEditButton()
              )}
            </Segment>
          </div>
        </Form>
      </div>
    );
  }
}

AdditionalCredentialsForm.propTypes = {
  currentUser: PropTypes.object,
  profile: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  profile: state.profile,
  profileTab: state.profileTab,
});

export default connect(mapStateToProps, { updateUserProfile, updateUserRoles })(
  AdditionalCredentialsForm
);
