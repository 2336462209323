import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filter, isEmpty, isNil } from 'lodash';
import {
  Form,
  Button,
  Input,
  Icon,
  Modal,
  Dropdown,
  TextArea,
  Popup,
  Label,
} from 'semantic-ui-react';
import { Link } from 'react-router';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// Import components.
import FollowUpAttachmentField from './FollowUpAttachmentField';

// Import utils.
import AmsModal from '../../../utils/AmsModal';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsTable from '../../../utils/AmsTable';

const FollowUpAdditionalCitationsForm = props => {
  const [query, setQuery] = useState('');
  const { citations } = useSelector(state => state.review.citations);
  const { followUpTaskDetail } = useSelector(state => state.submissions);
  const [form, setFormValues] = useState({
    formData: {},
    showCitationModal: false,
    modalCitationIndex: null,
    modalCitationValue: null,
  });

  const [correctedCitationReason, setCorrectedCitationReason] = useState('');
  const correctedCitationReasonProps =
    props.selectedCitationDetails &&
    props.selectedCitationDetails.correctedCitationReason;

  useEffect(() => {
    const { surveyData, formDisabled } = props;

    setFormValues({
      ...form,
      surveyData,
      formDisabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.citationsData)]);

  useEffect(() => {
    correctedCitationReason !== correctedCitationReasonProps &&
      setCorrectedCitationReason(correctedCitationReasonProps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctedCitationReasonProps]);

  const handleAttachmentChange = (additionalCitation, index, { formData }) => {
    let formattedData = {
      name: 'evidences',
      value: formData.evidences,
    };
    props.buildFormData(formattedData, index, 'additionalCitations');
  };

  const handleTextChange = (e, data, index) => {
    props.buildFormData(data, index, 'additionalCitations');
    setCorrectedCitationReason(data.value);
  };

  const buildGuides = guides => {
    let guideOptions =
      guides &&
      guides.map(guide => {
        return {
          key: guide.guideCode,
          value: guide.name,
          text: guide.name,
        };
      });
    return guideOptions;
  };

  const buildPerformanceMeasures = guideName => {
    const { guides } = followUpTaskDetail;
    if (!guideName)
      return [
        {
          key: '',
          value: '',
          text: '',
        },
      ];
    else {
      let selectedGuide =
        guides &&
        filter(guides, guide => {
          if (guide.name === guideName) {
            return guide;
          }
        });
      let performanceMeasureOptions =
        selectedGuide &&
        selectedGuide.length > 0 &&
        selectedGuide[0].performanceMeasures &&
        selectedGuide[0].performanceMeasures.map(
          (performanceMeasure, index) => {
            return {
              key: index,
              value: performanceMeasure.name,
              text: performanceMeasure.name,
            };
          }
        );
      return performanceMeasureOptions;
    }
  };

  const buildPerformanceAreas = guideName => {
    const { guides } = followUpTaskDetail;
    if (!guideName)
      return [
        {
          key: '',
          value: '',
          text: '',
        },
      ];
    else {
      let selectedGuide =
        guides &&
        filter(guides, guide => {
          if (guide.name === guideName) {
            return guide;
          }
        });
      let performanceAreaOptions =
        selectedGuide &&
        selectedGuide.length > 0 &&
        selectedGuide[0].performanceAreas &&
        selectedGuide[0].performanceAreas.map((performanceArea, index) => {
          return {
            key: index,
            value: performanceArea.name,
            text: performanceArea.name,
          };
        });
      return performanceAreaOptions;
    }
  };

  const generateCitationLabels = additionalCitation => {
    if (
      additionalCitation &&
      additionalCitation.citation &&
      additionalCitation.citation.length > 0
    ) {
      return (
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          {additionalCitation.citation}
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          No citations found.
        </div>
      );
    }
  };

  const renderGuideAndPM = formDisabled => {
    return (
      <>
        <Form.Field>
          <AmsFormLabel name="Guide Name*" fieldLabel="filterGuideName">
            <Dropdown
              placeholder="Select Guide Name"
              value={
                props.selectedCitationDetails &&
                props.selectedCitationDetails.guideName
              }
              name="guideName"
              onChange={(e, data) =>
                props.handleSelection(
                  e,
                  data,
                  props.selectedCitationDetails &&
                    props.selectedCitationDetails.key
                )
              }
              options={buildGuides(followUpTaskDetail.guides)}
              fluid
              search
              selection
              required
              aria-labelledby="filterGuideName"
              searchInput={{
                id: 'filterGuideName',
                title: 'Select a Guide Name',
                'aria-labelledby': 'filterGuideName',
              }}
              disabled={
                props.selectedCitationDetails &&
                !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
                formDisabled
              }
              error={
                props.responseErrors &&
                props.responseErrors.additionalCitationError &&
                !isNil(
                  props.responseErrors.additionalCitationError.guideName
                ) &&
                !!props.responseErrors.additionalCitationError.guideName
              }
            />
          </AmsFormLabel>
        </Form.Field>
        <Form.Field>
          <AmsFormLabel
            name="Performance Measure*"
            helpText={true}
            fieldLabel="filterPerformanceMeasure"
          >
            <Dropdown
              placeholder="Select Performance Measure"
              name="performanceMeasure"
              value={
                (props.selectedCitationDetails &&
                  props.selectedCitationDetails.performanceMeasure) ||
                ''
              }
              onChange={(e, data) =>
                props.handleSelection(
                  e,
                  data,
                  props.selectedCitationDetails &&
                    props.selectedCitationDetails.key
                )
              }
              options={
                props.selectedCitationDetails &&
                props.selectedCitationDetails.guideName
                  ? buildPerformanceMeasures(
                      props.selectedCitationDetails &&
                        props.selectedCitationDetails.guideName
                    )
                  : [
                      {
                        key: '',
                        value: '',
                        text: '',
                      },
                    ]
              }
              fluid
              search
              selection
              aria-labelledby="filterPerformanceMeasure"
              searchInput={{
                id: 'filterPerformanceMeasure',
                title: 'Filter by Performance Measure',
              }}
              disabled={
                props.selectedCitationDetails &&
                !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
                formDisabled
              }
              error={
                props.responseErrors &&
                props.responseErrors.additionalCitationError &&
                !isNil(
                  props.responseErrors.additionalCitationError
                    .performanceMeasure
                ) &&
                !!props.responseErrors.additionalCitationError
                  .performanceMeasure
              }
            />
          </AmsFormLabel>
        </Form.Field>
      </>
    );
  };

  const renderCAPA = formDisabled => {
    return (
      <>
        <Form.Field>
          <AmsFormLabel name="Content Area*" fieldLabel="filterContentArea">
            <Dropdown
              placeholder="Select Content Area"
              value={
                props.selectedCitationDetails &&
                props.selectedCitationDetails.guideName
              }
              name="guideName"
              onChange={(e, data) =>
                props.handleSelection(
                  e,
                  data,
                  props.selectedCitationDetails &&
                    props.selectedCitationDetails.key
                )
              }
              options={buildGuides(followUpTaskDetail.guides)}
              fluid
              search
              selection
              required
              aria-labelledby="filterContentArea"
              searchInput={{
                id: 'filterContentArea',
                title: 'Select a Content Area',
                'aria-labelledby': 'filterContentArea',
              }}
              disabled={
                props.selectedCitationDetails &&
                !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
                formDisabled
              }
              error={
                props.responseErrors &&
                props.responseErrors.additionalCitationError &&
                !isNil(
                  props.responseErrors.additionalCitationError.guideName
                ) &&
                !!props.responseErrors.additionalCitationError.guideName
              }
            />
          </AmsFormLabel>
        </Form.Field>
        <Form.Field>
          <AmsFormLabel
            name="Performance Area*"
            helpText={true}
            fieldLabel="filterPerformanceArea"
          >
            <Dropdown
              placeholder="Select Performance Area"
              name="performanceMeasure"
              value={
                (props.selectedCitationDetails &&
                  props.selectedCitationDetails.performanceMeasure) ||
                ''
              }
              onChange={(e, data) =>
                props.handleSelection(
                  e,
                  data,
                  props.selectedCitationDetails &&
                    props.selectedCitationDetails.key
                )
              }
              options={
                props.selectedCitationDetails &&
                props.selectedCitationDetails.guideName
                  ? buildPerformanceAreas(
                      props.selectedCitationDetails &&
                        props.selectedCitationDetails.guideName
                    )
                  : [
                      {
                        key: '',
                        value: '',
                        text: '',
                      },
                    ]
              }
              fluid
              search
              selection
              aria-labelledby="filterPerformanceArea"
              searchInput={{
                id: 'filterPerformanceArea',
                title: 'Filter by Performance Area',
              }}
              disabled={
                props.selectedCitationDetails &&
                !isEmpty(props.selectedCitationDetails.amsSubmissionId) &&
                formDisabled
              }
              error={
                props.responseErrors &&
                props.responseErrors.additionalCitationError &&
                !isNil(
                  props.responseErrors.additionalCitationError
                    .performanceMeasure
                ) &&
                !!props.responseErrors.additionalCitationError
                  .performanceMeasure
              }
            />
          </AmsFormLabel>
        </Form.Field>
      </>
    );
  };

  const renderForm = () => {
    const { formDisabled } = props;

    let citation = props.selectedCitationDetails
      ? props.selectedCitationDetails.citation
      : '';

    let params = {
      pathname: `/review/citations/definition`,
      query: { citation },
    };

    return (
      <div>
        <div>
          <br />
          <Form.Group widths="equal">
            {followUpTaskDetail?.isFollowupLegacy
              ? renderCAPA(formDisabled)
              : renderGuideAndPM(formDisabled)}
            <Form.Field
              width={5}
              error={
                props.responseErrors &&
                props.responseErrors.additionalCitationError &&
                !isNil(props.responseErrors.additionalCitationError.citation) &&
                !!props.responseErrors.additionalCitationError.citation
              }
            >
              <label>Citation Number*</label>
              {generateCitationLabels(props.selectedCitationDetails)}
              <Link
                to={'#'}
                onClick={e => {
                  e.preventDefault();
                  const newArr = [];
                  if (
                    props.selectedCitationDetails &&
                    props.selectedCitationDetails.citation &&
                    props.selectedCitationDetails.citation.length > 0
                  ) {
                    newArr[0] = props.selectedCitationDetails.citation;
                  }
                  setFormValues({
                    ...form,
                    showCitationModal: true,
                    modalCitationIndex:
                      props.selectedCitationDetails &&
                      props.selectedCitationDetails.key,
                    modalCitationValue: newArr,
                  });
                }}
              >
                {((props.selectedCitationDetails &&
                  isEmpty(props.selectedCitationDetails.amsSubmissionId)) ||
                  !formDisabled) &&
                  (props.selectedCitationDetails &&
                  props.selectedCitationDetails.citation &&
                  props.selectedCitationDetails.citation.length > 0
                    ? 'Edit Citation'
                    : 'Add a Citation')}
              </Link>
            </Form.Field>
          </Form.Group>

          <AmsFormLabel
            name={`Please explain your response${
              props.selectedCitationDetails.citation ? ` for ` : '*'
            }`}
            fieldLabel="correctedCitationReason"
          >
            {props.selectedCitationDetails &&
              props.selectedCitationDetails.citation &&
              !isEmpty(props.selectedCitationDetails.citation) && (
                <Link to={params} target="_blank">
                  <Popup
                    trigger={
                      <Label
                        style={{
                          color: 'blue',
                          background: 'white',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {props.selectedCitationDetails.citation
                          ? // eslint-disable-next-line no-useless-concat
                            `${props.selectedCitationDetails.citation}` + '*'
                          : ''}
                      </Label>
                    }
                    content="Select to see Citation Definition"
                  />
                </Link>
              )}
            <Form.Field
              control={TextArea}
              name="correctedCitationReason"
              required
              value={correctedCitationReason || ''}
              onChange={(e, data) =>
                handleTextChange(
                  e,
                  data,
                  props.selectedCitationDetails &&
                    props.selectedCitationDetails.key
                )
              }
              aria-labelledby="correctedCitationReason"
              error={
                props.responseErrors &&
                props.responseErrors.additionalCitationError &&
                !isNil(
                  props.responseErrors.additionalCitationError
                    .correctedCitationReason
                ) &&
                !!props.responseErrors.additionalCitationError
                  .correctedCitationReason
              }
            />
          </AmsFormLabel>

          <FollowUpAttachmentField
            additionalCitation={props.selectedCitationDetails}
            index={
              props.selectedCitationDetails && props.selectedCitationDetails.key
            }
            onChange={handleAttachmentChange}
            evidences={
              props.selectedCitationDetails &&
              props.selectedCitationDetails.evidences
            }
            formName={'additionalCitations'}
          />
          <br />
        </div>
      </div>
    );
  };

  const renderCitationModal = () => {
    return (
      <AmsModal
        size={'fullscreen'}
        className="ams-semantic-modal-fix"
        open={form.showCitationModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setFormValues({ ...form, showCitationModal: false });
        }}
      >
        <Modal.Header>Citations</Modal.Header>
        {generateCitationTable()}
        <Modal.Actions>
          <Button
            onClick={() => {
              setFormValues({
                ...form,
                showCitationModal: false,
                modalCitationIndex: null,
                modalCitationValue: null,
              });
              setQuery('');
            }}
            negative
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveModalSelection();
              setQuery('');
            }}
            positive
            labelPosition="right"
            icon="checkmark"
            content="Select"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const saveModalSelection = () => {
    const data = {
      name: 'citation',
      value: form.modalCitationValue ? form.modalCitationValue[0] : '',
    };
    props.buildFormData(data, form.modalCitationIndex, 'additionalCitations');
    setFormValues({
      ...form,
      modalCitationIndex: null,
      modalCitationValue: null,
      showCitationModal: false,
    });
  };

  const generateCitationTable = () => {
    const style = {
      description: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '60px',
      },
    };

    const formatDescription = (cell, row) => {
      return <div style={style.description}>{cell}</div>;
    };
    const columns = [
      {
        dataField: 'standardNumber',
        text: 'Citation Number',
        formatter: (cell, row) => {
          return (
            <Link onClick={e => e.preventDefault()} to="#">
              {cell}
            </Link>
          );
        },
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      {
        dataField: 'standardText',
        formatter: formatDescription,
        text: 'Citation Description',

        headerStyle: {
          width: '70%',
        },
      },
    ];

    const total = citations ? citations.length : 0;

    const selectRow = {
      mode: 'radio',
      style: { background: '#c8e6c9' },
      clickToSelect: true,
      selected: form.modalCitationValue,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          setFormValues(() => ({
            ...form,
            modalCitationValue: [row.standardNumber],
          }));
        } else {
          setFormValues(() => ({
            ...form,
            modalCitationValue: [],
          }));
        }
      },
      selectionRenderer: ({ mode, ...rest }) => {
        return rest.checked ? (
          <Icon color="green" name="check circle"></Icon>
        ) : null;
      },
    };

    const CitationSearch = props => {
      const handleClick = () => {
        props.onSearch(query);
      };
      return (
        <>
          <Input
            style={{ width: '92%', marginRight: '5px' }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                handleClick();
              }
            }}
          >
            <input
              placeholder="Search..."
              value={query}
              autoFocus
              onChange={e => {
                setQuery(e.target.value);
              }}
              type="text"
            />
          </Input>
          <Button onClick={handleClick}>Filter</Button>
        </>
      );
    };

    return (
      <ToolkitProvider
        keyField="standardNumber"
        data={(citations && citations) || []}
        columns={columns}
        search
      >
        {props => (
          <>
            <Modal.Content style={{ paddingBottom: '5px' }}>
              <CitationSearch {...props.searchProps} />
            </Modal.Content>
            <Modal.Content style={{ paddingTop: '0px' }} scrolling>
              <AmsTable
                selectRow={selectRow}
                remote={false}
                basic
                total={total}
                columns={columns}
                loading={false}
                {...props.baseProps}
              />
            </Modal.Content>
          </>
        )}
      </ToolkitProvider>
    );
  };

  return (
    <div>
      {renderForm()}
      {renderCitationModal()}
    </div>
  );
};

export default FollowUpAdditionalCitationsForm;
