import _ from 'lodash';

import {
  ADMIN_TAB_SELECTED,
  AZURE_USER_CREATED,
  USER_PROFILE_CREATED,
  USER_PROFILES_FETCHED,
  ROLES_LIST_FETCHED,
  ADMIN_FILES_FETCHED,
  SEARCH_USERS,
  ADMIN_LOGS_FETCHED,
} from '../redux/types';

const initialState = {
  total: 0,
  userProfilesList: {
    data: [],
    limit: 0,
    page: 0,
    total: -1,
  },
  active: {
    key: 0,
    pageTitle: '',
  },
  userProfiles: [],
  userRoles: [],
  createdUser: {},
  createdProfile: {},
  errors: {
    userCreate: {},
    profilereate: {},
  },
  files: {},
  searchUsersResult: [],
  logs: [],
};

export default function admin(state = initialState, action) {
  switch (action.type) {
    case ADMIN_TAB_SELECTED: {
      return {
        ...state,
        active: {
          key: action.active.key,
          pageTitle: action.active.pageTitle,
        },
      };
    }

    case AZURE_USER_CREATED: {
      return {
        ...state,
        createdUser: action.createdUser,
      };
    }

    case USER_PROFILE_CREATED: {
      return {
        ...state,
        createdProfile: action.userProfile,
        userProfiles: [...state.userProfiles, action.userProfile],
      };
    }

    case USER_PROFILES_FETCHED: {
      // Concatinate firstName and lastName to create name field also order data by name.
      const sortedList = _.sortBy(
        action.profiles.map(user => {
          user.name = `${user.firstName} ${user.lastName}`.trim();
          return user;
        }),
        [user => user.name.toLowerCase()],
        ['asc']
      );

      return {
        ...state,
        userProfiles: sortedList,
        userProfilesList: action.userProfilesList,
        total: action.userProfilesList.total,
        message: {},
      };
    }

    case ROLES_LIST_FETCHED: {
      return { ...state, userRoles: action.rolesList };
    }

    case ADMIN_FILES_FETCHED: {
      return { ...state, files: action.files };
    }

    case SEARCH_USERS: {
      return { ...state, searchUsersResult: action.searchUsersResult };
    }

    case ADMIN_LOGS_FETCHED: {
      return { ...state, logs: action.logs };
    }

    default:
      return state;
  }
}
