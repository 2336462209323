import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Button,
  Divider,
  Grid,
  Header,
  List,
  Message,
  Segment,
  Step,
} from 'semantic-ui-react';

// Import components.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import SlidingContainer from '../../../utils/layout/SlidingContainer';

// Import actions.
import { fetchFindingsDetail } from '../../../actions/reviewActions';

// Import configs

import './style.css';

const style = {
  header: {
    color: '#5072a6',
  },
  listItem: {
    lineHeight: '38px',
  },
  customStep: {},
};

const FollowUpRecommendationsLegacy = props => {
  const { id } = props.params;
  const { fetchFindingsDetail, findings } = props;
  const [citationIndex, setCitationIndex] = useState(0);
  const [loading, setLoading] = useState(null);
  const [errors, setErrors] = useState(null);

  const currentCitation = () => {
    if (!findings || !findings.FollowInfo) {
      return null;
    }
    return findings.FollowInfo.furRecommendations[citationIndex];
  };

  const fur = currentCitation();

  useEffect(() => {
    setLoading(true);
    fetchFindingsDetail(id)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setErrors(error.message);
      });
  }, [id, fetchFindingsDetail]);

  useEffect(() => {
    if (findings && findings.legacy === false) {
      props.router.push(
        `/review/${findings.reviewId}/follow-up-recommendations`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findings]);

  const generateGranteeInfo = () => {
    if (!findings) {
      return null;
    }

    let grantees = (findings.GrantInfo || []).map(grantee => {
      return ` ${grantee.granteeName} (${grantee.granteeId})`;
    });

    let { reviewId, reviewType } = findings;

    return grantees + '| ' + reviewType + ' ( ' + reviewId + ' ) ';
  };

  const generateHeaderInfo = () => {
    return (
      <Grid.Column>
        <p>
          <b>Review status: Shipped</b>
        </p>
        <p>
          <b>
            Date report received by Grantee:{' '}
            {findings ? (
              AmsDateFormatters.formatDate(findings.reportReceivedDate)
            ) : (
              <i>no date recieved from backend</i>
            )}
          </b>
        </p>
        <p>
          <b>Days till due date: past due</b>
        </p>
      </Grid.Column>
    );
  };

  const generateCAPDetails = () => {
    const ancDays = fur.findingsType === 'ANC' ? fur.capDays : 'N/A';
    const defDays = fur.findingsType === 'DEF' ? fur.capDays : 'N/A';
    return (
      <>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Non-Compliance
          </Header>
          <List relaxed="very" verticalAlign="middle">
            <List.Item>
              <List.Content floated="right">
                {findings.FollowInfo.ncCount}
              </List.Content>
              <List.Content>Number of Non-Compliance(S):</List.Content>
            </List.Item>
            <List.Item>
              <List.Content floated="right">{ancDays}</List.Content>
              <List.Content>Corrective Action Period Days:</List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Deficiency
          </Header>
          <List relaxed="very" verticalAlign="middle">
            <List.Item>
              <List.Content floated="right">
                {findings.FollowInfo.defCount}
              </List.Content>
              <List.Content>Number of Deficiency(s):</List.Content>
            </List.Item>
            <List.Item>
              <List.Content floated="right">{defDays}</List.Content>
              <List.Content>Corrective Action Period Days:</List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </>
    );
  };

  const generateReviewDetails = () => {
    return (
      <>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Follow-up Review data
          </Header>
          <List relaxed="very" verticalAlign="middle">
            <List.Item>
              <List.Content floated="right">{fur.furType}</List.Content>
              <List.Content>Recommended Review Type:</List.Content>
            </List.Item>
            <List.Item>
              <List.Content floated="right">{fur.furLead}</List.Content>
              <List.Content>Follow-up Lead:</List.Content>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {AmsDateFormatters.formatDate(fur.furStartDate)}
              </List.Content>
              <List.Content>Proposed Start Date of Review:</List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Overall Follow-up Recommendation
          </Header>
          <Segment basic>{findings.FollowInfo.overallRecommendations}</Segment>
        </Grid.Column>
      </>
    );
  };

  const generateCitations = () => {
    return (
      <>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Citations
          </Header>
          <Step.Group className="citationSteps" fluid>
            {generateCitationSteps()}
          </Step.Group>
          <Divider hidden />
          <span>
            <b>
              Citation due date:{' '}
              {AmsDateFormatters.formatDate(fur.citationDueDate)}
            </b>
          </span>
        </Grid.Column>
      </>
    );
  };

  const generateCitationSteps = () => {
    if (!findings) {
      return 'Loading';
    }

    const { furRecommendations } = findings.FollowInfo;

    const length = furRecommendations.length;

    return furRecommendations.map((fur, index) => {
      const title = `${fur.findingsType} ${fur.associatedCitations}`;

      return (
        <Step
          active={citationIndex === index}
          key={index}
          onClick={() => {
            setCitationIndex(index);
          }}
        >
          <Step.Content>
            <Step.Title>{title}</Step.Title>
            <Step.Description style={{ textAlign: 'center' }}>{`(${index +
              1} of ${length})`}</Step.Description>
          </Step.Content>
        </Step>
      );
    });
  };

  const generateCitationDetails = () => {
    return (
      <>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Citation Language
          </Header>
          <Segment basic>{fur.citationLanguage}</Segment>
        </Grid.Column>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Citation Narrative
          </Header>
          <Segment basic>{fur.citationNarrative}</Segment>
        </Grid.Column>
      </>
    );
  };

  const generateCitationActivity = () => {
    return (
      <>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Activities to support Correction
          </Header>
          <Segment basic>{fur.supportCorrectionText}</Segment>
        </Grid.Column>
        <Grid.Column>
          <Divider hidden></Divider>
          <Header style={style.header} as="h3">
            Activities to verify Correction
          </Header>
          <Segment basic>{fur.verifyCorrectionText}</Segment>
        </Grid.Column>
      </>
    );
  };

  const generateRPMInfo = () => {
    const { RPMFeedbackInfo } = findings.FollowInfo;
    const {
      followUpReviewer,
      grantSpecialist,
      pocProgramSpecialist,
      programSpecialist,
    } = RPMFeedbackInfo;

    let { reviewId } = findings;

    return (
      <Grid.Column>
        <Header style={style.header} as="h3">
          RPM Input
        </Header>
        <Divider />
        <p>Review ID: {reviewId}</p>
        <p>Total number of ANC Findings: {findings.FollowInfo.ncCount}</p>
        <p>Total number of DEF Findings: {findings.FollowInfo.defCount}</p>
        <p>
          Follow-up review for these findings is conducted by {followUpReviewer}
        </p>
        <p>Program Specialist: {programSpecialist || 'N/A'}</p>
        <p>Grantee Specialist: {grantSpecialist || 'N/A'}</p>
        <p>
          Point of contact Program Specialist: {pocProgramSpecialist || 'N/A'}
        </p>
      </Grid.Column>
    );
  };

  const showErrors = () => {
    if (isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        content={errors}
      />
    );
  };

  const renderInfo = () => {
    if (errors) {
      return showErrors();
    }

    return (
      <Segment basic loading={loading}>
        {findings && findings.FollowInfo ? (
          <Grid stackable>
            <Grid.Row>{generateHeaderInfo()}</Grid.Row>
            <Grid.Row columns="equal">{generateCAPDetails()}</Grid.Row>
            <Grid.Row columns="equal">{generateReviewDetails()}</Grid.Row>
            <Grid.Row columns="equal">{generateCitations()}</Grid.Row>
            <Grid.Row columns="equal">{generateCitationDetails()}</Grid.Row>
            <Grid.Row columns="equal">{generateCitationActivity()}</Grid.Row>
            <Grid.Row columns="equal">{generateRPMInfo()}</Grid.Row>
            <Grid.Row align="center">
              <Button style={{ margin: '0 auto' }}>
                <a
                  href={`/review/downloadFurRecs/${findings.reviewId}`}
                  download={`${findings.reviewId}.pdf`}
                  className=""
                >
                  Download PDF FURs
                </a>
              </Button>
            </Grid.Row>
          </Grid>
        ) : null}
      </Segment>
    );
  };

  return (
    <SlidingContainer calendar title={generateGranteeInfo()}>
      {renderInfo()}
    </SlidingContainer>
  );
};

const mapStateToProps = state => ({
  findings: state.review.fetchedFindingDetails,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {
  fetchFindingsDetail,
})(withRouter(FollowUpRecommendationsLegacy));
