/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';

//Import Components
import GranteeUnavailabilityAccordion from './GranteeUnavailabilityAccordion';

class GranteeCurrentlySelectedDatesModal extends Component {
  state = {
    showSelectedModal: false,
  };

  handleShowModal = e => {
    e.preventDefault();
    this.setState({ showSelectedModal: true });
  };

  handleHideModal = () => {
    this.setState({ showSelectedModal: false });
  };

  render() {
    return (
      <div>
        <a href="" onClick={this.handleShowModal}>
          View Currently Selected Dates
        </a>
        <Modal
          show={this.state.showSelectedModal}
          onHide={this.handleHideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Currently Selected Dates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GranteeUnavailabilityAccordion {...this.props} />
          </Modal.Body>
          <Modal.Footer>
            <Button color="blue" onClick={this.handleHideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(GranteeCurrentlySelectedDatesModal);
