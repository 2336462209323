import React from 'react';
import isEmpty from 'lodash/isEmpty';

export default function ClassReportTemplate({ classSummary }) {
  const emptyMessage = (
    <div>
      <br />
      <br />
      <br />
      <br />
      <h2>Report not availabel.</h2>
    </div>
  );

  const ccList = (
    <ul>
      <li>Ms.Kimberly Chalk, Regional Program Manager</li>
      <li>Rachelle Johnson, Policy Council Chairperson</li>
      <li>Lydia Canova, CEO / Executive Director</li>
      <li>Keisha Ceasar, Head Start Director</li>
    </ul>
  );

  const report = (
    <div>
      <br />
      <br />
      <br />
      <br />
      <div>
        <div className="col-md-8">
          <h5>To: Board Chairperson</h5>
          <p>
            <strong>
              <em>{classSummary.boardChairPersonname}</em>
            </strong>
          </p>
        </div>
        <div className="col-md-4">
          <h5>From: Responsible HHS Official</h5>
          <p>
            <strong>
              <em>{!isEmpty(classSummary) && classSummary.grantee.name}</em>
            </strong>
          </p>
          <p>
            <em>{!isEmpty(classSummary) && classSummary.grantee.line1}</em>
          </p>
          <p>
            <em>{!isEmpty(classSummary) && classSummary.grantee.line2}</em>
          </p>
          <p>
            <em>
              {!isEmpty(classSummary) &&
                `${classSummary.grantee.city}, ${classSummary.grantee.state}`}
            </em>
          </p>
          <p>
            <em>{!isEmpty(classSummary) && classSummary.grantee.postalCode}</em>
          </p>
        </div>
      </div>
      <div>
        <div className="text-center col-md-12">
          <h2>
            Results from CLASS<sup>®</sup> Observations
          </h2>
        </div>

        <div className="col-md-12">
          <p>
            Thank you for your support during the recent Office of Head Start
            onsite CLASS<sup>®</sup> review conducted from{' '}
            <strong>{classSummary.reviewStartDate}</strong> to{' '}
            <strong>{classSummary.reviewEndDate}</strong> of your Head Start
            program.
          </p>
        </div>
        <div className="col-md-12">
          <p>
            Observations were conducted in preschool center-based classrooms
            using the Pre-K Classroom Assessment Scoring System (CLASS
            <sup>®</sup>). The CLASS<sup>®</sup> tool looks at three domains and
            ten dimensions of teacher-child interactions and measures those
            observed interactions on a seven point scale. Please share these
            results with the appropriate governing board, policy council,
            management, staff and stakeholders.
          </p>
        </div>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Domain</th>
            <th>Score</th>
            <th>Domain</th>
            <th>Score</th>
            <th>Domain</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Emotional Support</td>
            <td>
              {!isEmpty(classSummary) && classSummary.score[0].emotionalSupport}
            </td>
            <td>Classroom Organization</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].classroomOrganization}
            </td>
            <td>Instructional Support</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].instructionalSupport}
            </td>
          </tr>
          <tr className="text-center">
            <td colSpan={7}>DIMENSIONS</td>
          </tr>
          <tr>
            <td>Positive Climate</td>
            <td>
              {!isEmpty(classSummary) && classSummary.score[0].positiveClimate}
            </td>
            <td>Behavior Management</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].behaviorManagement}
            </td>
            <td>Concept Development</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].conceptDevelopment}
            </td>
          </tr>
          <tr>
            <td>Negative Climate*</td>
            <td>
              {!isEmpty(classSummary) && classSummary.score[0].negativeClimate}
            </td>
            <td>Productivity</td>
            <td>
              {!isEmpty(classSummary) && classSummary.score[0].productivity}
            </td>
            <td>Quality of Feedback</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].qualityOfFeedback}
            </td>
          </tr>
          <tr>
            <td>Teacher Sensitivity</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].teacherSensitivity}
            </td>
            <td>Instructional Learning Formats</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].instructionalLearningFormats}
            </td>
            <td>Language Modeling</td>
            <td>
              {!isEmpty(classSummary) && classSummary.score[0].languageModeling}
            </td>
          </tr>
          <tr>
            <td>Regard for Student Perspectives</td>
            <td>
              {!isEmpty(classSummary) &&
                classSummary.score[0].studentPerspectives}
            </td>
            <td />
            <td />
            <td />
            <td />
          </tr>
        </tbody>
      </table>

      <div className="text-center">
        <p>
          *Note: To calculate the Emotional Support domain, subtract the
          Negative Climate score from 8, add the Positive Climate, Teacher
          Sensitivity, and Regard for Student Perspective scores, then divide by
          4.
        </p>
      </div>

      <div>
        <p>
          For more information on CLASS<sup>®</sup> domains and dimensions,
          please see the attached description and visit the Early Childhood
          Learning and Knowledge Center, National Center on Quality Teaching and
          Learning at http://eclkc.ohs.acf.hhs.gov/hslc/tta-system/teaching.
        </p>

        <p>If you have any questions, please contact your Regional Office.</p>
      </div>

      <div>cc: {ccList}</div>

      <div>
        <h3 className="text-center">
          About CLASS<sup>®</sup>
        </h3>
        <p>
          The Improving Head Start for School Readiness Act of 2007 requires
          that the Office of Head Start (OHS) include in the monitoring reviews
          of Head Start agencies a valid and reliable research-based
          observational instrument that assesses classroom quality, including
          the assessment of multiple dimensions of teacherchild interactions
          that are linked to positive child outcomes and later achievement.
        </p>

        <p>
          CLASS<sup>®</sup> assesses interactions between children and teachers
          in three broad domains of classroom quality: Emotional Support,
          Classroom Organization, and Instructional Support. The Office of Head
          Start believes that the domains of quality measured by CLASS
          <sup>®</sup> remain central to its approach to child development and
          education and serve as important indicators of the future school
          readiness of all Head Start children.
        </p>

        <p>
          For all dimensions*, the scoring principles are as follows<sup>1</sup>
          :
        </p>

        <div>
          <ol>
            <em>
              <h5>Low range score</h5>
            </em>
            <li>
              <em>
                The low range description of the CLASS<sup>®</sup> dimension
                fits the classroom and/or teacher very well. All, or almost all,
                relevant indicators in the low range are present.
              </em>
            </li>
            <li>
              <em>
                The low range description of the CLASS<sup>®</sup> dimension
                mostly fits the classroom and/or teacher, but there are one or
                two indicators that are in the middle range.
              </em>
            </li>

            <em>
              <h5>Middle range score</h5>
            </em>
            <li>
              <em>
                The middle range description of the CLASS<sup>®</sup> dimension
                mostly fits the classroom and/or teacher, but there are one or
                two indicators in the low range. All, or almost all, relevant
                indicators in the low range are present.
              </em>
            </li>
            <li>
              <em>
                The middle range description of the CLASS<sup>®</sup> dimension
                fits the classroom and/or teacher very well. All, or almost all,
                relevant indicators in the middle range are present.
              </em>
            </li>
            <li>
              <em>
                The middle range description of the CLASS<sup>®</sup> dimension
                mostly fits the classroom and/or teacher, but there are one or
                two indicators in the high range.
              </em>
            </li>

            <em>
              <h5>High range score</h5>
            </em>
            <li>
              <em>
                The high range description of the CLASS<sup>®</sup> dimension
                mostly fits the classroom and/or teacher, but there are one or
                two indicators in the middle range.
              </em>
            </li>
            <li>
              <em>
                The high range description of the CLASS<sup>®</sup> dimension
                fits the classroom and/or teacher very well. All, or almost all,
                relevant indicators in the high range are present.
              </em>
            </li>

            <p>
              *Note: The Negative Climate dimension is inversely scored with a
              higher score indicating lower quality. For all other dimensions
              and domains, a higher score indicates higher quality.
            </p>
          </ol>

          <p>
            The scores from each class observation are averaged across the
            grantee to result in <strong>grantee-level</strong> dimension
            scores. The grantee dimension scores are then used to calculate the
            grantee-level domain scores.
          </p>

          <p>
            The scores from CLASS<sup>®</sup> observations can be used for a
            variety of purposes, including professional development, program
            improvement, policy, goal-setting and monitoring. The Office of Head
            Start began using the CLASS<sup>®</sup> for monitoring purposes in
            FY2010 to collect information on the experiences of children at each
            grantee.
          </p>

          <p>
            In FY2012, OHS refined the use of the CLASS<sup>®</sup> in
            monitoring to include the use of a randomly selected sample of
            center-based preschool classes for observations, a clearly
            articulated methodology followed by CLASS<sup>®</sup> reviewers, and
            additional support for the CLASS<sup>®</sup> reviewer pool. For each
            preschool class selected in the sample, trained and certified CLASS
            reviewers conduct two 20 minute observations and score at the
            dimension level using a 7-point scale at the end of each observation
            cycle.
          </p>

          <div className="row">
            <div className="col-md-4">
              <hr />
            </div>
          </div>
          <div>
            <sup>1</sup> Pianta R, La Paro K, Hamre, B. Classroom Assessment
            Scoring System Manual—Pre-K, Paul H. Brookes Publishing Co., 2008.
          </div>
        </div>

        <br />
        <br />
        <br />

        <div className="row">
          <p className="text-center text-muted">
            Classroom Assessment Scoring System and CLASS are trademarks of
            Robert C. Pianta, Ph.D
          </p>
        </div>
      </div>
    </div>
  );

  return isEmpty(classSummary) ? emptyMessage : report;
}
