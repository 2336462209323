import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Reports from './../OldReports/Reports';

class ReviewDetailPage extends Component {
  componentWillMount = () => {
    const reviewId = this.props.router.params && this.props.router.params.id;
    this.props.router.replace(`/reports/${reviewId}`);
  };

  renderMetaData(metas) {
    let data = [];
    let counter = 1;

    _.each(metas, function(val, key) {
      if (!_.isEmpty(val)) {
        data.push(
          <li key={counter}>
            <ul className="list-unstyled">
              <li>
                <h5 className="text-muted">{key}</h5>
              </li>
              <li dangerouslySetInnerHTML={{ __html: val }} />
            </ul>
          </li>
        );

        counter++;
      }
    });

    return <ul className="list-unstyled">{data}</ul>;
  }

  buildForm() {
    return Object.keys(this.props.detail.data[0]).map((key, val) => {
      return (
        <li key={key}>
          <h5 className="text-muted">{key}</h5>
        </li>
      );
    });
  }

  // addGenerateReportButton() {
  //
  //   const pathName = this.context.router.location.pathname;
  //   const { roles } = this.props.currentUser;
  //
  //   if (_.includes(roles, 'AmsAdmi', 'Analyst', 'FieldOperationsManager', 'OHSCOR', 'SeniorDirector', 'HelpDesk', 'OHSStaff'))
  //     return <Link to={`${pathName}/report`} className='btn btn-primary' role='button'>Generate Report</Link>
  // }

  render() {
    return (
      <div>
        <div className="row">
          <div>
            <div className="col-md-8" />
            <div className="text-right col-md-4">
              {/*{this.addGenerateReportButton()}*/}
            </div>
          </div>

          {/*<div className='col-sm-12'>
            {this.props.detail ? <p>Asignee: {this.props.detail.asignee}</p> : ''}
            {this.props.detail.data ? <div>{this.renderMetaData(this.props.detail.data[0])}</div> : ''}
          </div>*/}
        </div>

        <div className="row">
          <div className="col-sm-12">&nbsp;</div>
        </div>

        <div className="row well">
          <div className="col-sm-12 ">
            <Reports reviewId={this.props.params.id} />
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    detail: state.reviews,
    error: state.error,
    groups: state.auth.groups,
  };
}

ReviewDetailPage.propTypes = {
  groups: PropTypes.array,
};

ReviewDetailPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(ReviewDetailPage);
