import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import components.
import { AmsGoogleMap } from '../../utils/GoogleMap';

// Import actions.
import { clearGeocode, fetchGeocode } from '../../actions/geocodeAction';
import { updateGeocode } from '../../actions/granteeActions';

class GranteeMap extends Component {
  componentDidMount() {
    const {
      line1,
      line2,
      city,
      state,
      latitude,
      longitude,
    } = this.props.address;

    if ((line1 || line2 || city || state) && !latitude && !longitude) {
      this.props.fetchGeocode(`${line1} ${line2} ${city} ${state}`);
    }
  }

  componentWillUnmount() {
    if (this.props.geocode.length) this.props.clearGeocode();
  }

  buildMap() {
    const { city, latitude, longitude } = this.props.address;
    const { geocode } = this.props;

    let markers = [];

    if (latitude && longitude) {
      markers.push({
        position: {
          lat: latitude,
          lng: longitude,
        },
        key: city,
        defaultAnimation: 2,
      });
    }

    if (geocode.length) {
      markers = geocode.map((geocodeItem, index) => {
        const { latitude, longitude } = geocodeItem;

        // Code block is commented out because backend no longer has endpoint.
        // if (grantee && latitude && longitude) {

        //   const geocodeObject = {
        //     granteeId: grantee.granteeId,
        //     hSlongitude: latitude,
        //     hSlatitude: longitude,
        //     eHSlongitude : 0,
        //     eHSlatitude : 0
        //   }

        //   this.props.updateGeocode(geocodeObject)
        // }

        return {
          position: {
            lat: latitude,
            lng: longitude,
          },
          key: index,
        };
      });
    }

    if (markers.length)
      return (
        <div className="col-sm-5 map">
          <AmsGoogleMap
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            markers={markers}
            defaultZoom={16}
            defaultCenter={markers[0].position}
          />
        </div>
      );

    return (
      <div className="col-sm-5 text-center">
        Could not display map. <br />
        No or invalid address.
      </div>
    );
  }

  render() {
    return this.buildMap();
  }
}

function mapStateToProps(state) {
  return {
    geocode: state.geocode,
    grantee: state.grantee,
  };
}

GranteeMap.propTypes = {
  grantee: PropTypes.object.isRequired,
  geocode: PropTypes.array.isRequired,
  address: PropTypes.object.isRequired,
  fetchGeocode: PropTypes.func.isRequired,
  clearGeocode: PropTypes.func.isRequired,
  updateGeocode: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchGeocode,
  clearGeocode,
  updateGeocode,
})(GranteeMap);
