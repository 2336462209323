import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router';

//import Actions
import { fetchUserHistory } from '../../../actions/profileActions';

import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';

const UserReviewHistory = props => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(25);
  const [sortName, setSortName] = useState('dueDate');
  const [sortOrder, setSortOrder] = useState('desc');

  const { profile } = props;
  const history = profile.history || {};
  const dispatch = useDispatch();

  const amsTableRef = useRef(null);

  useEffect(() => {
    if (profile.oid) {
      setLoading(true);

      dispatch(
        fetchUserHistory({
          filters: {
            type: 'reviews',
            oid: profile.oid,
          },
          page: page,
          limit: limit,
          sortName: sortName,
          sortOrder: sortOrder,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.oid, page, limit, sortName, sortOrder]);

  useEffect(() => {
    if (history && history.data) {
      setData(history.data);
      setTotal(history.total);
      setLoading(false);
    }
  }, [history]);

  const renderReviewsResult = () => {
    const dateFormatter = cell => AmsDateFormatters.formatDateTime(cell);

    const roleFormatter = cell => {
      if (cell) {
        return cell.map((e, index) => {
          return <p key={index}>{e}</p>;
        });
      }
      return 'N/A';
    };

    const skillFormatter = cell => {
      if (cell) {
        const { languageSkills, contentAreaSkills, otherSkills } = cell;
        return [
          ...(contentAreaSkills
            ? contentAreaSkills.map(e => <p key={e}>{e}</p>)
            : []),
          ...(languageSkills
            ? languageSkills.map(e => <p key={e}>{e}</p>)
            : []),
          ...(otherSkills ? otherSkills.map(e => <p key={e}>{e}</p>) : []),
        ];
      }
      return 'N/A';
    };

    const linkId = (cell, row) => {
      if ((row && row.newFAFlowFlag) || (row && row.reviewType === 'RAN')) {
        let easReview = row.reviewType === 'FA1-R' ? '/new' : '';
        let fiscalYear = row.fiscalYear
          ? `fy` + row.fiscalYear.toString().slice(-2)
          : 'fy19';
        return (
          <Link
            to={{
              pathname: `/review${easReview}/${fiscalYear}/${cell}`,
              state: { reviewType: `${row.reviewType}` },
            }}
          >
            {cell}
          </Link>
        );
      }
      return (
        <Link
          to={{
            pathname: `/review/${cell}`,
            state: { reviewType: `${row.reviewType}` },
          }}
        >
          {cell}
        </Link>
      );
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      setPage(page);
      setLimit(sizePerPage);
    };

    const onPageChange = (page, sizePerPage) => {
      setPage(page);
      setLimit(sizePerPage);
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (type === 'sort') {
        setSortOrder(sortOrder);
        setSortName(sortField);
      }
    };

    const columns = [
      {
        dataField: 'reviewId',
        text: 'Review ID',
        formatter: linkId,
        sort: true,
      },
      /*{
        dataField: 'granteeId',
        text: 'Grantee ID',
        sort: true,
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
      },
      {
        dataField: 'numberOfGrants',
        text: 'Number of Grants',
        sort: true,
      },*/
      {
        dataField: 'reviewType',
        text: 'Review Type',
        sort: true,
      },
      {
        dataField: 'startDate',
        text: 'Start Date',
        formatter: dateFormatter,
        sort: true,
      },
      /*{
        dataField: 'endDate',
        text: 'End Date',
        formatter: dateFormatter,
        sort: true,
      },*/
      {
        dataField: 'dueDate',
        text: 'Due Date',
        formatter: dateFormatter,
        sort: true,
      },
      /*{
        dataField: 'outCome',
        text: 'Outcome',
        sort: true,
      },*/
      {
        dataField: 'workFlowStatus',
        text: 'Review Status',
        sort: true,
      },
      {
        dataField: 'reviewSkills',
        formatter: skillFormatter,
        text: 'Review Skill',
      },
      {
        dataField: 'reviewRoles',
        text: 'Review Role',
        formatter: roleFormatter,
      },
    ];

    /*hall include columns such as review id(link), type, start, and end date, s
    tatus, a skill that the user was added for (content, Other, Language), and the role user played*/
    return (
      <div ref={amsTableRef} tabIndex="-1">
        <AmsTable
          remote
          data={data}
          page={page}
          total={total}
          limit={limit}
          loading={loading}
          columns={columns}
          keyField="_id"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  return renderReviewsResult();
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {})(UserReviewHistory);
