import React from 'react';
import PropTypes from 'prop-types';
import './assets/style.css';

const LinkItem = ({ to, title, ...restProps }) => (
  <li>
    <a href={`#${to}`} className="c-links__item" {...restProps}>
      {title}
    </a>
  </li>
);

LinkItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const AmsSkipLinks = ({ links }) => {
  const hasLinks = links && links.length;

  if (hasLinks) {
    return (
      <section className="ams-skiplinks">
        <div className="c-links">
          <p className="u-vs-hidden">Accessibility links</p>

          <ul>
            {links.map(link => (
              <LinkItem key={link.title} {...link} />
            ))}
          </ul>
        </div>
      </section>
    );
  }

  return null;
};

AmsSkipLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AmsSkipLinks;
