import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

const ReviewGranteeInfo = ({ selectedReview }) => {
  if (_.isEmpty(selectedReview.grantees)) return null;

  const { grantees, precedingGranteeId } = selectedReview;

  // const inActiveGranteeList = _.filter(grantees, grantee => {
  //   return grantee.granteeStatus !== 'Active';
  // });

  // const inActiveGranteeString = inActiveGranteeList
  //   .map(inactiveGrantee => {
  //     return `${inactiveGrantee.granteeId}`;
  //   })
  //   .join(', ');

  const firstGrantee = grantees[0] || [];

  return (
    <div>
      <div className="grantee-item">
        {firstGrantee.granteeName && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Grantee Name"
            >
              Grantee Name:{' '}
            </span>{' '}
            <Link
              to={`/grantee/${firstGrantee.granteeId}`}
            >{` ${firstGrantee.granteeName} (${firstGrantee.granteeId})`}</Link>
          </div>
        )}
        {precedingGranteeId && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Preceding Grantee ID"
            >
              Preceding Grantee ID:{' '}
            </span>{' '}
            <Link to={`/grantee/${precedingGranteeId}`}>
              {precedingGranteeId}
            </Link>
          </div>
        )}
        {selectedReview.reviewType && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Review Type"
            >
              Review Type:{' '}
            </span>{' '}
            {` ${selectedReview.reviewType}`}{' '}
            {selectedReview.reviewTypeDefinition &&
              `(${selectedReview.reviewTypeDefinition})`}
          </div>
        )}
        {selectedReview.reviewSubType && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Sub-type"
            >
              Sub-type:{' '}
            </span>{' '}
            {selectedReview.reviewSubType}
          </div>
        )}
        {selectedReview.reviewDataCollection && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Data Collection"
            >
              Data Collection:{' '}
            </span>{' '}
            {selectedReview.reviewDataCollection}
          </div>
        )}
        {selectedReview.reviewStatus && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Review Status"
            >
              Review Status:{' '}
            </span>{' '}
            {selectedReview.reviewStatus}
          </div>
        )}
        {selectedReview.reviewOutcome && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Review Outcome"
            >
              Review Outcome:{' '}
            </span>{' '}
            {selectedReview.reviewOutcome}
          </div>
        )}
        {selectedReview.rliApprovalRequest &&
          selectedReview.rliApprovalRequest !== 'N/A' && (
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Reviewer Request"
              >
                Reviewer Request:{' '}
              </span>{' '}
              {selectedReview.rliApprovalRequest}
            </div>
          )}
        {selectedReview.parentReviewId && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Parent Review"
            >
              Parent Review:{' '}
            </span>{' '}
            <Link to={`/review/${selectedReview.parentReviewId}`}>
              {selectedReview.parentReviewId}
            </Link>
          </div>
        )}
        {firstGrantee.region && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Region"
            >
              Region:{' '}
            </span>{' '}
            {firstGrantee.region}
          </div>
        )}
        <div>
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="State"
          >
            State:{' '}
          </span>{' '}
          {firstGrantee.state && firstGrantee.state}
        </div>
        {firstGrantee.agencyId && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Agency ID"
            >
              Agency ID:{' '}
            </span>{' '}
            {firstGrantee.agencyId}
          </div>
        )}
        {selectedReview.numberOfGrants && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Number of grants in this review"
            >
              Number of grants in this review:{' '}
            </span>{' '}
            {selectedReview.numberOfGrants}
          </div>
        )}
      </div>

      <ul className="grantee-list">
        {// eslint-disable-next-line array-callback-return
        grantees.map((grantee, index) => {
          // Ignore the first one because it's shown above with it's full detail.
          if (index > 0)
            return (
              grantee.granteeName && (
                <li className="grantee-item" key={index}>
                  <span
                    role="heading"
                    aria-level="3"
                    className="field-title"
                    aria-label="Grantee Name"
                  >
                    Grantee Name:{' '}
                  </span>{' '}
                  <Link
                    to={`/grantee/${grantee.granteeId}`}
                  >{` ${grantee.granteeName} (${grantee.granteeId})`}</Link>
                </li>
              )
            );
        })}
      </ul>
      {selectedReview['Masking & Vaccination Monitoring status'] && (
        <div>
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Masking & Vaccination Monitoring status"
          >
            Masking & Vaccination Monitoring status:{' '}
          </span>{' '}
          {selectedReview['Masking & Vaccination Monitoring status']}
        </div>
      )}
    </div>
  );
};

ReviewGranteeInfo.propTypes = {
  selectedReview: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewGranteeInfo);
