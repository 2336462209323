import React from 'react';
import DesktopContainer from './Desktop/DesktopContainer.js';
import MobileContainer from './Mobile/MobileContainer';

const ResponsiveContainer = props => (
  <div style={{ minHeight: '100vh', position: 'relative' }}>
    <DesktopContainer {...props}>{props.children}</DesktopContainer>
    <MobileContainer {...props}>{props.children}</MobileContainer>
  </div>
);

export default ResponsiveContainer;
