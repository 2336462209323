/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import { Dropdown, Form, Grid, Header, Segment } from 'semantic-ui-react';

// Import components.
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsSkipLinks from '../../../utils/AmsSkipLinks';
import AmsTable from '../../../utils/AmsTable';
import ReviewLayout from '../../../utils/layout/ReviewLayout';

// Import utils
import { reviewTypeFormat } from '../../../utils/TextFormatters';

import AmsDateFormatters from '../../../utils/AmsDateFormatters';

// Import actions
import {
  fetchSurveyGuides,
  fetchReviewPerformanceMeasures,
  fetchReviewPerformanceAreas,
} from '../../../actions/fy19/reviewActions';
import {
  downloadEvidenceFileAttachment,
  surveyEvidenceBinder,
} from '../../../actions/reviewActions';
import { downloadSurveyAttachment } from '../../../actions/surveyActions';

function SurveyEvidenceBinder(props) {
  const amsTableRef = useRef(null);
  const { params } = props.router;
  const { selectedReview } = useSelector(state => state.review);
  const fiscalYear = selectedReview && selectedReview.fiscalYear;
  const newFormsvalidReviewtypes = ['Follow-up', 'RAN', 'Special'];

  const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

  const [filters, setFilters] = useState({
    reviewId: '',
    guideName: '',
    performanceArea: '',
    type: '',
  });

  const [paginationOptions, setPaginationOptions] = useState({
    page: 1,
    limit: 10,
  });

  const [responseErrors, setResponseErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [performanceLoading, setPerformanceLoading] = useState(false);
  const [fetchingGuides, setFetchingGuides] = useState(false);

  const scrollToResults = () => scrollToRef(amsTableRef);

  const scrollToRef = ref => {
    window.scrollTo(0, ref.current.offsetTop);
    ref.current.focus();
  };

  const dispatch = useDispatch();
  const reviewId = params.id || params.reviewId;

  useEffect(() => {
    setFilters({ ...filters, reviewId: reviewId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  useEffect(() => {
    if (
      selectedReview.reviewId &&
      (!selectedReview.guides ||
        selectedReview.guides.length === 0 ||
        !selectedReview.performanceMeasures) &&
      fetchingGuides === false
    ) {
      const { reviewId, reviewType, fiscalYear } = selectedReview;
      if (!reviewId && !reviewType) return;

      let requestInput = { reviewId, reviewType, fiscalYear };
      dispatch(fetchSurveyGuides(requestInput));
      loadPerformanceMeasures();
      setFetchingGuides(true);
    }

    if (
      fetchingGuides === true &&
      selectedReview.guides &&
      selectedReview.performanceMeasures
    ) {
      setFetchingGuides(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReview]);

  //retrieve data whenever paginationOptions are changed
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOptions]);

  const loadPerformanceMeasures = () => {
    let guideCode;
    if (filters.guideName !== '') {
      guideCode = selectedReview.guides.find(e => {
        return e.name === filters.guideName;
      });
    }
    const { reviewId, reviewType, fiscalYear, isNewForms } = selectedReview;

    let requestInput = { reviewId, reviewType, fiscalYear };

    if (guideCode) {
      requestInput.guideCode = guideCode.guideCode;
      requestInput.guideName = guideCode.name;
    }

    let actionToDispatch;
    if (
      reviewType === 'FA2-CR' ||
      reviewType === 'FA1-FR' ||
      (newFormsvalidReviewtypes.includes(selectedReview.reviewType) &&
        selectedReview.isNewForms)
    ) {
      actionToDispatch = fetchReviewPerformanceAreas;
      requestInput.guideName = (guideCode && guideCode.name) || '';
    } else {
      actionToDispatch = fetchReviewPerformanceMeasures;
    }

    setFilters({
      ...filters,
      performanceArea: '',
    });
    setPerformanceLoading(true);

    dispatch(actionToDispatch(requestInput))
      .then(e => {
        setPerformanceLoading(false);
      })
      .catch(error => {
        setPerformanceLoading(false);
      });
  };

  //retrieve data whenever paginationOptions are changed
  useEffect(() => {
    if (selectedReview && selectedReview.reviewId && !fetchingGuides)
      loadPerformanceMeasures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.guideName]);

  const getData = () => {
    const { page, limit } = paginationOptions;
    setLoading(true);

    let requestInput = {
      filters,
      page,
      limit,
    };

    if (requestInput.filters && !requestInput.filters.reviewId) {
      requestInput.filters.reviewId = params.id || params.reviewId;
    }

    dispatch(surveyEvidenceBinder(requestInput))
      .then(data => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setResponseErrors([...responseErrors, error]);
      });
  };

  const filtersOnSubmit = e => {
    e.preventDefault();
    getData();
  };

  const handleValueChange = (e, { name, value }) => {
    if (name === 'type' && value === 'presite') {
      setFilters({
        ...filters,
        [name]: value,
        guideName: '',
        performanceArea: '',
      });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const handleFilterReset = () => {
    setFilters(
      {
        ...filters,
        performanceArea: '',
        guideName: '',
        type: '',
      },
      () => {
        getData();
      }
    );
  };

  const formatLink = (cell, row) => {
    const reviewId = filters.reviewId || params.id;
    const reviewType =
      selectedReview && reviewTypeFormat(selectedReview.reviewType);

    if (!reviewId || cell === 'N/A') {
      return cell;
    }

    if (selectedReview.reviewType == 'Follow-up') {
      return (
        <Link
          to={`/review/fy${selectedReview.fiscalYear.toString().slice(-2)}/${
            selectedReview.reviewId
          }/survey/followup?citation=${row.citation}`}
        >
          {cell}
        </Link>
      );
    }
    if (
      row?.questionIds?.length > 0 &&
      row.questionIds[0] !== '' &&
      selectedReview.reviewType !== 'Follow-up'
    ) {
      return (
        <Link
          to={{
            pathname: `/review/fy${selectedReview.fiscalYear
              .toString()
              .slice(-2)}/${selectedReview.reviewId}/survey/${reviewType}`,
            query: {
              g: encodeURIComponent(row.guideName),
            },
          }}
        >
          {cell}
        </Link>
      );
    }

    return (
      <Link
        aria-label={row?.performanceArea}
        to={{
          pathname: `/task/${reviewId}/survey/${reviewType}`,
          query: {
            g: encodeURIComponent(row.guideName),
            pm: encodeURIComponent(row?.performanceArea),
          },
        }}
      >
        {cell}
      </Link>
    );
  };

  const renderFileFilters = () => {
    const { guideName, performanceArea, type } = filters;

    const { guides, performanceMeasures, reviewType } = selectedReview;
    let guideOptions;
    let performanceMeasureOptions;
    let typeOptions;

    if (
      selectedReview &&
      selectedReview.reviewType &&
      (reviewType === 'FA2-CR' || reviewType === 'FA1-FR')
    ) {
      typeOptions = [
        {
          text: `${selectedReview.reviewType} Survey`,
          value: 'survey',
          key: 'survey',
        },
        {
          text: `Pre-Review Survey`,
          value: 'presite',
          key: 'presite',
        },
      ];
    }

    if (guides && guides.length > 0) {
      guideOptions = guides.map(e => {
        return {
          text: e.name,
          value: e.name,
          key: e.guideCode,
        };
      });
    }
    if (performanceMeasures && performanceMeasures.length > 0) {
      performanceMeasureOptions = performanceMeasures.map(e => {
        if (e && e.name) {
          return {
            text: e.name,
            value: e.name,
            key: e.formCode,
          };
        } else {
          return {
            text: e,
            value: e,
            key: e,
          };
        }
      });
    }
    return (
      <Segment>
        <Form onSubmit={filtersOnSubmit} noValidate className="filter-form">
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />

          <Form.Group>
            <Form.Field width={5}>
              <AmsFormLabel name="Guide Name" fieldLabel="filterGuideName">
                <Dropdown
                  placeholder="Select Guide Name"
                  value={guideName}
                  name="guideName"
                  disabled={type === 'presite'}
                  onChange={handleValueChange}
                  options={guideOptions || []}
                  fluid
                  search
                  selection
                  aria-labelledby="filterGuideName"
                  searchInput={{
                    id: 'filterGuideName',
                    title: 'Select a Guide Name',
                    'aria-labelledby': 'filterGuideName',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            <Form.Field width={5}>
              <AmsFormLabel
                name="Performance Area"
                helpText={true}
                fieldLabel="filterPerformanceMeasure"
              >
                <Dropdown
                  placeholder="Select Performance Area"
                  value={performanceArea}
                  disabled={type === 'presite'}
                  options={performanceMeasureOptions || []}
                  fluid
                  loading={performanceLoading}
                  search
                  selection
                  name="performanceArea"
                  onChange={handleValueChange}
                  aria-labelledby="filterPerformanceMeasure"
                  searchInput={{
                    id: 'filterPerformanceMeasure',
                    title: 'Filter by Performance Measure',
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
            &nbsp;
            <Form.Button
              className="filter-button"
              primary
              content={'Filter'}
              fluid
            />
            <Form.Button
              basic
              className="filter-button"
              content="Reset"
              fluid
              onClick={handleFilterReset}
            />
          </Form.Group>
        </Form>
      </Segment>
      // </div>
    );
  };

  const triggerDownload = downloadData => {
    if (downloadData.isLegacy) {
      dispatch(downloadSurveyAttachment(downloadData.id, downloadData.name));
    } else {
      dispatch(
        downloadEvidenceFileAttachment(downloadData.id, downloadData.name)
      );
    }
  };

  const downloadLink = (cell, row) => {
    return row.attachments.map(data => {
      // eslint-disable-next-line array-callback-return
      if (!data) return;
      let downloadData = {
        id: data.file_id,
        name: data.fileName,
        isLegacy: row.isLegacy,
      };

      return (
        <div>
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              triggerDownload(downloadData);
            }}
          >
            {downloadData.name}
          </a>
        </div>
      );
    });
  };

  const renderFilesTable = () => {
    const { reviewBinder } = selectedReview;
    if (!selectedReview || !selectedReview.reviewType) {
      return null;
    }

    const data =
      reviewBinder &&
      reviewBinder.data &&
      reviewBinder.data.map((e, index) => {
        return { index, ...e };
      });
    const limit = reviewBinder && reviewBinder.limit;
    const total = reviewBinder && reviewBinder.total;

    const onSizePerPageChange = (sizePerPage, page) => {
      setPaginationOptions({
        ...paginationOptions,
        page,
        limit: sizePerPage,
      });

      scrollToResults();
    };

    const onPageChange = (page, sizePerPage) => {
      setPaginationOptions({
        ...paginationOptions,
        page: page,
        limit: sizePerPage,
      });

      scrollToResults();
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === paginationOptions.sortName &&
        sortOrder === paginationOptions.sortOrder
      )
        return null;

      if (type === 'sort') {
        setPaginationOptions({
          ...paginationOptions,
          sortName: sortField || paginationOptions.sortName,
          sortOrder: sortOrder || paginationOptions.sortOrder,
        });
      }
    };

    const formatType = (cell, row) => {
      if (row.type === 'presite') {
        const pathname = `/task/${reviewId}/survey/presite`;

        return (
          <Link aria-label="Pre-Site" to={pathname}>
            Pre-Review Survey
          </Link>
        );
      } else {
        return `${selectedReview.reviewType} Survey`;
      }
    };

    const columns = [
      { dataField: 'index', hidden: true, text: 'index' },
      {
        dataField: 'guideName',
        text: 'Guide Name',
        formatter: formatLink,
      },
      {
        dataField: 'performanceArea',
        text: 'Performance Area',
        formatter: formatLink,
      },
      {
        dataField: 'citation',
        text: 'Citation',
      },
      {
        dataField: 'editedDate',
        text: 'Date/Time',
        formatter: formatDate,
      },
      {
        text: 'Uploaded By',
        dataField: 'editedBy',
      },
      { text: 'Download', dataField: 'attachments', formatter: downloadLink },
    ];
    return (
      <div ref={amsTableRef} tabIndex="-1">
        <AmsTable
          data={data || []}
          page={paginationOptions.page}
          total={total || 0}
          limit={limit}
          loading={loading}
          columns={columns}
          keyField="index"
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
        />
      </div>
    );
  };

  const showResponseErrors = () => {
    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map(
              (errorObject, index) =>
                errorObject && (
                  <li key={index}>
                    {errorObject.message} code:(
                    {errorObject.code})
                  </li>
                )
            )}
          </ul>
        </Alert>
      );
  };

  const showInvalidReviewType = () => {
    return <h4>N/A - Invalid Review Type</h4>;
  };

  return (
    <ReviewLayout type="Evidence Binder">
      <>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2" content="Evidence Binder" />
            <br />
          </Grid.Column>
        </Grid.Row>
        <>
          {' '}
          {showResponseErrors()}
          {renderFileFilters()}
          {renderFilesTable()}
        </>
      </>
    </ReviewLayout>
  );
}

export default SurveyEvidenceBinder;
