import React from 'react';
import { isEmpty } from 'lodash';

const formatReviewPageTitle = (review, report = false) => {
  if (!isEmpty(review.reviewType)) {
    const reviewType = review.reviewType && `${review.reviewType} `;
    const reviewId = review.reviewId && `Review ID ${review.reviewId} `;
    const reviewStatus = review.reviewStatus && `- ${review.reviewStatus}`;

    let title = `${reviewType} ${reviewId} ${reviewStatus}`;

    if (report === true) title = `Report for ${title}`;
    else if (report !== false) title = `${report} for ${title}`;

    return title;
  }

  return '';
};

const renderSubheader = (grantees) => {
      
  return !grantees ? ''
    : grantees.length === 1 ? <><span style={{paddingBottom: '0px', display: 'inline-block'}}>Grantee</span>: {formatReviewPageGranteeSubheading(grantees[0])}</>
    : grantees.length > 1 ? <>
      <span>Grantees:</span>
      <ui>
        {grantees.map(grantee => <li>{formatReviewPageGranteeSubheading(grantee)}</li>)}
      </ui>
    </>
    : ''
}

const formatReviewPageGranteeSubheading = (grantee) => {
  const {granteeName, state, granteeId} = grantee;
  return `${granteeName.charAt(granteeName.length-1) === '.' ? granteeName.slice(0,-1): granteeName}, ${state} (${granteeId})`;
}

const shortenText = (string, maxLen, separator = ' ', appendix = '...') => {
  if (isEmpty(string)) return null;

  const sanitizedString = string
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&#{0,1}[a-z0-9]+;/gi, '');

  if (sanitizedString.length <= maxLen) return sanitizedString;

  return `${sanitizedString.substr(
    0,
    sanitizedString.lastIndexOf(separator, maxLen)
  )} ${appendix}`;
};

const granteeName = grantee => {
  if (grantee) {
    const granteeId = grantee.granteeId && `${grantee.granteeId}`;
    //const reviewStatus = grantee.hsesStatus && `- ${grantee.hsesStatus}`;
    const granteeName = grantee.granteeName ? `${grantee.granteeName}` : '';

    const title = `${granteeName} (${granteeId})`;

    return title;
  }

  return '';
};

const formatNumber = x => {
  if (!x) return 'N/A';

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatSharedMember = user => {
  return `${user.actingRole ? user.actingRole + ' - ' : ''}${user.fullName}`;
};

const createReportStageMessage = (reportStageInfo, stage) => {
  const extraMessage = reportStageInfo => {
    if (reportStageInfo) {
      if (reportStageInfo.assignedTo) {
        if (stage === 'OGC' || stage === 'RO' || stage === 'OHS')
          return `${reportStageInfo.assignedTo &&
            reportStageInfo.assignedTo
              .fullName} and has been shared with ${reportStageInfo.sharedWith &&
            reportStageInfo.sharedWith.length > 0 &&
            reportStageInfo.sharedWith
              .map(user => formatSharedMember(user))
              .join(', ')}.`;
        else {
          let toReturn = ` ${reportStageInfo.assignedTo &&
            reportStageInfo.assignedTo.fullName} and has been shared with `;

          if (
            reportStageInfo.sharedWith &&
            reportStageInfo.sharedWith.length > 0 &&
            reportStageInfo.sharedWith.length <= 3
          ) {
            toReturn +=
              reportStageInfo.sharedWith
                .map(user => formatSharedMember(user))
                .join(', ') + '.';
          } else {
            toReturn += `${formatSharedMember(
              reportStageInfo.sharedWith[0]
            )}, ${formatSharedMember(
              reportStageInfo.sharedWith[1]
            )}, and ${reportStageInfo.sharedWith.length - 2} others.`;
          }

          return toReturn;
        }
      }
    }
  };
  switch (stage) {
    case 'signed':
      return 'The report is signed off';
    case 'finalized':
      return 'The report is finalized';
    case 'Shipped':
    case 'Grantee':
      return 'The report is shipped to Grantee';
    case 'Submit To FOM':
    case 'Submit To QC':
    case 'Return To FOM':
    case 'Return To RL':
    case 'OGC':
    case 'RO':
    case 'OHS':
    case 'Submit To Editor':
    case 'Submit To TW':
    case 'internalassigned':
    case 'internalunassigned':
      return (
        'The report is assigned to ' + extraMessage(reportStageInfo, stage)
      );
    default:
      break;
  }
};

const formatDatesArray = dates => {
  let dateText;
  if (dates.length === 1) {
    dateText = dates[0];
  } else if (dates.length === 2) {
    dateText = `${dates[0]} and ${dates[1]}`;
  } else {
    dateText =
      dates.slice(0, dates.length - 1).join(', ') +
      ', and ' +
      dates[dates.length - 1];
  }
  return dateText;
};

const formatPhoneNumber = str => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

const reviewTypeFormat = reviewType => {
  switch (reviewType) {
    case 'FA-1':
      return 'fa1';
    case 'FA1-FR':
      return 'fa1fr';
    case 'FA-2':
      return 'fa2';
    case 'RAN':
      return 'ran';

    case 'Special':
      return 'special';

    case 'AIAN-DEF':
      return 'aian-def';

    case 'ERSEA':
      return 'ersea';

    case 'FA2-CR':
      return 'fa2cr';

    case 'FA1-FR':
      return 'fa1fr';
    case 'Follow-up':
      return 'followup';

    default:
      break;
  }
};

export {
  formatReviewPageTitle,
  renderSubheader,
  shortenText,
  granteeName,
  createReportStageMessage,
  formatNumber,
  formatDatesArray,
  formatPhoneNumber,
  reviewTypeFormat,
};
