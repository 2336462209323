import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Button, Modal } from 'semantic-ui-react';

// Import UI config
import { timeout } from '../config';

// Import actions
import { me } from '../actions/meActions';
import { logout } from '../actions/authActions';
import AmsModal from './AmsModal';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      timeout: timeout && timeout.time,
      remaining: null,
      isIdle: false,
      lastActive: null,
      elapsed: null,
      showModal: false,
    };
    // Bind event handlers and methods
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.reset = this._reset.bind(this);
    this.pause = this._pause.bind(this);
    this.resume = this._resume.bind(this);
    this.changeTimeout = this._changeTimeout.bind(this);
  }

  componentDidMount() {
    if (this.idleTimer) {
      this.setState({
        remaining: this.idleTimer && this.idleTimer.getRemainingTime(),
        lastActive: this.idleTimer && this.idleTimer.getLastActiveTime(),
        elapsed: this.idleTimer && this.idleTimer.getElapsedTime(),
      });

      setInterval(() => {
        this.setState({
          remaining: this.idleTimer && this.idleTimer.getRemainingTime(),
          lastActive: this.idleTimer && this.idleTimer.getLastActiveTime(),
          elapsed: this.idleTimer && this.idleTimer.getElapsedTime(),
        });
      }, 1000);
    }
  }

  timeoutAlert() {
    const { isIdle, showModal } = this.state;

    // const duration = moment.duration(moment().diff(moment(lastActive)));
    // const min = duration.minutes();
    // const sec = duration.seconds();

    // // if (min < 0) return clearInterval(timer);
    // // if (min < 10 && min.length != 2) min = '0' + min;
    // // if (sec < 0 && min != 0) {
    // //   min -= 1;
    // //   sec = 59;
    // // } else if (sec < 10 && sec.length != 2) sec = '0' + sec;

    // let timespan = null;

    // if (min > 0) timespan = `${min} minutes and ${sec} seconds`;
    // else if (min <= 0) timespan = `${sec} seconds`;
    // else if (sec <= 0) timespan = null;

    // console.log({
    //   duration,
    //   remaining,
    //   // isIdle,
    //   lastActive,
    //   timespan,
    //   elapsed,
    //   showModal,
    // });

    if (!showModal && isIdle) return this.setState({ showModal: true });

    const message = `Due to inactivity, your session has expired. Press "Continue Session" to keep working or "Sign out" to be logged off.`;

    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size="small"
      >
        <Modal.Header>Session Timed Out</Modal.Header>

        <Modal.Content>{message}</Modal.Content>
        <Modal.Actions>
          <Button
            onClick={e => {
              e.preventDefault();
              this.props.logout();
            }}
            content="Sign out"
          />

          <Button primary onClick={this.reset} content="Continue session" />
        </Modal.Actions>
      </AmsModal>
    );
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          onActive={this.onActive}
          onIdle={this.onIdle}
          timeout={this.state.timeout}
          debounce={250}
          startOnLoad
        />

        {this.timeoutAlert()}
      </>
    );
  }

  _onActive() {
    this.setState({ isIdle: false });
  }

  _onIdle() {
    this.setState({ isIdle: true });
  }

  _changeTimeout() {
    this.setState({
      timeout: this.refs.timeoutInput.state.value(),
    });
  }

  _reset() {
    this.idleTimer.reset();
    this.props.me();
    this.setState({ showModal: false });
  }

  _pause() {
    this.idleTimer.pause();
  }

  _resume() {
    this.idleTimer.resume();
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { me, logout })(Timer);
