import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Image,
  Input,
  Menu,
  Responsive,
  Select,
} from 'semantic-ui-react';

//assets
import AMSLogo from './../../../assets/images/ams-logo.png';

//components
import { MemoMenuNavBar } from '../../../utils/menu';
import GlobalAlert from '../../Shared/GlobalAlert';

const getWidth = () => {
  const isSSR = typeof window === 'undefined';

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};
class MobileContainer extends Component {
  state = {
    dropdownMenuStyle: {
      display: 'none',
    },
  };

  handleToggleDropdownMenu = () => {
    let newState = Object.assign({}, this.state);
    if (newState.dropdownMenuStyle.display === 'none') {
      newState.dropdownMenuStyle = { display: 'flex' };
    } else {
      newState.dropdownMenuStyle = { display: 'none' };
    }

    this.setState(newState);
  };

  generateSecondNav = () => {
    const { roles } = this.props.currentUser;
    return <MemoMenuNavBar location={this.props.location} roles={roles} />;
  };

  render() {
    const options = [
      { key: 'review_id', text: 'Review Id', value: 'review_id' },
      { key: 'grantee_name', text: 'Grantee Name', value: 'grantee_name' },
    ];

    return (
      <Responsive getWidth={getWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Grid padded className="mobile only">
          <Menu borderless fluid size="huge">
            <Menu.Item as="a">
              <Image
                size="small"
                src={AMSLogo}
                style={{ marginRight: '1.5em' }}
                alt="Aligned Monitoring System 2.0 Home"
              />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Button
                  icon
                  basic
                  toggle
                  onClick={this.handleToggleDropdownMenu}
                >
                  <Icon name="content" />
                </Button>
              </Menu.Item>
            </Menu.Menu>
            <Menu
              vertical
              borderless
              fluid
              style={this.state.dropdownMenuStyle}
            >
              <Menu.Item>
                <Input icon type="text" placeholder="Search..." action>
                  <input />
                  <Icon name="search" />{' '}
                  <Select compact options={options} defaultValue="review_id" />
                </Input>
              </Menu.Item>
              <Divider />
              {this.generateSecondNav()}

              {/*<Menu.Item>
                <Dropdown
                  trigger={trigger}
                  options={options}
                  pointing="top right"
                  icon={null}
                />
              </Menu.Item>*/}
              <Divider />
              <Dropdown text="Test User" className="item">
                <Dropdown.Menu>
                  <Dropdown.Item as="a" icon="calendar" text="Calendar" />
                  <Dropdown.Divider />
                  <Dropdown.Item as="a" icon="user" text="Profile" />
                  <Dropdown.Item as="a" icon="users" text="Administration" />
                  <Dropdown.Item as="a" icon="sign out" text="Sign Out" />
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </Menu>
        </Grid>

        <GlobalAlert />

        <div style={{ height: '100vh' }}>{this.props.children}</div>

        {/* <Container fluid style={{ height: '100vh' }}>
          {this.state.activeItem.hasChildren && (
            <Dropdown selection fluid text="Test User" className="item">
              <Dropdown.Menu>
                <Dropdown.Item as="a" icon="calendar" text="Calendar" />
                <Dropdown.Divider />
                <Dropdown.Item as="a" icon="user" text="Profile" />
                <Dropdown.Item as="a" icon="users" text="Administration" />
                <Dropdown.Item as="a" icon="sign out" text="Sign Out" />
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Segment basic>{this.props.children}</Segment>
        </Container> */}

        {/*<Footer />*/}
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {})(MobileContainer);
