export const generateActivePostsObject = (
  categoryDetails,
  activeTags,
  isAdmin
) => {
  const addToPostsObject = (obj, tag, post) => {
    if (!obj.hasOwnProperty(tag._id)) {
      activePosts[tag._id] = [];
    }
    activePosts[tag._id].push(post);
  };

  const activePosts = {};
  if (!categoryDetails || !categoryDetails.posts) {
    return null;
  }
  categoryDetails.posts.forEach(e => {
    const tag = activeTags.find(tag => tag._id === e.tag) || null;
    if (e.published && tag !== null) {
      addToPostsObject(activePosts, tag, e);
    } else if (e.published === false && isAdmin && tag !== null) {
      addToPostsObject(activePosts, tag, e);
    }
  });

  return activePosts;
};

export const tagIsActive = (id, activeTags) => {
  const tag = activeTags.find(tag => tag._id === id) || null;
  return !!tag;
};
