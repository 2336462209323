import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Dimmer, Loader, Header, List } from 'semantic-ui-react';

class GranteeViewInstructionTab extends Component {
  render() {
    const { extensionUrl, expirationDate } = this.props;
    return (
      <div>
        {_.isEmpty(extensionUrl) ? (
          <Dimmer active inverted>
            <Loader inverted>Loading...</Loader>
          </Dimmer>
        ) : (
          <div>
            <Header as="h3">
              INSTRUCTIONS FOR SUBMITTING YOUR AVAILABILITY CALENDAR
            </Header>
            <Header as="h4">
              To access the calendar follow the steps below:
            </Header>
            <List ordered relaxed>
              <List.Item>Select “Availability Calendar” tab</List.Item>
              <List.Item>
                Select “Next Program Year” – Please do not select the “Current
                Program Year” as this feature is not available for use
              </List.Item>
              <List.Item>
                Enter the School Year Start and End Dates for your Head Start
                and/or Early Head Start program(s) in the applicable fields. You
                must enter the dates for both HS and EHS, if applicable.
              </List.Item>
              <List.Item>
                Select each month to complete the calendar. Note: You must
                update availability for all months to successfully submit your
                calendar. This includes months which extend before or after your
                program starts or ends.
              </List.Item>
              <List.Item>Select the “Grantee Unavailable” button.</List.Item>
              <List.Item>
                Select each day that your program is not available, including
                Federal holidays
              </List.Item>
              <List.Item>
                If applicable, select the “Children not in Session” button.
              </List.Item>
              <List.Item>
                Select each day that children are not in session.
              </List.Item>
              <List.Item>
                You also have the option of selecting “Spring Break” to select
                each day of Spring Break.
              </List.Item>
              <List.Item>
                To remove a date previously provided, select the Grantee
                Unavailable, Children Not in Session or Spring break token in
                the calendar and it will be removed. You can also select cancel
                if you would like to cancel all changes made for the month.
              </List.Item>
              <List.Item>
                After completing unavailability dates for the month, select the
                “Done for Month” button.
              </List.Item>
              <List.Item>
                If you do not have enough available weeks, you will receive an
                error message indicating that at least two weeks in a month must
                be available.
                <List.List>
                  <List.Item>
                    If your program is only in session for 4 days of the week,
                    please mark the remaining day as not unavailable or children
                    not in session
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Please note: if you have not completed each month or skipped a
                month(s), you will receive an error message letting you know
                before you submit your calendar. A check mark will also appear
                on the calendar view on the main screen to let you know which
                months have been completed.
              </List.Item>
              <List.Item>
                Please use the Grantee Comments field to add information that
                may be pertinent about your program’s calendar and schedule.
              </List.Item>
              <List.Item>
                Once all the months have been completed and submitted, you will
                receive a prompt message asking if you are sure about the
                unavailability dates you are submitting. If the information is
                correct, please select continue. Choose cancel if you want to
                edit dates you have selected.
              </List.Item>
              <List.Item>
                You will also receive a prompt message advising that once you
                have submitted your completed calendar, you will not be able to
                edit your dates. However, you can view your calendar at any time
                by accessing the same link you received in your availability
                calendar email. In order to update your availability throughout
                the year, please email your changes to{' '}
                <a href="mailto:OHSmonitoring@dlhcorp.com">
                  OHSmonitoring@dlhcorp.com
                </a>
              </List.Item>
              <List.Item>
                You will receive a system-generated confirmation email with the
                dates submitted for your program.
              </List.Item>
              {this.props.granteeDetails &&
              this.props.granteeDetails.extensionRequest &&
              !this.props.granteeDetails.requestComplete ? (
                <List.Item>
                  An extension request has already been sent
                </List.Item>
              ) : !this.props.granteeDetails.extensionRequest &&
                !this.props.granteeDetails.requestComplete ? (
                <List.Item>
                  If you are uncertain of your final program schedule at this
                  time, please provide your availability to the best of your
                  knowledge and your calendar can be updated at a later time to
                  reflect the changes. If you have extenuating circumstances and
                  are unable to submit your Availability Calendar by{' '}
                  {expirationDate}, you may request a one-time, 2 weeks maximum
                  extension by selecting the link provided here:{' '}
                  <a href={extensionUrl}>Request an Extension</a>
                </List.Item>
              ) : this.props.granteeDetails.extensionRequest &&
                this.props.granteeDetails.requestComplete &&
                this.props.granteeDetails.extensionRequestApproved ? (
                <List.Item>
                  The extension request has been approved. A new link has been
                  resent.
                </List.Item>
              ) : (
                <List.Item>The extension request has been denied.</List.Item>
              )}
            </List>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    granteeDetails: state.granteeDetails,
  };
};

export default connect(mapStateToProps, {})(GranteeViewInstructionTab);
