import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Message, Checkbox } from 'semantic-ui-react';
import ReactJson from 'react-json-view';

// Import actions.
import { updateLookup, addLookup } from '../../../actions/lookupActions';

class LookupObjectForm extends Component {
  state = {
    updating: false,
    adding: false,
    errors: {},
    data: {},
    newData: {
      value: {},
      description: '',
      label: '',
      valueType: 'Object',
      active: true,
      parent: false,
    },
  };

  componentWillReceiveProps = nextProps => {
    const { selectedLookup, selectedCategoryLookups } = nextProps;

    // Ignore other types
    if (selectedLookup.valueType !== 'Object') return;

    const data = {};
    selectedCategoryLookups.forEach(lookup => {
      if (!lookup.parent) data[lookup._id] = lookup;
    });

    this.setState({ data });
  };

  resetNewForm = () =>
    this.setState({
      adding: false,
      errors: {},
      newData: {
        value: {},
        valueType: 'Object',
        active: true,
        parent: false,
      },
    });

  validate = data => {
    const errors = {};
    // Validate update forms.
    if (data._id) {
      if (_.isEmpty(data.value))
        errors[data._id] = {
          ...errors[data._id],
          value: 'Lookup value is required.',
        };
    } else {
      // Validating new data form.
      if (_.isEmpty(data.value))
        errors.newData = {
          ...errors.newData,
          value: 'Lookup value is required.',
        };
    }

    return errors;
  };

  onUpdateSubmit = (id, e) => {
    e.preventDefault();

    const errors = this.validate(this.state.data[id]);
    this.setState({ errors });

    // No more errors submit data.
    if (!errors[id] || Object.keys(errors[id]).length === 0) {
      const { _id, value, active } = this.state.data[id];
      if (!_id) return;
      this.setState({ updating: { [_id]: true } });
      this.props
        .updateLookup(_id, { value, active })
        .then(() => this.setState({ updating: { [_id]: false }, errors: {} }))
        .catch(error => {
          this.setState({
            updating: { [_id]: false },
            errors: { ...this.state.errors, parentError: error.response },
          });
        });
    }
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.newData);

    this.setState({ errors });

    // No more errors submit data.
    if (!errors.newData || Object.keys(errors.newData).length === 0) {
      const { value, valueType, active, parent } = this.state.newData;

      this.setState({ adding: true });
      const category = this.props.selectedLookup.category;

      this.props
        .addLookup({ value, valueType, active, parent, category })
        .then(() => this.resetNewForm())
        .catch(error => {
          this.setState({
            adding: false,
            errors: { ...this.state.errors, parentError: error.response },
          });
        });
    }
  };

  handleChange = (id, e, { name, value }) => {
    this.setState({
      data: {
        ...this.state.data,
        [id]: { ...this.state.data[id], [name]: value },
      },
    });
  };

  handleToggle = (id, e) => {
    this.setState({
      data: {
        ...this.state.data,
        [id]: { ...this.state.data[id], active: !this.state.data[id].active },
      },
    });
  };

  handleNewChange = (e, { name, value }) => {
    this.setState({
      newData: { ...this.state.newData, [name]: value },
    });
  };

  handleNewToggle = () => {
    this.setState({
      newData: { ...this.state.newData, active: !this.state.newData.active },
    });
  };

  renderUpdateForm = () => {
    const { data, updating, errors } = this.state;

    return (
      !_.isEmpty(data) && (
        <div>
          <h4>Values</h4>

          {Object.keys(data).map((key, index) => {
            const child = data[key];

            if (!child._id) return null;

            return (
              <Form
                onSubmit={this.onUpdateSubmit.bind(this, child._id)}
                key={child._id}
              >
                <Form.Group inline>
                  <Form.Field
                    width={10}
                    control={ReactJson}
                    iconStyle="circle"
                    enableClipboard={false}
                    name={false}
                    displayObjectSize={false}
                    src={child.value}
                    onAdd={added => {
                      const id = child._id;
                      const value = added.updated_src;
                      this.setState({
                        data: {
                          ...this.state.data,
                          [id]: { ...this.state.data[id], value },
                        },
                      });
                    }}
                    onEdit={edited => {
                      const id = child._id;
                      const value = edited.updated_src;
                      this.setState({
                        data: {
                          ...this.state.data,
                          [id]: { ...this.state.data[id], value },
                        },
                      });
                    }}
                    onDelete={deleted => {
                      const id = child._id;
                      const value = deleted.updated_src;
                      this.setState({
                        data: {
                          ...this.state.data,
                          [id]: { ...this.state.data[id], value },
                        },
                      });
                    }}
                    error={errors[child._id] ? !!errors[child._id].value : null}
                  />

                  <Form.Field
                    width={4}
                    name="active"
                    role="group"
                    toggle
                    label={{ children: 'Enabled', htmlFor: child._id }}
                    control={Checkbox}
                    checked={child.active}
                    onChange={this.handleToggle.bind(this, child._id)}
                    id={child._id}
                    aria-labelledby={child._id}
                  />

                  <Form.Button
                    color="green"
                    size="tiny"
                    disabled={updating[child._id]}
                    content={updating[child._id] ? 'Updating...' : 'Update'}
                  />
                </Form.Group>
              </Form>
            );
          })}
          <div>
            <hr />
          </div>
        </div>
      )
    );
  };

  renderAddNewForm = () => {
    const { newData, errors, adding } = this.state;

    return (
      <div>
        <h4>Add new value</h4>
        <Form onSubmit={this.onSubmit}>
          <Form.Group inline>
            <Form.Field
              width={10}
              control={ReactJson}
              iconStyle="circle"
              enableClipboard={false}
              name={false}
              displayObjectSize={false}
              src={newData.value || {}}
              onAdd={added => {
                const value = added.updated_src;
                this.setState({ newData: { ...this.state.newData, value } });
              }}
              onEdit={edited => {
                const value = edited.updated_src;
                this.setState({ newData: { ...this.state.newData, value } });
              }}
              onDelete={deleted => {
                const value = deleted.updated_src;
                this.setState({ newData: { ...this.state.newData, value } });
              }}
              error={errors.newData ? !!errors.newData.value : null}
            />

            <Form.Field
              width={4}
              name="active"
              role="group"
              toggle
              label={{ children: 'Enabled', htmlFor: 'newChild' }}
              control={Checkbox}
              checked={newData.active}
              onChange={this.handleNewToggle}
              id="newChild"
              aria-labelledby="newChild"
            />

            <Form.Button
              color="green"
              size="tiny"
              disabled={adding}
              content={adding ? 'Saving...' : 'Add Value'}
            />
          </Form.Group>
        </Form>
      </div>
    );
  };

  showError = () => {
    const { errors } = this.state;
    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(key => (
          <li key={key}>
            {Object.keys(errors[key]).map(errorKey => errors[key][errorKey])}
          </li>
        ))}
      />
    );
  };

  render() {
    const { selectedLookup } = this.props;
    return (
      selectedLookup.valueType === 'Object' && (
        <div>
          {this.showError()}

          {this.renderUpdateForm()}

          {this.renderAddNewForm()}
        </div>
      )
    );
  }
}

LookupObjectForm.propTypes = {
  updateLookup: PropTypes.func.isRequired,
  addLookup: PropTypes.func.isRequired,
  selectedLookup: PropTypes.object.isRequired,
  selectedCategoryLookups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  selectedLookup: state.lookups.selectedLookup,
  selectedCategoryLookups: state.lookups.selectedCategoryLookups,
});

export default connect(mapStateToProps, { updateLookup, addLookup })(
  LookupObjectForm
);
