/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Form, Segment } from 'semantic-ui-react';
import AmsAlert from '../../utils/AmsAlert';

import _ from 'lodash';

// Import utils
import AmsTable from '../../utils/AmsTable';
import enforceRole from '../../utils/EnforceRole';

// Import actions
import {
  approveClassSurveys,
  fetchClassSubmissionsDetail,
} from '../../actions/submissions';

// Import config
import { acl } from '../../config';

class ReviewDetailCLASSItems extends Component {
  state = {
    roleType: '',
    submitter: '',
    submitterOptions: [],
    selectedSurveys: [],
  };

  constructor(props) {
    super(props);
    this.filterSegment = React.createRef();
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    let input = {
      reviewId: this.props.params.reviewId,
    };
    this.props.fetchClassSubmissionsDetail(input).then(() => {
      const { classSubmissionsDetail } = this.props;
      const options = classSubmissionsDetail.map(submission => {
        return {
          key: submission.submitterOid,
          value: submission.submitterOid,
          text: submission.submitterName,
        };
      });
      this.setState({
        submitterOptions: options,
      });
    });
  }

  scrollToRef = () => {
    window.scrollTo(0, this.filterSegment.current.offsetTop);
    this.filterSegment.current.focus();
  };

  getSubmissionsDetail = () => {
    const { submitter, roleType } = this.state;
    let input = {
      reviewId: this.props.params.reviewId,
      filters: {},
    };
    if (roleType) {
      input.filters = { ...input.filters, roleName: roleType };
    }
    if (submitter) {
      input.filters = { ...input.filters, submitterOid: submitter };
    }
    this.props.fetchClassSubmissionsDetail(input);
  };

  submitterOptions = () => {
    const { classSubmissionsDetail } = this.props;
    const options = classSubmissionsDetail.map(submission => {
      return {
        key: submission.submitterOid,
        text: submission.submitterName,
        value: submission.submitterOid,
      };
    });
    return options;
  };

  renderFilters = () => {
    const { roleType, submitter, submitterOptions } = this.state;
    return (
      <div ref={this.filterSegment} tabIndex="-1">
        <Segment>
          <h2>Filters</h2>
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                label={{ children: 'Role Type', htmlFor: 'selectRole' }}
                id="selectRole"
                name="roleType"
                // width={4}
                placeholder="Select Role"
                value={roleType}
                onChange={this.handleChange}
                options={[
                  { key: 'DualCoder', text: 'Dual Coder', value: 'DualCoder' },
                  { key: 'Reviewer', text: 'Reviewer', value: 'Reviewer' },
                ]}
                selection
                openOnFocus={false}
              />
              <Form.Dropdown
                label={{ children: 'Submitter', htmlFor: 'selectSubmitter' }}
                id="selectSubmitter"
                name="submitter"
                // width={4}
                placeholder="Select Submitter"
                selection
                value={submitter}
                onChange={this.handleChange}
                options={submitterOptions}
                openOnFocus={false}
              />
              <Form.Group
                inline
                className="review-submissions-filter-buttons-wrapper"
              >
                <Form.Button
                  aria-label="Filter Button"
                  /*label={{
                htmlFor: 'filterButton',
                children: '\u00A0',
                'aria-label': 'Filter Button',
              }}*/
                  className="filter-button"
                  // width={5}
                  primary
                  content="Filter"
                  onClick={e => {
                    e.preventDefault();
                    this.getSubmissionsDetail();
                  }}
                />

                <Form.Button
                  aria-label="Reset Button"
                  className="filter-button"
                  /*label={{
                htmlFor: 'resetButton',
                children: '\u00A0',
                'aria-label': 'Reset Button',
              }}*/
                  // width={5}
                  id="resetButton"
                  basic
                  content="Reset"
                  onClick={e => {
                    e.preventDefault();
                    this.setState(
                      {
                        submitter: '',
                        roleType: '',
                      },
                      () => {
                        this.getSubmissionsDetail();
                        this.scrollToRef();
                      }
                    ); // Clear filters then fetch data again.
                  }}
                />
              </Form.Group>
            </Form.Group>
          </Form>
        </Segment>
      </div>
    );
  };

  renderApproveSurveyButton() {
    const { currentUser } = this.props;
    const { selectedSurveys } = this.state;

    return (
      <div className="text-right finalize-survey">
        {selectedSurveys.length !== 0 &&
          enforceRole(
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                this.setState({ showAlert: true });
              }}
            >
              {' '}
              Approve (
              {_.reduce(
                selectedSurveys,
                (sum, survey) => {
                  return sum + survey.totalClassesCompleted;
                },
                0
              )}
              ) Survey Data
            </a>,
            acl.actions.classDetailButtons.approveSurveys, // Allowed roles
            currentUser.roles // Current user roles
          )}
      </div>
    );
  }

  handleApproveSurvey = () => {
    const { selectedSurveys } = this.state;
    const input = {
      reviewId: this.props.params.reviewId,
      submitterOids: selectedSurveys.map(survey => {
        return survey.submitterOid;
      }),
    };
    this.props.approveClassSurveys(input).then(() => {
      let input = {
        reviewId: this.props.params.reviewId,
      };
      this.props.fetchClassSubmissionsDetail(input);
      this.setState({
        selectedSurveys: [],
        showAlert: false,
      });
    });
  };

  showConfirmationAlert = () => {
    const { selectedSurveys } = this.state;

    return (
      <AmsAlert
        show={this.state.showAlert}
        title="Approve Surveys"
        text={`You are about to approve ${_.reduce(
          selectedSurveys,
          (sum, survey) => {
            return sum + survey.totalClassesCompleted;
          },
          0
        )} surveys. Are you sure?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, finalize surveys'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showAlert: false });
        }}
        onConfirm={() => {
          this.handleApproveSurvey();
        }}
      />
    );
  };

  // Handle single select
  handleRowSelect = (row, isSelected) => {
    const { selectedSurveys } = this.state;
    let selections = [];

    if (isSelected) {
      selections = [...selectedSurveys, row];
    }
    if (!isSelected) {
      selections = _.pull(selectedSurveys, row);
    }

    this.setState({
      ...this.state,
      selectedSurveys: selections,
    });
  };

  // Handle multi select
  handleSelectAll = (isSelected, rows) => {
    const { selectedSurveys } = this.state;
    let selections = [];

    if (isSelected) {
      selections = rows;
    }
    if (!isSelected) {
      selections = _.pull(selectedSurveys, ...rows);
    }

    this.setState({
      ...this.state,
      selectedSurveys: selections,
    });
  };

  renderTable = () => {
    //const { selectedSurveys } = this.state;
    const {
      classSubmissionsDetail,
      selectedReview,
      selectedReview: { reviewStatus },
    } = this.props;
    const grantee = {
      granteeName: !_.isEmpty(selectedReview.grantees[0].granteeName)
        ? selectedReview.grantees[0].granteeName
        : '',
      granteeId: !_.isEmpty(selectedReview.grantees[0].granteeId)
        ? selectedReview.grantees[0].granteeId
        : '',
    };

    const roleName = (cell, row) => {
      if (row.roleName === 'DualCoder') {
        return 'Dual Coder';
      } else {
        return row.roleName;
      }
    };

    const linkReviewer = (cell, row) => {
      const roleFlag = _.some(
        [
          'DualCoder',
          'ReviewLead',
          'FieldOperationsManager',
          'AmsAdmi',
          'Analyst',
          'TechnicalWriter',
          'Editor',
          'HelpDesk',
        ],
        role => _.includes(this.props.currentUser.roles, role)
      );
      const reviewType = this.props.selectedReview.reviewType;
      let params = {
        pathname: `/task/${this.props.selectedReview.fiscalYear}/${this.props.params.reviewId}/survey/class`,
        query: { ...row, ...grantee, roleFlag, reviewStatus, reviewType },
      };

      return <Link to={params}>{cell}</Link>;
    };

    const columns = [
      {
        headerStyle: {
          width: '30%',
        },
        dataField: 'submitterName',
        sort: true,
        formatter: linkReviewer,
        text: 'Submitter',
      },
      {
        headerStyle: {
          width: '20%',
        },
        dataField: 'roleName',
        sort: true,
        formatter: roleName,
        text: 'Role',
      },
      {
        headerStyle: {
          width: '10%',
        },
        dataField: 'totalClassesAssigned',
        sort: true,
        text: 'Total Classrooms Assigned',
      },
      {
        headerStyle: {
          width: '10%',
        },
        dataField: 'totalClassesCompleted',
        sort: true,
        text: 'Total Classrooms Completed',
      },
      {
        headerStyle: {
          width: '20%',
        },
        dataField: 'surveyStatus',
        sort: true,
        text: 'Status',
      },
    ];

    return (
      <div>
        <AmsTable
          basic={classSubmissionsDetail.length ? false : true}
          keyField={'submitterOid'}
          data={classSubmissionsDetail}
          columns={columns}
          total={classSubmissionsDetail.length}
          remote={false}
          paginationTotalHeaderSize="h3"
          sizePerPageOption={classSubmissionsDetail.length ? true : false}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderFilters()}
        {this.renderApproveSurveyButton()}
        {this.showConfirmationAlert()}
        {this.renderTable()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    users: state.users.users,
    classSubmissionsDetail: state.submissions.classSubmissionsDetail,
    selectedReview: state.review.selectedReview,
  };
}

ReviewDetailCLASSItems.propTypes = {};

ReviewDetailCLASSItems.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  fetchClassSubmissionsDetail,
  approveClassSurveys,
})(ReviewDetailCLASSItems);
