import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import $ from 'jquery';
import { Alert } from 'react-bootstrap';
import { Segment } from 'semantic-ui-react';

// Import component
import SlidingContainer from '../../utils/layout/SlidingContainer';
import TaskItems from './TaskItems';
import TaskDetailModal from './TaskDetailModal';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import SummaryFilters from '../Shared/FilterHelpers/SummaryFilters';
import { fetchFollowupSurveyCitations } from '../../actions/fy19/reviewActions';

import './assets/style.css';

class TaskPage extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      modalIsOpen: false,
      selectedTask: {},
      selectedFilters: {},
      errors: {},
      responseErrors: [],
      firstCitation: '',
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handelTaskItemClick = this.handelTaskItemClick.bind(this);
    this.filterSegment = React.createRef();
  }

  componentDidMount() {
    this.getData(this.state.selectedFilters);
  }

  componentWillUpdate(nextProps, nextState) {
    $('table thead tr th').attr('tabindex', '0');
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleFilterValidation = filters => {
    const errors = {};

    if (!_.isEmpty(filters.fromDate) && !_.isEmpty(filters.toDate)) {
      if (filters.toDate.isBefore(filters.fromDate))
        errors.toDate = 'To date must be after From date';
      return errors;
    }

    if (!_.isEmpty(filters.fromDate) && _.isEmpty(filters.toDate))
      errors.toDate = 'Both From and To dates are required';
    if (_.isEmpty(filters.fromDate) && !_.isEmpty(filters.toDate))
      errors.fromDate = 'Both From and To dates are required';

    return errors;
  };

  getData(fil) {
    let filters = {};

    if (!_.isEmpty(fil.fromDate) && !_.isEmpty(fil.toDate)) {
      filters = {
        ...filters,
        fromDate: AmsDateFormatters.getMoment(fil.fromDate).format(
          'YYYY-MM-DD'
        ),
        toDate: AmsDateFormatters.getMoment(fil.toDate).format('YYYY-MM-DD'),
      };
    }

    if (!_.isEmpty(fil.reviewId))
      filters = { ...filters, reviewId: fil.reviewId.trim() }; // Add filter if available

    if (!_.isEmpty(fil.taskDefKey))
      filters = { ...filters, taskDefKey: fil.taskDefKey }; // Add filter if available

    if (!_.isEmpty(fil.selectedGrantees)) {
      filters = { ...filters, granteeIdList: fil.selectedGrantees }; // Add selected grantees.
    }

    if (!_.isEmpty(fil.taskStatus)) {
      filters = { ...filters, taskStatus: fil.taskStatus }; // Add selected status.
    }

    if (fil.findings !== '') {
      filters = { ...filters, findings: fil.findings }; // Add findings.
    }

    this.setState({ selectedFilters: filters }, () =>
      this.taskItems.getData(this.state.selectedFilters)
    );
  }

  handleFilterSubmit = filters => {
    const errors = this.handleFilterValidation(filters);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.getData(filters);
      this.setState({ responseErrors: [] });
    }
  };

  scrollToRef = () => {
    window.scrollTo(0, this.filterSegment.current.offsetTop);
    this.filterSegment.current.focus();
  };

  handleFilterReset = () => {
    this.setState(
      {
        modalIsOpen: false,
        selectedTask: {},
        selectedFilters: {},
        errors: {},
        responseErrors: [],
      },
      () => {
        this.taskItems.getData({});
        this.scrollToRef();
      }
    );
  };

  renderTaskFilters() {
    const { errors } = this.state;

    const defaultFilters = [
      {
        key: 'reviewId',
        value: true,
      },
      {
        key: 'fromDate',
        value: true,
      },
      {
        key: 'toDate',
        value: true,
      },
    ];

    return (
      <div ref={this.filterSegment} tabIndex="-1">
        <Segment>
          {/* <Well className="page-filters">
        <div className="filter-header">
          <h2>Select filters</h2>
        </div> */}
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />

          <SummaryFilters
            page="Task"
            errors={errors}
            defaultFilters={defaultFilters}
            data={{
              handleFilterSubmit: this.handleFilterSubmit,
              handleFilterReset: this.handleFilterReset,
            }}
            {...this.props}
          />
          {/* </Well> */}
        </Segment>
      </div>
    );
  }

  showReviewError(error) {
    this.setState({
      responseErrors: [...this.state.responseErrors, error.response.data],
    });
  }

  renderTaskList() {
    const { selectedFilters } = this.state;

    return (
      <TaskItems
        filters={selectedFilters}
        onFilter={ref => (this.taskItems = ref)}
        taskItemClick={this.handelTaskItemClick}
        onError={this.showReviewError.bind(this)}
      />
    );
  }

  handelTaskItemClick(selectedTask) {
    const { reviewId, taskDefKey } = selectedTask;

    if (!reviewId && !taskDefKey) return;

    switch (taskDefKey) {
      case 'performCenterClassroomEligibilityFilesDataCollection': {
        switch (selectedTask.reviewType) {
          case 'FA1-FR':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/fa1fr`
            );
            break;
          case 'FA2-CR':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/fa2cr`
            );
            break;
          case 'Special':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/special`
            );
            break;
          default:
            break;
        }
        break;
      }
      case 'performEvidenceSourceGuideDataCollection': {
        switch (selectedTask.reviewType) {
          case 'FA1-FR':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/fa1fr`
            );
            break;
          case 'FA2-CR':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/fa2cr`
            );
            break;
          case 'RAN':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/ran`
            );
            break;
          case 'Special':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/special`
            );
            break;
          default:
            break;
        }
        break;
      }
      case 'performDataCollection': {
        switch (selectedTask.reviewType) {
          case 'CLASS':
          case 'AIAN-CLASS':
          case 'PR-CLASS':
          case 'VP-CLASS':
          case 'CLASS-Video':
          case 'AIAN CLASS Self-Observations':
            // window.open(`/task/${selectedTask.reviewId}/survey/class`);
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/class`
            );
            break;
          case 'FA-1': {
            if (selectedTask.newFAFlowFlag) {
              // window.open(`/task/${selectedTask.reviewId}/survey/fa1`);
              this.context.router.push(
                `/task/${selectedTask.reviewId}/survey/fa1`
              );
            } else {
              this.setState({
                modalIsOpen: true,
                selectedTask,
              });
            }
            break;
          }
          case 'FA1-FR':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/fa1fr`
            );
            break;

          case 'FA-2': {
            if (selectedTask.newFAFlowFlag) {
              // window.open(`/task/${selectedTask.reviewId}/survey/fa2`);
              this.context.router.push(
                `/task/${selectedTask.reviewId}/survey/fa2`
              );
            } else {
              this.setState({
                modalIsOpen: true,
                selectedTask,
              });
            }
            break;
          }
          case 'FA2-CR':
            this.context.router.push(
              `/review/fy${selectedTask.fiscalYear.toString().slice(-2)}/${
                selectedTask.reviewId
              }/survey/fa2cr`
            );
            break;
          case 'Follow-up':
            if (selectedTask.isNewForms) {
              this.props
                .fetchFollowupSurveyCitations({
                  reviewId: selectedTask.reviewId,
                })
                .then(data => {
                  console.log(this.state.firstCitation);
                  this.context.router.push(
                    `/review/fy${selectedTask.fiscalYear
                      .toString()
                      .slice(-2)}/${
                      selectedTask.reviewId
                    }/survey/followup?citation=${
                      data.followupCitations[0].name
                    }`
                  );
                });
            } else {
              this.context.router.push(
                `/task/${selectedTask.reviewId}/survey/follow-up`
              );
            }
            break;
          case 'RAN':
            // window.open(`/task/${selectedTask.reviewId}/survey/ran`);
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/ran`
            );
            break;
          case 'Special':
            // window.open(`/task/${selectedTask.reviewId}/survey/special`);
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/special`
            );
            break;
          case 'AIAN-DEF':
            // window.open(`/task/${selectedTask.reviewId}/survey/aian-def`);
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/aian-def`
            );
            break;
          case 'FA2-CR':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/fa2cr`
            );
            break;
          case 'FA1-FR':
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/fa1fr`
            );
            break;
          case 'ERSEA':
            // window.open(`/task/${selectedTask.reviewId}/survey/ersea`);
            this.context.router.push(
              `/task/${selectedTask.reviewId}/survey/ersea`
            );
            break;

          default:
            break;
        }
        break;
      }
      case 'approveDataCollection': {
        this.context.router.push(`/review/${selectedTask.reviewId}`);
        break;
      }
      case 'performReportCreation': {
        this.context.router.push(`/reviews/${selectedTask.reviewId}`);
        break;
      }
      case 'reviewReportCollection': {
        if (selectedTask.newFAFlowFlag) {
          let fiscalYear = selectedTask.fiscalYear
            ? `fy` + selectedTask.fiscalYear.toString().slice(-2)
            : 'fy19';
          this.context.router.push(
            `/review/${fiscalYear}/${selectedTask.reviewId}`
          );
        } else {
          this.context.router.push(`/review/${selectedTask.reviewId}`);
        }
        break;
      }
      default:
        break;
    }
  }

  showDetailModal() {
    const { selectedTask, modalIsOpen } = this.state;

    if (selectedTask && selectedTask._id)
      return (
        <TaskDetailModal
          task={selectedTask}
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
        />
      );
  }

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:(
                {errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  render() {
    return (
      <div className="task-page">
        {this.showResponseErrors()}

        {this.showDetailModal()}

        <SlidingContainer calendar title={'Tasks'}>
          {this.renderTaskFilters()}
          {this.renderTaskList()}
        </SlidingContainer>
        {/* Render Child components */}
        {this.props.children}
      </div>
    );
  }
}

TaskPage.propTypes = {
  tasks: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
};

TaskPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    tasks: state.tasks.taskList,
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps, { fetchFollowupSurveyCitations })(
  TaskPage
);
