import React from 'react';

function ListField({ schema }) {
  const renderList = () => {
    if (schema && schema.enum && schema.enum.length > 0) {
      return schema.enum.map(e => {
        return <li>{e}</li>;
      });
    }
  };
  return <ul>{renderList()}</ul>;
}

export default ListField;
