import React, { useEffect, useRef } from 'react';
import 'trix';
import 'trix/dist/trix.css';

const Editor = ({
  value,
  onChange,
  onFileUpload,
  onFileDelete,
  focusOnInit = false,
  id = 'trix-editor-instance',
  disabled = false,
}) => {
  const trixEditor = useRef(null);

  useEffect(() => {
    trixEditor.current.addEventListener('trix-change', e => {
      onChange(trixEditor.current.value);
    });
    
    trixEditor.current.addEventListener('trix-attachment-add', e => {
      onFileUpload(e.attachment);
    });
    trixEditor.current.addEventListener('trix-attachment-remove', e => {
      onFileDelete(e.attachment);
    });
    if (focusOnInit)
      setTimeout(() => {
        document.querySelector('trix-editor').focus();
      }, 500);
    
    if (disabled) {
      setTimeout(() => {
        document.querySelector("trix-editor").contentEditable = false
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trixEditor]);

  useEffect(() => {
    if (!trixEditor.current) return;
    if (trixEditor.current.inputElement.value === value) return;

    trixEditor.current.value = value;
  }, [value]);

  return React.createElement('trix-editor', {
    title: id,
    ref: trixEditor,
    id: id,
  });
};

export default Editor;
