import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';

// Import actions.
import { downloadManifestFile } from '../../actions/taskActions';

// Import components
import TaskTabs from './TaskTabs.jsx';

import pdfIcon from '../../assets/images/pdf-icon.png';

function TaskDetailModal({
  task,
  modalIsOpen,
  closeModal,
  currentUser,
  downloadManifestFile,
}) {
  // const { task, modalIsOpen, closeModal, currentUser } = props;

  if (!modalIsOpen) return null;

  return (
    <Modal
      className="modal-container"
      overlayClassName="modal-overlay-container"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      contentLabel="Task Detail"
    >
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-11">
            <h2 className="no-top-margin">
              <span className="modal-title">Review ID:</span> {task.reviewId}
            </h2>
          </div>

          <div className="col-sm-1">
            <span onClick={closeModal} className="clickable">
              <i className="fa fa-close fa-2x" />
            </span>
          </div>

          <div className="col-sm-12">
            <hr />
          </div>

          <div className="col-sm-12 formal-survey survey-table">
            <div className="review-manifest">
              <span className="category-title">Manifest file: </span>
              {task.manifestLink && (
                <img
                  alt="PDF icon"
                  className="manifest-pdf-icon"
                  src={pdfIcon}
                />
              )}
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href=""
                  onClick={event => {
                    event.preventDefault();
                    downloadManifestFile(
                      task.reviewId,
                      task.manifestLink
                    ).catch(error => console.error(error.message));
                  }}
                >
                  Manifest File
                </a>
              }
            </div>
            <div className="task-modal-tabs">
              {task && task.reviewId ? (
                <TaskTabs reviewId={task.reviewId} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

TaskDetailModal.propTypes = {
  review: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  downloadManifestFile: PropTypes.func.isRequired,
};

export default connect(null, { downloadManifestFile })(TaskDetailModal);
