import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

class GranteeCalendarUpdate extends Component {
  handleDismiss = () => {
    const { closeUpdateMessage } = this.props;
    closeUpdateMessage();
  };

  renderUpdateDetails() {
    let { showUpdateMessage } = this.props;
    return showUpdateMessage ? (
      <div>
        <Message
          onDismiss={this.handleDismiss}
          header="The changes you have made have been updated."
          content="Please select another Grantee to update from the drop down above."
        />
      </div>
    ) : (
      ''
    );
  }

  render() {
    return <div> {this.renderUpdateDetails()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarUpdate);
