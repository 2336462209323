import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import _ from 'lodash';

import { filterUsers, usersFilterCleared } from '../actions/userActions';

class UsersListDropdown extends Component {
  state = {
    users: {
      all: [],
    },
    selected: {},
    list: [],
    selectedValue: !this.props.value
      ? this.props.multiple
        ? []
        : ''
      : this.props.value,
    userType: this.props.userType || '',
    loading: false,
  };

  componentWillUnmount() {
    this.props.usersFilterCleared();
  }

  componentWillReceiveProps(nextProps) {
    const { filteredData, userType } = nextProps;

    if (userType && filteredData[userType]) {
      const list = filteredData[userType].map(item => {
        let text = '';
        if (item.name) text = item.name;
        if (item.fullName) text = item.fullName;

        return { key: item.oid, value: item.oid, text };
      });
      this.setState({ users: { ...this.state.users, [userType]: list } });
    } else {
      const list = filteredData.all.map(item => {
        let text = '';
        if (item.name) text = item.name;
        if (item.fullName) text = item.fullName;

        return { key: item.oid, value: item.oid, text };
      });
      this.setState({ users: { ...this.state.users, all: list } });
    }
  }

  componentDidMount() {
    const { value, filteredData, userType } = this.props;

    // Used to preload selections.
    if (!_.isEmpty(value) && _.isEmpty(filteredData)) {
      this.setState({ loading: true });
      value.forEach(id => {
        this.props
          .filterUsers(id, userType || '')
          .then(() => this.setState({ loading: false }))
          .catch(error => {
            this.setState({ loading: false });
            this.props.onError(error);
          });
      });
    }

    // Used to preload selections.
    if (!_.isEmpty(value) && !_.isEmpty(filteredData)) {
      this.setState({ loading: true });
      [...new Set(value)].forEach(id => {
        this.props
          .filterUsers(id, userType || '')
          .then(() => this.setState({ loading: false }))
          .catch(error => {
            this.setState({ loading: false });
            this.props.onError(error);
          });
      });
    }
  }

  onChange = (e, data) => {
    this.setState({ filterQuery: data.value });
    this.props.onValueSelected(e, data);
  };

  onSearchChange = (e, data) => {
    clearTimeout(this.timer);
    this.setState({
      filterQuery: data,
    });
    this.timer = setTimeout(this.filterList, 500);
  };

  filterList = () => {
    if (
      !this.state.filterQuery.trim() ||
      this.state.filterQuery.trim().length < 3
    )
      return;

    this.setState({ loading: true });

    this.props
      .filterUsers(this.state.filterQuery.trim(), this.props.userType || '')
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({ loading: false });
        this.props.onError(error);
      });
  };

  render() {
    const { users } = this.state;
    const {
      name,
      multiple,
      search,
      selection,
      placeholder,
      dropdownLabel,
      required,
      userType,
    } = this.props;

    return (
      <Form.Dropdown
        name={name || ''}
        required={!!required}
        fluid
        multiple={multiple}
        search={search}
        selection={selection}
        label={dropdownLabel || 'Users'}
        placeholder={placeholder || 'Select Users'}
        value={
          !this.props.value ? (this.props.multiple ? [] : '') : this.props.value
        }
        onSearchChange={this.onSearchChange}
        options={userType ? users[userType] || [] : users.all}
        loading={this.state.loading}
        onChange={this.onChange}
      />
    );
  }
}

UsersListDropdown.propTypes = {
  filteredData: PropTypes.object.isRequired,
  filterUsers: PropTypes.func.isRequired,
  usersFilterCleared: PropTypes.func,
  onValueSelected: PropTypes.func,
  onError: PropTypes.func,
};

const mapStateToProps = state => ({
  filteredData: state.usersFilter,
});

export default connect(
  mapStateToProps,
  { filterUsers, usersFilterCleared }
)(UsersListDropdown);
