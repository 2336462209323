import React, { useEffect, useState } from 'react';
import {
  Form,
  Dropdown,
  Header,
  Modal,
  Message,
  Button,
  Checkbox,
  Input,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { Link } from 'react-router';
import AmsModal from '../../../utils/AmsModal';
import { filter, find, forEach, isEmpty, omit } from 'lodash';
import AmsTable from '../../../utils/AmsTable';
import AmsAlert from '../../../utils/AmsAlert';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import enforceRole from '../../../utils/EnforceRole';
import { acl } from '../../../config';
import {
  fa2CenterSelected,
  fa2ClassroomSelected,
  fa2GuideSelected,
  fa2PerformanceMeasureSelected,
  fa2UpdateCentersClassrooms,
  fetchFA2FormDetail,
} from '../../../actions/fa2Actions';
import ShowErrors from '../../../utils/ShowErrors';

export default function FA2CenterClasssroom(props) {
  const {
    selectedPerformanceMeasure,
    selectedGuide,
    isReportFinalized,
    isReviewAccessible,
    generalInfo,
    reviewStatus,
    reviewId,
    reviewType,
    guides,
  } = useSelector(state => state.forms.fa2);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [warningAlertData, setWarningAlertData] = useState(false);
  const [showAddConfirmationAlert, setShowAddConfirmationAlert] = useState(
    false
  );
  const [showAddCenterModal, setShowAddCenterModal] = useState(false);
  const [showAddClassroomModal, setShowAddClassroomModal] = useState(false);
  const [newCenterName, setNewCenterName] = useState('');
  const [newClassroomName, setNewClassroomName] = useState('');

  const [centerAndClassroom, setCenterAndClassroom] = useState({
    centers: [],
    centerOptions: [],
    classrooms: [],
    classroomOptions: [],
    selectedCenter: '',
    selectedClassroom: '',
  });
  const currentUser = useSelector(state => state.auth.user);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  // useEffect to extract the centers and classrooms from the selected guide
  useEffect(() => {
    if (selectedPerformanceMeasure && selectedGuide) {
      extractCentersAndClassrooms();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuide, selectedPerformanceMeasure]);

  /**
   * Extract the centers and classrooms from the selected performance measure
   * and build the options for the dropdowns
   *
   * @returns {void}
   */
  const extractCentersAndClassrooms = () => {
    const { centers } = selectedPerformanceMeasure;
    if (isEmpty(centers)) {
      return;
    }
    let centerOptions = centers
      ? filter(centers, center => {
          return center.enabled;
        }).map(center => {
          return {
            key: center.centerName,
            text: center.centerName,
            value: center.centerName,
          };
        })
      : [];
    let selectedCenter =
      centers && centers.length
        ? props.center
          ? filter(centers, center => {
              return (
                centerOptions &&
                centerOptions.length &&
                center.centerName === props.center
              );
            })
          : filter(centers, center => {
              return (
                centerOptions &&
                centerOptions.length &&
                centerOptions[0] &&
                center.centerName === centerOptions[0].key
              );
            })
        : [];
    let classroomOptions =
      selectedCenter &&
      selectedCenter[0] &&
      selectedCenter[0].classrooms &&
      selectedCenter[0].classrooms.length
        ? filter(selectedCenter[0].classrooms, classroom => {
            return classroom.enabled;
          }).map(classroom => {
            return {
              key: classroom.classSampleId,
              text: classroom.classroomName,
              value: classroom.classSampleId,
            };
          })
        : [];
    let selectedClassroom = '';

    if (classroomOptions) {
      selectedClassroom = classroomOptions.find(c => c.key == props.classRoom)
        ?.key;
      if (!selectedClassroom) {
        selectedClassroom =
          classroomOptions[0] && classroomOptions[0].key
            ? classroomOptions[0].key
            : '';
      }
    }

    let selectedCenterOption = '';
    if (centerOptions) {
      selectedCenterOption = centerOptions.find(x => x.key == props.center)
        ?.key;
      if (!selectedCenterOption) {
        selectedCenterOption =
          centerOptions[0] && centerOptions[0].key ? centerOptions[0].key : '';
      }
    }

    setCenterAndClassroom({
      centers,
      centerOptions,
      selectedCenter: selectedCenterOption,
      classrooms:
        selectedCenter &&
        selectedCenter[0] &&
        selectedCenter[0].classrooms &&
        selectedCenter[0].classrooms.length
          ? selectedCenter[0].classrooms
          : [],
      classroomOptions,
      selectedClassroom,
    });

    const classroom = find(
      selectedCenter && selectedCenter[0] && selectedCenter[0].classrooms,
      {
        classSampleId: selectedClassroom,
      }
    );
    if (selectedCenter && selectedCenter.length > 0) {
      dispatch(fa2CenterSelected(selectedCenter[0].centerName));
      if (selectedCenter[0].forms && selectedCenter[0].forms[0].amsSubmissionId)
        props.fetchSurveyData(selectedCenter[0].forms[0].amsSubmissionId);
    }
    if (!isEmpty(classroom)) {
      dispatch(fa2ClassroomSelected(selectedClassroom));
      if (classroom.forms && classroom.forms[0].amsSubmissionId)
        props.fetchSurveyData(classroom.forms[0].amsSubmissionId);
    }
  };

  const showEditButton = () => {
    const reviewStatuses = ['Cancelled', 'In Analysis'];
    if (isReportFinalized) return;

    if (!isReviewAccessible) return;

    if (
      generalInfo &&
      generalInfo.endDate &&
      AmsDateFormatters.getMoment(generalInfo.endDate).diff(
        AmsDateFormatters.getMoment()
      ) < 0
    )
      return null;

    if (reviewStatus && reviewStatuses.includes(reviewStatus)) return null;

    if (selectedGuide && selectedGuide.guideCode === 'CTR') {
      return (
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
            setShowEditModal(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          Add/Edit Centers
        </Link>
      );
    }
    if (selectedGuide && selectedGuide.guideCode === 'CLS') {
      return (
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
            setShowEditModal(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          Add/Edit Classrooms{' '}
        </Link>
      );
    }
  };

  /**
   * Set the center changes
   * @param {Object} e
   * @param {Object} data - data that comes from the semantic ui dropdown
   * @returns {void}
   */
  const handleCenterChange = (e, { name, value }) => {
    if (
      !props.handleCenterAndClassChange(
        selectedGuide,
        selectedPerformanceMeasure,
        value,
        ''
      )
    ) {
      return;
    }

    const center = find(centerAndClassroom.centers, { centerName: value });
    setErrors({});
    // TO DO: remove updating redux

    let classroomToUse = '';

    if (center && center.centerName)
      dispatch(fa2CenterSelected(center.centerName));
    if (center && center.classrooms && center.classrooms.length > 0) {
      center.classrooms.some(e => {
        if (e.enabled === true) {
          classroomToUse = e;
        }
        return e.enabled === true;
      });
      dispatch(fa2ClassroomSelected(classroomToUse.classSampleId));
    }

    let classrooms =
      center &&
      center.classrooms &&
      center.classrooms.length &&
      filter(center.classrooms, classroom => {
        return classroom.enabled;
      });

    if (center && center.classrooms) {
      setCenterAndClassroom({
        ...centerAndClassroom,
        [name]: value,
        classrooms: center.classrooms,
        selectedClassroom: !isEmpty(classrooms)
          ? classroomToUse.classSampleId
          : '',
        classroomOptions:
          center && center.classrooms && center.classrooms.length
            ? filter(center.classrooms, classroom => {
                return classroom.enabled;
              }).map(classroom => {
                return {
                  key: classroom.classSampleId,
                  text: classroom.classroomName,
                  value: classroom.classSampleId,
                };
              })
            : [],
      });
      props.fetchSurveyData(classroomToUse.forms[0].amsSubmissionId);
    } else {
      setCenterAndClassroom({ ...centerAndClassroom, [name]: value });
      props.fetchSurveyData(center && center.forms[0].amsSubmissionId);
    }
  };

  /**
   * Set the classroom changes
   * @param {Object} e
   * @param {Object} data - data that comes from the semantic ui dropdown
   * @returns {void}
   */
  const handleClassroomChange = (e, { name, value }) => {
    if (
      !props.handleCenterAndClassChange(
        selectedGuide,
        selectedPerformanceMeasure,
        centerAndClassroom.selectedCenter,
        value
      )
    ) {
      return;
    }

    const classroom = find(centerAndClassroom.classrooms, {
      classSampleId: value,
    });
    setErrors({});
    // TO DO: remove updating redux
    if (classroom && classroom.classSampleId)
      dispatch(fa2ClassroomSelected(value));

    setCenterAndClassroom({ ...centerAndClassroom, [name]: value });
    props.fetchSurveyData(classroom && classroom.forms[0].amsSubmissionId);
  };
  // Render the center and classroom edit modal
  const renderCenterClassroomEditModal = () => {
    const modalTitle =
      selectedGuide && selectedGuide.guideCode === 'CTR'
        ? 'Manage Centers'
        : selectedGuide.guideCode === 'CLS'
        ? 'Manage Classrooms'
        : '';

    return (
      <AmsModal
        size="large"
        className="ams-semantic-modal-fix"
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        closeOnDimmerClick={false}
      >
        <Header>{modalTitle}</Header>
        <Modal.Content>
          <Modal.Description>
            {selectedPerformanceMeasure &&
              isEmpty(selectedPerformanceMeasure.centers) && (
                <Message
                  negative
                  icon="cancel"
                  header="Something went wrong!"
                  content="Could not find any Centers"
                />
              )}

            <div className="row">{centersClassroomsEditTable()}</div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            bsSize="small"
            className="pull-left"
            color="green"
            onClick={() => {
              if (selectedGuide && selectedGuide.guideCode === 'CTR')
                setShowAddCenterModal(true);
              else setShowAddClassroomModal(true);
            }}
          >
            {addButtonText}
          </Button>
          <Button
            primary
            onClick={() => {
              setShowConfirmationAlert(true);
            }}
          >
            Update
          </Button>
          <Button
            onClick={() => {
              setShowEditModal(false);
              fetchFormDetail();
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  const addButtonText =
    selectedGuide && selectedGuide.guideCode === 'CTR'
      ? 'Add Centers'
      : selectedGuide.guideCode === 'CLS'
      ? 'Add Classrooms'
      : '';

  // Build and render the center and classroom table for the edit modal
  const centersClassroomsEditTable = () => {
    const statusFormatter = (cell, row) => {
      const type = selectedGuide.guideCode === 'CTR' ? 'center' : 'classroom';
      const classroomsData = guides.find(element =>
        [
          'Classroom Exploration',
          'Classroom Exploration - Effective and Intentional Teaching Practices',
        ].includes(element.name)
      ).performanceMeasures[0];

      if (row.hasSubmission) return `There is a submission for this ${type}.`;
      else
        return (
          <Checkbox
            label={{
              children: <span className="visually-hidden">Toggle Status</span>,
            }}
            name="enabled"
            toggle
            aria-label="Status"
            value={true}
            onChange={(e, data) => {
              if (selectedGuide.guideCode !== 'CLS') {
                let hasClassroomData = false;
                classroomsData.centers
                  .find(element => row.centerName === element.centerName)
                  .classrooms.forEach(e => {
                    if (e.hasSubmission === true) hasClassroomData = true;
                  });

                if (hasClassroomData && data.checked === false) {
                  setShowWarningAlert(true);
                  setWarningAlertData({ row, data });
                  return;
                }
              }

              if (selectedGuide.guideCode === 'CTR')
                setCenterAndClassroom({
                  ...centerAndClassroom,
                  centers: filter(centerAndClassroom.centers, center => {
                    if (center.centerName === row.centerName) {
                      center.enabled = data.checked;
                    }
                    return center;
                  }),
                });
              else if (selectedGuide.guideCode === 'CLS')
                setCenterAndClassroom({
                  ...centerAndClassroom,
                  classrooms: filter(
                    centerAndClassroom.classrooms,
                    classroom => {
                      if (classroom.classSampleId === row.classSampleId) {
                        classroom.enabled = data.checked;
                      }
                      return classroom;
                    }
                  ),
                });
            }}
            checked={row.enabled}
          />
        );
    };

    const columns =
      selectedGuide.guideCode === 'CLS'
        ? [
            {
              dataField: 'classSampleId',
              text: 'id',
              sort: false,
              hidden: true,
            },
            {
              dataField: 'classroomName',
              text: 'Classroom',
              sort: true,
            },
            {
              formatter: statusFormatter,
              dataField: 'enabled',
              text: 'Action',
              sort: false,
            },
          ]
        : [
            {
              dataField: 'centerName',
              text: 'id',
              sort: false,
              hidden: true,
            },
            {
              dataField: 'centerName',
              text: 'Center',
              sort: true,
            },
            {
              formatter: statusFormatter,
              dataField: 'enabled',
              text: 'Action',
              sort: false,
            },
          ];

    return (
      <div>
        <AmsTable
          data={
            selectedGuide.guideCode === 'CTR'
              ? centerAndClassroom.centers
              : selectedGuide.guideCode === 'CLS'
              ? centerAndClassroom.classrooms
              : []
          }
          total={
            selectedGuide.guideCode === 'CTR'
              ? centerAndClassroom.centers && centerAndClassroom.centers.length
              : selectedGuide.guideCode === 'CLS'
              ? centerAndClassroom.classrooms &&
                centerAndClassroom.classrooms.length
              : 0
          }
          remote={false}
          keyField="_id"
          columns={columns}
          sizePerPageOption={false}
          basic
          pagination={
            selectedPerformanceMeasure &&
            selectedPerformanceMeasure.centers &&
            selectedGuide &&
            selectedGuide.guideCode === 'CTR'
              ? selectedPerformanceMeasure.centers.length > 5
              : centerAndClassroom.classrooms &&
                selectedGuide &&
                selectedGuide.guideCode === 'CLS'
              ? centerAndClassroom.classrooms.length > 5
              : false
          }
        />
      </div>
    );
  };

  const renderCenterConfirmation = () => {
    return (
      <AmsAlert
        show={showConfirmationAlert}
        title="Are you sure you want to make these changes"
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => setShowConfirmationAlert(false)}
        onConfirm={() => {
          setShowConfirmationAlert(false);
          setShowEditModal(false);
          setShowAddCenterModal(false);
          setShowAddClassroomModal(false);
          updateCentersClassrooms();
          props.setLoading(true);
        }}
      />
    );
  };

  const renderAlertWarning = () => {
    const { row, data } = warningAlertData;
    return (
      <AmsAlert
        show={showWarningAlert}
        title=" Are you sure you want to make these changes?"
        text="The Center you are trying to disable has a classroom with submissions."
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => setShowWarningAlert(false)}
        onConfirm={() => {
          setShowWarningAlert(false);
          if (selectedGuide.guideCode === 'CTR')
            setCenterAndClassroom({
              ...centerAndClassroom,
              centers: filter(centerAndClassroom.centers, center => {
                if (center.centerName === row.centerName) {
                  center.enabled = data.checked;
                }
                return center;
              }),
            });
          else if (selectedGuide.guideCode === 'CLS')
            setCenterAndClassroom({
              ...centerAndClassroom,
              classrooms: filter(centerAndClassroom.classrooms, classroom => {
                if (classroom.classSampleId === row.classSampleId) {
                  classroom.enabled = data.checked;
                }
                return classroom;
              }),
            });
        }}
      />
    );
  };
  const renderAddConfirmation = () => {
    return (
      <AmsAlert
        show={showAddConfirmationAlert}
        title={
          selectedGuide && selectedGuide.guideCode === 'CTR'
            ? 'Are you sure you want to add center and classroom'
            : 'Are you sure you want to add classroom'
        }
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => setShowAddConfirmationAlert(false)}
        onConfirm={() => {
          setShowAddConfirmationAlert(false);
          setShowEditModal(true);
          setShowAddCenterModal(false);
          setShowAddClassroomModal(false);
          setErrors({});
          if (!isEmpty(newCenterName)) newCenter();
          else newClassroom();
          closeModal();
        }}
      />
    );
  };

  const handleCenterName = (e, { name, value }) => {
    let trimCenterNameValue = trimSpaces(value);
    setNewCenterName(trimCenterNameValue);
  };

  const handleClassRoomName = (e, { name, value }) => {
    let trimClassroomNameValue = trimSpaces(value);
    setNewClassroomName(trimClassroomNameValue);
  };

  const whiteSpaceValidation = s => {
    return /^\s*$/.test(s);
  };

  const trimSpaces = e => {
    return e.replace(/^\s+|\s+$/gm, '');
  };

  const handleValidation = () => {
    const errors = {};
    if (!showAddClassroomModal) {
      if (isEmpty(newCenterName)) {
        errors.newCenterName = 'Center Name is required';
      }
      if (newCenterName.length > 0 && whiteSpaceValidation(newCenterName)) {
        errors.newCenterName = 'Center Name should not be empty';
      }
    }
    if (
      centerAndClassroom.centers.some(
        e => e.centerName.toLowerCase() === newCenterName.toLowerCase()
      ) &&
      !showAddClassroomModal
    ) {
      errors.newCenterName = 'Center Name is already exists';
    }
    if (isEmpty(newClassroomName)) {
      errors.newClassroomName = 'Classroom Name is required';
    }
    if (newClassroomName.length > 0 && whiteSpaceValidation(newClassroomName)) {
      errors.newClassroomName = 'Classroom Name should not be empty';
    }
    if (
      centerAndClassroom.classrooms.some(
        e => e.classroomName.toLowerCase() === newClassroomName.toLowerCase()
      )
    ) {
      errors.newClassroomName = 'Classroom Name is already exists';
    }
    return errors;
  };

  const renderAddCenterModal = () => {
    return (
      <AmsModal
        size="small"
        className="ams-semantic-modal-fix"
        open={showAddCenterModal}
        onClose={() => setShowAddCenterModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field
                required
                error={errors.newCenterName}
                control={Input}
                name="center"
                label="Add center"
                placeholdertext="Add Center"
                onChange={handleCenterName}
              ></Form.Field>
              <Form.Field
                required
                error={errors.newClassroomName}
                control={Input}
                name="classroom"
                label="Add classroom"
                placeholdertext="Add classroom"
                onChange={handleClassRoomName}
              ></Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              const errors = handleValidation();
              setErrors(errors);
              if (isEmpty(errors)) setShowAddConfirmationAlert(true);
            }}
          >
            Add
          </Button>
          <Button
            onClick={() => {
              setShowAddCenterModal(false);
              setErrors({});
              closeModal();
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  const renderAddClassroomModal = () => {
    return (
      <AmsModal
        size="small"
        className="ams-semantic-modal-fix"
        open={showAddClassroomModal}
        onClose={() => setShowAddClassroomModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field
                required
                error={errors.newClassroomName}
                control={Input}
                name="classroom"
                label="Add classroom"
                placeholdertext="Add classroom"
                onChange={handleClassRoomName}
              ></Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              const errors = handleValidation();
              setErrors(errors);
              if (isEmpty(errors)) setShowAddConfirmationAlert(true);
            }}
          >
            Add
          </Button>
          <Button
            onClick={() => {
              setShowAddClassroomModal(false);
              setErrors({});
              closeModal();
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  const closeModal = () => {
    setNewCenterName('');
    setNewClassroomName('');
  };

  const newCenter = () => {
    let newCenters = centerAndClassroom.centers;
    newCenters.push({
      centerName: newCenterName,
      enabled: true,
      hasSubmission: false,
      isNewCenter: true,
      newClassrooms: [newClassroomName],
    });
    setCenterAndClassroom({ ...centerAndClassroom, centers: newCenters });
  };

  const newClassroom = () => {
    let newClassrooms = centerAndClassroom.classrooms;
    newClassrooms.push({
      classSampleId: 0,
      classroomName: newClassroomName,
      enabled: true,
      hasSubmission: false,
      isNewClassroom: true,
    });
    setCenterAndClassroom({ ...centerAndClassroom, classrooms: newClassrooms });
  };

  // Render the center and classroom dropdowns
  const renderCentersAndClassrooms = () => {
    const {
      centerOptions,
      selectedCenter,
      classroomOptions,
      selectedClassroom,
      centers,
    } = centerAndClassroom;

    return (
      <>
        {!isEmpty(centers) &&
          (selectedPerformanceMeasure.name ===
            'Safety Practices (Center Exploration)' ||
            selectedPerformanceMeasure.name === 'Center Exploration Guide' ||
            selectedPerformanceMeasure.name === 'Classroom Exploration Guide' ||
            selectedPerformanceMeasure.name ===
              'Classroom Exploration (Effective and Intentional Teaching Practices)' ||
            selectedPerformanceMeasure.name ===
              'Teaching Practices in Classroom') && (
            <div>
              <Form.Field>
                <AmsFormLabel name="Select Center" fieldLabel="selectCenter">
                  <Dropdown
                    name="selectedCenter"
                    searchInput={{ id: 'selectCenter' }}
                    aria-labelledby="selectCenter"
                    options={centerOptions}
                    value={selectedCenter}
                    onChange={handleCenterChange}
                    selectOnBlur={false}
                    selection
                    search
                    fluid
                  />
                </AmsFormLabel>
              </Form.Field>
              {selectedPerformanceMeasure &&
                (selectedPerformanceMeasure.name ===
                  'Center Exploration Guide' ||
                  selectedPerformanceMeasure.name ===
                    'Safety Practices (Center Exploration)') &&
                enforceRole(
                  showEditButton(),
                  acl.actions.survey.manageCenterAndClassrooms,
                  currentUser.roles
                )}
            </div>
          )}

        {!isEmpty(centers) &&
          selectedPerformanceMeasure &&
          (selectedPerformanceMeasure.name === 'Classroom Exploration Guide' ||
            selectedPerformanceMeasure.name ===
              'Classroom Exploration (Effective and Intentional Teaching Practices)' ||
            selectedPerformanceMeasure.name ===
              'Teaching Practices in Classroom') && (
            <div>
              <Form.Field>
                <AmsFormLabel
                  name="Select Classroom"
                  fieldLabel="selectClassroom"
                >
                  <Dropdown
                    name="selectedClassroom"
                    searchInput={{ id: 'selectClassroom' }}
                    aria-labelledby="selectClassroom"
                    options={classroomOptions}
                    value={selectedClassroom}
                    onChange={handleClassroomChange}
                    selectOnBlur={false}
                    selection
                    search
                    fluid
                  />
                </AmsFormLabel>
              </Form.Field>
              {selectedPerformanceMeasure &&
                (selectedPerformanceMeasure.name ===
                  'Classroom Exploration Guide' ||
                  selectedPerformanceMeasure.name ===
                    'Classroom Exploration (Effective and Intentional Teaching Practices)' ||
                  selectedPerformanceMeasure.name ===
                    'Teaching Practices in Classroom') &&
                enforceRole(
                  showEditButton(),
                  acl.actions.survey.manageCenterAndClassrooms,
                  currentUser.roles
                )}
            </div>
          )}
      </>
    );
  };

  // Update the values of the center and classroom when enabled/disabled
  const updateCentersClassrooms = () => {
    let requestInput = {};

    if (selectedGuide.guideCode === 'CTR')
      requestInput = {
        guideCode: selectedGuide.guideCode,
        formCode: selectedPerformanceMeasure.formCode,
        reviewId,
        reviewStatus,
        reviewType,
        centers: centerAndClassroom.centers.map(center =>
          omit(center, 'forms')
        ),
      };
    else if (selectedGuide.guideCode === 'CLS') {
      requestInput = {
        guideCode: selectedGuide.guideCode,
        formCode: selectedPerformanceMeasure.formCode,
        reviewId,
        reviewStatus,
        reviewType,
        centers: [
          {
            centerName: centerAndClassroom.selectedCenter,
            classrooms: centerAndClassroom.classrooms.map(classrooms =>
              omit(classrooms, 'forms')
            ),
          },
        ],
      };
    }

    dispatch(
      fa2UpdateCentersClassrooms(
        requestInput,
        centerAndClassroom.centers,
        selectedPerformanceMeasure.name
      )
    )
      .then(response => {
        if (response) fetchFormDetail();
      })
      .catch(error => {
        setErrors({
          ...errors,
          fa2UpdateCenterClassrooms: error.response.data.message,
        });
        props.setLoading(false);
      });
  };

  const fetchFormDetail = () => {
    {
      dispatch(fetchFA2FormDetail({ reviewId })).then(() => {
        // Extract query strings if passed and use them to preselect Guide and PM.
        const guideName = !isEmpty(props.location.query.g)
          ? decodeURIComponent(props.location.query.g)
          : '';
        const performanceMeasure = !isEmpty(props.location.query.pm)
          ? decodeURIComponent(props.location.query.pm)
          : '';
        dispatch(fa2GuideSelected(guideName));
        dispatch(fa2PerformanceMeasureSelected(performanceMeasure));
        props.setLoading(false);
      });
    }
  };

  return (
    <>
      {selectedGuide && selectedPerformanceMeasure && (
        <>
          {<ShowErrors errors={errors} />}
          {renderCentersAndClassrooms()}
          {renderCenterClassroomEditModal()}
          {renderCenterConfirmation()}
          {renderAlertWarning()}
          {renderAddCenterModal()}
          {renderAddClassroomModal()}
          {renderAddConfirmation()}
        </>
      )}
    </>
  );
}
