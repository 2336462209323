import { isEmpty, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Message, Segment } from 'semantic-ui-react';

import _ from 'lodash';

//Import components
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsHelpText from '../../../utils/AmsHelpText';

// Import actions.
import {
  addNote,
  fetchProfileUpdatedDateNotes,
} from '../../../actions/noteActions';
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

// Import settings.
import { acl } from '../../../config';

class Miscellaneous extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    errors: {},
    data: {
      oid: '',
      willingShortNotice: false,
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (props.profile.oid !== state.data.oid) {
      const { profileTab } = props;

      const { oid, willingShortNotice } = props.profile;

      if (profileTab !== 'miscellaneous') {
        this.cancelEdit();
      }

      return {
        data: {
          oid,
          willingShortNotice,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    const { oid, willingShortNotice } = this.props.profile;

    const oldData = {
      oid,
      willingShortNotice,
    };

    this.setState({
      data: oldData,
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      let { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        const updateTimeNote = {
          note: AmsDateFormatters.getMoment().utc(),
          createdAt: AmsDateFormatters.getMoment().utc(),
          updateAt: AmsDateFormatters.getMoment().utc(),
          submittedBy: pick(this.props.currentUser, [
            'oid',
            'userName',
            'fullName',
          ]),
        };

        updateTimeNote.tags = {
          type: 'profile_update_date',
          userId: data.oid,
          field: 'Miscellaneous Update Date',
        };

        this.props.addNote(updateTimeNote).then(e => {
          const body = {
            filters: {
              tags: {
                userId: data.oid,
                type: 'profile_update_date',
              },
            },
          };

          this.props.fetchProfileUpdatedDateNotes(body);
        });

        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};

    return errors;
  };

  handleChange = (e, { name, value }) => {
    if (value === 'Yes') value = true;
    else if (value === 'No') value = false;

    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.userSettings.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  render() {
    const { editMode, loading, errors, data, showSuccess } = this.state;
    const { notes } = this.props;
    let date = null;
    if (!isEmpty(notes)) {
      const updatedTimeNotes = notes.filter(e => {
        return e.tags.field === 'Miscellaneous Update Date';
      });
      const note = updatedTimeNotes.reduce((acc, val) => {
        if (acc === null) return val;
        return AmsDateFormatters.getMoment(val.updateDate).isAfter(
          acc.updateDate
        )
          ? val
          : acc;
      }, null);
      date = note && note.note;
    }

    return (
      <>
        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <div className="profile-form-title-wrapper">
            <h2>Miscellaneous</h2>
          </div>

          <div className="profile-form-fields-wrapper">
            {showSuccess && (
              <Message
                positive
                icon="check"
                header="Success!"
                content="Data saved successfully."
              />
            )}

            {!_.isEmpty(errors) && (
              <Message
                negative
                icon="cancel"
                header="Something went wrong!"
                list={Object.keys(errors).map(errorKey => (
                  <li key={errorKey}>{errors[errorKey]}</li>
                ))}
              />
            )}

            <Form.Group widths="equal">
              {editMode ? (
                <Form.Field
                  style={{ width: '25px' }}
                  name="willingShortNotice"
                  onChange={this.handleChange}
                  value={data.willingShortNotice === true ? 'Yes' : 'No'}
                  options={[
                    { key: 'Yes', text: 'Yes', value: 'Yes' },
                    { key: 'No', text: 'No', value: 'No' },
                  ]}
                  placeholder="Select"
                  selection
                  control={Dropdown}
                  id="miscellaneousLimitedQuestion"
                  aria-labelledby="miscellaneousLimitedQuestion"
                  label={{
                    children: (
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="miscellaneousLimitedQuestion"
                        label="Are you willing to go on a Review with limited notice(0-14
                        days), possibly without a cash advance?"
                      />
                    ),
                    htmlFor: 'miscellaneousLimitedQuestion',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Are you willing to go on a Review with limited notice(0-14
                    days), possibly without a cash advance?
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="miscellaneousLimitedQuestion"
                    />
                  </label>
                  <div className="default-value">
                    {data.willingShortNotice === true ? 'Yes' : 'No'}
                  </div>
                </Form.Field>
              )}
            </Form.Group>
          </div>

          <Segment basic floated="right">
            {editMode ? (
              <div>
                <Button size="large" primary>
                  Save
                </Button>
                <Button
                  size="large"
                  onClick={e => {
                    e.preventDefault();
                    this.cancelEdit();
                  }}
                  basic
                >
                  Cancel
                </Button>
              </div>
            ) : (
              this.showEditButton()
            )}
          </Segment>
        </Form>
        {date ? (
          <span
            style={{ display: 'block', textAlign: 'center', marginTop: '40px' }}
          >
            Last updated on: {AmsDateFormatters.formatDate(date)}
          </span>
        ) : null}
      </>
    );
  }
}

Miscellaneous.propTypes = {
  profile: PropTypes.object,
  rolesList: PropTypes.array,
  lookups: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  notes: state.note.userProfileUpdatedNotes,
  profileTab: state.profileTab,
  rolesList: state.admin.userRoles,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, {
  updateUserProfile,
  addNote,
  fetchProfileUpdatedDateNotes,
  updateUserRoles,
})(Miscellaneous);
