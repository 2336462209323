import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Icon,
  Input,
  List,
  Message,
  Modal,
  Radio,
  Segment,
} from 'semantic-ui-react';

// Import util
import AmsModal from '../../utils/AmsModal';
import enforceRole from '../../utils/EnforceRole';

// Import config.
import { acl } from '../../config';

// Style
import './assets/style.css';

// Actions.
import {
  addTag,
  deleteTag,
  fetchTags,
  updateTag,
} from '../../actions/help/tagActions';

const HelpTagModal = () => {
  //state management
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [tagId, setTagId] = useState(null);
  const [data, setData] = useState({
    title: '',
    active: false,
  });

  //redux store data
  const { tags } = useSelector(state => state.help);
  const currentUser = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const generateModalAlert = () => {
    if (!_.isEmpty(errors)) {
      return (
        <Message style={{ width: '95%', margin: '0 auto' }} negative>
          <Icon name="cancel" />
          {errors.message}
        </Message>
      );
    }
  };

  const handleValueChange = (e, { name, value, checked }) => {
    setErrors({});
    if (name === 'active') {
      value = checked;
    }
    setData({ ...data, [name]: value });
  };

  const clearForm = () => {
    setErrors({});
    setData({
      title: '',
      active: false,
    });
    setTagId(null);
  };

  const onSubmit = e => {
    setErrors({});
    e.preventDefault();
    if (!_.isEmpty(errors)) return;

    // No errors save data.
    saveTag();
  };

  const saveTag = () => {
    if (tagId) {
      dispatch(updateTag(tagId, data))
        .then(data => {
          clearForm();
          setShowModal(false);
          dispatch(fetchTags());
        })
        .catch(error => {
          setErrors(error.message);
        });
    } else {
      dispatch(addTag(data))
        .then(data => {
          clearForm();
          setShowModal(false);
          dispatch(fetchTags());
        })
        .catch(error => {
          setErrors(error.message);
        });
    }
  };

  const renderTagList = () => {
    const editTagClicked = id => {
      setTagId(id);
      const tagData = tags.find(e => {
        return e._id === id;
      });

      setData(_.pick(tagData, ['title', 'active']));
    };

    const deleteTagClicked = id => {
      dispatch(deleteTag(id));
    };

    if (!tags || tags.length === 0) {
      return <span>No Tags found.</span>;
    }
    return (
      <List divided relaxed>
        {tags.map(e => (
          <List.Item key={e._id}>
            <List.Content floated="right">
              <Button.Group icon>
                <Button
                  onClick={event => {
                    editTagClicked(e._id);
                  }}
                  basic
                  aria-label="Edit Row"
                  color="green"
                >
                  <Icon name="pencil" />
                </Button>
                <Button
                  basic
                  onClick={event => {
                    deleteTagClicked(e._id);
                  }}
                  aria-label="Delete Row"
                  color="red"
                >
                  <Icon name="trash" />
                </Button>
              </Button.Group>
            </List.Content>
            <List.Content>{e.title}</List.Content>
          </List.Item>
        ))}
      </List>
    );
  };

  const renderModal = () => {
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        closeIcon
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {' '}
        <Modal.Header>Add or Modify Help Tags</Modal.Header>
        <Modal.Content scrolling>
          {' '}
          <div>{renderTagList()}</div>
          <Segment clearing>
            {generateModalAlert()}
            <Form onSubmit={onSubmit}>
              <Form.Field
                required
                value={data.title}
                onChange={handleValueChange}
                name="title"
                aria-label="Title of Tag"
                control={Input}
                label="Title"
                placeholder="Title"
              />
              <Form.Field
                checked={data.active}
                name="active"
                id="activeToggle"
                label={{ children: 'Active', htmlFor: 'activeToggle' }}
                onChange={handleValueChange}
                toggle
                control={Radio}
              />

              <Segment basic floated="left">
                <Button
                  type="submit"
                  onClick={e => {
                    onSubmit(e);
                  }}
                  disabled={!data.title}
                >
                  {!tagId ? 'Create' : 'Save'}
                </Button>
                <Button
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Segment>
              <Segment basic floated="right">
                <Button
                  onClick={() => {
                    clearForm();
                  }}
                >
                  Clear Form
                </Button>
              </Segment>
            </Form>
          </Segment>
        </Modal.Content>
      </AmsModal>
    );
  };

  const renderButton = () => {
    return enforceRole(
      <Button
        className="help-edit-modal-button"
        onClick={e => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
        Modify Tags
      </Button>,

      acl.actions.help.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  };
  return (
    <>
      {renderButton()}
      {renderModal()}
    </>
  );
};

export default HelpTagModal;
