import React from 'react';

import Calendar from './Calendar';

// Import components.
import SlidingContainer from '../../utils/layout/SlidingContainer';
import BrowserTitle from '../../utils/BrowserTitle';

const CalendarPage = () => {
  return (
    <SlidingContainer title={'My Calendar'}>
      <BrowserTitle title={'My Calendar'} />
      <Calendar />
    </SlidingContainer>
  );
};

export default CalendarPage;
