import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const AmsActionRow = ({ actions, pointing = 'top left' }) => (
  <Dropdown
    pointing={pointing}
    icon="ellipsis horizontal"
    button
    className="icon"
  >
    <Dropdown.Menu>
      {actions.map((e, index) => {
        return (
          <Dropdown.Item
            onClick={event => {
              e.onClick(event);
            }}
          >
            {e.text}
          </Dropdown.Item>
        );
      })}
    </Dropdown.Menu>
  </Dropdown>
);
export default AmsActionRow;
