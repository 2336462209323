import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Message, Button } from 'semantic-ui-react';
import _ from 'lodash';
import AmsAlert from '../../utils/AmsAlert';

// Import Actions
import {
  addClassSubmission,
  fetchAMSForm,
  fetchSubmission,
  addSubmission,
} from '../../actions/submissions';

//Import component
import { fields } from './CLASSSurveySchema';
import Form from 'react-jsonschema-form';

// Import helper function.
import enforceRole from '../../utils/EnforceRole';

// Import acl.
import { acl } from '../../config';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

class CLASSObservationCycle extends Component {
  state = {
    observationCycleDisabled: false,
    formStatus: '',
    formErrors: {},
    surveyData: {},
    submissionError: false,
    rawData: {},
    formSchema: {},
    uiSchema: {},
    submissionId: '',
    showAlert: false,
    runOnce: false,
    classroomSkiprequested:
      this.props.selectedClassroom.classroomSkipRequested || false,
    replacementRequested:
      this.props.selectedClassroom.replacementRequested || false,
    editMode: false,
    versionHistory: {},
    disableForm: false,
    key: AmsDateFormatters.getMoment(),
    obCycleSkipRequested:
      this.props.selectedClassroom.obCycleSkipRequested || false,
    showConfirmationAlert: false,
    formProcessing: false,
  };

  componentDidMount() {
    this.fetchData(this.props.observationCycle);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.observationCycle !== this.props.observationCycle ||
      nextProps.version !== this.props.version
    ) {
      this.setState(
        {
          surveyData: {},
          rawData: {},
          versionHistory: {},
          disableForm: false,
          runOnce: nextProps.reset.runOnce,
          editMode: nextProps.reset.false,
          formErrors: nextProps.reset.formErrors,
          key: nextProps.reset.key,
        },
        () => {
          this.fetchData(nextProps.observationCycle);
        }
      );
    }
    if (nextProps.selectedClassroom !== this.props.selectedClassroom) {
      this.setState({
        classroomSkipRequested:
          nextProps.selectedClassroom.classroomSkipRequested,
        replacementRequested: nextProps.selectedClassroom.replacementRequested,
        obCycleSkipRequested: nextProps.selectedClassroom.obCycleSkipRequested,
        classroomSkipApproved:
          nextProps.selectedClassroom.classroomSkipApproved,
        obCycleSkipApproved: nextProps.selectedClassroom.obCycleSkipApproved,
      });
    }
  }

  checkScoresOverDuration = (observationStartTime, observationStopTime) => {
    if (observationStartTime && observationStopTime) {
      if (
        AmsDateFormatters.getMoment(
          AmsDateFormatters.getFormattedMoment(observationStopTime, 'HH:mm A')
        ).diff(
          AmsDateFormatters.getFormattedMoment(observationStartTime, 'HH:mm A'),
          'minutes'
        ) < 20 &&
        AmsDateFormatters.getMoment(
          AmsDateFormatters.getFormattedMoment(observationStopTime, 'HH:mm A')
        ).diff(
          AmsDateFormatters.getFormattedMoment(observationStartTime, 'HH:mm A'),
          'minutes'
        ) >= 10
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  componentWillUnmount() {
    // console.log('umnounted')
  }

  fetchData = observationCycle => {
    const defaultFormId =
      !observationCycle.selectedFormId &&
      this.props.reviewType === 'PR-CLASS' &&
      this.props.isShowES
        ? '63ada739a0e656447fb8f9bb'
        : '5b16ce6f511e260af1b0bdd0';

    const input = {
      filters: {
        amsFormId: observationCycle.selectedFormId
          ? observationCycle.selectedFormId
          : defaultFormId,
      },
    };

    this.props.fetchAMSForm(input).then(data => {
      if (observationCycle.selectedSubmissionId) {
        let input =
          this.props.version || this.props.version === 0
            ? {
                filters: {
                  amsSubmissionId: observationCycle.selectedSubmissionId,
                  version: this.props.version,
                },
              }
            : {
                filters: {
                  amsSubmissionId: observationCycle.selectedSubmissionId,
                },
              };
        this.props
          .fetchSubmission(input)
          .then(response => {
            const { surveyData } = response.submission.data;
            this.setState({
              selectedFormId: data.AMSForm._id,
              formProcessing: false,
              surveyData: { ...surveyData },
              submission: response.submission,
              rawData: {
                observationTime: {
                  observationStartTime: surveyData.observationStartTimeObj,
                  observationStopTime: surveyData.observationStopTimeObj,
                  observationDurationCheck: surveyData.observationDurationCheck,
                  observationNotes: surveyData.observationNotes,
                },
                sampling: {
                  visitDate: surveyData.visitDate,
                  kidsEnrolled: surveyData.kidsEnrolled,
                },
                backgroundQuestions: {
                  minimumKidsPresent: surveyData.minimumKidsPresent,
                  predominantLanguage: surveyData.predominantLanguage,
                  otherPredominantLanguage: surveyData.otherPredominantLanguage,
                  otherLanguageSpoken: surveyData.otherLanguageSpoken,
                  otherLanguage: surveyData.otherLanguage,
                  childrenSpeakingOtherLanguages:
                    surveyData.childrenSpeakingOtherLanguages,
                  staffSpeakingOtherLanguages:
                    surveyData.staffSpeakingOtherLanguages,
                  leadTeacherPresent: surveyData.leadTeacherPresent,
                  leadTeacherNew: surveyData.leadTeacherNew,
                },
                formatActivity: {
                  activityRoutine: surveyData.activityRoutine,
                  activityTransitions: surveyData.activityTransitions,
                  activityWholeGroup: surveyData.activityWholeGroup,
                  activityIndividualTime: surveyData.activityIndividualTime,
                  activityMealsSnacks: surveyData.activityMealsSnacks,
                  activitySmallGroup: surveyData.activitySmallGroup,
                  activityFreeChoiceCenters:
                    surveyData.activityFreeChoiceCenters,
                },
                scores: {
                  positiveClimate: surveyData.positiveClimate,
                  negativeClimate: surveyData.negativeClimate,
                  negativeClimateReason: surveyData.negativeClimateReason,
                  teacherSensitivity: surveyData.teacherSensitivity,
                  studentPerspectiveRegard: surveyData.studentPerspectiveRegard,
                  behaviorManagement: surveyData.behaviorManagement,
                  productivity: surveyData.productivity,
                  instructionalLearningFormats:
                    surveyData.instructionalLearningFormats,
                  conceptDevelopment: surveyData.conceptDevelopment,
                  feedbackQuality: surveyData.feedbackQuality,
                  languageModeling: surveyData.languageModeling,
                },
              },
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              formProcessing: false,
            });
          });
      } else this.setState({ formProcessing: false, submission: {} });
    });
  };

  validate = (data, errors) => {
    const {
      sampling: { kidsEnrolled },
      backgroundQuestions: {
        minimumKidsPresent,
        predominantLanguage,
        otherPredominantLanguage,
        leadTeacherPresent,
        leadTeacherNew,
        otherLanguageSpoken,
        otherLanguage,
      },
      formatActivity,
      scores,
      observationTime: {
        observationStartTime,
        observationStopTime,
        observationStartTime: { starthour, startminute, startampm },
        observationStopTime: { stophour, stopminute, stopampm },
        observationNotes,
      },
    } = data;
    AmsDateFormatters.getFormattedMoment(
      `${starthour}:${startminute} ${startampm}`,
      'HH:mm A'
    );
    AmsDateFormatters.getFormattedMoment(
      `${stophour}:${stopminute} ${stopampm}`,
      'HH:mm A'
    );
    if (kidsEnrolled === undefined) {
      errors.sampling.kidsEnrolled.addError('is a required property');
    }
    if (minimumKidsPresent / kidsEnrolled < 0.5) {
      errors.backgroundQuestions.minimumKidsPresent.addError(
        'Less than 50% of children enrolled in the class are present.  Based on the OHS Monitoring CLASS methodology, you should not observe this class.'
      );
    }
    if (
      AmsDateFormatters.getFormattedMoment(
        `${starthour}:${startminute} ${startampm}`,
        'HH:mm A'
      ) &&
      AmsDateFormatters.getFormattedMoment(
        `${stophour}:${stopminute} ${stopampm}`,
        'HH:mm A'
      ) &&
      AmsDateFormatters.getFormattedMoment(
        `${stophour}:${stopminute} ${stopampm}`,
        'HH:mm A'
      ) <
        AmsDateFormatters.getFormattedMoment(
          `${starthour}:${startminute} ${startampm}`,
          'HH:mm A'
        )
    ) {
      errors.observationTime.observationStopTime.addError(
        'End time cannot be before start time'
      );
    }
    if (
      predominantLanguage === 'Other' &&
      otherPredominantLanguage === undefined
    ) {
      errors.backgroundQuestions.otherPredominantLanguage.addError(
        'You selected `Other` for predominant langauge being spoken, please specify'
      );
    } else if (
      observationStopTime &&
      AmsDateFormatters.getFormattedMoment(
        `${stophour}:${stopminute} ${stopampm}`,
        'HH:mm A'
      ).diff(
        AmsDateFormatters.getFormattedMoment(
          `${starthour}:${startminute} ${startampm}`,
          'HH:mm A'
        ),
        'minutes'
      ) < 20 &&
      observationStopTime &&
      AmsDateFormatters.getFormattedMoment(
        `${stophour}:${stopminute} ${stopampm}`,
        'HH:mm A'
      ).diff(
        AmsDateFormatters.getFormattedMoment(
          `${starthour}:${startminute} ${startampm}`,
          'HH:mm A'
        ),
        'minutes'
      ) >= 10 &&
      _.isEmpty(observationNotes)
    ) {
      errors.observationTime.observationNotes.addError(
        'You indicated that you observed for less than 20 minutes.  Observation cycles should be 20 minutes.  Please explain why you observed for less than 20 minutes and detail exactly how long you observed.'
      );
    } else if (
      observationStopTime &&
      AmsDateFormatters.getFormattedMoment(
        `${stophour}:${stopminute} ${stopampm}`,
        'HH:mm A'
      ).diff(
        AmsDateFormatters.getFormattedMoment(
          `${starthour}:${startminute} ${startampm}`,
          'HH:mm A'
        ),
        'minutes'
      ) < 10 &&
      _.isEmpty(observationNotes)
    ) {
      errors.observationTime.observationStopTime.addError(
        'You indicated that you observed for less than 10 minutes.  Observations must be a minimum of 10 minutes to be valid.  If you are unable to conduct an observation that is a minimum of 10 minutes, then the observation cycle should not be scored.'
      );
    } else if (
      observationStartTime &&
      AmsDateFormatters.getFormattedMoment(
        `${stophour}:${stopminute} ${stopampm}`,
        'HH:mm A'
      ).diff(
        AmsDateFormatters.getFormattedMoment(
          `${starthour}:${startminute} ${startampm}`,
          'HH:mm A'
        ),
        'minutes'
      ) >= 21
    ) {
      errors.observationTime.observationStartTime.addError(
        'The observation duration has been more than 20 minutes.  Observations cannot be more than 20 minutes.'
      );
    }
    if (otherLanguageSpoken && !otherLanguage) {
      errors.backgroundQuestions.otherLanguage.addError(
        'Please enter any languages other than the predominant language being spoken in the classroom'
      );
    }
    if (!_.includes(formatActivity, true)) {
      errors.formatActivity.addError(
        'Please select the format of this observation'
      );
    }
    if (leadTeacherPresent === undefined) {
      errors.backgroundQuestions.leadTeacherPresent.addError(
        'Please specify if the lead teacher was present during a portion of the observation'
      );
    }
    if (leadTeacherNew === undefined) {
      errors.backgroundQuestions.leadTeacherNew.addError(
        'Please indicate how long the lead teacher has been working with the group'
      );
    }
    if (scores.behaviorManagement === undefined) {
      errors.scores.behaviorManagement.addError('Please indicate a score');
    }
    if (scores.conceptDevelopment === undefined) {
      errors.scores.conceptDevelopment.addError('Please indicate a score');
    }
    if (scores.feedbackQuality === undefined) {
      errors.scores.feedbackQuality.addError('Please indicate a score');
    }
    if (scores.instructionalLearningFormats === undefined) {
      errors.scores.instructionalLearningFormats.addError(
        'Please indicate a score'
      );
    }
    if (scores.languageModeling === undefined) {
      errors.scores.languageModeling.addError('Please indicate a score');
    }
    if (scores.negativeClimate === undefined) {
      errors.scores.negativeClimate.addError('Please indicate a score');
    }
    if (scores.positiveClimate === undefined) {
      errors.scores.positiveClimate.addError('Please indicate a score');
    }
    if (scores.productivity === undefined) {
      errors.scores.productivity.addError('Please indicate a score');
    }
    if (scores.studentPerspectiveRegard === undefined) {
      errors.scores.studentPerspectiveRegard.addError(
        'Please indicate a score'
      );
    }
    if (scores.teacherSensitivity === undefined) {
      errors.scores.teacherSensitivity.addError('Please indicate a score');
    }
    if (
      scores.negativeClimate === (6 || 7) &&
      scores.negativeClimateReason === undefined
    ) {
      errors.scores.negativeClimateReason.addError(
        'Please indicate a reason why you have indicated a high negative score'
      );
    }
    return errors;
  };

  transformErrors = errors => {
    return errors.map(error => {
      if (
        error.name === 'required' &&
        error.params.missingProperty === 'visitDate'
      ) {
        error.message = 'Please enter visit date';
      }
      if (
        error.name === 'required' &&
        error.params.missingProperty === 'kidsEnrolled'
      ) {
        error.message = 'Please enter the number of children enrolled';
      }
      if (
        error.name === 'required' &&
        error.params.missingProperty === 'minimumKidsPresent'
      ) {
        error.message =
          'Please enter the minimum number of children present in class';
      }
      if (
        error.name === 'required' &&
        error.params.missingProperty === 'predominantLanguage'
      ) {
        error.message =
          'Please enter the predominant language spoken between teachers and students during this observation cycle';
      }
      return error;
    });
  };

  handleChange = value => {
    const { currentUser } = this.props;

    const { observationTime } = value.formData;
    const {
      observationStartTime: { starthour, startminute, startampm },
      observationStopTime: { stophour, stopminute, stopampm },
    } = observationTime;
    this.props.registerSurveyChanges(true);

    //check if empty submission, initialiazed form
    if (_.isEmpty(this.state.submission)) {
      //check user roles, if not dualcoder or reviewer, show error to form
      const allowed =
        currentUser.roles.includes('DualCoder') ||
        currentUser.roles.includes('Reviewer');

      if (!allowed) {
        this.setState({
          submissionError: true,
        });
        return;
      }
    }
    if (
      !this.state.runOnce &&
      starthour &&
      startminute &&
      startampm &&
      stophour &&
      stopminute &&
      stopampm
    ) {
      if (
        AmsDateFormatters.getFormattedMoment(
          `${stophour}:${stopminute} ${stopampm}`,
          'HH:mm A'
        ).diff(
          AmsDateFormatters.getFormattedMoment(
            `${starthour}:${startminute} ${startampm}`,
            'HH:mm A'
          ),
          'minutes'
        ) > 20
      ) {
        this.setState({ showAlert: true });
      }
    }

    // eslint-disable-next-line array-callback-return
    Object.keys(value.formData).map(groupKey => {
      this.setState(
        {
          ...this.state,
          rawData: {
            ...this.state.rawData,
            ...value.formData,
            observationTime: {
              ...value.formData.observationTime,
              observationDurationCheck: this.checkScoresOverDuration(
                `${starthour}:${startminute} ${startampm}`,
                `${stophour}:${stopminute} ${stopampm}`
              ),
            },
          },
          surveyData: {
            ...this.state.surveyData,
            ...value.formData[groupKey],
          },
        },
        () => {
          this.setState({
            ...this.state,
            rawData: {
              ...this.state.rawData,
              observationTime: {
                ...this.state.rawData.observationTime,
                observationNotes: this.state.rawData.observationTime
                  .observationDurationCheck
                  ? this.state.rawData.observationTime.observationNotes
                  : null,
              },
            },
            surveyData: {
              ...this.state.surveyData,
              observationNotes: this.state.rawData.observationTime
                .observationDurationCheck
                ? this.state.rawData.observationTime.observationNotes
                : null,
              observationDurationCheck: this.state.rawData.observationTime
                .observationDurationCheck,
            },
          });
        }
      );
    });
  };

  showObservationAlert = () => {
    return (
      <AmsAlert
        show={this.state.showAlert}
        title="Alert"
        type="error"
        showConfirm
        text={`You have observed for more than 20 minutes. Please submit now or this observation cannot be submitted.`}
        onConfirm={() => {
          this.setState({
            showAlert: false,
            runOnce: true,
          });
        }}
      />
    );
  };

  log = type => console.log.bind(console, type);

  onSubmit = () => {
    const {
      selectedClassroom,
      AMSForm,
      observationCycle,
      selectedCenter,
    } = this.props;

    const {
      observationStartTime: { startampm, starthour, startminute },
      observationStopTime: { stopampm, stophour, stopminute },
    } = this.state.surveyData;

    let observationStartTime = `${starthour}:${startminute} ${startampm}`;
    let observationStopTime = `${stophour}:${stopminute} ${stopampm}`;

    let cleanedSurveyData = _.omit(this.state.surveyData, [
      'observationStartTimeObj',
      'observationStopTimeObj',
    ]);

    const errors = this.state.rawData && this.validate(this.state.rawData);

    const submission = {
      data: {
        amsFormId: AMSForm._id,
        classSampleId: selectedClassroom.classSampleId, // Please note this field is specific to class reviews submissions/add
        surveyName: AMSForm.formName,
        surveyType: AMSForm.formType,
        reviewId: `${this.context.router.params.reviewId}`,
        surveyData: {
          ...cleanedSurveyData,
          observationStartTime,
          observationStopTime,
          centerName: selectedCenter.centerName,
          classroomName: selectedClassroom.classRoomName,
          observationCycle: observationCycle.observationCycle,
        },
      },
    };
    this.setState({
      ...this.state,
      formErrors: errors,
      showConfirmationAlert: true,
      submission: submission,
    });
  };

  submitEditedSubmission = () => {
    const {
      selectedClassroom,
      AMSForm,
      observationCycle,
      selectedCenter,
    } = this.props;

    const {
      observationStartTime: { startampm, starthour, startminute },
      observationStopTime: { stopampm, stophour, stopminute },
    } = this.state.surveyData;

    let observationStartTime = `${starthour}:${startminute} ${startampm}`;
    let observationStopTime = `${stophour}:${stopminute} ${stopampm}`;

    let cleanedSurveyData = _.omit(this.state.surveyData, [
      'observationStartTimeObj',
      'observationStopTimeObj',
    ]);

    const errors = this.state.rawData && this.validate(this.state.rawData);

    const submission = {
      data: {
        amsSubmissionId:
          observationCycle.selectedSubmissionId || this.state.submissionId,
        amsFormId: AMSForm._id,
        classSampleId: selectedClassroom.classSampleId, // Please note this field is specific to class reviews submissions/add
        surveyName: AMSForm.formName,
        surveyType: AMSForm.formType,
        reviewId: `${this.context.router.params.reviewId}`,
        surveyData: {
          ...cleanedSurveyData,
          observationStartTime,
          observationStopTime,
          centerName: selectedCenter.centerName,
          classroomName: selectedClassroom.classRoomName,
          observationCycle: observationCycle.observationCycle,
        },
      },
    };
    this.setState({
      ...this.state,
      formErrors: errors,
      showConfirmationAlert: true,
      submission: submission,
    });
  };

  updateObservationCycle = observationCycle => {
    this.props.updateObservationCycle(observationCycle);
  };

  handleSkipReplacementButtonText = () => {
    const {
      classroomSkipRequested,
      replacementRequested,
      obCycleSkipRequested,
      classroomSkipApproved,
      obCycleSkipApproved,
    } = this.state;
    if (
      classroomSkipRequested &&
      (classroomSkipApproved === '' ||
        classroomSkipApproved === null ||
        classroomSkipApproved === true)
    )
      return 'Skipped';
    else if (
      obCycleSkipRequested &&
      (_.isEmpty(obCycleSkipApproved) || obCycleSkipApproved === true) &&
      this.props.observationCycle.observationCycle === 2
    )
      return 'Skipped';
    else if (replacementRequested) return 'Replaced';
  };

  disableForm = () => {
    const {
      classroomSkipRequested,
      replacementRequested,
      editMode,
      obCycleSkipRequested,
      classroomSkipApproved,
      obCycleSkipApproved,
    } = this.state;
    const {
      observationCycle: { selectedSubmissionId, observationCycle },
      reviewStatus,
      classTaskDetail,
    } = this.props;

    if (classTaskDetail && classTaskDetail[0].isReportFinalized)
      return 'formDisabled';

    if (reviewStatus.toLowerCase() === 'cancelled') return 'formDisabled';

    if (
      (selectedSubmissionId ||
        (classroomSkipRequested && classroomSkipApproved !== false) ||
        replacementRequested) &&
      !editMode
    )
      return 'formDisabled';
    else if (
      observationCycle === 2 &&
      obCycleSkipRequested &&
      obCycleSkipApproved !== false
    )
      return 'formDisabled';

    return '';
  };

  renderConfirmationMessage = () => {
    const {
      showConfirmationAlert,
      editMode,
      formErrors,
      submission,
      selectedFormId,
    } = this.state;
    return (
      <AmsAlert
        show={showConfirmationAlert}
        title="You are about to submit your Observation data. Are you sure you want continue?"
        text={''}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Continue'}
        cancelButtonText={'Cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({
            showConfirmationAlert: false,
          });
        }}
        onConfirm={
          editMode
            ? () => {
                if (_.isEmpty(formErrors)) {
                  this.setState(
                    {
                      formProcessing: true,
                      editMode: false,
                      version: null,
                      showConfirmationAlert: false,
                    },
                    () => {
                      this.props.addSubmission(submission).then(data => {
                        if (data.amsSubmissionId) {
                          const observationCycleSubmitted = {
                            selectedFormId: selectedFormId,
                            selectedSubmissionId: data.amsSubmissionId,
                            observationCycle: this.props.observationCycle
                              .observationCycle,
                          };
                          this.updateObservationCycle(
                            observationCycleSubmitted
                          );
                          this.setState({});
                          this.props.registerSurveyChanges(false);
                        }
                      });
                    }
                  );
                } else
                  this.setState({ showConfirmationAlert: false }, () => {
                    this.props.registerSurveyChanges(false);
                  });
              }
            : () => {
                if (_.isEmpty(formErrors)) {
                  this.setState(
                    {
                      formProcessing: true,
                      showConfirmationAlert: false,
                    },
                    () => {
                      this.props.addClassSubmission(submission).then(data => {
                        if (data.amsSubmissionId) {
                          const observationCycleSubmitted = {
                            selectedFormId: selectedFormId,
                            selectedSubmissionId: data.amsSubmissionId,
                            observationCycle: this.props.observationCycle
                              .observationCycle,
                          };
                          this.updateObservationCycle(
                            observationCycleSubmitted
                          );
                          this.setState(
                            {
                              showConfirmationAlert: false,
                            },
                            () => {
                              this.props.registerSurveyChanges(false);
                            }
                          );
                        }
                      });
                    }
                  );
                } else
                  this.setState({ showConfirmationAlert: false }, () => {
                    this.props.registerSurveyChanges(false);
                  });
              }
        }
      />
    );
  };

  disableScoreQuestions = () => {
    const scoreQuestions = document.getElementsByClassName('scores-questions');

    const allInputs = scoreQuestions[0].querySelectorAll('fieldset');

    allInputs.forEach(input => input.setAttribute('disabled', true));
  };

  showEditButton = () => {
    const { editMode, submission } = this.state;
    const { currentUser, classTaskDetail } = this.props;

    if (classTaskDetail && classTaskDetail[0].isReportFinalized) return;

    if (editMode) return;

    if (submission && submission.editable) {
      return enforceRole(
        <Button
          size="large"
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            this.disableScoreQuestions();
            this.setState({ editMode: true });
          }}
        />,
        acl.actions.classDetailButtons.editSubmissions,
        currentUser.roles
      );
    }
  };

  render() {
    const {
      formErrors,
      rawData,
      submissionId,
      classroomSkipRequested,
      replacementRequested,
      editMode,
      obCycleSkipRequested,
      formProcessing,
      obCycleSkipApproved,
      classroomSkipApproved,
      submissionError,
    } = this.state;
    const {
      AMSForm,
      observationCycle,
      currentUser,
      reviewStatus,
      classTaskDetail,
    } = this.props;

    let isAllowed = false;
    if (_.isEmpty(this.state.submission)) {
      if (
        currentUser.roles.includes('DualCoder') ||
        currentUser.roles.includes('Reviewer')
      ) {
        isAllowed = true;
      }
    } else {
      isAllowed = true;
    }

    return (
      <div>
        {!_.isEmpty(formErrors) && (
          <Message
            negative
            icon="cancel"
            header="Please complete the required fields"
            list={Object.keys(formErrors).map(errorKey => (
              <li key={errorKey}>{formErrors[errorKey]}</li>
            ))}
          />
        )}
        {submissionError && (
          <Message
            negative
            icon="cancel"
            header="Error: Only the assigned DualCoder or Reviewer can fill out the initial submission"
            list={Object.keys(formErrors).map(errorKey => (
              <li key={errorKey}>{formErrors[errorKey]}</li>
            ))}
          />
        )}

        {this.showObservationAlert()}
        {this.renderConfirmationMessage()}
        <Segment basic loading={formProcessing}>
          <div className={editMode ? 'edit-mode' : ''}>
            <fieldset
              disabled={
                this.disableForm() === 'formDisabled' && !editMode
                  ? true
                  : false
              }
            >
              <Form
                key={this.state.key}
                schema={AMSForm.formSchema || {}}
                uiSchema={AMSForm.uiSchema || {}}
                onChange={this.handleChange}
                onSubmit={
                  editMode ? this.submitEditedSubmission : this.onSubmit
                }
                onError={this.log('errors')}
                validate={this.validate}
                transformErrors={this.transformErrors}
                liveValidate={false}
                showErrorList={false}
                formData={rawData}
                noHtml5Validate={true}
                fields={fields}
                className={this.disableForm()}
                // widgets={widgets}
              >
                <div className="buttonsEnabled">
                  {classTaskDetail &&
                    !classTaskDetail[0].isReportFinalized &&
                    !editMode &&
                    reviewStatus.toLowerCase() !== 'cancelled' &&
                    (!classroomSkipRequested ||
                      classroomSkipApproved === false) &&
                    !replacementRequested &&
                    !observationCycle.selectedSubmissionId &&
                    (observationCycle.observationCycle === 2 &&
                    obCycleSkipRequested &&
                    obCycleSkipApproved !== false
                      ? false
                      : true) && (
                      <Button
                        type="submit"
                        disabled={
                          observationCycle.selectedSubmissionId ||
                          submissionId ||
                          !isAllowed
                            ? true
                            : false
                        }
                        primary
                        size="large"
                        content={'submit'}
                      />
                    )}
                  {(observationCycle.selectedSubmissionId || submissionId) &&
                    (!classroomSkipRequested ||
                      classroomSkipApproved === false) &&
                    !replacementRequested &&
                    editMode &&
                    enforceRole(
                      <Button
                        type={'submit'}
                        primary
                        size="large"
                        content={'Save'}
                      />,
                      acl.actions.classDetailButtons.editSubmissions,
                      currentUser.roles
                    )}
                  {(observationCycle.selectedSubmissionId || submissionId) &&
                    (!classroomSkipRequested ||
                      classroomSkipApproved === false) &&
                    !replacementRequested &&
                    editMode &&
                    enforceRole(
                      <Button
                        negative
                        size="large"
                        content={'Cancel'}
                        onClick={() => {
                          this.setState({ editMode: false }, () =>
                            this.props.registerSurveyChanges(false)
                          );
                        }}
                      />,
                      acl.actions.classDetailButtons.editSubmissions,
                      currentUser.roles
                    )}
                  {((classroomSkipRequested &&
                    classroomSkipApproved !== false) ||
                    replacementRequested ||
                    (observationCycle.observationCycle === 2 &&
                    obCycleSkipRequested &&
                    obCycleSkipApproved !== false
                      ? true
                      : false)) && (
                    <Button
                      disabled={true}
                      primary
                      size="large"
                      content={this.handleSkipReplacementButtonText()}
                      onClick={() => {
                        return;
                      }}
                    />
                  )}
                </div>
                <input type="submit" value="submit" hidden />
              </Form>
            </fieldset>
            {this.showEditButton()}
            {/* {(observationCycle.selectedSubmissionId || submissionId) &&
              ((!classroomSkipRequested || classroomSkipApproved === false) &&
                !replacementRequested) &&
              !editMode &&
              (version === maxVersion || (!version && version !== 0)) &&
              enforceRole(
                <Button
                  size="large"
                  content={'Edit'}
                  color="green"
                  icon="edit"
                  onClick={e => {
                    this.setState({ editMode: true });
                  }}
                />,
                acl.actions.classDetailButtons.editSubmissions,
                currentUser.roles
              )} */}
          </div>
        </Segment>
      </div>
    );
  }
}

CLASSObservationCycle.contextTypes = {
  router: PropTypes.object.isRequired,
};

CLASSObservationCycle.propTypes = {
  currentUser: PropTypes.object.isRequired,
  addClassSubmission: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    AMSForm: state.submissions.AMSForm,
    selectedSubmission: state.submissions.selectedSubmission,
    classTaskDetail: state.submissions.classTaskDetail,
    resetVersionHistory: state.submissions.resetVersionHistory,
  };
}

export default connect(mapStateToProps, {
  addClassSubmission,
  fetchAMSForm,
  fetchSubmission,
  addSubmission,
})(CLASSObservationCycle);
