import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Form, Message, Input, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';

import UsersListDropdown from '../../utils/UsersListDropdown';

// Import actions
import { addTask } from '../../actions/taskActions';

import './assets/style.css';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

class TaskCreateForm extends Component {
  state = {
    data: {
      reviewId: '',
      startDate: null,
      endDate: null,
      taskName: '',
      userName: [],
    },
    modalIsOpen: true,
    saving: false,
    showSuccess: false,
    errors: {},
  };

  closeModal = () =>
    this.setState(
      {
        modalIsOpen: false,
      },
      () => this.context.router.push(`/admin/tasks`)
    );

  openModal = () => this.setState({ modalIsOpen: true });

  handleReviewIdChange = (e, { value }) =>
    this.setState({
      data: { ...this.state.data, reviewId: value },
      errors: _.omit(this.state.errors, 'reviewId'),
    });

  handeleGranteeSelected = value =>
    this.setState({
      data: { ...this.state.data, selectedGrantee: value },
      errors: _.omit(this.state.errors, 'selectedGrantee'),
    });

  handeleUserSelected = value =>
    this.setState({
      data: { ...this.state.data, userName: value },
      errors: _.omit(this.state.errors, 'userName'),
    });

  handleStartChange = start => {
    if (!start) {
      this.setState({
        data: { ...this.state.data, startDate: null, endDate: null },
        errors: _.omit(this.state.errors, 'startDate'),
      });
      return;
    }
    this.setState({
      data: {
        ...this.state.data,
        startDate: AmsDateFormatters.getMoment(start).startOf('day'),
      },
      errors: _.omit(this.state.errors, 'startDate'),
    });
  };

  handleEndChange = end => {
    if (!end) {
      this.setState({
        data: { ...this.state.data, endDate: null },
        errors: _.omit(this.state.errors, 'endDate'),
      });
      return;
    }
    this.setState({
      data: {
        ...this.state.data,
        endDate: AmsDateFormatters.getMoment(end).startOf('day'),
      },
      errors: _.omit(this.state.errors, 'endDate'),
    });
  };

  handleTaskNameChange = (e, { value }) =>
    this.setState({
      data: { ...this.state.data, taskName: value },
      errors: _.omit(this.state.errors, 'taskName'),
    });

  addFormErrors = error => {
    this.setState({
      showSuccess: false,
      errors: { ...this.state.errors, request: error },
    });
  };

  validate = data => {
    const errors = {};

    if (_.isEmpty(data.reviewId)) errors.reviewId = 'Review ID is required';
    if (_.isEmpty(data.startDate)) errors.startDate = 'Start Date is required';
    if (_.isEmpty(data.endDate)) errors.endDate = 'End Date is required';
    if (_.isEmpty(data.taskName)) errors.taskName = 'Task Name is required';
    if (_.isEmpty(data.userName)) errors.userName = 'User Name is required';

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.data);

    this.setState({ errors });

    // No more errors submit data.
    if (Object.keys(errors).length === 0) {
      this.setState({ saving: true });
      const {
        reviewId,
        startDate,
        endDate,
        taskName,
        userName,
      } = this.state.data;
      this.props
        .addTask({
          reviewId,
          taskName,
          userName,
          startDate: AmsDateFormatters.getMoment(startDate).format(
            'YYYY-MM-DD'
          ),
          endDate: AmsDateFormatters.getMoment(endDate).format('YYYY-MM-DD'),
        })
        .then(() => {
          this.setState({ showSuccess: true });
          this.clearForm();
        })
        .catch(error => {
          this.setState({
            showSuccess: false,
            saving: false,
            errors: {
              ...this.state.errors,
              response: error.response.data.message,
            },
          });
        });
    }
  };

  renderModal() {
    const { errors, saving } = this.state;
    const {
      reviewId,
      startDate,
      endDate,
      taskName,
      userName,
    } = this.state.data;

    return (
      <Modal
        className="modal-container"
        overlayClassName="modal-overlay-container"
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal.bind(this)}
        shouldCloseOnOverlayClick={false}
        contentLabel="Create Review Modal"
      >
        <div className="row" style={{ overflow: 'visible' }}>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-11">
                <h2 className="no-top-margin">Add New Task</h2>
              </div>
              <div className="col-sm-1">
                <Button
                  // color="black"
                  style={{ float: 'right', background: 'none' }}
                  icon
                  size="large"
                  onClick={this.closeModal.bind(this)}
                  aria-label="close"
                >
                  <Icon name="close"></Icon>
                </Button>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <hr />
          </div>

          {/* Message Area */}
          <div className="col-sm-12 form-message-wrapper">
            {this.showSuccess()}
            {this.showError()}
          </div>

          <div className="col-xs-12 form-wrapper">
            <Form onSubmit={this.onSubmit} noValidate>
              <Form.Field
                required
                error={!!errors.reviewId}
                label="Review ID"
                placeholder="Review ID"
                control={Input}
                value={reviewId}
                maxLength="10"
                onChange={this.handleReviewIdChange}
              />

              <Form.Field
                required
                error={!!errors.startDate}
                fluid
                // isClearable
                selectsStart
                dateFormat="MM/DD/YYYY"
                label={'Start Date'}
                control={DatePicker}
                placeholderText="Select Start Date"
                selected={startDate}
                minDate={AmsDateFormatters.getMoment()}
                startDate={startDate}
                onChange={this.handleStartChange}
              />

              <Form.Field
                required
                error={!!errors.endDate}
                fluid
                selectsEnd
                dateFormat="MM/DD/YYYY"
                label={'End Date'}
                control={DatePicker}
                placeholderText="Select End Date"
                disabled={!startDate}
                selected={endDate}
                // isClearable={true}
                startDate={startDate}
                minDate={startDate}
                endDate={endDate}
                onChange={this.handleEndChange}
              />

              <Form.Field
                required
                error={!!errors.taskName}
                control={AmsLookupDropdown}
                label="Task Name"
                placeholder="Select Task Name"
                value={taskName}
                selection
                category={'taskTypes'}
                onChange={this.handleTaskNameChange}
              />

              <Form.Field
                required
                error={!!errors.userName}
                dropdownLabel="User Name"
                control={UsersListDropdown}
                value={userName}
                multiple
                selection
                search
                onValueSelected={this.handeleUserSelected.bind(this)}
                onError={this.addFormErrors.bind(this)}
              />

              <Form.Group className="filter-wrapper">
                <Form.Button
                  label="&nbsp;"
                  basic
                  onClick={e => {
                    e.preventDefault();
                    this.clearForm();
                  }}
                >
                  Clear Form
                </Form.Button>
                <Form.Button disabled={saving} label="&nbsp;" primary>
                  {saving ? 'Saving...' : 'Add Task'}{' '}
                </Form.Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }

  clearForm = () =>
    this.setState({
      data: {
        reviewId: '',
        startDate: null,
        endDate: null,
        taskName: '',
        userName: [],
      },
      modalIsOpen: true,
      saving: false,
      errors: {},
    });

  showError = () => {
    const { errors } = this.state;
    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(
          errorKey =>
            !_.isEmpty(errors[errorKey]) && (
              <li key={errorKey}>{errors[errorKey]}</li>
            )
        )}
      />
    );
  };

  showSuccess = () => {
    const { showSuccess } = this.state;

    if (!showSuccess) return;

    // Hide success message after specified time.
    setTimeout(() => {
      this.setState({ showSuccess: false });
    }, 2000);

    return (
      <Message
        positive
        icon="check"
        header="Success!"
        content="Data saved successfully."
      />
    );
  };

  render() {
    return <div>{this.renderModal()}</div>;
  }
}

TaskCreateForm.propTypes = {
  addTask: PropTypes.func.isRequired,
};

TaskCreateForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(null, { addTask })(TaskCreateForm);
