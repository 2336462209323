import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Segment } from 'semantic-ui-react';

// Import actions.
import { fetchSurveyGuides } from '../../../../actions/fy19/reviewActions';

import AmsFormLabel from '../../../../utils/AmsFormLabel';

const SurveySubmissionsFilters = ({
  fetchSurveyGuides,
  guides,
  reviewId,
  reviewType,
  isNewForms,
  fiscalYear,
  onFilterClicked,
  router,
}) => {
  const [guideOptions, setGuideOptions] = useState([]);
  const [reviewStatusOptions] = useState([
    {
      key: 'Not Started',
      text: 'Not Started',
      value: 'Not Started',
    },
    {
      key: 'In Progress',
      text: 'In Progress',
      value: 'In Progress',
    },
    {
      key: 'Completed',
      text: 'Completed',
      value: 'Completed',
    },
  ]);
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [guideLoading, setGuideLoading] = useState(false);
  const [guidesLoaded, setGuidesLoaded] = useState(false);

  const filterSegment = useRef(null);
  const newFormReviewTypes = ['RAN', 'Special'];

  useEffect(() => {
    if (_.isEmpty(guides)) {
      if (
        (reviewType !== 'RAN' ||
          (newFormReviewTypes.includes(reviewType) && isNewForms)) &&
        reviewType !== 'Follow-up'
      ) {
        if (reviewId && reviewType && _.isEmpty(guides)) {
          loadGuidesData();
        }
      }
    }

    if (_.isEmpty(guideOptions)) {
      const guideOptionsUpdate = !_.isEmpty(guides)
        ? guides.map(guide => ({
            key: guide.name,
            text: guide.name,
            value: guide.name,
          }))
        : [];

      setGuideOptions(guideOptionsUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guides]);

  const scrollToRef = () => {
    window.scrollTo(0, filterSegment.current.offsetTop);
    filterSegment.current.focus();
  };

  const loadGuidesData = () => {
    if ((!reviewId && !reviewType) || reviewType === 'Follow-up') return;
    let requestInput = { reviewId, reviewType, fiscalYear };
    let willFetch = false;
    if (reviewId === router?.params?.reviewId) {
      willFetch = true;
    }
    setName('');
    setGuideLoading(true);
    setGuidesLoaded(willFetch);

    fetchSurveyGuides(requestInput)
      .then(() => setGuideLoading(false))
      .catch(error => {
        setGuideLoading(false);
      });
  };

  const handleGuideNameOnChange = (e, { name, value }) => {
    //setName(name);
    setName(value);
  };

  const handleOnChange = (e, { name, value }) => {
    setStatus(value);
  };

  const resetFilters = e => {
    if (e) e.preventDefault();
    setName('');
    setStatus('');
    setGuideLoading(false);
    onFilterClicked({});
    //loadGuidesData();
    scrollToRef();
  };

  const passFilters = () => {
    onFilterClicked({ name, status });
  };

  const renderGuides = () => {
    return (
      <AmsFormLabel name={'Guide'} fieldLabel="filterGuideName">
        <Form.Dropdown
          aria-labelledby="filterGuideName"
          name={'name'}
          placeholder={'Select Guide'}
          selection
          searchInput={{ id: 'filterGuideName' }}
          search
          fluid
          loading={guideLoading}
          selectOnBlur={false}
          value={name}
          options={guideOptions}
          onChange={handleGuideNameOnChange}
        />
      </AmsFormLabel>
    );
  };

  const renderReviewStatus = () => {
    return (
      <AmsFormLabel name="Status" fieldLabel="filterStatus">
        <Form.Dropdown
          name="status"
          aria-labelledby="filterStatus"
          searchInput={{ id: 'filterStatus' }}
          placeholder="Select Status"
          selection
          search
          fluid
          selectOnBlur={false}
          value={status}
          options={reviewStatusOptions}
          onChange={handleOnChange}
        />
      </AmsFormLabel>
    );
  };

  if (
    (newFormReviewTypes.includes(reviewType) && !isNewForms) ||
    reviewType === 'Follow-up'
  )
    return null;

  return (
    <div ref={filterSegment} tabIndex="-1">
      <Segment>
        <h2>Filters</h2>
        <Form className="review-survey-form">
          <Form.Group widths="equal">
            <Form.Field width={5}>{renderGuides()}</Form.Field>
            <Form.Field width={5}>{renderReviewStatus()}</Form.Field>
            <Form.Group inline className="review-survey-filter-buttons-wrapper">
              <Form.Button
                className="reviewDetails-primary-button"
                primary
                content="Filter"
                onClick={passFilters}
              />
              <Form.Button
                className="reviewDetails-secondary-button"
                basic
                content={'Clear'}
                onClick={resetFilters}
              />
            </Form.Group>
          </Form.Group>
        </Form>
      </Segment>
    </div>
  );
};

SurveySubmissionsFilters.propTypes = {
  fetchSurveyGuides: PropTypes.func.isRequired,
  guides: PropTypes.array.isRequired,
  reviewId: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  fiscalYear: PropTypes.string.isRequired,
  onFilterClicked: PropTypes.func.isRequired,
  isNewForms: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  reviewId: state.review.selectedReview.reviewId,
  fiscalYear: state.review.selectedReview.fiscalYear,
  reviewType: state.review.selectedReview.reviewType,
  guides: state.review.selectedReview.guides,
  isNewForms: state.review.selectedReview.isNewForms,
});

export default connect(mapStateToProps, {
  fetchSurveyGuides,
})(SurveySubmissionsFilters);
