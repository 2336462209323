import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Header, Button } from 'semantic-ui-react';

class GranteeCalendarCloseModal extends Component {
  state = {
    showCloseModal: false,
  };

  handleShowModal = () => {
    const { finalUpdate } = this.props;
    if (finalUpdate) {
      this.props.closeAction();
    } else {
      this.setState({ showCloseModal: true });
    }
  };

  handleCancelCloseAction = () => {
    this.setState({ showCloseModal: false });
  };

  handleContinueCloseAction = () => {
    this.setState({ showCloseModal: false });
    this.props.closeAction();
  };

  render() {
    const { showCloseModal } = this.state;
    return (
      <div>
        <Button color="red" onClick={this.handleShowModal}>
          Cancel
        </Button>
        <Modal show={showCloseModal} onHide={this.handleCancelCloseAction}>
          <Modal.Header closeButton>
            <Modal.Title>Close without Saving?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Header as="h3">
              If you cancel your changes will not be saved. Are you sure you
              want to cancel?
            </Header>
          </Modal.Body>
          <Modal.Footer>
            <Button color="green" onClick={this.handleContinueCloseAction}>
              Continue
            </Button>
            <Button color="red" onClick={this.handleCancelCloseAction}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(GranteeCalendarCloseModal);
