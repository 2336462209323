import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import { Menu } from 'semantic-ui-react';

export default function GranteeUnavialbility() {
  const { grantee, granteeDetails } = useSelector(state => state);

  if (grantee.granteeId || granteeDetails.granteeId)
    return (
      <Menu.Item
        as={Link}
        to={`/grantee/${grantee.granteeId ||
          granteeDetails.granteeId}/availability`}
      >
        Grantee Unavailability
      </Menu.Item>
    );
  else return null;
}
