import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Radio } from 'semantic-ui-react';

class SchedulerViewGranteeAvailabityRadioButton extends Component {
  handleClick = () => {
    const { handleMonthClick, month } = this.props;
    handleMonthClick(month);
  };

  render() {
    const { monthWasEdited, currentlySelectedMonth, month } = this.props;
    return (
      <Form.Field
        role="group"
        width={3}
        label={{
          children: monthWasEdited ? (
            <label>
              {month} <span className="fa fa-check" />
            </label>
          ) : (
            month
          ),
          htmlFor: `${month}`,
        }}
        name="selectedStateGroup"
        value={month}
        className="radio-toolbar"
        checked={currentlySelectedMonth === month}
        onChange={this.handleClick}
        control={Radio}
        id={`${month}`}
        aria-labelledby={`${month}`}
      ></Form.Field>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(SchedulerViewGranteeAvailabityRadioButton);
