import _, { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

//Import components
import AmsFormLabel from '../utils/AmsFormLabel';

//Import actions
import {
  filterGrantees,
  granteeFilterCleared,
} from '../actions/granteeActions';

class GranteeListDropdown extends Component {
  state = {
    list: [],
    selectedValue: !this.props.value
      ? this.props.multiple
        ? []
        : ''
      : this.props.value,
    loading: false,
  };

  componentDidMount() {
    const { value, filteredData, status } = this.props;

    // Used to preload selections.
    if (!_.isEmpty(value) && _.isEmpty(filteredData)) {
      this.setState({ loading: true });
      if (isArray(value))
        value.forEach(id => {
          this.props
            .filterGrantees(id, status)
            .then(() => this.setState({ loading: false }))
            .catch(error => {
              this.setState({ loading: false });
              this.props.onError(error);
            });
        });
      else {
        this.props
          .filterGrantees(value, status)
          .then(() => this.setState({ loading: false }))
          .catch(error => {
            this.setState({ loading: false });
            this.props.onError(error);
          });
      }
    }
  }

  componentWillUnmount() {
    this.props.granteeFilterCleared();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value && !nextProps.value) {
      this.props.granteeFilterCleared();
    } else {
      const { value, filteredData } = nextProps;

      let list =
        this.props.calledFrom && this.props.calledFrom === 'createReview'
          ? _.filter(filteredData, (item, index) => {
              return item;
            }).map((item, index) => ({
              key: item.granteeId + index,
              value: item.granteeId,
              text: item.active
                ? `${item.granteeName} (${item.granteeId})`
                : `${item.granteeName} (${item.granteeId}) - Inactive`,
            }))
          : filteredData.map((item, index) => ({
              key: item.granteeId + index,
              value: item.granteeId,
              text: item.active
                ? `${item.granteeName} (${item.granteeId})`
                : `${item.granteeName} (${item.granteeId}) - Inactive`,
            }));

      this.setState({ list, selectedValue: value });
    }
  }

  onChange = (e, { value }) => {
    this.setState({ filterQuery: value });
    this.props.onValueSelected(value);
  };

  onSearchChange = (e, data) => {
    clearTimeout(this.timer);
    this.setState({
      filterQuery: data,
    });
    this.timer = setTimeout(this.filterList, 500);
  };

  filterList = () => {
    const status = this.props.status || ['active', 'inactive'];

    if (
      !this.state.filterQuery.searchQuery.trim() ||
      this.state.filterQuery.searchQuery.trim().length < 3
    )
      return;

    this.setState({ loading: true });

    this.props
      .filterGrantees(this.state.filterQuery.searchQuery.trim(), status)
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({ loading: false });
        this.props.onError(error);
      });
  };

  render() {
    const { selectedValue, list } = this.state;
    const {
      multiple,
      search,
      selection,
      placeholder,
      dropdownLabel,
      required,
      tabIndex,
    } = this.props;

    return (
      <>
        <AmsFormLabel
          name={dropdownLabel ? dropdownLabel : 'Grantee'}
          helpText={true}
          fieldLabel="filterGrantees"
        >
          <Dropdown
            required={!!required}
            fluid
            multiple={multiple}
            search={search}
            selection={selection}
            placeholder={placeholder || 'Type Grantee Name or ID'}
            value={selectedValue}
            onSearchChange={this.onSearchChange}
            options={list}
            loading={this.state.loading}
            onChange={this.onChange}
            searchInput={{
              id: 'filterGrantees',
              title: 'Select Grantee',
            }}
            aria-labelledby="filterGrantees"
            selectOnBlur={false}
            openOnFocus={false}
            tabIndex={tabIndex}
          />
        </AmsFormLabel>
      </>
    );
  }
}

GranteeListDropdown.propTypes = {
  filteredData: PropTypes.array.isRequired,
  filterGrantees: PropTypes.func.isRequired,
  granteeFilterCleared: PropTypes.func.isRequired,
  onValueSelected: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  filteredData: state.granteeFilter,
});

export default connect(mapStateToProps, {
  filterGrantees,
  granteeFilterCleared,
})(GranteeListDropdown);
