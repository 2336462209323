import React from 'react';
import { Button, Header, Icon, Modal, Progress } from 'semantic-ui-react';
import AmsModal from './AmsModal';

const AmsAlert = ({
  show,
  title,
  type = 'info',
  showConfirm = true,
  showCancelButton,
  showThirdButton = false,
  confirmButtonText = 'OK',
  confirmButtonColor,
  cancelButtonText = 'Cancel',
  cancelButtonColor,
  thirdButtonText = '',
  thirdButtonColor,
  text,
  subtext = '',
  onConfirm = true,
  onCancel,
  onThirdButtonAction,
  html,
  progress = null,
  showProgress = false,
  closeIcon = false,
  errorText = null,
  closeOnDimmerClick = false,
  disableCancelButton = false,
  disableConfirmButton = false,
}) => {
  const style = {
    content: { textAlign: 'center' },
    iconGroup: { display: 'block', marginBottom: '10px' },
    buttonGroup: { display: 'block', marginTop: '20px' },
  };

  const renderTypeIcon = () => {
    let color;
    let icon;

    switch (type) {
      case 'warning':
        color = 'yellow';
        icon = 'warning';
        break;
      case 'error':
        color = 'red';
        icon = 'x';
        break;
      case 'success':
        color = 'green';
        icon = 'check';
        break;
      default:
        color = 'blue';
        icon = 'info';
        break;
    }

    return (
      <Icon.Group size="big" style={style.iconGroup}>
        <Icon size="big" name="circle outline" color={color} />
        <Icon name={icon} color={color} />
      </Icon.Group>
    );
  };

  return (
    <AmsModal
      closeIcon={closeIcon}
      role="alertdialog"
      size="tiny"
      className="ams-semantic-modal-fix"
      open={show}
      onClose={onCancel}
      closeOnDimmerClick={closeOnDimmerClick}
    >
      <Modal.Content style={style.content}>
        {' '}
        <Header>
          {renderTypeIcon()}
          {title}
        </Header>
        <p style={style.text}>{text}</p>
        {subtext !== '' && (
          <>
            <br />
            <p>{subtext}</p>
          </>
        )}
        {errorText !== null ? errorText : null}
        {showProgress && (
          <Progress className="eas-progress-bar" percent={progress} />
        )}
        <div style={style.buttonGroup}>
          {showCancelButton && (
            <Button disabled={disableCancelButton} onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )}
          {showConfirm && (
            <Button disabled={disableConfirmButton} primary onClick={onConfirm}>
              {confirmButtonText}
            </Button>
          )}
          {showThirdButton && (
            <Button negative onClick={onThirdButtonAction}>
              {thirdButtonText}
            </Button>
          )}
        </div>
      </Modal.Content>
    </AmsModal>
  );
};
export default AmsAlert;
