import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//
import {
  fa2GuideSelected,
  fa2PerformanceMeasureSelected,
  fetchCenterClassroomFindings,
  fetchFA2FormDetail,
} from '../../../actions/fa2Actions';
import { filter, isEmpty } from 'lodash';
import ShowErrors from '../../../utils/ShowErrors';
import {
  Segment,
  Grid,
  Button,
  Form as SemanticForm,
  Header,
  Popup,
  Label,
  Message,
  List,
} from 'semantic-ui-react';
//import Form from '@rjsf/core';
import Form from '@rjsf/core';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';
import AttachmentField from '../../Shared/FormHelpers/AttachmentField';
import BooleanTrueOnlyField from '../../Shared/FormHelpers/BooleanTrueOnlyField';
import ListField from '../../Shared/FormHelpers/ListField';
import { Link } from 'react-router';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { postFormSubmission } from '../../../actions/surveyActions';
import { fetchReviewOCISummary } from '../../../actions/fy19/reviewActions';
import OrderedListField from '../../Shared/FormHelpers/OrderedListField';
import DefinitionsListField from '../../Shared/FormHelpers/DefinitionsListField';

export default function FA2Form(props) {
  const dispatch = useDispatch();
  const {
    selectedPerformanceMeasure,
    selectedGuide,
    isReportFinalized,
    reviewStatus,
    readOnly,
    isReviewAccessible,
    submission,
    selectedCenter,
    selectedClassroom,
    reviewId,
    generalInfo,
    centerClassroomFindings,
  } = useSelector(state => state.forms.fa2);
  const { ociSummaryDetails } = useSelector(
    state => state.review.selectedReview
  );
  const [selectedPM, setSelectedPM] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [showFindings, setShowFindings] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [draftButtonClicked, setDraftButtonClicked] = useState(false);
  const [alertData, setAlertData] = useState({
    showAlert: false,
    alertType: '',
    alertMessage: '',
  });
  const [ociSummaryDetailsObject, setOciSummaryDetailsObject] = useState({});

  // useEffect to set selectedPerformanceMeasure
  useEffect(() => {
    if (!isEmpty(selectedPerformanceMeasure)) {
      setSelectedPM(selectedPerformanceMeasure);
    }
  }, [selectedPerformanceMeasure]);

  // useEffect to fetch ociSummaryDetails
  useEffect(() => {
    setEditButtonClicked(false);
    if (selectedPerformanceMeasure.name === 'Content Area Analysis') {
      dispatch(
        fetchReviewOCISummary({
          reviewId: reviewId,
          reviewType: 'FA-2',
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPM.name]);

  // useEffect to set form loading process when form schema is fetched
  useEffect(() => {
    setFormProcessing(false);
  }, [selectedPM.formSchema]);

  // useEffect to set form state
  useEffect(() => {
    if (submission && !isEmpty(submission)) {
      setFormDisabled(!isEmpty(submission));
    } else if (
      selectedGuide.guideCode === 'CTR' &&
      (selectedCenter === '' || isEmpty(selectedCenter)) &&
      selectedPerformanceMeasure.hasCenters === true
    ) {
      setFormDisabled(true);
    } else if (
      (selectedPerformanceMeasure.name ===
        'Safety Practices (Center Exploration)' ||
        selectedPerformanceMeasure.name ===
          'Classroom Exploration (Effective and Intentional Teaching Practices)') &&
      selectedPerformanceMeasure.centers === null
    )
      setFormDisabled(true);
    else if (selectedGuide.guideCode === 'CLS') {
      // Disable form if the payload has centers flagged enabled but no
      // classrooms selected
      if (
        selectedPerformanceMeasure.hasCenters === true &&
        selectedClassroom === ''
      )
        setFormDisabled(true);
      // Enable form if the payload has no centers flagged enabled and the forms
      // are not empty
      else if (
        selectedPerformanceMeasure.hasCenters === false &&
        !isEmpty(selectedPerformanceMeasure.forms)
      )
        setFormDisabled(false);
      else if (
        selectedPerformanceMeasure.hasCenters === true &&
        selectedClassroom
      )
        setFormDisabled(false);
    } else {
      setFormDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission]);

  // useEffect to set ociSummaryDetailsObject
  useEffect(() => {
    if (ociSummaryDetails) {
      setOciSummaryDetailsObject(
        convertArrayToObject(ociSummaryDetails.data, 'name')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ociSummaryDetails && ociSummaryDetails.data)]);

  /**
   * Convert array to object for ociSummaryDetails
   * @param {array} array
   * @param {string} key
   * @returns {object}
   */
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: Array.isArray(item.performanceMeasure)
          ? convertArrayToObject(item.performanceMeasure, 'name')
          : item.rating,
      };
    }, initialValue);
  };

  /**
   * Customize the form schema with custom descriptions and helpers
   * @param {string} id
   * @param {array | string} description
   * @returns
   */
  const customDescriptionField = ({ id, description }) => {
    // IF there is no description, hide the link
    const contentAreaAnalysisQsList = [
      'contentAreaQ1Rating',
      'contentAreaQ2Rating',
      'contentAreaQ3Rating',
      'contentAreaQ4Rating',
      'contentAreaQ5Rating',
      'contentAreaQ6Rating',
    ];

    const descriptionTextList = [
      'i. Education (e.g., high school degree, college classes):',
      'i. How many staff does the program employ?',
      'i. Does the program have a process for verifying the vaccination status of staff and for reviewing and granting exemptions (e.g., medical conditions, sincerely held religious beliefs) for the COVID-19 vaccine?',
      'a. Total number of staff in the program',
      'i. Does the recipient have families enrolled that do not meet the categorical eligibility requirements?',
      'i. For programs operating for fewer than 90 days, does the grantee aggregate and analyze child-level assessment at least two times a year?',
      'i. For programs operating for fewer than 90 days, does the grant recipient aggregate and analyze child-level assessment at least two times a year?',
      'i. Does the grant recipient have written documentation (certificate) that children are not exposed to lead in paint in this facility?',
      'i. Does the grant recipient have written documentation (e.g., lead assessment results, certificate) that children are not exposed to lead in paint in this facility?',
    ];

    const webSiteTextList = ['ECLKC Active Supervision Resource'];

    if (
      isEmpty(description) ||
      (description &&
        Array.isArray(description) &&
        isEmpty(description[0].text))
    )
      return null;

    return description && !Array.isArray(description) ? (
      contentAreaAnalysisQsList.includes(description) ? (
        <Message>
          {getPerformanceMeasureRatingsWithQuestionType(description)}
        </Message>
      ) : (
        <legend>{description}</legend>
      )
    ) : descriptionTextList.includes(description[0].text) ? (
      (description[0].text === descriptionTextList[5] ||
        description[0].text === descriptionTextList[6]) &&
      description[1] &&
      description[1].citationNumber ? (
        <Message className="education-text-label">
          {`${description[0].text}*`}
          <Link
            to={{
              pathname: `/review/citations/definition`,
              query: { citation: description[1].citationNumber },
            }}
            target="_blank"
          >
            <Popup
              trigger={
                <Label
                  style={{
                    color: 'blue',
                    background: 'white',
                    textDecorationLine: 'underline',
                  }}
                >
                  {description[1] ? description[1].citationNumber : ''}
                </Label>
              }
              content="Select to see Citation Definition"
            />
          </Link>
        </Message>
      ) : (
        <Message className="education-text-label">{`${description[0].text}*`}</Message>
      )
    ) : (
      <span>
        {description.map((d, i) => [
          i > 0 && '; ',
          webSiteTextList.includes(d.citationNumber) ? (
            <Link
              to={{
                pathname: `https://eclkc.ohs.acf.hhs.gov/safety-practices/article/active-supervision`,
                query: { citation: d.citationNumber },
              }}
              target="_blank"
            >
              <Popup
                trigger={
                  <Label
                    style={{
                      color: 'blue',
                      background: 'white',
                      textDecorationLine: 'underline',
                    }}
                  >
                    {d ? d.citationNumber : ''}
                  </Label>
                }
                content="Goto Website"
              />
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/review/citations/definition`,
                query: { citation: d.citationNumber },
              }}
              target="_blank"
            >
              <Popup
                trigger={
                  <Label
                    style={{
                      color: 'blue',
                      background: 'white',
                      textDecorationLine: 'underline',
                    }}
                  >
                    {d ? d.citationNumber : ''}
                  </Label>
                }
                content="Select to see Citation Definition"
              />
            </Link>
          ),
        ])}
      </span>
    );
  };

  /**
   * Set the custom fields to use for the form schema
   * @returns {Object}
   */
  const fields = () => {
    return {
      DescriptionField: customDescriptionField,
      listField: ListField,
      orderedListField: OrderedListField,
      definitionsListField: DefinitionsListField,
      attachmentField: AttachmentField,
      booleanTrueOnlyField: BooleanTrueOnlyField,
    };
  };

  /**
   * Submit the form data
   * @returns {void}
   */
  const handleSubmit = () => {
    if (!props.amsFormId || !reviewId) return;

    let requestObject = {
      reviewId,
      amsFormId: props.amsFormId,
      surveyData: props.formData,
    };

    if (props.formData.topQuestion === false) {
      requestObject = {
        reviewId,
        amsFormId: props.amsFormId,
        surveyData: { topQuestion: false },
      };
    }

    if (props.formData.aianQuestion === false) {
      requestObject = {
        reviewId,
        amsFormId: props.amsFormId,
        surveyData: { aianQuestion: false },
      };
    }

    if (submission.amsSubmissionId)
      requestObject.amsSubmissionId = submission.amsSubmissionId;

    if (selectedCenter) requestObject.centerName = selectedCenter;

    if (selectedClassroom) requestObject.classSampleId = selectedClassroom;

    setFormProcessing(true);

    // draft
    if (draftButtonClicked) {
      dispatch(
        postFormSubmission({
          data: { ...requestObject, submissionStatus: 'Draft' },
        })
      )
        .then(response => {
          setDraftButtonClicked(false);
          setErrors({});
          setAlertData({
            showAlert: true,
            alertType: 'success',
            alertMessage: 'Draft saved successfully',
          });
          setEditButtonClicked(false);
          props.dataHasChangedSwitch(false);
        })
        .catch(error => {
          setErrors({ ...errors, fa2SaveDraft: error.message });
          setFormProcessing(false);
          setDraftButtonClicked(false);
        });
    } else {
      // update
      if (
        !draftButtonClicked &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'submitted'
      ) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(response => {
            setErrors({});
            setAlertData({
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Updated successfully',
            });
            props.dataHasChangedSwitch(false);
            setEditButtonClicked(false);
          })
          .catch(error => {
            setErrors({ ...errors, fa2SaveDraft: error.message });
            setFormProcessing(false);
          });
        return; // End of update exit.
      }

      // new
      if (!draftButtonClicked) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(response => {
            setErrors({});
            setAlertData({
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Updated successfully',
            });
            props.dataHasChangedSwitch(false);
            setEditButtonClicked(false);
          })
          .catch(error => {
            setErrors({ ...errors, fa2SaveDraft: error.message });
            setFormProcessing(false);
          });
      }
    }
  };

  const handleError = e => {};

  /**
   * Custom validation for oci summary answers
   * @param {string} optionSelected
   * @param {string} question
   * @returns {boolean}
   */
  const isInValidOptionSelection = (optionSelected, question) => {
    const { fiscalYear } = generalInfo;
    let ociSummaryDetailsObj = ociSummaryDetailsObject[question];
    if (
      question ===
      'Monitoring and Implementing Quality Education and Child Development Services'
    ) {
      ociSummaryDetailsObj = Object.assign(
        ociSummaryDetailsObject[question],
        ociSummaryDetailsObject[
          'Classroom Exploration - Effective and Intentional Teaching Practices'
        ]
      );
    } else if (
      2022 === fiscalYear &&
      question === 'Monitoring and Implementing Quality Health Services'
    ) {
      ociSummaryDetailsObj = Object.assign(
        ociSummaryDetailsObject[question],
        ociSummaryDetailsObject['Center Exploration']
      );
    }
    switch (optionSelected) {
      case 'op1':
        if (
          !Object.values(ociSummaryDetailsObj)
            .filter(v => 'N/A' !== v)
            .some(v => v === '1' || v === '2' || v === '3')
        ) {
          return true;
        }
        break;
      case 'op2':
        if (
          Object.values(ociSummaryDetailsObj)
            .filter(v => 'N/A' !== v)
            .some(v => v !== '4' && v !== '5') ||
          Object.values(ociSummaryDetailsObj)
            .filter(v => 'N/A' !== v)
            .every(v => v === '5')
        ) {
          return true;
        }
        break;
      case 'op3':
        if (
          !Object.values(ociSummaryDetailsObj)
            .filter(v => 'N/A' !== v)
            .every(v => v === '5')
        ) {
          return true;
        }
        break;
      default:
        return false;
    }
  };

  /**
   * Custom Form validation associated with form schema submission
   * @param {Object} data
   * @param {Object} errors
   * @returns {Object}
   */
  const validate = (data, errors) => {
    const {
      question1YesNO,
      question2YesNO,
      question3YesNO,
      question4YesNO,
      question5YesNO,
      question6YesNO,
    } = data;

    if (draftButtonClicked) {
      return {};
    }
    question1YesNO &&
      isInValidOptionSelection(
        question1YesNO,
        'Program Management and Quality Improvement'
      ) &&
      errors.question1YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question2YesNO &&
      isInValidOptionSelection(
        question2YesNO,
        'Monitoring and Implementing Quality Education and Child Development Services'
      ) &&
      errors.question2YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question3YesNO &&
      isInValidOptionSelection(
        question3YesNO,
        'Monitoring and Implementing Quality Health Services'
      ) &&
      errors.question3YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question4YesNO &&
      isInValidOptionSelection(
        question4YesNO,
        'Monitoring and Implementing Quality Family and Community Engagement Services'
      ) &&
      errors.question4YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question5YesNO &&
      isInValidOptionSelection(
        question5YesNO,
        'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
      ) &&
      errors.question5YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question6YesNO &&
      isInValidOptionSelection(
        question6YesNO,
        'Monitoring and Implementing Fiscal Infrastructure'
      ) &&
      errors.question6YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    return errors;
  };

  /**
   * Transform form errors to custom error descriptions
   * @param {Object} errors
   * @returns {Object}
   */
  const transformErrors = errors => {
    if (draftButtonClicked) {
      return [];
    }
    return filter(errors, error => {
      if (error.name === 'maxItems') {
        error.message = 'This field ' + error.message + ' selected.';
      }
      if (error.name === 'required')
        error.message = 'This is a required field.';
      if (error.params.type === 'integer')
        error.message =
          'This field only accepts whole numbers. Please enter a whole number.';
      if (error.name === 'minimum')
        error.message =
          'This field only accept values greater than 0. Please enter a number greater than 0.';
      if (error.name === 'minItems')
        error.message = 'This is a required field.';
      if (error.message === 'should be string')
        error.message = 'Please add at least one file.';
      return (
        error.name === 'required' ||
        error.name === 'const' ||
        error.name === 'minimum' ||
        error.name === 'minItems' ||
        error.name === 'maxItems' ||
        error.message === 'Please add at least one file.' ||
        error.params.type === 'integer'
      );
    });
  };

  /**
   * Set up the custom form schema description associated with oci summary results
   * @param {string} description
   * @returns {string}
   */
  const getPerformanceMeasureRatingsWithQuestionType = description => {
    const { fiscalYear } = generalInfo;
    switch (description) {
      case 'contentAreaQ1Rating':
        const Q1_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Program Management and Quality Improvement'
          ] &&
          ociSummaryDetailsObject['Program Management and Quality Improvement'][
            'Program Management (Management Team Discussion)'
          ];
        const Q1_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Program Management and Quality Improvement'
          ] &&
          ociSummaryDetailsObject['Program Management and Quality Improvement'][
            'Ongoing Monitoring and Continuous Improvement (Management Team Discussion)'
          ];
        const Q1_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Program Management and Quality Improvement'
          ] &&
          ociSummaryDetailsObject['Program Management and Quality Improvement'][
            'Program Governance (Governing Body Discussion)'
          ];
        const Q1_PM4_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Program Management and Quality Improvement'
          ] &&
          ociSummaryDetailsObject['Program Management and Quality Improvement'][
            'Program Governance (Policy Council Discussion)'
          ];
        return ` Performance Measure Ratings (${
          Q1_PM1_RATING && 'N/A' !== Q1_PM1_RATING
            ? `PM 1 = ${Q1_PM1_RATING}, `
            : ''
        }
        ${
          Q1_PM2_RATING && 'N/A' !== Q1_PM2_RATING
            ? `PM 2 = ${Q1_PM2_RATING}, `
            : ''
        }  
        ${
          Q1_PM3_RATING && 'N/A' !== Q1_PM3_RATING
            ? `PM 3 = ${Q1_PM3_RATING}, `
            : ''
        } 
        ${
          Q1_PM4_RATING && 'N/A' !== Q1_PM4_RATING
            ? `PM 4 = ${Q1_PM4_RATING}`
            : ''
        })`;
      case 'contentAreaQ2Rating':
        const Q2_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Education and Child Development Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Education and Child Development Services'
          ]['Alignment with School Readiness (ECD Data Tour)'];
        const Q2_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Classroom Exploration - Effective and Intentional Teaching Practices'
          ] &&
          ociSummaryDetailsObject[
            'Classroom Exploration - Effective and Intentional Teaching Practices'
          ][
            'Effective and Intentional Teaching Practices (Performance Assessment)'
          ];

        const Q2_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Education and Child Development Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Education and Child Development Services'
          ][
            'Supporting Teachers in Promoting School Readiness (ECD Data Tour)'
          ];
        const Q2_PM4_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Education and Child Development Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Education and Child Development Services'
          ]['Home-Based Program Services (Home-based Discussion)'];

        return ` Performance Measure Ratings (${
          Q2_PM1_RATING && 'N/A' !== Q2_PM1_RATING
            ? `PM 1 = ${Q2_PM1_RATING}, `
            : ''
        }
        ${
          Q2_PM2_RATING && 'N/A' !== Q2_PM2_RATING
            ? `PM 2 = ${Q2_PM2_RATING}, `
            : ''
        } 
        ${
          Q2_PM3_RATING && 'N/A' !== Q2_PM3_RATING
            ? `PM 3 = ${Q2_PM3_RATING}, `
            : ''
        }  
        ${
          Q2_PM4_RATING && 'N/A' !== Q2_PM4_RATING
            ? `PM 4 = ${Q2_PM4_RATING}`
            : ''
        })`;
      case 'contentAreaQ3Rating':
        const Q3_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Health Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Health Services'
          ]['Child Health Status and Care (Health Data Tour)'];
        const Q3_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Health Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Health Services'
          ]['Mental Health (Health Data Tour)'];
        const Q3_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Health Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Health Services'
          ]['Oral Health and Nutrition (Health Data Tour)'];
        const Q3_PM4_RATING =
          ociSummaryDetailsObject &&
          (fiscalYear === 2022
            ? ociSummaryDetailsObject['Center Exploration'] &&
              ociSummaryDetailsObject['Center Exploration'][
                'Safety Practices (Performance Assessment)'
              ]
            : ociSummaryDetailsObject[
                'Monitoring and Implementing Quality Health Services'
              ] &&
              ociSummaryDetailsObject[
                'Monitoring and Implementing Quality Health Services'
              ]['Safety Practices (Health Data Tour)']);
        const Q3_PM5_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Health Services'
          ] &&
          (fiscalYear === 2022
            ? ociSummaryDetailsObject[
                'Monitoring and Implementing Quality Health Services'
              ]['Services to Expectant Families (Health Data Tour)']
            : ociSummaryDetailsObject[
                'Monitoring and Implementing Quality Health Services'
              ]['Services to Pregnant Women (Health Data Tour)']);
        return ` Performance Measure Ratings (${
          Q3_PM1_RATING && 'N/A' !== Q3_PM1_RATING
            ? `PM 1 = ${Q3_PM1_RATING}, `
            : ''
        }
          ${
            Q3_PM2_RATING && 'N/A' !== Q3_PM2_RATING
              ? `PM 2 = ${Q3_PM2_RATING}, `
              : ''
          }  
          ${
            Q3_PM3_RATING && 'N/A' !== Q3_PM3_RATING
              ? `PM 3 = ${Q3_PM3_RATING}, `
              : ''
          } 
          ${
            Q3_PM4_RATING && 'N/A' !== Q3_PM4_RATING
              ? `PM 4 = ${Q3_PM4_RATING}, `
              : ''
          } 
          ${
            Q3_PM5_RATING && 'N/A' !== Q3_PM5_RATING
              ? `PM 5 = ${Q3_PM5_RATING}`
              : ''
          })`;
      case 'contentAreaQ4Rating':
        const Q4_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Family and Community Engagement Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Family and Community Engagement Services'
          ]['Family Well-Being (FCE Data Tour)'];
        const Q4_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Family and Community Engagement Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Family and Community Engagement Services'
          ][
            'Strengthening Parenting and Parent-Child Supports (FCE Data Tour)'
          ];
        const Q4_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Family and Community Engagement Services'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Quality Family and Community Engagement Services'
          ][
            'Family Engagement in Education and Child Development Services (Policy Council/ Parent Group Discussion)'
          ];
        return ` Performance Measure Ratings (${
          Q4_PM1_RATING && 'N/A' !== Q4_PM1_RATING
            ? `PM 1 = ${Q4_PM1_RATING}, `
            : ''
        }
          ${
            Q4_PM2_RATING && 'N/A' !== Q4_PM2_RATING
              ? `PM 2 = ${Q4_PM2_RATING}, `
              : ''
          }  
          ${
            Q4_PM3_RATING && 'N/A' !== Q4_PM3_RATING
              ? `PM 3 = ${Q4_PM3_RATING}`
              : ''
          })`;
      case 'contentAreaQ5Rating':
        const Q5_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
          ]['Determining, Verifying, and Documenting Eligibility PM 1'];

        const Q5_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
          ]['Determining, Verifying, and Documenting Eligibility PM 2'];

        const Q5_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
          ]['Enrollment Verification'];

        return ` Performance Measure Ratings (${
          Q5_PM1_RATING && 'N/A' !== Q5_PM1_RATING
            ? `PM 1 = ${Q5_PM1_RATING}, `
            : ''
        }
        ${
          Q5_PM2_RATING && 'N/A' !== Q5_PM2_RATING
            ? `PM 2 = ${Q5_PM2_RATING}, `
            : ''
        }  
        ${
          Q5_PM3_RATING && 'N/A' !== Q5_PM3_RATING
            ? `PM 3 = ${Q5_PM3_RATING}`
            : ''
        })`;

      case 'contentAreaQ6Rating':
        const Q6_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ]['Budget Planning and Development (Fiscal Data Tour)'];

        const Q6_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ]['Ongoing Fiscal Capacity (Fiscal Data Tour)'];

        const Q6_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ]['Budget Execution (Fiscal Data Tour)'];

        const Q6_PM4_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Monitoring and Implementing Fiscal Infrastructure'
          ]['Facilities and Equipment (Fiscal Data Tour)'];

        return ` Performance Measure Ratings (${
          Q6_PM1_RATING && 'N/A' !== Q6_PM1_RATING
            ? `PM 1 = ${Q6_PM1_RATING}, `
            : ''
        }
        ${
          Q6_PM2_RATING && 'N/A' !== Q6_PM2_RATING
            ? `PM 2 = ${Q6_PM2_RATING}, `
            : ''
        }  
        ${
          Q6_PM3_RATING && 'N/A' !== Q6_PM3_RATING
            ? `PM 3 = ${Q6_PM3_RATING}, `
            : ''
        } 
        ${
          Q6_PM4_RATING && 'N/A' !== Q6_PM4_RATING
            ? `PM 4 = ${Q6_PM4_RATING}`
            : ''
        })`;
      default:
        break;
    }
  };

  const showEditButton = () => {
    if (readOnly) return;

    if (isReportFinalized) return;

    if (!isReviewAccessible) return;

    if (editButtonClicked) return;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (submission.editable) {
      return (
        <Button
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            setEditButtonClicked(true);
            setFormDisabled(false);
          }}
          style={{ display: 'inline-block' }}
        />
      );
    }
  };

  const showSubmitButton = () => {
    if (readOnly) return null;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (!isReviewAccessible) return;

    if (reviewStatus.toLowerCase() === 'cancelled') return null;

    if (
      !submission.surveyStatus ||
      (props.formData && submission.surveyStatus && editButtonClicked)
    )
      return (
        <Button
          disabled={isEmpty(props.formData)}
          type="submit"
          primary
          content={'Submit'}
        />
      );
  };

  const showDraftButton = () => {
    if (isEmpty(selectedPM.formSchema)) return null;

    if (!isReviewAccessible) return;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (readOnly) {
      return null;
    }

    if (
      !submission.surveyStatus ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft' &&
        editButtonClicked)
    )
      return (
        <Button
          type="submit"
          content="Save as Draft"
          disabled={isEmpty(props.formData)}
          onClick={() => {
            setDraftButtonClicked(true);
          }}
        />
      );

    return null;
  };

  const showCancelButton = () => {
    if (readOnly) return null;

    if (
      !editButtonClicked ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() !== 'submitted')
    )
      return null;
    else
      return (
        <Button
          negative
          // size="large"
          content={'Cancel'}
          onClick={() => {
            props.handleCancelChange(
              submission && submission.data && submission.data.surveyData
            );
            setEditButtonClicked(false);
            setFormDisabled(true);
          }}
        />
      );
  };

  // Fetch center and classroom findings
  const getCenterClassroomFindings = () => {
    if (
      selectedGuide &&
      (selectedGuide.guideCode === 'CTR' || selectedGuide.guideCode === 'CLS')
    ) {
      let requestInput = {
        reviewId: reviewId,
        guideCode: selectedGuide.guideCode,
      };
      dispatch(fetchCenterClassroomFindings(requestInput));
    }
  };

  const showFindingsButton = () => {
    if (
      selectedPerformanceMeasure &&
      selectedPerformanceMeasure.name !==
        'Safety Practices (Performance Assessment)' &&
      selectedPerformanceMeasure.name !==
        'Effective and Intentional Teaching Practices (Performance Assessment)'
    ) {
      if (showFindings) {
        setShowFindings(false);
      }
      return null;
    } else {
      return (
        <SemanticForm.Button
          content={'Findings'}
          primary
          onClick={e => {
            e.preventDefault();
            if (!showFindings) {
              getCenterClassroomFindings();
              setShowFindings(!showFindings);
            } else {
              setShowFindings(!showFindings);
            }
          }}
        />
      );
    }
  };

  const showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = alertData;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          setAlertData({ showAlert: false, alertType: '', alertMessage: '' });
          dispatch(fetchFA2FormDetail({ reviewId })).then(() => {
            dispatch(fa2GuideSelected(selectedGuide));

            dispatch(fa2PerformanceMeasureSelected(selectedPerformanceMeasure));
            setShowFindings(false);
            window.scrollTo(0, 0);
          });
        }}
      />
    );
  };

  // Render survey submission status
  const renderSurveyStatus = () => {
    if (isEmpty(submission)) return null;

    if (!submission.amsSubmissionId) return null;

    const { editTime, editedBy } = submission.data;

    return (
      <Segment
        style={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #e3e3e3',
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
          padding: '20px',
          marginLeft: '1rem',
        }}
      >
        {editedBy && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submitted By"
            >
              Submitted By:{' '}
            </span>
            {editedBy}
          </div>
        )}
        {submission.surveyStatus && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:{' '}
            </span>
            {submission.surveyStatus}
          </div>
        )}
        {editTime && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Time"
            >
              Submission Time:{' '}
            </span>
            {AmsDateFormatters.formatDateTime(editTime)}
          </div>
        )}
      </Segment>
    );
  };

  // Render survey PM description
  const renderDescription = () => {
    if (!selectedPerformanceMeasure) return;

    const { description } = selectedPerformanceMeasure;

    if (description) return <Header as="h2">{description}</Header>;
  };

  // Build the center and classroom findings list and section
  const buildCenterClassroomFindings = () => {
    const renderList = (fieldName, label) => {
      return (
        fieldName && (
          <List.Description>{`${label}: ${fieldName} `}</List.Description>
        )
      );
    };

    const offsetHeight = document.getElementById('form-container').clientHeight;

    const renderGroupedList = () => {
      return (
        centerClassroomFindings &&
        centerClassroomFindings.length > 0 &&
        centerClassroomFindings.map((center, cindex) => {
          return (
            <div key={cindex}>
              <Header as="h3">{center.centerName}</Header>
              {center.classrooms && center.classrooms.length > 0 ? (
                center.classrooms.map((classroom, crIndex) => {
                  return (
                    <List key={crIndex} divided relaxed={'very'}>
                      <Header
                        as="h4"
                        basic
                      >{`Classroom: ${classroom.classroomName} `}</Header>
                      {classroom.narratives &&
                        classroom.narratives.length > 0 &&
                        classroom.narratives.map((narrative, index) => {
                          return (
                            <List.Item key={index}>
                              <List.Content>
                                {renderList(narrative.question, 'Question')}
                                {renderList(narrative.citation, 'Citation')}
                                {renderList(narrative.narrative, 'Narrative')}
                                {renderList(
                                  narrative.qualitativeNotes,
                                  'QualitativeNotes'
                                )}
                              </List.Content>
                            </List.Item>
                          );
                        })}
                    </List>
                  );
                })
              ) : (
                <List divided relaxed={'very'}>
                  {center.narratives &&
                    center.narratives.length > 0 &&
                    center.narratives.map((narrative, index) => {
                      return (
                        <List.Item key={index}>
                          <List.Content>
                            {renderList(narrative.question, 'Question')}
                            {renderList(narrative.citation, 'Citation')}
                            {renderList(narrative.narrative, 'Narrative')}
                            {renderList(
                              narrative.qualitativeNotes,
                              'QualitativeNotes'
                            )}
                          </List.Content>
                        </List.Item>
                      );
                    })}
                </List>
              )}
              <br />
            </div>
          );
        })
      );
    };

    if (isEmpty(centerClassroomFindings))
      return (
        <div>
          <Segment style={{ overflow: 'auto', height: `${offsetHeight} px` }}>
            <Header as="h2">Aggregate Findings</Header>
            <List divided relaxed={'very'}>
              <List.Item>
                <List.Content>No Findings</List.Content>
              </List.Item>
            </List>
          </Segment>
        </div>
      );

    if (
      selectedPerformanceMeasure &&
      selectedPerformanceMeasure.name !==
        'Safety Practices (Performance Assessment)' &&
      selectedPerformanceMeasure.name !==
        'Effective and Intentional Teaching Practices (Performance Assessment)'
    )
      return;

    return (
      <Segment style={{ overflow: 'auto', height: `${offsetHeight} px` }}>
        <Header as="h2">Aggregate Findings</Header>
        {renderGroupedList()}
      </Segment>
    );
  };

  return (
    <>
      <div className="row form-container">
        <ShowErrors errors={errors} />
        {showSuccessConfirmation()}
        <Segment basic loading={formProcessing}>
          {renderSurveyStatus()}
          {renderDescription()}

          <Grid columns={showFindings ? 2 : 1}>
            <Grid.Column style={{ width: showFindings ? '75%' : '100%' }}>
              <div className="text-right">
                <div style={{ display: 'inline-block' }}>
                  {showEditButton()}
                </div>
                <div style={{ display: 'inline-block' }}>
                  {showFindingsButton()}
                </div>
              </div>
              <fieldset
                id="form-container"
                disabled={
                  isReportFinalized ||
                  (reviewStatus &&
                    reviewStatus.toLowerCase() === 'cancelled') ||
                  readOnly ||
                  !isReviewAccessible
                    ? true
                    : formDisabled
                }
              >
                {!isEmpty(selectedPM.formSchema) &&
                  !isEmpty(selectedPM.uiSchema) && (
                    <Form
                      ArrayFieldTemplate={ArrayFieldTemplate}
                      key={props.amsFormId || ''}
                      schema={selectedPM.formSchema}
                      uiSchema={selectedPM.uiSchema}
                      formData={
                        (selectedPM.uiSchema &&
                          selectedPM.formSchema &&
                          props.formData) ||
                        {}
                      }
                      onSubmit={handleSubmit}
                      onChange={form => {
                        props.handleDataChange(form);
                      }}
                      onError={handleError}
                      validate={validate}
                      liveValidate={false}
                      transformErrors={transformErrors}
                      showErrorList={false}
                      noHtml5Validate
                      fields={fields()}
                    >
                      <div className="ui form">
                        {!isReportFinalized &&
                          reviewStatus &&
                          reviewStatus.toLowerCase() !== 'cancelled' && (
                            <SemanticForm.Group>
                              {showDraftButton()}
                              {showSubmitButton()}
                              {showCancelButton()}
                            </SemanticForm.Group>
                          )}
                      </div>
                    </Form>
                  )}
              </fieldset>
            </Grid.Column>
            {showFindings && (
              <Grid.Column style={{ width: '25%', padding: '0' }}>
                {buildCenterClassroomFindings()}
              </Grid.Column>
            )}
          </Grid>
          {showEditButton()}
        </Segment>
      </div>
    </>
  );
}
