import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Well } from 'react-bootstrap';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';

const RANGranteeInfo = ({ ran: { granteeInfo, reviewId, reviewType } }) =>
  !_.isEmpty(granteeInfo) ? (
    <Segment basic>
      <Well>
        {reviewId && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Review ID"
            >
              Review ID:{' '}
            </span>{' '}
            {reviewId}
          </div>
        )}
        {granteeInfo.map(grantee => (
          <div key={grantee.granteeId}>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Grantee Name"
            >
              Grantee Name:{' '}
            </span>{' '}
            {`${grantee.granteeName} (${grantee.granteeId})`}
          </div>
        ))}
      </Well>
    </Segment>
  ) : null;

RANGranteeInfo.propTypes = {
  ran: PropTypes.object,
};

const mapStateToProps = state => ({
  ran: state.forms.ran,
});

export default connect(mapStateToProps)(RANGranteeInfo);
