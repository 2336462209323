import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, filter, find, map, uniqBy, remove, cloneDeep } from 'lodash';
import {
  Modal,
  Button,
  Form as SemanticForm,
  Message,
  Radio,
  Segment,
  Grid,
} from 'semantic-ui-react';

import AmsModal from '../../../utils/AmsModal';
import AmsTable from '../../../utils/AmsTable';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import Form from '@rjsf/core';
import {
  fa2ChildFileSave,
  fetchFA2FormSchema,
  fetchFA2FormDetail,
  fa2GuideSelected,
  fa2PerformanceMeasureSelected,
} from '../../../actions/fa2Actions';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import { allowedView } from '../../../utils/EnforceRole';
import { acl } from '../../../config';
import AmsAlert from '../../../utils/AmsAlert';
import { exportChildFiles } from '../../../actions/submissions';
import GranteeIdField from '../../Shared/FormHelpers/GranteeIdField';

const FA2ChildFiles = props => {
  const amsTableRef = useRef(null);
  const {
    selectedPerformanceMeasure,
    selectedGuide,
    isReportFinalized,
    reviewStatus,
    readOnly,
    submission,
    generalInfo,
    reviewId,
    reviewType,
  } = useSelector(state => state.forms.fa2);
  const { user } = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [childFileErrors, setChildFileErrors] = useState([]);
  const [selectedChildFile, setSelectedChildFile] = useState({});
  const [rowClicked, setRowClicked] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [fileEditButtonClicked, setFileEditButtonClicked] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [draftButtonClicked, setDraftButtonClicked] = useState(false);
  const [previousChildId, setPreviousChildId] = useState('');
  const [originalData, setOriginalData] = useState({});
  const [
    showDeleteConfirmationAlert,
    setShowDeleteConfirmationAlert,
  ] = useState(false);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [selectedPM, setSelectedPM] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);

  const [sizePerPage, setSizePerPage] = useState(10);
  const dispatch = useDispatch();

  // useEffect to set selected PM
  useEffect(() => {
    if (!isEmpty(selectedPerformanceMeasure)) {
      setSelectedPM(selectedPerformanceMeasure);
    }
  }, [selectedPerformanceMeasure]);

  // useEffect to set form state
  useEffect(() => {
    if (submission && !isEmpty(submission))
      setFormDisabled(!isEmpty(submission));
    else setFormDisabled(false);
  }, [submission]);

  // useEffect to fetch form schema when pm changes
  useEffect(() => {
    const { forms } = selectedPM;
    setFormProcessing(true);
    if (forms && forms[0]) {
      dispatch(
        fetchFA2FormSchema({ filters: { amsFormId: forms[0].amsFormId } })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPM.name]);

  // useEffect to stop form processing when form schema is fetched
  useEffect(() => {
    setFormProcessing(false);
  }, [selectedPM.formSchema]);

  // useEffect to update error state for child file
  useEffect(() => {
    if (props.childFileSaveErrors) {
      setErrors(props.childFileSaveErrors);
    }
  }, [props.childFileSaveErrors]);

  useEffect(() => {
    let data = props.formData.childFiles;
    let customSizePer = 0;
    if (data && data.length > 0) {
      const dataLength = data.length;
      customSizePer =
        dataLength > 10
          ? dataLength > 25
            ? dataLength > 30
              ? dataLength > 50
                ? dataLength > 100
                  ? 250
                  : 100
                : 50
              : 30
            : 25
          : 10;
    }
    if (customSizePer > sizePerPage) {
      setSizePerPage(customSizePer);
    }
  }, [props.formData.childFiles]);

  // Build and render child file listing table
  const renderChildFilesTable = () => {
    const data = props.formData.childFiles;

    const formatDate = (cell, row) =>
      AmsDateFormatters.formatDate(AmsDateFormatters.getMoment(cell));

    const rowStyle = (row, rowIndex) => {
      if (
        errors &&
        errors.childFiles &&
        errors.childFiles.length > 0 &&
        find(
          errors.childFiles,
          childId =>
            childId.toLowerCase().trim() === row.childId.toLowerCase().trim()
        )
      ) {
        return {
          backgroundColor: '#e0b4b4',
        };
      }
    };

    const actionFormatter = (cell, row) => {
      return (
        <Button.Group icon>
          <Button
            onClick={e => {
              e.preventDefault();
              setFileEditButtonClicked(true);
              setSelectedChildFile(row);
              setShowModal(true);
              setFormDisabled(false);
              setPreviousChildId(row.childId);
              setRowClicked(true);
            }}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-pencil fa-md" />
          </Button>
          &nbsp; | &nbsp;
          {allowedView(acl.actions.survey.deleteChildFiles, user.roles) && (
            <Button
              onClick={e => {
                e.preventDefault();
                setShowDeleteConfirmationAlert(true);
                setSelectedChildFile(row);
              }}
              className="clickable"
              style={{ background: 'none' }}
            >
              <i className="fa fa-trash fa-md" />
            </Button>
          )}
        </Button.Group>
      );
    };

    const childIdFormatter = (cell, row) => {
      if (!editButtonClicked && !isEmpty(submission)) {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={e => {
              e.preventDefault();
              if (!editButtonClicked) {
                setSelectedChildFile(row);
                setShowModal(true);
                setRowClicked(true);
                setPreviousChildId(row.childId);
              }
            }}
            // eslint-disable-next-line no-script-url
            href="javascript:void(0);"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            {cell}
          </a>
        );
      } else return cell;
    };

    const columns = [
      {
        dataField: 'childId',
        text:
          generalInfo.fiscalYear > 2021
            ? 'Child or Expectant Mother ID'
            : 'Child or Pregnant Woman ID',
        sort: true,
        style: { whiteSpace: 'normal' },
        formatter: childIdFormatter,
      },
      {
        dataField: 'submitterName',
        text: 'Submitter',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'fileReviewDate',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Date Added',
      },
    ];

    if (editButtonClicked) {
      columns.push({
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: actionFormatter,
        text: 'Action',
      });
    } else if (!editButtonClicked && isEmpty(submission)) {
      columns.push({
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: actionFormatter,
        text: 'Action',
      });
    }

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (!editButtonClicked && !isEmpty(submission)) {
          setSelectedChildFile(row);
          setShowModal(true);
          setRowClicked(true);
          setPreviousChildId(row.childId);
        }
      },
    };

    return (
      <>
        <div className="row">
          <div className="col-sm-8">
            <h5>Added Files</h5>
          </div>
          {!isReportFinalized &&
            reviewStatus &&
            reviewStatus.toLowerCase() !== 'cancelled' &&
            (editButtonClicked ||
              !submission.amsSubmissionId ||
              (submission.amsSubmissionId && editButtonClicked)) && (
              <div className="col-sm-1">
                <Button
                  basic
                  size="tiny"
                  disabled={readOnly}
                  onClick={() => {
                    setShowModal(true);
                    setFormDisabled(false);
                    setRowClicked(false);
                  }}
                >
                  Add
                </Button>
              </div>
            )}
          {submission && submission.amsSubmissionId && (
            <div className="col-sm-3 export-child-files-button">
              <Button
                primary
                size="small"
                icon
                onClick={() =>
                  dispatch(exportChildFiles(submission.amsSubmissionId))
                }
              >
                Export Files
              </Button>
              <br />
            </div>
          )}
          <br />
          <br />
        </div>

        <AmsTable
          remote={false}
          customSize={sizePerPage}
          sizePerPageOption={false}
          basic
          total={data ? data.length : 0}
          columns={columns}
          rowEvents={rowEvents}
          keyField="version"
          ref={amsTableRef}
          data={data ? data : []}
          rowStyle={rowStyle}
        />
      </>
    );
  };

  /**
   * Set the form data changes for submission
   * @param {Object} data - data that comes from the form schema
   * @returns {void}
   */
  const handleChange = ({ idSchema, formData }) => {
    setSelectedChildFile(formData);
  };

  /**
   * Transform the child file errors to custom error messages
   * @param {Object} childFileErrors
   * @returns {Object}
   */
  const transformErrors = childFileErrors => {
    if (draftButtonClicked) {
      return [];
    }

    return filter(childFileErrors, error => {
      if (error.name === 'required') {
        error.message = 'This is a required field.';
        return error.name === 'required' || error.name === 'minimum';
      } else if (error.name === 'pattern') {
        error.message =
          'Please enter a valid Grantee ID, example format - 01CH010527';
        return error.name === 'pattern';
      } else if (error.name === 'childId') {
        error.message =
          'The child file already exists please check the Child ID and try again.';
        return error.name === 'childId';
      } else if (error.name === 'format') {
        error.message = 'Please enter a valid date';
        return error.name === 'format';
      }
    });
  };

  /**
   * Check if the child file is unique
   * @param {String} id
   * @returns {Boolean}
   */
  const checkExistingChildId = id => {
    let data = [];
    if (
      props.formData &&
      props.formData.childFiles &&
      props.formData.childFiles.length > 0
    )
      data = props.formData.childFiles
        ? uniqBy(
            [
              ...(props.formData.childFiles || []),
              ...(props.childFileSubmission.create || []),
              ...(props.childFileSubmission.update || []),
            ],
            'childId'
          )
        : uniqBy(
            [
              ...(props.childFileSubmission.create || []),
              ...(props.childFileSubmission.update || []),
            ],
            'childId'
          );
    if (
      find(
        data,
        file => file.childId.toLowerCase().trim() === id.toLowerCase().trim()
      )
    )
      return true;
    else return false;
  };

  /**
   * Process a single child file addition
   * @param {Object} data - data that comes from the form schema
   * @returns {void}
   */
  const onSubmitChildFile = ({ idSchema, formData }) => {
    let childFileExists = checkExistingChildId(formData.childId);
    if (
      childFileExists &&
      formData.childId.toLowerCase().trim() !==
        previousChildId.toLowerCase().trim()
    ) {
      let errors = [];
      let error = {
        key: 'Child File Exists',
        message:
          'The child file already exists please check the Child ID and try again.',
        name: 'childId',
      };
      errors.push(error);
      setChildFileErrors(errors);
      return;
    }

    formData = {
      ...formData,
      submitterName: user.fullName,
      submitterOid: user.oid,
    };

    if (formData.availableForReview === false) {
      formData = {
        ...formData,
        eligibilityCategory: 'Not Applicable',
        interviewTookPlace: 'Not Applicable',
        typeUsedForEligibilityVerification: 'Not Applicable',
        conductRedetermination: {
          ...formData.conductRedetermination,
          yearEnrolled: 'Not Applicable',
          familySize: 0,
          eligibilityCategoryForRedetermination: 'Not Applicable',
        },
      };
    } else if (
      formData.typeUsedForEligibilityVerification ===
      'The eligibility determination record does not contain copies of any documents or statements used to verify eligibility for this child'
    ) {
      formData = {
        ...formData,
        conductRedetermination: {
          ...formData.conductRedetermination,
          yearEnrolled: 'Not Applicable',
          familySize: 0,
          eligibilityCategoryForRedetermination: 'Not Applicable',
        },
      };
    }

    props.handleDataChange({
      formData,
      rowClicked,
      fileEditButtonClicked,
      previousChildId,
      selectedChildFile,
    });

    setShowModal(false);
    setSelectedChildFile({});
    setChildFileErrors([]);
    setFileEditButtonClicked(false);
    setRowClicked(false);
    if (!isEmpty(errors) && errors.childFiles.length > 0) {
      let remainingErrors = remove(errors.childFiles, childfileId => {
        return (
          childfileId.toLowerCase().trim() !==
          previousChildId.toLowerCase().trim()
        );
      });
      if (remainingErrors.length === 0) {
        setErrors({});
      } else setErrors({ ...errors, childFiles: remainingErrors });
    } else {
      setErrors({});
    }
    setPreviousChildId('');
  };

  const handleError = error => console.log(error);

  // Set state to default values when closing modal
  const closeModal = () => {
    setShowModal(false);
    setRowClicked(false);
    setSelectedChildFile({});
    setChildFileErrors([]);
    setPreviousChildId('');
    setFileEditButtonClicked(false);
  };

  // Add custom field for gantee id in the form
  const fields = () => {
    return {
      granteeIdField: GranteeIdField,
    };
  };

  // Render the add child file modal
  const renderAddChildFiles = () => {
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        onClose={closeModal}
        size="large"
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{'Add Files'}</Modal.Header>
        <Modal.Content>
          {!isEmpty(childFileErrors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={map(childFileErrors, error => error.message)}
            />
          )}

          <div className="row">
            <div
              key={
                (selectedPM &&
                  selectedPM.forms &&
                  selectedPM.forms[0].amsFormId) ||
                ''
              }
              className="form-container"
            >
              <fieldset
                disabled={
                  readOnly ||
                  isReportFinalized ||
                  (reviewStatus && reviewStatus.toLowerCase() === 'cancelled')
                    ? true
                    : formDisabled
                }
              >
                <Form
                  idPrefix={
                    (selectedPM &&
                      selectedPM.forms &&
                      selectedPM.forms[0].amsFormId) ||
                    ''
                  }
                  schema={selectedPM.formSchema || {}}
                  uiSchema={selectedPM.uiSchema || {}}
                  formData={selectedChildFile}
                  onSubmit={onSubmitChildFile}
                  onChange={handleChange}
                  onError={handleError}
                  // validate={validate}
                  liveValidate={false}
                  showErrorList={false}
                  noHtml5Validate
                  transformErrors={transformErrors}
                  fields={fields()}
                >
                  <div className="ui form">
                    {!isReportFinalized && (
                      <SemanticForm.Group>
                        <SemanticForm.Button
                          primary
                          type="submit"
                          content={
                            fileEditButtonClicked || rowClicked
                              ? 'Save changes'
                              : 'Add'
                          }
                        />
                      </SemanticForm.Group>
                    )}
                  </div>
                </Form>
              </fieldset>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  // Render the top level question for adding child files
  const showTopQuestion = () => {
    const { topQuestion } = props.formData;
    const { aianQuestion } = props.formData;
    const aianReviewTypes = ['FA2-CR', 'Special'];
    return (
      <div className="ui form">
        <AmsFormLabel
          name="Were you able to complete the data collection task for this performance measure?*"
          fieldLabel="completedDataCollection"
          disabled={formDisabled}
        >
          <SemanticForm.Group inline>
            <SemanticForm.Field
              name={'topQuestion'}
              control={Radio}
              label="Yes"
              value={topQuestion}
              checked={topQuestion === true}
              onChange={e => {
                e.preventDefault();
                props.handleDataChange({ topQuestion: true }, 'topQuestion');
              }}
              disabled={formDisabled}
            />
            <SemanticForm.Field
              name={'topQuestion'}
              control={Radio}
              label="No"
              value={topQuestion}
              checked={topQuestion === false}
              onChange={e => {
                e.preventDefault();
                props.handleDataChange({ topQuestion: false }, 'topQuestion');
              }}
              disabled={formDisabled}
            />
          </SemanticForm.Group>
        </AmsFormLabel>
        {topQuestion && aianReviewTypes.includes(reviewType) && (
          <AmsFormLabel
            name="Is this an AIAN Program?*"
            fieldLabel="completedDataCollection"
            disabled={formDisabled}
          >
            <SemanticForm.Group inline>
              <SemanticForm.Field
                name={'aianQuestion'}
                control={Radio}
                label="Yes"
                value={aianQuestion}
                checked={aianQuestion === true}
                onChange={e => {
                  e.preventDefault();
                  props.handleDataChange(
                    { aianQuestion: true },
                    'aianQuestion'
                  );
                }}
                disabled={formDisabled}
              />
              <SemanticForm.Field
                name={'aianQuestion'}
                control={Radio}
                label="No"
                value={aianQuestion}
                checked={aianQuestion === false}
                onChange={e => {
                  e.preventDefault();
                  props.handleDataChange(
                    { aianQuestion: false },
                    'aianQuestion'
                  );
                }}
                disabled={formDisabled}
              />
            </SemanticForm.Group>
          </AmsFormLabel>
        )}
      </div>
    );
  };

  const showEditButton = () => {
    if (readOnly) return;

    if (isReportFinalized) return;

    if (editButtonClicked) return;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (
      submission.surveyStatus &&
      (submission.surveyStatus.toLowerCase() === 'submitted' ||
        submission.surveyStatus.toLowerCase() === 'draft') &&
      submission.editable
    ) {
      return (
        <Button
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            setOriginalData(
              submission &&
                submission.data &&
                cloneDeep(submission.data.surveyData)
            );
            setFormDisabled(false);
            setEditButtonClicked(true);
          }}
        />
      );
    }
  };

  const showSubmitButton = () => {
    const { aianQuestion } = props.formData;
    let childFiles = [];
    let existing =
      (submission &&
        submission.data &&
        submission.data.surveyData &&
        submission.data.surveyData.childFiles) ||
      [];
    const { create, update } = props.childFileSubmission;
    if (create || update || props.childFileSubmission.delete)
      childFiles = [
        ...(create || []),
        ...(update || []),
        ...(props.childFileSubmission.delete || []),
      ];
    if (existing) childFiles = [...existing, ...childFiles];
    if (!editButtonClicked && submission.amsSubmissionId) return;
    if (readOnly) return;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return;

    if (
      (editButtonClicked &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'submitted') ||
      !submission.surveyStatus ||
      (submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft')
    ) {
      return (
        <div>
          <SemanticForm.Button
            type="submit"
            primary
            disabled={
              props.formData.topQuestion === false
                ? false
                : props.formData.topQuestion &&
                  !isEmpty(childFiles) &&
                  ('Special' === reviewType ||
                    aianQuestion === false ||
                    aianQuestion === true)
                ? false
                : submission.surveyStatus &&
                  submission.surveyStatus.toLowerCase() === 'draft' &&
                  !isEmpty(childFiles) &&
                  ('Special' === reviewType ||
                    aianQuestion === false ||
                    aianQuestion === true)
                ? false
                : true
            }
            content={'Submit'}
            onClick={e => {
              e.preventDefault();
              saveData('submitted');
            }}
          />
        </div>
      );
    }
  };

  const showDraftButton = () => {
    if (!editButtonClicked && submission.amsSubmissionId) return;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (readOnly) {
      return null;
    }

    if (
      !submission.surveyStatus ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft' &&
        editButtonClicked)
    )
      return (
        <Button
          type="submit"
          content="Save as Draft"
          disabled={
            props.formData.topQuestion === false
              ? false
              : props.formData.topQuestion
              ? false
              : submission.surveyStatus &&
                submission.surveyStatus.toLowerCase() === 'draft'
              ? false
              : true
          }
          onClick={() => {
            setDraftButtonClicked(true);
            saveData('draft');
          }}
        />
      );

    return null;
  };

  const showCancelButton = () => {
    if (readOnly) return null;

    if (
      !editButtonClicked ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() !== 'submitted')
    )
      return null;
    else
      return (
        <Button
          negative
          content={'Cancel'}
          onClick={e => {
            e.preventDefault();
            setFormDisabled(true);
            setEditButtonClicked(false);
            props.fetchSurveyData(submission.amsSubmissionId);
            props.handleCancelChange(
              submission && submission.data && originalData
            );
          }}
        />
      );
  };

  const showDeleteConfirmation = () => {
    return (
      <AmsAlert
        show={showDeleteConfirmationAlert}
        title={'Are you sure you want to delete this child file?'}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, delete'}
        cancelButtonText={'No, cancel'}
        onCancel={() => {
          setShowDeleteConfirmationAlert(false);
          setSelectedChildFile({});
        }}
        onConfirm={() => {
          let deleted = [...(props.childFileSubmission.delete || [])];
          deleted.push(selectedChildFile);
          let created = remove(
            props.childFileSubmission.create,
            file => file.childId !== selectedChildFile.childId
          );
          let updated = remove(
            props.childFileSubmission.update,
            file => file.childId !== selectedChildFile.childId
          );

          props.handleChildFileDelete({
            update: updated,
            delete: deleted,
            create: created,
            topQuestion: props.formData.topQuestion,
            aianQuestion: props.formData.aianQuestion,
            selectedChildFile,
          });
          if (!isEmpty(errors) && errors.childFiles.length > 0) {
            let remainingErrors = remove(errors.childFiles, childfileId => {
              return (
                childfileId.toLowerCase().trim() !==
                selectedChildFile.childId.toLowerCase().trim()
              );
            });
            if (remainingErrors.length === 0) {
              setErrors({});
            } else setErrors({ ...errors, childFiles: remainingErrors });
          } else {
            setErrors({});
          }
          setShowDeleteConfirmationAlert(false);
          setSelectedChildFile({});
        }}
      />
    );
  };

  const showSuccessConfirmation = () => {
    return (
      <AmsAlert
        show={alert.show}
        title={alert.message}
        type={alert.type || 'success'}
        showConfirm
        onConfirm={() => {
          setAlert({
            show: false,
            message: '',
            type: '',
          });
          dispatch(fetchFA2FormDetail({ reviewId })).then(() => {
            dispatch(fa2GuideSelected(selectedGuide));
            dispatch(fa2PerformanceMeasureSelected(selectedPerformanceMeasure));
          });
        }}
      />
    );
  };

  /**
   * Build the child file submission data to be sent to the server
   * @param {Object} surveyStatus
   * @returns {void}
   */
  const saveData = surveyStatus => {
    let data = {
      create: props.childFileSubmission.create || [],
      update: props.childFileSubmission.update || [],
      delete:
        filter(
          props.childFileSubmission.delete,
          childFile => childFile.mongoId
        ) || [],
      topQuestion: props.formData.topQuestion,
      aianQuestion: props.formData.aianQuestion,
      surveyStatus,
    };
    if (submission && submission.amsSubmissionId)
      data.amsSubmissionId = submission.amsSubmissionId;
    if (selectedPM.forms && selectedPM.forms[0].amsFormId)
      data.amsFormId = selectedPM.forms[0].amsFormId;
    if (reviewId) data.reviewId = reviewId;
    if (selectedPM.formCode) data.formCode = selectedPM.formCode;
    if (selectedGuide.guideCode) data.guideCode = selectedGuide.guideCode;
    dispatch(fa2ChildFileSave({ data }))
      .then(response => {
        if (response && response.error) {
          setAlert({
            show: true,
            type: 'success',
            message: `${response.error}`,
          });
        } else
          setAlert({
            show: true,
            type: 'success',
            message: 'Data saved successfully',
          });
        props.dataHasChangedSwitch(false);
        resetState();
        dispatch(
          fetchFA2FormSchema({
            filters: { amsFormId: selectedPM.forms[0].amsFormId },
          })
        );
      })
      .catch(error => {
        let errorData =
          !isEmpty(error.response.data.message) &&
          JSON.parse(error.response.data.message) &&
          !isEmpty(JSON.parse(error.response.data.message).text) &&
          JSON.parse(error.response.data.message);
        setErrors(errorData);
      });
  };

  // Set state to default values after submission
  const resetState = () => {
    setErrors({});
    setChildFileErrors([]);
    setFileEditButtonClicked(false);
    setDraftButtonClicked(false);
    setEditButtonClicked(false);
  };

  // Render survey submission status
  const renderSurveyStatus = () => {
    if (isEmpty(submission)) return null;

    if (!submission.amsSubmissionId) return null;

    const { editTime, editedBy } = submission.data;

    return (
      <Segment
        style={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #e3e3e3',
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
          padding: '20px',
          marginLeft: '1rem',
        }}
      >
        {editedBy && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submitted By"
            >
              Submitted By:{' '}
            </span>
            {editedBy}
          </div>
        )}
        {submission.surveyStatus && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:{' '}
            </span>
            {submission.surveyStatus.toLowerCase() === 'draft'
              ? 'Draft'
              : 'Submitted'}
          </div>
        )}
        {editTime && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Time"
            >
              Submission Time:{' '}
            </span>
            {AmsDateFormatters.formatDateTime(editTime)}
          </div>
        )}
      </Segment>
    );
  };

  return (
    <div className="row form-container">
      {!isEmpty(errors) && (
        <Message
          negative
          icon={'cancel'}
          header={errors.text}
          list={errors.childFiles.map(errorKey => (
            <li key={errorKey}>
              <strong>
                Child/Expectant Mother ID {errorKey} is a duplicate
              </strong>
            </li>
          ))}
        />
      )}
      <Segment basic loading={formProcessing}>
        {renderSurveyStatus()}
        <Grid columns={1}>
          <Grid.Column>
            <legend>
              Determining, verifying, and documenting eligibility (Eligibility
              Files)
            </legend>
            {generalInfo.fiscalYear >= 2021 && showTopQuestion()}
            {showSuccessConfirmation()}
            {showDeleteConfirmation()}
            <div className="form-container form-group field field-object sm-col-12">
              {(props.formData.topQuestion ||
                generalInfo.fiscalYear < 2021) && (
                <>{submission && renderChildFilesTable()}</>
              )}
              <br />
              <div className="ui form">
                {(!isReportFinalized || !readOnly) && (
                  <SemanticForm.Group>
                    {showDraftButton()}
                    {showSubmitButton()}
                    {showEditButton()}
                    {showCancelButton()}
                  </SemanticForm.Group>
                )}
              </div>
            </div>
            {renderAddChildFiles()}
            <br />
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
};

export default FA2ChildFiles;
