import React, { Component } from 'react';
import { Step, Tab, Message, Form } from 'semantic-ui-react';
import SlidingContainer from '../utils/layout/SlidingContainer';

class StepTest extends Component {
  state = {
    active: 'Observation cycle two',
  };

  handleClick = (e, { title }) => this.setState({ active: title });

  reviewInfo = () => <Message visible>Reviw ID: 10X455</Message>;

  form = () => (
    <Form>
      <Form.Group>
        <Form.Input label="First name" placeholder="First Name" width={6} />
        <Form.Input label="Middle Name" placeholder="Middle Name" width={4} />
        <Form.Input label="Last Name" placeholder="Last Name" width={6} />
      </Form.Group>
      <Form.Group>
        <Form.Input placeholder="2 Wide" width={2} />
        <Form.Input placeholder="12 Wide" width={12} />
        <Form.Input placeholder="2 Wide" width={2} />
      </Form.Group>
      <Form.Group>
        <Form.Input placeholder="8 Wide" width={8} />
        <Form.Input placeholder="6 Wide" width={6} />
        <Form.Input placeholder="2 Wide" width={2} />
      </Form.Group>
    </Form>
  );

  steps = (active, completed) => (
    <Step.Group ordered fluid>
      <Step
        active={this.state.active.toLowerCase() === 'observation cycle one'}
        link
        completed
        onClick={this.handleClick}
        title="Observation Cycle One"
        description="Complete observation cycle one"
      />
      <Step
        active={this.state.active.toLowerCase() === 'observation cycle two'}
        link
        onClick={this.handleClick}
        title="Observation Cycle Two"
        description="Complete observation cycle two"
      />
    </Step.Group>
  );

  panes = () => [
    {
      menuItem: 'Classroom 1',
      render: () => (
        <Tab.Pane>
          {this.steps()}
          <div>{this.form()}</div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Classroom 2',
      render: () => <Tab.Pane>{this.steps()}</Tab.Pane>,
    },
    {
      menuItem: 'Classroom 3',
      render: () => <Tab.Pane>{this.steps()}</Tab.Pane>,
    },
  ];

  render() {
    return (
      <div className="step-page">
        {/* {this.showResponseErrors()}

        {this.showDetailModal()} */}

        <SlidingContainer title={'CLASS Survey'}>
          {this.reviewInfo()}

          <Form>
            <Form.Dropdown
              label="Select Center"
              onChange={this.handleChange}
              options={[
                { key: 1, text: 'Center 1', value: 'Center1' },
                { key: 2, text: 'Center 2', value: 'Center2' },
                { key: 3, text: 'Center 3', value: 'Center3' },
                { key: 4, text: 'Center 4', value: 'Center4' },
                { key: 5, text: 'Center 5', value: 'Center5' },
                { key: 6, text: 'Center 6', value: 'Center6' },
              ]}
              placeholder="Choose Center"
              selection
              search
              value={this.state.value}
            />
          </Form>
          <br />
          <br />

          <Tab
            menu={{
              fluid: true,
              vertical: true,
              tabular: true,
              stackable: true,
            }}
            panes={this.panes()}
          />
        </SlidingContainer>
        {/* Render Child components */}
        {this.props.children}
      </div>
    );
  }
}

StepTest.propTypes = {};

export default StepTest;
