import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//
import {
  fa1GuideSelected,
  fa1PerformanceMeasureSelected,
  fetchFA1FormDetail,
} from '../../../actions/fa1Actions';
import { filter, isEmpty } from 'lodash';
import ShowErrors from '../../../utils/ShowErrors';
import {
  Segment,
  Grid,
  Button,
  Form as SemanticForm,
  Header,
  Popup,
  Label,
  Message,
} from 'semantic-ui-react';
import Form from '@rjsf/core';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';
import AttachmentField from '../../Shared/FormHelpers/AttachmentField';
import { Link } from 'react-router';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { postFormSubmission } from '../../../actions/surveyActions';
import { fetchReviewOCISummary } from '../../../actions/fy19/reviewActions';
import BooleanTrueOnlyField from '../../Shared/FormHelpers/BooleanTrueOnlyField';
import DefinitionsListField from '../../Shared/FormHelpers/DefinitionsListField';

export default function FA1Form(props) {
  const dispatch = useDispatch();
  const {
    selectedPerformanceMeasure,
    selectedGuide,
    isReportFinalized,
    reviewStatus,
    readOnly,
    isReviewAccessible,
    submission,
    selectedCenter,
    selectedClassroom,
    reviewId,
  } = useSelector(state => state.forms.fa1);
  const { ociSummaryDetails } = useSelector(
    state => state.review.selectedReview
  );
  const [selectedPM, setSelectedPM] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [draftButtonClicked, setDraftButtonClicked] = useState(false);
  const [alertData, setAlertData] = useState({
    showAlert: false,
    alertType: '',
    alertMessage: '',
  });
  const [ociSummaryDetailsObject, setOciSummaryDetailsObject] = useState({});

  // useEffect to set selectedPerformanceMeasure
  useEffect(() => {
    if (!isEmpty(selectedPerformanceMeasure)) {
      setSelectedPM(selectedPerformanceMeasure);
    }
  }, [selectedPerformanceMeasure]);

  // useEffect to fetch ociSummaryDetails
  useEffect(() => {
    setEditButtonClicked(false);
    if (
      selectedPerformanceMeasure &&
      selectedPerformanceMeasure.name === 'Content Area Analysis'
    ) {
      dispatch(
        fetchReviewOCISummary({
          reviewId: reviewId,
          reviewType: 'FA-1',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedPM.name)]);

  useEffect(() => {
    setFormProcessing(false);
  }, [selectedPM.formSchema]);

  // useEffect to set form state
  useEffect(() => {
    if (submission && !isEmpty(submission)) {
      setFormDisabled(!isEmpty(submission));
    } else {
      setFormDisabled(false);
    }
  }, [submission]);

  // useEffect to set ociSummaryDetailsObject
  useEffect(() => {
    if (ociSummaryDetails) {
      setOciSummaryDetailsObject(
        convertArrayToObject(ociSummaryDetails.data, 'name')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ociSummaryDetails && ociSummaryDetails.data)]);

  /**
   * Convert array to object for ociSummaryDetails
   * @param {array} array
   * @param {string} key
   * @returns {object}
   */
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: Array.isArray(item.performanceMeasure)
          ? convertArrayToObject(item.performanceMeasure, 'name')
          : item.rating,
      };
    }, initialValue);
  };

  /**
   * Customize the form schema with custom descriptions and helpers
   * @param {string} id
   * @param {array | string} description
   * @returns
   */
  const customDescriptionField = ({ id, description }) => {
    // IF there is no description, hide the link
    if (
      isEmpty(description) ||
      (description &&
        Array.isArray(description) &&
        isEmpty(description[0].text))
    )
      return null;

    return description && !Array.isArray(description) ? (
      <Message>
        {getPerformanceMeasureRatingsWithQuestionType(description)}
      </Message>
    ) : description[0].text ===
      'i. Education (e.g., high school degree, college classes):' ? (
      <Message className="education-text-label">{`${description[0].text}*`}</Message>
    ) : (
      <span>
        {description.map((d, i) => [
          i > 0 && '; ',
          <Link
            to={{
              pathname: `/review/citations/definition`,
              query: { citation: d.citationNumber },
            }}
            target="_blank"
          >
            <Popup
              trigger={
                <Label
                  style={{
                    color: 'blue',
                    background: 'white',
                    textDecorationLine: 'underline',
                  }}
                >
                  {d ? d.citationNumber : ''}
                </Label>
              }
              content="Select to see Citation Definition"
            />
          </Link>,
        ])}
      </span>
    );
  };

  /**
   * Set the custom fields to use for the form schema
   * @returns {Object}
   */
  const fields = () => {
    return {
      DescriptionField: customDescriptionField,
      attachmentField: AttachmentField,
      booleanTrueOnlyField: BooleanTrueOnlyField,
      definitionsListField: DefinitionsListField,
    };
  };

  /**
   * Submit the form data
   * @returns {void}
   */
  const handleSubmit = () => {
    if (!props.amsFormId || !reviewId) return;

    let requestObject = {
      reviewId,
      amsFormId: props.amsFormId,
      surveyData: props.formData,
    };

    if (props.formData.topQuestion === false) {
      requestObject = {
        reviewId,
        amsFormId: props.amsFormId,
        surveyData: { topQuestion: false },
      };
    }

    if (submission.amsSubmissionId)
      requestObject.amsSubmissionId = submission.amsSubmissionId;

    if (selectedCenter) requestObject.centerName = selectedCenter;

    if (selectedClassroom) requestObject.classSampleId = selectedClassroom;

    setFormProcessing(true);

    // draft
    if (draftButtonClicked) {
      dispatch(
        postFormSubmission({
          data: { ...requestObject, submissionStatus: 'Draft' },
        })
      )
        .then(response => {
          setDraftButtonClicked(false);
          setErrors({});
          setAlertData({
            showAlert: true,
            alertType: 'success',
            alertMessage: 'Draft saved successfully',
          });
          setEditButtonClicked(false);
          props.dataHasChangedSwitch(false);
        })
        .catch(error => {
          setErrors({ ...errors, fa1SaveDraft: error.message });
          setFormProcessing(false);
          setDraftButtonClicked(false);
        });
    } else {
      // update
      if (
        !draftButtonClicked &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'submitted'
      ) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(response => {
            setErrors({});
            setAlertData({
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Updated successfully',
            });
            props.dataHasChangedSwitch(false);
            setEditButtonClicked(false);
          })
          .catch(error => {
            setErrors({ ...errors, fa2SaveDraft: error.message });
            setFormProcessing(false);
          });
        return; // End of update exit.
      }

      // new
      if (!draftButtonClicked) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(response => {
            setErrors({});
            setAlertData({
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Updated successfully',
            });
            props.dataHasChangedSwitch(false);
            setEditButtonClicked(false);
          })
          .catch(error => {
            setErrors({ ...errors, fa1SaveDraft: error.message });
            setFormProcessing(false);
          });
      }
    }
  };

  const handleError = e => {};

  /**
   * Custom validation for oci summary answers
   * @param {string} optionSelected
   * @param {string} question
   * @returns {boolean}
   */
  const isInValidOptionSelection = (optionSelected, question) => {
    let ociSummaryDetailsNewObject = ociSummaryDetailsObject[question];

    if (
      'Monitoring ERSEA: Eligibility, Attendance and Enrollment' === question
    ) {
      const questionOptions = [
        'Determining, Verifying, and Documenting Eligibility PM 1',
        'Determining, Verifying, and Documenting Eligibility PM 2',
        'Enrollment Verification PM 3',
      ];

      ociSummaryDetailsNewObject = filterOCISummaryDetailsObject(
        ociSummaryDetailsNewObject,
        questionOptions
      );
    } else if (
      'Monitoring and Implementing Fiscal Infrastructure' === question
    ) {
      const questionOptions = [
        'Fiscal Infrastructure, Capacity, and Responsiveness PM 1',
        'Fiscal Infrastructure, Capacity, and Responsiveness PM 2',
      ];

      ociSummaryDetailsNewObject = filterOCISummaryDetailsObject(
        ociSummaryDetailsNewObject,
        questionOptions
      );
    }

    switch (optionSelected) {
      case 'op1':
        if (
          !Object.values(ociSummaryDetailsNewObject)
            .filter(v => 'N/A' !== v)
            .some(v => v === '1' || v === '2' || v === '3')
        ) {
          return true;
        }
        break;
      case 'op2':
        if (
          Object.values(ociSummaryDetailsNewObject)
            .filter(v => 'N/A' !== v)
            .some(v => v !== '4' && v !== '5') ||
          Object.values(ociSummaryDetailsNewObject)
            .filter(v => 'N/A' !== v)
            .every(v => v === '5')
        ) {
          return true;
        }
        break;
      case 'op3':
        if (
          !Object.values(ociSummaryDetailsNewObject)
            .filter(v => 'N/A' !== v)
            .every(v => v === '5')
        ) {
          return true;
        }
        break;
      default:
        return false;
    }
  };

  /**
   * Filter the oci summary details object
   * @param {Object} convertedObj
   * @param {array} questionOptions
   * @returns {Object}
   */
  const filterOCISummaryDetailsObject = (convertedObj, questionOptions) => {
    convertedObj = Object.keys(
      ociSummaryDetailsObject[
        'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
      ]
    )
      .filter(key => questionOptions.includes(key))
      .reduce((obj, key) => {
        obj[key] =
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ][key];
        return obj;
      }, {});
    return convertedObj;
  };

  /**
   * Custom Form validation associated with form schema submission
   * @param {Object} data
   * @param {Object} errors
   * @returns {Object}
   */
  const validate = (data, errors) => {
    const {
      question1YesNO,
      question2YesNO,
      question3YesNO,
      question4YesNO,
      question5YesNO,
      question6YesNO,
    } = data;

    if (draftButtonClicked) {
      return {};
    }
    question1YesNO &&
      isInValidOptionSelection(
        question1YesNO,
        'Program Design and Management'
      ) &&
      errors.question1YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question2YesNO &&
      isInValidOptionSelection(
        question2YesNO,
        'Designing Quality Education and Child Development Program Services'
      ) &&
      errors.question2YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question3YesNO &&
      isInValidOptionSelection(
        question3YesNO,
        'Designing Quality Health Program Services'
      ) &&
      errors.question3YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question4YesNO &&
      isInValidOptionSelection(
        question4YesNO,
        'Designing Quality Family and Community Engagement Program Services'
      ) &&
      errors.question4YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question5YesNO &&
      isInValidOptionSelection(
        question5YesNO,
        'Monitoring ERSEA: Eligibility, Attendance and Enrollment'
      ) &&
      errors.question5YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    question6YesNO &&
      isInValidOptionSelection(
        question6YesNO,
        'Monitoring and Implementing Fiscal Infrastructure'
      ) &&
      errors.question6YesNO.addError(
        'One or more performance measures rating do not meet the specifications of the selected option. Select an appropriate option.'
      );

    return errors;
  };

  /**
   * Transform form errors to custom error descriptions
   * @param {Object} errors
   * @returns {Object}
   */
  const transformErrors = errors => {
    if (draftButtonClicked) {
      return [];
    }

    return filter(errors, error => {
      if (error.name === 'maxItems') {
        error.message = 'This field ' + error.message + ' selected.';
      }
      if (error.name === 'required')
        error.message = 'This is a required field.';
      if (error.params.type === 'integer')
        error.message =
          'This field only accepts whole numbers. Please enter a whole number.';
      if (error.name === 'minimum')
        error.message =
          'This field only accept values greater than 0. Please enter a number greater than 0.';
      if (error.name === 'minItems')
        error.message = 'This is a required field.';
      if (error.message === 'should be string')
        error.message = 'Please add at least one file.';
      return (
        error.name === 'required' ||
        error.name === 'minimum' ||
        error.name === 'minItems' ||
        error.name === 'maxItems' ||
        error.message === 'Please add at least one file.' ||
        error.params.type === 'integer'
      );
    });
  };

  /**
   * Set up the custom form schema description associated with oci summary results
   * @param {string} description
   * @returns {string}
   */
  const getPerformanceMeasureRatingsWithQuestionType = description => {
    switch (description) {
      case 'contentAreaQ1Rating':
        const Q1_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject['Program Design and Management'] &&
          ociSummaryDetailsObject['Program Design and Management'][
            'Program Design'
          ];
        const Q1_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject['Program Design and Management'] &&
          ociSummaryDetailsObject['Program Design and Management'][
            'Program Management'
          ];
        const Q1_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject['Program Design and Management'] &&
          ociSummaryDetailsObject['Program Design and Management'][
            'Program Governance'
          ];
        return ` Performance Measure Ratings (${
          Q1_PM1_RATING && 'N/A' !== Q1_PM1_RATING
            ? `PM 1 = ${Q1_PM1_RATING}, `
            : ''
        }
        ${
          Q1_PM2_RATING && 'N/A' !== Q1_PM2_RATING
            ? `PM 2 = ${Q1_PM2_RATING}, `
            : ''
        }  
        ${
          Q1_PM3_RATING && 'N/A' !== Q1_PM3_RATING
            ? `PM 3 = ${Q1_PM3_RATING}`
            : ''
        })`;
      case 'contentAreaQ2Rating':
        const Q2_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ] &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ]['Alignment with School Readiness'];
        const Q2_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ] &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ]['Effective and Intentional Approach to Teaching Practices'];
        const Q2_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ] &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ]['Supporting Teachers in Promoting School Readiness'];
        const Q2_PM4_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ] &&
          ociSummaryDetailsObject[
            'Designing Quality Education and Child Development Program Services'
          ]['Home-Based Program Services'];
        return ` Performance Measure Ratings (${
          Q2_PM1_RATING && 'N/A' !== Q2_PM1_RATING
            ? `PM 1 = ${Q2_PM1_RATING}, `
            : ''
        }
        ${
          Q2_PM2_RATING && 'N/A' !== Q2_PM2_RATING
            ? `PM 2 = ${Q2_PM2_RATING}, `
            : ''
        }  
        ${
          Q2_PM3_RATING && 'N/A' !== Q2_PM3_RATING
            ? `PM 3 = ${Q2_PM3_RATING}, `
            : ''
        } 
        ${
          Q2_PM4_RATING && 'N/A' !== Q2_PM4_RATING
            ? `PM 4 = ${Q2_PM4_RATING}`
            : ''
        })`;
      case 'contentAreaQ3Rating':
        const Q3_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Health Program Services'
          ] &&
          ociSummaryDetailsObject['Designing Quality Health Program Services'][
            'Child Health Status and Care'
          ];
        const Q3_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Health Program Services'
          ] &&
          ociSummaryDetailsObject['Designing Quality Health Program Services'][
            'Health and Safety Practices'
          ];
        return ` Performance Measure Ratings (${
          Q3_PM1_RATING && 'N/A' !== Q3_PM1_RATING
            ? `PM 1 = ${Q3_PM1_RATING}, `
            : ''
        }
        ${
          Q3_PM2_RATING && 'N/A' !== Q3_PM2_RATING
            ? `PM 2 = ${Q3_PM2_RATING}`
            : ''
        })`;
      case 'contentAreaQ4Rating':
        const Q4_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Family and Community Engagement Program Services'
          ] &&
          ociSummaryDetailsObject[
            'Designing Quality Family and Community Engagement Program Services'
          ]['Family Well-Being'];
        const Q4_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Designing Quality Family and Community Engagement Program Services'
          ] &&
          ociSummaryDetailsObject[
            'Designing Quality Family and Community Engagement Program Services'
          ]['Strengthening Parenting and Parent-Child Supports'];
        return ` Performance Measure Ratings (${
          Q4_PM1_RATING && 'N/A' !== Q4_PM1_RATING
            ? `PM 1 = ${Q4_PM1_RATING}, `
            : ''
        }
        ${
          Q4_PM2_RATING && 'N/A' !== Q4_PM2_RATING
            ? `PM 2 = ${Q4_PM2_RATING}`
            : ''
        })`;
      case 'contentAreaQ5Rating':
        const Q5_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ]['Determining, Verifying, and Documenting Eligibility PM 1'];
        const Q5_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ]['Determining, Verifying, and Documenting Eligibility PM 2'];
        const Q5_PM3_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ]['Enrollment Verification PM 3'];
        return ` Performance Measure Ratings (${
          Q5_PM1_RATING && 'N/A' !== Q5_PM1_RATING
            ? `PM 1 = ${Q5_PM1_RATING}, `
            : ''
        }
        ${
          Q5_PM2_RATING && 'N/A' !== Q5_PM2_RATING
            ? `PM 2 = ${Q5_PM2_RATING}, `
            : ''
        }
        ${
          Q5_PM3_RATING && 'N/A' !== Q5_PM3_RATING
            ? `PM 3 = ${Q5_PM3_RATING}`
            : ''
        })`;

      case 'contentAreaQ6Rating':
        const Q6_PM1_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ]['Fiscal Infrastructure, Capacity, and Responsiveness PM 1'];
        const Q6_PM2_RATING =
          ociSummaryDetailsObject &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ] &&
          ociSummaryDetailsObject[
            'Developing Effective ERSEA Strategies and Fiscal Infrastructure'
          ]['Fiscal Infrastructure, Capacity, and Responsiveness PM 2'];
        return ` Performance Measure Ratings (${
          Q6_PM1_RATING && 'N/A' !== Q6_PM1_RATING
            ? `PM 1 = ${Q6_PM1_RATING}, `
            : ''
        }
        ${
          Q6_PM2_RATING && 'N/A' !== Q6_PM2_RATING
            ? `PM 2 = ${Q6_PM2_RATING}`
            : ''
        })`;
      default:
        break;
    }
  };

  const showEditButton = () => {
    if (readOnly) return;

    if (isReportFinalized) return;

    if (!isReviewAccessible) return;

    if (editButtonClicked) return;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (submission.editable) {
      return (
        <Button
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            setEditButtonClicked(true);
            setFormDisabled(false);
          }}
          style={{ display: 'inline-block' }}
        />
      );
    }
  };

  const showSubmitButton = () => {
    if (readOnly) return null;

    if (isEmpty(selectedPM.formSchema)) return null;

    if (!isReviewAccessible) return;

    if (reviewStatus.toLowerCase() === 'cancelled') return null;

    if (
      !submission.surveyStatus ||
      (props.formData && submission.surveyStatus && editButtonClicked)
    )
      return (
        <Button
          disabled={isEmpty(props.formData)}
          type="submit"
          primary
          content={'Submit'}
        />
      );
  };

  const showDraftButton = () => {
    if (isEmpty(selectedPM.formSchema)) return null;

    if (!isReviewAccessible) return;

    if (reviewStatus && reviewStatus.toLowerCase() === 'cancelled') return null;

    if (readOnly) {
      return null;
    }

    if (
      !submission.surveyStatus ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft' &&
        editButtonClicked)
    )
      return (
        <Button
          type="submit"
          content="Save as Draft"
          disabled={isEmpty(props.formData)}
          onClick={() => {
            setDraftButtonClicked(true);
          }}
        />
      );

    return null;
  };

  const showCancelButton = () => {
    if (readOnly) return null;

    if (
      !editButtonClicked ||
      (props.formData &&
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() !== 'submitted')
    )
      return null;
    else
      return (
        <Button
          negative
          // size="large"
          content={'Cancel'}
          onClick={() => {
            props.handleCancelChange(
              submission && submission.data && submission.data.surveyData
            );
            setEditButtonClicked(false);
            setFormDisabled(true);
          }}
        />
      );
  };

  const showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = alertData;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          setAlertData({ showAlert: false, alertType: '', alertMessage: '' });
          dispatch(fetchFA1FormDetail({ reviewId })).then(() => {
            dispatch(fa1GuideSelected(selectedGuide));
            dispatch(fa1PerformanceMeasureSelected(selectedPerformanceMeasure));
            window.scrollTo(0, 0);
          });
        }}
      />
    );
  };

  // Render survey submission status
  const renderSurveyStatus = () => {
    if (isEmpty(submission)) return null;

    if (!submission.amsSubmissionId) return null;

    const { editTime, editedBy } = submission.data;

    return (
      <Segment
        style={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #e3e3e3',
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
          padding: '20px',
          marginLeft: '1rem',
        }}
      >
        {editedBy && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submitted By"
            >
              Submitted By:{' '}
            </span>
            {editedBy}
          </div>
        )}
        {submission.surveyStatus && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:{' '}
            </span>
            {submission.surveyStatus}
          </div>
        )}
        {editTime && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Time"
            >
              Submission Time:{' '}
            </span>
            {AmsDateFormatters.formatDateTime(editTime)}
          </div>
        )}
      </Segment>
    );
  };

  // Render survey PM description
  const renderDescription = () => {
    if (!selectedPerformanceMeasure) return;

    const { description } = selectedPerformanceMeasure;

    if (description) return <Header as="h2">{description}</Header>;
  };

  return (
    <>
      <div className="row form-container">
        <ShowErrors errors={errors} />
        {showSuccessConfirmation()}
        <Segment basic loading={formProcessing}>
          {renderSurveyStatus()}
          {renderDescription()}

          <Grid>
            <Grid.Column>
              <div className="text-right">
                <div style={{ display: 'inline-block' }}>
                  {showEditButton()}
                </div>
              </div>
              <fieldset
                id="form-container"
                disabled={
                  isReportFinalized ||
                  (reviewStatus &&
                    reviewStatus.toLowerCase() === 'cancelled') ||
                  readOnly ||
                  !isReviewAccessible
                    ? true
                    : formDisabled
                }
              >
                {!isEmpty(selectedPM.formSchema) &&
                  !isEmpty(selectedPM.uiSchema) && (
                    <Form
                      ArrayFieldTemplate={ArrayFieldTemplate}
                      key={props.amsFormId || ''}
                      schema={selectedPM.formSchema}
                      uiSchema={selectedPM.uiSchema}
                      formData={
                        (selectedPM.uiSchema &&
                          selectedPM.formSchema &&
                          props.formData) ||
                        {}
                      }
                      onSubmit={handleSubmit}
                      onChange={props.handleDataChange}
                      onError={handleError}
                      validate={validate}
                      liveValidate={false}
                      transformErrors={transformErrors}
                      showErrorList={false}
                      noHtml5Validate
                      fields={fields()}
                    >
                      <div className="ui form">
                        {!isReportFinalized &&
                          reviewStatus &&
                          reviewStatus.toLowerCase() !== 'cancelled' && (
                            <SemanticForm.Group>
                              {showDraftButton()}
                              {showSubmitButton()}
                              {showCancelButton()}
                            </SemanticForm.Group>
                          )}
                      </div>
                    </Form>
                  )}
              </fieldset>
            </Grid.Column>
          </Grid>
          {showEditButton()}
        </Segment>
      </div>
    </>
  );
}
