/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  concat,
  find,
  findIndex,
  isEmpty,
  isObject,
  orderBy,
  sortBy,
  uniq,
} from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Item,
  Label,
  List,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';

import BootstrapTable from 'react-bootstrap-table-next';
import {
  addPosition,
  addPositionReviewer,
  addPrimaryUser,
  incrementNumberOfReviewers,
  removePrimaryUser,
  selectedPosition,
  updatePositionReviewer,
  updateShadowReviewerLocation,
} from '../../../actions/reviewActions';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsModal from '../../../utils/AmsModal';
import AmsTable from '../../../utils/AmsTable';
import ReviewerFilter from '../../../utils/ReviewerFilter';
import ReviewerMaxWarningModal from '../Forms/ReviewerMaxWarningModal';

const style = {
  positionHeader: {
    background: '#f3f4f5',
  },
  skillHeader: {
    fontSize: '.8125rem',
    fontWeight: '400',
    textTransform: 'upperCase',
    color: 'rgba(0,0,0,.6)',
    letterSpacing: '.05em',
    marginBottom: '.5rem',
    marginTop: 0,
  },
  disableButton: {
    marginLeft: '10px',
  },
  assignButton: {
    marginLeft: '10px',
    cursor: 'pointer',
  },
};

const ReviewTeamCard = props => {
  const {
    reviewers,
    startDate,
    endDate,
    granteeRegions,
    grantInfo,
    reviewType,
  } = useSelector(store => store.review.reviewInfo);
  const dispatch = useDispatch();
  const [showReviewerWarning, setShowReviewerWarning] = useState(false);
  const [reviewerWarningData, setReviewerWarningData] = useState({});
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [editReviewer, setEditReviewer] = useState(false);
  const [editReviewerData, setEditReviewerData] = useState({});
  const [showConfirmEditReviewer, setShowConfirmEditReviewer] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [historyModalData, setHistoryModalData] = useState(null);
  const [withdrawnModalOpen, setWithdrawnModalOpen] = useState(false);
  const [withdrawReason, setWithdrawReason] = useState([]);

  const renderPositionTeam = team => {
    const nameFormatter = (cell, row) => {
      return (
        row && (
          <Grid style={{ marginLeft: '2px' }}>
            <Grid.Row columns={2}>
              <Grid.Column width={10}>
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header as="h4" content={row.name} />
                      {row.userName && <Item.Meta>{row.userName}</Item.Meta>}
                      {row.isPrimary &&
                      team.key !== 'shadowReviewer' &&
                      team.key !== 'pendingShadowReviewer' &&
                      team.key !== 'dualCoder' ? (
                        <Label color="green">Primary</Label>
                      ) : !row.isPrimary &&
                        team &&
                        team.team &&
                        team.team.length > 1 &&
                        team.key !== 'shadowReviewer' &&
                        team.key !== 'pendingShadowReviewer' &&
                        team.key !== 'dualCoder' ? (
                        <Label color="grey">Secondary</Label>
                      ) : (
                        <></>
                      )}
                      {row.isActive === false && (
                        <Label color="red">Inactive</Label>
                      )}
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      );
    };

    const actionFormatter = (cell, row) => {
      return (
        row && (
          <>
            {!row.isPrimary &&
              team &&
              team.team &&
              team.team.length > 1 &&
              team.key !== 'shadowReviewer' &&
              team.key !== 'pendingShadowReviewer' &&
              team.key !== 'dualCoder' && (
                <Button
                  basic
                  disabled={row.isActive === false}
                  size="mini"
                  color="blue"
                  content="Make Primary"
                  onClick={e => {
                    props.dataHasChangedSwitch(true);
                    e.preventDefault();
                    dispatch(addPrimaryUser({ ...row, type: team.value }));
                  }}
                  id={`make-${team.value}-${row.oid}`}
                />
              )}
            {row.isPrimary &&
              team &&
              team.team &&
              team.team.length > 1 &&
              team.key !== 'shadowReviewer' &&
              team.key !== 'pendingShadowReviewer' &&
              team.key !== 'dualCoder' && (
                <Button
                  basic
                  size="mini"
                  color="red"
                  content="Remove Primary"
                  onClick={e => {
                    props.dataHasChangedSwitch(true);
                    e.preventDefault();
                    dispatch(
                      removePrimaryUser({
                        ...row,
                        type: team.value,
                      })
                    );
                  }}
                  id={`remove-${team.value}-${row.oid}`}
                />
              )}
          </>
        )
      );
    };

    const onsiteFormatter = (cell, row) => {
      const index = team.team.indexOf(row);
      return (
        <AmsFormLabel
          table
          hidden
          name={`Select location for ${row.name}`}
          fieldLabel={`location-${row.id}-${team.value}`}
        >
          <Dropdown
            value={row.onSite}
            fluid
            aria-labelledby={`location-${row.id}-${team.value}`}
            upward
            selection
            index={index}
            type="location"
            onChange={(e, { value }) => {
              props.dataHasChangedSwitch(true);
              dispatch(
                updateShadowReviewerLocation({
                  ...row,
                  type: team.value,
                  onSite: value,
                })
              );
            }}
            options={[
              { value: true, key: 'On-site', text: 'On-site' },
              { value: false, key: 'Off-site', text: 'Off-site' },
            ]}
            openOnFocus={false}
            defaultOpen={false}
            selectOnBlur={false}
            search
            searchInput={{
              id: `location-${row.id}-${team.value}`,
            }}
          />
        </AmsFormLabel>
      );
    };

    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        formatter: nameFormatter,
        headerStyle: {
          width: '25%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'id',
        isDummyField: true,
        text: 'Action',
        formatter: actionFormatter,
        align: 'right',
        headerFormatter: (column, colIndex) => {
          return <span style={{ visibility: 'hidden' }}>{column.text}</span>;
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
    ];

    if (team.key === 'shadowReviewer' || team.key === 'pendingShadowReviewer') {
      columns.push({
        dataField: 'onsite',
        text: 'On-site/Off-site',
        formatter: onsiteFormatter,
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      });
    }

    return (
      <>
        <BootstrapTable
          keyField="oid"
          data={team.team ? team.team : []}
          columns={columns}
          bordered={false}
        />
        <Segment basic textAlign="center">
          <Button
            basic
            color="blue"
            content={`Add/Remove ${team.text}`}
            onClick={e => {
              if (e) e.preventDefault();
              props.addNewTeamMember(team);
            }}
          />
        </Segment>
      </>
    );
  };

  const renderReviewerPositions = () => {
    let counter = 0;
    let assignedReviewers = 0;
    let totalReviewers = 0;
    let positionsList = [...props.positions];

    positionsList &&
      positionsList.forEach(e => {
        const filteredReviewers = reviewers.filter(
          reviewer => reviewer.position === e._id
        );

        const readyReviewersCount = filteredReviewers.filter(
          r => r.status === 'assigned'
        ).length;

        assignedReviewers = assignedReviewers + readyReviewersCount;
        totalReviewers = totalReviewers + e.reviewersRequired;

        if (readyReviewersCount === e.reviewersRequired) {
          counter++;
        }
      });
    return (
      <>
        {positionsList && (
          <>
            <Grid.Row>
              <Grid.Column verticalAlign="middle">
                <Header as="h3">
                  <Icon name="user circle" />
                  <Header.Content>
                    Reviewers{' '}
                    <Header.Subheader>
                      {`${counter} of ${positionsList &&
                        positionsList.length} positions filled`}{' '}
                      and{' '}
                      {`${assignedReviewers} of ${totalReviewers} reviewers assigned`}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              {renderPositionCards()}
            </Grid.Row>
          </>
        )}
      </>
    );
  };

  const renderPositionCards = () => {
    if (!props.positions) return;

    const sortedPositions = sortBy(props.positions, o => [!o.active, o._id]);

    return sortedPositions.map((position, index) => {
      // eslint-disable-next-line no-unused-vars
      const checkReviewerStatus = reviewers.map(reviewer => {
        if (
          reviewer.position === position._id &&
          reviewer.status !== 'withdrawn'
        )
          return true;
        else return false;
      });
      //  const enableEdit = includes(checkReviewerStatus, true) ? false : true;
      return (
        <Grid.Row key={index}>
          <Grid.Column verticalAlign="middle">
            <Segment
              basic
              disabled={!position.active}
              // style={{ padding: '0px' }}
            >
              <Header as="h3">
                <Button
                  basic
                  size="tiny"
                  content={`Disable ${position.name}`}
                  style={style.disableButton}
                  disabled={!position.active}
                  onClick={e => {
                    props.dataHasChangedSwitch(true);
                    e.preventDefault();

                    sortedPositions.forEach((p, i) => {
                      if (p._id === position._id) {
                        props.positions[
                          findIndex(props.positions, i => i._id === p._id)
                        ].active = false;
                      }

                      return p;
                    });

                    dispatch(addPosition(props.positions));

                    reviewers.forEach((reviewer, index) => {
                      if (reviewer.position === position._id)
                        reviewers[index].status = 'invitationRescinded';

                      return reviewer;
                    });

                    dispatch(addPositionReviewer(reviewers));
                  }}
                />
                {position.active && (
                  <Button
                    basic
                    size="tiny"
                    color="green"
                    content="Edit"
                    onClick={e => {
                      e.preventDefault();
                      props.editReviewerPosition(position);
                    }}
                  />
                )}
              </Header>

              <Segment clearing attached="top" style={style.positionHeader}>
                <Header floated="right" style={{ marginBottom: '0px' }}>
                  {renderPositionReviewersCounter(position)}
                </Header>
                <Header floated="left" style={{ marginBottom: '0px' }}>
                  {renderPositionSkills(position)}
                </Header>
              </Segment>

              <Segment attached>{renderPositionReviewers(position)}</Segment>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  const renderPositionSkills = position => (
    <List horizontal relaxed="very">
      {position && !isEmpty(position) && (
        <List.Item>
          <List.Content>
            <List.Header style={style.skillHeader}>Position</List.Header>
            <Header as="h4">{position.name}</Header>
          </List.Content>
        </List.Item>
      )}

      {position && !isEmpty(position.contentAreaSkills) && (
        <List.Item>
          <List.Content>
            <List.Header style={style.skillHeader}>Content Skills</List.Header>
            <Header as="h4">{position.contentAreaSkills.join(', ')}</Header>
          </List.Content>
        </List.Item>
      )}

      {position && !isEmpty(position.languageSkills) && (
        <List.Item>
          <List.Content>
            <List.Header style={style.skillHeader}>Language Skills</List.Header>
            <Header as="h4">{position.languageSkills.join(', ')}</Header>
          </List.Content>
        </List.Item>
      )}

      {position && !isEmpty(position.otherSkills) && (
        <List.Item>
          <List.Content>
            <List.Header style={style.skillHeader}>Other Skills</List.Header>
            <Header as="h4">{position.otherSkills.join(', ')}</Header>
          </List.Content>
        </List.Item>
      )}
    </List>
  );

  const renderPositionReviewersCounter = position => {
    const filteredReviewers = reviewers.filter(
      reviewer => reviewer.position === position._id
    );

    const readyReviewersCount = filteredReviewers.filter(
      r => r.status === 'assigned'
    ).length;

    return (
      <>
        {position.reviewersRequired > 0 && (
          <List.Item>
            <List.Content>
              <List.Header style={style.skillHeader}>
                Reviewers Assigned
              </List.Header>
              <Header as="h3" textAlign="center" style={{ marginTop: '0px' }}>
                {`${readyReviewersCount} of ${position.reviewersRequired}`}
              </Header>
            </List.Content>
          </List.Item>
        )}
      </>
    );
  };

  const renderPositionReviewers = position => {
    const filteredReviewers = reviewers.filter(
      reviewer => reviewer.position === position._id
    );

    const assignedDateFormatter = (cell, row) => {
      cell = orderBy(
        cell,
        o => {
          return AmsDateFormatters.formatDate(o);
        },
        ['asc']
      );

      return (
        <>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column align={'right'}>
                <Label.Group circular>
                  {cell.map((e, index) => {
                    return (
                      <Popup
                        key={index}
                        trigger={
                          <Label as="a">
                            {AmsDateFormatters.getMoment(e).format('dd')}
                          </Label>
                        }
                        content={e}
                        size="small"
                      />
                    );
                  })}
                </Label.Group>
              </Grid.Column>
              <Grid.Column>
                <Button
                  basic
                  size="mini"
                  color="green"
                  content="Edit Dates"
                  onClick={e => {
                    e.preventDefault();
                    setEditReviewer(row);
                  }}
                ></Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      );
    };

    const renderReviewerLink = (reviewer, oid) => {
      return (
        <Popup
          content={`Select to view profile page for ${reviewer}`}
          position="top center"
          basic
          on={['hover', 'focus']}
          trigger={
            <Link target="_blank" to={'/admin/users/profile/' + oid}>
              {reviewer}
              <span
                style={{
                  transition: 'none 0s ease 0s',
                }}
                className="visually-hidden"
              >
                {`View profile page for ${reviewer}`}
              </span>
            </Link>
          }
        />
      );
    };

    const nameFormatter = (cell, row) => {
      const hasHistory =
        props.history && props.history.filter(e => e && e.oid === row.oid);
      return (
        row && (
          <Grid
            style={{
              marginLeft: '2px',
            }}
          >
            <Grid.Row columns={2}>
              <Grid.Column width={10}>
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header as="h4">
                        {renderReviewerLink(row.name, row.oid)}
                      </Item.Header>
                      <Item.Meta>{row.userName} </Item.Meta>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
              <Grid.Column
                style={{
                  marginTop: '10px',
                }}
                width={6}
              >
                {hasHistory && hasHistory.length > 0 && (
                  <Button
                    onClick={e => {
                      setHistoryModalOpen(true);
                      setHistoryModalData({
                        data: hasHistory,
                        name: row.fullName,
                      });
                    }}
                    basic
                    size="tiny"
                  >
                    <Icon name="history" />
                    History
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      );
    };

    const statusFormatter = (cell, row) => {
      switch (cell) {
        case 'accepted':
          return assignButtonWhenAccepted('Invitation accepted');
        case 'accepted with changes':
          return assignButtonWhenAccepted(
            'Invitation Accepted (But unable to travel)'
          );
        case 'pending':
          return 'Invitation pending';
        case 'assigned':
          return (
            <>
              <>Reviewer assigned</>
            </>
          );
        case 'declined':
          return 'Invitation declined';
        case 'withdrawn':
          return (
            <>
              Invitation Withdrawn
              {row.withdrawReason && row.withdrawReason.length > 0
                ? row.withdrawReason.map(e => {
                    return (
                      <span
                        style={{
                          display: 'block',
                        }}
                      >
                        <Label>{e}</Label>
                      </span>
                    );
                  })
                : null}
            </>
          );
        default:
          return 'Invitation pending';
      }

      function assignButtonWhenAccepted(invitationStatus) {
        return (
          <>
            {invitationStatus}{' '}
            <a
              href="#"
              style={style.assignButton}
              onClick={e => {
                e.preventDefault();

                if (!checkReviewersRequired(position)) {
                  setShowReviewerWarning(true);
                  setReviewerWarningData({
                    row,
                    type: 'assign',
                    position,
                  });
                  return;
                }

                reviewers.forEach((reviewer, index) => {
                  if (reviewer.oid === row.oid)
                    reviewers[index].status = 'assigned';

                  return reviewer;
                });

                dispatch(addPositionReviewer(reviewers));
              }}
            >
              Assign
            </a>
          </>
        );
      }
    };

    const actionFormatter = (cell, row) => {
      if (
        row.status === 'invitationRescinded' ||
        row.status === 'withdrawn' ||
        row.status === 'declined' ||
        !position.active
      )
        return;

      return (
        row && (
          <>
            <Button
              basic
              size="mini"
              color="red"
              content="Withdraw"
              onClick={e => {
                e.preventDefault();
                setWithdrawnModalOpen(row);
              }}
            />
          </>
        )
      );
    };

    const assignedAsFormatter = (cell, row) => {
      const index = reviewers.indexOf(row);
      return (
        <Dropdown
          value={
            cell && cell === 'Yes' ? 'On-Site' : cell === 'No' ? 'Virtual' : ''
          }
          fluid
          search
          upward
          selection
          index={index}
          type="willingToTravel"
          onChange={(ev, data) => handleChange(data)}
          options={[
            {
              value: 'On-Site',
              key: 'On-Site',
              text: 'On-Site',
            },
            {
              value: 'Virtual',
              key: 'Virtual',
              text: 'Virtual',
            },
          ]}
          openOnFocus={false}
          defaultOpen={false}
          selectOnBlur={false}
          placeholder={'Select Assigned As'}
        />
      );
    };

    const handleChange = data => {
      const { index } = data;
      if (index === -1) return;
      const row = reviewers[data.index];
      const dataValue =
        data.value === 'On-Site' ? 'Yes' : data.value === 'Virtual' ? 'No' : '';
      if (row && row.willingToTravel !== dataValue) {
        dispatch(
          updatePositionReviewer(row, {
            dates: row.dates,
            invitationDate: row.invitationDate,
            status: row.status,
            willingToTravel: dataValue,
          })
        );
      }
    };

    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        formatter: nameFormatter,
        headerStyle: {
          width: '25%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'willingToTravel',
        text: 'Assigned As',
        formatter: assignedAsFormatter,
        headerStyle: {
          width: '15%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'type',
        text: 'Type',
        headerStyle: {
          width: '10%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },

      {
        dataField: 'invitationDate',
        text: 'Invitation Date',
        headerStyle: {
          width: '7%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'homeState',
        text: 'State',
        headerStyle: {
          width: '5%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        formatter: statusFormatter,
        headerStyle: {
          width: '15%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'dates',
        text: 'Invited/Assigned Days',
        headerStyle: {
          width: '25%',
        },
        formatter: assignedDateFormatter,
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'id',
        isDummyField: true,
        text: 'Action',
        formatter: actionFormatter,
        align: 'right',
        headerFormatter: (column, colIndex) => {
          return (
            <span
              style={{
                visibility: 'hidden',
              }}
            >
              {column.text}
            </span>
          );
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
    ];

    const rowClasses = (row, rowIndex) => {
      let classes = null;
      if (
        row.status === 'invitationRescinded' ||
        row.status === 'withdrawn' ||
        row.status === 'declined'
      ) {
        classes = 'reviewer-search-withdrawn-row';
      }
      return classes;
    };

    return (
      <>
        {!isEmpty(filteredReviewers) ? (
          <>
            <BootstrapTable
              keyField="oid"
              rowClasses={rowClasses}
              data={filteredReviewers}
              columns={columns}
              bordered={false}
            />
            <Segment basic textAlign="center" disabled={!position.active}>
              <Button
                basic
                color="blue"
                content={`Add reviewers for ${position.name}`}
                // disabled={!(startDate && endDate)}
                onClick={e => {
                  if (e) e.preventDefault();

                  if (!checkReviewersRequired(position)) {
                    setShowReviewerWarning(true);
                    setReviewerWarningData({
                      position,
                      type: 'add',
                    });
                    return;
                  }

                  setShowSearchModal(true);
                  dispatch(selectedPosition(position, true));
                }}
              />
            </Segment>
          </>
        ) : (
          <Segment basic textAlign="center" disabled={!position.active}>
            <Header color="grey">
              There are no reviewers added to this position.
            </Header>

            <div>
              <Button
                // disabled={!(startDate && endDate)}
                basic
                color="blue"
                content="Add new reviewer"
                onClick={e => {
                  if (e) e.preventDefault();
                  setShowSearchModal(true);
                  dispatch(selectedPosition(position, true));
                }}
              />
            </div>
          </Segment>
        )}
      </>
    );
  };

  const checkReviewersRequired = position => {
    if (!isObject(position)) {
      position = props.positions.find(e => e._id === position);
    }
    const filteredReviewers = reviewers.filter(
      reviewer => reviewer.position === position._id
    );

    const readyReviewersCount = filteredReviewers.filter(
      r => r.status === 'assigned'
    ).length;

    if (position.reviewersRequired === readyReviewersCount) {
      return false;
    }
    return true;
  };

  const searchModalToggle = () => {
    setShowSearchModal(false);
    setEditReviewer(false);
    dispatch(selectedPosition({}, false));
  };

  const updateReviewer = (reviewer, data) => {
    setEditReviewer(false);
    setShowConfirmEditReviewer(true);
    setEditReviewerData({ reviewer, data });
  };

  const maxReviewersRequiredConfirmed = position => {
    props.positions[position._id] = {
      ...position,
      reviewersRequired: position.reviewersRequired + 1,
    };

    dispatch(addPosition(props.positions));
    dispatch(incrementNumberOfReviewers());
    props.editReviewerPosition({});
  };

  const renderMaxReviewerWarningModal = () => {
    return (
      <ReviewerMaxWarningModal
        show={showReviewerWarning}
        onConfirm={() => {
          if (reviewerWarningData.type === 'add') {
            setShowSearchModal(true);
            dispatch(selectedPosition(reviewerWarningData.position, true));
            setReviewerWarningData({});
            setShowReviewerWarning(false);
          } else {
            reviewers.forEach((reviewer, index) => {
              if (reviewer.oid === reviewerWarningData.row.oid)
                reviewers[index].status = 'assigned';

              return reviewer;
            });

            dispatch(addPositionReviewer(reviewers));
            maxReviewersRequiredConfirmed(reviewerWarningData.position);
            setReviewerWarningData({});
            setShowReviewerWarning(false);
          }
        }}
        onCancel={() => {
          setReviewerWarningData({});
          setShowReviewerWarning(false);
        }}
      ></ReviewerMaxWarningModal>
    );
  };

  const renderConfirmationReviewerEditDialog = () => {
    if (!editReviewerData.reviewer) return null;
    const prevDates =
      editReviewerData.reviewer.previousDates ||
      editReviewerData.reviewer.dates;
    let dates;
    if (prevDates.length === 1) {
      dates = prevDates[0];
    } else {
      dates =
        prevDates
          .slice(0, editReviewerData.reviewer.dates.length - 1)
          .join(', ') +
        ', and ' +
        prevDates[prevDates.length - 1];
    }
    let text;
    switch (editReviewerData.reviewer.status) {
      case 'accepted':
        text =
          'The Reviewer has already accepted the invitation for the dates: ' +
          dates +
          '.';
        break;
      case 'accepted with changes':
        text =
          'The Reviewer has already accepted with changes the invitation for the dates: ' +
          dates +
          '.';
        break;
      case 'assigned':
        text =
          'The Reviewer has already accepted and assigned on the review for the dates: ' +
          dates +
          '.';
        break;
      case 'declined':
        text =
          'The Reviewer has already declined the invitation for the dates: ' +
          dates +
          '.';
        break;
      case 'withdrawn':
        text =
          'The Reviewer has already been withdrawn from the invitation for the dates: ' +
          dates +
          '.';
        break;
      default:
        text =
          'The Reviewer has already been sent the invitation for the dates: ' +
          dates +
          '.';
        break;
    }

    text += ' Are you sure you want to continue to update the assigned days?';

    return (
      <AmsAlert
        show={showConfirmEditReviewer}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Confirm"
        confirmButtonColor={'#DD6B55'}
        text={text}
        onConfirm={() => {
          props.dataHasChangedSwitch(true);
          dispatch(
            updatePositionReviewer(
              editReviewerData.reviewer,
              editReviewerData.data
            )
          );
          setEditReviewer(false);
          setShowConfirmEditReviewer(false);
          setEditReviewerData({});
        }}
        onCancel={() => {
          setEditReviewer(false);
          setShowConfirmEditReviewer(false);
          setEditReviewerData({});
        }}
      />
    );
  };

  const renderHistoryModal = () => {
    return (
      <AmsModal
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setHistoryModalOpen(false);
        }}
        open={historyModalOpen}
        className="ams-semantic-modal-fix"
        // style={{ overflowY: 'hidden' }}
      >
        <Header
          content={`${historyModalData && historyModalData.name}'s History`}
        />
        <Modal.Content>
          <Modal.Description>
            {generateReviewerHistoryTable()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setHistoryModalOpen(false)} content="Close" />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const renderReviewerWithdrawnModal = () => {
    return (
      <AmsModal
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setWithdrawnModalOpen(false);
        }}
        open={!!withdrawnModalOpen}
        className="ams-semantic-modal-fix"
        // style={{ overflowY: 'hidden' }}
      >
        <Header
          content={`Reason for Withdrawing Reviewer (${withdrawnModalOpen.fullName})`}
        />
        <Modal.Content>
          <Modal.Description>
            <AmsLookupDropdown
              required
              placeholder="Select Reason for Withdraw"
              value={withdrawReason}
              multiple
              onChange={(e, { value }) => {
                setWithdrawReason(value);
              }}
              control={AmsLookupDropdown}
              fluid
              search
              selection
              // clearable
              category={'reviewerWithdrawReason'}
              aria-labelledby="withdrawReason"
              searchInput={{
                id: 'withdrawReason',
                title: 'Select a Reason for Withdrawing the Reviewer',
                'aria-labelledby': 'withdrawReason',
              }}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              props.dataHasChangedSwitch(true);
              reviewers.forEach((reviewer, index) => {
                if (reviewer.oid === withdrawnModalOpen.oid) {
                  reviewers[index].status = 'invitationRescinded';
                  reviewers[index].withdrawReason = withdrawReason;
                }

                return reviewer;
              });

              dispatch(addPositionReviewer(reviewers));
              setWithdrawReason([]);
              setWithdrawnModalOpen(false);
            }}
            content="Submit"
            disabled={withdrawReason.length === 0}
          ></Button>
          <Button
            onClick={() => {
              setWithdrawnModalOpen(false);
              setWithdrawReason([]);
            }}
            content="Cancel"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const generateReviewerHistoryTable = () => {
    const assignedDateFormatter = (cell, row) => {
      cell = orderBy(
        cell,
        o => {
          return AmsDateFormatters.formatDate(o);
        },
        ['asc']
      );

      return (
        <>
          <Label.Group circular>
            {cell.map((e, index) => {
              return (
                <Popup
                  style={{ zIndex: '100000' }}
                  key={index}
                  trigger={
                    <Label as="a">
                      {AmsDateFormatters.getMoment(e).format('dd')}
                    </Label>
                  }
                  content={e}
                  size="small"
                />
              );
            })}
          </Label.Group>
        </>
      );
    };

    const modalData = historyModalData && historyModalData.data;

    if (!modalData) {
      return null;
    }
    if (modalData.length === 0) {
      return <div>No results found.</div>;
    }

    const columns = [
      {
        dataField: 'oid',
        text: 'ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'dates',
        text: 'Invited/Assigned Days',
        width: '30%',
        formatter: assignedDateFormatter,
      },
      { dataField: 'invitationDate', text: 'Invitation Date' },
      { dataField: 'willingToTravel', text: 'Willing To Travel' },
    ];

    const total = modalData.length;

    return (
      <AmsTable
        remote={false}
        data={modalData}
        basic
        pagination={false}
        total={total}
        columns={columns}
        loading={false}
        options={{
          hideSizePerPage: true,

          showTotal: false,
        }}
        showTotal={false}
      />
    );
  };

  const renderReviewerSearch = () => {
    return (
      <ReviewerFilter
        showSearchModal={showSearchModal}
        editReviewer={editReviewer}
        updateReviewer={updateReviewer}
        startDate={AmsDateFormatters.getMoment(startDate)}
        endDate={AmsDateFormatters.getMoment(endDate)}
        reviewType={reviewType}
        grantee={grantInfo.map(grantee => grantee.granteeId)}
        granteeRegions={uniq(granteeRegions).map(e => String(e))}
        edit={true}
        searchModalToggle={searchModalToggle}
        excludeList={reviewers}
        selectedReviewer={selectedReviewer => {
          props.dataHasChangedSwitch(true);
          const position = props.positions.find(
            e => e._id === selectedReviewer.position
          );
          if (
            !checkReviewersRequired(position) &&
            isEmpty(
              reviewers.filter(
                reviewer => reviewer.oid === selectedReviewer.oid
              )
            )
          ) {
            maxReviewersRequiredConfirmed(position);
          }

          const isUpdate = find(reviewers, e => {
            return e.oid === selectedReviewer.oid;
          });
          if (isUpdate) {
            setShowConfirmEditReviewer(true);
            setEditReviewerData({
              reviewer: isUpdate,
              data: {
                dates: selectedReviewer.dates,
                status: selectedReviewer.status,
                invitationDate: selectedReviewer.invitationDate,
              },
            });
            setShowSearchModal(false);
            return;
          }
          const reviewersList = concat(reviewers, selectedReviewer);
          setShowSearchModal(false);
          dispatch(addPositionReviewer(reviewersList));
        }}
      />
    );
  };

  return (
    <>
      {props.currentTeamPositions &&
        props.currentTeamPositions
          .filter(position => position.active)
          // eslint-disable-next-line array-callback-return
          .map(teamPosition => {
            if (teamPosition.type !== 'reviewers') {
              let teamRole = props.team.find(
                t => t.value === teamPosition.type
              );

              return (
                teamRole && (
                  <Grid.Row key={teamRole.key}>
                    <Grid.Column verticalAlign="middle">
                      <Segment
                        basic
                        // disabled={!position.active}
                        // style={{ padding: '0px' }}
                      >
                        <>
                          <Segment
                            clearing
                            attached="top"
                            style={style.positionHeader}
                          >
                            <Header
                              floated="right"
                              style={{ marginBottom: '0px' }}
                            >
                              <List.Item>
                                <List.Content>
                                  <List.Header style={style.skillHeader}>
                                    PEOPLE IN TEAM
                                  </List.Header>
                                  <Header
                                    as="h3"
                                    textAlign="center"
                                    style={{ marginTop: '0px' }}
                                  >
                                    {teamRole.team.length}
                                  </Header>
                                </List.Content>
                              </List.Item>
                            </Header>
                            <Header
                              floated="left"
                              style={{ marginBottom: '0px' }}
                            >
                              <List.Item>
                                <List.Content>
                                  <List.Header style={style.skillHeader}>
                                    POSITION
                                  </List.Header>
                                  <Header
                                    as="h3"
                                    textAlign="center"
                                    style={{ marginTop: '0px' }}
                                  >
                                    {teamRole.key === 'pendingShadowReviewer'
                                      ? 'Shadow Reviewers (Invited)'
                                      : teamRole.key === 'shadowReviewer'
                                      ? 'Shadow Reviewers (Assigned)'
                                      : teamRole.text}
                                  </Header>
                                </List.Content>
                              </List.Item>
                            </Header>
                          </Segment>
                          <Segment attached>
                            {renderPositionTeam(teamRole)}
                          </Segment>
                        </>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                )
              );
            }
          })}
      {props.positions &&
        props.positions.length > 0 &&
        renderReviewerPositions()}
      {renderReviewerSearch()}
      {renderMaxReviewerWarningModal()}
      {renderConfirmationReviewerEditDialog()}
      {renderHistoryModal()}
      {renderReviewerWithdrawnModal()}
    </>
  );
};

export default ReviewTeamCard;
