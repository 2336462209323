import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SlidingContainer from '../../../utils/layout/SlidingContainer';
import {
  Menu,
  Segment,
  Sidebar,
  Grid,
  Checkbox,
  Responsive,
} from 'semantic-ui-react';
import '../assets/style.css';
import FindingsCitationDetails from './FindingsCitationDetails';
import NotesContainer from '../../Notes/index';
import { fetchNotes } from '../../../actions/noteActions';
//Import Helper functions
import { formatUserNotes } from '../../Notes/util.js';

//import actions
import {
  fetchFindingDetails,
  fetchReviewDetail,
} from '../../../actions/reviewActions';
import { isEmpty } from 'lodash';
import FindingsNotAvailable from './FindingsNotAvailable';
import { IsFindingsAvailable, prependStringToKeys } from './FindingsUtils';
import { renderSubheader } from '../../../utils/TextFormatters.js';

export default function FindingsPage(props) {
  const dispatch = useDispatch();
  const { selectedReview } = useSelector(state => state.review);
  const { fetchedFindingDetails } = useSelector(state => state.review);

  const [visible, setVisible] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState(
    'Follow-up' === selectedReview.reviewType
      ? 'correctedFindings'
      : 'findingDetails'
  );
  const [activeCitation, setActiveCitation] = useState({
    citation: '',
    activeCitationIndex: 0,
    category: '',
  });
  const [activeStrongPractice, setActiveStrongPractice] = useState({
    citation: '',
    activeCitationIndex: 0,
    category: '',
  });

  const [activeCorrected, setActiveCorrected] = useState({
    citation: '',
    activeCitationIndex: 0,
    category: '',
    subCategory: '',
  });

  const [activeUnCorrected, setActiveUnCorrected] = useState({
    citation: '',
    activeCitationIndex: 0,
    category: '',
    subCategory: '',
  });

  const { notes } = useSelector(state => state.note);
  const [saveButtonAction, setSaveButtonAction] = useState('NOT_SAVED');
  const { params } = props.router;
  const citationFindings = fetchedFindingDetails?.collectDataTypes?.findings;
  const strongPracticeFindings =
    fetchedFindingDetails?.collectDataTypes?.strongPractices;
  const fotpFindings = prependStringToKeys(
    fetchedFindingDetails?.collectDataTypes?.fotp,
    'Findings Outside the Protocol'
  );
  const citationAndFotpFindings = { ...citationFindings, ...fotpFindings };
  const correctedFindings =
    fetchedFindingDetails?.collectDataTypes?.correctedFindings;
  const unCorrectedFindings =
    fetchedFindingDetails?.collectDataTypes?.unCorrectedFindings;

  useEffect(() => {
    const id = props.router.params.id || props.router.params.reviewId;
    if (!selectedReview.reviewId || selectedReview.reviewId !== id) {
      dispatch(fetchReviewDetail({ reviewId: id }));
    }
    if (selectedReview.reviewId) {
      dispatch(fetchFindingDetails({ reviewId: id }));
      setSaveButtonAction('NOT_SAVED');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, props.selectedReview]);

  useEffect(() => {
    dispatch(
      fetchFindingDetails(
        {
          reviewId: selectedReview.reviewId,
        },
        false,
        {
          reviewType: (selectedReview || {}).reviewType,
        },
        { isNewForms: selectedReview.isNewForms }
      )
    );
    if (selectedReview.reviewId) {
      fetchSurveyNotes();
    }
    setSaveButtonAction('NOT_SAVED');
  }, [selectedReview]);

  useEffect(() => {
    if (
      'NOT_SAVED' === saveButtonAction ||
      (['NO_CHECKBOX_CHECKED', 'STATUS_DROPPED'].includes(saveButtonAction) &&
        'In Analysis' !== selectedReview.reviewStatus)
    ) {
      if (!isEmpty(citationAndFotpFindings)) {
        handleMenuItemClick('findingDetails');
        populateActiveCitationByType(citationAndFotpFindings, 'finding');
      } else if (!isEmpty(strongPracticeFindings)) {
        handleMenuItemClick('strongPracticeDetails');
        populateActiveCitationByType(strongPracticeFindings, 'strongPractice');
      } else if (!isEmpty(correctedFindings)) {
        handleMenuItemClick('correctedFindings');
        populateActiveCitationBySubType(correctedFindings, 'correctedFindings');
      } else if (!isEmpty(unCorrectedFindings)) {
        handleMenuItemClick('unCorrectedFindings');
        populateActiveCitationBySubType(
          unCorrectedFindings,
          'unCorrectedFindings'
        );
      } else {
        handleMenuItemClick('');
      }
    }

    //check query params to see if default citation should be selected
    const queryParams = new URLSearchParams(props.location.search);
    const citationQuery = queryParams.get('defaultCitation');

    if (citationQuery && fetchedFindingDetails) {
      for (const [category, findings] of Object.entries(
        citationAndFotpFindings
      )) {
        const activeCitationIndex = findings.findIndex(
          f => f.citation === citationQuery
        );
        if (activeCitationIndex !== -1) {
          // If the citation is found in the list
          setActiveCitation({
            citation: citationQuery,
            activeCitationIndex: activeCitationIndex,
            category: category,
          });
          break; // Stop searching once we've found the citation
        }
      }
    }
  }, [JSON.stringify(fetchedFindingDetails)]);

  // Add event listener to handle closing sidebar with ESC key
  useEffect(() => {
    const ESC_KEY_CODE = 27;

    const handleKeyDown = event => {
      if (event.keyCode === ESC_KEY_CODE && visible) {
        setVisible(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const boldLabelStyle = { fontFamily: 'PublicSans-Bold', color: '#000000' };
  const boldLabelItalicStyle = {
    fontFamily: 'PublicSans-Bold',
    color: '#000000',
    fontStyle: 'italic',
    fontSize: '13px',
  };

  const populateActiveCitationByType = (findingOrStrongPractice, type) => {
    for (const category in findingOrStrongPractice) {
      const findings = findingOrStrongPractice[category];
      if (findings && findings.length > 0) {
        let activeCitation = {
          citation:
            'finding' === type
              ? findings[0].citation
              : findings[0]?.collectedData[0]?.questionCode,
          activeCitationIndex: 0,
          category: category,
        };
        if ('finding' === type) {
          setActiveCitation(activeCitation);
        } else if ('strongPractice' === type) {
          setActiveStrongPractice(activeCitation);
        }
        break;
      }
    }
  };

  const populateActiveCitationBySubType = (
    correctedOrUncorrectedFindings,
    type
  ) => {
    for (const category in correctedOrUncorrectedFindings) {
      const subCategories = correctedOrUncorrectedFindings[category];
      for (const subCategory in subCategories) {
        const findings = subCategories[subCategory];
        if (findings && findings.length > 0) {
          let activeCitation = {
            citation: findings[0].citation,
            activeCitationIndex: 0,
            category: category,
            subCategory: subCategory,
          };
          if ('correctedFindings' === type) {
            setActiveCorrected(activeCitation);
          } else if ('unCorrectedFindings' === type) {
            setActiveUnCorrected(activeCitation);
          }
          return;
        }
      }
    }
  };

  const handleMenuItemClick = menuItem => {
    setActiveMenuItem(menuItem);
    setActiveCitation({});
    setActiveStrongPractice({});
    setActiveCorrected({});
    setActiveUnCorrected({});
  };

  const renderFindingsMenu = () => {
    const findings =
      citationFindings &&
      Object.entries(citationAndFotpFindings).map(
        ([category, findings], index1) => {
          return (
            <Menu.Menu key={`category-${index1}`}>
              <Menu.Item>
                <Menu.Header style={boldLabelStyle}>{category}</Menu.Header>
              </Menu.Item>
              {findings.map((finding, activeCitationIndex) => (
                <Menu.Item
                  key={`finding-citation-${activeCitationIndex}`}
                  onClick={() => {
                    handleMenuItemClick('findingDetails');
                    setActiveCitation({
                      citation: finding?.citation,
                      activeCitationIndex,
                      category: category,
                    });
                  }}
                  as="a"
                  active={activeCitation?.citation === finding?.citation}
                >
                  {finding.citation}
                </Menu.Item>
              ))}
            </Menu.Menu>
          );
        }
      );
    return findings;
  };

  const renderStrongPracticesMenu = () => {
    const findings =
      citationFindings &&
      Object.entries(strongPracticeFindings).map(
        ([category, findings], index1) => {
          return (
            <Menu.Menu key={`category-${index1}`}>
              <Menu.Item>
                <Menu.Header style={boldLabelStyle}>{category}</Menu.Header>
              </Menu.Item>
              {findings.map((finding, activeCitationIndex) => (
                <Menu.Item
                  key={`finding-citation-${activeCitationIndex}`}
                  onClick={() => {
                    handleMenuItemClick('strongPracticeDetails');
                    setActiveStrongPractice({
                      citation: finding?.collectedData[0]?.questionCode,
                      activeCitationIndex,
                      category: finding?.category,
                    });
                  }}
                  as="a"
                  active={
                    activeStrongPractice?.citation ===
                    finding?.collectedData[0]?.questionCode
                  }
                >
                  {finding.collectedData[0].questionCode}
                </Menu.Item>
              ))}
            </Menu.Menu>
          );
        }
      );
    return findings;
  };

  const renderCorrectedFindingsMenu = () => {
    return (
      <Menu.Menu>
        {correctedFindings &&
          Object.entries(correctedFindings).map(([category, subcategories]) => (
            <React.Fragment key={`category-${category}`}>
              <Menu.Item>
                <Menu.Header style={boldLabelStyle}>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: '15px',
                    }}
                  >
                    {category}
                  </div>
                </Menu.Header>
              </Menu.Item>
              {Object.entries(subcategories)
                .sort(([subCatA], [subCatB]) => {
                  if (subCatA === 'Corrected') return -1;
                  if (subCatB === 'Corrected') return 1;
                  if (subCatA === 'Withdrawn') return 1;
                  if (subCatB === 'Withdrawn') return -1;
                  return 0;
                })
                .map(([subCategory, items]) => (
                  <React.Fragment
                    key={`subCategory-${category}-${subCategory}`}
                  >
                    <div
                      style={{
                        marginLeft: '12px',
                        fontWeight: 400,
                        fontSize: '13px',
                      }}
                    >
                      {'Corrected' === subCategory &&
                        correctedMenuItems(items, category, subCategory)}

                      {'Withdrawn' === subCategory && (
                        <Menu.Item>
                          <Menu.Header style={boldLabelItalicStyle}>
                            {subCategory}
                          </Menu.Header>
                        </Menu.Item>
                      )}
                      {'Withdrawn' === subCategory &&
                        correctedMenuItems(items, category, subCategory)}
                    </div>
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </Menu.Menu>
    );
  };

  const correctedMenuItems = (items, category, subCategory) => {
    return items.map((item, activeCitationIndex) => (
      <Menu.Item
        key={`item-${category}-${subCategory}-${activeCitationIndex}`}
        onClick={() => {
          handleMenuItemClick('correctedFindings');
          setActiveCorrected({
            citation: item?.citation,
            activeCitationIndex,
            category: category,
            subCategory: subCategory,
          });
        }}
        as="a"
        active={activeCorrected?.citation === item?.citation}
      >
        {item.citation}
      </Menu.Item>
    ));
  };

  const renderUnCorrectedFindingsMenu = () => {
    return (
      <Menu.Menu>
        {unCorrectedFindings &&
          Object.entries(unCorrectedFindings).map(
            ([category, subcategories]) => (
              <React.Fragment key={`category-${category}`}>
                <Menu.Item>
                  <Menu.Header style={boldLabelStyle}>
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: '15px',
                      }}
                    >
                      {category}
                    </div>
                  </Menu.Header>
                </Menu.Item>
                {Object.entries(subcategories)
                  .sort(([subCatA], [subCatB]) => {
                    if (subCatA === 'Uncorrected') return -1;
                    if (subCatB === 'Uncorrected') return 1;
                    if (subCatA === 'New Findings') return 1;
                    if (subCatB === 'New Findings') return -1;
                    return 0;
                  })
                  .map(([subCategory, items]) => (
                    <React.Fragment
                      key={`subCategory-${category}-${subCategory}`}
                    >
                      <div
                        style={{
                          marginLeft: '12px',
                          fontWeight: 400,
                          fontSize: '13px',
                        }}
                      >
                        {'Uncorrected' === subCategory &&
                          unCorrectedMenuItems(items, category, subCategory)}
                        {'New Findings' === subCategory && (
                          <Menu.Item>
                            <Menu.Header style={boldLabelItalicStyle}>
                              {subCategory}
                            </Menu.Header>
                          </Menu.Item>
                        )}
                        {'New Findings' === subCategory &&
                          unCorrectedMenuItems(items, category, subCategory)}
                      </div>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            )
          )}
      </Menu.Menu>
    );
  };

  const unCorrectedMenuItems = (items, category, subCategory) => {
    return items.map((item, activeCitationIndex) => (
      <Menu.Item
        key={`item-${category}-${subCategory}-${activeCitationIndex}`}
        onClick={() => {
          handleMenuItemClick('unCorrectedFindings');
          setActiveUnCorrected({
            citation: item?.citation,
            activeCitationIndex,
            category: category,
            subCategory: subCategory,
          });
        }}
        as="a"
        active={activeUnCorrected?.citation === item?.citation}
      >
        {item.citation}
      </Menu.Item>
    ));
  };

  const surveyNotes = notes && formatUserNotes(notes);

  const fetchSurveyNotes = () => {
    const body = {
      filters: {
        tags: {
          reviewId: selectedReview?.reviewId,
          type: 'survey_collection',
        },
      },
    };
    dispatch(fetchNotes(body));
  };

  const handleSaveButtonAction = value => {
    setSaveButtonAction(value);
  };

  return (
    <span>
      <SlidingContainer
        title={`Findings Analysis for ${selectedReview.reviewType} Review ID ${selectedReview.reviewId}`}
        subheader={selectedReview.grantees && renderSubheader(selectedReview.grantees)}
        actionButtons={
          <NotesContainer
            tags={{
              reviewId: selectedReview?.reviewId,
              type: 'survey_collection',
            }}
            list={surveyNotes}
            enabled={surveyNotes}
            type={'Notes'}
            reloadData={() => {
              if (selectedReview.reviewId) fetchSurveyNotes();
            }}
            reloadDataOnClick={true}
          />
        }
      >
        {IsFindingsAvailable(fetchedFindingDetails) ? (
          <Grid className="findings-grid-class">
            {!visible && (
              <Checkbox
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',

                  marginLeft: '1%',
                  marginBottom: '10px',
                  marginTop: '10px',
                }}
                checked={visible}
                label={
                  visible ? (
                    <>
                      <div style={{ color: '#1D3665' }}>&lt; Hide Menu </div>
                    </>
                  ) : (
                    <>
                      <div style={{ color: '#1D3665' }}>Show Menu &gt;</div>
                    </>
                  )
                }
                onChange={(e, data) => setVisible(data.checked)}
              />
            )}
            <div className="findings-container-class">
              <Sidebar.Pushable>
                <Sidebar
                  as={Menu}
                  animation="slid along"
                  vertical
                  visible={visible}
                  //width="thin"
                  style={{
                    height: '960px',
                    width: '253px',
                    borderRadius: '0px',
                    position: 'absolute',
                    boxSizing: 'border-box',
                  }}
                >
                  <Checkbox
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      textAlign: 'right',
                      // marginLeft: 'auto',
                      marginLeft: '64%',
                      marginBottom: '30px',
                      marginTop: '10px',
                    }}
                    checked={visible}
                    label={
                      visible ? (
                        <>
                          <div
                            style={{
                              color: '#1D3665',
                              right: '28px',
                              top: '8px',
                            }}
                          >
                            &lt; Hide Menu{' '}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              color: '#1D3665',
                            }}
                          >
                            Show Menu &gt;
                          </div>
                        </>
                      )
                    }
                    onChange={(e, data) => setVisible(data.checked)}
                  />
                  {'Follow-up' !== selectedReview?.reviewType && (
                    <Menu.Item
                      as="a"
                      name="findingDetails"
                      active={activeMenuItem === 'findingDetails'}
                    >
                      <Menu.Header style={boldLabelStyle}>
                        Findings{` (${fetchedFindingDetails?.findingCount})`}
                      </Menu.Header>
                      <Menu.Menu>{renderFindingsMenu()}</Menu.Menu>
                    </Menu.Item>
                  )}
                  {'FA1-FR' !== selectedReview?.reviewType &&
                    'RAN' !== selectedReview?.reviewType &&
                    'Follow-up' !== selectedReview?.reviewType && (
                      <Menu.Item
                        as="a"
                        name="strongPracticeDetails"
                        active={activeMenuItem === 'strongPracticeDetails'}
                      >
                        <Menu.Header style={boldLabelStyle}>
                          Strong Practices
                          {` (${fetchedFindingDetails?.strongPracticeCount})`}
                        </Menu.Header>
                        <Menu.Menu>{renderStrongPracticesMenu()}</Menu.Menu>
                      </Menu.Item>
                    )}
                  {'Follow-up' === selectedReview?.reviewType && (
                    <Menu.Item
                      as="a"
                      name="correctedFindings"
                      active={activeMenuItem === 'correctedFindings'}
                    >
                      <Menu.Header style={boldLabelStyle}>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: '17px',
                          }}
                        >
                          Corrected Findings{' '}
                          {` (${fetchedFindingDetails?.correctedFindingsCount})`}
                        </div>
                      </Menu.Header>
                      <Menu.Menu>{renderCorrectedFindingsMenu()}</Menu.Menu>
                    </Menu.Item>
                  )}
                  {'Follow-up' === selectedReview?.reviewType && (
                    <Menu.Item
                      as="a"
                      name="unCorrectedFindings"
                      active={activeMenuItem === 'unCorrectedFindings'}
                    >
                      <Menu.Header style={boldLabelStyle}>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: '17px',
                          }}
                        >
                          Uncorrected Findings
                          {` (${fetchedFindingDetails?.unCorrectedFindingsCount})`}
                        </div>
                      </Menu.Header>
                      <Menu.Menu>{renderUnCorrectedFindingsMenu()}</Menu.Menu>
                    </Menu.Item>
                  )}
                </Sidebar>
                <Sidebar.Pusher>
                  <Segment basic>
                    <Responsive
                      style={{
                        width: visible ? '78%' : '92%',
                      }}
                    >
                      {activeMenuItem === 'findingDetails' ? (
                        <FindingsCitationDetails
                          citation={
                            citationAndFotpFindings?.[
                              activeCitation?.category
                            ]?.[activeCitation?.activeCitationIndex]
                          }
                          handleSaveButtonAction={handleSaveButtonAction}
                        />
                      ) : activeMenuItem === 'strongPracticeDetails' ? (
                        <FindingsCitationDetails
                          citation={
                            strongPracticeFindings?.[
                              activeStrongPractice?.category
                            ]?.[activeStrongPractice?.activeCitationIndex]
                          }
                          handleSaveButtonAction={handleSaveButtonAction}
                        />
                      ) : activeMenuItem === 'correctedFindings' ? (
                        <FindingsCitationDetails
                          citation={
                            correctedFindings?.[activeCorrected?.category]?.[
                              activeCorrected?.subCategory
                            ]?.[activeCorrected?.activeCitationIndex]
                          }
                          handleSaveButtonAction={handleSaveButtonAction}
                        />
                      ) : activeMenuItem === 'unCorrectedFindings' ? (
                        <FindingsCitationDetails
                          citation={
                            unCorrectedFindings?.[
                              activeUnCorrected?.category
                            ]?.[activeUnCorrected?.subCategory]?.[
                              activeUnCorrected?.activeCitationIndex
                            ]
                          }
                          handleSaveButtonAction={handleSaveButtonAction}
                        />
                      ) : null}
                    </Responsive>
                  </Segment>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </div>
          </Grid>
        ) : (
          <FindingsNotAvailable fetchedFindingDetails={fetchedFindingDetails} />
        )}
      </SlidingContainer>
    </span>
  );
}
