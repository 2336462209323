import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Message, Checkbox } from 'semantic-ui-react';

// Import actions.
import { addLookup, updateLookup } from '../../../actions/lookupActions';

class LookupBooleanForm extends Component {
  state = {
    adding: false,
    data: {
      value: null,
      label: '',
      description: '',
      valueType: 'Boolean',
      active: true,
      parent: false,
    },
    errors: {},
  };

  componentWillReceiveProps = nextProps => {
    const { selectedCategoryLookups, selectedLookup } = nextProps;

    // Ignore other types
    if (selectedLookup.valueType !== 'Boolean') return;
    const data = _.find(selectedCategoryLookups, {
      category: selectedLookup.category,
      valueType: selectedLookup.valueType,
      parent: false,
    });

    if (data && data._id) {
      this.setState({ data });
    } else {
      // Reset for when the next boolean loads.
      this.resetData();
    }
  };

  resetData = () =>
    this.setState({
      data: {
        value: null,
        label: '',
        description: '',
        valueType: 'Boolean',
        active: true,
        parent: false,
      },
    });

  handleChange = (e, { name, value }) =>
    this.setState({ data: { ...this.state.data, [name]: value } });

  handleToggle = () => {
    this.setState({
      data: { ...this.state.data, active: !this.state.data.active },
    });
  };

  validate = data => {
    const errors = {};
    // Only validate new values.
    if (!data._id)
      if (_.isEmpty(data.value)) errors.value = 'Value is required.';

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.data);

    this.setState({ errors });

    // No more errors submit data.
    if (Object.keys(errors).length === 0) {
      const { _id, value, active } = this.state.data;
      const { category, valueType } = this.props.selectedLookup;

      const booleanValue = value === 'true' ? true : false;
      const data = {
        valueType,
        category,
        value: booleanValue,
        active,
        parent: false,
      };

      this.setState({ adding: true });

      // New Data
      if (!_id) {
        this.props
          .addLookup(data)
          .then(() => this.setState({ adding: false, errors: {} }))
          .catch(error => {
            this.setState({
              adding: false,
              errors: { ...this.state.errors, parentError: error.response },
            });
          });
      } else {
        // Update
        this.props
          .updateLookup(_id, data)
          .then(() => this.setState({ adding: false, errors: {} }))
          .catch(error => {
            this.setState({
              adding: false,
              errors: { ...this.state.errors, parentError: error.response },
            });
          });
      }
    }
  };

  renderAddNewForm = () => {
    const { data, adding, errors } = this.state;
    return (
      <div>
        <h4>Value</h4>
        <Form onSubmit={this.onSubmit}>
          <Form.Group widths="equal">
            <Form.Group>
              <Form.Field
                radio
                name="value"
                label={{ children: 'True', htmlFor: 'trueValue' }}
                value="true"
                control={Checkbox}
                role="group"
                error={!!errors.value}
                checked={data.value === 'true' || data.value === true}
                onChange={this.handleChange}
                id="trueValue"
                aria-labelledby="trueValue"
              />
              <Form.Field
                radio
                name="value"
                label={{ children: 'False', htmlFor: 'falseValue' }}
                value="false"
                control={Checkbox}
                role="group"
                error={!!errors.value}
                checked={data.value === 'false' || data.value === false}
                onChange={this.handleChange}
                id="falseValue"
                aria-labelledby="falseValue"
              />
            </Form.Group>

            <Form.Field style={{ textAlign: 'right' }}>
              <Form.Field
                name="active"
                toggle
                label={{ children: 'Enabled', htmlFor: 'booleanEnabled' }}
                control={Checkbox}
                role="group"
                value={data.active.toString()}
                checked={data.active === true}
                onChange={this.handleToggle}
                id="booleanEnabled"
                aria-labelledby="booleanEnabled"
              />
            </Form.Field>

            <Form.Field style={{ textAlign: 'right' }}>
              <Form.Button
                color="green"
                size="tiny"
                disabled={adding}
                content={
                  adding ? 'Saving...' : data._id ? 'Update' : 'Add Value'
                }
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  };

  showError = () => {
    const { errors } = this.state;
    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(
          errorKey =>
            !_.isEmpty(errors[errorKey]) && (
              <li key={errorKey}>{errors[errorKey]}</li>
            )
        )}
      />
    );
  };

  render() {
    const { selectedLookup } = this.props;
    return (
      selectedLookup.valueType === 'Boolean' && (
        <div>
          <div>
            {this.showError()}

            {this.renderAddNewForm()}
          </div>
        </div>
      )
    );
  }
}

LookupBooleanForm.propTypes = {
  updateLookup: PropTypes.func.isRequired,
  addLookup: PropTypes.func.isRequired,
  selectedLookup: PropTypes.object.isRequired,
  selectedCategoryLookups: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  selectedLookup: state.lookups.selectedLookup,
  selectedCategoryLookups: state.lookups.selectedCategoryLookups,
});

export default connect(mapStateToProps, { updateLookup, addLookup })(
  LookupBooleanForm
);
