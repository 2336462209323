import axios from 'axios';

import { downloadBlob } from '../components/Shared/FormHelpers/helpers/utils';

import {
  GET_AGREEMENT,
  UPDATE_AGREEMENT,
  GET_CURRENT_USER_AGREEMENTS,
} from '../redux/types';

export function getAgreement(agreement) {
  return {
    type: GET_AGREEMENT,
    agreement,
  };
}

export function updateAgreement(agreement) {
  return {
    type: UPDATE_AGREEMENT,
    agreement,
  };
}

export function getAgreements(agreements) {
  return {
    type: GET_CURRENT_USER_AGREEMENTS,
    agreements,
  };
}

export const fetchAgreement = agreementName => dispatch =>
  axios
    .get(`/agreements/fetch/${agreementName}`)
    .then(response => dispatch(getAgreement(response.data)));

export const signAgreement = agreement => dispatch =>
  axios
    .post(`/agreements/sign`, agreement)
    .then(response => dispatch(updateAgreement(response.data)));

export const fetchAgreements = agreement => dispatch =>
  axios
    .get(`/agreements/agreements`, agreement)
    .then(response => dispatch(getAgreements(response.data)));

export const downloadUnsignedAgreement = (id, type) => dispatch =>
  axios
    .post(
      `/agreements/downloadUnsignedAgreement/${id}`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;

      if (!data) return;
      const blob = new Blob([data], {
        type: 'MIME',
      });

      downloadBlob(blob, `${type}-unsigned.pdf`);

      return data;
    });

export const downloadSignedAgreement = (id, type) => dispatch =>
  axios
    .post(
      `/agreements/downloadSignedAgreement/${id}`,
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      const { data } = response;

      if (!data) return;
      const blob = new Blob([data], {
        type: 'MIME',
      });

      downloadBlob(blob, `${type}-Signed.pdf`);

      return data;
    });
