import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAgencies } from '../actions/granteeActions';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

class AgencyLookup extends Component {
  componentDidMount() {
    const { value } = this.props;

    // Used to preload selections.
    if (value) {
      this.props.fetchAgencies({ contains: value });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.value && nextProps.value === '') {
      this.reset = true;
    }
    return true;
  }

  onSearchChange = (e, data) => {
    clearTimeout(this.timer);
    this.setState(
      {
        filterQuery: data.searchQuery,
      },
      () => {
        if (this.state.filterQuery.length < 2) {
          return;
        } else {
          this.props.fetchAgencies({ contains: this.state.filterQuery });
        }
      }
    );
  };

  onChange = (e, { value }) => {
    this.setState({ filterQuery: value });
    this.props.onValueSelected(value);
  };

  renderDropdown = () => {
    let options = [];
    if (!this.reset) {
      options = (this.props.agencies || []).map(agency => ({
        key: agency.agencyId + agency.agencyName,
        text: agency.agencyId + '/' + agency.agencyName,
        value: agency.agencyId,
      }));
    } else {
      this.reset = !this.reset;
    }
    const dropDownProps = _.omit(this.props, [
      'fetchAgencies',
      'agencies',
      'contains',
      'onValueSelected',
    ]);
    return (
      <Dropdown
        {...dropDownProps}
        options={options}
        onSearchChange={this.onSearchChange}
        onChange={this.onChange}
        selectOnBlur={false}
        openOnFocus={false}
      />
    );
  };

  render() {
    return this.renderDropdown();
  }
}

const mapStateToProps = state => {
  return {
    agencies: state.grantee.agencies,
  };
};

export default connect(mapStateToProps, { fetchAgencies })(AgencyLookup);
