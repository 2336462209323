import {
  SET_REVIEWS,
  SET_REVIEW_DETAIL,
  SET_REVIEW_ERROR,
} from '../redux/types';

export default function reviews(state = {}, action) {
  switch (action.type) {
    case SET_REVIEWS: {
      return action.reviews;
    }
    case SET_REVIEW_DETAIL: {
      return action.detail;
    }
    case SET_REVIEW_ERROR: {
      return { ...state, error: action.error.message };
    }
    default:
      return state;
  }
}
