import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToken } from '../actions/authActions';

class AuthPage extends Component {
  componentDidMount() {
    const { me } = this.context.router.location.query;
    this.props.addToken(me);
    this.context.router.push('/');
  }

  render() {
    return (
      <div>
        <p>Authorizing.......</p>
      </div>
    );
  }
}

AuthPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

AuthPage.propTypes = {
  addToken: PropTypes.func.isRequired,
};

export default connect(
  null,
  { addToken }
)(AuthPage);
