import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

const ShowErrors = ({ errors, icon, header }) =>
  !_.isEmpty(errors) ? (
    <Message
      negative
      icon={icon || 'cancel'}
      header={header || 'Something went wrong!'}
      list={Object.keys(errors).map(errorKey => (
        <li key={errorKey}>
          <strong>{errorKey}: </strong>
          {errors[errorKey]}
        </li>
      ))}
    />
  ) : null;

ShowErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  icon: PropTypes.string,
  header: PropTypes.string,
};

export default ShowErrors;
