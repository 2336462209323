import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';

// Import components.
import GranteeViewCurrentYearTab from './GranteeViewCurrentYearTab';
import GranteeViewNextYearTab from './GranteeViewNextYearTab';

class GranteeViewCalendarTab extends Component {
  render() {
    const {
      selectedGrantee,
      showAlert,
      submitAction,
      saveAction,
      closeAction,
      previouslySavedGrantee,
      fedHolidays,
      linkExpiration,
      updateGranteeData,
      closeUpdateAction,
    } = this.props;

    return (
      <Tab
        panes={[
          {
            menuItem: 'Current Program Year',
            render: () => (
              <Tab.Pane>
                <GranteeViewCurrentYearTab
                  previouslySavedGrantee={previouslySavedGrantee}
                  selectedGrantee={selectedGrantee}
                  fedHolidays={fedHolidays}
                  showAlert={showAlert}
                  submitAction={submitAction}
                  saveAction={saveAction}
                  closeAction={closeAction}
                  linkExpiration={linkExpiration}
                  updateGranteeData={updateGranteeData}
                  closeUpdateAction={closeUpdateAction}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Next Program Year',
            render: () => (
              <Tab.Pane>
                <GranteeViewNextYearTab />
              </Tab.Pane>
            ),
          },
        ]}
      />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeViewCalendarTab);
