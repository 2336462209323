import axios from 'axios';
import fileDownload from 'js-file-download';
import base64 from 'base64-arraybuffer';

import { getFollowupRecommendationsResponse } from './reviewActions';
import { downloadBlob } from '../components/Shared/FormHelpers/helpers/utils';

import {
  REPORT_LIST_FETCHED,
  REPORT_EXTERNAL_USERS_FETCHED,
  REPORT_UPDATED,
  REPORT_VERSION_DATA_FETCHED,
  REPORT_STATUS_DATA_FETCHED,
  REPORT_SHARED,
  REPORT_SHARED_PDF,
  REPORT_SAVED,
  REPORT_TEMPLATE,
  REPORT_DRAFT_TEMPLATE,
  ADD_ERROR,
  REMOVE_ERROR,
  REPORT_FINDINGS,
  REPORT_GRANTEE_CONTACT,
  REPORT_STAGE_UPDATED,
  REPORT_UPLOADED_COMMENT_FILES,
  REPORT_COMMENT_FILE_NAMES,
  REPORT_COMMENT_FILE_LIST,
  REPORT_COMMENT_FILE_CONTENTS,
  REPORT_SEARCH_PATTERNS,
  REPORT_SAVED_FOLLOWUP_RECOMMENDATIONS,
  REPORT_DEADLINE_DATES,
  REPORT_ASSIGNEES_FILTER,
  SET_REPORT_CONTENT,
  SEND_REPORT_COMMENT,
  FINALIZED_REPORT_DOWNLOADED,
  REPORT_NEW_FETCHED,
} from '../redux/types';

export const SET_CLASS_SUMMARY = 'SET_CLASS_SUMMARY';

// TODO: Replace with new implementation.
export function setClassSummary(classSummary) {
  return {
    type: SET_CLASS_SUMMARY,
    classSummary,
  };
}

export function getReports(reports) {
  return {
    type: REPORT_LIST_FETCHED,
    reports,
  };
}

export function getUploadCommentFiles(commentFilesUploaded) {
  return {
    type: REPORT_UPLOADED_COMMENT_FILES,
    commentFilesUploaded,
  };
}

export function getDraftReport(reportDraftTemplate) {
  return {
    type: REPORT_DRAFT_TEMPLATE,
    reportDraftTemplate,
  };
}

export function getExternalUsers(externalUsers) {
  return {
    type: REPORT_EXTERNAL_USERS_FETCHED,
    externalUsers,
  };
}

export function reportUpdated(report) {
  return {
    type: REPORT_UPDATED,
    report,
  };
}

export function getReportTemplate(template, reportType) {
  return {
    type: REPORT_TEMPLATE,
    template,
    reportType: reportType,
  };
}

export function getReportFindings(responses) {
  return {
    type: REPORT_FINDINGS,
    responses,
  };
}

export function getReportVersionData(reportVersionData) {
  return {
    type: REPORT_VERSION_DATA_FETCHED,
    reportVersionData,
  };
}

export function getReportStatusData(reportStatusData) {
  return {
    type: REPORT_STATUS_DATA_FETCHED,
    reportStatusData,
  };
}

export function getReportSharedResult(reportSharedResult) {
  return {
    type: REPORT_SHARED,
    reportSharedResult,
  };
}

export function getReportSharedPDFResult(reportSharedPDFResult) {
  return {
    type: REPORT_SHARED_PDF,
    reportSharedPDFResult,
  };
}

export function getReportSavedResult(reportSavedResult) {
  return {
    type: REPORT_SAVED,
    reportSavedResult,
  };
}

export function getReportGranteeContact(reportGranteeContact) {
  return {
    type: REPORT_GRANTEE_CONTACT,
    reportGranteeContact,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function getUpdateReportStageResponse(updateReportStageResponse) {
  return {
    type: REPORT_STAGE_UPDATED,
    updateReportStageResponse,
  };
}

export function getfetchCommentsFileListResponse(commentFileList) {
  return {
    type: REPORT_COMMENT_FILE_LIST,
    commentFileList,
  };
}

export function getfetchCommentFileNamesResponse(commentFileNames) {
  return {
    type: REPORT_COMMENT_FILE_NAMES,
    commentFileNames,
  };
}

export function getfetchFileContentsResponse(fetchedFileContents) {
  return {
    type: REPORT_COMMENT_FILE_CONTENTS,
    fetchedFileContents,
  };
}
export const finalizedReportDownloaded = () => ({
  type: FINALIZED_REPORT_DOWNLOADED,
});

export function getReportNew(payload) {
  return {
    type: REPORT_NEW_FETCHED,
    payload,
  };
}

export function getfetchSearchReports(
  fetchedSearchReports,
  searchType,
  searchQuery
) {
  fetchedSearchReports.searchType = searchType;
  fetchedSearchReports.searchQuery = searchQuery;

  return {
    type: REPORT_SEARCH_PATTERNS,
    fetchedSearchReports,
  };
}

export function getDeadlineDates(fetchedDeadlineDates) {
  return {
    type: REPORT_DEADLINE_DATES,
    fetchedDeadlineDates,
  };
}

export function getReportSavedFollowupRecommendations(
  savedFollowupRecommendations
) {
  return {
    type: REPORT_SAVED_FOLLOWUP_RECOMMENDATIONS,
    savedFollowupRecommendations,
  };
}

export function fetchedAssigneesFilter(fetchedAssigneesFilterList) {
  return {
    type: REPORT_ASSIGNEES_FILTER,
    fetchedAssigneesFilterList,
  };
}

export function setReportContent(reportData) {
  return {
    type: SET_REPORT_CONTENT,
    reportData,
  };
}

export const updateConfirmedTos = payload => {
  return { type: SEND_REPORT_COMMENT, payload };
};

export const fetchReportList = reportInput => dispatch =>
  axios.post(`/report/list`, reportInput).then(function(response) {
    dispatch(getReports(response.data));
  });

export function fetchExternalUsers(action) {
  return dispatch => {
    axios
      .post(`/report/externalUsers`, {
        filters: {
          group: action.group,
          reviewId: action.reviewId,
          reviewType: action.reviewType,
        },
      })
      .then(function(response) {
        dispatch(getExternalUsers(response.data));
      })
      .catch(function(error) {
        dispatch(setError(error));
      });
  };
}

export function fetchReportVersionData(versionId, cb) {
  return dispatch => {
    axios
      .post('/report/version?reportKey=' + versionId, {})
      .then(
        cb ||
          function(response) {
            dispatch(getReportVersionData(response.data));
          }
      )
      .catch(function(error) {
        dispatch(setError(error));
        console.log(error);
      });
  };
}

export function fetchReportStatusData(reviewId) {
  return dispatch => {
    axios
      .post(`/report/status?reviewId=${reviewId}`, {})
      .then(function(response) {
        dispatch(getReportStatusData(response.data || {}));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function reportShareAction(inputReq, callback) {
  return dispatch => {
    axios
      .post(`/report/share/html`, inputReq)
      .then(response => {
        dispatch(fetchReportNew({ reviewId: inputReq.reviewID }));
        return (
          callback(response) || dispatch(getReportSharedResult(response.data)),
          callback || console.error
        );
      })
      .catch(function(error) {
        return callback(error) || console.error;
      });
  };
}
export function reportConversationReplyAction(inputReq, callback, reviewId) {
  return dispatch => {
    axios
      .post(`/report/conversations`, inputReq)
      .then(response => {
        dispatch(fetchReportNew({ reviewId: reviewId }));
        return callback(response) || null, callback || console.error;
      })
      .catch(function(error) {
        return callback(error) || console.error;
      });
  };
}
export function reportExternalCommentReplyAction(inputReq, callback) {
  return dispatch => {
    axios
      .post(`/report/externalComments`, inputReq)
      .then(response => {
        dispatch(fetchReportNew({ reviewId: inputReq.reviewId }));
        return callback(response) || null, callback || console.error;
      })
      .catch(function(error) {
        return callback(error) || console.error;
      });
  };
}

export function reportShareMultipleAction(inputReq, callback, reviewId) {
  return dispatch => {
    axios
      .post(`/report/share/multiple`, inputReq)
      .then(response => {
        return callback(response) || null, callback || console.error;
      })
      .catch(function(error) {
        return callback(error) || console.error;
      });
  };
}

export function reportSharePDFAction(inputReq, callback) {
  return dispatch => {
    axios
      .post(`/report/share/pdf`, inputReq)
      .then(response => {
        dispatch(fetchReportNew({ reviewId: inputReq.reviewID }));
        return (
          callback(response) ||
            dispatch(getReportSharedPDFResult(response.data)),
          callback || console.error
        );
      })
      .catch(function(error) {
        return callback(error) || console.error;
      });
  };
}

export function reportSaveAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/save`, inputReq)
      .then(function(response) {
        dispatch(getReportSavedResult(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function reportTemplateAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/template`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function reportNewFaTwoAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/newFA2template`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(() =>
        dispatch(getReportTemplate('Error', inputReq.templateType || null))
      );
  };
}

export function newDraftReportAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/surveyDraftReport`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(() =>
        dispatch(getReportTemplate('Error', inputReq.templateType || null))
      );
  };
}

export function reportNewFaOneAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/newFA1template`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(() =>
        dispatch(getReportTemplate('Error', inputReq.templateType || null))
      );
  };
}

export function reportRanAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/RANtemplate`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(() =>
        dispatch(getReportTemplate('Error', inputReq.templateType || null))
      );
  };
}

export function reportSpecialAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/special-template`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(() =>
        dispatch(getReportTemplate('Error', inputReq.templateType || null))
      );
  };
}

export function reportAIANDEFAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/aian-def-template`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(() =>
        dispatch(getReportTemplate('Error', inputReq.templateType || null))
      );
  };
}

export function reportERSEAAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/ersea-template`, inputReq)
      .then(function(response) {
        dispatch(
          getReportTemplate(response.data, inputReq.templateType || null)
        );
      })
      .catch(() =>
        dispatch(getReportTemplate('Error', inputReq.templateType || null))
      );
  };
}

export function reportFindingsAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/findings`, inputReq)
      .then(function(response) {
        dispatch(getReportFindings(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function fetchClassSummary(reviewId) {
  return dispatch => {
    axios
      .post('/report/classSummary', {
        type: 'ClassSummary',
        reviewId,
      })
      .then(function(response) {
        dispatch(setClassSummary(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export const updateReport = (reviewId, reportUpdate) => dispatch => {
  axios
    .patch(`/report/${reviewId}`, reportUpdate)
    .then(response => {
      dispatch(reportUpdated(response.data));
    })
    .catch(error => {
      dispatch(setError(error));
    });
};

export const fetchReportDraftTemplate = (requestInput, type) => dispatch => {
  if (
    type === 'FA-2' ||
    type === 'CLASS' ||
    type === 'AIAN-CLASS' ||
    type === 'PR-CLASS' ||
    type === 'VP-CLASS' ||
    type === 'FA2-CR' ||
    type === 'AIAN CLASS Self-Observations' ||
    type === 'CLASS-Video'
  ) {
    axios
      .post(`/report/draftReport/${type}`, requestInput)
      .then(response => dispatch(getDraftReport(response.data)))
      .catch(err => dispatch(setError(err)));
  }
  if (type === 'Follow-up') {
    axios
      .post(`/report/followupDraft`, requestInput)
      .then(response =>
        dispatch(
          getReportTemplate(response.data, { reportType: 'Follow-up' } || null)
        )
      )
      .catch(err => dispatch(setError(err)));
  }
};

export const fetchReportGranteeContact = reviewId => dispatch =>
  axios
    .get(`/report/granteecontact/${reviewId}`)
    .then(response => dispatch(getReportGranteeContact(response.data)));

export const updateReportStage = requestInput => dispatch =>
  axios
    .patch(`/report/updateReportStage`, requestInput)
    .then(response => dispatch(getUpdateReportStageResponse(response.data)));

export const downloadPdfReport = (hashContent, filename) => dispatch =>
  axios
    .post(
      `/report-noauth/convertToPdf`,
      { hashContent },
      { responseType: 'arraybuffer' }
    ) // This is required for file download to work
    .then(response => {
      const { data } = response;

      if (!data) return;

      const contentType = response.headers['content-type'];

      fileDownload(response.data, `${filename}.pdf`, contentType);
      return response;
    });

export const saveFollowupRecommendations = (requestInput, cb) => dispatch => {
  axios
    .post(`/report/saveFollowupRecommendations`, requestInput)
    .then(
      response =>
        dispatch(getReportSavedFollowupRecommendations(response.data)) &&
        dispatch(getFollowupRecommendationsResponse(response.data)) &&
        cb(response)
    )
    .catch(err => cb(err) || console.error);
};

export const fetchDeadlineDates = requestInput => dispatch =>
  axios
    .get(`/report/getDeadlineDates/${requestInput.reviewId}`)
    .then(response => dispatch(getDeadlineDates(response.data)));

export const fetchCommentsFileList = requestInput => dispatch =>
  axios
    .post(`/report/fetchCommentsFileList`, requestInput)
    .then(response =>
      dispatch(getfetchCommentsFileListResponse(response.data))
    );

export const fetchCommentFileNames = requestInput => dispatch =>
  axios
    .post(`/report-noauth/fetchCommentFileNames`, requestInput)
    .then(response =>
      dispatch(getfetchCommentFileNamesResponse(response.data))
    );

export const fetchSearchReports = requestInput => dispatch =>
  axios
    .post(`/search/searchpatterns`, requestInput)
    .then(response =>
      dispatch(
        getfetchSearchReports(
          response.data,
          requestInput.searchCriteria,
          requestInput.searchStrings
        )
      )
    );

export const fetchFileContents = (requestInput, withToken) => dispatch =>
  axios
    .post(
      (withToken ? '/report' : '/report-noauth') + '/fetchFileContents',
      requestInput
    )
    .then(response => {
      const { data } = response;

      if (!data) return;

      const content = response.data.fileBytes;
      const fileName = requestInput.displayFileName || 'file';
      const blob = new Blob([base64.decode(content)], {
        type: 'application/octet-stream',
      });

      downloadBlob(blob, fileName);

      return response;
    });

export const uploadCommentFiles = (files, hashContent) => dispatch =>
  Promise.all(
    files.map(file => {
      let formDataContent = new FormData();
      formDataContent.append('file', file, file.name);
      formDataContent.append('displayFileName', file.name);
      formDataContent.append('hashContent', hashContent);
      return new Promise((resolve, reject) => {
        fetch(`/report-noauth/savecommentfiles`, {
          method: 'POST',
          body: formDataContent,
        }).then(resolve, reject);
      });
    })
  ).then(
    res => {
      return dispatch(
        getUploadCommentFiles({
          files: files,
          responses: res,
        })
      );
    },
    err => {
      return dispatch(
        getUploadCommentFiles({
          files: files,
          error: err.message,
        })
      );
    }
  );

export const fetchAssigneesFilter = requestInput => dispatch =>
  axios
    .post(`/report/v1/assignees`, requestInput)
    .then(response => dispatch(fetchedAssigneesFilter(response.data)));

export const generateReportAction = requestInput => dispatch =>
  axios
    .post(`/report/generateReport`, requestInput, {
      responseType: 'arraybuffer',
    })
    .then(response => {
      const { data } = response;

      if (!data) return;

      const contentType = response.headers['content-type'];

      fileDownload(
        response.data,
        `${requestInput.reviewID}-${requestInput.versionNumber}.pdf`,
        contentType
      );
      dispatch(finalizedReportDownloaded());
      return response;
    });

export const fetchReportContent = hashId => dispatch =>
  axios
    .get(`/report-noauth/content/${encodeURIComponent(hashId)}`, {
      withCredentials: true,
    })
    .then(response => {
      response.data.closed = false;
      dispatch(setReportContent(response.data));
    })
    .catch(() => {
      let response = {};
      (response.data || {}).closed = true;
      dispatch(setReportContent(response.data));
    });

export const sendComment = (
  requestInput,
  message,
  confirmTosState
) => dispatch =>
  axios
    .post(`/report-noauth/addComment`, requestInput)
    .then(() => {
      if (!message) {
        alert('Comment Submitted');
      }
      if (confirmTosState) {
        dispatch(
          updateConfirmedTos({
            error: '',
            confirmedTos: true,
          })
        );
      } else {
        dispatch(
          updateConfirmedTos({
            error: '',
          })
        );
      }
    })
    .catch(err => {
      // fixme add action to handle error
      console.error(err);
    });

export const HSESDownloadReport = guid => dispatch =>
  axios
    .post(
      `/report-noauth/HSESDownloadReport`,
      { guid },
      { responseType: 'arraybuffer' }
    ) // This is required for file download to work
    .then(response => {
      const { data } = response;

      if (!data) return;

      const contentType = response.headers['content-type'];

      fileDownload(response.data, `${guid}.pdf`, contentType);
      return response;
    });

export const fetchReportNew = requestInput => dispatch =>
  axios
    .post(`/report/fetchReportNew`, requestInput)
    .then(response => dispatch(getReportNew(response.data)));

export function reportSaveActionNew(inputReq) {
  return dispatch => {
    axios
      .post(`/report/save`, inputReq)
      .then(function(response) {
        dispatch(fetchReportNew({ reviewId: response.data.reviewID }));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function surveyReportSaveAction(inputReq) {
  return dispatch => {
    axios
      .post(`/report/saveReport`, inputReq)
      .then(function(response) {
        dispatch(getReportSavedResult(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function surveyReportSaveActionNew(inputReq) {
  return dispatch => {
    axios
      .post(`/report/saveReport`, inputReq)
      .then(function(response) {
        dispatch(fetchReportNew({ reviewId: response.data.reviewID }));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function fetchReportEditDetails(inputReq) {
  return dispatch => {
    return axios
      .post(`/report/reportEditDetails`, inputReq)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}

export function saveReportDetails(inputReq) {
  return dispatch => {
    return axios
      .post(`/report/reportSaveDetails`, inputReq)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  };
}
