import LayoutField from 'react-jsonschema-form-layout-2';
export const formSchema = {
  type: 'object',
  required: [],
  properties: {
    additionalCitations: {
      type: 'array',
      title: 'Select Citation number to enter additional Findings',
      items: {
        type: 'object',
        properties: {
          citation: {
            type: 'string',
            title: 'Add Findings',
            enum: [
              '1302.33(b)(1)',
              '1302.70(a)',
              '1302.71(a)',
              '1302.102(a)(3)',
              '1302.102(c)(2)(ii)-(iii)',
              '1302.31(b)(1)(i)',
              '1302.31(b)(1)(ii)',
              '1302.31(b)(2)',
              '1302.31(e)',
              '1302.33(b)(2)',
              '1302.61(a)',
              '1302.71(d)',
              '1302.32(a)(2)',
              '1302.45(a)(1)',
              '1302.45(b)(2)',
              '1302.92(b)(5)',
              '1302.92(c)(1)',
              '1302.35(a)',
              '1302.35(c)',
              '1302.35(c)(4)',
              '1302.35(e)(2)',
              '1302.12(c)',
              '1302.12(e)',
              '1302.12(k)',
              '1302.14(b)(1)',
              '1302.16(a)(2)(i)',
              '1302.16(a)(2)(ii-iii)',
              '1302.16(a)(2)(iv)',
              '1302.16(b)',
              '642(g)',
              '1302.45(b)(5)',
              '1302.50(b)(3)',
              '1302.52(c)(3)',
              '1302.53(a)(2)(vi)',
              '1302.51(a)',
              '1302.51(b)',
              '1302.34(b)(2)',
              '1302.46(b)(1)',
              '1302.50(b)(1)',
              '1302.62(b)',
              '1302.71(b)(1)',
              '1302.71(b)(2)',
              '1302.101(a)(3)',
              '1302.102(b)(1)(iii)',
              '642(c)(1)(E)(iv)(VII)(bb) ',
              '642(c)(2)(D)(iv)',
              '642(d)(3)',
              '1303.12',
              '1303.52(b)',
              '642(d)(2)(A)-(I)',
              '642(c)(1)(E)(iv)(VII)(aa) ',
              '642(c)(1)(B)(i)',
              '75.303(a)(b) and (e)',
              '1302.91(c)',
              '653(a)',
              '75.414',
              '75.430(i)',
              '75.405(a)',
              '75.302(b)(4)',
              '75.302(b)(3)',
              '75.305(b)(1)',
              '75.303(c)',
              '75.303(d)',
              '75.302(b)(7)',
              '75.403(a)-(g)',
              '75.329(a)-(b)',
              '75.332',
              '75.328(a)(4)(5)(7)',
              '75.328(b)',
              '1303.46(b)(1)-(4)',
              '1303.52(c) ',
              '75.308(c)(1)(xi)',
              '75.320(d)(2)',
              '1302.41(a)',
              '1302.42(a)(1)',
              '1302.42(b)(1)(i)',
              '1302.42(c)-(d)',
              '1302.45(a)(2)',
              '1302.45(b)(1)',
              '1302.45(b)(3)',
              '1302.43',
              '1302.44(a)(1)',
              '1302.47(b)(7)(vi)',
              '1302.47(b)(2)(v)',
              '1302.47(b)(5)(i)',
              '1302.47(b)(5)(ii)',
              '1302.47(b)(5)(iii)',
              '1302.47(b)(5)(iv)',
              '1302.47(b)(1)(ix)',
              '648A(g)(3) ',
              '1302.90(c)',
              '1302.80',
              '1302.81',
              '1302.91(a)',
              '1302.92(b)',
              '1302.101(a)(1)',
              '1302.101(a)(2)',
              '1302.102(b)(1)(i)',
              '1302.102(c)(2)(iv)',
              '1302.102(d)',
              '1301.2(b)(2)',
              '642(c)(1)(E)(ii)',
              '642(c)(1)(E)(iv)(V)(bb)',
              '642(c)(2)(D)(i)',
            ],
          },
          citationExplanation: {
            type: 'string',
            title: 'Please explain your response',
          },
          evidenceFiles: {
            type: 'array',
            title: 'Attach Supporting Documents',
            items: {
              type: 'string',
            },
          },
        },
      },
    },
  },
};

export const fields = {
  layout: LayoutField,
};

export const uiSchema = {
  additionalCitations: {
    items: {
      'ui:field': 'layout',
      'ui:layout': [
        {
          citation: { md: 3 },
          citationExplanation: { md: 9 },
        },
        {
          evidenceFiles: { md: 12 },
        },
      ],
      citation: {
        'ui:widget': 'select',
      },
      citationExplanation: {
        'ui:widget': 'textarea',
      },
      evidenceFiles: {
        'ui:options': {
          orderable: false,
          removable: true,
        },
        items: {
          'ui:widget': 'file',
        },
      },
    },
    'ui:options': {
      orderable: false,
    },
  },
};
