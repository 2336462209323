import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import Unauthorized from '../components/Shared/Unauthorized';

export default function(ComposedComponent, authorize) {
  class Authenticate extends React.Component {
    componentWillMount() {
      // if (!this.isJwtValid()) {
      //   store.dispatch(setCurrentUser({}));
      // }

      if (!this.props.isAuthenticated) {
        this.context.router.push('/login');
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.isAuthenticated) {
        this.context.router.push('/');
      }
    }

    isJwtValid() {
      const decoded = localStorage.token ? jwtDecode(localStorage.token) : {};

      if (_.isEmpty(decoded)) return false;

      const { exp } = decoded;

      if (moment().isSameOrAfter(moment.unix(exp))) {
        localStorage.removeItem('token');
        this.context.router.push('/login');
        return false;
      }

      return true;
    }

    isUserAuthorized() {
      const { currentUser } = this.props;

      if (authorize && authorize.length) {
        // Check if user roles match the ACL rule.
        if (_.intersection(authorize, currentUser.roles).length) return true;
        return false;
      }

      if (
        this.props &&
        this.props.routes &&
        ((this.props.routes.length > 2 &&
          this.props.routes[2].name === 'Detail') ||
          (this.props.routes.length > 1 &&
            [
              'Reports',
              'New Survey Outcome',
              'Reviews',
              'Data-Collections',
              'Task',
            ].includes(this.props.routes[1].name))) &&
        this.props.selectedReview &&
        this.props.selectedReview.reviewAccessLevel &&
        this.props.selectedReview.reviewAccessLevel.isReviewAccessible ===
          false &&
        this.props.selectedReview.reviewAccessLevel.hasOwnProperty(
          'isReadOnlyReview'
        ) &&
        this.props.selectedReview.reviewAccessLevel.isReadOnlyReview === -1 &&
        (this.props.selectedReview || {}).reviewStatus !== 'Report Signed' &&
        (this.props.selectedReview || {}).reviewStatus !== 'Shipped'
      ) {
        return false;
      }

      // It's not a restricted route. Just show it.
      return true;
    }

    render() {
      if (this.isUserAuthorized()) {
        return <ComposedComponent {...this.props} />;
      } else {
        return <Unauthorized />;
      }
    }
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired,
  };

  Authenticate.contextTypes = {
    router: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      currentUser: state.auth.user,
      selectedReview: state.review.selectedReview,
    };
  }

  return connect(mapStateToProps)(Authenticate);
}
