import React, { useState, useEffect } from 'react';
import {
  Card,
  Checkbox,
  Button,
  Form,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import AmsAlert from '../../../utils/AmsAlert';
import { determineFindingStatus, findingCardClasses } from './FindingsUtils';

//import actions
import { confirmGrantees } from '../../../actions/reviewActions';

const FindingsGranteeCard = props => {
  const [loading, setLoading] = useState(false);
  const { selectedReview } = useSelector(state => state.review);
  const { grantees } = selectedReview;

  const { confirmedGrantees, citation } = props;

  const dispatch = useDispatch();

  const [checkedGrantees, setCheckedGrantees] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (selectedReview && selectedReview.grantees) {
      // Initialize all grantees as not checked

      setCheckedGrantees(
        selectedReview.grantees.map(g => ({ ...g, checked: false }))
      );
    }
  }, [selectedReview, confirmedGrantees]);

  const handleCheckboxChange = granteeId => {
    setCheckedGrantees(
      checkedGrantees.map(g =>
        g.granteeId === granteeId ? { ...g, checked: !g.checked } : g
      )
    );
  };

  if (
    !selectedReview ||
    !selectedReview.grantees ||
    selectedReview.grantees.length === 0
  ) {
    return null;
  }

  const renderGranteeCheckboxes = () => {
    return grantees.map(grantee => {
      return (
        <Checkbox
          disabled={
            selectedReview.readOnly || grantee.granteeStatus === 'Inactive'
          }
          key={`grantee-id-${grantee.granteeId}`}
          id={`grantee-id-${grantee.granteeId}`}
          role="checkbox"
          aria-checked={grantee.checked}
          onChange={() => handleCheckboxChange(grantee.granteeId)}
          checked={grantee.checked}
          label={
            grantee.granteeId +
            `${grantee.granteeStatus === 'Inactive' ? ' (Inactive)' : ''}`
          }
        />
      );
    });
  };

  const saveConfirmedGranteeIDs = () => {
    setLoading(true);
    let confirmedGrantees = checkedGrantees
      .filter(grantee => grantee.checked)
      .map(({ granteeId, granteeName }) => ({ granteeId, granteeName }));

    const input = {
      findingId: props.findingId,
      confirmedGrantees,
    };

    dispatch(
      confirmGrantees(
        input,
        { reviewId: selectedReview.reviewId },
        selectedReview,
        { isNewForms: selectedReview.isNewForms }
      )
    )
      .then(e => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`Are you sure?`}
        onConfirm={() => {
          setShowAlert(false);
          saveConfirmedGranteeIDs();
          props.handleSaveButtonAction('SAVE');
        }}
        onCancel={() => {
          setShowAlert(false);
        }}
      />
    );
  };

  const handleConfirmation = () => {
    setShowAlert(true);
  };

  return (
    <Card
      className={findingCardClasses(
        'findings-card',
        determineFindingStatus(selectedReview, citation)
      )}
    >
      <Dimmer active={loading} inverted>
        <Loader active={loading} inverted>
          Loading
        </Loader>
      </Dimmer>
      {showConfirmationAlert()}
      <Card.Content
        style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
        header="Grantee ID"
      />
      {confirmedGrantees && confirmedGrantees.length === 0 ? (
        <Form style={{ margin: '10px' }}>
          <div role="group" aria-labelledby="checkbox-grantee-group"></div>
          {renderGranteeCheckboxes()}
          <Button
            disabled={selectedReview.readOnly}
            onClick={handleConfirmation}
            fluid
            style={{ backgroundColor: '#245E9D', color: 'white' }}
          >
            Save ID
          </Button>
        </Form>
      ) : (
        <div style={{ margin: '10px' }}>
          {confirmedGrantees &&
            confirmedGrantees.map(grantee => {
              return (
                <span key={grantee.granteeId}>
                  <span>
                    {grantee.granteeId}
                    {grantee.status === 'Inactive' ? ' (Inactive)' : ''}
                  </span>
                  <br />
                </span>
              );
            })}
        </div>
      )}
    </Card>
  );
};

export default FindingsGranteeCard;
