import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import actions.
import { selectTab } from '../../actions/adminActions';

class ReportAdminPage extends Component {
  componentWillMount() {
    this.props.selectTab({
      key: 2,
      pageTitle: 'Reports',
    });
  }

  render() {
    return <div>Reports Admin Page</div>;
  }
}

ReportAdminPage.propTypes = {};

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  { selectTab }
)(ReportAdminPage);
