import React, { useEffect, useState } from 'react';
import { Form, Button, Icon, TextArea } from 'semantic-ui-react';
import $ from 'jquery';
import { useSelector } from 'react-redux';

const NoteForm = ({ submitNote }) => {
  const [content, setContent] = useState('');
  const [showInput, setShowInput] = useState(false);

  const { selectedReview } = useSelector(state => state.review);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (
      ((selectedReview || {}).reviewAccessLevel &&
        (selectedReview || {}).reviewAccessLevel.isReviewAccessible ===
          false) ||
      (selectedReview || {}).reviewStatus === 'Report Signed' ||
      (selectedReview || {}).reviewStatus === 'Shipped'
    ) {
      setReadOnly(true);
    }
  }, [selectedReview]);

  const handleSubmit = () => {
    setShowInput(false);
    if (content === '') return null;

    submitNote(content);
    setContent('');
  };

  const expandTextArea = ({ target }) => {
    setContent(target.value);
    const rows = Math.round(
      $(target)
        .val()
        .split('\n').length || 3,
      $(target).attr('rows') || 3
    );

    $(target).attr('rows', rows < 3 ? 3 : rows);
    $(target).css('width', $(target).css('width'));
  };

  return (
    <Form onSubmit={handleSubmit}>
      {!showInput && !readOnly ? (
        <Button
          onClick={() => setShowInput(true)}
          className="hollow-button"
          size="small"
          fluid
          circular
        >
          <Icon name="plus"></Icon>
          Add a note...
        </Button>
      ) : (
        <div
          style={{
            padding: '5px',
            border: '1px solid #dadce0',
            minHeight: '50px',
            borderRadius: '7px',
            margin: '5px',
            background: 'white',
          }}
        >
          <TextArea
            className={'add-note-textarea'}
            onChange={expandTextArea}
            disabled={readOnly}
            name="note"
            title="Add a note"
            placeholder="Add a note..."
          ></TextArea>

          {!readOnly && (
            <div style={{ overflow: 'auto' }}>
              <Form.Button size="tiny" floated="right">
                Done
              </Form.Button>
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

export default NoteForm;
