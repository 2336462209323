import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header, Message } from 'semantic-ui-react';
// Import Actions
import { fetchERSEAChildFilesSummary } from '../../../actions/surveyActions';
// Import components.
import ReviewLayout from '../../../utils/layout/ReviewLayout';
import './table.css';

export default function ChildFileOutcomeNew(props) {
  const { childFilesERSEASummary } = useSelector(state => state.survey);
  const dispatch = useDispatch();

  const tableOverallHeaders = [
    'Outcome (Yes/No)',
    'Outcome Details (Child file id’s)',
  ];
  const tableReviewResultHeaders = ['Count', 'Percentage'];
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { params, location } = props.props;
    if (!params.id) return;
    let input = {
      reviewId: params.id,
      amsSubmissionId: params.amsSubmissionId,
      amsFormId: params.amsFormId,
      reviewType: location?.query?.reviewType,
    };
    dispatch(fetchERSEAChildFilesSummary(input))
      .then(res => {})
      .catch(err => {
        setErrors({
          errors: {
            ...errors,
            fetchERSEAChildFilesSummary:
              (err.response && err.response.data.message) || '',
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TableRowData = ({ rowData, type, rowType }) => (
    <>
      {rowData &&
        rowData.map(rowDataItem => {
          if ('overAllOutcome' === type) {
            // if ('criteria1' === rowType) {
            //   return (
            //     <>
            //       <td>
            //         {(
            //           (rowDataItem && rowDataItem['missingChidIdsListCount']) ||
            //           {}
            //         ).isThereAnyDuplicateChildIds || false
            //           ? 'Yes'
            //           : 'No'}
            //       </td>
            //     </>
            //   );
            // } else
            if ('criteria2' === rowType) {
              return (
                <>
                  <td>
                    {rowDataItem &&
                    rowDataItem['unavailableReviewChildIdsCount'] > 0
                      ? 'Yes'
                      : 'No'}
                  </td>
                  <td>
                    {(
                      rowDataItem && rowDataItem['unavailableReviewChildIds']
                    ).join(', ')}
                  </td>
                </>
              );
            }
          } else {
            if ('criteria1' === rowType) {
              return (
                <>
                  <td>{rowDataItem['filesReviewed']['count']}</td>
                  <td>{rowDataItem['filesReviewed']['percent']}</td>
                </>
              );
            } else if ('criteria2' === rowType) {
              return (
                <>
                  <td>{rowDataItem['fileErrors']['count']}</td>
                  <td>{rowDataItem['fileErrors']['percent']}</td>
                </>
              );
            }
          }
        })}
    </>
  );

  const Table = ({ data, type }) => {
    let title = 'OVERALL ELIGIBILITY FILE SUBMISSION RESULTS';
    let short_description = null;

    let description = <div>Are any child files unavailable for review?</div>;

    if (type === 'k130212') {
      title =
        'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: DOCUMENTATION - 1302.12(k)';

      short_description = <div>Total # and % of files reviewed</div>;
      description = (
        <div>
          <p>
            Total # and % of files with any one of the four documentation errors
          </p>
          <ul>
            <li>No eligibility determination record (EDR) for child</li>
            <li>Eligibility category not indicated in EDR</li>
            <li>
              No documentation that an in-person or phone interview took place
            </li>
            <li>Eligibility documentation not retained</li>
          </ul>
        </div>
      );
    } else if (type === 'c130212') {
      title =
        'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: ELIGIBILITY - 1302.12(c) / 1302.12(e)';
      short_description = <div>Total # and % of files reviewed</div>;
      description = (
        <div>
          <p>
            Total # and % of files in error (over allowance) for any of the
            following income categories
          </p>
          <ul>
            <li>Family income is between 100-130% of poverty guidelines</li>
            <li>Family income is greater than 130% of poverty guidelines</li>
            <li>No eligible category determination made by program</li>
          </ul>
        </div>
      );
    }
    return (
      <>
        <h1>{title}</h1>
        <div className="App" id="childFileOutcome">
          <table className="table table-hover  table-review">
            <thead>
              <tr>
                <td rowspan="1"></td>
                {data &&
                  data.map((item, index) => (
                    <TableHeadItem item={`Grant No. ${item.granteeId}`} />
                  ))}
              </tr>
              <tr>
                <th scope="col">Criteria</th>
                {data &&
                  data.map((item, index) => {
                    if (type === 'overAllOutcome') {
                      return (
                        <>
                          {tableOverallHeaders &&
                            tableOverallHeaders.map(item1 => (
                              <TableHeadItemCol item={item1} />
                            ))}
                        </>
                      );
                    } else {
                      return (
                        <>
                          {tableReviewResultHeaders &&
                            tableReviewResultHeaders.map(item1 => (
                              <TableHeadItemCol item={item1} />
                            ))}
                        </>
                      );
                    }
                  })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {null !== short_description && (
                  <TableHeadItemRow item={short_description} />
                )}
                <TableRowData
                  rowData={data}
                  type={type}
                  rowType={'criteria1'}
                />
              </tr>
              <tr>
                <TableHeadItemRow item={description} />
                <TableRowData
                  rowData={data}
                  type={type}
                  rowType={'criteria2'}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const TableHeadItem = ({ item }) => {
    return <th colspan="2">{`${item}`}</th>;
  };

  const TableHeadItemCol = ({ item }) => {
    return <th scope="col">{`${item}`}</th>;
  };

  const TableHeadItemRow = ({ item }) => {
    return <th scope="col">{item}</th>;
  };

  const renderERSEAChildFiles = () => {
    return (
      <div>
        <div className="App">
          {childFilesERSEASummary &&
            null !== childFilesERSEASummary.overAllOutcome && (
              <Table
                data={
                  childFilesERSEASummary &&
                  childFilesERSEASummary.overAllOutcome
                }
                type={'overAllOutcome'}
              />
            )}
        </div>
        <div className="App">
          {childFilesERSEASummary &&
            null !== childFilesERSEASummary['k130212'] && (
              <Table
                data={
                  childFilesERSEASummary && childFilesERSEASummary['k130212']
                }
                type={'k130212'}
              />
            )}
        </div>

        <div className="App">
          {childFilesERSEASummary &&
            null !== childFilesERSEASummary['c130212'] && (
              <Table
                data={
                  childFilesERSEASummary && childFilesERSEASummary['c130212']
                }
                type={'c130212'}
              />
            )}
        </div>
      </div>
    );
  };

  const hasMessage =
    childFilesERSEASummary &&
    childFilesERSEASummary['childFilesSummaryPreqFound'] === false;
  return (
    <div>
      <ReviewLayout type={'Eligibility Files'} errors={errors}>
        <Header as="h2" content="Eligibility File Submission Results" />

        {hasMessage && (
          <Message info header={childFilesERSEASummary.message}></Message>
        )}
        {childFilesERSEASummary &&
          childFilesERSEASummary.message !==
            'This section of ERSEA was not reviewed' &&
          renderERSEAChildFiles()}
      </ReviewLayout>
    </div>
  );
}
