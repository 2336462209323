export function setWeatherIcon(code) {
  let icon = 'wi wi-cloud';

  switch (code) {
    case '0':
      icon = 'wi wi-tornado';
      break;
    case '1':
      icon = 'wi wi-storm-showers';
      break;
    case '2':
      icon = 'wi wi-tornado';
      break;
    case '3':
      icon = 'wi wi-thunderstorm';
      break;
    case '4':
      icon = 'wi wi-thunderstorm';
      break;
    case '5':
      icon = 'wi wi-snow';
      break;
    case '6':
      icon = 'wi wi-rain-mix';
      break;
    case '7':
      icon = 'wi wi-rain-mix';
      break;
    case '8':
      icon = 'wi wi-sprinkle';
      break;
    case '9':
      icon = 'wi wi-sprinkle';
      break;
    case '10':
      icon = 'wi wi-hail';
      break;
    case '11':
      icon = 'wi wi-showers';
      break;
    case '12':
      icon = 'wi wi-showers';
      break;
    case '13':
      icon = 'wi wi-snow';
      break;
    case '14':
      icon = 'wi wi-storm-showers';
      break;
    case '15':
      icon = 'wi wi-snow';
      break;
    case '16':
      icon = 'wi wi-snow';
      break;
    case '17':
      icon = 'wi wi-hail';
      break;
    case '18':
      icon = 'wi wi-hail';
      break;
    case '19':
      icon = 'wi wi-cloudy-gusts';
      break;
    case '20':
      icon = 'wi wi-fog';
      break;
    case '21':
      icon = 'wi wi-fog';
      break;
    case '22':
      icon = 'wi wi-fog';
      break;
    case '23':
      icon = 'wi wi-cloudy-gusts';
      break;
    case '24':
      icon = 'wi wi-cloudy-windy';
      break;
    case '25':
      icon = 'wi wi-thermometer';
      break;
    case '26':
      icon = 'wi wi-cloudy';
      break;
    case '27':
      icon = 'wi wi-night-cloudy';
      break;
    case '28':
      icon = 'wi wi-day-cloudy';
      break;
    case '29':
      icon = 'wi wi-night-cloudy';
      break;
    case '30':
      icon = 'wi wi-day-cloudy';
      break;
    case '31':
      icon = 'wi wi-night-clear';
      break;
    case '32':
      icon = 'wi wi-day-sunny';
      break;
    case '33':
      icon = 'wi wi-night-clear';
      break;
    case '34':
      icon = 'wi wi-day-sunny-overcast';
      break;
    case '35':
      icon = 'wi wi-hail';
      break;
    case '36':
      icon = 'wi wi-day-sunny';
      break;
    case '37':
      icon = 'wi wi-thunderstorm';
      break;
    case '38':
      icon = 'wi wi-thunderstorm';
      break;
    case '39':
      icon = 'wi wi-thunderstorm';
      break;
    case '40':
      icon = 'wi wi-storm-showers';
      break;
    case '41':
      icon = 'wi wi-snow';
      break;
    case '42':
      icon = 'wi wi-snow';
      break;
    case '43':
      icon = 'wi wi-snow';
      break;
    case '44':
      icon = 'wi wi-cloudy';
      break;
    case '45':
      icon = 'wi wi-lightning';
      break;
    case '46':
      icon = 'wi wi-snow';
      break;
    case '47':
      icon = 'wi wi-thunderstorm';
      break;
    case '3200':
      icon = 'wi wi-cloud';
      break;
    default:
      break;
  }

  return icon;
}
