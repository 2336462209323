import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

class GranteeViewExpiredMessage extends Component {
  renderDetails() {
    let { showExpiredMessage } = this.props;
    return showExpiredMessage ? (
      <Message
        header="The link to change your unavailability has expired!"
        content='You currently cannot make any changes to your unavailability because the link has expired. Please click the "Request an Extension" link on the instruction page to see if you can be granted a 2 week exension.'
      />
    ) : (
      <div />
    );
  }

  render() {
    return <div> {this.renderDetails()} </div>;
  }
}

export default GranteeViewExpiredMessage;
