import React from 'react';
import Helmet from 'react-helmet';

export default function BrowserTitle({ title, header }) {
  const prefix = title || header;
  if (prefix) {
    return (
      <Helmet>
        <title>{prefix} | Head Start - Aligned Monitoring System</title>
      </Helmet>
    );
  }
  return (
    <Helmet>
      <title>Head Start - Aligned Monitoring System</title>
    </Helmet>
  );
}
