import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filters from '../../utils/Filters';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

// Import actions
import { fetchSurvey } from '../../actions/rtlSurveyActions';

class RtlSurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.currentUser.oid || '',
      startDate: AmsDateFormatters.getMoment()
        .startOf('day')
        .subtract(30, 'days')
        .format(),
      endDate: AmsDateFormatters.getMoment()
        .endOf('day')
        .format(),
    };

    this.dateFormatter = this.dateFormatter.bind(this);
  }

  componentWillMount() {
    const { userId, startDate, endDate } = this.state;

    if (userId) {
      this.props.fetchSurvey({ userId, startDate, endDate });
    }
  }

  dateFormatter(cell, row) {
    return AmsDateFormatters.formatDate(cell);
  }

  prepareRtlSurveyList() {
    const { surveys } = this.props;

    const options = {
      sizePerPage: 5,
      hideSizePerPage: true,
    };

    return (
      <BootstrapTable
        data={surveys}
        tableContainerClass="table-container"
        headerContainerClass="table-header"
        options={options}
        hover
        condensed
        bordered={false}
        pagination
      >
        <TableHeaderColumn dataField="reviewer" dataSort={true}>
          Reviewer
        </TableHeaderColumn>
        <TableHeaderColumn dataField="reviewId" isKey={true} dataSort={true}>
          ReviewID
        </TableHeaderColumn>
        <TableHeaderColumn dataField="completedsurvey" dataSort={true}>
          Completed Survey
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="startDate"
          dataSort={true}
          dataFormat={this.dateFormatter}
        >
          Start Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="endDate"
          dataSort={true}
          dataFormat={this.dateFormatter}
        >
          End Date
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  refreshTable(filters) {}

  render() {
    return (
      <div className="panel panel-white">
        <div className="panel-body--old">
          <Filters filterData={this.refreshTable} />
          {this.prepareRtlSurveyList()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    surveys: state.survey.surveys,
    currentUser: state.auth.user,
  };
}

RtlSurveyList.propTypes = {
  surveys: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchSurvey: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { fetchSurvey })(RtlSurveyList);
