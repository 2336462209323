import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Accordion,
  Button,
  Dropdown,
  Header,
  Icon,
  Modal,
} from 'semantic-ui-react';
import AmsAlert from '../../utils/AmsAlert';
import AmsFormLabel from '../../utils/AmsFormLabel';
import SlidingContainer from '../../utils/layout/SlidingContainer';

import _ from 'lodash';
import Helmet from 'react-helmet';
import ShowErrors from '../../utils/ShowErrors';
import XlsExport from './FindingsExport';

// Import style
import './assets/style.css';

// Import actions
import {
  confirmGrantee,
  downloadReviewEvidence,
  fetchFindingDetails,
  fetchReviewDetail,
  fetchReviewDocuments,
  updateCAPDays,
  updateDisallowance,
  updateFindingStatus,
  updateFUD,
} from '../../actions/reviewActions';
import { fetchUsers } from '../../actions/userActions';
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsModal from '../../utils/AmsModal';

import { acl } from '../../config';
import { renderSubheader } from '../../utils/TextFormatters';

class ReviewFindingsPage extends Component {
  state = {
    addNew: true,
    rows: [],
    reversed: [],
    readOnlyUsers: [
      'GrantSpecialist',
      'RegionalOfficeStaff',
      'FiscalSpecialist',
      'RegionalProgramManager',
      'ProgramSpecialist',
    ],
    readOnlySpecialUsers: [
      'GrantSpecialist',
      'RegionalOfficeStaff',
      'FiscalSpecialist',
    ],
    readOnlyRanUsers: [
      'GrantSpecialist',
      'RegionalOfficeStaff',
      'RegionalProgramManager',
    ],
    showCapConfirmation: false,
    showFUDConfirmation: false,
    showDisallowanceAlert: false,
    disallowanceAlertData: null,
  };

  componentDidMount() {
    this.findingDetailsRequested = null;
    this.props.fetchReviewDetail({
      reviewId: this.props.params.id,
    });
    this.props.fetchUsers();
    this.props.fetchReviewDocuments({
      filters: {
        reviewId: this.props.params.id,
      },
      page: 1,
      limit: 10000,
      sortName: 'reviewId',
      sortOrder: 'desc',
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.review || {}).fetchedFindingDetails &&
      !this.state.citations
    ) {
      this.setState({
        citations: nextProps.review.fetchedFindingDetails.findingsList,
      });
    }
  }

  adjustFindingDetails(details, reviewDetails) {
    if (!details) return details;
    let newDetails = [];
    details.forEach(finding => {
      if ('associatedCitations' in finding || 'nonAssocCitations' in finding) {
        (finding.associatedCitations || []).forEach(item => {
          newDetails.push({
            citation: item.associatedCitation || item.nonAssocCitations || null,
            citationDefinition: item.associatedCitationDefinition || null,
            granteeId:
              ((reviewDetails.grantees || [])[0] || {}).granteeId || null,
            guideName: null,
            performanceMeasure: null,
            findingStatus: (() => {
              let type = finding.type + '';
              if (type.match(/Deficiency/)) return 'DEF';
              if (type.match(/Area\s+of\s+Noncompliance/)) return 'ANC';
              if (type.match(/Area\s+of\s+Concern/)) return 'AOC';

              return type;
            })(finding.type),
            narrative: item.narrative,
            reportDeliveryDate: finding.reportDeliveryDate,
            correctiveActionTimeFrame:
              item.correctiveActionPeriod || finding.correctiveActionPeriod,
            correctiveActionDueDate:
              item.correctionDueDate || finding.correctionDueDate,
            followUpDetermination: finding.followUpDetermination,
          });
        });
      } else {
        newDetails.push(finding);
      }
    });
    return newDetails;
  }

  showInfo(node, data, options, ind) {
    let details = (this.props.review || {}).fetchedFindingDetails || null;
    let files =
      (
        ((this.props.review.fetchedFindingDetails || {}).findingsList || {})[
          ind
        ] || {}
      ).evidenceFiles || [];

    options = options || {};
    let index;
    let nodes = Array.prototype.slice.call(
      node.parentNode.querySelectorAll('tr')
    );
    nodes.forEach((n, i) => {
      if (n === node) index = i;
    });
    let nodeInfo;

    if (nodes[index + 1]) {
      if ((nodes[index + 1].className || '').indexOf('headers-row') !== -1) {
        nodeInfo = nodes[index + 1];
      }
    }

    let id =
      'id-' +
      Object.keys(options)
        .sort()
        .join('-');

    if (nodeInfo) {
      let returnState = false;
      if (!returnState && id === nodeInfo.getAttribute('id')) {
        returnState = true;
      }
      nodeInfo.parentNode.removeChild(nodeInfo);

      if (!returnState) {
        this.showInfo(node, data, options, ind);
      }
      return;
    }

    nodeInfo = document.createElement('tr');
    nodeInfo.setAttribute('id', id);
    nodeInfo.className = 'headers-row';
    let nodeInfoContainer = document.createElement('td');
    nodeInfoContainer.colSpan = node.querySelectorAll('td').length;
    nodeInfo.appendChild(nodeInfoContainer);
    if (options.citations) {
      let block = document.createElement('div');
      if (((this.props.review || {}).selectedReview || {}).newFAFlowFlag) {
        block.appendChild(
          document.createTextNode(data.associatedCitationDefinition || '')
        );
      } else {
        let definition = '';
        definition = data.citationDefinition
          ? data.citationDefinition
          : data.associatedCitationDefinition
          ? data.associatedCitationDefinition
          : null;

        block.appendChild(document.createTextNode(definition));
      }

      nodeInfoContainer.appendChild(block);
    }

    if (options.narrative) {
      let narrativeContainer = document.createElement('div');
      narrativeContainer.style.width = '100%';
      narrativeContainer.style.position = 'relative';
      narrativeContainer.style.display = 'block';
      narrativeContainer.style.whiteSpace = 'pre-wrap';
      narrativeContainer.className = 'narrative-container';
      narrativeContainer.appendChild(document.createTextNode(data.narrative));
      narrativeContainer.appendChild(document.createElement('br'));
      nodeInfoContainer.appendChild(narrativeContainer);
    }

    if (options.disallowance) {
      let disallowanceContainer = document.createElement('div');
      disallowanceContainer.style.width = '100%';
      disallowanceContainer.style.position = 'relative';
      disallowanceContainer.style.display = 'block';
      disallowanceContainer.style.whiteSpace = 'pre-wrap';
      disallowanceContainer.className = 'disallowance-container';

      disallowanceContainer.appendChild(
        document.createTextNode(
          `Estimated Disallowance Value (Dollar Amount): ${
            data.disallowanceMonetized === false
              ? 'Not Monetized'
              : '$' + data.disallowanceMonetizedAmount
          }`
        )
      );
      disallowanceContainer.appendChild(document.createElement('br'));
      disallowanceContainer.appendChild(
        document.createTextNode('Details: ' + data.disallowanceDetail)
      );
      disallowanceContainer.appendChild(document.createElement('br'));
      disallowanceContainer.appendChild(document.createElement('br'));

      if (data.disallowanceMonetized === true) {
        let inputDiv = document.createElement('div');
        inputDiv.innerHTML += `<label class="disallowance-input-label">Actual Disallowance Value(Dollar Amount): </label><label class="disallowance-input-label">Actual Disallowance Justification: </label>`;
        inputDiv.innerHTML += `<br><input type='number' step='0.01' value="" id="disallowance-value${data.findingId}" class="disallowance-value-input"><textarea rows="4" type="text" value="" id="disallowance-justification${data.findingId}" class="disallowance-justification-input">`;
        disallowanceContainer.appendChild(inputDiv);

        let submitButton = document.createElement('div');
        submitButton.className = 'btn btn-primary disallowance-input';
        submitButton.textContent = 'Save';
        submitButton.dataset.id = data.findingId;
        inputDiv.appendChild(submitButton);
        let clearButton = document.createElement('div');
        clearButton.className = 'btn disallowance-input';
        clearButton.dataset.id = data.findingId;
        clearButton.style = 'margin-left: 10px;background: rgb(226 226 226);';
        clearButton.appendChild(document.createTextNode('Clear'));
        inputDiv.appendChild(clearButton);

        clearButton.addEventListener('click', function(e) {
          const id = e.currentTarget.dataset.id;
          let input = document.getElementById(`disallowance-value${id}`);
          input.value = '';
          input = document.getElementById(`disallowance-justification${id}`);
          input.value = '';
        });

        let self = this;
        submitButton.addEventListener('click', function(e) {
          const id = e.currentTarget.dataset.id;
          let dValue = document.getElementById(`disallowance-value${id}`).value;
          let dJustification = document.getElementById(
            `disallowance-justification${id}`
          ).value;

          if (dJustification.length === 0) {
            self.setState({
              showDisallowanceAlert: true,
              disallowanceAlertData:
                'Please add a justification into the input field.',
            });
            return;
          }

          if (dValue < 0) {
            self.setState({
              showDisallowanceAlert: true,
              disallowanceAlertData:
                'Disallowance Value cannot be negative. Please enter a number greater than or equal to 0.',
            });
            let input = document.getElementById(`disallowance-value${id}`);
            input.value = '';
            return;
          }

          const requestInput = {
            reviewId: details.reviewId,
            findingId: data.findingId,
            disallowanceMonetizedAmount: Number(
              data.disallowanceMonetizedAmount
            ),
            disallowanceValue: Number(dValue),
            justification: dJustification,
          };
          self.props
            .updateDisallowance(
              requestInput,
              { reviewId: self.props.params.id },
              !self.props.review.selectedReview.newFAFlowFlag,
              {
                reviewType: ((self.props.review || {}).selectedReview || {})
                  .reviewType,
              }
            )
            .then(() => {
              let input = document.getElementById(`disallowance-value${id}`);
              input.value = '';
              input = document.getElementById(
                `disallowance-justification${id}`
              );
              input.value = '';

              setTimeout(function() {
                let data =
                  self.props.review.fetchedFindingDetails.findings[ind];
                self.showInfo(node, data, options, ind);
                self.showInfo(node, data, options, ind);
              }, 5000);
            })
            .catch(error => {
              self.setState({
                showDisallowanceAlert: true,
                disallowanceAlertData: error.message,
              });
            });
        });

        if (data.disallowanceHistory && data.disallowanceHistory.length > 0) {
          let tableDiv = document.createElement('div');
          let tableContents = `<table class="justification-history-table">
        <tbody><tr>
          <th>Previous</th>
          <th style="width:20%">Current</th>
          <th style="width:50%">Justification</th>
          <th>Submitted By</th>
          <th>Submission Date/Time</th>
        </tr>`;

          data.disallowanceHistory.forEach(row => {
            const previous = row.previousAmount.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            });
            const current = row.currentAmount.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            });

            tableContents += `<tr class="history-row"><td>$${previous}</td><td>$${current}</td><td style="text-align:left !important">${
              row.justification
            }</td><td>${
              row.submittedBy
            }</td><td>${AmsDateFormatters.formatDateTime(
              row.submittedTime
            )}</td></tr>`;
          });
          tableContents += '</tbody></table>';
          tableDiv.innerHTML += tableContents;

          disallowanceContainer.appendChild(tableDiv);
        }
      }

      nodeInfoContainer.appendChild(disallowanceContainer);
    }

    if (options.files) {
      let evidenceContainer = document.createElement('div');
      evidenceContainer.style.width = '100%';
      evidenceContainer.style.position = 'relative';
      evidenceContainer.style.display = 'block';
      evidenceContainer.className = 'evidence-container';
      let evidenceListContainer = document.createElement('div');
      evidenceListContainer.style.width = '100%';
      evidenceListContainer.style.position = 'relative';
      evidenceListContainer.style.display = 'block';
      evidenceListContainer.className = 'evidence-container';
      let EvidenceButton = document.createElement('div');
      EvidenceButton.style.marginTop = '20px';
      EvidenceButton.className = 'btn btn-primary';
      EvidenceButton.appendChild(document.createTextNode('Evidence'));
      evidenceContainer.appendChild(EvidenceButton);
      let fileListContainer = document.createElement('ul');
      fileListContainer.style.marginTop = '10px';
      fileListContainer.style.display = 'none';
      files.forEach(file => {
        let li = document.createElement('li');
        let link = document.createElement('p');
        link.style.cursor = 'pointer';
        link.style.textDecoration = 'underline';
        link.style.color = 'blue';
        let filename = file.split('|');
        filename.pop();
        filename = filename.join('-');
        link.appendChild(document.createTextNode(filename));
        li.appendChild(link);
        fileListContainer.appendChild(li);
        link.onclick = () =>
          this.props.downloadReviewEvidence(
            this.props.params.id,
            file,
            filename
          );
      });

      if (files.length === 0) {
        fileListContainer.style.fontStyle = 'italic';
        let noFiles = document.createElement('p');
        noFiles.innerText = 'No files found';
        fileListContainer.appendChild(noFiles);
      }
      evidenceListContainer.appendChild(fileListContainer);
      nodeInfoContainer.appendChild(evidenceContainer);
      nodeInfoContainer.appendChild(evidenceListContainer);

      EvidenceButton.addEventListener('click', function() {
        fileListContainer.style.display =
          fileListContainer.style.display === 'none' ? '' : 'none';
      });
    }

    if (nodes[index + 1]) {
      nodes[index + 1].parentNode.insertBefore(nodeInfo, nodes[index + 1]);
    } else {
      node.parentNode.appendChild(nodeInfo);
    }

    //ensure disallowance input only has 2 decimals
    const disallowanceInputElements = document.getElementsByClassName(
      'disallowance-value-input'
    );

    if (disallowanceInputElements.length > 0) {
      Array.from(disallowanceInputElements).forEach(inputElement => {
        inputElement.addEventListener('change', event => {
          event.target.value = parseFloat(event.target.value).toFixed(2);
        });
      });
    }
  }

  renderAssociations(row) {
    let list = [row];
    if (!Array.isArray(list)) return null;
    return list.map((item, index) => {
      return (
        <Link
          href="#"
          key={index}
          onClick={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            this.showInfo(
              ev.target.parentNode.parentNode,
              row,
              {
                citations: true,
              },
              index
            );
          }}
        >
          {item.citation}
        </Link>
      );
    });
  }

  formatPerformanceMeasure = (row, index) => {
    const reviewId = this.context.router.params.id;
    if (
      this.getReviewType() === 'ran' &&
      row.guideName !== 'Supervision' &&
      row.guideName !== 'Discipline' &&
      row.guideName !== 'Inappropriate Release' &&
      row.guideName !== 'Reporting'
    ) {
      return (
        <Link
          to={{
            pathname: `/task/${reviewId}/survey/${this.getReviewType()}`,
            query: {
              g: encodeURIComponent('Findings Outside the Protocol'),
              pm: encodeURIComponent('Findings Outside the Protocol'),
            },
          }}
          target="_blank"
        >
          {row.performanceMeasure}
        </Link>
      );
    } else {
      return (
        <Link
          to={{
            pathname: `/task/${reviewId}/survey/${this.getReviewType()}`,
            query: {
              g: encodeURIComponent(row.guideName),
              pm: encodeURIComponent(row.performanceMeasure),
            },
          }}
          target="_blank"
        >
          {row.performanceMeasure}
        </Link>
      );
    }
  };

  getReviewType = () => {
    const { selectedReview } = this.props.review;

    let reviewType = selectedReview
      ? selectedReview.reviewType
        ? selectedReview.reviewType
        : ''
      : '';

    switch (reviewType) {
      case 'FA-1':
        return 'fa1';
      case 'FA1-FR':
        return 'fa1fr';
      case 'FA-2':
        return 'fa2';
      case 'FA2-CR':
        return 'fa2cr';
      case 'RAN':
        return 'ran';

      case 'Special':
        return 'special';

      case 'AIAN-DEF':
        return 'aian-def';

      case 'Follow-up':
        return 'follow-up';

      case 'ERSEA':
        return 'ersea';

      default:
        break;
    }
  };

  statusFormatter(row, index, asText) {
    if (asText) {
      return row.findingStatus;
    } else {
      let details = (this.props.review || {}).fetchedFindingDetails || null;
      if (!details) {
        return null;
      }

      details.findingsList = this.adjustFindingDetails(
        details.findingsList || details.findings,
        (this.props.review || {}).selectedReview || {}
      );

      let findingStatusValue = details.findingsList[index].findingStatus;
      if (row.preliminary) {
        return row.findingStatus;
      }

      if (row.findingStatus === 'Abandoned') {
        return row.findingStatus;
      }

      if (details && !details.isReviewAccessible) {
        return row.findingStatus;
      }
      if (
        this.props.review.selectedReview.newFAFlowFlag &&
        this.props.review.selectedReview.reviewStatus === 'In Analysis' &&
        (this.props.review.selectedReview.reviewType === 'Special' ||
        this.props.review.selectedReview.reviewType === 'ERSEA'
          ? _.intersection(
              this.state.readOnlySpecialUsers,
              (this.props.currentUser || {}).roles
            ).length === 0
          : this.props.review.selectedReview.reviewType === 'RAN'
          ? _.intersection(
              this.state.readOnlyRanUsers,
              (this.props.currentUser || {}).roles
            ).length === 0
          : _.intersection(
              this.state.readOnlyUsers,
              (this.props.currentUser || {}).roles
            ).length === 0)
      ) {
        let options =
          (this.props.review.selectedReview || {}).reviewType !== 'RAN'
            ? ['PANC', 'AOC', 'DEF', 'ANC', 'DROPPED', 'COMPLIANT'].map(
                (v, index) => {
                  if (index === 0) {
                    return { value: v, text: v, disabled: true };
                  } else {
                    return { value: v, text: v };
                  }
                }
              )
            : [
                'ANC',
                'AOC',
                'DEF',
                'DROPPED',
                'COMPLIANT',
                'Regional Office TTA',
              ].map(v => {
                return { value: v, text: v };
              });

        if (
          (this.props.review.selectedReview || {}).reviewType === 'Follow-up' &&
          (this.props.review.selectedReview || {}).fiscalYear >= 2023
        ) {
          options = options.filter(
            option => option.value !== 'AOC' && option.value !== 'COMPLIANT'
          );
        }

        findingStatusValue = (
          <AmsFormLabel
            name={`Select Finding Status for ${row.citation}`}
            fieldLabel={`findingStatusSelect${index}`}
            hidden
            table={true}
          >
            <Dropdown
              placeholder="Select Finding Status"
              searchInput={{ id: `findingStatusSelect${index}` }}
              aria-labelledby={`findingStatusSelect${index}`}
              value={findingStatusValue}
              onChange={event => {
                findingStatusValue = event.target.innerText;
                const findingUpdateBody = {
                  findingId: details.findingsList[index].findingId,
                  newStatus: event.target.innerText,
                  reason: '',
                };
                this.setState({
                  findingUpdateBody: findingUpdateBody,
                  reasonBoxShow: true,
                });
              }}
              fluid
              search
              upward
              selection
              options={options}
              openOnFocus={false}
            />
          </AmsFormLabel>
        );
      }
      return <div>{findingStatusValue}</div>;
    }
  }

  ociStatusFormatter = (row, index, asText) => {
    if (asText) {
      return row.findingStatus;
    } else {
      let details = (this.props.review || {}).fetchedFindingDetails || null;
      if (!details) {
        return null;
      }

      details.findingsList = this.adjustFindingDetails(
        details.findingsList || details.findings,
        (this.props.review || {}).selectedReview || {}
      );

      let findingStatusValue = details.findingsList[index].findingStatus;
      if (row.preliminary) {
        return row.findingStatus;
      }

      if (details && !details.isReviewAccessible) {
        return row.findingStatus;
      }
      if (
        this.props.review.selectedReview.newFAFlowFlag &&
        this.props.review.selectedReview.reviewStatus === 'In Analysis' &&
        (this.props.review.selectedReview.reviewType === 'Special' ||
        this.props.review.selectedReview.reviewType === 'ERSEA'
          ? _.intersection(
              this.state.readOnlySpecialUsers,
              (this.props.currentUser || {}).roles
            ).length === 0
          : this.props.review.selectedReview.reviewType === 'RAN'
          ? _.intersection(
              this.state.readOnlyRanUsers,
              (this.props.currentUser || {}).roles
            ).length === 0
          : _.intersection(
              this.state.readOnlyUsers,
              (this.props.currentUser || {}).roles
            ).length === 0)
      ) {
        findingStatusValue = (
          <Dropdown
            placeholder="Select Finding Status"
            value={findingStatusValue}
            onChange={event => {
              findingStatusValue = event.target.innerText;
              const findingUpdateBody = {
                findingId: details.findingsList[index].findingId,
                newStatus: event.target.innerText,
                reason: '',
              };
              this.setState({
                findingUpdateBody: findingUpdateBody,
                reasonBoxShow: true,
              });
            }}
            fluid
            aria-label={`Select finding status for ${row.citation}`}
            upward
            selection
            options={['OCI', 'DROPPED'].map(v => {
              return { value: v, text: v };
            })}
            openOnFocus={false}
          />
        );
      }
      return <div>{findingStatusValue}</div>;
    }
  };

  reasonBox = () => {
    let reason;
    if (!this.state.addNew) {
      reason = this.state.editedReason;
    }
    if (this.state.reasonBoxShow) {
      return (
        <div className="backdrop">
          <div className="reason-box">
            <h3>Please enter reason for status change</h3>
            <textarea
              name="reason"
              id="reason"
              rows="4"
              onChange={() => {
                reason = document.getElementById('reason').value;
              }}
              defaultValue={reason}
            />
            <br />
            <div className="reason-box-controls">
              <button
                onClick={() => {
                  let action = () => {
                    this.props.updateFindingStatus(
                      {
                        ...this.state.findingUpdateBody,
                        reason: reason,
                        addNew: this.state.addNew,
                        who: this.props.user,
                      },
                      {
                        reviewType: (
                          (this.props.review || {}).selectedReview || {}
                        ).reviewType,
                      },
                      { reviewId: this.props.params.id },
                      !this.props.review.selectedReview.newFAFlowFlag
                    );
                  };
                  this.setState({
                    reasonBoxShow: false,
                    action: action,
                    showAlert: true,
                  });
                }}
                className="btn btn-primary btn-success"
              >
                Submit
              </button>
              &nbsp;&nbsp;
              <button
                onClick={() => {
                  this.setState({
                    reasonBoxShow: false,
                    addNew: true,
                    editedReason: '',
                  });
                }}
                className="btn btn-primary btn-danger"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  handleClick = index => {
    let newRows = [...this.state.rows];
    newRows[index] = !newRows[index];
    this.setState({ rows: newRows });
  };

  locateUsername = oid => {
    if (this.props.users.length === 0) {
      return;
    }
    return (this.props.users.find(user => user.oid === oid) || {}).name;
  };

  reasonFormatter(row, findingId, index) {
    if (!(row.findingStatusHistory || []).length) {
      if (row.findingStatus === 'Abandoned' && row.findingStatusReason) {
        return row.findingStatusReason;
      }

      return '';
    }

    let reversedStatusHistory = [...row.findingStatusHistory].reverse();
    let recent = { ...reversedStatusHistory[0] };
    let older = [];
    if (reversedStatusHistory.length > 1) {
      older = [...reversedStatusHistory];
      older.splice(0, 1);
    }
    return (
      <Accordion>
        <Accordion.Title active>
          <div className="row">
            <div className="col-md-10">
              <span
                style={{
                  background: '#e2e2e2',
                  borderRadius: '3px',
                  padding: '3px',
                }}
              >
                {recent.statusFrom} → {recent.statusTo}
              </span>
            </div>
            <div className="col-md-2">
              <div
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  display:
                    _.intersection(
                      this.state.readOnlyUsers,
                      (this.props.currentUser || {}).roles
                    ).length > 0
                      ? 'none'
                      : '',
                }}
                onClick={() => {
                  this.setState({
                    reasonBoxShow: true,
                    addNew: false,
                    findingUpdateBody: {
                      findingId: findingId,
                      newStatus: recent.statusTo,
                    },
                    editedReason: recent.reason,
                  });
                }}
              >
                <svg
                  aria-hidden="true"
                  className="svg-icon va-text-bottom o50 iconPencilSm"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                >
                  <path d="M8.37 4.7L2 11.11V13h1.88l6.37-6.43zM12.23 3.83L11.1 2.71a.5.5 0 0 0-.7 0L9.2 3.86l1.88 1.84 1.14-1.16a.5.5 0 0 0 0-.71z" />
                </svg>
              </div>
            </div>
          </div>
        </Accordion.Title>
        <Accordion.Content active>
          <div className="row">
            <div className="col-md-12">
              {recent.reason}
              <span
                style={{
                  display: 'block',
                  fontSize: '0.8em',
                  borderTop: '1px solid rgb(171,171,171)',
                  marginTop: '5px',
                  paddingTop: '5px',
                }}
              >
                {this.locateUsername(recent.who)} on{' '}
                {AmsDateFormatters.formatUtcDate(new Date(recent.updatedTime))}
              </span>
            </div>
          </div>
        </Accordion.Content>

        <div
          style={{ display: reversedStatusHistory.length > 1 ? '' : 'none' }}
        >
          <Accordion.Title
            active={this.state.rows[index]}
            style={{ color: '#4183c4' }}
            onClick={() => this.handleClick(index)}
          >
            <Icon name="dropdown" />
            <strong>Older changes</strong>
          </Accordion.Title>

          <Accordion.Content active={this.state.rows[index]}>
            {older.map((olderChange, index) => {
              return (
                <div
                  key={olderChange.statusFrom + olderChange.statusTo + index}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <span
                        style={{
                          background: '#e2e2e2',
                          borderRadius: '3px',
                          padding: '3px',
                        }}
                      >
                        {olderChange.statusFrom} → {olderChange.statusTo}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ marginTop: '12px' }}>
                      {olderChange.reason}
                      <span
                        style={{
                          display: 'block',
                          fontSize: '0.8em',
                          color: '#ababab',
                          borderTop: '1px solid rgb(171,171,171)',
                          marginTop: '5px',
                          paddingTop: '5px',
                        }}
                      >
                        {this.locateUsername(olderChange.who)} on{' '}
                        {AmsDateFormatters.formatDate(
                          new Date(olderChange.updatedTime)
                        )}
                      </span>
                    </div>
                  </div>
                  <hr
                    style={{
                      display:
                        older.length === 1 || index === older.length - 1
                          ? 'none'
                          : '',
                      marginTop: '10px !important',
                      marginBottom: '14px !important',
                    }}
                  />
                </div>
              );
            })}
          </Accordion.Content>
        </div>
      </Accordion>
    );
  }

  capDaysFormatter = (row, index) => {
    let { fetchedFindingDetails } = this.props.review;
    let { roles } = this.props.currentUser;
    let options = [...Array(367).keys()].map(day => {
      return {
        value: `${day} days`,
        text: `${day} days` || '-',
        key: `${day} days`,
      };
    });

    if (
      fetchedFindingDetails &&
      (fetchedFindingDetails.isReportFinalized ||
        row.correctiveActionTimeFrame === 'N/A')
    )
      return row.correctiveActionTimeFrame;

    if (row.findingStatus === 'Abandoned') {
      return 'N/A';
    }

    if (
      !_.isEmpty(_.intersection(acl.actions.review.CAPUpdate, roles)) &&
      row.reviewType !== 'Follow-up' &&
      !row.preliminary
    ) {
      return (
        <AmsFormLabel
          table={true}
          hidden
          fieldLabel={`capDaySelection${index}`}
          name={`Select FUD for ${row.citation}`}
        >
          <Dropdown
            placeholder={
              row.correctiveActionTimeFrame
                ? row.correctiveActionTimeFrame
                : 'Select CAP Days'
            }
            fluid
            selection
            search
            searchInput={{ id: `capDaySelection${index}` }}
            aria-labelledby={`capDaySelection${index}`}
            options={options}
            value={row.correctiveActionTimeFrame || ''}
            onChange={(e, { name, value }) => {
              this.setState({
                previousCapDays: row.correctiveActionTimeFrame,
                newCapDays: value,
                index,
                selectedRow: row,
                showCapConfirmation: true,
              });
            }}
            openOnFocus={false}
          />
        </AmsFormLabel>
      );
    } else {
      return <div>{row.correctiveActionTimeFrame}</div>;
    }
  };

  showFUDConfirmationAlert() {
    let details = (this.props.review || {}).fetchedFindingDetails || null;

    const { index, newFUDStatus } = this.state;

    const inlineStyle = {
      modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
      },
    };
    return (
      <AmsModal
        centered
        size="tiny"
        style={inlineStyle.modal}
        open={this.state.showFUDConfirmation}
      >
        <Header
          icon="warning circle"
          content="Update Follow-up determination?"
        />
        <Modal.Content>
          <p>Are you sure you want to update the Follow-up determination?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              this.setState({
                showFUDConfirmation: false,
              });
            }}
          >
            No
          </Button>
          <Button
            color="green"
            onClick={() => {
              const requestInput = {
                findingId: details.findingsList[index].findingId,
                newStatus: newFUDStatus,
              };
              this.props.updateFUD(
                requestInput,
                { reviewId: this.props.params.id },
                !this.props.review.selectedReview.newFAFlowFlag,
                {
                  reviewType: ((this.props.review || {}).selectedReview || {})
                    .reviewType,
                }
              );
              this.setState({
                showFUDConfirmation: false,
              });
            }}
          >
            Yes
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  }

  showCapConfirmationAlert() {
    let details = (this.props.review || {}).fetchedFindingDetails || null;
    const { index, newCapDays } = this.state;
    const inlineStyle = {
      modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
      },
    };
    return (
      <AmsModal
        centered
        size="tiny"
        style={inlineStyle.modal}
        open={this.state.showCapConfirmation}
      >
        <Header
          icon="warning circle"
          content="Update Corrective Action Time Frame"
        />
        <Modal.Content>
          <p>
            Are you sure you want to update the corrective action period for
            selected finding?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              this.setState({
                showCapConfirmation: false,
                // capDays: this.state.previousCapDays,
              });
            }}
          >
            No
          </Button>
          <Button
            color="green"
            onClick={() => {
              const requestInput = {
                findingId: details.findingsList[index].findingId,
                newCAP: newCapDays,
                reviewId: this.props.params.id,
              };
              this.props.updateCAPDays(
                requestInput,
                { reviewId: this.props.params.id },
                !this.props.review.selectedReview.newFAFlowFlag,
                {
                  reviewType: ((this.props.review || {}).selectedReview || {})
                    .reviewType,
                }
              );
              this.setState({
                showCapConfirmation: false,
                // newCapDays: newCapDays,
              });
            }}
          >
            Yes
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  }

  excelReasonFormatter = row => {
    if (!row.findingStatusHistory.length) {
      return '';
    }
    let reversedList = [...row.findingStatusHistory].reverse();
    let exportList = reversedList.map(reason => {
      return `${reason.statusFrom} → ${reason.statusTo} | reason: ${
        reason.reason
      } | who: ${this.locateUsername(
        reason.who
      )} | when: ${AmsDateFormatters.formatDate(new Date(reason.updatedTime))}`;
    });
    exportList = exportList.join('\r\n').replace(/\\r\\n/g, '\r\n');
    return exportList;
  };

  selectGrantee = (row, index, granteeId) => {
    if (!this.state.citations[index].payload) {
      let i = (
        (this.props.review || {}).selectedReview || {}
      ).grantees.findIndex(el => {
        return el.granteeId === granteeId;
      });
      const payload = {
        findingId: row.findingId,
        confirmedGrantees: [
          {
            granteeId: granteeId,
            granteeName: ((this.props.review || {}).selectedReview || {})
              .grantees[i].granteeName,
          },
        ],
      };
      let newCitations = [...this.state.citations];
      let newCitation = newCitations[index];
      newCitation.payload = payload;
      newCitations[index] = newCitation;
      this.setState({
        ...this.state,
        citations: newCitations,
      });
    } else {
      // check if grantee ID was already added and box is being unchecked
      let isThere = this.state.citations[
        index
      ].payload.confirmedGrantees.findIndex(el => {
        return el.granteeId === granteeId;
      });
      // grantee ID not found in existing payload, adding now
      if (isThere === -1) {
        let newPayload = { ...this.state.citations[index].payload };
        let newConfirmedGrantees = [
          ...this.state.citations[index].payload.confirmedGrantees,
        ];

        let i = (
          (this.props.review || {}).selectedReview || {}
        ).grantees.findIndex(el => {
          return el.granteeId === granteeId;
        });

        newConfirmedGrantees.push({
          granteeId: granteeId,
          granteeName: ((this.props.review || {}).selectedReview || {})
            .grantees[i].granteeName,
        });
        newPayload.confirmedGrantees = newConfirmedGrantees;
        let newCitations = [...this.state.citations];
        let newCitation = newCitations[index];
        newCitation.payload = newPayload;
        newCitations[index] = newCitation;
        this.setState({
          ...this.state,
          citations: newCitations,
        });
      }
      // box is unchecked
      else {
        let newPayload = { ...this.state.citations[index].payload };
        let newConfirmedGrantees = [
          ...this.state.citations[index].payload.confirmedGrantees,
        ];
        newConfirmedGrantees.splice(isThere, 1);
        newPayload.confirmedGrantees = newConfirmedGrantees;
        let newCitations = [...this.state.citations];
        let newCitation = newCitations[index];
        newCitation.payload = newPayload;
        newCitations[index] = newCitation;
        this.setState({
          ...this.state,
          citations: newCitations,
        });
      }
    }
  };

  submitGranteeConfirmation = ind => {
    console.log('here');
    let reviewType = {
      reviewType: (this.props.review.selectedReview || {}).reviewType,
    };
    let body = this.state.citations[ind].payload;
    let reviewId = { reviewId: this.props.review.selectedReview.reviewId };
    this.props.confirmGrantee(body, reviewId, reviewType);
  };

  showConfirmationAlert = () => {
    let action = this.state.action;
    return (
      <AmsAlert
        show={this.state.showAlert}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`Are you sure?`}
        onConfirm={() => {
          action();
          this.setState({ showAlert: false, addNew: true, editedReason: '' });
        }}
        onCancel={() => {
          this.setState({ showAlert: false, addNew: true, editedReason: '' });
        }}
      />
    );
  };

  showTerminatedAlert = () => {
    let show = this.state.showTerminatedAlert;
    let terminated = this.state.terminatedAlertData;

    if (!terminated) {
      return null;
    }

    let text = `The grantee ${
      terminated.granteeId
    } has been ${terminated.inactivationReason.toLowerCase()}. Please select another grantee before confirming.`;

    return (
      <AmsAlert
        show={show}
        title="Oops!"
        text={text}
        type={'error'}
        showConfirm
        showCancelButton={false}
        onConfirm={() => {
          this.setState({
            showTerminatedAlert: false,
            terminatedAlertData: null,
          });
        }}
      />
    );
  };

  showDisallowanceActionAlert = () => {
    let show = this.state.showDisallowanceAlert;
    let text = this.state.disallowanceAlertData;

    return (
      <AmsAlert
        show={show}
        title="Something went wrong!"
        text={text}
        type={'error'}
        showConfirm
        showCancelButton={false}
        onConfirm={() => {
          this.setState({
            showDisallowanceAlert: false,
            disallowanceAlertData: null,
          });
        }}
      />
    );
  };

  validGrantees = index => {
    const { confirmedGrantees } = this.state.citations[index].payload;
    let valid = true;
    confirmedGrantees.forEach(grantee => {
      let i = ((this.props.review || {}).selectedReview || {}).grantees.find(
        el => {
          return el.granteeId === grantee.granteeId;
        }
      );
      if (
        i &&
        i.inactivationReason &&
        (i.inactivationReason === 'Terminated' ||
          i.inactivationReason === 'Relinquished')
      ) {
        this.setState({ showTerminatedAlert: true, terminatedAlertData: i });
        valid = false;
        return false;
      }
    });
    return valid;
  };

  render() {
    let details = (this.props.review || {}).fetchedFindingDetails || null;
    let reviewType =
      ((this.props.review || {}).selectedReview || {}).reviewType || '';
    if (
      reviewType &&
      !this.findingDetailsRequested &&
      !isEmpty(this.props.params.id)
    ) {
      this.findingDetailsRequested = true;
      this.props.fetchFindingDetails(
        {
          reviewId: this.props.params.id,
        },
        !this.props.review.selectedReview.newFAFlowFlag,
        {
          reviewType: ((this.props.review || {}).selectedReview || {})
            .reviewType,
        }
      );
    }
    if (!details) {
      return <div>Loading ...</div>;
    }

    details.findingsList = this.adjustFindingDetails(
      details.findingsList || details.findings,
      (this.props.review || {}).selectedReview || {}
    );
    // let grantees = (
    //   ((this.props.review || {}).selectedReview || {}).grantees || []
    // ).map(grantee => {
    //   return ` ${grantee.granteeName} (${grantee.granteeId})`;
    // });

    let exportProps = {
      grantees: ((this.props.review || {}).selectedReview || {}).grantees || [],
      reviewType: (this.props.review.selectedReview || {}).reviewType,
      details: (this.props.review || {}).fetchedFindingDetails || null,
      adjustFindingDetailsAction: this.adjustFindingDetails,
      statusFormatterAction: this.statusFormatter,
      excelReasonFormatterAction: this.excelReasonFormatter,
      reviewId: (this.props.review.selectedReview || {}).reviewId,
    };

    let showFURButton = false;

    //check roles and report shipped date for FUR access
    if (
      this.props.review.selectedReview &&
      this.props.review.selectedReview.showLegacyPlanFollowup === true
    ) {
      showFURButton = true;
    }

    const legacy = ((this.props.review || {}).selectedReview || {}).legacy;

    return (
      <span>
        <Helmet>
          <body className="review-findings-page" />
        </Helmet>
        {this.reasonBox()}
        <SlidingContainer
          granteeStatusBanner={true}
          granteeStatusData={{
            id: this.props.review.selectedReview.reviewId,
            type: 'review',
          }}
          title={`Findings Analysis for ${reviewType} Review ID ${this.props.params.id}`}
          subheader={this.props.review.selectedReview.grantees && renderSubheader(this.props.review.selectedReview.grantees)}
        >
          {this.showConfirmationAlert()}
          {this.showTerminatedAlert()}
          {this.showCapConfirmationAlert()}
          {this.showFUDConfirmationAlert()}
          {this.showDisallowanceActionAlert()}
          <div className="row">
            <div
              className="col-sm-12"
              style={{
                textAlign: 'right',
              }}
            >
              {showFURButton && (
                <button
                  onClick={() => {
                    !legacy
                      ? this.context.router.push(
                          `/review/${this.props.params.id}/follow-up-recommendations`
                        )
                      : this.context.router.push(
                          `/review/${this.props.params.id}/follow-up-recommendations-legacy`
                        );
                  }}
                  className="btn btn-sm btn-default"
                  disabled={details && !details.isReviewAccessible}
                >
                  {!legacy
                    ? 'Plan Follow-up Review'
                    : 'Follow-up Recommendations'}
                </button>
              )}
              &nbsp;
              <XlsExport {...exportProps} />
              <br />
              &nbsp;
            </div>
          </div>
          {this.props.error.length ? (
            <ShowErrors
              errors={{
                Error: 'Action cannot be completed. Please contact helpdesk',
              }}
            />
          ) : null}
          <div className="row">
            <div className="col-sm-12 review-findings-wrapper">
              <table className="table table-hover table-condensed table-review findings-table">
                <thead>
                  <tr>
                    <th scope="col" className="nowrap">
                      Citations
                    </th>
                    <th scope="col" className="nowrap min-w160">
                      Grantee ID
                    </th>
                    <th
                      scope="col"
                      className="nowrap"
                      style={{ display: reviewType !== 'RAN' ? '' : 'none' }}
                    >
                      Guide Name
                    </th>
                    <th scope="col" className="nowrap">
                      {reviewType !== 'RAN'
                        ? 'Performance Measure'
                        : 'Specification Type'}
                    </th>
                    <th scope="col" className="nowrap min-w160">
                      Findings Status
                    </th>
                    <th scope="col" className="nowrap min-w180">
                      Reason
                    </th>
                    <th scope="col" className="nowrap">
                      Narrative
                    </th>
                    <th scope="col" className="nowrap">
                      {reviewType !== 'FA-2'
                        ? 'Potential Disallowance'
                        : 'Disallowance'}
                    </th>
                    <th scope="col" className="nowrap">
                      Report Shipped Date
                    </th>
                    <th scope="col" className="nowrap">
                      Corrective Action
                      <br />
                      Time frame
                    </th>
                    <th scope="col" className="nowrap">
                      Corrective Action
                      <br />
                      Due Date
                    </th>
                    <th scope="col" className="nowrap">
                      Follow-up
                      <br />
                      Determination
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {details.findingsList.length === 0 ? (
                    <tr>
                      <td style={{ textAlign: 'center' }} colSpan="12">
                        no results
                      </td>
                    </tr>
                  ) : (
                    details.findingsList.map((row, index) => {
                      let findingId = row.findingId;
                      return (
                        <tr key={index}>
                          <td>{this.renderAssociations(row)}</td>
                          <td>
                            {exportProps.grantees.length === 1 ? (
                              exportProps.grantees[0].granteeId
                            ) : row.confirmedGrantees &&
                              row.confirmedGrantees.length === 0 &&
                              _.intersection(
                                this.state.readOnlyUsers,
                                (this.props.currentUser || {}).roles
                              ).length === 0 ? (
                              <span>
                                {(
                                  (this.props.review || {}).selectedReview || {}
                                ).grantees.map(grant => {
                                  return (
                                    <span key={grant.granteeId}>
                                      <input
                                        type="checkbox"
                                        aria-label={`Select grantee ${grant.granteeId}`}
                                        name="grantee"
                                        value={grant.granteeId}
                                        onClick={() =>
                                          this.selectGrantee(
                                            row,
                                            index,
                                            grant.granteeId
                                          )
                                        }
                                      />
                                      &nbsp;
                                      <span>{grant.granteeId}</span>
                                      <br />
                                    </span>
                                  );
                                })}
                                <button
                                  onClick={() => {
                                    if (!this.validGrantees(index)) {
                                      return;
                                    }

                                    let action = () => {
                                      this.submitGranteeConfirmation(index);
                                    };
                                    this.setState({
                                      action: action,
                                      showAlert: true,
                                    });
                                  }}
                                  disabled={
                                    !(
                                      (
                                        (
                                          (
                                            ((this.state || {}).citations ||
                                              {})[index] || {}
                                          ).payload || {}
                                        ).confirmedGrantees || {}
                                      ).length > 0
                                    )
                                  }
                                  className="grantee-confirm-btn btn btn-primary"
                                >
                                  Confirm
                                </button>
                              </span>
                            ) : (
                              row.confirmedGrantees &&
                              row.confirmedGrantees.map(grantee => {
                                return (
                                  <span key={grantee.granteeId}>
                                    <span>{grantee.granteeId}</span>
                                    <br />
                                  </span>
                                );
                              })
                            )}
                          </td>
                          <td
                            style={{
                              display: reviewType !== 'RAN' ? '' : 'none',
                            }}
                          >
                            {row.guideName === null &&
                            !((this.props.review || {}).selectedReview || {})
                              .newFAFlowFlag ? (
                              <i>Not applicable</i>
                            ) : (
                              row.guideName
                            )}
                          </td>
                          <td>
                            {row.performanceMeasure === null &&
                            !((this.props.review || {}).selectedReview || {})
                              .newFAFlowFlag ? (
                              <i>Not applicable</i>
                            ) : (
                              this.formatPerformanceMeasure(row, index)
                            )}
                          </td>
                          <td>
                            {(row && row.findingStatus === 'OCI') ||
                            (row &&
                              row.findingStatus === 'DROPPED' &&
                              row.citation === 'Not Applicable')
                              ? this.ociStatusFormatter(row, index)
                              : this.statusFormatter(row, index)}
                          </td>
                          <td>
                            {
                              <Accordion>
                                {this.reasonFormatter(row, findingId, index)}
                              </Accordion>
                            }
                          </td>
                          <td>
                            <Link
                              href="#"
                              onClick={ev => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                this.showInfo(
                                  ev.target.parentNode.parentNode,
                                  row,
                                  {
                                    narrative: true,
                                    files: true,
                                  },
                                  index
                                );
                              }}
                            >
                              {((row.narrative || '') + '').replace(
                                // eslint-disable-next-line no-useless-escape
                                /^([\s\S]{30,}?)[\s\.\,][\s\S]+$/,
                                '$1 ...'
                              )}
                            </Link>
                          </td>
                          <td>
                            {row.disallowance ? (
                              <Link
                                href="#"
                                onClick={ev => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  if (row.disallowance)
                                    this.showInfo(
                                      ev.target.parentNode.parentNode,
                                      row,
                                      {
                                        disallowance: true,
                                      },
                                      index
                                    );
                                }}
                              >
                                Yes
                              </Link>
                            ) : (
                              'No'
                            )}
                          </td>
                          <td>
                            {row.reportDeliveryDate
                              ? AmsDateFormatters.formatDate(
                                  row.reportDeliveryDate
                                )
                              : ''}
                          </td>
                          <td>
                            {row &&
                            (row.findingStatus === 'OCI' ||
                              row.findingStatus === 'AOC')
                              ? 'Follow up with Regional Office for support'
                              : row &&
                                row.findingStatus === 'DROPPED' &&
                                row.citation === 'Not Applicable'
                              ? 'N/A'
                              : this.capDaysFormatter(row, index)}
                          </td>
                          <td>
                            {row && row.findingStatus === 'OCI'
                              ? ''
                              : row.correctiveActionDueDate
                              ? row.correctiveActionDueDate === 'N/A'
                                ? 'N/A'
                                : AmsDateFormatters.formatDate(
                                    row.correctiveActionDueDate
                                  )
                              : ''}
                          </td>
                          <td>
                            {(row && row.findingStatus === 'OCI') ||
                            (row &&
                              row.findingStatus === 'DROPPED' &&
                              row.citation === 'Not Applicable')
                              ? 'N/A'
                              : row.followUpDetermination}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </SlidingContainer>
      </span>
    );
  }
}

ReviewFindingsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  review: state.review,
  error: state.error,
  user: state.auth.user.oid,
  currentUser: state.auth.user,
  users: state.users.users,
});

export default connect(mapStateToProps, {
  fetchReviewDetail,
  fetchFindingDetails,
  fetchReviewDocuments,
  updateFindingStatus,
  updateFUD,
  downloadReviewEvidence,
  confirmGrantee,
  fetchUsers,
  updateCAPDays,
  updateDisallowance,
})(ReviewFindingsPage);
