import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

// Import component
import SlidingContainer from '../../utils/layout/SlidingContainer';
import AmsTable from '../../utils/AmsTable';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import Actions
import {
  fetchRegionalReviews,
  exportMyRegionalReviewsTable,
} from '../../actions/reviews';

class MyRegionalReviewsPage extends Component {
  state = {
    loading: false,
    page: 1,
    limit: 25,
    // sortName: 'reviewEndDate',
    // sortOrder: 'desc',
  };

  componentDidMount() {
    this.props.fetchRegionalReviews();
    this.amsTableRef = React.createRef();
  }

  exportTable = () => {
    this.props.exportMyRegionalReviewsTable();
  };

  render() {
    const { review } = this.props;

    const dateFormatter = (cell, row) => AmsDateFormatters.formatDate(cell);

    const columns = [
      {
        dataField: 'reviewId',
        sort: true,
        text: 'Review ID',
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'granteeName',
        text: 'Grantee Name',
        sort: true,
        headerStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'granteeId',
        sort: true,
        text: 'Grantee ID',
        headerStyle: {
          width: '10%',
        },
      },
      {
        text: 'Review Type',
        sort: true,
        dataField: 'reviewType',
        headerStyle: {
          width: '20%',
        },
      },
      {
        text: 'Review Status',
        headerStyle: {
          width: '20%',
        },
        dataField: 'reviewStatus',
        sort: true,
      },
      {
        headerStyle: {
          width: '20%',
        },
        formatter: dateFormatter,
        dataField: 'startDate',
        sort: true,
        text: 'Start Date',
      },
    ];

    const scrollToRef = () => {
      console.log('here');
      window.scrollTo(0, this.amsTableRef.current.offsetTop);
      this.amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, page) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onPageChange = (page, sizePerPage) => {
      this.setState(
        {
          page,
          limit: sizePerPage,
        },
        () => {
          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    return (
      <>
        <SlidingContainer calendar title={'Regional Reviews'}>
          <div className="col-md-2 col-md-offset-10">
            <Button
              // primary
              size="small"
              onClick={this.exportTable}
            >
              Export Table
            </Button>
          </div>
          <br />
          <br />
          <br />
          <div ref={this.amsTableRef} tabIndex="-1">
            <AmsTable
              columns={columns}
              data={review.regionalReviews}
              page={this.state.page}
              limit={this.state.limit}
              total={review.regionalReviews ? review.regionalReviews.length : 0}
              remote={false}
              keyField="reviewId'"
              onPageChange={onPageChange}
              onSizePerPageChange={onSizePerPageChange}
            />
          </div>
        </SlidingContainer>
      </>
    );
  }
}
const mapStateToProps = ({ review }) => {
  return {
    review,
  };
};

export default connect(mapStateToProps, {
  fetchRegionalReviews,
  exportMyRegionalReviewsTable,
})(MyRegionalReviewsPage);
