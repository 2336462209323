import React, { useEffect, useRef } from 'react';
import AmsHelpText from './AmsHelpText';

const AmsFormLabel = ({
  hidden,
  helpText,
  fieldLabel,
  name,
  children,
  table = false,
}) => {
  const labelRef = useRef(null);
  useEffect(() => {
    if (
      labelRef &&
      labelRef.current &&
      document
        .querySelector(`[for=${fieldLabel}]`)
        .parentElement.querySelector('[role=listbox]')
    ) {
      document
        .querySelector(`[for=${fieldLabel}]`)
        .parentElement.querySelector('[role=listbox]')
        .setAttribute('aria-labelledby', fieldLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <>
      <label
        style={
          table ? { display: 'block', margin: 0 } : hidden ? { margin: 0 } : {}
        }
        ref={labelRef}
        htmlFor={fieldLabel || ''}
      >
        {hidden ? (
          <span
            style={{ transition: 'none 0s ease 0s' }}
            className="visually-hidden"
          >
            {name}
          </span>
        ) : (
          <span>
            {name}{' '}
            {fieldLabel && helpText === true && (
              <AmsHelpText category={'helpText'} fieldLabel={fieldLabel} />
            )}
          </span>
        )}
      </label>
      {children}
    </>
  );
};

export default AmsFormLabel;
