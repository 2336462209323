import _, { reverse, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Message } from 'semantic-ui-react';

// Import components
import AmsHelpText from '../../../utils/AmsHelpText';

// Import actions.
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

import { downloadSignedAgreement } from '../../../actions/agreementActions';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';

class VendorInformationForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    errors: {},
    data: {
      oid: '',
      vendorId: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (props.profile.oid !== state.data.oid) {
      const { profileTab } = props;
      const { vendorId, oid } = props.profile;

      if (profileTab !== 'vendorInformation') {
        this.cancelEdit();
      }

      return {
        data: {
          oid,
          vendorId,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      const { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};
    return errors;
  };

  handleChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  agreementsTable = agreements => {
    const dateFormatter = (cell, row) => {
      return cell ? AmsDateFormatters.formatDateTime(cell) : '';
    };

    const sortedAgreements = reverse(sortBy(agreements, ['signedOn']));

    const columns = [
      {
        dataField: 'type',
        text: 'Document Name',
        // sort: true,
      },
      {
        dataField: 'signedOn',
        text: 'Signed Date/Time',
        // sort: true,
        formatter: dateFormatter,
      },
      {
        dataField: 'expirationDate',
        text: 'Expiration Date/Time',
        width: '30%',
        formatter: dateFormatter,
      },
    ];

    return (
      <AmsTable
        remote={false}
        data={sortedAgreements}
        basic
        pagination={false}
        total={sortedAgreements.length}
        columns={columns}
        loading={false}
        options={{
          hideSizePerPage: true,

          showTotal: false,
        }}
        showTotal={false}
      />
    );
  };

  render() {
    const {
      editMode,
      loading,
      errors,
      data,
      emptyValue,
      showSuccess,
    } = this.state;

    return (
      <Form onSubmit={this.onSubmit} loading={loading}>
        <div className="profile-form-title-wrapper">
          <h2>Vendor Information</h2>
        </div>

        <div className="profile-form-fields-wrapper">
          {showSuccess && (
            <Message
              positive
              icon="check"
              header="Success!"
              content="Data saved successfully."
            />
          )}

          {!_.isEmpty(errors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={Object.keys(errors).map(errorKey => (
                <li key={errorKey}>{errors[errorKey]}</li>
              ))}
            />
          )}

          <Form.Group widths="2">
            <Form.Field>
              <label>
                Vendor ID
                <AmsHelpText
                  category="helpText"
                  fieldLabel="userSettingsVendorID"
                />
              </label>
              {editMode ? (
                <Input
                  name="vendorId"
                  onChange={this.handleChange}
                  value={data.vendorId}
                  placeholder="Vendor ID"
                />
              ) : (
                <div className="default-value">
                  {data.vendorId || emptyValue}
                </div>
              )}
            </Form.Field>
          </Form.Group>
        </div>
        <br />
        <div className="profile-form-title-wrapper">
          <h2>Agreements</h2>
        </div>
        <div className="profile-form-fields-wrapper">
          <div>
            {this.props.profile &&
              this.props.profile.signedAgreements &&
              this.props.profile.signedAgreements.length > 0 && (
                <>{this.agreementsTable(this.props.profile.signedAgreements)}</>
              )}
          </div>
        </div>

        {/* <Segment basic floated="right">
          {
            editMode ?
              <div>
                <Button size="large" primary>Save</Button>
                <Button size="large" onClick={(e) => { e.preventDefault(); this.cancelEdit() }} basic>Cancel</Button>
              </div>
              : <Button size="large" floated="right" onClick={(e) => { e.preventDefault(); this.setState({ error: {}, showSuccess: false, editMode: true }); }} >Edit</Button>
          }
        </Segment> */}
      </Form>
    );
  }
}

VendorInformationForm.propTypes = {
  profile: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  profileTab: state.profileTab,
});

export default connect(mapStateToProps, {
  updateUserProfile,
  updateUserRoles,
  downloadSignedAgreement,
})(VendorInformationForm);
