import React from 'react';
import { useSelector } from 'react-redux';
import { List, Segment } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

const FollowUpCorrectiveStatusInfo = props => {
  const { followUpTaskDetail, selectedSubmission } = useSelector(
    state => state.submissions
  );
  const { FollowInfo } = followUpTaskDetail;
  const { surveyStatus, data } = selectedSubmission;

  const selectedCitationDetails = props.selectedCitationDetails;

  return (
    <div>
      {selectedCitationDetails &&
      selectedCitationDetails.isActive &&
      ((!selectedCitationDetails.isPreliminaryFinding &&
        !isEmpty(selectedCitationDetails.amsSubmissionId)) ||
        selectedCitationDetails.isPreliminaryFinding) &&
      (!isEmpty(FollowInfo) ||
        !isEmpty(selectedSubmission) ||
        !isEmpty(followUpTaskDetail)) ? (
        <Segment
          tertiary
          style={{
            background: 'rgba(34, 36, 38, 0.05)',
            color: 'rgba(0,0,0,.95)',
          }}
        >
          <List>
            {selectedCitationDetails &&
              !isEmpty(selectedCitationDetails.amsSubmissionId) &&
              data &&
              data.editedBy && (
                <List.Item>
                  <span
                    role="heading"
                    aria-level="2"
                    className="field-title"
                    aria-label="Submitted By"
                  >
                    Submitted By:
                  </span>{' '}
                  {data.editedBy}
                </List.Item>
              )}
            {
              <List.Item>
                <span
                  role="heading"
                  aria-level="2"
                  className="field-title"
                  aria-label="Submission Status"
                >
                  Submission Status:
                </span>{' '}
                {selectedCitationDetails &&
                !isEmpty(selectedCitationDetails.amsSubmissionId) &&
                data &&
                surveyStatus
                  ? surveyStatus
                  : 'Pending'}
              </List.Item>
            }

            {selectedCitationDetails &&
              !isEmpty(selectedCitationDetails.amsSubmissionId) &&
              data &&
              data.editTime && (
                <List.Item>
                  <span
                    role="heading"
                    aria-level="2"
                    className="field-title"
                    aria-label="Submission Time"
                  >
                    Submission Time:
                  </span>{' '}
                  {AmsDateFormatters.formatDateTime(data.editTime)}
                </List.Item>
              )}
          </List>

          {selectedCitationDetails.isPreliminaryFinding && followUpTaskDetail && (
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Corrective Action Period End Date"
              >
                Corrective Action Period End Date :{' '}
              </span>{' '}
              {selectedCitationDetails &&
              selectedCitationDetails.correctiveActionPeriodEndDate === null
                ? AmsDateFormatters.formatDate(
                    followUpTaskDetail.correctiveActionPeriodEndDate
                  )
                : AmsDateFormatters.formatDate(
                    selectedCitationDetails.correctiveActionPeriodEndDate
                  )}
            </div>
          )}
          {selectedCitationDetails.isPreliminaryFinding && followUpTaskDetail && (
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Corrective Action Period Days"
              >
                Corrective Action Period Days :{' '}
              </span>{' '}
              {selectedCitationDetails &&
              selectedCitationDetails.capDays === null
                ? followUpTaskDetail.capDays
                : selectedCitationDetails.capDays}
            </div>
          )}
        </Segment>
      ) : (
        <Segment
          tertiary
          style={{
            background: 'rgba(34, 36, 38, 0.05)',
            color: 'rgba(0,0,0,.95)',
          }}
        >
          <List.Item>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:
            </span>{' '}
            {selectedCitationDetails && !selectedCitationDetails.isActive
              ? 'Dropped'
              : 'Pending'}
          </List.Item>
        </Segment>
      )}
    </div>
  );
};

export default FollowUpCorrectiveStatusInfo;
