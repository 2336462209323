import React from 'react';
import { Card, Grid } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import { findingCardClasses, determineFindingStatus } from './FindingsUtils';
const FindingsStatusCard = props => {
  const { citation } = props;
  const { selectedReview } = useSelector(state => state.review);

  if (
    !selectedReview ||
    !selectedReview.grantees ||
    selectedReview.grantees.length === 0
  ) {
    return null;
  }
  const boldLabelStyle = { fontFamily: 'PublicSans-Bold' };

  const dueDate = citation?.correctiveActionDueDate;
  const followUpDetermination = citation?.followUpDetermination;
  const findingStatus = citation?.findingStatus;
  return (
    <Card
      className={findingCardClasses(
        'findings-card',
        determineFindingStatus(selectedReview, citation)
      )}
    >
      <Card.Content
        style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
        header="Corrective Action Status"
      />
      <Grid style={{ margin: '10px', marginTop: '-5px', marginBottom: '15px' }}>
        <Grid.Row style={{ marginBottom: '-10px' }}>
          <Grid.Column width={7}>
            <span style={boldLabelStyle}>Due Date:</span>
          </Grid.Column>
          <Grid.Column
            width={9}
            style={{ textAlign: 'left', paddingLeft: '-10px' }}
          >
            {null !== dueDate
              ? AmsDateFormatters.getMoment(dueDate).format('MMMM D, YYYY')
              : 'DROPPED' === findingStatus ||
                ('Follow-up' === selectedReview.reviewType &&
                  [
                    'Withdrawn',
                    'Corrected',
                    'Uncorrected Deficiency',
                    'Elevated to Deficiency',
                    '',
                  ].includes(citation?.followUpDetermination))
              ? 'N/A'
              : 'TBD'}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginBottom: '-10px' }}>
          <Grid.Column width={7}>
            <span style={boldLabelStyle}>Follow Up Determination:</span>
          </Grid.Column>
          <Grid.Column
            width={9}
            style={{ textAlign: 'left', paddingLeft: '-10px' }}
          >
            {followUpDetermination}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  );
};

export default FindingsStatusCard;
