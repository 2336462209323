import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Tab, Grid } from 'semantic-ui-react';

// Import components.
import GranteeViewInstructionTab from './GranteeViewInstructionTab';
import GranteeViewCalendarTab from './GranteeViewCalendarTab';

class GranteeViewAvailabilityTabs extends Component {
  renderDetails() {
    const {
      showTabs,
      showAlert,
      submitAction,
      saveAction,
      closeAction,
      selectedGrantee,
      previouslySavedGrantee,
      fedHolidays,
      extensionUrl,
      linkExpiration,
      updateGranteeData,
      closeUpdateAction,
      expirationDate,
    } = this.props;
    return showTabs ? (
      <Grid>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment basic>
              <Tab
                menu={{ color: 'blue', fluid: true, vertical: true }}
                panes={[
                  {
                    menuItem: 'Instructions',
                    render: () => (
                      <Tab.Pane>
                        <GranteeViewInstructionTab
                          selectedGrantee={selectedGrantee}
                          extensionUrl={extensionUrl}
                          expirationDate={expirationDate}
                        />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Availability Calendar',
                    render: () => (
                      <Tab.Pane>
                        <GranteeViewCalendarTab
                          previouslySavedGrantee={previouslySavedGrantee}
                          selectedGrantee={selectedGrantee}
                          fedHolidays={fedHolidays}
                          showAlert={showAlert}
                          submitAction={submitAction}
                          saveAction={saveAction}
                          closeAction={closeAction}
                          linkExpiration={linkExpiration}
                          updateGranteeData={updateGranteeData}
                          closeUpdateAction={closeUpdateAction}
                        />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ) : (
      <div />
    );
  }
  render() {
    return <div> {this.renderDetails()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(GranteeViewAvailabilityTabs);
