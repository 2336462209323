import LayoutField from 'react-jsonschema-form-layout-2';

export const fields = {
  layout: LayoutField,
};

export const fileUploadFormSchema = {
  type: 'object',
  required: ['evidences'],
  properties: {
    evidences: {
      type: 'array',
      title: 'Attach Supporting Documents',
      items: {
        type: 'string',
      },
    },
  },
};

export const fileUploadUISchema = {
  evidences: {
    'ui:options': {
      orderable: false,
      removable: true,
    },
    items: {
      'ui:field': 'attachmentField',
    },
  },
};
