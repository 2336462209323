import axios from 'axios';

import {
  ADMIN_TAB_SELECTED,
  AZURE_USER_CREATED,
  USER_PROFILE_CREATED,
  USER_PROFILES_FETCHED,
  ADMIN_FILES_FETCHED,
  ROLES_LIST_FETCHED,
  ADD_ERROR,
  REMOVE_ERROR,
  SEARCH_USERS,
  ADMIN_LOGS_FETCHED,
} from '../redux/types';

export const selectTab = active => ({
  type: ADMIN_TAB_SELECTED,
  active,
});

export const userCreatedOnAzure = createdUser => ({
  type: AZURE_USER_CREATED,
  createdUser,
});

export const userProfileCreated = userProfile => ({
  type: USER_PROFILE_CREATED,
  userProfile,
});

export const userProfilesFetched = profiles => ({
  type: USER_PROFILES_FETCHED,
  userProfilesList: profiles,
  profiles: profiles.data,
});

export const adminFilesFetched = files => ({
  type: ADMIN_FILES_FETCHED,
  files,
});

export const rolesListFetched = rolesList => ({
  type: ROLES_LIST_FETCHED,
  rolesList,
});

export const usersFetched = searchUsersResult => {
  return { type: SEARCH_USERS, searchUsersResult };
};

export const logsFetched = logs => ({
  type: ADMIN_LOGS_FETCHED,
  logs,
});

export const setError = error => ({ type: ADD_ERROR, error });

export const removeError = index => ({ type: REMOVE_ERROR, index });

export const fetchUsersList = filterInput => dispatch =>
  axios
    .post(`/users/list/admin`, filterInput)
    .then(response => dispatch(userProfilesFetched(response.data)));

export const fetchFilesList = filterInput => dispatch =>
  axios
    .post(`/files/list/admin`, filterInput)
    .then(response => dispatch(adminFilesFetched(response.data)));

export const updateFile = formData => dispatch =>
  axios.post(`/files/update/admin`, formData).then(response => response.data);

export const createUserOnAzure = (azureData, profileData) => dispatch =>
  axios.post(`/azure/users`, { azureData, profileData }).then(response => {
    if (response.data) {
      const { id, userPrincipalName } = response.data;

      if (id && userPrincipalName) {
        profileData.oid = id;
        profileData.userName = userPrincipalName;
        profileData.roles = profileData.roles.map(role => role.role);

        // Send data to AMS so that profile is created.
        dispatch(createUserProfile(profileData));
      }
    }

    dispatch(userCreatedOnAzure(response.data));
  });

export const createUserProfile = profileData => dispatch => {
  axios
    .post(`/account/create`, profileData)
    .then(response => {
      dispatch(userProfileCreated(response.data));
    })
    .catch(error => {
      dispatch(setError(error));
    });
};

export const getAmsRoles = () => dispatch =>
  axios
    .post('/admin/roles')
    .then(response => dispatch(rolesListFetched(response.data)));

export const fetchSearchUsers = query => dispatch => {
  axios
    .post(`/users/searchUsers/${query}`)
    .then(response => dispatch(usersFetched(response.data)));
};

export const fetchLogs = input => dispatch => {
  axios
    .post(`/admin/logs`, input)
    .then(response => dispatch(logsFetched(response.data)));
};
