import {
  SUBMISSION_UPLOAD,
  SUBMISSIONS_FETCHED,
  SUBMISSION_SELECTED,
  CLASS_ASSIGNMENTS_FETCHED,
  CLASS_FORM_FETCHED,
  CLASS_TASK_DETAIL_FETCHED,
  CLASS_ASSIGNMENTS_ADDED,
  CLASS_SUBMISSIONS_DETAIL_FETCHED,
  CLASS_SAMPLE_REPORT_FETCHED,
  DUAL_COMPARISON_REPORT_FETCHED,
  CLASS_METHODOLOGY_FETCHED,
  PASS_FAIL_REPORT_FETCHED,
  FOLLOWUP_TASK_DETAIL_FETCHED,
  CLASS_VERSION_HISTORY_FETCHED,
  SUBMISSION_FU_SELECTED,
  FU_TASK_DETAIL_FETCHED,
  CLASS_FREEZE_STATUS_FETCHED,
} from '../redux/types';

const initialState = {
  submission: {},
  selectedSubmission: {},
  submissions: [],
  uploadedSubmiision: {},
  classAssignments: {},
  AMSForm: {},
  classTaskDetail: [],
  classAssignmentAdded: '',
  classAssignmentLoading: false,
  classSubmissionsDetail: [],
  followUpTaskDetail: {},
  classSamplingReportList: [],
  dualComparisonReport: [],
  classMethodologyReportList: [],
  passFailReport: {},
};

export default function submissions(state = initialState, action) {
  switch (action.type) {
    case SUBMISSION_UPLOAD:
      return { ...state, uploadedSubmiision: action.submission };

    case SUBMISSIONS_FETCHED:
      return { ...state, submissions: action.submissions };

    case SUBMISSION_SELECTED:
      return { ...state, selectedSubmission: action.submission };

    case SUBMISSION_FU_SELECTED:
      return { ...state, selectedSubmission: action.submission };

    case CLASS_ASSIGNMENTS_FETCHED:
      return {
        ...state,
        classAssignments: action.classAssignments,
        classAssignmentLoading: false,
      };

    case CLASS_ASSIGNMENTS_ADDED:
      return {
        ...state,
        classAssignmentAdded: action.classAssignmentAdded,
        classAssignmentLoading: true,
      };

    case CLASS_FORM_FETCHED:
      return { ...state, AMSForm: action.AMSForm };

    case CLASS_TASK_DETAIL_FETCHED:
      return { ...state, classTaskDetail: action.classTaskDetail };

    case CLASS_SUBMISSIONS_DETAIL_FETCHED:
      return {
        ...state,
        classSubmissionsDetail: action.classSubmissionsDetail,
      };

    case CLASS_SAMPLE_REPORT_FETCHED:
      return {
        ...state,
        classSamplingReportList: action.classSamplingReportList,
      };

    case DUAL_COMPARISON_REPORT_FETCHED:
      return { ...state, dualComparisonReport: action.dualComparisonReport };

    case CLASS_METHODOLOGY_FETCHED:
      return {
        ...state,
        classMethodologyReportList: action.classMethodologyReportList,
      };

    case PASS_FAIL_REPORT_FETCHED:
      return { ...state, passFailReport: action.passFailReport };

    case FOLLOWUP_TASK_DETAIL_FETCHED:
      return { ...state, followUpTaskDetail: action.followUpTaskDetail };

    case FU_TASK_DETAIL_FETCHED:
      return { ...state, followUpTaskDetail: action.followUpTaskDetail };

    case CLASS_VERSION_HISTORY_FETCHED:
      return { ...state, resetVersionHistory: action.resetVersionHistory };

    case CLASS_FREEZE_STATUS_FETCHED:
      return {
        ...state,
        classFreezeDetails: action.classFreezeDetails,
      };

    default:
      return state;
  }
}
