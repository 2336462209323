/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { Button, Card, Divider, Segment } from 'semantic-ui-react';

const NotesList = props => {
  const { tags, filterOptions, defaultFilter } = props;
  //defaultFilter is a key in tags ex: 'performanceMeasure'
  //filterOptions will be all the avaialble values for the defaultFilter key in tags ex: [Program Management, Program Performance]

  let { list } = props;

  //filter is a value in tags, ex: 'Program Management'
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    if (filter === null || filter === undefined) {
      setFilter(tags[defaultFilter]);
    } else {
      const found = filterOptions.find(e => {
        return e.value === filter;
      });
      if (!found) {
        setFilter(tags[defaultFilter]);
      }
    }
  }, [filterOptions]);

  const generateFilterDropdown = () => {
    return (
      filterOptions &&
      filterOptions.map((e, index) => {
        if (e.value === 'divider' && e.text === '') {
          return <Divider key={index}></Divider>;
        }

        return (
          <Button
            style={{
              background: 'none',
              borderStyle: 'none',
              textAlign: 'left',
            }}
            onClick={() => {
              setFilter(e.value);
            }}
            className="dropdown-item"
            active={filter === e.value}
            key={e.key}
            value={e.value}
          >
            {e.text}
          </Button>
        );
      })
    );
  };

  const generateNotes = () => {
    list = list.filter(e => {
      return (
        isEmpty(filter) ||
        e.filter[defaultFilter] === filter ||
        (e.filter[filter] !== undefined && e.filter[filter] === tags[filter])
      );
    });

    return !isEmpty(list) ? (
      <Card.Group style={{ marginTop: '10px' }} itemsPerRow={1}>
        {list.map((e, index) => {
          return (
            <Card key={index}>
              <Card.Content extra content={e.header} />
              <Card.Content content={e.content} />
              <Card.Content extra content={e.footer} />
            </Card>
          );
        })}
      </Card.Group>
    ) : (
      <p className="no-notes-found">No Notes Found</p>
    );
  };

  const showFilterDropdown = () => {
    return (
      !isEmpty(filterOptions) && (
        <Segment basic clearing style={{ paddingLeft: '0px' }}>
          <div className="dropdown">
            <Button
              className="filter-notes-dropdown-button"
              id="dropdownMenu1"
              data-toggle="dropdown"
              style={{
                background: 'none',
                borderStyle: 'none',
              }}
              size="medium"
              as={Link}
              to={`#`}
            >
              Filter Notes
              <span className="caret"></span>
            </Button>
            <div className="dropdown-menu">
              <Button.Group vertical>{generateFilterDropdown()}</Button.Group>
            </div>
          </div>
        </Segment>
      )
    );
  };

  return (
    <>
      {showFilterDropdown()}
      {generateNotes()}
    </>
  );
};

export default NotesList;
