import _ from 'lodash';
import React from 'react';
import { Well } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Segment } from 'semantic-ui-react';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

const ReviewInfo = ({ findings }) => {
  const generateGranteeInfoLink = () => {
    let reviewType = findings && findings.reviewType;
    let grantees = ((findings || {}).grantees || []).map(grantee => {
      return ` ${grantee.granteeName} (${grantee.granteeId})`;
    });

    const info = !_.isEmpty(grantees)
      ? grantees + ' | ' + reviewType + ' ( ' + findings.reviewId + ' ) '
      : null;
    return info ? (
      <h3>
        <Link to={`/review/${findings.reviewId}`}>{info}</Link>
      </h3>
    ) : null;
  };

  const generateShippedReportDetails = () => {
    return (
      <Well>
        <p>
          <strong>Review status: </strong> {(findings || {}).reviewStatus}
        </p>
        <p>
          <strong>Date Report Shipped to the Grantee: </strong>{' '}
          {(findings || {}).reportShippedDate &&
            AmsDateFormatters.formatDate(findings.reportShippedDate)}
        </p>
        {(findings || {}).findings && generateFindingsSummary()}
      </Well>
    );
  };

  const generateFindingsSummary = () => {
    return findings.findings.map((e, index) => {
      return (
        <p key={index}>
          <strong>
            {e.findingType} - {e.capDays} CAP Days -{' '}
            {AmsDateFormatters.formatDate(e.tfcStartDate)} to{' '}
            {AmsDateFormatters.formatDate(e.tfcEndDate)}
          </strong>
        </p>
      );
    });
  };

  return (
    <Segment basic>
      {generateGranteeInfoLink()}
      {generateShippedReportDetails()}
    </Segment>
  );
};

ReviewInfo.propTypes = {};

const mapStateToProps = state => ({
  findings: state.review.fetchedFindingDetails,
});

export default connect(mapStateToProps)(ReviewInfo);
