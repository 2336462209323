import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { HSESDownloadReport } from '../../actions/reportActions';

// Assets
import './assets/style.css';

class HSESWebService extends Component {
  componentDidMount() {
    const guid = this.context.router.params.guid;
    this.props.HSESDownloadReport(guid);
  }

  render() {
    return <div></div>;
  }
}

HSESWebService.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, { HSESDownloadReport })(HSESWebService);
