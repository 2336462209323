const OTHER_KEY = 'opt-other';
const OTHER_REASON_KEY = 'opt-other-reason-';
const NO_CHECKBOX_LIST = 'no_checkbox_list';
const YES_CHECKBOX_LIST = 'yes_checkbox_list';
const OTHER = 'Other';
const REGEX_NUMBER_UNDERSCORE = /^\d+_.*/;
export {
  OTHER_KEY,
  OTHER_REASON_KEY,
  NO_CHECKBOX_LIST,
  YES_CHECKBOX_LIST,
  OTHER,
  REGEX_NUMBER_UNDERSCORE,
};
