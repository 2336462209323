import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Message, Table } from 'semantic-ui-react';

// Import Actions
import { fetchFY19ChildFilesSummary } from '../../../actions/surveyActions';

// Import components.
import ReviewLayout from '../../../utils/layout/ReviewLayout';

const style = {
  tableHeader: {
    background: 'rgb(243, 244, 245)',
  },
};

class ChildFileOutcome extends Component {
  state = {
    errors: {},
    childFilesSummary: [],
  };

  componentDidMount() {
    const { id, amsSubmissionId } = this.props.props.params;

    if (amsSubmissionId) {
      let input = {
        reviewId: id,
        amsSubmissionId: amsSubmissionId,
      };
      this.props
        .fetchFY19ChildFilesSummary(input)
        .then(data => {
          this.setState({
            childFilesSummary: data.childFilesSummary,
          });
        })
        .catch(error =>
          this.setState({
            errors: {
              ...this.state.errors,
              fetchFY19ChildFilesSummary:
                (error.response && error.response.data.message) || '',
            },
          })
        );
    }
  }

  renderChildFiles() {
    const { childFilesSummary } = this.props;

    return (
      <div>
        <Header as="h3">OVERALL ELIGIBILITY FILE SUBMISSION RESULTS</Header>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                scope="col"
                width={10}
                style={style.tableHeader}
              >
                Criteria
              </Table.HeaderCell>
              <Table.HeaderCell scope="col" style={style.tableHeader}>
                Outcome ( Yes / No )
              </Table.HeaderCell>
              <Table.HeaderCell scope="col" style={style.tableHeader}>
                Outcome Details (Child file id's)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Are there any duplicated child files?</Table.Cell>
              <Table.Cell>
                {((childFilesSummary && childFilesSummary[2]) || {})
                  .isThereAnyDuplicateChildIds || false
                  ? 'Yes'
                  : 'No'}
              </Table.Cell>
              <Table.Cell>
                {(
                  ((childFilesSummary && childFilesSummary[2]) || {})
                    .duplicateChildIdsList || []
                ).join(', ')}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Are any child files unavailable for review?
              </Table.Cell>
              <Table.Cell>
                {((childFilesSummary && childFilesSummary[2]) || {})
                  .isThereAnyMissingChildIds || false
                  ? 'Yes'
                  : 'No'}
              </Table.Cell>
              <Table.Cell>
                {(
                  ((childFilesSummary && childFilesSummary[2]) || {})
                    .missingChidIdsList || []
                ).join(', ')}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {childFilesSummary &&
          Array.isArray(childFilesSummary) &&
          childFilesSummary
            .filter((_data, index) => index < 2)
            .map((data, index) => {
              let title =
                'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: DOCUMENTATION - ' +
                data.type;
              let short_description = (
                <Table.Cell>Total files reviewed</Table.Cell>
              );
              let description = (
                <Table.Cell>
                  <p>
                    Total # and % of files with any one of the four
                    documentation errors
                  </p>
                  <ul>
                    <li>No eligibility determination record (EDR) for child</li>
                    <li>Eligibility category not indicated in EDR</li>
                    <li>
                      No documentation that an in-person or phone interview took
                      place
                    </li>
                    <li>Eligibility documentation not retained</li>
                  </ul>
                </Table.Cell>
              );
              if (data.type === '1302_12_k') {
                title =
                  'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: DOCUMENTATION - 1302.12(k)';
              } else if (data.type === '1302_12_c') {
                title =
                  'ELIGIBILITY FILE REVIEW RESULTS FOR ERSEA 1: ELIGIBILITY - 1302.12(c) / 1302.12(e)';
                short_description = (
                  <Table.Cell>Total # of files reviewed</Table.Cell>
                );
                description = (
                  <Table.Cell>
                    <p>
                      Total # of files in error (over allowance) for any of the
                      following income categories
                    </p>
                    <ul>
                      <li>
                        Family income is between 100-130% of poverty guidelines
                      </li>
                      <li>
                        Family income is greater than 130% of poverty guidelines
                      </li>
                      <li>
                        No eligible category determination made by program
                      </li>
                    </ul>
                  </Table.Cell>
                );
              }
              return (
                <>
                  <Header as="h3">{title}</Header>
                  <Table celled unstackable key={index}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          scope="col"
                          width={10}
                          style={style.tableHeader}
                        >
                          Criteria
                        </Table.HeaderCell>
                        <Table.HeaderCell scope="col" style={style.tableHeader}>
                          Count
                        </Table.HeaderCell>
                        <Table.HeaderCell scope="col" style={style.tableHeader}>
                          Percentage
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        {short_description}
                        <Table.Cell>
                          {(data.totalFilesReviewed || {}).count || 0}
                        </Table.Cell>
                        <Table.Cell>
                          {(data.totalFilesReviewed || {}).percent || 0}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        {description}
                        <Table.Cell>
                          {(data.totalFileErrors || {}).count || 0}
                        </Table.Cell>
                        <Table.Cell>
                          {(data.totalFileErrors || {}).percent || 0}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </>
              );
            })}
      </div>
    );
  }

  render() {
    const { childFilesSummary } = this.props;
    const hasMessage =
      childFilesSummary &&
      childFilesSummary['childFilesSummaryPreqFound'] === false;

    return (
      <div>
        {/* <SlidingContainer title={'Child File Submission Results'}> */}
        <ReviewLayout type={'Eligibility Files'} errors={this.state.errors}>
          {/* <div style="well survey-header-info">
            {(location.query || {}).granteeName} ({' '}
            {(location.query || {}).granteeId} ) |{' '}
            {(location.query || {}).reviewType} ( {params.id} )
          </div> */}
          <Header as="h2" content="Eligibility File Submission Results" />

          {hasMessage && (
            <Message info header={childFilesSummary.message}></Message>
          )}

          {childFilesSummary &&
            childFilesSummary.message !==
              'This section of ERSEA was not reviewed' &&
            this.renderChildFiles()}
        </ReviewLayout>
        {/* </SlidingContainer> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    childFilesSummary: state.survey.childFilesSummary,
    selectedReview: state.review.selectedReview,
  };
}

export default connect(mapStateToProps, {
  fetchFY19ChildFilesSummary,
})(ChildFileOutcome);
