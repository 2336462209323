import React from 'react';

//Import components
import AmsHelpText from '../../../utils/AmsHelpText';

const TrainingForm = () => (
  <div>
    <div className="profile-form-title-wrapper">
      <h2>
        Training
        <AmsHelpText category="helpText" fieldLabel="trainingComingSoon" />
      </h2>
    </div>
    <p>Available soon.</p>
  </div>
);

export default TrainingForm;
