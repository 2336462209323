import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Menu, Segment, Responsive, Form } from 'semantic-ui-react';
import _ from 'lodash';

// Import actions.
import { performanceMeasureSelected } from '../../../actions/presiteActions';

class PresitePerformanceMeasures extends React.Component {
  handleDropdownSelect = (e, { value }) => {
    this.performanceMeasuresSelected(value);
  };

  handleMenuSelect = (e, { name }) => {
    e.preventDefault();
    this.performanceMeasuresSelected(name);
  };

  performanceMeasuresSelected = value => {
    const {
      presite: { performanceMeasures, selectedPerformanceMeasure },
    } = this.props;
    const selected = _.find(performanceMeasures, { name: value });

    if (selectedPerformanceMeasure && value !== selectedPerformanceMeasure.name)
      this.props.performanceMeasureSelected(selected);
  };

  renderPerformanceMeasures = () => {
    const {
      presite: { selectedPerformanceMeasure, performanceMeasures },
    } = this.props;

    if (!performanceMeasures) return;

    const list = performanceMeasures.map(item => ({
      key: item.id,
      text: item.name,
      value: item.name,
    }));

    if (!list.length) return null;

    const selectedName = selectedPerformanceMeasure
      ? selectedPerformanceMeasure.name
      : '';

    return (
      <Form>
        <Responsive as={Segment} basic maxWidth={767}>
          <Form.Dropdown
            label="Select Performance Measure"
            placeholder="Performance Measure"
            options={list}
            value={selectedName}
            onChange={this.handleDropdownSelect}
            selectOnBlur={false}
            selection
            search
            fluid
          />
        </Responsive>

        <Responsive className="field" as={Segment} basic minWidth={768}>
          {/* <label>Select Performance Measure </label> */}
          <Menu fluid vertical>
            {list.map((item, index) => (
              <Menu.Item
                key={index}
                to="#"
                link
                as={Link}
                name={item.text}
                content={item.text}
                active={selectedName === item.value}
                onClick={this.handleMenuSelect}
              />
            ))}
          </Menu>
        </Responsive>
      </Form>
    );
  };

  render = () => {
    return <div>{this.renderPerformanceMeasures()}</div>;
  };
}

PresitePerformanceMeasures.propTypes = {
  presite: PropTypes.object.isRequired,
  performanceMeasureSelected: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  presite: state.forms.presite,
});

export default connect(mapStateToProps, { performanceMeasureSelected })(
  PresitePerformanceMeasures
);
