import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  Form,
  Radio,
  Header,
  Button,
  Label,
  Message,
  List,
  Icon,
} from 'semantic-ui-react';
import _ from 'lodash';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

// Import jQueyr Full Calendar.
import $ from 'jquery';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar/dist/fullcalendar.js';

class SchedulerViewGranteeCalendarModal extends Component {
  state = {
    showModal: false,
    selectedType: 'unavailable',
    unavailableDates: [],
    sessionDates: [],
    breakDates: [],
    holidays: [],
    finalUpdate: false,
    unavailableColor: '#323a45',
    nonInSessionColor: '#205493',
    springBreakColor: '#2e8540',
    holidayColor: '#4c2c92',
    showCancelModal: false,
    showErrorModal: false,
    showConfirmModal: false,
    showErrorTwoWeeksMsg: false,
  };

  componentDidUpdate() {
    this.buildCalendar();
  }

  handleShowModal = () => {
    this.setState({ selectedType: 'unavailable' });
    this.getData();
    this.updateCalendar();
  };

  handleShowCancelAction = () => {
    const { handleHideCalendarModal } = this.props;
    handleHideCalendarModal();
  };

  getData = () => {
    const { unavailableDates, sessionDates, breakDates, holidays } = this.props;
    this.setState({
      unavailableDates: !_.isEmpty(unavailableDates)
        ? Array.from(unavailableDates)
        : [],
      sessionDates: !_.isEmpty(sessionDates) ? Array.from(sessionDates) : [],
      breakDates: !_.isEmpty(breakDates) ? Array.from(breakDates) : [],
      holidays: !_.isEmpty(holidays) ? Array.from(holidays) : [],
    });
  };

  handleSubmitUpdate = () => {
    const { unavailableDates, sessionDates, breakDates } = this.state;
    const { handleUpdateMonth } = this.props;
    this.setState({ showErrorTwoWeeksMsg: false });
    if (this.validateUnavailability()) {
      handleUpdateMonth(unavailableDates, sessionDates, breakDates);
    } else {
      this.setState({ showErrorTwoWeeksMsg: true });
    }
  };

  handleDismissTwoWeeksMessage = () => {
    this.setState({ showErrorTwoWeeksMsg: false });
  };

  handleConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  handleCloseErrorAction = () => {
    this.setState({ showErrorModal: false, showModal: true });
  };

  validateUnavailability() {
    const { currentlySelectedMonthYear } = this.props;

    //If the month is November or January then do not check for 2 week availability
    if (
      currentlySelectedMonthYear.month() === 0 ||
      currentlySelectedMonthYear.month() === 10
    ) {
      return true;
    }

    let currentMonth = this.getAvailableDaysInMonth(currentlySelectedMonthYear);
    return this.areTwooWeeksAvailable(currentMonth);
  }

  areTwooWeeksAvailable(currentMonth) {
    const { currentlySelectedMonthYear } = this.props;
    const { unavailableDates, sessionDates, breakDates, holidays } = this.state;
    const month = AmsDateFormatters.getMoment(
      currentlySelectedMonthYear
    ).format('MMMM');
    let allDates = [].concat(
      this.parseHolidays(holidays, month),
      this.parseSelectedMonthOnly(sessionDates, month),
      this.parseSelectedMonthOnly(breakDates, month),
      this.parseSelectedMonthOnly(unavailableDates, month)
    );

    for (let i = 0; i < allDates.length; i++) {
      for (let k = 0; k < currentMonth.length; k++) {
        for (let j = 0; j < currentMonth[k].length; j++) {
          if (currentMonth[k][j].isSame(allDates[i], 'day')) {
            currentMonth[k].splice(j, 1);
          }
        }
      }
    }

    return this.are2WeeksAvailableWith4DaysInaRow(currentMonth);
  }

  parseHolidays(holidays, selectedMonth) {
    let parsed = [];

    // eslint-disable-next-line array-callback-return
    holidays.map(day => {
      if (
        AmsDateFormatters.getMoment(day.date).format('MMMM') === selectedMonth
      )
        parsed.push(AmsDateFormatters.getMoment(day.date));
    });

    return parsed;
  }

  parseSelectedMonthOnly(days, selectedMonth) {
    let parsed = [];

    // eslint-disable-next-line array-callback-return
    days.map(day => {
      if (day.format('MMMM') === selectedMonth)
        parsed.push(AmsDateFormatters.getMoment(day));
    });

    return parsed;
  }

  are2WeeksAvailableWith4DaysInaRow(currentMonth) {
    let numberOfWeeks = 0;
    currentMonth.forEach(function(week) {
      if (week.length >= 4) {
        if (week.length > 4) {
          numberOfWeeks++;
        } else {
          let day1 = parseInt(week[0].format('DD'));
          let day2 = parseInt(week[1].format('DD'));
          let day3 = parseInt(week[2].format('DD'));
          let day4 = parseInt(week[3].format('DD'));
          if (day2 === day1 + 1 && day3 === day2 + 1 && day4 === day3 + 1)
            numberOfWeeks++;
        }
      }
    });

    if (numberOfWeeks >= 2) return true;
    return false;
  }

  getAvailableDaysInMonth(currentlySelectedMonthYear) {
    const mondays = this.getMondaysOfSelectedMonth(currentlySelectedMonthYear);
    let month = [];
    mondays.forEach(function(monday) {
      let date = monday.endOf('day');
      let daysOfweek = [];
      let day = 0;
      while (day < 5) {
        day++;
        daysOfweek.push(date);
        date = date.clone().add(1, 'd');
        if (AmsDateFormatters.getMoment(date).format('dddd') === 'Saturday')
          day = 5;
        if (
          AmsDateFormatters.getMoment(date).format('MMMM') !==
          currentlySelectedMonthYear.format('MMMM')
        )
          day = 5;
      }
      if (daysOfweek.length >= 4) month.push(daysOfweek);
    });
    return month;
  }

  getMondaysOfSelectedMonth(currentlySelectedMonthYear) {
    let mondays = [];
    let currentMonth = currentlySelectedMonthYear.clone();
    const startOfMonth = currentMonth.startOf('month');
    if (startOfMonth.format('dddd') == 'Tuesday') {
      mondays.push(startOfMonth.clone());
    }
    let monday = currentMonth.startOf('month').day('Monday');
    if (monday.date() > 7) monday = monday.clone().add(7, 'd');
    const month = monday.month();
    while (month === monday.month()) {
      mondays.push(monday);
      monday = monday.clone().add(7, 'd');
    }
    return mondays;
  }

  handleSelectedStateChange = (e, { value }) => {
    this.setState({ selectedType: value });
  };

  handleEventClick(calEvent, jsEvent, view) {
    let dates = [];
    const { editMode } = this.props;

    if (editMode) {
      if (calEvent && calEvent.title.toLowerCase() === 'unavailable') {
        dates = this.state.unavailableDates;
        dates.splice(dates.indexOf(calEvent.date), 1);
        this.setState({ unavailableDates: dates });
      }
      if (calEvent && calEvent.title.toLowerCase() === 'not in session') {
        dates = this.state.sessionDates;
        dates.splice(dates.indexOf(calEvent.date), 1);
        this.setState({ sessionDates: dates });
      }
      if (calEvent && calEvent.title.toLowerCase() === 'spring break') {
        dates = this.state.breakDates;
        dates.splice(dates.indexOf(calEvent.date), 1);
        this.setState({ breakDates: dates });
      }
      this.updateCalendar();
    }
  }

  handleDayClick(date, jsEvent, view, resourceObj) {
    const { editMode } = this.props;
    if (editMode) {
      let newDate = AmsDateFormatters.getMoment.utc(date).endOf('day');
      let weekday = AmsDateFormatters.getMoment(newDate).format('dddd');
      let holidayCheck = AmsDateFormatters.getMoment(newDate).format(
        'YYYY-MM-DD 00:00:00'
      );
      if (!this.doesEventExistOnThisDay(newDate, weekday, holidayCheck)) {
        this.addDayToSelectedEvent(newDate);
      }
    }
  }

  doesEventExistOnThisDay(dateToValidate, weekday) {
    if (this.validateIsWeekend(weekday)) return true;
    if (this.validateIsEventAlready(dateToValidate)) return true;
    return false;
  }

  validateIsWeekend(weekday) {
    const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
    if (isWeekend) return true;
    return false;
  }

  validateIsEventAlready(dateToValidate) {
    const { unavailableDates, sessionDates, breakDates } = this.state;
    if (this.isDayInDates(dateToValidate, unavailableDates)) return true;
    if (this.isDayInDates(dateToValidate, sessionDates)) return true;
    if (this.isDayInDates(dateToValidate, breakDates)) return true;
    return false;
  }

  isDayInDates(dateToValidate, dates) {
    let result = false;
    // eslint-disable-next-line array-callback-return
    dates.map(day => {
      if (dateToValidate.isSame(day, 'day')) result = true;
    });
    return result;
  }

  addDayToSelectedEvent(date) {
    if (this.state.selectedType === 'unavailable') {
      this.state.unavailableDates.push(date);
    } else if (this.state.selectedType === 'session') {
      this.state.sessionDates.push(date);
    } else {
      this.state.breakDates.push(date);
    }
    this.updateCalendar();
  }

  buildCalendar() {
    const { currentlySelectedMonthYear } = this.props;
    $('#scheduler-view-grantee-calendar').fullCalendar({
      header: {
        left: 'title',
        center: '',
        right: '',
      },
      defaultDate: currentlySelectedMonthYear,
      contentHeight: 'auto',
      weekends: true,
      customButtons: {},
      timezone: 'America/New_York', // We're enforcing Easter Timezone.
      showNonCurrentDates: false,
      dayClick: this.handleDayClick.bind(this),
      eventClick: this.handleEventClick.bind(this),
      displayEventTime: false,
    });
    this.updateCalendar();
  }

  updateCalendar() {
    let unavailableDates = this.updateIcons(
      this.state.unavailableDates,
      'Unavailable',
      this.state.unavailableColor
    );
    let sessionDates = this.updateIcons(
      this.state.sessionDates,
      'Not in session',
      this.state.nonInSessionColor
    );
    let breakDates = this.updateIcons(
      this.state.breakDates,
      'Spring Break',
      this.state.springBreakColor
    );
    let holidays = this.updateIcons(
      this.state.holidays,
      '',
      this.state.holidayColor
    );
    $('#scheduler-view-grantee-calendar').fullCalendar('removeEvents');
    $('#scheduler-view-grantee-calendar').fullCalendar('addEventSource', [
      ...unavailableDates,
      ...sessionDates,
      ...breakDates,
      ...holidays,
    ]);
  }

  updateIcons(dates, eventType, color) {
    const { currentlySelectedMonthYear } = this.props;
    const month = currentlySelectedMonthYear.format('MMMM');
    let returnDays = [];
    // eslint-disable-next-line array-callback-return
    dates.map(day => {
      if (eventType !== '') {
        if (month === day.format('MMMM')) {
          returnDays.push({ date: day, title: eventType, color: color });
        }
      } else {
        //holidays
        returnDays.push({
          date: day.date,
          title: day.description,
          color: color,
        });
      }
    });
    return returnDays;
  }

  renderTwoWeeksAvailableMessage() {
    return (
      <Message negative onDismiss={this.handleDismissTwoWeeksMessage}>
        <Message.Header>
          You have selected too many days to be unavailable.
        </Message.Header>
        <List>
          <List.Item className="red">
            {' '}
            <Icon name="help" />
            At least 4 days in a week and two weeks in a month should be
            available.
          </List.Item>
        </List>
      </Message>
    );
  }

  calendarFormModal() {
    // eslint-disable-next-line no-unused-vars
    const { showModal, selectedType, showErrorTwoWeeksMsg } = this.state;
    const {
      showCalendarModal,
      currentlySelectedMonthYear,
      editMode,
    } = this.props;
    const month = currentlySelectedMonthYear.format('MMMM');
    return (
      <div>
        <Modal
          id="calendar-modal"
          show={showCalendarModal}
          onHide={this.handleShowCancelAction}
          onShow={this.handleShowModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Select Unavailabilty Dates for{' '}
              {currentlySelectedMonthYear.format('MMMM YYYY')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Header as="h5">Weekends will be blocked.</Header>
            {showErrorTwoWeeksMsg ? this.renderTwoWeeksAvailableMessage() : ''}
            <Form>
              <Form.Group inline>
                <Form.Field
                  role="group"
                  width={5}
                  label={{
                    children: 'Grantee Unavailable',
                    htmlFor: 'unavailable',
                  }}
                  name="selectedStateGroup"
                  value="unavailable"
                  className="radio-toolbar width-adjustment"
                  checked={selectedType === 'unavailable'}
                  onChange={this.handleSelectedStateChange}
                  control={Radio}
                  id="unavailable"
                  aria-labelledby="unavailable"
                />
                <Form.Field
                  role="group"
                  width={6}
                  label={{
                    children: 'Children not in session',
                    htmlFor: 'session',
                  }}
                  name="selectedStateGroup"
                  value="session"
                  className="radio-toolbar width-adjustment"
                  checked={selectedType === 'session'}
                  onChange={this.handleSelectedStateChange}
                  control={Radio}
                  id="session"
                  aria-labelledby="session"
                />
                <Form.Field
                  role="group"
                  width={5}
                  label={{ children: 'Spring Break', htmlFor: 'break' }}
                  name="selectedStateGroup"
                  value="break"
                  className="radio-toolbar width-adjustment"
                  checked={selectedType === 'break'}
                  onChange={this.handleSelectedStateChange}
                  control={Radio}
                  id="break"
                  aria-labelledby="break"
                />
              </Form.Group>
              <input type="submit" value="submit" hidden />
            </Form>
            <div
              className="calendar-widget"
              id="scheduler-view-grantee-calendar"
              ref="calendar"
            />
            <Form.Group>
              <Form.Field>
                <Header as="h4">Key</Header>
                <Form.Group inline>
                  <Label
                    className="grantee-calendar-available-color"
                    horizontal
                  >
                    Available
                  </Label>
                  <Label
                    className="grantee-calendar-unavailable-color"
                    horizontal
                  >
                    Unavailable
                  </Label>
                  <Label className="grantee-calendar-session-color" horizontal>
                    Not In Session
                  </Label>
                  <Label className="grantee-calendar-break-color" horizontal>
                    Spring Break
                  </Label>
                  <Label className="grantee-calendar-holiday-color" horizontal>
                    Holiday
                  </Label>
                </Form.Group>
              </Form.Field>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!editMode}
              color="blue"
              onClick={this.handleSubmitUpdate}
            >
              Done For {month}
            </Button>
            <Button color="red" onClick={this.handleShowCancelAction}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  render() {
    return <div>{this.calendarFormModal()}</div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(SchedulerViewGranteeCalendarModal);
