import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Menu, Responsive, Segment } from 'semantic-ui-react';
import { Link } from 'react-router';
import { find, isEmpty } from 'lodash';

export default function RAN24PerformanceMeasures(props) {
  const { selectedGuide, selectedPerformanceMeasure } = useSelector(
    state => state.forms.ran
  );
  const [pmList, setPmList] = useState([]);
  const [selectedName, setSelectedName] = useState('');

  // useEffect to set the selected guide performance measure list options
  useEffect(() => {
    if (!isEmpty(selectedGuide)) {
      let list = selectedGuide.performanceMeasures.map(item => ({
        key: item.id,
        text: item.name,
        value: item.name,
      }));
      setPmList(list);
    }
  }, [selectedGuide]);

  // useEffect to set the selected performance measure name
  useEffect(() => {
    if (selectedPerformanceMeasure) {
      setSelectedName(selectedPerformanceMeasure.name);
    }
  }, [selectedPerformanceMeasure]);

  return (
    <>
      <Form>
        <Responsive as={Segment} basic maxWidth={767}>
          <Form.Dropdown
            label="Select Performance Area"
            placeholder="Performance Area"
            options={pmList}
            value={selectedName}
            // onChange={this.handleDropdownSelect}
            selectOnBlur={false}
            selection
            search
            fluid
          />
        </Responsive>

        <Responsive className="field" as={Segment} basic minWidth={768}>
          <label>Select Performance Area</label>
          <Menu fluid vertical>
            {pmList.map((item, index) => (
              <Menu.Item
                key={index}
                href="javascript:void(0);"
                as={Link}
                name={item.text}
                content={item.text}
                active={selectedName === item.value}
                onClick={(e, { name }) => {
                  const selectedPM = find(selectedGuide.performanceMeasures, {
                    name: name,
                  });

                  if (
                    selectedPerformanceMeasure &&
                    name !== selectedPerformanceMeasure.name
                  )
                    props.changePerformanceMeasure(selectedPM, selectedGuide);
                }}
              />
            ))}
          </Menu>
        </Responsive>
      </Form>
    </>
  );
}
