import React from 'react';

// Import components

import AmsAlert from '../../../utils/AmsAlert';

const ReviewerMaxWarningModal = ({ onConfirm, onCancel, show }) => {
  return (
    <AmsAlert
      show={show}
      title="Are you sure?"
      type="warning"
      showConfirm
      showCancelButton
      cancelButtonText="No"
      confirmButtonText="Yes"
      confirmButtonColor={'#DD6B55'}
      text={
        'The total number of reviewers required for this position is already met, do you want to continue to add an additional reviewer?'
      }
      onConfirm={() => {
        onConfirm();
      }}
      onCancel={() => {
        onCancel();
      }}
    />
  );
};

export default ReviewerMaxWarningModal;
