import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

// Content tools
import '../assets/content-tools/content-tools.min.css';

// Plugins
import '../assets/plugins/fontawesome/css/font-awesome.css';

// Style
/*import '../assets/css/ams-base.min.css';
import '../assets/css/ams-blue.css';
import '../assets/css/components/button.css';
import '../assets/css/ams.css';*/
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select/dist/react-select.css';
import 'sweetalert/dist/sweetalert.css';
import '../assets/css/app.css';
import '../assets/css/react-bootstrap-table.css';
import '../assets/css/weather-icons-master/css/weather-icons.min.css';
import '../semantic/dist/semantic.min.css';

// Custom components
import OutdatedBrowser from '../utils/OutdatedBrowser';
import ScrollToTop from '../utils/ScrollToTop';
import { ToastContainer } from 'react-toastify';

//layout
import { find } from 'lodash';
import AgreementsPage from '../utils/AgreementsPage';
import ResponsiveContainer from './Layouts/ResponsiveContainer';

class App extends Component {
  state = {
    alertVisible: true,
  };

  handleAlertDismiss() {
    this.setState({ alertVisible: false });
  }

  render() {
    // If there is an outdated browser, show the message.
    if (OutdatedBrowser && OutdatedBrowser.length > 0)
      return (
        <>
          <OutdatedBrowser />
        </>
      );

    if (find(this.props.agreements, agreement => agreement.signed === false)) {
      return <AgreementsPage />;
    }

    if (this.props.location.pathname === '/reviews') {
      browserHistory.push('/report');
    }

    return (
      <>
        <ScrollToTop />
        <ToastContainer />
        <ResponsiveContainer {...this.props}>
          {this.props.children}
        </ResponsiveContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  agreements: state.agreement.agreements,
});

export default connect(mapStateToProps, {})(App);
