import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

// Import actions
import { fetchCitations } from '../../actions/reviewActions';

class ReviewCitationsPage extends Component {
  state = {
    roleType: '',
    submitter: '',
    submitterOptions: [],
    citations: [],
  };

  componentDidMount = () => {
    this.props.fetchCitations({});
  };

  // componentWillReceiveProps = nextProps => {
  //   if (nextProps.citations) {
  //     this.setState({ citations: nextProps.citations.citations });
  //   }
  // };

  renderTable = () => {
    const { citations } = this.props;
    const citationNumber = this.props.location.query.citation;

    return (
      <div>
        <BootstrapTable
          ref="table"
          trClassName="clickable"
          data={
            citations
              ? citations.citations?.filter(
                  cit => cit.standardNumber === citationNumber
                )
              : []
          }
          tableContainerClass="table-container"
          headerContainerClass="table-header"
          hover
          condensed
          bordered={false}
          search
        >
          <TableHeaderColumn
            width="10"
            dataField="standardNumber"
            dataSort={false}
            isKey={true}
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
          >
            Citation Number
          </TableHeaderColumn>
          <TableHeaderColumn
            width="60"
            dataField="standardText"
            dataSort={false}
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
          >
            Citation Text
          </TableHeaderColumn>
          <TableHeaderColumn
            width="30"
            dataField="affiliatedPerformanceMeasure"
            dataSort={false}
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
          >
            Affiliated Performance Measure
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>
          <hr />
        </div>
        {this.renderTable()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    citations: state.review.citations,
  };
}

ReviewCitationsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { fetchCitations })(
  ReviewCitationsPage
);
