import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Item, Search } from 'semantic-ui-react';
// Import actions.
import { fetchSearchUsers } from '../actions/adminActions';

const resultRenderer = ({ fullName }) => {
  return <Item.Meta content={fullName} />;
};

const SearchUsers = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const { searchUsersList } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedValue.trim() || selectedValue.trim().length < 3) {
      setIsLoading(false);
    } else {
      dispatch(fetchSearchUsers(selectedValue.trim()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue.length < 3) {
      setIsLoading(false);
      setResults([]);
      setSelectedValue(selectedValue);
    } else {
      const re = new RegExp(_.escapeRegExp(selectedValue), 'i');
      const isMatch = result => re.test(result.fullName);
      setIsLoading(false);
      setResults(_.filter(searchUsersList, isMatch));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUsersList]);

  const handleResultSelect = (e, { result }) => {
    const selectedValue = result.fullName;
    setSelectedValue(selectedValue);
    props.onValueSelected(e, selectedValue);
  };

  const handleSearchChange = (e, { value }) => {
    setIsLoading(true);
    setSelectedValue(value);
    props.onValueSelected(e, value);
  };

  const renderUsers = () => {
    return (
      <Search
        placeholder="Search Name"
        loading={isLoading}
        onResultSelect={handleResultSelect}
        onSearchChange={_.debounce(handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        value={selectedValue}
        resultRenderer={resultRenderer}
        id="name"
        aria-labelledby="name"
      />
    );
  };
  return renderUsers();
};

const mapStateToProps = state => ({
  searchUsersList: state.admin.searchUsersResult,
});

export default connect(mapStateToProps, {})(SearchUsers);
