import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Radio,
  TextArea,
  Header,
  Modal,
  Icon,
  Dropdown,
  Segment,
  Divider,
  Grid,
  Dimmer,
  Loader,
  List,
} from 'semantic-ui-react';
import { Well } from 'react-bootstrap';
import { isEmpty, has, find, isNil, includes, filter } from 'lodash';
import { Link } from 'react-router';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTableNext from 'react-bootstrap-table-next';

// Import actions.
import {
  fetchReviewDetail,
  fetchCitations,
} from '../../../actions/reviewActions';
import { postFormSubmission } from '../../../actions/surveyActions';
import {
  fetchReviewGuides,
  fetchReviewPerformanceMeasures,
} from '../../../actions/fy19/reviewActions';
import {
  fetchFA2FormSchema,
  fetchFA2FormDetail,
  fa2GuideSelected,
  fa2PerformanceMeasureSelected,
} from '../../../actions/fa2Actions';

// Import components.
import FindingsOutsideProtocolAttachmentField from './FA2FindingsOutsideProtocolAttachmentField';

// Import utils.
import AmsAlert from '../../../utils/AmsAlert';
import AmsAlertMessage from '../../../utils/AmsAlertMessage';
import AmsModal from '../../../utils/AmsModal';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

// Disallowance citations that enable additional questions on the form.
const DISALLOWANCE_CITATIONS = [
  '75.302(b)(2)',
  '75.302(b)(5)',
  '75.306(b)(1)',
  '75.306(b)(2)',
  '75.306(b)(3)',
  '75.306(b)(4)',
  '75.306(b)(5)',
  '75.306(b)(6)',
  '75.306(b)(7)',
  '75.306(d)(1)',
  '75.306(d)(2)',
  '75.306(e)',
  '75.308(c)(1)(iv)',
  '75.308(c)(1)(vii)',
  '75.309(a)',
  '75.309(b)',
  '75.320(a)(1)',
  '75.320(a)(2)',
  '75.320(a)(3)',
  '75.320(e)',
  '75.333(a)',
  '75.333(b)(1)',
  '75.333(b)(2)',
  '75.333(b)(3)',
  '75.333(b)(4)',
  '75.333(b)(5)',
  '75.333(c)',
  '75.342(b)(1)',
  '75.431(a)',
  '641A(e)(1)(B)(i)',
  '641A(e)(1)(B)(ii)',
  '641A(e)(1)(B)(iii)',
];

const FindingsOutsideProtocol = (props, { router }) => {
  // Grab data from the data props
  const {
    fa2: {
      reviewId,
      submission,
      selectedPerformanceMeasure,
      selectedGuide,
      guides,
    },
    fa2,
  } = useSelector(state => state.forms);

  const performanceMeasure =
    selectedGuide &&
    selectedGuide.performanceMeasures &&
    find(
      selectedGuide.performanceMeasures,
      p => p.name === 'Findings Outside the Protocol'
    );

  const { amsFormId, amsSubmissionId } =
    performanceMeasure && performanceMeasure.forms[0];

  const { selectedReview } = useSelector(state => state.review);

  const [loading, setLoading] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [dataHasChanged, setDataHasChanged] = useState(false);

  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [draftButtonClicked, setDraftButtonClicked] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const [performanceLoading, setPerformanceLoading] = useState(false);
  const [fetchingGuides, setFetchingGuides] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [additionalCitations, setAdditionalCitations] = useState();

  const [showCitationModal, setShowCitationModal] = useState(false);
  const [citation, setCitation] = useState([]);

  const [filters, setFilters] = useState({
    reviewId: '',
    selectGuideName: '',
    selectPerformanceMeasure: '',
    type: '',
  });

  const { citations } = useSelector(state => state.review.citations);
  const dispatch = useDispatch();

  const amsTaskRef = useRef(null);

  const [query, setQuery] = useState('');
  const [clickedIndex, setClickedIndex] = useState(null);

  const [data, setData] = useState({
    reviewId,
    amsFormId,
    surveyData: {
      question1: undefined,
      additionalCitations,
    },
  });

  // useEffect to fetch the review detail
  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      if (
        reviewId &&
        selectedReview &&
        (!has(selectedReview.reviewId) || selectedReview.reviewId === '')
      ) {
        try {
          // Fetch Review Detail.
          await dispatch(fetchReviewDetail({ reviewId }));
          setLoading(false);
        } catch (err) {
          setResponseErrors(err);
        }
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to fetch citations
  useEffect(() => {
    const getData = async () => {
      if (!citations && selectedReview.fiscalYear) {
        try {
          await dispatch(
            fetchCitations({
              reviewType: 'FA-2',
              fiscalYear: selectedReview.fiscalYear,
              reviewId: selectedReview.reviewId,
            })
          );
        } catch (err) {
          setResponseErrors(err);
        }
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReview.fiscalYear]);

  // useEffect to fetch review guides when selected review changes
  useEffect(() => {
    const getData = async () => {
      if (
        selectedReview.reviewId &&
        (!selectedReview.guides ||
          selectedReview.guides.length === 0 ||
          !selectedReview.performanceMeasures) &&
        fetchingGuides === false
      ) {
        try {
          const { reviewId, reviewType, fiscalYear } = selectedReview;
          if (!reviewId && !reviewType) return;

          const requestInput = { reviewId, reviewType, fiscalYear };

          await dispatch(fetchReviewGuides(requestInput));
          await loadPerformanceMeasures(1);

          setFetchingGuides(true);
        } catch (error) {
          setResponseErrors(error);
        }
      }

      if (
        fetchingGuides === true &&
        selectedReview.guides &&
        selectedReview.performanceMeasures
      ) {
        setFetchingGuides(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReview]);

  // useEffect to load performance measures when selected guide changes
  useEffect(() => {
    if (selectedReview && selectedReview.reviewId && !fetchingGuides)
      loadPerformanceMeasures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.guideName]);

  // useEffect to set the data for the form when additional citations are added or fetched
  useEffect(() => {
    setData({
      ...data,
      surveyData: {
        ...data.surveyData,
        additionalCitations,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalCitations]);

  // useEffect to set form state
  useEffect(() => {
    if (!isEmpty(submission)) {
      submission.data && setData({ ...submission.data });

      submission.data &&
        submission.data.surveyData &&
        setFormDisabled(!isEmpty(submission.data.surveyData));
    } else {
      setFormDisabled(false);
    }
  }, [submission]);

  /**
   * Fetch form schema
   */
  const fetchFormSchema = () => {
    setLoading(true);

    if (amsFormId) {
      dispatch(fetchFA2FormSchema({ filters: { amsFormId } }));
    } else {
      // TO DO: Display something when form id is not present
      setLoading(false);
    }
  };

  const scrollToResults = () => scrollToRef(amsTaskRef);

  const scrollToRef = ref => {
    window.scrollTo(0, ref.current.offsetTop);
    ref.current.focus();
  };

  /**
   * Fetch performance measures based on the selected guide filter
   */
  const loadPerformanceMeasures = () => {
    let guideCode;

    if (filters.guideName !== '') {
      guideCode =
        selectedReview &&
        selectedReview.guides &&
        selectedReview.guides.find(e => e.name === filters.guideName);
    }

    const { reviewId, reviewType, fiscalYear } = selectedReview;

    let requestInput = { reviewId, reviewType, fiscalYear };

    if (guideCode) {
      requestInput.guideCode = guideCode.guideCode;
      requestInput.guideName = guideCode.name;
    }

    setFilters({
      ...filters,
      performanceMeasure: '',
    });

    setPerformanceLoading(true);
    dispatch(fetchReviewPerformanceMeasures(requestInput));
    setPerformanceLoading(false);
  };

  /**
   * Handle the change from dropdown of pm and guide
   * @param {Object} e
   * @param {object} data
   * @param {Number} index
   * @returns {void}
   */
  const handleDropdownChange = (e, data, index) => {
    const { name, value } = data;

    const values = [...props.formData.additionalCitations];

    if (
      name === 'selectGuideName' &&
      values[index]['selectGuideName'] !== value
    ) {
      values[index]['selectPerformanceMeasure'] = '';
    }

    values[index] = {
      ...values[index],
      [name]: value,
    };

    props.handleDataChange({
      formData: {
        ...props.formData,
        additionalCitations: values,
      },
    });
  };

  /**
   * Handle the change from the additional citations form questions for disallowance
   * @param {Object} i
   * @param {object} e
   * @returns {void}
   */
  const handleChange = (i, e) => {
    let { name, value } = e.target;

    const values = [...props.formData.additionalCitations];

    values[i] = {
      ...values[i],
      [name]: value,
    };

    props.handleDataChange({
      formData: {
        ...props.formData,
        additionalCitations: values,
      },
    });
  };

  // Handle addition of new citation
  const handleAdd = () => {
    const values = [...props.formData.additionalCitations];
    values.push({});
    props.handleDataChange({
      formData: {
        ...props.formData,
        additionalCitations: values,
      },
    });

    setAdditionalCitations(values);
  };

  // Handle deletion of citation
  const handleRemove = i => {
    const values = [...props.formData.additionalCitations];
    values.splice(i, 1);

    props.handleDataChange({
      formData: {
        ...props.formData,
        additionalCitations: values,
      },
    });
    setAdditionalCitations(values);
  };

  // Handle changes to attachments
  const handleAttachmentChange = (additionalCitation, index, { formData }) => {
    const values = [...props.formData.additionalCitations];

    values[index] = {
      ...values[index],
      evidenceFiles: formData.evidenceFiles,
    };

    props.handleDataChange({
      formData: {
        ...props.formData,
        additionalCitations: values,
      },
    });
  };

  /**
   * Build and render guides and performance measures for the form
   * @param {Object} additionalCitation
   * @param {object} index
   * @returns {Object}
   */
  const renderGuides = (additionalCitation, index) => {
    const { type } = filters;
    let guideOptions;
    let performanceMeasureOptions;

    let guideName = additionalCitation.selectGuideName;

    if (guides && guides.length > 0) {
      guideOptions = guides
        .filter(guide => {
          return (
            guide.name !== 'Highlights, Summaries and Other Findings' &&
            guide.name !== 'COVID-19' &&
            guide.name !== 'OHS Notes'
          );
        })
        .map(guide => {
          return {
            text: guide.name,
            value: guide.name,
            key: guide.guideCode,
          };
        });
    }

    if (!guideName)
      performanceMeasureOptions = [
        {
          key: '',
          value: '',
          text: '',
        },
      ];
    else {
      let selectedGuide =
        guides &&
        filter(guides, guide => {
          if (guide.name === guideName) {
            return guide;
          }
        });
      performanceMeasureOptions =
        selectedGuide &&
        selectedGuide.length > 0 &&
        selectedGuide[0].performanceMeasures &&
        selectedGuide[0].performanceMeasures.map(
          (performanceMeasure, index) => {
            return {
              key: index,
              value: performanceMeasure.name,
              text: performanceMeasure.name,
            };
          }
        );
    }

    const additionalCitationsError =
      responseErrors && responseErrors.additionalCitationsErrors
        ? responseErrors.additionalCitationsErrors[index]
        : {};

    return (
      <>
        <Form.Field>
          <AmsFormLabel name="Guide Name*" fieldLabel="filterGuideName">
            <Dropdown
              placeholder="Select Guide Name"
              value={additionalCitation && additionalCitation.selectGuideName}
              name="selectGuideName"
              error={
                additionalCitationsError &&
                !isNil(additionalCitationsError.selectGuideName) &&
                !!additionalCitationsError.selectGuideName
              }
              disabled={type === 'presite' ? true : formDisabled}
              onChange={(e, d) => handleDropdownChange(e, d, index)}
              options={guideOptions || []}
              fluid
              search
              selection
              required
              aria-labelledby="filterGuideName"
              searchInput={{
                id: 'filterGuideName',
                title: 'Select a Guide Name',
                'aria-labelledby': 'filterGuideName',
              }}
            />
          </AmsFormLabel>
        </Form.Field>

        <Form.Field>
          <AmsFormLabel
            name="Performance Measure*"
            helpText={true}
            fieldLabel="filterPerformanceMeasure"
          >
            <Dropdown
              placeholder="Select Performance Measure"
              name="selectPerformanceMeasure"
              error={
                additionalCitationsError &&
                !isNil(additionalCitationsError.selectPerformanceMeasure) &&
                !!additionalCitationsError.selectPerformanceMeasure
              }
              value={
                additionalCitation &&
                additionalCitation.selectPerformanceMeasure
              }
              disabled={type === 'presite' ? true : formDisabled}
              onChange={(e, d) => handleDropdownChange(e, d, index)}
              options={performanceMeasureOptions || []}
              fluid
              loading={performanceLoading}
              search
              selection
              aria-labelledby="filterPerformanceMeasure"
              searchInput={{
                id: 'filterPerformanceMeasure',
                title: 'Filter by Performance Measure',
              }}
            />
          </AmsFormLabel>
        </Form.Field>
      </>
    );
  };

  /**
   * Render citation label
   * @param {Object} additionalCitation
   * @returns {Object}
   */
  const renderCitationLabels = additionalCitation => {
    if (additionalCitation.citation && additionalCitation.citation.length > 0) {
      return (
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          {additionalCitation.citation}
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          No citations selected.
        </div>
      );
    }
  };

  // Build and render the citations table for the modal
  const renderCitationTable = () => {
    const style = {
      description: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 'auto',
      },
    };

    const formatDescription = (cell, row) => {
      return <div style={style.description}>{cell}</div>;
    };

    const columns = [
      {
        dataField: 'standardNumber',
        text: 'Citation Number',
        formatter: (cell, row) => {
          return (
            <Link onClick={e => e.preventDefault()} to="#">
              {cell}
            </Link>
          );
        },
        sort: true,
        headerStyle: {
          width: '30%',
        },
      },
      {
        dataField: 'standardText',
        formatter: formatDescription,
        text: 'Citation Description',

        headerStyle: {
          width: '70%',
        },
      },
    ];

    const total = citations ? citations.length : 0;

    const selectRow = {
      mode: 'radio',
      style: { background: '#c8e6c9' },
      clickToSelect: true,
      selected: citation,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          setCitation([row.standardNumber]);
        } else {
          setCitation([]);
        }
      },
      selectionRenderer: ({ mode, ...rest }) => {
        return rest.checked ? (
          <Icon color="green" name="check circle"></Icon>
        ) : null;
      },
    };

    const CitationSearch = props => {
      const handleClick = () => {
        props.onSearch(query);
      };
      return (
        <>
          <Input
            style={{ width: '92%', marginRight: '5px' }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                handleClick();
              }
            }}
          >
            <input
              placeholder="Search..."
              value={query}
              autoFocus
              onChange={e => {
                setQuery(e.target.value);
              }}
              type="text"
            />
          </Input>
          <Button onClick={handleClick}>Filter</Button>
        </>
      );
    };

    return (
      <ToolkitProvider
        keyField="standardNumber"
        data={(citations && citations) || []}
        columns={columns}
        search
      >
        {props => (
          <>
            <Modal.Content style={{ paddingBottom: '5px' }}>
              <CitationSearch {...props.searchProps} />
            </Modal.Content>
            <Modal.Content style={{ paddingTop: '0px' }} scrolling>
              <BootstrapTableNext
                selectRow={selectRow}
                search
                remote={false}
                basic
                pagination={paginationFactory({
                  hideSizePerPage: true,
                  sizePerPage: 15,
                })}
                total={total}
                columns={columns}
                loading={false}
                hover
                striped
                bordered={false}
                {...props.baseProps}
              />
            </Modal.Content>
          </>
        )}
      </ToolkitProvider>
    );
  };

  /**
   * Render the citations modal for selection
   * @param {Object} additionalCitation
   * @param {Object} index
   * @param {Object} additionalCitationsError
   * @returns {Object}
   */
  const renderCitationModal = (
    additionalCitation,
    index,
    additionalCitationsError = {}
  ) => {
    return (
      <>
        <Form.Field
          width={5}
          error={
            additionalCitationsError &&
            !isNil(additionalCitationsError.citation) &&
            !!additionalCitationsError.citation
          }
        >
          <label>Citation Number*</label>
          {renderCitationLabels(additionalCitation)}
          <Link
            to={'#'}
            onClick={e => {
              const citationData = props.formData.additionalCitations[index];
              e.preventDefault();
              setShowCitationModal(true);
              setClickedIndex(index);
              if (!isEmpty(citationData)) {
                setCitation(
                  citationData.citation ? [citationData.citation] : []
                );
              } else {
                setCitation([]);
              }
            }}
          >
            {!formDisabled &&
              (additionalCitation &&
              additionalCitation.citation &&
              additionalCitation.citation.length > 0
                ? 'Edit Citation'
                : 'Add a Citation')}
          </Link>
        </Form.Field>

        <AmsModal
          size={'fullscreen'}
          className="ams-semantic-modal-fix"
          open={showCitationModal}
          closeIcon
          centered={false}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => setShowCitationModal(false)}
        >
          <Modal.Header>Citations</Modal.Header>
          {renderCitationTable(additionalCitation, index)}
          <Modal.Actions>
            <Button
              onClick={() => {
                setShowCitationModal(false);
                setQuery('');
                setCitation([]);
              }}
              negative
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                const values = [...props.formData.additionalCitations];

                values[clickedIndex] = {
                  ...values[clickedIndex],
                  citation: citation[0],
                };

                props.handleDataChange({
                  formData: {
                    ...props.formData,
                    additionalCitations: values,
                  },
                });
                setShowCitationModal(false);
                setQuery('');
                setCitation([]);
              }}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Select"
            />
          </Modal.Actions>
        </AmsModal>
      </>
    );
  };

  // Check if citation is a disallowance citation
  const disallowanceCitation = citation => {
    // Check if the selected citation is fiscal
    const citationFound = includes(DISALLOWANCE_CITATIONS, citation);

    return citationFound;
  };

  // Custom validations for form submission
  const validate = data => {
    let errs = {};
    const additionalCitationsErrors = [];
    const citationsCheck = [];

    if (!data) errs.error = 'Data is required';

    if (data && data.surveyData && !data.surveyData.question1) return errs;

    data &&
      data.surveyData &&
      data.surveyData.additionalCitations &&
      // eslint-disable-next-line array-callback-return
      data.surveyData.additionalCitations.map(additionalCitation => {
        const additionalCitationError = {};

        if (
          citationsCheck.length > 0 &&
          citationsCheck.includes(additionalCitation.citation)
        ) {
          additionalCitationError.citation =
            'Duplicate citation is not allowed.';
        }
        citationsCheck.push(additionalCitation.citation);

        if (!additionalCitation.selectGuideName)
          additionalCitationError.selectGuideName = 'Guide name is required.';
        if (!additionalCitation.selectPerformanceMeasure)
          additionalCitationError.selectPerformanceMeasure =
            'Performance measure is required.';
        if (!additionalCitation.citation)
          additionalCitationError.citation = 'Citation is required.';

        if (disallowanceCitation(additionalCitation.citation)) {
          if (additionalCitation.disallowance === undefined)
            additionalCitationError.disallowance =
              'Potential disallowance is required.';

          if (additionalCitation.disallowance === true) {
            if (!additionalCitation.disallowanceDetail)
              additionalCitationError.disallowanceDetail =
                'Additional details regarding the potential disallowance is required.';

            if (additionalCitation.disallowanceMonetized === undefined)
              additionalCitationError.disallowanceMonetized =
                'Potential disallowance monetized is required.';

            if (additionalCitation.disallowanceMonetized === true) {
              if (!additionalCitation.disallowanceMonetizedAmount)
                additionalCitationError.disallowanceMonetizedAmount =
                  'Estimated monetary value of the potential disallowance is required.';
            }
          }
        }

        if (
          !additionalCitation.evidenceFiles ||
          additionalCitation.evidenceFiles.length === 0
        )
          additionalCitationError.evidenceFiles =
            'Supporting documents is required.';
        else {
          let anyDefined = false;
          for (let i = 0; i < additionalCitation.evidenceFiles.length; i++) {
            if (additionalCitation.evidenceFiles[i] !== undefined) {
              anyDefined = true;
            }
          }
          if (!anyDefined) {
            additionalCitationError.evidenceFiles =
              'Supporting documents is required.';
          }
        }
        if (!additionalCitation.narrative)
          additionalCitationError.narrative =
            'Explanation to your response is required.';

        if (additionalCitationError)
          additionalCitationsErrors.push(additionalCitationError);
      });

    if (
      data &&
      data.surveyData &&
      data.surveyData.question1 === true &&
      data.surveyData.additionalCitations.length === 0
    ) {
      additionalCitationsErrors.push({ finding: 'Finding is required.' });
    }

    if (!isEmpty(additionalCitationsErrors)) {
      errs.additionalCitationsErrors = additionalCitationsErrors;
    }

    errs = {
      ...responseErrors,
      ...errs,
    };

    setResponseErrors(errs);

    return errs;
  };

  // Build and render complete form
  const renderAdditionalCitation = (additionalCitation, index) => {
    // if (isEmpty(additionalCitation)) return;

    const additionalCitationsError =
      responseErrors && responseErrors.additionalCitationsErrors
        ? responseErrors.additionalCitationsErrors[index]
        : {};

    return (
      <Segment key={index}>
        <Grid>
          <Grid.Column floated="left" width={8}>
            <Header as="h4" floated="left">{`Finding ${index + 1}`}</Header>
          </Grid.Column>
          <Grid.Column floated="right" width={8}>
            <Form.Field
              basic
              control={Button}
              color="blue"
              floated="right"
              size="tiny"
              onClick={() => handleRemove(index)}
            >
              Remove this Finding
            </Form.Field>
          </Grid.Column>
        </Grid>

        <Divider />

        <Form.Group widths="equal">
          {renderGuides(additionalCitation, index)}
          {renderCitationModal(
            additionalCitation,
            index,
            additionalCitationsError
          )}
        </Form.Group>

        {disallowanceCitation(additionalCitation.citation) && (
          <>
            <AmsFormLabel
              name="Is there a potential disallowance?*"
              helpText={true}
              fieldLabel="filterReviewType"
              disabled={formDisabled}
            >
              <Form.Group inline>
                <Form.Field
                  // name="disallowance"
                  error={
                    additionalCitationsError &&
                    !isNil(additionalCitationsError.disallowance) &&
                    !!additionalCitationsError.disallowance
                  }
                  name={`disallowance_${index}`}
                  control={Radio}
                  label="Yes"
                  value={additionalCitation.disallowance}
                  checked={additionalCitation.disallowance === true}
                  onChange={e => {
                    e.preventDefault();

                    const values = [...props.formData.additionalCitations];
                    values[index] = {
                      ...values[index],
                      disallowance: true,
                    };

                    props.handleDataChange({
                      formData: {
                        ...props.formData,
                        additionalCitations: values,
                      },
                    });
                  }}
                  disabled={formDisabled}
                />
                <Form.Field
                  // name="disallowance"
                  error={
                    additionalCitationsError &&
                    !isNil(additionalCitationsError.disallowance) &&
                    !!additionalCitationsError.disallowance
                  }
                  name={`disallowance_${index}`}
                  control={Radio}
                  label="No"
                  value={additionalCitation.disallowance}
                  checked={additionalCitation.disallowance === false}
                  onChange={e => {
                    e.preventDefault();

                    const values = [...props.formData.additionalCitations];
                    values[index] = {
                      ...values[index],
                      disallowance: false,
                    };

                    props.handleDataChange({
                      formData: {
                        ...props.formData,
                        additionalCitations: values,
                      },
                    });
                  }}
                  disabled={formDisabled}
                />
              </Form.Group>
            </AmsFormLabel>

            {additionalCitation.disallowance && (
              <>
                <AmsFormLabel
                  name="Provide additional details regarding the potential disallowance*"
                  fieldLabel="textDisallowanceDetail"
                >
                  <Form.Field
                    control={TextArea}
                    name="disallowanceDetail"
                    error={
                      additionalCitationsError &&
                      !isNil(additionalCitationsError.disallowanceDetail) &&
                      !!additionalCitationsError.disallowanceDetail
                    }
                    value={
                      additionalCitation &&
                      additionalCitation.disallowanceDetail
                    }
                    onChange={e => handleChange(index, e)}
                    aria-labelledby="textDisallowanceDetail"
                  />
                </AmsFormLabel>

                <AmsFormLabel
                  name="Was the potential disallowance monetized?*"
                  helpText={true}
                  fieldLabel="labelDisallowanceMonetized"
                >
                  <Form.Group inline>
                    <Form.Field
                      error={
                        additionalCitationsError &&
                        !isNil(
                          additionalCitationsError.disallowanceMonetized
                        ) &&
                        !!additionalCitationsError.disallowanceMonetized
                      }
                      name={`disallowanceMonetized${index}`}
                      control={Radio}
                      label="Yes"
                      value={additionalCitation.disallowanceMonetized}
                      checked={
                        additionalCitation.disallowanceMonetized === true
                      }
                      onChange={e => {
                        e.preventDefault();

                        const values = [...props.formData.additionalCitations];
                        values[index] = {
                          ...values[index],
                          disallowanceMonetized: true,
                        };

                        props.handleDataChange({
                          formData: {
                            ...props.formData,
                            additionalCitations: values,
                          },
                        });
                      }}
                      disabled={formDisabled}
                    />
                    <Form.Field
                      error={
                        additionalCitationsError &&
                        !isNil(
                          additionalCitationsError.disallowanceMonetized
                        ) &&
                        !!additionalCitationsError.disallowanceMonetized
                      }
                      name={`disallowanceMonetized${index}`}
                      control={Radio}
                      label="No"
                      value={additionalCitation.disallowanceMonetized}
                      checked={
                        additionalCitation.disallowanceMonetized === false
                      }
                      onChange={e => {
                        e.preventDefault();

                        const values = [...props.formData.additionalCitations];
                        values[index] = {
                          ...values[index],
                          disallowanceMonetized: false,
                        };

                        props.handleDataChange({
                          formData: {
                            ...props.formData,
                            additionalCitations: values,
                          },
                        });
                      }}
                      disabled={formDisabled}
                    />
                  </Form.Group>
                </AmsFormLabel>

                {additionalCitation.disallowanceMonetized && (
                  <>
                    <AmsFormLabel
                      name="What is the estimated monetary value of the potential disallowance? (For example 1234 or 1234.56)*"
                      fieldLabel="textDisallowanceMonetizeAmount"
                    >
                      <Form.Field
                        control={Input}
                        type="number"
                        name="disallowanceMonetizedAmount"
                        error={
                          additionalCitationsError &&
                          !isNil(
                            additionalCitationsError.disallowanceMonetizedAmount
                          ) &&
                          !!additionalCitationsError.disallowanceMonetizedAmount
                        }
                        value={
                          additionalCitation &&
                          additionalCitation.disallowanceMonetizedAmount
                        }
                        onChange={e => handleChange(index, e)}
                        onKeyDown={e => {
                          if (e.keyCode === 109 || e.keyCode === 189)
                            e.preventDefault();
                        }}
                        min="1"
                        aria-labelledby="textDisallowanceMonetizeAmount"
                      />
                    </AmsFormLabel>
                  </>
                )}
              </>
            )}
          </>
        )}

        <AmsFormLabel
          name={`Please explain your response${
            additionalCitation.citation
              ? ` for ${additionalCitation.citation}`
              : ''
          }*`}
          fieldLabel="textNarrative"
        >
          <Form.Field
            control={TextArea}
            name="narrative"
            error={
              additionalCitationsError &&
              !isNil(additionalCitationsError.narrative) &&
              !!additionalCitationsError.narrative
            }
            value={additionalCitation && additionalCitation.narrative}
            onChange={e => handleChange(index, e)}
            aria-labelledby="textNarrative"
          />
        </AmsFormLabel>

        <FindingsOutsideProtocolAttachmentField
          additionalCitation={additionalCitation}
          index={index}
          onChange={handleAttachmentChange}
          evidences={additionalCitation && additionalCitation.evidenceFiles}
          formName={'additionalCitations'}
          formDisabled={formDisabled}
        />

        <AmsFormLabel
          name="Provide a description of the finding and/or documents uploaded"
          fieldLabel="textDescription"
        >
          <Form.Field
            control={TextArea}
            name="description"
            value={additionalCitation && additionalCitation.description}
            onChange={e => handleChange(index, e)}
            aria-labelledby="textDescription"
          />
        </AmsFormLabel>
      </Segment>
    );
  };

  // Render form submission status
  const renderSurveyStatus = () => {
    if (
      isEmpty(submission) ||
      (isEmpty(submission) && !submission.amsSubmissionId)
    )
      return null;

    const {
      surveyStatus,
      data: { editedBy, editTime },
    } = submission;

    return (
      <Well>
        <List>
          {editedBy && (
            <List.Item>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Submitted By"
              >
                Submitted By:
              </span>{' '}
              {editedBy}
            </List.Item>
          )}

          {surveyStatus && (
            <List.Item>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Submission Status"
              >
                Submission Status:
              </span>{' '}
              {surveyStatus}
            </List.Item>
          )}

          {editTime && (
            <List.Item>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Submission Time"
              >
                Submission Time:
              </span>{' '}
              {AmsDateFormatters.formatDateTime(editTime)}
            </List.Item>
          )}
        </List>
      </Well>
    );
  };

  const renderDraftButton = () => {
    const { surveyStatus } = submission;

    if (fa2 && fa2.readOnly) return null;

    if (fa2 && !fa2.isReviewAccessible) return null;

    if (fa2 && fa2.reviewStatus && fa2.reviewStatus === 'Cancelled')
      return null;

    if (
      !surveyStatus ||
      (data &&
        surveyStatus &&
        surveyStatus.toLowerCase() === 'draft' &&
        editButtonClicked)
    )
      return (
        <Form.Field
          control={Button}
          content="Save as Draft"
          disabled={isEmpty(data)}
          onClick={e => {
            const payload = {
              reviewId,
              amsFormId,
              surveyData: { ...props.formData },
            };

            if (amsSubmissionId) payload.amsSubmissionId = amsSubmissionId;
            payload.submissionStatus = 'Draft';

            const submitData = async () => {
              try {
                setLoading(true);
                const res = await dispatch(
                  postFormSubmission({ data: payload })
                );

                if (res) {
                  props.dataHasChangedSwitch(false);
                }

                setLoading(false);

                setShowAlert(true);
                setAlertMessage({
                  message: 'Saved successfully',
                  type: 'success',
                });
                setResponseErrors();
                setDataHasChanged(false);
              } catch (err) {
                setLoading(false);
                setResponseErrors(err);
                setDataHasChanged(false);
              }
            };

            submitData();
            scrollToResults();
          }}
        />
      );

    return null;
  };

  const renderSubmitButton = () => {
    const { surveyStatus } = submission;

    if (fa2 && fa2.readOnly) return null;

    if (fa2 && !fa2.isReviewAccessible) return;

    if (fa2 && fa2.reviewStatus && fa2.reviewStatus === 'Cancelled')
      return null;

    if (!surveyStatus || (data && surveyStatus && editButtonClicked))
      return (
        <Form.Field
          control={Button}
          content="Submit"
          primary
          onClick={e => {
            e.preventDefault();
            const payload = {
              reviewId,
              amsFormId,
              surveyData: { ...props.formData },
            };

            if (amsSubmissionId) payload.amsSubmissionId = amsSubmissionId;
            payload.submissionStatus = 'Submitted';

            const { additionalCitationsErrors } = validate(payload);

            let validationError = false;

            additionalCitationsErrors &&
              additionalCitationsErrors.forEach(additionalCitationsError => {
                if (!isEmpty(additionalCitationsError) && !validationError) {
                  validationError = true;
                }
              });

            if (validationError) {
              // Go to the top of the summary table
              scrollToResults();

              return null;
            }

            const submitData = async () => {
              try {
                setLoading(true);

                let res = '';

                res = await dispatch(postFormSubmission({ data: payload }));

                if (res) {
                  props.dataHasChangedSwitch(false);
                }

                setLoading(false);

                setShowAlert(true);
                setAlertMessage({
                  message: 'Saved successfully',
                  type: 'success',
                });

                scrollToResults();
                setDataHasChanged(false);
              } catch (err) {
                setLoading(false);
                setResponseErrors(err);
                scrollToResults();
                setDataHasChanged(false);
              }
            };

            if (!draftButtonClicked) {
              submitData();
              setResponseErrors();
              scrollToResults();
            }
          }}
        />
      );
  };

  const renderEditButton = () => {
    if (!formDisabled) return null;

    if (fa2 && fa2.readOnly) return;

    if (fa2 && !fa2.isReviewAccessible) return;

    if (fa2 && fa2.isReportFinalized) return;

    if (fa2 && fa2.reviewStatus && fa2.reviewStatus === 'Cancelled')
      return null;

    if (submission && submission.editable) {
      return (
        <Button
          content="Edit"
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            setFormDisabled(false);
            setEditButtonClicked(true);
            scrollToResults();
          }}
        />
      );
    }
  };

  const renderCancelButton = () => {
    if (formDisabled) return null;

    if (fa2 && fa2.readOnly) return null;

    if (fa2 && fa2.reviewStatus && fa2.reviewStatus === 'Cancelled')
      return null;

    if (
      isEmpty(submission) ||
      (isEmpty(submission) && !submission.amsSubmissionId)
    )
      return null;
    if (
      submission &&
      submission.surveyStatus &&
      submission.surveyStatus.toLowerCase() !== 'submitted'
    )
      return null;
    else
      return (
        <Form.Field
          control={Button}
          negative
          content={'Cancel'}
          onClick={e => {
            e.preventDefault();

            setLoading(false);
            props.handleCancelChange(
              submission && submission.data && submission.data.surveyData
            );

            setEditButtonClicked(true);
            setFormDisabled(true);
            setResponseErrors();
            scrollToResults();
          }}
        />
      );
  };

  const showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={showAlert}
        title=""
        type={alertMessage && alertMessage.type}
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#DD6B55'}
        text={alertMessage && alertMessage.message}
        onConfirm={() => {
          setShowAlert(false);
          setAlertMessage({});
          dispatch(fetchFA2FormDetail({ reviewId })).then(() => {
            dispatch(fa2GuideSelected(selectedGuide));
            dispatch(fa2PerformanceMeasureSelected(selectedPerformanceMeasure));
            fetchFormSchema();
          });
        }}
      />
    );
  };

  // Render alert if there are errors
  const renderAlertMessage = () => {
    if (isEmpty(responseErrors)) return null;

    const additionalCitationsErrors =
      responseErrors && responseErrors.additionalCitationsErrors
        ? responseErrors.additionalCitationsErrors
        : [];

    let message =
      additionalCitationsErrors &&
      additionalCitationsErrors.map((additionalCitationsError, index) => {
        if (isEmpty(additionalCitationsError)) return null;

        const items = Object.keys(additionalCitationsError).map(key => (
          <li key={key}>{additionalCitationsError[key]}</li>
        ));

        return (
          <div key={index}>
            <strong>
              {`Finding ${index + 1} has ${items.length} missing items.`}
            </strong>
            <ul>{items}</ul>
          </div>
        );
      });

    if (
      message &&
      message.length === 0 &&
      (responseErrors.toString() ===
        'Error: Request failed with status code 440' ||
        responseErrors.toString() ===
          'Error: Request failed with status code 502')
    ) {
      message = 'Your session has expired. Please relogin.';
    }

    return (
      <AmsAlertMessage error>
        <>{message}</>
      </AmsAlertMessage>
    );
  };

  return (
    <div id="task" role="article" ref={amsTaskRef} tabIndex="-1">
      {loading && (
        <Dimmer active inverted>
          <Loader content="Loading..." />
        </Dimmer>
      )}

      {renderAlertMessage()}

      <div>
        {renderSurveyStatus()}

        <div className="ui form">
          <Segment
            basic
            clearing
            style={{ paddingTop: '0', paddingBottom: '0' }}
          >
            <Header as="h2" floated="right" style={{ marginRight: '0' }}>
              {formDisabled && renderEditButton()}
            </Header>
            <Header as="h2" floated="left">
              Findings Outside the Protocol
            </Header>
          </Segment>

          <fieldset
            disabled={
              (fa2 && fa2.isReportFinalized) ||
              (fa2 &&
                fa2.reviewStatus &&
                fa2.reviewStatus.toLowerCase() === 'cancelled') ||
              (fa2 && fa2.readOnly) ||
              (fa2 && !fa2.isReviewAccessible)
                ? true
                : formDisabled
            }
          >
            <AmsFormLabel
              name="Were there any findings outside of the protocol*"
              helpText={true}
              fieldLabel="filterReviewType"
              disabled={formDisabled}
            >
              <Form.Group inline>
                <Form.Field
                  name="question1"
                  control={Radio}
                  label="Yes"
                  value={props.formData.question1}
                  checked={props.formData.question1 === true}
                  onChange={e => {
                    e.preventDefault();
                    props.handleDataChange({
                      formData: {
                        ...props.formData,
                        question1: true,
                        additionalCitations: [{}],
                      },
                    });
                  }}
                  disabled={formDisabled}
                />
                <Form.Field
                  name="question1"
                  control={Radio}
                  label="No"
                  value={props.formData.question1}
                  checked={props.formData.question1 === false}
                  onChange={e => {
                    e.preventDefault();
                    props.handleDataChange({
                      formData: {
                        ...props.formData,
                        question1: false,
                        additionalCitations: [{}],
                      },
                    });
                  }}
                  disabled={formDisabled}
                />
              </Form.Group>
            </AmsFormLabel>

            {props.formData && props.formData.question1 ? (
              <>
                <Header as="h3" disabled={formDisabled}>
                  Input the corresponding standard associated with this finding
                </Header>

                {props.formData.additionalCitations &&
                  props.formData.additionalCitations.map(
                    (additionalCitation, index) => {
                      return renderAdditionalCitation(
                        additionalCitation,
                        index
                      );
                    }
                  )}

                <Segment basic textAlign="center">
                  <Button
                    basic
                    color="blue"
                    content="Add an additional finding"
                    onClick={handleAdd}
                  />
                </Segment>
              </>
            ) : (
              ''
            )}

            {props.formData && !isNil(props.formData.question1) && (
              <>
                <Divider />

                {!formDisabled && (
                  <Form.Group inline>
                    {renderDraftButton()}
                    {renderSubmitButton()}
                    {renderCancelButton()}
                  </Form.Group>
                )}
              </>
            )}
          </fieldset>
        </div>

        {renderEditButton()}

        {showConfirmationAlert()}
      </div>
    </div>
  );
};

export default FindingsOutsideProtocol;
