/* eslint-disable */

import findIndex from 'lodash/findIndex';

import {
  CAROUSEL_ADDED,
  CAROUSEL_FETCHED,
  CAROUSEL_UPDATED,
  CAROUSEL_DELETED,
  CAROUSEL_IMAGE_ADDED,
} from '../redux/types';

export default function carousel(state = [], action) {
  switch (action.type) {
    case CAROUSEL_FETCHED:
      return action.carousels;

    case CAROUSEL_ADDED: {
      return [...state, action.carousel];
    }

    case CAROUSEL_UPDATED: {
      let modified = state.map((carouselItem, index) => {
        if (carouselItem._id !== action.carousel._id) {
          return carouselItem;
        }
        return {
          ...carouselItem,
          ...action.carousel,
        };
      });
      return modified;
    }

    case CAROUSEL_DELETED: {
      const index = findIndex(state, { _id: action.id });

      if (index >= 0) {
        const modified = [...state.slice(0, index), ...state.slice(index + 1)];

        return modified;
      }

      return state;
    }

    default:
      return state;
  }
}
