import React, { Component } from 'react';

class Unauthorized extends Component {
  render() {
    return (
      <div>
        <h1 className="text-center">
          You are not authorized to see this content ¯\_(ツ)_/¯{' '}
        </h1>
      </div>
    );
  }
}

export default Unauthorized;
