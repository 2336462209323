import axios from 'axios';

import {
  SUBMISSION_SELECTED,
  FOLLOWUP_TASK_DETAIL_FETCHED,
} from '../redux/types';

export function selectSubmission(submission) {
  return {
    type: SUBMISSION_SELECTED,
    submission,
  };
}

export function getFollowUpTaskDetail(followUpTaskDetail) {
  return {
    type: FOLLOWUP_TASK_DETAIL_FETCHED,
    followUpTaskDetail,
  };
}

export const addSubmission = obj => dispatch =>
  axios.post(`/survey/follow-up/save-submission`, obj).then(response => {
    return response.data;
  });

export const fetchSubmission = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchSubmission`, requestInput)
    .then(response => dispatch(selectSubmission(response.data)));

export const fetchFollowUpTaskDetail = requestInput => dispatch =>
  axios
    .post(`/submissions/fetchFollowUpTaskDetail`, requestInput)
    .then(response => dispatch(getFollowUpTaskDetail(response.data)));

export const saveDraft = obj => dispatch =>
  axios.post(`/survey/follow-up/save-draft`, obj).then(response => {
    return response.data;
  });
