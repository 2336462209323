import axios from 'axios';

import {
  PRESITE_PERFORMANCE_MEASURE_SELECTED,
  PRESITE_FORM_DETAIL_FETCHED,
  PRESITE_DRAFT_SAVED,
  PRESITE_SUBMISSION_SAVED,
  PRESITE_SUBMISSION_UPDATED,
  PRESITE_SUBMISSION_FETCHED,
  PRESITE_SUBMISSION_CLEARED,
  PRESITE_FORM_SCHEMA_FETCHED,
  PRESITE_UPDATE_GUIDE,
} from '../redux/types';

export const performanceMeasureSelected = selected => ({
  type: PRESITE_PERFORMANCE_MEASURE_SELECTED,
  selected,
});

export const presiteFormDetailFetched = presite => ({
  type: PRESITE_FORM_DETAIL_FETCHED,
  presite,
});

export const presiteFormSchemaFetched = schema => ({
  type: PRESITE_FORM_SCHEMA_FETCHED,
  schema,
});

export const presiteSubmissionFetched = submission => ({
  type: PRESITE_SUBMISSION_FETCHED,
  submission,
});

export const presiteClearSubmissionRequest = submission => ({
  type: PRESITE_SUBMISSION_CLEARED,
});

export const presiteDraftSaved = () => ({
  type: PRESITE_DRAFT_SAVED,
});

export const presiteSubmissionSaved = () => ({
  type: PRESITE_SUBMISSION_SAVED,
});

export const presiteSubmissionUpdated = () => ({
  type: PRESITE_SUBMISSION_UPDATED,
});

export const presiteUpdateGuide = (performanceMeasure, submissionId) => ({
  type: PRESITE_UPDATE_GUIDE,
  performanceMeasure,
  submissionId,
});

export const fetchPresiteFormDetail = request => dispatch =>
  axios
    .post(`/survey/presite/fetch-form-detail`, request)
    .then(response => dispatch(presiteFormDetailFetched(response.data)));

export const fetchPresiteFormSchema = formId => dispatch =>
  axios
    .post(`/survey/presite/fetch-form-schema`, formId)
    .then(response => dispatch(presiteFormSchemaFetched(response.data)));

export const fetchPresiteSubmission = request => dispatch =>
  axios
    .post(`/survey/presite/fetch-submission`, request)
    .then(response => dispatch(presiteSubmissionFetched(response.data)));

export const clearPresiteSubmission = () => dispatch =>
  dispatch(presiteClearSubmissionRequest());

export const presetSaveDraft = request => dispatch =>
  axios.post(`/survey/presite/save-draft`, request).then(response => {
    dispatch(presiteDraftSaved());
    return response.data;
  });

export const presetSaveSubmission = request => dispatch =>
  axios.post(`/survey/presite/save-submission`, request).then(response => {
    dispatch(presiteSubmissionSaved());
    return response.data;
  });

export const presetUpdateSubmission = request => dispatch =>
  axios.post(`/survey/presite/update-submission`, request).then(response => {
    dispatch(presiteSubmissionSaved());
    return response.data;
  });
