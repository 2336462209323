import PropTypes from 'prop-types';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';

const ReviewLegacyInfo = ({ selectedReview }) => {
  if (selectedReview.systemName !== 'OHSMS') return null;
  let team = [];
  if (selectedReview && selectedReview.Team && selectedReview.Team.length > 0) {
    team = selectedReview.Team.map(e => {
      return {
        ...e,
        name: `${e.firstName} ${e.lastName}`,
      };
    });
  }

  const renderTeamTable = () => {
    return (
      <BootstrapTable
        trClassName="clickable"
        data={team}
        pagination
        remote
        tableContainerClass="table-container"
        headerContainerClass="table-header"
        hover
        condensed
        bordered={false}
      >
        <TableHeaderColumn dataField="name" isKey={true} dataSort={true}>
          {' '}
          Name
        </TableHeaderColumn>
        <TableHeaderColumn dataField="roleName" dataSort={true}>
          Role Name
        </TableHeaderColumn>
        <TableHeaderColumn dataField="effectiveDate" dataSort={true}>
          Effective Date
        </TableHeaderColumn>
        <TableHeaderColumn dataField="expirationDate" dataSort={true}>
          Expiration Date
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  return (
    <div>
      {selectedReview.reportShippedDate && (
        <div>
          <span className="field-title">Shipped Date: </span>{' '}
          {selectedReview.reportShippedDate}
        </div>
      )}

      {selectedReview.systemName && (
        <div>
          <span className="field-title">System Field: </span>{' '}
          {selectedReview.systemName}
        </div>
      )}
      {selectedReview.reviewOutcome && (
        <div>
          <span className="field-title">Review Outcome: </span>{' '}
          {selectedReview.reviewOutcome}
        </div>
      )}
      {team && team.length > 0 && (
        <div>
          <h3>Team </h3> {renderTeamTable()}
        </div>
      )}
    </div>
  );
};

ReviewLegacyInfo.propTypes = {
  selectedReview: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewLegacyInfo);
