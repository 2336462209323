import _ from 'lodash';

import { acl } from '../config';

import { SET_CURRENT_USER } from '../redux/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  roleGroup: 'none',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      let roleGroup = 'none';

      // As per MVP, the roles are grouped into three; administrator, reviewer and manager.
      // Based on that, roleGroups will hold user role group. Can be used to show/hide UI element depending on the user role group.
      if (action.user.roles) {
        roleGroup = action.user.roles.map(role => {
          return _.findKey(acl, values => {
            return _.indexOf(values, role) !== -1;
          });
        })[0]; // Multiple groups not supported.
      }

      return {
        isAuthenticated: !_.isEmpty(action.user),
        user: action.user || {},
        roleGroup,
      };

    default:
      return state;
  }
};
