import React from 'react';
import { withRouter } from 'react-router';
import { Breadcrumb, Divider, Grid, Header, List } from 'semantic-ui-react';

import BrowserTitle from '../../../utils/BrowserTitle';

import HelpBreadcrumb from '../../Help/HelpBreadcrumb';

const renderPageHeader = (router, routes, params, data) => {
  const { pathname } = router.location;
  let label = '';
  let pageHeader = '';
  let breadcrumb = [];

  switch (pathname) {
    case '/workbench':
      label = '/workbench';
      pageHeader = 'Workbench';
      break;

    case '/profile':
      label = '/profile';
      pageHeader = 'Profile';
      break;

    case '/calendar':
      label = '/calendar';
      pageHeader = 'Calendar';
      break;

    case '/survey':
      label = '/survey';
      pageHeader = 'Survey';
      break;

    case '/report':
      label = '/report';
      pageHeader = 'Reports';
      break;

    case '/task':
      label = '/task';
      pageHeader = 'Tasks';
      break;

    case '/grantee':
      label = '/grantee';
      pageHeader = 'Grantees';
      break;

    case '/myregionalreviews':
      label = '/myregionalreviews';
      pageHeader = 'Regional Reviews';
      break;

    case '/unauthorized':
      label = '/unauthorized';
      pageHeader = 'Unauthorized';
      break;

    case '/help':
      label = '/help';
      pageHeader = 'Help';
      break;

    // eslint-disable-next-line no-duplicate-case
    case '/calendar':
      label = '/calendar';
      pageHeader = 'My Calendar';
      break;

    default:
      break;
  }
  if (pathname.search('/help') >= 0) {
    breadcrumb = HelpBreadcrumb(routes, params);
  } else if (pathname.search('/calendar') >= 0) {
    label = '/calendar';
    pageHeader = 'Calendar';
    breadcrumb.push(
      { key: 'home', content: 'Home', href: '/' },
      { key: 'calendar', content: 'My Calendar' }
    );
  } else if (pathname.search('/search') >= 0) {
    label = '/search';
    pageHeader = 'Final Report Search';
    breadcrumb.push(
      { key: 'home', content: 'Home', href: '/' },
      { key: 'search', content: 'Final Report Search' }
    );
  } else if (pathname.search('/review') >= 0) {
    label = '/review';
    pageHeader = 'Review';
    breadcrumb = [{ key: 'home', content: 'Home', href: '/' }];
    if (
      (params.reviewId || params.id) &&
      routes[routes.length - 1].getDisplayName
    ) {
      breadcrumb.push({ key: 'review', content: 'Reviews', href: '/review' });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
        href: params.reviewId
          ? `/review/${params.reviewId}`
          : `/review/${params.id}`,
      });
      breadcrumb.push({
        key: routes[routes.length - 1].getDisplayName(),
        content: routes[routes.length - 1].getDisplayName(),
        active: true,
      });
    } else if (params.reviewId || params.id) {
      breadcrumb.push({ key: 'review', content: 'Reviews', href: '/review' });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
      });
    } else {
      breadcrumb.push({ key: 'review', content: 'Reviews' });
    }
  } else if (pathname.search('/grantee') >= 0) {
    label = '/grantee';
    pageHeader = 'Grantees';
    breadcrumb = [{ key: 'home', content: 'Home', href: '/' }];
    if (
      (params.reviewId || params.id) &&
      routes[routes.length - 1].getDisplayName
    ) {
      breadcrumb.push({ key: 'grantee', content: 'Grantee', href: '/grantee' });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
        href: params.reviewId
          ? `/grantee/${params.reviewId}`
          : `/grantee/${params.id}`,
      });
      breadcrumb.push({
        key: routes[routes.length - 1].getDisplayName(),
        content: routes[routes.length - 1].getDisplayName(),
        active: true,
      });
    } else if (params.reviewId || params.id) {
      breadcrumb.push({ key: 'grantee', content: 'Grantee', href: '/grantee' });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
      });
    } else {
      breadcrumb.push({ key: 'grantee', content: 'Grantee' });
    }
  } else if (pathname.search('/admin') >= 0) {
    label = '/admin';
    pageHeader = 'Administration';
    breadcrumb = [
      { key: 'home', content: 'Home', href: '/' },
      { key: 'admin', content: 'Administration' },
    ];
  } else if (pathname.search('/report') >= 0) {
    label = '/reports';
    pageHeader = 'Reports';
    breadcrumb = [{ key: 'home', content: 'Home', href: '/' }];
    if (
      (params.reviewId || params.id) &&
      routes[routes.length - 1].getDisplayName
    ) {
      breadcrumb.push({ key: 'reports', content: 'Reports', href: '/report' });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
        href: params.reviewId
          ? `/reports/${params.reviewId}`
          : `/reports/${params.id}`,
      });
      breadcrumb.push({
        key: routes[routes.length - 1].getDisplayName(),
        content: routes[routes.length - 1].getDisplayName(),
        active: true,
      });
    } else if (params.reviewId || params.id) {
      breadcrumb.push({ key: 'reports', content: 'Reports', href: '/report' });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
      });
    } else {
      breadcrumb.push({ key: 'reports', content: 'Reports' });
    }
  } else if (pathname.search('/task') >= 0) {
    label = '/task';
    pageHeader = 'Task';
    breadcrumb = [{ key: 'home', content: 'Home', href: '/' }];
    if (
      (params.reviewId || params.id) &&
      routes[routes.length - 1].getDisplayName
    ) {
      breadcrumb.push({ key: 'task', content: 'Tasks', href: '/task' });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
        href: params.reviewId
          ? `/review/${params.reviewId}`
          : `/review/${params.id}`,
      });
      breadcrumb.push({
        key: routes[routes.length - 1].getDisplayName(),
        content: routes[routes.length - 1].getDisplayName(),
        active: true,
      });
    } else if (params.reviewId || params.id) {
      breadcrumb.push({
        key: 'task',
        content: 'Tasks',
        href: '/task',
      });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
      });
    } else if (
      routes[routes.length - 1].getDisplayName &&
      routes[routes.length - 1].getDisplayName() === 'All Findings'
    ) {
      breadcrumb.push({
        key: 'task',
        content: 'Tasks',
        href: '/task',
      });
      breadcrumb.push({
        key: 'all',
        content: 'All Findings',
      });
    } else {
      breadcrumb.push({ key: 'task', content: 'Tasks' });
    }
  } else if (pathname.search('/myregionalreviews') >= 0) {
    label = '/myregionalreviews';
    pageHeader = 'Regional Reviews';
    breadcrumb = [{ key: 'home', content: 'Home', href: '/' }];
    if (
      (params.reviewId || params.id) &&
      routes[routes.length - 1].getDisplayName
    ) {
      breadcrumb.push({
        key: 'myregionalreviews',
        content: 'My Regional Reviews',
        href: '/myregionalreviews',
      });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
        href: params.reviewId
          ? `/reviews/${params.reviewId}`
          : `/reviews/${params.id}`,
      });
      breadcrumb.push({
        key: routes[routes.length - 1].getDisplayName(),
        content: routes[routes.length - 1].getDisplayName(),
        active: true,
      });
    } else if (params.reviewId || params.id) {
      breadcrumb.push({
        key: 'myregionalreviews',
        content: 'My Regional Reviews',
        href: '/myregionalreviews',
      });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
      });
    } else {
      breadcrumb.push({
        key: 'myregionalreviews',
        content: 'My Regional Reviews',
      });
    }
  } else if (pathname.search('/profile') >= 0) {
    label = '/profile';
    pageHeader = 'Profile';
    breadcrumb = [{ key: 'home', content: 'Home', href: '/' }];
    if (
      (params.reviewId || params.id) &&
      routes[routes.length - 1].getDisplayName
    ) {
      breadcrumb.push({
        key: 'profile',
        content: 'Profile',
        href: '/profile',
      });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
        href: params.reviewId
          ? `/profile/${params.reviewId}`
          : `/profile/${params.id}`,
      });
      breadcrumb.push({
        key: routes[routes.length - 1].getDisplayName(),
        content: routes[routes.length - 1].getDisplayName(),
        active: true,
      });
    } else if (params.reviewId || params.id) {
      breadcrumb.push({
        key: 'profile',
        content: 'Profile',
        href: '/profile',
      });
      breadcrumb.push({
        key: params.reviewId ? params.reviewId : params.id,
        content: params.reviewId ? params.reviewId : params.id,
      });
    } else {
      breadcrumb.push({
        key: 'profile',
        content: 'Profile',
      });
    }
  }
  return {
    fieldLabel: label,
    header: pageHeader,
    breadcrumb,
  };
};

const PageTitle = ({
  router,
  routes,
  params,
  title,
  subheader,
  calendarButton,
  actionButtons,
  breadcrumbEnabled = true,
}) => {
  const { header, breadcrumb } = renderPageHeader(router, routes, params);

  return (
    <>
      <BrowserTitle title={title} header={header}></BrowserTitle>
      <div className="global-spacer" style={{ marginTop: '2rem' }}>
        <Grid>
          <Grid.Column floated="left" mobile={16} tablet={8} computer={10}>
            <div className="page-title">
              <Header as="h1">
                {title || header}
                {subheader && <Header.Subheader>{subheader}</Header.Subheader>}
              </Header>
              {breadcrumbEnabled && (
                <Breadcrumb
                  divider="/"
                  sections={breadcrumb}
                  style={{ backgroundColor: 'unset', padding: 'unset' }}
                />
              )}
              {/* <Breadcrumbs
              setDocumentTitle={true}
              itemClass=""
              separator=" / "
              routes={routes}
              params={params}
            /> */}
            </div>
          </Grid.Column>
          <Grid.Column
            floated="right"
            mobile={16}
            tablet={8}
            computer={6}
            verticalAlign="middle"
            textAlign="right"
          >
            <List horizontal>
              {actionButtons && actionButtons}

              {/* <List.Item>
              <List.Content>{calendarButton}</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <AmsHelpText
                  type="page"
                  category="pageHelp"
                  fieldLabel={fieldLabel}
                />
              </List.Content>
            </List.Item> */}
            </List>
          </Grid.Column>
        </Grid>

        <Divider />
      </div>
    </>
  );
};

export default withRouter(PageTitle);
