import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

// Import Components
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsTable from '../../../utils/AmsTable';

// Import actions.
import { addNote, fetchNotes } from '../../../actions/noteActions';
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

// Import helper function.
import enforceRole from '../../../utils/EnforceRole';

// Import settings.
import { acl, ethnicity } from '../../../config';
import AmsModal from '../../../utils/AmsModal';

class DemographicForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    languageAdd: false,
    languageEdit: false,
    languageDelete: false,
    showModal: false,
    modalTitle: '',
    errors: {},
    selectedLanguage: {}, // Language selected to be updated.
    updatedLanguage: {}, // Update temporary store.
    data: {
      oid: '',
      ethnicityInfo: '',
      languages: [],
      specificEthnicity: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    const { profileTab } = props;
    const { ethnicityInfo, languages, oid, specificEthnicity } = props.profile;
    const data = { ethnicityInfo, languages, oid, specificEthnicity };

    if (!_.isEqual(state.data, data)) {
      if (profileTab !== 'demographic') {
        this.cancelEdit();
      }

      if (state.localChange) {
        return { localChange: false };
      }

      return {
        data: {
          oid,
          ethnicityInfo,
          languages,
          specificEthnicity,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
    });
  };

  handleLanguageEdit(selectedLanguage, e) {
    e.preventDefault();

    this.setState({
      selectedLanguage,
      updatedLanguage: selectedLanguage,
      showModal: true,
      modalTitle: 'Edit Language',
      languageEdit: true,
      languageAdd: false,
      languageDelete: false,
    });
  }

  addNewLanguage = () => {
    this.setState({
      showModal: true,
      languageEdit: false,
      languageDelete: false,
      languageAdd: true,
      modalTitle: 'Add Language',
      selectedLanguage: {},
      updatedLanguage: {},
    });
  };

  handleLanguageRemove(selectedLanguage, e) {
    e.preventDefault();

    this.setState({
      showDeleteConfirm: true,
      languageDelete: true,
      selectedLanguage,
      updatedLanguage: selectedLanguage,
    });
  }

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      const { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};
    return errors;
  };

  handleChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      localChange: true,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  handleLanguageChange = (e, { name, value }) =>
    this.setState({
      ...this.state,
      updatedLanguage: { ...this.state.updatedLanguage, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  handleLanguageVerified = (e, { name, checked }) =>
    this.setState({
      ...this.state,
      updatedLanguage: { ...this.state.updatedLanguage, [name]: checked },
      errors: _.omit(this.state.errors, name),
    });

  setLanguageChanges = () => {
    const {
      selectedLanguage,
      updatedLanguage,
      data,
      languageAdd,
      languageDelete,
    } = this.state;

    let updatedLanguages = data.languages.map(language => {
      if (updatedLanguage.languagesUuid === language.languagesUuid) {
        language = updatedLanguage;
      }
      return language;
    });

    // During new language add
    if (languageAdd) {
      updatedLanguage.languagesUuid = uuidv4();
      updatedLanguages.push(updatedLanguage);
    }

    // During language delete.
    if (languageDelete) {
      updatedLanguages = _.pull(updatedLanguages, selectedLanguage);
    }

    this.saveLanguage({ oid: data.oid, languages: updatedLanguages });
  };

  saveLanguage = languageData => {
    this.setState({ loading: true });
    this.props
      .updateUserProfile(languageData)
      .then(() =>
        this.setState({
          showSuccess: true,
          loading: false,
          languageEdit: false,
          languageAdd: false,
          languageDelete: false,
          showModal: false,
          showDeleteConfirm: false,
          modalTitle: '',
        })
      )
      .catch(err => {
        this.setState({
          showSuccess: false,
          loading: false,
          languageEdit: false,
          languageAdd: false,
          languageDelete: false,
          showModal: false,
          showDeleteConfirm: false,
          modalTitle: '',
          errors: { ...this.state.errors, profile: err.response.data.message },
        });
      });

    this.closeEditModal();
  };

  closeEditModal = () => {
    this.setState({
      showModal: false,
      languageEdit: false,
      languageAdd: false,
      languageDelete: false,
      modalTitle: '',
      selectedLanguage: {}, // Language selected to be updated.
      updatedLanguage: {}, // Update temporary store.
    });
  };

  closeDeleteConfirmModal = () => {
    this.setState({
      showDeleteConfirm: false,
      languageEdit: false,
      languageAdd: false,
      languageDelete: false,
      showModal: false,
      selectedLanguage: {}, // Language selected to be updated.
      updatedLanguage: {}, // Update temporary store.
    });
  };

  showLanguageModal = () => {
    const { currentUser } = this.props;
    const {
      languageEdit,
      showModal,
      updatedLanguage,
      loading,
      modalTitle,
    } = this.state;

    return (
      <AmsModal
        open={showModal}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="ams-semantic-modal-fix"
      >
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Content>
          <Form loading={loading}>
            <Form.Group widths="equal">
              {!languageEdit ? (
                <Form.Field required>
                  <AmsFormLabel fieldLabel="languageName" name="Language">
                    <AmsLookupDropdown
                      name="languageName"
                      // search
                      selection
                      onChange={this.handleLanguageChange}
                      value={updatedLanguage.languageName}
                      category={'languages'}
                      placeholder="Select language"
                      id="languageName"
                      aria-labelledby="languageName"
                      searchInput={{
                        id: 'languageNameSearch',
                        title: 'language search',
                        'aria-labelledby': 'languageName',
                      }}
                    />
                  </AmsFormLabel>
                </Form.Field>
              ) : (
                <Form.Field>
                  <label>Language</label>
                  <div className="default-value">
                    {updatedLanguage.languageName}
                  </div>
                </Form.Field>
              )}

              <Form.Field
                required
                name="langProficiency"
                control={AmsLookupDropdown}
                selection
                onChange={this.handleLanguageChange}
                value={updatedLanguage.langProficiency}
                category={'languageProficiency'}
                placeholder="Select proficiency"
                id="langProficiency"
                aria-labelledby="langProficiency"
                label={{ children: 'Proficiency', htmlFor: 'langProficiency' }}
              />

              {enforceRole(
                <Form.Field
                  name="langVerified"
                  onChange={this.handleLanguageVerified}
                  checked={updatedLanguage.langVerified === true ? true : false}
                  toggle
                  control={Checkbox}
                  label={{ children: 'Verified', htmlFor: 'langVerified' }}
                  id="langVerified"
                  aria-labelledby="langVerified"
                />,
                acl.actions.profile.checkBoxValidation,
                currentUser.roles
              ) || (
                <Form.Field>
                  <label>Verified</label>
                  {updatedLanguage.langVerified ? 'Yes' : 'No'}
                </Form.Field>
              )}
            </Form.Group>
            <input type="submit" value="submit" hidden />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.setLanguageChanges}>
            Save
          </Button>
          <Button onClick={this.closeEditModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  showDeleteConfirmModal = () => {
    const { showDeleteConfirm, selectedLanguage } = this.state;
    return (
      <AmsModal
        open={showDeleteConfirm}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="language-modal ams-semantic-modal-fix"
      >
        <Modal.Header>Remove Language</Modal.Header>
        <Modal.Content>
          <p>
            You are about to remove {selectedLanguage.languageName} from the
            language list. Are you sure?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.setLanguageChanges}>
            Remove
          </Button>
          <Button onClick={this.closeDeleteConfirmModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.userSettings.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  renderSpecificEthnicityInput = () => {
    return (
      <Form.Group widths="2">
        <Form.Input
          label="Specify Ethnicity"
          value={this.state.data.specificEthnicity}
          name="specificEthnicity"
          onChange={this.handleChange}
          placeholder=""
        />
      </Form.Group>
    );
  };

  render() {
    const {
      editMode,
      loading,
      errors,
      data,
      emptyValue,
      showSuccess,
    } = this.state;

    const userLanguageValue = _.find(
      ethnicity,
      e => e.value === data.ethnicityInfo
    );

    const languageVerifiedFormatter = (cell, row) =>
      cell === true ? 'Yes' : 'No';

    const actionFormatter = (cell, row) => {
      return (
        <Button.Group icon>
          <Button
            onClick={this.handleLanguageEdit.bind(this, row)}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-pencil fa-md" />
          </Button>
          <Button
            onClick={this.handleLanguageRemove.bind(this, row)}
            className="clickable"
            style={{ background: 'none' }}
          >
            <i className="fa fa-trash fa-md" />
          </Button>
        </Button.Group>
      );
    };

    const columns = [
      {
        dataField: 'languageName',
        text: 'Language',
        sort: true,
        headerStyle: { width: '30%' },
      },
      {
        dataField: 'langProficiency',
        text: 'Proficiency',
        sort: true,
        headerStyle: { width: '30%' },
      },
      {
        text: 'Verified',
        dataField: 'langVerified',
        style: { textAlign: 'center' },
        formatter: languageVerifiedFormatter,
        sort: true,
        headerStyle: { width: '15%', textAlign: 'center' },
      },
      {
        hidden: !editMode,
        style: { textAlign: 'center' },
        headerStyle: { width: '25%', textAlign: 'center' },
        formatter: actionFormatter,
        text: 'Action',
      },
    ];
    const total = data.languages ? data.languages.length : 0;

    return (
      <div>
        {this.showLanguageModal()}
        {this.showDeleteConfirmModal()}

        <div className="profile-form-title-wrapper">
          <h2>Demographic</h2>
        </div>

        <div className="profile-form-fields-wrapper">
          {showSuccess && (
            <Message
              positive
              icon="check"
              header="Success!"
              content="Data saved successfully."
            />
          )}

          {!_.isEmpty(errors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={Object.keys(errors).map(errorKey => (
                <li key={errorKey}>{errors[errorKey]}</li>
              ))}
            />
          )}

          <div className="row">
            <div className="col-sm-7">
              <h3>
                Languages/Fluency
                <AmsHelpText
                  category="helpText"
                  fieldLabel="demographicLanguage"
                />
              </h3>
            </div>
            {editMode && (
              <div className="col-sm-1">
                <Button onClick={this.addNewLanguage} basic size="tiny">
                  {' '}
                  Add
                </Button>
              </div>
            )}
            <br />
            <br />
            <br />
          </div>

          <Form onSubmit={this.onSubmit} loading={loading}>
            <Form.Group widths="2">
              <Col className="row language-table" xs={12} sm={12}>
                <AmsTable
                  keyField="languageName"
                  columns={columns}
                  data={data.languages}
                  basic
                  remote={false}
                  pagination={data.languages && data.languages.length > 10}
                  total={total}
                  sizePerPageOption={false}
                ></AmsTable>
              </Col>
            </Form.Group>

            <h3>Ethnicity (optional)</h3>
            <Form.Group widths="2">
              {editMode ? (
                <Form.Field
                  selection
                  name="ethnicityInfo"
                  onChange={this.handleChange}
                  value={data.ethnicityInfo}
                  category={'ethnicity'}
                  placeholder="Select Ethnicity"
                  id="ethnicityInfo"
                  aria-labelledby="ethnicityInfo"
                  control={AmsLookupDropdown}
                  label={{
                    children: (
                      <AmsHelpText
                        category="helpText"
                        fieldLabel="demographicEthnicity"
                        label="Affiliation"
                      />
                    ),
                    htmlFor: 'ethnicityInfo',
                  }}
                />
              ) : (
                <Form.Field>
                  <label>
                    Affiliation
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="demographicEthnicity"
                    />
                  </label>
                  <div className="default-value">
                    {userLanguageValue
                      ? userLanguageValue.text === 'Native American' &&
                        this.state.data.specificEthnicity !== ''
                        ? `${userLanguageValue.text}, ${this.state.data.specificEthnicity}`
                        : userLanguageValue.text
                      : emptyValue}
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            {editMode && data.ethnicityInfo === 'Native American'
              ? this.renderSpecificEthnicityInput()
              : null}

            <Segment basic floated="right">
              {editMode ? (
                <div>
                  <Button size="large" primary>
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={e => {
                      e.preventDefault();
                      this.cancelEdit();
                    }}
                    basic
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                this.showEditButton()
              )}
            </Segment>
          </Form>
          <div style={{ minHeight: '280px' }}></div>
        </div>
      </div>
    );
  }
}

DemographicForm.propTypes = {
  currentUser: PropTypes.object,
  profile: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  profile: state.profile,
  profileTab: state.profileTab,
});

export default connect(mapStateToProps, {
  updateUserProfile,
  updateUserRoles,
  addNote,
  fetchNotes,
})(DemographicForm);
