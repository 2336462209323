import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon as FortAwesomeFontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FontAwesomeIcon({ className, ...otherProps }) {
  const props = Object.assign(
    { className: classnames([className, 'fa']) },
    otherProps
  );
  return <FortAwesomeFontAwesomeIcon {...props} />;
}
