import _ from 'lodash';
import {
  LOOKUPS_FETCHED,
  CATEGORY_LOOKUPS_FETCHED,
  LOOKUP_ADDED,
  LOOKUP_UPADATED,
  AMS_LOOKUP_FETCHED,
} from '../redux/types';

const initialState = {
  selectedLookup: {},
  selectedCategoryLookups: [],
  list: [],
  categories: [],
  amsLookups: {},
};

export default function lookups(state = initialState, action) {
  switch (action.type) {
    case LOOKUP_ADDED: {
      if (action.lookup.parent)
        return { ...state, selectedLookup: action.lookup };

      return {
        ...state,
        selectedCategoryLookups: [
          ...state.selectedCategoryLookups,
          action.lookup,
        ],
      };
    }

    case LOOKUP_UPADATED: {
      if (action.lookup.parent) {
        const index = _.findIndex(state.categories, { _id: action.lookup._id });
        if (index !== -1) {
          const categories = [
            ...state.categories.slice(0, index),
            action.lookup,
            ...state.categories.slice(index + 1),
          ];
          return {
            ...state,
            categories,
            selectedLookup: action.lookup,
          };
        }
      }

      if (!action.lookup.parent) {
        const index = _.findIndex(state.selectedCategoryLookups, {
          _id: action.lookup._id,
        });
        if (index !== -1) {
          const selectedCategoryLookups = [
            ...state.selectedCategoryLookups.slice(0, index),
            action.lookup,
            ...state.selectedCategoryLookups.slice(index + 1),
          ];
          return {
            ...state,
            selectedCategoryLookups,
          };
        }
      }

      return state;
    }

    case LOOKUPS_FETCHED: {
      const categories = _.filter(action.lookups, { parent: true }); // filter only categories
      const selectedLookup = state.selectedLookup._id
        ? state.selectedLookup
        : categories[0];
      return {
        ...state,
        categories,
        selectedLookup,
        selectedCategoryLookups: _.filter(action.lookups, {
          category: selectedLookup.category,
        }),
        list: action.lookups,
      };
    }

    case CATEGORY_LOOKUPS_FETCHED: {
      return {
        ...state,
        selectedLookup: _.find(action.lookups, { parent: true }),
        selectedCategoryLookups: action.lookups,
      };
    }

    case AMS_LOOKUP_FETCHED: {
      return {
        ...state,
        amsLookups: { ...state.amsLookups, [action.category]: action.lookups },
      };
    }

    default:
      return state;
  }
}
