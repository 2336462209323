import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Segment } from 'semantic-ui-react';

// Import actions.
import {
  fetchReviewGuides,
  fetchReviewPerformanceMeasures,
} from '../../../../actions/fy19/reviewActions';

import AmsFormLabel from '../../../../utils/AmsFormLabel';
class ReviewSubmissionsFilters extends Component {
  state = {
    guides: [],
    performanceMeasures: [],
    guideOptions: [],
    performanceMeasuresOptions: [],
    reviewStatusOptions: [
      // TODO:  Add to lookups
      {
        key: 'Not Started',
        text: 'Not Started',
        value: 'Not Started',
      },
      {
        key: 'In Progress',
        text: 'In Progress',
        value: 'In Progress',
      },
      {
        key: 'Completed',
        text: 'Completed',
        value: 'Completed',
      },
    ],
    reviewId: '',
    reviewType: '',
    guideCode: '',
    status: '',
    guideName: '',
    performanceMeasure: '',
    guideLoading: false,
    performanceMeasureLoading: false,
    guidesLoaded: false,
  };

  constructor(props) {
    super(props);
    this.filterSegment = React.createRef();
  }

  componentDidMount = () => {
    const { guides, performanceMeasures, reviewId, reviewType } = this.props;
    const { guideOptions, performanceMeasuresOptions } = this.state;

    if (_.isEmpty(performanceMeasures) && _.isEmpty(guides)) {
      if (
        (this.props.fiscalYear > 2021 && this.props.reviewType === 'RAN') ||
        reviewType !== 'Follow-up'
      ) {
        if (reviewId && reviewType && _.isEmpty(guides)) {
          this.loadGuidesData();
        }
        if (_.isEmpty(performanceMeasures)) {
          this.loadPerformanceMeasuresData();
        }
      }
    }

    if (_.isEmpty(guideOptions) && _.isEmpty(performanceMeasuresOptions)) {
      const guideOptionsUpdate = !_.isEmpty(guides)
        ? guides.map(guide => ({
            key: guide.guideCode,
            text: guide.name,
            value: guide.guideCode,
          }))
        : [];

      const performanceMeasuresOptionsUpdate = !_.isEmpty(performanceMeasures)
        ? performanceMeasures.map(performanceMeasure => ({
            key: performanceMeasure.formCode,
            text: performanceMeasure.name,
            value: performanceMeasure.formCode,
          }))
        : [];
      this.setState({
        ...this.state,
        guideOptions: guideOptionsUpdate,
        performanceMeasuresOptions: performanceMeasuresOptionsUpdate,
      });
    }
  };

  scrollToRef = () => {
    window.scrollTo(0, this.filterSegment.current.offsetTop);
    this.filterSegment.current.focus();
  };

  getData = () => {
    this.loadGuidesData();
    this.loadPerformanceMeasuresData();
  };

  loadGuidesData = () => {
    const { reviewId, reviewType, fiscalYear } = this.props;
    if ((!reviewId && !reviewType) || reviewType === 'Follow-up') return;
    let requestInput = { reviewId, reviewType, fiscalYear };
    let willFetch = false;
    if (reviewId === this.context.router.params.reviewId) {
      willFetch = true;
    }
    this.setState({ guideName: '', guideLoading: true });
    this.props
      .fetchReviewGuides(requestInput)
      .then(() =>
        this.setState({ guideLoading: false, guidesLoaded: willFetch })
      )
      .catch(error => {
        this.setState({ guideLoading: false });
      });
  };

  loadPerformanceMeasuresData = () => {
    const { reviewId, reviewType, fiscalYear } = this.props;

    if ((!reviewId && !reviewType) || reviewType === 'Follow-up') return;
    let requestInput = { reviewId, reviewType, fiscalYear };
    if (this.state.guideCode) requestInput.guideCode = this.state.guideCode;
    this.setState({ performanceMeasure: '', performanceMeasureLoading: true });

    this.props
      .fetchReviewPerformanceMeasures(requestInput)
      .then(() => this.setState({ performanceMeasureLoading: false }))
      .catch(error => {
        this.setState({ performanceMeasureLoading: false });
      });
  };

  componentWillReceiveProps(nextProps) {
    const {
      guides,
      performanceMeasures,
      reviewId,
      reviewType,
      fiscalYear,
    } = nextProps;

    const guideOptions = !_.isEmpty(guides)
      ? guides.map(guide => ({
          key: guide.guideCode,
          text: guide.name,
          value: guide.guideCode,
        }))
      : [];

    const performanceMeasuresOptions = !_.isEmpty(performanceMeasures)
      ? performanceMeasures.map(performanceMeasure => ({
          key: performanceMeasure.formCode,
          text: performanceMeasure.name,
          value: performanceMeasure.formCode,
        }))
      : [];

    this.setState(
      {
        guides,
        performanceMeasures,
        reviewId,
        reviewType,
        guideOptions,
        performanceMeasuresOptions,
        fiscalYear,
      },
      () => {
        // First time load guides
        if (
          (this.props.fiscalYear > 2021 && this.props.reviewType === 'RAN') ||
          reviewType !== 'Follow-up'
        ) {
          if (
            reviewId &&
            reviewType &&
            _.isEmpty(guides) &&
            this.state.guidesLoaded === false
          ) {
            this.loadGuidesData();
            this.loadPerformanceMeasuresData();
          }
        }
      }
    );
  }

  handleGuideNameOnChange = (e, { name, value }) => {
    this.setState(
      {
        [name]: value,
        guideCode: value,
      },
      () => {
        // Fetch performance measures for the selected guide.
        if (value) {
          this.loadPerformanceMeasuresData();
        }
      }
    );
  };

  handleOnChange = (e, { name, value }) => this.setState({ [name]: value });

  resetFilters = e => {
    if (e) e.preventDefault();
    this.setState(
      {
        guides: [],
        performanceMeasures: [],
        guideOptions: [],
        performanceMeasuresOptions: [],
        guideCode: '',
        status: '',
        guideName: '',
        performanceMeasure: '',
        guideLoading: false,
        performanceMeasureLoading: false,
      },
      () => {
        this.passFilters();
        this.getData();
        this.scrollToRef();
      }
    );
  };

  passFilters = () => {
    const { guideName, performanceMeasure, status } = this.state;
    this.props.onFilterClicked({ guideName, performanceMeasure, status });
  };

  renderGuides = () => {
    return (
      <AmsFormLabel name="Guide Name" fieldLabel="filterGuideName">
        <Form.Dropdown
          aria-labelledby="filterGuideName"
          name="guideName"
          placeholder="Select Guide Name"
          selection
          searchInput={{ id: 'filterGuideName' }}
          search
          fluid
          loading={this.state.guideLoading}
          selectOnBlur={false}
          value={this.state.guideName}
          options={this.state.guideOptions}
          onChange={this.handleGuideNameOnChange}
        />
      </AmsFormLabel>
    );
  };

  renderPerformanceMeasures = () => {
    return (
      <AmsFormLabel
        name="Performance Measure"
        fieldLabel="filterPerformanceMeasure"
      >
        <Form.Dropdown
          name="performanceMeasure"
          placeholder="Select Performance Measure"
          selection
          aria-labelledby="filterPerformanceMeasure"
          searchInput={{ id: 'filterPerformanceMeasure' }}
          search
          fluid
          loading={this.state.performanceMeasureLoading}
          selectOnBlur={false}
          value={this.state.performanceMeasure}
          options={this.state.performanceMeasuresOptions}
          onChange={this.handleOnChange}
        />
      </AmsFormLabel>
    );
  };

  renderReviewStatus = () => {
    return (
      <AmsFormLabel name="Status" fieldLabel="filterStatus">
        <Form.Dropdown
          name="status"
          aria-labelledby="filterStatus"
          searchInput={{ id: 'filterStatus' }}
          placeholder="Select Status"
          selection
          search
          fluid
          selectOnBlur={false}
          value={this.state.status}
          options={this.state.reviewStatusOptions}
          onChange={this.handleOnChange}
        />
      </AmsFormLabel>
    );
  };

  render() {
    // Show filter for non-RAN review types
    if (
      (this.props.fiscalYear < 2022 && this.props.reviewType === 'RAN') ||
      (this.props.fiscalYear > 2021 &&
        this.props.reviewType === 'RAN' &&
        this.props.isRANIncident === false) ||
      this.props.reviewType === 'Follow-up'
    )
      return null;

    return (
      <div ref={this.filterSegment} tabIndex="-1">
        {' '}
        <Segment>
          <h2>Filters</h2>
          <Form>
            <Form.Group widths="equal">
              <Form.Field width={5}>{this.renderGuides()}</Form.Field>
              <Form.Field width={5}>
                {this.renderPerformanceMeasures()}
              </Form.Field>
              <Form.Field width={5}>{this.renderReviewStatus()}</Form.Field>
              <Form.Group
                inline
                className="review-submissions-filter-buttons-wrapper"
              >
                <Form.Button
                  className="filter-button"
                  primary
                  content="Filter"
                  onClick={this.passFilters}
                />
                <Form.Button
                  className="filter-button"
                  basic
                  content="Reset"
                  onClick={this.resetFilters}
                />
              </Form.Group>
            </Form.Group>
          </Form>
        </Segment>
      </div>
    );
  }
}

ReviewSubmissionsFilters.contextTypes = {
  router: PropTypes.object.isRequired,
};

ReviewSubmissionsFilters.propTypes = {
  fetchReviewGuides: PropTypes.func.isRequired,
  fetchReviewPerformanceMeasures: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  reviewId: state.review.selectedReview.reviewId,
  fiscalYear: state.review.selectedReview.fiscalYear,
  reviewType: state.review.selectedReview.reviewType,
  guides: state.review.selectedReview.guides,
  performanceMeasures: state.review.selectedReview.performanceMeasures,
});

export default connect(mapStateToProps, {
  fetchReviewGuides,
  fetchReviewPerformanceMeasures,
})(ReviewSubmissionsFilters);
