import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Label, Menu } from 'semantic-ui-react';

const ReviewChildFiles = ({ selectedReview, survey }) => {
  if (selectedReview.reviewType === 'RAN') return null;

  let showSpecialChildfilesCard = false;

  // Find if the PreSite checkboxes were selected on the special review.
  if (survey && survey.guides) {
    const guides = _.find(survey.guides, { guideCode: 'ERSEA' });
    const pmOrPAs =
      selectedReview.reviewType === 'Special' && selectedReview?.isNewForms
        ? guides?.performanceAreas
        : guides?.performanceMeasures;
    const presiteChildfiles =
      guides &&
      pmOrPAs &&
      _.find(pmOrPAs, o => {
        if (
          (o.isActive === true && o.key === 'ERSEA-0') ||
          o?.performanceArea ===
            'Eligibility File Review - Individual File Data'
        ) {
          return true;
        }
        return false;
      });

    if (presiteChildfiles && presiteChildfiles.isActive) {
      showSpecialChildfilesCard = true;
    }
  }

  let showCard =
    selectedReview &&
    selectedReview.reviewId &&
    (selectedReview.reviewType === 'FA-2' ||
      selectedReview.reviewType === 'FA2-CR' ||
      (selectedReview.reviewType === 'Special' && showSpecialChildfilesCard) ||
      (selectedReview.reviewType === 'AIAN-DEF' && showSpecialChildfilesCard) ||
      (selectedReview.reviewType === 'ERSEA' && showSpecialChildfilesCard))
      ? true
      : false;

  let cardCount =
    selectedReview &&
    selectedReview.childFileCount &&
    selectedReview.childFileCount.currentCount
      ? `${selectedReview.childFileCount.currentCount}/${selectedReview.childFileCount.currentCount}`
      : `0/0`;

  let granteeInfo = ((selectedReview || {}).grantees || [])[0] || null;
  let granteeName = (granteeInfo || {}).granteeName;
  let granteeId = (granteeInfo || {}).granteeId;
  let reviewType = selectedReview.reviewType;
  let easReview = reviewType === 'FA1-R' ? '/new' : '';
  let fiscalYear = selectedReview.fiscalYear
    ? `fy` + selectedReview.fiscalYear.toString().slice(-2)
    : 'fy19';

  return showCard ? (
    <Menu.Item
      key="ReviewChildFiles"
      to={{
        pathname: `/review${easReview}/${fiscalYear}/${
          selectedReview.reviewId
        }/survey-outcome/${
          selectedReview.childFileCount &&
          selectedReview.childFileCount.amsSubmissionId
            ? selectedReview.childFileCount.amsSubmissionId
            : 'no-submission-id'
        }/${
          selectedReview.childFileCount &&
          selectedReview.childFileCount.amsFormId
            ? selectedReview.childFileCount.amsFormId
            : 'no-form-id'
        }/child-files`,
        query: { granteeId, granteeName, reviewType },
      }}
      link
      as={Link}
    >
      <Label>{cardCount}</Label>
      Eligibility Files
    </Menu.Item>
  ) : null;
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  survey: state.survey,
});

export default connect(mapStateToProps)(ReviewChildFiles);
