import React, { Component } from 'react';

import SurveyList from './SurveyList';

class Survey extends Component {
  render() {
    return (
      <div>
        <SurveyList />
      </div>
    );
  }
}

export default Survey;
