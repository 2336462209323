import React from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

const FollowUpGranteeInfo = () => {
  const {
    granteeInfo,
    reviewId,
    reviewStartDate,
    reviewEndDate,
    parentReportShippedDate,
  } = useSelector(state => state.submissions.followUpTaskDetail);
  return (
    <div>
      {granteeInfo && !isEmpty(granteeInfo) ? (
        <Segment
          tertiary
          style={{
            background: 'rgba(34, 36, 38, 0.05)',
            color: 'rgba(0,0,0,.95)',
          }}
        >
          {reviewId && (
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Review ID"
              >
                Review ID:{' '}
              </span>{' '}
              {reviewId}
            </div>
          )}
          {granteeInfo &&
            granteeInfo.map(grantee => (
              <div key={grantee.granteeId}>
                <span
                  role="heading"
                  aria-level="2"
                  className="field-title"
                  aria-label="Grantee Name"
                >
                  Grantee Name:{' '}
                </span>{' '}
                {`${grantee.granteeName} (${grantee.granteeId})`}
              </div>
            ))}
          {reviewStartDate && reviewEndDate && (
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Review Start and End Date"
              >
                Review Start and End Date :{' '}
              </span>{' '}
              {`${AmsDateFormatters.formatDate(
                reviewStartDate
              )} - ${AmsDateFormatters.formatDate(reviewEndDate)}` || ''}
            </div>
          )}
          {parentReportShippedDate && (
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Parent review report shipped date"
              >
                Parent review report shipped date :{' '}
              </span>{' '}
              {`${AmsDateFormatters.formatDate(parentReportShippedDate)}` || ''}
            </div>
          )}
        </Segment>
      ) : null}
    </div>
  );
};

export default FollowUpGranteeInfo;
