import React from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { sortBy, find } from 'lodash';

const GlobalAlert = ({ carousels }) => {
  const alert = carousels && find(carousels, c => c.alert === true);

  if (alert)
    return (
      <div
        style={{
          background: '#ffbe2e',
          textAlign: 'left',
          color: '#000',
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
      >
        <Segment basic>
          <strong style={{ fontWeight: '700' }}>{alert.title}</strong>{' '}
          {alert.blurb}
        </Segment>
      </div>
    );

  return null;
};

const mapStateToProps = state => ({
  carousels: sortBy(state.carousel, ['weight']),
});

export default connect(
  mapStateToProps,
  {}
)(GlobalAlert);
