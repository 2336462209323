import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Radio, Button, Well } from 'react-bootstrap';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import _ from 'lodash';
import { Loader, Dimmer } from 'semantic-ui-react';
import AmsAlert from '../../utils/AmsAlert';
// eslint-disable-line import/no-extraneous-dependencies

// Import components
import { FieldGroupField, FieldGroupDatePicker } from '../../utils/FieldGroup';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import actions
import { updateSumbission } from '../../actions/surveyActions';

// Import style
import './assets/style.css';

class SurveyEditForm extends Component {
  state = {
    editMode: false,
    showAlert: false,
    showErrorAlert: false,
    errorMessage: '',
    changesQA: {},
    submissionQA: [],
  };

  componentWillReceiveProps(nextProps) {
    const { submissionQA } = nextProps.selectedSubmission;
    this.setState({ submissionQA });
  }

  handleInputValueChage(event) {
    this.setState({
      changesQA: {
        ...this.state.changesQA,
        [event.target.id]: event.target.value,
      },
    });
  }

  handleDateChange(elementId, value, formattedValue) {
    if (!value) {
      this.setState({
        changesQA: { ...this.state.changesQA, [elementId]: null },
      });
      return;
    }
    this.setState({
      changesQA: {
        ...this.state.changesQA,
        [elementId]: AmsDateFormatters.getMoment(value).format(),
      },
    });
  }

  handleRadioSelect(option, event) {
    this.setState({
      changesQA: { ...this.state.changesQA, [event.target.id]: option.name },
    });
  }

  handleMultiCheckboxCheck(selections, event) {
    this.setState({
      changesQA: { ...this.state.changesQA, [event.target.id]: selections },
    });
  }

  renderFormElements(qa, isRepeat = false) {
    const { editMode } = this.state;

    switch (qa.type) {
      case 'repeat':
        if (qa.answer && qa.questionLabel) {
          return (
            <div key={qa.questionName} className="repeat-wrapper">
              <label className="control-label repeat-label">
                {qa.questionLabel}
              </label>
              {
                <div className="repeat-content">
                  {qa.answer.map((repeatQa, repeatKey) => {
                    // eslint-disable-next-line array-callback-return
                    return repeatQa.map((repeatItem, repeatItemKey) => {
                      if (repeatItem) {
                        return (
                          <div
                            className="repeat-survey-field"
                            key={repeatItemKey}
                          >
                            {' '}
                            {this.renderFormElements(repeatItem, true)}{' '}
                          </div>
                        );
                      }
                    });
                  })}
                </div>
              }
            </div>
          );
        }
        break;
      case 'group':
        if (qa.answer && qa.questionLabel)
          return (
            <div key={qa.questionName}>
              <label className="control-label group-label">
                {qa.questionLabel}
              </label>
              {qa.answer.map((groupQa, key) => {
                return (
                  <div className="group-survey-field" key={key}>
                    {' '}
                    {this.renderFormElements(groupQa)}{' '}
                  </div>
                );
              })}
            </div>
          );
        break;
      case 'integer':
        return (
          <FieldGroupField
            bsSize="small"
            disabled={!editMode}
            onChange={this.handleInputValueChage.bind(this)}
            key={qa.questionName}
            id={qa.questionName}
            type="number"
            value={
              this.state.changesQA[qa.questionName] === undefined
                ? qa.answer
                : this.state.changesQA[qa.questionName]
            }
            label={qa.questionLabel}
          />
        );

      case 'note': {
        return <Well key={qa.questionName}>{qa.questionLabel}</Well>;
      }

      case 'text':
        return (
          <FieldGroupField
            bsSize="small"
            disabled={!editMode}
            onChange={this.handleInputValueChage.bind(this)}
            key={qa.questionName}
            id={qa.questionName}
            type="text"
            value={
              this.state.changesQA[qa.questionName] === undefined
                ? qa.answer
                : this.state.changesQA[qa.questionName]
            }
            label={qa.questionLabel}
          />
        );

      case 'text-multiline':
        return (
          <FieldGroupField
            bsSize="small"
            style={{ height: 150 }}
            disabled={!editMode}
            onChange={this.handleInputValueChage.bind(this)}
            key={qa.questionName}
            id={qa.questionName}
            componentClass="textarea"
            value={
              this.state.changesQA[qa.questionName] === undefined
                ? qa.answer
                : this.state.changesQA[qa.questionName]
            }
            label={qa.questionLabel}
          />
        );

      case 'date':
        return (
          <FieldGroupDatePicker
            bsSize="small"
            disabled={!editMode}
            key={qa.questionName}
            id={qa.questionName}
            value={
              this.state.changesQA[qa.questionName] === null
                ? ''
                : this.state[qa.questionName] || qa.answer
            }
            onChange={this.handleDateChange.bind(this, qa.questionName)}
            label={qa.questionLabel}
          />
        );

      case 'time':
        return (
          <FieldGroupField
            bsSize="small"
            disabled={!editMode}
            onChange={this.handleInputValueChage.bind(this)}
            key={qa.questionName}
            id={qa.questionName}
            type="text"
            value={
              this.state.changesQA[qa.questionName] === undefined
                ? qa.answer
                : this.state.changesQA[qa.questionName]
            }
            label={qa.questionLabel}
          />
        );

      case 'select one': {
        let options = qa.options.map((option, index) => {
          return (
            <Radio
              disabled={!editMode}
              checked={
                !_.isEmpty(this.state.changesQA[qa.questionName])
                  ? this.state.changesQA[qa.questionName] === option.name
                  : option.name === qa.answer
              }
              key={index}
              id={qa.questionName}
              onChange={this.handleRadioSelect.bind(this, option)}
              name={qa.questionName}
            >
              {option.label}
            </Radio>
          );
        });
        return (
          <div className="select-one-wrapper" key={qa.questionName}>
            <div>{qa.questionLabel}</div>
            {options}
          </div>
        );
      }

      case 'select all that apply': {
        let checkboxGroup = (
          <CheckboxGroup
            name={qa.questionName}
            value={
              this.state.changesQA[qa.questionName] || qa.answer.split(' ')
            }
            onChange={this.handleMultiCheckboxCheck.bind(this)}
            checkboxDepth={2}
          >
            {qa.options.map((option, index) => {
              return (
                <div key={option.name + index}>
                  <Checkbox
                    disabled={!editMode}
                    key={index}
                    value={option.name}
                    id={qa.questionName}
                  />
                  {` ${option.label}`}
                </div>
              );
            })}
          </CheckboxGroup>
        );

        return (
          <div className="select-all-wrapper" key={qa.questionName}>
            <div>{qa.questionLabel}</div>
            {checkboxGroup}
          </div>
        );
      }

      case 'photo' || 'audio' || 'video':
        if (qa.answer)
          return (
            <div className="attachment-wrapper" key={qa.answer}>
              <br />
              <label htmlFor="attachement" className="control-label">
                {qa.questionLabel}
              </label>
              <div>
                <i className="fa fa-paperclip m-r-xs" />
                <a
                  name="attachement"
                  target="_blank"
                  href={qa.answer}
                  rel="noopener noreferrer"
                >
                  Attachment Link
                </a>
              </div>
            </div>
          );
        break;
      default:
        return null;
    }
  }

  renderEditButton() {
    const { enableEditSurvey } = this.props.selectedSubmission;
    const { editMode } = this.state;
    const buttonStyle = editMode ? 'primary' : 'default';

    return (
      enableEditSurvey && (
        <Button
          bsStyle={buttonStyle}
          onClick={this.handelEditClick.bind(this)}
          className="btn edit-button"
        >
          {' '}
          {!editMode ? 'Edit' : 'Save'}
        </Button>
      )
    );
  }

  buildForm() {
    const { loading, submissionQA } = this.state;

    return loading ? (
      <Dimmer active inverted>
        <Loader inverted>Updating...</Loader>
      </Dimmer>
    ) : (
      <div>
        <div className="col-sm-12 text-right">{this.renderEditButton()}</div>
        <div className="row col-sm-12">
          {submissionQA &&
            submissionQA.map(qa => {
              return this.renderFormElements(qa);
            })}
        </div>
      </div>
    );
  }

  showEditConfirmAlert() {
    const { showAlert } = this.state;

    return (
      <AmsAlert
        show={showAlert}
        title="Modify submission"
        text={`You are about to modify this submission. Are you sure you want to continue?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, save changes'}
        cancelButtonText={'No, cancel'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showAlert: false });
        }}
        onConfirm={() => {
          this.saveUpdate();
        }}
      />
    );
  }

  showErrorAlert() {
    const { showErrorAlert, errorMessage } = this.state;

    return (
      <AmsAlert
        title={'Error'}
        show={showErrorAlert}
        text={errorMessage}
        type={'error'}
        onConfirm={() =>
          this.setState({ showErrorAlert: false, errorMessage: '' })
        }
      />
    );
  }

  saveUpdate() {
    const { changesQA } = this.state;
    const { selectedReview, currentUser, selectedSubmission } = this.props;

    if (
      !_.isEmpty(changesQA) &&
      selectedReview.reviewId &&
      currentUser.oid &&
      selectedSubmission.koboFormId &&
      selectedSubmission.submissionId
    ) {
      const updateObject = {
        reviewId: selectedReview.reviewId,
        reviewerId: currentUser.oid,
        koboFormId: selectedSubmission.koboFormId,
        submissionId: selectedSubmission.submissionId,
        changesQA,
      };

      this.setState({ loading: true });

      this.props
        .updateSumbission(updateObject)
        .then(() => {
          this.setState({
            loading: false,
            editMode: !this.state.editMode,
            showAlert: false,
            changesQA: {},
          });
        })
        .catch(error => {
          const { message } = error.response.data;

          if (message) {
            this.setState({
              loading: false,
              showAlert: false,
              showErrorAlert: true,
              errorMessage: message,
            });
          }
        });
    }
  }

  handelEditClick() {
    const { editMode, changesQA } = this.state;

    if (editMode && !_.isEmpty(changesQA)) {
      this.setState({ showAlert: true });
      return;
    }

    // No changes yet so wait in edit mode.
    if (editMode && _.isEmpty(changesQA)) {
      return;
    }

    this.setState({ editMode: !this.state.editMode });
  }

  render() {
    return (
      <div className="row">
        {this.showEditConfirmAlert()}
        {this.showErrorAlert()}
        {this.buildForm()}
      </div>
    );
  }
}

SurveyEditForm.propTypes = {
  selectedSubmission: PropTypes.object.isRequired,
  updateSumbission: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedSubmission: state.survey.selectedSubmission,
    currentUser: state.auth.user,
  };
}

export default connect(mapStateToProps, { updateSumbission })(SurveyEditForm);
