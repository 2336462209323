/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-extra-bind */
import base64 from 'base64-arraybuffer';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

//Import Components
import AmsAlert from '../../utils/AmsAlert';
import ReportItem from './ReportItem';
import ReportShare from './ReportShare';

// Import Actions.
import {
  fetchCommentsFileList,
  fetchDeadlineDates,
  fetchFileContents,
  fetchReportDraftTemplate,
  fetchReportList,
  fetchReportStatusData,
  fetchReportVersionData,
  generateReportAction,
  reportAIANDEFAction,
  reportERSEAAction,
  reportFindingsAction,
  reportNewFaOneAction,
  reportNewFaTwoAction,
  newDraftReportAction,
  reportRanAction,
  reportSaveAction,
  reportShareAction,
  reportSharePDFAction,
  reportSpecialAction,
  reportTemplateAction,
  surveyReportSaveAction,
} from '../../actions/reportActions';

import {
  fetchEmailNotifyFOMRequest,
  fetchEmailNotifyQAARequest,
  fetchEmailNotifyRLRequest,
  fetchEmailReturnToFOM,
  fetchReviewDetail,
} from '../../actions/reviewActions';
import { fetchRasUrl } from '../../actions/surveyActions';
import { fetchUsers } from '../../actions/userActions';

import moment from 'moment-timezone';
// Import config
import { settings } from '../../config';

// Set Default Timezone.
moment.tz.setDefault(settings.defaultTimeZone);

// import { fetchReviews } from '../../actions/reviewActions';

require('./assets/reports.css');

// import { getProfile, SET_CURRENT_USER } from './../../actions/authActions'

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      dashboardVisible: false,
      retFomAlrt: false,
      submitToQaaAlert: false,
      returnToRLAlert: false,
      signAlert: false,
      finalizeAlert: false,
      message: '',
      // reportsTemplates: [],
      defaultTemplate: null,
      profile: null,
      reportId: null,
      downloadedFiles: [],
      finalized: false,
      // showAlert: false
      // defaultTemplatePurpose: null,
    };

    this.initContentToolsChecker();

    this.reportItem = null;
    this.reportShare = null;

    this.createReport = this.createReport.bind(this);
    this.loadReport = this.loadReport.bind(this);
    this.loadReportVersion = this.loadReportVersion.bind(this);
    this.loadReportComparingVersion = this.loadReportComparingVersion.bind(
      this
    );
    this.reportDashboard = this.reportDashboard.bind(this);
    this.saveReport = this.saveReport.bind(this);
    this.signReport = this.signReport.bind(this);
    // this.confirmReport = this.confirmReport.bind(this)

    // this.retrieveTemplateList = this.retrieveTemplateList.bind(this)
    // this.getTemplateList = this.getTemplateList.bind(this)
    this.createReportForm = this.createReportForm.bind(this);

    this.componentDidMount = this.componentDidMount.bind(this);

    this.onReportItem = this.onReportItem.bind(this);
    this.onReportShare = this.onReportShare.bind(this);
    this.onReportItemUpdate = this.onReportItemUpdate.bind(this);

    this.loadExistingReviews = this.loadExistingReviews.bind(this);
    this.loadComparingVersions = this.loadComparingVersions.bind(this);

    this.reportShareConfigOHS = this.reportShareConfigOHS.bind(this);
    this.reportShareConfigOGC = this.reportShareConfigOGC.bind(this);
    this.reportShareConfigRO = this.reportShareConfigRO.bind(this);
    this.reportShareConfigGrantee = this.reportShareConfigGrantee.bind(this);

    this.onReportShareSubmit = this.onReportShareSubmit.bind(this);

    this.applyState = this.applyState.bind(this);

    this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);

    this.launchRAS = this.launchRAS.bind(this);
    // this.addComment = this.addComment.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.showConfirmationAlert = this.showConfirmationAlert.bind(this);
    this.showReturntoFOMAlert = this.showReturntoFOMAlert.bind(this);
    this.showSubmittoQAAAlert = this.showSubmittoQAAAlert.bind(this);
    this.showReturnToRLAlert = this.showReturnToRLAlert.bind(this);
    this.showSignAlert = this.showSignAlert.bind(this);
    this.showFinalizeAlert = this.showFinalizeAlert.bind(this);
  }

  applyState(name, conf, cb) {
    if (!cb) cb = () => {};
    let _self = this;
    switch (name) {
      case 'UNLOAD_REPORT':
        _self.setState(
          {
            // reportId : null,
            // reportVersionId: null,
            defaultTemplate: null,
            defaultTemplatePurpose: null,
            message: '',
            dashboardVisible: false,
          },
          cb
        );
        break;
      case 'LOAD_REPORT_FROM_TEMPLATE':
        _self.setState(
          {
            // reportVersionId: null,
            // defaultTemplate: null,
            defaultTemplatePurpose: conf.template,
            message: '',
            dashboardVisible: false,
          },
          cb
        );
        break;
      case 'LOAD_REPORT_FROM_TEMPLATE_CONFIRM':
        _self.setState(
          {
            // reportVersionId: null,
            defaultTemplate: this.state.defaultTemplatePurpose,
            defaultTemplatePurpose: null,
            message: '',
            dashboardVisible: true,
          },
          cb
        );
        break;
      case 'LOAD_REPORT_FROM_TEMPLATE_SKIP':
        _self.setState(
          {
            // reportVersionId: null,
            defaultTemplate: false,
            defaultTemplatePurpose: null,
            message: '',
            dashboardVisible: true,
          },
          cb
        );
        break;
      case 'LOAD_REPORT_FROM_TEMPLATE_CANCEL':
        _self.applyState('UNLOAD_REPORT');
        break;
      case 'LOAD_REPORT_BY_ID':
        _self.setState(
          {
            reportId: (this.props.reportStatusData || {}).reportID,
            reportVersionId: conf.versionId || null,
            reportVersionData: null,
            // defaultTemplate: null,
            // defaultTemplatePurpose: null,
            // dashboardVisible: ( conf.versionId ? true : false ),
            message: '',
          },
          cb || (() => {})
        );
        break;
      // case "LOAD_REPORT_TEMPLATES":
      //   _self.setState({
      //     reportsTemplates: conf.list
      //   }, cb);
      // break;
      case 'SHOW_DASHBOARD':
        _self.setState({ dashboardVisible: true }, cb);
        break;
      case 'SHOW_DASHBOARD_EMPTY_TEMPLATE':
        _self.setState({ dashboardVisible: true, defaultTemplate: null }, cb);
        break;
      case 'HIDE_DASHBOARD':
        _self.setState(
          {
            reportId: null,
            reportVersionId: null,
            dashboardVisible: false,
          },
          cb
        );
        break;
      case 'ERROR_MESSAGE':
        _self.setState(
          {
            message: conf.message,
          },
          cb
        );
        break;
      case 'PROFILE_INFO':
        _self.setState(
          {
            profile: conf.profile || {},
          },
          cb
        );
        break;
      default:
        console.warn('Unknown state name', arguments);
    }
  }

  onReportItem(reportItem) {
    this.reportItem = reportItem;
    this.applyState('PROFILE_INFO', {
      profile: this.props.currentUser,
    });
  }

  onReportShare(reportShare) {
    this.reportShare = reportShare;
  }

  onReportItemUpdate(_reportItem) {
    let reportState = ((this.reportItem || {}).currentData || {}).state;

    let levels = version => {
      if (version.versionStatus === 'signed') {
        return 5;
      } else if (version.versionStatus === 'finalized') {
        return 4;
      } else if (version.versionStatus === 'signed') {
        return 3;
      } else {
        return 0;
      }
    };

    let maxLevel =
      (
        ((this.props.reportStatusData || {}).reportVersions || [])
          .map(version => {
            return {
              versionStatus: version.versionStatus,
              level: levels(version),
            };
          })
          .sort((a, b) => b.level - a.level)[0] || {}
      ).versionStatus || 'draft';

    document
      .querySelector('.reports-dashboard')
      .setAttribute('report-state', reportState);
    document
      .querySelector('.reports-dashboard')
      .setAttribute('report-max-state', maxLevel);

    if (!this.state.reportVersionId) {
      if (this.lastCleanSavedReview !== this.reviewId) {
        this.lastCleanSavedReview = this.reviewId;
        this.saveReport();
      }
    }
  }

  saveContentToolsTrigger() {
    let buttons = {
      edit: document.querySelector(
        'body > .ct-app > .ct-widget.ct-ignition > .ct-ignition__button--edit'
      ),
      confirm: document.querySelector(
        'body > .ct-app > .ct-widget.ct-ignition > .ct-ignition__button--confirm'
      ),
      cancel: document.querySelector(
        'body > .ct-app > .ct-widget.ct-ignition > .ct-ignition__button--cancel'
      ),
    };
    let ctwidget = document.querySelector(
      'body > .ct-app > .ct-widget.ct-ignition'
    );

    let states = {
      ready:
        ((ctwidget || {}).className + '').indexOf('ct-ignition--ready') !== -1,
      editing:
        ((ctwidget || {}).className + '').indexOf('ct-ignition--editing') !==
        -1,
    };

    let dashboard = document.querySelector('.reports-dashboard');

    if (states.editing) {
      document.querySelector(
        '.report-item--edit-button.action-edit'
      ).innerHTML = 'Edit';
      if (dashboard.hasAttribute('report-state-editing')) {
        dashboard.removeAttribute('report-state-editing');
      }
    } else {
      dashboard.setAttribute('report-state-editing', 'true');
      document.querySelector(
        '.report-item--edit-button.action-edit'
      ).innerHTML = 'Close Editing';
    }

    if (states.ready) {
      buttons.edit.click();
      document
        .querySelector('.reports-dashboard .report-item-editor')
        .setAttribute('data-editing', 'true');
    } else if (states.editing) {
      document
        .querySelector('.reports-dashboard .report-item-editor')
        .setAttribute('data-editing', 'false');
      let container = document.querySelector('.reports-dashboard');
      let popup = document.createElement('div');
      popup.innerHTML =
        // eslint-disable-next-line no-multi-str
        '<div class="edit-review-form-save">\
        <div>\
          <p>Do you accept your edits?</p>\
          <button class="btn btn-primary">Yes</button>\
          <button class="btn btn-default">Cancel</button>\
        </div>\
      </div>';
      container.appendChild(popup);
      let popupButtons = popup.getElementsByTagName('button');
      popupButtons[0].addEventListener('click', function() {
        try {
          buttons.confirm.click();
        } catch (er) {
          console.error(er);
        }
        popup.parentElement.removeChild(popup);
      });
      popupButtons[1].addEventListener('click', function() {
        try {
          buttons.cancel.click();
        } catch (er) {
          console.error(er);
        }
        popup.parentElement.removeChild(popup);
      });
    }
  }

  initContentToolsChecker() {
    try {
      clearInterval(window.ContentToolsBarChecker);
    } catch (er) {}

    window.ContentToolsBarChecker = setInterval(function() {
      let toolbox = document.querySelector('body > .ct-app > .ct-toolbox');
      if (toolbox) {
        let container = document.querySelector('.reports-toolbox-container');
        if (container) {
          container.innerHTML = '';
          toolbox.parentElement.removeChild(toolbox);
          container.appendChild(toolbox);
        }
      }
    }, 500);
  }

  saveReport(reportStatus) {
    if (reportStatus === 'finalized') {
      this.setState({ finalized: true });
    }

    // content hardcoded
    //let reviewId = window.location.pathname.replace(/.*\//, '');
    // let reportNode = window.document.querySelector('.report-item-editor');
    this.reportItem.getStyledContent().then(content => {
      if (!reportStatus)
        reportStatus = (this.reportItem.getCurrentData() || {}).state;

      content = content.replace(
        // eslint-disable-next-line no-useless-escape
        /(\<(div|p|h2)[\s\n][^\>]*)data\-editable/,
        '$1'
      );

      let versionNumber =
        (this.props.reportVersionData || {}).versionNumber || null;

      if (!versionNumber) {
        versionNumber = '1.00';
      } else {
        versionNumber = (parseFloat(versionNumber) + 0.01).toFixed(2);
      }
      const validReviewTypes = ['FA2-CR', 'FA1-FR'];
      const newFormReviewTypes = ['RAN', 'Special'];
      const input = {
        reviewID: this.reviewId,
        reportID: this.props.reportStatusData.reportID || undefined,
        reportContent: content,
        reportStatus: this.props.reportStatusData.reportStatus || '',
        versionStatus: reportStatus || 'draft',
        who: this.props.currentUser.oid,
        versionNumber: versionNumber,
        reportRegenerated: versionNumber[0] !== '1',
        when: new Date().toISOString().replace(/\.\d{1,3}Z$/, '-0400'),
      };
      if (
        validReviewTypes.includes(this.props.reviewType) ||
        (newFormReviewTypes.includes(this.props.reviewType) &&
          this.props.reportVersionData.isNewForms)
      ) {
        this.props.surveyReportSaveAction(input);
      } else {
        this.props.reportSaveAction(input);
      }
    }, console.error);
  }

  generateReport(reportStatus) {
    // let reviewId = window.location.pathname.replace(/.*\//, '');
    this.reportItem.getStyledContent().then(content => {
      content = content.replace(
        // eslint-disable-next-line no-useless-escape
        /(\<(div|p|h2)[\s\n][^\>]*)data\-editable/,
        '$1'
      );
      let versionNumber =
        (this.props.reportVersionData || {}).versionNumber || null;

      if (!versionNumber) {
        versionNumber = '1.00';
      }

      this.props.generateReportAction({
        reviewID: this.reviewId,
        reportID: this.props.reportStatusData.reportID || undefined,
        reportContent: content,
        who: this.props.currentUser.oid,
        when: new Date().toISOString().replace(/\.\d{1,3}Z$/, '-0400'),
        versionStatus: reportStatus || 'draft',
        reportRegenerated: versionNumber[0] !== '1',
        versionNumber: versionNumber,
        recreateReport: false,
      });
    }, console.error);
  }

  // componentWillReceiveProps
  shouldComponentUpdate(nextProps, _nextState) {
    if (nextProps.reportSavedResult) {
      let reportKey = (
        nextProps.reportSavedResult.reportVersions[
          nextProps.reportSavedResult.reportVersions.length - 1
        ] || {}
      ).reportKey;

      if (
        nextProps.reportSavedResult._id &&
        reportKey !== this.state.reportVersionId
      ) {
        let _self = this;

        _self.loadReportVersion(
          nextProps.reportSavedResult.reportID,
          reportKey,
          () => {
            // nextProps.reportSavedResult = null;
          },
          true
        );
        _self.props.fetchReportStatusData(_self.reviewId);
      }
    }

    if (nextProps.reportSharedResult) {
      if (this.reportSharedResult !== nextProps.reportSharedResult) {
        this.reportSharedResult = nextProps.reportSharedResult;
        this.reportShare.hide();
        if (this.reportSharedResult.success) {
          alert(this.reportSharedResult.success);
        } else {
          alert(
            'Error: ' + (this.reportSharedResult.success || 'unknown error')
          );
        }
      }
    }

    if (nextProps.reportSharedPDFResult) {
      if (this.reportSharedPDFResult !== nextProps.reportSharedPDFResult) {
        this.reportSharedPDFResult = nextProps.reportSharedPDFResult;
        this.reportShare.hide();
        if (this.reportSharedPDFResult.success) {
          alert(this.reportSharedPDFResult.success);
        } else {
          alert(
            'Error: ' + (this.reportSharedPDFResult.success || 'unknown error')
          );
        }
      }
    }
    return true;
  }

  signReport() {
    let _self = this;
    let loadSignatureByURL = function(url) {
      let signature = new Image();

      signature.onload = function() {
        let signatureUpdated;

        // create additional canvas
        let canvas = window.document.createElement('canvas');

        // make canvas higher with dHeight pixels
        canvas.height = signature.height;
        canvas.width = signature.width;
        let context = canvas.getContext('2d');

        // inset image into that canvas
        context.drawImage(signature, 0, 0);

        // create a new Image
        signatureUpdated = new Image();

        signatureUpdated.onerror = function(err) {
          console.error(err);
          alert(err.toString());
        };

        // after image loaded append it to contet
        signatureUpdated.onload = function() {
          //  [data-name=\"content\"]
          let container = window.document.querySelector(
            '.report-item--content'
          );
          let nodeSignature = container.querySelector('.user-report-signature');
          if (!nodeSignature) {
            container.innerHTML = container.innerHTML.replace(
              // eslint-disable-next-line no-useless-escape
              /[\_]{10,}/,
              '<span class="user-report-signature"></span>'
            );
            nodeSignature = container.querySelector('.user-report-signature');
          }

          if (!nodeSignature) {
            container.innerHTML =
              container.innerHTML +
              '<span class="user-report-signature"></span>';
            nodeSignature = container.querySelector('.user-report-signature');
          }
          if (nodeSignature.querySelector('img')) {
            alert('Document already signed');
          } else {
            let signatureDate = document.createElement('b');
            signatureDate.className = 'user-report-signature-date';
            let d = new Date();
            let s = function(i) {
              return (i < 10 ? '0' : '') + i;
            };
            signatureDate.appendChild(
              document.createTextNode(
                'Date: ' +
                  s(d.getMonth() + 1) +
                  '/' +
                  s(d.getDate()) +
                  '/' +
                  d.getFullYear()
              )
            );
            nodeSignature.appendChild(signatureUpdated);
            nodeSignature.appendChild(document.createElement('br'));
            nodeSignature.appendChild(signatureDate);
            _self.saveReport('signed');
          }
        };

        // export that canvas as data URL
        // apply url to new Image
        signatureUpdated.src = canvas.toDataURL();
      };

      signature.onerror = function(err) {
        console.error(err);
        alert('Signature Error: unable to load image signature');
      };

      signature.src = url;
    };

    fetch('/users/fetchadminsignature', {
      method: 'post',
      credentials: 'include',
    })
      .then(function(response) {
        return response.arrayBuffer();
      })
      .then(function(arrayBuf) {
        let blobFile = new Blob([arrayBuf], { type: 'image/png' });
        let url = URL.createObjectURL(blobFile);
        // window.open(url);
        loadSignatureByURL(url);
      });
  }

  downloadBigBase64(filename, content, contentType) {
    fileDownload(base64.decode(content), filename || 'file', contentType);
  }

  reportDashboard() {
    let className = window.document.body.className;
    // eslint-disable-next-line no-useless-escape
    className = className.replace(/\s*dashboard\-visible\s*/, ' ');
    if (this.state.dashboardVisible) {
      window.document.body.className = className + ' dashboard-visible';
    } else {
      window.document.body.className = className;
    }

    // <button className='btn btn-lg btn-primary' style={{ display: this.state.reportId ? 'inline-block' : 'none' }} onClick={this.confirmReport}>Confirm</button>&nbsp;

    return this.state.dashboardVisible &&
      this.props.reportTemplate &&
      this.state.defaultTemplate !== null ? (
      <div>
        <ReportItem
          onInit={this.onReportItem}
          actionSaveReport={(() => {
            this.saveReport();
            // eslint-disable-next-line no-extra-bind
          }).bind(this)}
          onDataLoad={this.onReportItemUpdate}
          reportId={this.state.reportId}
          reportVersionId={this.state.reportVersionId}
          reportVersionData={this.props.reportVersionData || null}
          findings={this.props.reportFindings}
          draftFindings={this.props.reportDraftTemplate}
          template={this.state.defaultTemplate}
          onUseComparingVersion={(versionData => {
            this.loadReportVersion(
              versionData.reportID,
              versionData.reportKey,
              () => {},
              true
            );
            // eslint-disable-next-line no-extra-bind
          }).bind(this)}
          forcedVersion={this.reportItemForcedVersion}
        />

        <br />
        <br />
        <br />
        <h2>Report Distribution Activity Log</h2>
        <table className="external-shares-table">
          <tr>
            <th>Shared by</th>
            <th>Shared with</th>
            <th>Shared date</th>
            <th>Expiration date</th>
          </tr>
          {((this.props.reportStatusData || {}).reportLinks || []).length
            ? (this.props.reportStatusData || {}).reportLinks.map(
                (link, index) => {
                  if (!link.sharedExternal || link.sharedExternal === '[ ]')
                    // eslint-disable-next-line array-callback-return
                    return;
                  return (
                    <tr key={index}>
                      <td>{link.sharedBy}</td>
                      <td>{link.sharedExternal}</td>
                      <td>
                        {moment(new Date(link.sharedDate)).format('MM/DD/YY')}
                      </td>
                      <td>
                        {moment(new Date(link.expirationDate)).format(
                          'MM/DD/YY'
                        )}
                      </td>
                    </tr>
                  );
                }
              )
            : null}
        </table>
        <hr />
        {(() => {
          let processedGroups = [];
          let groupsOrder = {
            default: 10,
            Grantee: 1,
            RO: 2,
            OGC: 3,
            OHS: 4,
          };
          return (this.props.reportStatusData.reportLinks || [])
            .sort(function(a, b) {
              return (
                (groupsOrder[a.group || 'default'] || groupsOrder['default']) -
                (groupsOrder[b.group || 'default'] || groupsOrder['default'])
              );
            })
            .map((itemLink, index) => {
              if ((itemLink.comments || []).length === 0) return null;
              let header = null;
              if (processedGroups.indexOf(itemLink.group) === -1) {
                processedGroups.push(itemLink.group);
                header = (
                  <h2>Comments from {itemLink.group || 'Unknown'} group</h2>
                );
              }
              return (
                <div key={index}>
                  <div className="cm-col-12" style={{ textAlign: 'left' }}>
                    {header}
                    <hr />
                    <h4>Comment Link shared by: {itemLink.sharedBy}</h4>
                    {(comments => {
                      if (comments.length === 0)
                        return (
                          <p>
                            <i>no comments found...</i>
                          </p>
                        );

                      return comments.map((comment, index) => {
                        // sample of obj on 22.12.17
                        // {author: "ooleksiiuk@gmail.com", authorFullName: "", comment: null, when: "12/22/2017T16:40:33"}
                        return (
                          <p key={index}>
                            <b>{comment.authorFullName}</b> ( {comment.author} ){' '}
                            <i>
                              on{' '}
                              {moment(comment.when).format('MM-DD-YYYY, HH:mm')}
                            </i>
                            <br />
                            <br />
                            {comment.comment ? (
                              comment.comment
                            ) : (
                              <i>empty comment...</i>
                            )}
                          </p>
                        );
                      });
                    }).bind(this)(itemLink.comments)}
                  </div>
                </div>
              );
            });
        }).bind(this)()}

        {(() => {
          if (
            Array.isArray((this.props.commentFileList || {}).commentsFileList)
          ) {
            return (
              <div>
                {this.props.commentFileList.commentsFileList
                  .map((conf, index) => {
                    let content =
                      this.props.commentFileContents[conf.binaryFileName] ||
                      null;
                    if (content) {
                      if (
                        this.state.downloadedFiles.indexOf(
                          conf.binaryFileName
                        ) === -1
                      ) {
                        let files = this.state.downloadedFiles || [];
                        files.push(conf.binaryFileName);
                        this.setState({
                          downloadedFiles: files,
                        });

                        this.downloadBigBase64(
                          conf.displayFileName,
                          content,
                          'application/octet-stream'
                        );
                      }
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          key={index}
                          download={conf.displayFileName}
                          onClick={(ev => {
                            ev.nativeEvent.preventDefault();
                            ev.nativeEvent.stopPropagation();

                            this.downloadBigBase64(
                              conf.displayFileName,
                              content,
                              'application/octet-stream'
                            );
                          }).bind(this)}
                        >
                          {conf.displayFileName}
                        </a>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          key={index}
                          download={conf.displayFileName}
                          onClick={(ev => {
                            ev.nativeEvent.preventDefault();
                            ev.nativeEvent.stopPropagation();
                            this.props.fetchFileContents(
                              {
                                binaryFileName: conf.binaryFileName,
                              },
                              true
                            );
                          }).bind(this)}
                        >
                          {conf.displayFileName}
                        </a>
                      );
                    }
                  })
                  .map((link, index) => {
                    return (
                      <div key={index} style={{ 'text-align': 'left' }}>
                        {link}
                      </div>
                    );
                  })}
              </div>
            );
          }
          return null;
        }).bind(this)()}
        {(() => {
          if (this.props.reportStatusData) {
            if (this.props.reportStatusData.approvalStatus === 'approved') {
              return <span>Report was approved</span>;
            } else if (
              this.props.reportStatusData.approvalStatus === 'rejected'
            ) {
              return <span>Report was rejected</span>;
            }
          }
        }).bind(this)()}
        <div style={{ display: this.state.message ? 'block' : 'none' }}>
          <p>
            <b>Error: </b> {this.state.message}
          </p>
        </div>
      </div>
    ) : this.state.message ? (
      <div>
        <p>
          <b>Error: </b> {this.state.message}
        </p>
      </div>
    ) : (
      ''
    );
  }

  createReport(defaultTemplate) {
    this.applyState('LOAD_REPORT_FROM_TEMPLATE', {
      template: defaultTemplate,
    });
    this.toggleComments(false);
  }

  loadReport(reportId, cb) {
    this.props.fetchCommentsFileList({
      reviewId: this.reviewId,
      reportId: (this.props.reportStatusData || {}).reportID,
    });
    this.applyState('LOAD_REPORT_BY_ID', { id: reportId }, cb);
    this.toggleComments(false);
  }

  loadReportVersion(reportId, reportVersionId, cb, forcedVersion) {
    this.reportItemForcedVersion = !!forcedVersion;

    this.applyState(
      'LOAD_REPORT_BY_ID',
      { id: reportId, versionId: reportVersionId },
      (() => {
        if (this.state.reportVersionId) {
          this.lastReportVersionId = reportVersionId;
          this.loadReportVersionFlag = this.state.reportVersionId;
          this.props.fetchReportVersionData(this.state.reportVersionId);
        }
        this.props.fetchCommentsFileList({
          reviewId: this.reviewId,
          reportId: (this.props.reportStatusData || {}).reportID,
        });
        if (cb) cb();
      }).bind(this)
    );
    this.toggleComments(false);
  }

  loadReportComparingVersion(_reportId, reportVersionId, cb) {
    let _self = this;
    this.props.fetchReportVersionData(reportVersionId, versionData => {
      let item = this.reportItem;
      if (item && versionData.data) {
        item.showComparingVersion(versionData.data);
      }
      _self.toggleComments(false);
      if (cb) cb();
    });
  }

  toggleComments(state) {
    // destroy unattached availabletInstances
    window.commentsPluginInterface
      .availabletInstances()
      .forEach(function(instance) {
        var node = instance.node();
        var body = window.document.body;
        var found = false;
        while (node) {
          if (node === body) found = true;
          node = node.parentElement;
        }
        if (!found) instance.destroy();
      });
    let status;

    if (typeof state === 'undefined') {
      status = window.document.querySelector('#label-toggle-comments').checked;
    } else if (status === null) {
      status = !!window.commentsPluginInterface
        .availabletInstances()
        .filter(function(comment) {
          return comment.messageListVisibility();
        }).length;

      window.document.querySelector(
        '#label-toggle-comments'
      ).checked = !!status;
    } else {
      if (document.getElementById('label-toggle-comments')) {
        window.document.querySelector(
          '#label-toggle-comments'
        ).checked = !!status;
      }
    }

    if (status) {
      window.commentsPluginInterface
        .availabletInstances()
        .forEach(function(comment) {
          comment.hideMessages();
        });
    } else {
      window.commentsPluginInterface
        .availabletInstances()
        .forEach(function(comment) {
          comment.showMessages();
        });
    }
  }

  //   addComment() {
  //     let selection = window.getSelection();
  //     console.log(selection);
  //     let checkContainer = function(node) {
  //       var needed = window.document.querySelector(
  //         '.active-inline-chats--comments-container-context'
  //       );
  //       if (!needed) return false;
  //
  //       let n = node;
  //       while (n.parentNode) {
  //         if (
  //           ((n.className || '') + '').indexOf(
  //             'active-inline-chats--comments-container-context'
  //           ) !== -1
  //         ) {
  //           return true;
  //         }
  //         n = n.parentNode;
  //       }
  //     };
  //     if ((selection.baseNode || {}).data !== (selection.extentNode || {}).data) {
  //       this.saveReport();
  //     setTimeout(() => {this.setState({...this.state, showAlert: true});}, 500);
  //
  // } else if (
  //       checkContainer(selection.anchorNode) &&
  //       checkContainer(selection.focusNode)
  //     ) {
  //       window.commentsPluginInterface.generateChatBox();
  //     }
  //   }

  componentDidMount() {
    let _self = this;
    _self.lastReportVersionId = null;
    _self.reviewId = window.location.pathname.replace(/.*\//, '');

    this.props.fetchReportStatusData(_self.reviewId);
    this.props.fetchUsers();
    this.props.fetchReviewDetail({ reviewId: _self.reviewId });
    // TODO » this is a fix, use fetchReviewDetail
    this.props.fetchReportList({
      userId: this.props.currentUser.oid,
      start: 0,
      rows: 1000,
    });

    this.props.fetchRasUrl({ reviewId: _self.reviewId });
    this.props.fetchDeadlineDates({ reviewId: _self.reviewId });
  }

  componentDidUpdate() {
    Array.prototype.slice
      .call(document.querySelectorAll('.timeline-group'))
      // eslint-disable-next-line array-callback-return
      .map(function(timelineNode) {
        var totalDays = 0;
        var totalWidth = 90;
        let points = Array.prototype.slice
          .call(timelineNode.querySelectorAll('section'))
          .map(function(node, index, arr) {
            var days = parseInt(node.getAttribute('data-days'), 10) || 1;
            if (index < arr.length - 1) totalDays += days;
            return {
              node: node,
              days: days,
            };
          });

        points.forEach(function(point, index, arr) {
          if (index < arr.length - 1)
            point.node.style.width =
              Math.floor(((totalWidth * point.days) / totalDays) * 100) / 100 +
              '%';
        });
      });
  }

  loadExistingReviews() {
    let reports = (this.props.reportStatusData || {}).reportVersions || [];
    const reportsIdsSorted = reports.sort(
      (d1, d2) => new Date(d2.cDate).getTime() - new Date(d1.cDate).getTime()
    );

    let reportListItems = reportsIdsSorted.map(item => {
      return (
        <li key={item.reportKey}>
          <a
            onClick={this.loadReportVersion.bind(
              this,
              this.state.reportId,
              item.reportKey,
              () => {},
              true
            )}
            href="#"
          >
            {(item.versionName !== null ? `${item.versionName},` : '') +
              ' ' +
              moment(item.cDate)
                .tz('America/New_York')
                .format('L LTS')}
          </a>
        </li>
      );
    });

    if (reportListItems.length === 0) {
      reportListItems = (
        <li>
          <span>
            <i>&nbsp;&nbsp;Empty list</i>
          </span>
        </li>
      );
    }

    return (
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-lg btn-primary dropdown-toggle active report-item--versions-button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Report Versions &nbsp;
          <span className="caret" />
        </button>
        <ul className="dropdown-menu existing-reports" role="menu">
          {reportListItems}
        </ul>
      </div>
    );
  }

  loadComparingVersions() {
    let reports = (this.props.reportStatusData || {}).reportVersions || [];
    const reportsIdsSorted = reports.sort(
      (d1, d2) => new Date(d2.cDate).getTime() - new Date(d1.cDate).getTime()
    );

    let reportListCompareItems = reportsIdsSorted
      .filter(
        (item => {
          if (
            (this.reportItem || {}).componentDefaultLoadtemplate ===
              'template' &&
            !this.reportItemForcedVersion
          )
            return true;
          return item.reportKey !== this.state.reportVersionId;
        }).bind(this)
      )
      .map(item => {
        return (
          <li key={item.reportKey}>
            <a
              onClick={this.loadReportComparingVersion.bind(
                this,
                this.state.reportId,
                item.reportKey,
                () => {}
              )}
              href="#"
            >
              {(item.versionName !== null ? `${item.versionName},` : '') +
                ' ' +
                item.cDate}
            </a>
          </li>
        );
      });

    if (reportListCompareItems.length === 0) {
      reportListCompareItems = (
        <li>
          <span>
            <i>&nbsp;&nbsp;Empty list</i>
          </span>
        </li>
      );
    }

    return (
      <div className="btn-group" role="group" style={{ display: 'none' }}>
        <button
          type="button"
          className="btn btn-lg btn-primary dropdown-toggle active"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Compare with Version &nbsp;
          <span className="caret" />
        </button>
        <ul className="dropdown-menu existing-reports" role="menu">
          {reportListCompareItems}
        </ul>
      </div>
    );
  }

  createReportForm() {
    let reviewTypeTemplate = this.props.reviewType;

    if (reviewTypeTemplate && (this.props.reportVersionData || {}).reviewID) {
      if (!this.templateRequested) {
        this.templateRequested = true;
        if (reviewTypeTemplate === 'FA-2') {
          this.props.reportNewFaTwoAction({
            reviewId: this.props.reportVersionData.reviewID,
          });
        }
        if (reviewTypeTemplate === 'FA2-CR') {
          this.props.newDraftReportAction({
            reviewId: this.props.reportVersionData.reviewID,
          });
        }
        if (reviewTypeTemplate === 'FA-1') {
          this.props.reportNewFaOneAction({
            reviewId: this.props.reportVersionData.reviewID,
          });
        }
        if (reviewTypeTemplate === 'RAN') {
          this.props.reportRanAction({
            reviewId: this.props.reportVersionData.reviewID,
          });
        }
        if (reviewTypeTemplate === 'Special') {
          if (this.props.reportVersionData.isNewForms) {
            this.props.newDraftReportAction({
              reviewId: this.props.reportVersionData.reviewID,
            });
          } else {
            this.props.reportSpecialAction({
              reviewId: this.props.reportVersionData.reviewID,
            });
          }
        }
        if (reviewTypeTemplate === 'AIAN-DEF') {
          this.props.reportAIANDEFAction({
            reviewId: this.props.reportVersionData.reviewID,
          });
        }
        if (reviewTypeTemplate === 'ERSEA') {
          this.props.reportERSEAAction({
            reviewId: this.props.reportVersionData.reviewID,
          });
        }
        if (reviewTypeTemplate === 'FA1-FR') {
          this.props.newDraftReportAction({
            reviewId: this.props.reportVersionData.reviewID,
          });
        }
        this.props.reportTemplateAction({
          reviewID: this.props.reviewId,
          reviewType: reviewTypeTemplate,
          templateType: 'NCMP',
        });

        this.props.reportTemplateAction({
          reviewID: this.props.reviewId,
          reviewType: reviewTypeTemplate,
          templateType: 'CMP',
        });

        this.props.reportFindingsAction({
          reviewID: this.props.reviewId,
        });

        this.props.fetchReportDraftTemplate({ reviewId: this.props.reviewId });
      }
    } else {
      this.templateRequested = false;
    }

    if (this.state.defaultTemplate !== null) return '';

    if (!(this.templateRequested && this.props.reportTemplate)) return '';

    if (!this.props.reportStatusData) return 'checking existing versions...';

    if (this.props.reportVersionData) {
      this.applyState('LOAD_REPORT_FROM_TEMPLATE_SKIP');
      return '';
    } else if (this.loadReportVersionFlag) {
      return 'loading existing version...';
    }

    if (
      this.state.defaultTemplatePurpose &&
      this.state.defaultTemplatePurpose.skipConfirm
    ) {
      this.applyState('LOAD_REPORT_FROM_TEMPLATE_CONFIRM');
      return;
    }

    return this.state.defaultTemplatePurpose ? (
      <div className="create-review-form-confirm">
        <div>
          <p
            dangerouslySetInnerHTML={{
              __html: this.state.defaultTemplatePurpose.confirmQuestion,
            }}
          />
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={(() => {
              this.applyState('LOAD_REPORT_FROM_TEMPLATE_CONFIRM');
            }).bind(this)}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-lg"
            onClick={(() => {
              this.applyState('LOAD_REPORT_FROM_TEMPLATE_CANCEL');
            }).bind(this)}
          >
            No
          </button>
        </div>
      </div>
    ) : (
      <div className="create-review-form" />
    );
  }

  reportShareConfigOHS() {
    if (!this.reportShare)
      return console.error('Report Share popup not found...');

    this.reportShare.reset({
      reportFile: true,
      reportLink:
        'http://ams.gov.sample/a/ams.com/doc/d/1KHvafaadfasdAmkkooiimPtVa8wkoloemnodaiilfoelooe.html',
    });
    this.reportShare.sharedGroup = 'OHS';
    this.reportShare.actionName('OHS');
    this.reportShare.show();
  }

  reportShareConfigOGC() {
    if (!this.reportShare)
      return console.error('Report Share popup not found...');

    this.reportShare.reset({
      reportFile: true,
      reportLink:
        'http://ams.gov.sample/a/ams.com/doc/d/1KHvafaadfasdAmkkooiimPtVa8wkoloemnodaiilfoelooe.html',
    });
    this.reportShare.sharedGroup = 'OGC';
    this.reportShare.actionName('OGC');
    this.reportShare.show();
  }

  reportShareConfigRO() {
    if (!this.reportShare)
      return console.error('Report Share popup not found...');

    this.reportShare.reset({
      reportFile: true,
      reportLink:
        'http://ams.gov.sample/a/ams.com/doc/d/1KHvafaadfasdAmkkooiimPtVa8wkoloemnodaiilfoelooe.html',
    });
    this.reportShare.sharedGroup = 'RO';
    this.reportShare.actionName('RO');
    this.reportShare.show();
  }

  reportShareConfigGrantee() {
    if (!this.reportShare)
      return console.error('Report Share popup not found...');

    this.reportShare.reset({
      reportLink:
        'http://ams.gov.sample/a/ams.com/doc/d/1KHvafaadfasdAmkkooiimPtVa8wkoloemnodaiilfoelooe.html',
      explain: 'Sharing report with grantee:',
    });
    this.reportShare.sharedGroup = 'Grantee';
    this.reportShare.actionName('Grantee');
    this.reportShare.show();
  }

  onReportShareSubmit(shareState, reportShare, previewOnly) {
    let inputReq = {
      reviewID: this.props.reviewId,
      reportID: this.props.reportVersionData.reportID,
      versionNumber: (this.props.reportVersionData || {}).versionNumber || null,
      reportKey: this.state.reportVersionId,
      message: shareState.message,
      sharedGroup: this.reportShare.sharedGroup,
      sharedBy: this.props.currentUser.email,
      sharedInternal: shareState.usersTo,
      sharedExternal: shareState.emailTo,
      includeComments: shareState.sendComments,
      sharedDate: new Date().toISOString(),
      reportLink: window.location.origin + '/add-comment',
      expirationDate: (date => {
        let i = b => {
          return (b < 10 ? '0' : '') + b;
        };
        return (
          i(date.getMonth() + 1) +
          '/' +
          i(date.getDate()) +
          '/' +
          date.getFullYear()
        );
      })(
        new Date(
          (reportShare.expirationDate
            ? reportShare.expirationDate.toISOString().replace(/T.*/, '') +
              ' 12:00:00'
            : '') || new Date()
        )
      ),
    };

    let action = this.props.reportShareAction;

    switch (shareState.actionName) {
      case 'OHS':
        console.info('TODO share OHS.. ');
        break;
      case 'OGC':
        console.info('TODO share OGC.. ');
        break;
      case 'RO':
        console.info('TODO share RO.. ');
        break;
      case 'Grantee':
        action = this.props.reportSharePDFAction;
        this.setState({ reportStatusState: 'Grantee' });
        inputReq = {
          reviewID: this.props.reviewId,
          reportID: this.props.reportVersionData.reportID,
          reportKey: this.state.reportVersionId,
          message: shareState.message,
          sharedGroup: this.reportShare.sharedGroup,
          granteeEmailList: shareState.granteeEmails,
          sharedBy: this.props.currentUser.email,
          reportLink: window.location.origin + '/add-comment',
        };
        console.info('TODO share Grantee.. ');
        break;
      default:
        console.error('Unknown share mode');
        break;
    }

    if (previewOnly === undefined || previewOnly === true) {
      let grantee =
        ((this.props.reportDraftTemplate || {}).granteeDetailsList || [])[0] ||
        null;
      this.reportItem.getStyledContent().then(content => {
        content = content.replace(
          // eslint-disable-next-line no-useless-escape
          /(\<(div|p|h2)[\s\n][^\>]*)data\-editable/,
          '$1'
        );
        this.setState({
          reportSharePreview: (
            <div className="report-item--share-container-wrapper ">
              {previewOnly === true || previewOnly === undefined ? (
                <button
                  className="btn btn-default share-close-preview"
                  onClick={() => {
                    this.setState({
                      reportSharePreview: null,
                    });
                  }}
                >
                  <span>
                    <i className="fa fa-times" />
                  </span>
                </button>
              ) : null}
              <div
                className={
                  'animated slideInRight report-item--share-container' +
                  (previewOnly === true ? ' wide-preview' : '')
                }
                dangerouslySetInnerHTML={{
                  __html:
                    (grantee
                      ? // eslint-disable-next-line no-multi-str
                        "<div class='report-shared--top-info'>\
                    <div>\
                      <b>Grantee Name: </b>" +
                        grantee.granteeName +
                        // eslint-disable-next-line no-multi-str
                        '\
                  <br />\
                  <b>Grantee ID: </b>' +
                        grantee.granteeId +
                        // eslint-disable-next-line no-multi-str
                        '\
                </div>\
              </div>'
                      : '') + content,
                }}
              />
              <div
                className="shared-message animated slideInDown"
                style={{ display: 'message' in shareState ? 'block' : 'none' }}
              >
                <p>{shareState.message}</p>
              </div>
              <div
                className="buttons animated slideInUp"
                style={{ display: previewOnly === true ? 'none' : '' }}
              >
                <button
                  onClick={() => {
                    action(inputReq);
                    this.reportShare.sharedGroup = null;
                    this.setState({
                      reportSharePreview: null,
                    });
                  }}
                  className="btn btn-primary"
                >
                  Send
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => {
                    this.setState({
                      reportSharePreview: null,
                    });
                  }}
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          ),
        });
      });
    } else if (previewOnly === false) {
      action(inputReq);
    }
  }

  launchRAS() {
    window.open(this.props.rasUrl.surveyKoboURL);
  }

  periodsUpdate(periods, _endDate, excludeDates, startDate) {
    var totalDays = 0;
    periods.forEach(period => {
      totalDays += period.days;
    });
    var dateToStr = date => {
      return new Date(date.valueOf() - date.getTimezoneOffset() * 1000 * 60)
        .toISOString()
        .replace(/T.*/, '');
    };
    var now = dateToStr(new Date());
    var date = new Date(startDate);
    // var decDate = function(date) {
    //   return new Date(date.valueOf() - 24 * 60 * 60 * 1000);
    // };
    var incDate = function(date) {
      return new Date(date.valueOf() + 24 * 60 * 60 * 1000);
    };
    var index = 0;

    while (index < periods.length) {
      let period = periods[index];
      period.daysList = period.daysList || [];
      period.holidays = period.holidays || [];
      let days = period.days;
      while (days > 0) {
        let day = dateToStr(date);

        if (
          excludeDates.indexOf(day) !== -1 ||
          [0, 6].indexOf(date.getDay()) !== -1
        ) {
          period.holidays.push(day);
          date = incDate(date);
        } else {
          period.daysList.push(day);
          date = incDate(date);
          days--;
        }
      }
      index++;
    }

    periods.forEach(period => {
      period.daysDone = period.daysList.filter(day => {
        return day <= now;
      }).length;
      period.progress = period.daysDone / period.days;
    });

    return periods;
  }

  showConfirmationAlert = () => {
    return (
      <AmsAlert
        show={this.state.showAlert}
        title="Invalid text selection"
        type="warning"
        showConfirm
        confirmButtonText="Ok"
        confirmButtonColor={'#1d3665'}
        text={`Please select a text segment you would like to add a comment to that is within same paragraph, title, table cell, or bullet item.`}
        onConfirm={() => {
          this.setState({ ...this.state, showAlert: false });
        }}
      />
    );
  };

  showReturntoFOMAlert() {
    return (
      <AmsAlert
        show={this.state.retFomAlrt}
        title="Please confirm submission"
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`You are about to send the report to Manager for additional information. Are you sure you want to continue?`}
        onConfirm={() => {
          this.props.fetchEmailReturnToFOM(
            {
              reviewId: this.reviewId,
            },
            result => {
              if (result instanceof Error) {
                alert('Error: ' + (result.message || 'unknown error'));
              } else {
                alert('Returned to Manager');
              }
            },
            this.setState({ retFomAlrt: false })
          );
        }}
        onCancel={() => {
          this.setState({ retFomAlrt: false });
        }}
      />
    );
  }

  showSubmittoQAAAlert() {
    return (
      <AmsAlert
        show={this.state.submitToQaaAlert}
        title="Please confirm submission"
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`You are about to submit the report to Analyst for report verification. Are you sure you want to continue?`}
        onConfirm={() => {
          this.props.fetchEmailNotifyQAARequest(
            {
              reviewId: this.reviewId,
            },
            result => {
              if (result instanceof Error) {
                alert('Error: ' + (result.message || 'unknown error'));
              } else {
                alert('Notify was submitted to QAA');
              }
            },
            this.setState({ submitToQaaAlert: false })
          );
        }}
        onCancel={() => {
          this.setState({ submitToQaaAlert: false });
        }}
      />
    );
  }
  showReturnToRLAlert() {
    return (
      <AmsAlert
        show={this.state.returnToRLAlert}
        title="Please confirm submission"
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`You are about to send the report to Lead for additional information. Are you sure you want to continue?`}
        onConfirm={() => {
          this.props.fetchEmailNotifyRLRequest(
            {
              reviewId: this.reviewId,
            },
            result => {
              if (result instanceof Error) {
                alert('Error: ' + (result.message || 'unknown error'));
              } else {
                alert('Notification was submitted to Lead');
              }
            },
            this.setState({ returnToRLAlert: false })
          );
        }}
        onCancel={() => {
          this.setState({ returnToRLAlert: false });
        }}
      />
    );
  }

  showSignAlert() {
    return (
      <AmsAlert
        show={this.state.signAlert}
        title="Please confirm submission"
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`You are about to sign the Report. Are you sure you want to continue?`}
        onConfirm={(() => {
          this.signReport();
          this.setState({ signAlert: false, reportStatusState: 'signed' });
        }).bind(this)}
        onCancel={() => {
          this.setState({ signAlert: false });
        }}
      />
    );
  }

  showFinalizeAlert() {
    return (
      <AmsAlert
        show={this.state.finalizeAlert}
        title="Please confirm submission"
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Continue"
        confirmButtonColor={'#DD6B55'}
        text={`You are about to Finalize the Report. Are you sure you want to continue?`}
        onConfirm={(() => {
          this.saveReport('finalized');
          this.setState({ finalizeAlert: false });
        }).bind(this)}
        onCancel={() => {
          this.setState({ finalizeAlert: false });
        }}
      />
    );
  }

  render() {
    let reportDashboardItem = this.reportDashboard();
    let reviewType = this.props.reviewType;

    if (
      this.props.reviewId &&
      !this.state.reportVersionId &&
      this.props.reportStatusData
    ) {
      if (((this.props.reportStatusData || {}).reportVersions || []).length) {
        var reportVersionId = this.props.reportStatusData.reportVersions.sort(
          function(va, vb) {
            let a = va.cDate;
            let b = vb.cDate;
            return a === b ? 0 : a > b ? -1 : 1;
          }
        )[0];

        if (reportVersionId && reportVersionId !== this.lastReportVersionId) {
          let _self = this;
          this.lastReportVersionId = reportVersionId;
          window.setTimeout(() => {
            _self.loadReportVersion(
              _self.props.reviewId,
              reportVersionId.reportKey,
              () => {}
            );
          }, 0);
        }
      }
    }

    let reportStatusMessage = null;
    if (this.props.reportVersionData) {
      switch (this.props.reportVersionData.versionStatus) {
        case null:
          reportStatusMessage = (
            <i className="review-id--state-saved">
              {' '}
              - create report from the drop down menu
            </i>
          );
          break;
        case 'draft':
          reportStatusMessage = (
            <i className="review-id--state-saved"> - existing report</i>
          );
          break;
        case 'finalized':
          reportStatusMessage = (
            <i className="review-id--state-saved">
              {' '}
              - existing report ready to be signed
            </i>
          );
          break;
        case 'signed':
          reportStatusMessage = (
            <i className="review-id--state-saved">
              {' '}
              - existing report finalized and ready to be shared with Grantee
            </i>
          );
          break;
        default:
          reportStatusMessage = null;
          break;
      }
    }

    return (
      <div className="reports-dashboard">
        {this.showConfirmationAlert()}
        {this.showReturntoFOMAlert()}
        {this.showSubmittoQAAAlert()}
        {this.showReturnToRLAlert()}
        {this.showFinalizeAlert()}
        {this.showSignAlert()}
        <div className="col-sm-12">
          <button
            className="generate-pdf-button btn btn-primary"
            style={{
              display: this.state.dashboardVisible ? 'block' : 'none',
            }}
            onClick={() =>
              this.generateReport(this.props.reportVersionData.versionStatus)
            }
          >
            Generate report
          </button>
        </div>
        {this.state.reportSharePreview || null}
        <div className="col-sm-12">
          <div className="reports-top-buttons">
            <h2 className="review-id">
              Review ID:
              <span>{this.props.reviewId}</span>
              {reportStatusMessage}
            </h2>
            {(() => {
              if (this.props.reportStatusData) {
                if (this.props.reportStatusData.approvalStatus === 'approved') {
                  return <span>Report was approved</span>;
                } else if (
                  this.props.reportStatusData.approvalStatus === 'rejected'
                ) {
                  return <span>Report was rejected</span>;
                }
              }
            }).bind(this)()}
            {(() => {
              if (this.props.reportStatusData && this.props.currentUser) {
                if (
                  this.state['reportStatusState'] === 'Grantee' ||
                  this.props.reportStatusData.reportStage === 'Shipped' ||
                  this.props.reportStatusData.reportStage === 'Grantee'
                ) {
                  return (
                    <div className="alert alert-success">
                      The Report is shipped to Grantee
                    </div>
                  );
                } else if (
                  this.state['reportStatusState'] === 'signed' ||
                  this.props.reportStatusData.reportStage === 'signed'
                ) {
                  return (
                    <div className="alert alert-success">
                      The Report is Signed Off
                    </div>
                  );
                } else if (
                  this.state['finalized'] === true ||
                  this.props.reportStatusData.reportStage === 'finalized'
                ) {
                  return (
                    <div className="alert alert-success">
                      The Report is finalized
                    </div>
                  );
                } else if (
                  (this.props.reportStatusData.assigneeName ||
                    this.props.reportStatusData.assignedTo) &&
                  this.props.reportStatusData.assigneeName !==
                    this.props.currentUser.fullName
                ) {
                  let name = '';
                  if ((this.props.users || []).length) {
                    name =
                      this.props.reportStatusData.assigneeName ||
                      (
                        this.props.users.find(
                          u => u.oid === this.props.reportStatusData.assignedTo
                        ) || {}
                      ).name;
                  }
                  return (
                    <h3 className="alert alert-danger">
                      The report is currently being worked on by assigned user{' '}
                      {name}
                    </h3>
                  );
                }
              }
              return null;
            }).bind(this)()}
            {((this.props.currentUser || {}).roles || []).indexOf(
              'FieldOperationsManager'
            ) === -1 ||
            ((this.props.currentUser || {}).roles || []).indexOf('AmsAdmi') !==
              -1 ||
            this.props.reviewType !== 'FA-2' ? (
              <div
                className="reports-top-right-buttons"
                style={{
                  display: this.state.dashboardVisible ? 'block' : 'none',
                }}
              >
                {this.state.reportVersionId ? (
                  <div className="btn-group action-share" role="group">
                    {this.props.faFlow === false &&
                    this.props.reviewType === 'FA-2' &&
                    (this.props.rasUrl || {}).surveyKoboURL ? (
                      <button
                        onClick={this.launchRAS}
                        className="btn btn-primary action-launch-ras"
                      >
                        Launch RAS
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={_ev => {
                        this.onReportShareSubmit({}, {}, true);
                      }}
                    >
                      Preview
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Share
                    </button>
                    <ul className="dropdown-menu" role="menu">
                      <li onClick={this.reportShareConfigOHS}>
                        <span>OHS</span>
                      </li>
                      {((this.props || {}).reportStatusData || {})
                        .reviewType === 'CLASS' ||
                      ((this.props || {}).reportStatusData || {}).reviewType ===
                        'AIAN-CLASS' ||
                      ((this.props || {}).reportStatusData || {}).reviewType ===
                        'PR-CLASS' ||
                      ((this.props || {}).reportStatusData || {}).reviewType ===
                        'VP-CLASS' ||
                      ((this.props || {}).reportStatusData || {}).reviewType ===
                        'CLASS-Video' ||
                      ((this.props || {}).reportStatusData || {}).reviewType ===
                        'AIAN CLASS Self-Observations' ||
                      (((this.props || {}).reportStatusData || {})
                        .reviewType === 'RAN' &&
                        ((this.props.currentUser || {}).roles || []).indexOf(
                          'RegionalProgramManager'
                        ) !== -1) ? null : (
                        <span>
                          <li onClick={this.reportShareConfigOGC}>
                            <span>OGC</span>
                          </li>
                          <li onClick={this.reportShareConfigRO}>
                            <span>RO</span>
                          </li>
                        </span>
                      )}
                      <li
                        onClick={this.reportShareConfigGrantee}
                        className="action-share--grantee"
                      >
                        <span>
                          Grantee
                          <i>document must be signed</i>
                        </span>
                      </li>
                    </ul>
                    <ReportShare
                      onInit={this.onReportShare}
                      onSubmit={this.onReportShareSubmit}
                      reviewId={this.reviewId}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : null}
            <hr />
          </div>

          {this.createReportForm()}

          <div
            className="reports-top-buttons"
            style={{ display: this.state.dashboardVisible ? 'block' : 'none' }}
          >
            {this.loadExistingReviews()}
            {this.loadComparingVersions()}
            {/*<button*/}
            {/*className="btn btn-lg btn-primary report-item--addcomment-button active-inline-chats--add-box"*/}
            {/*onClick={this.addComment}*/}
            {/*>*/}
            {/*Add Comment*/}
            {/*</button>*/}
            {((this.props.currentUser || {}).roles || []).indexOf('Analyst') !==
              -1 ||
            ((this.props.currentUser || {}).roles || []).indexOf(
              'TechnicalWriter'
            ) !== -1 ||
            ((this.props.currentUser || {}).roles || []).indexOf('Editor') !==
              -1 ||
            ((this.props.currentUser || {}).roles || []).indexOf('AmsAdmi') !==
              -1 ? (
              <button
                className="btn btn-lg btn-primary report-item--finalize-button"
                onClick={() => {
                  this.setState({ finalizeAlert: true });
                }}
                style={{ display: this.props.reviewId ? '' : 'none' }}
              >
                Finalize Report
              </button>
            ) : null}
            {((this.props.currentUser || {}).roles || []).indexOf('Analyst') !==
              -1 ||
            ((this.props.currentUser || {}).roles || []).indexOf(
              'TechnicalWriter'
            ) !== -1 ||
            ((this.props.currentUser || {}).roles || []).indexOf('Editor') !==
              -1 ||
            ((this.props.currentUser || {}).roles || []).indexOf('AmsAdmi') !==
              -1 ? (
              <button
                className="btn btn-lg btn-primary report-item--sign-button"
                onClick={() => {
                  this.setState({ signAlert: true });
                }}
                style={{ display: this.props.reviewId ? '' : 'none' }}
              >
                Sign
              </button>
            ) : null}
            &nbsp;
            {((this.reportItem || {}).currentData || {}).state === 'signed' ||
            ((this.reportItem || {}).currentData || {}).state === 'finalized' ||
            this.state.finalized ? null : (
              <span>
                {(reviewType === 'CLASS' ||
                  reviewType === 'Follow-up' ||
                  reviewType === 'FA-1' ||
                  reviewType === 'FA1-FR' ||
                  reviewType === 'Special' ||
                  reviewType === 'FA-2' ||
                  reviewType === 'FA2-CR' ||
                  reviewType === 'RAN' ||
                  reviewType === 'AIAN-CLASS' ||
                  reviewType === 'AIAN-DEF' ||
                  reviewType === 'ERSEA' ||
                  reviewType === 'PR-CLASS' ||
                  reviewType === 'VP-CLASS' ||
                  reviewType === 'CLASS-Video' ||
                  reviewType === 'AIAN CLASS Self-Observations') &&
                (((this.props.currentUser || {}).roles || []).indexOf(
                  'AmsAdmi'
                ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'FollowupLead'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'Analyst'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'TechnicalWriter'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'Editor'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'FieldOperationsManager'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'ReviewLead'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'FollowupLead'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'ProgramSpecialist'
                  ) !== -1 ||
                  (((this.props.currentUser || {}).roles || []).indexOf(
                    'RegionalProgramManager'
                  ) !== -1 &&
                    (reviewType === 'Special' ||
                      reviewType === 'RAN' ||
                      reviewType === 'AIAN-DEF' ||
                      reviewType === 'Follow-up' ||
                      reviewType === 'ERSEA'))) ? (
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      this.setState({ returnToRLAlert: true });
                    }}
                  >
                    Return To Lead
                  </button>
                ) : (
                  ''
                )}
                &nbsp;
                {(reviewType === 'CLASS' ||
                  reviewType === 'Follow-up' ||
                  reviewType === 'FA-1' ||
                  reviewType === 'FA1-FR' ||
                  reviewType === 'Special' ||
                  reviewType === 'FA-2' ||
                  reviewType === 'FA2-CR' ||
                  reviewType === 'RAN' ||
                  reviewType === 'AIAN-CLASS' ||
                  reviewType === 'AIAN-DEF' ||
                  reviewType === 'ERSEA' ||
                  reviewType === 'PR-CLASS' ||
                  reviewType === 'VP-CLASS' ||
                  reviewType === 'CLASS-Video' ||
                  reviewType === 'AIAN CLASS Self-Observations') &&
                (((this.props.currentUser || {}).roles || []).indexOf(
                  'AmsAdmi'
                ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'Analyst'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'TechnicalWriter'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'Editor'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'FollowupLead'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'ReviewLead'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'ProgramSpecialist'
                  ) !== -1) ? (
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      this.setState({ retFomAlrt: true });
                    }}
                  >
                    Return To Manager
                  </button>
                ) : (
                  ''
                )}
                &nbsp;
                {(reviewType === 'CLASS' ||
                  reviewType === 'Follow-up' ||
                  reviewType === 'FA-1' ||
                  reviewType === 'FA1-FR' ||
                  reviewType === 'Special' ||
                  reviewType === 'FA-2' ||
                  reviewType === 'FA2-CR' ||
                  reviewType === 'RAN' ||
                  reviewType === 'AIAN-CLASS' ||
                  reviewType === 'AIAN-DEF' ||
                  reviewType === 'ERSEA' ||
                  reviewType === 'PR-CLASS' ||
                  reviewType === 'VP-CLASS' ||
                  reviewType === 'CLASS-Video' ||
                  reviewType === 'AIAN CLASS Self-Observations') &&
                (((this.props.currentUser || {}).roles || []).indexOf(
                  'AmsAdmi'
                ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'FollowupLead'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'FieldOperationsManager'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'ReviewLead'
                  ) !== -1 ||
                  ((this.props.currentUser || {}).roles || []).indexOf(
                    'ProgramSpecialist'
                  ) !== -1 ||
                  (((this.props.currentUser || {}).roles || []).indexOf(
                    'RegionalProgramManager'
                  ) !== -1 &&
                    (reviewType === 'Special' ||
                      reviewType === 'RAN' ||
                      reviewType === 'AIAN-DEF' ||
                      reviewType === 'Follow-up' ||
                      reviewType === 'ERSEA'))) ? (
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      this.setState({ submitToQaaAlert: true });
                    }}
                  >
                    Submit To QAA
                  </button>
                ) : (
                  ''
                )}
              </span>
            )}
            &nbsp;
          </div>
          <div className="reports-toolbox-container" />
          {reportDashboardItem}
        </div>
      </div>
    );
  }
}
Reports.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user,
    users: state.users.users,
    isAuthenticated: state.auth.isAuthenticated,
    reports: state.report.reports,
    reportVersionData: state.report.reportVersionData,
    reportStatusData: state.report.reportStatusData,
    reportSavedResult: state.report.reportSavedResult,
    reportSharedResult: state.report.reportSharedResult,
    reportSharedPDFResult: state.report.reportSharedPDFResult,
    reviewList: state.review.reviewList,
    commentFileList: state.report.commentFileList,
    reportTemplate: state.report.reportTemplate,
    reviewTemplateData: state.report.reviewTemplateData,
    reportFindings: state.report.reportFindings,
    reportDraftTemplate: state.report.reportDraftTemplate,
    commentFileContents: state.report.commentFileContents,
    rasUrl: state.survey.rasUrl,
    fetchedDeadlineDates: state.report.fetchedDeadlineDates,
    fetchedEmailNotifyRL: state.review.fetchedEmailNotifyRL,
    fetchedEmailNotifyQC: state.review.fetchedEmailNotifyQC,
    fetchedEmailNotifyFOM: state.review.fetchedEmailNotifyFOM,
    reviewDraftReport: state.review.reviewDraftReport,
    reviewType: state.review.selectedReview.reviewType,
    faFlow: state.review.selectedReview.newFAFlowFlag,
  };
};

export default connect(mapStateToProps, {
  fetchReportVersionData,
  fetchReportStatusData,
  reportShareAction,
  reportSharePDFAction,
  reportSaveAction,
  reportTemplateAction,
  reportFindingsAction,
  fetchReportList,
  fetchUsers,
  fetchReportDraftTemplate,
  fetchRasUrl,
  fetchCommentsFileList,
  fetchFileContents,
  fetchDeadlineDates,
  fetchEmailNotifyRLRequest,
  fetchEmailNotifyQAARequest,
  fetchEmailNotifyFOMRequest,
  fetchReviewDetail,
  fetchEmailReturnToFOM,
  reportNewFaTwoAction,
  newDraftReportAction,
  reportNewFaOneAction,
  reportSpecialAction,
  reportAIANDEFAction,
  reportERSEAAction,
  generateReportAction,
  reportRanAction,
  surveyReportSaveAction,
})(Reports);
