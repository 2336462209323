import axios from 'axios';

import {
  USERS_FETCHED,
  USERS_INTERNAL_FETCHED,
  USERS_INTERNAL_TEAM_FETCHED,
  ADD_ERROR,
  REMOVE_ERROR,
  USERS_FILTERED,
  USERS_FILTER_CLEARED,
  REGIONAL_USERS_FETCHED,
  FOM_USERS_FETCHED,
  REPORT_COMMENT_USERS,
} from '../redux/types';

export function getUsers(users) {
  return {
    type: USERS_FETCHED,
    users,
  };
}

export function getInternalUsers(internalUsers) {
  return {
    type: USERS_INTERNAL_FETCHED,
    internalUsers,
  };
}

export function getInternalTeamUsers(internalTeamUsers) {
  return {
    type: USERS_INTERNAL_TEAM_FETCHED,
    internalTeamUsers,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export const usersFiltered = (users, userType) => ({
  type: USERS_FILTERED,
  users,
  userType: userType === '' ? 'shadowReviewer' : userType,
});

export const usersFilterCleared = () => ({
  type: USERS_FILTER_CLEARED,
});

export function getFOMUsers(fomUsers) {
  return {
    type: FOM_USERS_FETCHED,
    fomUsers,
  };
}

export const reportCommentUsersFetched = payload => {
  return { type: REPORT_COMMENT_USERS, payload };
};

export const fetchInternalUsers = action => dispatch =>
  axios
    .post(`/report/internalUsers`, { reviewType: action.reviewType })
    .then(response => dispatch(getInternalUsers(response.data)));

export const fetchInternalTeamUsers = action => dispatch =>
  axios
    .post(`/report/internalTeamUsers`, {
      filters: {
        reviewType: action.reviewType,
        reviewId: action.reviewId,
      },
    })
    .then(response => dispatch(getInternalTeamUsers(response.data)));

export function fetchUsers() {
  return dispatch => {
    axios
      .post(`/users`)
      .then(response => {
        dispatch(getUsers(response.data));
      })
      .catch(error => {
        dispatch(setError(error));
      });
  };
}

export const filterUsers = (query, role = '') => dispatch =>
  axios
    .post(`/users/filter/${query}/${role}`)
    .then(response => dispatch(usersFiltered(response.data, role)));

export const fetchAllUsers = (userType, options) => dispatch => {
  if (options.startDate && options.endDate) {
    return axios
      .post(`/users/all`, {
        startDate: options.startDate,
        endDate: options.endDate,
      })
      .then(response => {
        if (response.data) {
          const data = response.data.filter(e => {
            return e.status === '';
          });
          return dispatch(usersFiltered(data, userType));
        }
      });
  }

  return axios
    .post(`/users/all`)
    .then(response => dispatch(usersFiltered(response.data, userType)));
};

export const fetchedRegionalUsers = payload => ({
  type: REGIONAL_USERS_FETCHED,
  payload,
});

export const fetchRegionalUsers = reqbody => dispatch =>
  axios
    .post('/users/regional-users', reqbody)
    .then(response => dispatch(fetchedRegionalUsers(response.data)))
    .catch(err => {});

export const fetchFOMUsers = request => dispatch =>
  axios
    .post(`/users/fetch-fom-users`)
    .then(response => dispatch(getFOMUsers(response.data)))
    .catch(error => dispatch(setError(error)));

export const reportCommentUsers = cb => dispatch =>
  axios
    .post('/users', { withCredentials: true })
    .then(() => dispatch(reportCommentUsersFetched({ userAuth: true })) && cb())
    .catch(
      () => dispatch(reportCommentUsersFetched({ userAuth: false })) && cb()
    );
