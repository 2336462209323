import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import styles.
import './assets/style.css';

// Import actions.
import { selectTab } from '../../actions/adminActions';

// Import components.
import LookupList from './Parameters/LookupList';

class ParametersAdminPage extends Component {
  componentWillMount = () => {
    this.props.selectTab({
      key: 5,
      pageTitle: 'Parameters',
    });
  };
  render() {
    return (
      <div>
        <LookupList />
      </div>
    );
  }
}

ParametersAdminPage.propTypes = {};

export default connect(
  null,
  { selectTab }
)(ParametersAdminPage);
