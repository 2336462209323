import React from 'react';
import { Card } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { determineFindingStatus, findingCardClasses } from './FindingsUtils';

const FindingsStandardCard = props => {
  const { citation } = props;
  const { selectedReview } = useSelector(state => state.review);

  if (
    !selectedReview ||
    !selectedReview.grantees ||
    selectedReview.grantees.length === 0
  ) {
    return null;
  }

  const boldLabelStyle = { fontFamily: 'PublicSans-Bold' };

  const associatedCitationDefinition =
    citation && citation.associatedCitationDefinition;

  return (
    <Card
      className={findingCardClasses(
        'findings-card',
        determineFindingStatus(selectedReview, citation)
      )}
    >
      <Card.Content
        style={{ backgroundColor: '#E9E9E9', fontSize: '18px' }}
        header="Standard"
      />
      <div style={{ margin: '30px 10px' }}>{associatedCitationDefinition}</div>
    </Card>
  );
};

export default FindingsStandardCard;
