import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';

// Import actions.
import { has, isEmpty } from 'lodash';
import {
  clearFA1Forms,
  fa1GuideSelected,
  fa1PerformanceMeasureSelected,
  fetchFA1FormDetail,
  fetchFA1FormSchema,
  fetchFA1Submission,
} from '../../../actions/fa1Actions';
import { fetchNotes } from '../../../actions/noteActions';
import { formatUserNotes, generateTagFilter } from '../../Notes/util.js';
import { isEqual } from 'lodash';

import SlidingContainer from '../../../utils/layout/SlidingContainer';
import NotesContainer from '../../Notes';
import GranteeInfo from './FA1GranteeInfo';
import FA1Guides from './FA1Guides';
import FA1PerformanceMeasures from './FA1PerformanceMeasures';
import FA1FRPerformanceMeasures from '../FA1FRSurvey/FA1FRPerformanceMeasures';

import { fetchReviewOCISummary } from '../../../actions/fy19/reviewActions';
import { fetchReviewDetail } from '../../../actions/reviewActions';
import { postFormSubmission } from '../../../actions/surveyActions';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';
import FA1FindingsOutsideProtocol from './FA1FindingsOutsideProtocol';
import FA1Form from './FA1Form';
import FA1FRFindingsOutsideProtocol from '../FA1FRSurvey/FindingOutsideProtocol';

export default function FA1Survey(props) {
  const dispatch = useDispatch();
  const {
    reviewType,
    selectedGuide,
    selectedPerformanceMeasure,
    isReportFinalized,
    reviewId,
    submission,
    selectedCenter,
    selectedClassroom,
  } = useSelector(state => state.forms.fa1);
  const { notes } = useSelector(state => state.note);
  const { selectedReview } = useSelector(state => state.review);
  const [tags, setTags] = useState({});
  const [surveyNotes, setSurveyNotes] = useState([]);
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [showMovingAlert, setShowMovingAlert] = useState(false);
  const [movingTo, setMovingTo] = useState({ pm: '', guide: '' });

  const amsTableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [amsFormId, setAmsFormId] = useState('');
  const [selectedPM, setSelectedPM] = useState({});
  const [originalData, setOriginalData] = useState({});

  const [title, setTitle] = useState('');

  // useEffect to set title for different review types
  useEffect(() => {
    if (reviewType) {
      const title = reviewType && reviewType === 'FA1-FR' ? 'FA1-FR' : 'FA-1';
      setTitle(title);
    }
  }, [reviewType]);

  // useEffect to fetch the form details and selected review
  useEffect(() => {
    let id = props.params && props.params.reviewId && props.params.reviewId;
    if (id) {
      if (!has(selectedReview, 'id') || selectedReview.id === '') {
        dispatch(fetchReviewDetail({ reviewId: id }))
          .then(() => {
            dispatch(
              fetchFA1FormDetail({
                reviewId: id,
                reviewType: selectedReview.reviewType,
              })
            );
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        dispatch(
          fetchFA1FormDetail({
            reviewId: id,
            reviewType: selectedReview.reviewType,
          })
        );
      }
    }
    return () => {
      dispatch(clearFA1Forms());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to run preselect on pm and guide
  useEffect(() => {
    if (reviewId) preselect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId, JSON.stringify(props.location.query || '')]);

  // useEffect to construct tag options for notes
  useEffect(() => {
    if (
      notes &&
      !isEmpty(selectedGuide) &&
      !isEmpty(selectedPerformanceMeasure)
    ) {
      const pmOptions =
        selectedGuide.performanceMeasures.map(pm => {
          return {
            key: pm.name,
            text: pm.name,
            value: pm.name,
          };
        }) || [];
      setSurveyNotes(formatUserNotes(notes));
      setTags({
        type: 'survey_collection',
        reviewId,
        guide: selectedGuide.name,
        performanceMeasure: selectedPerformanceMeasure.name,
      });
      setOptions(
        generateTagFilter([
          ...[
            { key: 'review', value: '', text: 'All Notes for this Review' },
            { key: 'guide', value: 'guide', text: 'All Notes for this Guide' },
            { key: 'divider', value: 'divider', text: '' },
          ],
          ...pmOptions,
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  // useEffect to fetch notes when the selected guide or pm changes
  useEffect(() => {
    if (selectedGuide && selectedPerformanceMeasure) {
      let id = props.params && props.params.reviewId && props.params.reviewId;
      const body = {
        filters: {
          tags: {
            reviewId: id,
          },
        },
      };
      dispatch(fetchNotes(body));
      setSelectedPM(selectedPerformanceMeasure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuide, selectedPerformanceMeasure]);

  // useEffect to fetch form schema and oci summary when the pm changes
  useEffect(() => {
    if (
      selectedPM &&
      selectedPM.name &&
      selectedPM.name === 'Content Area Analysis'
    ) {
      dispatch(
        fetchReviewOCISummary({
          reviewId: reviewId,
          reviewType: 'FA-1',
        })
      ).then(() => fetchFormSchema());
    } else {
      fetchFormSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedPM.name)]);

  // useEffect to fetch form schema when selected pm changes
  useEffect(() => {
    if (selectedPM && !selectedPM.formSchema) fetchFormSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedPM.formSchema)]);

  /**
   * Fetch form survey submission
   * @param {string} amsSubmissionId
   */
  const fetchSurveyData = amsSubmissionId => {
    if (amsSubmissionId)
      dispatch(
        fetchFA1Submission({
          filters: {
            amsSubmissionId: amsSubmissionId,
          },
        })
      ).then(response => {
        setLoading(false);
        if (response.submission) {
          setFormData(response.submission.data.surveyData);
          setOriginalData(response.submission.data.surveyData);
        }
      });
    else {
      setLoading(false);
      setFormData({});
    }
  };

  /**
   * Fetch form schema
   */
  const fetchFormSchema = () => {
    const { forms } = selectedPerformanceMeasure;

    setLoading(true);

    let amsFormId = forms && forms[0] && forms[0].amsFormId;
    let amsSubmissionId = forms && forms[0] && forms[0].amsSubmissionId;
    if (amsFormId) {
      setAmsFormId(amsFormId);
      dispatch(fetchFA1FormSchema({ filters: { amsFormId } })).then(
        response => {
          if (response) {
            fetchSurveyData(amsSubmissionId);
          }
        }
      );
    } else {
      // TO DO: Display something when form id is not present
      setLoading(false);
    }
  };

  /**
   * Preselect the guide and pm
   */
  const preselect = () => {
    // Extract query strings if passed and use them to preselect Guide and PM.
    const guideName = !isEmpty(props.location.query.g)
      ? decodeURIComponent(props.location.query.g)
      : '';
    const performanceMeasure = !isEmpty(props.location.query.pm)
      ? decodeURIComponent(props.location.query.pm)
      : '';
    dispatch(fa1GuideSelected(guideName));
    dispatch(fa1PerformanceMeasureSelected(performanceMeasure));
  };

  /**
   * Set the guide and pm on redux store
   * @param {Object} guide
   */
  const changeGuide = guide => {
    setLoading(true);
    if (dataHasChanged && !showMovingAlert && checkDifference()) {
      setMovingTo({ guide: guide });
      setShowMovingAlert(true);
      return;
    }
    setFormData({});
    dispatch(fetchFA1FormDetail({ reviewId, reviewType })).then(() => {
      dispatch(fa1GuideSelected(guide));
      dispatch(fa1PerformanceMeasureSelected(guide.performanceMeasures[0]));
      setLoading(false);
    });
    props.router.push(
      `${props.location.pathname}?g=${encodeURIComponent(
        guide.name
      )}&pm=${encodeURIComponent(guide.performanceMeasures[0].name)}`
    );
  };

  const checkDifference = () => {
    if (isEqual({}, formData) || isEqual(formData, originalData)) {
      return false;
    }
    return true;
  };

  /**
   * Set the pm and guide on redux store
   * @param {Object} performanceMeasure
   * @param {Object} selectedGuide
   * @returns {void}
   */
  const changePerformanceMeasure = (performanceMeasure, selectedGuide) => {
    setLoading(true);
    if (dataHasChanged && !showMovingAlert && checkDifference()) {
      setMovingTo({ pm: performanceMeasure, guide: selectedGuide });
      setShowMovingAlert(true);
      return;
    }
    setFormData({});
    dispatch(fetchFA1FormDetail({ reviewId, reviewType })).then(() => {
      dispatch(fa1GuideSelected(selectedGuide));
      dispatch(fa1PerformanceMeasureSelected(performanceMeasure));
      setLoading(false);
    });
    props.router.push(
      `${props.location.pathname}?g=${encodeURIComponent(
        selectedGuide.name
      )}&pm=${encodeURIComponent(performanceMeasure.name)}`
    );
  };

  /**
   * Set the form data changes for submission
   * @param {Object} formData
   * @returns {void}
   */
  const handleDataChange = ({ formData }) => {
    if (Object.values(formData).every(v => v === undefined)) {
      setFormData({});
      return;
    }
    setFormData(formData);
    setDataHasChanged(true);
  };

  /**
   * Keep track of the form data changes and cancel moving to another page
   * @param {Object} formData
   */
  const handleCancelChange = formData => {
    setFormData(formData);
    setDataHasChanged(false);
  };

  /**
   * Display the moving alert modal when the data has changed
   * @returns {void}
   */
  const renderMovingAlert = () => {
    const { surveyStatus } = submission;
    return (
      <AmsAlert
        show={showMovingAlert}
        title="Are you sure you want to move away from this form"
        text={`You selected another page, are you sure you want to leave the current page?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={
          surveyStatus && surveyStatus.toLowerCase() === 'submitted'
            ? 'Submit and continue'
            : 'Save draft and continue'
        }
        cancelButtonText={'Continue without saving'}
        showConfirm
        showCancelButton
        showThirdButton
        thirdButtonText="Cancel"
        onThirdButtonAction={() => {
          setShowMovingAlert(false);
          setLoading(false);
        }}
        onCancel={() => {
          if (movingTo.pm)
            changePerformanceMeasure(movingTo.pm, movingTo.guide);
          else changeGuide(movingTo.guide);
          setShowMovingAlert(false);
          setDataHasChanged(false);
        }}
        onConfirm={() => {
          setShowMovingAlert(false);
          handleSubmit();
        }}
      />
    );
  };

  /**
   * Submit the form data
   * @returns {void}
   */
  const handleSubmit = () => {
    const { forms, hasCenters, centers } = selectedPerformanceMeasure;
    let amsFormId = '';
    if (hasCenters) {
      if (centers && centers[0].forms) {
        amsFormId =
          centers &&
          centers[0] &&
          centers[0].forms &&
          centers[0].forms[0].amsFormId;
      } else {
        amsFormId =
          centers &&
          centers[0] &&
          centers[0].classrooms &&
          centers[0].classrooms[0].forms &&
          centers[0].classrooms[0].forms[0].amsFormId;
      }
    } else amsFormId = forms && forms[0] && forms[0].amsFormId;

    if (!amsFormId || !reviewId) return;

    let requestObject = { reviewId, amsFormId, surveyData: formData };

    if (formData.topQuestion === false) {
      requestObject = {
        reviewId,
        amsFormId,
        surveyData: { topQuestion: false },
      };
    }

    if (submission.amsSubmissionId)
      requestObject.amsSubmissionId = submission.amsSubmissionId;

    if (selectedCenter) requestObject.centerName = selectedCenter;

    if (selectedClassroom) requestObject.classSampleId = selectedClassroom;

    // draft
    if (
      isEmpty(submission) ||
      (submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'draft')
    ) {
      dispatch(
        postFormSubmission({
          data: { ...requestObject, submissionStatus: 'Draft' },
        })
      )
        .then(() => {
          dataHasChangedSwitch(false);
          dispatch(fetchFA1FormDetail({ reviewId, reviewType })).then(() => {
            if (movingTo.pm)
              changePerformanceMeasure(movingTo.pm, movingTo.guide);
            else changeGuide(movingTo.guide);
            setLoading(false);
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      // update
      if (
        submission.surveyStatus &&
        submission.surveyStatus.toLowerCase() === 'submitted'
      ) {
        dispatch(
          postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
        )
          .then(() => {
            dataHasChangedSwitch(false);
            dispatch(fetchFA1FormDetail({ reviewId, reviewType })).then(() => {
              if (movingTo.pm)
                changePerformanceMeasure(movingTo.pm, movingTo.guide);
              else changeGuide(movingTo.guide);
              setLoading(false);
            });
          })
          .catch(error => {
            console.log(error);
          });
        return; // End of update exit.
      }
    }
  };

  /**
   * Render the version table for the submissions
   * @returns {void}
   */
  const renderVersionHistoryTable = () => {
    const data = submission.versionHistory.sort((a, b) =>
      AmsDateFormatters.getMoment(b.modifiedDate).diff(
        AmsDateFormatters.getMoment(a.modifiedDate)
      )
    );

    const linkVersion = cell => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const amsSubmissionId = submission.amsSubmissionId;
            const version = cell;

            // Fetch specific version.
            dispatch(
              fetchFA1Submission({
                filters: { amsSubmissionId, version },
              })
            ).then(response => {
              if (response.submission)
                setFormData(response.submission.data.surveyData);
            });
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = cell => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      <AmsTable
        remote={false}
        basic
        total={data.length}
        columns={columns}
        keyField="version"
        ref={amsTableRef}
        data={data}
      />
    );
  };

  // Switch to control dataHasChanged state
  const dataHasChangedSwitch = value => {
    setDataHasChanged(value);
  };

  return (
    <SlidingContainer
      calendar
      granteeStatusBanner={true}
      granteeStatusData={{
        type: 'review',
      }}
      title={`${title} Survey for Review ID ${reviewId}`}
      actionButtons={
        <NotesContainer
          tags={tags && tags}
          defaultFilter={'performanceMeasure'}
          filterOptions={options && options}
          list={surveyNotes && surveyNotes}
          enabled={!isReportFinalized && surveyNotes}
          type={'Notes'}
          reloadData={() => {
            const body = {
              filters: {
                tags: {
                  reviewId,
                },
              },
            };
            dispatch(fetchNotes(body));
          }}
        />
      }
    >
      {dataHasChanged && renderMovingAlert()}
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>{<GranteeInfo />}</Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <FA1Guides {...props} changeGuide={changeGuide} />
            {reviewType === 'FA1-FR' ? (
              <FA1FRPerformanceMeasures
                changePerformanceMeasure={changePerformanceMeasure}
              />
            ) : (
              <FA1PerformanceMeasures
                changePerformanceMeasure={changePerformanceMeasure}
              />
            )}
          </Grid.Column>

          <Grid.Column stretched width={12}>
            {!isEmpty(selectedPerformanceMeasure) &&
            selectedPerformanceMeasure.name ===
              'Findings Outside the Protocol' &&
            selectedReview.fiscalYear >= 2020 ? (
              reviewType === 'FA1-FR' ? (
                <FA1FRFindingsOutsideProtocol
                  formData={formData}
                  handleDataChange={handleDataChange}
                  dataHasChangedSwitch={dataHasChangedSwitch}
                  handleCancelChange={handleCancelChange}
                />
              ) : (
                <FA1FindingsOutsideProtocol
                  formData={formData}
                  handleDataChange={handleDataChange}
                  dataHasChangedSwitch={dataHasChangedSwitch}
                  handleCancelChange={handleCancelChange}
                />
              )
            ) : (
              <Segment basic>
                <FA1Form
                  handleDataChange={handleDataChange}
                  formData={formData}
                  dataHasChangedSwitch={dataHasChangedSwitch}
                  handleCancelChange={handleCancelChange}
                  amsFormId={amsFormId}
                />
              </Segment>
            )}
            {submission &&
              submission.versionHistory &&
              renderVersionHistoryTable()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </SlidingContainer>
  );
}
