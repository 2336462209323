import React from 'react';

function OrderedListField({ schema }) {
  const renderList = () => {
    if (schema && schema.enum && schema.enum.length > 0) {
      return schema.enum.map(e => {
        return <li>{e}</li>;
      });
    }
  };
  return <ol>{renderList()}</ol>;
}

export default OrderedListField;
