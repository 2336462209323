import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import AmsAlert from '../../../utils/AmsAlert';
import AmsTable from '../../../utils/AmsTable';

import _ from 'lodash';

// Import actions.
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

// Import settings.
import { acl } from '../../../config';

// Import Components.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AmsModal from '../../../utils/AmsModal';
import enforceRole from '../../../utils/EnforceRole';

const skillsCategory = [
  {
    key: 'contentAreaSkills',
    text: 'Content Area Skills',
    value: 'contentAreaSkills',
  },
  { key: 'languages', text: 'Language Skills', value: 'languages' },
  { key: 'otherSkills', text: 'Other Skills', value: 'otherSkills' },
];
class Skills extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    errors: {},
    selectedCategory: '',
    selectedSkill: '',
    effectiveDate: null,
    expirationDate: null,
    showEditDateModal: false,
    showModal: false,
    editedRow: {},
    editedCell: {},
    editErrors: [],
    data: {
      oid: '',
      userSkill: [],
    },
    showDeleteConfirmationAlert: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.profile.oid !== state.data.oid) {
      const { profileTab } = props;

      const { oid, userSkill } = props.profile;

      if (profileTab !== 'skills') {
        this.cancelEdit();
      }

      return {
        data: {
          oid,
          userSkill,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      let { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};
    return errors;
  };

  handleCategorySelections = (e, { name, value }) => {
    this.setState(
      {
        selectedSkill: '',
        [name]: '',
      },
      () => this.setState({ [name]: value })
    );
  };

  handleSkillSelection = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  addSkill = e => {
    e.preventDefault();

    this.setState({
      showModal: true,
      skillAdd: true,
      modalTitle: 'Add Skill',
    });
  };

  closeEditModal = () => {
    this.setState({
      showModal: false,
      showEditDateModal: false,
      selectedCategory: '',
      selectedSkill: '',
      editedCell: {},
      editedRow: {},
    });
  };

  expireSkill = (cell, row) => {
    const { data } = this.state;
    const newData = _.cloneDeep(data);
    let expiredSkill = _.find(newData.userSkill, {
      skillUuid: row.skillUuid,
    });
    let index = _.indexOf(newData.userSkill, expiredSkill);
    newData.userSkill[index][
      'expirationTime'
    ] = AmsDateFormatters.getMoment().format();
    this.setState({ data: newData });
  };

  setSkillAddition = () => {
    const {
      selectedSkill,
      selectedCategory,
      data,
      effectiveDate,
      expirationDate,
    } = this.state;
    let category = '';
    if (selectedCategory === 'contentAreaSkills') category = 'Content Area';
    else if (selectedCategory === 'languages') category = 'Language';
    else if (selectedCategory === 'otherSkills') category = 'Other';

    let newSkill = {
      skillUuid: uuidv4(),
      category: category,
      skill: selectedSkill,
      effectiveTime: effectiveDate
        ? effectiveDate.format()
        : AmsDateFormatters.getMoment().format(),
      expirationTime: expirationDate
        ? expirationDate.endOf('day').format()
        : null,
      isDeleted: false,
    };
    let newData = _.cloneDeep(data);
    newData.userSkill.push(newSkill);
    this.setState({ data: newData, test: 'hi' }, () => {
      this.props
        .updateUserProfile(this.state.data)
        .then(() => {
          this.setState({
            showSuccess: true,
            loading: false,
            showModal: false,
            category: '',
            skill: '',
            effectiveDate: null,
            expirationDate: null,
            selectedCategory: '',
            selectedSkill: '',
          });
        })
        .catch(err => {
          this.setState({
            showSuccess: false,
            loading: false,
            errors: {
              ...this.state.errors,
              profile: err.response.data.message,
            },
          });
        });
    });
  };

  setDateEditChanges = () => {
    const { editedRow, data, effectiveDate, expirationDate } = this.state;
    const errors = {};
    if (editedRow) {
      const newData = _.cloneDeep(data);
      let editedSkill = _.find(newData.userSkill, {
        skillUuid: editedRow && editedRow.skillUuid,
      });

      let index = _.indexOf(newData.userSkill, editedSkill);

      newData.userSkill[index]['effectiveTime'] = effectiveDate
        ? effectiveDate.format()
        : editedRow.effectiveTime;
      newData.userSkill[index]['expirationTime'] = expirationDate
        ? expirationDate.endOf('day').format()
        : editedRow.expirationTime;

      if (
        newData.userSkill[index]['effectiveTime'] &&
        newData.userSkill[index]['effectiveTime'] <
          newData.userSkill[index]['expirationTime']
      ) {
        this.setState({ data: newData }, () => {
          this.props
            .updateUserProfile(this.state.data)
            .then(() =>
              this.setState({
                showSuccess: true,
                loading: false,
                showModal: false,
                category: '',
                skill: '',
                effectiveDate: null,
                expirationDate: null,
                showEditDateModal: false,
                editedRow: {},
                editedCell: {},
                editErrors: [],
              })
            )
            .catch(err => {
              this.setState({
                showSuccess: false,
                loading: false,
                editErrors: [
                  ...this.state.errors,
                  (err.response || { data: Error('unknown error') }).data,
                ],
              });
            });
        });
      } else {
        this.setState({
          editErrors: [
            ...this.state.errors,
            { message: 'Please enter the correct effective/expiration time' },
          ],
        });
      }
    } else return;
  };

  showSkipErrors() {
    const { editErrors } = this.state;

    if (editErrors && editErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {editErrors.map((errorObject, index) => (
              <li key={index}>{errorObject.message}</li>
            ))}
          </ul>
        </Alert>
      );
  }

  showAddSkillModal = () => {
    const {
      selectedCategory,
      showModal,
      selectedSkill,
      loading,
      effectiveDate,
      expirationDate,
    } = this.state;
    return (
      <AmsModal
        open={showModal}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="education-modal ams-semantic-modal-fix"
      >
        <Modal.Header>Add Skill</Modal.Header>
        <Modal.Content>
          <Form loading={loading}>
            <Form.Group widths="2">
              <Form.Field
                name="selectedCategory"
                selection
                options={skillsCategory}
                onChange={this.handleCategorySelections}
                placeholder={'Please select skill category'}
                value={selectedCategory}
                control={Dropdown}
                id="selectedCategory"
                aria-labelledby="selectedCategory"
                label={{
                  children: 'Category',
                  htmlFor: 'selectedCategory',
                }}
              />

              {selectedCategory && (
                <Form.Field
                  name="selectedSkill"
                  selection
                  fluid
                  onChange={this.handleSkillSelection}
                  value={selectedSkill}
                  category={selectedCategory}
                  control={AmsLookupDropdown}
                  id="selectedSkill"
                  aria-labelledby="selectedSkill"
                  label={{
                    children: 'Skill',
                    htmlFor: 'selectedSkill',
                  }}
                />
              )}
            </Form.Group>
            {selectedCategory && selectedSkill && (
              <Form.Group widths="2">
                <Form.Field
                  name="effectiveDate"
                  control={DatePicker}
                  placeholder="Please select start Date"
                  selected={effectiveDate && effectiveDate}
                  selectsStart
                  startDate={effectiveDate && effectiveDate}
                  onChange={e => this.setState({ effectiveDate: e })}
                  onChangeRaw={this.handleDateChangeRaw}
                  id="effectiveDate"
                  aria-labelledby="effectiveDate"
                  label={{
                    children: 'Effective Date',
                    htmlFor: 'effectiveDate',
                  }}
                />
                <Form.Field
                  name="expirationDate"
                  control={DatePicker}
                  placeholder="Please select end date"
                  selected={expirationDate && expirationDate}
                  selectsEnd
                  startDate={effectiveDate && effectiveDate}
                  minDate={AmsDateFormatters.getMoment()}
                  endDate={expirationDate}
                  onChange={e => this.setState({ expirationDate: e })}
                  onChangeRaw={this.handleDateChangeRaw}
                  id="expirationDate"
                  aria-labelledby="expirationDate"
                  label={{
                    children: 'Expiration Date',
                    htmlFor: 'expirationDate',
                  }}
                />
              </Form.Group>
            )}
            <input type="submit" value="submit" hidden />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={this.setSkillAddition}
            disabled={selectedCategory && selectedSkill ? false : true}
          >
            Save
          </Button>
          <Button onClick={this.closeEditModal}>Close</Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  closeEditSkillModal = () => {
    this.setState({
      showEditDateModal: false,
      editedCell: null,
      editedRow: null,
      editMode: false,
    });
  };

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  renderEditDateModal = (cell, row) => {
    const {
      editedRow,
      showEditDateModal,
      loading,
      effectiveDate,
      expirationDate,
    } = this.state;
    return (
      <AmsModal
        open={showEditDateModal}
        onClose={this.closeEditModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="education-modal ams-semantic-modal-fix"
      >
        <Modal.Header>Edit Effective/Expiration Date</Modal.Header>
        <Modal.Content>
          {this.showSkipErrors()}
          <Form loading={loading}>
            <Form.Group widths="2">
              <Form.Field
                name="effectiveDate"
                label={'Effective Date'}
                control={DatePicker}
                placeholder="Please select start Date"
                // isClearable={true}
                selected={
                  effectiveDate
                    ? effectiveDate
                    : editedRow && editedRow.effectiveTime
                    ? AmsDateFormatters.getMoment(
                        editedRow && editedRow.effectiveTime
                      )
                    : null
                }
                selectsStart
                startDate={
                  effectiveDate
                    ? effectiveDate
                    : AmsDateFormatters.getMoment(
                        editedRow && editedRow.effectiveTime
                      )
                }
                onChange={e =>
                  editedRow
                    ? this.setState({
                        effectiveDate: e,
                        editedRow: {
                          ...this.state.editedRow,
                          effectiveTime: e,
                        },
                      })
                    : this.setState({
                        effectiveDate: e,
                      })
                }
                onChangeRaw={this.handleDateChangeRaw}
              />
              <Form.Field
                name="expirationDate"
                label={'Expiration Date'}
                control={DatePicker}
                placeholder="Please select end date"
                // isClearable={true}
                selected={
                  expirationDate
                    ? expirationDate
                    : editedRow && editedRow.expirationTime
                    ? AmsDateFormatters.getMoment(
                        editedRow && editedRow.expirationTime
                      )
                    : null
                }
                selectsEnd
                startDate={
                  effectiveDate
                    ? effectiveDate
                    : AmsDateFormatters.getMoment(
                        editedRow && editedRow.effectiveTime
                      )
                }
                minDate={AmsDateFormatters.getMoment()}
                endDate={
                  expirationDate
                    ? expirationDate
                    : AmsDateFormatters.getMoment(
                        editedRow && editedRow.expirationTime
                      )
                }
                onChange={e =>
                  editedRow
                    ? this.setState({
                        expirationDate: e,
                        editedRow: {
                          ...this.state.editedRow,
                          expirationTime: e,
                        },
                      })
                    : this.setState({
                        expirationDate: e,
                      })
                }
                onChangeRaw={this.handleDateChangeRaw}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.setDateEditChanges}>
            Save
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              this.closeEditModal();
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  editDateClicked = (cell, row) => {
    this.setState({
      showEditDateModal: true,
      modalTitle: 'Edit Effective/Expiration Date',
      editedRow: { ...row, orignalEffectiveTime: row.effectiveTime },
      editedCell: cell,
    });
  };

  actionFormatter = (cell, row) => {
    return (
      <Button.Group icon>
        <span>
          {row.expirationTime <= AmsDateFormatters.getMoment().format() ? (
            <Button
              negative
              onClick={this.expireSkill.bind(this, cell, row)}
              disabled
            >
              Expired
            </Button>
          ) : (
            <Button negative onClick={this.expireSkill.bind(this, cell, row)}>
              Expire
            </Button>
          )}
        </span>
        &nbsp; | &nbsp;
        <Button
          onClick={this.editDateClicked.bind(this, cell, row)}
          className="clickable"
          style={{ background: 'none' }}
        >
          <i className="fa fa-pencil fa-md" />
        </Button>
        &nbsp; | &nbsp;
        <Button
          onClick={e => {
            e.preventDefault();
            this.setState({ showDeleteConfirmationAlert: true, row });
          }}
          className="clickable"
          style={{ background: 'none' }}
        >
          <i className="fa fa-trash fa-md" />
        </Button>
      </Button.Group>
    );
  };

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.adminPanel.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  showDeleteConfirmation = () => {
    const { showDeleteConfirmationAlert, row, data } = this.state;

    return (
      <AmsAlert
        show={showDeleteConfirmationAlert}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonText={'Yes, delete'}
        cancelButtonText={'No, cancel'}
        title={'Remove Skill'}
        text={'Are you sure you want to remove this skill'}
        onConfirm={() => {
          this.setState({
            data: {
              ...data,
              userSkill: _.filter(data.userSkill, skill => {
                if (_.isEqual(skill, row)) {
                  skill.isDeleted = true;
                }
                return skill;
              }),
            },
            showDeleteConfirmationAlert: false,
          });
        }}
        onCancel={() => {
          this.setState({ showDeleteConfirmationAlert: false });
        }}
      />
    );
  };

  render() {
    const { editMode, loading, errors, data, showSuccess } = this.state;
    const { currentUser } = this.props;

    const dateFormatter = (cell, row) => {
      if (cell) return AmsDateFormatters.formatDateTime(cell);
      else return null;
    };

    let filteredData =
      data.userSkill &&
      _.filter(data.userSkill, skill => {
        return !skill.isDeleted;
      });

    const columns = [
      {
        dataField: 'skillUuid',
        hidden: true,
      },
      {
        dataField: 'category',
        text: 'Category',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'skill',
        text: 'Skill',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'effectiveTime',
        sort: true,
        formatter: dateFormatter,
        style: { whiteSpace: 'normal' },
        text: 'Effective Date',
      },
      {
        dataField: 'expirationTime',
        sort: true,
        text: 'Expiration Date',
        formatter: dateFormatter,
        style: {
          whiteSpace: 'normal',
        },
      },

      {
        text: 'Opted Out',
        dataField: 'optedOut',
        sort: true,
        formatter: dateFormatter,
      },
    ];

    if (editMode) {
      columns.push({
        style: {
          width: '25%',
        },
        headerStyle: {
          textAlign: 'center',
        },
        isDummy: true,
        dataField: 'dummy',
        align: 'center',
        formatter: this.actionFormatter,
        text: 'Action',
      });
    }

    return (
      <div>
        {this.showAddSkillModal()}
        {this.renderEditDateModal()}
        {this.showDeleteConfirmation()}
        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <div className="profile-form-title-wrapper">
            <h2>
              Skills
              <AmsHelpText category="helpText" fieldLabel="userSpecialSkills" />
            </h2>
          </div>

          <div className="profile-form-fields-wrapper">
            {showSuccess && (
              <Message
                positive
                icon="check"
                header="Success!"
                content="Data saved successfully."
              />
            )}

            {!_.isEmpty(errors) && (
              <Message
                negative
                icon="cancel"
                header="Something went wrong!"
                list={Object.keys(errors).map(errorKey => (
                  <li key={errorKey}>{errors[errorKey]}</li>
                ))}
              />
            )}

            <Form.Group widths="equal">
              <Form.Field>
                <div className="row col-sm-12">
                  <div className="col-sm-10">
                    <h5>Skills</h5>
                  </div>
                  {editMode && (
                    <div className="col-sm-2 add-skill-button">
                      <Button
                        primary
                        size="small"
                        icon
                        labelPosition="left"
                        onClick={this.addSkill}
                      >
                        <Icon name="add" />
                        Add Skill
                      </Button>
                    </div>
                  )}
                </div>
                <Col className="row col-sm-12" xs={12} sm={12}>
                  <AmsTable
                    remote={false}
                    basic
                    total={filteredData.length}
                    columns={columns}
                    keyField="skillUuid"
                    ref="table"
                    data={filteredData}
                  />
                </Col>
              </Form.Field>
            </Form.Group>
          </div>

          {enforceRole(
            <Segment basic floated="right">
              {editMode ? (
                <div>
                  <Button size="large" primary>
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={e => {
                      e.preventDefault();
                      this.cancelEdit();
                    }}
                    basic
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  size="large"
                  floated="right"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({
                      error: {},
                      showSuccess: false,
                      editMode: true,
                    });
                  }}
                >
                  Edit
                </Button>
              )}
            </Segment>,
            acl.actions.profile.adminPanel, // Allowed roles
            currentUser.roles // Current user roles
          )}
        </Form>
      </div>
    );
  }
}

Skills.propTypes = {
  profile: PropTypes.object,
  rolesList: PropTypes.array,
  lookups: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  profileTab: state.profileTab,
  rolesList: state.admin.userRoles,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { updateUserProfile, updateUserRoles })(
  Skills
);
