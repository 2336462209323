import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, filter, orderBy } from 'lodash';
import {
  Modal,
  Button,
  Form as SemanticForm,
  Segment,
  Grid,
  Popup,
} from 'semantic-ui-react';

import AmsModal from '../../../utils/AmsModal';
import AmsTable from '../../../utils/AmsTable';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import Form from '@rjsf/core';
import {
  fetchRANFormDetail,
  ranGuideSelected,
  ranPerformanceMeasureSelected,
} from '../../../actions/ranActions';
import { allowedView } from '../../../utils/EnforceRole';
import { acl } from '../../../config';
import AmsAlert from '../../../utils/AmsAlert';
import GranteeIdField from '../../Shared/FormHelpers/GranteeIdField';
import { postFormSubmission } from '../../../actions/surveyActions';
import ItalicField from '../../Shared/FormHelpers/ItalicField';

const RANIncidentMulti = props => {
  const amsTableRef = useRef(null);
  const {
    selectedPerformanceMeasure,
    selectedGuide,
    isReportFinalized,
    reviewStatus,
    readOnly,
    reviewId,
    submission,
    fiscalYear,
  } = useSelector(state => state.forms.ran);
  const { user } = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [rowClicked, setRowClicked] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [fileEditButtonClicked, setFileEditButtonClicked] = useState(false);
  const [prevSelectedIncidentDtls, setPrevSelectedIncidentDtls] = useState('');
  const [
    showDeleteConfirmationAlert,
    setShowDeleteConfirmationAlert,
  ] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedPM, setSelectedPM] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showMovingAwayDialog, setShowMovingAwayDialog] = useState(false);
  const [alertData, setAlertData] = useState({
    showAlert: false,
    alertType: '',
    alertMessage: '',
  });
  const [showAddIncidentDialog, setShowAddIncidentDialog] = useState(false);
  const [findingSubmissionAlert, setFindingSubmissionAlert] = useState(false);

  const dispatch = useDispatch();

  // useEffect to set selected PM
  useEffect(() => {
    if (!isEmpty(selectedPerformanceMeasure)) {
      setSelectedPM(selectedPerformanceMeasure);
    }
  }, [selectedPerformanceMeasure]);

  // useEffect to stop form processing when form schema is fetched
  useEffect(() => {
    setFormProcessing(false);
    setShowModal(false);
  }, [selectedPM.formSchema]);

  // useEffect to update error state for incident file
  useEffect(() => {
    if (props.incidentFileSaveErrors) {
      setErrors(props.incidentFileSaveErrors);
    }
  }, [props.incidentFileSaveErrors]);

  // Build and render incident file listing table
  const renderIncidentFilesTable = () => {
    const { forms } = selectedPM;
    const data = orderBy(forms, ['incidentId'], ['desc']);
    const formatDate = (cell, row) =>
      AmsDateFormatters.formatDate(AmsDateFormatters.getMoment(cell));

    const rowStyle = (row, rowIndex) => {
      if (!row.isActive) {
        return {
          backgroundColor: '#e0b4b4',
        };
      }
    };

    const actionFormatter = (cell, row) => {
      return (
        <Button.Group icon>
          <Popup
            trigger={
              <Button
                onClick={e => {
                  e.preventDefault();
                  setFileEditButtonClicked(true);
                  props.handleSelectedIncidents(row);
                  setFormDisabled(false);
                  setRowClicked(true);
                  setPrevSelectedIncidentDtls(row);
                  props.fetchSurveyData(row.amsSubmissionId);
                  setShowModal(true);
                  if (!row.isActive) {
                    setFormDisabled(true);
                  }
                }}
                className="clickable"
                style={{ background: 'none' }}
              >
                {row.isActive ? (
                  <i className="fa fa-pencil fa-md" />
                ) : (
                  <i className="fa fa-eye fa-md" />
                )}
              </Button>
            }
            disabled={row.isActive}
            content={!row.isActive && 'View'}
            size="mini"
            inverted
          />
          &nbsp; | &nbsp;
          <Popup
            trigger={
              allowedView(
                acl.actions.survey.deleteIncidentFiles,
                user.roles
              ) && (
                <Button
                  onClick={e => {
                    if (row.isActive) {
                      e.preventDefault();
                      setShowDeleteConfirmationAlert(true);
                      props.handleSelectedIncidents(row);
                      props.fetchSurveyData(row.amsSubmissionId);
                      setPrevSelectedIncidentDtls(row);
                    } else {
                      setShowModal(false);
                    }
                  }}
                  className="clickable"
                  style={{ background: 'none' }}
                >
                  <i className="fa fa-trash fa-md" />
                </Button>
              )
            }
            disabled={row.isActive}
            content={!row.isActive && 'This incident is already deleted'}
            size="mini"
            inverted
          />
        </Button.Group>
      );
    };

    const incidentFormatter = (cell, row) => {
      return (
        <Popup
          trigger={
            allowedView('All', user.roles) && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={e => {
                  e.preventDefault();
                  setFileEditButtonClicked(true);
                  props.handleSelectedIncidents(row);
                  setFormDisabled(false);
                  setRowClicked(true);
                  setPrevSelectedIncidentDtls(row);
                  props.fetchSurveyData(row.amsSubmissionId);
                  setShowModal(true);
                  setFormDisabled(true);
                }}
                // eslint-disable-next-line no-script-url
                href="javascript:void(0);"
                style={{ color: 'black', textDecoration: 'none' }}
              >
                {cell}
              </a>
            )
          }
          content={'View'}
          size="mini"
          inverted
        />
      );
    };

    const columns = [
      {
        dataField: 'incidentId',
        text: 'Incident ID',
        sort: true,
        style: { whiteSpace: 'normal' },
        formatter: incidentFormatter,
      },
      {
        dataField: 'submittedBy',
        text: 'Submitter',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'fileReviewDate',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Date Added',
      },
    ];

    columns.push({
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      formatter: actionFormatter,
      text: 'Action',
    });

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (!isEmpty(submission)) {
          props.handleSelectedIncidents(row);
          setRowClicked(true);
        }
      },
    };
    return (
      <>
        <div>
          {!isReportFinalized &&
            reviewStatus &&
            reviewStatus.toLowerCase() !== 'cancelled' && (
              <div>
                <Button
                  basic
                  size="tiny"
                  disabled={readOnly}
                  onClick={() => {
                    setShowModal(true);
                    setFormDisabled(false);
                    setRowClicked(false);
                    setPrevSelectedIncidentDtls(props.selectedIncidentFile);
                    props.handleSelectedIncidents({});
                  }}
                >
                  Add Incident
                </Button>
              </div>
            )}
        </div>
        {data.length > 0 && <h5>Added Incident Files</h5>}
        <AmsTable
          remote={false}
          basic
          total={data ? data.length : 0}
          columns={columns}
          rowEvents={rowEvents}
          keyField="version"
          ref={amsTableRef}
          data={data ? data : []}
          rowStyle={rowStyle}
        />
      </>
    );
  };

  /**
   * Set the form data changes for submission
   * @param {Object} data - data that comes from the form schema
   * @returns {void}
   */
  const handleChange = ({ idSchema, formData }) => {
    props.handleSelectedIncidents({
      formData,
      incidentId: props.selectedIncidentFile.incidentId,
      mongoId: props.selectedIncidentFile.mongoId,
      amsSubmissionId: props.selectedIncidentFile.amsSubmissionId,
    });
  };

  /**
   * Transform the incident file errors to custom error messages
   * @param {Object} incidentFileErrors
   * @returns {Object}
   */
  const transformErrors = incidentFileErrors => {
    return filter(incidentFileErrors, error => {
      if (error.name === 'minItems') {
        error.message = 'This is a required field.';
        return error.name === 'minItems';
      }
      if (error.name === 'required') {
        error.message = 'This is a required field.';
        return error.name === 'required' || error.name === 'minimum';
      } else if (error.name === 'pattern') {
        error.message =
          'Please enter a valid Grantee ID, example format - 01CH010527';
        return error.name === 'pattern';
      } else if (error.name === 'incidentId') {
        error.message =
          'The incident file already exists please check the Incident ID and try again.';
        return error.name === 'incidentId';
      } else if (error.name === 'format') {
        error.message = 'Please enter a valid date';
        return error.name === 'format';
      } else if (error.name === 'minimum') {
        return (error.message =
          'This field only accept values greater than 0. Please enter a whole number.');
      }
      if (error.params.type === 'integer') {
        return (error.message =
          'This field only accepts whole numbers. Please enter a whole number.');
      }
    });
  };

  /**
   * Submit the form data
   * @returns {void}
   */
  const handleSubmit = ({ idSchema, formData }) => {
    if ((selectedPM && !selectedPM.amsFormId) || !reviewId) return;

    let requestObject = {
      reviewId,
      amsFormId: selectedPM.amsFormId,
      incidentType: 'RAN-INCIDENTS',
      surveyData: formData,
    };

    if (
      props.selectedIncidentFile &&
      props.selectedIncidentFile.amsSubmissionId
    ) {
      requestObject.amsSubmissionId =
        props.selectedIncidentFile.amsSubmissionId;
      requestObject.mongoId = props.selectedIncidentFile.mongoId;
      requestObject.submitterName = user.fullName;
      requestObject.submitterOid = user.oid;
      actionTriggered('UPDATE', requestObject);
    } else {
      const { forms } = selectedPM;
      const data = forms;

      let maxValue = 0;
      if (!isEmpty(data)) {
        maxValue = Math.max.apply(
          Math,
          data.map(o => {
            return o.incidentId;
          })
        );
      }

      if (formData) {
        formData.isActive = true;
        formData.incidentId = ++maxValue;
        actionTriggered('ADD', requestObject);
      }
    }
    setFormProcessing(true);
    resetState();
    setShowModal(false);
  };

  const handleError = error => {
    console.log(error);
    if (error.length >= 1) {
      setShowErrorDialog(true);
    }
  };

  const actionTriggered = (action, requestObject) => {
    dispatch(
      postFormSubmission({
        data: { ...requestObject, submissionStatus: 'Submitted' },
      })
    )
      .then(response => {
        setErrors({});
        if (
          props.selectedIncidentFile &&
          !props.selectedIncidentFile.amsSubmissionId
        ) {
          setShowAddIncidentDialog(true);
          props.handleSelectedIncidents({});
        }
        setAlertData({
          showAlert: true,
          alertType: 'success',
          alertMessage:
            action === 'ADD'
              ? 'Incident added successfully'
              : action === 'UPDATE'
              ? 'Incident saved successfully'
              : action === 'DELETE'
              ? 'Incident dropped successfully'
              : '',
        });
      })
      .catch(error => {
        setErrors({ ...errors, fa2SaveDraft: error.message });
        setFormProcessing(false);
        setAlertData({
          showAlert: true,
          alertType: 'error',
          alertMessage:
            action === 'ADD'
              ? 'Incident add was unsuccessful'
              : action === 'UPDATE'
              ? 'Incident save was unsuccessful'
              : action === 'DELETE'
              ? 'Incident delete was unsuccessful'
              : '',
        });
      });
  };

  // Set state to default values when closing modal
  const closeModal = () => {
    setShowMovingAwayDialog(false);
    setShowModal(false);
    setRowClicked(false);
    props.handleSelectedIncidents(prevSelectedIncidentDtls);
  };

  // Add custom field for gantee id in the form
  const fields = () => {
    return {
      granteeIdField: GranteeIdField,
      italicField: ItalicField,
    };
  };

  const validate = (data, errors) => {
    const today = AmsDateFormatters.getTodayDate();

    if (
      selectedPerformanceMeasure.name ===
        'Document Inappropriate Release Incident(s)' ||
      selectedPerformanceMeasure.name ===
        'Document Inappropriate Release Incident(s) Details Guide'
    ) {
      const { question11, question6 } = data;
      incidentReportedErrors(data, errors);
      if (question11.question11aYesNo && fiscalYear >= 2023) {
        validateDateError(
          question11.question11aYesNo,
          'question11aYesNo',
          errors
        );
        staffTrainingErrors(
          question11.question11aYesNo,
          'question11aYesNo',
          errors
        );
      } else if (question6.question6aYes) {
        const diff = AmsDateFormatters.getDifference(
          AmsDateFormatters.getMoment(today),
          AmsDateFormatters.getMoment(question6.question6aYes)
        );
        if (diff / 365 > 7) {
          errors.question6.question6aYes.addError('Please enter a valid date.');
        }
      }
    }

    if (
      selectedPerformanceMeasure.name ===
      'Document Supervision Incident(s) Details Guide'
    ) {
      const { question17 } = data;
      incidentReportedErrors(data, errors);
      validateDateError(
        question17.question17aYesNo,
        'question17aYesNo',
        errors
      );
      staffTrainingErrors(
        question17.question17aYesNo,
        'question17aYesNo',
        errors
      );
    }

    if (
      selectedPerformanceMeasure.name ===
      'Document Discipline Incident(s) Details Guide'
    ) {
      const { question10 } = data;
      incidentReportedErrors(data, errors);
      validateDateError(
        question10.question10aYesNo,
        'question10aYesNo',
        errors
      );
      staffTrainingErrors(
        question10.question10aYesNo,
        'question10aYesNo',
        errors
      );
    }
    return errors;
  };

  const staffTrainingErrorMessage = () => {
    return 'The date of the last training entered cannot be today or a day in the future. Please update the date for the last training to be a past date.';
  };

  const validateDateErrorMessage = () => {
    return 'Please enter a valid date.';
  };

  const incidentReportedErrorMessage = () => {
    return 'Incident report date cannot be before the incident date.';
  };

  const staffTrainingErrors = (questionKey, questionType, errors) => {
    const today = AmsDateFormatters.getTodayDate();
    if (questionKey) {
      const question = AmsDateFormatters.getMoment(questionKey);
      if (
        AmsDateFormatters.getMoment(today)
          .startOf('day')
          .isSameOrBefore(question.startOf('day'))
      ) {
        if (questionType == 'question10aYesNo') {
          errors.question10.question10aYesNo.addError(
            staffTrainingErrorMessage()
          );
        } else if (questionType == 'question17aYesNo') {
          errors.question17.question17aYesNo.addError(
            staffTrainingErrorMessage()
          );
        } else if (questionType == 'question11aYesNo') {
          errors.question11.question11aYesNo.addError(
            staffTrainingErrorMessage()
          );
        }
      }
    }
  };

  const validateDateError = (questionKey, questionType, errors) => {
    const today = AmsDateFormatters.getTodayDate();
    if (questionKey) {
      const diff = AmsDateFormatters.getDifference(
        AmsDateFormatters.getMoment(today),
        AmsDateFormatters.getMoment(questionKey)
      );
      if (diff / 365 > 7) {
        if (questionType == 'question10aYesNo') {
          errors.question10.question10aYesNo.addError(
            validateDateErrorMessage()
          );
        } else if (questionType == 'question17aYesNo') {
          errors.question17.question17aYesNo.addError(
            validateDateErrorMessage()
          );
        } else if (questionType == 'question11aYesNo') {
          errors.question11.question11aYesNo.addError(
            validateDateErrorMessage()
          );
        } else if (questionType == 'question4Date') {
          errors.question4.question4Date.addError('Please enter a valid date.');
        } else if (questionType == 'question5Date') {
          errors.question5.question5Date.addError('Please enter a valid date.');
        }
      }
    }
  };

  const incidentReportedErrors = (data, errors) => {
    const { question4, question5 } = data;
    validateDateError(question4.question4Date, 'question4Date', errors);
    validateDateError(question5.question5Date, 'question5Date', errors);
    if (question4.question4Date) {
      const q4 = AmsDateFormatters.getMoment(question4.question4Date);
      const q5 = AmsDateFormatters.getMoment(question5.question5Date);
      if (q5.isBefore(q4)) {
        errors.question5.question5Date.addError(incidentReportedErrorMessage());
      }
    }
  };

  // Render the add incident file modal
  const renderAddIncidentFiles = () => {
    return (
      <AmsModal
        className="ams-semantic-modal-fix"
        open={showModal}
        onClose={closeModal}
        size="large"
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{'Add Incident Files'}</Modal.Header>
        <Modal.Content>
          {/* {!isEmpty(incidentFileErrors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={map(incidentFileErrors, error => error.message)}
            />
          )} */}
          <Segment basic loading={props.loading}>
            <div className="row">
              <div
                key={
                  (selectedPM &&
                    selectedPM.forms &&
                    selectedPM.forms[0] &&
                    selectedPM.forms[0].amsFormId) ||
                  ''
                }
                className="form-container"
              >
                <fieldset
                  disabled={
                    isReportFinalized ||
                    (reviewStatus && reviewStatus.toLowerCase() === 'cancelled')
                      ? true
                      : formDisabled
                  }
                >
                  <Form
                    idPrefix={
                      (selectedPM &&
                        selectedPM.forms &&
                        selectedPM.forms[0] &&
                        selectedPM.forms[0].amsFormId) ||
                      ''
                    }
                    schema={selectedPM.formSchema || {}}
                    uiSchema={selectedPM.uiSchema || {}}
                    formData={
                      (props.selectedIncidentFile &&
                        props.selectedIncidentFile.formData) ||
                      {}
                    }
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    onError={handleError}
                    validate={validate}
                    liveValidate={false}
                    showErrorList={false}
                    noHtml5Validate
                    transformErrors={transformErrors}
                    fields={fields()}
                  >
                    <div className="ui form">
                      {!isReportFinalized && (
                        <SemanticForm.Group>
                          <SemanticForm.Button
                            primary
                            type="submit"
                            content={
                              fileEditButtonClicked || rowClicked
                                ? 'Save changes'
                                : 'Add'
                            }
                          />
                        </SemanticForm.Group>
                      )}
                    </div>
                  </Form>
                  {fileEditButtonClicked &&
                    submission &&
                    submission.versionHistory &&
                    renderVersionHistoryTable()}
                </fieldset>
              </div>
            </div>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setFileEditButtonClicked(false);
              if (!formDisabled) {
                setShowMovingAwayDialog(true);
              } else {
                closeModal();
              }
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </AmsModal>
    );
  };

  /**
   * Render the version table for the submissions
   * @returns {void}
   */
  const renderVersionHistoryTable = () => {
    const data = submission.versionHistory.sort((a, b) =>
      AmsDateFormatters.getMoment(b.modifiedDate).diff(
        AmsDateFormatters.getMoment(a.modifiedDate)
      )
    );

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const amsSubmissionId = submission.amsSubmissionId;
            const version = cell;
            props.fetchSurveyDataByVersion({
              filters: { amsSubmissionId, version },
            });
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      <AmsTable
        remote={false}
        basic
        total={data.length}
        columns={columns}
        keyField="version"
        ref={amsTableRef}
        data={data}
      />
    );
  };

  const showDeleteConfirmation = () => {
    return (
      <AmsAlert
        show={showDeleteConfirmationAlert}
        title={'Are you sure you want to delete this incident file?'}
        type={'warning'}
        showConfirm
        showCancelButton
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes, delete'}
        cancelButtonText={'No, cancel'}
        onCancel={() => {
          setShowDeleteConfirmationAlert(false);
        }}
        onConfirm={() => {
          if (
            selectedGuide &&
            selectedGuide.performanceMeasures[1] &&
            selectedGuide.performanceMeasures[1].forms[0] &&
            'Submitted' ===
              selectedGuide.performanceMeasures[1].forms[0].submissionStatus &&
            checkIncidentsCountByPMName(selectedPM.name)
          ) {
            setFindingSubmissionAlert(true);
            setShowDeleteConfirmationAlert(false);
          } else {
            let requestObject = {
              reviewId,
              amsFormId: selectedPM.amsFormId,
              incidentType: 'RAN-INCIDENTS',
              surveyData:
                props.selectedIncidentFile &&
                props.selectedIncidentFile.formData,
            };

            if (
              props.selectedIncidentFile &&
              props.selectedIncidentFile.amsSubmissionId
            ) {
              requestObject.amsSubmissionId =
                props.selectedIncidentFile.amsSubmissionId;
              requestObject.mongoId = props.selectedIncidentFile.mongoId;
              requestObject.submitterName = user.fullName;
              requestObject.submitterOid = user.oid;
              requestObject.surveyData.isActive = false;
            }
            setFormProcessing(true);
            actionTriggered('DELETE', requestObject);
            setShowDeleteConfirmationAlert(false);
            props.handleSelectedIncidents({});
          }
        }}
      />
    );
  };

  const checkIncidentsCountByPMName = PMName => {
    let foundPMeasure = selectedGuide.performanceMeasures.find(
      measure => measure.name === PMName
    );
    if (foundPMeasure) {
      return foundPMeasure.forms.filter(f => f.isActive).length < 2
        ? true
        : false;
    } else {
      return true;
    }
  };

  const showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = alertData;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          setAlertData({ showAlert: false, alertType: '', alertMessage: '' });
          dispatch(fetchRANFormDetail({ reviewId })).then(() => {
            dispatch(ranGuideSelected(selectedGuide));
            dispatch(ranPerformanceMeasureSelected(selectedPerformanceMeasure));
          });
        }}
      />
    );
  };

  // Set state to default values after submission
  const resetState = () => {
    setErrors({});
    setFileEditButtonClicked(false);
  };

  // Render survey submission status
  const renderSurveyStatus = () => {
    if (isEmpty(props.selectedIncidentFile)) return null;
    if (!props.selectedIncidentFile.incidentId) return null;
    const {
      submissionTime,
      submittedBy,
      incidentId,
    } = props.selectedIncidentFile;

    return (
      <Segment
        style={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #e3e3e3',
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
          padding: '20px',
          marginLeft: '1rem',
        }}
      >
        {submittedBy && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submitted By"
            >
              Submitted By:{' '}
            </span>
            {submittedBy}
          </div>
        )}
        <div>
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Submission Status"
          >
            Submission Status:{' '}
          </span>
          {'Submitted'}
        </div>
        {submissionTime && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Time"
            >
              Submission Time:{' '}
            </span>
            {AmsDateFormatters.formatDateTime(submissionTime)}
          </div>
        )}
        {props.selectedIncidentFile && props.selectedIncidentFile.incidentId && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Incident ID:{' '}
            </span>
            {incidentId}
          </div>
        )}
      </Segment>
    );
  };

  const renderErrorDialogAlert = () => {
    return (
      <AmsAlert
        show={showErrorDialog}
        title="Oops!"
        text={
          'The incident form is incomplete. Ensure that all fields have been properly filled out.'
        }
        type={'error'}
        showConfirm
        onConfirm={() => {
          setShowErrorDialog(false);
        }}
      />
    );
  };

  const renderMovingAwayDialogAlert = () => {
    return (
      <AmsAlert
        show={showMovingAwayDialog}
        title="Alert!"
        text={'Changes you made may not be saved. Would you like to continue?'}
        type={'warning'}
        showConfirm
        showCancelButton={true}
        onConfirm={() => {
          closeModal();
        }}
        onCancel={() => {
          setShowMovingAwayDialog(false);
        }}
      />
    );
  };

  const renderAddIncidentAlert = () => {
    return (
      <AmsAlert
        show={showAddIncidentDialog}
        title="Alert!"
        text={'Do you want to add more incidents?'}
        type={'warning'}
        showConfirm
        showCancelButton={true}
        confirmButtonText={'Yes, Add'}
        cancelButtonText={'No, Cancel'}
        onConfirm={() => {
          setShowModal(true);
          setShowAddIncidentDialog(false);
          setPrevSelectedIncidentDtls(props.selectedIncidentFile);
          props.handleSelectedIncidents({});
          setFileEditButtonClicked(false);
        }}
        onCancel={() => {
          setShowAddIncidentDialog(false);
          //props.handleSelectedIncidents(prevSelectedIncidentDtls);
        }}
      />
    );
  };

  const showFindingSubmissionError = () => {
    return (
      <AmsAlert
        show={findingSubmissionAlert}
        title="Alert"
        type="error"
        showConfirm
        text={`The incident cannot be deleted as the incident is used for a previously submitted finding guide.`}
        onConfirm={() => {
          setFindingSubmissionAlert(false);
        }}
      />
    );
  };

  return (
    <div className="row form-container">
      <Segment basic loading={formProcessing}>
        {renderSurveyStatus()}
        <Grid columns={1}>
          <Grid.Column>
            <legend>
              {selectedPerformanceMeasure && selectedPerformanceMeasure.name}
            </legend>
            {showSuccessConfirmation()}
            {showDeleteConfirmation()}
            <div className="form-container form-group field field-object sm-col-12">
              {<>{renderIncidentFilesTable()}</>}
              <br />
            </div>
            {renderAddIncidentFiles()}
            {renderErrorDialogAlert()}
            {renderMovingAwayDialogAlert()}
            {renderAddIncidentAlert()}
            {showFindingSubmissionError()}
            <br />
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
};

export default RANIncidentMulti;
