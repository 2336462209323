import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Segment, Responsive, Form } from 'semantic-ui-react';
import _ from 'lodash';
import { Link } from 'react-router';

// Import actions.
import { ranPerformanceMeasureSelected } from '../../../actions/ranActions';

class RANPerformanceMeasures extends React.Component {
  handleDropdownSelect = (e, { value }) => {
    this.performanceMeasuresSelected(value);
  };

  handleMenuSelect = (e, { name }) => {
    e.preventDefault();
    this.performanceMeasuresSelected(name);
  };

  performanceMeasuresSelected = value => {
    const {
      ran: {
        selectedGuide: { performanceMeasures },
        selectedPerformanceMeasure,
      },
    } = this.props;

    const selected = _.find(performanceMeasures, { name: value });

    if (selectedPerformanceMeasure && value !== selectedPerformanceMeasure.name)
      this.props.ranPerformanceMeasureSelected(selected);
  };

  renderPerformanceMeasures = () => {
    if (!this.props.ran.selectedGuide) return;
    const {
      ran: {
        selectedPerformanceMeasure,
        selectedGuide: { performanceMeasures },
      },
    } = this.props;

    if (!performanceMeasures) return;

    const list = performanceMeasures.map(item => ({
      key: item.id,
      text: item.name,
      value: item.name,
    }));

    if (!list.length) return null;

    const selectedName = selectedPerformanceMeasure
      ? selectedPerformanceMeasure.name
      : '';

    return (
      <Form>
        <Responsive as={Segment} basic maxWidth={767}>
          <Form.Dropdown
            label="Select Performance Measure"
            placeholder="Performance Measure"
            options={list}
            value={selectedName}
            onChange={this.handleDropdownSelect}
            selectOnBlur={false}
            selection
            search
            fluid
          />
        </Responsive>

        <Responsive className="field" as={Segment} basic minWidth={768}>
          <label htmlFor="selectPerformanceMeasure">
            Select Performance Measure{' '}
          </label>
          <Menu role="menubar" id="selectPerformanceMeasure" fluid vertical>
            {list.map((item, index) => (
              <Menu.Item
                role="menuitem"
                as={Link}
                aria-labelledby="selectPerformanceMeasure"
                to="#"
                link
                key={index}
                name={item.text}
                content={item.text}
                active={selectedName === item.value}
                onClick={this.handleMenuSelect}
              />
            ))}
          </Menu>
        </Responsive>
      </Form>
    );
  };

  render = () => {
    return <div>{this.renderPerformanceMeasures()}</div>;
  };
}

RANPerformanceMeasures.propTypes = {
  ran: PropTypes.object.isRequired,
  ranPerformanceMeasureSelected: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ran: state.forms.ran,
});

export default connect(mapStateToProps, { ranPerformanceMeasureSelected })(
  RANPerformanceMeasures
);
