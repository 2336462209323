import axios from 'axios';
import moment from 'moment';

import {
  CALENDAR_EVENTS_FETCHED,
  CALENDAR_HOLIDAYS_FETCHED,
  CALENDAR_EVENT_ADDED,
  CALENDAR_EVENT_DELETED,
  CALENDAR_EVENT_UPDATED,
} from '../redux/types';

export const holidaysFetched = holidays => ({
  type: CALENDAR_HOLIDAYS_FETCHED,
  holidays,
});

export const userEventsFetched = (events, lastUpdatedDate) => ({
  type: CALENDAR_EVENTS_FETCHED,
  events,
  lastUpdatedDate,
});

export const userEventAdded = event => ({
  type: CALENDAR_EVENT_ADDED,
  event,
});

export const userEventUpdated = event => ({
  type: CALENDAR_EVENT_UPDATED,
  event,
});

export const userEventDeleted = deleted => ({
  type: CALENDAR_EVENT_DELETED,
  deleted,
});

export const fetchHolidays = year => dispatch =>
  axios
    .post(`/calendar/holidays`, { year })
    .then(response => dispatch(holidaysFetched(response.data)));

export const fetchUserEvents = year => dispatch =>
  axios
    .post(`/calendar/events/list`, { year })
    .then(response =>
      dispatch(
        userEventsFetched(
          response.data.calendarEvents,
          response.data.lastUpdatedDate
        )
      )
    );

export const fetchAdministeredUserEvents = (year, oid) => dispatch =>
  axios
    .post(`/calendar/events/user-events`, { year, oid })
    .then(response =>
      dispatch(
        userEventsFetched(
          response.data.calendarEvents,
          response.data.lastUpdatedDate
        )
      )
    );

export const addUserEvents = (event, oid, excludedDates) => dispatch =>
  axios
    .post(`/calendar/events`, { event, oid, excludedDates })
    .then(response => dispatch(fetchUserEvents(moment().year())));

export const updateUserEvent = (id, input) => dispatch =>
  axios
    .patch(`/calendar/events/${id}`, input)
    .then(response => dispatch(fetchUserEvents(moment().year())));

export const deleteUserEvent = id => dispatch =>
  axios
    .delete(`/calendar/events/${id}`)
    .then(response => dispatch(userEventDeleted(response.data)));
