import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';

export default function GranteeInfo(props) {
  const { granteeInfo, reviewId } = useSelector(state => state.forms.fa2);
  return (
    <>
      {!isEmpty(granteeInfo) ? (
        <Segment
          style={{
            backgroundColor: '#f5f5f5',
            border: '1px solid #e3e3e3',
            boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
            padding: '20px',
            marginLeft: '1rem',
          }}
        >
          {reviewId && (
            <div>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Review ID"
              >
                Review ID:{' '}
              </span>
              {reviewId}
            </div>
          )}
          {granteeInfo.map(grantee => (
            <div key={grantee.granteeId}>
              <span
                role="heading"
                aria-level="2"
                className="field-title"
                aria-label="Grantee Name"
              >
                Grantee Name:{' '}
              </span>
              {`${grantee.granteeName} (${grantee.granteeId})`}
            </div>
          ))}
        </Segment>
      ) : null}
    </>
  );
}
