import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

//Import Grantee ActionOs
import { fetchGrantee, granteeFetched } from '../../actions/granteeActions';

// Import configs.

// Style
import './assets/style.css';

//import components
import SchedulingGranteeViewUnavailability from './SchedulerViewGranteeAvailability';

class SchedulingGranteeView extends Component {
  state = {
    showGranteeAvailability: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    this.props.granteeFetched({});
  }

  getData() {
    const granteeId = this.context.router.params.id;

    this.props.fetchGrantee(granteeId);

    this.setState({
      granteeAvailabilityLink: '/grantee/' + granteeId + '/availability',
    });
  }

  showCalendar = e => {
    e.preventDefault();
    this.setState({ showGranteeAvailability: true });
  };

  hideCalendar = () => {
    window.location.reload(true);
  };

  renderSchedulingAvailability() {
    const { grantee } = this.props;

    return _.isEmpty(grantee) ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <SchedulingGranteeViewUnavailability />
    );
  }

  render() {
    return <div> {this.renderSchedulingAvailability()} </div>;
  }
}

SchedulingGranteeView.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    grantee: state.grantee,
  };
};

export default connect(mapStateToProps, { fetchGrantee, granteeFetched })(
  SchedulingGranteeView
);
