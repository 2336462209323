import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Message,
  Segment,
  TextArea,
} from 'semantic-ui-react';

import _ from 'lodash';

//Import components
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';

// Import actions.
import {
  updateUserProfile,
  updateUserRoles,
} from '../../../actions/profileActions';

// Import settings.
import { acl } from '../../../config';

class ExperienceForm extends Component {
  state = {
    emptyValue: '--',
    showSuccess: false,
    loading: false,
    editMode: false,
    errors: {},
    data: {
      oid: '',
      currentlyEmployed: false,
      currentOrPreviousHeadStartDirector: false,
      currentJobTitle: '',
      currentEmployerName: '',
      currentJobCommitment: '',
      currentJobYears: '',
      currentEmployerPhone: '',
      currentEmployerEmail: '',
      currentEmployerFax: '',
      currentEmployerAddrLine1: '',
      currentEmployerAddrLine2: '',
      currentEmployerAddrCity: '',
      currentEmployerAddrState: '',
      currentEmployerAddrZip: '',
      currentJobResponsibilities: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (props.profile.oid !== state.data.oid) {
      const { profileTab } = props;

      const {
        oid,
        currentlyEmployed,
        currentOrPreviousHeadStartDirector,
        currentJobTitle,
        currentEmployerName,
        currentJobCommitment,
        currentJobYears,
        currentEmployerPhone,
        currentEmployerEmail,
        currentEmployerFax,
        currentEmployerAddrLine1,
        currentEmployerAddrLine2,
        currentEmployerAddrCity,
        currentEmployerAddrState,
        currentEmployerAddrZip,
        currentJobResponsibilities,
      } = props.profile;

      if (profileTab !== 'experience') {
        this.cancelEdit();
      }

      return {
        data: {
          oid,
          currentlyEmployed,
          currentOrPreviousHeadStartDirector:
            currentOrPreviousHeadStartDirector || false,
          currentJobTitle,
          currentEmployerName,
          currentJobCommitment,
          currentJobYears,
          currentEmployerPhone,
          currentEmployerEmail,
          currentEmployerFax,
          currentEmployerAddrLine1,
          currentEmployerAddrLine2,
          currentEmployerAddrCity,
          currentEmployerAddrState,
          currentEmployerAddrZip,
          currentJobResponsibilities,
        },
      };
    }
    return null;
  }

  cancelEdit = () => {
    const { profile } = this.props;

    const {
      oid,
      currentlyEmployed,
      currentOrPreviousHeadStartDirector,
      currentJobTitle,
      currentEmployerName,
      currentJobCommitment,
      currentJobYears,
      currentEmployerPhone,
      currentEmployerEmail,
      currentEmployerFax,
      currentEmployerAddrLine1,
      currentEmployerAddrLine2,
      currentEmployerAddrCity,
      currentEmployerAddrState,
      currentEmployerAddrZip,
      currentJobResponsibilities,
    } = profile;

    const oldData = {
      data: {
        oid,
        currentlyEmployed,
        currentOrPreviousHeadStartDirector:
          currentOrPreviousHeadStartDirector || false,
        currentJobTitle,
        currentEmployerName,
        currentJobCommitment,
        currentJobYears,
        currentEmployerPhone,
        currentEmployerEmail,
        currentEmployerFax,
        currentEmployerAddrLine1,
        currentEmployerAddrLine2,
        currentEmployerAddrCity,
        currentEmployerAddrState,
        currentEmployerAddrZip,
        currentJobResponsibilities,
      },
    };

    this.setState({
      showSuccess: false,
      loading: false,
      editMode: false,
      errors: {},
      data: oldData,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ showSuccess: false, loading: true });

      let { data } = this.state;

      // Only update if there's a valid userId.
      if (data.oid) {
        // if not currently employed, discard employment data.
        if (!data.currentlyEmployed) {
          data = {
            oid: data.oid,
            currentlyEmployed: data.currentlyEmployed,
            currentOrPreviousHeadStartDirector:
              data.currentOrPreviousHeadStartDirector,
            currentJobTitle: '',
            currentEmployerName: '',
            currentJobCommitment: '',
            currentJobYears: '',
            currentEmployerPhone: '',
            currentEmployerEmail: '',
            currentEmployerFax: '',
            currentEmployerAddrLine1: '',
            currentEmployerAddrLine2: '',
            currentEmployerAddrCity: '',
            currentEmployerAddrState: '',
            currentEmployerAddrZip: '',
            currentJobResponsibilities: '',
          };
        }

        this.props
          .updateUserProfile(data)
          .then(() =>
            this.setState({
              showSuccess: true,
              loading: false,
              editMode: false,
            })
          )
          .catch(err => {
            this.setState({
              showSuccess: false,
              loading: false,
              errors: {
                ...this.state.errors,
                profile: err.response.data.message,
              },
            });
          });
      }
    }
  };

  validate = data => {
    const errors = {};

    if (data.currentlyEmployed) {
      if (!data.currentJobTitle)
        errors.currentJobTitle = 'Current job title is required';
      if (!data.currentEmployerName)
        errors.currentEmployerName = 'Employer name is required';
      if (!data.currentEmployerPhone)
        errors.currentEmployerPhone = 'Employer phone is required';
      if (!data.currentJobYears)
        errors.currentJobYears = 'Years in current position is required';
      if (!data.currentEmployerEmail)
        errors.currentEmployerEmail = 'Employer email is required';
      if (!data.currentEmployerAddrLine1)
        errors.currentEmployerAddrLine1 = 'Address is required';
      if (!data.currentEmployerAddrCity)
        errors.currentEmployerAddrCity = 'Employer city is required';
      if (!data.currentEmployerAddrState)
        errors.currentEmployerAddrState = 'Employer state is required';
      if (!data.currentEmployerAddrZip)
        errors.currentEmployerAddrZip = 'Employer Zip is required';
    }

    return errors;
  };

  handleChange = (e, { name, value }) => {
    if (value === 'Yes') value = true;
    else if (value === 'No') value = false;

    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  showEditButton = () => {
    const { currentUser, profile } = this.props;

    if (
      currentUser &&
      acl.actions.profile.userSettings.some(r => currentUser.roles.includes(r))
    ) {
      return (
        <Button
          size="large"
          floated="right"
          onClick={e => {
            e.preventDefault();
            this.setState({
              error: {},
              showSuccess: false,
              editMode: true,
            });
          }}
        >
          Edit
        </Button>
      );
    } else if (currentUser && profile) {
      if (currentUser.oid !== profile.oid) return;
      else
        return (
          <Button
            size="large"
            floated="right"
            onClick={e => {
              e.preventDefault();
              this.setState({
                error: {},
                showSuccess: false,
                editMode: true,
              });
            }}
          >
            Edit
          </Button>
        );
    } else return;
  };

  render() {
    const {
      editMode,
      loading,
      errors,
      data,
      emptyValue,
      showSuccess,
    } = this.state;

    return (
      <Form onSubmit={this.onSubmit} loading={loading} noValidate>
        <div className="profile-form-title-wrapper">
          <h2>Experience</h2>
        </div>

        <div className="profile-form-fields-wrapper">
          {showSuccess && (
            <Message
              positive
              icon="check"
              header="Success!"
              content="Data saved successfully."
            />
          )}

          {!_.isEmpty(errors) && (
            <Message
              negative
              icon="cancel"
              header="Something went wrong!"
              list={Object.keys(errors).map(errorKey => (
                <li key={errorKey}>{errors[errorKey]}</li>
              ))}
            />
          )}

          <Form.Group widths="3">
            {editMode ? (
              <Form.Field
                style={{ width: '25px' }}
                error={!!errors.currentlyEmployed}
                name="currentlyEmployed"
                onChange={this.handleChange}
                value={data.currentlyEmployed === true ? 'Yes' : 'No'}
                options={[
                  { key: 'Yes', text: 'Yes', value: 'Yes' },
                  { key: 'No', text: 'No', value: 'No' },
                ]}
                placeholder="Select"
                selection
                required
                control={Dropdown}
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="experienceEmploymentStatus"
                      label="Are you Currently employed?"
                    />
                  ),
                  htmlFor: 'currentlyEmployed',
                }}
                id="currentlyEmployed"
                aria-labelledby="currentlyEmployed"
              />
            ) : (
              <Form.Field required>
                <label>
                  Are you Currently employed?
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="experienceEmploymentStatus"
                  />
                </label>
                <div className="default-value">
                  {data.currentlyEmployed === true ? 'Yes' : 'No'}
                </div>
              </Form.Field>
            )}

            {editMode ? (
              <Form.Field
                style={{ width: '25px' }}
                name="currentOrPreviousHeadStartDirector"
                onChange={this.handleChange}
                value={
                  data.currentOrPreviousHeadStartDirector === true
                    ? 'Yes'
                    : 'No'
                }
                options={[
                  { key: 'Yes', text: 'Yes', value: 'Yes' },
                  { key: 'No', text: 'No', value: 'No' },
                ]}
                placeholder="Select"
                selection
                control={Dropdown}
                label={{
                  children: (
                    <AmsHelpText
                      category="helpText"
                      fieldLabel="experienceDirector"
                      label="Are you a current or former Head Start Director?"
                    />
                  ),
                  htmlFor: 'currentOrPreviousHeadStartDirector',
                }}
                id="currentOrPreviousHeadStartDirector"
                aria-labelledby="currentOrPreviousHeadStartDirector"
              />
            ) : (
              <Form.Field>
                <label>
                  Are you a current or former Head Start Director?
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="experienceDirector"
                  />
                </label>
                <div className="default-value">
                  {data.currentOrPreviousHeadStartDirector === true
                    ? 'Yes'
                    : 'No'}
                </div>
              </Form.Field>
            )}
          </Form.Group>

          {data.currentlyEmployed === true && (
            <div>
              <h3>Current Employment</h3>

              <Form.Group widths="equal">
                {editMode ? (
                  <Form.Field
                    required
                    error={!!errors.currentJobTitle}
                    name="currentJobTitle"
                    onChange={this.handleChange}
                    value={data.currentJobTitle}
                    placeholder="Job Title"
                    control={Input}
                    label={{
                      children: 'Job Title',
                      htmlFor: 'currentJobTitle',
                    }}
                    id="currentJobTitle"
                    aria-labelledby="currentJobTitle"
                  />
                ) : (
                  <Form.Field>
                    <label>Job Title</label>
                    <div className="default-value">
                      {data.currentJobTitle || emptyValue}
                    </div>
                  </Form.Field>
                )}

                {editMode ? (
                  <Form.Input
                    required
                    error={!!errors.currentEmployerName}
                    name="currentEmployerName"
                    onChange={this.handleChange}
                    value={data.currentEmployerName}
                    placeholder="Employer Name"
                    control={Input}
                    label={{
                      children: 'Employer Name',
                      htmlFor: 'currentEmployerName',
                    }}
                    id="currentEmployerName"
                    aria-labelledby="currentEmployerName"
                  />
                ) : (
                  <Form.Field required>
                    <label>Employer Name</label>
                    <div className="default-value">
                      {data.currentEmployerName || emptyValue}
                    </div>
                  </Form.Field>
                )}
              </Form.Group>
              <Form.Group widths="equal">
                {editMode ? (
                  <Form.Field
                    name="currentJobCommitment"
                    onChange={this.handleChange}
                    value={data.currentJobCommitment || ''}
                    options={[
                      { key: 'none', text: 'None', value: '' },
                      {
                        key: 'Full-time',
                        text: 'Full-time',
                        value: 'Full-time',
                      },
                      {
                        key: 'Part-time',
                        text: 'Part-time',
                        value: 'Part-time',
                      },
                    ]}
                    placeholder="Select"
                    selection
                    control={Dropdown}
                    label={{
                      children: 'Full-time or part-time',
                      htmlFor: 'currentJobCommitment',
                    }}
                    id="currentJobCommitment"
                    aria-labelledby="currentJobCommitment"
                  />
                ) : (
                  <Form.Field>
                    <label>Full-time or part-time</label>
                    <div className="default-value">
                      {data.currentJobCommitment || emptyValue}
                    </div>
                  </Form.Field>
                )}

                {editMode ? (
                  <Form.Field
                    required
                    error={!!errors.currentJobYears}
                    name="currentJobYears"
                    onChange={this.handleChange}
                    value={data.currentJobYears}
                    placeholder="Years in current position"
                    control={Input}
                    label={{
                      children: 'Years in current position',
                      htmlFor: 'currentJobYears',
                    }}
                    id="currentJobYears"
                    aria-labelledby="currentJobYears"
                  />
                ) : (
                  <Form.Field required>
                    <label>Years in current position</label>
                    <div className="default-value">
                      {data.currentJobYears || emptyValue}
                    </div>
                  </Form.Field>
                )}
              </Form.Group>

              <Form.Group widths="3">
                {editMode ? (
                  <Form.Field
                    required
                    error={!!errors.currentEmployerPhone}
                    name="currentEmployerPhone"
                    onChange={this.handleChange}
                    value={data.currentEmployerPhone}
                    placeholder="Job Title"
                    control={Input}
                    label={{
                      children: 'Employer Phone',
                      htmlFor: 'currentEmployerPhone',
                    }}
                    id="currentEmployerPhone"
                    aria-labelledby="currentEmployerPhone"
                  />
                ) : (
                  <Form.Field required>
                    <label>Employer Phone</label>
                    <div className="default-value">
                      {data.currentEmployerPhone || emptyValue}
                    </div>
                  </Form.Field>
                )}

                {editMode ? (
                  <Form.Field
                    name="currentEmployerFax"
                    onChange={this.handleChange}
                    value={data.currentEmployerFax}
                    placeholder="Employer Fax"
                    control={Input}
                    label={{
                      children: 'Employer Fax',
                      htmlFor: 'currentEmployerFax',
                    }}
                    id="currentEmployerFax"
                    aria-labelledby="currentEmployerFax"
                  />
                ) : (
                  <Form.Field>
                    <label>Employer Fax</label>
                    <div className="default-value">
                      {data.currentEmployerFax || emptyValue}
                    </div>
                  </Form.Field>
                )}

                {editMode ? (
                  <Form.Field
                    required
                    error={!!errors.currentEmployerEmail}
                    name="currentEmployerEmail"
                    onChange={this.handleChange}
                    value={data.currentEmployerEmail}
                    placeholder="Employer Email"
                    control={Input}
                    label={{
                      children: 'Employer Email',
                      htmlFor: 'currentEmployerEmail',
                    }}
                    id="currentEmployerEmail"
                    aria-labelledby="currentEmployerEmail"
                  />
                ) : (
                  <Form.Field required>
                    <label>Employer Email</label>
                    <div className="default-value">
                      {data.currentEmployerEmail || emptyValue}
                    </div>
                  </Form.Field>
                )}
              </Form.Group>

              <Form.Group widths="2">
                {editMode ? (
                  <Form.Field
                    required
                    error={!!errors.currentEmployerAddrLine1}
                    name="currentEmployerAddrLine1"
                    onChange={this.handleChange}
                    value={data.currentEmployerAddrLine1}
                    placeholder="Address Line1"
                    control={Input}
                    label={{
                      children: 'Address Line1',
                      htmlFor: 'currentEmployerAddrLine1',
                    }}
                    id="currentEmployerAddrLine1"
                    aria-labelledby="currentEmployerAddrLine1"
                  />
                ) : (
                  <Form.Field required>
                    <label>Address Line1</label>
                    <div className="default-value">
                      {data.currentEmployerAddrLine1 || emptyValue}
                    </div>
                  </Form.Field>
                )}

                {editMode ? (
                  <Form.Field
                    name="currentEmployerAddrLine2"
                    onChange={this.handleChange}
                    value={data.currentEmployerAddrLine2}
                    placeholder="Address Line2"
                    control={Input}
                    label={{
                      children: 'Address Line2',
                      htmlFor: 'currentEmployerAddrLine2',
                    }}
                    id="currentEmployerAddrLine2"
                    aria-labelledby="currentEmployerAddrLine2"
                  />
                ) : (
                  <Form.Field>
                    <label>Address Line2</label>
                    <div className="default-value">
                      {data.currentEmployerAddrLine2 || emptyValue}
                    </div>
                  </Form.Field>
                )}
              </Form.Group>

              <Form.Group widths="3">
                {editMode ? (
                  <Form.Field
                    required
                    error={!!errors.currentEmployerAddrCity}
                    name="currentEmployerAddrCity"
                    onChange={this.handleChange}
                    value={data.currentEmployerAddrCity}
                    placeholder="City"
                    control={Input}
                    label={{
                      children: 'City',
                      htmlFor: 'currentEmployerAddrCity',
                    }}
                    id="currentEmployerAddrCity"
                    aria-labelledby="currentEmployerAddrCity"
                  />
                ) : (
                  <Form.Field required>
                    <label>City</label>
                    <div className="default-value">
                      {data.currentEmployerAddrCity || emptyValue}
                    </div>
                  </Form.Field>
                )}

                {editMode ? (
                  <Form.Field required>
                    <AmsFormLabel
                      fieldLabel="currentEmployerAddrState"
                      name="State"
                      helpText={true}
                    >
                      <AmsLookupDropdown
                        error={!!errors.currentEmployerAddrState}
                        name="currentEmployerAddrState"
                        onChange={this.handleChange}
                        value={data.currentEmployerAddrState || ''}
                        search
                        selection
                        category={'stateNames'}
                        placeholder="State"
                        id="currentEmployerAddrState"
                        aria-labelledby="currentEmployerAddrState"
                        searchInput={{
                          id: 'currentEmployerAddrStateSearch',
                          title: 'Employer address state',
                          'aria-labelledby': 'currentEmployerAddrState',
                        }}
                      />
                    </AmsFormLabel>
                  </Form.Field>
                ) : (
                  <Form.Field required>
                    <label>State</label>
                    <div className="default-value">
                      {data.currentEmployerAddrState || emptyValue}
                    </div>
                  </Form.Field>
                )}

                {editMode ? (
                  <Form.Field
                    required
                    error={!!errors.currentEmployerAddrZip}
                    name="currentEmployerAddrZip"
                    onChange={this.handleChange}
                    value={data.currentEmployerAddrZip}
                    placeholder="Zip"
                    control={Input}
                    label={{
                      children: 'Zip',
                      htmlFor: 'currentEmployerAddrZip',
                    }}
                    id="currentEmployerAddrZip"
                    aria-labelledby="currentEmployerAddrZip"
                  />
                ) : (
                  <Form.Field required>
                    <label>Zip</label>
                    <div className="default-value">
                      {data.currentEmployerAddrZip || emptyValue}
                    </div>
                  </Form.Field>
                )}
              </Form.Group>

              <Form.Group widths="2">
                {editMode ? (
                  <Form.Field
                    name="currentJobResponsibilities"
                    onChange={this.handleChange}
                    value={data.currentJobResponsibilities}
                    placeholder="Tell us more"
                    control={TextArea}
                    label={{
                      children: 'Summary of responsibilities',
                      htmlFor: 'currentJobResponsibilities',
                    }}
                    id="currentJobResponsibilities"
                    aria-labelledby="currentJobResponsibilities"
                  />
                ) : (
                  <Form.Field>
                    <label>Summary of responsibilities</label>
                    <div className="default-value">
                      {data.currentJobResponsibilities || emptyValue}
                    </div>
                  </Form.Field>
                )}
              </Form.Group>
            </div>
          )}
        </div>

        <Segment basic floated="right">
          {editMode ? (
            <div>
              <Button size="large" primary>
                Save
              </Button>
              <Button
                size="large"
                onClick={e => {
                  e.preventDefault();
                  this.cancelEdit();
                }}
                basic
              >
                Cancel
              </Button>
            </div>
          ) : (
            this.showEditButton()
          )}
        </Segment>
      </Form>
    );
  }
}

ExperienceForm.propTypes = {
  profile: PropTypes.object,
  rolesList: PropTypes.array,
  lookups: PropTypes.object,
  profileTab: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserRoles: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  profileTab: state.profileTab,
  rolesList: state.admin.userRoles,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { updateUserProfile, updateUserRoles })(
  ExperienceForm
);
