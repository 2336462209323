import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Dimmer,
  Grid,
  Header,
  List,
  Loader,
  Modal,
  Segment,
} from 'semantic-ui-react';
import Calendar from '../components/Calendar/Calendar';
import AmsModal from './AmsModal';
import { formatPhoneNumber } from './TextFormatters';

// Import actions
import { fetchUserCard } from '../actions/profileActions';

// Import utils.
import AmsDateFormatters from '../utils/AmsDateFormatters';

const AmsUserCard = ({ user, linkText }) => {
  const { oid, fullName } = user;

  const formatDate = (cell, row) => AmsDateFormatters.formatDate(cell);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const { card } = useSelector(store => store.profile);
  const startDate = AmsDateFormatters.getMoment()
    .startOf('day')
    .toISOString();

  const endDate = AmsDateFormatters.getMoment(startDate)
    .add(31, 'd')
    .toISOString();

  useEffect(() => {
    if (show === true && !loading) {
      setLoading(true);
      const body = {
        oid,
        startDate,
        endDate,
      };

      dispatch(fetchUserCard(body))
        .then(e => {
          setLoading(false);
        })
        .catch(e => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const style = {
    content: { textAlign: 'left' },
    iconGroup: { display: 'block', marginBottom: '10px' },
    buttonGroup: { display: 'block', marginTop: '20px' },
  };

  const prepareEvents = list => {
    const toReturn = {};
    if (!list || list.length === 0) {
      return null;
    }
    list &&
      list.forEach(e => {
        const key = formatDate(
          AmsDateFormatters.getMoment(e.start).startOf('day')
        );
        if (toReturn.hasOwnProperty(key)) {
          toReturn[key].push(e);
        } else {
          toReturn[key] = [e];
        }
      });
    return toReturn;
  };

  let preparedEvents = {};
  let dates;
  if (card && card.events) {
    preparedEvents = prepareEvents(card.events);
    dates = preparedEvents && Object.keys(preparedEvents);
  }

  return (
    <span onClick={e => e.stopPropagation()}>
      <Link
        onClick={e => {
          setShow(true);
        }}
      >
        {linkText || fullName}
      </Link>
      <AmsModal
        className="ams-semantic-modal-fix"
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setShow(false);
        }}
        open={show}
      >
        <Modal.Header>
          <List>
            <List.Item>
              {/* <List.Icon name="marker" /> */}
              <List.Content>
                <List.Header as="h2">{fullName}</List.Header>
                <List.Description>
                  <List bulleted horizontal link>
                    <List.Item>{card && card.email}</List.Item>
                    <List.Item>
                      {card &&
                        card.cellPhone &&
                        formatPhoneNumber(`+${card.cellPhone}`)}
                    </List.Item>
                  </List>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Modal.Header>
        <Modal.Content style={style.content} scrolling>
          {' '}
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
          ) : (
            <Modal.Description>
              <Segment basic>
                <Grid columns="equal">
                  {/* <Grid.Row>
                    <Grid.Column>
                      <Header as="h5">Contact Details</Header>
                      <List>
                        <List.Item icon="mail" content={card && card.email} />
                        <List.Item
                          icon="phone square"
                          content={
                            card && card.cellPhone ? card.cellPhone : 'N/A'
                          }
                        />
                      </List>
                    </Grid.Column>
                  </Grid.Row> */}

                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" dividing>
                        Calendar
                      </Header>
                      <div>
                        <Calendar
                          oid={oid || ''}
                          simple
                          userAdministration={true}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" dividing>
                        User Skills
                      </Header>
                      {card && card.userSkill && card.userSkill.length > 0 ? (
                        <List relaxed>
                          {card &&
                            card.userSkill &&
                            card.userSkill.map((e, i) => {
                              return (
                                <List.Item key={i}>
                                  <List.Header content={e.skill} />
                                  {e.category} - ({formatDate(e.effectiveTime)}
                                  {e.expirationTime
                                    ? ` - ${formatDate(e.expirationTime)}`
                                    : null}
                                  ){e.isDeleted && <span>(DELETED)</span>}
                                </List.Item>
                              );
                            })}
                        </List>
                      ) : (
                        <span>No skills found.</span>
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Header as="h3" dividing>
                        Additional Info
                      </Header>
                      <List relaxed>
                        <List.Item>
                          <strong>Vendor ID</strong>:{' '}
                          {card && card.vendorId ? card.vendorId : 'N/A'}
                        </List.Item>
                        <List.Item>
                          <strong>Region ID</strong>:{' '}
                          {card && card.regionId ? card.regionId : 'N/A'}
                        </List.Item>
                        <List.Item>
                          <strong>On Hold</strong>:{' '}
                          {card && card.onHold ? 'True' : 'False'}
                        </List.Item>
                        <List.Item>
                          <strong>User Roles</strong>:{' '}
                          {card && card.userRoles
                            ? card.userRoles.join(', ')
                            : 'N/A'}
                        </List.Item>
                        <List.Item>
                          <strong>User Type</strong>:{' '}
                          {card && card.userType ? card.userType : 'N/A'}
                        </List.Item>
                        <List.Item>
                          <strong>Willing to Travel:</strong>{' '}
                          {card && card.willingToTravel
                            ? card.willingToTravel
                            : 'N/A'}
                        </List.Item>
                        <List.Item>
                          <strong>Additional Travel Information</strong>:{' '}
                          {card && card.additionalInfo
                            ? card.additionalInfo
                            : 'N/A'}
                        </List.Item>
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            onClick={e => {
              setShow(false);
            }}
            content="Close"
          />
        </Modal.Actions>
      </AmsModal>
    </span>
  );
};
export default AmsUserCard;
