import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

//Import Components
import GranteeCalendarListColumn from './GranteeCalendarListColumn';

class GranteeCurrentlySelectedTable extends Component {
  render() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>October</Table.HeaderCell>
            <Table.HeaderCell>November</Table.HeaderCell>
            <Table.HeaderCell>December</Table.HeaderCell>
            <Table.HeaderCell>January</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="10" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="11" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="12" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="01" />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>February</Table.HeaderCell>
            <Table.HeaderCell>March</Table.HeaderCell>
            <Table.HeaderCell>April</Table.HeaderCell>
            <Table.HeaderCell>May</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="02" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="03" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="04" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="05" />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>June</Table.HeaderCell>
            <Table.HeaderCell>July</Table.HeaderCell>
            <Table.HeaderCell>August</Table.HeaderCell>
            <Table.HeaderCell>September</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="06" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="07" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="08" />
            </Table.Cell>
            <Table.Cell>
              <GranteeCalendarListColumn {...this.props} month="09" />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCurrentlySelectedTable);
