import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Grid, Image, Segment } from 'semantic-ui-react';

import { login, logout } from '../../actions/authActions';

// Assets
import logo from './assets/img/ams-logo.png';
import './assets/style.css';

// Import component
import OutdatedBrowser from '../../utils/OutdatedBrowser';

class LoginPage extends Component {
  componentDidMount() {
    const { isAuthenticated } = this.props;

    // If the user has an active session, redirect them to /.
    if (isAuthenticated) browserHistory.push('/');
  }

  showError() {
    if (this.props.error) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.error}
        </div>
      );
    }
  }

  handleLoginClick() {
    this.props.login();
  }

  handleLogoutClick() {
    this.props.logout();
  }

  showAMSLogo() {
    return <Image centered src={logo} alt="Aligned Monitoring System 2.0" />;
  }

  renderAuthButtons() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated)
      return (
        <Button primary fluid size="huge" onClick={() => this.props.logout()}>
          Sign out
        </Button>
      );

    return (
      <Button primary fluid size="huge" onClick={() => this.props.login()}>
        Sign in
      </Button>
    );
  }

  renderDescription = () => (
    <>
      <p>
        IT-AMS Software is provided solely for use by the Office of Head Start
        (OHS) Review Team members conducting OHS Monitoring Reviews and other
        staff supporting the review process. It is not authorized for any other
        purpose.
      </p>

      <p>
        You are accessing a U.S. Government information system, which includes
        (1) this computer, (2) this computer network, (3) all computers
        connected to this network, and (4) all devices and storage media
        attached to this network or to a computer on this network. This
        information system is provided for U.S. Government-authorized use only.
      </p>
      <p>
        Unauthorized or improper use of this system may result in disciplinary
        action, as well as civil and criminal penalties. By using this
        information system, you understand and consent to the following:
      </p>
      <ol>
        <li>
          You have no reasonable expectation of privacy regarding any
          communications or data transiting or stored on this information
          system. At any time, and for any lawful Government purpose, the
          government may monitor, intercept, and search and seize any
          communication or data transiting or stored on this information system.{' '}
        </li>
        <li>
          Any communication or data transiting or stored on this information
          system may be disclosed or used for any lawful Government purpose.
        </li>
      </ol>
    </>
  );

  render() {
    // If there is an oudated browser, show the message.
    if (OutdatedBrowser && OutdatedBrowser.length > 0)
      return (
        <>
          <OutdatedBrowser />
        </>
      );

    return (
      <>
        <div className="login-form">
          <OutdatedBrowser />

          <Helmet>
            <body className="root auth" />
          </Helmet>
          <style>
            {`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
        `}
          </style>
          <Grid
            textAlign="center"
            style={{ height: '100%' }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: '70%' }}>
              <Segment>
                <Segment basic textAlign="center">
                  {this.showAMSLogo()}
                  <span className="sr-only">
                    <h1>Login| Head Start - Aligned Monitoring System</h1>
                  </span>
                </Segment>

                <Segment basic textAlign="left">
                  {this.renderDescription()}
                </Segment>

                <Segment basic>{this.renderAuthButtons()}</Segment>
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

LoginPage.propTypes = {
  currentUser: PropTypes.object,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { login, logout })(LoginPage);
