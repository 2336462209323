import _ from 'lodash';
import { USERS_FILTERED, USERS_FILTER_CLEARED } from '../redux/types';

const initialState = {
  all: [],
};

export default function usersFilter(state = initialState, action) {
  switch (action.type) {
    case USERS_FILTERED: {
      if (action.userType) {
        const current = state[action.userType] || [];
        return {
          ...state,
          [action.userType]: _.uniqBy([...current, ...action.users], 'oid'),
        };
      } else {
        if (!action.userType && action.users) {
          return {
            ...state,
            all: _.uniqBy([...state.all, ...action.users], 'oid'),
          };
        }
        return state;
      }
    }

    case USERS_FILTER_CLEARED: {
      return state;
    }

    default:
      return state;
  }
}
