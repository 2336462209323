import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Well } from 'react-bootstrap';
import Form from '@rjsf/core';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import $ from 'jquery';
import {
  Form as SemanticForm,
  Header,
  Label,
  Popup,
  Segment,
} from 'semantic-ui-react';
import AmsAlert from '../../../utils/AmsAlert';

// Import actions.
import {
  fetchRANSubmission,
  ranPerformanceMeasureSelected,
  ranSaveDraft,
  ranSaveSubmission,
  ranUpdateGuide,
  ranUpdateSubmission,
} from '../../../actions/ranActions';

import { clearFA1Forms } from '../../../actions/fa1Actions';

import { postFormSubmission } from '../../../actions/surveyActions';

// Import components.
import ShowErrors from '../../../utils/ShowErrors';
import ArrayFieldTemplate from '../../Shared/FormHelpers/ArrayFieldTemplate';
import AttachmentField from '../../Shared/FormHelpers/AttachmentField';

// Import config.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsTable from '../../../utils/AmsTable';

// Import utils
import { flattenObj } from '../../Shared/FormHelpers/helpers/utils';

class RANForm extends Component {
  state = {
    showClearAlert: false,
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
    alertType: '',
    formProcessing: false,
    formDisabled: true,
    editButtonClicked: false,
    reviewId: '',
    amsFormId: '',
    amsSubmissionId: '',
    submittedBy: '',
    submissionStatus: '',
    submissionTime: '',
    formSchema: {},
    uiSchema: {},
    formData: {},
    submission: {},
    errors: {},
    showMovingConfirmationAlert: false,
    dataHasChanged: false,
    cancelMovingAway: false,
  };

  componentWillUnmount() {
    //this.props.clearFA1Forms();
  }
  componentDidUpdate() {
    $('select')
      .parent()
      .parents('.panel-body')
      .attr('style', 'display:none');
  }

  componentWillReceiveProps(nextProps) {
    const {
      ran: { reviewId, selectedPerformanceMeasure, submission },
      formProcessing,
      errors,
      selectedCenter,
      selectedClassroom,
    } = nextProps;

    const {
      dataHasChanged,
      changingAmsFormID,
      changingSubmission,
    } = this.state;

    if (this.state.cancelMovingAway) {
      this.setState({
        cancelMovingAway: false,
        amsFormId: changingAmsFormID,
        amsSubmissionId:
          changingSubmission && changingSubmission.amsSubmissionId,
      });
      return;
    }

    this.setState({
      formProcessing,
      errors,
      submission,
      reviewId,
      selectedCenter,
      selectedClassroom,
      formDisabled: true,
      editButtonClicked: false,
      ...this.extractFormDetails(selectedPerformanceMeasure),
    });

    if (nextProps.formChangeRequested && dataHasChanged) {
      this.setState(
        {
          showMovingConfirmationAlert: true,
          changingSubmission: nextProps.changedSubmission,
          changingAmsFormID: nextProps.changedAmsFormId,
          changingGuideName: nextProps.changedGuideName,
          changingPerformanceName: nextProps.changedPerformanceName,
        },
        () => {
          return;
        }
      );
    }

    if (
      selectedPerformanceMeasure &&
      selectedPerformanceMeasure.formSchema &&
      selectedPerformanceMeasure.uiSchema
    ) {
      const { formSchema, uiSchema } = selectedPerformanceMeasure;

      this.setState({
        formSchema,
        uiSchema,
      });

      if (submission && submission.data && submission.data.surveyData) {
        const { surveyData } = submission.data;
        this.setState({
          formDisabled: !_.isEmpty(surveyData),
          formData: surveyData,
        });
      } else {
        this.setState({ formData: {}, formDisabled: false, submission: {} });
      }
    }
  }

  extractFormDetails = selectedPerformanceMeasure => {
    if (!selectedPerformanceMeasure) return;
    const formDetails = {};

    formDetails.amsFormId =
      selectedPerformanceMeasure.forms[0] &&
      selectedPerformanceMeasure.forms[0].amsFormId;
    formDetails.amsSubmissionId =
      selectedPerformanceMeasure.forms[0] &&
      selectedPerformanceMeasure.forms[0].amsSubmissionId;

    return formDetails;
  };

  resetState = () => {
    this.setState({
      formProcessing: false,
      formDisabled: true,
      editButtonClicked: false,
      reviewId: '',
      amsFormId: '',
      amsSubmissionId: '',
      submittedBy: '',
      submissionStatus: '',
      submissionTime: '',
      selectedCenter: '',
      selectedClassroom: '',
      formSchema: {},
      uiSchema: {},
      formData: {},
      submission: {},
      errors: {},
    });
  };

  //used for key/value pair modification of survey data object, for omitBy lodash function
  //that results in  deleting key/value pairs where the value is not a real user inputted answer
  trimObj = e => {
    return (
      (_.isEmpty(e) ||
        e.length === 0 ||
        (Array.isArray(e) && e[0] === undefined)) &&
      !(e === true || e === false || typeof e === 'number')
    );
  };

  checkDifference = newData => {
    let modified = flattenObj(newData);

    modified = _.omitBy(modified, this.trimObj);

    //checks if flattened surveyData is empty to an empty object, or if its equal to the original survey data submission
    //uses flatten and trim to ensure that the data object does not equate to different because of citation data
    if (
      _.isEqual({}, modified) ||
      _.isEqual(
        modified,
        this.state.submission &&
          this.state.submission.data &&
          _.omitBy(
            flattenObj(this.state.submission.data.surveyData),
            this.trimObj
          )
      )
    ) {
      return false;
    }
    return true;
  };

  handleChange = ({ formData }) => {
    // If Every value of the form field is undefined, set formData to empty so that save buttons are disabled.
    if (Object.values(formData).every(v => v === undefined)) {
      this.setState({ formData: {} });
      return;
    }

    if (this.checkDifference(formData))
      this.setState(
        {
          formData: formData,
          dataHasChanged: true,
          changedFormData: formData,
        },
        () => this.props.dataHasChangedSwitch(true)
      );
  };

  validate = (data, errors) => {
    if (this.state.draftButtonClicked) {
      return {};
    }
    return errors;
  };

  handleError = e => {};

  transformErrors = errors => {
    if (this.state.draftButtonClicked) {
      return [];
    }
    return _.filter(errors, error => {
      if (error.name === 'maxItems') {
        error.message = 'This field ' + error.message + ' selected.';
        return error.name === 'maxItems';
      }
      if (error.name === 'minItems') {
        error.message = 'This is a required field.';
        return error.name === 'minItems';
      }
      if (error.message === 'should be string') {
        error.message = 'Please add at least one file.';
        return error.message === 'Please add at least one file.';
      }
      if (error.name === 'required') {
        error.message = 'This is a required field.';
        return error.name === 'required' || error.name === 'minimum';
      } else if (error.name === 'format') {
        error.message = 'Please enter a valid date';
        return error.name === 'format';
      }
    });
  };

  onSubmit = () => {
    const {
      draftButtonClicked,
      reviewId,
      amsFormId,
      amsSubmissionId,
      formData,
      selectedCenter,
      selectedClassroom,
      submission: { surveyStatus },
    } = this.state;

    const {
      ran: { selectedPerformanceMeasure, selectedGuide },
    } = this.props;

    if (!amsFormId || !reviewId) return;

    let requestObject = { reviewId, amsFormId, surveyData: formData };

    if (amsSubmissionId) requestObject.amsSubmissionId = amsSubmissionId;

    if (selectedCenter) requestObject.centerName = selectedCenter;

    if (selectedClassroom) requestObject.classSampleId = selectedClassroom;

    this.setState({ formProcessing: true });

    // draft
    if (draftButtonClicked) {
      this.props
        .postFormSubmission({
          data: { ...requestObject, submissionStatus: 'Draft' },
        })
        .then(response => {
          this.setState(
            {
              draftButtonClicked: false,
              errors: {},
              showAlert: true,
              alertType: 'success',
              alertMessage: 'Draft saved successfully',
              dataHasChanged: false,
            },
            () => this.props.dataHasChangedSwitch(false)
          );

          const { amsSubmissionId } = response;

          if (amsSubmissionId) {
            this.props.ranUpdateGuide(
              selectedGuide.name,
              selectedPerformanceMeasure.name,
              amsSubmissionId
            );

            this.resetState();

            this.props
              .fetchRANSubmission({
                filters: { amsSubmissionId },
              })
              .then(() => this.setState({ formProcessing: false, errors: {} }))
              .catch(error =>
                this.setState({
                  formProcessing: false,
                  errors: {
                    ...this.state.errors,
                    fetchRANSubmission: error.message,
                  },
                })
              );
          }
        })
        .catch(error =>
          this.setState({
            formProcessing: false,
            draftButtonClicked: false,
            errors: {
              ...this.state.errors,
              ranSaveDraft: error.message,
            },
          })
        );
    } else {
      // update
      if (
        !draftButtonClicked &&
        surveyStatus &&
        surveyStatus.toLowerCase() === 'submitted'
      ) {
        this.props
          .postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
          .then(response => {
            this.setState(
              {
                errors: {},
                showAlert: true,
                alertType: 'success',
                alertMessage: 'Updated successfully',
                dataHasChanged: false,
              },
              () => this.props.dataHasChangedSwitch(false)
            );

            const { amsSubmissionId } = response;
            if (amsSubmissionId) {
              this.resetState();
              this.props
                .fetchRANSubmission({
                  filters: { amsSubmissionId },
                })
                .then(() =>
                  this.setState({ formProcessing: false, errors: {} })
                )
                .catch(error => {
                  this.setState({
                    formProcessing: false,
                    errors: {
                      ...this.state.errors,
                      fetchRANSubmission: error.message,
                    },
                  });
                });
            } else {
              this.setState({ formProcessing: false, errors: {} });
            }
          })
          .catch(error => {
            this.setState({
              formProcessing: false,
              errors: {
                ...this.state.errors,
                ranSaveSubmission: error.message,
              },
            });
          });
        return; // End of update exit.
      }

      // new
      if (!draftButtonClicked) {
        this.props
          .postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
          .then(response => {
            this.setState(
              {
                showAlert: true,
                alertType: 'success',
                alertMessage: 'Data saved to Report',
                dataHasChanged: false,
              },
              () => this.props.dataHasChangedSwitch(false)
            );

            const { amsSubmissionId } = response;
            if (amsSubmissionId) {
              this.props.ranUpdateGuide(
                selectedGuide.name,
                selectedPerformanceMeasure.name,
                amsSubmissionId
              );

              this.resetState();

              this.props
                .fetchRANSubmission({
                  filters: { amsSubmissionId },
                })
                .then(() => this.setState({ formProcessing: false }))
                .catch(error => {
                  this.setState({
                    formProcessing: false,
                    errors: {
                      ...this.state.errors,
                      fetchRANSubmission: error.message,
                    },
                  });
                });
            } else {
              this.setState({ formProcessing: false, errors: {} });
            }
          })
          .catch(error => {
            this.setState({
              formProcessing: false,
              errors: {
                ...this.state.errors,
                ranSaveSubmission: error.message,
              },
            });
          });
      }
    }
  };

  showSuccessConfirmation = () => {
    const { showAlert, alertMessage, alertType } = this.state;

    return (
      <AmsAlert
        show={showAlert}
        title={alertMessage}
        type={alertType || 'success'}
        showConfirm
        onConfirm={() => {
          this.setState({
            showAlert: false,
            alertMessage: '',
            alertType: '',
            dataHasChanged: false,
          });
        }}
      />
    );
  };

  showClearConfirmation = () => {
    const { showClearAlert } = this.state;

    return (
      <AmsAlert
        show={showClearAlert}
        title={'Are you sure you want to clear this form'}
        text={`You have selected to clear the form. Are you sure you want to continue? Note: previously submitted forms will still be retrievable from the version history.`}
        type={'warning'}
        showConfirm
        showCancelButton
        cancelButtonText={'Cancel'}
        confirmButtonText={'Continue'}
        onConfirm={ev => {
          this.setState({
            showClearAlert: false,
            formData: {},
          });
        }}
        onCancel={() => {
          this.setState({
            showClearAlert: false,
          });
        }}
      />
    );
  };

  renderMovingConfirmation = () => {
    const {
      showMovingConfirmationAlert,
      surveyStatus,
      changedFormData,
      changingSubmission,
    } = this.state;
    return (
      <AmsAlert
        show={showMovingConfirmationAlert}
        title="Are you sure you want to move away from this form"
        text={`You selected another page, are you sure you want to leave the current page?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={
          surveyStatus
            ? surveyStatus.toLowerCase() === 'submitted'
              ? 'Submit and continue'
              : 'Save draft and continue'
            : changingSubmission &&
              changingSubmission.surveyStatus &&
              changingSubmission.surveyStatus.toLowerCase() === 'submitted'
            ? 'Submit and continue'
            : 'Save draft and continue'
        }
        cancelButtonText={'Continue without saving'}
        showConfirm
        showCancelButton
        showThirdButton
        thirdButtonText="Cancel"
        onThirdButtonAction={() => {
          this.setState(
            {
              formDisabled: false,
              showMovingConfirmationAlert: false,
              cancelMovingAway: true,
            },
            () => {
              this.props.ranPerformanceMeasureSelected(
                this.state.changingPerformanceName
              );
            }
          );
        }}
        onCancel={() => {
          this.setState(
            {
              showMovingConfirmationAlert: false,
              dataHasChanged: false,
            },
            () => {
              // this.props.showAlert(false, {});
              this.props.dataHasChangedSwitch(false);
            }
          );
        }}
        onConfirm={() => {
          if (changedFormData) {
            this.setState(
              {
                draftButtonClicked:
                  changingSubmission &&
                  changingSubmission.surveyStatus &&
                  changingSubmission.surveyStatus.toLowerCase() === 'submitted'
                    ? false
                    : true,
              },
              () => this.saveRANDataChanges(changedFormData)
            );
          }
        }}
      />
    );
  };

  saveRANDataChanges = data => {
    const {
      draftButtonClicked,
      reviewId,
      changingAmsFormID,
      selectedCenter,
      selectedClassroom,
      changingSubmission: { surveyStatus, amsSubmissionId },
      changedFormData,
      changingGuideName,
      changingPerformanceName,
    } = this.state;

    const {
      ran: { selectedPerformanceMeasure, selectedGuide },
    } = this.props;

    if (!changingAmsFormID || !reviewId) return;

    let requestObject = {
      reviewId,
      amsFormId: changingAmsFormID,
      surveyData: changedFormData,
    };

    if (amsSubmissionId) requestObject.amsSubmissionId = amsSubmissionId;

    if (selectedCenter) requestObject.centerName = selectedCenter;

    if (selectedClassroom) requestObject.classSampleId = selectedClassroom;

    this.setState({ formProcessing: true });

    // draft
    if (draftButtonClicked) {
      this.props
        .postFormSubmission({
          data: { ...requestObject, submissionStatus: 'Draft' },
        })
        .then(response => {
          this.setState({
            draftButtonClicked: false,
            errors: {},
            dataHasChanged: false,
          });

          const { amsSubmissionId } = response;

          if (amsSubmissionId) {
            this.props.ranUpdateGuide(
              changingGuideName,
              changingPerformanceName,
              amsSubmissionId,
              selectedCenter,
              selectedClassroom
            );

            this.setState(
              {
                formProcessing: false,
                errors: {},
                showMovingConfirmationAlert: false,
              },
              () => {
                this.resetState();
                this.props.dataHasChangedSwitch(false);
              }
            );
          } else {
            this.setState({
              formProcessing: false,
              errors: {},
              showMovingConfirmationAlert: false,
            });
          }
        })
        .catch(error => {
          this.setState({
            formProcessing: false,
            draftButtonClicked: false,
            errors: {
              ...this.state.errors,
              ranSaveDraft: error.message,
            },
            showMovingConfirmationAlert: false,
            dataHasChanged: false,
          });
        });
    } else {
      // update
      if (
        !draftButtonClicked &&
        surveyStatus &&
        surveyStatus.toLowerCase() === 'submitted'
      ) {
        this.props
          .postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
          .then(response => {
            this.setState(
              {
                errors: {},
                dataHasChanged: false,
              },
              () => this.props.dataHasChangedSwitch(false)
            );
            const { amsSubmissionId } = response;
            if (amsSubmissionId) {
              this.setState({
                formProcessing: false,
                errors: {},
                showMovingConfirmationAlert: false,
              });
            } else {
              this.setState({
                formProcessing: false,
                errors: {},
                dataHasChanged: false,
                showMovingConfirmationAlert: false,
              });
            }
          })
          .catch(error => {
            this.setState({
              formProcessing: false,
              errors: {
                ...this.state.errors,
                ranSaveSubmission: error.message,
              },
              showMovingConfirmationAlert: false,
              dataHasChanged: false,
            });
          });
        return; // End of update exit.
      }

      // new
      if (!draftButtonClicked) {
        this.props
          .postFormSubmission({
            data: { ...requestObject, submissionStatus: 'Submitted' },
          })
          .then(response => {
            this.setState({
              dataHasChanged: false,
            });
            const { amsSubmissionId } = response;
            if (amsSubmissionId) {
              this.props.ranUpdateGuide(
                selectedGuide.name,
                selectedPerformanceMeasure.name,
                amsSubmissionId,
                selectedCenter,
                selectedClassroom
              );
              this.setState(
                {
                  formProcessing: false,
                  errors: {},
                  showMovingConfirmationAlert: false,
                },
                () => {
                  this.resetState();
                  this.props.dataHasChangedSwitch(false);
                }
              );
            } else {
              this.setState({
                formProcessing: false,
                errors: {},
                showMovingConfirmationAlert: false,
              });
            }
          })
          .catch(error => {
            this.setState({
              formProcessing: false,
              errors: {
                ...this.state.errors,
                ranSaveSubmission: error.message,
              },
              showMovingConfirmationAlert: false,
              dataHasChanged: false,
            });
          });
      }
    }
  };

  showDraftButton = () => {
    const {
      formData,
      formSchema,
      submission: { surveyStatus },
      editButtonClicked,
    } = this.state;

    const { ran } = this.props;

    if (ran && ran.readOnly) {
      return null;
    }

    if (ran && ran.isReportFinalized) return;

    if (ran && !ran.isReviewAccessible) return;

    if (
      ran &&
      ran.reviewStatus &&
      ran.reviewStatus.toLowerCase() === 'cancelled'
    )
      return null;

    if (_.isEmpty(formSchema)) return null;

    if (
      !surveyStatus ||
      (formData &&
        surveyStatus &&
        surveyStatus.toLowerCase() === 'draft' &&
        editButtonClicked)
    )
      return (
        <SemanticForm.Button
          type="submit"
          content="Save as Draft"
          disabled={_.isEmpty(formData)}
          onClick={() => this.setState({ draftButtonClicked: true })}
        />
      );

    return null;
  };

  showEditButton = () => {
    const {
      submission: { editable },
      formSchema,
    } = this.state;

    const { ran } = this.props;

    if (ran && ran.readOnly) return;

    if (ran && ran.isReportFinalized) return;

    if (ran && !ran.isReviewAccessible) return;

    if (
      ran &&
      ran.reviewStatus &&
      ran.reviewStatus.toLowerCase() === 'cancelled'
    )
      return null;

    if (this.state.editButtonClicked) return;

    if (_.isEmpty(formSchema)) return null;

    if (editable) {
      return (
        <SemanticForm.Button
          content={'Edit'}
          color="green"
          icon="edit"
          onClick={e => {
            e.preventDefault();
            this.setState({ formDisabled: false, editButtonClicked: true });
          }}
        />
      );
    }
  };

  showSubmitButton = () => {
    const {
      formData,
      submission: { surveyStatus },
      editButtonClicked,
      formSchema,
    } = this.state;

    const { ran } = this.props;

    if (ran && ran.isReportFinalized) return;

    if (ran && ran.readOnly) return null;

    if (ran && !ran.isReviewAccessible) return;

    if (
      ran &&
      ran.reviewStatus &&
      ran.reviewStatus.toLowerCase() === 'cancelled'
    )
      return null;

    if (_.isEmpty(formSchema)) return null;

    if (!surveyStatus || (formData && surveyStatus && editButtonClicked))
      return (
        <SemanticForm.Button
          disabled={_.isEmpty(formData)}
          type="submit"
          primary
          content={'Submit'}
        />
      );
  };

  showCancelButton = () => {
    const { ran } = this.props;

    const {
      formData,
      submission: { surveyStatus },
      editButtonClicked,
    } = this.state;

    const { submission } = this.props.ran;

    if (ran && ran.readOnly) return null;

    if (
      !editButtonClicked ||
      (formData && surveyStatus && surveyStatus.toLowerCase() !== 'submitted')
    )
      return null;
    else
      return (
        <SemanticForm.Button
          negative
          // size="large"
          content={'Cancel'}
          onClick={() => {
            this.setState(
              {
                editButtonClicked: false,
                formDisabled: true,
                dataHasChanged: false,
                formData:
                  submission &&
                  submission.data &&
                  submission.data.surveyData &&
                  submission.data.surveyData,
              },
              () => this.props.dataHasChangedSwitch(false)
            );
          }}
        />
      );
  };

  showClearButton = () => {
    const { ran } = this.props;

    const {
      formData,
      submission: { surveyStatus },
      editButtonClicked,
    } = this.state;

    const { submission } = this.props.ran;

    if (!ran) return null;

    if (ran && ran.readOnly) return null;

    if (
      submission &&
      submission.data &&
      submission.data.surveyData &&
      !editButtonClicked
    ) {
      return null;
    }

    return (
      <SemanticForm.Button
        secondary
        content={'Clear Form'}
        disabled={_.isEmpty(formData)}
        onClick={ev => {
          ev.preventDefault();
          this.setState({ showClearAlert: true });
        }}
      />
    );
  };

  renderVersionTable = () => {
    if (_.isEmpty(this.state.submission)) return null;

    const {
      submission: { versionHistory },
      amsSubmissionId,
    } = this.state;

    const linkVersion = (cell, row) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            const version = cell;

            // Fetch specific version.
            this.setState({ formProcessing: true });
            this.props
              .fetchRANSubmission({
                filters: { amsSubmissionId, version },
              })
              .then(() => this.setState({ formProcessing: false }))
              .catch(error => {
                this.setState({
                  formProcessing: false,
                  errors: {
                    ...this.state.errors,
                    fetchRANSubmission: error.message,
                  },
                });
              });
          }}
        >
          {cell}
        </a>
      );
    };

    const formatDate = (cell, row) => AmsDateFormatters.formatDateTime(cell);

    const columns = [
      {
        dataField: 'version',
        text: 'Version',
        sort: true,
        formatter: linkVersion,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editedByFullName',
        text: 'Edited By',
        sort: true,
        style: { whiteSpace: 'normal' },
      },
      {
        dataField: 'editTime',
        sort: true,
        formatter: formatDate,
        style: { whiteSpace: 'normal' },
        text: 'Edited Time',
      },
    ];
    return (
      <AmsTable
        remote={false}
        basic
        total={versionHistory.length}
        columns={columns}
        keyField="version"
        ref="table"
        data={versionHistory}
      />
    );
  };

  renderSurveyStatus = () => {
    if (_.isEmpty(this.state.submission)) return null;

    const {
      submission: {
        amsSubmissionId,
        surveyStatus,
        data: { editedBy, editTime },
      },
    } = this.state;

    if (!amsSubmissionId) return null;

    return (
      <Well>
        {editedBy && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submitted By"
            >
              Submitted By:{' '}
            </span>
            {editedBy}
          </div>
        )}
        {surveyStatus && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Status"
            >
              Submission Status:{' '}
            </span>
            {surveyStatus}
          </div>
        )}
        {editTime && (
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Submission Time"
            >
              Submission Time:{' '}
            </span>
            {AmsDateFormatters.formatDateTime(editTime)}
          </div>
        )}
      </Well>
    );
  };

  customDescriptionField = ({ id, description }) => {
    // IF there is no description, hide the link
    if (
      _.isEmpty(description) ||
      (description && _.isEmpty(description[0].text))
    )
      return null;

    return (
      <span>
        {description.map((d, i) => [
          i > 0 && '; ',
          <Link
            to={{
              pathname: `/review/citations/definition`,
              query: { citation: d.citationNumber },
            }}
            target="_blank"
          >
            <Popup
              trigger={
                <Label
                  style={{
                    color: 'blue',
                    background: 'white',
                    textDecorationLine: 'underline',
                  }}
                >
                  {' '}
                  {d ? d.citationNumber : ''}
                </Label>
              }
              content="Select to see Citation Definition"
            />
          </Link>,
        ])}
      </span>
    );
  };

  fields = () => {
    return {
      DescriptionField: this.customDescriptionField,
      attachmentField: AttachmentField,
    };
  };

  renderDescription = () => {
    const {
      ran: { selectedPerformanceMeasure },
    } = this.props;

    if (!selectedPerformanceMeasure) return;

    const { description } = selectedPerformanceMeasure;

    if (description) return <Header as="h3">{description}</Header>;
  };

  render() {
    const { formSchema, uiSchema, formData } = this.state;
    const { ran } = this.props;

    if (!formSchema && !uiSchema) return null;

    return (
      <div className="row form-container">
        <ShowErrors errors={this.state.errors} />
        {this.renderMovingConfirmation()}
        {this.showSuccessConfirmation()}
        {this.showClearConfirmation()}
        <Segment basic loading={this.state.formProcessing}>
          {this.renderSurveyStatus()}
          {this.renderDescription()}

          <div className="text-right">{this.showEditButton()}</div>

          <fieldset
            disabled={
              (ran && ran.isReportFinalized) ||
              (ran &&
                ran.reviewStatus &&
                ran.reviewStatus.toLowerCase() === 'cancelled') ||
              (ran && ran.readOnly) ||
              (ran && !ran.isReviewAccessible)
                ? true
                : this.state.formDisabled
            }
          >
            <Form
              ArrayFieldTemplate={ArrayFieldTemplate}
              key={this.state.amsFormId}
              schema={formSchema}
              uiSchema={uiSchema}
              formData={formData}
              onSubmit={this.onSubmit}
              onChange={this.handleChange}
              onError={this.handleError}
              validate={this.validate}
              liveValidate={false}
              transformErrors={this.transformErrors}
              showErrorList={false}
              noHtml5Validate
              fields={this.fields()}
            >
              <div className="ui form">
                {ran && !ran.isReportFinalized && (
                  <SemanticForm.Group>
                    {this.showDraftButton()}
                    {this.showSubmitButton()}
                    {this.showCancelButton()}
                    {this.showClearButton()}
                  </SemanticForm.Group>
                )}
              </div>
            </Form>
          </fieldset>
          <div className="text-right">{this.showEditButton()}</div>
          <br />
          <br />
          {this.renderVersionTable()}
        </Segment>
      </div>
    );
  }
}

RANForm.propTypes = {
  ran: PropTypes.object,
  ranSaveDraft: PropTypes.func.isRequired,
  ranSaveSubmission: PropTypes.func.isRequired,
  ranUpdateSubmission: PropTypes.func.isRequired,
  fetchRANSubmission: PropTypes.func.isRequired,
  ranUpdateGuide: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ran: state.forms.ran,
});

export default connect(mapStateToProps, {
  ranSaveDraft,
  ranSaveSubmission,
  postFormSubmission,
  fetchRANSubmission,
  ranUpdateSubmission,
  ranUpdateGuide,
  ranPerformanceMeasureSelected,
  clearFA1Forms,
})(RANForm);
