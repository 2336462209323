import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_ERROR,
} from '../actions/notificationActions';

const initialState = {
  notifications: [],
  error: {},
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      return {
        notifications: action.notifications,
      };
    }

    case SET_NOTIFICATIONS_ERROR: {
      return { ...state, error: action.error };
    }

    default:
      return state;
  }
}
