/* eslint-disable jsx-a11y/anchor-is-valid */
import { concat, includes, isEmpty, isObject, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Label,
  List,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';

import { find, orderBy } from 'lodash';

// Import components
import AmsAlert from '../../../utils/AmsAlert';
import AmsModal from '../../../utils/AmsModal';
import AmsTable from '../../../utils/AmsTable';
import ReviewerFilter from '../../../utils/ReviewerFilter';
// import ReviewerSearch from '../../../utils/ReviewerSearch';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import ReviewerMaxWarningModal from './ReviewerMaxWarningModal';

// Import actions.
import {
  addPosition,
  addPositionReviewer,
  incrementNumberOfReviewers,
  selectedPosition,
  updatePositionReviewer,
} from '../../../actions/reviewActions';

import '../assets/style.css';

const style = {
  positionHeader: {
    background: '#f3f4f5',
  },
  skillHeader: {
    fontSize: '.8125rem',
    fontWeight: '400',
    textTransform: 'upperCase',
    color: 'rgba(0,0,0,.6)',
    letterSpacing: '.05em',
    marginBottom: '.5rem',
    marginTop: 0,
  },
  disableButton: {
    marginLeft: '10px',
  },
  assignButton: {
    marginLeft: '10px',
    cursor: 'pointer',
  },
};

const ReviewersPosition = ({
  startDate,
  endDate,
  reviewType,
  grantee,
  granteeRegions,
  edit,
  history,
}) => {
  const {
    positions,
    reviewers,
    numberOfReviewers,
    numberOfReviewersAssigned,
  } = useSelector(store => store.review.reviewInfo);
  const [reviewerPositionSkills, setReviewerPostion] = useState({
    languageSkills: [],
    contentAreaSkills: [],
    otherSkills: [],
    reviewersRequired: null,
  });
  const [modalOpen, setOpenModal] = useState(false);
  const [positionsList, setPositions] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [editPosition, setEditPositions] = useState(false);
  const [editReviewer, setEditReviewer] = useState(false);
  const [showConfirmEditReviewer, setShowConfirmEditReviewer] = useState(false);
  const [editReviewerData, setEditReviewerData] = useState({});
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [historyModalData, setHistoryModalData] = useState(null);
  const [showReviewerWarning, setShowReviewerWarning] = useState(false);
  const [reviewerWarningData, setReviewerWarningData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const updatePositions = () => {
      setPositions(positions);
    };

    updatePositions();
  });

  const onChangeAddSkills = (e, { name, value }) => {
    if (name === 'reviewersRequired') {
      value = Number(value);
    }
    let reviewerPosition = reviewerPositionSkills;
    reviewerPosition = {
      ...reviewerPosition,
      [name]: value,
    };

    setReviewerPostion(reviewerPosition);
  };

  const addPositions = () => {
    if (editPosition) {
      positionsList[reviewerPositionSkills._id] = {
        ...reviewerPositionSkills,
      };
    } else
      positionsList.push({
        ...reviewerPositionSkills,
        active: true,
      });

    const updatedPositions = positionsList.map((position, index) => {
      position = {
        ...position,
        _id: position.index ? position.index.toString() : index.toString(),
        name: position.name ? position.name : `Position ${index + 1}`,
      };

      return position;
    });

    dispatch(addPosition(updatedPositions));
    setReviewerPostion({});
  };

  const handleModalOpen = e => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setEditPositions(false);
    setReviewerPostion({});
    setOpenModal(false);
  };

  const renderSectionTitle = () => {
    let counter = 0;
    let assignedReviewers = 0;
    let totalReviewers = 0;

    positions &&
      positions.forEach(e => {
        const filteredReviewers = reviewers.filter(
          reviewer => reviewer.position === e._id
        );

        const readyReviewersCount = filteredReviewers.filter(
          r => r.status === 'assigned'
        ).length;

        assignedReviewers = assignedReviewers + readyReviewersCount;
        totalReviewers = totalReviewers + e.reviewersRequired;

        if (readyReviewersCount === e.reviewersRequired) {
          counter++;
        }
      });

    return (
      <Grid.Row>
        <Grid.Column verticalAlign="middle">
          <Header as="h2">
            <Icon name="user circle" />
            <Header.Content>
              Reviewers{' '}
              <Header.Subheader>
                {`${counter} of ${positions &&
                  positions.length} positions filled`}{' '}
                and{' '}
                {`${
                  numberOfReviewersAssigned < assignedReviewers
                    ? assignedReviewers
                    : numberOfReviewersAssigned
                } of ${
                  numberOfReviewers < totalReviewers
                    ? totalReviewers
                    : numberOfReviewers
                } reviewers assigned`}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>

        <Grid.Column
          verticalAlign="middle"
          textAlign="right"
          width={3}
        ></Grid.Column>

        <Grid.Column verticalAlign="middle" textAlign="right" width={3}>
          {renderHistoryModal()}
          <AmsModal
            trigger={
              <Button
                basic
                fluid
                color="blue"
                size="large"
                content="Add New Position"
                onClick={handleModalOpen}
              />
            }
            size="small"
            centered={false}
            closeOnDimmerClick={false}
            onClose={handleModalClose}
            open={modalOpen}
            className="ams-semantic-modal-fix"
            closeOnEscape={false}
            // style={{ overflowY: 'hidden' }}
          >
            <Header content="Select skills for position" />
            <Modal.Content>
              <Modal.Description>
                <Form.Field required>
                  <AmsFormLabel
                    name="How many reviewers do you need for this position?"
                    fieldLabel="reviewersRequired"
                  >
                    <Input
                      width={7}
                      required
                      name="reviewersRequired"
                      placeholder="Enter the number of reviewers needed"
                      value={
                        reviewerPositionSkills &&
                        reviewerPositionSkills.reviewersRequired
                          ? reviewerPositionSkills.reviewersRequired
                          : null
                      }
                      onChange={(e, data) => onChangeAddSkills(e, data)}
                      control={Input}
                      style={{ width: '100%' }}
                      type="number"
                      id="reviewersRequired"
                      fluid
                      search
                      selection
                    />
                  </AmsFormLabel>
                </Form.Field>
                <Form.Field required>
                  <AmsFormLabel
                    name="Content Area Skills"
                    fieldLabel="contentAreaSkills"
                  >
                    <AmsLookupDropdown
                      width={7}
                      name="contentAreaSkills"
                      placeholder="Select Content Area Skills"
                      value={
                        reviewerPositionSkills &&
                        reviewerPositionSkills.contentAreaSkills &&
                        reviewerPositionSkills.contentAreaSkills.length > 0
                          ? reviewerPositionSkills.contentAreaSkills
                          : []
                      }
                      onChange={(e, data) => onChangeAddSkills(e, data)}
                      category={'contentAreaSkills'}
                      fluid
                      multiple
                      // search
                      selection
                      // searchInput={{
                      //   id: 'contentAreaSkillsSearch',
                      //   title: 'Select Content Area Skills',
                      // }}
                      id="contentAreaSkills"
                      // aria-labelledby="contentAreaSkills"
                    />
                  </AmsFormLabel>
                </Form.Field>
                <Form.Field>
                  <AmsFormLabel
                    name="Language Skills"
                    fieldLabel="languageSkills"
                  >
                    <AmsLookupDropdown
                      name="languageSkills"
                      placeholder="Select Language Skills"
                      value={
                        reviewerPositionSkills &&
                        reviewerPositionSkills.languageSkills &&
                        reviewerPositionSkills.languageSkills.length > 0
                          ? reviewerPositionSkills.languageSkills
                          : []
                      }
                      onChange={(e, data) => onChangeAddSkills(e, data)}
                      category={'languages'}
                      fluid
                      multiple
                      // search
                      selection
                      // searchInput={{
                      //   id: 'languageSkills',
                      //   title: 'Select Language Skills',
                      // }}
                      // aria-labelledby="languageSkills"
                      id="languageSkills"
                    />
                  </AmsFormLabel>
                </Form.Field>
                <Form.Field>
                  <AmsFormLabel name="Other Skills" fieldLabel="otherSkills">
                    <AmsLookupDropdown
                      width={7}
                      name="otherSkills"
                      placeholder="Select Other Skills"
                      value={
                        reviewerPositionSkills &&
                        reviewerPositionSkills.otherSkills &&
                        reviewerPositionSkills.otherSkills.length > 0
                          ? reviewerPositionSkills.otherSkills
                          : []
                      }
                      onChange={(e, data) => onChangeAddSkills(e, data)}
                      category={'otherSkills'}
                      fluid
                      multiple
                      // search
                      selection
                      // searchInput={{
                      //   id: 'otherSkillsSearch',
                      //   title: 'Select Other Skills',
                      // }}
                      id="otherSkills"
                      // aria-labelledby="otherSkills"
                    />
                  </AmsFormLabel>
                </Form.Field>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={handleModalClose} content="Close" />
              <Button
                disabled={
                  ((reviewerPositionSkills.contentAreaSkills &&
                    reviewerPositionSkills.contentAreaSkills.length > 0) ||
                    (reviewerPositionSkills.languageSkills &&
                      reviewerPositionSkills.languageSkills.length > 0) ||
                    (reviewerPositionSkills.otherSkills &&
                      reviewerPositionSkills.otherSkills.length > 0)) &&
                  reviewerPositionSkills.reviewersRequired &&
                  reviewerPositionSkills.reviewersRequired > 0
                    ? false
                    : true
                }
                onClick={() => {
                  setEditPositions(false);
                  setOpenModal(false);
                  addPositions();
                }}
                primary
                content="Save position"
              />
            </Modal.Actions>
          </AmsModal>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const renderHistoryModal = () => {
    return (
      <AmsModal
        centered={false}
        closeOnDimmerClick={false}
        onClose={() => {
          setHistoryModalOpen(false);
        }}
        open={historyModalOpen}
        className="ams-semantic-modal-fix"
        // style={{ overflowY: 'hidden' }}
      >
        <Header
          content={`${historyModalData && historyModalData.name}'s History`}
        />
        <Modal.Content>
          <Modal.Description>
            {generateReviewerHistoryTable()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setHistoryModalOpen(false)} content="Close" />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const generateReviewerHistoryTable = () => {
    const assignedDateFormatter = (cell, row) => {
      cell = orderBy(
        cell,
        o => {
          return AmsDateFormatters.formatDate(o);
        },
        ['asc']
      );

      return (
        <>
          <Label.Group circular>
            {cell.map((e, index) => {
              return (
                <Popup
                  style={{ zIndex: '100000' }}
                  key={index}
                  trigger={
                    <Label as="a">
                      {AmsDateFormatters.getMoment(e).format('dd')}
                    </Label>
                  }
                  content={e}
                  size="small"
                />
              );
            })}
          </Label.Group>
        </>
      );
    };

    const modalData = historyModalData && historyModalData.data;

    if (!modalData) {
      return null;
    }
    if (modalData.length === 0) {
      return <div>No results found.</div>;
    }

    const columns = [
      {
        dataField: 'oid',
        text: 'ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'dates',
        text: 'Invited/Assigned Days',
        width: '30%',
        formatter: assignedDateFormatter,
      },
      { dataField: 'invitationDate', text: 'Invitation Date' },
    ];

    const total = modalData.length;

    return (
      <AmsTable
        remote={false}
        data={modalData}
        basic
        pagination={false}
        total={total}
        columns={columns}
        loading={false}
        options={{
          hideSizePerPage: true,

          showTotal: false,
        }}
        showTotal={false}
      />
    );
  };

  const renderPositionReviewersCounter = position => {
    const filteredReviewers = reviewers.filter(
      reviewer => reviewer.position === position._id
    );

    const readyReviewersCount = filteredReviewers.filter(
      r => r.status === 'assigned'
    ).length;

    return (
      <>
        {position.reviewersRequired > 0 && (
          <List.Item>
            <List.Content>
              <List.Header style={style.skillHeader}>
                Reviewers Assigned
              </List.Header>
              <Header as="h3" textAlign="center" style={{ marginTop: '0px' }}>
                {`${readyReviewersCount} of ${position.reviewersRequired}`}
              </Header>
            </List.Content>
          </List.Item>
        )}
      </>
    );
  };

  const checkReviewersRequired = position => {
    if (!isObject(position)) {
      position = positionsList.find(e => e._id === position);
    }
    const filteredReviewers = reviewers.filter(
      reviewer => reviewer.position === position._id
    );

    const readyReviewersCount = filteredReviewers.filter(
      r => r.status === 'assigned'
    ).length;

    if (position.reviewersRequired === readyReviewersCount) {
      return false;
    }
    return true;
  };

  const renderPositionSkills = position => (
    <List horizontal relaxed="very">
      {position && !isEmpty(position.contentAreaSkills) && (
        <List.Item>
          <List.Content>
            <List.Header style={style.skillHeader}>Content Skills</List.Header>
            <Header as="h3">{position.contentAreaSkills.join(', ')}</Header>
          </List.Content>
        </List.Item>
      )}

      {position && !isEmpty(position.languageSkills) && (
        <List.Item>
          <List.Content>
            <List.Header style={style.skillHeader}>Language Skills</List.Header>
            <Header as="h3">{position.languageSkills.join(', ')}</Header>
          </List.Content>
        </List.Item>
      )}

      {position && !isEmpty(position.otherSkills) && (
        <List.Item>
          <List.Content>
            <List.Header style={style.skillHeader}>Other Skills</List.Header>
            <Header as="h3">{position.otherSkills.join(', ')}</Header>
          </List.Content>
        </List.Item>
      )}
    </List>
  );

  const renderPositionReviewers = position => {
    const filteredReviewers = reviewers.filter(
      reviewer => reviewer.position === position._id
    );

    const assignedDateFormatter = (cell, row) => {
      cell = orderBy(
        cell,
        o => {
          return AmsDateFormatters.formatDate(o);
        },
        ['asc']
      );

      return (
        <>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column align={'right'}>
                <Label.Group circular>
                  {cell.map((e, index) => {
                    return (
                      <Popup
                        key={index}
                        trigger={
                          <Label as="a">
                            {AmsDateFormatters.getMoment(e).format('dd')}
                          </Label>
                        }
                        content={e}
                        size="small"
                      />
                    );
                  })}
                </Label.Group>
              </Grid.Column>
              <Grid.Column>
                <Button
                  basic
                  size="mini"
                  color="green"
                  content="Edit Dates"
                  onClick={e => {
                    e.preventDefault();
                    setEditReviewer(row);
                  }}
                ></Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      );
    };

    const nameFormatter = (cell, row) => {
      const hasHistory = history && history.filter(e => e && e.oid === row.oid);
      return (
        row && (
          <Grid style={{ marginLeft: '2px' }}>
            <Grid.Row columns={2}>
              <Grid.Column width={10}>
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header as="h4" content={row.name} />
                      <Item.Meta>{row.userName} </Item.Meta>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '10px' }} width={6}>
                {hasHistory && hasHistory.length > 0 && (
                  <Button
                    onClick={e => {
                      setHistoryModalOpen(true);
                      setHistoryModalData({
                        data: hasHistory,
                        name: row.fullName,
                      });
                    }}
                    basic
                    size="tiny"
                  >
                    <Icon name="history" />
                    History
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      );
    };

    const statusFormatter = (cell, row) => {
      switch (cell) {
        case 'accepted':
          return (
            <>
              Invitation accepted{' '}
              <a
                href="#"
                style={style.assignButton}
                onClick={e => {
                  e.preventDefault();

                  if (!checkReviewersRequired(position)) {
                    setShowReviewerWarning(true);
                    setReviewerWarningData({ row, type: 'assign', position });
                    return;
                  }

                  reviewers.forEach((reviewer, index) => {
                    if (reviewer.oid === row.oid)
                      reviewers[index].status = 'assigned';

                    return reviewer;
                  });

                  dispatch(addPositionReviewer(reviewers));
                }}
              >
                Assign
              </a>
            </>
          );
        case 'pending':
          return 'Invitation pending';
        case 'assigned':
          return 'Reviewer assigned';
        case 'declined':
          return 'Invitation declined';
        case 'withdrawn':
          return 'Invitation withdrawn';
        // eslint-disable-next-line no-duplicate-case
        case 'withdrawn':
          return 'Withdrawn';
        default:
          return 'Invitation pending';
      }
    };

    const actionFormatter = (cell, row) => {
      if (
        row.status === 'invitationRescinded' ||
        row.status === 'withdrawn' ||
        row.status === 'declined' ||
        !position.active
      )
        return;

      return (
        row && (
          <>
            <Button
              basic
              size="mini"
              color="red"
              content="Withdraw"
              onClick={e => {
                e.preventDefault();

                reviewers.forEach((reviewer, index) => {
                  if (reviewer.oid === row.oid)
                    reviewers[index].status = 'invitationRescinded';

                  return reviewer;
                });

                dispatch(addPositionReviewer(reviewers));
              }}
            />
          </>
        )
      );
    };

    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        formatter: nameFormatter,
        headerStyle: {
          width: '25%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'invitationDate',
        text: 'Invitation Date',
        headerStyle: {
          width: '15%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'homeState',
        text: 'State',
        headerStyle: {
          width: '10%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        formatter: statusFormatter,
        headerStyle: {
          width: '15%',
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'dates',
        text: 'Invited/Assigned Days',
        headerStyle: {
          width: '25%',
        },
        formatter: assignedDateFormatter,
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
      {
        dataField: 'id',
        isDummyField: true,
        text: 'Action',
        formatter: actionFormatter,
        align: 'right',
        headerFormatter: (column, colIndex) => {
          return <span style={{ visibility: 'hidden' }}>{column.text}</span>;
        },
        headerAttrs: {
          scope: 'col',
          tabIndex: -1,
        },
      },
    ];

    const rowClasses = (row, rowIndex) => {
      let classes = null;
      if (
        row.status === 'invitationRescinded' ||
        row.status === 'withdrawn' ||
        row.status === 'declined'
      ) {
        classes = 'reviewer-search-withdrawn-row';
      }
      return classes;
    };

    return (
      <>
        {!isEmpty(filteredReviewers) ? (
          <>
            <BootstrapTable
              keyField="oid"
              rowClasses={rowClasses}
              data={filteredReviewers}
              columns={columns}
              bordered={false}
            />
            <Segment basic textAlign="center" disabled={!position.active}>
              <Button
                basic
                color="blue"
                content={`Add reviewers for ${position.name}`}
                disabled={!(startDate && endDate)}
                onClick={e => {
                  if (e) e.preventDefault();

                  if (!checkReviewersRequired(position)) {
                    setShowReviewerWarning(true);
                    setReviewerWarningData({ position, type: 'add' });
                    return;
                  }

                  setShowSearchModal(true);
                  dispatch(selectedPosition(position, true));
                }}
              />
            </Segment>
          </>
        ) : (
          <Segment basic textAlign="center" disabled={!position.active}>
            <Header color="grey">
              There are no reviewers added to this position.
            </Header>

            <div>
              <Button
                disabled={!(startDate && endDate)}
                basic
                color="blue"
                content="Add new reviewer"
                onClick={e => {
                  if (e) e.preventDefault();
                  setShowSearchModal(true);
                  dispatch(selectedPosition(position, true));
                }}
              />
            </div>
          </Segment>
        )}
      </>
    );
  };

  const renderPositionCards = () => {
    if (!positions) return;

    const sortedPositions = sortBy(positions, o => [!o.active, o._id]);

    return sortedPositions.map((position, index) => {
      const checkReviewerStatus = reviewers.map(reviewer => {
        if (
          reviewer.position === position._id &&
          reviewer.status !== 'withdrawn'
        )
          return true;
        else return false;
      });
      const enableEdit = includes(checkReviewerStatus, true) ? false : true;
      return (
        <Grid.Row key={index}>
          <Grid.Column verticalAlign="middle">
            <Segment
              basic
              disabled={!position.active}
              // style={{ padding: '0px' }}
            >
              <Header as="h3">
                {position.name}
                <Button
                  basic
                  size="tiny"
                  content="Disable position"
                  style={style.disableButton}
                  disabled={!position.active}
                  onClick={e => {
                    e.preventDefault();

                    sortedPositions.forEach((p, i) => {
                      if (p._id === position._id) {
                        positions[i].active = false;
                      }

                      return p;
                    });

                    dispatch(addPosition(positions));

                    reviewers.forEach((reviewer, index) => {
                      if (reviewer.position === position._id)
                        reviewers[index].status = 'invitationRescinded';

                      return reviewer;
                    });

                    dispatch(addPositionReviewer(reviewers));
                  }}
                />
                {enableEdit && position.active && (
                  <Button
                    basic
                    size="tiny"
                    color="green"
                    content="Edit"
                    onClick={e => {
                      e.preventDefault();
                      setReviewerPostion(position);
                      setEditPositions(true);
                      handleModalOpen(e);
                    }}
                  />
                )}
              </Header>

              <Segment clearing attached="top" style={style.positionHeader}>
                <Header floated="right" style={{ marginBottom: '0px' }}>
                  {renderPositionReviewersCounter(position)}
                </Header>
                <Header floated="left" style={{ marginBottom: '0px' }}>
                  {renderPositionSkills(position)}
                </Header>
              </Segment>

              <Segment attached>{renderPositionReviewers(position)}</Segment>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  const searchModalToggle = () => {
    setShowSearchModal(false);
    setEditReviewer(false);
    dispatch(selectedPosition({}, false));
  };

  const updateReviewer = (reviewer, data) => {
    setEditReviewer(false);
    setShowConfirmEditReviewer(true);
    setEditReviewerData({ reviewer, data });
  };

  const maxReviewersRequiredConfirmed = position => {
    positionsList[position._id] = {
      ...position,
      reviewersRequired: position.reviewersRequired + 1,
    };

    dispatch(addPosition(positionsList));
    dispatch(incrementNumberOfReviewers());
    setReviewerPostion({});
  };

  const renderMaxReviewerWarningModal = () => {
    return (
      <ReviewerMaxWarningModal
        show={showReviewerWarning}
        onConfirm={() => {
          if (reviewerWarningData.type === 'add') {
            setShowSearchModal(true);
            dispatch(selectedPosition(reviewerWarningData.position, true));
            setReviewerWarningData({});
            setShowReviewerWarning(false);
          } else {
            reviewers.forEach((reviewer, index) => {
              if (reviewer.oid === reviewerWarningData.row.oid)
                reviewers[index].status = 'assigned';

              return reviewer;
            });

            dispatch(addPositionReviewer(reviewers));
            maxReviewersRequiredConfirmed(reviewerWarningData.position);
            setReviewerWarningData({});
            setShowReviewerWarning(false);
          }
        }}
        onCancel={() => {
          setReviewerWarningData({});
          setShowReviewerWarning(false);
        }}
      ></ReviewerMaxWarningModal>
    );
  };

  const renderConfirmationReviewerEditDialog = () => {
    if (!editReviewerData.reviewer) return null;
    const prevDates =
      editReviewerData.reviewer.previousDates ||
      editReviewerData.reviewer.dates;
    let dates;
    if (prevDates.length === 1) {
      dates = prevDates[0];
    } else {
      dates =
        prevDates
          .slice(0, editReviewerData.reviewer.dates.length - 1)
          .join(', ') +
        ', and ' +
        prevDates[prevDates.length - 1];
    }
    let text;
    switch (editReviewerData.reviewer.status) {
      case 'accepted':
        text =
          'The Reviewer has already accepted the invitation for the dates: ' +
          dates +
          '.';
        break;
      case 'assigned':
        text =
          'The Reviewer has already accepted and assigned on the review for the dates: ' +
          dates +
          '.';
        break;
      case 'declined':
        text =
          'The Reviewer has already declined the invitation for the dates: ' +
          dates +
          '.';
        break;
      case 'withdrawn':
        text =
          'The Reviewer has already been withdrawn from the invitation for the dates: ' +
          dates +
          '.';
        break;
      default:
        text =
          'The Reviewer has already been sent the invitation for the dates: ' +
          dates +
          '.';
        break;
    }

    text += ' Are you sure you want to continue to update the assigned days?';

    return (
      <AmsAlert
        show={showConfirmEditReviewer}
        title=""
        type="warning"
        showConfirm
        showCancelButton
        confirmButtonText="Confirm"
        confirmButtonColor={'#DD6B55'}
        text={text}
        onConfirm={() => {
          dispatch(
            updatePositionReviewer(
              editReviewerData.reviewer,
              editReviewerData.data
            )
          );
          setEditReviewer(false);
          setShowConfirmEditReviewer(false);
          setEditReviewerData({});
        }}
        onCancel={() => {
          setEditReviewer(false);
          setShowConfirmEditReviewer(false);
          setEditReviewerData({});
        }}
      />
    );
  };

  const renderReviewerSearch = () => {
    return (
      <ReviewerFilter
        showSearchModal={showSearchModal}
        editReviewer={editReviewer}
        updateReviewer={updateReviewer}
        startDate={startDate}
        endDate={endDate}
        reviewType={reviewType}
        grantee={grantee}
        granteeRegions={granteeRegions}
        edit={edit}
        searchModalToggle={searchModalToggle}
        excludeList={reviewers}
        selectedReviewer={selectedReviewer => {
          const position = positionsList.find(
            e => e._id === selectedReviewer.position
          );
          // const filteredReviewers = reviewers.filter(
          //   reviewer => reviewer.position === position._id
          // );
          if (
            !checkReviewersRequired(position) &&
            isEmpty(
              reviewers.filter(
                reviewer => reviewer.oid === selectedReviewer.oid
              )
            )
          ) {
            maxReviewersRequiredConfirmed(position);
          }

          const isUpdate = find(reviewers, e => {
            return e.oid === selectedReviewer.oid;
          });
          if (isUpdate) {
            setShowConfirmEditReviewer(true);
            setEditReviewerData({
              reviewer: isUpdate,
              data: {
                dates: selectedReviewer.dates,
                status: selectedReviewer.status,
                invitationDate: selectedReviewer.invitationDate,
              },
            });
            setShowSearchModal(false);
            return;
          }
          const reviewersList = concat(reviewers, selectedReviewer);

          //setSelectedReviewers(reviewersList);
          setShowSearchModal(false);
          dispatch(addPositionReviewer(reviewersList));
        }}
      />
    );
  };

  return (
    <Container fluid>
      <Divider section />
      {renderConfirmationReviewerEditDialog()}
      {renderMaxReviewerWarningModal()}
      <Grid stackable columns="equal">
        {renderSectionTitle()}

        {renderPositionCards()}
      </Grid>

      {renderReviewerSearch()}
    </Container>
  );
};

export default ReviewersPosition;
