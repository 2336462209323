import React, { useEffect, useState } from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

const SurveyNoteForm = ({ submitNote, currentNote }) => {
  const [content, setContent] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [disableEdit, setDisableEdit] = useState(true);
  const [disableTextArea, setDisableTextArea] = useState(false);

  const { selectedReview } = useSelector(state => state.review);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (
      ((selectedReview || {}).reviewAccessLevel &&
        (selectedReview || {}).reviewAccessLevel.isReviewAccessible ===
          false) ||
      (selectedReview || {}).reviewStatus === 'Report Signed' ||
      (selectedReview || {}).reviewStatus === 'Shipped'
    ) {
      setReadOnly(true);
    }
  }, [selectedReview]);

  useEffect(() => {
    setDisableEdit(currentNote == '');
    setDisableSave(currentNote !== '');
    setDisableTextArea(currentNote !== '');
    setContent(currentNote);
  }, [currentNote || '']);
  const handleSubmit = () => {
    setDisableEdit(false);
    setDisableSave(true);
    if (content === '') return null;

    submitNote(content);
    setContent(content);
    setDisableTextArea(true);
  };

  const handleEdit = () => {
    setDisableEdit(true);
    setDisableSave(false);
    setDisableTextArea(false);
  };

  const expandTextArea = e => {
    setContent(e.target.value);
    const rows = Math.round(e.target.value.split('\n').length, 3);
    e.target.rows = rows;
    e.target.style.width = '100%';
  };

  return (
    <Form>
      {
        <div
          style={{
            padding: '5px',
            border: '1px solid #dadce0',
            minHeight: '50px',
            borderRadius: '7px',
            margin: '5px',
            background: 'white',
          }}
        >
          <TextArea
            className={'add-note-textarea'}
            onChange={expandTextArea}
            name="note"
            disabled={disableTextArea || readOnly}
            title="Add a note"
            placeholder="Add a note.."
            value={content}
            rows={3}
          ></TextArea>

          {!readOnly && (
            <div
              style={{
                overflow: 'auto',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '5px',
              }}
            >
              <Form.Button
                onClick={handleEdit}
                disabled={disableEdit}
                size="tiny"
                floated="right"
                className={disableEdit ? '' : 'primary'}
                style={{
                  marginRight: '5px',
                }}
              >
                Edit
              </Form.Button>
              <Form.Button
                onClick={handleSubmit}
                disabled={disableSave}
                size="tiny"
                floated="right"
                className={disableEdit ? '' : 'primary'}
              >
                Save
              </Form.Button>
            </div>
          )}
        </div>
      }
    </Form>
  );
};

export default SurveyNoteForm;
