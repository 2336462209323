import React from 'react';
import { Menu } from 'semantic-ui-react';

// Import components.
import GranteeUnavailability from './GranteeUnavailability';
import ScheduleExtenstions from './ScheduleExtenstions';

const GranteeSidebar = props => {
  return (
    <Menu vertical fluid>
      <GranteeUnavailability />
      <ScheduleExtenstions />
    </Menu>
  );
};

GranteeSidebar.propTypes = {};

export default GranteeSidebar;
