import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Header, Button } from 'semantic-ui-react';

class GranteeCalendarSaveModal extends Component {
  state = {
    showSaveModal: false,
  };

  handleShowModal = () => {
    this.setState({ showSaveModal: true });
  };

  handleCancelCloseAction = () => {
    this.setState({ showSaveModal: false });
  };

  handleContinueCloseAction = () => {
    this.setState({ showSaveModal: false });
    this.props.closeAction();
  };

  render() {
    const { showSaveModal } = this.state;
    const { finalUpdate } = this.props;
    return (
      <div>
        <Button
          disabled={finalUpdate}
          color="orange"
          onClick={this.handleShowModal}
        >
          Save & Close
        </Button>
        <Modal show={showSaveModal} onHide={this.handleShowCancelAction}>
          <Modal.Header closeButton>
            <Modal.Title>Save and Close?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Header as="h3">
              Are you sure you want to Save your changes and finish later?
            </Header>
          </Modal.Body>
          <Modal.Footer>
            <Button color="green" onClick={this.handleContinueCloseAction}>
              Continue
            </Button>
            <Button color="red" onClick={this.handleCancelCloseAction}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(GranteeCalendarSaveModal);
