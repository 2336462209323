import { WORKFLOW_STATUS_FETCHED } from '../redux/types';

const initialState = {
  workflowStatus: {},
};

export default function workflow(state = initialState, action) {
  switch (action.type) {
    case WORKFLOW_STATUS_FETCHED: {
      return { ...state, workflowStatus: action.workflowStatus };
    }

    default:
      return state;
  }
}
