/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Loader, Dimmer, Button } from 'semantic-ui-react';
import fileDownload from 'js-file-download';

import './asset/style.css';

// Import Actions.
import { uploadPlanningFile } from '../../actions/planningActions';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

class PlanningPage extends Component {
  state = {
    uploading: false,
    uploadedFile: null,
    uploadError: '',
    mappedFile: { data: null, mime: '' },
  };

  handleFile(file) {
    if (!file[0]) return;

    this.setState({ uploading: true });

    let planningFile = new FormData();
    planningFile.append('file', file[0]);

    this.props
      .uploadPlanningFile(planningFile)
      .then(response => {
        if (response.data)
          this.setState({
            uploadError: '',
            uploading: false,
            mappedFile: {
              data: response.data,
              mime: response.headers['content-type'],
            },
          });

        this.setState({ uploadError: '', uploading: false });
      })
      .catch(error => {
        const { message } = error.response.data;

        if (message) this.setState({ uploadError: message, uploading: false });

        this.setState({ uploading: false });
      });
  }

  handleAlertDismiss() {
    this.setState({ alertVisible: false });
  }

  renderDropeZone() {
    const { uploading } = this.state;

    return uploading ? (
      <Dimmer active inverted>
        <Loader inverted>Uploading file...</Loader>
      </Dimmer>
    ) : (
      <div className="col-xs-12">
        <h2>Add File</h2>
        <Dropzone
          onDrop={this.handleFile.bind(this)}
          multiple={false}
          className="upload-area"
          activeClassName="dragged"
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            acceptedFiles,
            rejectedFiles,
          }) => {
            return (
              <div
                className="upload-area-attachment upload-area"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div>Drag file here or ...</div>
                <br />
                <Button loading={uploading}>Choose File to upload</Button>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }

  renderDownloadLink() {
    const { data, mime } = this.state.mappedFile;

    if (data)
      return (
        <div className="col-xs-12 planning-download-link">
          <a
            href=""
            onClick={event => {
              event.preventDefault();
              fileDownload(
                data,
                `planning_${AmsDateFormatters.getMoment().format('x')}.csv`,
                mime
              );
            }}
          >
            Download Review File
          </a>
        </div>
      );
  }

  renderUploadError() {
    const { uploadError } = this.state;

    if (uploadError)
      return (
        <div className="col-xs-12 planning-download-link">{uploadError}</div>
      );
  }

  render() {
    return (
      <div className="page admin-page">
        <div className="panel panel-white">
          <div className="panel-heading">
            <h2 className="panel-title">Planning</h2>
          </div>

          <div className="panel-body--old">
            {this.renderDropeZone()}
            {this.renderDownloadLink()}
            {this.renderUploadError()}
          </div>
        </div>
      </div>
    );
  }
}

PlanningPage.propTypes = {
  uploadPlanningFile: PropTypes.func.isRequired,
};

export default connect(null, { uploadPlanningFile })(PlanningPage);
