import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Loader,
  Segment,
  Dropdown,
  Button,
  Form,
  Container,
  Popup,
} from 'semantic-ui-react';
import _ from 'lodash';

// Style
import './assets/style.css';

// Import components
import GranteeViewAvailabilityTabs from './GranteeViewAvailabilityTabs';
import GranteeCalendarSubmitted from './GranteeViewAvailabilityTabs/GranteeCalendarSubmitted';
import GranteeCalendarSaved from './GranteeViewAvailabilityTabs/GranteeCalendarSaved';
import GranteeCalendarClosed from './GranteeViewAvailabilityTabs/GranteeCalendarClosed';
import GranteeCalendarUpdate from './GranteeViewAvailabilityTabs/GranteeCalendarUpdate';
import GranteeCalendarCancelUpdate from './GranteeViewAvailabilityTabs/GranteeCalendarCancelUpdate';
import GranteecalendarSaveMultiMessage from './GranteeViewAvailabilityTabs/GranteecalendarSaveMultiMessage';
import GranteeCalendarSubmitMultiMessage from './GranteeViewAvailabilityTabs/GranteeCalendarSubmitMultiMessage';
import GranteeCalendarResetMultiMessage from './GranteeViewAvailabilityTabs/GranteeCalendarResetMultiMessage';
import GranteeCalendarAlreadySubmittedMessage from './GranteeViewAvailabilityTabs/GranteeCalendarAlreadySubmittedMessage';
import GranteeCalendarExpiredMessage from './GranteeViewAvailabilityTabs/GranteeCalendarExpiredMessage';

import AmsAlert from '../../utils/AmsAlert';
import AmsDateFormatters from '../../utils/AmsDateFormatters';

// Import action
import { fetchGranteeHash, updateGrantee } from '../../actions/granteeActions';

class GranteeViewAvailability extends Component {
  state = {
    currentDate: AmsDateFormatters.getMoment().startOf('day'),
    grantees: [],
    selectedGrantee: {},
    showTabs: true,
    showSubmitMessage: false,
    showSaveMessage: false,
    showCloseMessage: false,
    showDataChangeMessage: false,
    showUpdateMessage: false,
    dataSaved: false,
    dataSavedMulti: false,
    updatedGranteeDetails: {},
    isGranteeSelected: false,
    previouslySavedGrantee: {},
    extensionUrl: {},
    canSubmitGranteesDetails: true,
    showResetConfirmation: false,
    finalUpdate: false,
    showCancelUpdateMessage: false,
    showMultiSaveConfirmation: false,
    showNoChangesMessage: false,
    showSavedMultiMessage: false,
    showSubmitMultiMessage: false,
    showMultiSubmitConfirmation: false,
    showSubmittedMultiMessage: false,
    showResetdMultiMessage: false,
    showAlreadySubmittedMessage: false,
    showExpiredMessage: false,
    selectedValue: '',
    expirationDate: '',
  };

  componentWillMount = () => {
    this.getData();
  };

  getData = () => {
    const { fetchGranteeHash } = this.props;
    let hash = window.location.href.split('hash=')[1];
    let month = AmsDateFormatters.getMoment().month();
    let fiscalYear = AmsDateFormatters.getMoment().year();
    if (month >= 9)
      fiscalYear = AmsDateFormatters.getMoment()
        .add(1, 'y')
        .year();
    fetchGranteeHash({ hash, fiscalYear }).then(() =>
      this.setupSelectableGrantees()
    );
  };

  setupSelectableGrantees = () => {
    let { granteeDetails } = this.props;
    const grantees = !_.isEmpty(granteeDetails)
      ? granteeDetails.grantInfoList
      : [];
    let granteeOptions = [];
    if (!_.isEmpty(grantees)) {
      for (let m = 0; m < grantees.length; m++) {
        let granteeText =
          grantees[m].granteeName + ' (' + grantees[m].granteeId + ')';
        if (grantees[m].granteeEdited) granteeText += ' - EDITED';
        granteeOptions.push({
          key: grantees[m].granteeId,
          value: grantees[m].granteeId,
          text: granteeText,
        });
      }
      if (granteeOptions.length === 1)
        this.setState({
          isGranteeSelected: true,
          selectedGrantee: grantees[0],
        });
    }
    this.setState({
      granteeOptions: granteeOptions,
      grantees: grantees,
      fedHolidays: granteeDetails.fedHolidays,
      extensionUrl: granteeDetails.extensionUrl,
      linkExpiration: granteeDetails.linkExpiration,
      expirationDate: granteeDetails.expirationDate,
    });

    this.verifyIfFinalSubmitAvailable(grantees);
    this.isFinalUpdate(grantees);
  };

  isFinalUpdate = grantees => {
    const { granteeDetails } = this.props;
    const { currentDate, showSubmittedMultiMessage } = this.state;
    let result = grantees.find(el => el.finalUpdate === true) ? true : false;
    this.setState({
      finalUpdate: result,
      showAlreadySubmittedMessage: showSubmittedMultiMessage ? false : result,
    });
    if (!result) {
      let expirationDate = AmsDateFormatters.getMoment(
        granteeDetails.linkExpiration
      ).endOf('day');
      if (expirationDate <= currentDate) {
        this.setState({
          showExpiredMessage: true,
          finalUpdate: true,
        });
      }
    }
  };

  submitAction = () => {
    this.setState({
      showTabs: false,
      showSubmitMessage: true,
      dataSaved: true,
      isGranteeSelected: false,
    });
  };

  saveAction = () => {
    this.setState({
      showTabs: false,
      showSaveMessage: true,
      dataSaved: true,
      isGranteeSelected: false,
    });
  };

  closeAction = () => {
    this.setState({
      showTabs: false,
      showCloseMessage: true,
      dataSaved: true,
      isGranteeSelected: false,
    });
  };

  showTabsAgain = () => {
    this.getData();
    this.setState({
      showSaveMessage: false,
      showSubmitMessage: false,
      showCloseMessage: false,
      showTabs: true,
    });
  };

  renderMovingConfirmation = () => {
    const { showDataChangeMessage } = this.state;
    return (
      <AmsAlert
        show={showDataChangeMessage}
        title="You have navigated away from the page with changes that you have not saved!"
        text={`The changes you have made will be lost if you do not save your changes. Do you want to save your current changes?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Save and continue'}
        cancelButtonText={'Continue without saving'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showDataChangeMessage: false });
        }}
        onConfirm={() => {
          this.submitChanges();
        }}
      />
    );
  };

  showAlert = (showDataChangeMessage, granteeDetails) => {
    const { dataSaved } = this.state;
    if (!dataSaved) {
      this.setState({
        updatedGranteeDetails: granteeDetails,
        showDataChangeMessage: showDataChangeMessage,
      });
    } else {
      this.setState({ dataSaved: false });
    }
  };

  submitChanges = () => {
    const { updateGrantee } = this.props;
    const { updatedGranteeDetails, grantees } = this.state;

    //We only want to save to backend when there is only 1 grantee
    if (grantees.length > 1) {
      this.updateGranteeData(updatedGranteeDetails);
    } else {
      updateGrantee(updatedGranteeDetails);
      this.getData();
    }
    this.setState({ showDataChangeMessage: false });
  };

  handleSelectedGranteesChange = (e, { value }) => {
    const { grantees } = this.state;
    this.setState({ isGranteeSelected: false }, () => {
      for (let m = 0; m < grantees.length; m++) {
        if (grantees[m].granteeId === value) {
          this.setState({
            selectedGrantee: grantees[m],
            selectedValue: grantees[m].granteeId,
            isGranteeSelected: true,
            showTabs: true,
            showCloseMessage: false,
            showSaveMessage: false,
            showSubmitMessage: false,
            showUpdateMessage: false,
            showCancelUpdateMessage: false,
            showSavedMultiMessage: false,
            showSubmitMultiMessage: false,
            showSubmittedMultiMessage: false,
            showResetdMultiMessage: false,
            showAlreadySubmittedMessage: false,
            showExpiredMessage: false,
          });
        }
      }
    });
  };

  verifyIfFinalSubmitAvailable = grantees => {
    let result = false;

    if (!_.isEmpty(grantees)) {
      for (let i = 0; i < grantees.length; i++) {
        if (_.isEmpty(grantees[i].selectedMonths)) result = true;
        else
          for (let k in grantees[i].selectedMonths) {
            if (!grantees[i].selectedMonths[k]) result = true;
          }

        let isHSRequired = false;
        let isEHSRequired = false;
        let type = '';

        if (grantees[i].granteeType !== undefined)
          type = grantees[i].granteeType;

        if (type.toLowerCase().indexOf('and') >= 0) {
          isHSRequired = true;
          isEHSRequired = true;
          this.setState({ isHSRequired: true, isEHSRequired: true });
        } else if (type.toLowerCase().startsWith('early')) {
          isEHSRequired = true;
          this.setState({ isEHSRequired: true });
        } else if (type.toLowerCase().startsWith('head')) {
          isHSRequired = true;
          this.setState({ isHSRequired: true });
        } else {
          this.setState({ isHSRequired: false, isEHSRequired: false });
        }

        if (
          isHSRequired &&
          (_.isEmpty(grantees[i].hsStartDate) ||
            _.isEmpty(grantees[i].hsEndDate))
        ) {
          result = true;
        }

        if (
          isEHSRequired &&
          (_.isEmpty(grantees[i].ehsStartDate) ||
            _.isEmpty(grantees[i].ehsEndDate))
        ) {
          result = true;
        }
      }
    } else {
      result = true;
    }
    this.setState({ canSubmitGranteesDetails: result });
  };

  updateGranteeData = granteeData => {
    let { grantees } = this.state;
    let grantee = granteeData.grantees[0];

    grantee.granteeEdited = true;
    grantee.unavailabilityDates = grantee.unavailableDates;
    // eslint-disable-next-line no-self-assign
    grantee.notInSessionDates = grantee.notInSessionDates;
    // eslint-disable-next-line no-self-assign
    grantee.springBreakDates = grantee.springBreakDates;
    let granteeIndex = grantees.findIndex(
      el => el.granteeId === grantee.granteeId
    );
    const updatedGrantee = _.clone(grantee);
    grantees[granteeIndex] = updatedGrantee;
    this.setupSelectableGrantees();
    this.verifyIfFinalSubmitAvailable(grantees);
    this.setState({
      grantees: grantees,
      previouslySavedGrantee: updatedGrantee,
      showTabs: false,
      showUpdateMessage: true,
      dataSavedMulti: true,
      dataSaved: false,
      isGranteeSelected: false,
      selectedGrantee: {},
      selectedValue: '',
    });
  };

  renderNoChangesMadeMessage = () => {
    const { showNoChangesMessage } = this.state;
    return (
      <AmsAlert
        show={showNoChangesMessage}
        title="No changes have been made!"
        text={`No changes have been made that need to be submitted.`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'OK'}
        showConfirm
        onConfirm={() => {
          this.setState({ showNoChangesMessage: false });
        }}
      />
    );
  };

  handleSubmitGranteesConfirmation = () => {
    this.setState({ showMultiSubmitConfirmation: true });
  };

  handleSubmitGrantees = () => {
    const { updateGrantee } = this.props;
    const { grantees } = this.state;
    if (!_.isEmpty(grantees)) {
      for (let i = 0; i < grantees.length; i++) {
        grantees[i].finalUpdate = true;
      }
    }
    updateGrantee({ grantees: grantees });
    this.setState(
      {
        showMultiSubmitConfirmation: false,
        showSubmittedMultiMessage: true,
        previouslySavedGrantee: {},
      },
      () => {
        this.resetPage();
      }
    );
  };

  renderSubmitMultiConfirmation = () => {
    const { showMultiSubmitConfirmation } = this.state;
    return (
      <AmsAlert
        show={showMultiSubmitConfirmation}
        title="Submitting Final Changes!"
        text={`Are you sure you want to submit your final changes? You will not be able to edit Grantee Unavailability after submitting your final changes`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showMultiSubmitConfirmation: false });
        }}
        onConfirm={() => {
          this.handleSubmitGrantees();
        }}
      />
    );
  };

  handleSaveGranteesConfirmation = () => {
    const { dataSavedMulti } = this.state;
    if (dataSavedMulti) this.setState({ showMultiSaveConfirmation: true });
    else this.setState({ showNoChangesMessage: false });
  };

  handleSaveGrantees = () => {
    const { updateGrantee } = this.props;
    const { grantees } = this.state;
    updateGrantee({ grantees: grantees });
    this.setState({
      showMultiSaveConfirmation: false,
      showSavedMultiMessage: true,
      dataSavedMulti: false,
      showCancelUpdateMessage: false,
    });
    this.resetPage();
  };

  renderSaveMultiConfirmation = () => {
    const { showMultiSaveConfirmation } = this.state;
    return (
      <AmsAlert
        show={showMultiSaveConfirmation}
        title="Saving Changes!"
        text={`Are you sure you want to save your changes? This is not a final submission and you will be allowed to continue editing later if needed.`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({
            showMultiSaveConfirmation: false,
          });
        }}
        onConfirm={() => {
          this.handleSaveGrantees();
        }}
      />
    );
  };

  handleResetGrantees = () => {
    const { dataSavedMulti } = this.state;
    if (dataSavedMulti) this.showChangesNotSavedMessage();
    else this.handleResetGranteesAction();
  };

  handleResetGranteesAction = () => {
    this.setState({
      showResetConfirmation: false,
      showResetdMultiMessage: true,
      dataSavedMulti: false,
      previouslySavedGrantee: {},
    });
    this.resetPage();
  };

  showChangesNotSavedMessage = () => {
    this.setState({ showResetConfirmation: true });
  };

  renderResetConfirmation = () => {
    const { showResetConfirmation } = this.state;
    return (
      <AmsAlert
        show={showResetConfirmation}
        title="Changes have not been saved!"
        text={`The changes you have made have not been changed are you sure you want to cancel?`}
        type={'warning'}
        confirmButtonColor={'#112e51'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          this.setState({ showResetConfirmation: false });
        }}
        onConfirm={() => {
          this.handleResetGranteesAction();
        }}
      />
    );
  };

  resetPage = () => {
    this.getData();
    this.setState({
      isGranteeSelected: false,
      showTabs: false,
      showCloseMessage: false,
      showSaveMessage: false,
      showSubmitMessage: false,
      showUpdateMessage: false,
      selectedGrantee: {},
    });
  };

  closeUpdateAction = () => {
    const { finalUpdate, grantees } = this.state;
    this.setState({
      showTabs: false,
      isGranteeSelected: false,
      selectedGrantee: {},
      showCancelUpdateMessage: finalUpdate ? false : true,
      selectedValue: '',
    });
    this.isFinalUpdate(grantees);
  };

  closeUpdateMessage = () => {
    this.setState({ showUpdateMessage: false });
  };

  closeCancelMultiMessage = () => {
    this.setState({ showCancelUpdateMessage: false });
  };

  closeSaveMultiMessage = () => {
    this.setState({ showSavedMultiMessage: false });
  };

  closeSubmitMultiMessage = () => {
    this.setState({ showSubmittedMultiMessage: false });
  };

  closeResetMultiMessage = () => {
    this.setState({ showResetdMultiMessage: false });
  };

  renderTabs() {
    const {
      showTabs,
      selectedGrantee,
      isGranteeSelected,
      previouslySavedGrantee,
      fedHolidays,
      extensionUrl,
      linkExpiration,
      expirationDate,
    } = this.state;
    return isGranteeSelected ? (
      <div>
        <GranteeViewAvailabilityTabs
          selectedGrantee={selectedGrantee}
          previouslySavedGrantee={previouslySavedGrantee}
          showTabs={showTabs}
          extensionUrl={extensionUrl}
          fedHolidays={fedHolidays}
          showAlert={this.showAlert}
          submitAction={this.submitAction}
          saveAction={this.saveAction}
          closeAction={this.closeAction}
          linkExpiration={linkExpiration}
          updateGranteeData={this.updateGranteeData}
          closeUpdateAction={this.closeUpdateAction}
          expirationDate={expirationDate}
        />
      </div>
    ) : (
      ''
    );
  }

  renderGranteeAvailability() {
    const {
      grantees,
      granteeOptions,
      showSubmitMessage,
      showSaveMessage,
      showCloseMessage,
      showUpdateMessage,
      canSubmitGranteesDetails,
      finalUpdate,
      isGranteeSelected,
      showCancelUpdateMessage,
      showSavedMultiMessage,
      showSubmittedMultiMessage,
      showResetdMultiMessage,
      showAlreadySubmittedMessage,
      showExpiredMessage,
      dataSavedMulti,
      selectedValue,
    } = this.state;
    const {
      granteeDetails,
      granteeDetails: { grantInfoList, isMultiGrant },
    } = this.props;

    return _.isEmpty(granteeDetails) || _.isEmpty(granteeOptions) ? (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    ) : (
      <Segment id="grantee-view-availability">
        {this.renderMovingConfirmation()}
        {this.renderResetConfirmation()}
        {this.renderSaveMultiConfirmation()}
        {this.renderSubmitMultiConfirmation()}
        <div className="grantee-view-page">
          <GranteeCalendarExpiredMessage
            showExpiredMessage={showExpiredMessage}
            grantees={grantees}
          />
          <GranteeCalendarAlreadySubmittedMessage
            showAlreadySubmittedMessage={showAlreadySubmittedMessage}
            grantees={grantees}
          />
          <br />
          {grantees.length > 1 ? (
            <Dropdown
              placeholder="Select a Grantee to View/Edit"
              label="Select Grantee:"
              fluid
              search
              selection
              onChange={this.handleSelectedGranteesChange}
              options={granteeOptions}
              value={selectedValue}
            />
          ) : (
            <div className="grantee-view-page-header">
              <strong>Grantee: </strong>
              {grantees[0].granteeName} ({grantees[0].granteeId})
            </div>
          )}
          {this.renderTabs()}
          <GranteeCalendarSubmitted
            showSubmitMessage={showSubmitMessage}
            showTabsAgain={this.showTabsAgain}
          />
          <GranteeCalendarSaved
            showSaveMessage={showSaveMessage}
            showTabsAgain={this.showTabsAgain}
          />
          <GranteeCalendarClosed
            showCloseMessage={showCloseMessage}
            showTabsAgain={this.showTabsAgain}
          />
          <GranteeCalendarUpdate
            showUpdateMessage={showUpdateMessage}
            closeUpdateMessage={this.closeUpdateMessage}
          />
          <GranteeCalendarCancelUpdate
            showCancelUpdateMessage={showCancelUpdateMessage}
            closeCancelMultiMessage={this.closeCancelMultiMessage}
          />
          <GranteecalendarSaveMultiMessage
            showSavedMultiMessage={showSavedMultiMessage}
            closeSaveMultiMessage={this.closeSaveMultiMessage}
          />
          <GranteeCalendarSubmitMultiMessage
            showSubmittedMultiMessage={showSubmittedMultiMessage}
            closeSubmitMultiMessage={this.closeSubmitMultiMessage}
          />
          <GranteeCalendarResetMultiMessage
            showResetdMultiMessage={showResetdMultiMessage}
            closeResetMultiMessage={this.closeResetMultiMessage}
          />
          <br />
          {isMultiGrant &&
          !isGranteeSelected &&
          grantInfoList &&
          grantInfoList.length > 1 ? (
            <Container textAlign="center">
              {!finalUpdate ? (
                <Form.Group>
                  <Popup
                    content="All required fields must be filled out before you can submit for all grantee unavailabilities"
                    trigger={
                      <span>
                        <Button
                          disabled={canSubmitGranteesDetails}
                          color="red"
                          onClick={this.handleSubmitGranteesConfirmation}
                        >
                          Submit Unavailability for All Grantees
                        </Button>
                      </span>
                    }
                    disabled={!canSubmitGranteesDetails}
                  />

                  <Button
                    disabled={!dataSavedMulti}
                    color="green"
                    onClick={this.handleSaveGranteesConfirmation}
                  >
                    Save Current Progress
                  </Button>
                  <Button
                    disabled={!dataSavedMulti}
                    color="yellow"
                    onClick={this.handleResetGrantees}
                  >
                    Cancel All Changes
                  </Button>
                </Form.Group>
              ) : (
                ''
              )}
            </Container>
          ) : (
            ''
          )}
        </div>
      </Segment>
    );
  }

  render() {
    return <div> {this.renderGranteeAvailability()} </div>;
  }
}

const mapStateToProps = state => {
  return {
    granteeDetails: state.granteeDetails,
  };
};

export default connect(mapStateToProps, {
  fetchGranteeHash,
  updateGrantee,
})(GranteeViewAvailability);
