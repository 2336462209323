import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';

// Import utils.
import AmsDateFormatters from '../../utils/AmsDateFormatters';

export default function GranteeInfo({ grantee }) {
  const {
    agencyId,
    granteeId,
    hsesStatus,
    granteeType,
    region,
    lastReview,
    nextReview,
    currStartDate,
    hSAddress,
    hSDirector,
    eHSAddress,
    eHSDirector,
    status,
    precedingGrantNumbers,
    succeedingGranteeId,
    granteeAwardStartDate,
    granteeAwardEndDate,
    ceoEmail,
    ceoName,
    boardChairEmail,
    boardChairName,
  } = grantee;

  const [type, setType] = useState(null);
  const precedingGrantsItems = precedingGrantNumbers.map(grantee => (
    <li>
      <Link
        to={`/grantee/${grantee.granteeId}`}
      >{` ${grantee.granteeId}`}</Link>
    </li>
  ));

  useEffect(() => {
    switch (granteeType) {
      case 'Early Head Start':
        setType('EHS');
        break;
      case 'Head Start':
        setType('HS');
        break;
      case 'Head Start and Early Head Start':
        setType('Both');
        break;
      default:
        break;
    }
  }, [granteeType]);

  return (
    <div className="col-sm-7 info">
      {/* {granteeName && (
        <div className="col-sm-12">
          <h2>{granteeName && granteeName}</h2>
        </div>
      )} */}

      {hSAddress && (type === 'HS' || type === 'Both') && (
        <div className="col-sm-12 grantee-address field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="HS Address"
          >
            HS Address:{' '}
          </span>
          <span>{hSAddress.line1 && hSAddress.line1}</span>
          {hSAddress.line1 && ', '}
          <span>{hSAddress.line2 && hSAddress.line2}</span>
          {hSAddress.line1 && ', '}
          <span>{hSAddress.city && hSAddress.city}</span>
          {hSAddress.line1 && ', '}
          <span>{hSAddress.state && hSAddress.state}</span>
          {hSAddress.line1 && ', '}
          <span>{hSAddress.postal && hSAddress.postal}</span>
        </div>
      )}

      {eHSAddress && (type === 'EHS' || type === 'Both') && (
        <div className="col-sm-12 grantee-address field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="EHS Address"
          >
            EHS Address:{' '}
          </span>
          <span>{eHSAddress.line1 && eHSAddress.line1}</span>
          {eHSAddress.line1 && ', '}
          <span>{eHSAddress.line2 && eHSAddress.line2}</span>
          {eHSAddress.line1 && ', '}
          <span>{eHSAddress.city && eHSAddress.city}</span>
          {eHSAddress.line1 && ', '}
          <span>{eHSAddress.state && eHSAddress.state}</span>
          {eHSAddress.line1 && ', '}
          <span>{eHSAddress.postal && eHSAddress.postal}</span>
        </div>
      )}

      {granteeId && (
        <div className="col-sm-12 grantee-id field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Grantee ID"
          >
            Grantee ID:{' '}
          </span>{' '}
          {granteeId}
        </div>
      )}

      {granteeAwardStartDate && (
        <div className="col-sm-12 grantee-award-start-date field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Grantee Award Start date"
          >
            Grantee Award Start date:{' '}
          </span>{' '}
          {AmsDateFormatters.formatDate(granteeAwardStartDate)}
        </div>
      )}

      {granteeAwardEndDate && (
        <div className="col-sm-12 grantee-award-end-date field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Grantee Award End date"
          >
            Grantee Award End date:{' '}
          </span>{' '}
          {AmsDateFormatters.formatDate(granteeAwardEndDate)}
        </div>
      )}

      {succeedingGranteeId && (
        <div className="col-sm-12 preceding-grantee-id field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Succeeding Grantee ID"
          >
            Succeeding Grantee ID:{' '}
          </span>
          <Link to={`/grantee/${succeedingGranteeId}`}>
            {succeedingGranteeId}
          </Link>
        </div>
      )}

      {granteeType && (
        <div className="col-sm-12 grantee-type field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Type"
          >
            Type:{' '}
          </span>{' '}
          {granteeType}
        </div>
      )}

      {region && (
        <div className="col-sm-12 grantee-region field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Region"
          >
            Region:{' '}
          </span>{' '}
          {region}
        </div>
      )}

      {hsesStatus && (
        <div className="col-sm-12 grantee-status field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="HSES Status"
          >
            HSES Status:{' '}
          </span>{' '}
          {hsesStatus}
        </div>
      )}

      {agencyId && (
        <div className="col-sm-12 grantee-grant field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Agency ID"
          >
            Agency ID:{' '}
          </span>{' '}
          {agencyId}
        </div>
      )}

      {status && (
        <div className="col-sm-12 grantee-status field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Status"
          >
            Status:{' '}
          </span>
          <div>
            {status &&
              currStartDate &&
              `${status} as of ${AmsDateFormatters.formatDate(currStartDate)}`}
          </div>
        </div>
      )}

      {precedingGrantNumbers && (
        <div className="col-sm-12 preceding-grantee-id field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Preceding Grantee ID"
          >
            Preceding Grantee ID:{' '}
          </span>
          <ul>{precedingGrantsItems}</ul>
        </div>
      )}

      {lastReview && (
        <div className="col-sm-12 grantee-last-review-date field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Last Review"
          >
            Last Review:{' '}
          </span>
          {AmsDateFormatters.formatDate(lastReview)}
        </div>
      )}

      {nextReview && (
        <div className="col-sm-12 grantee-next-review-date field">
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Next Review"
          >
            Next Review:{' '}
          </span>
          <div>{AmsDateFormatters.formatDate(nextReview)}</div>
        </div>
      )}

      {hSDirector && (type === 'HS' || type === 'Both') && (
        <div className="col-sm-12 grantee-director field">
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="HS Director"
            >
              HS Director:{' '}
            </span>
            {hSDirector.name && `${hSDirector.name}`}
            {hSDirector.phoneNumber && `, ${hSDirector.phoneNumber}`}
            {hSDirector.email && (
              <span>
                , <a href={`mailto:${hSDirector.email}`}>{hSDirector.email}</a>
              </span>
            )}
          </div>
        </div>
      )}

      {eHSDirector && (type === 'EHS' || type === 'Both') && (
        <div className="col-sm-12 grantee-director field">
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="EHS Director"
            >
              EHS Director:{' '}
            </span>
            {eHSDirector.name && `${eHSDirector.name}`}
            {eHSDirector.phoneNumber && `, ${eHSDirector.phoneNumber}`}
            {eHSDirector.email && (
              <span>
                ,{' '}
                <a href={`mailto:${eHSDirector.email}`}>{eHSDirector.email}</a>
              </span>
            )}
          </div>
        </div>
      )}

      {ceoName && (
        <div className="col-sm-12 grantee-director field">
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Chief Executive Officer"
            >
              Chief Executive Officer/Executive Director:{' '}
            </span>
            {ceoName && `${ceoName}`}
            {ceoEmail && (
              <span>
                , <a href={`mailto:${ceoEmail}`}>{ceoEmail}</a>
              </span>
            )}
          </div>
        </div>
      )}

      {ceoName && (
        <div className="col-sm-12 grantee-director field">
          <div>
            <span
              role="heading"
              aria-level="2"
              className="field-title"
              aria-label="Authorizing Official"
            >
              Authorizing Official/Board Chairperson:{' '}
            </span>
            {boardChairName && `${boardChairName}`}
            {boardChairEmail && (
              <span>
                , <a href={`mailto:${boardChairEmail}`}>{boardChairEmail}</a>
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

GranteeInfo.propTypes = {
  grantee: PropTypes.object.isRequired,
};
