import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReviewEvidenceBinder from '.';
import SurveyEvidenceBinder from '../../EvidenceBinder';

//import Actions
import {
  fetchFindingDetails,
  fetchReviewDetail,
} from '../../../../actions/reviewActions';

const EvidenceBinderPaths = props => {
  const dispatch = useDispatch();
  const { selectedReview } = useSelector(state => state.review);
  const { params } = props.router;
  const validReviewtypes = ['FA2-CR', 'FA1-FR'];
  const newFormsvalidReviewtypes = ['Follow-up', 'RAN', 'Special'];

  useEffect(() => {
    const id = props.router.params.id || props.router.params.reviewId;
    if (!selectedReview.reviewId || selectedReview.reviewId !== id) {
      dispatch(fetchReviewDetail({ reviewId: id }));
    }
    if (selectedReview.reviewId) {
      dispatch(fetchFindingDetails({ reviewId: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, props.selectedReview]);

  return (
    <>
      {validReviewtypes.includes(selectedReview.reviewType) ||
      (newFormsvalidReviewtypes.includes(selectedReview.reviewType) &&
        selectedReview.isNewForms) ? (
        <SurveyEvidenceBinder {...props} />
      ) : (
        <ReviewEvidenceBinder {...props} />
      )}
    </>
  );
};
export default EvidenceBinderPaths;
