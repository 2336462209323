import React, { Component } from 'react';

class BrowseGranteeData extends Component {
  render() {
    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        style={{ border: 'none', width: '100%', height: '100%' }}
        src={`${process.env.REACT_APP_REPORT_URI}/browseGranteeData `}
      />
    );
  }
}

export default BrowseGranteeData;
