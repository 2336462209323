import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import AmsAlert from '../../../utils/AmsAlert';
import AmsModal from '../../../utils/AmsModal';
import { FieldGroupField } from '../../../utils/FieldGroup';

import {
  Button,
  Card,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Modal,
  Segment,
} from 'semantic-ui-react';

// Import util
import AmsTable from '../../../utils/AmsTable';
import enforceRole from '../../../utils/EnforceRole';

// Import config.
import { acl } from '../../../config';

// Style
import './assets/style.css';

// Actions.
import {
  addCarousel,
  deleteCarousel,
  fetchCarousels,
  upateAllCarousels,
  updateCarousel,
} from '../../../actions/carouselAction';

class CarouselTiles extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      loading: true,
      showSaveAlert: false,
      errors: {},
      data: {
        carouselId: '',
        carouselTitle: '',
        carouselBlurb: '',
        carouselLink: '',
        carouselImage: null,
        carouselAlt: true,
        editImagePath: null,
        carouselWeight: '',
        alert: false,
        carouselAltText: '',
      },
      editCarousel: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleInputValueChage = this.handleInputValueChage.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  componentDidMount() {
    this.props
      .fetchCarousels()
      .then(() => this.setState({ loading: false }))
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      errors: {},
      data: {
        carouselId: '',
        carouselTitle: '',
        carouselBlurb: '',
        carouselLink: '',
        carouselImage: null,
        editImagePath: null,
        carouselWeight: '',
      },
      editCarousel: false,
    });
  }

  handleAddCarouselClick() {
    this.openModal();
  }

  handleInputValueChage(event) {
    this.setState({
      data: { ...this.state.data, [event.target.id]: event.target.value },
      errors: { ...this.state.errors, [event.target.id + 'Error']: '' },
    });
  }

  saveCarousel() {
    const { carousels } = this.props;
    const {
      carouselId,
      carouselTitle,
      carouselBlurb,
      carouselLink,
      editImagePath,
      carouselImage,
      carouselWeight,
      carouselAlt,
      carouselAltText,
      alert,
    } = this.state.data;

    const carouselObject = {
      title: carouselTitle,
      blurb: carouselBlurb,
      link: carouselLink,
      image: editImagePath,
      alert,
    };
    if (carouselImage) {
      carouselObject.file = carouselImage;
      carouselObject.imageAltText = carouselAlt === true ? carouselAltText : '';
    }

    // If there's a carouselId, its an update. Else a new record.
    if (carouselId) {
      if (editImagePath) {
        carouselObject.imageAltText =
          carouselAlt === true ? carouselAltText : '';
      }
      carouselObject.weight = carouselWeight; // During update keep old weight.
      this.props.updateCarousel(carouselId, carouselObject).then(() =>
        this.setState({
          showSaveAlert: true,
        })
      );
    } else {
      carouselObject.weight = carousels.length; //Add item at the last position.
      this.props.addCarousel(carouselObject).then(() =>
        this.setState({
          showSaveAlert: true,
        })
      );
    }

    this.clearForm();
  }

  handleFile(file) {
    this.setState({
      data: {
        ...this.state.data,
        editImagePath: '',
        carouselImage: file[0],
        carouselAlt: true,
      },
    });
  }

  showCarousel() {
    let { carousels } = this.props;

    if (carousels.length) {
      // carousels = [carousels[5]]

      return (
        <div id="amsCarousel" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            {carousels.map((carousel, index) => {
              return (
                <li
                  key={index}
                  data-target="#amsCarousel"
                  data-slide-to={index}
                  className={!index ? 'active' : ''}
                />
              );
            })}
          </ol>

          <div className="carousel-inner" role="listbox">
            {carousels.map((carousel, index) => {
              return (
                <div key={index} className={!index ? 'item active' : 'item'}>
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-7">
                            <div
                              className="image"
                              style={{
                                background: `url(/files/carousel/${carousel.image}) no-repeat`,
                              }}
                            >
                              <img
                                src={`/files/carousel/${carousel.image}`}
                                alt="Slider"
                                className="img-responsive hide"
                              />
                            </div>
                          </div>
                          <div className="col-sm-5 text">
                            <h1>{carousel.title}</h1>
                            <p>{carousel.blurb}</p>
                            <p>
                              {!_.isEmpty(carousel.link) && (
                                <a
                                  className="btn btn-lg btn-primary"
                                  href={`/external-link/?url=${carousel.link}`}
                                  rel="noopener noreferrer"
                                  aria-label={`Read More about ${carousel &&
                                    carousel.title}`}
                                >
                                  Read More &rarr;
                                </a>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <a
            className="left carousel-control"
            href="#amsCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="glyphicon glyphicon-chevron-left"
              aria-hidden="true"
            />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control"
            href="#amsCarousel"
            role="button"
            data-slide="next"
          >
            <span
              className="glyphicon glyphicon-chevron-right"
              aria-hidden="true"
            />
            <span className="sr-only">Next</span>
          </a>
        </div>
      );
    }
  }

  showConfirmationAlert() {
    return (
      <AmsAlert
        show={this.state.showSaveAlert}
        title="Success"
        type="success"
        showConfirm
        text={`This feature is updated.`}
        onConfirm={() => {
          this.setState({
            showSaveAlert: false,
            modalIsOpen: false,
          });
        }}
      />
    );
  }

  clearForm() {
    this.setState({
      errors: {},
      data: {
        carouselId: '',
        carouselTitle: '',
        carouselBlurb: '',
        carouselLink: '',
        carouselImage: null,
        editImagePath: null,
        carouselWeight: '',
        alert: false,
        carouselAlt: false,
        carouselAltText: '',
      },
      editCarousel: false,
    });
  }

  showImagePreview() {
    const { editImagePath, carouselImage } = this.state.data;

    if (carouselImage)
      return (
        <img
          role="presentation"
          src={carouselImage.preview}
          className="img-preview"
          alt="Carousel preview"
        />
      );
    if (editImagePath)
      return (
        <img
          role="presentation"
          src={`/files/carousel/${editImagePath}`}
          className="img-preview"
          alt="Carousel preview"
        />
      );
  }

  validate = data => {
    const errors = {};

    if (!data.carouselTitle) errors.carouselTitleError = 'Title is required.';
    if (!data.carouselBlurb) errors.carouselBlurbError = 'Blurb is rquired.';
    if (
      data.carouselImage !== null &&
      data.carouselAlt === true &&
      !data.carouselAltText
    ) {
      errors.carouselAltTextError = 'Alt Text is required.';
    }
    return errors;
  };

  handleToggle = (e, { name, checked }) =>
    this.setState({
      ...this.state,
      data: { ...this.state.data, [name]: checked },
      errors: _.omit(this.state.errors, name),
    });

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (
      errors &&
      ((errors.carouselTitleError && errors.carouselBlurbError) ||
        errors.carouselAltTextError)
    )
      return;

    // No errors save data.
    this.saveCarousel();
  };

  showModal() {
    const {
      carouselTitle,
      carouselBlurb,
      carouselLink,
      editImagePath,
      carouselImage,
      carouselAlt,
      carouselAltText,
      alert,
    } = this.state.data;
    const {
      carouselTitleError,
      carouselBlurbError,
      carouselAltTextError,
    } = this.state.errors;

    return (
      <AmsModal
        size="large"
        className="ams-semantic-modal-fix"
        open={this.state.modalIsOpen}
        onClose={this.closeModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header as="h2">Add or Modify Features</Modal.Header>
        <Modal.Content scrolling>
          <div>{this.addCarouselList()}</div>
          <h3>
            {this.state.editCarousel ? 'Edit Feature' : 'Add New Feature'}
          </h3>
          <Segment clearing>
            <Form onSubmit={this.onSubmit}>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    <FieldGroupField
                      id="carouselTitle"
                      validationState={carouselTitleError ? 'error' : null}
                      value={carouselTitle}
                      type="text"
                      onChange={this.handleInputValueChage}
                      label="Title"
                      placeholder="Feature title"
                      help={carouselTitleError || 'Feature Title'}
                    />
                  </div>

                  <div className="col-sm-12">
                    <FieldGroupField
                      id="carouselBlurb"
                      validationState={carouselBlurbError ? 'error' : null}
                      value={carouselBlurb}
                      type="textarea"
                      maxLength="400"
                      onChange={this.handleInputValueChage}
                      label="Blurb"
                      componentClass="textarea"
                      style={{ height: '100px' }}
                      placeholder="Feature Blurb"
                      help={carouselBlurbError || 'Feature Blurb'}
                    />
                  </div>

                  <div className="col-sm-12">
                    <Form.Field
                      label={{
                        htmlFor: 'wideAlert',
                        children: 'Show Feature as system-wide Alert?',
                      }}
                      control={Checkbox}
                      id="wideAlert"
                      toggle
                      aria-labelledby="wideAlert"
                      role="group"
                      name="alert"
                      checked={alert}
                      onClick={this.handleToggle}
                    />
                  </div>

                  {!alert && (
                    <>
                      <div className="col-sm-12">
                        <FieldGroupField
                          id="carouselLink"
                          value={carouselLink}
                          type="text"
                          onChange={this.handleInputValueChage}
                          label="Link"
                          placeholder="Feature Link"
                          help="Note: Please enter complete link, e.g. https://portal.ams.com/reviews or for external links, e.g https://google.com"
                        />
                      </div>

                      <div className="col-sm-10">
                        <label htmlFor="uploadImage" className="control-label">
                          Feature image
                        </label>
                        <Dropzone
                          onDrop={this.handleFile}
                          multiple={false}
                          accept="image/*"
                          className="upload-area"
                          activeClassName="dragged"
                        >
                          {({ getRootProps, getInputProps }) => {
                            return (
                              <div
                                className="upload-area-attachment upload-area"
                                style={{
                                  textAlign: 'center',
                                  paddingTop: '10px',
                                }}
                                {...getRootProps()}
                              >
                                <input id="uploadImage" {...getInputProps()} />
                                <div>Drag image here or ...</div>
                                <br />
                                <button
                                  onClick={e => e.preventDefault()}
                                  className="btn btn-primary btn-sm"
                                >
                                  Choose image to upload
                                </button>
                              </div>
                            );
                          }}
                        </Dropzone>
                      </div>

                      <div className="col-sm-2">
                        <label className="control-label">Preview</label>
                        {this.showImagePreview()}
                      </div>
                      {(carouselImage || editImagePath) && (
                        <>
                          <div className="col-sm-12">
                            <Form.Field
                              label={{
                                htmlFor: 'carouselImageAlt',
                                children: 'Does this image require alt text?',
                              }}
                              control={Checkbox}
                              id="carouselImageAlt"
                              toggle
                              aria-labelledby="carouselImageAlt"
                              role="group"
                              name="carouselAlt"
                              checked={carouselAlt}
                              onClick={this.handleToggle}
                            />
                          </div>
                          {carouselAlt && (
                            <div className="col-sm-12">
                              <FieldGroupField
                                id="carouselAltText"
                                validationState={
                                  carouselAltTextError ? 'error' : null
                                }
                                value={carouselAltText}
                                type="text"
                                onChange={this.handleInputValueChage}
                                label="Feature Image Alt Text"
                                placeholder="Alt text"
                                help={
                                  carouselAltTextError ||
                                  'Feature Image Alt Text'
                                }
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div>
                <Segment basic floated="left">
                  <Button type="submit" primary>
                    Save Feature
                  </Button>
                  <Button onClick={this.closeModal}>Close</Button>
                </Segment>
                <Segment basic floated="right">
                  <Button onClick={this.clearForm.bind(this)}>
                    Clear Form
                  </Button>
                </Segment>
              </div>
            </Form>
          </Segment>
        </Modal.Content>
      </AmsModal>
    );
  }

  addCarouselList() {
    let { carousels } = this.props;
    const action = (cell, row) => {
      return (
        <Button.Group icon>
          <Button
            basic
            aria-label="Edit Row"
            color="green"
            onClick={this.handleCarouselEdit.bind(this, row)}
          >
            <Icon name="pencil" />
          </Button>
          <Button
            aria-label="Delete Row"
            basic
            color="red"
            onClick={this.handleCarouselRemove.bind(this, row._id)}
          >
            <Icon name="trash" />
          </Button>
        </Button.Group>
      );
    };

    const weightButton = (cell, row) => {
      const { carousels } = this.props;

      const index = _.indexOf(carousels, row);

      return (
        <span>
          {/* First Item in the list */}
          {index === 0 && (
            <Button.Group icon>
              <Button
                onClick={this.handleCarouselWeightChange.bind(
                  this,
                  row,
                  'down'
                )}
                aria-label="Move down"
                basic
                color="black"
              >
                <Icon name="arrow down" />
              </Button>
            </Button.Group>
          )}

          {/* Last item in the list */}
          {index === carousels.length - 1 && (
            <Button.Group icon>
              <Button
                onClick={this.handleCarouselWeightChange.bind(this, row, 'up')}
                aria-label="Move up"
                basic
                color="black"
              >
                <Icon name="arrow up" />
              </Button>
            </Button.Group>
          )}

          {/* All other items between first and last items */}
          {index !== 0 && index !== carousels.length - 1 ? (
            <Button.Group icon>
              <Button
                onClick={this.handleCarouselWeightChange.bind(
                  this,
                  row,
                  'down'
                )}
                aria-label="Move down"
                basic
                color="black"
              >
                <Icon name="arrow down" />
              </Button>
              <Button
                onClick={this.handleCarouselWeightChange.bind(this, row, 'up')}
                aria-label="Move up"
                basic
                color="black"
              >
                <Icon name="arrow up" />
              </Button>
            </Button.Group>
          ) : null}
        </span>
      );
    };
    const blurbFormat = cell => {
      return (
        <span
          style={{
            display: 'block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '28em',
          }}
        >
          {cell}{' '}
        </span>
      );
    };
    const columns = [
      { dataField: '_id', hidden: true, text: 'id' },
      {
        dataField: 'title',
        headerStyle: { width: '25%' },
        text: 'Title',
        style: {
          width: '25%',
        },
      },
      {
        dataField: 'blurb',
        headerStyle: { width: '25%' },
        formatter: blurbFormat,
        text: 'Blurb',
        style: {
          width: '25%',
        },
      },
      {
        headerStyle: { width: '10%', textAlign: 'center' },

        formatter: weightButton,
        text: 'Order',
        style: {
          width: '10%',
          textAlign: 'center',
        },
      },
      {
        text: 'Action',
        formatter: action,
        headerStyle: { width: '15%', textAlign: 'center' },
        style: { textAlign: 'center', width: '15%' },
      },
    ];

    /*<TableHeaderColumn dataField="title">Title</TableHeaderColumn>
        <TableHeaderColumn dataField="blurb">Blurb</TableHeaderColumn>
        {/* <TableHeaderColumn dataField="link" dataFormat={linkData}>
          Link
        </TableHeaderColumn> */
    // eslint-disable-next-line no-lone-blocks
    {
      /* <TableHeaderColumn
          dataAlign="center"
          dataField="image"
          width="10%"
          dataFormat={imageData}
        >
          Image
        </TableHeaderColumn> */
    }

    /*<TableHeaderColumn dataAlign="center" dataFormat={action} width="15%">
          Action
        </TableHeaderColumn>*/

    return (
      <>
        <h3>Existing Features</h3>
        <AmsTable
          data={carousels}
          columns={columns}
          keyField="_id"
          basic
          remote={false}
          sizePerPageOption={false}
          total={carousels ? carousels.length : 0}
          pagination={carousels.length > 10}
        />
      </>
    );
  }

  handleCarouselWeightChange(row, direction) {
    const { carousels } = this.props;

    const currentIndex = _.findIndex(carousels, row);
    let newPosition = 0;

    direction === 'down'
      ? (newPosition = currentIndex + 1)
      : (newPosition = currentIndex - 1);

    // eslint-disable-next-line no-extend-native
    Array.prototype.move = function(from, to) {
      this.splice(to, 0, this.splice(from, 1)[0]);
      return this;
    };

    const modifiedCarousels = carousels
      .move(currentIndex, newPosition)
      .map((carousel, index) => {
        carousel.weight = index;
        return carousel;
      });

    this.props
      .upateAllCarousels(modifiedCarousels)
      .catch(error => console.error(error));
  }

  handleCarouselEdit(carousel, event) {
    event.preventDefault();

    this.setState({
      data: {
        carouselAltText: carousel.imageAltText || '',
        carouselAlt: carousel.imageAltText ? true : false,
        carouselId: carousel._id,
        carouselTitle: carousel.title,
        carouselBlurb: carousel.blurb,
        carouselLink: carousel.link,
        editImagePath: carousel.image,
        carouselWeight: carousel.weight,
        alert: carousel.alert,
      },
      editCarousel: true,
    });
  }

  handleCarouselRemove(id, event) {
    event.preventDefault();

    if (id) {
      this.props.deleteCarousel(id);
      this.clearForm();
    }
  }

  showAddCarouselsButton() {
    const { currentUser } = this.props;

    return enforceRole(
      <Button primary onClick={this.handleAddCarouselClick.bind(this)}>
        Add/Modify
      </Button>,
      acl.actions.caraousel.create, // Allowed roles
      currentUser.roles // Current user roles
    );
  }

  render_old() {
    const home = this.context.router.location.pathname;
    if (home === '/') {
      return (
        <div>
          {this.showModal()}
          {this.showCarousel()}
          {this.showAddCarouselsButton()}
        </div>
      );
    } else {
      return null;
    }
  }

  renderTiles = () => {
    const { carousels } = this.props;

    const tiles =
      carousels &&
      // eslint-disable-next-line array-callback-return
      _.orderBy(carousels, o => o.weight).map((carousel, index) => {
        const itemLink = carousel && carousel.link && new URL(carousel.link);
        if (index < 4) {
          return (
            <Grid.Column key={index}>
              <Card fluid>
                {index === 0 && (
                  <Image
                    src="https://blog.asana.com/wp-content/post-images/Asana-Tips-Feature-Roundup_Article-1024x655@2x.png"
                    wrapped
                    ui={false}
                    alt=""
                  />
                )}
                {index !== 0 && carousel.image !== null && (
                  <Image
                    src={`/files/carousel/${carousel.image}`}
                    wrapped
                    ui={false}
                    alt={`${
                      carousel.imageAltText ? carousel.imageAltText : ''
                    }`}
                  />
                )}
                <Card.Content>
                  <Card.Header as="h3">{carousel.title}</Card.Header>

                  <Card.Description>{carousel.blurb}</Card.Description>
                </Card.Content>
                {!_.isEmpty(carousel.link) && (
                  <Card.Content extra>
                    {(window &&
                      window.location &&
                      window.location.hostname.toLowerCase()) ===
                    (itemLink &&
                      itemLink.hostname &&
                      itemLink.hostname.toLowerCase()) ? (
                      <Link
                        href={`${carousel.link}`}
                        rel="noopener noreferrer"
                        style={{ color: '#005ea2' }}
                        aria-label={`Read More about ${carousel &&
                          carousel.title}`}
                      >
                        Read More
                      </Link>
                    ) : (
                      <Link
                        href={`/external-link/?url=${carousel.link}`}
                        rel="noopener noreferrer"
                        style={{ color: '#005ea2' }}
                        aria-label={`Read More about ${carousel &&
                          carousel.title} external link`}
                      >
                        Read More <span className="sr-only">external icon</span>
                        <Icon name="external" />
                      </Link>
                    )}
                  </Card.Content>
                )}
              </Card>
            </Grid.Column>
          );
        }
      });

    return <Grid.Row stretched>{tiles}</Grid.Row>;
  };

  render() {
    return (
      <Grid stackable columns="equal">
        <Loader
          active={this.state.loading}
          inline="centered"
          content="Loading features"
        />

        <Grid.Row columns="equal">
          <Grid.Column>
            <Header as="h2">Features</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            {this.showAddCarouselsButton()}
          </Grid.Column>
        </Grid.Row>

        {this.renderTiles()}
        {this.showModal()}
        {this.showConfirmationAlert()}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    carousels: _.sortBy(state.carousel, ['weight']),
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.user,
  };
}

CarouselTiles.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  carousels: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchCarousels: PropTypes.func.isRequired,
  addCarousel: PropTypes.func.isRequired,
  updateCarousel: PropTypes.func.isRequired,
  deleteCarousel: PropTypes.func.isRequired,
  upateAllCarousels: PropTypes.func.isRequired,
};

CarouselTiles.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  fetchCarousels,
  addCarousel,
  updateCarousel,
  deleteCarousel,
  upateAllCarousels,
})(CarouselTiles);
