import { SET_TODOS, SET_TODO_ERROR } from '../actions/todoActions';

export default function todos(state = [], action) {
  switch (action.type) {
    case SET_TODOS: {
      return action.todos;
    }
    case SET_TODO_ERROR: {
      return { ...state, error: action.error.message };
    }
    default:
      return state;
  }
}
