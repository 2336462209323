import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

const RANGranteeInfo = ({ ran: { granteeInfo, reviewId, reviewType } }) =>
  !isEmpty(granteeInfo) ? (
    <Segment basic>
      {reviewId && (
        <div>
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Review ID"
          >
            Review ID:{' '}
          </span>{' '}
          {reviewId}
        </div>
      )}
      {granteeInfo.map(grantee => (
        <div key={grantee.granteeId}>
          <span
            role="heading"
            aria-level="2"
            className="field-title"
            aria-label="Grantee Name"
          >
            Grantee Name:{' '}
          </span>{' '}
          {`${grantee.granteeName} (${grantee.granteeId})`}
        </div>
      ))}
    </Segment>
  ) : null;

RANGranteeInfo.propTypes = {
  ran: PropTypes.object,
};

const mapStateToProps = state => ({
  ran: state.forms.ran,
});

export default connect(mapStateToProps)(RANGranteeInfo);
