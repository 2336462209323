import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router';

const ReviewManagementAnalysisReport = ({ selectedReview }) => {
  if (!selectedReview) return null;

  const fiscalYear =
    selectedReview.fiscalYear && selectedReview.fiscalYear.toString().slice(-2);
  const newFormReviewTypes = ['Special'];

  return selectedReview &&
    (selectedReview.reviewType === 'FA2-CR' ||
      (newFormReviewTypes.includes(selectedReview.reviewType) &&
        selectedReview?.isNewForms)) &&
    selectedReview.fiscalYear >= 2024 ? (
    <Menu.Item
      as={Link}
      to={`/review/fy${fiscalYear}/${selectedReview.reviewId}/management_systems_analysis_summary`}
    >
      Management Systems Analysis Summary
    </Menu.Item>
  ) : null;
};

ReviewManagementAnalysisReport.propTypes = {
  selectedReview: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps)(ReviewManagementAnalysisReport);
