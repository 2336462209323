/* eslint-disable jsx-a11y/anchor-is-valid */
import { find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap/lib/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Divider, Segment } from 'semantic-ui-react';
import {
  downloadUnsignedAgreement,
  fetchAgreement,
  fetchAgreements,
  signAgreement,
} from '../actions/agreementActions';
import {} from '../actions/authActions';
import SlidingContainer from './layout/SlidingContainer';

// Import pdf image
import { Helmet } from 'react-helmet';
import pdfIcon from '../assets/images/pdf-icon.png';

const AgreementsPage = props => {
  const { user } = useSelector(state => state.auth);
  const { agreement, agreements } = useSelector(state => state.agreement);
  const [unsignedAgreement, setUnsignedAgreement] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (agreements && agreements.length > 0) {
      let unsigned = find(agreements, agreement => agreement.signed === false);
      setUnsignedAgreement(unsigned);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unsignedAgreement && unsignedAgreement.name) {
      dispatch(fetchAgreement(unsignedAgreement.name));
    } else if (isEmpty(unsignedAgreement)) browserHistory.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsignedAgreement]);

  const agreementSign = () => {
    let input = {
      type: agreement.type,
      name: agreement.name,
      content: agreement.content,
      oid: user.oid,
    };
    dispatch(signAgreement({ ...input })).then(() => {
      dispatch(fetchAgreements());
    });
  };

  return (
    <SlidingContainer title={agreement && agreement.content && 'Agreement'}>
      <Helmet>
        <title>{`${
          agreement.type ? agreement.type : ''
        } | Head Start - Aligned Monitoring System`}</title>
      </Helmet>
      <Container>
        <div>
          {agreement && agreement.content && (
            <>
              <Segment>
                <div dangerouslySetInnerHTML={{ __html: agreement.content }} />
              </Segment>

              <Divider></Divider>
              <div>
                <a
                  href=""
                  onClick={event => {
                    if (event) event.preventDefault();
                    dispatch(
                      downloadUnsignedAgreement(agreement._id, agreement.type)
                    );
                  }}
                >
                  <img
                    alt=""
                    className="manifest-pdf-icon"
                    src={pdfIcon}
                    width="16"
                    style={{ marginRight: '5px' }}
                  />
                  <span>Download {`${agreement.type}`} PDF</span>
                </a>
                <Button
                  primary
                  style={{ float: 'right' }}
                  onClick={agreementSign}
                  size={'huge'}
                >
                  Agree and Continue
                </Button>
              </div>
            </>
          )}
        </div>
      </Container>
    </SlidingContainer>
  );
};

export default AgreementsPage;
