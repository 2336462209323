import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';

// Import components.
import AmsFormLabel from '../../utils/AmsFormLabel';
import AmsLookupDropdown from '../../utils/AmsLookupDropdown';
import SlidingContainer from '../../utils/layout/SlidingContainer';

// Import forms.
import ReviewsCreateForm from './Forms/ReviewsCreateForm';

// Import actions.
import { reviewInfoFetch } from '../../actions/reviewActions';

class ReviewCreatePage extends Component {
  state = {
    reviewType: '',
    fiscalYear: '',
    errors: {},
    message: '',
    dataLoaded: false,
    edit: _.last(this.context.router.routes).path === 'edit',
  };

  componentDidMount = () => {
    const { edit } = this.state;
    const { params } = this.context.router;

    // Only run during edit mode.
    if (edit && params.id) {
      // Pass reviewId to fetch review details.
      this.props
        .reviewInfoFetch(params.id)
        .then(() => this.setState({ dataLoaded: true }))
        .catch(error => {
          console.error(error);
        });
    }
  };

  componentWillReceiveProps = nextProps => {
    const {
      reviewInfo: { reviewType, fiscalYear },
    } = nextProps;

    if (reviewType) this.setState({ reviewType });
    if (fiscalYear) this.setState({ fiscalYear });
  };

  renderReviewTypes = () => {
    const { edit, reviewType } = this.state;
    return (
      <Form.Group widths={2}>
        <Form.Field required disabled={edit}>
          <AmsFormLabel name="Review Type" fieldLabel="selectReviewType">
            <AmsLookupDropdown
              // aria-labelledby="selectReviewType"
              searchInput={{
                id: 'selectReviewTypeSearch',
                title: 'Select a Review Type',
              }}
              value={reviewType}
              category={'reviewTypes'}
              onChange={(e, { value }) =>
                this.setState({
                  reviewType: value,
                })
              }
              selection
              fluid
              // search
              placeholder="Please select Review Type"
              setError={this.setError}
              id="selectReviewType"
            />
          </AmsFormLabel>
        </Form.Field>
      </Form.Group>
    );
  };

  renderFiscalYear = () => {
    const { edit, reviewType, fiscalYear } = this.state;

    if (!reviewType) return;

    return (
      <Form.Group widths={2}>
        <Form.Field required disabled={edit}>
          <AmsFormLabel name="Fiscal Year" fieldLabel="selectFiscalYear">
            <AmsLookupDropdown
              // aria-labelledby="selectFiscalYear"
              value={fiscalYear}
              category={'fiscalYear'}
              onChange={(e, { value }) =>
                this.setState({
                  fiscalYear: value,
                })
              }
              selection
              fluid
              placeholder="Please select Fiscal Year"
              setError={this.setError}
              id="selectFiscalYear"
            />
          </AmsFormLabel>
        </Form.Field>
      </Form.Group>
    );
  };

  renderForms = () => {
    const { reviewType, fiscalYear, edit, dataLoaded } = this.state;

    if (!reviewType || !fiscalYear) return;

    return (
      <ReviewsCreateForm
        reviewType={reviewType}
        fiscalYear={fiscalYear}
        edit={edit}
        dataLoaded={dataLoaded}
        setError={this.setError}
        setMessage={this.setMessage}
      />
    );
  };

  setMessage = message => {
    if (message) this.setState({ message });
  };

  setError = (errors, omit = '') => {
    if (!_.isEmpty(errors)) {
      if (omit) {
        this.setState({ errors: { ..._.omit(this.state.errors, omit) } });
        return;
      }
      this.setState({ errors: { ...this.state.errors, ...errors } });
    } else {
      this.setState({ errors });
    }
  };

  showError = () => {
    const { errors } = this.state;

    if (_.isEmpty(errors)) return;

    return (
      <Message
        negative
        icon="cancel"
        header="Something went wrong!"
        list={Object.keys(errors).map(errorKey => (
          <li key={errorKey}>{errors[errorKey]}</li>
        ))}
      />
    );
  };

  showConfirmation = () => {
    const { message } = this.state;

    if (_.isEmpty(message)) return;

    return (
      <Message positive icon="check" header="Success!" content={message} />
    );
  };

  render() {
    const { edit } = this.state;
    return (
      <div className="create-review-page">
        <SlidingContainer
          calendar
          title={edit ? 'Edit Review' : 'Create Review'}
        >
          {this.showError()}
          {this.showConfirmation()}
          <Form id="createReviewForm">
            {this.renderReviewTypes()}
            {this.renderFiscalYear()}
            <input type="submit" value="Submit" hidden />
          </Form>

          {this.renderForms()}
        </SlidingContainer>
      </div>
    );
  }
}

ReviewCreatePage.contextTypes = {
  router: PropTypes.object.isRequired,
};

ReviewCreatePage.propTypes = {
  reviewInfo: PropTypes.object.isRequired,
  reviewInfoFetch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  reviewInfo: state.review.reviewInfo,
});

export default connect(mapStateToProps, { reviewInfoFetch })(ReviewCreatePage);
