import axios from 'axios';

import { WORKFLOW_STATUS_FETCHED } from '../redux/types';

export const workFlowStatusFetched = workflowStatus => ({
  type: WORKFLOW_STATUS_FETCHED,
  workflowStatus,
});

export function fetchWorkflowStatus() {
  return dispatch => {
    axios.post(`/workflow/status`).then(response => {
      dispatch(workFlowStatusFetched(response.data));
    });
  };
}
