import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchReviews } from '../../actions/reviews';
import ReviewsList from './ReviewsList';

class ReviewsPage extends Component {
  componentWillMount() {
    if (this.props.location.pathname !== '/reviews') {
      this.props.fetchReviews();
    }
  }

  showError() {
    if (this.props.error) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.error}
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.showError()}
        {/*{this.props.reviews.name ? <h4>{this.props.reviews.name}</h4> : ''}*/}

        {this.props.reviews.review ? (
          <ReviewsList reviews={this.props.reviews.review} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

ReviewsPage.propTypes = {
  reviews: PropTypes.object.isRequired,
  fetchReviews: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    reviews: state.reviews,
    error: state.reviews.error,
  };
}

export default connect(
  mapStateToProps,
  { fetchReviews }
)(ReviewsPage);
