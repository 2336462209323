import React from 'react';
import { Button, Checkbox, Form, Header, Modal } from 'semantic-ui-react';
import AmsModal from '../../../utils/AmsModal';

const ShowUpdateConfirmationDialog = props => {
  return (
    <AmsModal
      size="large"
      centered={false}
      closeOnDimmerClick={false}
      onClose={() => {
        props.close();
      }}
      open={props.showUpdateConfirmation}
      className="ams-semantic-modal-fix"
    >
      <Header content="Update Review" />

      <Modal.Content>
        <Modal.Description>
          <Header content="You are about to update this review. Are you sure?" />
          <Form>
            <>
              Is the Team planning complete? (If Yes select the checkbox, if No,
              no action is required)
            </>
            <Form.Field
              label={{
                children: 'Team planning is complete',
                htmlFor: 'updateTeamPlanning',
              }}
              role="group"
              id={'updateTeamPlanning'}
              aria-labelledby={'updateTeamPlanning'}
              checked={props.teamPlanningComplete}
              onChange={() => {
                props.setTeamPlanningComplete();
              }}
              control={Checkbox}
            ></Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.close();
          }}
          content="No, Cancel"
        />
        <Button
          onClick={() => {
            props.save();
          }}
          primary
          content="Yes, update it"
        />
      </Modal.Actions>
    </AmsModal>
  );
};

export default ShowUpdateConfirmationDialog;
