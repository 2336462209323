import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

class GranteeCalendarCancelUpdate extends Component {
  handleDismiss = () => {
    const { closeCancelMultiMessage } = this.props;
    closeCancelMultiMessage();
  };

  renderCancelUpdateDetails() {
    let { showCancelUpdateMessage } = this.props;
    return showCancelUpdateMessage ? (
      <div>
        <Message
          onDismiss={this.handleDismiss}
          header="The changes you have made have been cancelled."
          content="Please select another Grantee to update from the drop down above."
        />
      </div>
    ) : (
      ''
    );
  }

  render() {
    return <div> {this.renderCancelUpdateDetails()} </div>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(GranteeCalendarCancelUpdate);
