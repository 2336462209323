import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIcon from './FontAwesomeIcon';
import Wysiwyg from '../../../Shared/Wysiwyg';
import DOMPurify from 'dompurify';

import { formBuilderFocusedComponent } from '../../../../actions/surveyActions';

const useStyles = createUseStyles({
  cardEnumOption: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '.5em',
    '& input': { width: '80%', marginRight: '1em', marginBottom: 0 },
    '& .delete-button': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
});

// Wysiwyg options field corresponding to an array of values, add and remove
export default function CardEnumWysiwygOptions({
  initialValues,
  names,
  onChange,
  onDelete,
}) {
  const [focusedIndex, setFocusedIndex] = React.useState(null);
  const dispatch = useDispatch();
  const focusedComponent = useSelector(state => state.survey.formBuilderFocus);

  const handleFocus = index => {
    setFocusedIndex(index);
    const elementsToRemove = document.querySelectorAll('trix-toolbar');

    // Iterate over the NodeList and remove each element
    elementsToRemove.forEach(element => {
      element.remove();
    });
    setTimeout(() => {
      dispatch(formBuilderFocusedComponent('CardEnumWysiwygOption-' + index)); // Use a unique identifier per option
    }, 250);
  };

  const isComponentFocused = index => {
    return focusedComponent === 'CardEnumWysiwygOption-' + index;
  };
  const buildItem = (name, index, canEditAndDelete) => {
    possibleValues.push(
      <div key={index}>
        <div className={classes.cardEnumOption}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            className="focusable-wsywig-form-input enum-option-wsywig two-line-wsyswig"
          >
            {isComponentFocused(index) ? (
              <Wysiwyg
                id={`enum-trix-editor-${index}`}
                key={`val-${index}`}
                disabled={!canEditAndDelete}
                focusOnInit={true}
                value={name === undefined || name === null ? '' : name}
                onChange={content => {
                  if (!canEditAndDelete) return;
                  onChange([
                    ...initialValues.slice(0, index),
                    content,
                    ...initialValues.slice(index + 1),
                  ]);
                }}
              />
            ) : (
              <div
                style={{ maxHeight: '80px' }}
                className="form-control enum-input wsywig-input-unfocused"
                name={`enum-${index}`}
                value={name === undefined || name === null ? '' : name}
                onFocus={() => handleFocus(index)}
                contentEditable={true}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }}
              />
            )}
          </div>
          {canEditAndDelete ? (
            <div className="delete-button">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  if (onDelete) {
                    onDelete(index);
                  }
                  // remove this value
                  onChange(
                    [
                      ...initialValues.slice(0, index),
                      ...initialValues.slice(index + 1),
                    ],
                    names
                      ? [...names.slice(0, index), ...names.slice(index + 1)]
                      : undefined
                  );
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const classes = useStyles();
  const possibleValues = [];
  for (let index = 0; index < initialValues.length; index += 1) {
    const value = initialValues[index];
    let name = `${value}`;
    if (names && index < names.length) name = names[index];
    buildItem(name, index, true);
  }

  return (
    <React.Fragment>
      {possibleValues}
      <FontAwesomeIcon
        icon={faPlus}
        onClick={() => {
          // add a new dropdown option
          onChange([...initialValues, ''], names ? [...names, ''] : undefined);
        }}
      />
    </React.Fragment>
  );
}
