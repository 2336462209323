import React, { useState } from 'react';
import { Link, withRouter } from 'react-router';

//components
import ProfileNav from '../../Profile/ProfileNav';

//assets
import AMSLogo from './../../../assets/images/ams-logo.png';

import {
  Button,
  Form,
  Grid,
  Icon,
  Image,
  Menu,
  Select,
} from 'semantic-ui-react';

const options = [
  { key: 'reviewId', text: 'Review ID', value: 'reviewId' },
  { key: 'granteeName', text: 'Grantee Name', value: 'granteeName' },
];

const DesktopNavBar = props => {
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('reviewId');
  const [placeholder, setPlaceholder] = useState(
    'Search Final Reports by Review ID or Grantee Name'
  );

  const handleInputChange = (e, data) => {
    setQuery(data.value);
  };

  const handleSearchSubmit = () => {
    const payloadQuery = encodeURIComponent(query);
    if (query) {
      props.router.push(`/search/${searchType}/${payloadQuery}/1`);
    }
  };
  return (
    <Grid padded className="tablet computer only">
      <Menu inverted style={{ boxShadow: 'none' }} fluid>
        <Menu.Item
          position="left"
          as={Link}
          to="/"
          style={{
            padding: '10px',
            margin: '10px',
            marginLeft: '0',
            borderRadius: '10px',
            background: '#fff',
          }}
        >
          <Image
            src={AMSLogo}
            style={{ marginRight: '1em', width: '160px' }}
            alt="Aligned Monitoring System 2.0 Home"
          />
          {/* <Image
          src={AMSLogoSquare}
          style={{ marginRight: '1em', width: '32px' }}
          alt="Aligned Monitoring System 2.0 Home"
        />
        <span
          style={{
            fontSize: '1.25rem',
            lineHeight: '4rem',
            fontStyle: 'normal',
            fontWeight: 700,
          }}
        >
          Aligned Monitoring System <sup>2.0</sup>
        </span> */}
        </Menu.Item>

        <Menu.Item style={{ marginTop: '-10px' }}>
          <Form role="search" onSubmit={handleSearchSubmit}>
            <Form.Input
              value={query}
              onChange={handleInputChange}
              icon
              type="text"
              placeholder={placeholder}
              action
            >
              {/* <label htmlFor="header-search">
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Search
                </span>
              </label> */}
              <input
                id="header-search"
                style={{ width: '400px' }}
                aria-label="Search"
              />
              <Icon name="search" />{' '}
              <Select
                title="Search type"
                onChange={(e, data) => {
                  data.value === 'reviewId'
                    ? setPlaceholder('Enter Review ID')
                    : setPlaceholder('Enter Grantee Name');
                  setSearchType(data.value);
                }}
                compact
                options={options}
                value={searchType}
              />
              <Button type="submit">
                <span
                  style={{ transition: 'none 0s ease 0s' }}
                  className="visually-hidden"
                >
                  Submit Search
                </span>
              </Button>
            </Form.Input>
          </Form>
        </Menu.Item>

        <Menu.Item position="right">
          <ProfileNav />
        </Menu.Item>
      </Menu>
    </Grid>
  );
};

export default withRouter(DesktopNavBar);
