import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';

// Import config
import { acl } from '../../../config';

// Import actions.
import {
  fetchReviewDetail,
  fetchReviewEvidence,
} from '../../../actions/reviewActions';
import {
  fetchSpecialAssignments,
  fetchSpecialSurveyAssignments,
} from '../../../actions/specialActions';
import { getClassSamplingReport } from '../../../actions/submissions';

// Import components
import ReviewLayout from '../../../utils/layout/ReviewLayout';
// import SlidingContainer from '../../../utils/layout/SlidingContainer';
import { formatReviewPageTitle } from '../../../utils/TextFormatters';
import ReviewInfo from './ReviewInfo';
import ReviewEditButton from './ReviewInfo/ReviewEditButton';
// import ShowErrors from '../../../utils/ShowErrors';
import ReviewSubmissions from './ReviewSubmissions';
// import ReviewCards from './ReviewCards';
import { formatUserNotes } from '../../ProgramOverview/util';
import { fetchNotes } from '../../../actions/noteActions';
import SurveyNotesContainer from '../../ProgramOverview/SurveyNotesContainer';

class ReviewDetailPage extends Component {
  state = {
    errors: {},
    showSideBar: true,
    shouldOpenProgramOverview: false,
  };

  componentDidMount = () => {
    this.loadData();
    this.fetchProgramOverview();

    //check if query paramse exist to pre open the program overview notes
    const queryParams = new URLSearchParams(this.props.location.search);
    const shouldOpenProgramOverview =
      queryParams.get('openProgramOverview') === 'true';

    this.setState({ shouldOpenProgramOverview });
  };

  componentWillReceiveProps = nextProps => {
    const {
      selectedReview,
      currentUser: { roles },
    } = this.props;

    let hasPermissionSpecialReview = _.intersection(
      acl.actions.review.specialAction,
      roles
    );

    if (_.isEmpty(hasPermissionSpecialReview) && _.includes(roles, 'Reviewer'))
      hasPermissionSpecialReview = ['Reviewer'];

    if (
      !_.isEmpty(hasPermissionSpecialReview) &&
      selectedReview &&
      selectedReview.reviewId &&
      (selectedReview.reviewType === 'Special' ||
        selectedReview.reviewType === 'AIAN-DEF' ||
        selectedReview.reviewType === 'ERSEA')
    ) {
      if (
        selectedReview.reviewType === 'Special' &&
        selectedReview?.isNewForms
      ) {
        this.props
          .fetchSpecialSurveyAssignments({ reviewId: selectedReview.reviewId })
          .catch(error =>
            this.setState({
              errors: {
                ...this.state.errors,
                fetchSpecialSurveyAssignments:
                  (error.response && error.response.data.message) || '',
              },
            })
          );
      } else {
        this.props
          .fetchSpecialAssignments({ reviewId: selectedReview.reviewId })
          .catch(error =>
            this.setState({
              errors: {
                ...this.state.errors,
                fetchSpecialAssignments:
                  (error.response && error.response.data.message) || '',
              },
            })
          );
      }
    }
  };

  loadData = () => {
    const { selectedReview } = this.props;
    const { reviewId } = this.props.params;

    if (reviewId) {
      // Fetch Review Detail.
      this.props.fetchReviewDetail({ reviewId }).catch(error =>
        this.setState({
          errors: {
            ...this.state.errors,
            fetchReviewDetail:
              (error.response && error.response.data.message) || '',
          },
        })
      );
      // Fetch Review Evidences.
      this.props.fetchReviewEvidence({ filters: { reviewId } }).catch(error =>
        this.setState({
          errors: {
            ...this.state.errors,
            fetchReviewEvidence:
              (error.response && error.response.data.message) || '',
          },
        })
      );
      // Fetch Class Sampling Report only for CLASS review type.
      if (
        (selectedReview.reviewType === 'CLASS' ||
          selectedReview.reviewType === 'VP-CLASS' ||
          selectedReview.reviewType === 'CLASS-Video' ||
          selectedReview.reviewType === 'AIAN CLASS Self-Observations') &&
        ((this.props.currentUser || {}).roles || []).indexOf('OHSStaff') === -1
      ) {
        this.props.getClassSamplingReport({ reviewId }).catch(error =>
          this.setState({
            errors: {
              ...this.state.errors,
              getClassSamplingReport:
                (error.response && error.response.data.message) || '',
            },
          })
        );
      }
    }
  };

  handleShowSideBar = showSideBarInd => {
    this.setState({ ...this.state, showSideBar: showSideBarInd });
  };

  fetchProgramOverview = () => {
    const body = {
      filters: {
        tags: {
          reviewId: this.props.params.reviewId,
          type: 'program_overview',
        },
      },
    };

    this.props.fetchNotes(body);
  };

  render() {
    const { selectedReview, notes } = this.props;
    const legacy = selectedReview.systemName === 'OHSMS';
    const surveyReviewTypes = ['FA1-FR', 'FA2-CR'];
    const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];
    const surveyNotes = notes && formatUserNotes(notes);

    // eslint-disable-next-line no-unused-vars
    const actionButtons = (
      <>
        <List.Item>
          <List.Content>
            <ReviewEditButton />
          </List.Content>
        </List.Item>
        {/* <List.Item>
          <List.Content>
            <Label
              circular
              color="orange"
              size="huge"
              style={{
                paddingLeft: '1rem !important',
                paddingRight: '1rem !important',
              }}
            >
              {selectedReview.reviewStatus}
            </Label>
          </List.Content>
        </List.Item> */}
      </>
    );

    // return (
    //   <div className="review-detail-page">
    //     <SlidingContainer
    //       sidebarComponent={!legacy && <ReviewCards />}
    //       calendar
    //       title={formatReviewPageTitle(selectedReview)}
    //       // title={
    //       //   selectedReview.reviewId &&
    //       //   selectedReview.reviewStatus &&
    //       //   `Review ID: ${selectedReview.reviewId} / Status: ${selectedReview.reviewStatus}`
    //       // }
    //       actionButtons={actionButtons}
    //     >
    //       <ShowErrors errors={this.state.errors} />
    //       <ReviewInfo type={legacy ? 'legacy' : 'fy19'} />
    //       {legacy === false && <ReviewSubmissions />}
    //     </SlidingContainer>
    //   </div>
    // );

    return (
      <ReviewLayout
        title={formatReviewPageTitle(selectedReview)}
        legacy={legacy}
        errors={this.state.errors}
        selectedReview={selectedReview}
        enableSideBar={
          surveyReviewTypes.includes(selectedReview?.reviewType) ||
          (newFormReviewTypes.includes(selectedReview?.reviewType) &&
            selectedReview?.isNewForms)
        }
        handleShowSideBar={this.handleShowSideBar}
        actionButtons={
          (surveyReviewTypes.includes(selectedReview?.reviewType) ||
            (newFormReviewTypes.includes(selectedReview?.reviewType) &&
              selectedReview?.isNewForms)) && (
            <div className="notes-container-wrapper">
              <ReviewEditButton />
              {selectedReview.reviewType != 'RAN' &&
                selectedReview.reviewType.trim().toLowerCase() !=
                  'Follow-up'.toLowerCase() && (
                  <SurveyNotesContainer
                    defaultOpen={this.state.shouldOpenProgramOverview}
                    tags={{
                      reviewId: selectedReview?.reviewId,
                      type: 'program_overview',
                    }}
                    list={surveyNotes}
                    enabled={surveyNotes}
                    type={'Program Overview'}
                    reloadData={() => {
                      if (selectedReview.reviewId) this.fetchProgramOverview();
                    }}
                    isPrimary={true}
                    reloadDataOnClick={true}
                  />
                )}
            </div>
          )
        }
      >
        <div
          style={{
            width: this.state.showSideBar ? '100%' : '124%',
          }}
        >
          <ReviewInfo type={legacy ? 'legacy' : 'fy19'} />
          {legacy === false &&
            this.props.params.reviewId ===
              this.props.selectedReview.reviewId && <ReviewSubmissions />}
        </div>
      </ReviewLayout>
    );
  }
}

ReviewDetailPage.propTypes = {
  selectedReview: PropTypes.object.isRequired,
  fetchReviewEvidence: PropTypes.func.isRequired,
  getClassSamplingReport: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
  fetchedFindingDetails: state.review.fetchedFindingDetails,
  currentUser: state.auth.user,
  notes: state.note.notes,
});

export default connect(mapStateToProps, {
  fetchReviewDetail,
  fetchReviewEvidence,
  getClassSamplingReport,
  fetchSpecialAssignments,
  fetchNotes,
  fetchSpecialSurveyAssignments,
})(ReviewDetailPage);
