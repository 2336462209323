import _ from 'lodash';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

// Import utils.
import AmsDateFormatters from '../../../utils/AmsDateFormatters';

class ScheduleViewGranteeSchoolDates extends Component {
  handleHsStartChange = hsStartDate => {
    const { handleHsStartDate, handleHsEndDate } = this.props;
    if (!hsStartDate) {
      handleHsStartDate(null);
      handleHsEndDate(null);
      return;
    }
    handleHsStartDate(AmsDateFormatters.getMoment(hsStartDate).startOf('day'));
  };

  handlehsEndChange = hsEndDate => {
    const { handleHsEndDate } = this.props;
    if (!hsEndDate) {
      handleHsEndDate(null);
      return;
    }
    handleHsEndDate(AmsDateFormatters.getMoment(hsEndDate).startOf('day'));
  };

  handleEhsStartChange = ehsStartDate => {
    const { handleEhsStartDate, handleEhsEndDate } = this.props;
    if (!ehsStartDate) {
      handleEhsStartDate(null);
      handleEhsEndDate(null);
      return;
    }
    handleEhsStartDate(
      AmsDateFormatters.getMoment(ehsStartDate).startOf('day')
    );
  };

  handleEhsEndChange = ehsEndDate => {
    const { handleEhsEndDate } = this.props;
    if (!ehsEndDate) {
      handleEhsEndDate(null);
      return;
    }
    handleEhsEndDate(AmsDateFormatters.getMoment(ehsEndDate).startOf('day'));
  };

  render() {
    const {
      hsStartDate,
      hsEndDate,
      ehsStartDate,
      ehsEndDate,
      isHSRequired,
      isEHSRequired,
      editMode,
    } = this.props;
    return (
      <div>
        {isHSRequired ? (
          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                width={2}
                name="hsStartDate"
                control={DatePicker}
                label={{
                  children: 'HS:School Year Start Date',
                  htmlFor: 'hsStartDate',
                }}
                placeholderText="Enter Date in valid Format: MM/DD/YYYY"
                selectsStart
                selected={hsStartDate}
                startDate={hsStartDate}
                // isClearable
                onChange={this.handleHsStartChange}
                id="hsStartDate"
                aria-labelledby="hsStartDate"
              />
            ) : (
              <Form.Field width={2}>
                <strong>HS:School Year Start Date: </strong>
                {!_.isEmpty(hsStartDate)
                  ? AmsDateFormatters.formatDate(hsStartDate)
                  : ''}
              </Form.Field>
            )}
            {editMode ? (
              <Form.Field
                width={2}
                name="hsEndDate"
                control={DatePicker}
                label={{
                  children: 'HS:School Year End Date',
                  htmlFor: 'hsEndDate',
                }}
                placeholderText="Enter Date in valid Format: MM/DD/YYYY"
                disabled={!hsStartDate || !editMode}
                selected={hsEndDate}
                selectsEnd
                startDate={hsStartDate}
                endDate={hsEndDate}
                // isClearable
                onChange={this.handlehsEndChange}
                id="hsEndDate"
                aria-labelledby="hsEndDate"
              />
            ) : (
              <Form.Field width={2}>
                <strong>HS:School Year End Date: </strong>
                {!_.isEmpty(hsEndDate)
                  ? AmsDateFormatters.formatDate(hsEndDate)
                  : ''}
              </Form.Field>
            )}
          </Form.Group>
        ) : (
          ''
        )}
        {isEHSRequired ? (
          <Form.Group widths="equal">
            {editMode ? (
              <Form.Field
                width={2}
                name={{ children: 'ehsStartDate', htmlFor: 'ehsStartDate' }}
                control={DatePicker}
                label={'EHS:School Year Start Date'}
                placeholderText="Enter Date in valid Format: MM/DD/YYYY"
                selectsStart
                disabled={!editMode}
                selected={ehsStartDate}
                startDate={ehsStartDate}
                // isClearable
                onChange={this.handleEhsStartChange}
                id="ehsStartDate"
                aria-labelledby="ehsStartDate"
              />
            ) : (
              <Form.Field width={2}>
                <strong>EHS:School Year Start Date: </strong>
                {!_.isEmpty(ehsStartDate)
                  ? AmsDateFormatters.formatDate(ehsStartDate)
                  : ''}
              </Form.Field>
            )}
            {editMode ? (
              <Form.Field
                width={2}
                name="ehsEndDate"
                control={DatePicker}
                label={{
                  children: 'EHS:School Year End Date',
                  htmlFor: 'ehsEndDate',
                }}
                placeholderText="Enter Date in valid Format: MM/DD/YYYY"
                disabled={!ehsStartDate || !editMode}
                selected={ehsEndDate}
                selectsEnd
                startDate={ehsStartDate}
                endDate={ehsEndDate}
                // isClearable
                onChange={this.handleEhsEndChange}
                id="ehsEndDate"
                aria-labelledby="ehsEndDate"
              />
            ) : (
              <Form.Field width={2}>
                <strong>EHS:School Year End Date: </strong>
                {!_.isEmpty(ehsEndDate)
                  ? AmsDateFormatters.formatDate(ehsEndDate)
                  : ''}
              </Form.Field>
            )}
          </Form.Group>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ScheduleViewGranteeSchoolDates);
