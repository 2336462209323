import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Input,
  Loader,
} from 'semantic-ui-react';

// Components
import BrowserTitle from '../../utils/BrowserTitle';
import HelpCategoryTiles from './HelpCategoryTiles';
import HelpSearchResults from './HelpSearchResults';

import { queryHelpData } from '../../actions/help/postActions';
// Style
import './assets/style.css';

const HelpHomePage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [results, setResults] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const submitSearchQuery = () => {
    setSearchLoading(true);

    dispatch(queryHelpData({ query: searchQuery }))
      .then(response => {
        setSearchLoading(false);
        setResults(response);
      })
      .catch(err => setSearchLoading(true));
  };

  return (
    <>
      <BrowserTitle title={'Help'} />
      <div
        style={{
          background: 'rgb(246, 246, 246)',
          padding: '2rem 0',
          marginBottom: '3rem',
        }}
      >
        <Grid centered>
          <>
            {searchLoading === true ? (
              <Dimmer active inverted>
                <Loader inverted>Loading...</Loader>
              </Dimmer>
            ) : (
              <>
                <Grid.Row>
                  <Header style={{ textAlign: 'center', fontSize: '50px' }}>
                    Hi. How can we help?
                  </Header>
                </Grid.Row>

                <Grid.Row>
                  <Input
                    onSubmit={submitSearchQuery}
                    aria-label="Search"
                    icon="search"
                    onKeyPress={(e, data) => {
                      if (e.key === 'Enter') submitSearchQuery();
                    }}
                    value={searchQuery}
                    action={
                      <Button onClick={(e, data) => submitSearchQuery}>
                        <span className="visually-hidden">Search</span>
                        <Icon name="search"></Icon>
                      </Button>
                    }
                    iconPosition="left"
                    onChange={(e, data) => setSearchQuery(data.value)}
                    size="large"
                    style={{ textAlign: 'center', minWidth: '500px' }}
                    placeholder="Find anything (e.g. adding reviews, search tasks, etc)"
                  />
                </Grid.Row>
              </>
            )}
          </>
        </Grid>
      </div>

      {isEmpty(results) ? (
        <Container>
          <HelpCategoryTiles />
        </Container>
      ) : (
        <Container text>
          <HelpSearchResults results={results} />
        </Container>
      )}
    </>
  );
};

export default HelpHomePage;
