import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';

import {
  TASK_ADDED,
  TASK_FETCHED,
  TASK_UPDATED,
  TASK_DELETED,
  TASK_DETAIL_FETCHED,
  TASK_REPORT_FINDINGS_DETAIL_FETCHED,
  TASK_REPORT_FINDINGS_FEEDBACK_PROVIDED,
  TASKS_FETCHED,
  DELEGATED_TASKS_FETCHED,
  ADD_ERROR,
  REMOVE_ERROR,
} from '../redux/types';

export function setTask(task) {
  return {
    type: TASK_ADDED,
    task,
  };
}

export function getTasks(tasks) {
  return {
    type: TASK_FETCHED,
    tasks,
  };
}

export const taskDetailFetched = taskDetail => ({
  type: TASK_DETAIL_FETCHED,
  taskDetail,
});

export const taskReportFindingsDetailFetched = taskDetail => ({
  type: TASK_REPORT_FINDINGS_DETAIL_FETCHED,
  taskDetail,
});

export const taskReportFindingsFeedbackProvided = feedback => ({
  type: TASK_REPORT_FINDINGS_FEEDBACK_PROVIDED,
  feedback,
});

export function modifyTask(task) {
  return {
    type: TASK_UPDATED,
    task,
  };
}

export function taskDeleted(response) {
  return {
    type: TASK_DELETED,
    taskId: response.taskId,
  };
}

export function setError(error) {
  return {
    type: ADD_ERROR,
    error,
  };
}

export function removeError(index) {
  return {
    type: REMOVE_ERROR,
    index,
  };
}

export function clearTaskList() {
  return dispatch => {
    dispatch(getTasks([]));
  };
}

export const taskAdded = task => ({
  type: TASK_ADDED,
  task,
});

export const tasksFetched = tasks => ({
  type: TASKS_FETCHED,
  tasks,
});

export const delegatedTasksFetched = tasks => ({
  type: DELEGATED_TASKS_FETCHED,
  tasks,
});

export const fetchTasksList = taskInput => dispatch =>
  axios.post(`/task/list`, taskInput).then(response => {
    return dispatch(getTasks(response.data));
  });

export const deleteTask = taskId => dispatch =>
  axios
    .delete(`/task/${taskId}`)
    .then(response => dispatch(taskDeleted(response.data)));

export function fetchTasks(userId) {
  return dispatch => {
    axios
      .post(`/task/list/${userId}`)
      .then(response => {
        dispatch(getTasks(response.data));
      })
      .catch(error => {
        dispatch(setError(error));
      });
  };
}

export const fetchTaskDetail = reviewId => dispatch =>
  axios.post(`/task/detail`, { reviewId }).then(response => {
    return dispatch(taskDetailFetched(response.data));
  });

export const addTask = data => dispatch =>
  axios
    .post('/task', data)
    .then(response => dispatch(taskAdded(response.data)));

export const fetchReportFindingsDetail = input => dispatch =>
  axios
    .post('/task/reportFindingsDetail', input)
    .then(response => dispatch(taskReportFindingsDetailFetched(response.data)))
    .then(response => {
      if (input.delegated === true) response.taskDetail.delegated = true;
      return response.taskDetail;
    });

export const downloadManifestFile = (reviewId, url) => dispatch =>
  axios
    .post(`/files/download/`, { url }, { responseType: 'arraybuffer' }) // This is required for file download to work
    .then(response => {
      const { data } = response;

      if (!data) return;

      const contentType = response.headers['content-type'];

      fileDownload(
        response.data,
        `${reviewId}_manifest_${moment().format('x')}.pdf`,
        contentType
      );
      return response;
    });

export const fetchTaskList = filterData => dispatch =>
  axios
    .post(`/tasks`, filterData)
    .then(response => dispatch(tasksFetched(response.data)));

export const fetchDelegatedTaskList = filterData => dispatch =>
  axios
    .post(`/tasks/delegated`, filterData)
    .then(response => dispatch(delegatedTasksFetched(response.data)));

export const provideReportFindingsFeedback = input => dispatch =>
  axios
    .post('/task/provideReportFindingsFeedback', input)
    .then(response =>
      dispatch(taskReportFindingsFeedbackProvided(response.data))
    )
    .then(response => {
      return response.taskDetail;
    });

export const provideDelegatedTaskFeedback = input => dispatch =>
  axios
    .post('/task/provideDelegatedTaskFeedback', input)
    .then(response =>
      dispatch(taskReportFindingsFeedbackProvided(response.data))
    )
    .then(response => {
      return response.taskDetail;
    });

export const exportDelegatedTasks = () => dispatch =>
  axios
    .post(`/task/exportDelegatedTasks`, {}, { responseType: 'arraybuffer' })
    .then(response => {
      const { data } = response;
      const contentType = response.headers['content-type'];
      if (!response) return;

      fileDownload(
        data,
        `ohs_delegated_tasks_${moment().format('x')}.xlsx`,
        contentType
      );
      return response;
    });
