import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import Actions
import { fetchReviewFollowUps } from '../../../actions/reviewActions';

// Import components
import ReviewLayout from '../../../utils/layout/ReviewLayout';
import FollowUpsListItems from './FollowUpsListItems';

class ReviewFollowUpPage extends Component {
  state = {
    formProcessing: false,
    list: false,
  };

  componentDidMount = () => {
    const reviewId = this.props.router.params.id;
    this.fetchFollowUps(reviewId);
  };

  fetchFollowUps = reviewId => {
    this.setState({
      formProcessing: true,
    });
    if (!reviewId) return;
    let input = {
      filters: {
        parentReviewId: reviewId,
      },
      page: 1,
      limit: 25,
      sortName: 'dueDate',
      sortOrder: 'desc',
    };
    this.props.fetchReviewFollowUps(input);
  };

  render = () => {
    // const legacy = selectedReview.systemName === 'OHSMS';
    return (
      <ReviewLayout
        type={'Follow-up Reviews'}
        // title={formatReviewPageTitle(selectedReview)}
        errors={this.state.errors}
      >
        <h1>Follow-up Reviews</h1>
        <br />
        <FollowUpsListItems />
      </ReviewLayout>
    );
  };
}

ReviewFollowUpPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

ReviewFollowUpPage.propTypes = {
  fetchReviewFollowUps: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedReview: state.review.selectedReview,
});

export default connect(mapStateToProps, {
  fetchReviewFollowUps,
})(ReviewFollowUpPage);
