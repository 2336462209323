import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

export default function ReviewsList({ reviews }) {
  const emptyMessage = <p>There are no items to be reviewed.</p>;

  const reviewsList = reviews.map(review => {
    return (
      <li className="list-group-item" key={review.RevId}>
        <Link to={`/reviews/${review.RevId}`}>{review.RevId}</Link>
      </li>
    );
  });

  return (
    <ul className="list-group">
      {reviews.length === 0 ? emptyMessage : reviewsList}
    </ul>
  );
}

ReviewsList.propTypes = {
  reviews: PropTypes.array.isRequired,
};
