import React from 'react';
import { Dimmer, Segment } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

const ReviewOCISummaryLegend = props => {
  const { ociSummaryDetails } = useSelector(
    state => state.review.selectedReview
  );

  const generateLegend = () => {
    const { legend } = ociSummaryDetails;

    return Object.keys(legend).map(e => {
      return (
        <p>
          {e} - {legend[e]}
        </p>
      );
    });
  };

  return (
    <Segment basic>
      {ociSummaryDetails && ociSummaryDetails.legend ? (
        <>{generateLegend()}</>
      ) : (
        <Dimmer active inverted></Dimmer>
      )}
    </Segment>
  );
};

export default ReviewOCISummaryLegend;
